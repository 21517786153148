import React, { ReactNode } from 'react'

import { ThemeProvider } from '@babylon/medkit'

interface MedkitGlobalThemeProviderProps {
  children: ReactNode
}

const medkitGlobalTheme = {
  [ThemeProvider.theme.input.borderWidth]: '1px',
  [ThemeProvider.theme.select.height]: '36px',
}

export default function MedkitGlobalThemeProvider({
  children,
}: MedkitGlobalThemeProviderProps) {
  return <ThemeProvider theme={medkitGlobalTheme}>{children}</ThemeProvider>
}
