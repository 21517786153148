import { gql } from '@apollo/client'

const EHR_PATIENT_QUERY = gql`
  query AP_GET_EHR_PATIENT($patientId: ID!) {
    getEHRPatient(patientId: $patientId) {
      externalEhrId
    }
  }
`

export default EHR_PATIENT_QUERY
