import { defineMessages } from 'react-intl'

const baseId = 'redeem_code'

export default defineMessages({
  redeemed_codes_heading: {
    id: `${baseId}.redeemed_codes_heading`,
    defaultMessage: 'Codes',
  },
  confirm_code: {
    id: `${baseId}.confirm_code`,
    defaultMessage: 'Submit code',
  },
  enter_code: {
    id: `${baseId}.enter_code`,
    defaultMessage: 'Enter membership code',
  },
  radio_membership: {
    id: `${baseId}.radio_membership`,
    defaultMessage: 'Membership',
  },
  radio_promotional: {
    id: `${baseId}.radio_promotional`,
    defaultMessage: 'Promotional',
  },
  redeem_code: {
    id: `${baseId}.redeem_code`,
    defaultMessage: 'Redeem Code',
  },
  redemption_success: {
    id: `${baseId}.redemption_success`,
    defaultMessage: 'Success! The promotional code has been redeemed!',
  },
  redemption_error: {
    id: `${baseId}.redemption_error`,
    defaultMessage:
      "This code wasn't redeemed. Check the correct option was selected.",
  },
  redeemed_table_heading: {
    id: `${baseId}.redeemed_table_headling`,
    defaultMessage: 'Redeemed Codes',
  },
  status_heading: {
    id: `${baseId}.status_heading`,
    defaultMessage: 'Status',
  },
  promotion_heading: {
    id: `${baseId}.promotion_heading`,
    defaultMessage: 'Promotion',
  },
  plan_heading: {
    id: `${baseId}.plan_heading`,
    defaultMessage: 'Plan',
  },
  type_heading: {
    id: `${baseId}.type_heading`,
    defaultMessage: 'Type',
  },
  code_heading: {
    id: `${baseId}.code_heading`,
    defaultMessage: 'Code',
  },
  started_end_heading: {
    id: `${baseId}.started_ended_heading`,
    defaultMessage: 'Started/Ended',
  },
  appointments_heading: {
    id: `${baseId}.appointments_heading`,
    defaultMessage: 'Appointments',
  },
  actions_heading: {
    id: `${baseId}.actions_heading`,
    defaultMessage: 'Actions',
  },
  appointments_redeemed: {
    id: `${baseId}.appointments_redeemed`,
    defaultMessage: '{count} appointments',
  },
  appointments_left: {
    id: `${baseId}.appointments_left`,
    defaultMessage: '{count} left',
  },
  action_remove: {
    id: `${baseId}.action_remove`,
    defaultMessage: 'Remove',
  },
  action_reactivate: {
    id: `${baseId}.action_reactivate`,
    defaultMessage: 'Reactivate',
  },
  remove_modal_title: {
    id: `${baseId}.remove_modal_title`,
    defaultMessage: 'Remove promotion',
  },
  remove_modal_content: {
    id: `${baseId}.remove_modal_content`,
    defaultMessage:
      'Are you sure you want to remove the <b>{promoName}</b> promotion?',
  },
  remove_modal_no_action: {
    id: `${baseId}.remove_modal_no_action`,
    defaultMessage: 'no',
  },
  remove_modal_yes_action: {
    id: `${baseId}.remove_modal_yes_action`,
    defaultMessage: 'Yes',
  },
  reactivate_modal_title: {
    id: `${baseId}.reactivate_modal_title`,
    defaultMessage: 'Reactivate promotion',
  },
  reactivate_modal_content: {
    id: `${baseId}.reactivate_modal_content`,
    defaultMessage:
      'Are you sure you want to reactivate the <b>{promoName}</b> promotion?',
  },
  remove_promo_success: {
    id: `${baseId}.remove_promo_success`,
    defaultMessage: 'Success! The promotion was removed!',
  },
  remove_promo_failure: {
    id: `${baseId}.remove_promo_failure`,
    defaultMessage: 'The promotion could not be removed. Please try again',
  },
  reactivate_promo_success: {
    id: `${baseId}.reactivate_promo_success`,
    defaultMessage: 'Success! The promotion was reactivated!',
  },
  reactivate_promo_failure: {
    id: `${baseId}.reactivate_promo_failure`,
    defaultMessage: 'The promotion could not be reactivated. Please try again',
  },
})
