import React, { Fragment } from 'react'
import { useFormatMessage } from '@babylon/intl'
import cn from 'classnames'
import { Text, Box } from '@babylon/medkit'

import DataPointStateText from '../DataPointStateText'
import { FlowInterface } from '../../types'
import messages from './messages'

import styles from './DataPoints.module.scss'

const DataPoints = ({
  flow,
  highlightYesAnswers,
  hideNotApplicableAnswers,
}: Props) => {
  const fm = useFormatMessage()
  const NotApplicableDisplayValue = 'Not applicable'

  if (!flow.status.dataPointCompleteness) {
    return null
  }

  const {
    associatedDataPointDefinitions,
    completedDataPoints,
    percentageComplete,
  } = flow.status.dataPointCompleteness

  const dataPointList = associatedDataPointDefinitions?.map((definition) => {
    const dataPointValue = completedDataPoints?.find(
      (point) => point.id === definition.id
    )

    if (
      hideNotApplicableAnswers &&
      dataPointValue?.value === null &&
      dataPointValue?.displayValue === NotApplicableDisplayValue
    ) {
      return null
    }

    const highlightValue =
      highlightYesAnswers && dataPointValue?.value === 'true'

    return (
      <Fragment key={definition.id}>
        <Text className={styles.dataPointKey} tag="dt" variant="body">
          {definition.displayKey}
        </Text>
        <Text
          className={cn(
            highlightValue
              ? styles.dataPointValueHighlighted
              : styles.dataPointValue
          )}
          tag="dd"
          variant="body"
        >
          {dataPointValue?.displayValue || '-'}
        </Text>
      </Fragment>
    )
  })

  return (
    <Box className={styles.dataPoints} padding="medium">
      <DataPointStateText
        state={flow.status.dataPointCollectionState}
        text={fm(messages.percentageComplete, { percentageComplete })}
      />
      <dl className={styles.list}>{dataPointList}</dl>
    </Box>
  )
}

interface Props {
  flow: FlowInterface
  highlightYesAnswers?: boolean
  hideNotApplicableAnswers?: boolean
}

export default DataPoints
