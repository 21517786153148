import React from 'react'

import { FormikInput } from '@babylon/core-ui'
import ServiceTypeField from './ServiceTypeField'

interface Props {
  isEditing: boolean
  value?: string
  label: string
  id?: string
  name?: string
  toolTipText?: string
}

const NameField = ({
  isEditing = false,
  value = '',
  label,
  id = 'name',
  name = 'name',
  toolTipText = '',
}: Props) =>
  isEditing ? (
    <FormikInput id={id} name={name} label={label} toolTipText={toolTipText} />
  ) : (
    <ServiceTypeField label={label} value={value} tooltipText={toolTipText} />
  )

export default NameField
