import { defineMessages } from 'react-intl'

const baseId = 'error_boundary'

export default defineMessages({
  message: {
    id: `${baseId}.message`,
    defaultMessage: 'Something went wrong, please try again later',
  },
  error_code: {
    id: `${baseId}.error_code`,
    defaultMessage: 'Error code:',
  },
  error_id: {
    id: `${baseId}.error_id`,
    defaultMessage: 'Error ID:',
  },
})
