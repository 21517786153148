import React from 'react'
import { Tag } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { useProductConfig } from '@babylon/product-config'

import {
  getStatusText,
  isErrorStatus,
  isWarningStatus,
  PDSStatuses,
} from '../../util/pdsStatus'
import messages from './AccountTags.messages'
import styles from './styles.module.scss'

export enum IdentityVerificationStatus {
  Succeeded = 'succeeded',
  Pending = 'pending',
  Failed = 'failed',
}

interface ProfileLock {
  name?: string
}

interface SmsMfaStatusTagProps {
  isSmsMfaActive?: boolean
}

const SmsMfaStatusTag = ({ isSmsMfaActive }: SmsMfaStatusTagProps) => {
  const fm = useFormatMessage()

  if (isSmsMfaActive === undefined) {
    return null
  }

  return (
    <div
      data-testid={
        isSmsMfaActive
          ? 'account-tag-sms-mfa-enabled'
          : 'account-tag-sms-mfa-disabled'
      }
    >
      <Tag className={styles['accountTag--isSmsMFAActive']}>
        {isSmsMfaActive
          ? fm(messages.sms_mfa_enabled)
          : fm(messages.sms_mfa_disabled)}
      </Tag>
    </div>
  )
}

const ProfileLockTag = ({ name }: ProfileLock) => {
  const fm = useFormatMessage()

  if (name && name in messages) {
    return (
      <div data-testid={`account-tag-${name}`}>
        <Tag className={styles[`accountTag--${name}`]}>
          {fm(messages[name])}
        </Tag>
      </div>
    )
  }

  return null
}

interface IdentityVerificationTagProps {
  idVerificationStatus: IdentityVerificationStatus | null
}

const IdentityVerificationTag = ({
  idVerificationStatus,
}: IdentityVerificationTagProps) => {
  const fm = useFormatMessage()

  if (idVerificationStatus == IdentityVerificationStatus.Succeeded) {
    return (
      <div data-testid="account-tag-id-verified">
        <Tag className={styles['accountTag--idVerified']}>
          {fm(messages.id_verified)}
        </Tag>
      </div>
    )
  }

  if (idVerificationStatus == IdentityVerificationStatus.Pending) {
    return (
      <div data-testid="account-tag-id-verification-pending">
        <Tag className={styles['accountTag--idVerificationPending']}>
          {fm(messages.id_verification_pending)}
        </Tag>
      </div>
    )
  }

  if (idVerificationStatus == IdentityVerificationStatus.Failed) {
    return (
      <div data-testid="account-tag-id-not-verified">
        <Tag className={styles['accountTag--idVerificationFailed']}>
          {fm(messages.id_verification_failed)}
        </Tag>
      </div>
    )
  }

  return (
    <div data-testid="account-tag-id-not-verified">
      <Tag className={styles['accountTag--noIdVerificationRecord']}>
        {fm(messages.no_id_verification)}
      </Tag>
    </div>
  )
}

export interface AccountTagsProps {
  className?: string
  familyAccount: boolean
  familyAccountOwner: boolean
  idVerificationStatus: IdentityVerificationStatus | null
  minor: boolean
  pdsStatus?: PDSStatuses
  isSmsMfaActive?: boolean
  isStubbedProfile?: boolean
  isPendingRegistration?: boolean
  profileLocks?: ProfileLock[]
  isProfileLocked?: boolean
  showInformedConsentDecisionRequired?: boolean
  showMedicalHistoryAuthConsentDecisionRequired?: boolean
  preferredConsumerNetwork?: string
}

const getPDSClassSuffix = (status: PDSStatuses) =>
  (isErrorStatus(status) && 'error') ||
  (isWarningStatus(status) && 'warning') ||
  ''

const AccountTags = ({
  className,
  familyAccount,
  familyAccountOwner,
  idVerificationStatus,
  minor,
  pdsStatus,
  isSmsMfaActive,
  isStubbedProfile,
  isPendingRegistration,
  profileLocks,
  isProfileLocked,
  showInformedConsentDecisionRequired,
  showMedicalHistoryAuthConsentDecisionRequired,
  preferredConsumerNetwork,
}: AccountTagsProps) => {
  const { getProp } = useProductConfig()
  const preRegisteredProfilesEnabled = getProp(
    'memberOperations',
    'preRegisteredProfilesEnabled'
  )

  const fm = useFormatMessage()
  const showPDS = pdsStatus
    ? isErrorStatus(pdsStatus) || isWarningStatus(pdsStatus)
    : false
  const pdsClassSuffix =
    pdsStatus && showPDS ? getPDSClassSuffix(pdsStatus) : ''
  const pdsStatusText = getStatusText(pdsStatus as PDSStatuses)
  const displayPreRegisteredTag =
    preRegisteredProfilesEnabled && isStubbedProfile && !isPendingRegistration

  return (
    <div
      className={
        className ? `${styles.accountTags} ${className}` : styles.accountTags
      }
      data-testid="account-tags"
    >
      {displayPreRegisteredTag && (
        <div data-testid="account-tag-preregistered">
          <Tag className={styles['accountTag--preregistered']}>
            {fm(messages.preregistered)}
          </Tag>
        </div>
      )}
      {isPendingRegistration && (
        <div data-testid="account-tag-pending-registration">
          <Tag className={styles['accountTag--preregistered']}>
            {fm(messages.pending_registration)}
          </Tag>
        </div>
      )}
      {minor && (
        <div data-testid="account-tag-minor">
          <Tag className={styles['accountTag--minor']}>
            {fm(messages.minor)}
          </Tag>
        </div>
      )}
      {familyAccount && (
        <div data-testid="account-tag-family">
          <Tag className={styles['accountTag--family']}>
            {fm(messages.family)}
          </Tag>
        </div>
      )}
      {familyAccount && familyAccountOwner && (
        <div data-testid="account-tag-owner">
          <Tag className={styles['accountTag--owner']}>
            {fm(messages.owner)}
          </Tag>
        </div>
      )}
      {isProfileLocked &&
        profileLocks?.map((lock) => (
          <ProfileLockTag key={`account-tag-${lock.name}`} name={lock.name} />
        ))}
      <IdentityVerificationTag idVerificationStatus={idVerificationStatus} />
      {showPDS && (
        <div data-testid="account-tag-pds">
          <Tag className={styles[`accountTag--${pdsClassSuffix}`]}>
            {fm(messages.pds_status, { status: pdsStatusText })}
          </Tag>
        </div>
      )}
      <SmsMfaStatusTag isSmsMfaActive={isSmsMfaActive} />
      {showMedicalHistoryAuthConsentDecisionRequired && (
        <div>
          <Tag className={styles['accountTag--legalConsent']}>
            {fm(messages.medical_history_auth_consent_required)}
          </Tag>
        </div>
      )}
      {showInformedConsentDecisionRequired && (
        <div>
          <Tag className={styles['accountTag--legalConsent']}>
            {fm(messages.informed_consent_required)}
          </Tag>
        </div>
      )}
      {preferredConsumerNetwork && (
        <div>
          <Tag className={styles['accountTag--preferredCN']}>
            {fm(messages.preferred_consumer_network, {
              preferredCN: preferredConsumerNetwork,
            })}
          </Tag>
        </div>
      )}
    </div>
  )
}

export default AccountTags
