import { defineMessages } from 'react-intl'

const baseId = 'appointment_wait_time_status'

export const baseStatusKey = 'status'

export default defineMessages({
  status10: {
    id: `${baseId}.status_10`,
    defaultMessage: 'Good',
  },
  status20: {
    id: `${baseId}.status_20`,
    defaultMessage: 'Busy',
  },
  status30: {
    id: `${baseId}.status_30`,
    defaultMessage: 'Overloaded',
  },
  status40: {
    id: `${baseId}.status_40`,
    defaultMessage: 'Critical',
  },
})
