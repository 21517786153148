import React from 'react'
import { useField } from 'formik'
import Select from 'react-select'

import { Label } from '@babylon/core-ui'

import { MultiTag } from '..'

type Options = {
  key: string | number
  name: string | number
}

interface FormikMultiTagSelectProps {
  field: string
  label: string
  options?: Options[]
  disabled?: boolean
  placeholder?: string
  'data-testid'?: string
}

export default function FormikMultiTagSelect({
  field,
  label,
  options = [],
  disabled,
  placeholder = 'Select one or more...',
  'data-testid': dataTestId,
}: FormikMultiTagSelectProps) {
  const [{ value }, { error }, { setValue }] = useField(field)

  /*
   * react-select doesn't have a way to use classes to change the control style
   */
  const selectErrorBorder = error ? { borderColor: '#ff3b2f' } : {}

  return (
    <div data-testid={dataTestId}>
      <Label htmlFor={`${field}-id`}>{label}</Label>
      <Select
        disabled={disabled}
        id={`${field}-id`}
        options={options}
        labelKey="name"
        valueKey="key"
        onChange={(option) => setValue([...new Set([...value, option?.key])])}
        style={selectErrorBorder}
        matchProp="label"
        placeholder={placeholder}
      />
      <MultiTag
        tags={options?.filter((option) => value?.includes(option.key))}
        onChange={(tags) => setValue(tags.map(({ key }) => key))}
      />
    </div>
  )
}
