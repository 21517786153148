import React from 'react'
import cn from 'classnames'

import { Text } from '@babylon/core-ui'
import { formatClinician } from '../BookSlotDialog/BookSlotUtils'
import { Clinician } from './GroupSlots'

import styles from './AvailabilitySlots.module.css'

interface Props {
  active?: boolean
  disabled?: boolean
  clinicians?: Clinician[]
  time: string
}

const AvailabilitySlotContent = ({
  active = false,
  disabled = false,
  clinicians,
  time,
}: Props) => (
  <div
    className={cn(
      styles.slot,
      disabled && styles.slotDisabled,
      active && styles.slotActive
    )}
  >
    <Text bold tag="div" className={styles.slotTime}>
      {time}
    </Text>

    {!disabled && clinicians && clinicians.length === 1 && (
      <Text tag="div" ellipsis>
        {formatClinician(clinicians[0])}
      </Text>
    )}

    {!disabled && clinicians && clinicians.length > 1 && (
      <Text tag="div" ellipsis>
        {clinicians.length} available
      </Text>
    )}
  </div>
)

export default AvailabilitySlotContent
