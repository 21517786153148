import { defineMessages } from 'react-intl'

const baseId = 'comments_card'

export default defineMessages({
  empty_comments_list: {
    id: `${baseId}.empty_comments_list`,
    defaultMessage: 'No comments',
  },
  error: {
    id: `${baseId}.error`,
    defaultMessage: 'Error!',
  },
  header: {
    id: `${baseId}.header`,
    defaultMessage: 'Comments',
  },
  view_more: {
    id: `${baseId}.view_more`,
    defaultMessage: 'View all comments ({totalItems})',
  },
  add_comment_button: {
    id: `${baseId}.add_comment_button`,
    defaultMessage: 'Add comment',
  },
  validation: {
    id: `${baseId}.comment_validation`,
    defaultMessage: 'Comment cannot be empty',
  },
  placeholder: {
    id: `${baseId}.placeholder`,
    defaultMessage: 'Add a comment update here...',
  },
  submit: {
    id: `${baseId}.submit`,
    defaultMessage: 'Add comment',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  comment_added: {
    id: `${baseId}.comment_added`,
    defaultMessage: 'Comment added',
  },
  tooltip: {
    id: `${baseId}.tooltip`,
    defaultMessage: `
      Add comments to provide up to date information on what support and/or the
      patient has done or promised to do.
    `,
  },
})
