import React from 'react'
import classNames from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import {
  Box,
  ButtonReactRouterLink,
  ButtonVariant,
  Text,
} from '@babylon/medkit'

import { AssessmentStageInterface, PatientInterface } from '../../types'
import { getAssessmentState, getDisplayValues } from './utils'

import styles from './Card.module.scss'

const Card = ({ assessmentStage, member }: Props) => {
  const fm = useFormatMessage()

  const assessmentState = getAssessmentState(assessmentStage.nextFlows)
  const { buttonText, stateTag } = getDisplayValues(fm, assessmentState)

  return (
    <Box className={styles.card}>
      <Text className={styles.header} tag="span" variant="h5">
        {assessmentStage.content.header}
      </Text>
      <Text
        className={classNames(styles.tag, styles[`tag_${assessmentState}`])}
        tag="span"
        variant="body"
      >
        {stateTag}
      </Text>
      <ButtonReactRouterLink
        fullWidth
        to={`/admin/patients/${member.id}/assessment`}
        variant={ButtonVariant.secondary}
      >
        {buttonText}
      </ButtonReactRouterLink>
    </Box>
  )
}

interface Props {
  assessmentStage: AssessmentStageInterface
  member: PatientInterface
}

export default Card
