import React from 'react'
import cn from 'classnames'
import { Close } from '@babylon/icons'
import { Grid, Cell } from '@babylon/core-ui'
import { Text } from '@babylon/medkit'
import PopupDialog from '../PopupDialog'
import styles from './DrawerView.module.scss'

type Props = {
  title: string
  toggleVisible: () => void
  visible: boolean
  className?: string
  ref?: React.Ref<HTMLDivElement>
  children: JSX.Element | JSX.Element[]
  headerItem?: JSX.Element | JSX.Element[]
  headerClassName?: string
  disableCloseOnClickOut?: boolean
}

/**
 * Component renders a pop-out drawer view
 *
 * @example ```
 *  const [isVisible, setIsVisible] = useState(false)
 *
 *  <DrawerView
 *    title="Some title"
 *    toggleVisible={() => setIsVisible(false)}
 *    visible={isVisisble}
 *  >
 *    <p>Drawer contents!</p>
 *  </DrawerView>
 * ```
 *
 */
const DrawerView = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      toggleVisible,
      visible,
      children,
      className,
      headerItem,
      headerClassName,
      disableCloseOnClickOut,
    },
    ref
  ) => (
    <PopupDialog
      appear
      visible={visible}
      toggleVisible={toggleVisible}
      ref={ref}
      disableCloseOnClickOut={disableCloseOnClickOut}
      className={className}
    >
      <div>
        <Grid
          templateColumns="1fr 1fr 40px"
          className={cn(styles.DrawerView__header, headerClassName)}
        >
          <Cell
            width={headerItem ? 1 : 2}
            className={styles.DrawerView__heading}
          >
            <Text variant="h1">{title}</Text>
          </Cell>
          {headerItem && (
            <Cell center left={2} className={styles.DrawerView__headerItem}>
              {headerItem}
            </Cell>
          )}
          <Cell center left={3}>
            <button
              type="button"
              onClick={toggleVisible}
              className={styles.DrawerView__closeButton}
              data-testid="close-btn"
            >
              <Close />
              <span>Close</span>
            </button>
          </Cell>
        </Grid>
        <div>{children}</div>
      </div>
    </PopupDialog>
  )
)

export default DrawerView
