/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'

// I hate this so much, but can't find a better way to do this
// 🤬🤬🤬🤬🤬
const useOnUnmount = (callback, dependencies) => {
  const isUnmounting = useRef(false)
  useEffect(
    () => () => {
      isUnmounting.current = true
    },
    []
  )
  useEffect(
    () => () => {
      if (isUnmounting.current) {
        callback()
      }
    },
    dependencies
  )
}

export default useOnUnmount
