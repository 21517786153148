import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import className from 'classnames/bind'

import messages from './messages'
import styles from './TopBar.module.scss'

const cx = className.bind(styles)

const Icon = ({
  width = 22,
  height = 17,
  color = 'currentColor',
}: SvgProps) => (
  <svg
    className={cx(
      styles.topBarButton__icon,
      'topBarButton__icon--patientSearch'
    )}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 22"
  >
    <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 23 4)">
      <path
        fill={color}
        fillRule="nonzero"
        d="M17.9 9.88c1.307.825 2.868 3.612 3.105 5.465a.75.75 0 0 1-.743.845h-2.334a.75.75 0 1 1 0-1.5h1.39c-.448-1.385-1.48-3.075-2.219-3.542a.75.75 0 0 1 .802-1.268zM16 3.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zm0 1.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zm-1.907 9.945c-.55-2.889-2.378-4.26-5.665-4.26s-5.162 1.374-5.793 4.26h11.458zm-13.09.655c.535-4.218 3.084-6.416 7.425-6.416 4.348 0 6.85 2.208 7.276 6.436a.75.75 0 0 1-.747.825H1.747a.75.75 0 0 1-.745-.845zm7.409-7.284c-2.227 0-4.044-1.743-4.044-3.908C4.368 1.992 6.185.25 8.412.25c2.226 0 4.044 1.742 4.044 3.908 0 2.165-1.818 3.908-4.044 3.908zm0-1.5c1.412 0 2.544-1.086 2.544-2.408 0-1.323-1.132-2.408-2.544-2.408C7 1.75 5.868 2.835 5.868 4.158 5.868 5.48 7 6.566 8.412 6.566z"
      />
    </g>
  </svg>
)

export default () => (
  <Link
    data-testid="topbar-patient-search"
    className={styles.topBarButton__link}
    to="/admin/patients"
  >
    <Icon />
    <FormattedMessage {...messages.patient_search} />
  </Link>
)
