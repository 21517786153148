import { gql } from '@apollo/client'

export const GET_RELATED_PERSONS = gql`
  query GetRelatedPersons(
    $first: NonNegativeInt!
    $patientId: ID!
    $relationshipsFirst2: NonNegativeInt!
    $input: CodeableConceptCodingInput!
  ) {
    patient(id: $patientId) {
      ... on Patient {
        relatedPersons(first: $first) {
          edges {
            node {
              id
              relationships(first: $relationshipsFirst2) {
                edges {
                  node {
                    coding(input: $input) {
                      ... on Coding {
                        code
                        system
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const CREATE_PATIENT_PROFILE = gql`
  mutation CreatePatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      ... on Patient {
        id
      }
    }
  }
`

export const REGISTER_PATIENT_PROFILE = gql`
  mutation RegisterPatient($input: RegisterPatientInput!) {
    registerPatient(input: $input) {
      ... on Patient {
        id
      }
      ... on UnrecoverableError {
        errorMessage
      }
      ... on EmailTakenError {
        errorMessage
      }
    }
  }
`
export const LINK_DEPENDENT_TO_GUARDIAN = gql`
  mutation LinkDependentToGuardian(
    $input: RegistrationRegisterPatientAndCreateGuardianRelationshipInput!
  ) {
    _registration_registerPatientAndCreateGuardianRelationship(input: $input) {
      ... on Patient {
        id
        name {
          given
          family
        }
      }
      ... on UnrecoverableError {
        errorMessage
      }
    }
  }
`
