import { gql } from '@apollo/client'

const GetBookAppointmentData = gql`
  query getBookAppointmentData($memberId: ID!, $includingDigital: Boolean) {
    consultantTypes(memberId: $memberId)
    consultationTypes(memberId: $memberId)
    patient(id: $memberId) {
      id
      uuid
      first_name
      last_name
      address_post_code
      date_of_birth
      preferredConsumerNetwork: preferred_consumer_network {
        id
        enableRecordingConsent: enable_recording_consent
        languageRequested: language_requested
      }
      service_types(including_digital: $includingDigital) {
        uuid
        name
      }
    }
  }
`

export default GetBookAppointmentData
