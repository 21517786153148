import React from 'react'
import { FormikInput } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import * as Access from '@babylon/web-platform-access'
import { FormSection, FieldRow } from '../FormFields'
import messages from './HealthcareDetails.messages'

export interface HealthcareDetailsProps {
  disableMemberEditForm?: boolean
}

const HealthcareDetails = ({
  disableMemberEditForm,
}: HealthcareDetailsProps) => {
  const fm = useFormatMessage()
  const [canEditPublicHealthcareId] = Access.useDecide(
    'edit-public-healthcare-id'
  )

  return (
    <div data-testid="member-edit-healthcare-details">
      <FormSection title={fm(messages.title)}>
        <FieldRow testId="babylon_id">
          <FormikInput
            id="babylon_id"
            name="id"
            label={fm(messages.id)}
            readOnly
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="public_healthcare_id">
          <FormikInput
            id="public_healthcare_id"
            name="healthcare_identifier.public_identifier"
            label={fm(messages.public_id)}
            readOnly={!canEditPublicHealthcareId}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="private_healthcare_id">
          <FormikInput
            id="private_healthcare_id"
            name="healthcare_identifier.private_identifier"
            label={fm(messages.private_id)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
      </FormSection>
    </div>
  )
}

export default HealthcareDetails
