import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { ArrowUpFilled } from '@babylon/icons'
import { useFormatMessage } from '@babylon/intl'
import { Box, Spinner, Text } from '@babylon/medkit'

import { logException } from '../../../..'
import useAssessmentStage from '../../utils/useAssessmentStage'
import DataPoints from '../DataPoints'
import Scores from '../Scores'
import ErrorAssessmentStage from '../ErrorAssessmentStage'
import SidePanel from '../SidePanel'
import messages from './messages'

import styles from './FlowsSummary.module.scss'

import DataPointsFilter from '../DataPointsFilter'
import filterMessages from '../DataPointsFilter/messages'
import useAssessmentFilterFeatureFlags from '../../utils/useAssessmentFilterFeatureFlags'

const FlowsSummary = ({ onClose }: Props) => {
  const fm = useFormatMessage()
  const {
    assessmentYesFilterEnabled,
    assessmentNotApplicableFilterEnabled,
  } = useAssessmentFilterFeatureFlags()
  const [selectedFlowIds, setSelectedFlowIds] = useState<string[]>([])
  const { id: memberId } = useParams<{ id: string }>()
  const [highlightYesAnswers, setHighlightYesAnswers] = useState(false)
  const [hideNotApplicableAnswers, setHideNotApplicableAnswers] = useState(
    false
  )

  const { data, error, loading, refetch } = useAssessmentStage(memberId)

  if (error) {
    logException(error)
  }

  const onSelectFlow = (id: string) => {
    if (selectedFlowIds.includes(id)) {
      setSelectedFlowIds(
        selectedFlowIds.filter((selectedFlowId) => selectedFlowId !== id)
      )
    } else {
      setSelectedFlowIds([...selectedFlowIds, id])
    }
  }

  const { assessmentStage } = data

  return (
    <SidePanel onClose={onClose} title={fm(messages.title)}>
      {loading && <Spinner center variant="primary" />}
      {error && <ErrorAssessmentStage loading={loading} refetch={refetch} />}

      {!loading && assessmentYesFilterEnabled && (
        <DataPointsFilter
          onClick={() => setHighlightYesAnswers(!highlightYesAnswers)}
          checked={highlightYesAnswers}
          label={fm(filterMessages.yesAnswersFilter)}
        />
      )}

      {!loading && assessmentNotApplicableFilterEnabled && (
        <DataPointsFilter
          onClick={() => setHideNotApplicableAnswers(!hideNotApplicableAnswers)}
          checked={hideNotApplicableAnswers}
          label={fm(filterMessages.notApplicableAnswersFilter)}
        />
      )}

      {assessmentStage &&
        assessmentStage.nextFlows.map((flow) => {
          const { id, name } = flow
          const isFlowSelected = selectedFlowIds.includes(id)

          return (
            <Box className={styles.flow} key={id}>
              <Text tag="h2" variant="h5">
                <button
                  aria-controls={`${id}_panel`}
                  aria-expanded={isFlowSelected}
                  className={styles.header}
                  data-testid={`${id}_header`}
                  id={`${id}_header`}
                  onClick={() => onSelectFlow(id)}
                  type="button"
                >
                  <span>{name}</span>
                  <span
                    className={classNames(styles.arrow, {
                      [styles.arrow_selected]: isFlowSelected,
                    })}
                  >
                    <ArrowUpFilled />
                  </span>
                </button>
              </Text>
              <Box
                aria-labelledby={`${id}_header`}
                className={styles.panel}
                data-testid={`${id}_panel`}
                id={`${id}_panel`}
                hidden={!isFlowSelected}
                role="region"
              >
                <DataPoints
                  flow={flow}
                  highlightYesAnswers={highlightYesAnswers}
                  hideNotApplicableAnswers={hideNotApplicableAnswers}
                />
                <Scores scores={flow.scores} />
              </Box>
            </Box>
          )
        })}
    </SidePanel>
  )
}

interface Props {
  onClose: () => void
}

export default FlowsSummary
