import {
  Action,
  Actions,
  SetFamilyMembersAction,
  UpdateOwnerAction,
} from './actions'

export interface State {
  member: Member
  familyMembers: Member[]
}

export type Reducer = (prevState: State, action: Action) => State

const updateMemberOwner = (newOwnerId: string) => (member: any) => ({
  ...member,
  family_accounts_owner: newOwnerId === member.id,
  owner_id: newOwnerId === member.id ? null : newOwnerId,
})

const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case Actions.SET_FAMILY_MEMBERS: {
      return {
        ...state,
        familyMembers: (action as SetFamilyMembersAction).familyMembers,
      }
    }
    case Actions.UPDATE_OWNER: {
      const { newOwnerId } = action as UpdateOwnerAction
      let { member, familyMembers = [] } = state

      member = updateMemberOwner(newOwnerId)(member)
      familyMembers = familyMembers.map(updateMemberOwner(newOwnerId))

      return {
        ...state,
        familyMembers,
        member,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
