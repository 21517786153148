import { gql } from '@apollo/client'

export const AdminDrugAudits = gql`
  query AdminDrugAudits($auditableId: ID!) {
    adminDrugAudits(auditableId: $auditableId) {
      id
      auditableId
      babylonRequestId
      region
      eventCategory
      eventType
      eventReason
      eventTimestamp
      performedById
      performedByName
    }
  }
`
