import { defineMessages } from 'react-intl'

const baseId = 'switch_search_banner'

export default defineMessages({
  new_search_switch_banner: {
    id: `${baseId}.new_search_switch_banner`,
    defaultMessage:
      'NEW PATIENT SEARCH - Click to go to the old patient search. You can switch back at any time.',
  },
  old_search_switch_banner: {
    id: `${baseId}.old_search_switch_banner`,
    defaultMessage:
      'OLD PATIENT SEARCH - Click to go to the new patient search. You can switch back at any time.',
  },
})
