import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik, useFormikContext, FormikErrors } from 'formik'
import { useMutation, ApolloError } from '@apollo/client'
import remove from 'lodash/remove'
import * as Yup from 'yup'
import {
  BirthDetails,
  HealthcareIdentifier,
  ProfileLock,
  PublicHealthcareDetails,
} from '@babylon/graphql-middleware-types'
import { Grid, Cell, Snackbar } from '@babylon/core-ui'
import { AlertFilled } from '@babylon/icons'
import { useFormatMessage } from '@babylon/intl'
import {
  useTracking,
  TrackingActionType,
  TrackingElementType,
} from '@babylon/tracking/react'
import Header from './components/Header'
import PersonalDetails from './components/PersonalDetails'
import ContactDetails from './components/ContactDetails'
import HealthcareDetails from './components/HealthcareDetails'
import GPDetails from './components/GPDetails'
import { PartialMemberForEdit, Region, State } from '../types'
import {
  isValidOrEmptyPhoneNumber,
  hasErrorMessage,
  parseErrorsFromExtensions,
  parseErrorsFromMessage,
  replaceObjectValues,
  stripTypename,
} from './utils'
import messages from './MemberEdit.messages'
import styles from './MemberEdit.module.scss'
import { UPDATE_PATIENT } from './queries'
import { PATIENT_QUERY } from '../../../queries/timeline/queries'
import { fullPatientFields } from '../../../queries'
import { useIsMounted, MemberOpsModuleName } from '../../..'
import {
  ConsumerNetworkMembership,
  GPDetails as GPDetailsType,
} from '../../../app'

export interface MemberEditProps {
  consumerNetworks: ConsumerNetworkMembership[]
  isPublicHealthcareDetailsDisabled: boolean
  member: PartialMemberForEdit
  regions: Region[]
  states: State[]
  profileLocks: ProfileLock[]
  useAccountOwnersEmergencyDetails: boolean
  disableMemberEditForm?: boolean
}

export interface MemberEditStatus {
  error: string
}

Yup.addMethod(Yup.string, 'phoneNumber', isValidOrEmptyPhoneNumber)

export function prepareDataForUpdate(
  member: PartialMemberForEdit
): PartialMemberForEdit {
  const memberKeys = Object.keys(member) as (keyof PartialMemberForEdit)[]
  const preparedMember = replaceObjectValues<PartialMemberForEdit>(
    member,
    remove(memberKeys, (k) => k !== 'birth_details'),
    '',
    null
  )

  const birthDetails = replaceObjectValues(
    preparedMember.birth_details,
    ['country', 'town'],
    null,
    ''
  )
  preparedMember.birth_details =
    birthDetails && (birthDetails.town || birthDetails.country)
      ? birthDetails
      : null

  return preparedMember as PartialMemberForEdit
}

const hasErrors = (
  errors: FormikErrors<PartialMemberForEdit>,
  status: MemberEditStatus
) => Object.keys(errors).length > 0 || (status ? !!status.error : false)

const getMemberValues = (
  member: PartialMemberForEdit
): PartialMemberForEdit => ({
  ...replaceObjectValues<PartialMemberForEdit>(member, [
    'address_first_line',
    'address_second_line',
    'address_third_line',
    'address_state_code',
    'address_post_code',
    'previous_names',
    'previous_practice_name',
  ]),
  birth_details: replaceObjectValues<BirthDetails>(member.birth_details, [
    'town',
    'country',
  ]),
  emergency_contact_details: member.emergency_contact_details.map(
    (contact) => ({
      ...contact,
      full_phone_number: `${contact.phone_country_code}${contact.phone_number}`,
    })
  ),
  gp_details: replaceObjectValues<GPDetailsType>(member.gp_details, [
    'gp_name',
    'gp_address_first_line',
    'gp_address_second_line',
    'gp_address_third_line',
    'gp_address_post_code',
    'gp_surgery_name',
    'gp_surgery_phone_number',
  ]),
  healthcare_identifier: replaceObjectValues<HealthcareIdentifier>(
    member.healthcare_identifier,
    ['public_identifier', 'private_identifier']
  ),
  public_healthcare_details: replaceObjectValues<PublicHealthcareDetails>(
    member.public_healthcare_details,
    ['armed_forces_enlisting_date', 'arrival_date', 'disability']
  ),
  region: {
    iso_code: member.region.iso_code,
    name: member.region.name,
  },
})

const MemberEditForm = ({
  consumerNetworks,
  isPublicHealthcareDetailsDisabled,
  member,
  regions,
  states,
  profileLocks,
  useAccountOwnersEmergencyDetails,
  disableMemberEditForm,
}: MemberEditProps) => {
  const { errors, status, isSubmitting } = useFormikContext()
  const fm = useFormatMessage()
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(
    hasErrors(errors, status)
  )
  const { trackEvent } = useTracking()
  const isMounted = useIsMounted()

  useEffect(() => {
    if (isMounted && (isSubmitting || status?.error)) {
      setShowErrorMessage(hasErrors(errors, status))

      if (Object.keys(errors).length > 0) {
        trackEvent({
          patientId: member.id,
          elementName: 'edit-member-error',
          moduleName: MemberOpsModuleName.memberEditForm,
          elementType: TrackingElementType.form,
          actionType: TrackingActionType.formError,
        })
      }
    }
  }, [errors, status, isMounted, isSubmitting, trackEvent, member])

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        data-testid="member-edit-error-snackbar"
        icon={<AlertFilled className={styles.alert} />}
        intent="error"
        message={status ? status.error : fm(messages.error)}
        open={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
      />
      <Snackbar
        autoHideDuration={5000}
        data-testid="member-edit-disabled-snackbar"
        icon={<AlertFilled className={styles.alert} />}
        intent="primary"
        message={fm(messages.locked, {
          lockType:
            profileLocks.length > 0
              ? profileLocks.map((locks) => locks.name.toUpperCase()).join(', ')
              : '',
        })}
        open={disableMemberEditForm}
      />
      <Form>
        <Header
          id={member.id}
          firstName={member.first_name}
          lastName={member.last_name}
          consumerNetworks={consumerNetworks}
          disableMemberEditForm={disableMemberEditForm}
        />
        <Grid columns={4} columnGap={60} className={styles.content}>
          <Cell>
            <PersonalDetails
              regions={regions}
              isPublicHealthcareDetailsDisabled={
                isPublicHealthcareDetailsDisabled
              }
              disableMemberEditForm={disableMemberEditForm}
            />
          </Cell>
          <Cell>
            <ContactDetails
              useAccountOwnersEmergencyDetails={
                useAccountOwnersEmergencyDetails
              }
              states={states}
              regions={regions}
              disableMemberEditForm={disableMemberEditForm}
            />
          </Cell>
          <Cell>
            <HealthcareDetails disableMemberEditForm={disableMemberEditForm} />
          </Cell>
          <Cell>
            <GPDetails disableMemberEditForm={disableMemberEditForm} />
          </Cell>
        </Grid>
      </Form>
    </>
  )
}

export const MemberEdit = ({ member, ...rest }: MemberEditProps) => {
  const history = useHistory()
  const [updatePatient] = useMutation(UPDATE_PATIENT, {
    refetchQueries: [
      {
        query: PATIENT_QUERY(fullPatientFields),
        variables: {
          patientId: member.id,
        },
      },
    ],
  })
  const fm = useFormatMessage()
  const { trackEvent } = useTracking()

  const validationRules = Yup.object().shape({
    // Personal Details
    first_name: Yup.string().trim().required(fm(messages.cant_be_blank)),
    last_name: Yup.string().trim().required(fm(messages.cant_be_blank)),
    date_of_birth: Yup.string().nullable().required(fm(messages.cant_be_blank)),
    // Contact Details
    email: Yup.string()
      .trim()
      .email(fm(messages.invalid))
      .required(fm(messages.cant_be_blank)),
    full_phone_number: Yup.string().trim().phoneNumber(fm(messages.invalid)),
  })

  return (
    <div data-testid="member-edit">
      <Formik
        initialValues={getMemberValues(member)}
        validationSchema={validationRules}
        validateOnBlur
        validateOnChange={false}
        onSubmit={(payload, actions) => {
          const { setErrors, setStatus, setSubmitting } = actions
          const updatedPatient = prepareDataForUpdate(stripTypename(payload))
          updatePatient({
            variables: {
              id: member.id,
              patient: updatedPatient,
            },
          })
            .then(() => {
              setSubmitting(false)
              trackEvent({
                patientId: member.id,
                elementName: 'edit-member-success',
                moduleName: MemberOpsModuleName.memberEditForm,
                elementType: TrackingElementType.form,
                actionType: TrackingActionType.formSuccess,
              })

              history.push(`/admin/patients/${member.id}/memberships`)
            })
            .catch(({ graphQLErrors, message }: ApolloError) => {
              setSubmitting(false)

              if (graphQLErrors && hasErrorMessage(graphQLErrors)) {
                const { errors, status } = parseErrorsFromExtensions(
                  graphQLErrors
                )
                setErrors(errors)
                setStatus({ error: status || fm(messages.error) })

                // TODO: Once all code that populates errors in this style is gone
                // this block (through the else) should be removed
              } else if (Array.isArray(graphQLErrors) && graphQLErrors.length) {
                const { errors, status } = parseErrorsFromMessage(graphQLErrors)
                setErrors(errors)
                setStatus({ error: status || fm(messages.error) })
              } else {
                setStatus({ error: message })
              }
            })
        }}
      >
        <MemberEditForm member={member} {...rest} />
      </Formik>
    </div>
  )
}

export default MemberEdit
