import React from 'react'
import { FormikInput, Grid } from '@babylon/core-ui'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useFormatMessage } from '@babylon/intl'
import { ButtonSimple, ButtonVariant, Text } from '@babylon/medkit'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../../../../..'
import styles from '../../MemberRelationshipsCard.module.scss'
import messages from '../../MemberRelationshipsCard.messages'

const validationSchema = (yup: typeof Yup) =>
  yup.object().shape({
    uuid: yup.string().uuid().required(),
  })
type AddRelatedPersonsFormProps = {
  handleSubmit: (id: string | undefined) => void
  onCreateNewPatientButtonClick: () => void
  isMinor: boolean
  memberId: string | undefined
}

const AddRelatedPersonsForm = ({
  handleSubmit,
  onCreateNewPatientButtonClick,
  memberId,
  isMinor,
}: AddRelatedPersonsFormProps) => {
  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberRelatedPersons,
  })

  return (
    <Formik
      initialValues={{
        uuid: memberId,
      }}
      onSubmit={(values) => {
        trackClick({
          elementName: 'search-related-person-button',
          elementType: TrackingElementType.button,
        })
        handleSubmit(values.uuid)
      }}
      validationSchema={validationSchema(Yup)}
    >
      {() => (
        <Form>
          <Text variant="body" className={styles.AddRelatedPersonsDrawer__body}>
            {!isMinor
              ? fm(messages.search_patient_message)
              : fm(messages.create_search_patient_message)}
          </Text>
          <Grid templateColumns="3fr 1fr">
            <FormikInput
              type="text"
              name="uuid"
              label={fm(messages.uuid_text_input)}
              id="uuid"
              data-testid="related-person-uuid"
              value={memberId}
            />
            <ButtonSimple
              className={styles.AddRelatedPersonsForm__searchButton}
              type="submit"
              variant={ButtonVariant.primary}
            >
              {fm(messages.search_button)}
            </ButtonSimple>
          </Grid>
          {isMinor && (
            <ButtonSimple
              className={styles.AddRelatedPersonsForm__createNewPatientButton}
              type="button"
              variant={ButtonVariant.secondary}
              onClick={onCreateNewPatientButtonClick}
            >
              {fm(messages.create_new_patient_button)}
            </ButtonSimple>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default AddRelatedPersonsForm
