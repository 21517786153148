import React from 'react'
import { ButtonSimple } from '@babylon/medkit'
import { Heading } from '@babylon/core-ui'
import { Modal } from '..'
import styles from '../../styles.module.css'

interface Props {
  showConfirmCloseModal: boolean
  setShowConfirmCloseModal: (boolean) => void
  onConfirmClose: () => void
}

const EndConversationConfirmationModal = ({
  showConfirmCloseModal,
  setShowConfirmCloseModal,
  onConfirmClose,
}: Props) => (
  <Modal
    visible={showConfirmCloseModal}
    onRequestClose={() => {
      setShowConfirmCloseModal(false)
    }}
    Header={<Heading>End Live Chat</Heading>}
    Footer={
      <ButtonSimple
        onClick={() => {
          onConfirmClose()
          setShowConfirmCloseModal(false)
        }}
      >
        End as Resolved
      </ButtonSimple>
    }
  >
    <div className={styles.dialogDescription}>
      Was the member&apos;s issue resolved during this chat? If they require
      further help, please end the chat as “unresolved”, and they will be
      directed to our support team.
    </div>
  </Modal>
)

export default EndConversationConfirmationModal
