import { defineMessages } from 'react-intl'

const baseId = 'conversation_timeline'

export default defineMessages({
  printConversation: {
    id: `${baseId}.print_conversation`,
    defaultMessage: 'Print conversation',
  },
  header: {
    id: `${baseId}.header`,
    defaultMessage: 'Chat History',
  },
  conversation_type_covid19: {
    id: `${baseId}.conversation_type_covid19`,
    defaultMessage: 'Covid19',
  },
  conversation_type_support: {
    id: `${baseId}.conversation_type_support`,
    defaultMessage: 'Support live chat',
  },
  conversation_type_care_live_chat: {
    id: `${baseId}.conversation_type_care_live_chat`,
    defaultMessage: 'Live chat',
  },
  conversation_view_more: {
    id: `${baseId}.conversation_view_more`,
    defaultMessage: 'View more conversations',
  },
})
