import { useLazyQuery, ApolloError, ErrorPolicy } from '@apollo/client'
import { PatientSearchV2 } from '../queries'

interface useMemberSearchQueryProps {
  onCompleted?: () => void
  onError?: (error: ApolloError) => void
  errorPolicy?: ErrorPolicy | undefined
}

export default function useMemberSearchQuery({
  onCompleted,
  onError,
  errorPolicy = 'none',
}: useMemberSearchQueryProps = {}) {
  return useLazyQuery(PatientSearchV2, {
    errorPolicy,
    context: { clientName: 'platform-gateway' },
    onCompleted,
    onError,
  })
}
