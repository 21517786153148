import { QueryHookOptions } from '@apollo/client'
import { WorkflowState } from '@babylon/graphql-middleware-types'
import { sub, startOfToday } from 'date-fns'
import {
  PATIENT_CONSULTATIONS,
  PATIENT_TIMELINE,
  CONSULTATIONS,
  WORKFLOW_TASKS,
  REPEAT_TEMPLATES,
  PAGED_HISTORY,
} from './queries'
import { PATIENT_HISTORY_PAGE_SIZE } from './common'

const startDate = new Date().toISOString()

type PagedHistoryQueryOptionsParams = {
  patientId: string
  currentPage?: number
  pageSize?: number
  includeWorkflowsEnabled?: boolean
  memberTimelineRepeatPrescriptionsEnabled?: boolean
}

export const pagedHistoryQueryOptions = ({
  patientId,
  currentPage = 0,
  pageSize = PATIENT_HISTORY_PAGE_SIZE,
  includeWorkflowsEnabled = false,
  memberTimelineRepeatPrescriptionsEnabled = true,
}: PagedHistoryQueryOptionsParams): QueryHookOptions => {
  const eventTypes = ['Consultation']
  if (includeWorkflowsEnabled) {
    eventTypes.push('WorkflowV2')
  }

  if (memberTimelineRepeatPrescriptionsEnabled) {
    eventTypes.push('RepeatTemplate')
  }

  return {
    query: PAGED_HISTORY,
    variables: {
      patientId,
      eventTypes,
      pagination: { currentPage, pageSize },
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  }
}

type patientHistoryQueryOptionsParams = {
  patientId: string
  includingDigital?: boolean
}

export const patientHistoryQueryOptions = ({
  patientId,
  includingDigital = false,
}: patientHistoryQueryOptionsParams): QueryHookOptions => ({
  query: PATIENT_TIMELINE,
  variables: {
    patientId,
    includingDigital,
  },
  notifyOnNetworkStatusChange: true,
  errorPolicy: 'all',
})

export const upcomingActivitiesQueryOptions = (
  patientId: string
): QueryHookOptions => ({
  query: CONSULTATIONS,
  variables: {
    searchCriteria: {
      patientSearchCriteria: {
        id: patientId,
      },
      status: 'confirmed',
      dateRange: { startDate },
    },
  },
  errorPolicy: 'all',
})

export const patientConsultationsQueryOptions = (
  patientUuid: string
): QueryHookOptions => ({
  query: PATIENT_CONSULTATIONS,
  variables: {
    patientUuid,
    status: 'confirmed',
    dateRange: { startDate },
  },
  errorPolicy: 'all',
})

export const activeWorkflowsQueryOptions = (
  patientId: string,
  includeWorkflowsEnabled: boolean = false
): QueryHookOptions => ({
  query: WORKFLOW_TASKS,
  variables: {
    patientId,
    searchCriteria: {
      state: WorkflowState.Active,
    },
  },
  notifyOnNetworkStatusChange: true,
  errorPolicy: 'all',
  skip: !includeWorkflowsEnabled,
})

export const outstandingRepeatTemplateQueryOptions = (
  patientId: string,
  memberTimelineRepeatPrescriptionsEnabled: boolean = true
): QueryHookOptions => ({
  query: REPEAT_TEMPLATES,
  variables: {
    patientId,
    size: 2147483647,
    userStatesFilter: [
      {
        userState: 'READY_TO_COLLECT',
        lastChangeDateMin: sub(startOfToday(), { days: 3 }),
      },
    ],
  },
  errorPolicy: 'all',
  skip: !memberTimelineRepeatPrescriptionsEnabled,
})
