import React from 'react'
import {
  OnDetailsCopy,
  OnTransferOwnership,
  OnResendInvitationEmail,
} from '../../../types'
import MemberCard from '../MemberCard'
import styles from './styles.module.scss'

export interface Props {
  onDetailsCopy: OnDetailsCopy
  onTransferOwnership: OnTransferOwnership
  onResendInvitationEmail: OnResendInvitationEmail
}

export interface QueriedFamilyMembersProps extends Props {
  ids: string[]
  onFamilyMembersLoaded: (familyMembers: Member[]) => void
}

export interface FamilyMembersProps extends Props {
  ownerId?: number
  members: Member[]
}

const FamilyMembers = ({
  members,
  ownerId,
  onDetailsCopy,
  onTransferOwnership,
  onResendInvitationEmail,
}: FamilyMembersProps) => (
  <>
    {members.map((member: Member) => (
      <div
        key={member.id}
        className={styles.familyMember}
        data-testid="search-result-family-member"
      >
        <MemberCard
          isMain={false}
          {...member}
          familyAccount
          ownerId={ownerId}
          onDetailsCopy={onDetailsCopy}
          onResendInvitationEmail={onResendInvitationEmail}
          onTransferOwnership={onTransferOwnership}
        />
      </div>
    ))}
  </>
)

export default FamilyMembers
