import { gql } from '@apollo/client'

import { ConsultationForGpFragment } from './fragments'

export const CONSULTATIONS_V2_QUERY = gql`
  query consultations(
    $searchCriteria: ConsultationSearchCriteria
    $sortCriteria: ConsultationSortCriteria
    $paginationCriteria: PaginationCriteria
  ) {
    consultationsv2(
      searchCriteria: $searchCriteria
      sortCriteria: $sortCriteria
      paginationCriteria: $paginationCriteria
    ) {
      totalItems
      pageIndex
      itemsPerPage
      showBroadWarning
      items {
        ...ConsultationForGpFragment
      }
    }
  }
  ${ConsultationForGpFragment}
`

export const CONSUMER_NETWORKS_QUERY = gql`
  query consumerNetworks {
    consumerNetworks {
      id
      name
    }
  }
`

export const GP_SURGERY_FAXES_QUERY = gql`
  query gpSurgeryFaxes {
    gpSurgeryFaxes {
      id
      name
      faxNumber
    }
  }
`
