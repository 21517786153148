import os from 'os'

export const stringFormatter = (value: string) => {
  const quote = '"'
  const escapedQuote = `${quote}${quote}`

  let formattedValue = value

  if (value.includes(quote)) {
    formattedValue = value.replace(new RegExp(quote, 'g'), escapedQuote)
  }

  return `${quote}${formattedValue}${quote}`
}

export const stringQuoteOnlyIfNecessaryFormatter = (value: string) => {
  const quote = '"'
  const separator = ','
  const eol = os.EOL

  if ([quote, separator, eol].some((char) => value.includes(char))) {
    return stringFormatter(value)
  }

  return value
}
