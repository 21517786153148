import { QueryHookOptions } from '@apollo/client'
import {
  ConsumerNetwork,
  PatientConsumerNetwork,
  Maybe,
} from '@babylon/graphql-middleware-types'

import { useGetPatientConsumerNetworkQuery } from '../../queries/PatientConsumerNetworksQuery.middleware.hooks'
import logException from '../../ScheduleAppointment/BookAppointmentView/hooks/logException'

export type PreferredConsumerNetwork = Pick<
  PatientConsumerNetwork,
  'id' | 'active' | 'gp_at_hand' | 'is_preferred'
> & {
  consumer_network?: Maybe<Pick<ConsumerNetwork, 'uuid' | 'name'>>
}

interface usePreferredNetworkReturnProps {
  preferredNetwork?: PreferredConsumerNetwork
  loading: boolean
}

export default function usePreferredNetwork(
  memberId: string,
  options?: Pick<QueryHookOptions, 'skip'>
): usePreferredNetworkReturnProps {
  const { data, loading } = useGetPatientConsumerNetworkQuery({
    variables: {
      patientId: memberId,
    },
    skip: options?.skip,
    onError: (err) => logException(err),
  })

  const preferredNetwork =
    data?.patientConsumerNetworks?.find(
      (consumerNetwork) => consumerNetwork?.is_preferred
    ) || undefined

  return { preferredNetwork, loading }
}
