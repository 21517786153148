import { useCallback } from 'react'
import { TrackingElementType, TrackingActionType } from '../types'
import { useTracking } from './useTracking'

type Params = {
  pathname: string
  destination?: string
  isRedirect?: boolean
}
/**
 * Hook provides view tracking for a given component
 */
export function useTrackView() {
  const service = useTracking()

  const trackView = useCallback(
    ({ pathname, destination, isRedirect = false }: Params) => {
      const actionType = isRedirect
        ? TrackingActionType.pageRedirected
        : TrackingActionType.pageLoaded

      const linkDestination =
        isRedirect && destination ? { linkDestination: destination } : undefined

      service.trackView({
        screenTitle: pathname,
        elementType: TrackingElementType.page,
        actionType,
        ...linkDestination,
      })
    },
    [service]
  )

  return { trackView }
}
