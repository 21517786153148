import { addMinutes } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { Clinician } from '../AvailabilitySlots/GroupSlots'
import { Clinic } from '../AvailabilitySlots/GroupSlotsByClinic'

export const findClinicById = (
  clinics: Clinic[] = [],
  clinicId?: number | 'any'
): Clinic | null => {
  if (!clinicId || clinicId === 'any') {
    return null
  }

  return (
    clinics.find((clinic) => clinic.id.toString() === clinicId.toString()) ||
    null
  )
}

export const formatClinician = (clinician: Clinician): string => {
  if (typeof clinician === 'undefined') {
    return ''
  }

  const { gender, name } = clinician

  return `${gender ? `(${gender.charAt(0).toUpperCase()}) ` : ''}${name}`
}

export const formatDate = (UTCTimestamp: string, timeZone: string): string =>
  format(utcToZonedTime(new Date(UTCTimestamp), timeZone), 'eee do MMM yyyy', {
    timeZone,
  })

export const formatDuration = (
  UTCTimestamp: string,
  duration: number,
  timeZone: string
): string => {
  const start = utcToZonedTime(new Date(UTCTimestamp), timeZone)
  const end = addMinutes(start, duration)
  const timeZoneCode = format(start, 'OOO', { timeZone })
  const formattedStart = format(start, 'HH:mm', { timeZone })
  const formattedEnd = format(end, 'HH:mm', { timeZone })

  return `${formattedStart} - ${formattedEnd} ${timeZoneCode}`
}

export const formatLocation = (clinic: Clinic): string => {
  if (!clinic) {
    return ''
  }

  const { address, fullAddress, name } = clinic

  if (fullAddress) {
    const { line1, line2, line3, postalCode } = fullAddress

    return [`${name}:`, line1, line2, line3, postalCode]
      .filter((value) => value)
      .join(' ')
  }

  return `${name}: ${address}`
}
