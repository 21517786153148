import React, { FC } from 'react'
import cn from 'classnames'
import styles from './NoContent.module.scss'

export interface NoContentProps {
  type?: string
  testId: string
}

const NoContent: FC<NoContentProps> = ({ type = '', children, testId }) => (
  <div className={cn(styles.noContent, styles[type])} data-testid={testId}>
    {children}
  </div>
)

export default NoContent
