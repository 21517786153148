import { defineMessages } from 'react-intl'

const baseId = 'activity_log'

export default defineMessages({
  header: {
    id: `${baseId}.header`,
    defaultMessage: 'Activity log',
  },
  patients: {
    id: `${baseId}.patients`,
    defaultMessage: 'Patients',
  },
  memberDetails: {
    id: `${baseId}.member_details`,
    defaultMessage: 'Member details',
  },
  accessDenied: {
    id: `${baseId}.access_denied`,
    defaultMessage: 'Access denied',
  },
})
