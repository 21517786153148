import { useQuery } from '@apollo/client'
import { useProductConfig } from '@babylon/product-config'
import { Patient } from '@babylon/graphql-middleware-types'
import {
  PATIENT_CONSULTATIONS,
  PATIENT_TIMELINE,
  REPEAT_TEMPLATES,
  WORKFLOW_TASKS,
  PAGED_HISTORY,
  outstandingRepeatTemplateQueryOptions,
  patientConsultationsQueryOptions,
  patientHistoryQueryOptions,
  activeWorkflowsQueryOptions,
  pagedHistoryQueryOptions,
} from '../../queries'

export type PendingPatientInvites = {
  patient: Pick<Patient, 'id' | 'pendingInvites'>
}

export const useTimelineQueries = (patientId: string, patientUuid: string) => {
  const { getProp } = useProductConfig()
  const includeWorkflowsEnabled = getProp(
    'memberOperations',
    'includeWorkflowsEnabled'
  )
  const digitalInviteExtensionEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteExtensionEnabled'
  )

  const digitalInviteEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteEnabled'
  )

  const memberTimelineRepeatPrescriptionsEnabled = getProp(
    'memberOperations',
    'memberTimelineRepeatPrescriptionsEnabled'
  )

  const includingDigital = digitalInviteExtensionEnabled || digitalInviteEnabled

  // fetchPolicy will perform a re-fetch when a new appointment is booked
  const upcomingActivities = useQuery(PATIENT_CONSULTATIONS, {
    ...patientConsultationsQueryOptions(patientUuid),
    fetchPolicy: 'cache-and-network',
  })
  const outstandingRepeatPrescriptions = useQuery(REPEAT_TEMPLATES, {
    ...outstandingRepeatTemplateQueryOptions(
      patientId,
      memberTimelineRepeatPrescriptionsEnabled
    ),
  })

  const patientHistory = useQuery<PendingPatientInvites>(PATIENT_TIMELINE, {
    ...patientHistoryQueryOptions({
      patientId,
      includingDigital,
    }),
    fetchPolicy: 'cache-and-network',
  })

  const pagedHistory = useQuery(PAGED_HISTORY, {
    ...pagedHistoryQueryOptions({
      patientId,
      includeWorkflowsEnabled,
      memberTimelineRepeatPrescriptionsEnabled,
    }),
  })

  const activeWorkflows = useQuery(WORKFLOW_TASKS, {
    ...activeWorkflowsQueryOptions(patientId, includeWorkflowsEnabled),
  })

  return {
    loading:
      patientHistory.loading ||
      upcomingActivities.loading ||
      (activeWorkflows && activeWorkflows.loading) ||
      (outstandingRepeatPrescriptions &&
        outstandingRepeatPrescriptions.loading),
    outstandingRepeatPrescriptions,
    activeWorkflows,
    patientHistory,
    pagedHistory,
    upcomingActivities,
  }
}

export default useTimelineQueries
