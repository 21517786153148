import React from 'react'
import { Grid, Cell } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'
import {
  AccountTags,
  IdentityVerificationStatus,
  PatientName,
} from '../../../../..'
import { TagList, ConsumerNetworkNames } from '../Tags'
import styles from './styles.module.scss'

export interface HeadlineProps
  extends Pick<
    Member,
    | 'consumerNetworkName'
    | 'familyMembers'
    | 'accountOwner'
    | 'firstName'
    | 'identityCheck'
    | 'lastName'
    | 'minor'
    | 'publicHealthcareAdmission'
    | 'queuedForActivation'
    | 'coreRubyPatientId'
    | 'id'
  > {
  familyAccount: boolean
  isPendingRegistration?: boolean
}

const Headline = ({
  id,
  familyAccount,
  consumerNetworkName,
  accountOwner,
  firstName,
  identityCheck,
  lastName,
  minor,
  publicHealthcareAdmission,
  queuedForActivation,
  coreRubyPatientId,
  isPendingRegistration,
}: HeadlineProps) => {
  const { getProp } = useProductConfig()
  const consumerNetworkTagEnabled = getProp(
    'memberOperations',
    'consumerNetworkTagEnabled'
  )
  const preRegisteredProfilesEnabled = getProp(
    'memberOperations',
    'preRegisteredProfilesEnabled'
  )
  const enableProfileLink =
    !!preRegisteredProfilesEnabled || !!coreRubyPatientId

  return (
    <div className={styles.headline} data-testid="search-item-headline">
      <Grid columns={12} columnGap={16}>
        <Cell width={7}>
          <AccountTags
            familyAccount={familyAccount}
            familyAccountOwner={accountOwner}
            pdsStatus={publicHealthcareAdmission?.pdsTraceStatus}
            minor={minor}
            idVerificationStatus={
              identityCheck &&
              (identityCheck.status as IdentityVerificationStatus)
            }
            isStubbedProfile={!coreRubyPatientId}
            isPendingRegistration={isPendingRegistration}
          />
          <PatientName
            firstName={firstName}
            lastName={lastName}
            id={id}
            enableProfileLink={enableProfileLink}
          />
        </Cell>
        <Cell width={5}>
          <TagList>
            {consumerNetworkTagEnabled && (
              <ConsumerNetworkNames
                queuedForActivation={queuedForActivation}
                consumerNetworkName={consumerNetworkName}
              />
            )}
          </TagList>
        </Cell>
      </Grid>
    </div>
  )
}

export default Headline
