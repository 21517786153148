import React, { FC } from 'react'
import classNames from 'classnames/bind'
import { Tag } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { isQueued } from '../../../../..'

import messages from './Tags.messages'
import styles from './styles.module.scss'

const cx = classNames.bind(styles)

export interface TagListProps {
  testId?: string
}

export interface ConsumerNetworkNamesProps {
  list: ConsumerNetwork[]
  publicHealthcareAdmission: PublicHealthcareAdmission | null
  queuedForActivation: boolean
}

export const ConsumerNetworkNames: FC<ConsumerNetworkNamesProps> = ({
  list = [],
  publicHealthcareAdmission,
  queuedForActivation,
}) => {
  const fm = useFormatMessage()

  return (
    <TagList testId="search-item-tags-consumer-networks">
      {list.map((consumerNetwork) => {
        if (!consumerNetwork) {
          return null
        }

        const queued = isQueued(
          consumerNetwork,
          publicHealthcareAdmission,
          queuedForActivation
        )
        const name = queued
          ? `${consumerNetwork.name} - ${fm(messages.queued)}`
          : consumerNetwork.name
        const className = cx('consumerNetworkTag', {
          'consumerNetworkTag--queued': queued,
        })

        return (
          <Tag className={className} key={consumerNetwork.name}>
            {name}
          </Tag>
        )
      })}
    </TagList>
  )
}

export const TagList: FC<TagListProps> = ({ children, testId }) => (
  <div className={styles.tagWrapper} data-testid={testId}>
    {children}
  </div>
)
