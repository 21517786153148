import React from 'react'
import { Text, Button } from '@babylon/core-ui'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

import { FindCliniciansQuery } from '../AvailabilitySyncPage/FindCliniciansQuery.hooks'

import styles from './ClinicianSearchResults.module.css'

export interface ClinicianSearchResultsProps {
  hasSearched: boolean
  data: FindCliniciansQuery
  onSelect: (id: string, name: string) => void
}

export default function ClinicianSearchResults({
  hasSearched,
  data,
  onSelect,
}: ClinicianSearchResultsProps) {
  if (!data?.getEligibleConsultants?.length && hasSearched) {
    return (
      <div
        data-testid="clinician-search-no-result"
        className={styles.ClinicianSearchNoResult}
      >
        <FontAwesomeIcon icon={faSearch} /> No results found
      </div>
    )
  }

  return (
    <ul
      data-testid="choose-clinician-list"
      className={styles.ChooseClinicianList}
    >
      {data?.getEligibleConsultants?.map((clinician) => {
        if (!clinician) {
          return <></>
        }

        return (
          <li
            key={clinician.id}
            data-testid="choose-clinician-list-item"
            className={styles.ChooseClinicianListItem}
          >
            <Button
              onClick={() => onSelect(clinician.id, clinician.name)}
              className={styles.ChooseClinicianListItemButton}
            >
              <Text bold color="grey-type">
                {clinician.id}: {clinician.name}
              </Text>
            </Button>
          </li>
        )
      })}
    </ul>
  )
}
