import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose, withState, withHandlers } from 'recompose'

import { envUrl } from '@babylon/babylon-env'
import { withBabylonUser } from '@babylon/babylon-user'

import {
  loadPersistentData,
  savePersistentData,
  persistentDataNames,
} from '@/util/localStorage'
import withSpinner from '@/util/withSpinner'
import promptReason from '@/components/ReasonDialog'
import graphqlWithProps from '@/util/graphqlWithProps'

import {
  PrescriptionRegions,
  GpPrescriptions,
  PrescriptionChangeState,
} from './queries'

import actionTypes from './actionTypes.json'
import PrescriptionsView from './PrescriptionsView'

// DATA

export const queryOptions = {
  options: ({ user }) => ({
    variables: {
      consultantId: user.id,
      size: parseInt(
        loadPersistentData(persistentDataNames.PRESCRIPTION_PAGINATION_SIZE) ||
          10
      ),
      page: 0,
      statuses: ['CONFIRMED_RECEIVED'],
    },
  }),
}

const withData = compose(
  graphql(PrescriptionRegions, { name: 'regions' }),
  graphqlWithProps(GpPrescriptions, queryOptions),
  graphql(PrescriptionChangeState, { name: 'prescriptionChangeState' })
)

// STATE

const withStates = compose(
  withState('spinnerType', 'setSpinnerType', null),
  withState('removedPrescriptionIds', 'setRemovedPrescriptionIds', [])
)

// ACTIONS

export const handlePageSizeChange = ({ data, setSpinnerType }) => (size) => {
  savePersistentData(persistentDataNames.PRESCRIPTION_PAGINATION_SIZE, size)
  setSpinnerType('page-size')
  data.updateVariables({ size, page: 0 })
}

export const handlePageChange = ({ data, setSpinnerType }) => (page) => {
  setSpinnerType('pagination')
  data.updateVariables({ page })
}

export const handleSort = ({ data, setSpinnerType }) => (column, direction) => {
  setSpinnerType('sort')
  data.updateVariables({ sort: `${column},${direction}`, page: 0 })
}

export const handleStateChange = ({
  data,
  setSpinnerType,
  setRemovedPrescriptionIds,
}) => (event, states) => {
  setSpinnerType('page')
  data.updateVariables({ statuses: states.split(','), page: 0 }, () => {
    setRemovedPrescriptionIds([])
  })
}

export const onAction = ({ client, prescriptionChangeState }) => (
  action,
  { id }
) => {
  switch (action) {
    case 'VIEW_PDF':
      window.open(`${envUrl('FILE_DOWNLOAD_URL')}/prescription/${id}.pdf`, id)

      break
    default: {
      const processAction = (reason) => {
        prescriptionChangeState({
          variables: { id, action, reason },
        }).then(() => {
          client.resetStore()
        })
      }

      if (actionTypes[action].reasons) {
        promptReason(actionTypes[action].reasons)().then(
          processAction,
          () => null
        )
      } else {
        processAction()
      }
    }
  }
}

const withActions = withHandlers({
  handlePageSizeChange,
  handlePageChange,
  handleSort,
  handleStateChange,
  onAction,
})

// LOADER

export const isLoading = (props) => !props.data || !props.data.prescriptions

const withLoader = withSpinner(isLoading)

export default compose(
  withBabylonUser,
  withData,
  withStates,
  withApollo,
  withActions,
  withLoader
)(PrescriptionsView)
