import React from 'react'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import ConsumerNetworkTag from '../../../../../components/ConsumerNetworkTag'
import messages from './ConsumerNetworkNames.messages'
import styles from './styles.module.scss'

const cx = classNames.bind(styles)

export interface ConsumerNetworkNamesProps {
  consumerNetworkName: string
  queuedForActivation: boolean
}

export const ConsumerNetworkNames = ({
  consumerNetworkName,
  queuedForActivation,
}: ConsumerNetworkNamesProps) => {
  const fm = useFormatMessage()

  const name = queuedForActivation
    ? `${consumerNetworkName} - ${fm(messages.queued)}`
    : consumerNetworkName

  const className = cx('consumerNetworkTag', {
    'consumerNetworkTag--queued': queuedForActivation,
  })

  return <ConsumerNetworkTag name={name} isPreferred className={className} />
}
