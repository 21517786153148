import qs from 'qs'
import {
  startOfDay,
  endOfDay,
  parseISO,
  parse,
  isValid,
  differenceInMinutes,
} from 'date-fns'
import { format } from 'date-fns-tz'
import { ClinicianAvailabilityShift } from '@babylon/graphql-middleware-types'
import {
  DATE_FORMAT,
  DEFAULT_TIMEZONE,
} from './AppointmentManagementPage.constants'
import type {
  AppointmentManagementPageQueryParameters,
  GetValidQueryParameterOptions,
  AppointmentStatus,
  Appointment,
  AppointmentMedium,
  Slot,
} from './AppointmentManagementPage.types'
import { PractitionerQuery } from './Practitioner.federated.hooks'
import { AvailabilitySlotsQuery } from './AvailableSlots.middleware.hooks'

const timezoneDateFormat = "yyyy-MM-dd'T'HH:mm:ssXXX"
const admitterIdentifier = 'ADM'

const entityIdentifiers = {
  APPOINTMENT: 'https://appointment.bbl.health/Appointment',
  PATIENT: 'https://coreruby.bbl.health/Patient',
  PRACTITIONER: 'https://clinician.bbl.health/Practitioner',
}

export const parseQueryString = (
  queryString: string
): AppointmentManagementPageQueryParameters =>
  qs.parse(queryString, {
    ignoreQueryPrefix: true,
  })

export const getSelectedDateRange = (dateString: string, timeZone: string) => {
  const date = parseISO(dateString)
  const startDate = startOfDay(date)
  const endDate = endOfDay(date)

  return {
    startDate: format(startDate, timezoneDateFormat, {
      timeZone,
    }),
    endDate: format(endDate, timezoneDateFormat, {
      timeZone,
    }),
  }
}

export const getValidQueryParameters = ({
  queryStringParameters,
  defaultDate,
  userTimezone,
}: GetValidQueryParameterOptions) => {
  const { date: queryDate, timezone: queryTimezone } = queryStringParameters

  const parsedDate = queryDate
    ? parse(queryDate, DATE_FORMAT, new Date())
    : null

  const date = parsedDate && isValid(parsedDate) ? parsedDate : defaultDate
  const fallbackTimezone = userTimezone ?? DEFAULT_TIMEZONE

  return {
    date: format(date, DATE_FORMAT),
    timezone: queryTimezone ?? fallbackTimezone,
  }
}

export const mergePractitionerAppointments = (
  previousResult: PractitionerQuery,
  fetchMoreResult: PractitionerQuery | undefined
) => {
  if (
    !fetchMoreResult ||
    !previousResult.practitioner ||
    !fetchMoreResult?.practitioner ||
    !fetchMoreResult?.practitioner.appointments?.pageInfo
  ) {
    return previousResult
  }

  return {
    ...previousResult,
    practitioner: {
      ...previousResult.practitioner,
      appointments: {
        ...fetchMoreResult.practitioner.appointments,
        pageInfo: fetchMoreResult.practitioner.appointments?.pageInfo,
        edges: [
          ...(previousResult.practitioner?.appointments?.edges ?? []),
          ...(fetchMoreResult.practitioner?.appointments?.edges ?? []),
        ],
      },
    },
  }
}

export const mergeSlots = (
  previousResult: AvailabilitySlotsQuery,
  fetchMoreResult: AvailabilitySlotsQuery | undefined
) => {
  if (
    !fetchMoreResult ||
    !previousResult.availabilitySlots ||
    !fetchMoreResult?.availabilitySlots
  ) {
    return previousResult
  }

  const mergedSlots = {
    ...previousResult,
    availabilitySlots: {
      ...fetchMoreResult.availabilitySlots,
      availability_slots: [
        ...previousResult.availabilitySlots.availability_slots,
        ...fetchMoreResult.availabilitySlots.availability_slots,
      ],
    },
  }

  return mergedSlots
}

export const buildFederatedPractitionerId = (practitionerId: string) =>
  window.btoa(`${entityIdentifiers.PRACTITIONER}/${practitionerId}`)

export const updatePractitionerAppointmentStatus = (
  practitionerResponse: PractitionerQuery,
  appointmentId: string,
  status: AppointmentStatus
) => {
  const { practitioner } = practitionerResponse
  const appointmentEdges = practitioner?.appointments?.edges.map((edge) => {
    if (edge.node?.id !== appointmentId) {
      return edge
    }

    return {
      ...edge,
      node: {
        ...edge.node,
        status: status.toUpperCase(),
      },
    }
  })

  return {
    ...practitionerResponse,
    practitioner: {
      ...practitioner,
      appointments: {
        ...practitioner?.appointments,
        edges: appointmentEdges,
      },
    },
  }
}

export const isNonNullable = <TValue extends unknown>(
  value: TValue | null | undefined
): value is NonNullable<TValue> => value !== null && value !== undefined

export const formatPractitioner = (
  practitioner: PractitionerQuery['practitioner']
) => ({
  fullName: practitioner?.names?.edges[0]?.node?.text ?? 'Unknown',
})

export const getBookingAgent = (entityTypename: string | undefined) => {
  switch (entityTypename) {
    case 'Patient':
      return 'Patient'
    case 'Practitioner':
      return 'Clinician'
    case 'NonFederatedResource':
      return 'Operations'
    default:
      return null
  }
}

export const formatPractitionerAppointments = (
  practitioner: PractitionerQuery['practitioner']
): Appointment[] => {
  if (!practitioner || !practitioner.appointments?.edges.length) {
    return []
  }

  const appointmentNodes = practitioner.appointments.edges
    .map((edge) => edge.node)
    .filter(isNonNullable)

  const appointments = appointmentNodes.map((appointment) => {
    const {
      id,
      start,
      end,
      reasonCodes,
      status,
      medium,
      participants,
      patientConsumerNetwork,
      identifiers,
      requestedLanguage,
    } = appointment

    const startTime = start ? parseISO(start) : null
    const endTime = end ? parseISO(end) : null
    const durationInMinutes =
      startTime && endTime ? differenceInMinutes(endTime, startTime) : null
    const isTranslatorRequired = Boolean(requestedLanguage)

    const appointmentIdentifier = identifiers?.edges.find(
      (identifier) => identifier.node?.system === entityIdentifiers.APPOINTMENT
    )

    const admitter = participants?.edges?.find((participantsEdge) =>
      participantsEdge.node?.types?.edges?.find((typeEdge) =>
        typeEdge.node?.codings?.edges?.find(
          (codingEdge) => codingEdge.node?.code === admitterIdentifier
        )
      )
    )

    const patientNode = participants?.edges?.find(
      (edge) => edge.node?.actor?.__typename === 'Patient'
    )

    const patient =
      patientNode?.node?.actor?.__typename === 'Patient'
        ? patientNode?.node.actor
        : null

    const patientFirstName = patient?.name?.given
      ? patient?.name.given.join(' ')
      : 'Unknown'
    const patientLastName = patient?.name?.family ?? 'Unknown'
    const patientIdentifier = patient?.identifiers?.edges?.find(
      (identifier) => identifier.node?.system === entityIdentifiers.PATIENT
    )
    const isPatientMinor = patient?.isMinor ?? false

    return {
      id,
      babylonId: appointmentIdentifier?.node?.value
        ? appointmentIdentifier.node.value
        : null,
      startTime: start ?? null,
      endTime: end ?? null,
      durationInMinutes,
      isTranslatorRequired,
      status: status?.toUpperCase() ?? null,
      medium: (medium as AppointmentMedium) ?? null,
      patientConsumerNetworkId: patientConsumerNetwork ?? null,
      appointmentReasons:
        reasonCodes?.nodes
          .map((reasonNode) => reasonNode?.text?.text)
          .filter(isNonNullable) ?? [],
      patientId: patient?.id ?? null,
      patientBabylonId: patientIdentifier?.node?.value
        ? patientIdentifier.node.value
        : null,
      patientName: `${patientLastName}, ${patientFirstName}`,
      isPatientMinor,
      bookingAgent: getBookingAgent(admitter?.node?.actor?.__typename),
    }
  })

  return appointments
}

export const isAppointmentSlotAfterCurrentSessionEnd = (
  appointmentOrSlot: Appointment | Slot,
  session: ClinicianAvailabilityShift
) => {
  const time =
    'startTime' in appointmentOrSlot
      ? appointmentOrSlot.startTime
      : appointmentOrSlot.slot_time

  return (
    time &&
    differenceInMinutes(parseISO(session.shift_end_time), parseISO(time)) < 0
  )
}

export const formatPractitionerSlots = (
  practitioner: PractitionerQuery['practitioner']
): Slot[] => {
  if (!practitioner || !practitioner.availabilitySlots?.edges.length) {
    return []
  }

  const slotNodes = practitioner.availabilitySlots.edges
    .map((edge) => edge.node)
    .filter(isNonNullable)

  const slots = slotNodes.map((slot) => {
    const { bookingConstraint, start, end, id } = slot

    const startTime = start ? parseISO(start) : null
    const endTime = end ? parseISO(end) : null

    return {
      id,
      digital_bookable: bookingConstraint?.isDigital as boolean,
      physical_bookable: bookingConstraint?.isPhysical as boolean,
      admin: bookingConstraint?.isNonBookable as boolean,
      slot_time: start as string,
      slot_size: (startTime && endTime
        ? differenceInMinutes(endTime, startTime)
        : null) as number,
    }
  })

  return slots
}
