import React from 'react'
import classnames from 'classnames'
import styles from './LoadingContent.module.scss'

export interface LoadingBlockProps {
  className?: string
}

export const LoadingHeader = () => (
  <div className={styles.loadingHeader} data-testid="loading-header" />
)

export const LoadingBlock = ({ className }: LoadingBlockProps) => (
  <div
    className={classnames(styles.loadingBlock, className)}
    data-testid="loading-block"
  />
)
