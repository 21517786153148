import React from 'react'
import { useFormikContext } from 'formik'
import { FormikSelect } from '@babylon/core-ui'
import ServiceTypeField from './ServiceTypeField'
import { ServiceTypeCategory } from '../../../types/ServiceType'

interface Props {
  isEditing: boolean
  categories: ServiceTypeCategory[]
  preferredCategories?: ServiceTypeCategory[]
}

const LABEL = 'Category'

const CategoryField = ({
  isEditing,
  preferredCategories = [],
  categories,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<{
    service_type_categories: { label: string; value: string }[]
  }>()

  const formatedCategories = categories.map(({ uuid, name }) => ({
    value: uuid,
    label: name,
  }))

  return isEditing ? (
    <>
      <FormikSelect
        label={LABEL}
        id="service_type_categories"
        name="service_type_categories"
        placeholder="Please select a category type"
        options={formatedCategories}
        onChange={(option) => {
          setFieldValue('service_type_categories', option)
        }}
        value={values.service_type_categories}
        isMulti
      />
    </>
  ) : (
    <ServiceTypeField
      label={LABEL}
      value={preferredCategories.map(({ name }) => name).join(' / ')}
    />
  )
}

export default CategoryField
