/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type ClinicianQueryVariables = Types.Exact<{
  idOrUuid: Types.Scalars['ID']
}>

export type ClinicianQuery = {
  consultant?: Types.Maybe<Pick<Types.Consultant, 'uuid' | 'id' | 'email'>>
}

export const ClinicianDocument = gql`
  query Clinician($idOrUuid: ID!) {
    consultant(id: $idOrUuid) {
      uuid
      id
      email
    }
  }
`
export function useClinicianQuery(
  baseOptions: Apollo.QueryHookOptions<ClinicianQuery, ClinicianQueryVariables>
) {
  return Apollo.useQuery<ClinicianQuery, ClinicianQueryVariables>(
    ClinicianDocument,
    baseOptions
  )
}
export function useClinicianLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClinicianQuery,
    ClinicianQueryVariables
  >
) {
  return Apollo.useLazyQuery<ClinicianQuery, ClinicianQueryVariables>(
    ClinicianDocument,
    baseOptions
  )
}
export type ClinicianQueryHookResult = ReturnType<typeof useClinicianQuery>
export type ClinicianLazyQueryHookResult = ReturnType<
  typeof useClinicianLazyQuery
>
export type ClinicianQueryResult = Apollo.QueryResult<
  ClinicianQuery,
  ClinicianQueryVariables
>
