import React from 'react'

import { FormikTextarea, Tooltip } from '@babylon/core-ui'

interface Props {
  withTooltip?: boolean
}

const ReasonField = ({ withTooltip = false }: Props) => (
  <FormikTextarea
    id="appointment_reason"
    name="appointment_reason"
    label={
      <>
        Reason of Appointment:{' '}
        {withTooltip && (
          <Tooltip
            info="The reason will be visible to both the user and the next clinician"
            placement="right"
            overlayStyle={{ width: 300 }}
          />
        )}
      </>
    }
    placeholder="Enter a reason for the patient and the clinician"
  />
)

export default ReasonField
