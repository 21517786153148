import React, { ChangeEvent, FC, useState } from 'react'
import { Input, Text, Button } from '@babylon/core-ui'
import { useMutation, ApolloError } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import { useSnackBar } from '../../../../../../hooks'
import { errorListFromQueryError } from '../../../../../../util/helpers'
import messages from '../RelatedPersons.messages'
import styles from './AddGuardian.module.scss'
import { ADD_GUARDIAN } from './queries'

interface AddGuardianProps {
  dependantId: string
  disabled?: boolean
}

const GetErrorMessage = (error: ApolloError, carerId: string, fm) => {
  let errorMessage = fm(messages.add_guardian_error)

  if (error.toString().includes('Guardian cannot be minor')) {
    return fm(messages.add_guardian_minor_error)
  }

  if (error.toString().includes('Dependant must have a date of birth')) {
    return fm(messages.add_guardian_date_of_birth_error)
  }

  const graphQLErrors = errorListFromQueryError(error)
  graphQLErrors.forEach((graphqlError) => {
    const { status, data } = graphqlError.response

    if (status === 404) {
      errorMessage = fm(messages.add_guardian_not_found_error, { carerId })
    }

    const details = data?.details?.[0]?.issue || []

    if (
      status === 400 &&
      (details.includes('already exists') ||
        details.includes('There is already an inverse relationship'))
    ) {
      errorMessage = fm(messages.add_guardian_relationship_already_exists_error)
    }
  })

  return errorMessage
}

const AddGuardian: FC<AddGuardianProps> = ({
  dependantId,
  disabled = false,
}: AddGuardianProps) => {
  const fm = useFormatMessage()
  const [carerId, setCarerId] = useState('')
  const onChangeCarerIdHandler = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setCarerId(target.value)
  const { setSnackbarMessage } = useSnackBar()

  const [addGuardian, { loading }] = useMutation(ADD_GUARDIAN, {
    notifyOnNetworkStatusChange: true,
    variables: { carerId, dependantId },
    onError: (error) => {
      const errorMessage = GetErrorMessage(error, carerId, fm)
      setSnackbarMessage(errorMessage, undefined, 'error')
    },
    refetchQueries: ['AP_GetCarers'],
  })

  return (
    <div className={styles.AddGuardian}>
      <Text bold size="medium" className={styles.AddGuardian__label}>
        {fm(messages.add_guardian_header)}
      </Text>
      <Input
        data-testid="Add-Guardian-Text"
        onChange={onChangeCarerIdHandler}
        placeholder={fm(messages.add_guardian_placeholder)}
        className={styles.AddGuardian__input}
      />
      <Button
        data-testid="Add-Guardian-Button"
        onClick={() => addGuardian()}
        loading={loading}
        disabled={!carerId || disabled}
        className={styles.AddGuardian__button}
      >
        {fm(messages.add_guardian_button_text)}
      </Button>
    </div>
  )
}

export default AddGuardian
