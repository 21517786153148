/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type MonthlyCalendarScheduleQueryVariables = Types.Exact<{
  consultantId: Types.Scalars['ID']
  date?: Types.Maybe<Types.Scalars['String']>
  timezone?: Types.Maybe<Types.Scalars['String']>
}>

export type MonthlyCalendarScheduleQuery = {
  monthlyCalendarScheduleV2: Pick<
    Types.MonthlyCalendarSchedule,
    'shifts' | 'incompleteConsultations'
  >
}

export const MonthlyCalendarScheduleDocument = gql`
  query MonthlyCalendarSchedule(
    $consultantId: ID!
    $date: String
    $timezone: String
  ) {
    monthlyCalendarScheduleV2(
      consultantId: $consultantId
      date: $date
      timezone: $timezone
    ) {
      shifts
      incompleteConsultations
    }
  }
`
export function useMonthlyCalendarScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<
    MonthlyCalendarScheduleQuery,
    MonthlyCalendarScheduleQueryVariables
  >
) {
  return Apollo.useQuery<
    MonthlyCalendarScheduleQuery,
    MonthlyCalendarScheduleQueryVariables
  >(MonthlyCalendarScheduleDocument, baseOptions)
}
export function useMonthlyCalendarScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonthlyCalendarScheduleQuery,
    MonthlyCalendarScheduleQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    MonthlyCalendarScheduleQuery,
    MonthlyCalendarScheduleQueryVariables
  >(MonthlyCalendarScheduleDocument, baseOptions)
}
export type MonthlyCalendarScheduleQueryHookResult = ReturnType<
  typeof useMonthlyCalendarScheduleQuery
>
export type MonthlyCalendarScheduleLazyQueryHookResult = ReturnType<
  typeof useMonthlyCalendarScheduleLazyQuery
>
export type MonthlyCalendarScheduleQueryResult = Apollo.QueryResult<
  MonthlyCalendarScheduleQuery,
  MonthlyCalendarScheduleQueryVariables
>
