/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type GetOrphanedSlotsQueryVariables = Types.Exact<{
  offset?: Types.Maybe<Types.Scalars['Int']>
  limit?: Types.Maybe<Types.Scalars['Int']>
}>

export type GetOrphanedSlotsQuery = {
  orphanedSlots: Pick<Types.OrphanedSlots, 'total' | 'limit' | 'offset'> & {
    slots: Array<
      Pick<
        Types.OrphanedSlot,
        | 'consultant_id'
        | 'consultant_name'
        | 'time'
        | 'appointment_id'
        | 'patient_name'
        | 'patient_id'
        | 'priority'
        | 'slot_size'
        | 'supply_network_id'
        | 'category_id'
        | 'recurrent'
        | 'marked_for_removal'
        | 'physical'
        | 'digital_bookable'
        | 'physical_bookable'
        | 'admin'
        | 'practice_id'
        | 'rtm'
        | 'availability_upload_id'
        | 'shift_id'
        | 'text_bookable'
        | 'local_time'
        | 'updated_at'
      >
    >
  }
}

export const GetOrphanedSlotsDocument = gql`
  query GetOrphanedSlots($offset: Int, $limit: Int) {
    orphanedSlots(offset: $offset, limit: $limit) {
      total
      limit
      offset
      slots {
        consultant_id
        consultant_name
        time
        appointment_id
        patient_name
        patient_id
        priority
        slot_size
        supply_network_id
        category_id
        recurrent
        marked_for_removal
        physical
        digital_bookable
        physical_bookable
        admin
        practice_id
        rtm
        availability_upload_id
        shift_id
        text_bookable
        local_time
        updated_at
      }
    }
  }
`
export function useGetOrphanedSlotsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrphanedSlotsQuery,
    GetOrphanedSlotsQueryVariables
  >
) {
  return Apollo.useQuery<GetOrphanedSlotsQuery, GetOrphanedSlotsQueryVariables>(
    GetOrphanedSlotsDocument,
    baseOptions
  )
}
export function useGetOrphanedSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrphanedSlotsQuery,
    GetOrphanedSlotsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOrphanedSlotsQuery,
    GetOrphanedSlotsQueryVariables
  >(GetOrphanedSlotsDocument, baseOptions)
}
export type GetOrphanedSlotsQueryHookResult = ReturnType<
  typeof useGetOrphanedSlotsQuery
>
export type GetOrphanedSlotsLazyQueryHookResult = ReturnType<
  typeof useGetOrphanedSlotsLazyQuery
>
export type GetOrphanedSlotsQueryResult = Apollo.QueryResult<
  GetOrphanedSlotsQuery,
  GetOrphanedSlotsQueryVariables
>
