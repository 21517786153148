import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { InputFilter, Option } from '../../components/InputFilter'
import { useServiceTypesQuery } from './ServiceTypesQuery.middleware.hooks'
import messages from './ServiceTypesSearcher.messages'

type Props = {
  readOnly: boolean
  multiple: boolean
  defaultValue?: any[]
  onSelectedServiceTypesChanged: (selectedServiceTypes: Option[]) => void
}

const ServiceTypesSearcher = ({
  readOnly,
  multiple,
  defaultValue,
  onSelectedServiceTypesChanged,
}: Props) => {
  const { data, loading } = useServiceTypesQuery()

  const fm = useFormatMessage()

  const options = loading ? [] : ((data?.serviceTypes || []) as Option[])

  return (
    <InputFilter
      label={fm(messages.title)}
      readOnly={readOnly}
      multiple={multiple}
      defaultValue={defaultValue}
      options={options}
      loading={loading}
      onSelectedValuesChange={onSelectedServiceTypesChanged}
    />
  )
}

export default ServiceTypesSearcher
