import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'

import DataPoints from '../../components/DataPoints'
import Scores from '../../components/Scores'
import SidePanel from '../../components/SidePanel'
import useAssessmentFilterFeatureFlags from '../../utils/useAssessmentFilterFeatureFlags'
import DataPointsFilter from '../../components/DataPointsFilter'
import { FlowInterface } from '../../types'
import messages from './messages'
import filterMessages from '../../components/DataPointsFilter/messages'

import styles from './FlowPanel.module.scss'

const FlowPanel = ({
  flow,
  isChatbotOpen,
  onClose,
  onOpenChatbot,
  shouldChatbotSkip,
}: Props) => {
  const fm = useFormatMessage()
  const {
    assessmentYesFilterEnabled,
    assessmentNotApplicableFilterEnabled,
  } = useAssessmentFilterFeatureFlags()
  const [highlightYesAnswers, setHighlightYesAnswers] = useState(false)
  const [hideNotApplicableAnswers, setHideNotApplicableAnswers] = useState(
    false
  )

  const isComplete = flow.status.dataPointCollectionState === 'all_collected'
  const isContinueLoading = isChatbotOpen && shouldChatbotSkip
  const isRestartLoading = isChatbotOpen && !shouldChatbotSkip

  return (
    <SidePanel onClose={onClose} title={flow.name}>
      {assessmentYesFilterEnabled && (
        <DataPointsFilter
          onClick={() => setHighlightYesAnswers(!highlightYesAnswers)}
          checked={highlightYesAnswers}
          label={fm(filterMessages.yesAnswersFilter)}
        />
      )}

      {assessmentNotApplicableFilterEnabled && (
        <DataPointsFilter
          onClick={() => setHideNotApplicableAnswers(!hideNotApplicableAnswers)}
          checked={hideNotApplicableAnswers}
          label={fm(filterMessages.notApplicableAnswersFilter)}
        />
      )}

      <DataPoints
        flow={flow}
        highlightYesAnswers={highlightYesAnswers}
        hideNotApplicableAnswers={hideNotApplicableAnswers}
      />
      <Scores scores={flow.scores} />
      <div className={styles.buttons}>
        <ButtonSimple
          disabled={isChatbotOpen}
          fullWidth
          isLoading={isRestartLoading}
          loadingLabel=""
          onClick={() => onOpenChatbot(false)}
          variant={ButtonVariant.secondary}
        >
          {fm(messages.restart)}
        </ButtonSimple>
        {!isComplete && (
          <ButtonSimple
            disabled={isChatbotOpen}
            fullWidth
            isLoading={isContinueLoading}
            loadingLabel=""
            onClick={() => onOpenChatbot(true)}
          >
            {fm(messages.continue)}
          </ButtonSimple>
        )}
      </div>
    </SidePanel>
  )
}

interface Props {
  flow: FlowInterface
  isChatbotOpen: boolean
  onClose: () => void
  onOpenChatbot: (shouldSkip: boolean) => void
  shouldChatbotSkip: boolean
}

export default FlowPanel
