import { defineMessages } from 'react-intl'

const baseId = 'conversation_messages'

export default defineMessages({
  transferredToAgent: {
    id: `${baseId}.transferred_to_agent`,
    defaultMessage: '{agent1} transferred the conversation to {agent2}',
  },
  agentConnected: {
    id: `${baseId}.agent_connected`,
    defaultMessage: 'The patient connected to {agent}',
  },
})
