import React, { useMemo } from 'react'
import { Grid, Cell, Text } from '@babylon/core-ui'
import { formatISO } from 'date-fns'
import styles from './ClinicianVisibilityGrid.module.css'
import ClinicianVisibilitySlot from '../ClinicianVisibilitySlot'
import ClinicianAvatarAndName from '../../components/ClinicianAvatarAndName'
import { createTimes, mapClinicianSlotsToTimes, formatTime } from '../utils'

interface Props {
  day?: string
  heading?: string
  clinicians: GroupedClinician[]
  timezoneId?: string
  startAt?: {
    hours: number
    mins: number
  }
  refetchResults: () => void
}

export const minSlotSize = 5
export const singleCellWidth = 30

export default function ClinicianVisibilityGrid({
  day = formatISO(new Date()),
  heading,
  clinicians,
  timezoneId,
  startAt,
  refetchResults,
}: Props) {
  const times = useMemo(
    () => createTimes({ day, timezoneId, interval: 5, startAt }),
    [day, timezoneId, startAt]
  )
  const displayTimes = useMemo(
    () =>
      createTimes({
        day,
        timezoneId,
        interval: 10,
        formatFunc: formatTime,
        startAt,
      }),
    [day, timezoneId, startAt]
  )

  return (
    <div className={styles.wrapper}>
      <Grid
        templateColumns={`repeat(${displayTimes.length}, 1fr)`}
        className={styles.grid}
        columnGap="0"
        rowGap="0"
      >
        {displayTimes.map((time) => (
          <Cell
            key={time}
            className={styles.timeCell}
            width={1}
            data-testid="time-cell"
          >
            <Text className={styles.time} size="small">
              {time}
            </Text>
          </Cell>
        ))}
      </Grid>

      <Grid
        columns="1"
        className={styles.clinicianGrid}
        columnGap="0"
        rowGap="0"
      >
        <Cell className={styles.headerSlot}>
          <Text
            color="black-type"
            bold
            className={styles.headerText}
            data-testid="clinician-visibility-grouping-heading"
          >
            {heading}
          </Text>
        </Cell>
        {clinicians.map((clinician) => (
          <Cell
            key={clinician.id}
            // clinician-visibility-clinician-cell className is used to identify
            // the element
            className={`clinician-visibility-clinician-cell ${styles.cell}`}
            data-testid="clinician-cell"
          >
            <ClinicianAvatarAndName
              name={clinician.name}
              avatarUrl=""
              profession={clinician.profession_name}
              to={`/admin/consultants/${clinician.id}`}
            />
          </Cell>
        ))}
      </Grid>
      <Grid
        templateColumns={`repeat(${times.length}, 1fr)`}
        templateRows={`repeat(${clinicians.length}, 1fr)`}
        className={styles.grid}
        columnGap="0"
        rowGap="0"
      >
        {clinicians.map((clinician) =>
          mapClinicianSlotsToTimes(times, clinician.slots, timezoneId).map(
            (slot) => (
              <Cell
                style={{
                  // this is used over width prop because there is an issue when only
                  // one row exists the cell does not span correctly.
                  // width = (slot_size / single slot) * width of single cell
                  width:
                    ((slot.slot_size || minSlotSize) / minSlotSize || 1) *
                    singleCellWidth,
                }}
                width={(slot.slot_size || minSlotSize) / minSlotSize}
                key={slot.timeline_slot}
                data-testid="clinician-time-slot-cell"
                className={styles.gridCell}
              >
                {slot?.slot_time && (
                  <ClinicianVisibilitySlot
                    data-testid="clinician-slot"
                    slot={slot}
                    onCancelAppointment={refetchResults}
                  />
                )}
              </Cell>
            )
          )
        )}
      </Grid>
    </div>
  )
}
