import { gql } from '@apollo/client'

const patientBody = `
  id
  address_first_line
  address_second_line
  address_third_line
  address_state_code
  address_post_code
  consumer_network_names
  consumer_networks {
    active
    consumer_network {
      id
      name
    }
    is_preferred
  }
  date_of_birth
  email
  family_accounts_member_ids
  family_accounts_owner
  first_name
  full_phone_number
  gender
  healthcare_identifier {
    public_identifier
    private_identifier
    unconfirmed_public_identifier
  }
  identity_check {
    status
  }
  invitation_was_sent_but_not_accepted
  last_name
  minor
  owner_id
  patient_membership_link
  public_healthcare_admission {
    pds_trace_status
    consumer_network_id
  }
  queued_for_activation
`

const commonBody = `
  pagination {
    perPage
    page
    total
  }
  patients {
    ${patientBody}
  }
`

export const SearchPatients = gql`
  query AP_SearchPatients($query: MopPatientSearchCriteria!) {
    searchPatients(searchCriteria: $query) {
      ${commonBody}
    }
  }
`

export const PATIENTS_BY_IDS = gql`
  query AP_PatientsByIds($ids: [ID]!) {
    patientsByIds(ids: $ids) {
      ${patientBody}
    }
  }
`
