import React, { ReactNode } from 'react'

import Breadcrumbs from '../Breadcrumbs'
import Heading from '../../foundation/Heading'
import './Page.scss'

interface Props {
  children?: ReactNode
  breadcrumbs?: ReactNode[]
  title?: string
  actions?: ReactNode
  inlineBreadcrumbs?: boolean
}

const Page = ({
  children,
  breadcrumbs,
  title,
  actions,
  inlineBreadcrumbs,
}: Props) => (
  <div className="core-page">
    {breadcrumbs && !inlineBreadcrumbs && <Breadcrumbs items={breadcrumbs} />}

    {(title || actions || breadcrumbs) && (
      <div className="core-page__header">
        {breadcrumbs && inlineBreadcrumbs && (
          <Breadcrumbs items={breadcrumbs} noSeparator />
        )}
        {title && <Heading level="h1">{title}</Heading>}
        {actions && <div>{actions}</div>}
      </div>
    )}

    <div className="core-page__content">{children}</div>
  </div>
)

export default Page
