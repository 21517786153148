import React from 'react'
import { useQuery } from '@apollo/client'
import { useHistory, useLocation, RouteComponentProps } from 'react-router-dom'

import { Button, Page, Card } from '@babylon/core-ui'

import ProfessionsManagementList from './ProfessionsManagementList'

import { UnexpectedError } from '../../Utils'
import { LoadingContainer } from '../../components'
import GetProfessions from '../GetProfessionsQuery'

interface ProfessionsData {
  professions: Profession[]
}

const ProfessionsManagement = () => {
  const { loading, error, data } = useQuery<ProfessionsData>(GetProfessions)
  const { pathname } = useLocation<RouteComponentProps>()

  return (
    <Page breadcrumbs={['Professions']}>
      {error && <UnexpectedError message={error.message} visible />}
      <Card
        title="Professions"
        actions={<AddProfessionCTA path={`${pathname}/add`} />}
      >
        <LoadingContainer loading={loading}>
          {data && <ProfessionsManagementList items={data.professions} />}
        </LoadingContainer>
      </Card>
    </Page>
  )
}

export default ProfessionsManagement

const AddProfessionCTA = ({ path }: { path: string }) => {
  const history = useHistory()

  const handleClick = () => {
    history.push(path)
  }

  return <Button onClick={handleClick}>Add profession</Button>
}
