import React, { useState } from 'react'
import {
  FormikSelect,
  SelectOptionType,
  SelectValueType,
} from '@babylon/core-ui'
import { debounce } from 'lodash'
import { FormikAsyncDropdownProps } from './FormikAsyncDropdown.types'

const SEARCH_TERM_MIN_LENGTH = 3
const SEARCH_TERM_DEBOUNCE_TIME = 500

const FormikAsyncDropdown = ({
  name,
  label,
  placeholder,
  onSearch,
  initialOptions,
}: FormikAsyncDropdownProps) => {
  const [options, setOptions] = useState<SelectOptionType[]>(initialOptions)
  const [isLoading, setIsLoading] = useState(false)

  const handleSelectInput = debounce((value: string) => {
    if (value.length < SEARCH_TERM_MIN_LENGTH) {
      return
    }

    setIsLoading(true)

    onSearch(value)
      .then((response) => {
        setOptions(response)
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false)
      })
  }, SEARCH_TERM_DEBOUNCE_TIME)

  const handleSelectChange = (value: SelectValueType) => {
    if (!value) {
      setOptions([])

      return
    }

    setOptions([value])
  }

  return (
    <FormikSelect
      id={name}
      isSearchable
      isClearable
      name={name}
      label={label}
      options={options}
      isLoading={isLoading}
      placeholder={placeholder}
      onInputChange={handleSelectInput}
      onChange={handleSelectChange}
    />
  )
}

export default FormikAsyncDropdown
