const RelationshipTypes: Map<string, string> = new Map([
  ['GUARDIAN', 'GUARDIAN'],
  ['MINOR', 'MINOR'],
  ['ADULT-FAMILY', 'ADULT FAMILY'],
])

interface GetTagTextProps {
  type: string
  isPrimary: boolean
  isCarer: boolean
}

export const getTagText = ({ type, isPrimary, isCarer }: GetTagTextProps) => {
  const tag =
    (type === RelationshipTypes.get('GUARDIAN') &&
      !isCarer &&
      RelationshipTypes.get('MINOR')) ||
    RelationshipTypes.get(type)

  return (tag && `${(isPrimary && 'PRIMARY ') || ''}${tag}`) || ''
}
