import React, { FC, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, FormikSelect } from '@babylon/core-ui'
import { Formik, Form } from 'formik'
import { FormattedMessage } from 'react-intl'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../../..'
import { logException } from '../../../../ErrorBoundary'

import styles from './Membership.module.scss'
import messages from './ConsumerNetworksCard.messages'
import {
  CANCEL_GP_AT_HAND,
  DEREGISTER_GP_AT_HAND,
  REJOIN_CONSUMER_NETWORK,
  REMOVE_CONSUMER_NETWORK,
  SELECT_CONSUMER_NETWORK,
  GPAH_CONSUMER_NETWORKS,
  ADD_PATIENT_TO_CONSUMER_NETWORK,
} from './queries'

export interface ConsumerNetworkPayload {
  networkId: string
  patientId: string
}

export interface ActionDropdownProps extends ConsumerNetworkPayload {
  onActionClick: () => void
}

export interface ActionButtonProps extends ConsumerNetworkPayload {
  onClick: () => void
}

export interface CommonButtonProps {
  action: string
  mutation: any
  mutationVariables: any
  onComplete: () => void
}

const CommonButton: FC<CommonButtonProps> = ({
  action,
  children,
  mutation,
  mutationVariables,
  onComplete: onCompleted,
}) => {
  const [mutationFn] = useMutation(mutation, { onCompleted })
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })
  const onClick = () => {
    trackClick({
      elementName: 'consumer-network-btn',
      elementType: TrackingElementType.button,
    })
    mutationFn(mutationVariables)
  }

  return (
    <Button
      className={styles.consumerNetwork__button}
      intent="secondary"
      testId={`action-button-${action}`}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export const ChangeCityDropdown = ({
  networkId,
  patientId,
  onActionClick,
}: ActionDropdownProps) => {
  const { loading, error: getConsumerNetworksError, data } = useQuery<{
    gpahConsumerNetworks: { id: string; name: string }[]
  }>(GPAH_CONSUMER_NETWORKS, {
    onError: (error) => {
      logException(error)
    },
  })
  const [
    addPatientToConsumerNetwork,
    { error: addPatientToConsumerNetworkError },
  ] = useMutation(ADD_PATIENT_TO_CONSUMER_NETWORK, {
    onCompleted: () => {
      onActionClick()
    },
    onError: (error) => {
      logException(error)
    },
  })

  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })

  const options = useMemo(
    () =>
      data?.gpahConsumerNetworks
        .filter((x) => x.id !== networkId)
        .map((x) => ({ value: x.id, label: x.name })) || [],
    [data, networkId]
  )

  if (getConsumerNetworksError) {
    return <FormattedMessage {...messages.error_fetching_consumer_networks} />
  }

  if (addPatientToConsumerNetworkError) {
    return (
      <FormattedMessage {...messages.error_adding_user_to_consumer_network} />
    )
  }

  return (
    <Formik initialValues={{ gpahChangeCity: null }} onSubmit={() => {}}>
      <Form>
        <FormikSelect
          id="gpahChangeCity"
          isLoading={loading}
          name="gpahChangeCity"
          options={options}
          onChange={(option) => {
            if (!option) {
              return
            }

            trackClick({
              elementName: 'gpah-change-city-dd',
              elementType: TrackingElementType.dropdown,
            })
            addPatientToConsumerNetwork({
              variables: { input: { patientId, networkId: option.value } },
            })
          }}
          placeholder={<FormattedMessage {...messages.change_city_dropdown} />}
        />
      </Form>
    </Formik>
  )
}

export const CancelButton: FC<ActionButtonProps> = ({ onClick, patientId }) => (
  <CommonButton
    action="cancel"
    mutation={CANCEL_GP_AT_HAND}
    mutationVariables={{ variables: { patientId } }}
    onComplete={onClick}
  >
    <FormattedMessage {...messages.cancel_button} />
  </CommonButton>
)

export const DeregisterButton: FC<ActionButtonProps> = ({
  onClick,
  patientId,
}) => (
  <CommonButton
    action="deregister"
    mutation={DEREGISTER_GP_AT_HAND}
    mutationVariables={{ variables: { patientId } }}
    onComplete={onClick}
  >
    <FormattedMessage {...messages.deregister_button} />
  </CommonButton>
)

export const RejoinButton: FC<ActionButtonProps> = ({ onClick, ...input }) => (
  <CommonButton
    action="rejoin"
    mutation={REJOIN_CONSUMER_NETWORK}
    mutationVariables={{ variables: { input } }}
    onComplete={onClick}
  >
    <FormattedMessage {...messages.rejoin_button} />
  </CommonButton>
)

export const RemoveButton: FC<ActionButtonProps> = ({ onClick, ...input }) => (
  <CommonButton
    action="remove"
    mutation={REMOVE_CONSUMER_NETWORK}
    mutationVariables={{ variables: { input } }}
    onComplete={onClick}
  >
    <FormattedMessage {...messages.remove_button} />
  </CommonButton>
)

export const SelectButton: FC<ActionButtonProps> = ({ onClick, ...input }) => (
  <CommonButton
    action="select"
    mutation={SELECT_CONSUMER_NETWORK}
    mutationVariables={{ variables: { input } }}
    onComplete={onClick}
  >
    <FormattedMessage {...messages.select_button} />
  </CommonButton>
)
