import React from 'react'

import Icon from '@/components/Icon'

import styles from './styles.module.scss'

const ActionsView = ({ buttons, actions: { onAction }, id }) => (
  <div className={styles.actions}>
    {buttons.map((button) => (
      <div key={button.name} className={button.name}>
        <button onClick={() => onAction && onAction(button.name, id)}>
          {button.icon ? <Icon name={button.icon} /> : null}
          {button.icon && button.label ? ' ' : null}
          {button.label}
        </button>
      </div>
    ))}
  </div>
)

export default ActionsView
