import { gql } from '@apollo/client'

export const APPLY_REDEMPTIONS = gql`
  mutation MOP_ApplyRedemption(
    $code: String!
    $patientId: Int!
    $redemptionType: RedemptionType!
  ) {
    applyRedemptionV1(
      code: $code
      patientId: $patientId
      redemptionType: $redemptionType
    )
  }
`

export const QUERY_REDEEMED_CODES = gql`
  query MOP_GetRedeemCodes($patientId: ID!, $page: Int) {
    redeemedCodes(patientId: $patientId, page: $page) {
      id
      promotion_name
      plan_title
      promotion_type
      status
      code
      activated_at
      redemption_end_date
      appointments_left
      appointments_booked
      is_reactivatable
    }
  }
`

export const REMOVE_REDEMPTION_CODE = gql`
  mutation MOP_RemoveRedemption($patientId: ID!, $redemptionId: ID!) {
    removeRedemption(patientId: $patientId, redemptionId: $redemptionId)
  }
`

export const REACTIVATE_REDEMPTION_CODE = gql`
  mutation MOP_ReactivateRedemption($patientId: ID!, $redemptionId: ID!) {
    reactivateRedemption(patientId: $patientId, redemptionId: $redemptionId)
  }
`
