import {
  startOfISOWeek,
  endOfISOWeek,
  formatISO,
  eachDayOfInterval,
} from 'date-fns'

export default function buildWeekFromDay(day = new Date()) {
  const start = startOfISOWeek(new Date(day))
  const end = endOfISOWeek(new Date(day))

  return {
    start: formatISO(start),
    end: formatISO(end),
    days: eachDayOfInterval({
      start,
      end,
    }).map((value) => formatISO(new Date(value), { representation: 'date' })),
  }
}
