import { GraphQLError } from 'graphql'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import set from 'lodash/set'
import * as Yup from 'yup'
import { parsePhoneNumberWithError } from 'libphonenumber-js/max'
import { GraphQLErrorWithExtensions } from '../../../core'

type FormStatus = {
  status: String
  errors: Object
}

// Used to parse errors that have been serialized in the error message
// into a format that can be populated into the form's errors.
// This can be removed once we're using the Apollo error extensions throughout.
export const parseErrorsFromMessage = (
  graphQLErrors: ReadonlyArray<GraphQLError>
): FormStatus => {
  const errorsObj = {}
  const formErrors: [string] | [] = []

  const parsedError = JSON.parse(graphQLErrors[0].message)
  const errors =
    get(parsedError, 'extensions.response.body.errors') ||
    get(parsedError, 'response.data.errors')

  if (errors) {
    if (Array.isArray(errors)) {
      formErrors.push(errors)
    } else {
      for (const [field, error] of Object.entries<string | string[]>(errors)) {
        const errorMessage: string = Array.isArray(error)
          ? error.join(', ')
          : error
        set(errorsObj, field, capitalize(errorMessage))
      }
    }
  } else {
    console.warn('No errors found in response')
  }

  return {
    status: formErrors.join(', '),
    errors: errorsObj,
  }
}

export const hasErrorMessage = (graphQLErrors: readonly GraphQLError[]) =>
  graphQLErrors.find((err: GraphQLErrorWithExtensions) => {
    const errorCode = err.extensions?.response?.status

    return !!errorCode && errorCode >= 400
  })

export const parseErrorsFromExtensions = (
  graphQLErrors: ReadonlyArray<GraphQLError>
): FormStatus => {
  let fieldErrors = {}
  const formErrors: [string] | [] = []

  for (const graphQLError of graphQLErrors) {
    const errors = graphQLError.extensions?.response?.body?.errors

    if (Array.isArray(errors)) {
      // coerce to string?
      formErrors.push(errors)
    } else if (typeof errors === 'object') {
      fieldErrors = errors
    }
  }

  return {
    status: formErrors.join(', '),
    errors: fieldErrors,
  }
}

export function replaceObjectValues<T>(
  prop: T | null,
  keys: (keyof T)[],
  comparator: any = null,
  replacement: any = ''
): T {
  return keys.reduce<T>(
    (obj, key) => {
      if (!prop) {
        return {
          ...obj,
          [key]: replacement,
        }
      }

      const value = prop[key]
      let newValue

      if (Array.isArray(value)) {
        newValue = value.map((v) =>
          replaceObjectValues(v, Object.keys(v), comparator, replacement)
        )
      } else if (typeof value === 'object' && value !== null) {
        newValue = replaceObjectValues<any>(
          value,
          Object.keys(value),
          comparator,
          replacement
        )
      } else {
        newValue = prop[key] !== comparator ? prop[key] : replacement
      }

      return {
        ...obj,
        [key]: newValue,
      }
    },
    { ...prop } as T
  )
}

export const stripTypename = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(stripTypename)
  }

  const copy = { ...obj }

  if (copy.hasOwnProperty('__typename')) {
    delete copy.__typename
  }

  for (const [key, value] of Object.entries(copy)) {
    if (value && typeof value === 'object') {
      copy[key] = stripTypename(value)
    }
  }

  return copy
}

export function isValidOrEmptyPhoneNumber(msg: string) {
  let customErrorMessage

  return Yup.mixed().test({
    name: 'isValidOrEmptyPhoneNumber',
    exclusive: false,
    message: () => customErrorMessage || msg,
    test(value) {
      try {
        if (value === undefined) {
          return true
        }

        const phoneNumber = parsePhoneNumberWithError(value)

        return phoneNumber.isPossible()
      } catch (err) {
        // TODO: Map error messages to localized custom error messages
        // customErrorMessage = err.message
        return false
      }
    },
  })
}
