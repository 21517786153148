import React, { useState, ReactElement } from 'react'
import {
  Text,
  Card,
  Table,
  TableCell,
  TableRow,
  Button,
  ConfirmationModal,
} from '@babylon/core-ui'
import UnexpectedError from '../../../Utils/UnexpectedError'
import EntityItemsModal from './EntityItemsModal'
import titleCase from './titleCase'
import styles from '../../ServiceMix.module.css'

const NULL_CONFIRMATION = {
  id: '',
  name: '',
}

const defaultEntityRenderer = ({ item }: EntityRendererProps<Entity>) => (
  <Text style={{ fontWeight: 'bold' }}>{item.name}</Text>
)

export interface Entity {
  id: string
  name: string
}

export interface EntityRendererProps<E extends Entity> {
  item: E
}

export interface EntityQuery<E extends Entity> {
  query: any
  variables: (filterText: string) => any
  resultSelector: (data: any) => E[]
}

interface EntityCardProps<E extends Entity> {
  name: string
  query: EntityQuery<E>
  entityRenderer?: (props: EntityRendererProps<E>) => ReactElement
  extraColumns?: Record<string, string>
  items: E[]
  allowFiltering?: boolean
  onItemsAdded: (ids: string[]) => Promise<any>
  onItemRemoved: (id: string) => Promise<any>
}

const EntityCard = <E extends Entity>({
  name,
  query,
  entityRenderer = defaultEntityRenderer,
  extraColumns = {},
  items,
  allowFiltering = true,
  onItemsAdded,
  onItemRemoved,
}: EntityCardProps<E>) => {
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [confirmationRemovalItem, setConfirmationRemovalItem] = useState(
    NULL_CONFIRMATION
  )
  const [itemsBeingAdded, setItemsBeingAdded] = useState(false)
  const [itemBeingRemoved, setItemBeingRemoved] = useState(false)
  const [onItemsAddedError, setOnItemsAddedError] = useState(null)
  const [onItemRemovedError, setOnItemRemovedError] = useState(null)
  const titleCaseName = titleCase(name)

  return (
    <>
      <Card
        title={`${titleCaseName}(s)`}
        actions={
          <Button
            disabled={addModalOpen || itemsBeingAdded}
            loading={itemsBeingAdded}
            onClick={() => setAddModalOpen(true)}
          >
            Add {name}s
          </Button>
        }
      >
        <Table
          fullWidth
          striped
          headers={['Name', ...Object.values(extraColumns), 'Action(s)']}
        >
          {!!items.length &&
            items.map((item: Entity) => {
              const { id, name: itemName } = item

              return (
                <TableRow key={id}>
                  <TableCell>{itemName}</TableCell>
                  {Object.keys(extraColumns).map((key) => (
                    <TableCell key={key}>{item[key]}</TableCell>
                  ))}
                  <TableCell width="110px">
                    <Button
                      intent="secondary"
                      disabled={confirmationRemovalItem.id !== ''}
                      loading={
                        itemBeingRemoved && confirmationRemovalItem.id === id
                      }
                      onClick={() => {
                        setConfirmationRemovalItem({ name: itemName, id })
                        setRemoveModalOpen(true)
                      }}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
        </Table>
        <EntityItemsModal
          name={name}
          query={query}
          entityRenderer={entityRenderer}
          currentItems={items}
          open={addModalOpen}
          allowFiltering={allowFiltering}
          onClose={() => {
            setAddModalOpen(false)
          }}
          onItemsAdded={async (itemIds) => {
            await setItemsBeingAdded(true)

            try {
              await onItemsAdded(itemIds)
            } catch (e) {
              setOnItemsAddedError(e)
            } finally {
              await setItemsBeingAdded(false)
            }
          }}
        />
        <ConfirmationModal
          className={styles.MedKitTheme}
          label={`${titleCaseName}(s)`}
          open={removeModalOpen}
          onClose={() =>
            Promise.all([
              setRemoveModalOpen(false),
              setConfirmationRemovalItem(NULL_CONFIRMATION),
            ])
          }
          onConfirm={async () => {
            await Promise.all([
              setRemoveModalOpen(false),
              setItemBeingRemoved(true),
            ])

            try {
              await onItemRemoved(confirmationRemovalItem.id)
            } catch (e) {
              setOnItemRemovedError(e)
            }

            await Promise.all([
              setConfirmationRemovalItem(NULL_CONFIRMATION),
              setItemBeingRemoved(false),
            ])
          }}
        >
          Are you sure you want to remove {confirmationRemovalItem.name}?
        </ConfirmationModal>
      </Card>
      <UnexpectedError
        visible={!!onItemsAddedError}
        onClose={() => {
          setOnItemsAddedError(null)
        }}
        message={`${titleCaseName}(s) were not added.`}
      />
      <UnexpectedError
        visible={!!onItemRemovedError}
        onClose={() => {
          setOnItemRemovedError(null)
        }}
        message={`${titleCaseName} was not removed.`}
      />
    </>
  )
}

export default EntityCard
