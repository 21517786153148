import { defineMessages } from 'react-intl'

const baseId = 'patient_metrics'

export default defineMessages({
  modal_title: {
    id: `${baseId}.modal_title`,
    defaultMessage: 'Patient metrics',
  },
})
