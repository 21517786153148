import React from 'react'

const LiveChat = ({
  width = 22,
  height = 22,
  color = 'currentColor',
}: SvgProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.894 18.7424L18.6743 18.5227H18.3636H8.36364C7.77785 18.5227 7.29545 18.0403 7.29545 17.4545V15.6364C7.29545 15.0506 7.77785 14.5682 8.36364 14.5682H20.1818C20.7676 14.5682 21.25 15.0506 21.25 15.6364V20.7145C21.25 20.7606 21.2377 20.7872 21.225 20.8052C21.2099 20.8266 21.1852 20.8477 21.1519 20.8615C21.1186 20.8753 21.0862 20.8779 21.0604 20.8734C21.0387 20.8696 21.0111 20.8595 20.9785 20.8269L18.894 18.7424ZM6.07 11.25H5.72083L5.49605 11.5172L3.00579 14.4777C2.97999 14.499 2.95777 14.5066 2.93963 14.5098C2.91382 14.5142 2.88141 14.5117 2.84811 14.4979C2.81482 14.4841 2.79009 14.463 2.77502 14.4416C2.76234 14.4236 2.75 14.397 2.75 14.3509V3.81818C2.75 3.2324 3.2324 2.75 3.81818 2.75H16.5455C17.1312 2.75 17.6136 3.23239 17.6136 3.81818V10.1818C17.6136 10.7676 17.1312 11.25 16.5455 11.25H6.07Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M13.8182 7.68168H6.54546C6.41994 7.68168 6.31819 7.57993 6.31819 7.45441V6.54532C6.31819 6.4198 6.41994 6.31805 6.54546 6.31805H13.8182C13.9437 6.31805 14.0455 6.4198 14.0455 6.54532V7.45441C14.0455 7.57993 13.9437 7.68168 13.8182 7.68168Z"
      stroke={color}
      strokeWidth="1.36364"
    />
  </svg>
)

export default LiveChat
