import React, { useEffect, useState } from 'react'
import * as Cookies from 'js-cookie'
import { snakeCase } from 'lodash/fp'
import { Checkbox, Heading } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'
import { FormattedMessage } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import messages from './messages'
import styles from './styles.module.scss'

const Experiment = ({ experiment }: { experiment: string }) => {
  const fm = useFormatMessage()
  const [isEnabled, setEnabled] = useState(Cookies.get(experiment) === 'true')

  useEffect(() => {
    if (isEnabled) {
      Cookies.set(experiment, 'true', { expires: 1095 })
    } else {
      Cookies.remove(experiment)
    }
  }, [isEnabled, experiment])

  return (
    <tr key={experiment} className={styles.ExperimentName}>
      <td>
        <Checkbox
          name={experiment}
          checked={isEnabled}
          onChange={() => {
            setEnabled(!isEnabled)
          }}
          testId={`experiment-${experiment}`}
        />
      </td>
      <th>
        <label htmlFor={experiment}>
          {messages[experiment] ? fm(messages[experiment]) : `${experiment}`}
        </label>
      </th>
    </tr>
  )
}

const Experiments = () => {
  let enabledProductConfigExperiments: string[] = []
  const { getProp } = useProductConfig()

  const productConfigExperiments = getProp<Record<string, boolean>>(
    'memberOperations',
    'betaFeatures'
  )

  for (let key in productConfigExperiments) {
    if (productConfigExperiments[key]) {
      enabledProductConfigExperiments.push(snakeCase(key))
    }
  }

  return (
    <div className={styles.Experiments}>
      <Heading level="h2" className={styles.Heading}>
        <FormattedMessage {...messages.heading} />
      </Heading>
      <table>
        <tbody>
          {enabledProductConfigExperiments.length &&
            enabledProductConfigExperiments.map((experiment) => (
              <Experiment key={String(experiment)} experiment={experiment} />
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default Experiments
