import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faVideo,
  faHospitalUser,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons'
import {
  LegacyFormikDropdown,
  LegacyFormikDropdownOption,
  LegacyFormikDropdownOptionValues,
} from '@babylon/core-ui'
import ConsultationTypeLabels, {
  ConsultationTypeName,
} from '../../types/ConsultationTypes'

export const flipDirection = (consultationType) =>
  consultationType === 'voice' ? 'horizontal' : undefined

const icons = {
  physical: faHospitalUser,
  video: faVideo,
  voice: faPhone,
}

export const ConsultationTypeLabel = ({ value }: any) => (
  <span>
    <FontAwesomeIcon icon={icons[value]} flip={flipDirection(value)} />
    <span> {ConsultationTypeLabels[value]}</span>
  </span>
)

interface Props {
  className?: string
  consultationTypeList: ConsultationTypeName[]
  onChange: (value: LegacyFormikDropdownOptionValues | null) => void
  disabled?: boolean
}

export default function ConsultationTypeSelector({
  className,
  consultationTypeList,
  onChange,
  disabled = false,
}: Props) {
  return (
    <div className={className} data-testid="consultation-type-selector">
      <LegacyFormikDropdown
        label="Consultation Type"
        id="consultation_type"
        name="consultation_type"
        options={consultationTypeList.map((value) => ({ value }))}
        onChange={(option: LegacyFormikDropdownOption | null) =>
          onChange(option?.value || null)
        }
        optionRenderer={ConsultationTypeLabel}
        valueRenderer={ConsultationTypeLabel}
        disabled={disabled}
        autoFocus
        searchable={false}
      />
    </div>
  )
}
