export const persistentDataNames = {
  PRESCRIPTION_PAGINATION_SIZE: 'prescription-pagination-size',
  DRUGS_PAGINATION_SIZE: 'drugs-pagination-size',
}

export const loadPersistentData = (name) =>
  window.localStorage.getItem(`ap2-${name}`)

export const savePersistentData = (name, value) =>
  window.localStorage.setItem(`ap2-${name}`, value)
