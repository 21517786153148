import { useProductConfig } from '@babylon/product-config'
import { envFlag } from '@babylon/babylon-env'

import * as Access from '@babylon/web-platform-access'

export default function useAccessControl(): [
  { [key: string]: boolean },
  boolean
] {
  // Populate the navigation config
  const { getProp } = useProductConfig()
  const config = (getProp('memberOperations', 'navigationAvailability') ||
    {}) as NavConfig

  const [
    {
      canAccessDashboard,
      canAccessAllAppointment,
      canAccessOldAppointment,
      canAccessNewAppointment,
      canAccessConsultationSummary,
      canAccessAdminPrescription,
      canAccessGpPrescription,
      canAccessPharmacyPrescription,
      canAccessReferral,
      canAccessHospitalReferral,
      canAccessNhsRegistration,
      canAccessFindClinician,
      canAccessGpSurgeryFax,
      canAccessProfession,
      canAccessReallocateShift,
      canAccessClinicianVisibility,
      canAccessConsumerNetwork,
      canAccessPlan,
      canAccessPromotion,
      canAccessPromotionValidation,
      canAccessUploadPromoCode,
      canAccessNetworkRelationship,
      canAccessAppSetting,
      canAccessDrug,
      canAccessPharmacy,
      canAccessPharmacist,
      canAccessPortalLogin,
      canAccessRegion,
      canAccessReport,
      canAccessServiceMix,
      canAccessSpecialismCategory,
      canAccessSupplyNetwork,
      canAccessAvailability,
      canAccessLiveConversation,
    },
    canAccessError,
    canAccessLoading,
  ] = Access.useDecide({
    canAccessDashboard: 'dashboard',
    canAccessAllAppointment: 'all-appointment',
    canAccessOldAppointment: 'old-appointment',
    canAccessNewAppointment: 'new-appointment',
    canAccessConsultationSummary: 'consultation-summary',
    canAccessAdminPrescription: 'admin-prescription',
    canAccessGpPrescription: 'gp-prescription',
    canAccessPharmacyPrescription: 'pharmacy-prescription',
    canAccessReferral: 'referral',
    canAccessHospitalReferral: 'hospital-referral',
    canAccessNhsRegistration: 'nhs-registration',
    canAccessFindClinician: 'find-clinician',
    canAccessGpSurgeryFax: 'gp-surgery-fax',
    canAccessProfession: 'profession',
    canAccessReallocateShift: 'reallocate-shift',
    canAccessClinicianVisibility: 'clinician-visibility',
    canAccessConsumerNetwork: 'consumer-network',
    canAccessPlan: 'plan',
    canAccessPromotion: 'promotion',
    canAccessPromotionValidation: 'promotion-validation',
    canAccessUploadPromoCode: 'upload-promo-code',
    canAccessNetworkRelationship: 'network-relationship',
    canAccessAppSetting: 'app-setting',
    canAccessDrug: 'drug',
    canAccessPharmacy: 'pharmacy',
    canAccessPharmacist: 'pharmacist',
    canAccessPortalLogin: 'portal-login',
    canAccessRegion: 'region',
    canAccessReport: 'report',
    canAccessServiceMix: 'service-mix',
    canAccessSpecialismCategory: 'specialism-category',
    canAccessSupplyNetwork: 'supply-network',
    canAccessAvailability: 'availability',
    canAccessLiveConversation: 'live-conversation',
  })
  return [
    {
      accessDashboard:
        config.categoryDashboard && !canAccessError && canAccessDashboard,
      accessAllAppointments: !canAccessError && canAccessAllAppointment,
      accessOldAppointments:
        config.appointments && !canAccessError && canAccessOldAppointment,
      accessNewAppointments:
        config.appointments && !canAccessError && canAccessNewAppointment,
      accessAvailability: !canAccessError && canAccessAvailability,
      accessConsultationSummaries:
        config.toSendToGp &&
        getProp('memberOperations', 'consultationSummariesEnabled') &&
        !canAccessError &&
        canAccessConsultationSummary,
      accessPrescriptions:
        config.prescriptions &&
        !canAccessError &&
        (canAccessAdminPrescription ||
          canAccessPharmacyPrescription ||
          canAccessGpPrescription),
      accessHospitalReferrals:
        config.hospitalReferrals &&
        !canAccessError &&
        canAccessHospitalReferral,
      accessNhsRegistrations:
        config.nhsRegistrations && !canAccessError && canAccessNhsRegistration,
      accessFindClinician:
        config.findClinician && !canAccessError && canAccessFindClinician,
      accessGpSurgeryFax:
        config.gpSurgeryFax && !canAccessError && canAccessGpSurgeryFax,
      accessConsumerNetworks:
        config.consumerNetwork && !canAccessError && canAccessConsumerNetwork,
      accessPlans: config.plans && !canAccessError && canAccessPlan,
      accessPromotions:
        config.promotions && !canAccessError && canAccessPromotion,
      accessPromotionValidations:
        config.promotionValidations &&
        !canAccessError &&
        canAccessPromotionValidation,
      accessUploadPromoCodes:
        config.uploadPromoCodes && !canAccessError && canAccessUploadPromoCode,
      accessNetworkRelationships:
        config.consumerNetwork &&
        !canAccessError &&
        canAccessNetworkRelationship,
      accessAppSettings:
        config.appSettings && !canAccessError && canAccessAppSetting,
      accessDrugs: config.drugs && !canAccessError && canAccessDrug,
      accessPharmacies:
        config.pharmacies && !canAccessError && canAccessPharmacy,
      accessPharmacists:
        config.pharmacists && !canAccessError && canAccessPharmacist,
      accessPortalLogins:
        config.portalLogins && !canAccessError && canAccessPortalLogin,
      accessRegions: config.regions && !canAccessError && canAccessRegion,
      accessReports: config.reports && !canAccessError && canAccessReport,
      accessSpecialismCategories:
        config.specialismCategories &&
        !canAccessError &&
        canAccessSpecialismCategory,
      accessSupplyNetworks:
        config.supplyNetworks && !canAccessError && canAccessSupplyNetwork,
      accessClinicianVisibility:
        getProp('memberOperations', 'clinicianVisibilityPageEnabled') &&
        !canAccessError &&
        canAccessClinicianVisibility,
      accessProfessions:
        getProp('memberOperations', 'professionsPageEnabled') &&
        !canAccessError &&
        canAccessProfession,
      accessReallocateShift:
        getProp('memberOperations', 'reallocateShiftPageEnabled') &&
        !canAccessError &&
        canAccessReallocateShift,
      accessReferrals:
        getProp('memberOperations', 'referralsPageEnabled') &&
        !canAccessError &&
        canAccessReferral,
      accessServiceMix:
        getProp('memberOperations', 'serviceMixPageEnabled') &&
        !canAccessError &&
        canAccessServiceMix,
      accessAppointmentWaitTimes: envFlag(
        'ENABLE_APPOINTMENT_WAIT_TIMES_PAGE',
        false
      ),
      accessAppointmentWaitTimeConfigs: envFlag(
        'ENABLE_APPOINTMENT_WAIT_TIME_CONFIGS_PAGE',
        false
      ),
      accessLiveConversations:
        envFlag('ENABLE_LIVE_CONVERSATION_PAGE', false) &&
        canAccessLiveConversation &&
        !canAccessError,
      accessCareJourney:
        getProp(
          'clinicalCare',
          'appointments.adminPortal.careJourneyCreationEntryEnabled'
        ) && !canAccessError,
    },
    canAccessLoading,
  ]
}
