import { graphql } from '@apollo/client/react/hoc'
import { compose, withState, withHandlers } from 'recompose'

import { envUrl } from '@babylon/babylon-env'
import {
  loadPersistentData,
  savePersistentData,
  persistentDataNames,
} from '@/util/localStorage'
import withSpinner from '@/util/withSpinner'
import promptReason from '@/components/ReasonDialog'
import graphqlWithProps from '@/util/graphqlWithProps'
import withErrorMessage from '@/util/withErrorMessage'

import {
  PrescriptionRegions,
  PharmacyPrescriptions,
  PrescriptionRejectPharmacySupplier,
  PrescriptionMarkDrugsDispatched,
} from './queries'

import PrescriptionsView from './PrescriptionsView'

// DATA

export const queryOptions = {
  options: {
    variables: {
      size: parseInt(
        loadPersistentData(persistentDataNames.PRESCRIPTION_PAGINATION_SIZE) ||
          10
      ),
      page: 0,
      states: 'DELIVERY_PENDING',
    },
  },
}

const withData = compose(
  graphql(PrescriptionRegions, { name: 'regions' }),
  graphqlWithProps(PharmacyPrescriptions, queryOptions),
  graphql(PrescriptionRejectPharmacySupplier, {
    name: 'prescriptionRejectPharmacySupplier',
  }),
  graphql(PrescriptionMarkDrugsDispatched, {
    name: 'prescriptionMarkDrugsDispatched',
  })
)

// STATE

const withStates = compose(
  withState('spinnerType', 'setSpinnerType', null),
  withState('removedPrescriptionIds', 'setRemovedPrescriptionIds', [])
)

// ACTIONS

export const handlePageSizeChange = ({ data, setSpinnerType }) => (size) => {
  savePersistentData(persistentDataNames.PRESCRIPTION_PAGINATION_SIZE, size)
  setSpinnerType('page-size')
  data.updateVariables({ size, page: 0 })
}

export const handlePageChange = ({ data, setSpinnerType }) => (page) => {
  setSpinnerType('pagination')
  data.updateVariables({ page })
}

export const handleSort = ({ data, setSpinnerType }) => (column, direction) => {
  setSpinnerType('sort')
  data.updateVariables({ sort: `${column},${direction}`, page: 0 })
}

export const handleStateChange = ({
  data,
  setSpinnerType,
  setRemovedPrescriptionIds,
}) => (event, states) => {
  setTimeout(() => {
    setSpinnerType(states)
    data.updateVariables({ states, page: 0 }, () => {
      setRemovedPrescriptionIds([])
    })
  }, 100)
}

export const handleConfirm = ({
  prescriptionMarkDrugsDispatched,
  removedPrescriptionIds,
  setRemovedPrescriptionIds,
}) => (event, { id }) => {
  prescriptionMarkDrugsDispatched({
    variables: { id },
    refetchQueries: ['PharmacyPrescriptions'],
  })
  setRemovedPrescriptionIds([...removedPrescriptionIds, id])
}

const rejectionReasons = [
  'Medicine not in stock/unavailable',
  'Price unsuitable for the patient',
  'Cannot contact patient',
]

export const handleCancel = ({
  prescriptionRejectPharmacySupplier,
  removedPrescriptionIds,
  setRemovedPrescriptionIds,
}) => (event, { id }) => {
  promptReason(rejectionReasons)().then(
    (reason) => {
      prescriptionRejectPharmacySupplier({
        variables: { id, reason },
        refetchQueries: ['PharmacyPrescriptions'],
      })
      setRemovedPrescriptionIds([...removedPrescriptionIds, id])
    },
    () => null
  )
}

export const viewPdf = () => (event, { id }) => {
  window.open(`${envUrl('FILE_DOWNLOAD_URL')}/prescription/${id}.pdf`, id)
}

const withActions = withHandlers({
  handlePageSizeChange,
  handlePageChange,
  handleSort,
  handleStateChange,
  handleConfirm,
  handleCancel,
  viewPdf,
})

// LOADER

export const isLoading = (props) =>
  !props.data || !props.data.pharmacyPrescriptions

const withLoader = withSpinner(isLoading)

export default compose(
  withData,
  withStates,
  withActions,
  withErrorMessage(),
  withLoader
)(PrescriptionsView)
