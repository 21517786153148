import { useQuery } from '@apollo/client'
import logException from './logException'
import { Messages } from '../localization'
import { GetAppointmentCliniciansQuery } from './GetAppointmentCliniciansQuery'

interface UseConsultantListDataArgs {
  consultantType?: string
  consultationType?: string
  memberId?: string | number // ID
  serviceTypeUuid?: string | number // ID
}

const updateErrorMessage = (error: any, data: { error?: any }) => {
  if (!error && !!data?.error) {
    logException(data.error)

    return Messages.defaultErrorMessage
  }

  return error
}

export default function useConsultantListData({
  consultantType,
  consultationType,
  memberId,
  serviceTypeUuid,
}: UseConsultantListDataArgs) {
  const { data, loading, error } = useQuery(GetAppointmentCliniciansQuery, {
    variables: {
      consultantType,
      consultationType,
      memberId,
      serviceTypeUuid,
    },
  })

  const clinicians = data?.appointmentClinicians

  return {
    consultantLoading: loading,
    consultantList: Array.isArray(clinicians) ? clinicians : [],
    error: updateErrorMessage(error, data),
  }
}
