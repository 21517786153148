import { defineMessages } from 'react-intl'

const baseId = 'covid_19_triage'

export default defineMessages({
  covidHeader: {
    id: `${baseId}.covidHeader`,
    defaultMessage: 'COVID-19 History',
  },
  outcomeHeader: {
    id: `${baseId}.outcomeHeader`,
    defaultMessage: 'Outcome',
  },
  empty: {
    id: `${baseId}.empty`,
    defaultMessage: 'No results',
  },
})
