import { defineMessages } from 'react-intl'

const baseId = 'transactions'

export default defineMessages({
  transactions: {
    id: `${baseId}.transactions`,
    defaultMessage: 'Transactions',
  },
  type: {
    id: `${baseId}.type`,
    defaultMessage: 'Type',
  },
  card: {
    id: `${baseId}.card`,
    defaultMessage: 'Card',
  },
  price: {
    id: `${baseId}.price`,
    defaultMessage: 'Price',
  },
  created: {
    id: `${baseId}.created`,
    defaultMessage: 'Created',
  },
  refund: {
    id: `${baseId}.refund`,
    defaultMessage: 'Refund',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  refundTransaction: {
    id: `${baseId}.refundTransaction`,
    defaultMessage: 'Refund a Transaction',
  },
  selectRefundReason: {
    id: `${baseId}.selectRefundReason`,
    defaultMessage: 'Select reason for refund',
  },
  transaction_error: {
    id: `${baseId}.transaction_error`,
    defaultMessage:
      "The refund wasn't processed. You will need to do this manually.",
  },
  transaction_success: {
    id: `${baseId}.transaction_success`,
    defaultMessage: 'Success! The refund is being processed.',
  },
})
