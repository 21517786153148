import { defineMessages } from 'react-intl'

const baseId = 'alert_delete_confirmation_modal'

export default defineMessages({
  confirm_remove_heading: {
    id: `${baseId}.confirm_remove_heading`,
    defaultMessage: 'Remove patient alert',
  },
  confirm_remove_body: {
    id: `${baseId}.confirm_remove_body`,
    defaultMessage: 'This action is permanent and cannot be undone.',
  },
  confirm_remove_yes: {
    id: `${baseId}.confirm_remove_yes`,
    defaultMessage: 'Yes, remove alert',
  },
  confirm_remove_no: {
    id: `${baseId}.confirm_remove_no`,
    defaultMessage: 'No, keep alert',
  },
})
