import React, { createElement } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import ConditionalWrapper from '../ConditionalWrapper'
import styles from './styles.module.scss'

export interface Props {
  firstName: string
  lastName: string
  id?: string
  enableProfileLink?: boolean
  tagName?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  dataTestId?: string
}

const PatientName = ({
  firstName,
  lastName,
  id,
  enableProfileLink = true,
  onClick,
  tagName = 'div',
  dataTestId,
}: Props) => {
  const { search: queryString } = useLocation()

  return (
    <ConditionalWrapper
      condition={enableProfileLink && id}
      wrapper={(children) => (
        <Link
          to={`/admin/patients/${id}/memberships${queryString}`}
          onClick={onClick}
          data-testid={dataTestId}
        >
          {children}
        </Link>
      )}
    >
      {createElement(
        tagName,
        {
          className: styles.patientName,
        },
        `${lastName?.toUpperCase()}, ${firstName}`
      )}
    </ConditionalWrapper>
  )
}
export default PatientName
