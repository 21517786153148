/* eslint-disable camelcase */
import gql from 'graphql-tag'

export const PATIENT_IDENTIFIERS_QUERY = gql`
  query PatientIdentifiers($id: ID!) {
    patient(id: $id) {
      id
      uuid
      account_owner_id
      accounts_count
      account_owner {
        first_name
        last_name
      }
      family_accounts {
        id
      }
      family_accounts_owner
      identity_check {
        id
        status
        check_id
      }
      minor
      demographics {
        lookup_retry_attempts_left
        status
      }
      healthcare_identifier {
        public_identifier
        private_identifier
        unconfirmed_public_identifier
      }
    }
  }
`

export type PatientIdentifiersQueryResponse = {
  patient: {
    id: string
    uuid: string
    account_owner_id: string
    accounts_count: number
    account_owner: {
      first_name: string
      last_name: string
    }[]
    family_accounts: {
      id: string
    }[]
    family_accounts_owner: boolean
    identity_check: {
      id: string
      status: string
      check_id: string
    }
    minor: boolean
    demographics?: {
      lookup_retry_attempts_left: string
      status: string
    }
    healthcare_identifier: {
      public_identifier: string
      private_identifier: string
      unconfirmed_public_identifier: string
    }
  }
}

export const PATIENT_QUERY = gql`
  query Patient($id: ID!) {
    patient(id: $id) {
      ... on Patient {
        name {
          family
          given
        }
        id
        gender
        birthDate
        addresses(first: 10) {
          nodes {
            line
            city
            state
            postalCode
            country
          }
        }
        telecoms(first: 1) {
          nodes {
            ... on PhoneNumberContactPoint {
              phoneNumber
            }
            ... on EmailAddressContactPoint {
              emailAddress
            }
          }
        }
        identifiers(first: 10) {
          nodes {
            system
            value
          }
        }
      }
    }
  }
`

export type PhoneNumberNode = { phoneNumber: string }
export type EmailAddressNode = { emailAddress: string }
export type PatientQueryResponse = {
  patient: {
    addresses: {
      nodes: {
        city: string
        country: string
        postalCode: string
        state: string
      }[]
    }
    birthDate: string
    gender: string
    id: string
    identifiers: {
      nodes: {
        system: string
        value: string
      }[]
    }
    name: {
      family: string
      given: string[]
    }
    telecoms: {
      nodes: (PhoneNumberNode | EmailAddressNode)[]
    }
  }
}

export const PORTAL_LOGIN_PROFILE_QUERY = gql`
  query PortalLoginProfile($id: ID!) {
    portalUser(id: $id) {
      id
      uuid
      fullName
    }
  }
`

export const CLINICIAN_PROFILE_QUERY = gql`
  query ClinicianProfile {
    viewer {
      consultant {
        name
      }
    }
  }
`

export type PortalLoginProfileQueryResponse = {
  portalUser: {
    id: string
    uuid: string
    fullName: string
  }
}

export type ClinicianProfileQueryResponse = {
  viewer: {
    consultant: {
      name: string
    }
  }
}

export const CONSUMER_NETWORKS_QUERY = gql`
  query ConsumerNetworks($id: ID!) {
    patientConsumerNetworks(id: $id) {
      active
      is_preferred
      is_default
      consumer_network {
        name
      }
    }
  }
`

export type ConsumerNetworksQueryResponse = {
  patientConsumerNetworks: {
    active: boolean
    is_preferred: boolean
    is_default: boolean
    consumer_network: {
      name: string
    }
  }[]
}
