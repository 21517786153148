let config = {}

let appLocaleOverride = null
let appModeOverride = null
let appNameOverride = null
let trackingEnabledOverride = null

if (process.env?.REACT_APP_APP_ID === 'rwanda_web') {
  config = {
    APP_ID: process.env.REACT_APP_APP_ID,
    CORE_URL: process.env.REACT_APP_CORE_URL,
    MICROSERVICES_URL: process.env.REACT_APP_MICROSERVICES_URL,
    BASE_CHATSCRIPT_URL_PREFIX:
      process.env.REACT_APP_BASE_CHATSCRIPT_URL_PREFIX,
  }
} else if (typeof window !== 'object') {
  config = process.env
} else {
  config = window.babylonConfig || {}
}

// Query String Overrides
if (typeof window === 'object') {
  const appLocaleQueryString = window.location.search.match(
    /app_locale=([a-zA-Z_0-9-]+)/
  )
  appLocaleOverride = appLocaleQueryString ? appLocaleQueryString[1] : null

  const appModeQueryString = window.location.search.match(
    /app_mode=([a-zA-Z_0-9-]+)/
  )
  appModeOverride = appModeQueryString ? appModeQueryString[1] : null

  const appNameQueryString = window.location.search.match(
    /app_id=([a-zA-Z_0-9-]+)/
  )
  appNameOverride = appNameQueryString ? appNameQueryString[1] : null

  const trackingEnabledQueryString = window.location.search.match(
    /tracking_enabled=([a-zA-Z_0-9-]+)/
  )
  trackingEnabledOverride = trackingEnabledQueryString
    ? trackingEnabledQueryString[1].toLowerCase() === 'true'
    : null
}

// Overrides from web-app
if (window.initialState) {
  config.appName = window.initialState.appName
  config.appLocale = window.initialState.localisation?.language
}

const environment = process.env.REACT_APP_ENV || 'dev'
const DEFAULT_APP_LOCALE = 'en-GB'
const DEFAULT_APP_MODE = 'SELF'
const DEFAULT_APP_NAME = 'babylon'
const DEFAULT_CORE_URL = `https://app-uk.${environment}.babylontech.co.uk`
const DEFAULT_GOOGLE_API_KEY = 'AIzaSyDO3jkWIoagfJ9LqKsBAA7iaNfRWxUx9jA'
const DEFAULT_MICROSERVICES_URL = `https://services-uk.${environment}.babylontech.co.uk`
const DEFAULT_TRACKING_ENABLED = true
const DEFAULT_VERSION = ''
const DEFAULT_BASE_CHATSCRIPT_URL_PREFIX = 'chatscript'

const finalConfig = {
  APP_LOCALE:
    appLocaleOverride?.replace('_', '-') ||
    config.appLocale ||
    DEFAULT_APP_LOCALE,
  APP_MODE: appModeOverride || DEFAULT_APP_MODE,
  APP_NAME:
    appNameOverride || config.APP_ID || config.appName || DEFAULT_APP_NAME,
  CORE_URL: config.CORE_URL || DEFAULT_CORE_URL,
  GOOGLE_API_KEY: config.GOOGLE_API_KEY || DEFAULT_GOOGLE_API_KEY,
  GOOGLE_TRACKING_ID: config.GOOGLE_TRACKING_ID,
  MICROSERVICES_URL: config.MICROSERVICES_URL || DEFAULT_MICROSERVICES_URL,
  SENTRY_ENVIRONMENT: config.SENTRY_ENVIRONMENT,
  SENTRY_URL: config.SENTRY_URL || config.SENTRY_DSN,
  TRACKING_ENABLED:
    config.MICROSERVICES_URL ||
    (trackingEnabledOverride ?? DEFAULT_TRACKING_ENABLED),
  VERSION: process.env.REACT_APP_VERSION || DEFAULT_VERSION,
  BASE_CHATSCRIPT_URL_PREFIX:
    config.BASE_CHATSCRIPT_URL_PREFIX || DEFAULT_BASE_CHATSCRIPT_URL_PREFIX,
}

const {
  APP_LOCALE,
  APP_MODE,
  APP_NAME,
  CORE_URL,
  GOOGLE_API_KEY,
  GOOGLE_TRACKING_ID,
  MICROSERVICES_URL,
  SENTRY_ENVIRONMENT,
  SENTRY_URL,
  TRACKING_ENABLED,
  VERSION,
  BASE_CHATSCRIPT_URL_PREFIX,
} = finalConfig

export default finalConfig
export {
  APP_LOCALE,
  APP_MODE,
  APP_NAME,
  CORE_URL,
  GOOGLE_API_KEY,
  GOOGLE_TRACKING_ID,
  MICROSERVICES_URL,
  SENTRY_ENVIRONMENT,
  SENTRY_URL,
  TRACKING_ENABLED,
  VERSION,
  BASE_CHATSCRIPT_URL_PREFIX,
}
