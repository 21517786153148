import React, { FC } from 'react'
import { Modal } from '@babylon/core-ui'
import { ButtonSimple, ButtonVariant, Text, Textarea } from '@babylon/medkit'
import { useMutation } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import { Formik } from 'formik'
import messages from './AlertAddModal.messages'
import styles from './AlertAddModal.module.scss'
import { addAlertGQL } from '../AlertList/queries'

export interface AlertAddOverlayProps {
  patientId: String
  onCancel: () => void
  onSave: () => void
  visible?: boolean
}

const AlertAddModal: FC<AlertAddOverlayProps> = ({
  visible = false,
  patientId,
  onCancel,
  onSave: onCompleted,
}) => {
  const fm = useFormatMessage()
  const [addAlert] = useMutation(addAlertGQL, { onCompleted })

  if (!visible) {
    return null
  }

  return (
    <Modal
      label={fm(messages.alerts)}
      open={visible}
      onClose={onCancel}
      includeClose={false}
    >
      <div data-testid="member-alerts-add-alert-modal">
        <Text variant="h4">{fm(messages.add_alert)}</Text>
        <Formik
          initialValues={{ description: '' }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true)
            addAlert({
              variables: { patientId, description: values.description },
            })
            actions.setSubmitting(false)
          }}
        >
          {({ values, handleChange, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.Alert__AddNew__description}>
                <Textarea
                  id="add-alert-description"
                  name="description"
                  onChange={handleChange}
                  data-testid="member-alerts-add-alert-description"
                  value={values.description}
                />
              </div>
              <div className={styles.Alert__AddNew__actions}>
                <ButtonSimple
                  variant={ButtonVariant.secondary}
                  fullWidth={false}
                  onClick={onCancel}
                  data-testid="member-alerts-cancel-button"
                >
                  {fm(messages.cancel)}
                </ButtonSimple>

                <ButtonSimple
                  type="submit"
                  variant={ButtonVariant.primary}
                  fullWidth={false}
                  disabled={isSubmitting || !values.description.length}
                  className={styles.Alert__AddNew__save_button}
                  data-testid="member-alerts-add-alert-button"
                >
                  {fm(messages.save_alert)}
                </ButtonSimple>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default AlertAddModal
