import { defineMessages } from 'react-intl'

const baseId = 'consultations_for_gps.consultation_patient_list_item'

export default defineMessages({
  patient_info_title: {
    id: `${baseId}.patient_info_title`,
    defaultMessage: 'Patient',
  },
  consultation_info_title: {
    id: `${baseId}.consultation_info_title`,
    defaultMessage: 'Consultation',
  },
  consumer_network_group_title: {
    id: `${baseId}.consumer_network_group_title`,
    defaultMessage: 'Consumer Network',
  },
  gp_practice_group_title: {
    id: `${baseId}.gp_practice_group_title`,
    defaultMessage: 'GP Practice',
  },
  status_group_title: {
    id: `${baseId}.status_group_title`,
    defaultMessage: 'Status',
  },
  fax_number_title: {
    id: `${baseId}.fax_number_title`,
    defaultMessage: 'Fax Number',
  },
  actions_title: {
    id: `${baseId}.actions_title`,
    defaultMessage: 'Actions',
  },
  send_and_complete_button_label: {
    id: `${baseId}.send_and_complete_button_label`,
    defaultMessage: 'Send and complete',
  },
  sent_to_gp_select_name: {
    id: `${baseId}.sent_to_gp_select_name`,
    defaultMessage: 'Sent to GP',
  },
  sent_to_gp_select_marked_as_sent_label: {
    id: `${baseId}.sent_to_gp_select_marked_as_sent_label`,
    defaultMessage: 'Marked as sent',
  },
  sent_to_gp_select_marked_as_sent_label_v2: {
    id: `${baseId}.sent_to_gp_select_marked_as_sent_label_v2`,
    defaultMessage: 'Sent',
  },
  sent_to_gp_select_unmark_label: {
    id: `${baseId}.sent_to_gp_select_unmark_label`,
    defaultMessage: 'Unmark',
  },
  sent_to_gp_select_unmark_label_v2: {
    id: `${baseId}.sent_to_gp_select_unmark_label_v2`,
    defaultMessage: 'Not Sent',
  },
  download_button_label: {
    id: `${baseId}.download_button_label`,
    defaultMessage: 'Download',
  },
  mark_as_sent_button_label: {
    id: `${baseId}.mark_as_sent_button_label`,
    defaultMessage: 'Mark as sent',
  },
  mark_as_unsent_button_label: {
    id: `${baseId}.mark_as_unsent_button_label`,
    defaultMessage: 'Mark as unsent',
  },
  send_fax_button_label: {
    id: `${baseId}.send_fax_button_label`,
    defaultMessage: 'Send fax',
  },
  mark_as_sent_confirmation: {
    id: `${baseId}.mark_as_sent_confirmation`,
    defaultMessage: 'Are you sure you wish to update mark as sent status?',
  },
  user_id: {
    id: `${baseId}.user_id`,
    defaultMessage: 'ID',
  },
  healthcare_id: {
    id: `${baseId}.healthcare_id`,
    defaultMessage: 'Healthcare ID',
  },
  search_fax_placeholder: {
    id: `${baseId}.search_fax_placeholder`,
    defaultMessage: 'Search...',
  },
  error_loading_patient: {
    id: `${baseId}.error_loading_patient`,
    defaultMessage: "We couldn't load this patient's information",
  },
})
