import * as Yup from 'yup'
import { OTHER_SERVICE_TYPE } from './utils'

export const arrangeAppointmentValidation = Yup.object().shape({
  member_uuid: Yup.string().required(),
  service_type_uuid: Yup.string().required('Please select an appointment type'),
  service_type_name: Yup.string(),
})

export const recommendedAppointmentValidation = Yup.object().shape({
  notes_for_member: Yup.string()
    .trim()
    .required('Please set a reason for the appointment'),
  chaperone_required: Yup.boolean(),
  duration_minutes: Yup.number().required('Please select the duration'),
  intimate: Yup.boolean(),
  service_type_uuid: Yup.string().required('Please select an appointment type'),
  allowed_mediums: Yup.array(Yup.string()).min(
    1,
    'Please select at least one medium'
  ),
  preferred_medium: Yup.string(),
  preferred_profession: Yup.string().when(
    'service_type_uuid',
    (serviceTypeUuid, schema) => {
      if (serviceTypeUuid === OTHER_SERVICE_TYPE.uuid) {
        return schema.required('Please select a default clinician')
      }

      return schema
    }
  ),
  allowed_professions: Yup.array(Yup.string()).when(
    'service_type_uuid',
    (serviceTypeUuid, schema) => {
      if (serviceTypeUuid === OTHER_SERVICE_TYPE.uuid) {
        return schema.min(1, 'Please select at least one clinician')
      }

      return schema
    }
  ),
})
