import { defineMessages } from 'react-intl'

const baseId = 'top_bar'

export default defineMessages({
  account_settings: {
    id: `${baseId}.account_settings`,
    defaultMessage: 'Account settings',
  },
  log_out: {
    id: `${baseId}.log_out`,
    defaultMessage: 'Log out',
  },
  user_profile: {
    id: `${baseId}.user_profile`,
    defaultMessage: 'User profile',
  },
  patient_search: {
    id: `${baseId}.patient_search`,
    defaultMessage: 'Find a patient',
  },
  menu: {
    id: `${baseId}.menu`,
    defaultMessage: 'Menu',
  },
  language: {
    id: `${baseId}.language`,
    defaultMessage: 'Language',
  },
  language_english_uk: {
    id: `${baseId}.language_english_uk`,
    defaultMessage: 'English (UK)',
  },
  language_english_ca: {
    id: `${baseId}.language_english_ca`,
    defaultMessage: 'English (CA)',
  },
  language_english_us: {
    id: `${baseId}.language_english_us`,
    defaultMessage: 'English (US)',
  },
})
