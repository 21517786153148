import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useApolloClient, useQuery } from '@apollo/client'
import { BabylonUserProvider, BabylonUserRender } from '@babylon/babylon-user'
import { spinnerLoaderInstance } from '@babylon/core-ui'
import * as Access from '@babylon/web-platform-access'
import { PageErrorMessage } from '@babylon/cce-shared-components'
import { envVar } from '@babylon/babylon-env'
import { setLogRocketUser } from '@babylon/tracking/logrocket'
import { keepAliveAuthSession } from '@babylon/web-platform-utils-auth-session'
import AppContent from '../AppContent'

import styles from './styles.module.css'
import { OpenIDAuthProvider } from './OpenIDAuthProvider'
import { CoreRubyAuthProvider } from './CoreRubyAuthProvider'

export * from './consts'

const ENABLE_NEW_AUTH = envVar('ENABLE_NEW_AUTH') === 'true'

const ENABLE_WEB_PLATFORM_ACCESS_AUTHZ_IMPLEMENTATION =
  envVar('ENABLE_WEB_PLATFORM_ACCESS_AUTHZ_IMPLEMENTATION') === 'true'

const AuthProvider = ENABLE_NEW_AUTH ? OpenIDAuthProvider : CoreRubyAuthProvider

const AppFrame = () => {
  const apolloClient = useApolloClient()

  return (
    <div className={styles.app}>
      <Router>
        <AuthProvider>
          <BabylonUserProvider useQuery={useQuery}>
            {({ loading, error, user }: BabylonUserRender) => {
              if (loading) {
                return spinnerLoaderInstance
              }

              if (error || !user) {
                return <PageErrorMessage />
              }

              setLogRocketUser(user)
              // this needs to be called only after we are authenticated as would otherwise cause a failure in the redirect flow.
              if (ENABLE_NEW_AUTH) {
                keepAliveAuthSession(true)
              }

              return (
                <Access.AccessProvider
                  defaultAction="view"
                  resourcePrefix="rn:babylon:app:portal:"
                  roles={user?.roles.map(({ name }) => name) as string[]}
                  apolloClient={apolloClient}
                  useLegacyContext={
                    !ENABLE_WEB_PLATFORM_ACCESS_AUTHZ_IMPLEMENTATION
                  }
                >
                  <AppContent />
                </Access.AccessProvider>
              )
            }}
          </BabylonUserProvider>
        </AuthProvider>
      </Router>
    </div>
  )
}

export default AppFrame
