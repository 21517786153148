import { gql } from '@apollo/client'

export const PrescriptionRegions = gql`
  query PrescriptionRegions {
    prescriptionRegions {
      regionCode
      name
    }
  }
`
export const AdminActiveIngredient = gql`
  query AdminActiveIngredient($query: String!, $limit: Int) {
    adminActiveIngredient(query: $query, limit: $limit) {
      activeIngredientId
      name
      iri
    }
  }
`

export const adminDrugAlertFieldsFragment = gql`
  fragment AdminDrugAlertFieldsFragment on AdminDrugAlert {
    id
    activeIngredient {
      activeIngredientId
      name
    }
    regions
    prescriptionLimit
    timePeriod
    allowPrescribe
    createdAt
  }
`

export const AdminDrugAlert = gql`
  query AdminDrugAlert($id: ID!) {
    adminDrugAlert(id: $id) {
      ...AdminDrugAlertFieldsFragment
    }
  }
  ${adminDrugAlertFieldsFragment}
`

export const AddAdminDrugAlert = gql`
  mutation AddAdminDrugAlert($adminDrugAlertInput: AdminDrugAlertInput!) {
    addAdminDrugAlert(adminDrugAlert: $adminDrugAlertInput) {
      ...AdminDrugAlertFieldsFragment
    }
  }
  ${adminDrugAlertFieldsFragment}
`

export const UpdateAdminDrugAlert = gql`
  mutation UpdateAdminDrugAlert($adminDrugAlertInput: AdminDrugAlertInput!) {
    updateAdminDrugAlert(adminDrugAlert: $adminDrugAlertInput) {
      ...AdminDrugAlertFieldsFragment
    }
  }
  ${adminDrugAlertFieldsFragment}
`
