import React, { useCallback, useState } from 'react'
import { useLocation, useParams, useHistory, Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Page } from '@babylon/core-ui'
import {
  AppointmentFields,
  useConsultantTypeDurations,
  UnexpectedError,
} from '..'

import AppointmentInviteQuery from './AppointmentInviteQuery'

import { LoadingContainer } from '../components'
import BookAppointmentView from './BookAppointmentView'

interface MapInviteToFiltersArgs
  extends Pick<
    AppointmentFields,
    | 'consultation_type'
    | 'duration_minutes'
    | 'service_type_uuid'
    | 'allowed_mediums'
    | 'allowed_professions'
    | 'consultant_type'
  > {
  notes_for_member: AppointmentFields['appointment_reason']
  preferred_medium: AppointmentFields['consultation_type']
}

export interface Filter
  extends Pick<
    AppointmentFields,
    | 'consultation_type'
    | 'duration_minutes'
    | 'service_type_uuid'
    | 'appointment_reason'
    | 'allowed_mediums'
    | 'allowed_professions'
    | 'consultant_type'
  > {}

const mapInviteToFilters = ({
  consultation_type,
  consultant_type,
  duration_minutes,
  notes_for_member,
  service_type_uuid,
  allowed_mediums,
  allowed_professions,
  preferred_medium,
}: MapInviteToFiltersArgs): Filter => ({
  allowed_mediums,
  allowed_professions,
  appointment_reason: notes_for_member,
  consultation_type: preferred_medium || consultation_type,
  duration_minutes,
  service_type_uuid,
  consultant_type,
})

export default function SetAppointment() {
  const history = useHistory()
  const { id: memberId } = useParams<{ id: string }>()
  const { state, search } = useLocation()
  const [filters, setFilters] = useState<AppointmentFields>(state?.filters)
  const [disableEdit, updateDisableEdit] = useState<boolean>(false)
  const { consultantTypesDuration, error } = useConsultantTypeDurations()
  const params = new URLSearchParams(search)
  const inviteId = params.get('invite')

  const setFiltersFromInvite = useCallback(
    (data) => {
      const professionById = data.professions.reduce((acc, profession) => {
        acc[profession.id] = profession

        return acc
      }, {})

      const inviteData = {
        ...data.appointmentInvite,
        allowed_professions: data.appointmentInvite.allowed_professions?.map(
          (professionId) => professionById[professionId]?.key.toUpperCase()
        ),
        consultant_type: professionById[
          data.appointmentInvite.preferred_profession
        ]?.key.toUpperCase(),
      }

      setFilters(mapInviteToFilters(inviteData))
      updateDisableEdit(true)
    },
    [setFilters]
  )

  const returnToEdit = () => {
    history.push(`/admin/patients/${memberId}/book-appointment`, {
      originalFilters: state?.originalFilters,
      enrichedWithServiceType: state?.enrichedWithServiceType,
      filters,
      selectedMedium: state?.selectedMedium,
    })
  }

  const { loading } = useQuery(AppointmentInviteQuery, {
    variables: {
      inviteId,
    },
    skip: !inviteId,
    fetchPolicy: 'no-cache',
    onCompleted: setFiltersFromInvite,
  })

  return (
    <Page
      breadcrumbs={[
        <Link to="/admin/patients/search">Patients</Link>,
        <Link to={`/admin/patients/${memberId}/memberships`}>
          Member details
        </Link>,
        'Schedule Appointment',
      ]}
      title="Schedule Appointment"
    >
      {error && <UnexpectedError visible message={error.message} />}
      <LoadingContainer loading={loading}>
        {!loading && (!inviteId || !!filters) && (
          <div data-testid="appointment-availability">
            <BookAppointmentView
              filters={filters}
              inviteId={inviteId}
              memberId={memberId}
              onEdit={disableEdit ? undefined : returnToEdit}
              slotDurationList={consultantTypesDuration}
            />
          </div>
        )}
      </LoadingContainer>
    </Page>
  )
}
