/* eslint-disable camelcase */
import { useQuery } from '@apollo/client'
import {
  IdentityVerificationStatus,
  PDSStatuses,
} from '@babylon/member-operations'
import {
  EmailAddressNode,
  PatientQueryResponse,
  PatientIdentifiersQueryResponse,
  PATIENT_QUERY,
  PATIENT_IDENTIFIERS_QUERY,
  PhoneNumberNode,
  CONSUMER_NETWORKS_QUERY,
  ConsumerNetworksQueryResponse,
} from './queries'

export type PatientProfile = {
  id: string
  birthDate?: string
  gender?: string
  summarizedName: string
  givenNames: string[]
  familyName: string
  phoneNumber?: string
  emailAddress?: string
  babylonId?: string
  hasFamilyAccounts: boolean
  hasFamilyAccountsOwner: boolean
  isMinor: boolean
  pdsStatus?: PDSStatuses
  identityCheck?: IdentityVerificationStatus
  identifiers: {
    public?: string
    private?: string
    unconfirmedPublic?: string
  }
  preferredConsumerNetwork?: string
}

const patientQueryToProfile = (
  patientQuery: PatientQueryResponse,
  patientIdentifiersQuery: PatientIdentifiersQueryResponse,
  consumerNetworksQuery: ConsumerNetworksQueryResponse,
  patientId?: string
): PatientProfile => {
  const { patient } = patientQuery
  const { telecoms, identifiers, name } = patient

  const phoneNumberNode = telecoms?.nodes.find(
    (n) => (n as PhoneNumberNode).phoneNumber
  ) as PhoneNumberNode | undefined
  const phoneNumber = phoneNumberNode?.phoneNumber

  const emailAddressNode = telecoms?.nodes.find(
    (n) => (n as EmailAddressNode).emailAddress
  ) as EmailAddressNode | undefined
  const emailAddress = emailAddressNode?.emailAddress

  const babylonId = identifiers?.nodes.find(
    (e) => e.system === 'https://coreruby.bbl.health/Patient'
  )?.value
  const familyName = name ? name.family : ''
  const givenNames = name ? name.given : []
  const summarizedName = `${familyName.toUpperCase()}, ${givenNames.join(' ')}`

  const healthcareIds = patientIdentifiersQuery.patient.healthcare_identifier
  const {
    family_accounts,
    family_accounts_owner,
    account_owner_id,
    accounts_count,
    identity_check,
    minor,
    demographics,
  } = patientIdentifiersQuery.patient

  const hasFamilyAccounts = !!(
    (family_accounts && family_accounts.length) ||
    account_owner_id ||
    (accounts_count && accounts_count > 0)
  )

  const pdsStatus = demographics?.status as PDSStatuses | undefined

  const preferredConsumerNetwork = consumerNetworksQuery?.patientConsumerNetworks.find(
    (cn) => cn.active && cn.is_preferred
  )?.consumer_network.name

  return {
    id: patient.id || patientId || '',
    birthDate: patient.birthDate,
    gender: patient.gender,
    summarizedName,
    givenNames,
    familyName,
    phoneNumber,
    emailAddress,
    babylonId,
    hasFamilyAccounts,
    identityCheck: identity_check?.status as
      | IdentityVerificationStatus
      | undefined,
    hasFamilyAccountsOwner: family_accounts_owner,
    isMinor: minor,
    pdsStatus,
    identifiers: {
      private: healthcareIds?.private_identifier,
      public: healthcareIds?.public_identifier,
      unconfirmedPublic: healthcareIds?.unconfirmed_public_identifier,
    },
    preferredConsumerNetwork,
  }
}

const usePatientProfile = (patientId: string | undefined) => {
  const {
    loading: patientLoading,
    data: patientData,
    error: patientError,
    refetch: patientRefetch,
  } = useQuery<PatientQueryResponse>(PATIENT_QUERY, {
    skip: !patientId,
    variables: { id: patientId },
    context: { clientName: 'platform-gateway' },
    onError: (err) => {
      if (err.networkError) {
        console.info(`Will retry. Encountered error fetching user data: ${err}`)
        patientRefetch()
      } else {
        // err.graphQLErrors
        console.info(
          `Will not retry. Encountered error fetching user data: ${err}`
        )
      }
    },
  })

  /**
   * These identifiers are not ready yet under the new unified federated graph
   * So we have to retrieve them from graphql-middleware 😢
   */
  const {
    loading: identifiersLoading,
    data: identifiersData,
    error: identifiersError,
  } = useQuery<PatientIdentifiersQueryResponse>(PATIENT_IDENTIFIERS_QUERY, {
    skip: !patientId,
    variables: { id: patientId },
  })

  const {
    loading: consumerNetworksLoading,
    data: consumerNetworksData,
    error: consumerNetworksError,
  } = useQuery<ConsumerNetworksQueryResponse>(CONSUMER_NETWORKS_QUERY, {
    skip: !patientId,
    variables: { id: patientId },
  })

  return {
    loading: patientLoading || identifiersLoading || consumerNetworksLoading,
    error: patientError || identifiersError || consumerNetworksError,
    patientProfile:
      patientData && identifiersData && consumerNetworksData
        ? patientQueryToProfile(
            patientData,
            identifiersData,
            consumerNetworksData,
            patientId
          )
        : undefined,
  }
}

export default usePatientProfile
