import React, { FC } from 'react'
import { Grid, Cell } from '@babylon/core-ui'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { useFormatMessage } from '@babylon/intl'
import {
  MemberOpsModuleName,
  Address,
  Email,
  GenderAge,
  IDs,
  PhoneNumber,
} from '../../../../..'

import { OnDetailsCopy } from '../../../types'

import messages from './Details.messages'

export interface DetailsProps extends Member {
  onDetailsCopy: OnDetailsCopy
}

const Details: FC<DetailsProps> = ({
  address,
  coreRubyPatientId,
  dateOfBirth,
  email,
  gender,
  healthcareIdentifier,
  onDetailsCopy,
  phoneNumber,
  uuid,
}) => {
  const fm = useFormatMessage()

  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.membersSearchView,
  })
  const { line1, line2, line3, city, postalCode, state } = address || {}
  const privateId = healthcareIdentifier?.privateIdentifier || ''
  const publicId = healthcareIdentifier?.publicIdentifier || ''
  const unconfirmedPublicId =
    healthcareIdentifier?.unconfirmedPublicIdentifier || ''

  const copyToClipboardHandler = (message: string) => () => {
    onDetailsCopy(message)
    trackClick({
      elementName: 'copy-to-clipboard-btn',
      elementType: TrackingElementType.button,
    })
  }

  const copyIdToClipboardHandler = (message: string) => {
    onDetailsCopy(message)
    trackClick({
      elementName: 'copy-id-to-clipboard-btn',
      elementType: TrackingElementType.button,
    })
  }

  return (
    <Grid gap={16} columns={12}>
      <Cell width={2}>
        <GenderAge
          dateOfBirth={dateOfBirth}
          gender={gender?.id || 'Unknown'}
          onDateOfBirthCopy={copyToClipboardHandler(fm(messages.dob_copied))}
        />
      </Cell>
      <Cell width={2}>
        <Address
          line1={line1}
          line2={line2}
          line3={line3}
          city={city}
          state={state}
          postcode={postalCode}
          onAddressCopy={copyToClipboardHandler(fm(messages.address_copied))}
        />
      </Cell>
      <Cell width={3}>
        <div>
          <PhoneNumber
            phoneNumber={phoneNumber}
            onPhoneNumberCopy={copyToClipboardHandler(
              fm(messages.phone_copied)
            )}
          />
        </div>
        <div>
          <Email
            email={email}
            onEmailCopy={copyToClipboardHandler(fm(messages.email_copied))}
          />
        </div>
      </Cell>
      <Cell width={2}>
        <IDs
          babylonId={coreRubyPatientId}
          privateId={privateId}
          publicId={publicId}
          babylonUserUuid={uuid}
          unconfirmedPublicId={unconfirmedPublicId}
          onIDCopy={copyIdToClipboardHandler}
        />
      </Cell>
    </Grid>
  )
}

export default Details
