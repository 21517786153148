import React from 'react'
import { Pills, Fieldset } from '@babylon/medkit'
import ConsultationTypes, {
  ConsultationTypeName,
} from '../../types/ConsultationTypes'

interface MediumSelectionProps {
  options?: ConsultationTypeName[]
  selectedMediums: ConsultationTypeName[]
  onChange: (allowedMediums: ConsultationTypeName[]) => void
}

export default function MediumSelection({
  options,
  selectedMediums,
  onChange,
}: MediumSelectionProps) {
  if (!options) {
    return null
  }

  return (
    <Fieldset legend="Medium" role="group">
      <Pills
        isControlledComponent
        multiSelect
        data-testid="medium-selection"
        name="mediums"
        options={options?.map((medium) => ({
          label: ConsultationTypes[medium],
          selected: selectedMediums.includes(medium),
          value: medium,
        }))}
        onChange={(mediums) => onChange(mediums as ConsultationTypeName[])}
      />
    </Fieldset>
  )
}
