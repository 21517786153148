import * as Yup from 'yup'

const professionValidation = Yup.object().shape({
  name: Yup.string().trim().required('Please type the name of the profession'),
  alternative_slot_durations_minutes: Yup.array().of(Yup.number()),
  default_slot_duration_minutes: Yup.number().when(
    'alternative_slot_durations_minutes',
    (
      alternative_slot_durations_minutes: Array<string>,
      schema: Yup.ArraySchema<any>
    ) =>
      !alternative_slot_durations_minutes.length
        ? schema.required(
            'Please choose optional times to be able to select a default time'
          )
        : schema.required('Please choose the default duration time')
  ),
  medical_identifier_label: Yup.string().nullable(),
  default_appointment_price_cents: Yup.number().min(0),
})

export default professionValidation
