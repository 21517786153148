import { defineMessages } from 'react-intl'

const baseId = 'assessment_data_points_filter'

export default defineMessages({
  yesAnswersFilter: {
    id: `${baseId}.yes_answers_filter`,
    defaultMessage: 'Highlight Yes answers',
  },
  notApplicableAnswersFilter: {
    id: `${baseId}.not_applicable_answers_filter`,
    defaultMessage: 'Hide not applicable answers',
  },
})
