import { defineMessages } from 'react-intl'

const baseId = 'repeat_template_card'

export default defineMessages({
  book_appointment: {
    id: `${baseId}.book_appointment`,
    defaultMessage: 'Book an appointment',
  },
  days_to_reorder: {
    id: `${baseId}.days_to_reorder`,
    defaultMessage:
      'Medication runs out {days, plural, one {in {days} day} other {in {days} days}}',
  },
  due_date: {
    id: `${baseId}.due_date`,
    defaultMessage: 'Due: {date}',
  },
  duration: {
    id: `${baseId}.duration`,
    defaultMessage: 'Duration of medication',
  },
  duration_days: {
    id: `${baseId}.duration_days`,
    defaultMessage: '{duration} days',
  },
  follow_up_needed_gp: {
    id: `${baseId}.follow_up_needed_gp`,
    defaultMessage: 'Follow up needed with GP',
  },
  follow_up_needed_pharmicist: {
    id: `${baseId}.follow_up_needed_pharmicist`,
    defaultMessage: 'Follow up needed with pharmicist',
  },
  full_follow_up_needed: {
    id: `${baseId}.full_follow_up_needed`,
    defaultMessage: 'Follow up needed',
  },
  full_ready_to_collect: {
    id: `${baseId}.full_ready_to_collect`,
    defaultMessage: 'Ready to collect',
  },
  full_reorder_soon: {
    id: `${baseId}.full_reorder_soon`,
    defaultMessage: 'Reorder soon',
  },
  full_reorder_now: {
    id: `${baseId}.full_reorder_now`,
    defaultMessage: 'Reorder now',
  },
  full_order_now: {
    id: `${baseId}.full_order_now`,
    defaultMessage: 'Available to reorder',
  },
  full_checking_reorder: {
    id: `${baseId}.full_checking_reorder`,
    defaultMessage: 'Checking reorder',
  },
  full_collected: {
    id: `${baseId}.full_collected`,
    defaultMessage: 'Collected',
  },
  full_stopped: {
    id: `${baseId}.full_stopped`,
    defaultMessage: 'Stopped',
  },
  mark_as_stopped_failure: {
    id: `${baseId}.mark_as_stopped_failure`,
    defaultMessage: 'Prescription state failed to update',
  },
  mark_as_stopped_success: {
    id: `${baseId}.mark_as_stopped_success`,
    defaultMessage: 'Prescription successfully marked as stopped',
  },
  mark_repeat_prescription_as_stopped: {
    id: `${baseId}.stop_repeat_prescription`,
    defaultMessage: 'Mark as stopped',
  },
  next_review: {
    id: `${baseId}.next_review`,
    defaultMessage: 'Next medication review',
  },
  notes: {
    id: `${baseId}.notes`,
    defaultMessage: 'Notes to patient',
  },
  notes_reorder_now: {
    id: `${baseId}.notes_reorder_now`,
    defaultMessage:
      'This is a repeat prescription. You have the option to reorder your medication on an ongoing basis.',
  },
  notes_reorder_soon: {
    id: `${baseId}.notes_reorder_soon`,
    defaultMessage:
      'This is a repeat prescription. You have the option to reorder your medication on an ongoing basis.',
  },
  notes_order_now: {
    id: `${baseId}.notes_order_now`,
    defaultMessage:
      'This has been set up as a repeat prescription. You have the option to reorder your medication on an ongoing basis.',
  },
  notes_checking_reorder: {
    id: `${baseId}.notes_checking_reorder`,
    defaultMessage:
      "Your GP or pharmacist is carrying out a routine check on your order. We'll let you know when it's ready to collect or out for delivery.",
  },
  notes_ready_to_collect: {
    id: `${baseId}.notes_ready_to_collect`,
    defaultMessage:
      'Your medication is ready to collect from your chosen pharmacy.',
  },
  notes_follow_up_needed_gp: {
    id: `${baseId}.notes_follow_up_needed_gp`,
    defaultMessage:
      'Your GP needs to check this treatment is still right for you. Please book an appointment with them to discuss.',
  },
  notes_follow_up_needed_pharmacist: {
    id: `${baseId}.notes_follow_up_needed_pharmacist`,
    defaultMessage:
      'The pharmacist needs to check this treatment is still right for you. Please book an appointment with them to discuss.',
  },
  notes_stopped: {
    id: `${baseId}.notes_stopped`,
    defaultMessage: 'This prescription has been stopped.',
  },
  show_details: {
    id: `${baseId}.show_details`,
    defaultMessage: 'Show details',
  },
  status: {
    id: `${baseId}.status`,
    defaultMessage: 'Prescription status',
  },
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Prescription (repeat)',
  },
  collected: {
    id: `${baseId}.collected`,
    defaultMessage: 'COLLECTED',
  },
  ready_to_collect: {
    id: `${baseId}.ready_to_collect`,
    defaultMessage: 'READY TO COLLECT',
  },
  reorder_soon: {
    id: `${baseId}.reorder_soon`,
    defaultMessage: 'REORDER SOON',
  },
  reorder_now: {
    id: `${baseId}.reorder_now`,
    defaultMessage: 'REORDER NOW',
  },
  checking_reorder: {
    id: `${baseId}.checking_reorder`,
    defaultMessage: 'CHECKING REORDER',
  },
  follow_up_needed: {
    id: `${baseId}.follow_up_needed`,
    defaultMessage: 'FOLLOW UP NEEDED',
  },
  stopped: {
    id: `${baseId}.stopped`,
    defaultMessage: 'STOPPED',
  },
})
