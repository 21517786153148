import React from 'react'
import { Chip } from '@mui/material'
import { useFormatMessage } from '@babylon/intl'
import { WaitTimeStatus } from '../../pages/AppointmentWaitTimes'
import messages, { baseStatusKey } from './AppointmentWaitTimeStatus.messages'
import type { AppointmentWaitTimeStatusProps } from './AppointmentWaitTimeStatus.types'

const getChipColor = (waitTimeStatus: WaitTimeStatus) => {
  switch (waitTimeStatus) {
    case WaitTimeStatus.Good:
      return 'success'
    case WaitTimeStatus.Busy:
      return 'warning'
    case WaitTimeStatus.Overloaded:
    case WaitTimeStatus.Critical:
      return 'error'
    default:
      return 'info'
  }
}

export const AppointmentWaitTimeStatus = ({
  status,
}: AppointmentWaitTimeStatusProps) => {
  const fm = useFormatMessage()
  const chipVariant = status === WaitTimeStatus.Critical ? 'filled' : 'outlined'
  const chipColor = getChipColor(status)
  const chipLabelKey = `${baseStatusKey}${status}`
  const chipLabel = fm(messages[chipLabelKey])

  return (
    <Chip
      variant={chipVariant}
      color={chipColor}
      label={chipLabel}
      size="small"
    />
  )
}

export default AppointmentWaitTimeStatus
