import { defineMessages } from 'react-intl'

const baseId = 'create_new_patient_form'

export default defineMessages({
  create_new_patient_form_text: {
    id: `${baseId}.create_new_patient_form_text`,
    defaultMessage:
      'The fields below are required to create a new patient. The new patient will automatically be linked to the one currently selected.',
  },
  create_new_patient_form_text_2: {
    id: `${baseId}.create_new_patient_form_text_2`,
    defaultMessage:
      'The new patient will receive an email invitation to join Babylon.',
  },
  firstname_text_input: {
    id: `${baseId}.firstname_text_input`,
    defaultMessage: 'First name',
  },
  lastname_text_input: {
    id: `${baseId}.lastname_text_input`,
    defaultMessage: 'Last name',
  },
  address_1_text_input: {
    id: `${baseId}.address_1_text_input`,
    defaultMessage: 'Address line 1',
  },
  address_2_text_input: {
    id: `${baseId}.address_2_text_input`,
    defaultMessage: 'Address line 2',
  },
  postal_code_text_input: {
    id: `${baseId}.postal_code_text_input`,
    defaultMessage: 'Postal code',
  },
  email_text_input: {
    id: `${baseId}.email_text_input`,
    defaultMessage: 'Email',
  },
  date_of_birth: {
    id: `${baseId}.date_of_birth`,
    defaultMessage: 'Date of birth',
  },
  city_text_input: {
    id: `${baseId}.city_text_input`,
    defaultMessage: 'City',
  },
  state_text_input: {
    id: `${baseId}.state_text_input`,
    defaultMessage: 'State',
  },
  phone_number_text_input: {
    id: `${baseId}.phone_number_text_input`,
    defaultMessage: 'Phone number',
  },
  create_guardian: {
    id: `${baseId}.create_guardian`,
    defaultMessage: 'Create a guardian profile',
  },
  create_dependent: {
    id: `${baseId}.create_dependent`,
    defaultMessage: 'Create a dependent profile',
  },
  back: {
    id: `${baseId}.back`,
    defaultMessage: 'Back',
  },
  cant_be_blank: {
    id: `${baseId}.cant_be_blank`,
    defaultMessage: "Can't be blank",
  },
  invalid: {
    id: `${baseId}.invalid`,
    defaultMessage: 'Is invalid',
  },
  invalid_phone_number: {
    id: `${baseId}.invalid_phone_number`,
    defaultMessage: 'Please enter a valid phone number',
  },
  register_patient_success: {
    id: `${baseId}.register_patient_success`,
    defaultMessage: 'Successfully registered by sending email to {email}',
  },
  linking_profiles_success: {
    id: `${baseId}.linking_profiles_success`,
    defaultMessage: 'Guardian added to {fullName}',
  },
  create_patient_error: {
    id: `${baseId}.create_patient_error`,
    defaultMessage:
      'Error creating patient. Please try again or contact the administrator',
  },
  register_patient_error: {
    id: `${baseId}.register_patient_error`,
    defaultMessage:
      'Error registering patient with stub id: {stubProfileId}. Please try again using the UUID Search form',
  },
  linking_profiles_error: {
    id: `${baseId}.linking_profiles_error`,
    defaultMessage:
      'The patient was created and sent an invitation email however an error occurred when linking the profiles. Please try linking the profiles manually using the search form',
  },
  email_already_taken_error: {
    id: `${baseId}.email_already_taken_error`,
    defaultMessage:
      'The email address you entered is already linked to an existing account. Please confirm with the patient and enter another email address.',
  },
})
