const errorHasStatus = (error, status) => error?.response?.status === status

export const errorListFromQueryError = (queryError) => {
  const graphQLErrors = queryError?.graphQLErrors ?? []

  return graphQLErrors.map((error) => {
    if (error.extensions) {
      return error.extensions
    }

    return JSON.parse(error.message)
  })
}

export const includesAuthenticationError = (errors) =>
  errors.some((error) => errorHasStatus(error, 403))

export const includesMissingRecordError = (errors) =>
  errors.some((error) => errorHasStatus(error, 404))
