import React, { useReducer, Dispatch, ReactNode } from 'react'
import classNames from 'classnames/bind'
import { Card, SnackbarIntent } from '@babylon/core-ui'
import { AlertFilled, TickCircle } from '@babylon/icons'
import { useFormatMessage } from '@babylon/intl'
import * as actions from './data/actions'
import MemberContext from './data/context'
import reducer, { Reducer } from './data/reducer'
import FamilyMembers from './FamilyMembers'
import MemberCard from './MemberCard'
import messages from './SearchResultItem.messages'
import styles from './styles.module.scss'

const cx = classNames.bind(styles)
const baseClassName = 'searchResultItem'

export type SetSnackbarMessage = (
  message?: string,
  icon?: ReactNode,
  intent?: SnackbarIntent
) => void

type FamilyMemberNode = {
  node: {
    to: {
      accountOwner: boolean
      coreRubyPatientId: number
      id: string
    }
  }
}

export interface SearchResultItemProps extends Member {
  accountOwner: boolean
  consumerNetworkName: string
  coreRubyPatientId: number
  expanded: boolean

  onFamilyMembersToggle(id: string): void

  setSnackbarMessage: SetSnackbarMessage
  familyMembers: {
    edges: FamilyMemberNode[]
  }
}

const getDispatchers = (
  dispatch: Dispatch<actions.AllActions>,
  setSnackbarMessage: SetSnackbarMessage
) => ({
  invitationResent: (message?: string) => (
    patientId?: string,
    error?: string
  ) => {
    if (patientId) {
      setSnackbarMessage(
        message,
        <TickCircle className={styles.snackbar__icon} />
      )
    } else if (error) {
      setSnackbarMessage(
        error,
        <AlertFilled className={styles.snackbar__icon} />,
        'error'
      )
    }
  },
  updateOwner: (transferMessage: string) => (
    newOwnerId?: string,
    error?: string
  ) => {
    if (newOwnerId) {
      dispatch(actions.updateOwner(newOwnerId))
      setSnackbarMessage(
        transferMessage,
        <TickCircle className={styles.snackbar__icon} />
      )
    } else if (error) {
      setSnackbarMessage(
        error,
        <AlertFilled className={styles.snackbar__icon} />,
        'error'
      )
    }
  },
  setFamilyMembers: (members: Member[]) =>
    dispatch(actions.setFamilyMembers(members)),
})

const SearchResultItem = ({
  accountOwner,
  consumerNetworkName,
  coreRubyPatientId,
  expanded,
  onFamilyMembersToggle,
  setSnackbarMessage,
  ...props
}: SearchResultItemProps) => {
  const [{ familyMembers, member }, dispatch] = useReducer<Reducer>(reducer, {
    member: { ...props },
    familyMembers: [],
  })
  const { updateOwner, invitationResent } = getDispatchers(
    dispatch,
    setSnackbarMessage
  )
  const fm = useFormatMessage()
  const transferMessage = fm(messages.snack_transfer_ownership_success)
  const invitationMessage = fm(messages.snack_invitation_resent_success)

  const itemClassNames = cx(baseClassName, {
    [`${baseClassName}--expanded`]: expanded,
  })
  const familyClassNames = cx(`${baseClassName}__family`, {
    [`${baseClassName}__family--expanded`]: expanded,
  })

  const memberFamilyMembers = props.familyMembers?.edges.map(
    (edge) => edge.node.to
  )

  const hasFamilyMembers = !!memberFamilyMembers?.length

  const ownerId = accountOwner
    ? coreRubyPatientId
    : memberFamilyMembers?.find((member) => member.accountOwner)
        ?.coreRubyPatientId

  return (
    <div data-testid="search-result">
      <MemberContext.Provider value={{ member, familyMembers }}>
        <Card className={itemClassNames}>
          <MemberCard
            {...member}
            accountOwner={accountOwner}
            consumerNetworkName={consumerNetworkName}
            coreRubyPatientId={coreRubyPatientId}
            expanded={expanded}
            hasFamilyMembers={hasFamilyMembers}
            isMain
            onDetailsCopy={setSnackbarMessage}
            onResendInvitationEmail={invitationResent(invitationMessage)}
            onTransferOwnership={updateOwner(transferMessage)}
            onFamilyMembersToggle={onFamilyMembersToggle}
            ownerId={ownerId}
            familyAccount={hasFamilyMembers}
          />
          {expanded && hasFamilyMembers && (
            <div className={familyClassNames}>
              <FamilyMembers
                ownerId={ownerId}
                members={memberFamilyMembers}
                onDetailsCopy={setSnackbarMessage}
                onTransferOwnership={updateOwner(transferMessage)}
                onResendInvitationEmail={invitationResent(invitationMessage)}
              />
            </div>
          )}
        </Card>
      </MemberContext.Provider>
    </div>
  )
}

export default SearchResultItem
