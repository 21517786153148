import React, { ReactNode } from 'react'
import { Icon, IconProps } from '@babylon/web-platform-ui'

const getIcon = (newIcon: IconProps['icon']): ReactNode => (
  <Icon icon={newIcon} />
)

const getIconForDecision = (decision: string) => {
  switch (decision) {
    case 'GP':
    case 'GP_URGENT':
    case 'DOCTOR':
      return getIcon('MedicalSpecialist')
    case 'HOSPITAL':
      return getIcon('Hospital')
    case 'HOSPITAL_URGENT':
    case 'AMBULANCE':
      return getIcon('Ambulance')
    case 'SELF_CARE':
    case 'HOME':
      return getIcon('Home')
    case 'PHARMACY':
      return getIcon('Hospital')
    default:
      return undefined
  }
}

export default getIconForDecision
