import React from 'react'

import { Cell, Grid, Text } from '@babylon/core-ui'

import {
  formatClinician,
  formatDate,
  formatDuration,
  formatLocation,
} from './BookSlotUtils'
import { Clinician } from '../AvailabilitySlots/GroupSlots'
import { Clinic } from '../AvailabilitySlots/GroupSlotsByClinic'

interface Props {
  clinic?: Clinic | null
  clinician?: Clinician | null
  slotDuration: number
  UTCTimestamp: string
  timezoneId: string
}

const BookSlotDetails = ({
  clinician,
  slotDuration,
  clinic,
  UTCTimestamp,
  timezoneId,
}: Props) => (
  <Grid columns={3} style={{ marginBottom: '10px' }}>
    <Cell width={1}>
      <Text color="grey-type" uppercase>
        Date
      </Text>
    </Cell>
    <Cell width={2}>
      <Text color="grey-type" size="large" data-testid="book-slot-dialog-date">
        {formatDate(UTCTimestamp, timezoneId)}
      </Text>
    </Cell>
    <Cell width={1}>
      <Text color="grey-type" uppercase>
        Time
      </Text>
    </Cell>
    <Cell width={2}>
      <Text color="grey-type" size="large" data-testid="book-slot-dialog-time">
        {formatDuration(UTCTimestamp, slotDuration, timezoneId)}
      </Text>
    </Cell>
    {clinic && (
      <>
        <Cell width={1}>
          <Text color="grey-type" uppercase>
            Location
          </Text>
        </Cell>
        <Cell width={2}>
          <Text
            color="grey-type"
            size="large"
            data-testid="book-slot-dialog-location"
          >
            {formatLocation(clinic)}
          </Text>
        </Cell>
      </>
    )}
    {clinician && (
      <>
        <Cell width={1}>
          <Text color="grey-type" uppercase>
            Consultant
          </Text>
        </Cell>
        <Cell width={2}>
          <Text
            color="grey-type"
            size="large"
            data-testid="book-slot-dialog-clinician"
          >
            {formatClinician(clinician)}
          </Text>
        </Cell>
      </>
    )}
  </Grid>
)

export default BookSlotDetails
