import React from 'react'
import LegendKey from './LegendKey'
import styles from './styles.module.css'
import {
  ClinicianVisibilityColours,
  ClinicianVisibilityServiceIcons,
} from '../ClinicianVisibility'

export default function Legend() {
  return (
    <div className={styles.legend}>
      <LegendKey
        color={ClinicianVisibilityColours.digital_physical_available}
        legend="Digital physical appointment available"
      />
      <LegendKey color={ClinicianVisibilityColours.admin} legend="Admin Slot" />
      <LegendKey
        color={ClinicianVisibilityColours.digital_available}
        legend="Digital appointment available"
      />
      <LegendKey
        color={ClinicianVisibilityColours.digital_booked}
        legend="Digital appointment booked"
      />
      <LegendKey
        color={ClinicianVisibilityColours.physical_available}
        legend="Physical appointment available"
      />
      <LegendKey
        color={ClinicianVisibilityColours.physical_booked}
        legend="Physical appointment booked"
      />
      <LegendKey
        color={ClinicianVisibilityColours.externally_booked_physical}
        legend="Externally booked physical"
      />
      <LegendKey
        icon={
          ClinicianVisibilityServiceIcons[
            '8cafcd5c-7dde-413c-ae05-1e74fe2900ec'
          ]
        }
        legend="Smear test"
      />
      <LegendKey
        icon={
          ClinicianVisibilityServiceIcons[
            'f2dfb1c3-b4c3-4a40-8bec-ededa7b9ecbf'
          ]
        }
        legend="Blood test"
      />
    </div>
  )
}
