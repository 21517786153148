import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import {
  Box,
  ButtonReactRouterLink,
  ButtonSimple,
  ButtonVariant,
  LinkButton,
  Modal,
  useModalActions,
  withModalProvider,
} from '@babylon/medkit'

import FlowsSummary from '../../components/FlowsSummary'
import MultiLineText from '../../components/MultiLineText'
import SubmitForm from '../../components/SubmitForm'
import { PatientInterface } from '../../types'
import messages from './messages'

import styles from './View.module.scss'

const View = ({ member }: Props) => {
  const fm = useFormatMessage()
  const { closeModal, openModal } = useModalActions()
  const [isSummaryOpen, setIsSummaryOpen] = useState(false)

  const onClickSubmit = () => {
    openModal(
      <SubmitForm member={member} onClose={closeModal} reason="early-exit" />
    )
  }

  return (
    <Box className={styles.view}>
      <Box className={styles.instruction}>
        <MultiLineText text={fm(messages.instruction)} />
      </Box>
      <LinkButton
        data-testid="open-assessment-summary"
        onClick={() => setIsSummaryOpen(true)}
      >
        {fm(messages.summary)}
      </LinkButton>
      <Box className={styles.actions}>
        <ButtonSimple onClick={onClickSubmit}>
          {fm(messages.submit)}
        </ButtonSimple>
        <ButtonReactRouterLink
          to={`/admin/patients/${member.id}/assessment`}
          variant={ButtonVariant.secondary}
        >
          {fm(messages.back)}
        </ButtonReactRouterLink>
      </Box>
      {isSummaryOpen && (
        <FlowsSummary onClose={() => setIsSummaryOpen(false)} />
      )}
      <Modal />
    </Box>
  )
}

interface Props {
  member: PatientInterface
}

const enhance = withModalProvider<Props>()

export default enhance(View)
