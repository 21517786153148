import React, { useState, useCallback } from 'react'
import { Card, Grid, Page, Cell } from '@babylon/core-ui'
import { useMutation } from '@apollo/client'
import { ScrollSync } from 'react-scroll-sync'
import { formatISO } from 'date-fns'

import ReallocateShiftPageParams from '../ReallocateShiftPageParams'
import ReallocateShiftErrorManager, {
  useReallocateShiftErrorManager,
  ERROR_MESSAGES,
} from '../ReallocateShiftErrorManager'

import ClinicianShift from '../ClinicianShift'
import ReallocateShiftFilter from '../ReallocateShiftFilter'
import ReallocateShiftCTA from '../ReallocateShiftCTA'
import ConfirmReallocationModal from '../ConfirmReallocationModal'

import { useQueryParams, UnexpectedError } from '../../Utils'

import { AvailabilitySlotType } from '../ClinicianTimeline/GetClinicianAvailability'
import ReallocateShiftMutation from './ReallocateShiftMutation'
import PageMessage, { PageMessageType } from './PageMessage'

import styles from './ReallocateShiftPage.module.css'

const ReallocateShiftPage = () => {
  const {
    errorMessage,
    setErrorMessage,
    resetErrorMessage,
  } = useReallocateShiftErrorManager()
  const { getQueryParams, resetQueryParams } = useQueryParams()

  const [firstClinicianShift, setFirstClinicianShift] = useState<
    AvailabilitySlotType[]
  >([])
  const [openModal, setOpenModal] = useState(false)
  const [
    pageMessageType,
    setPageMessageType,
  ] = useState<PageMessageType | null>(null)
  const [isLoadingAllShifts, setIsLoadingAllShifts] = useState({
    first: true,
    covering: true,
  })

  const [reallocateShift, { error }] = useMutation(ReallocateShiftMutation, {
    onCompleted: (data) => {
      if (data?.reallocateShift?.orphan_appointments?.length) {
        setPageMessageType('warning')
      } else {
        setPageMessageType('success')
      }

      resetQueryParams()
      setOpenModal(false)
    },
    onError: () => {
      setOpenModal(false)
    },
  })

  const {
    first_clinician_id: firstClinicianId,
    covering_clinician_id: coveringClinicianId,
    date,
    shift_start: shiftStart,
    shift_end: shiftEnd,
  } = getQueryParams()

  const isCTAHidden = !firstClinicianId || !coveringClinicianId

  const onLoadingFirstClinicianShift = useCallback(
    (slotsInShift) => {
      setIsLoadingAllShifts((prevState) => ({ ...prevState, first: false }))

      return setFirstClinicianShift(slotsInShift)
    },
    [setFirstClinicianShift, setIsLoadingAllShifts]
  )

  const onLoadingCoveringClinicianShift = useCallback(
    (slotsInShift) => {
      if (slotsInShift.length) {
        setErrorMessage(ERROR_MESSAGES.overlap)
      } else {
        resetErrorMessage()
      }

      setIsLoadingAllShifts((prevState) => ({ ...prevState, covering: false }))
    },
    [setIsLoadingAllShifts, resetErrorMessage, setErrorMessage]
  )

  const handleClinicianChange = useCallback(
    (clinicianType) => () => {
      setIsLoadingAllShifts((prevState) => ({
        ...prevState,
        [clinicianType]: true,
      }))
    },
    [setIsLoadingAllShifts]
  )

  const handleShiftReallocation = () =>
    reallocateShift({
      variables: {
        filters: {
          from_clinician_id: firstClinicianId,
          to_clinician_id: coveringClinicianId,
          slots: firstClinicianShift.map((appointment) => ({
            slot_id: parseInt(appointment.id, 10),
            appointment_id: appointment?.appointment?.id,
          })),
          start_date_time: formatISO(new Date(`${date} ${shiftStart}`)),
          end_date_time: formatISO(new Date(`${date} ${shiftEnd}`)),
        },
      },
    })

  const isLoadingShifts = Object.values(isLoadingAllShifts).reduce(
    (acc, value) => acc && value,
    true
  )

  const hasSlotsToReallocate = firstClinicianShift.length > 0

  return (
    <Page>
      <Grid
        className={styles.ReallocationShiftPage}
        templateColumns="270px auto"
        columnGap={10}
      >
        <Cell top={0}>
          <ReallocateShiftFilter />
        </Cell>
        <Card padding="none">
          <ScrollSync>
            <Grid
              className={styles.ReallocationShiftGrid}
              height={700}
              columns={2}
              columnGap={0}
            >
              <Cell
                className={styles.ReallocationDateClinicianShiftCell}
                middle
              >
                <ClinicianShift
                  onChangeClinician={handleClinicianChange('first')}
                  onLoadingShift={onLoadingFirstClinicianShift}
                  clinicianType="first"
                />
              </Cell>
              <Cell
                className={styles.ReallocationDateClinicianShiftCell}
                middle
              >
                <ClinicianShift
                  onChangeClinician={handleClinicianChange('covering')}
                  onLoadingShift={onLoadingCoveringClinicianShift}
                  clinicianType="covering"
                />
              </Cell>
            </Grid>
          </ScrollSync>
        </Card>
      </Grid>
      {!isCTAHidden && (
        <>
          <ReallocateShiftCTA
            data-testid="reallocate-shift-cta"
            disabled={
              !!errorMessage || isLoadingShifts || !hasSlotsToReallocate
            }
            errorMessage={errorMessage}
            onClick={() => setOpenModal(true)}
          />
          {openModal && (
            <ConfirmReallocationModal
              shiftStart={shiftStart as string}
              shiftEnd={shiftEnd as string}
              date={date as string}
              firstClinicianId={firstClinicianId as string}
              coveringClinicianId={coveringClinicianId as string}
              isOpen={openModal}
              onConfirm={handleShiftReallocation}
              onClose={() => setOpenModal(false)}
            />
          )}
        </>
      )}
      {error && <UnexpectedError message={error.message} visible />}
      {pageMessageType && (
        <PageMessage
          type={pageMessageType}
          onClose={() => setPageMessageType(null)}
        />
      )}
    </Page>
  )
}

export default function ReallocateShift() {
  return (
    <ReallocateShiftPageParams>
      <ReallocateShiftErrorManager>
        <ReallocateShiftPage />
      </ReallocateShiftErrorManager>
    </ReallocateShiftPageParams>
  )
}
