import React, { useReducer, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import { DrawerView } from '../../../../../..'
import messages from './messages'
import { QUERY_REDEEMED_CODES } from './queries'
import { Redemption } from './types'
import styles from './RedeemedPromoCodes.module.scss'
import RedemptionForm from './RedemptionForm'
import RedeemedCodeEntries from './RedeemedCodesEntries'
import ConfirmRedemptionActionModal, {
  ActionType,
} from './ConfirmRedemptionActionModal'

// 25 is the default page length returned by the API
const DEFAULT_ENTRIES_COUNT = 25

const REMOVE_REDEMPTION = 'remove-redemption'
const REACTIVATE_REDEMPTION = 'reactivate-redemption'
const CLOSE_MODAL = 'close-modal'

type DrawerProps = {
  patientId: string
  toggleVisible: () => void
  visible: boolean
}

type ReducerAction = {
  type: string | null
  isOpened?: boolean
  redemptionId?: string
  redemptionName?: string
}

type ModalState = {
  isOpened: boolean
  redemptionId: string | null
  redemptionName: string | null
  actionType: ActionType | null
}

const initialModalState = {
  isOpened: false,
  redemptionId: null,
  redemptionName: null,
  actionType: null,
} as ModalState

function modalStateReducer(_: ModalState, action: ReducerAction) {
  switch (action.type) {
    case REMOVE_REDEMPTION:
      return {
        isOpened: true,
        redemptionId: action.redemptionId,
        redemptionName: action.redemptionName,
        actionType: ActionType.removePromo,
      } as ModalState
    case REACTIVATE_REDEMPTION:
      return {
        isOpened: true,
        redemptionId: action.redemptionId,
        redemptionName: action.redemptionName,
        actionType: ActionType.reactivatePromo,
      } as ModalState

    case CLOSE_MODAL:
    default:
      return initialModalState
  }
}

const INCREMENT_PAGE = 'increment-page'
const SET_RESULTS = 'set-results'
const REFRESH_RESULTS = 'refresh-results'
const INCREMENT_PAGE_AND_SET_RESULTS = 'increment-page-and-set-results'

type EntriesReducerAction = {
  type: string | null
  incrementPage?: boolean
  entriesPayload?: Redemption[]
}

type EntriesState = {
  nextPage: number
  hasMore: boolean
  redemptions: Redemption[]
}

const initialEntriesState = {
  nextPage: 2,
  hasMore: true,
  redemptions: [],
} as EntriesState

function entriesStateReducer(
  state: EntriesState,
  action: EntriesReducerAction
) {
  const hasMore = (entriesCount: number) =>
    entriesCount === DEFAULT_ENTRIES_COUNT

  const unwrapEntries = (entries?: Redemption[]) => entries || []

  let entries: Redemption[]
  switch (action.type) {
    case INCREMENT_PAGE:
      return {
        ...state,
        nextPage: state.nextPage + 1,
      }
    case SET_RESULTS:
      entries = unwrapEntries(action.entriesPayload)
      return {
        ...state,
        hasMore: hasMore(entries.length),
        redemptions: [...state.redemptions, ...entries],
      }
    case INCREMENT_PAGE_AND_SET_RESULTS:
      entries = unwrapEntries(action.entriesPayload)
      return {
        ...state,
        nextPage: state.nextPage + 1,
        hasMore: hasMore(entries.length),
        redemptions: [...state.redemptions, ...entries],
      }
    case REFRESH_RESULTS:
      entries = unwrapEntries(action.entriesPayload)
      return {
        ...state,
        hasMore: false,
        redemptions: [...entries],
      }
    default:
      return initialEntriesState
  }
}

const RedeemedPromoCodesDrawer = ({
  patientId,
  toggleVisible,
  visible,
}: DrawerProps) => {
  const fm = useFormatMessage()
  const [modalState, dispatchModalState] = useReducer(
    modalStateReducer,
    initialModalState
  )
  const [entriesState, dispatchEntriesState] = useReducer(
    entriesStateReducer,
    initialEntriesState
  )
  const ref = useRef<HTMLDivElement | null>(null)

  // Policy must be set to `no-cache` to allow the drawer to fetch fresh results whenever invoke
  const { refetch, fetchMore } = useQuery(QUERY_REDEEMED_CODES, {
    fetchPolicy: 'no-cache',
    variables: { patientId, page: 1 },
    onCompleted: ({ redeemedCodes }) =>
      dispatchEntriesState({
        type: SET_RESULTS,
        entriesPayload: redeemedCodes,
      }),
  })

  const fetchNextEntries = (page: number, isRefetch = false) => {
    fetchMore({
      variables: {
        page: isRefetch ? page : entriesState.nextPage,
      },
      updateQuery: (_, { fetchMoreResult }) => {
        const { redeemedCodes } = fetchMoreResult
        dispatchEntriesState({
          type: INCREMENT_PAGE_AND_SET_RESULTS,
          entriesPayload: redeemedCodes,
        })
      },
    })
  }

  const handleEntryAction = (
    id: string,
    redemptionName: string,
    actionType: ActionType
  ) => {
    let type: string | null = null
    if (actionType === ActionType.removePromo) {
      type = REMOVE_REDEMPTION
    } else if (actionType === ActionType.reactivatePromo) {
      type = REACTIVATE_REDEMPTION
    }

    dispatchModalState({
      type,
      redemptionId: id,
      redemptionName,
    })
  }

  const handleRefetch = async () => {
    const { data } = await refetch()

    dispatchEntriesState({
      type: REFRESH_RESULTS,
      entriesPayload: data.redeemedCodes,
    })
    for (let i = 2; i < entriesState.nextPage; ++i) {
      fetchNextEntries(i, true)
    }
  }

  return (
    <DrawerView
      title={fm(messages.redeemed_codes_heading)}
      visible={visible}
      toggleVisible={toggleVisible}
      className={styles.RedeemCodesDrawer}
      ref={ref}
    >
      <RedemptionForm patientId={patientId} onRedemption={handleRefetch} />
      <div data-testid="redeemed-codes-entries">
        <RedeemedCodeEntries
          onAction={handleEntryAction}
          entriesPayload={entriesState.redemptions}
          fetchNextEntries={fetchNextEntries}
          hasMoreEntries={entriesState.hasMore}
          getScrollParent={() => ref.current}
        />
      </div>
      <ConfirmRedemptionActionModal
        actionType={modalState.actionType}
        isOpened={modalState.isOpened}
        onClose={() => dispatchModalState({ type: CLOSE_MODAL })}
        patientId={patientId}
        redemptionId={modalState.redemptionId}
        redemptionName={modalState.redemptionName}
        onSuccess={handleRefetch}
      />
    </DrawerView>
  )
}

export default RedeemedPromoCodesDrawer
