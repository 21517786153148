import { defineMessages } from 'react-intl'

const baseId = 'profile_header'

export default defineMessages({
  book_appointment: {
    id: `${baseId}.book_appointment`,
    defaultMessage: 'Book appointment',
  },
  reset_password: {
    id: `${baseId}.reset_password`,
    defaultMessage: 'Reset password',
  },
  reset_password_snack_message: {
    id: `${baseId}.reset_password_snack_message`,
    defaultMessage: 'Password reset instructions have been sent to {email}',
  },
  reset_password_error: {
    id: `${baseId}.reset_password_network_error`,
    defaultMessage: 'Reset Password Failed. {message}',
  },
  edit_profile: {
    id: `${baseId}.edit_profile`,
    defaultMessage: 'Edit profile',
  },
  switch_old_view: {
    id: `${baseId}.switch_old_view`,
    defaultMessage: 'Use old member details',
  },
  show_all_details: {
    id: `${baseId}.show_all_details`,
    defaultMessage: 'Show more',
  },
  hide_all_details: {
    id: `${baseId}.hide_all_details`,
    defaultMessage: 'Show less',
  },
  identity_check_override: {
    id: `${baseId}.identity_check_override`,
    defaultMessage: 'Override',
  },
  identity_check_overridden_snack_message: {
    id: `${baseId}.identity_check_overridden_snack_message`,
    defaultMessage: "Member's ID has been successfully overridden",
  },
  identity_check_modal_header: {
    id: `${baseId}.identity_check_modal_header`,
    defaultMessage: 'Override ID verification',
  },
  identity_check_modal_content: {
    id: `${baseId}.identity_check_modal_content`,
    defaultMessage:
      "Are you sure you want to override patient's ID verification?",
  },
  identity_check_button: {
    id: `${baseId}.identity_check_button`,
    defaultMessage: 'Override ID verification',
  },
  add_family_members: {
    id: `${baseId}.add_family_members`,
    defaultMessage: 'Add family members',
  },
  view_family_members: {
    id: `${baseId}.view_family_members`,
    defaultMessage: 'View family members',
  },
  view_on_onfido: {
    id: `${baseId}.view_on_onfido`,
    defaultMessage: 'View ID on Onfido',
  },
  delete_patient_button: {
    id: `${baseId}.delete_patient_button`,
    defaultMessage: 'Delete patient data',
  },
  fix_login_issues_button: {
    id: `${baseId}.fix_login_issues_button`,
    defaultMessage: 'Fix login issues',
  },
  fix_login_issues_confirmation: {
    id: `${baseId}.fix_login_issues_confirmation`,
    defaultMessage: 'Continue',
  },
  fix_login_issues_cancel: {
    id: `${baseId}.fix_login_issues_cancel`,
    defaultMessage: 'Cancel',
  },
  fix_login_issues_header: {
    id: `${baseId}.fix_login_issues_header`,
    defaultMessage: 'Are you sure?',
  },
  fix_login_issues_content: {
    id: `${baseId}.fix_login_issues_content`,
    defaultMessage:
      'This option should be reserved as a last resort to fix backend issues when someone cannot log in. It will reset the user’s records in our authentication system. It doesn’t affect patient records or other personal details.',
  },
  fix_login_issues_success: {
    id: `${baseId}.fix_login_issues_success`,
    defaultMessage: 'Successfully fixed login issues',
  },
  fix_login_issues_failed: {
    id: `${baseId}.fix_login_issues_failed`,
    defaultMessage: 'Failure fixing login issues, error: {message}',
  },
  family_account_owner: {
    id: `${baseId}.family_account_owner`,
    defaultMessage: 'Family account owner',
  },
  disable_sms_mfa_confirmation: {
    id: `${baseId}.disable_sms_mfa_confirmation`,
    defaultMessage: 'Disable',
  },
  disable_sms_mfa_modal_header: {
    id: `${baseId}.disable_sms_mfa_modal_header`,
    defaultMessage: 'Disable SMS MFA',
  },
  disable_sms_mfa_modal_content: {
    id: `${baseId}.disable_sms_mfa_modal_content`,
    defaultMessage:
      'Are you sure you would like to disable the SMS Multi-Factor Authentication for this member?',
  },
  disable_sms_mfa_menu_option: {
    id: `${baseId}.disable_sms_mfa_menu_option`,
    defaultMessage: 'Disable SMS MFA',
  },
  disable_sms_mfa_success: {
    id: `${baseId}.disable_sms_mfa_success`,
    defaultMessage: 'Successfully disabled SMS MFA',
  },
  disable_sms_mfa_failure: {
    id: `${baseId}.disable_sms_mfa_failure`,
    defaultMessage: 'Something went wrong disabling SMS MFA, please try again',
  },
  send_registration_email: {
    id: `${baseId}.send_registration_email`,
    defaultMessage: 'Send registration email',
  },
})
