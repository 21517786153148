import { defineMessages } from 'react-intl'

const baseId = 'referral_info'

export default defineMessages({
  referral_speciality: {
    id: `${baseId}.referral_speciality`,
    defaultMessage: 'Referral speciality',
  },
  referred_to: {
    id: `${baseId}.referred_to`,
    defaultMessage: 'Referred to',
  },
})
