import { defineMessages } from 'react-intl'

const baseId = 'appointment_card_details'

export default defineMessages({
  appointment_type: {
    id: `${baseId}.appointment_type`,
    defaultMessage: 'Appointment type',
  },
  arrival_instructions: {
    id: `${baseId}.arrival_instructions`,
    defaultMessage: 'Patient instructions',
  },
  calls_made: {
    id: `${baseId}.calls_made`,
    defaultMessage: 'Calls made to patient',
  },
  check_in: {
    id: `${baseId}.check_in`,
    defaultMessage: 'Check-in',
  },
  check_in_status: {
    id: `${baseId}.check_in_status`,
    defaultMessage: 'N/A',
  },
  clinic_location: {
    id: `${baseId}.clinic_location`,
    defaultMessage: 'Clinic location',
  },
  consultation_id: {
    id: `${baseId}.consultation_id`,
    defaultMessage: 'Consultation ID',
  },
  no_arrival_instructions: {
    id: `${baseId}.no_arrival_instructions`,
    defaultMessage: 'N/A',
  },
  no_reason: {
    id: `${baseId}.no_reason`,
    defaultMessage: 'N/A',
  },
  notes: {
    id: `${baseId}.notes`,
    defaultMessage: 'Patient instructions',
  },
  original_consultation: {
    id: `${baseId}.original_consultation`,
    defaultMessage: 'Original consultation',
  },
  reason_for_appointment: {
    id: `${baseId}.reason_for_appointment`,
    defaultMessage: 'Reason for appointment',
  },
  share_notes: {
    id: `${baseId}.share_notes`,
    defaultMessage: 'Share notes with GP?',
  },
  guestName: {
    id: `${baseId}.guestName`,
    defaultMessage: 'Caregiver',
  },
})
