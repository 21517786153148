import { defineMessages } from 'react-intl'

const baseId = 'transactions.transactions'

export default defineMessages({
  refund: {
    id: `${baseId}.refund`,
    defaultMessage: 'Refund',
  },
  refunded: {
    id: `${baseId}.refunded`,
    defaultMessage: 'Refunded',
  },
  free: {
    id: `${baseId}.free`,
    defaultMessage: 'Free',
  },
  voided: {
    id: `${baseId}.voided`,
    defaultMessage: 'Voided',
  },
  null: {
    id: `${baseId}.null`,
    defaultMessage: 'Null',
  },
  refund_date: {
    id: `${baseId}.refund_date`,
    defaultMessage: 'Refund date',
  },
  refund_reason: {
    id: `${baseId}.refund_reason`,
    defaultMessage: 'Reason',
  },
})
