import { defineMessages } from 'react-intl'

const baseId = 'pharmacists_datagrid'

export const messages = defineMessages({
  edit: {
    id: `${baseId}.edit`,
    defaultMessage: 'Edit',
  },
  loading: {
    id: `${baseId}.loading`,
    defaultMessage: 'Loading Pharmacist Data',
  },
  error: {
    id: `${baseId}.error`,
    defaultMessage: 'Error loading PharmacistData:',
  },
  noPharmacists: {
    id: `${baseId}.no_pharmacists`,
    defaultMessage: 'No pharmacists found',
  },
  pharmacist: {
    id: `${baseId}.pharmacist`,
    defaultMessage: 'Pharmacist',
  },
  editLogin: {
    id: `${baseId}.editLogin`,
    defaultMessage: 'Edit Login',
  },
  name: {
    id: `${baseId}.name`,
    defaultMessage: 'Name',
  },
  email: {
    id: `${baseId}.email`,
    defaultMessage: 'Email',
  },
  pharmacySupplier: {
    id: `${baseId}.pharmacySupplier`,
    defaultMessage: 'Pharmacy Supplier',
  },
  loadingPharmacySuppliers: {
    id: `${baseId}.loadingPharmacySuppliers`,
    defaultMessage: 'Loading Pharmacy Suppliers ...',
  },
  loadingRegions: {
    id: `${baseId}.loadingRegions`,
    defaultMessage: 'Loading Regions ...',
  },
  loadingPortalRoles: {
    id: `${baseId}.loadingPortalRoles`,
    defaultMessage: 'Loading Portal Roles ...',
  },
  loadingPharmacySuppliersError: {
    id: `${baseId}.loadingPharmacySuppliersError`,
    defaultMessage: 'Error loading Pharmacy Suppliers.',
  },
  loadingRegionsError: {
    id: `${baseId}.loadingRegionsError`,
    defaultMessage: 'Error loading Regions.',
  },
  loadingPortalRolesError: {
    id: `${baseId}.loadingPortalRolesError`,
    defaultMessage: 'Error loading portal roles.',
  },
  active: {
    id: `${baseId}.active`,
    defaultMessage: 'Active',
  },
  pharmacistRole: {
    id: `${baseId}.pharmacistRole`,
    defaultMessage: 'Pharmacist Role',
  },
  successfulPharmacistEdit: {
    id: `${baseId}.successfulPharmacistEdit`,
    defaultMessage: 'Pharmacist has been updated successfully',
  },
  errorPharmacistEdit: {
    id: `${baseId}.errorPharmacistEdit`,
    defaultMessage: 'Error editing the pharmacist',
  },
  save: {
    id: `${baseId}.save`,
    defaultMessage: 'Save',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  region: {
    id: `${baseId}.region`,
    defaultMessage: 'Region',
  },
  autoSelectRegionMessage: {
    id: `${baseId}.autoSelectRegionMessage`,
    defaultMessage:
      'Please note that the region of pharmacy supplier will be auto-selected as region of the pharmacist',
  },
})
