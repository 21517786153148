import { gql } from '@apollo/client'

export default gql`
  mutation RemoveClinicFromServiceType(
    $service_type_uuid: ID!
    $clinic_id: ID!
  ) {
    removeClinicFromServiceType(
      service_type_uuid: $service_type_uuid
      clinic_id: $clinic_id
    )
  }
`
