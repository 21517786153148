import { defineMessages } from 'react-intl'

const baseId = 'share_notes'

export default defineMessages({
  accept: {
    id: `${baseId}.accept`,
    defaultMessage: 'Yes',
  },
  decline: {
    id: `${baseId}.decline`,
    defaultMessage: 'No',
  },
})
