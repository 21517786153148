import React, { useState, useEffect, useRef, FC } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { Card } from '@babylon/core-ui'
import * as Access from '@babylon/web-platform-access'
import { useProductConfig } from '@babylon/product-config'
import { Tick } from '@babylon/icons'
import styles from './TopBar.module.scss'
import messages from './messages'
import {
  getLocale,
  clearLocale,
  setLocale,
  TSupportedLocale,
} from '@/util/intl'
import { useLogout } from '@/util/useLogout'

export interface IconMenuTogglerProps {
  expanded: boolean
  onToggle(): void
  testId: string
}

const ProfileMenuToggler: FC<IconMenuTogglerProps> = ({
  onToggle,
  testId,
  expanded,
  children,
}) => {
  const toggleClassName = classnames(styles.profile__menu, {
    [styles['profile__menu--expanded']]: !!expanded,
  })

  const fm = useFormatMessage()

  return (
    <>
      <div className={styles.profile__menu_icon}>
        <a data-testid={testId} onClick={onToggle}>
          <svg
            className={styles.profile__menu_icon_svg}
            width="35"
            height="35"
            viewBox="0 0 24 24"
          >
            <path d="M12 2.4c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6zm0 2.88c1.86 0 3.36 1.5 3.36 3.36 0 1.86-1.5 3.36-3.36 3.36-1.86 0-3.36-1.5-3.36-3.36 0-1.86 1.5-3.36 3.36-3.36zm0 14.712a7.489 7.489 0 0 1-6.24-3.349c.031-2.07 4.16-3.203 6.24-3.203 2.07 0 6.209 1.134 6.24 3.203A7.489 7.489 0 0 1 12 19.992z" />
            <title>{fm(messages.user_profile)}</title>
          </svg>
        </a>
      </div>

      <div className={toggleClassName} role="menu">
        <Card padding="sm">{children}</Card>
      </div>
    </>
  )
}

interface LanguageProps {
  languageISO: TSupportedLocale
  label: string
}

const Language = ({ languageISO, label }: LanguageProps) => {
  const currentLocale = getLocale()
  const isSelected = currentLocale === languageISO

  return (
    <div>
      <a
        className={styles.language__menu_item}
        onClick={() => {
          if (isSelected) {
            clearLocale()
          } else {
            setLocale(languageISO)
          }

          location.reload()
        }}
      >
        {label}
        {isSelected ? <Tick scale={0.5} /> : null}
      </a>
    </div>
  )
}

interface LanguagesProps {
  includeTest: boolean
}

const Languages = ({ includeTest }: LanguagesProps) => {
  const fm = useFormatMessage()

  return (
    <div className={styles.profile__menu_submenu}>
      <a
        className={styles.profile__menu_item}
        data-testid="profile-menu__language"
      >
        {fm(messages.language)}
      </a>
      <div className={styles.submenu_container}>
        <div className={styles.submenu}>
          <Card padding="sm">
            <Language
              languageISO="en_GB"
              label={fm(messages.language_english_uk)}
            />
            <Language
              languageISO="en_CA"
              label={fm(messages.language_english_ca)}
            />
            <Language
              languageISO="en_US"
              label={fm(messages.language_english_us)}
            />
            {includeTest ? (
              <Language languageISO="en_TEST" label="Test" />
            ) : null}
          </Card>
        </div>
      </div>
    </div>
  )
}

const Profile = () => {
  const [expanded, setExpanded] = useState(false)
  const logout = useLogout()
  const node = useRef<any>(null)
  const fm = useFormatMessage()
  const [canAccessAccountSetting] = Access.useDecide('account-setting')

  const toggleProfileMenuHandler = () => {
    setExpanded(!expanded)
  }

  const settingsHandler = () => {
    setExpanded(false)
  }

  const logoutHandler = () => {
    logout()
  }

  const handleClickOutside = (e: any) => {
    if (node && node.current.contains(e.target)) {
      return
    }

    setExpanded(false)
  }

  const { getBlob } = useProductConfig()
  const { languageMenuItemEnabled, testLanguageOptionEnabled } = getBlob(
    'memberOperations'
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <span
      ref={node}
      className={styles.profile__iconWrapper}
      data-test-id="profile-menu"
    >
      <ProfileMenuToggler
        expanded={expanded}
        testId="profile-toggle"
        onToggle={toggleProfileMenuHandler}
      >
        {canAccessAccountSetting && (
          <Link
            className={styles.profile__menu_item}
            data-testid="profile-menu__account-settings"
            to="/portal_login/edit"
            onClick={settingsHandler}
          >
            {fm(messages.account_settings)}
          </Link>
        )}
        {languageMenuItemEnabled ? (
          <Languages includeTest={testLanguageOptionEnabled} />
        ) : null}
        <a
          className={styles.profile__menu_item}
          data-testid="profile-menu__logout"
          onClick={logoutHandler}
        >
          {fm(messages.log_out)}
        </a>
      </ProfileMenuToggler>
    </span>
  )
}

export default Profile
