import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faTint } from '@fortawesome/pro-solid-svg-icons'
import { Page, Text } from '@babylon/core-ui'
import Legend from './Legend'
import ClinicianVisibilitySearch from './ClinicianVisibilitySearch/ClinicianVisibilitySearch'
import ClinicianVisibilityResults from './ClinicianVisibilityResults'

export const ClinicianVisibilityColours = {
  digital_physical_available: '#17B04A',
  physical_available: '#ED991B',
  digital_available: '#0070FF',
  physical_booked: '#F6CB8D',
  digital_booked: '#80B6FF',
  externally_booked_physical: '#A0A0A0',
  admin: '#FB3131',
}

export const ClinicianVisibilityServiceIcons = {
  '8cafcd5c-7dde-413c-ae05-1e74fe2900ec': <FontAwesomeIcon icon={faStar} />, // Smear test serviceType
  'f2dfb1c3-b4c3-4a40-8bec-ededa7b9ecbf': <FontAwesomeIcon icon={faTint} />, // Blood test serviceType
}

export type ClinicianVisibilityType = keyof typeof ClinicianVisibilityColours
export type ClinicianVisibilityServiceTypes = keyof typeof ClinicianVisibilityServiceIcons

export default function ClinicianVisibility() {
  return (
    <Page
      breadcrumbs={[
        <Text color="grey-type">Clinicians</Text>,
        <Text bold color="grey-type">
          Clinician Visibility
        </Text>,
      ]}
    >
      <ClinicianVisibilitySearch />
      <Legend />
      <ClinicianVisibilityResults />
    </Page>
  )
}
