import messages from './AppointmentWaitTimeConfigsSaveDialog.messages'
import { AppointmentWaitTimeConfigsSaveDialogOperations } from './AppointmentWaitTimeConfigsSaveDialog.types'

export interface ValidationResult {
  isValid: boolean
  message: string
}

export const getDialogTitle = (operation: string) => {
  switch (operation) {
    case AppointmentWaitTimeConfigsSaveDialogOperations.Update:
      return messages.updateWaitTimeThresholdTitle.defaultMessage
    case AppointmentWaitTimeConfigsSaveDialogOperations.Insert:
      return messages.createWaitTimeThresholdTitle.defaultMessage
    default:
      return ''
  }
}
export const validateWaitTimeThresholdInputFormat = (
  waitTimeThreshold: string
): boolean => {
  const input = waitTimeThreshold?.trim()

  return !!input && /^(?:\d+d\/(?:\d+h\/(?:\d+m)?)|\d+[dh]?)$/.test(input)
}

export const extractWaitTimeThreshold = (waitTimeThreshold: string) =>
  Number(waitTimeThreshold.replace(/\D/g, ''))

export const convertToMinutes = (waitTimeThreshold: string) => {
  const splitedWaitTimeThreshold = validateWaitTimeThresholdInputFormat(
    waitTimeThreshold
  )
    ? waitTimeThreshold.split('/')
    : undefined
  const isSplitedWaitTimeThresholdValid = splitedWaitTimeThreshold?.length === 3

  if (isSplitedWaitTimeThresholdValid) {
    const days = extractWaitTimeThreshold(splitedWaitTimeThreshold[0])
    const hours = extractWaitTimeThreshold(splitedWaitTimeThreshold[1])
    const minutes = extractWaitTimeThreshold(splitedWaitTimeThreshold[2])

    return days * 1440 + hours * 60 + minutes
  }

  return 0
}

export const isWaitTimeThresholdsValid = (
  critical: string,
  overloaded: string,
  busy: string
) => {
  const criticalInMinutes = convertToMinutes(critical)
  const overloadedInMinutes = convertToMinutes(overloaded)
  const busyInMinutes = convertToMinutes(busy)

  return (
    criticalInMinutes > 0 &&
    overloadedInMinutes > 0 &&
    busyInMinutes > 0 &&
    criticalInMinutes > overloadedInMinutes &&
    overloadedInMinutes > busyInMinutes
  )
}

export const validateWaitTimeConfigsInput = (
  consumerNetworkIds: string | any[],
  serviceTypeUuids: string | any[],
  critical: string,
  overloaded: string,
  busy: string
): ValidationResult => {
  const isConsumerNetworkIdsValid = consumerNetworkIds.length === 1
  const isServiceTypeUuidsValid = serviceTypeUuids.length <= 1
  const isCriticalFormatValid = validateWaitTimeThresholdInputFormat(critical)
  const isOverloadedFormatValid = validateWaitTimeThresholdInputFormat(
    overloaded
  )
  const isBusyForamtValid = validateWaitTimeThresholdInputFormat(busy)

  if (!isConsumerNetworkIdsValid) {
    return {
      isValid: false,
      message: messages.invalidConsumerNetworkErrorMessage.defaultMessage,
    }
  }

  if (!isServiceTypeUuidsValid) {
    return {
      isValid: false,
      message: messages.invalidServiceTypeErrorMessage.defaultMessage,
    }
  }

  if (!isCriticalFormatValid) {
    return {
      isValid: false,
      message:
        messages.invalidCriticalWaitTimeThresholdErrorMessage.defaultMessage,
    }
  }

  if (!isOverloadedFormatValid) {
    return {
      isValid: false,
      message:
        messages.invalidOverloadedWaitTimeThresholdErrorMessage.defaultMessage,
    }
  }

  if (!isBusyForamtValid) {
    return {
      isValid: false,
      message: messages.invalidBusyWaitTimeThresholdErrorMessage.defaultMessage,
    }
  }

  if (!isWaitTimeThresholdsValid(critical, overloaded, busy)) {
    return {
      isValid: false,
      message: messages.invalidWaitTimeThresholdsErrorMessage.defaultMessage,
    }
  }

  return { isValid: true, message: '' }
}

export const getErrorStatusCode = (error) =>
  error?.graphQLErrors[0]?.extensions?.response?.status.toString()
