import React, { Component } from 'react'
import { map } from '.'

const withForm = (config, data, actions, plugins) => (BaseComponent) =>
  class WithForm extends Component {
    constructor(props) {
      super(props)
      const data = this.getData(props)
      this.state = (data && this.updateState(data)) || {}
      this.actions = actions ? this.prepareActions(actions) : {}
    }

    // TO-DO: fix unsafe method:
    // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
    UNSAFE_componentWillReceiveProps(nextProps) {
      const data = this.getData(this.props)
      const nextData = this.getData(nextProps)

      if (nextData && data !== nextData) {
        const nextState = this.updateState(nextData)
        this.setState(nextState)
      }
    }

    getData(props) {
      return typeof data === 'function' ? data(props) : data
    }

    render() {
      const formName = config.name || 'form'
      const props = {
        ...this.props,
        [formName]: {
          config,
          state: { ...this.state },
          plugins,
          actions: this.actions,
        },
      }

      return <BaseComponent {...props} />
    }

    updateState(data) {
      return config.fields.reduce((state, field) => {
        state[field.name] = data[field.name]

        return state
      }, {})
    }

    createAction = (action) => (...args) =>
      action(
        {
          ...this.props,
          state: { ...this.state },
          setState: this.setState.bind(this),
        },
        ...args
      )

    prepareActions(actions) {
      return {
        ...map(actions, this.createAction),
        onChange: (name, value) => this.setState({ [name]: value }),
        onSave: actions.onSave
          ? () => actions.onSave(this.props, this.state)
          : null,
      }
    }
  }

export default withForm
