import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Unknown } from '../..'
import CopyToClipboardButton from '../../CopyToClipboardButton'
import commonStyles from '../styles.module.scss'
import messages from '../CopiableMemberInformation.messages'

export interface PhoneNumberProps {
  phoneNumber: string | null
  onPhoneNumberCopy?: (message: string, phoneNumber: string) => void
}

const PhoneNumber = ({ phoneNumber, onPhoneNumberCopy }: PhoneNumberProps) => {
  const fm = useFormatMessage()

  if (!phoneNumber || (phoneNumber && !phoneNumber.trim())) {
    return <Unknown label={fm(messages.phone_number_unknown)} />
  }

  const phoneNumberCopiedHandler = (copiedNumber: string) => {
    onPhoneNumberCopy &&
      onPhoneNumberCopy(fm(messages.phone_number), copiedNumber)
  }

  return (
    <CopyToClipboardButton
      className={commonStyles.copiable__copyToClipboardButton}
      value={phoneNumber}
      onCopiedToClipboard={phoneNumberCopiedHandler}
    >
      <span data-testid="copiable-phone-number">{phoneNumber}</span>
    </CopyToClipboardButton>
  )
}

export default PhoneNumber
