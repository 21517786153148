import React from 'react'
import cx from 'classnames'

const Row = ({ className, children, stretch, even, center, end }) => (
  <div className={cx('layout-row', className, { stretch, even, center, end })}>
    {children}
  </div>
)

export default Row
