import { isBefore } from 'date-fns'

const isEarlierSlot = (earliestTime?: string, timeToCompare?: string) => {
  if (
    timeToCompare &&
    (!earliestTime || isBefore(new Date(timeToCompare), new Date(earliestTime)))
  ) {
    return timeToCompare
  }

  return earliestTime
}

export default function getEarliestSlotTime(
  slots: ClinicianSlots[]
): string | undefined {
  const allClinicianSlots = slots.reduce(
    (acc, clinicianSlot) => [...acc, ...clinicianSlot.slots],
    []
  )
  const allVisibilitySlots = allClinicianSlots.reduce(
    (accSlot, slot) => [...accSlot, ...slot?.visibility_slots],
    []
  )

  return allVisibilitySlots.reduce(
    (acc, slot) => isEarlierSlot(acc, slot.slot_time),
    undefined
  )
}
