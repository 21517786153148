import React from 'react'
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar'
import type { Formats } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { format, parse, startOfWeek, getDay, subHours } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import classNames from 'classnames'
import styles from './ClinicianCalendar.module.css'
import type { ClinicianCalendarProps } from './ClinicianCalendar.types'

const locales = {
  'en-GB': enGB,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () =>
    startOfWeek(new Date(), {
      weekStartsOn: 1,
    }),
  getDay,
  locales,
})

const formats: Formats = {
  dayFormat: (date) => format(date, 'EEE dd/M'),
  eventTimeRangeFormat: (dateRange) => format(dateRange.start, 'HH:mm - '),
  timeGutterFormat: (date) => format(date, 'HH:mm'),
  dayRangeHeaderFormat: (dateRange) =>
    `${format(dateRange.start, 'dd')} - ${format(
      dateRange.end,
      'dd MMM yyyy'
    )}`,
}

const ClinicianCalendar = ({
  events,
  onNavigate,
  onEventSelected,
}: ClinicianCalendarProps) => (
  <Calendar
    localizer={localizer}
    events={events}
    step={5}
    timeslots={3}
    views={[Views.WEEK]}
    defaultView={Views.WEEK}
    scrollToTime={subHours(new Date(), 1)}
    onSelectEvent={onEventSelected}
    onNavigate={onNavigate}
    formats={formats}
    showMultiDayTimes={false}
    className={styles.calendar}
    slotPropGetter={() => ({
      className: styles.timeslot,
    })}
    eventPropGetter={(event) => {
      const { isAdmin } = event.resource

      return {
        className: classNames({
          [styles.event]: true,
          [styles.isAdminEvent]: isAdmin,
        }),
      }
    }}
  />
)

export default ClinicianCalendar
