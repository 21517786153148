import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useFormikContext } from 'formik'
import { Button } from '@babylon/core-ui'
import { ConsumerNetworksTags } from '../../../..'
import messages from './Header.messages'
import styles from './Header.module.scss'

export interface HeaderProps {
  consumerNetworks: ConsumerNetworkMembership[]
  firstName: string
  id: string
  lastName: string
  disableMemberEditForm?: boolean
}

const parseConsumerNetworks = ({
  consumer_network,
  is_preferred,
}: ConsumerNetworkMembership) => ({
  id: consumer_network.id,
  name: consumer_network.name,
  isPreferred: is_preferred,
})

const Header = ({
  consumerNetworks,
  firstName,
  id,
  lastName,
  disableMemberEditForm,
}: HeaderProps) => {
  const { isSubmitting } = useFormikContext()
  const history = useHistory()
  const cancelButtonHandler = () => {
    history.push(`/admin/patients/${id}/memberships`)
  }

  return (
    <header className={styles.header} data-testid="member-edit-header">
      <div>
        <div className={styles.header__name}>
          <span className={styles.header__edit}>
            <FormattedMessage {...messages.edit_profile} />
          </span>
          <span data-testid="member-name">
            <FormattedMessage
              {...messages.patient_name}
              values={{
                firstName: <span>{firstName}</span>,
                lastName: (
                  <span className={styles.header__lastName}>{lastName}</span>
                ),
              }}
            />
          </span>
        </div>
        <ConsumerNetworksTags
          consumerNetworks={consumerNetworks.map(parseConsumerNetworks)}
        />
      </div>
      <div className={styles.header__buttons}>
        <Button
          testId="cancel"
          onClick={cancelButtonHandler}
          intent="secondary"
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button
          testId="save"
          intent="primary"
          type="submit"
          loading={isSubmitting}
          disabled={disableMemberEditForm}
        >
          <FormattedMessage {...messages.save} />
        </Button>
      </div>
    </header>
  )
}

export default Header
