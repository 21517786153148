import React from 'react'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import styles from './Summary.module.scss'
import messages from './Summary.messages'

interface SummaryProps {
  startedAt: string
  endedAt: string
  patientFullname: string
  agents: string[]
  patientId: string
}

const Summary = ({
  startedAt,
  endedAt,
  patientFullname,
  agents,
  patientId,
}: SummaryProps) => (
  <div className={styles.Summary}>
    <table>
      <tbody>
        <tr>
          <td>
            <FormattedMessage {...messages.started} />
          </td>
          <td className={styles.Summary__value}>{startedAt}</td>
        </tr>
        <tr>
          <td>
            <FormattedMessage {...messages.ended} />
          </td>
          <td className={styles.Summary__value}>{endedAt}</td>
        </tr>
      </tbody>
    </table>
    <div className={styles.Summary__column}>
      <table>
        <tbody>
          <tr>
            <td>
              <FormattedMessage {...messages.patient} />
            </td>
            <td
              className={classnames(
                styles.Summary__value,
                styles['Summary__value--user']
              )}
            >
              {patientFullname}
            </td>
          </tr>
          <tr>
            <td>
              <FormattedMessage {...messages.agents} />
            </td>
            <td>
              {agents.map((agent) => (
                <div key={agent} className={styles.Summary__value}>
                  {agent}
                </div>
              ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <table>
      <tbody>
        <tr>
          <td>
            <FormattedMessage {...messages.babylonId} />
          </td>
          <td className={styles.Summary__value}>{patientId}</td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default Summary
