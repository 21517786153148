export const pillsTheme = {
  background: '--medkit-pill-background',
  backgroundHover: '--medkit-pill-background-hover',
  backgroundActive: '--medkit-pill-background-active',
  border: '--medkit-pill-border',
  borderHover: '--medkit-pill-border-hover',
  borderActive: '--medkit-pill-border-active',
  color: '--medkit-pill-color',
  colorHover: '--medkit-pill-color-hover',
  colorActive: '--medkit-pill-color-active',
}
