import React from 'react'
import { SvgProps } from '../../../app'

const FaceToFaceIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
  className,
}: SvgProps) => (
  <svg height={height} width={width} viewBox={`0 0 ${width} ${height}`}>
    <defs>
      <path
        id="face-to-face-a"
        d="M14.75 10.177v.539a.75.75 0 0 1-1.5 0v-.54h-.6a.75.75 0 0 1 0-1.5h.6V8a.75.75 0 1 1 1.5 0v.677h.615a.75.75 0 0 1 0 1.5h-.615zm-7.212-2.55a.75.75 0 0 1-1.5 0v-.96c0-.686.4-1.308 1.023-1.592l6.34-2.899a1.75 1.75 0 0 1 1.472.008l6.14 2.89a1.75 1.75 0 0 1 1.006 1.584v13.58a1.75 1.75 0 0 1-1.75 1.75H17.22a1.75 1.75 0 0 1-1.75-1.75v-3.605a.25.25 0 0 0-.25-.25h-2.437a.75.75 0 0 1 0-1.5h2.437c.967 0 1.75.783 1.75 1.75v3.605c0 .138.112.25.25.25h3.049a.25.25 0 0 0 .25-.25V6.658a.25.25 0 0 0-.144-.226l-6.14-2.89a.25.25 0 0 0-.21-.002L7.684 6.44a.25.25 0 0 0-.147.227v.961zM1.972 21.156c.43-3.449 2.5-5.266 5.994-5.266 3.5 0 5.535 1.827 5.877 5.285a.75.75 0 0 1-.747.824H2.716a.75.75 0 0 1-.744-.843zm6.062-5.514c-1.84 0-3.338-1.463-3.338-3.276 0-1.814 1.499-3.277 3.338-3.277 1.84 0 3.338 1.463 3.338 3.277 0 1.813-1.498 3.276-3.338 3.276z"
      />
    </defs>
    <g fill={color} fillRule="evenodd">
      <mask id="face-to-face-b" fill="#fff">
        <use href="#face-to-face-a" />
      </mask>
      <use fillRule="nonzero" href="#face-to-face-a" />
      <g className={className} mask="url(#face-to-face-b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

export default FaceToFaceIcon
