import React from 'react'

const Commercial = ({
  width = 20,
  height = 18,
  color = 'currentColor',
}: SvgProps) => (
  <svg width={width} height={height} viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M9.818 0c2.057 0 3.775 1.45 4.135 3.368h4.933a.75.75 0 0 1 .75.75v11.79a.75.75 0 0 1-.75.75H.75a.75.75 0 0 1-.75-.75V4.118a.75.75 0 0 1 .75-.75h4.933C6.043 1.448 7.76 0 9.818 0zM7.772 9.078H1.5v6.08h16.636v-6.08h-6.273v1.777a.75.75 0 0 1-.75.75h-2.59a.75.75 0 0 1-.75-.75l-.001-1.777zm2.592-.841H9.273v1.868h1.09V8.237zm7.772-3.369H1.5v2.71h6.272v-.091a.75.75 0 0 1 .75-.75h2.592a.75.75 0 0 1 .75.75l-.001.091h6.273v-2.71zM9.818 1.5c-1.228 0-2.26.79-2.592 1.868h5.184C12.077 2.29 11.045 1.5 9.818 1.5z"
      />
    </g>
  </svg>
)

export default Commercial
