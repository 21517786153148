import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Alert } from '@babylon/core-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { HumanitySyncStatusEnum } from '@babylon/graphql-middleware-types'
import styles from './SyncCanceller.module.css'
import CancelHumanitySyncMutation, {
  CancelHumanitySyncMutationResult,
  CancelHumanitySyncMutationVariables,
} from './CancelHumanitySyncMutation'

interface SyncCancellerAlertDataProps {
  type?: 'warning' | 'error' | 'success'
  title?: string
  message?: string
  visible: boolean
}

export interface SyncCancellerProps {
  syncStatus: HumanitySyncStatusEnum
  syncId: string
  callback?: () => void
  disabled?: boolean
}

const SyncCanceller = ({
  syncStatus,
  syncId,
  callback,
  disabled,
}: SyncCancellerProps) => {
  const [alertData, setAlertData] = useState<SyncCancellerAlertDataProps>({
    type: 'warning',
    title: '',
    message: '',
    visible: false,
  })

  const [cancelSync, { loading }] = useMutation<
    CancelHumanitySyncMutationResult,
    CancelHumanitySyncMutationVariables
  >(CancelHumanitySyncMutation, {
    onCompleted: () => {
      setAlertData({
        type: 'success',
        title: 'Cancel succeeded',
        message: 'Cancellation succeeded, the sync was stopped',
        visible: true,
      })

      if (callback) {
        callback()
      }
    },
    onError: () => {
      setAlertData({
        type: 'error',
        title: 'Error requesting cancel',
        message: 'The request to cancel the sync has failed',
        visible: true,
      })
    },
  })

  const canCancel = (status: HumanitySyncStatusEnum) =>
    [
      HumanitySyncStatusEnum.Pending,
      HumanitySyncStatusEnum.InProgress,
    ].includes(status)

  const handleCancel = () => {
    setAlertData({ visible: false })

    cancelSync({
      variables: {
        id: syncId,
      },
    })
  }

  return (
    <div className={styles.CancelButtonContainer}>
      <Button
        data-testid="cancel-button"
        intent="error"
        fill={false}
        loading={loading}
        disabled={disabled || loading || !canCancel(syncStatus)}
        icon={<FontAwesomeIcon icon={faBan} />}
        onClick={() => handleCancel()}
        className={styles.CancelButton}
      >
        Cancel sync
      </Button>

      {alertData.visible && (
        <Alert
          data-testid="alert"
          className={styles.AlertBox}
          title={alertData.title}
          intent={alertData.type}
          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
        >
          {alertData.message}
        </Alert>
      )}
    </div>
  )
}

export default SyncCanceller
