import React from 'react'
import { useFormikContext } from 'formik'
import { FormikDropdownSelect } from '@babylon/core-ui'
import ServiceTypeField from './ServiceTypeField'
import ConsultationTypes, {
  ConsultationTypeName,
} from '../../../types/ConsultationTypes'

interface Props {
  isEditing: boolean
}

const LABEL = 'Default medium'

const DefaultMediumField = ({ isEditing }: Props) => {
  const { values } = useFormikContext<{
    allowed_mediums: ConsultationTypeName[]
    preferred_medium: ConsultationTypeName
  }>()

  return isEditing ? (
    <FormikDropdownSelect
      id="preferred_medium"
      name="preferred_medium"
      label={LABEL}
      options={values.allowed_mediums?.map((medium) => ({
        key: medium,
        value: ConsultationTypes[medium],
      }))}
    />
  ) : (
    <ServiceTypeField
      label={LABEL}
      value={ConsultationTypes[values.preferred_medium]}
    />
  )
}

export default DefaultMediumField
