import { Heading } from '@babylon/core-ui'
import { ButtonSimple, ButtonVariant, Icon } from '@babylon/medkit'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useConversation } from '@babylon/atlas.js'
import Modal from '../Modal'
import styles from './styles.module.css'

type DeleteModalProps = {
  visible: boolean
  onRequestClose: () => void
  conversationId: string | undefined
  message: string
  attachments: NonNullable<ReturnType<typeof useConversation>['attachments']>
  messageId?: string
}

const extractAttachmentDataFromMessage = (attachmentUrl: string) => {
  const regex = /\[(.*?)\]\(.*?\/([^/]+)\)$/
  const matches = regex.exec(attachmentUrl)

  return matches ? [matches[1], matches[2]] : undefined
}

const DeleteAttachmentModal = ({
  visible,
  onRequestClose,
  conversationId,
  message,
  attachments,
  messageId,
}: DeleteModalProps) => {
  const [fileName, setFileName] = useState<string | undefined>(undefined)
  const [attachmentId, setAttachmentId] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    const attachmentData = extractAttachmentDataFromMessage(message)

    if (attachmentData) {
      setFileName(attachmentData[0])
      setAttachmentId(attachmentData[1])
    }
  }, [message])

  const handleDeleteAttachment = () => {
    if (!conversationId || !attachmentId) {
      throw new Error(
        'Missing conversationId or attachmentId, should not happen'
      )
    }

    attachments
      .delete(conversationId, attachmentId, messageId)
      .then(() => {
        toast.success('File deleted successfully!')
      })
      .catch(() => {
        toast.error('Error deleting file, please try again later!')
      })
      .finally(() => {
        onRequestClose()
      })
  }

  const handlerRequestClose = () => {
    onRequestClose()
  }

  return (
    <Modal
      visible={visible}
      onRequestClose={handlerRequestClose}
      Header={<Heading level="h2">Delete File</Heading>}
      Footer={
        <div className={styles.showDeleteAttachmentButtons}>
          <ButtonSimple
            onClick={handleDeleteAttachment}
            variant={ButtonVariant.destructive}
          >
            <Icon
              title="Remove file"
              icon="Delete"
              height={15}
              width={15}
              fill="coral"
            />
            &nbsp;Yes, delete
          </ButtonSimple>
          <ButtonSimple onClick={handlerRequestClose}>No</ButtonSimple>
        </div>
      }
      showCloseIcon={false}
    >
      <div className={styles.showDeleteAttachmentModalBody}>{fileName}</div>
    </Modal>
  )
}

export default DeleteAttachmentModal
