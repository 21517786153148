import { defineMessages } from 'react-intl'

const baseId = 'member_search'

export default defineMessages({
  snack_transfer_ownership_success: {
    id: `${baseId}.snack_transfer_ownership_success`,
    defaultMessage: 'Account owner changed',
  },
  snack_invitation_resent_success: {
    id: `${baseId}.snack_invitation_resent_success`,
    defaultMessage: 'Invitation resent',
  },
})
