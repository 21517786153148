import React from 'react'
import { Modal } from '@babylon/core-ui'
import styles from './SideOverlay.module.css'
import type { SideOverlayProps } from './SideOverlay.types'

const SideOverlay = ({ title, children, onClose }: SideOverlayProps) => (
  <Modal
    className={styles.dialog}
    overlayClassName={styles.overlay}
    includeClose={false}
    onClose={onClose}
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
    testId="side-overlay"
    open
  >
    <div className={styles.header}>
      <h2 className={styles.heading}>{title}</h2>

      <button type="button" onClick={onClose} className={styles.closeButton}>
        &times;
        <span className={styles.hidden}>Close</span>
      </button>
    </div>

    {children}
  </Modal>
)

export default SideOverlay
