import { useAtlas, useAtlasStore, useMemberMessages } from '@babylon/atlas.js'
import React, { useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import { Spinner } from '@babylon/medkit'
import { useIntl } from '@babylon/intl'
import { envVar } from '@babylon/babylon-env'
import {
  CommaList,
  Pill,
  RelativeDateTimeWithOffset,
  WithTitle,
} from '../utils'
import { Panel, PanelRow } from '../panel'
import usePatientProfile from '../../pages/LiveConversationPage/usePatientProfile'
import { Message } from '../../pages/LiveConversationPage/components'
import styles from './conversationDetail.module.css'
import { Button } from '../button'
import { useLazyAgentName } from '../../hooks/useLazyAgentName'

interface Props {
  memberId: string
  conversationId: string
  onClose?: () => void
}

// eslint-disable-next-line import/prefer-default-export
export const ConversationDetail = ({
  memberId,
  conversationId,
  onClose,
}: Props) => {
  const { conversation, messages, state } = useMemberMessages(
    memberId,
    conversationId
  )
  const agentNameFetch = useLazyAgentName()
  const { patientProfile } = usePatientProfile(memberId)
  const bots = useAtlasStore((s) => s.bots)
  const countryIsoCode = envVar('ENVIRONMENT_COUNTRY_ISO_CODE')
  const { formatTime } = useIntl()
  const atlas = useAtlas()
  useEffect(() => {
    if (!bots || bots.status === 'idle') {
      atlas.memberHistory.loadBots()
    }
  }, [atlas, bots])

  if (!conversation) {
    return <Spinner variant="primary" />
  }

  if (state === 'error') {
    return <>Error: ${messages}</>
  }

  const notAMember = ({ id }: { id?: string }) => id !== memberId

  const agent = conversation.participants.find(notAMember)
  const agents = conversation.participants.filter(notAMember)
  const sortedMessages = messages.sort((a, b) =>
    a.sentAt.localeCompare(b.sentAt)
  )
  const messagesAreLoading = state === 'loading'

  const ChatInfo = (
    <dl>
      <dt>Status</dt>
      <dd>
        <Pill>{conversation.state}</Pill>
      </dd>
      <dt>ID</dt>
      <dd>{conversation.id}</dd>
      <dt>Participants</dt>
      <dd>
        <CommaList
          items={[
            patientProfile ? (
              patientProfile.familyName
            ) : (
              <Spinner variant="primary" />
            ),
            ...agents.map(({ id, type }) => agentNameFetch(id, type)),
          ]}
        />
      </dd>
      {conversation.tags.length > 0 && (
        <>
          <dt>Tags</dt>
          <dd>
            <CommaList items={conversation.tags} />
          </dd>
        </>
      )}
    </dl>
  )

  const printableConversation = () => {
    const printableContent = (
      <div>
        <h3>{agentNameFetch(agent?.id, agent?.type)}</h3>
        <p>
          Started at:{' '}
          <RelativeDateTimeWithOffset datetime={conversation.started_at} />
        </p>
        {ChatInfo}
        <div style={{ marginTop: '10px' }}>
          {sortedMessages.map((message) => (
            <div
              key={message.messageId}
              style={{ margin: '3px', paddingTop: '10px' }}
            >
              <span>
                {formatTime(message.sentAt, {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: countryIsoCode !== 'GBR',
                })}{' '}
              </span>{' '}
              <span>{message.senderId !== memberId ? 'Agent' : 'Member'} </span>
              {': '}
              <span>{message.message} </span>
            </div>
          ))}
        </div>
      </div>
    )

    return ReactDOMServer.renderToString(printableContent)
  }

  const printConversation = () => {
    const printWindow = window.open('', '_blank', 'sandbox')
    const additionalContent = document.createElement('div')
    additionalContent.innerHTML = printableConversation()

    if (!printWindow) {
      return
    }

    printWindow.document.title = 'Admin Portal - Conversation Detail'
    printWindow.document.body.appendChild(additionalContent)
    printWindow.print()
  }

  return (
    <Panel>
      <PanelRow type="header" data-testid="conversation-view-panel-head">
        <WithTitle title={agentNameFetch(agent?.id, agent?.type)}>
          Started{' '}
          <RelativeDateTimeWithOffset datetime={conversation.started_at} />
        </WithTitle>
        {onClose && (
          <Button onClick={onClose} className={styles.noPrint}>
            &times;
          </Button>
        )}
      </PanelRow>
      <PanelRow data-testid="conversation-view-panel-details">
        {ChatInfo}
      </PanelRow>
      <PanelRow className={styles.messagesContainer}>
        {messagesAreLoading ? (
          <Spinner variant="primary" />
        ) : (
          sortedMessages.map((message) => (
            <Message
              key={message.messageId}
              message={message}
              mine={message.senderId !== memberId}
              attachments={conversation.attachments}
              conversationId={conversation.id}
            />
          ))
        )}
      </PanelRow>
      <PanelRow>
        <Button
          disabled={messagesAreLoading}
          className={styles.noPrint}
          onClick={() => printConversation()}
        >
          Download this conversation
        </Button>
      </PanelRow>
    </Panel>
  )
}
