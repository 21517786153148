import { useBabylonUser } from '@babylon/babylon-user'
import { useEffect } from 'react'
import { useProductConfigProps } from './types'

const usePreFetchConfig = async ({ getConfig }: useProductConfigProps) => {
  const { partnerIds: partnerList, uuid } = useBabylonUser()

  useEffect(() => {
    const fetchData = async () => {
      const data = await getConfig(partnerList, uuid)
      localStorage.setItem(`product-config-${uuid}`, JSON.stringify(data || {}))
    }

    fetchData()
  }, [getConfig, partnerList, uuid])
}

export default usePreFetchConfig
