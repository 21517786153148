import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { PreferredConsumerNetwork } from '../hooks/usePreferredNetwork/usePreferredNetwork'
import messages from './PatientNetworkFootnote.messages'

import styles from './PatientNetworkFootnote.module.css'

type PatientNetworkFootnoteProps = {
  patientNetwork?: PreferredConsumerNetwork
}

const PatientNetworkFootnote = ({
  patientNetwork,
}: PatientNetworkFootnoteProps) => {
  const fm = useFormatMessage()

  return (
    <div className={styles.patientNetworkFootnote}>
      {fm(messages.patient_network_footnote)}{' '}
      {patientNetwork?.consumer_network?.name} ({patientNetwork?.id})
    </div>
  )
}

export default PatientNetworkFootnote
