import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Close } from '@babylon/icons'
import { useMutation } from '@apollo/client'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'
import { Button, Cell, Grid, Modal } from '@babylon/core-ui'
import messages from '../ConsumerNetworksCard.messages'
import styles from './SubscriptionActionModal.module.scss'
import { CANCEL_PATIENT_SUBSCRIPTION } from '../queries'
import { useSnackBar } from '../../../../../../hooks'

type Props = {
  patientId: string
  subscriptionId: string
}

const SubcriptionActionModal = ({ patientId, subscriptionId }: Props) => {
  const fm = useFormatMessage()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { setSnackbarMessage } = useSnackBar()
  const [cancelPatientSubscription] = useMutation(CANCEL_PATIENT_SUBSCRIPTION)

  const cancelSubscription = async () => {
    try {
      await cancelPatientSubscription({
        variables: {
          patientId,
          subscriptionId,
        },
        refetchQueries: ['AP_ConsumerNetworks'],
      })
      setSnackbarMessage(fm(messages.unsub_snackbar_success), null, 'success')
    } catch (error) {
      setSnackbarMessage(fm(messages.unsub_snackbar_failure), null, 'error')
    } finally {
      setIsModalOpen(false)
    }
  }

  return (
    <div className={styles.UnsubscribeButton}>
      <Button
        intent="secondary"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        {fm(messages.unsub_button_label)}
      </Button>

      <Modal
        label={fm(messages.unsub_modal_title)}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        includeClose={false}
      >
        <div className={styles.ModalContent}>
          <Grid columns={6}>
            <Cell width={4}>
              <h2>{fm(messages.unsub_modal_title)}</h2>
            </Cell>
            <Cell center left={6}>
              <button
                type="button"
                className={styles.ModalContent__closeButton}
                onClick={() => setIsModalOpen(false)}
              >
                <Close />
              </button>
            </Cell>
          </Grid>
          <p>{fm(messages.unsub_modal_body)}</p>
          <div className={styles.ModalContent__actions}>
            <ButtonSimple
              variant={ButtonVariant.secondary}
              onClick={() => {
                cancelSubscription()
              }}
            >
              {fm(messages.unsub_modal_submit_label)}
            </ButtonSimple>
            <ButtonSimple
              variant={ButtonVariant.primary}
              onClick={() => setIsModalOpen(false)}
            >
              {fm(messages.unsub_modal_cancel_label)}
            </ButtonSimple>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SubcriptionActionModal
