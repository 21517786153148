import React from 'react'

import { Heading, Text } from '@babylon/core-ui'

interface Props {
  serviceType?: string
  reason?: string
  notes?: string
}

const TimelineItemDetails = ({ serviceType, reason, notes }: Props) => (
  <Text tag="div" color="grey-type">
    {serviceType && (
      <p>
        <Heading level="h3">Appointment type</Heading>
        <Text>{serviceType}</Text>
      </p>
    )}
    {reason && (
      <p>
        <Heading level="h3">Reason for appointment</Heading>
        <Text>{reason}</Text>
      </p>
    )}
    {notes && (
      <p>
        <Heading level="h3">Notes</Heading>
        <Text>{notes}</Text>
      </p>
    )}
  </Text>
)

export default TimelineItemDetails
