import React, { ReactNode, useRef, useState } from 'react'
import { useQuery, ApolloError } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { Button } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import {
  ErrorBoundary,
  PopupDialogProps,
  EntriesTimeline,
  DrawerView,
  MemberOpsModuleName,
} from '../../../../../../..'

import InfiniteScroller from '../../../../../../InfiniteScroller'
import Messages from '../Messages'
import { PATIENT_QUERY } from './queries'
import styles from './EntriesHistory.module.scss'
import messages from './EntriesHistory.messages'

export interface EntriesHistoryProps
  extends Omit<PopupDialogProps, 'children'> {
  heading: string
  patientId: string
  printable?: boolean
  transcriptHeadingComponent?: (entry: Entry) => ReactNode
  loading?: boolean
  hasMore?: boolean
  loadMore: () => void
  entries: Entry[]
  error?: ApolloError
  onEntryClick?: () => void
}

export interface EntriesHistoryHeaderProps {
  title: string
  toggleVisible: () => void
}

const EntriesHistory = ({
  toggleVisible,
  visible,
  patientId,
  heading,
  printable,
  transcriptHeadingComponent,
  loading,
  hasMore,
  loadMore,
  entries,
  error,
  onEntryClick,
}: EntriesHistoryProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })
  const { data } = useQuery(PATIENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { id: patientId },
  })
  const patient = data?.patient
  const [selectedEntry, selectEntry] = useState<Entry | null>(null)

  const ref = useRef<HTMLDivElement | null>(null)

  const { getProp } = useProductConfig()
  const chatTranscriptPrintEnabled = getProp(
    'memberOperations',
    'chatTranscriptPrintEnabled'
  )

  return (
    <DrawerView
      title={heading}
      ref={ref}
      visible={visible}
      toggleVisible={toggleVisible}
    >
      {!selectedEntry && (
        <ErrorBoundary>
          <InfiniteScroller
            scrollParentRef={ref.current}
            loadMore={loadMore}
            hasMore={hasMore}
            hasResults={loading || !!entries?.length}
            error={error}
          >
            <EntriesTimeline
              onEntryClick={(entry) => {
                if (onEntryClick) {
                  onEntryClick()
                }

                selectEntry(entry)
              }}
              entries={entries || []}
            />
          </InfiniteScroller>
        </ErrorBoundary>
      )}
      {selectedEntry && (
        <ErrorBoundary>
          <Messages
            heading={
              transcriptHeadingComponent &&
              transcriptHeadingComponent(selectedEntry)
            }
            scrollParentRef={ref.current}
            handleBack={() => selectEntry(null)}
            conversationId={selectedEntry.id}
            userId={patient?.uuid}
            patient={patient}
          />
          {printable && chatTranscriptPrintEnabled && (
            <div className={styles.ConversationActions}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  trackClick({
                    elementName: 'print-conversation-btn',
                    elementType: TrackingElementType.button,
                  })
                }}
                href={`/admin/patients/${patientId}/conversations/${selectedEntry.id}?nonav=true`}
              >
                <Button className={styles.ConversationActions__printButton}>
                  <FormattedMessage {...messages.printConversation} />
                </Button>
              </a>
            </div>
          )}
        </ErrorBoundary>
      )}
    </DrawerView>
  )
}

export default EntriesHistory
