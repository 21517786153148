import React, { useState, useRef, useCallback } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'

import { envUrl } from '@babylon/babylon-env'
import { Button } from '@babylon/babylon-forms/forms'

import requestIdGenerator from '@/util/requestIdGenerator'

import styles from './styles.module.scss'

const uploadFile = (url, data, setState) => {
  const cookie = JSON.parse(Cookies.get('autologin_info')) || {}
  const { csrf_token } = cookie
  axios({
    method: 'post',
    url,
    data,
    headers: {
      'X-Security-Token': csrf_token,
      'babylon-request-id': requestIdGenerator.generate(),
    },
    withCredentials: true,
    onUploadProgress: (progressEvent) => {
      const progress = progressEvent.lengthComputable
        ? (progressEvent.loaded * 100) / progressEvent.total
        : 0
      setState({ progress, error: false, done: false })
    },
  })
    .then((response) => {
      if (response.status >= 400) {
        setState({ progress: false, error: true, done: false })
      } else {
        setState({ progress: false, error: false, done: true })
      }
    })
    .catch(() => {
      setState({ progress: false, error: true, done: false })
    })
}

const useMergeState = (initialValue) => {
  const [state, setState] = useState(initialValue)

  return [
    state,
    (updatedValues) =>
      setState((prevState) => ({ ...prevState, ...updatedValues })),
  ]
}

const UploadTemplates = () => {
  const [{ progress, done, error, inputKey }, setState] = useMergeState({
    progress: false,
    done: false,
    error: false,
    inputKey: 1,
  })
  const inputElement = useRef()

  const handleUploadClick = useCallback(
    (event) => {
      event.preventDefault()
      inputElement.current.click()
    },
    [inputElement]
  )

  const handleChange = useCallback(() => {
    const formData = new FormData()
    formData.append('file', inputElement.current.files[0])
    const url = envUrl('NHS_REPORT_UPLOAD_URL')
    uploadFile(url, formData, setState)
  }, [inputElement, setState])

  const handleReset = useCallback(() => {
    setState({
      progress: false,
      error: false,
      done: false,
      inputKey: inputKey + 1,
    })
  }, [setState, inputKey])

  const PrescriptionSquadLink = () => (
    <a
      href="https://babylonhealth.slack.com/messages/prescription-squad"
      target="_blank"
      rel="noopener noreferrer"
    >
      #prescription-squad
    </a>
  )

  return (
    <div className={styles.prescriptionsView}>
      <p>
        Please use this page to re-upload the repeat templates report when the{' '}
        <strong>automated process fails</strong>. <br /> Should you need to do
        this, please contact the <PrescriptionSquadLink /> (on slack) for
        assistance before starting.
      </p>
      <div className={styles.upload}>
        <input
          key={inputKey}
          type="file"
          style={{ display: 'none' }}
          ref={inputElement}
          onChange={handleChange}
        />
        <Button
          type="button"
          small
          onClick={handleUploadClick}
          disabled={done || error}
        >
          Upload
        </Button>
        {progress !== false ? (
          <div className={styles.progressBar}>
            <div style={{ width: `${progress}%` }} />
          </div>
        ) : null}
      </div>
      {done ? (
        <div className={styles.ok}>
          The upload has been successful.{' '}
          <Button small onClick={handleReset}>
            OK
          </Button>
        </div>
      ) : null}
      {error ? (
        <div className={styles.error}>
          The upload has failed. Please contact the <PrescriptionSquadLink />{' '}
          <Button small onClick={handleReset}>
            OK
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export default UploadTemplates
