import React from 'react'

import { FormikDropdownSelect } from '@babylon/core-ui'
import ServiceTypeField from './ServiceTypeField'

interface Props {
  isEditing: boolean
  value?: string
}

const LABEL = 'Grouping'

const GroupingField = ({ isEditing = false, value = '' }: Props) =>
  isEditing ? (
    <FormikDropdownSelect
      id="grouping"
      name="grouping"
      label={LABEL}
      options={[
        { key: 'NURSE', value: 'Nurse' },
        { key: 'GP', value: 'GP' },
      ]}
    />
  ) : (
    <ServiceTypeField label={LABEL} value={value} />
  )

export default GroupingField
