import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose, withHandlers, withStateHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'

import withSpinner from '@/util/withSpinner'
import withErrorMessage from '@/util/withErrorMessage'
import graphqlWithProps from '@/util/graphqlWithProps'
import showConfirmDialog from '@/components/ConfirmDialog'

import { AdminDrugAlerts, DeleteAdminDrugAlert } from './queries'

import AlertsView from './AlertsView'

// DATA

export const queryOptions = {
  options: {
    variables: {
      size: 10,
      page: 0,
      query: '',
      sort: 'activeIngredient.name,asc',
    },
  },
}

const withData = compose(
  graphqlWithProps(AdminDrugAlerts, queryOptions),
  graphql(DeleteAdminDrugAlert, { name: 'deleteAdminDrugAlert' })
)

// STATE

export const defaultState = {
  spinnerType: null,
}

export const setSpinnerType = (state) => (spinnerType) => ({
  ...state,
  spinnerType,
})

const withState = withStateHandlers(defaultState, {
  setSpinnerType,
})

// ACTIONS

export const handlePageSizeChange = ({ data, setSpinnerType }) => (size) => {
  setSpinnerType('page-size')
  data.updateVariables({ size, page: 0 })
}

export const handlePageChange = ({ data, setSpinnerType }) => (page) => {
  setSpinnerType('pagination')
  data.updateVariables({ page })
}

export const handleEditClick = ({ history }) => (event, alert) => {
  history.push(`/admin/drugs/alerts/edit/${alert.id}`)
}

export const handleAddClick = ({ history }) => () => {
  history.push('/admin/drugs/alerts/add')
}

export const handleAuditClick = ({ history }) => (event, alert) => {
  history.push(
    `/admin/drugs/alerts/audit/${alert.activeIngredient.activeIngredientId}`
  )
}

const showConfirmDeleteDialog = showConfirmDialog(
  'Are you sure you want to delete the drug alert?'
)

export const handleDeleteClick = ({
  client,
  deleteAdminDrugAlert,
  errorAlert,
}) => (event, { id }) => {
  showConfirmDeleteDialog().then(
    // yes
    () =>
      deleteAdminDrugAlert({
        variables: { id },
      })
        .then(() => {
          client.resetStore()
        })
        .catch(() => {
          errorAlert()
        }),
    // no
    () => null
  )
}

export const updateVariables = debounce(
  (data, query) => data.updateVariables({ query, page: 0 }),
  500
)

export const handleSearchChange = ({ data }) => (event, query) => {
  updateVariables(data, query)
}

const columnNameToSortLabel = (column) =>
  column === 'activeIngredient' ? 'activeIngredient.name' : column

export const handleSort = ({ data, setSpinnerType }) => (column, direction) => {
  setSpinnerType('sort')
  data.updateVariables({
    sort: `${columnNameToSortLabel(column)},${direction}`,
    page: 0,
  })
}

const withActions = withHandlers({
  handlePageSizeChange,
  handlePageChange,
  handleEditClick,
  handleAuditClick,
  handleAddClick,
  handleDeleteClick,
  handleSearchChange,
  handleSort,
})

// LOADER

export const isLoading = (props) => !props.data || !props.data.adminDrugAlerts

const withLoader = withSpinner(isLoading)

export default compose(
  withRouter,
  withData,
  withState,
  withApollo,
  withErrorMessage(),
  withActions,
  withLoader
)(AlertsView)
