import { gql } from '@apollo/client'

export const CHAT_QUERY = gql`
  query AP_ChatHistory(
    $userId: ID!
    $offset: Int
    $limit: Int
    $order: String
    $type: String
  ) {
    chatscriptConversations(
      userId: $userId
      offset: $offset
      limit: $limit
      order: $order
      type: $type
    ) {
      more
      conversations {
        id
        preview
        result {
          outcome
          type
        }
        startedAt
        status
        type
      }
    }
  }
`

export const PATIENT_QUERY = gql`
  query AP_Patient($id: ID!) {
    patient(id: $id) {
      id
      uuid
      first_name
      last_name
    }
  }
`
