import { defineMessages } from 'react-intl'

const baseId = 'consultation_note'

export default defineMessages({
  heading: {
    id: `${baseId}.heading`,
    defaultMessage: 'Consultation',
  },
  no_entries_placeholder: {
    id: `${baseId}.no_entries_placeholder`,
    defaultMessage: 'No entries',
  },
  told_by_patient: {
    id: `${baseId}.told_by_patient`,
    defaultMessage: 'What you told us',
  },
  explored_by_gp: {
    id: `${baseId}.explored_by_gp`,
    defaultMessage: 'What we explored',
  },
  assessment: {
    id: `${baseId}.assessment`,
    defaultMessage: 'Our assessment',
  },
  treatment_plan: {
    id: `${baseId}.treatment_plan`,
    defaultMessage: 'What you should do',
  },
  fallback_plan: {
    id: `${baseId}.fallback_plan`,
    defaultMessage: 'What to do if things do not improve',
  },
  prescriptions: {
    id: `${baseId}.prescriptions`,
    defaultMessage: 'Prescriptions',
  },
  referrals: {
    id: `${baseId}.referrals`,
    defaultMessage: 'Referrals',
  },
  drug_name_header: {
    id: `${baseId}.drug_name`,
    defaultMessage: 'Drug name',
  },
  drug_quantity_header: {
    id: `${baseId}.header_drug_quanity`,
    defaultMessage: 'Quantity',
  },
  drug_directions_header: {
    id: `${baseId}.header_drug_directions`,
    defaultMessage: 'Directions',
  },
  refferals_category_header: {
    id: `${baseId}.header_refferals_category`,
    defaultMessage: 'Category',
  },
  refferals_specialist_header: {
    id: `${baseId}.header_refferals_specialist`,
    defaultMessage: 'Specialism',
  },
})
