/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type RequestSyncShiftsReportMutationVariables = Types.Exact<{
  input: Types.RequestSyncShiftsReportInput
}>

export type RequestSyncShiftsReportMutation = {
  requestSyncShiftsReport: Pick<Types.ShiftsCsvDownload, 'reportDownloadUrl'>
}

export const RequestSyncShiftsReportDocument = gql`
  mutation requestSyncShiftsReport($input: RequestSyncShiftsReportInput!) {
    requestSyncShiftsReport(input: $input) {
      reportDownloadUrl
    }
  }
`
export type RequestSyncShiftsReportMutationFn = Apollo.MutationFunction<
  RequestSyncShiftsReportMutation,
  RequestSyncShiftsReportMutationVariables
>
export function useRequestSyncShiftsReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestSyncShiftsReportMutation,
    RequestSyncShiftsReportMutationVariables
  >
) {
  return Apollo.useMutation<
    RequestSyncShiftsReportMutation,
    RequestSyncShiftsReportMutationVariables
  >(RequestSyncShiftsReportDocument, baseOptions)
}
export type RequestSyncShiftsReportMutationHookResult = ReturnType<
  typeof useRequestSyncShiftsReportMutation
>
export type RequestSyncShiftsReportMutationResult = Apollo.MutationResult<RequestSyncShiftsReportMutation>
export type RequestSyncShiftsReportMutationOptions = Apollo.BaseMutationOptions<
  RequestSyncShiftsReportMutation,
  RequestSyncShiftsReportMutationVariables
>
