// eslint-disable-next-line import/prefer-default-export
export enum MemberOpsModuleName {
  alertList = 'alert-list',
  commentsList = 'comments-list',
  memberEditForm = 'member-edit-form',
  memberView = 'member-view',
  profilesHeader = 'member-profiles-header',
  consumerNetworks = 'member-profiles-consumer-networks',
  profileAccountServices = 'member-profiles-account-services',
  timeline = 'member-profile-timeline',
  membersSearchView = 'members-search-view',
  searchForm = 'search-form',
  screenPopModal = 'support-interaction-modal',
  memberRelatedPersons = 'member-related-persons',
}
