import { defineMessages } from 'react-intl'

const baseId = 'app_bar'

export default defineMessages({
  logo_alt: {
    id: `${baseId}.logo_alt`,
    defaultMessage: 'eMed logo',
  },
})
