import { defineMessages } from 'react-intl'

const baseId = 'timeline'

export default defineMessages({
  upcoming_activities: {
    id: `${baseId}.upcoming_activities`,
    defaultMessage: 'Upcoming activity',
  },
  upcoming_activities_tooltip: {
    id: `${baseId}.upcoming_activities_tooltip`,
    defaultMessage: 'Displays any future activity such as future appointments',
  },
  no_upcoming_activities: {
    id: `${baseId}.no_upcoming_activities`,
    defaultMessage: 'No upcoming activity',
  },
  outstanding_activities: {
    id: `${baseId}.outstanding_activities`,
    defaultMessage: 'Outstanding activity',
  },
  no_outstanding_activities: {
    id: `${baseId}.no_outstanding_activities`,
    defaultMessage: 'No outstanding activity',
  },
  outstanding_activities_tooltip: {
    id: `${baseId}.outstanding_activities_tooltip`,
    defaultMessage:
      'Displays any activity that needs action by the patient or Babylon, for example appointment invites',
  },
  patient_history: {
    id: `${baseId}.patient_history`,
    defaultMessage: 'Past activity',
  },
  patient_history_tooltip: {
    id: `${baseId}.patient_history_tooltip`,
    defaultMessage: `Displays chronologically the patient’s past activity with
    Babylon, for example past consultations`,
  },
  no_patient_history: {
    id: `${baseId}.no_patient_history`,
    defaultMessage: 'No past activity',
  },
  view_all_patient_history: {
    id: `${baseId}.view_all_patient_history`,
    defaultMessage: 'View more patient history',
  },
  consultation_note_heading: {
    id: `${baseId}.consultation_note_heading`,
    defaultMessage: 'Consultation',
  },
  media: {
    id: `${baseId}.media`,
    defaultMessage: 'Media',
  },
  told_by_patient: {
    id: `${baseId}.told_by_patient`,
    defaultMessage: 'What you told us',
  },
  assessment: {
    id: `${baseId}.assessment`,
    defaultMessage: 'Our assessment',
  },
  treatment_plan: {
    id: `${baseId}.treatment_plan`,
    defaultMessage: 'What you should do',
  },
  fallback_plan: {
    id: `${baseId}.fallback_plan`,
    defaultMessage: 'What to do if things do not improve',
  },
  prescriptions: {
    id: `${baseId}.prescriptions`,
    defaultMessage: 'Prescriptions',
  },
  referrals: {
    id: `${baseId}.referrals`,
    defaultMessage: 'Referrals',
  },
  allowed_mediums_physical: {
    id: `${baseId}.allowed_mediums_physical`,
    defaultMessage: 'Face-to-face',
  },
  allowed_mediums_voice: {
    id: `${baseId}.allowed_mediums_voice`,
    defaultMessage: 'Voice',
  },
  allowed_mediums_video: {
    id: `${baseId}.allowed_mediums_video`,
    defaultMessage: 'Video',
  },
})
