import React from 'react'
import { useQuery } from '@apollo/client'

import { FormikMultiTagSelect } from '../../../components'

import GetProfessionsQuery from './GetProfessionsQuery'

const sortProfessions = (professions: Profession[] = []) =>
  [...professions]?.sort((a, b) => a.name.localeCompare(b.name))

export default function ProfessionsSelector() {
  const { data, loading } = useQuery(GetProfessionsQuery)

  const sortedOptions = sortProfessions(data?.professions)

  return (
    <FormikMultiTagSelect
      label="Profession"
      field="profession"
      disabled={loading}
      options={sortedOptions}
    />
  )
}
