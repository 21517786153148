import { defineMessages } from 'react-intl'

const baseId = 'entries_timeline'

export default defineMessages({
  recent: {
    id: `${baseId}.recent`,
    defaultMessage: 'Most Recent',
  },
  recent_tooltip: {
    id: `${baseId}.recent_tooltip`,
    defaultMessage: 'Conversations within the current calendar month',
  },
  lastMonth: {
    id: `${baseId}.lastMonth`,
    defaultMessage: 'Last month',
  },
  lastMonth_tooltip: {
    id: `${baseId}.lastMonth_tooltip`,
    defaultMessage: 'Conversations within the previous calendar month',
  },
  history: {
    id: `${baseId}.history`,
    defaultMessage: 'History',
  },
  history_tooltip: {
    id: `${baseId}.history_tooltip`,
    defaultMessage: 'All other historical conversations',
  },
  no_conversation_recent: {
    id: `${baseId}.no_conversation_recent`,
    defaultMessage: 'No recent conversations',
  },
  no_conversation_lastMonth: {
    id: `${baseId}.no_conversation_lastMonth`,
    defaultMessage: 'No conversations last month',
  },
  no_conversation_history: {
    id: `${baseId}.no_conversation_history`,
    defaultMessage: 'No historic conversations',
  },
})
