import React from 'react'
import { useHistory } from 'react-router'
import { useMutation } from '@apollo/client'
import { Modal } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import messages from './SupportInteractionModal.messages'
import styles from './SupportInteractionModal.module.scss'
import LinkSupportInteractionQuery from './linkSupportInteractionQuery'
import { MemberOpsModuleName } from '../../../../..'

interface Props {
  patientUuid: string
  transactionId?: string
  transactionType?: string
  isOpen: boolean
  onClose: () => void
}

const SupportInteractionModal = ({
  patientUuid,
  transactionId,
  transactionType,
  isOpen = false,
  onClose,
}: Props) => {
  const fm = useFormatMessage()
  const history = useHistory()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.screenPopModal,
  })

  const [linkSupportInteraction] = useMutation(LinkSupportInteractionQuery, {
    variables: {
      patientUuid,
      transactionId,
      transactionType,
    },
    onCompleted: () => {
      onClose()
      history.replace({ search: '' })
    },
  })

  const handleYes = () => {
    trackClick({
      elementName: 'screenpop-yes-btn',
      elementType: TrackingElementType.button,
    })
    linkSupportInteraction()
  }

  const handleNo = () => {
    trackClick({
      elementName: 'screenpop-no-btn',
      elementType: TrackingElementType.button,
    })
    onClose()
  }

  return (
    <Modal
      open={isOpen}
      includeClose={false}
      className={styles.InteractionModal}
      overlayClassName={styles.InteractionModalOverlay}
    >
      <div className={styles.InteractionModalContentWrapper}>
        <p>{fm(messages.isThisThePatient)}</p>
        <div className={styles.ModalActions}>
          <ButtonSimple variant={ButtonVariant.secondary} onClick={handleNo}>
            {fm(messages.no)}
          </ButtonSimple>
          <ButtonSimple variant={ButtonVariant.primary} onClick={handleYes}>
            {fm(messages.yes)}
          </ButtonSimple>
        </div>
      </div>
    </Modal>
  )
}

export default SupportInteractionModal
