/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

/**
 * A single tab component - should be wrapped in `TabGroup`.
 */
export default function Tab({ name, children }) {
  return (
    <div data-tab-name={name} className="tab">
      {children}
    </div>
  )
}

Tab.propTypes = {
  /** A unique ID of a tab */
  name: PropTypes.string.isRequired,
  /** Tab title */
  title: PropTypes.node.isRequired,
  /** Disabled tab button */
  disabled: PropTypes.bool,
}
