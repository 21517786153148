import React from 'react'
import classnames from 'classnames'
import { format, parseISO } from 'date-fns'
import { groupMessagesByDateAndSender } from '@babylon/member-operations'
import styles from './Messages.module.scss'

interface MessagesProps {
  messages: ChatscriptMessage[]
  patientFullname: string
}

const Messages = ({ messages, patientFullname }: MessagesProps) => {
  const messagesGroupedByDate = groupMessagesByDateAndSender(
    messages,
    patientFullname
  )

  return (
    <div className={styles.Messages}>
      {Object.entries(messagesGroupedByDate).map(([date, groupedMessages]) => {
        return (
          <div key={date}>
            <div className={styles.Messages__dateHeader}>{date}</div>
            {groupedMessages.map(({ sender, messages }) => {
              const isUser = sender.type === 'user'
              const senderName = sender.name
              const className = classnames(styles.Messages__sender, {
                [styles['Messages__sender--user']]: isUser,
              })

              return (
                <div key={`${senderName}-${messages[0].id}`}>
                  <div className={className}>{senderName}</div>
                  {messages.map((message) => {
                    const time = format(parseISO(message.sentAt), 'HH:mm')
                    return (
                      <div key={message.id}>
                        <div className={styles.Message}>
                          <div className={styles.Message__time}>{time}</div>
                          <div className={styles.Message__text}>
                            {message?.value?.text}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Messages
