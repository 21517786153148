import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import Transcript from './components/Transcript'
import { USER_UUID_QUERY } from './queries'

const useUserUUID = (id: string) => {
  const { data } = useQuery(USER_UUID_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { id },
  })

  return data?.patient
}

const ConversationTranscript = () => {
  const { patientId, id } = useParams<{ id: string; patientId: string }>()
  const patient = useUserUUID(patientId)

  useEffect(() => {
    document.body.classList.add('allow-print')
    return () => {
      document.body.classList.remove('allow-print')
    }
  })

  return patient ? <Transcript patient={patient} conversationId={id} /> : null
}

export default ConversationTranscript
