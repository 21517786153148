import { defineMessages } from 'react-intl'

const baseId = 'assessment_submit_form'

export default defineMessages({
  description: {
    id: `${baseId}.description`,
    defaultMessage:
      'Submitting this assessment will send a PDF copy of the answers to the user’s medical record.',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  submit: {
    id: `${baseId}.submit`,
    defaultMessage: 'Submit',
  },
  submitError: {
    id: `${baseId}.submit_error`,
    defaultMessage: 'Something went wrong. Please try submitting again.',
  },
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Submit assessment',
  },
})
