import { useQuery, QueryHookOptions } from '@apollo/client'
import { DocumentNode } from 'graphql'

export default function useQueryConversations(
  query: DocumentNode,
  userId: string,
  options: Pick<QueryHookOptions, 'skip'> = {}
) {
  const { error, data, loading, fetchMore } = useQuery(query, {
    variables: {
      userId,
      order: 'desc',
      limit: 20,
      offset: 0,
    },
    ...options,
  })

  const covidConversations = data?.chatscriptConversations?.conversations || []

  const normalisedConversation = covidConversations.map(
    (conversation: ChatscriptConversation) => ({
      id: conversation?.id,
      time: conversation?.startedAt,
      text: conversation?.preview,
      tag: conversation?.result?.type,
      userUuid: conversation?.userUuid,
      patientUuid: conversation?.patientUuid,
    })
  )

  const fetchMoreConversations = () =>
    fetchMore({
      variables: {
        userId,
        order: 'desc',
        limit: 20,
        offset: data?.chatscriptConversations?.conversations.length,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        const prevConversations = prev.chatscriptConversations.conversations
        const nextConversations =
          fetchMoreResult.chatscriptConversations.conversations
        return {
          chatscriptConversations: {
            ...prev.chatscriptConversations,
            more: fetchMoreResult.chatscriptConversations.more,
            conversations: [...prevConversations, ...nextConversations],
          },
        }
      },
    })

  return {
    data: normalisedConversation,
    loading,
    error,
    more: data?.chatscriptConversations?.more,
    fetchMore: fetchMoreConversations,
  }
}
