import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useQuery } from '@apollo/client'
import { columns, columnsToShow } from './columns'
import styles from './styles.module.scss'
import { CoreDataTable } from '@/components/DataTableFromGQL'
import { messages } from './messages'
import {
  Connection,
  CoreRubyRegionEdge,
  EmailAddressContactPoint,
  HealthgraphIdentifierEdge,
  Organization,
  OrganizationEdge,
  OrganizationsResult,
  PortalRoleEdge,
  Practitioner,
  PractitionerRoleEdge,
  PractitionerRoleFilter,
  UpdatePharmacistPractitionerInput,
  UpdatePharmacistPractitionerPortalLoginAttributesInput,
} from '@/gql/graphql'
import {
  QUERY_GET_FILTERED_PHARMACISTS,
  QUERY_GET_PHARMACY_SUPPLIERS,
  QUERY_GET_PORTAL_ROLES,
  QUERY_GET_REGIONS,
} from '@/routes/Pharmacists/apollo/queries'
import { HealthGraphIdentifierSystem } from '@/routes/Common/enums'
import { PharmacySupplier, PortalRole, Region } from '@/routes/Pharmacies/types'
import { CREATE_PHARMACIST, EDIT_PHARMACIST } from '../apollo/mutations'
import {
  EditObjectConfig,
  EditObjectConfigField,
} from '@/components/DataTableFromGQL/types'
import { PharmacistRoles } from '../enums/Roles'

export const PharmacistsDataGrid = () => {
  const fm = useFormatMessage()
  const [regions, setRegions] = useState<Region[]>()
  const [pharmacySuppliers, setPharmacySuppliers] = useState<
    PharmacySupplier[]
  >()
  const [portalRoles, setPortalRoles] = useState<PortalRole[]>()

  const pharmacistFilter: PractitionerRoleFilter = {
    role: {
      ONE_OF: [
        {
          code: 'oal54j_MF2',
        },
      ],
    },
  }

  const { loading: loadingPortalRoles, error: portalRolesError } = useQuery(
    QUERY_GET_PORTAL_ROLES,
    {
      variables: {
        first: 100,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true, // required to trigger onCompleted function after refetch
      context: {
        clientName: 'platform-gateway',
      },
      onCompleted: (data: any) => {
        const roles: PortalRole[] = []
        data.portalRoles?.edges?.forEach((portalRoleEdge: PortalRoleEdge) => {
          roles.push({
            id: portalRoleEdge.node?.id as string,
            value: portalRoleEdge.node?.value as string,
            key: portalRoleEdge.node?.key as string,
          })
        })
        setPortalRoles(roles)
      },
    }
  )

  const regionForPharmacist = (pharmacist: PractitionerRoleEdge) => {
    const regionCode = (pharmacist.node?.organization as Organization).addresses
      ?.nodes[0]?.country
    return regions?.find((region) => region.code === regionCode)
  }
  const rowFormatter = (data: Connection) => {
    return data?.edges.map((item: PractitionerRoleEdge) => {
      return {
        id: (item.node?.practitioner as Practitioner).id as string,
        coreRubyId: ((item.node
          ?.practitioner as Practitioner).identifiers?.nodes?.filter(
          (identifier) =>
            identifier?.system ===
            HealthGraphIdentifierSystem.PHARMACIST_PRACTITIONER_CORE_RUBY_ID
        )[0]?.value as unknown) as number,
        active: (item.node?.practitioner as Practitioner).isActive,
        name:
          (item.node?.practitioner as Practitioner).names?.nodes[0]?.text || '',
        email:
          ((item.node?.practitioner as Practitioner).telecoms
            ?.nodes[0] as EmailAddressContactPoint)?.emailAddress || '',
        portal_login_id: ((item.node
          ?.practitioner as Practitioner).identifiers?.nodes?.filter(
          (identifier) =>
            identifier?.system ===
            HealthGraphIdentifierSystem.PHARMACIST_PRACTITIONER_PORTAL_LOGIN_ID
        )[0]?.value as unknown) as number,
        pharmacistRole: (item.node
          ?.practitioner as Practitioner).practitionerRole?.nodes.includes(
          PharmacistRoles.PHARMACIST
        ),
        pharmacy_supplier: (item.node?.organization as Organization).name,
        region_code: (item.node?.organization as Organization).addresses
          ?.nodes[0]?.country,
        regionName: regionForPharmacist(item)?.name || '',
      }
    })
  }

  const { loading: loadingRegions, error: regionsError } = useQuery(
    QUERY_GET_REGIONS,
    {
      variables: {
        first: 500,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true, // required to trigger onCompleted function after refetch
      context: {
        clientName: 'platform-gateway',
      },
      onCompleted: (data: any) => {
        const coreRubyRegions: Region[] = []
        data.coreRubyRegions?.edges?.forEach((edge: CoreRubyRegionEdge) => {
          coreRubyRegions.push({
            id: edge.node?.id as string,
            name: edge.node?.name as string,
            code: edge.node?.code as string,
          })
        })
        setRegions(coreRubyRegions)
      },
    }
  )

  const {
    loading: loadingPharmacySuppliers,
    error: pharmacySuppliersError,
  } = useQuery<OrganizationsResult>(QUERY_GET_PHARMACY_SUPPLIERS, {
    variables: {
      first: 500,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true, // required to trigger onCompleted function after refetch
    context: {
      clientName: 'platform-gateway',
    },
    onCompleted: (data: any) => {
      const organizations: PharmacySupplier[] = []
      data.organizations?.edges?.forEach((edge: OrganizationEdge) => {
        const region_code =
          (edge.node?.addresses?.nodes[0]?.country as string) || ''
        const supplier_name = edge?.node?.name as string
        const region_name =
          regions?.find((region) => region.code === region_code)?.name || ''
        organizations.push({
          id: (edge?.node?.identifiers?.edges?.filter(
            (identifier: HealthgraphIdentifierEdge) =>
              identifier?.node?.system ===
              HealthGraphIdentifierSystem.PHARMACY_SUPPLIER_CORE_RUBY_ID
          )[0]?.node?.value as unknown) as string,
          label: `${supplier_name} (${region_name})`,
          supplier_name,
          region_code,
          region_name,
        })
      })
      setPharmacySuppliers(organizations)
    },
  })

  function loadedWithoutErrors() {
    return (
      !loadingRegions &&
      !loadingPharmacySuppliers &&
      !loadingPortalRoles &&
      !regionsError &&
      !pharmacySuppliersError &&
      !portalRolesError
    )
  }

  /* Create the config for our edit and add modals */

  const getPharamcistRoleAsArray = () => {
    return (
      portalRoles
        ?.filter((role) => role.value === PharmacistRoles.PHARMACIST)
        .map((role) => role.id) || []
    )
  }
  const editMutationInputFormatter = (data: any) => {
    const pharmacySupplier = pharmacySuppliers?.find(
      (supplier) => supplier.label === data.pharmacy_supplier
    )
    const portalLoginAttributes: UpdatePharmacistPractitionerPortalLoginAttributesInput = {
      id: (data.portal_login_id as unknown) as string,
      email: data.email,
      isActive: data.active,
      name: data.name,
      roles: data.pharmacistRole ? getPharamcistRoleAsArray() : [],
    }
    const input: UpdatePharmacistPractitionerInput = {
      id: data.id,
      coreRubyId: (data.coreRubyId as unknown) as string,
      ...(pharmacySupplier && { pharmacySupplierId: pharmacySupplier.id }),
      ...(pharmacySupplier && { regionCode: pharmacySupplier.region_code }),
      portalLoginAttributes,
    }
    return input
  }

  const createMutationInputFormatter = (data: any) => {
    const pharmacySupplier = pharmacySuppliers?.find(
      (supplier) => supplier.label === data.pharmacy_supplier
    )
    const input = {
      email: data.email,
      name: data.name,
      isActive: data.active,
      pharmacySupplierId: pharmacySupplier?.id,
      regionCode: pharmacySupplier?.region_code,
      roles: data.pharmacistRole ? getPharamcistRoleAsArray() : [],
    }
    return input
  }

  const editFields: EditObjectConfigField[] = [
    {
      name: 'name',
      type: 'text',
      dataField: 'name',
      label: fm(messages.name),
      required: true,
    },
    {
      name: 'email',
      type: 'text',
      label: fm(messages.email),
      required: true,
    },
    {
      name: 'active',
      type: 'checkbox',
      label: fm(messages.active),
      defaultValue: true,
    },
    {
      name: 'pharmacistRole',
      type: 'checkbox',
      label: fm(messages.pharmacistRole),
      defaultValue: true,
    },
    {
      name: 'regionName',
      type: 'select',
      label: fm(messages.region),
      options: regions?.map((region) => ({
        value: region.code,
        label: region.name,
      })),
    },
    {
      name: 'pharmacy_supplier',
      type: 'select',
      label: fm(messages.pharmacySupplier),
      options: pharmacySuppliers?.map((pharmacySupplier) => ({
        value: pharmacySupplier.id,
        label: pharmacySupplier.label,
      })),
      required: true,
      helperText: fm(messages.autoSelectRegionMessage),
    },
    {
      name: 'id',
      hidden: true,
    },
    {
      name: 'coreRubyId',
      hidden: true,
    },
    {
      name: 'portal_login_id',
      hidden: true,
    },
  ]

  const editConfig: EditObjectConfig = {
    mutation: EDIT_PHARMACIST,
    fields: editFields,
    headings: {
      header: 'name',
    },
    mutationInputFormatter: editMutationInputFormatter,
  }

  const addFields = [...editFields]
  const regionNameField = addFields.find((field) => field.name === 'regionName')
  if (regionNameField) {
    regionNameField.hidden = true
  }

  const addConfig: EditObjectConfig = {
    mutation: CREATE_PHARMACIST,
    fields: addFields,
    headings: {
      header: 'name',
    },
    mutationInputFormatter: createMutationInputFormatter,
  }

  return (
    <div className={styles.fillContent}>
      <CoreDataTable
        id="pharmacists"
        rowFormatter={rowFormatter}
        columns={columns}
        defaultFilter={pharmacistFilter}
        initialColumnsToShow={columnsToShow}
        query={QUERY_GET_FILTERED_PHARMACISTS}
        editConfig={loadedWithoutErrors() ? editConfig : undefined}
        addConfig={loadedWithoutErrors() ? addConfig : undefined}
        objectTypeLabel={fm(messages.pharmacist)}
      />
    </div>
  )
}
