import { defineMessages } from 'react-intl'

const baseId = 'account_services_activity_log'

export default defineMessages({
  header: {
    id: `${baseId}.header`,
    defaultMessage: 'Activity log',
  },
  copyReportUrl: {
    id: `${baseId}.copy_report_url`,
    defaultMessage: 'Copy link to appointment log',
  },
  reportUrlCopied: {
    id: `${baseId}.report_url_copied`,
    defaultMessage: 'Link copied',
  },
})
