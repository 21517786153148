import { gql } from '@apollo/client'

export const PROFILE_QUERY = gql`
  query AP_Profile($patientUuid: ID!) {
    patient(id: $patientUuid) {
      ... on Patient {
        name {
          family
          given
        }
        id
        gender
        birthDate
        sexAtBirth
        isMinor
        activationStatus: _activationStatus
        addresses(first: 10) {
          edges {
            node {
              line
              city
              state
              postalCode
              country
            }
          }
        }
        telecoms(first: 1) {
          edges {
            node {
              ... on PhoneNumberContactPoint {
                phoneNumber
              }
              ... on EmailAddressContactPoint {
                emailAddress
              }
            }
          }
        }
        identifiers(first: 10) {
          edges {
            node {
              system
              value
            }
          }
        }
        relatedPersons(first: 10) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`
