import React from 'react'

import * as Access from '@babylon/web-platform-access'
import { PageErrorMessage } from '@babylon/cce-shared-components'
import { spinnerLoaderInstance } from '@babylon/core-ui'

import AdminPrescriptions from './AdminPrescriptions'
import PharmacyPrescriptions from './PharmacyPrescriptions'
import GpPrescriptions from './GpPrescriptions'

const Prescriptions = (props) => {
  const [
    canAccessAdminPrescription,
    canAccessAdminPrescriptionError,
  ] = Access.useDecide('admin-prescription')

  const [
    canAccessPharmacyPrescription,
    canAccessPharmacyPrescriptionError,
  ] = Access.useDecide('pharmacy-prescription')
  const [
    canAccessGpPrescription,
    canAccessGpPrescriptionError,
  ] = Access.useDecide('gp-prescription')

  if (
    canAccessAdminPrescriptionError ||
    canAccessPharmacyPrescriptionError ||
    canAccessGpPrescriptionError
  ) {
    return <PageErrorMessage />
  }

  if (canAccessAdminPrescription) {
    return <AdminPrescriptions {...props} />
  }

  if (canAccessPharmacyPrescription) {
    return <PharmacyPrescriptions {...props} />
  }

  if (canAccessGpPrescription) {
    return <GpPrescriptions {...props} />
  }

  return spinnerLoaderInstance
}

export default Prescriptions
