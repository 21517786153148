import React from 'react'

import { FormikRadioGroup, Radio, Text } from '@babylon/core-ui'

import { formatClinician } from './BookSlotUtils'
import { Clinician } from '../AvailabilitySlots/GroupSlots'

interface Props {
  clinicians: Clinician[]
}

const BookSlotCliniciansField = ({ clinicians = [] }: Props) => (
  <FormikRadioGroup
    id="consultant_id"
    name="consultant_id"
    label="Preferred Consultant"
  >
    {clinicians
      .filter((clinician) => clinician.available)
      .map((clinician) => (
        <Radio key={clinician.id} value={clinician.id}>
          <Text size="large">{formatClinician(clinician)}</Text>
        </Radio>
      ))}
  </FormikRadioGroup>
)

export default BookSlotCliniciansField
