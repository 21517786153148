import { defineMessages } from 'react-intl'

const baseId = 'digital_appointment'

export default defineMessages({
  appointment_type: {
    id: `${baseId}.appointment_type`,
    defaultMessage: 'Appointment type',
  },
  appointment_type_placeholder: {
    id: `${baseId}.appointment_type_placeholder`,
    defaultMessage: 'Select appointment type',
  },
  appointment_reason: {
    id: `${baseId}.appointment_reason`,
    defaultMessage: 'Reason for appointment',
  },
  appointment_reason_helper: {
    id: `${baseId}.appointment_reason_helper`,
    defaultMessage: 'This will appear in the app to the patient',
  },
  duration_helper: {
    id: `${baseId}.duration_helper`,
    defaultMessage:
      'We advise using the recommended appointment duration. Due to lack of availability, longer appointments require the patient to book further into the future which delays their treatment.',
  },
  book_btn: {
    id: `${baseId}.book_btn`,
    defaultMessage: 'Book for patient',
  },
  send_btn: {
    id: `${baseId}.send_btn`,
    defaultMessage: 'Send to patient',
  },
})
