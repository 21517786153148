import { defineMessages } from 'react-intl'
import { MemberThreadSubType } from '@babylon/graphql-middleware-types'

const baseId = 'timeline_test_results'

export default defineMessages({
  outstanding_tests: {
    id: `${baseId}.outstanding_tests`,
    defaultMessage: 'Outstanding tests',
  },
  outstanding_tests_tooltip: {
    id: `${baseId}.outstanding_tests_tooltip`,
    defaultMessage:
      'Displays any test results that needs action by the patient or Babylon.',
  },
  past_tests: {
    id: `${baseId}.past_tests`,
    defaultMessage: 'Past tests',
  },
  past_tests_tooltip: {
    id: `${baseId}.past_tests_tooltip`,
    defaultMessage:
      'Displays chronologically the patient’s past test results with Babylon.',
  },
  [MemberThreadSubType.BloodTest]: {
    id: `${baseId}.blood_test`,
    defaultMessage: 'Blood test',
  },
  view_history: {
    id: `${baseId}.view_history`,
    defaultMessage: 'View history',
  },
})
