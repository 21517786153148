import React, { useState } from 'react'
import qs from 'qs'
import { Button, Grid, Page } from '@babylon/core-ui'
import { useHistory, useLocation, useParams } from 'react-router'
import { NetworkStatus, useApolloClient } from '@apollo/client'
import { TrackingActionType, useTracking } from '@babylon/tracking/react'
import { envFlag } from '@babylon/babylon-env'
import { differenceInMinutes, parseISO } from 'date-fns'
import {
  getSelectedDateRange,
  getValidQueryParameters,
  mergePractitionerAppointments,
  parseQueryString,
  updatePractitionerAppointmentStatus,
  formatPractitionerAppointments,
  buildFederatedPractitionerId,
  isNonNullable,
  formatPractitioner,
  mergeSlots,
  isAppointmentSlotAfterCurrentSessionEnd,
  formatPractitionerSlots,
} from './AppointmentManagementPage.utils'
import styles from './AppointmentManagementPage.module.css'
import { DateSelector } from './DateSelector'
import {
  PractitionerDocument,
  usePractitionerQuery,
} from './Practitioner.federated.hooks'
import { Appointment } from './Appointment'
import LoadingContainer from '../components/LoadingContainer'
import {
  AppointmentSortOption,
  AppointmentStatus,
  SortOrder,
  AVAILABLE_SLOTS_FILTER_VALUE,
} from './AppointmentManagementPage.types'
import { AppointmentsMessage } from './AppointmentsMessage'
import { PractitionerDetails } from './PractitionerDetails'
import { SideOverlay } from './SideOverlay'
import {
  CancelAppointmentForm,
  CancelAppointmentFormValues,
} from './CancelAppointmentForm'
import { CancelAppointmentInformation } from './CancelAppointmentInformation'
import { timezones } from './timezones'
import type { DateSelectorValues } from './DateSelector'
import type {
  AppointmentManagementPageParameters,
  Appointment as AppointmentType,
  Slot as SlotType,
} from './AppointmentManagementPage.types'
import {
  CancelAppointmentMutation,
  useCancelAppointmentMutation,
} from './CancelAppointment.middleware.hooks'
import { useCancelReasonsQuery } from './CancelReasons.middleware.hooks'
import { FilterOptionsForm } from './FilterOptionsForm'
import { useAvailabilitySlotsQuery } from './AvailableSlots.middleware.hooks'
import { useClinicianQuery } from './Clinician.middleware.hooks'
import { useClinicianAvailabilityShiftsQuery } from './ClinicianAvailabilityShifts.middleware.hooks'
import { Slot } from './Slot'
import { AppointmentFilter } from '../types/generated/federated-graph'
import useAppointmentFilters from './useAppointmentFilters'
import { Session } from './Session'
import { DownloadCSV } from './DownloadCSV'

const APPOINTMENT_LIMIT = 20
const SLOTS_LIMIT = 100

const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
type AppointmentManagementEventPayload = {
  eventLabel?: string
  eventAction?: string
  babylonAppointmentId?: string | null
}

const AppointmentManagementPage = () => {
  const { trackEvent } = useTracking()
  const trackAppointmentManagementEvent = ({
    eventLabel,
    eventAction,
    babylonAppointmentId,
  }: AppointmentManagementEventPayload) => {
    if (babylonAppointmentId) {
      trackEvent({
        appointmentId: babylonAppointmentId,
        eventLabel,
        eventAction,
        eventCategory: 'appointment management',
        tealiumEvent: TrackingActionType.click,
        actionType: TrackingActionType.click,
      })
    }
  }
  const apolloClient = useApolloClient()
  const history = useHistory()
  const location = useLocation()
  const params = useParams<AppointmentManagementPageParameters>()

  const queryStringParameters = parseQueryString(location.search)
  const { date, timezone } = getValidQueryParameters({
    queryStringParameters,
    defaultDate: new Date(),
    userTimezone: defaultTimezone,
  })

  const { startDate, endDate } = getSelectedDateRange(date, timezone)

  const [cancelAppointmentId, setCancelAppointmentId] = useState<string | null>(
    null
  )
  const [cancelAppointmentError, setCancelAppointmentError] = useState<
    string | null
  >(null)

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)

  const {
    appointmentFilters,
    setAppointmentFilters,
    appointmentFilterQueryVariables,
  } = useAppointmentFilters()

  const [slotPage, setSlotPage] = useState(1)

  const appointmentManagementFilterEnabled = !!envFlag(
    'ENABLE_APPOINTMENT_MANAGMENT_FILTER'
  )

  const appointmentManagementSlotsEnabled = !!envFlag(
    'ENABLE_APPOINTMENT_MANAGEMENT_SLOTS'
  )

  const appointmentManagementSessionShiftsEnabled = !!envFlag(
    'ENABLE_APPOINTMENT_MANAGEMENT_SESSION_SHIFTS_ENABLED'
  )

  const appointmentManagementCsvDownloadEnabled = !!envFlag(
    'ENABLE_APPOINTMENT_MANAGEMENT_CSV_DOWNLOAD_ENABLED'
  )

  const {
    data: shiftsData,
    loading: shiftsLoading,
  } = useClinicianAvailabilityShiftsQuery({
    variables: {
      consultantUuid: params.practitionerId,
      startDate,
      endDate,
    },
    skip: !appointmentManagementSessionShiftsEnabled,
  })

  const sessionShifts = shiftsData?.clinicianAvailabilityShifts?.items ?? []

  const filterVariables: AppointmentFilter = {
    date: {
      GTE: startDate,
      LTE: endDate,
    },
    ...appointmentFilterQueryVariables,
  }

  const isAvailableFilterSelected = appointmentFilters.appointmentStatus.includes(
    AVAILABLE_SLOTS_FILTER_VALUE
  )

  const {
    data: cancelReasonsData,
    loading: isCancelReasonsLoading,
  } = useCancelReasonsQuery()

  const { data: clinicianData, loading: clinicianLoading } = useClinicianQuery({
    variables: {
      idOrUuid: params.practitionerId,
    },
  })

  const clinicianId = clinicianData?.consultant?.id as string

  const isSkipDisplaySlots =
    !isAvailableFilterSelected &&
    !!(
      appointmentFilterQueryVariables.status ||
      appointmentFilterQueryVariables.medium
    )

  const practitionerVariables = {
    id: buildFederatedPractitionerId(params.practitionerId),
    first: APPOINTMENT_LIMIT,
    appointmentSort: [
      {
        field: AppointmentSortOption.Date,
        order: SortOrder.Asc,
      },
    ],
    filter: filterVariables,
  }

  const {
    data: practitionerData,
    fetchMore: fetchMoreAppointments,
    networkStatus: practitionerNetworkStatus,
  } = usePractitionerQuery({
    context: {
      clientName: 'platform-gateway',
    },
    notifyOnNetworkStatusChange: true,
    variables: practitionerVariables,
  })

  const isSkipDisplayAppointments =
    !appointmentFilters.appointmentMedium.length &&
    isAvailableFilterSelected &&
    appointmentFilters.appointmentStatus.length === 1

  const {
    data: slotsData,
    loading: availableSlotsLoading,
    fetchMore: fetchMoreSlots,
  } = useAvailabilitySlotsQuery({
    variables: {
      availabilitySlotsRequest: {
        clinician_id: [clinicianId],
        slot_time_from: startDate,
        slot_time_to: endDate,
        limit: SLOTS_LIMIT,
      },
    },
    skip:
      !appointmentManagementSlotsEnabled || isSkipDisplaySlots || !clinicianId,
  })

  const practitionerSlots = formatPractitionerSlots(
    practitionerData?.practitioner
  )

  const slots =
    slotsData?.availabilitySlots?.availability_slots ?? practitionerSlots
  const availableSlots = slots.filter((slot) => !slot?.admin)

  const adminSlots =
    (!isAvailableFilterSelected && slots.filter((slot) => slot?.admin)) || []

  const hasMoreSlots = slotsData?.availabilitySlots.more

  const isPractitionerLoading =
    practitionerNetworkStatus === NetworkStatus.loading ||
    practitionerNetworkStatus === NetworkStatus.setVariables

  const isLoading =
    isPractitionerLoading ||
    isCancelReasonsLoading ||
    availableSlotsLoading ||
    clinicianLoading ||
    shiftsLoading

  const practitioner = formatPractitioner(practitionerData?.practitioner)
  const appointments = isSkipDisplayAppointments
    ? []
    : formatPractitionerAppointments(practitionerData?.practitioner)

  const appointmentsAndSlots = [
    ...appointments,
    ...availableSlots,
    ...adminSlots,
  ]

  appointmentsAndSlots.sort((slot1, slot2) => {
    const time1 = 'startTime' in slot1 ? slot1.startTime : slot1.slot_time
    const time2 = 'startTime' in slot2 ? slot2.startTime : slot2.slot_time

    if (!time1 || !time2) {
      return 0
    }

    return differenceInMinutes(parseISO(time1), parseISO(time2))
  })

  const appointmentCancelReasons =
    cancelReasonsData?.cancelReasons?.filter(isNonNullable) ?? []

  const hasAppointmentsOrAvailableSlots = Boolean(appointmentsAndSlots.length)

  const appointmentToCancel = cancelAppointmentId
    ? appointments.find((appointment) => appointment.id === cancelAppointmentId)
    : undefined

  const hasMoreAppointments =
    practitionerData?.practitioner?.appointments?.pageInfo?.hasNextPage ?? false

  const handleCancelAppointmentComplete = (
    response: CancelAppointmentMutation
  ) => {
    const appointmentId = response.cancelAppointmentWithMicroservice?.id

    if (!appointmentId || !practitionerData) {
      return
    }

    const cancelledAppointment = appointments.find(
      (appointment) => appointment.babylonId === appointmentId.toString()
    )

    if (!cancelledAppointment) {
      return
    }

    apolloClient.writeQuery({
      query: PractitionerDocument,
      data: updatePractitionerAppointmentStatus(
        practitionerData,
        cancelledAppointment.id,
        AppointmentStatus.Cancelled
      ),
      variables: practitionerVariables,
    })

    setCancelAppointmentId(null)
  }

  const [
    cancelAppointment,
    { loading: isCancellingAppointment },
  ] = useCancelAppointmentMutation({
    onCompleted: handleCancelAppointmentComplete,
    onError: (error) => {
      setCancelAppointmentError(error.message)
    },
  })

  const handleDateSelectorChange = (values: DateSelectorValues) => {
    history.replace({
      search: qs.stringify(values),
    })
  }

  const handleLoadMoreButtonClick = () => {
    const cursor =
      practitionerData?.practitioner?.appointments?.pageInfo?.endCursor

    if (cursor && hasMoreAppointments) {
      fetchMoreAppointments({
        variables: {
          cursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) =>
          mergePractitionerAppointments(previousResult, fetchMoreResult),
      })
    }

    if (hasMoreSlots) {
      fetchMoreSlots({
        variables: {
          availabilitySlotsRequest: {
            clinician_id: [clinicianId],
            slot_time_from: startDate,
            slot_time_to: endDate,
            limit: SLOTS_LIMIT,
            offset: slotPage * SLOTS_LIMIT,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) =>
          mergeSlots(previousResult, fetchMoreResult),
      })

      setSlotPage(slotPage + 1)
    }
  }

  const handleAppointmentCancelClick = (
    appointmentId: string,
    babylonAppointmentId: string | null
  ) => {
    trackAppointmentManagementEvent({
      babylonAppointmentId,
      eventAction: 'click cancel appointment button',
    })
    setCancelAppointmentId(appointmentId)
  }

  const trackShowDetails = (babylonAppointmentId: string | null) =>
    trackAppointmentManagementEvent({
      babylonAppointmentId,
      eventAction: 'click show details',
    })

  const trackSelectCancelReason = (babylonAppointmentId, cancelReason) =>
    trackAppointmentManagementEvent({
      babylonAppointmentId,
      eventLabel: cancelReason,
      eventAction: 'select cancel appointment dropdown',
    })

  const handleCancelModalClose = () => {
    setCancelAppointmentError(null)
    setCancelAppointmentId(null)
    setIsFilterDrawerOpen(false)
  }

  const handleCancelAppointmentSubmit = (
    values: CancelAppointmentFormValues
  ) => {
    const { appointmentId, cancelReasonId } = values

    const parsedCancelReasonId = parseInt(cancelReasonId, 10)

    if (Number.isNaN(parsedCancelReasonId)) {
      return
    }

    trackAppointmentManagementEvent({
      babylonAppointmentId: appointmentId,
      eventAction: 'confirm cancel appointment',
    })
    cancelAppointment({
      variables: {
        appointmentId,
        cancelReasonId: parsedCancelReasonId,
      },
    })
  }

  const getAppointmentOrSlotComponent = (
    appointmentOrSlot: AppointmentType | SlotType
  ) => {
    if ('digital_bookable' in appointmentOrSlot) {
      return (
        <Slot
          key={appointmentOrSlot.id}
          timezone={timezone}
          slotTime={appointmentOrSlot.slot_time}
          slotSize={appointmentOrSlot.slot_size}
          physicalBookable={appointmentOrSlot.physical_bookable}
          digitalBookable={appointmentOrSlot.digital_bookable}
          isAdmin={appointmentOrSlot.admin}
        />
      )
    }

    return (
      <Appointment
        key={appointmentOrSlot.id}
        id={appointmentOrSlot.id}
        babylonId={appointmentOrSlot.babylonId}
        startTime={appointmentOrSlot.startTime}
        durationInMinutes={appointmentOrSlot.durationInMinutes}
        medium={appointmentOrSlot.medium}
        isTranslatorRequired={appointmentOrSlot.isTranslatorRequired}
        status={appointmentOrSlot.status}
        appointmentReasons={appointmentOrSlot.appointmentReasons}
        patientId={appointmentOrSlot.patientId}
        patientBabylonId={appointmentOrSlot.patientBabylonId}
        patientName={appointmentOrSlot.patientName}
        patientConsumerNetworkId={appointmentOrSlot.patientConsumerNetworkId}
        isPatientMinor={appointmentOrSlot.isPatientMinor}
        bookingAgent={appointmentOrSlot.bookingAgent}
        timezone={timezone}
        onCancel={handleAppointmentCancelClick}
        trackShowDetails={trackShowDetails}
      />
    )
  }

  const slotsAndSessionShifts: JSX.Element[] = []
  let appointmentsAndSlotIndex = 0

  let lastSessionEndTime

  if (sessionShifts?.length) {
    lastSessionEndTime = sessionShifts[sessionShifts.length - 1].shift_end_time

    for (let i = 0; i < sessionShifts.length; i++) {
      const session = sessionShifts[i]

      slotsAndSessionShifts.push(
        <Session
          key={session.id + session.shift_start_time}
          detailText="Start of session"
          timezone={timezone}
          time={session.shift_start_time}
        />
      )

      while (appointmentsAndSlotIndex < appointmentsAndSlots.length) {
        const appointmentOrSlot = appointmentsAndSlots[appointmentsAndSlotIndex]

        if (
          isAppointmentSlotAfterCurrentSessionEnd(appointmentOrSlot, session)
        ) {
          slotsAndSessionShifts.push(
            <Session
              key={session.id + session.shift_end_time}
              detailText="End of session"
              timezone={timezone}
              time={session.shift_end_time}
            />
          )

          break
        }

        slotsAndSessionShifts.push(
          getAppointmentOrSlotComponent(appointmentOrSlot)
        )
        appointmentsAndSlotIndex += 1
      }
    }
  }

  return (
    <Page
      breadcrumbs={[
        <div className={styles.breadcrumb}>Clinicians Profile</div>,
      ]}
    >
      {practitioner && (
        <PractitionerDetails
          fullName={practitioner.fullName}
          emailAddress={clinicianData?.consultant?.email}
        />
      )}

      <Grid
        templateRows="repeat(2, auto)"
        rowGap={32}
        className={styles.container}
      >
        <div className={styles.filterActions}>
          <DateSelector
            date={date}
            currentDate={new Date()}
            timezone={timezone}
            timezones={timezones}
            onChange={handleDateSelectorChange}
            practitionerId={params.practitionerId}
          />
          <div className={styles.buttonActions}>
            {appointmentManagementFilterEnabled && (
              <Button
                type="button"
                intent="secondary"
                onClick={() => {
                  setIsFilterDrawerOpen(true)
                }}
                loading={practitionerNetworkStatus === NetworkStatus.fetchMore}
                className={styles.filterButton}
              >
                Filter
              </Button>
            )}
            {appointmentManagementCsvDownloadEnabled && (
              <DownloadCSV
                practitionerId={params.practitionerId}
                startDate={startDate}
                endDate={endDate}
                timezone={timezone}
              />
            )}
          </div>
        </div>
        <LoadingContainer loading={isLoading} className={styles.content}>
          {practitionerNetworkStatus === NetworkStatus.ready &&
            !hasAppointmentsOrAvailableSlots && (
              <AppointmentsMessage
                title="No appointments"
                message="There are no appointments for this day."
              />
            )}

          {hasAppointmentsOrAvailableSlots && (
            <div className={styles.appointments}>
              {appointmentManagementSessionShiftsEnabled
                ? slotsAndSessionShifts
                : appointmentsAndSlots.map(getAppointmentOrSlotComponent)}
            </div>
          )}

          {(hasMoreAppointments || hasMoreSlots) && (
            <Button
              type="button"
              intent="secondary"
              onClick={handleLoadMoreButtonClick}
              loading={practitionerNetworkStatus === NetworkStatus.fetchMore}
              className={styles.loadMoreButton}
            >
              Load more appointments
            </Button>
          )}
          {hasAppointmentsOrAvailableSlots && lastSessionEndTime && (
            <Session
              detailText="End of session"
              timezone={timezone}
              time={lastSessionEndTime}
            />
          )}
        </LoadingContainer>
      </Grid>

      {(appointmentToCancel || isFilterDrawerOpen) && (
        <SideOverlay
          title={appointmentToCancel ? 'Cancel Appointment' : 'Filter'}
          onClose={handleCancelModalClose}
        >
          {isFilterDrawerOpen && (
            <FilterOptionsForm
              isSubmitting={isPractitionerLoading}
              onSubmit={(values) => {
                setAppointmentFilters({
                  appointmentMedium: values.appointmentMedium,
                  appointmentStatus: values.appointmentStatus,
                })
                setIsFilterDrawerOpen(false)
              }}
              appointmentFilters={appointmentFilters}
            />
          )}
          {appointmentToCancel && (
            <>
              <div className={styles.cancelInformation}>
                <CancelAppointmentInformation
                  appointmentId={appointmentToCancel.babylonId}
                  appointmentReasons={appointmentToCancel.appointmentReasons}
                  startTime={appointmentToCancel.startTime}
                  durationInMinutes={appointmentToCancel.durationInMinutes}
                  patientName={appointmentToCancel.patientName}
                  patientBabylonId={appointmentToCancel.patientBabylonId}
                  timezone={timezone}
                />
              </div>
              <CancelAppointmentForm
                appointmentId={appointmentToCancel.babylonId}
                cancelReasons={appointmentCancelReasons}
                isSubmitting={isCancellingAppointment}
                onSubmit={handleCancelAppointmentSubmit}
                trackSelectCancelReason={trackSelectCancelReason}
              />

              {cancelAppointmentError && (
                <p className={styles.error}>{cancelAppointmentError}</p>
              )}
            </>
          )}
        </SideOverlay>
      )}
    </Page>
  )
}

export default AppointmentManagementPage
