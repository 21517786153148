import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { useMutation } from '@apollo/client'
import { Close } from '@babylon/icons'
import { Modal, Grid, Cell } from '@babylon/core-ui'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import messages from './messages'
import { REACTIVATE_REDEMPTION_CODE, REMOVE_REDEMPTION_CODE } from './queries'
import styles from './ConfirmRedemptionActionModal.module.scss'
import { useSnackBar } from '../../../../../../../hooks'
import { MemberOpsModuleName } from '../../../../../../..'

export enum ActionType {
  removePromo,
  reactivatePromo,
}

function getMessagesByAction(actionType: ActionType | null) {
  switch (actionType) {
    case ActionType.reactivatePromo:
      return {
        title: messages.reactivate_modal_title,
        content: messages.reactivate_modal_content,
        successSnackbar: messages.reactivate_promo_success,
        failureSnackbar: messages.reactivate_promo_failure,
      }
    case ActionType.removePromo:
    default:
      return {
        title: messages.remove_modal_title,
        content: messages.remove_modal_content,
        successSnackbar: messages.remove_promo_success,
        failureSnackbar: messages.remove_promo_failure,
      }
  }
}

function unwrapResult(payload: any, actionType: ActionType | null) {
  if (!payload.data) return null

  if (actionType === ActionType.removePromo) {
    return payload.data.removeRedemption
  } else if (actionType === ActionType.reactivatePromo) {
    return payload.data.reactivateRedemption
  } else {
    return null
  }
}

const ConfirmRedemptionActionModal = ({
  actionType,
  patientId,
  redemptionId,
  redemptionName,
  isOpened,
  onClose,
  onSuccess,
}: {
  actionType: ActionType | null
  patientId: string
  redemptionId: string | null
  redemptionName: string | null
  isOpened: boolean
  onClose: () => void
  onSuccess: () => void
}) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()
  const { setSnackbarMessage } = useSnackBar()
  const [removeRedemptionCode] = useMutation(REMOVE_REDEMPTION_CODE)
  const [reactivateRedemptionCode] = useMutation(REACTIVATE_REDEMPTION_CODE)

  const copy = getMessagesByAction(actionType)

  const mutationAction = (actionType: ActionType | null) => {
    if (actionType === ActionType.removePromo) {
      return removeRedemptionCode
    } else if (actionType === ActionType.reactivatePromo) {
      return reactivateRedemptionCode
    } else {
      return null
    }
  }

  async function handleCodeAction() {
    const action = mutationAction(actionType)
    if (!action) return

    try {
      const result = await action({
        variables: { patientId, redemptionId },
      })

      const wasSubmissionSuccessful = unwrapResult(result, actionType)
      if (wasSubmissionSuccessful === true) {
        setSnackbarMessage(fm(copy.successSnackbar), null, 'success')
        onSuccess()
        onClose()
      } else {
        setSnackbarMessage(fm(copy.failureSnackbar), null, 'error')
      }
    } catch (e) {
      setSnackbarMessage(fm(copy.failureSnackbar), null, 'error')
    }
  }

  return (
    <Modal
      label={fm(copy.title)}
      open={isOpened}
      onClose={onClose}
      includeClose={false}
    >
      <div className={styles.ModalContent}>
        <header>
          <Grid columns={6}>
            <Cell width={4}>
              <h2>{fm(copy.title)}</h2>
            </Cell>
            <Cell center left={6}>
              <button
                type="button"
                onClick={onClose}
                className={styles.ModalContent__closeButton}
              >
                <Close />
              </button>
            </Cell>
          </Grid>
        </header>
        <p data-testid="modal-body">
          <FormattedMessage
            {...copy.content}
            values={{
              b: (chunks: any) => <strong>{chunks}</strong>,
              promoName: redemptionName,
            }}
          />
        </p>
        <footer>
          <ButtonSimple
            className={styles.ModalContent__Action}
            data-testid="confirm-action-btn"
            variant={ButtonVariant.primary}
            onClick={() => {
              handleCodeAction()
              let elAction: string = 'unknown'
              if (actionType === ActionType.removePromo) {
                elAction = 'remove'
              } else if (actionType === ActionType.reactivatePromo) {
                elAction = 'reactivate'
              }

              trackClick({
                elementName: `confirm-${elAction}-btn`,
                elementType: TrackingElementType.button,
              })
            }}
          >
            {fm(messages.remove_modal_yes_action)}
          </ButtonSimple>
          <ButtonSimple
            className={styles.ModalContent__Action}
            data-testid="reject-action-btn"
            variant={ButtonVariant.secondary}
            onClick={() => onClose()}
          >
            {fm(messages.remove_modal_no_action)}
          </ButtonSimple>
        </footer>
      </div>
    </Modal>
  )
}

export default ConfirmRedemptionActionModal
