import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { usePrevious } from './usePrevious'
import { useTrackView } from './useTrackView'

/**
 * Hook provides route tracking on a route change
 */
export function useTrackRoute() {
  const { pathname } = useLocation()
  const { trackView } = useTrackView()
  const prevRoute = usePrevious(pathname)

  useEffect(() => {
    if (prevRoute !== pathname) {
      trackView({ pathname })
    }
  }, [pathname, prevRoute, trackView])
}
