import React from 'react'

import { AgentWithAvailability } from '@babylon/atlas.js'

import { Queue } from '@babylon/atlas.js/dist/lib/types'
import AgentAvatar from './AgentAvatar'
import QueueListItem from './QueueListItem'
import { nameToInitials } from './utils'

type Props = {
  agent: AgentWithAvailability
  personalQueue: Queue
  userId: string
  onQueueSelect: (q: Queue) => void
  highlighted: boolean
}

const AgentQueueItem = ({
  agent,
  personalQueue,
  userId,
  onQueueSelect,
  highlighted,
}: Props) => {
  const queueCount = personalQueue.conversations.length

  return (
    <QueueListItem
      // key={a.id}
      disabled={agent.id === userId}
      label={agent.id === userId ? '(YOU)' : ''}
      highlighted={highlighted}
      onSelected={() => {
        if (personalQueue) {
          onQueueSelect(personalQueue)
        }
      }}
      Icon={
        <AgentAvatar
          initials={nameToInitials(agent.nickname)}
          available={agent.available}
        />
      }
      title={agent.nickname}
      description={`(${queueCount} members in queue)`}
    />
  )
}

export default AgentQueueItem
