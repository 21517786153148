// not tested and not used yet

import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@/components/Icon'

import styles from './styles.module.scss'

const SortButtonView = ({
  name,
  search,
  searchPhrase,
  onSearchChange,
  onSearch,
}) => {
  if (search) {
    return (
      <div>
        <input
          onChange={(event) =>
            onSearchChange && onSearchChange(name, event.target.value)
          }
          defaultValue=""
          value={searchPhrase}
        />
        <button
          className={styles.rightButton}
          onClick={() => onSearch && onSearch(name)}
        >
          <Icon name="search" />
        </button>
      </div>
    )
  }

  return null
}

SortButtonView.defaultProps = {
  search: false,
  searchPhrase: '',
  onSearchChange: null,
  onSearch: null,
}

SortButtonView.propTypes = {
  name: PropTypes.string.isRequired,
  search: PropTypes.bool,
  searchPhrase: PropTypes.string,
  onSearchChange: PropTypes.func,
  onSearch: PropTypes.func,
}

export default SortButtonView
