import cn from 'classnames'
import { MemberWorkflowState } from '@babylon/graphql-middleware-types'
import styles from './status.module.css'

const getStatusStyles = (
  state?: MemberWorkflowState
): { [style: string]: boolean } | {} =>
  !state
    ? {}
    : cn({
        [styles.pending]: state === MemberWorkflowState.Pending,
        [styles.completed]: state === MemberWorkflowState.Completed,
      })

export default getStatusStyles
