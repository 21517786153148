import React, { FC } from 'react'
import classnames from 'classnames/bind'

import Tag from '../Tag'
import styles from './styles.module.scss'

export interface ConsumerNetworkTagProps {
  className?: string
  'data-testid'?: string
  isPreferred?: boolean
  name?: string | null
}

const cx = classnames.bind(styles)

const ConsumerNetworkTag: FC<ConsumerNetworkTagProps> = ({
  className,
  'data-testid': dataTestId,
  isPreferred = false,
  name,
}) => {
  if (!name) {
    return null
  }

  return (
    <Tag
      label={name}
      className={cx('ConsumerNetworkTag', className, {
        ConsumerNetworkTag__preferred: isPreferred,
      })}
      data-testid={dataTestId}
    />
  )
}

export default ConsumerNetworkTag
