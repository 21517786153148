import React from 'react'
import ReactSelect from 'react-select'
import { Pagination as CorePagination, Text } from '@babylon/core-ui'

import TotalResults, { TotalResultsProps } from '../TotalResults'

import styles from './Pagination.module.css'

interface PaginationProps extends Pick<TotalResultsProps, 'total'> {
  onPageChange?: (resultOffset: number) => void
  onNumberOfResultsPerPageChange?: (numberOfResultsPerPage: number) => void
  numberOfResultsPerPage?: number
  resultOffset?: number
  canChangeNumberOfResultsPerPage?: boolean
}

const resultsRanges = ['10', '20', '50'].map((nextLimit) => ({
  label: nextLimit,
  value: nextLimit,
}))

export default function Pagination({
  total = 0,
  onPageChange,
  onNumberOfResultsPerPageChange,
  numberOfResultsPerPage = 10,
  resultOffset = 0,
  canChangeNumberOfResultsPerPage = false,
}: PaginationProps) {
  const nextPage = () =>
    onPageChange && onPageChange(resultOffset + numberOfResultsPerPage)
  const prevPage = () =>
    onPageChange && onPageChange(resultOffset - numberOfResultsPerPage)
  const setPage = (page: number) =>
    onPageChange && onPageChange((page - 1) * numberOfResultsPerPage)
  const setNumberOfResultsPerPage = (option) =>
    onNumberOfResultsPerPageChange &&
    onNumberOfResultsPerPageChange(parseInt(`${option?.value}`, 10))
  const currentPage =
    resultOffset === 0 ? 1 : resultOffset / numberOfResultsPerPage + 1

  return (
    <div className={styles.pagination}>
      <div className={styles.slot}>
        <TotalResults total={total} />
      </div>
      <div className={styles.slotCreateSpace}>
        {canChangeNumberOfResultsPerPage && (
          <Text color="grey-type">Show rows per page</Text>
        )}
      </div>
      <div className={styles.slot}>
        {canChangeNumberOfResultsPerPage && (
          <ReactSelect
            value={numberOfResultsPerPage}
            className={styles.select}
            name="numberOfResultsPerPageSelector"
            id="numberOfResultsPerPageSelector"
            data-testid="numberOfResultsPerPageSelector"
            options={resultsRanges}
            onChange={setNumberOfResultsPerPage}
            clearable={false}
          />
        )}
      </div>
      <div className={styles.slot}>
        <CorePagination
          totalPages={Math.ceil(total / numberOfResultsPerPage)}
          currentPage={currentPage}
          onPageClick={setPage}
          onPrevClick={prevPage}
          onNextClick={nextPage}
        />
      </div>
    </div>
  )
}
