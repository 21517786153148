import React from 'react'
import DayCalendar, { DayCalendarProps } from './DayCalendar'
import WeekCalendar, { WeekCalendarProps } from './WeekCalendar'
import { shouldRenderDayView } from '../../../../Utils'

import { ConsultationTypeName } from '../../../../types/ConsultationTypes'

export interface CalendarProps extends DayCalendarProps, WeekCalendarProps {
  appointmentClinic?: string
  consultationType: ConsultationTypeName
}

export default function Calendar({
  appointmentClinic,
  consultationType,
  selectedDate,
  selectedDateUpdate,
  selectedWeek,
  selectedWeekUpdate,
}: CalendarProps) {
  return (
    <div data-testid="appointment-booking-calendar">
      {shouldRenderDayView(consultationType, appointmentClinic) ? (
        <DayCalendar
          selectedDate={selectedDate}
          selectedDateUpdate={selectedDateUpdate}
        />
      ) : (
        <WeekCalendar
          selectedWeek={selectedWeek}
          selectedWeekUpdate={selectedWeekUpdate}
        />
      )}
    </div>
  )
}
