import { gql } from '@apollo/client'
import { commonPatientFields } from '../../../queries'

export const REGIONS = gql`
  query AP_Regions {
    regions {
      id
      name
      iso_code
    }
  }
`
export const CONSUMER_NETWORKS_QUERY = gql`
  query AP_ConsumerNetworks($patientId: ID!) {
    patientConsumerNetworks(id: $patientId) {
      consumer_network {
        id
        name
      }
      is_preferred
    }
  }
`
export const UPDATE_PATIENT = gql`
  mutation AP_UpdatePatient($id: ID!, $patient: MopPatientInput!) {
    updateMopPatient(id: $id, patient: $patient) {
      ${commonPatientFields}
    }
  }
`

export const STATES_QUERY = gql`
  query AP_RegionStates($regionIso: String!) {
    regionStates(regionIso: $regionIso) {
      code
      name
    }
  }
`
