import { defineMessages } from 'react-intl'

const baseId = 'search_patient_form'

export default defineMessages({
  clear: {
    id: `${baseId}.clear`,
    defaultMessage: 'Clear',
  },
  search: {
    id: `${baseId}.search`,
    defaultMessage: 'Search',
  },
  use_old_portal: {
    id: `${baseId}.use_old_portal`,
    defaultMessage: 'Use old portal',
  },
})
