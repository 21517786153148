import React from 'react'
import { useQuery } from '@apollo/client'
import { Alert, DropdownSelect } from '@babylon/core-ui'
import GetSupplyNetworksQuery from './GetSupplyNetworksQuery'

type SupplyNetwork = {
  id: number
  uuid: string
  name: string
}

interface SupplyNetworksData {
  supplyNetworks: SupplyNetwork[]
}

interface SupplyNetworkSearcherProps {
  onSelect: (id: string, name: string) => void | undefined
}

const SupplyNetworkSearcher = ({ onSelect }: SupplyNetworkSearcherProps) => {
  const { loading, error, data } = useQuery<SupplyNetworksData>(
    GetSupplyNetworksQuery
  )
  const supplyNetworks = loading ? [] : data?.supplyNetworks
  const sortedData = supplyNetworks
    ?.map(({ id, name }) => ({ key: id, value: name }))
    .sort((a, b) => a.value.localeCompare(b.value))

  const handleMakeSelection = (supplyNetwork) => {
    onSelect(supplyNetwork.key, supplyNetwork.value)
  }

  if (error) {
    return <Alert title="There was an error">{error}</Alert>
  }

  return (
    <div className="supply-network-searcher-container">
      {!loading && (
        <DropdownSelect
          data-testid="supply-network-dropdown"
          options={sortedData}
          placeholder="Select supply network"
          onChange={handleMakeSelection}
        />
      )}
    </div>
  )
}

export default SupplyNetworkSearcher
