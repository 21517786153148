import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Cell, Grid, KebabMenu, KebabMenuRenderOptions } from '@babylon/core-ui'
import { ViewMoreToggler } from '../../../../..'
import {
  OnDetailsCopy,
  OnTransferOwnership,
  OnResendInvitationEmail,
} from '../../../types'
import Headline from '../Headline'
import Details from '../Details'
import { TransferOwnershipModal } from './Modals'
import {
  Actions,
  TransferOwnershipButton,
  ResendInvitationEmailButton,
} from './ActionButtons'
import messages from './MemberCard.messages'
import styles from './styles.module.scss'

export interface MemberCardProps extends Member {
  onDetailsCopy: OnDetailsCopy
  onTransferOwnership: OnTransferOwnership
  expanded?: boolean
  isMain?: boolean
  onFamilyMembersToggle?: (id: string) => void
  onResendInvitationEmail: OnResendInvitationEmail
  hasFamilyMembers: boolean
  familyAccount: boolean
  uuid: string
}

export interface AccountActionState {
  action?: Actions
  mutation?: any
}

const MemberCard = ({
  accountOwner,
  address,
  consumerNetworkName,
  coreRubyPatientId,
  dateOfBirth,
  email,
  expanded = false,
  firstName,
  gender,
  hasFamilyMembers,
  healthcareIdentifier,
  id,
  identityCheck,
  invitationWasSentButNotAccepted,
  isMain = false,
  lastName,
  minor,
  onDetailsCopy,
  onFamilyMembersToggle,
  onResendInvitationEmail,
  onTransferOwnership,
  ownerId,
  phoneNumber,
  publicHealthcareAdmission,
  queuedForActivation,
  familyAccount,
}: MemberCardProps) => {
  const [accountAction, setAccountAction] = useState<AccountActionState>({})

  const canBeMadeOwner = !accountOwner && minor === false && !!ownerId

  const canHaveInvitationResent = invitationWasSentButNotAccepted

  const toggleClickHandler = () => {
    onFamilyMembersToggle && onFamilyMembersToggle(id)
  }

  const transferOwnershipHandler = (transferOwnership: any) => () => {
    if (transferOwnership) {
      transferOwnership({
        variables: {
          oldOwnerId: ownerId,
          newOwnerId: coreRubyPatientId,
        },
      })
    }
  }

  const actionHandler = (action: Actions, mutation: any) => () =>
    setAccountAction({ action, mutation })

  const renderKebabMenu = ({ menuItemProps }: KebabMenuRenderOptions) => (
    <div data-testid="member-card-more-menu">
      {canBeMadeOwner && (
        <TransferOwnershipButton
          menuItemProps={menuItemProps}
          onClick={actionHandler}
          onComplete={(data) => {
            onTransferOwnership(data.new_owner_id)
            setAccountAction({})
          }}
          onError={(error) => {
            onTransferOwnership(undefined, error)
            setAccountAction({})
          }}
        />
      )}
      {canHaveInvitationResent && (
        <ResendInvitationEmailButton
          menuItemProps={menuItemProps}
          patientId={coreRubyPatientId}
          onComplete={(data) => {
            onResendInvitationEmail(data.id)
          }}
          onError={(error) => {
            onResendInvitationEmail(undefined, error)
          }}
        />
      )}
    </div>
  )

  return (
    <Grid className={styles.memberCard} columns={12} gap={16}>
      <TransferOwnershipModal
        open={accountAction.action === Actions.TransferOwnership}
        onCancel={() => setAccountAction({})}
        onConfirm={transferOwnershipHandler(accountAction.mutation)}
      />
      <Cell width={12}>
        <Headline
          id={id}
          accountOwner={accountOwner}
          consumerNetworkName={consumerNetworkName}
          coreRubyPatientId={coreRubyPatientId}
          firstName={firstName}
          identityCheck={identityCheck}
          lastName={lastName}
          minor={minor}
          publicHealthcareAdmission={publicHealthcareAdmission}
          queuedForActivation={queuedForActivation}
          familyAccount={familyAccount}
          isPendingRegistration={invitationWasSentButNotAccepted}
        />
      </Cell>
      <div className={styles.actions}>
        {(canBeMadeOwner || canHaveInvitationResent) && (
          <KebabMenu>{renderKebabMenu}</KebabMenu>
        )}
      </div>
      <Cell width={12}>
        <Details
          address={address}
          coreRubyPatientId={coreRubyPatientId}
          dateOfBirth={dateOfBirth}
          email={email}
          gender={gender}
          healthcareIdentifier={healthcareIdentifier}
          onDetailsCopy={onDetailsCopy}
          phoneNumber={phoneNumber}
          uuid={id}
        />
      </Cell>
      {hasFamilyMembers && isMain ? (
        <Cell width={12}>
          <ViewMoreToggler
            open={expanded}
            testId="member-card-view-family-members"
            onToggle={toggleClickHandler}
          >
            {expanded ? (
              <FormattedMessage {...messages.hide_family_members} />
            ) : (
              <FormattedMessage {...messages.show_family_members} />
            )}
          </ViewMoreToggler>
        </Cell>
      ) : null}
    </Grid>
  )
}

export default MemberCard
