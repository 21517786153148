import { useCallback, useRef } from 'react'
import { PatientProfile } from './usePatientProfile'

function sendMessageNotification(sender: PatientProfile | undefined) {
  const body = sender
    ? `You've received a new message from ${sender.familyName.toUpperCase()}, ${sender.givenNames.join(
        ' '
      )}`
    : 'You have received a new message'

  return new Notification('New message', { body })
}

function sendMatchNotification() {
  return new Notification('New match', {
    body:
      'You have been matched with a new patient. Please go to the admin portal to start this conversation',
  })
}

const useEnableNotifications = () => {
  const notificationsEnabledRef = useRef<boolean>(false)

  const requestNotificationPermissions = useCallback(() => {
    if (!('Notification' in window)) {
      return
    }

    notificationsEnabledRef.current = Notification.permission === 'granted'

    if (
      Notification.permission === 'denied' ||
      Notification.permission === 'default'
    ) {
      Notification.requestPermission().then((permission) => {
        notificationsEnabledRef.current = permission === 'granted'
      })
    }
  }, [])
  const doSendMessageNotification = useCallback(
    (sender: PatientProfile | undefined) => {
      if (notificationsEnabledRef.current) {
        sendMessageNotification(sender)
      }
    },
    [notificationsEnabledRef]
  )

  return {
    requestNotificationPermissions,
    notificationsEnabledRef,
    sendMessageNotification: doSendMessageNotification,
    sendMatchNotification,
  }
}

export default useEnableNotifications
