import React from 'react'
import { Text } from '@babylon/core-ui'

import styles from './styles.module.css'

type Props = {
  online?: boolean
}

const WaitingForPair = ({ online = false }: Props) => (
  <div className={styles.container}>
    {online ? (
      <>
        <Text tag="p" size="large">
          You are <b>online</b> and <b>available for chat</b>
        </Text>
        <Text tag="p" size="large">
          Waiting to match you with a member...
        </Text>
      </>
    ) : (
      <>
        <Text tag="p" size="large">
          You are currently <b>offline</b> and <b>unavailable for chat</b>
        </Text>
        <Text tag="p" size="large">
          You won&apos;t be matched with a member
        </Text>
      </>
    )}
  </div>
)

export default WaitingForPair
