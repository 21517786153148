import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Tag, Card, Heading } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { CONSUMER_NETWORKS_QUERY } from '../../../Member/Edit/queries'
import ConsumerNetworkTag from '../../../ConsumerNetworkTag'
import ViewMoreToggler from '../../../ViewMoreToggler'
import { MemberOpsModuleName } from '../../../..'
import RepeatTemplateIcon from '../../icons/RepeatTemplateIcon'
import MediumIcon from '../../MediumIcon'
import {
  getFormattedDate,
  isReorder,
  generateMapLink,
  validateAddress,
} from '../../utils'
import { UserTemplateState, RepeatTemplateCardProps } from '../../../types'
import styles from './styles.module.scss'
import messages from './RepeatTemplateCard.messages'
import Details from './Details'
import Menu from './Menu'

const RepeatTemplateCard = ({
  id,
  patientId,
  daysToReorder,
  drugName,
  duration,
  lastAvailabilityDateTime,
  lastUserChange,
  reviewDate,
  repeatState,
  userState,
  latestPharmacy,
  pastActivitesRefetch,
}: RepeatTemplateCardProps) => {
  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })

  const { data: consumerNetworkData } = useQuery(CONSUMER_NETWORKS_QUERY, {
    variables: { patientId },
  })
  const preferredNetworkName = useMemo(() => {
    if (consumerNetworkData?.patientConsumerNetworks) {
      const preferredNetwork = consumerNetworkData?.patientConsumerNetworks.find(
        (cn) => cn.is_preferred
      )

      return preferredNetwork?.consumer_network?.name
    }

    return ''
  }, [consumerNetworkData])

  const locale = (localStorage.getItem('locale') || 'en_GB').replace('_', '-') // todo: update with intl utilities

  const date =
    isReorder(userState) && lastAvailabilityDateTime ? (
      <FormattedMessage
        {...messages.due_date}
        values={{
          date: getFormattedDate({ date: lastAvailabilityDateTime, locale }),
        }}
      />
    ) : (
      getFormattedDate({ date: lastUserChange, locale })
    )

  const pharmacyLink = latestPharmacy && (
    <>
      <div
        className={styles.RepeatTemplateCard__pharmacyName}
        data-testid="member-timeline-pharmacy-name"
      >
        {validateAddress(latestPharmacy.address) ? (
          <a
            href={generateMapLink(latestPharmacy.address)}
            className={styles.RepeatTemplateCard__pharmacyLink}
            data-testid="member-timeline-pharmacy-link"
            onClick={(e) => {
              trackClick({
                elementName: 'pharmacy-link',
                elementType: TrackingElementType.link,
                href: e.currentTarget.href,
              })
            }}
          >
            {latestPharmacy.name}
          </a>
        ) : (
          latestPharmacy.name
        )}
      </div>
      {latestPharmacy.phoneNumber && (
        <span
          className={styles.RepeatTemplateCard__pharmacyPhoneNumber}
          data-testid="member-timeline-pharmacy-phone-number"
        >
          {latestPharmacy.phoneNumber}
        </span>
      )}
    </>
  )

  const [expanded, setExpanded] = useState<boolean>(false)

  const viewMoreTogglerHandler = () => {
    setExpanded((toggle) => !toggle)
    trackClick({
      elementName: 'view-more-btn',
      elementType: TrackingElementType.button,
    })
  }

  return (
    <div
      className={styles.RepeatTemplateCard__wrapper}
      data-testid="timeline-card"
    >
      <MediumIcon
        icon={
          <RepeatTemplateIcon className={styles.RepeatTemplateCard__icon} />
        }
      />
      <Card className={styles.RepeatTemplateCard}>
        <div className={styles.RepeatTemplateCard__summary}>
          <div>
            <span className={styles.RepeatTemplateCard__date}>{date}</span>
            <RepeatTemplateTag userState={userState} />
          </div>

          <div>
            <ConsumerNetworkTag
              name={preferredNetworkName}
              isPreferred
              className={styles.RepeatTemplateCard__cardNetworkTag}
            />
          </div>

          <div>
            <Heading
              level="h3"
              data-testid="timeline-card-title"
              className={styles.RepeatTemplateCard__title}
            >
              <FormattedMessage {...messages.title} />
            </Heading>
            <span>{drugName}</span>
          </div>

          <div>{pharmacyLink}</div>

          <div className={styles.RepeatTemplateCard__viewMoreCell}>
            <ViewMoreToggler
              testId="timeline-card-show-details"
              open={!!expanded}
              size="small"
              onToggle={viewMoreTogglerHandler}
              className={styles.RepeatTemplateCard__viewMore}
            >
              {fm(messages.show_details)}
            </ViewMoreToggler>
          </div>

          <div className={styles.RepeatTemplateCard__menu}>
            <Menu
              pastActivitesRefetch={pastActivitesRefetch}
              patientId={patientId}
              repeatTemplateId={id}
              userState={userState}
            />
          </div>
        </div>

        <Details
          daysToReorder={daysToReorder}
          duration={duration}
          repeatState={repeatState}
          reviewDate={reviewDate}
          show={expanded}
          userState={userState}
        />
      </Card>
    </div>
  )
}

const RepeatTemplateTag = ({ userState }) => {
  if (userState === UserTemplateState.ORDER_NOW) {
    return null
  }

  const userStateToColorMap: { [k: string]: string } = {
    COLLECTED: 'RepeatTemplateCard__label--success',
    READY_TO_COLLECT: 'RepeatTemplateCard__label--success',
    REORDER_SOON: 'RepeatTemplateCard__label--success',
    REORDER_NOW: 'RepeatTemplateCard__label--warning',
    CHECKING_REORDER: 'RepeatTemplateCard__label--warning',
    FOLLOW_UP_NEEDED: 'RepeatTemplateCard__label--warning',
    STOPPED: 'RepeatTemplateCard__label--error',
  }

  const tagCssClass = userStateToColorMap[userState]
  const userStateKey = userState.toLowerCase()
  const stateMessage =
    userStateKey in messages
      ? messages[userStateKey as keyof typeof messages]
      : null

  return (
    <Tag className={tagCssClass ? styles[tagCssClass] : null}>
      {stateMessage ? (
        <FormattedMessage {...stateMessage} />
      ) : (
        userState.replace(/_/g, ' ')
      )}
    </Tag>
  )
}

export default RepeatTemplateCard
