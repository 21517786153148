import {
  searchableFields,
  parseSearchParams,
  generateFullPhoneNumber,
  SearchableField,
} from './utils'
import { Command } from './actions'

export type Params = { [key: string]: any }
export type Errors = any[]

interface State {
  searchableFields: SearchableField[]
  isLoading: boolean
  searchParams: any | null
  errors: Errors
  params: Params
  page?: number | null
  per_page?: number | null
}

export const initialState: State = {
  searchableFields,
  isLoading: false,
  searchParams: null,
  errors: [],
  params: {},
}

const completeSearch = (state: State): State => ({
  ...state,
  isLoading: false,
  errors: [],
})

const completePageLoad = (state: State, params: Params): State => ({
  ...state,
  isLoading: false,
  searchParams: null,
  params: {
    ...state.params,
    ...params,
    full_phone_number: params ? generateFullPhoneNumber(params) : null,
  },
})

const updateForm = (state: State, params: Params): State => ({
  ...state,
  searchParams: null,
  params: {
    ...params,
    full_phone_number: params ? generateFullPhoneNumber(params) : null,
  },
})

const updateField = (state: State, name: string, value: any): State => ({
  ...state,
  params: {
    ...state.params,
    [name]: value,
  },
})

const updateSearch = (state: State, params: Params): State => ({
  ...state,
  isLoading: true,
  errors: [],
  searchParams: parseSearchParams({
    ...state.params,
    ...state.searchParams,
    ...params,
  }),
})

const clear = (): State => ({
  ...initialState,
})

const setErrors = (state: State, errors: Errors): State => ({
  ...state,
  errors,
})

export default (state: State, action: Command): State => {
  switch (action.type) {
    case 'SET_ERRORS':
      return setErrors(state, action.errors)
    case 'COMPLETE_SEARCH':
      return completeSearch(state)
    case 'COMPLETE_PAGE_LOAD':
      return completePageLoad(state, action.decryptedPatientsSearchFilters)
    case 'UPDATE_FORM':
      return updateForm(state, action.params)
    case 'UPDATE_FIELD':
      return updateField(state, action.name, action.value)
    case 'SUBMIT_SEARCH':
      return updateSearch(state, { ...action.params, per_page: 10, page: 1 })
    case 'UPDATE_ROWS_PER_PAGE':
      return updateSearch(state, { per_page: action.per_page, page: 1 })
    case 'UPDATE_PAGE':
      return updateSearch(state, { page: action.page })
    case 'CLEAR_SEARCH':
      return clear()
    default:
      return state
  }
}
