import React, { useCallback, useState, useMemo } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useMutation, useQuery, ApolloError } from '@apollo/client'

import { logException, useSnackBar, Header } from '../../../../..'
import LoadingRelatedPersonsCard from './LoadingRelatedPersonsCard'
import NoContent from './NoContent'

import messages from './RelatedPersons.messages'
import RelatedPerson from './RelatedPerson'
import styles from './RelatedPersonsCard.module.scss'
import AddGuardian from './AddGuardian'
import { CarersProps, Relationship } from './types'
import RenderConfirmationDialog from './RenderConfirmationDialog'
import {
  CARERS_QUERY,
  GetUuid,
  RELATIONSHIPS_QUERY,
  REMOVE_CARER,
  UPDATE_PRIMARY_GUARDIAN,
} from './queries'

const sortByPrimary = (carers = []) =>
  [...carers].sort((relationship: any) => !relationship.isPrimary || -1)

const RelatedPersons = ({
  patientId,
  relationshipsAmountToShow = 10,
}: CarersProps) => {
  const fm = useFormatMessage()
  const { setSnackbarMessage } = useSnackBar()
  const [relationType, setRelationType] = useState('')
  const [carerToRemove, setCarerToRemove] = useState('')
  const [carerToPrimary, setCarerToPrimary] = useState('')
  // get patient babylon id
  const { data, loading } = useQuery(GetUuid, {
    notifyOnNetworkStatusChange: true,
    variables: { id: patientId },
  })
  const uuid = data?.patient?.uuid || null
  // Set graphQL query options
  const queryOptions = {
    notifyOnNetworkStatusChange: true,
    variables: { id: uuid },
    skip: !uuid,
    onError: (error: ApolloError) => logException(error),
  }
  // Get all member's dependants
  const {
    loading: dependantsLoading,
    error: dependantsError,
    data: dependantsData,
  } = useQuery(RELATIONSHIPS_QUERY, queryOptions)
  // Get all member's carers
  const {
    loading: carersLoading,
    error: carersError,
    data: carersData,
  } = useQuery(CARERS_QUERY, queryOptions)
  const onFinally = useCallback(
    ({ action = 'set_primary', type = 'success', error }) => {
      // log error
      if (error) {
        logException(error)
      }

      setSnackbarMessage(
        fm(
          messages[`${action}_${relationType}_${type}` as keyof typeof messages]
        ),
        undefined,
        type
      )

      setRelationType('')
      setCarerToRemove('')
      setCarerToPrimary('')
    },
    [fm, setSnackbarMessage, relationType]
  )
  // remove guardian mutation
  const [removeGuardianMutation, { loading: removing }] = useMutation(
    REMOVE_CARER,
    {
      onError: (error) => onFinally({ action: 'remove', type: 'error', error }),
      onCompleted: () => onFinally({ action: 'remove' }),
      refetchQueries: ['AP_GetCarers'],
      awaitRefetchQueries: true,
    }
  )
  // Set carer as primary
  const [setPrimaryCarerMutation, { loading: setting }] = useMutation(
    UPDATE_PRIMARY_GUARDIAN,
    {
      onError: (error) => onFinally({ type: 'error', error }),
      onCompleted: () => onFinally({}),
      refetchQueries: ['AP_GetCarers'],
      awaitRefetchQueries: true,
    }
  )

  // Render no content
  const renderNoContent = (message: string = 'no_relationships') => (
    <div className={styles.RelatedPersonCard__noContent}>
      <NoContent testId="member-alerts-no-carers" type={message}>
        {fm(messages[message as keyof typeof messages])}
      </NoContent>
    </div>
  )

  const renderConfirmationModal = useMemo(() => {
    const removeGuardianHandler = () =>
      removeGuardianMutation({
        variables: {
          relationshipId: carerToRemove,
        },
      })

    return (
      relationType && (
        <RenderConfirmationDialog
          loading={removing}
          onClose={[setRelationType, setCarerToRemove]}
          onConfirm={removeGuardianHandler}
          title={`remove_${relationType}_confirm_title`}
          description="remove_carer_confirm_description"
        />
      )
    )
  }, [carerToRemove, relationType, removeGuardianMutation, removing])

  const renderSetPrimaryCarerConfirmationModal = useMemo(() => {
    const setPrimaryCarer = () =>
      setPrimaryCarerMutation({
        variables: {
          dependantId: patientId,
          dependantUuid: uuid,
          carerRelationId: carerToPrimary,
        },
      })

    return (
      relationType && (
        <RenderConfirmationDialog
          loading={setting}
          onClose={[setRelationType, setCarerToPrimary]}
          onConfirm={setPrimaryCarer}
          title={`set_primary_${relationType}_confirm_title`}
          description="set_primary_carer_confirm_description"
        />
      )
    )
  }, [
    patientId,
    uuid,
    relationType,
    carerToPrimary,
    setPrimaryCarerMutation,
    setting,
  ])

  const showRelatedPersons = (
    relationships: Relationship[],
    testid: string
  ) => {
    const isPrimaryAdultFamily =
      relationships.length === 1 &&
      relationships[0].type.includes('ADULT-FAMILY')

    return relationships
      .slice(0, relationshipsAmountToShow)
      .map((relationship: any) => {
        const relationshipType =
          (relationship.type.includes('GUARDIAN') && 'guardian') || 'carer'

        return (
          <RelatedPerson
            key={relationship.id || relationship.dependant}
            relationId={relationship.id || relationship.dependant}
            {...relationship}
            actions={
              (isPrimaryAdultFamily || !relationship.isPrimary) &&
              !!relationship.id && [
                ...((!relationship.isPrimary && [
                  {
                    label: fm(
                      messages[
                        `set_primary_${relationshipType}_button_label` as keyof typeof messages
                      ]
                    ),
                    disabled: removing || setting,
                    handler: (relationId: any) => {
                      setRelationType(relationshipType)
                      setCarerToPrimary(relationId)
                    },
                  },
                ]) ||
                  []),
                {
                  label: fm(
                    messages[
                      `remove_${relationshipType}_button_label` as keyof typeof messages
                    ]
                  ),
                  disabled: removing || setting,
                  handler: (relationId: any) => {
                    setRelationType(relationshipType)
                    setCarerToRemove(relationId)
                  },
                },
              ]
            }
            testid={testid}
          />
        )
      })
  }

  const carersSortedByRelationship = sortByPrimary(carersData?.carers)

  return (
    <div
      className={styles.RelatedPersonCard__wrapper}
      data-testid="related_persons_card"
    >
      {!loading && !uuid && renderNoContent('error')}
      <Header
        title={fm(messages.carers_header)}
        info={fm(messages.carers_tooltip)}
      />
      {(loading || carersLoading) && <LoadingRelatedPersonsCard />}
      {!loading && carersError && renderNoContent('error')}
      {!loading && !carersLoading && !carersError && (
        <>
          <AddGuardian dependantId={patientId} disabled={removing} />
          {carersData?.carers.length
            ? showRelatedPersons(
                carersSortedByRelationship,
                'carers-relationships'
              )
            : renderNoContent()}
          {carerToRemove && renderConfirmationModal}
          {carerToPrimary && renderSetPrimaryCarerConfirmationModal}
        </>
      )}
      <Header
        title={fm(messages.dependants_header)}
        info={fm(messages.dependants_tooltip)}
      />
      {(loading || dependantsLoading) && <LoadingRelatedPersonsCard />}
      {!loading && dependantsError && renderNoContent('error')}
      {!loading &&
        !dependantsLoading &&
        !dependantsError &&
        (dependantsData?.relationships.length
          ? showRelatedPersons(
              dependantsData?.relationships,
              'dependants-relationships'
            )
          : renderNoContent())}
    </div>
  )
}

export default RelatedPersons
