import { useEffect, useState, useCallback } from 'react'
import { get, snakeCase } from 'lodash/fp'
import Cookie from 'js-cookie'
import { useBabylonUser } from '@babylon/babylon-user'
import { useProductConfigProps } from './types'

const KEY_BASE = 'product-config'
const getStorageKey = (
  userUuid: string,
  contractId?: string,
  shouldUseContractId?: boolean
) =>
  shouldUseContractId
    ? `${KEY_BASE}-contract-id-${contractId}`
    : `${KEY_BASE}-${userUuid}`

const useProductConfig = (
  { getConfig, shouldUseContractId }: useProductConfigProps,
  contractId?: string
) => {
  const { partnerIds: partnerList, uuid } = useBabylonUser()

  const localStorageKey = getStorageKey(uuid, contractId, shouldUseContractId)

  const [cache, setCache] = useState(
    JSON.parse(localStorage.getItem(localStorageKey) || '{}')
  )

  useEffect(() => {
    if (
      Object.keys(cache).length === 0 &&
      (!shouldUseContractId || contractId)
    ) {
      const updateCacheAndStorage = (data) => {
        setCache(data)
        localStorage.setItem(localStorageKey, JSON.stringify(data))
      }
      const fetchData = async () =>
        updateCacheAndStorage(await getConfig(partnerList))
      const fetchDataWithContractId = async () =>
        updateCacheAndStorage(
          await getConfig(undefined, undefined, contractId, true)
        )

      if (contractId) {
        fetchDataWithContractId()

        return
      }

      fetchData()
    }
  }, [
    getConfig,
    cache,
    partnerList,
    localStorageKey,
    contractId,
    shouldUseContractId,
  ])

  const determineBetaFeatureValue = <T = boolean>(data, propName): T => {
    const mainFeatureValue = data[propName]
    const betaFeatureValue = data.betaFeatures[propName]
    const cookieFeatureValue = (propName) => Cookie.get(snakeCase(propName))

    return mainFeatureValue === false && betaFeatureValue === true
      ? cookieFeatureValue(propName)
      : mainFeatureValue
  }

  const getBlob = useCallback(
    (pathName: string) => {
      const data = get(pathName, cache)

      if (data?.hasOwnProperty('betaFeatures')) {
        return Object.keys(data.betaFeatures).reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: determineBetaFeatureValue(data, curr),
          }),
          data
        )
      }

      return data || {}
    },
    [cache]
  )

  const getProp = useCallback(
    <T = boolean>(pathName: string, propName: string): T | false => {
      const data = get(pathName, cache)

      if (data?.hasOwnProperty('betaFeatures')) {
        const isPropExperiment = data.betaFeatures.hasOwnProperty(propName)

        if (isPropExperiment) {
          return determineBetaFeatureValue<T>(data, propName)
        }
      }

      return get(propName, data) ?? false
    },
    [cache]
  )

  return {
    getBlob,
    getProp,
  }
}

export default useProductConfig
