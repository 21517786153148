import React from 'react'
import { KebabMenu } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import messages from './TestResults.messages'
import styles from './MemberTaskMenu.module.scss'

interface MemberTaskMenuProps {
  onClickViewHistory: () => void
}

export default function MemberTaskMenu({
  onClickViewHistory,
}: MemberTaskMenuProps) {
  const fm = useFormatMessage()

  return (
    <KebabMenu containerClassName={styles.MedKitTheme}>
      {() => (
        <button onClick={onClickViewHistory} className={styles.menuButton}>
          {fm(messages.view_history)}
        </button>
      )}
    </KebabMenu>
  )
}
