import React, { useState, useReducer } from 'react'
import { useQuery } from '@apollo/client'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { Grid, Cell } from '@babylon/core-ui'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import {
  DrawerView,
  Tag,
  TagTypes,
  TagVariants,
  ViewMoreToggler,
} from '../../../../../..'
import messages from './AppointmentCredits.messages'
import styles from './AppointmentCredits.module.scss'
import { QUERY_APPOINTMENT_CREDITS } from './queries'
import AddAppointmentCreditModal from './AddAppointmentCreditModal'
import RemoveAppointmentCreditModal from './RemoveAppointmentCreditModal'
import { MemberOpsModuleName } from '../../../../../../..'

type AppointmentCredit = {
  id: string
  is_redeemed: boolean | null
  consumer_network_name: string | null
  show_reason: string | null
  professions: string[] | null
  created_at: string
  created_by: {
    id: string
    fullName: string
  }
}

type Props = {
  patientId: string
  toggleVisible: () => void
  visible: boolean
}

const AppointmentCreditDetails = ({
  createdAt,
  createdBy,
  reason,
  labelledById,
  isExpanded = false,
  removeCode,
}: {
  reason: string | null
  createdAt: string
  createdBy: string
  labelledById: string
  isExpanded: boolean
  removeCode: () => void
}) => {
  const fm = useFormatMessage()
  const Date = () => (
    <FormattedDate
      timeZone="UTC"
      day="2-digit"
      month="short"
      year="numeric"
      value={createdAt}
    />
  )
  const Time = () => <FormattedTime value={createdAt} />

  const expandedStyle = `${
    isExpanded ? styles.AppointmentCreditEntry__DetailsExpanded : ''
  }`
  const classes = `${styles.AppointmentCreditEntry__Details} ${expandedStyle}`

  return (
    <div
      id={labelledById}
      className={classes}
      aria-hidden={!isExpanded}
      data-testid="details-region"
    >
      <Grid columns={3}>
        <Cell>
          <h3>{fm(messages.credit_details_date_heading)}</h3>
          <p data-testid="date-time-created">
            <FormattedMessage
              tagName="span"
              {...messages.credit_details_date_time_created}
              values={{
                date: <Date />,
                time: <Time />,
              }}
            />
          </p>
        </Cell>
        <Cell>
          <h3>{fm(messages.credit_details_created_by_heading)}</h3>
          <strong>{createdBy}</strong>
        </Cell>
        <Cell>
          {reason && (
            <>
              <h3>{fm(messages.credit_details_reason_heading)}</h3>
              <span>{reason}</span>
            </>
          )}
        </Cell>
      </Grid>
      <div className={styles.AppointmentCreditEntry__DetailsActions}>
        <ButtonSimple
          data-testid="remove-credit-action"
          className={styles.AppointmentCreditEntry__DetailsRemove}
          variant={ButtonVariant.destructive}
          onClick={removeCode}
        >
          {fm(messages.credit_details_remove)}
        </ButtonSimple>
      </div>
    </div>
  )
}

const AppointmentCreditEntry = ({
  id,
  consumer_network_name,
  is_redeemed,
  professions,
  created_at,
  created_by,
  show_reason,
  removeCode,
}: AppointmentCredit & {
  removeCode: (id: string) => void
}) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false)

  const professionList = professions ? professions.join(', ') : null

  const redemptionStatus = is_redeemed
    ? messages.redeemed_yes
    : messages.redeemed_no

  const labelledById = `details-label-${id}`

  return (
    <div className={styles.AppointmentCreditEntry}>
      <Grid templateColumns="1fr 1fr 1fr 24px">
        <Cell className={styles.ConsumerNetworkTag}>
          {consumer_network_name && (
            <Tag label={consumer_network_name} data-testid="network-tag" />
          )}
        </Cell>
        <Cell>{professionList}</Cell>
        <Cell>
          <Tag
            label={fm(redemptionStatus)}
            type={is_redeemed ? TagTypes.SUCCESS : TagTypes.ERROR}
            variant={TagVariants.OUTLINE}
            data-testid="status-tag"
          />
        </Cell>
        <Cell>
          <ViewMoreToggler
            className={styles.AppointmentCreditEntry__ToggleDetails}
            ariaControls={labelledById}
            testId="toggle-details"
            open={isDetailsExpanded}
            onToggle={() => {
              setIsDetailsExpanded(!isDetailsExpanded)
              trackClick({
                elementName: `${
                  isDetailsExpanded ? 'close' : 'open'
                }-view-more-appointment-credits-btn`,
                elementType: TrackingElementType.button,
              })
            }}
          >
            <span>{fm(messages.toggle_details)}</span>
          </ViewMoreToggler>
        </Cell>
      </Grid>
      <AppointmentCreditDetails
        labelledById={labelledById}
        isExpanded={isDetailsExpanded}
        createdAt={created_at}
        createdBy={created_by.fullName}
        reason={show_reason}
        removeCode={() => removeCode(id)}
      />
    </div>
  )
}

type DrawerState = {
  addCreditModalOpened: boolean
  removeCreditModalOpened: boolean
  selectedCreditId: string | null
}

type ReducerAction = {
  type: string
  selectedCreditId?: string
}

const initialDrawerState = {
  addCreditModalOpened: false,
  removeCreditModalOpened: false,
  selectedCreditId: null,
} as DrawerState

const ADD_CREDIT = 'add-credit'
const REMOVE_CREDIT = 'remove-credit'
const CLOSE_MODAL = 'close-modal'

function reducer(state: DrawerState, action: ReducerAction) {
  switch (action.type) {
    case REMOVE_CREDIT:
      return {
        ...state,
        removeCreditModalOpened: true,
        selectedCreditId: action.selectedCreditId,
      } as DrawerState
    case ADD_CREDIT:
      return {
        ...state,
        addCreditModalOpened: true,
      }
    case CLOSE_MODAL:
    default:
      return initialDrawerState
  }
}

const AppointmentCredits = ({ patientId, toggleVisible, visible }: Props) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })

  const fm = useFormatMessage()
  const { data, refetch } = useQuery(QUERY_APPOINTMENT_CREDITS, {
    variables: {
      patientId,
    },
  })

  const [drawerState, dispatch] = useReducer(reducer, initialDrawerState)

  const entries = data?.appointmentCredits.map((entry: AppointmentCredit) => (
    <AppointmentCreditEntry
      key={entry.id}
      {...entry}
      removeCode={(selectedCreditId: string) => {
        dispatch({ type: REMOVE_CREDIT, selectedCreditId })
        trackClick({
          elementName: 'remove-appointment-credit-btn',
          elementType: TrackingElementType.button,
        })
      }}
    />
  ))

  return (
    <DrawerView
      title={fm(messages.appointment_credits_heading)}
      visible={visible}
      toggleVisible={toggleVisible}
    >
      <div className={styles.AppointmentCredits}>
        <div className={styles.AppointmentCreditEntries}>
          <Grid
            templateColumns="1fr 1fr 1fr 24px"
            className={styles.TableHeading}
          >
            <Cell>{fm(messages.consumer_network_heading)}</Cell>
            <Cell>{fm(messages.professions_heading)}</Cell>
            <Cell>{fm(messages.redeemed_heading)}</Cell>
          </Grid>
          <div data-testid="appointment-credits-entries">{entries}</div>
        </div>
        <footer className={styles.AppointmentCreditActions}>
          <ButtonSimple
            variant={ButtonVariant.primary}
            type="submit"
            className={styles.AddCreditButton}
            onClick={() => {
              dispatch({ type: ADD_CREDIT })
              trackClick({
                elementName: 'open-add-credit-modal-btn',
                elementType: TrackingElementType.button,
              })
            }}
          >
            {fm(messages.add_credit_button)}
          </ButtonSimple>
          <AddAppointmentCreditModal
            patientId={patientId}
            isOpened={drawerState.addCreditModalOpened}
            onAddCreditSuccess={() => refetch()}
            onClose={() => dispatch({ type: CLOSE_MODAL })}
          />
          <RemoveAppointmentCreditModal
            patientId={patientId}
            appointmentCreditId={drawerState.selectedCreditId}
            isOpened={drawerState.removeCreditModalOpened}
            onRemovalSuccess={() => refetch()}
            onClose={() => dispatch({ type: CLOSE_MODAL })}
          />
        </footer>
      </div>
    </DrawerView>
  )
}

export default AppointmentCredits
