/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type GetHumanitySyncShiftItemsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
  offset?: Types.Maybe<Types.Scalars['Int']>
  limit?: Types.Maybe<Types.Scalars['Int']>
  timezone?: Types.Maybe<Types.Scalars['String']>
  filters?: Types.Maybe<Types.HumanitySyncShiftItemFilters>
}>

export type GetHumanitySyncShiftItemsQuery = {
  humanitySyncShiftItems: Pick<
    Types.HumanitySyncShiftItems,
    'total' | 'limit' | 'offset'
  > & {
    shifts: Array<
      Pick<
        Types.HumanitySyncShiftItem,
        | 'shift_id'
        | 'consultant_id'
        | 'consultant_name'
        | 'errors'
        | 'warnings'
        | 'shift_pattern'
        | 'shift_start'
        | 'shift_end'
      >
    >
  }
}

export const GetHumanitySyncShiftItemsDocument = gql`
  query GetHumanitySyncShiftItems(
    $id: ID!
    $offset: Int
    $limit: Int
    $timezone: String
    $filters: HumanitySyncShiftItemFilters
  ) {
    humanitySyncShiftItems(
      id: $id
      offset: $offset
      limit: $limit
      timezone: $timezone
      filters: $filters
    ) {
      total
      limit
      offset
      shifts {
        shift_id
        consultant_id
        consultant_name
        errors
        warnings
        shift_pattern
        shift_start
        shift_end
      }
    }
  }
`
export function useGetHumanitySyncShiftItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHumanitySyncShiftItemsQuery,
    GetHumanitySyncShiftItemsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetHumanitySyncShiftItemsQuery,
    GetHumanitySyncShiftItemsQueryVariables
  >(GetHumanitySyncShiftItemsDocument, baseOptions)
}
export function useGetHumanitySyncShiftItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHumanitySyncShiftItemsQuery,
    GetHumanitySyncShiftItemsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetHumanitySyncShiftItemsQuery,
    GetHumanitySyncShiftItemsQueryVariables
  >(GetHumanitySyncShiftItemsDocument, baseOptions)
}
export type GetHumanitySyncShiftItemsQueryHookResult = ReturnType<
  typeof useGetHumanitySyncShiftItemsQuery
>
export type GetHumanitySyncShiftItemsLazyQueryHookResult = ReturnType<
  typeof useGetHumanitySyncShiftItemsLazyQuery
>
export type GetHumanitySyncShiftItemsQueryResult = Apollo.QueryResult<
  GetHumanitySyncShiftItemsQuery,
  GetHumanitySyncShiftItemsQueryVariables
>
