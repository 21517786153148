import React from 'react'
import { Grid, Cell } from '@babylon/core-ui'
import { LoadingHeader, LoadingBlock } from '../LoadingContent'
import styles from './Timeline.module.scss'

const sections = ['outstanding', 'upcoming', 'past']

const LoadingTimeline = () => (
  <div data-testid="loading-timeline">
    <LoadingHeader />
    <Grid rowGap={32}>
      {sections.map((section) => (
        <Cell key={section}>
          <Grid rowGap={16}>
            <LoadingBlock className={styles.loadingBlock} />
            <LoadingBlock className={styles.loadingBlock} />
          </Grid>
        </Cell>
      ))}
    </Grid>
  </div>
)

export default LoadingTimeline
