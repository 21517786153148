import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { KebabMenu, KebabMenuRenderOptions } from '@babylon/core-ui'
import { envUrl, envVar } from '@babylon/babylon-env'
import { useProductConfig } from '@babylon/product-config'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { useQuery } from '@apollo/client'
import { MemberOpsModuleName } from '../../../../..'
import { SetSnackbarMessage } from '../../../types'
import { DeletePatientCheckButton } from './DeletePatientCheckButton'
import { FixLoginIssuesButton } from './FixLoginIssuesButton'
import { OverrideIdentityCheckButton } from './OverrideIdentityCheckButton'
import { ResetPasswordButton } from './ResetPasswordButton'
import messages from './ProfileHeader.messages'
import styles from './ProfileHeader.module.scss'
import { MFA_GQL } from './queries'
import {
  getMfaStatus,
  getMfaEnrollmentId,
  EnrollmentTypes,
} from './utils/mfaDataSelector'
import DeleteSmsMfaButton from './DeleteSmsMfaButton'

export interface ProfileHeaderKebabMenuProps {
  member: Member
  setSnackbarMessage: SetSnackbarMessage
}

const ProfileHeaderKebabMenu = ({
  member,
  setSnackbarMessage,
}: ProfileHeaderKebabMenuProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profilesHeader,
  })

  const {
    id,
    uuid,
    email,
    account_owner_id,
    accounts_count,
    family_accounts,
    identity_check,
  } = member

  const hasFamilyAccounts = !!(
    (family_accounts && family_accounts.length) ||
    account_owner_id ||
    (accounts_count && accounts_count > 0)
  )

  const { getBlob } = useProductConfig()
  const {
    memberDeleteEnabled = envVar('DELETE_PATIENT_BUTTON') || false,
    fixLoginIssuesEnabled = envVar('FIX_LOGIN_ISSUES_BUTTON') || false,
    memberIdentityCheckEnabled,
    viewFamilyMembersEnabled,
    smsMfaMaintenanceEnabled,
  } = getBlob('memberOperations')

  const { data } = useQuery(MFA_GQL, {
    context: { clientName: 'platform-gateway' },
    variables: { id: uuid, first: 1 },
    skip: !smsMfaMaintenanceEnabled,
  })

  const isMFAActive = data?.user?.isMFAActive
  const mfaEnrollments = data?.user?.mfaEnrollments
  const isSmsMfaActive = getMfaStatus({
    isMFAActive,
    mfaEnrollments,
    enrollmentType: EnrollmentTypes.sms,
  })
  const smsMfaEnrollmentId = getMfaEnrollmentId(
    mfaEnrollments,
    EnrollmentTypes.sms
  )

  return (
    <KebabMenu
      trackingEvent={() => {
        trackClick({
          elementName: 'profile-header-kebab-menu-btn',
          elementType: TrackingElementType.button,
        })
      }}
    >
      {({ menuItemProps }: KebabMenuRenderOptions) => (
        <div
          className={styles.actionsMoreMenu}
          data-testid="member-profile-actions-more-menu"
        >
          <Link
            {...menuItemProps}
            data-testid="member-profile-edit-button"
            to={`/admin/patients/${member.id}/edit`}
            onClick={(e) => {
              trackClick({
                elementName: 'member-profile-edit-link',
                elementType: TrackingElementType.link,
                href: e.currentTarget.href,
              })
            }}
          >
            <FormattedMessage {...messages.edit_profile} />
          </Link>
          <ResetPasswordButton
            email={email}
            setSnackbarMessage={setSnackbarMessage}
            menuItemProps={menuItemProps}
          />
          {fixLoginIssuesEnabled && (
            <FixLoginIssuesButton
              patientUuid={uuid}
              email={email}
              setSnackbarMessage={setSnackbarMessage}
              menuItemProps={menuItemProps}
            />
          )}
          {memberIdentityCheckEnabled && identity_check?.check_id && (
            <a
              {...menuItemProps}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://onfido.com/dashboard/v2/#/checks/${identity_check.check_id}/reports`}
              onClick={(e) => {
                trackClick({
                  elementName: 'view-in-onfido-link',
                  elementType: TrackingElementType.link,
                  href: e.currentTarget.href,
                })
              }}
            >
              <FormattedMessage {...messages.view_on_onfido} />
            </a>
          )}
          <OverrideIdentityCheckButton
            patientId={id}
            identityCheck={identity_check}
            setSnackbarMessage={setSnackbarMessage}
            menuItemProps={menuItemProps}
          />
          {viewFamilyMembersEnabled && (
            <a
              {...menuItemProps}
              data-testid="member-profile-view-family-members"
              href={`${envUrl(
                'ADMIN_PORTAL_FRAME_LEGACY_PORTAL_URL'
              )}admin/patients/${id}/family_accounts`}
              onClick={(e) => {
                trackClick({
                  elementName: `${
                    hasFamilyAccounts
                      ? 'view-family-members-link'
                      : 'add-family-members-link'
                  }`,
                  elementType: TrackingElementType.button,
                  href: e.currentTarget.href,
                })
              }}
            >
              {hasFamilyAccounts ? (
                <FormattedMessage {...messages.view_family_members} />
              ) : (
                <FormattedMessage {...messages.add_family_members} />
              )}
            </a>
          )}
          {smsMfaMaintenanceEnabled && isSmsMfaActive && smsMfaEnrollmentId && (
            <DeleteSmsMfaButton
              smsMfaEnrollmentId={smsMfaEnrollmentId}
              menuItemProps={menuItemProps}
              setSnackbarMessage={setSnackbarMessage}
              patientUuid={uuid}
            />
          )}
          {memberDeleteEnabled && (
            <DeletePatientCheckButton
              patientId={id}
              setSnackbarMessage={setSnackbarMessage}
              menuItemProps={menuItemProps}
            />
          )}
        </div>
      )}
    </KebabMenu>
  )
}

export default ProfileHeaderKebabMenu
