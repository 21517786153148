import React, { useState } from 'react'
import { Button } from '@babylon/core-ui'
import { format as formatDate } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useApolloClient } from '@apollo/client'
import { usePractitionerQuery } from '../Practitioner.federated.hooks'
import {
  AppointmentSortOption,
  SortOrder,
  AppointmentStatus,
} from '../AppointmentManagementPage.types'
import {
  buildFederatedPractitionerId,
  formatPractitioner,
  formatPractitionerAppointments,
} from '../AppointmentManagementPage.utils'
import type { Appointment } from '../AppointmentManagementPage.types'
import {
  PatientDocument,
  PatientQuery,
  PatientQueryVariables,
} from '../Appointment/Patient.middleware.hooks'
import { stringQuoteOnlyIfNecessaryFormatter } from './DownloadCSV.utils'
import styles from './DownloadCSV.module.css'

const APPOINTMENT_LIMIT = 200
const TIME_FORMAT = 'HH:mm'
const DATE_FORMAT = 'dd-MM-yyyy'

export interface DownloadCSVProps {
  practitionerId: string
  startDate: string
  endDate: string
  timezone: string
}

const DownloadCSV = ({
  practitionerId,
  startDate,
  endDate,
  timezone,
}: DownloadCSVProps) => {
  const [fetchingCSVInfo, setFetchingCSVInfo] = useState(false)
  const apolloClient = useApolloClient()

  const filterVariables = {
    date: {
      GTE: startDate,
      LTE: endDate,
    },
    status: { ONE_OF: [AppointmentStatus.Booked, AppointmentStatus.Fulfilled] },
  }
  const practitionerVariables = {
    id: buildFederatedPractitionerId(practitionerId),
    first: APPOINTMENT_LIMIT,
    appointmentSort: [
      {
        field: AppointmentSortOption.Date,
        order: SortOrder.Asc,
      },
    ],
    filter: filterVariables,
  }

  const { data: practitionerData, loading } = usePractitionerQuery({
    context: {
      clientName: 'platform-gateway',
    },
    variables: practitionerVariables,
    skip: !fetchingCSVInfo,
  })

  const getApptConsumerNetworkName = async (
    patientId?: string | null,
    patientConsumerNetworkId?: string | null
  ) => {
    if (!patientId || !patientConsumerNetworkId) {
      return ''
    }

    const { data: patientData } = await apolloClient.query<
      PatientQuery,
      PatientQueryVariables
    >({
      query: PatientDocument,
      variables: {
        patientId,
        withNoticeDecisions: false,
      },
    })

    const consumerNetwork = patientData?.patientConsumerNetworks.find(
      (patientConsumerNetwork) =>
        patientConsumerNetwork?.consumer_network?.uuid ===
        patientConsumerNetworkId
    )?.consumer_network

    return consumerNetwork?.name || ''
  }

  const download = (data: string, fileName: string) => {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: 'text/csv' })

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')

    // Passing the blob downloading url
    a.setAttribute('href', url)

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', `${fileName}.csv`)

    // Performing a download with click
    a.click()
  }

  const getCsvRow = async (appointment: Appointment) => {
    let consumerNetworkName

    try {
      consumerNetworkName = await getApptConsumerNetworkName(
        appointment.patientId,
        appointment.patientConsumerNetworkId
      )
    } catch (error) {
      consumerNetworkName = 'error fetching network'
    }

    const time = formatDate(
      utcToZonedTime(appointment.startTime || '', timezone),
      TIME_FORMAT
    )

    const duration = `${appointment.durationInMinutes} min`

    const formatedPatientName = stringQuoteOnlyIfNecessaryFormatter(
      appointment.patientName || ''
    )
    const formatedConsumerNetworkName = stringQuoteOnlyIfNecessaryFormatter(
      consumerNetworkName
    )

    const row = `${time}, ${duration}, ${formatedPatientName}, ${appointment.medium}, ${formatedConsumerNetworkName}`

    return row
  }

  const csvBuilder = async (appointments: Appointment[]) => {
    const headers = [
      'Appointment Time',
      'Appointment Duration',
      'Patient Name',
      'Medium',
      'Consumer Network',
    ]

    const promises = appointments.map(getCsvRow)
    const csvRows = [headers.join(','), ...(await Promise.all(promises))]

    return csvRows.join('\n')
  }

  if (fetchingCSVInfo && !loading) {
    const practitioner = formatPractitioner(practitionerData?.practitioner)
    const appointments = formatPractitionerAppointments(
      practitionerData?.practitioner
    )

    const date = formatDate(utcToZonedTime(startDate, timezone), DATE_FORMAT)
    const fileName = `${practitioner.fullName}_${date}`

    csvBuilder(appointments)
      .then((data) => {
        download(data, fileName)
      })
      .finally(() => {
        setFetchingCSVInfo(false)
      })
  }

  return (
    <Button
      type="button"
      intent="secondary"
      onClick={() => {
        setFetchingCSVInfo(true)
      }}
      loading={fetchingCSVInfo}
      className={styles.downloadButton}
      data-testid="download-csv"
    >
      Download CSV
    </Button>
  )
}

export default DownloadCSV
