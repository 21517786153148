import React, { ComponentType } from 'react'
import ModalProvider from './ModalProvider'
import { IModalProviderProps } from './ModalProvider.types'

const withModalProvider = function <T>(
  callbackOrProps?: ((props: T) => IModalProviderProps) | IModalProviderProps
) {
  return (Component: ComponentType<T>) => (props: T) => {
    let providerModalProps: IModalProviderProps = {}

    if (typeof callbackOrProps === 'function') {
      providerModalProps = callbackOrProps(props)
    } else if (callbackOrProps) {
      providerModalProps = callbackOrProps
    }

    return (
      <ModalProvider {...providerModalProps}>
        <Component {...props} />
      </ModalProvider>
    )
  }
}

export default withModalProvider
