import React from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import Unknown from '../../../Unknown'
import { Maybe, RepeatTemplateState, UserTemplateState } from '../../../types'
import {
  getFormattedDate,
  isFollowUpWithGP,
  isFollowUpWithPharmicist,
  isReorder,
} from '../../utils'
import messages from './RepeatTemplateCard.messages'
import styles from './styles.module.scss'

export interface DetailsProps {
  daysToReorder: Maybe<number>
  duration: Maybe<number>
  repeatState: Maybe<RepeatTemplateState>
  reviewDate: Maybe<string>
  show: boolean
  userState: UserTemplateState
}

const showDuration = (userState: UserTemplateState) =>
  userState === UserTemplateState.REORDER_NOW ||
  userState === UserTemplateState.READY_TO_COLLECT ||
  userState === UserTemplateState.ORDER_NOW

const showNextReview = (userState: UserTemplateState) =>
  isReorder(userState) || userState === UserTemplateState.READY_TO_COLLECT

const getStatusMessage = (
  state: Maybe<RepeatTemplateState>,
  userState: UserTemplateState
): MessageDescriptor => {
  if (userState === UserTemplateState.FOLLOW_UP_NEEDED) {
    if (isFollowUpWithGP(state)) {
      return messages.follow_up_needed_gp
    }
    if (isFollowUpWithPharmicist(state)) {
      return messages.follow_up_needed_pharmicist
    }
    return messages.full_follow_up_needed
  }

  return messages[`full_${userState.toLowerCase()}` as keyof typeof messages]
}

const getPatientNote = (
  state: Maybe<RepeatTemplateState>,
  userState: UserTemplateState
): MessageDescriptor | void => {
  if (userState === UserTemplateState.FOLLOW_UP_NEEDED) {
    if (isFollowUpWithGP(state)) {
      return messages.notes_follow_up_needed_gp
    }
    if (isFollowUpWithPharmicist(state)) {
      return messages.notes_follow_up_needed_pharmacist
    }
  }

  return messages[`notes_${userState.toLowerCase()}` as keyof typeof messages]
}

const Details = ({
  daysToReorder,
  duration,
  reviewDate,
  repeatState,
  show,
  userState,
}: DetailsProps) => {
  if (!show) {
    return null
  }

  const patientNote = getPatientNote(repeatState, userState)
  const reorderDaysLeft =
    userState === UserTemplateState.REORDER_SOON ? (
      <div data-testid="timeline-card-days-to-reorder">
        <FormattedMessage
          {...messages.days_to_reorder}
          values={{ days: daysToReorder }}
        />
      </div>
    ) : null
  const medicationDuration = showDuration(userState) ? (
    <>
      <label className={styles.Details__label}>
        <FormattedMessage {...messages.duration} />
      </label>
      <div
        className={styles.Details__value}
        data-testid="timeline-card-duration"
      >
        {typeof duration === 'number' ? (
          <FormattedMessage {...messages.duration_days} values={{ duration }} />
        ) : (
          <Unknown />
        )}
      </div>
    </>
  ) : null

  const locale = (localStorage.getItem('locale') || 'en_GB').replace('_', '-') // todo: update with intl utilities

  return (
    <div
      className={styles.Details__section}
      data-testid="timeline-card-more-details"
    >
      <div>
        <label className={styles.Details__label}>
          <FormattedMessage {...messages.status} />
        </label>
        <div
          className={styles.Details__value}
          data-testid="timeline-card-status"
        >
          <FormattedMessage {...getStatusMessage(repeatState, userState)} />
          {reorderDaysLeft}
        </div>

        {medicationDuration}
      </div>

      <div>
        {showNextReview(userState) && (
          <div>
            <label className={styles.Details__label}>
              <FormattedMessage {...messages.next_review} />
            </label>
            <div
              className={styles.Details__value}
              data-testid="timeline-card-next-review"
            >
              {getFormattedDate({ date: reviewDate, locale })}
            </div>
          </div>
        )}

        <label className={styles.Details__label}>
          <FormattedMessage {...messages.notes} />
        </label>
        <div
          className={styles.Details__value}
          data-testid="timeline-card-notes"
        >
          {patientNote ? <FormattedMessage {...patientNote} /> : null}
        </div>
      </div>
    </div>
  )
}

export default Details
