import { GridRenderCellParams } from '@mui/x-data-grid'
import { ReactElement } from 'react'

export type CssValue = number | string
export type Dimensions = {
  /* Width of the table */
  width?: CssValue
  /* Height of the table */
  height?: CssValue
  /* Size according to the content */
  dynamic?: boolean
}

export type SortOrder = 'asc' | 'desc'
export type SortRule = {
  column: string
  order: SortOrder
}

export type RowSpacing = {
  bottom?: number
  top?: number
}

export type PaginationState = {
  /* Set the number of rows in one page. Default is 100. */
  pageSize: number

  /* Set the number of rows in one page. Default is 0. */
  page: number
}

export type Action = {
  /* String to display when shown in the dropdown menu */
  text: string

  /* Icon to be displayed for the action */
  icon: JSX.Element

  /* Click event handler */
  onClick: (row: RowData) => void

  /* Indicates whether the action is only displayed in the dropdown menu */
  onlyShowInDropdownMenu: boolean

  /* The type of the action to display in the row */
  type: ActionType
}

export enum ActionType {
  DELETE = 'delete',
  EDIT = 'edit',
  VIEW = 'view',
}

export type Pagination = {
  /* Mode of pagination, the default is client */
  mode?: 'client' | 'server'

  /* Starting number of rows per page */
  initialPageSize?: number

  /* Initial page to load, 0-indexed */
  initialPage?: number

  /* Dropdown to select page size by the user */
  pageSizeOptions?: number[]

  /* Maximum number of rows, in case of server pagination */
  rowCount?: number

  /* Used for server pagination. It fires the callback when the user changes the page */
  handlePagination?: (state: PaginationState) => void

  /* Use to control pagination */
  paginationState?: PaginationState
}

/* Definition of a column */
export type ColumnDefinition = {
  /* Name of the field that is in the row data */
  fieldNameInRow: string

  /* Text displayed in the 1st row of the column */
  headerName?: string

  /* Displayed as a tooltip when hovering over the column header */
  description?: string

  /* Minimum width of a column, default: 50px */
  minWidth?: number

  /* Exact width of a column */
  width?: number

  /* Maximum width of a column */
  maxWidth?: number

  /* Prevent or allow to resize a column */
  resizable?: boolean

  flex?: number

  /* Custom renderer for the column */
  renderCell?: (params: GridRenderCellParams<Date>) => ReactElement
}

/* Settings affecting all rows */
export type RowsSettings = {
  /* Desired height of all rows */
  height?: number

  /* If true each row has a height that accommodates its content */
  dynamicHeight?: boolean

  /* Space between rows */
  rowSpacing?: RowSpacing
}

export type RowData = {
  /* Unique ID of a row */
  id: string

  [fieldName: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}
