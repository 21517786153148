import React from 'react'

import { Button, Text } from '@babylon/core-ui'

import EMPTY_VIEW_COPY from './constants'

import styles from './ChooseClinician.module.css'

export interface ChooseClinicianProps {
  clinicianType: 'first' | 'covering'
  onClinicianButtonClick: () => void
}

export default function ChooseClinician({
  clinicianType,
  onClinicianButtonClick,
}: ChooseClinicianProps) {
  return (
    <>
      <Button
        onClick={onClinicianButtonClick}
        className={styles.chooseClinicianButton}
        dataTestId={`choose-${clinicianType}-clinician`}
      >
        {EMPTY_VIEW_COPY[clinicianType].button}
      </Button>
      <Text align="center" className={styles.chooseClinicianText} tag="div">
        {EMPTY_VIEW_COPY[clinicianType].subtext}
      </Text>
    </>
  )
}
