import React from 'react'
// import { format, parseISO } from 'date-fns'
import { Heading } from '@babylon/core-ui'
import { useFormatMessage, useIntl } from '@babylon/intl'
import { FormattedMessage } from 'react-intl'
import Entry from '../Entry'
import EntryV2 from '../EntryV2'
import TimelineBlock from '../Timeline/TimelineBlock'
import styles from './EntriesTimeline.module.scss'
import { Entry as EntryType } from '../types'
import messages from './messages'

export interface ConversationsProps {
  entries: EntryType[]
  onEntryClick: (entry: EntryType) => void
  displayEntriesInTimeline?: boolean
}

export default function EntriesTimeline({
  entries,
  onEntryClick,
  displayEntriesInTimeline,
}: ConversationsProps) {
  const fm = useFormatMessage()
  const intl = useIntl()

  const groupedByMonth = entries.reduce(
    (agg: { [key: string]: EntryType[] }, entry) => {
      const time = entry?.time
      const monthYear = intl.formatDate(time, {
        year: 'numeric',
        month: 'long',
      })
      agg[monthYear] = agg[monthYear] || []
      // TODO: sort by time after push
      agg[monthYear].push(entry)

      return agg
    },
    {}
  )

  const currentDate = new Date()
  const lastMonth = new Date()
  lastMonth.setMonth(currentDate.getMonth() - 1)
  const recentDateParsed = intl.formatDate(currentDate, {
    year: 'numeric',
    month: 'long',
  })
  const lastMonthParsed = intl.formatDate(lastMonth, {
    year: 'numeric',
    month: 'long',
  })

  const groupedByTimeCategory = entries.reduce(
    (agg: { [key: string]: EntryType[] }, entry) => {
      const time = entry?.time

      const monthYear = intl.formatDate(time, {
        year: 'numeric',
        month: 'long',
      })
      agg.recent = agg.recent || []
      agg.lastMonth = agg.lastMonth || []
      agg.history = agg.history || []

      if (monthYear === recentDateParsed) {
        agg.recent.push(entry)
      } else if (monthYear === lastMonthParsed) {
        agg.lastMonth.push(entry)
      } else {
        agg.history.push(entry)
      }

      return agg
    },
    {}
  )

  return (
    <div
      data-testid="entries-timeline"
      className={styles.EntriesTimelineWrapper}
    >
      {!displayEntriesInTimeline &&
        Object.entries(groupedByMonth).map(([monthYear, entriesPerMonth]) => (
          <div
            key={monthYear}
            className={styles.EntriesTimelineGroup}
            data-testid="entries-timeline-group"
          >
            <Heading className={styles.EntriesTimelineGroup__heading}>
              {monthYear}
            </Heading>
            <div className={styles.EntriesTimelineGroup_list}>
              {entriesPerMonth.map((entry) => (
                <Entry
                  data-testid="entries-timeline-group__entry"
                  key={entry?.id}
                  text={entry?.text}
                  time={entry?.time}
                  tag={entry?.tag}
                  type={entry?.type}
                  userUuid={entry?.userUuid}
                  patientUuid={entry?.patientUuid}
                  onClick={entry.id ? () => onEntryClick(entry) : undefined}
                />
              ))}
            </div>
          </div>
        ))}

      {displayEntriesInTimeline &&
        Object.entries(groupedByTimeCategory).map(
          ([category, entriesPerCategory]) => (
            <TimelineBlock
              key={category}
              testId="entries-timeline-group"
              title={fm(messages[`${category}`])}
              info={fm(messages[`${category}_tooltip`])}
            >
              <div className={styles.EntriesTimelineGroup_list}>
                {!entriesPerCategory.length && (
                  <div>
                    <FormattedMessage
                      {...messages[`no_conversation_${category}`]}
                    />
                  </div>
                )}
                {entriesPerCategory &&
                  entriesPerCategory.map((entry) => (
                    <EntryV2
                      data-testid="entries-timeline-group__entry"
                      key={entry?.id}
                      text={entry?.text}
                      time={entry?.time}
                      tag={entry?.tag}
                      type={entry?.type}
                      userUuid={entry?.userUuid}
                      patientUuid={entry?.patientUuid}
                      onClick={entry.id ? () => onEntryClick(entry) : undefined}
                    />
                  ))}
              </div>
            </TimelineBlock>
          )
        )}
    </div>
  )
}
