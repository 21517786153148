import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Cell, Grid, Heading } from '@babylon/core-ui'
import { AlertFilled, TickCircle } from '@babylon/icons'
import { envFlag } from '@babylon/babylon-env'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { Unknown, MemberOpsModuleName } from '../../../../..'

import { Address } from '../../../../CopiableMemberInformation'
import { PDSStatuses } from '../../../../../util/pdsStatus'
import { SetSnackbarMessage } from '../../../types'
import { fullPatientFields } from '../../../../../queries'
import { PATIENT_QUERY } from '../../../../../queries/timeline/queries'
import EmergencyContact from '../EmergencyContact'
import FormattedDate, { FormattedDateProps } from '../FormattedDate'
import PDSStatus from '../PDSStatus'
import messages from './MoreDetails.messages'
import styles from './MoreDetails.module.scss'

export interface MoreDetailsProps {
  memberId: string
  setSnackbarMessage: SetSnackbarMessage
  informedConsentDecisionDate?: string
  medicalHistoryAuthorizationDecisionDate?: string
}

const generateAddressKey = (val: string | null, idx: number) => `${val}-${idx}`

const PatientInfoDateEntry = ({
  date,
  titleKey,
  testId,
}: {
  titleKey: keyof typeof messages
  date: FormattedDateProps['value']
  testId?: string
}) => (
  <div className={styles.moreDetails__block} data-testid={testId}>
    <Heading level="h3">
      <FormattedMessage {...messages[titleKey]} />
    </Heading>
    <div data-testid={testId ? `${testId}-details` : undefined}>
      {date ? <FormattedDate value={date} /> : <Unknown />}
    </div>
  </div>
)

const MoreDetails = ({
  memberId,
  setSnackbarMessage,
  informedConsentDecisionDate,
  medicalHistoryAuthorizationDecisionDate,
}: MoreDetailsProps) => {
  const isLegalConsentPreviewEnabled: boolean = !!envFlag(
    'ENABLE_MEMBER_LEGAL_CONSENT_PREVIEW',
    false
  )

  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })
  const { data } = useQuery(PATIENT_QUERY(fullPatientFields), {
    variables: { patientId: memberId },
  })

  const member = get(data, 'patient')

  if (!member) {
    return null
  }

  const {
    birth_details,
    demographics,
    emergency_contact_details,
    exact_match,
    gp_details,
    id,
    public_healthcare_details,
    previous_addresses,
    previous_practice_name,
    timezone_id,
  } = member

  const pdsStatus = demographics ? (demographics.status as PDSStatuses) : null
  const lookupLimit = demographics
    ? demographics.lookup_retry_attempts_left || 0
    : 0

  const onRetryPDSLookup = (message: string[], status?: string) => {
    const intent = status ? 'primary' : 'error'
    const icon = intent === 'primary' ? TickCircle : AlertFilled
    trackClick({
      elementName: 'retry-pds-lookup-btn',
      elementType: TrackingElementType.button,
    })
    setSnackbarMessage(message[0], icon, intent)
  }

  return (
    <div className={styles.moreDetails} data-testid="more-details">
      <Grid className={styles.profileContent} columns={12} columnGap={16}>
        <Cell width={2}>
          <div
            className={styles.moreDetails__block}
            data-testid="town-of-birth"
          >
            <Heading level="h3">
              <FormattedMessage {...messages.town_of_birth} />
            </Heading>
            <div data-testid="town-of-birth-details">
              {birth_details && birth_details.town ? (
                birth_details.town
              ) : (
                <Unknown />
              )}
            </div>
          </div>
          <div
            className={styles.moreDetails__block}
            data-testid="country-of-birth"
          >
            <Heading level="h3">
              <FormattedMessage {...messages.country_of_birth} />
            </Heading>
            <div data-testid="country-of-birth-details">
              {birth_details && birth_details.country ? (
                birth_details.country
              ) : (
                <Unknown />
              )}
            </div>
          </div>
          <div className={styles.moreDetails__block} data-testid="arrival-date">
            <Heading level="h3">
              <FormattedMessage {...messages.arrival_date} />
            </Heading>
            <div>
              {public_healthcare_details &&
              public_healthcare_details.arrival_date ? (
                <FormattedDate value={public_healthcare_details.arrival_date} />
              ) : (
                <Unknown />
              )}
            </div>
          </div>
          {envFlag('ENABLE_APPOINTMENT_TIMEZONE') && (
            <div className={styles.moreDetails__block} data-testid="timezone">
              <Heading level="h3">
                <FormattedMessage {...messages.timezone} />
              </Heading>
              <div>{timezone_id || <Unknown />}</div>
            </div>
          )}
        </Cell>
        <Cell width={4}>
          <Heading level="h3">
            <FormattedMessage {...messages.previous_addresses} />
          </Heading>
          <div data-testid="previous-addresses">
            {previous_addresses && previous_addresses.length ? (
              previous_addresses.map((address, idx) => (
                <div
                  className={styles.moreDetails__block}
                  data-testid={`previous-address-${idx + 1}`}
                  key={generateAddressKey(address.first_line, idx)}
                >
                  <Address
                    line1={address.first_line}
                    line2={address.second_line}
                    line3={address.third_line}
                    county={address.county}
                    postcode={address.post_code}
                    town={address.town}
                    onAddressCopy={setSnackbarMessage}
                  />
                </div>
              ))
            ) : (
              <Unknown />
            )}
          </div>
        </Cell>
        <Cell width={3}>
          <div
            className={styles.moreDetails__block}
            data-testid="emergency-contacts"
          >
            <Heading level="h3">
              <FormattedMessage {...messages.emergency_contacts} />
            </Heading>
            {emergency_contact_details.length ? (
              emergency_contact_details.map(
                ({
                  first_name,
                  last_name,
                  phone_country_code,
                  phone_number,
                }) => (
                  <EmergencyContact
                    key={`${first_name}${last_name}${phone_number}`}
                    name={`${first_name} ${last_name}`}
                    phoneNumber={`${phone_country_code} ${phone_number}`}
                    onPhoneNumberCopy={setSnackbarMessage}
                  />
                )
              )
            ) : (
              <Unknown />
            )}
          </div>
          <div className={styles.moreDetails__block} data-testid="disability">
            <Heading level="h3">
              <FormattedMessage {...messages.disability} />
            </Heading>
            <div data-testid="disability-details">
              {public_healthcare_details &&
              public_healthcare_details.disability ? (
                public_healthcare_details.disability
              ) : (
                <Unknown />
              )}
            </div>
          </div>
          <PatientInfoDateEntry
            titleKey="armed_forces_enlisting_date"
            testId="armed-forced-enlisting-date"
            date={public_healthcare_details?.armed_forces_enlisting_date}
          />
          {isLegalConsentPreviewEnabled ? (
            <PatientInfoDateEntry
              titleKey="informed_consent_date"
              date={informedConsentDecisionDate}
              testId="informed-consent-date"
            />
          ) : null}
          {isLegalConsentPreviewEnabled ? (
            <PatientInfoDateEntry
              titleKey="medication_history_auth_consent_date"
              testId="medHistory-consent-date"
              date={medicalHistoryAuthorizationDecisionDate}
            />
          ) : null}
        </Cell>
        <Cell width={3}>
          <div className={styles.moreDetails__block}>
            <PDSStatus
              exactMatch={exact_match}
              patientId={id}
              status={pdsStatus}
              lookupLimit={lookupLimit}
              onRetryPDSLookup={onRetryPDSLookup}
            />
          </div>
          <div className={styles.moreDetails__block} data-testid="gp-name">
            <Heading level="h3">
              <FormattedMessage {...messages.gp_name} />
            </Heading>
            <div data-testid="gp-name-details">
              {gp_details && gp_details.gp_name ? (
                gp_details.gp_name
              ) : (
                <Unknown />
              )}
            </div>
          </div>
          <div className={styles.moreDetails__block} data-testid="gp-address">
            <Heading level="h3">
              <FormattedMessage {...messages.gp_address} />
            </Heading>
            <div data-testid="gp-address-details">
              <Address
                line1={gp_details.gp_address_first_line}
                line2={gp_details.gp_address_second_line}
                line3={gp_details.gp_address_third_line}
                postcode={gp_details.gp_address_post_code}
                onAddressCopy={setSnackbarMessage}
              />
            </div>
          </div>

          <div
            className={styles.moreDetails__block}
            data-testid="gp-surgery-name"
          >
            <Heading level="h3">
              <FormattedMessage {...messages.gp_surgery_name} />
            </Heading>
            <div data-testid="gp-surgery-name-details">
              {gp_details?.gp_surgery_name ?? <Unknown />}
            </div>
          </div>
          <div
            className={styles.moreDetails__block}
            data-testid="gp-surgery-phone-number"
          >
            <Heading level="h3">
              <FormattedMessage {...messages.gp_surgery_phone_number} />
            </Heading>
            <div data-testid="gp-surgery-phone-number-details">
              {gp_details?.gp_surgery_phone_number ?? <Unknown />}
            </div>
          </div>

          <div
            className={styles.moreDetails__block}
            data-testid="previous-healthcare-authorities"
          >
            <Heading level="h3">
              <FormattedMessage {...messages.previous_healthcare_authorities} />
            </Heading>
            <div data-testid="previous-healthcare-authorities-details">
              {previous_practice_name || <Unknown />}
            </div>
          </div>
        </Cell>
      </Grid>
    </div>
  )
}

export default MoreDetails
