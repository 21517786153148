/* eslint-disable curly */
/* eslint-disable padding-line-between-statements */
import { useCallback, useEffect } from 'react'

type Options = {
  enabled?: boolean
  up?: () => void
  down?: () => void
  esc?: () => void
  enter?: () => void
}

const useKeys = ({
  enabled = true,
  up = () => undefined,
  down = () => undefined,
  esc = () => undefined,
  enter = () => undefined,
}: Options) => {
  const keyHandler = useCallback(
    (e) => {
      if (!enabled) return

      switch (e.key) {
        case 'ArrowUp':
          up()
          break
        case 'ArrowDown':
          down()
          break
        case 'Escape':
          esc()
          break
        // on enter call onQueueSelect with right queue
        case 'Enter': {
          enter()
          break
        }
        default:
          break
      }
    },
    [enabled, up, down, esc, enter]
  )

  useEffect(() => {
    window.addEventListener('keydown', keyHandler)
    return () => {
      window.removeEventListener('keydown', keyHandler)
    }
  }, [keyHandler])
}

export default useKeys
