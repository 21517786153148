import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  TextField,
  Typography,
} from '@mui/material'
import { AppointmentWaitTimeStatusType } from '@babylon/graphql-middleware-types'
import messages from './AppointmentWaitTimeConfigsSaveDialog.messages'
import { ConsumerNetworkSearcher } from '../ConsumerNetworkSearcher'
import { ServiceTypesSearcher } from '../ServiceTypesSearcher'
import {
  AppointmentWaitTimeConfigsSaveDialogProps,
  AppointmentWaitTimeConfigsSaveDialogOperations,
} from './AppointmentWaitTimeConfigsSaveDialog.types'
import {
  getDialogTitle,
  validateWaitTimeThresholdInputFormat,
  convertToMinutes,
  validateWaitTimeConfigsInput,
} from './AppointmentWaitTimeConfigsSaveDialog.utils'
import { Option } from '../../components/InputFilter'
import { useSaveAppointmentWaitTimeConfigsMutation } from './SaveAppointmentWaitTimeConfig.hooks'
import { useUpdateAppointmentWaitTimeConfigsMutation } from './UpdateAppointmentWaitTimeConfig.hooks'
import { getErrorStatusCode } from '../Utils/GraphQLUtils'

const insertOperation = AppointmentWaitTimeConfigsSaveDialogOperations.Insert
const updateOperation = AppointmentWaitTimeConfigsSaveDialogOperations.Update
const AppointmentWaitTimeConfigsSaveDialog = ({
  isOpen,
  operation,
  consumerNetwork,
  serviceType,
  handleSaveWaitTimeConfigsAfterActions,
  handleSaveDialogClickClose,
}: AppointmentWaitTimeConfigsSaveDialogProps) => {
  const [selectedConsumerNetworks, setSelectedConsumerNetworks] = useState<
    Option[]
  >([])
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<Option[]>([])
  const [critical, setCritical] = useState('')
  const [overloaded, setOverloaded] = useState('')
  const [busy, setBusy] = useState('')
  const [errorMessage, setErrorMesssage] = useState('')
  const handleCriticalOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCritical(event.target.value)
  }
  const handleOverloadedOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOverloaded(event.target.value)
  }
  const handleBusyOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBusy(event.target.value)
  }
  const resetWaitTimeConfigsInput = () => {
    setSelectedConsumerNetworks([])
    setSelectedServiceTypes([])
    setCritical('')
    setOverloaded('')
    setBusy('')
    setErrorMesssage('')
  }
  const [saveWaitTimeConfigs] = useSaveAppointmentWaitTimeConfigsMutation({
    variables: {
      input: {
        consumer_network_id: Number(
          selectedConsumerNetworks.map(({ id }) => id)[0]
        ),
        service_type_uuid: selectedServiceTypes.map(({ uuid }) => uuid)[0],
        wait_time_status_thresholds: [
          {
            type: AppointmentWaitTimeStatusType.Critical,
            value: convertToMinutes(critical),
          },
          {
            type: AppointmentWaitTimeStatusType.Overloaded,
            value: convertToMinutes(overloaded),
          },
          {
            type: AppointmentWaitTimeStatusType.Busy,
            value: convertToMinutes(busy),
          },
        ],
      },
    },
    onCompleted: () => {
      resetWaitTimeConfigsInput()
      handleSaveWaitTimeConfigsAfterActions('201')
    },
    onError: (error) => {
      resetWaitTimeConfigsInput()
      handleSaveWaitTimeConfigsAfterActions(getErrorStatusCode(error))
    },
  })

  const [updateWaitTimeConfigs] = useUpdateAppointmentWaitTimeConfigsMutation({
    variables: {
      input: {
        consumer_network_id: Number(consumerNetwork?.id),
        service_type_uuid: serviceType?.id,
        wait_time_status_thresholds: [
          {
            type: AppointmentWaitTimeStatusType.Critical,
            value: convertToMinutes(critical),
          },
          {
            type: AppointmentWaitTimeStatusType.Overloaded,
            value: convertToMinutes(overloaded),
          },
          {
            type: AppointmentWaitTimeStatusType.Busy,
            value: convertToMinutes(busy),
          },
        ],
      },
    },
    onCompleted: () => {
      resetWaitTimeConfigsInput()
      handleSaveWaitTimeConfigsAfterActions('204')
    },
    onError: (error) => {
      resetWaitTimeConfigsInput()
      handleSaveWaitTimeConfigsAfterActions(getErrorStatusCode(error))
    },
  })

  const handleOnInsert = async () => {
    const selectedConsumerNetworkIds = selectedConsumerNetworks.map(
      ({ id }) => id
    )
    const selectedServiceTypeUuids = selectedServiceTypes.map(
      ({ uuid }) => uuid
    )
    const validationResult = validateWaitTimeConfigsInput(
      selectedConsumerNetworkIds,
      selectedServiceTypeUuids,
      critical,
      overloaded,
      busy
    )

    if (!validationResult.isValid) {
      setErrorMesssage(validationResult.message)

      return
    }

    await saveWaitTimeConfigs()
  }

  const handleOnUpdate = async () => {
    const selectedConsumerNetworkIds = consumerNetwork?.id
      ? [consumerNetwork.id]
      : []
    const selectedServiceTypeUuids = serviceType?.id ? [serviceType.id] : []
    const validationResult = validateWaitTimeConfigsInput(
      selectedConsumerNetworkIds,
      selectedServiceTypeUuids,
      critical,
      overloaded,
      busy
    )

    if (!validationResult.isValid) {
      setErrorMesssage(validationResult.message)

      return
    }

    await updateWaitTimeConfigs()
  }

  const handleOnSave = async () => {
    if (operation === insertOperation) {
      handleOnInsert()
    } else if (operation === updateOperation) {
      handleOnUpdate()
    }
  }

  const handleOnClose = async () => {
    resetWaitTimeConfigsInput()
    handleSaveDialogClickClose()
  }

  const createWaitTimeThresholdTextField = (
    label: string,
    value: string,
    handleOnchange: React.ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const isWaitTimeThresholdValid = validateWaitTimeThresholdInputFormat(value)
    const helperText = isWaitTimeThresholdValid
      ? ''
      : messages.waitTimeThresholdTextFieldErrorMessage.defaultMessage

    return (
      <TextField
        error={!isWaitTimeThresholdValid}
        required
        id="outlined-required"
        label={label}
        helperText={helperText}
        onChange={handleOnchange}
      />
    )
  }

  const createWaitTimeThresholdErrorMessage = () =>
    errorMessage !== '' ? (
      <Box display="flex" gap="15px" paddingTop={4}>
        <Typography variant="body2" component="h2" style={{ color: '#d32f2f' }}>
          {errorMessage}
        </Typography>
      </Box>
    ) : undefined

  const isOperationInsert = (operation: string) => operation !== insertOperation

  return (
    <Dialog open={isOpen} onClose={handleOnClose} maxWidth="xl">
      <DialogTitle>{getDialogTitle(operation)}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {messages.consumerNetworkServiceTypeFormTitle.defaultMessage}
        </DialogContentText>
        <Box display="flex" gap="15px" paddingTop={2} paddingBottom={4}>
          <ConsumerNetworkSearcher
            readOnly={isOperationInsert(operation)}
            multiple={isOperationInsert(operation)}
            defaultValue={
              isOperationInsert(operation) ? [consumerNetwork] : undefined
            }
            onSelectedConsumerNetworksChanged={(consumerNetworks) => {
              setSelectedConsumerNetworks(consumerNetworks)
            }}
          />
          <ServiceTypesSearcher
            readOnly={isOperationInsert(operation)}
            multiple={isOperationInsert(operation)}
            defaultValue={
              isOperationInsert(operation) ? [serviceType] : undefined
            }
            onSelectedServiceTypesChanged={(serviceTypes) => {
              setSelectedServiceTypes(serviceTypes)
            }}
          />
        </Box>
        <DialogContentText>
          {messages.waitTimeThresholdsFormTitle.defaultMessage}
        </DialogContentText>
        <Box display="flex" gap="15px" paddingTop={2}>
          {createWaitTimeThresholdTextField(
            messages.criticalTextFieldTitle.defaultMessage,
            critical,
            handleCriticalOnChange
          )}
          {createWaitTimeThresholdTextField(
            messages.overloadedTextFieldTitle.defaultMessage,
            overloaded,
            handleOverloadedOnChange
          )}
          {createWaitTimeThresholdTextField(
            messages.busyTextFieldTitle.defaultMessage,
            busy,
            handleBusyOnChange
          )}
        </Box>
        {createWaitTimeThresholdErrorMessage()}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          style={{ textTransform: 'none' }}
          color="secondary"
          onClick={handleOnClose}
        >
          {messages.cancelButtonTitle.defaultMessage}
        </Button>
        <Button
          autoFocus
          variant="outlined"
          style={{ textTransform: 'none' }}
          color="secondary"
          onClick={handleOnSave}
        >
          {messages.saveButtonTitle.defaultMessage}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AppointmentWaitTimeConfigsSaveDialog
