import { defineMessages } from 'react-intl'

const baseId = 'duration'

export default defineMessages({
  duration: {
    id: `${baseId}.duration`,
    defaultMessage:
      '{hours, plural, =0 {} one {{hours} hr} other {{hours} hrs}} {minutes, plural, =0 {} one {{minutes} min} other {{minutes} mins}}',
  },
})
