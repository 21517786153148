import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'
import { onError } from '@apollo/client/link/error'
import AvailabilitySyncPage from './AvailabilitySyncPage'
import AvailabilitySyncShiftsPage from './AvailabilitySyncShiftsPage'
import OrphanedAppointmentsPage from './OrphanedAppointmentsPage'
import { ClinicianCalendarPage } from '../pages/ClinicianCalendarPage'
import { routes } from '../constants/routes'

interface Props extends RouteComponentProps {}

const AvailabilitySync = ({ match: { path } }: Props) => {
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.error(graphQLErrors)
    }

    if (networkError) {
      console.error(networkError)
    }
  })

  return (
    <Switch>
      <Route
        exact
        path="/admin/availability_sync/:syncId"
        component={AvailabilitySyncShiftsPage}
      />
      <Route
        exact
        path="/admin/orphaned_appointments_new"
        component={OrphanedAppointmentsPage}
      />
      <Route
        exact
        path={routes.clinicianCalendar}
        component={ClinicianCalendarPage}
      />
      <Route exact path={path} component={AvailabilitySyncPage} />
    </Switch>
  )
}

export default AvailabilitySync
