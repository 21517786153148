import Radio from './Radio'
import RadioGroup from './RadioGroup'
import RadioGroupV2 from './RadioGroupV2'

import withField, { withValidationFields } from '../withField'

const RadioGroupField = withField(RadioGroup)
const RadioGroupWithValidation = withValidationFields(RadioGroup)
// This is a temporary version that fixes a radio button initial value issue.
// As the fix affects more components a proper solution would require time and
// attention.
const RadioGroupFieldV2 = withField(RadioGroupV2)

export {
  Radio,
  RadioGroup,
  RadioGroupField,
  RadioGroupFieldV2,
  RadioGroupWithValidation,
}
