import * as Yup from 'yup'

export const digitalAppointmentFormValidation = Yup.object().shape({
  notes_for_member: Yup.string()
    .trim()
    .required('Please set a reason for the appointment'),
  appointment_type: Yup.string()
    .trim()
    .required('Please select an appointment type'),
  duration_minutes: Yup.string().trim().required('Please select a duration'),
})
