import { eachDayOfInterval } from 'date-fns'
import { format } from 'date-fns-tz'

import {
  DataSlot,
  GroupedSlots,
  Header,
  flattenClinicianAvailability,
  formatHeaderDate,
  getTimezoneInfoFromUTCTimestamp,
} from './GroupSlots'

export const buildWeekHeaders = (dates: Date[] = []): Header[] =>
  dates.map((date) => ({
    key: format(date, 'yyyy-MM-dd'),
    ...formatHeaderDate(date),
  }))

const groupByDay = (
  slots: DataSlot[] = [],
  startDate: string,
  endDate: string,
  selectedTimezoneId: string = '',
  selectedClinicId?: string,
  selectedClinicianId?: string
): GroupedSlots => {
  const slotIndex = {}
  const times = new Set<string>()

  slots.forEach((slot) => {
    const { date, time } = getTimezoneInfoFromUTCTimestamp(
      slot.UTCTimestamp,
      selectedTimezoneId
    )

    let consultantsAvailability = slot.consultantsAvailability || []

    if (selectedClinicianId) {
      consultantsAvailability = consultantsAvailability.filter(
        (availability) =>
          `${availability.consultant.id}` === selectedClinicianId
      )
    }

    if (selectedClinicId) {
      consultantsAvailability = consultantsAvailability.filter(
        (availability) => `${availability.clinicId}` === selectedClinicId
      )
    }

    const consultants = consultantsAvailability.map(
      flattenClinicianAvailability
    )

    if (consultants.length > 0) {
      times.add(time)

      slotIndex[date] = slotIndex[date] || {}
      slotIndex[date][time] = {
        available: slot.available,
        consultants,
        date,
        time,
        UTCTimestamp: slot.UTCTimestamp,
      }

      if (selectedClinicId) {
        slotIndex[date][time].clinicId = selectedClinicId
      }
    }
  })

  const dates = eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  })

  return {
    headers: buildWeekHeaders(Array.from(dates)),
    times: Array.from(times).sort(),
    slotIndex,
  }
}

export default groupByDay
