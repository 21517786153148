import { defineMessages } from 'react-intl'

const baseId = 'alert_card'

export default defineMessages({
  remove_alert_button: {
    id: `${baseId}.remove_alert_button`,
    defaultMessage: 'Remove patient alert',
  },
})
