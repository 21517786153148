import { useQuery } from '@apollo/client'
import { useProductConfig } from '@babylon/product-config'

import logException from './logException'
import { Messages } from '../localization'
import GetBookAppointmentData from './GetBookAppointmentData'
import { AppointmentFields } from '../../../AppointmentFields'

interface ErrorArgs {
  consultantTypes?: any[]
  consultationTypes?: any[]
  error?: any
  loading?: boolean
  enableRecordingConsent?: boolean
}

interface UseBookAppointmentArgs {
  includingDigital?: boolean
  memberId: string | number
  filters: Pick<
    AppointmentFields,
    | 'allowed_mediums'
    | 'allowed_professions'
    | 'consultation_type'
    | 'consultant_type'
    | 'chaperone_required'
  >
}

const updateErrorMessage = ({
  consultantTypes,
  consultationTypes,
  error,
  loading,
  enableRecordingConsent,
}: ErrorArgs) => {
  if (
    !loading &&
    (error ||
      !consultantTypes?.length ||
      !consultationTypes?.length ||
      !enableRecordingConsent)
  ) {
    logException(error)

    return Messages.defaultErrorMessage
  }

  return false
}

function getConsultantionTypesFromFilters({
  allowed_mediums: allowedMediums,
  consultation_type: consultationType,
}: Pick<AppointmentFields, 'allowed_mediums' | 'consultation_type'> = {}) {
  if (allowedMediums) {
    return allowedMediums
  }

  if (consultationType) {
    return [consultationType]
  }

  return null
}

function getConsultantFromFilters({
  allowed_professions: allowedProfessions,
  allowed_mediums: allowedMediums,
  consultant_type: consultantType,
}: Pick<
  AppointmentFields,
  'allowed_professions' | 'allowed_mediums' | 'consultant_type'
> = {}) {
  if (allowedProfessions?.length) {
    return allowedProfessions
  }

  if (consultantType) {
    return [consultantType]
  }

  /*
   * Service types selected from GP@Hand flow don't have a profession defined.
   * GP@H flow will set allowed mediums
   * Returning empty prevents the profession selector to be displayed
   */
  if (allowedMediums) {
    return []
  }

  return null
}

export default function useBookAppointmentData({
  memberId,
  filters,
}: UseBookAppointmentArgs) {
  const { getProp } = useProductConfig()
  const digitalInviteExtensionEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteExtensionEnabled'
  )

  const digitalInviteEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteEnabled'
  )

  const includingDigital = digitalInviteExtensionEnabled || digitalInviteEnabled

  const { data, loading, error } = useQuery(GetBookAppointmentData, {
    variables: {
      memberId,
      includingDigital,
    },
  })

  const patient = data?.patient
  const consultationTypes =
    getConsultantionTypesFromFilters(filters) || data?.consultationTypes
  const consultantTypes =
    getConsultantFromFilters(filters) || data?.consultantTypes

  const enableRecordingConsent =
    patient?.preferredConsumerNetwork?.enableRecordingConsent || false
  const languageRequested =
    patient?.preferredConsumerNetwork?.languageRequested || false

  const isChaperoneRequired = !!filters?.chaperone_required

  return {
    isChaperoneRequired,
    consultantTypeList: Array.isArray(consultantTypes) ? consultantTypes : [],
    consultationTypeList: Array.isArray(consultationTypes)
      ? consultationTypes
      : [],
    enableRecordingConsent,
    languageRequested,
    patient,
    loading,
    error: updateErrorMessage({
      consultantTypes,
      consultationTypes,
      error,
      loading,
      enableRecordingConsent,
    }),
  }
}
