import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Page, Card } from '@babylon/core-ui'

import GetProfessionInfo from './GetProfessionInfo'
import ProfessionDetail from './ProfessionDetail'
import { UnexpectedError } from '../../Utils'
import { LoadingContainer } from '../../components'

type Profession = {
  id: string
  name: string
  alternative_slot_durations_minutes: number[]
  default_slot_duration_minutes: number
  default_appointment_price_cents: number
  medical_identifier_label: string
}

interface ProfessionData {
  profession: Profession
}

const ProfessionInfo = () => {
  const { id } = useParams<{ id: string }>()

  const { data, loading, error } = useQuery<ProfessionData>(GetProfessionInfo, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id,
    },
  })

  const professionName = loading ? 'Loading ...' : data?.profession?.name

  return (
    <Page
      breadcrumbs={[
        <Link key="1" to="/admin/professions">
          Professions
        </Link>,
        professionName,
      ]}
    >
      {error && <UnexpectedError message={error.message} visible />}
      <Card title={professionName}>
        <LoadingContainer loading={loading}>
          {data && (
            <ProfessionDetail
              medicalIdentifierLabel={
                data?.profession?.medical_identifier_label
              }
              alternativeSlotDuration={
                data?.profession?.alternative_slot_durations_minutes
              }
              defaultSlotDuration={
                data?.profession?.default_slot_duration_minutes
              }
            />
          )}
        </LoadingContainer>
      </Card>
    </Page>
  )
}

export default ProfessionInfo
