import React from 'react'
import styles from './utils.module.css'

export const RelativeDateTimeWithOffset = ({
  datetime,
}: {
  datetime: string
}) => {
  const localTime = new Date(datetime).toLocaleString()

  // Get the user's browser time zone offset
  const timeZoneOffset = new Date().getTimezoneOffset()
  const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffset / 60))
  const timeZoneOffsetMinutes = Math.abs(timeZoneOffset % 60)
  const timeZoneOffsetSign = timeZoneOffset < 0 ? '+' : '-'
  // Construct the final timestamp string eg. UK: 10/08/2021, 10:00:00 (GMT +1:00)
  const localTimestamp = `${localTime} (GMT ${timeZoneOffsetSign}${timeZoneOffsetHours}:${timeZoneOffsetMinutes})`

  return <time>{localTimestamp}</time>
}

export const CommaList = ({ items }: { items: React.ReactNode[] }) => (
  <ul className={styles.commaList}>
    {items
      .filter((item) => item !== null)
      .map((item, index) => (
        <li key={index}>{item}</li>
      ))}
  </ul>
)

export const Pill = ({ children }: { children: string }) => (
  <span className={styles.pill}>{children}</span>
)

type WithTitleColumnProps = React.HTMLAttributes<HTMLDivElement> & {
  title: React.ReactNode
  pills?: (string | undefined)[]
}

export const WithTitle = ({
  title,
  pills,
  children,
  ...props
}: WithTitleColumnProps) => (
  <div {...props} className={styles.withTitle}>
    <div>
      <span className={styles.withTitleTitle}>{title}</span>
      <div className={styles.withTitlePills}>
        {pills
          ?.filter((pill) => !!pill)
          .map((pill) => (
            <div>{pill}</div>
          ))}
      </div>
    </div>
    <div>{children}</div>
  </div>
)

export const Unknown = () => <span className={styles.unknown}>unknown</span>
