import React, { useState, useEffect } from 'react'
import { Card, ButtonSimple } from '@babylon/medkit'
import { Formik, Form, FormikValues } from 'formik'
import { useFormatMessage } from '@babylon/intl'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { LoadingContainer } from '../../components'
import { UnexpectedError } from '../../Utils'
import DurationSelector from '../../features/ArrangeAppointment/DurationSelector'
import messages from '../DigitalAppointmentForm.messages'
import AppointmentTypeSelect from './AppointmentTypeSelect'
import AppointmentTypeNotes from '../AppointmentTypeNotes'
import { digitalAppointmentFormValidation } from '../digitalAppointmentFormValidation'
import BookForPatient from './BookForPatient'
import { AppointmentAnalyticsModuleName } from '../../analytics/types'
import styles from './CreateForm.module.css'

interface FormProps {
  loading: boolean
  professions: Profession[]
  memberId: string
  initialValues: FormikValues
  onSubmit: (value: FormikValues) => void
}

export default function CreateForm({
  loading,
  onSubmit,
  professions = [],
  memberId,
  initialValues,
}: FormProps) {
  const [selectedProfession, setSelectedProfession] = useState<Profession>()
  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: AppointmentAnalyticsModuleName.digitalInvite,
  })

  useEffect(() => {
    if (initialValues.appointment_type) {
      const selectedAppointmentType = professions.find(
        ({ id }) => id === initialValues.appointment_type
      )
      setSelectedProfession(selectedAppointmentType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={digitalAppointmentFormValidation}
      onSubmit={onSubmit}
    >
      <Form>
        <UnexpectedError visible={false} onClose={() => {}} />
        <Card vertical>
          <LoadingContainer loading={loading}>
            <AppointmentTypeSelect
              label={fm(messages.appointment_type)}
              professions={professions}
              placeholder={fm(messages.appointment_type_placeholder)}
              className={styles.Dropdown}
              setSelectedProfession={setSelectedProfession}
            />
            <AppointmentTypeNotes
              label={fm(messages.appointment_reason)}
              helpText={fm(messages.appointment_reason_helper)}
            />
            <DurationSelector
              className={styles.Dropdown}
              defaultDuration={
                selectedProfession?.default_slot_duration_minutes
              }
              recommendDuration={
                !!selectedProfession?.default_slot_duration_minutes
              }
              helpText={fm(messages.duration_helper)}
              onClickTrack={(duration) => {
                trackClick({
                  elementName: `duration-${duration}-mins`,
                  elementType: TrackingElementType.dropdown,
                })
              }}
              alternativeDurationMinutes={
                selectedProfession?.alternative_slot_durations_minutes || []
              }
            />
          </LoadingContainer>
        </Card>
        <div className={styles.buttonRow}>
          <BookForPatient
            onClickTrack={() => {
              trackClick({
                elementName: 'book-for-patient',
                elementType: TrackingElementType.button,
              })
            }}
            memberId={memberId}
            professions={professions}
          />

          <ButtonSimple type="submit">{fm(messages.send_btn)}</ButtonSimple>
        </div>
      </Form>
    </Formik>
  )
}
