import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { envFlag } from '@babylon/babylon-env'
import { useProductConfig } from '@babylon/product-config'
import { CancellationReason, Consultant, Practice } from '../../../types'
import ClinicianName from '../../../ClinicianName'
import ConditionalWrapper from '../../../ConditionalWrapper'
import ConsumerNetworkTag from '../../../ConsumerNetworkTag'
import Tag from '../../../Tag'
import Unknown from '../../../Unknown'
import { getFormattedDateTime } from '../../utils'
import CallList from '../CallList'
import ShareNotes from '../ShareNotes'
import messages from './Details.messages'
import sharedStyles from '../../shared.module.scss'
import styles from './styles.module.scss'

export interface DetailProps {
  appointmentType?: string
  cancellationReasons?: CancellationReason[]
  clinic?: Practice | null
  guestName?: string | null
  consultant?: Consultant | null
  consumerNetwork?: string | null
  id: string
  notes?: string
  originalConsultation?: string | null
  reason?: string | null
  show: boolean
  timezoneId?: string | null
}

const Details = ({
  appointmentType,
  cancellationReasons,
  clinic,
  guestName,
  consultant,
  consumerNetwork,
  id,
  notes,
  originalConsultation,
  reason,
  show,
  timezoneId,
}: DetailProps) => {
  const fm = useFormatMessage()
  const { getBlob } = useProductConfig()
  if (!show) {
    return null
  }

  const enableLinkToClinic = envFlag('TIMELINE_F2F_LINK_TO_CLINIC')

  const { shareNotesWithGpEnabled, timelineCallListEnabled } = getBlob(
    'memberOperations'
  )
  const uniqueReasons = [...new Set(cancellationReasons?.map((r) => r.reason))]

  const locale = (localStorage.getItem('locale') || 'en_GB').replace('_', '-') // todo: update with intl utilities

  return (
    <>
      <div className={cx(styles.Details__section, styles.Details__onecolumn)}>
        {cancellationReasons && cancellationReasons.length > 0 && (
          <div>
            <label className={styles.Details__label}>
              <FormattedMessage {...messages.cancellation_reason} />
            </label>
            <div
              className={styles.Details__value}
              data-testid="timeline-card-cancellation-reasons"
            >
              {uniqueReasons.map((cancellationReason) => (
                <p
                  key={cancellationReason}
                  className={styles.Details__cancellationReason}
                >
                  {cancellationReason}
                </p>
              ))}
            </div>
          </div>
        )}

        <label className={styles.Details__label}>
          <FormattedMessage {...messages.reason_for_appointment} />
        </label>
        <div
          className={styles.Details__value}
          data-testid="timeline-card-reason-for-appointment"
        >
          {reason || <FormattedMessage {...messages.no_reason} />}
        </div>
      </div>

      <div className={styles.Details__section}>
        {/* Column One */}
        <div>
          <label className={styles.Details__label}>
            <FormattedMessage {...messages.network} />
          </label>
          <div className={styles.Details__value}>
            <ConsumerNetworkTag
              name={consumerNetwork}
              isPreferred
              className={styles.Details__cardNetworkTag}
            />
            {guestName && (
              <Tag
                label={fm(messages.guestName_label)}
                className={sharedStyles.guestTag}
              />
            )}
          </div>

          {consultant && (
            <>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.clinician} />
              </label>
              <div className={styles.Details__value}>
                <ClinicianName
                  name={consultant.name}
                  id={consultant.id}
                  gender={consultant.gender}
                />
              </div>
            </>
          )}

          {!!clinic && (
            <>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.clinic_location} />
              </label>
              <div
                data-testid="timeline-card-clinic-name"
                className={styles.Details__value}
              >
                <ConditionalWrapper
                  condition={enableLinkToClinic}
                  wrapper={(children) => (
                    <Link
                      to={`/admin/practices/${clinic.id}`}
                      className={styles.Details__link}
                    >
                      {children}
                    </Link>
                  )}
                >
                  {clinic.name}
                </ConditionalWrapper>

                {!!clinic.full_address && (
                  <div data-testid="timeline-card-clinic-address">
                    <div>{clinic.full_address.address_line_1}</div>
                    <div>{clinic.full_address.address_line_2}</div>
                    <div>{clinic.full_address.address_line_3}</div>
                    <div>{clinic.full_address.postal_code}</div>
                  </div>
                )}
              </div>

              {!!clinic.phone_number && (
                <>
                  <label className={styles.Details__label}>
                    <FormattedMessage {...messages.clinic_phone_number} />
                  </label>
                  <div className={styles.Details__value}>
                    {clinic.phone_number}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {/* Column Two */}
        <div>
          {!!appointmentType && (
            <div>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.appointment_type} />
              </label>
              <div
                data-testid="timeline-card-appointment-type"
                className={styles.Details__value}
              >
                {appointmentType}
              </div>
            </div>
          )}

          {!!originalConsultation && (
            <div>
              <div className={styles.AppointmentCard__moreDetailsHeading}>
                <FormattedMessage {...messages.original_consultation} />
              </div>
              <div data-testid="timeline-card-appointment-original-time">
                {getFormattedDateTime({ date: originalConsultation, locale })}
              </div>
            </div>
          )}

          {!!clinic && (
            <div>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.arrival_instructions} />
              </label>
              <div
                data-testid="timeline-card-arrival-instructions"
                className={styles.Details__value}
              >
                {clinic.arrival_instructions || (
                  <FormattedMessage {...messages.no_arrival_instructions} />
                )}
              </div>
            </div>
          )}

          <label className={styles.Details__label}>
            <FormattedMessage {...messages.consultation_id} />
          </label>
          <div className={styles.Details__value}>{id || <Unknown />}</div>

          {timelineCallListEnabled && (
            <>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.calls_made} />
              </label>
              <div className={styles.Details__value}>
                <CallList appointmentId={id} timezoneId={timezoneId} />
              </div>
            </>
          )}

          {!!notes && (
            <div>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.notes} />
              </label>
              <div
                className={styles.Details__value}
                data-testid="timeline-card-notes"
              >
                {notes}
              </div>
            </div>
          )}

          {shareNotesWithGpEnabled && (
            <>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.share_notes} />
              </label>
              <div className={styles.Details__value}>
                <ShareNotes consultationId={id} />
              </div>
            </>
          )}

          {guestName && (
            <>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.guestName} />
              </label>
              <div className={styles.Details__value}>{guestName}</div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Details
