/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type CancelReasonsQueryVariables = Types.Exact<{ [key: string]: never }>

export type CancelReasonsQuery = {
  cancelReasons?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.CancelReason, 'id' | 'reason'>>>
  >
}

export const CancelReasonsDocument = gql`
  query CancelReasons {
    cancelReasons {
      id
      reason
    }
  }
`
export function useCancelReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CancelReasonsQuery,
    CancelReasonsQueryVariables
  >
) {
  return Apollo.useQuery<CancelReasonsQuery, CancelReasonsQueryVariables>(
    CancelReasonsDocument,
    baseOptions
  )
}
export function useCancelReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CancelReasonsQuery,
    CancelReasonsQueryVariables
  >
) {
  return Apollo.useLazyQuery<CancelReasonsQuery, CancelReasonsQueryVariables>(
    CancelReasonsDocument,
    baseOptions
  )
}
export type CancelReasonsQueryHookResult = ReturnType<
  typeof useCancelReasonsQuery
>
export type CancelReasonsLazyQueryHookResult = ReturnType<
  typeof useCancelReasonsLazyQuery
>
export type CancelReasonsQueryResult = Apollo.QueryResult<
  CancelReasonsQuery,
  CancelReasonsQueryVariables
>
