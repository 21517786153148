import { defineMessages } from 'react-intl'

const baseId = 'entry_v2'

export default defineMessages({
  viewConversation: {
    id: `${baseId}.view_conversation`,
    defaultMessage: 'View conversation',
  },
  care_live_chat: {
    id: `${baseId}.care_live_chat`,
    defaultMessage: 'COVID-19 live chat',
  },
})
