import React from 'react'
import Select from 'react-select'
import { useQuery } from '@apollo/client'
import { useField } from 'formik'

import { supportedTranslationLanguages } from '@babylon/intl'
import styles from './TranslationLanguage.module.css'
import { GetTranslationLanguagesQuery } from './GetTranslationLanguagesQuery'

export default function TranslationLanguage() {
  const { data, loading } = useQuery(GetTranslationLanguagesQuery)
  const [{ value }, , { setValue }] = useField('language_requested')
  const [{ value: isRequested }] = useField('language_required')
  // `und` is a special case ISO 639-3 code for 'Undetermined'. This value
  // is used to represent the "Other" option for selecting a translator
  const options =
    data?.translationLanguages?.languages.map((languageCode) => {
      const languageName = supportedTranslationLanguages[languageCode]

      return {
        value: languageCode,
        label: languageName,
      }
    }) ?? []

  const ValueRenderedWithPlaceholder = (opt) => (
    <span>
      <span className="value">{opt.label}</span>
      <span
        className="Select-placeholder"
        placeholder="Search Translation Language"
      />
    </span>
  )

  return (
    <div className={styles.translationLanguage}>
      <label htmlFor="translationLanguage">Language</label>
      <Select
        id="translation-language"
        name="language_requested"
        value={value}
        onChange={(option) => setValue(option?.value)}
        options={options}
        noResultsText="No matching results"
        menuStyle={{ maxHeight: '165px' }}
        backspaceRemoves={false}
        deleteRemoves={false}
        valueRenderer={ValueRenderedWithPlaceholder}
        clearable={false}
        multi={false}
        disabled={!isRequested || loading}
      />
    </div>
  )
}
