import React, { useState } from 'react'
import {
  Box,
  ButtonGroup,
  Button,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import { useFormatMessage } from '@babylon/intl'
import * as Types from '@babylon/graphql-middleware-types'
import messages from './AppointmentWaitTimeConfigsTable.messages'
import {
  convertMinutesToWaitTimeStatusThresholdMap,
  filterWaitTimeStautsThresholdByType,
  formatWaitTimeStatusThreshold,
} from './AppointmentWaitTimeConfigsTable.utils'
import { AppointmentWaitTimeConfigsDeleteDialog } from '../AppointmentWaitTimeConfigsDeleteDialog'
import { AppointmentWaitTimeStatus } from '../AppointmentWaitTimeStatus'
import type { AppointmentWaitTimeConfigsTableProps } from './AppointmentWaitTimeConfigsTable.types'
import { AppointmentWaitTimeConfigsSaveDialog } from '../AppointmentWaitTimeConfigsSaveDialog'
import { AppointmentWaitTimeConfigsSaveDialogOperations } from '../AppointmentWaitTimeConfigsSaveDialog/AppointmentWaitTimeConfigsSaveDialog.types'

const tableHeadings = [
  {
    size: '50%',
    label: messages.consumerNetworkTableHeading,
    isWaitTimeStatusThresholdHeader: false,
  },
  {
    size: '50%',
    label: messages.serviceTypeTableHeading,
    isWaitTimeStatusThresholdHeader: false,
  },
  {
    size: '0%',
    label: messages.criticalWaitTimeStatusThresholdTableHeading,
    isWaitTimeStatusThresholdHeader: true,
  },
  {
    size: '0%',
    label: messages.overloadedWaitTimeStatusThresholdTableHeading,
    isWaitTimeStatusThresholdHeader: true,
  },
  {
    size: '0%',
    label: messages.busyWaitTimeStatusThresholdTableHeading,
    isWaitTimeStatusThresholdHeader: true,
  },
]
const AppointmentWaitTimeConfigsTable = ({
  waitTimeConfigs,
  isLoading,
  handleSaveWaitTimeConfigsAfterActions,
  handleWaitTimeConfigsDialogDelete,
}: AppointmentWaitTimeConfigsTableProps) => {
  const fm = useFormatMessage()
  const [
    deleteWaitTimeConfigsDialogOpen,
    setDeleteWaitTimeConfigsDialogOpen,
  ] = useState(false)
  const [
    updateWaitTimeConfigsDialogOpen,
    setUpdateWaitTimeConfigsDialogOpen,
  ] = React.useState(false)
  const [consumerNetwork, setConsumerNetwork] = useState<
    Pick<Types.ConsumerNetwork, 'id' | 'name'>
  >()
  const [serviceType, setServiceType] = useState<
    Pick<Types.ServiceType, 'id' | 'name'>
  >()
  const handleDeleteWaitTimeConfigsDialogClickOpen = (
    consumerNetwork: Pick<Types.ConsumerNetwork, 'id' | 'name'>,
    serviceType: Pick<Types.ServiceType, 'id' | 'name'> | undefined
  ) => {
    setConsumerNetwork(consumerNetwork)
    setServiceType(serviceType)
    setDeleteWaitTimeConfigsDialogOpen(true)
  }
  const handleWaitTimeConfigsDialogClickDelete = (statusCode: string) => {
    setDeleteWaitTimeConfigsDialogOpen(false)
    handleWaitTimeConfigsDialogDelete(statusCode)
  }
  const handleWaitTimeConfigsDialogClickClose = () => {
    setDeleteWaitTimeConfigsDialogOpen(false)
  }
  const handleUpdateWaitTimeConfigsDialogClickOpen = (
    consumerNetwork: Pick<Types.ConsumerNetwork, 'id' | 'name'>,
    serviceType: Pick<Types.ServiceType, 'id' | 'name'> | undefined
  ) => {
    setConsumerNetwork(consumerNetwork)
    setServiceType(serviceType)
    setUpdateWaitTimeConfigsDialogOpen(true)
  }
  const handleUpdateWaitTimeConfigsDialogClose = () => {
    setUpdateWaitTimeConfigsDialogOpen(false)
  }

  return (
    <Table aria-label={fm(messages.tableTitleLabel)}>
      <TableHead>
        <TableRow>
          {tableHeadings.map((heading) => {
            const formattedHeadingLable = fm(heading.label)

            return (
              <TableCell
                key={formattedHeadingLable}
                component="th"
                width={heading.size}
              >
                {heading.isWaitTimeStatusThresholdHeader ? (
                  <AppointmentWaitTimeStatus
                    status={Number(formattedHeadingLable)}
                  />
                ) : (
                  formattedHeadingLable
                )}
              </TableCell>
            )
          })}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading ? (
          waitTimeConfigs.map(
            ({ consumerNetwork, serviceType, waitTimeStatusThresholds }) => (
              <TableRow key={`${consumerNetwork.id}-${serviceType?.id}`} hover>
                <TableCell>{consumerNetwork.name}</TableCell>
                <TableCell>{serviceType?.name}</TableCell>
                <TableCell>
                  {formatWaitTimeStatusThreshold(
                    convertMinutesToWaitTimeStatusThresholdMap(
                      filterWaitTimeStautsThresholdByType(
                        waitTimeStatusThresholds,
                        'critical'
                      )?.value
                    )
                  )}
                </TableCell>
                <TableCell>
                  {formatWaitTimeStatusThreshold(
                    convertMinutesToWaitTimeStatusThresholdMap(
                      filterWaitTimeStautsThresholdByType(
                        waitTimeStatusThresholds,
                        'overloaded'
                      )?.value
                    )
                  )}
                </TableCell>
                <TableCell>
                  {formatWaitTimeStatusThreshold(
                    convertMinutesToWaitTimeStatusThresholdMap(
                      filterWaitTimeStautsThresholdByType(
                        waitTimeStatusThresholds,
                        'busy'
                      )?.value
                    )
                  )}
                </TableCell>
                <TableCell align="right">
                  <ButtonGroup
                    variant="text"
                    color="inherit"
                    aria-label="text button group"
                  >
                    <Button
                      style={{ textTransform: 'none' }}
                      color="secondary"
                      onClick={() =>
                        handleUpdateWaitTimeConfigsDialogClickOpen(
                          consumerNetwork,
                          serviceType || undefined
                        )
                      }
                    >
                      {
                        messages.updateWaitTimeThresholdButtonTitle
                          .defaultMessage
                      }
                    </Button>
                    <Button
                      style={{ textTransform: 'none' }}
                      color="secondary"
                      onClick={() =>
                        handleDeleteWaitTimeConfigsDialogClickOpen(
                          consumerNetwork,
                          serviceType || undefined
                        )
                      }
                    >
                      {
                        messages.deleteWaitTimeThresholdButtonTitle
                          .defaultMessage
                      }
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            )
          )
        ) : (
          <TableRow>
            <TableCell colSpan={tableHeadings.length}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={400}
              >
                <CircularProgress />
              </Box>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      <AppointmentWaitTimeConfigsSaveDialog
        isOpen={updateWaitTimeConfigsDialogOpen}
        operation={AppointmentWaitTimeConfigsSaveDialogOperations.Update}
        consumerNetwork={consumerNetwork}
        serviceType={serviceType}
        handleSaveWaitTimeConfigsAfterActions={
          handleSaveWaitTimeConfigsAfterActions
        }
        handleSaveDialogClickClose={handleUpdateWaitTimeConfigsDialogClose}
      />
      <AppointmentWaitTimeConfigsDeleteDialog
        isOpen={deleteWaitTimeConfigsDialogOpen}
        consumerNetwork={consumerNetwork}
        serviceType={serviceType}
        handleDialogClickDelete={handleWaitTimeConfigsDialogClickDelete}
        handleDialogClickClose={handleWaitTimeConfigsDialogClickClose}
      />
    </Table>
  )
}

export default AppointmentWaitTimeConfigsTable
