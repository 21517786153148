import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useQuery, QueryResult, useMutation } from '@apollo/client'
import { Card, Page } from '@babylon/core-ui'
import {
  useTrackClick,
  TrackingElementType,
  useTracking,
  TrackingActionType,
} from '@babylon/tracking/react'
import { UnexpectedError } from '../../Utils'
import CreateForm from './CreateForm'
import GetProfessionsInfoQuery from './GetProfessionsQuery'
import CreateAppointmentInvite from '../CreateInviteMutation'
import MemberQuery from '../MemberQuery'
import InviteSentConfirmation from '../../SetupAppointment/CreateInvite/InviteSentConfirmation'
import { AppointmentAnalyticsModuleName } from '../../analytics/types'
import styles from '../DigitalAppointmentForm.module.css'

interface Props {
  memberId: string
}

const baseValues = {
  appointment_type: '',
  notes_for_member: '',
  duration_minutes: null,
}

export default function FormWithProfessions({ memberId }: Props) {
  const { state } = useLocation()
  const {
    data,
    loading: loadingQuery,
    error: errorQuery,
  }: QueryResult = useQuery(GetProfessionsInfoQuery, {
    variables: {
      memberId,
    },
  })

  const [
    createInvite,
    { data: submittedData, loading: submitting, error: errorSubmit },
  ] = useMutation(CreateAppointmentInvite)

  const { data: patientData } = useQuery(MemberQuery, {
    variables: { memberId },
  })

  const { trackClick } = useTrackClick({
    moduleName: AppointmentAnalyticsModuleName.digitalInvite,
  })

  const { trackEvent } = useTracking()

  const onTrackClick = (elementName) => {
    trackClick({
      elementName,
      elementType: TrackingElementType.button,
    })
  }
  const handleSubmit = (values) => {
    if (!patientData?.patient?.uuid) {
      return
    }

    const createInviteForm = {
      notes_for_member: values.notes_for_member,
      duration_minutes: values.duration_minutes,
      preferred_profession: values.appointment_type,
      allowed_professions: [values.appointment_type],
      member_uuid: patientData.patient.uuid,
      intimate: false,
      earliest_booking_date: new Date().toISOString(),
    }

    createInvite({
      variables: {
        input: createInviteForm,
      },
    })

    onTrackClick('send-to-patient')
  }
  const error = errorQuery || errorSubmit

  const loading = loadingQuery || submitting
  const invite = submittedData?.createAppointmentInvite

  const professions =
    data?.professions.filter(({ key }) =>
      data.consultantTypes.includes(key.toUpperCase())
    ) || []

  let professionSummary

  if (invite?.preferred_profession) {
    professionSummary = professions.find(
      ({ id }) => id === invite.preferred_profession
    )
  }

  const isInviteSentConfirmationOpen = !submitting && !errorSubmit && !!invite

  if (isInviteSentConfirmationOpen) {
    trackEvent({
      moduleName: AppointmentAnalyticsModuleName.digitalInvite,
      actionType: TrackingActionType.popupLoaded,
      elementType: TrackingElementType.popup,
      elementName: 'invite-successfully-sent',
    })
  }

  return (
    <Page
      title="Book an Appointment"
      breadcrumbs={[
        <Link key="1" to="/admin/patients/search">
          Patients
        </Link>,
        <Link key="2" to={`/admin/patients/${memberId}/memberships`}>
          Member details
        </Link>,
        'Book Appointment',
      ]}
    >
      {error && <UnexpectedError message={error.message} visible />}
      <InviteSentConfirmation
        className={styles.ModalButton}
        memberId={memberId}
        isOpen={isInviteSentConfirmationOpen}
        serviceTypeName={professionSummary?.name}
        durationMinutes={`${invite?.duration_minutes} minutes`}
        notes={invite?.notes_for_member}
        onTrackDoneBtn={() => onTrackClick('done')}
      />
      <Card>
        <CreateForm
          initialValues={{
            ...baseValues,
            ...(state?.originalFilters || {}),
          }}
          loading={loading}
          professions={professions}
          onSubmit={handleSubmit}
          memberId={memberId}
        />
      </Card>
    </Page>
  )
}
