import React, { FC, FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Heading } from '@babylon/core-ui'
import messages from './messages'
import styles from './styles.module.scss'

interface ErrorBlockComponent extends FunctionComponent {
  ErrorCode: FunctionComponent<ErrorCodeProps>
  Heading: FunctionComponent
  NextSteps: FunctionComponent
}

interface ErrorCodeProps {
  code: string
  idCode: string
}

const ErrorBlock: ErrorBlockComponent = ({ children }) => (
  <div className={styles.errorBlock} data-testid="error-block">
    {children}
  </div>
)

const ErrorBlockHeading: FC = ({ children }) => (
  <Heading className={styles.errorBlock__heading} level="h2">
    <span data-testid="error-block-heading">{children}</span>
  </Heading>
)

const ErrorBlockNextSteps: FC = ({ children }) => (
  <p className={styles.errorBlock__nextSteps}>{children}</p>
)

const ErrorCode: FC<ErrorCodeProps> = ({ code, idCode }) => (
  <div className={styles.errorBlock__code}>
    <div>
      <strong>
        <FormattedMessage {...messages.error_code} />
      </strong>
      <span data-testid="error-block-code">{code}</span>
    </div>
    <div>
      <strong>
        <FormattedMessage {...messages.error_id} />
      </strong>
      <span data-testid="error-block-id">{idCode}</span>
    </div>
  </div>
)

export default ErrorBlock
ErrorBlock.ErrorCode = ErrorCode
ErrorBlock.Heading = ErrorBlockHeading
ErrorBlock.NextSteps = ErrorBlockNextSteps
