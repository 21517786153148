import { graphql } from '@apollo/client/react/hoc'
import { compose, withProps } from 'recompose'
import { withStore } from '@babylon/babylon-forms/forms'

import { withSpinner } from '@/util'
import withErrorMessage, { dialogErrorHandler } from '@/util/withErrorMessage'

import { PrescriptionAudit } from './queries'
import PrescriptionAuditsView from './PrescriptionAuditsView'

// DATA

const defaultData = ({
  match: { params: { id } } = { params: { id: null } },
}) => ({
  paramId: id,
})

export const prescriptionAuditsQueryOptions = {
  options: ({ paramId }) => ({ variables: { prescriptionId: paramId } }),
  skip: ({ paramId }) => !paramId,
}

const withData = compose(
  withProps(defaultData),
  graphql(PrescriptionAudit, prescriptionAuditsQueryOptions)
)

// FILTER FORM

const withFilterStore = withStore(
  {
    hideViewedLogs: true,
  },
  { name: 'filterStore' }
)

// FILTERED DATA

const withFilteredData = withProps((props) => {
  const originalItems = props.data.prescriptionAudit || []
  const { hideViewedLogs } = props.filterStore.state
  const logItems = originalItems.filter(
    (item) => !hideViewedLogs || item.eventType !== 'PRESCRIPTION_VIEWED'
  )
  logItems.sort(
    (a, b) => new Date(b.eventTimestamp) - new Date(a.eventTimestamp)
  )

  return {
    logItems,
    faxLogItems: props.data.prescriptionFaxStatus || [],
  }
})

// LOADER

export const isLoading = (props) => !props.data || !props.data.prescriptionAudit

const withLoader = withSpinner(isLoading)

const Container = compose(
  withData,
  withLoader,
  withFilterStore,
  withFilteredData,
  withErrorMessage(dialogErrorHandler)
)(PrescriptionAuditsView)

Container.width = '90%'

export default Container
