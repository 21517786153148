import { MessageDescriptor } from 'react-intl'
import { User } from '@babylon/babylon-user'

export type BabylonUser = User | null

export enum IconKeys {
  Dashboard = 'dashboard',
  Patients = 'patients',
  Practitioners = 'practitioners',
  Admin = 'admin',
  Commercial = 'commercial',
  LiveChat = 'live_chat',
}

export type GeneratedLink = (id?: string) => string

export type NavItemTitle = MessageDescriptor | string

export interface NavLink {
  title: NavItemTitle
  link: string | GeneratedLink
  hidden?: boolean
  icon?: IconKeys
}

export interface NavCategory {
  id: string
  title: NavItemTitle
  links: NavLink[]
  hidden?: boolean
  icon?: IconKeys
}

export type NavItem = NavCategory | NavLink
