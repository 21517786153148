/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { QueryResult } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { Spinner } from '@babylon/core-ui'

import UnexpectedError from './UnexpectedError'

const WithQuery = (props: any) => (
  <Query {...props}>
    {({ loading, error, data }: QueryResult) => {
      if (loading) {
        return <Spinner size="large" centered />
      }

      if (error) {
        return <UnexpectedError message={error.message} />
      }

      return props.children(data)
    }}
  </Query>
)

export default WithQuery
