import React from 'react'
import { Form, Button } from '@babylon/babylon-forms/forms'
import {
  DataGrid,
  Columns,
  Column,
  Checkbox,
  TabGroup,
  Tab,
} from '@babylon/babylon-forms/widgets'

import TimezoneDate from '@/components/TimezoneDate'

import styles from './styles.module.scss'

const formatDate = (fieldName) => (item) => (
  <span className={styles.noWrap}>
    <TimezoneDate date={item[fieldName]} />
  </span>
)

const removeUnderscores = (fieldName) => (state) =>
  (state[fieldName] || '').replace(/_/g, ' ')

const DrugAuditsView = ({ logItems, faxLogItems, filterStore, history }) => (
  <div>
    <div className={styles.audits}>
      <TabGroup value="logs">
        <Tab name="logs" title="Logs">
          <Form store={filterStore}>
            <Checkbox label="Hide viewed logs" name="hideViewedLogs" />
            <DataGrid value={logItems} className="widget border">
              <Columns>
                <Column
                  name="eventTimestamp"
                  label="Date"
                  value={formatDate('eventTimestamp')}
                />
                <Column
                  name="loginType"
                  label="Login type"
                  value={removeUnderscores('loginType')}
                />
                <Column name="performedByName" label="Name" />
                <Column
                  name="eventType"
                  label="Action type"
                  value={removeUnderscores('eventType')}
                />
                <Column name="eventDetails" label="Reason" />
              </Columns>
            </DataGrid>
          </Form>
        </Tab>
        <Tab name="fax-log" title="Fax logs">
          <DataGrid value={faxLogItems} className="widget border">
            <Columns>
              <Column name="faxableId" label="Faxable ID" />
              <Column
                name="status"
                label="Status"
                value={removeUnderscores('status')}
              />
              <Column name="pageCount" label="Page count" />
              <Column name="errorId" label="Error ID" />
              <Column name="errorType" label="Error type" />
              <Column
                name="createdAt"
                label="Created at"
                value={formatDate('createdAt')}
              />
              <Column name="errorMessage" label="Error message" />
              <Column
                name="sentAt"
                label="Sent at"
                value={formatDate('sentAt')}
              />
              <Column
                name="failedAt"
                label="Failed at"
                value={formatDate('failedAt')}
              />
            </Columns>
          </DataGrid>
        </Tab>
      </TabGroup>
    </div>
    <div className={styles.rightRow}>
      <Button outline onClick={() => history.goBack()}>
        Cancel
      </Button>
    </div>
  </div>
)

export default DrugAuditsView
