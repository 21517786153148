import React from 'react'
import styles from './PractitionerDetails.module.css'
import type { PractitionerDetailsProps } from './PractitionerDetails.types'

const PractitionerDetails = ({
  fullName,
  emailAddress,
}: PractitionerDetailsProps) => (
  <section className={styles.details} data-testid="practitioner-details">
    <h1 className={styles.name}>{fullName}</h1>

    {emailAddress ? (
      <div className={styles.info}>
        <div className={styles.infoTitle}>Email</div>
        <div>{emailAddress}</div>
      </div>
    ) : null}
  </section>
)

export default PractitionerDetails
