import React from 'react'
import { differenceInDays, format } from 'date-fns'
import { Grid, GridSpacing, Icon, Text } from '@babylon/medkit'
import { MemberTask } from '@babylon/graphql-middleware-types'
import styles from './MemberTaskLogEntry.module.css'

export const memberFormattedDateTime = (
  createdAt: string,
  comparison: Date
) => {
  const createdAtDateTime = new Date(createdAt)
  const difference = differenceInDays(comparison, createdAtDateTime)
  let daysAgo = `${difference} ${difference === 1 ? 'day' : 'days'} ago`
  if (difference === 0) {
    daysAgo = 'Today'
  }
  return [daysAgo, `(${format(createdAtDateTime, 'EEE d LLLL')})`]
}

export type MemberTaskLogItem = Omit<MemberTask, 'thread' | 'id' | 'state'>

interface MemberTaskLogEntryProps extends MemberTaskLogItem {
  isCurrentTask?: boolean
  showCurrentTask?: boolean
}

export default function MemberTaskLogEntry({
  createdAt,
  logEntry,
  isCurrentTask = false,
  showCurrentTask = false,
}: MemberTaskLogEntryProps) {
  if (isCurrentTask && !showCurrentTask) {
    return null
  }
  const now = new Date()
  const [daysAgo, formattedDate] = memberFormattedDateTime(createdAt, now)
  return (
    <div className={styles.card} key={createdAt}>
      <Grid container spacing={GridSpacing.XS}>
        <Grid item>
          <Icon icon="Tick" title="Tick" />
        </Grid>
        <Grid item className={styles.textContainer}>
          <Text variant="smallBody" className={styles.dateText}>
            <span className={styles.boldText}>{daysAgo}</span> {formattedDate}
          </Text>
          <Text variant="body">{logEntry || ''}</Text>
        </Grid>
      </Grid>
    </div>
  )
}
