import React from 'react'
import copy from 'clipboard-copy'
import Tooltip from 'rc-tooltip'
import { Form, Button } from '@babylon/babylon-forms/forms'
import {
  DataGrid,
  Columns,
  Column,
  Pagination,
  Spinner,
  DateRangePicker,
  Text,
} from '@babylon/babylon-forms/widgets'
import { PatientName } from '@babylon/member-operations'
import moment from 'moment'

import Icon from '@/components/Icon'
import TimezoneDate from '@/components/TimezoneDate'

import PaginationSize from './PaginationSize'
import { formatBadgeStatus } from '../utils'

import styles from './styles.module.scss'

const copyUrlToClipboard = (id) => () => {
  copy(`${location.origin}/admin/prescriptions/${id}`) //eslint-disable-line
}

const SmallButton = (props) => <Button small outline type="button" {...props} />

const ItemView = ({ item }) => {
  const {
    id,
    patient: {
      id: patientId,
      first_name: patientFirstName,
      last_name: patientLastName,
    },
    drugName,
    latestIssue,
    reviewDate,
    userState,
  } = item
  const { issuedAt, durationDays } = latestIssue || {}

  return (
    <tr>
      <td className={styles.noWrap}>
        <Tooltip
          transitionName="tooltip-fade"
          mouseEnterDelay={0.05}
          mouseLeaveDelay={0}
          trigger={['hover']}
          overlay="Copy prescription URL"
          placement="top"
        >
          <Icon
            onClick={copyUrlToClipboard(id)}
            role="button"
            tabIndex="-1"
            name="clipboard"
            className={styles.icon}
          />
        </Tooltip>{' '}
        <strong>
          <PatientName
            firstName={patientFirstName}
            lastName={patientLastName}
            id={patientId}
          />
        </strong>
      </td>
      <td>{drugName || 'N/A'}</td>
      <td className={styles.narrowRow}>
        <TimezoneDate date={reviewDate} format="dd/MM/yyyy" />
      </td>
      <td className={styles.narrowRow}>
        <TimezoneDate date={issuedAt} format="dd/MM/yyyy" />
      </td>
      <td>{formatBadgeStatus(userState)}</td>
      <td>{issuedAt ? `${durationDays} days` : 'N/A'}</td>
    </tr>
  )
}

const Filter = ({ filter, onFilterChange, onFilterReset }) => (
  <Form
    store={filter}
    className={styles.templateFilters}
    onSubmit={onFilterChange}
  >
    <div className={styles.filterFields}>
      <Text label="Patient ID" name="patientId" placeholder="e.g.: 1" />
      <DateRangePicker
        label="Start date"
        startName="startDateMin"
        endName="startDateMax"
        displayFormat="DD/MM/YYYY"
        showClearDates
        isOutsideRange={() => false}
        initialVisibleMonth={() => moment().subtract(1, 'month')}
        small
      />
      <DateRangePicker
        label="Review date"
        startName="reviewDateMin"
        endName="reviewDateMax"
        displayFormat="DD/MM/YYYY"
        showClearDates
        isOutsideRange={() => false}
        initialVisibleMonth={() => moment().subtract(1, 'month')}
        small
      />
    </div>
    <div className={styles.buttonRow}>
      <SmallButton onClick={onFilterReset}>Reset all filters</SmallButton>
      <Button small>Apply filter</Button>
    </div>
  </Form>
)

const TemplatesView = ({
  data: {
    loading,
    repeatTemplates: { itemsPerPage, pageIndex, totalItems, items },
    variables: { sort = '', ...filterValues },
  },
  handlePageSizeChange,
  handlePageChange,
  handleSort,
  handleToggleFilter,
  spinnerType,
  handleConsultantIdClick,
  filter,
  handleFilterChange,
  handleFilterReset,
  showFilter,
}) => {
  const [column, direction] = sort.split(',')
  const hasFilter = !!(
    filterValues.patientId ||
    filterValues.startDateMin ||
    filterValues.startDateMax ||
    filterValues.reviewDateMin ||
    filterValues.reviewDateMax ||
    (filterValues.states && filterValues.states.length)
  )

  return (
    <div className={styles.prescriptionsView}>
      <div className={styles.listHeader}>
        <PaginationSize
          value={itemsPerPage}
          onChange={handlePageSizeChange}
          totalItems={totalItems}
          spinner={<Spinner visible={spinnerType === 'page-size' && loading} />}
        />
        <div className={styles.filterToolbar}>
          <Button
            small
            outline={!hasFilter}
            alert={hasFilter}
            onClick={handleToggleFilter}
          >
            {showFilter ? 'Hide filters' : 'Show filters'}
          </Button>
          <Spinner visible={spinnerType === 'filter' && loading} />
        </div>
      </div>
      {showFilter && (
        <Filter
          filter={filter}
          onFilterChange={handleFilterChange}
          onFilterReset={handleFilterReset}
        />
      )}
      <DataGrid
        value={items}
        ItemView={ItemView}
        sort={{
          column,
          direction,
          loading: spinnerType === 'sort' && loading,
        }}
        onSort={handleSort}
        icons={{
          asc: <Icon name="sort-up" />,
          desc: <Icon name="sort-down" />,
          sort: <Icon name="sort" />,
          spinner: <Spinner visible />,
        }}
        context={{ handleConsultantIdClick }}
      >
        <Columns>
          <Column name="patientName" label="Patient" />
          <Column name="drug" label="Drug" />
          <Column name="reviewDate" label="Review date" />
          <Column name="issueDate" label="Last issue date" />
          <Column name="state" label="Current Status" />
          <Column name="duration" label="Duration" />
        </Columns>
      </DataGrid>
      <div className={styles.bottomToolbar}>
        <div />
        {itemsPerPage < totalItems && (
          <div className={styles.rowRight}>
            <Spinner visible={spinnerType === 'pagination' && loading} />
            <Pagination
              page={pageIndex}
              size={itemsPerPage}
              totalElements={totalItems}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TemplatesView
