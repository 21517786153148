import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { KebabMenuRenderOptions } from '@babylon/core-ui'
import { useMutation } from '@apollo/client'
import {
  TrackingElementType,
  useTrackClick,
  useTracking,
  TrackingActionType,
} from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../../../../..'

import { GET_RELATED_PERSONS, LINK_DEPENDENT_TO_GUARDIAN } from '../../queries'
import { PROFILE_QUERY } from '../../../../../queries'
import { logException } from '../../../../../../ErrorBoundary'
import { SetSnackbarMessage } from '../../../../../types'

import messages from './AddRelatedPersonButton.messages'

export const DEPENDENT_REGISTRATION_STRATEGY = 'outreach-dependent'
export const GUARDIAN_RELATIONSHIP_CODE = 'IqWhpl2qHO'

export interface AddRelatedPersonButtonProps extends KebabMenuRenderOptions {
  currentMemberId: string
  relatedPersonId: string
  isRelatedPersonMinor: boolean
  isCurrentMemberMinor: boolean
  currentMemberName?: string
  setIsLoading: (loading: boolean) => void
  hideDrawer?: () => void
  setSnackbarMessage: SetSnackbarMessage
}

const AddRelatedPersonButton = ({
  currentMemberId,
  relatedPersonId,
  isRelatedPersonMinor,
  isCurrentMemberMinor,
  currentMemberName,
  setIsLoading,
  hideDrawer,
  setSnackbarMessage,
  menuItemProps,
}: AddRelatedPersonButtonProps) => {
  const fm = useFormatMessage()
  const trackingElementName = `related-person-add-${
    isCurrentMemberMinor ? 'guardian' : 'dependent'
  }-button`
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberRelatedPersons,
  })
  const { trackEvent } = useTracking()
  const [addRelatedPerson] = useMutation(LINK_DEPENDENT_TO_GUARDIAN, {
    context: { clientName: 'platform-gateway' },
    refetchQueries: [
      {
        query: PROFILE_QUERY,
        variables: {
          patientUuid: relatedPersonId,
        },
        context: { clientName: 'platform-gateway' },
      },
      {
        query: GET_RELATED_PERSONS,
        variables: {
          patientId: currentMemberId,
          first: 5,
          relationshipsFirst2: 10,
          input: {
            system: 'https://bbl.health',
          },
        },
        context: { clientName: 'platform-gateway' },
      },
    ],
  })
  const trackError = () => {
    trackEvent({
      patientId: currentMemberId,
      elementName: trackingElementName,
      moduleName: MemberOpsModuleName.memberRelatedPersons,
      elementType: TrackingElementType.button,
      actionType: TrackingActionType.formError,
    })
  }
  const handleAddRelatedPerson = async () => {
    setIsLoading(true)
    trackClick({
      elementName: trackingElementName,
      elementType: TrackingElementType.button,
    })

    try {
      const response = await addRelatedPerson({
        variables: {
          input: {
            relatedPersons: [
              {
                _registration_fromPatient: isRelatedPersonMinor
                  ? currentMemberId
                  : relatedPersonId,
                relationship: {
                  system: 'https://bbl.health',
                  code: GUARDIAN_RELATIONSHIP_CODE,
                  display: null,
                },
              },
            ],
            registrationStrategy: DEPENDENT_REGISTRATION_STRATEGY,
            managingOrganization: 'BABYLON',
            dependentStubProfileId: isRelatedPersonMinor
              ? relatedPersonId
              : currentMemberId,
          },
        },
      })
      const data =
        response.data._registration_registerPatientAndCreateGuardianRelationship

      if (data.errorMessage) {
        setSnackbarMessage(fm(messages.add_related_person_error), null, 'error')
        setIsLoading(false)
        trackError()

        return
      }

      setIsLoading(false)
      setSnackbarMessage(
        isRelatedPersonMinor
          ? fm(messages.add_dependent_success, { name: currentMemberName })
          : fm(messages.add_guardian_success, { name: currentMemberName })
      )
      trackEvent({
        patientId: currentMemberId,
        elementName: trackingElementName,
        moduleName: MemberOpsModuleName.memberRelatedPersons,
        elementType: TrackingElementType.button,
        actionType: TrackingActionType.formSuccess,
      })

      if (isCurrentMemberMinor) {
        hideDrawer?.()
      }
    } catch (err) {
      logException(err)
      setIsLoading(false)
      setSnackbarMessage(fm(messages.add_related_person_error), null, 'error')
      trackError()
    }
  }

  return (
    <button
      {...menuItemProps}
      data-testid={trackingElementName}
      onClick={() => handleAddRelatedPerson()}
    >
      {isCurrentMemberMinor
        ? fm(messages.add_as_guardian)
        : fm(messages.add_dependent)}
    </button>
  )
}

export default AddRelatedPersonButton
