import React from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

export interface Props {
  name: string
}

const AdminName = ({ name }: Props) => (
  <div className={cx(styles.adminName)}>{name}</div>
)

export default AdminName
