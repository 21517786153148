import React, { FC, useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import {
  TrackingActionType,
  TrackingElementType,
  useTracking,
  useTrackClick,
} from '@babylon/tracking/react'
import { PatientIdProps } from '../../../types'
import { MemberOpsModuleName } from '../../../../..'
import AddComment from './AddComment'
import RecentComments from './RecentComments'
import PaginatedComments from './PaginatedComments'
import messages from './CommentsCard.messages'

export interface CommentsCardProps extends PatientIdProps {
  viewable?: number
  setSnackbarMessage: (m: string) => void
}

const CommentsCard: FC<CommentsCardProps> = ({
  patientId,
  setSnackbarMessage,
  viewable = 2,
}) => {
  const [viewMore, setViewMore] = useState(false)
  const [showAddCommentModal, setShowAddCommentModal] = useState(false)
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.commentsList,
  })
  const { trackEvent } = useTracking()

  const onViewMoreHandler = () => {
    setViewMore(true)
    trackClick({
      elementName: 'view-more-button',
      elementType: TrackingElementType.button,
    })
  }
  const onCloseHandler = () => setViewMore(false)
  const onAddNewCommentHandler = () => {
    setShowAddCommentModal(true)
    trackClick({
      elementName: 'open-add-comment-modal-button',
      elementType: TrackingElementType.button,
    })
  }

  const fm = useFormatMessage()

  return (
    <>
      <div data-testid="member-comments">
        <RecentComments
          patientId={patientId}
          viewable={viewable}
          onViewMoreComments={onViewMoreHandler}
          onAddNewComment={onAddNewCommentHandler}
          shouldRefetch={shouldRefetch}
          resetRefetch={() => setShouldRefetch(false)}
        />
      </div>
      <PaginatedComments
        open={viewMore}
        onCloseHandler={onCloseHandler}
        patientId={patientId}
      />
      <AddComment
        patientId={patientId}
        visible={showAddCommentModal}
        onCancel={() => {
          setShowAddCommentModal(false)
          trackClick({
            elementName: 'cancel-add-comment-button',
            elementType: TrackingElementType.button,
          })
        }}
        onSubmit={() => {
          setShowAddCommentModal(false)
          setSnackbarMessage(fm(messages.comment_added))
          setShouldRefetch(true)
          trackEvent({
            patientId: patientId,
            moduleName: MemberOpsModuleName.commentsList,
            elementType: TrackingElementType.form,
            elementName: 'add-comment-success',
            actionType: TrackingActionType.formSuccess,
          })
        }}
      />
    </>
  )
}

export default CommentsCard
