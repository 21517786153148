import { useFormatMessage } from '@babylon/intl'
import messages from './TimelineTabs.messages'
import { TabOption, TabId } from './types'

export default function useTabOptions(
  allowedTabs: TabId[],
  selectedTab: TabId
): TabOption[] {
  const fm = useFormatMessage()

  return allowedTabs.map((tab) => ({
    label: fm(messages[tab]),
    value: tab,
    selected: selectedTab === tab,
  }))
}
