import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Unknown } from '../..'
import CopyToClipboardButton from '../../CopyToClipboardButton'
import commonStyles from '../styles.module.scss'
import messages from '../CopiableMemberInformation.messages'
import styles from './styles.module.scss'

export interface AddressProps {
  line1?: string | null
  line2?: string | null
  line3?: string | null
  city?: string | null
  postcode?: string | null
  county?: string | null
  region?: string | null
  town?: string | null
  state?: string | null
  onAddressCopy?: (message: string, address: string) => void
}

const generateKey = (val: string | null, idx: number) => `${val}-${idx}`

const renderAddressLine = (line: string | null | void, idx: number) => {
  if (!line) {
    return null
  }

  return (
    <span
      className={styles.addressLine}
      key={`address-line-${generateKey(line, idx)}`}
    >
      {line}
    </span>
  )
}

const Address = (props: AddressProps) => {
  const { region, onAddressCopy, ...address } = props
  const { line1, line2, line3, town, city, county, state, postcode } = address
  const addressLines = [
    line1,
    line2,
    line3,
    town,
    city,
    state,
    county,
    postcode,
    region,
  ].filter(Boolean)
  const showAddress = !!addressLines.length
  const fm = useFormatMessage()
  const addressCopiedHandler = (copiedAddress: string) => {
    onAddressCopy && onAddressCopy(fm(messages.address), copiedAddress)
  }

  return (
    <div className={styles.address} data-testid="address">
      {showAddress ? (
        <div>
          <CopyToClipboardButton
            className={commonStyles.copiable__copyToClipboardButton}
            value={addressLines.join('\n')}
            onCopiedToClipboard={addressCopiedHandler}
          >
            <span data-testid="copiable-address">
              {addressLines.map(renderAddressLine)}
            </span>
          </CopyToClipboardButton>
        </div>
      ) : null}
      {!showAddress && <Unknown label={fm(messages.address_unknown)} />}
    </div>
  )
}

export default Address
