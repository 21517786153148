import { defineMessages } from 'react-intl'

const baseId = 'appointment_wait_time_configs'

export default defineMessages({
  pageTitle: {
    id: `${baseId}.page_title`,
    defaultMessage: 'Configure waiting times thresholds',
  },
  pageIntro: {
    id: `${baseId}.page_intro`,
    defaultMessage:
      'Define wait time thresholds to determine what waiting times are critical, overloaded, busy or good per Consumer Network and Service Type.',
  },
  createWaitTimeConfigsButtonTitle: {
    id: `${baseId}.create_wait_time_configs_button_title`,
    defaultMessage: 'Create new threshold',
  },
  createWaitTimeConfigsConflictErrorMessage: {
    id: `${baseId}.create_wait_time_configs_conflict_error_message`,
    defaultMessage:
      'Failed to create wait time threshold due to duplicate record already exists.',
  },
  createWaitTimeConfigsBadRequestErrorMessage: {
    id: `${baseId}.create_wait_time_configs_bad_request_error_message`,
    defaultMessage:
      'Failed to create wait time threshold due to providing invalid input.',
  },
  createWaitTimeConfigsInternalServertErrorMessage: {
    id: `${baseId}.create_wait_time_configs_internal_server_error_message`,
    defaultMessage: 'Failed to create wait time threshold. Please try again.',
  },
  deleteWaitTimeConfigsErrorMessage: {
    id: `${baseId}.delete_wait_time_configs_error_message`,
    defaultMessage: 'Failed to delete wait time threshold. Please try again.',
  },
})
