import React from 'react'
import { AppointmentStatus } from '../AppointmentManagementPage.types'
import styles from './AppointmentStatusBadge.module.css'
import type { AppointmentStatusBadgeProps } from './AppointmentStatusBadge.types'

const statusMap = {
  [AppointmentStatus.Arrived]: 'Arrived',
  [AppointmentStatus.Booked]: 'Booked',
  [AppointmentStatus.Cancelled]: 'Cancelled',
  [AppointmentStatus.CheckedIn]: 'Checked in',
  [AppointmentStatus.EnteredInError]: 'Entered in error',
  [AppointmentStatus.Fulfilled]: 'Fulfilled',
  [AppointmentStatus.Noshow]: 'No show',
  [AppointmentStatus.Pending]: 'Pending',
  [AppointmentStatus.Proposed]: 'Proposed',
  [AppointmentStatus.Waitlist]: 'Waitlist',
}

const AppointmentStatusBadge = ({ status }: AppointmentStatusBadgeProps) => (
  <span
    className={styles.appointmentStatus}
    data-testid="appointment-status-badge"
  >
    {statusMap[status]}
  </span>
)

export default AppointmentStatusBadge
