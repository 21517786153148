import { useState, useCallback } from 'react'
import {
  initConsultantType,
  initConsultationType,
  initConsultationTimezone,
  defaultValuesFromFilters,
} from './helpers'

interface UseBookingStateArgs {
  filters?: any
  enableRecordingConsent?: boolean
  consultationType?: string
  consultationTypeList?: string[]
  consultantType?: string
  consultantTypeList?: string[]
  slotDurationList?: { [key: string]: any }
}

// TODO: move into formik where possible
export default function useBookingState(props: UseBookingStateArgs) {
  const allProps = {
    ...props,
    ...defaultValuesFromFilters({ filters: props.filters }),
  }

  const {
    enableRecordingConsent = false,
    consultationType,
    consultationTypeList = [],
    consultantType,
    consultantTypeList = [],
    slotDurationList,
    slotDuration = 10,
    filters,
  } = allProps
  // TODO address the eslint error below
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultStateValues = {
    appointmentClinic: 'any',
    consultant: 'any',
    consultantType: initConsultantType({ consultantType, consultantTypeList }),
    consultationType: initConsultationType({
      consultationType,
      consultationTypeList,
    }),
    error: false,
    recordingConsent: enableRecordingConsent,
    validationError: false,
    slotDuration,
    consultationTimezone: initConsultationTimezone(consultationType),
  }

  const [bookingState, setBookingState] = useState(defaultStateValues)

  const resetState = useCallback(() => setBookingState(defaultStateValues), [
    defaultStateValues,
  ])

  const updateBookingState = (obj: { [key: string]: any }) =>
    setBookingState((state) => ({
      ...state,
      ...obj,
    }))

  const validationErrorUpdate = (error: any) =>
    updateBookingState({ error, validationError: !!error })

  const slotDurationUpdate = (value: number) =>
    updateBookingState({
      slotDuration: value,
    })

  const errorUpdate = (error: any) => updateBookingState({ error })
  const consultationTypeUpdate = (value: string | null) => {
    if (!value) {
      return
    }

    updateBookingState({
      consultationType: value,
      consultant: defaultStateValues.consultant,
      consultantType: defaultStateValues.consultantType,
      slotDuration:
        filters?.duration_minutes || slotDurationList?.[value]?.default || 10,
      appointmentClinic: defaultStateValues.appointmentClinic,
      recordingConsent: defaultStateValues.recordingConsent,
      consultationTimezone: initConsultationTimezone(value),
    })
  }

  const consultantTypeUpdate = (value: string | null) => {
    if (!value) {
      return
    }

    updateBookingState({
      consultantType: value,
      appointmentClinic: defaultStateValues.appointmentClinic,
      consultant: defaultStateValues.consultant,
      slotDuration: slotDurationList?.[value]?.default || 10,
    })
  }

  const consultantUpdate = (value: any) =>
    updateBookingState({
      consultant: value,
      appointmentClinic: defaultStateValues.appointmentClinic,
    })

  const appointmentClinicUpdate = (value: string) =>
    updateBookingState({
      appointmentClinic: value,
    })

  const consultationTimezoneUpdate = (value: string) =>
    updateBookingState({
      consultationTimezone: value,
    })

  return {
    ...allProps,
    ...bookingState,
    validationErrorUpdate,
    slotDurationUpdate,
    errorUpdate,
    consultationTypeUpdate,
    consultationTimezoneUpdate,
    consultantTypeUpdate,
    consultantUpdate,
    appointmentClinicUpdate,
    resetState,
  }
}
