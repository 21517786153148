import { gql } from '@apollo/client'

export interface CancelHumanitySyncMutationResult {
  status: string
}

export interface CancelHumanitySyncMutationVariables {
  id: string
}

export default gql`
  mutation CancelHumanitySync($id: ID) {
    cancelHumanitySync(id: $id) {
      status
    }
  }
`
