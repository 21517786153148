import { defineMessages } from 'react-intl'

const baseId = 'member_search'

export default defineMessages({
  first_name: {
    id: `${baseId}.first_name`,
    defaultMessage: 'First name',
  },
  last_name: {
    id: `${baseId}.last_name`,
    defaultMessage: 'Last name',
  },
  babylon_id: {
    id: `${baseId}.babylon_id`,
    defaultMessage: 'Babylon ID',
  },
  date_of_birth: {
    id: `${baseId}.date_of_birth`,
    defaultMessage: 'Date of birth',
  },
  post_code: {
    id: `${baseId}.post_code`,
    defaultMessage: 'Postcode',
  },
  full_phone_number: {
    id: `${baseId}.full_phone_number`,
    defaultMessage: 'Phone number',
  },
  email: {
    id: `${baseId}.email`,
    defaultMessage: 'Email',
  },
  public_healthcare_identifier: {
    id: `${baseId}.public_healthcare_identifier`,
    defaultMessage: 'Public ID',
  },
})
