import React from 'react'
import classNames from 'classnames'
import type { ExpandButtonProps } from './ExpandButton.types'
import styles from './ExpandButton.module.css'

export const ExpandButton = ({
  text,
  variant,
  isExpanded,
  onClick,
}: ExpandButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    aria-expanded={isExpanded}
    className={classNames({
      [styles.button]: true,
      [styles.collapsed]: variant === 'small',
      [styles.expanded]: isExpanded,
    })}
  >
    <span
      className={classNames({
        [styles.hidden]: variant === 'large',
      })}
    >
      {text}
    </span>
  </button>
)

export default ExpandButton
