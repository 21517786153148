import React from 'react'
import cx from 'classnames'
import styles from './button.module.css'

type AnimatedButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  width?: number
}

export const AnimatedButton = ({
  children,
  className,
  width,
  ...props
}: AnimatedButtonProps) => (
  <button
    type="button"
    style={{ width: `${width || 100}px` }}
    className={cx(className, styles.button, styles.animatedButton)}
    {...props}
  >
    {children}
  </button>
)

export const Button = ({
  children,
  className,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button type="button" className={cx(className, styles.button)} {...props}>
    {children}
  </button>
)
