import { gql } from '@apollo/client'

export const updateConsent = gql`
  mutation updateGpConsent($id: ID!, $gpConsent: Boolean!) {
    updateConsultationGpConsent(id: $id, gpConsent: $gpConsent) {
      id
      gpConsent
    }
  }
`

export const getConsent = gql`
  query ConsultationGpDetails($consultationId: ID!) {
    consultation(id: $consultationId) {
      gpConsent
    }
  }
`
