import { defineMessages } from 'react-intl'

const baseId = 'app_content'

export default defineMessages({
  user_warning: {
    id: `${baseId}.user_warning`,
    defaultMessage: 'You are not allowed to use this portal as a clinician.',
  },
  use_clinical_portal: {
    id: `${baseId}.use_clinical_portal`,
    defaultMessage: 'Please use Clinical Portal instead.',
  },
  logout: {
    id: `${baseId}.logout`,
    defaultMessage: 'Log out',
  },
})
