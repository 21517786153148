import { defineMessages } from 'react-intl'

const baseId = 'referrals'

export default defineMessages({
  heading: {
    id: `${baseId}.heading`,
    defaultMessage: 'Referrals',
  },
  send: {
    id: `${baseId}.send`,
    defaultMessage: 'Send',
  },
  resend: {
    id: `${baseId}.resend`,
    defaultMessage: 'Resend',
  },
})
