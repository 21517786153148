import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'

import { TabGroup, Tab } from '@babylon/babylon-forms/widgets'
import PopupRoute from '@/components/PopupRoute'
import PageHeader from '@/components/PageHeader'

import Drugs from './drugs'
import Alerts from './alerts'
import ActiveIngredients from './activeIngredients'
import DrugEdit from '../DrugEdit'
import DrugAudits from '../DrugAudits'
import DrugAlertEdit from '../DrugAlertEdit'
import DrugAlertAudits from '../DrugAlertAudits'
import DrugActiveIngredientsAudits from '../DrugActiveIngredientsAudits'

import styles from './styles.module.scss'

const DrugsAndDrugAlerts = ({ history, location: { pathname } }) => (
  <div>
    <PageHeader>Drugs</PageHeader>
    <TabGroup
      value={pathname}
      onChange={(_, route) => history.push(route)}
      className={styles.tabs}
    >
      <Tab name="/admin/drugs" title="Drug list" />
      <Tab name="/admin/drugs/alerts" title="Drug alerts" />
      <Tab name="/admin/drugs/active-ingredients" title="Active ingredients" />
    </TabGroup>
    <Switch>
      <Route path="/admin/drugs/alerts" component={Alerts} />
      <Route
        path="/admin/drugs/active-ingredients"
        component={ActiveIngredients}
      />
      <Route path="/admin/drugs" component={Drugs} />
    </Switch>
    <PopupRoute path="/admin/drugs/add" component={DrugEdit} />
    <PopupRoute path="/admin/drugs/edit/:id" component={DrugEdit} />
    <PopupRoute path="/admin/drugs/audits/:id" component={DrugAudits} />
    <PopupRoute
      path="/admin/drugs/alerts/audit/:id"
      component={DrugAlertAudits}
    />
    <PopupRoute path="/admin/drugs/alerts/edit/:id" component={DrugAlertEdit} />
    <PopupRoute path="/admin/drugs/alerts/add" component={DrugAlertEdit} />
    <PopupRoute
      path="/admin/drugs/active-ingredients/audits/:id"
      component={DrugActiveIngredientsAudits}
    />
  </div>
)

export default withRouter(DrugsAndDrugAlerts)
