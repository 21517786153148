import React from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import { Page } from '@babylon/core-ui'

import { envFlag } from '@babylon/babylon-env'
import { useFormatMessage } from '@babylon/intl'
import GetServiceTypeQuery from './GetServiceTypeQuery'
import SaveServiceTypeMutation, {
  SaveServiceTypeInput,
} from './SaveServiceTypeMutation'
import GetServiceTypeCategories from '../GetServiceTypeCategoriesQuery'
import ServiceTypeInfo from '../ServiceTypeInfo'
import ServiceTypeClinicians from './ServiceTypeClinicians'
import ServiceTypeClinics from './ServiceTypeClinics'
import ServiceTypeConsumerNetworks from './ServiceTypeConsumerNetworks'
import { UnexpectedError } from '../../Utils'
import { LoadingContainer } from '../../components'
import messages from '../serviceTypes.messages'
import ServiceTypePartnerPortalLink from './ServiceTypePartnerPortal/ServiceTypePartnerPortalLink'

const ServiceType = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const { loading: getLoading, error: queryError, data, refetch } = useQuery(
    GetServiceTypeQuery,
    {
      variables: { uuid },
    }
  )
  const [
    saveServiceType,
    { loading: saveLoading, error: mutationError },
  ] = useMutation(SaveServiceTypeMutation)

  const {
    loading: loadingCategories,
    data: categories,
    error: errorCategories,
  } = useQuery(GetServiceTypeCategories)

  const fm = useFormatMessage()

  const {
    name,
    member_facing_name: memberFacingName,
    default_duration_minutes: durationMinutes,
    grouping,
    us_ehr_appointment_type: usEhrAppointmentType,
    member_instructions: instructions,
    member_description: memberDescription,
    clinics,
    clinicians,
    consumer_networks: consumerNetworks,
    preferred_medium: preferredMedium,
    allowed_mediums: allowedMediums,
    categories: preferredCategories,
  } = data?.serviceType || {}

  const handleSave = async (input: SaveServiceTypeInput): Promise<void> => {
    await saveServiceType({ variables: { input } })
    await refetch()
  }

  const hasError = !!queryError || !!mutationError || !!errorCategories
  const errorMessage =
    queryError?.message || mutationError?.message || errorCategories?.message

  const showPartnerPortalLink = envFlag(
    'ENABLE_LINK_FROM_SERVICE_TYPE_TO_PARTNER_PORTAL',
    false
  )

  return (
    <Page
      breadcrumbs={[
        <Link key="1" to="/admin/service-mix">
          {fm(messages.header)}
        </Link>,
        'Service Type',
      ]}
    >
      <LoadingContainer
        loading={getLoading || saveLoading || loadingCategories}
      >
        <UnexpectedError visible={hasError} message={errorMessage} />
        <ServiceTypeInfo
          name={name}
          memberFacingName={memberFacingName}
          durationMinutes={durationMinutes}
          usEhrAppointmentType={usEhrAppointmentType}
          grouping={grouping}
          instructions={instructions}
          memberDescription={memberDescription}
          allowedMediums={allowedMediums}
          preferredMedium={preferredMedium}
          onSave={handleSave}
          uuid={uuid}
          preferredCategories={preferredCategories}
          categories={categories?.serviceTypeCategories}
        />

        {showPartnerPortalLink ? (
          <ServiceTypePartnerPortalLink />
        ) : (
          <ServiceTypeConsumerNetworks
            serviceTypeUuid={uuid}
            consumerNetworks={consumerNetworks}
          />
        )}

        <ServiceTypeClinics serviceTypeUuid={uuid} clinics={clinics} />
        <ServiceTypeClinicians serviceTypeUuid={uuid} clinicians={clinicians} />
      </LoadingContainer>
    </Page>
  )
}

export default ServiceType
