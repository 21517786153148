import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { ConfirmationModal } from '@babylon/core-ui'
import messages from './RelatedPersons.messages'

interface RenderConfirmationDialogProps {
  loading?: boolean
  onClose: any
  onConfirm: any
  title: string
  description: string
}

export default ({
  loading,
  onClose,
  onConfirm,
  title,
  description,
}: RenderConfirmationDialogProps) => {
  const fm = useFormatMessage()

  return (
    <ConfirmationModal
      open
      includeClose={false}
      loading={loading}
      onClose={() => onClose.forEach((h: any) => h.call(''))}
      onConfirm={onConfirm}
    >
      <ConfirmationModal.Heading>
        {fm(messages[title as keyof typeof messages])}
      </ConfirmationModal.Heading>
      <ConfirmationModal.Content>
        {fm(messages[description as keyof typeof messages])}
      </ConfirmationModal.Content>
    </ConfirmationModal>
  )
}
