import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery, useMutation } from '@apollo/client'
import { RadioGroup, Radio } from '@babylon/core-ui'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../..'
import messages from './ShareNotes.messages'
import { getConsent, updateConsent } from './queries'

type Consultation = {
  consultation: {
    gpConsent: boolean
  }
}

const ShareNotes = ({ consultationId }: { consultationId: string }) => {
  const [consentChecked, setConsentChecked] = useState(false)

  const [updateConsentMutation] = useMutation(updateConsent)

  const { loading } = useQuery<Consultation>(getConsent, {
    variables: {
      consultationId,
    },
    onCompleted: ({ consultation: { gpConsent } }) =>
      setConsentChecked(gpConsent),
  })

  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })

  if (loading) {
    return null
  }

  return (
    <RadioGroup
      name="consent"
      value={`${consentChecked}`}
      onChange={() => {
        updateConsentMutation({
          variables: {
            id: consultationId,
            gpConsent: !consentChecked,
          },
        })
        setConsentChecked((state) => !state)

        if (consentChecked === true) {
          trackClick({
            elementName: 'enable-consent-consultation-summary-btn',
            elementType: TrackingElementType.button,
          })
        } else {
          trackClick({
            elementName: 'disable-consent-consultation-summary-btn',
            elementType: TrackingElementType.button,
          })
        }
      }}
      columns={2}
    >
      <Radio value="true" data-testid="accept-share-notes">
        <FormattedMessage {...messages.accept} />
      </Radio>
      <Radio value="false">
        <FormattedMessage {...messages.decline} />
      </Radio>
    </RadioGroup>
  )
}

export default ShareNotes
