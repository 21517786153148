import React, { KeyboardEvent, MouseEvent, ReactNode, memo } from 'react'
import { Link } from 'react-router-dom'
import { MessageDescriptor } from 'react-intl'
import classnames from 'classnames'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import { useBabylonUser } from '@babylon/babylon-user'
import { useFormatMessage } from '@babylon/intl'
import { ViewMoreToggler } from '@babylon/member-operations'
import {
  BabylonUser,
  GeneratedLink,
  NavCategory,
  NavItem,
  NavLink,
  IconKeys,
} from './types'

import { getNavStructure, isNavCategory } from './utils'
import useNavState from './hooks/useNavState'
import { useAccessControl } from '../../hooks'
import Icon from './Icon'
import styles from './styles.module.scss'

interface SideNavLinkProps extends NavLink {
  title: string
  visible: boolean
  isTopLevel?: boolean
}

interface SideNavCategoryProps extends NavCategory {
  title: string
}

interface SideNavTitleProps {
  expandable: boolean
  title: ReactNode
  expanded?: boolean
  icon?: IconKeys
}

type Formatter = (message: MessageDescriptor) => string

const getTitle = ({ title }: NavItem, formatter: Formatter) =>
  isString(title) ? title : formatter(title)

const getGeneratedLink = (link: GeneratedLink | string, user: BabylonUser) => {
  if (isFunction(link) && user) {
    const { id: userId } = user
    return link(userId)
  }
  return isString(link) ? link : '/'
}

const SideNavTitle = ({
  expandable,
  expanded,
  icon,
  title,
}: SideNavTitleProps) => {
  const inline = !expandable && !icon
  const className = classnames(styles.sideNav__title, {
    [styles['sideNav__title--inline']]: inline,
    [styles['sideNav__title--expanded']]: !!expanded,
  })
  const iconClassName = classnames(styles.sideNav__icon, {
    [styles['sideNav__icon--expanded']]: !!expanded,
  })
  const toggleClassName = classnames(styles.sideNav__toggle, {
    [styles['sideNav__toggle--expanded']]: !!expanded,
  })

  return (
    <span className={className} data-testid="side-navigation-title">
      <span className={styles.sideNav__titleWrapper}>
        <span className={iconClassName}>
          <Icon id={icon} />
        </span>
        {title}
      </span>
      {expandable && (
        <span className={toggleClassName}>
          <ViewMoreToggler open={!!expanded} testId="side-navigation-toggle" />
        </span>
      )}
    </span>
  )
}

const SideNavLink = memo(
  ({ icon, isTopLevel, link, title, visible, hidden }: SideNavLinkProps) => {
    const user = useBabylonUser()
    if (!link || !user || hidden) {
      return null
    }

    const generatedLink = getGeneratedLink(link, user)
    const className = isTopLevel ? 'sideNav__topLevelLink' : 'sideNav__link'

    return (
      <li>
        <Link
          className={styles[className]}
          aria-hidden={!visible}
          data-testid="side-navigation-link"
          tabIndex={!visible ? -1 : undefined}
          to={generatedLink}
        >
          <SideNavTitle expandable={false} icon={icon} title={title} />
        </Link>
      </li>
    )
  }
)

const SideNavCategory = ({
  id,
  icon,
  links,
  title,
  hidden,
}: SideNavCategoryProps) => {
  const fm = useFormatMessage()
  const [navState, setNavState] = useNavState()
  const isExpanded = !!navState[id]

  if (!Array.isArray(links) || !links.length || hidden) {
    return null
  }

  const categoryTitle = isString(title) ? title : fm(title)
  const titleClassNames = classnames(styles.sideNav__categoryTitle, {
    [styles['sideNav__categoryTitle--expanded']]: isExpanded,
  })
  const linkClassNames = classnames(styles.sideNav__links, {
    [styles['sideNav__links--expanded']]: isExpanded,
  })

  const toggleCategory = () => {
    const newCategoryState = !isExpanded
    setNavState(id, newCategoryState)
  }

  const categoryButtonHandler = () => (
    event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>
  ) => {
    const { keyCode = null } = event as KeyboardEvent
    if (keyCode === 13 || keyCode === null) {
      toggleCategory()
    }
  }

  return (
    <li
      className={styles.sideNav__category}
      data-testid="side-navigation-category"
    >
      <div
        className={titleClassNames}
        data-testid="side-navigation-category-toggle"
        aria-expanded={isExpanded}
        aria-pressed={isExpanded}
        role="button"
        tabIndex={0}
        onClick={categoryButtonHandler()}
        onKeyDown={categoryButtonHandler()}
      >
        <SideNavTitle
          icon={icon}
          title={categoryTitle}
          expandable
          expanded={isExpanded}
        />
      </div>
      <ul className={linkClassNames} data-testid="side-navigation-links">
        {links.map((link, idx) => {
          const linkTitle = getTitle(link, fm)
          const key = `${linkTitle}-${idx}`
          return (
            <SideNavLink
              key={key}
              {...link}
              title={linkTitle}
              visible={isExpanded}
            />
          )
        })}
      </ul>
    </li>
  )
}

const SideNav = () => {
  const fm = useFormatMessage()
  const [accessControl] = useAccessControl()
  const structure = getNavStructure(accessControl)

  return (
    <nav className={styles.sideNav} data-testid="side-navigation">
      <ul className={styles.sideNav__categories}>
        {structure?.map((navItem) => {
          const title = getTitle(navItem, fm)
          if (isNavCategory(navItem)) {
            return <SideNavCategory key={title} {...navItem} title={title} />
          }
          return (
            <SideNavLink
              key={title}
              {...navItem}
              isTopLevel
              title={title}
              visible
            />
          )
        })}
      </ul>
    </nav>
  )
}

export default SideNav
