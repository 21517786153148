/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type AppointmentWaitTimesQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int']
  limit: Types.Scalars['Int']
  consumerNetworkIds?: Types.Maybe<Array<Types.Maybe<Types.Scalars['ID']>>>
  serviceTypeUuids?: Types.Maybe<Array<Types.Maybe<Types.Scalars['ID']>>>
}>

export type AppointmentWaitTimesQuery = {
  appointmentWaitTimes: Pick<
    Types.AppointmentWaitTimes,
    'total' | 'offset' | 'limit'
  > & {
    waitTimes: Array<
      Pick<
        Types.AppointmentWaitTime,
        'status' | 'medium' | 'nextAppointmentTimes' | 'timeUpdated'
      > & {
        consumerNetwork: Pick<Types.ConsumerNetwork, 'id' | 'name'>
        serviceType: Pick<Types.ServiceType, 'id' | 'name'>
      }
    >
  }
}

export const AppointmentWaitTimesDocument = gql`
  query AppointmentWaitTimes(
    $offset: Int!
    $limit: Int!
    $consumerNetworkIds: [ID]
    $serviceTypeUuids: [ID]
  ) {
    appointmentWaitTimes(
      offset: $offset
      limit: $limit
      consumerNetworkIds: $consumerNetworkIds
      serviceTypeUuids: $serviceTypeUuids
    ) {
      waitTimes {
        status
        medium
        nextAppointmentTimes
        timeUpdated
        consumerNetwork {
          id
          name
        }
        serviceType {
          id
          name
        }
      }
      total
      offset
      limit
    }
  }
`
export function useAppointmentWaitTimesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentWaitTimesQuery,
    AppointmentWaitTimesQueryVariables
  >
) {
  return Apollo.useQuery<
    AppointmentWaitTimesQuery,
    AppointmentWaitTimesQueryVariables
  >(AppointmentWaitTimesDocument, baseOptions)
}
export function useAppointmentWaitTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentWaitTimesQuery,
    AppointmentWaitTimesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AppointmentWaitTimesQuery,
    AppointmentWaitTimesQueryVariables
  >(AppointmentWaitTimesDocument, baseOptions)
}
export type AppointmentWaitTimesQueryHookResult = ReturnType<
  typeof useAppointmentWaitTimesQuery
>
export type AppointmentWaitTimesLazyQueryHookResult = ReturnType<
  typeof useAppointmentWaitTimesLazyQuery
>
export type AppointmentWaitTimesQueryResult = Apollo.QueryResult<
  AppointmentWaitTimesQuery,
  AppointmentWaitTimesQueryVariables
>
