import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { Text } from '@babylon/core-ui'

import styles from './ClinicianAvatarAndName.module.css'

export function ClinicianDetails({ name, profession, to }) {
  return (
    <div className={styles.ClinicianDetailsContainer}>
      <Text data-testid="clinician-name" size="large" bold>
        {to ? <Link to={to}>{name}</Link> : name}
      </Text>
      {profession && (
        <Text data-testid="clinician-profession" size="small">
          {profession}
        </Text>
      )}
    </div>
  )
}

interface ClinicianAvatarAndNameProps {
  avatarUrl?: string
  name?: string
  profession?: string
  to?: string
  className?: string
}

export default function ClinicianAvatarAndName({
  avatarUrl,
  name = '',
  profession = '',
  to,
  className,
}: ClinicianAvatarAndNameProps) {
  return (
    <div className={cx(styles.ClinicianAvatarAndName, className)}>
      <AvatarWithFallback url={avatarUrl} initial={name.charAt(0)} />
      <ClinicianDetails name={name} profession={profession} to={to} />
    </div>
  )
}

interface AvatarWithFallbackProps {
  url?: string
  initial: string
}

const AvatarWithFallback = ({ url, initial }: AvatarWithFallbackProps) => {
  const AvatarClassName = cx(styles.ClinicianAvatarAndNameAvatar, {
    [styles.ClinicianAvatarAndNameAvatarFallback]: !url,
  })

  if (!url) {
    return (
      <div data-testid="clinician-avatar-fallback" className={AvatarClassName}>
        {initial}
      </div>
    )
  }

  return (
    <img
      className={AvatarClassName}
      data-testid="clinician-avatar"
      src={url}
      alt="Clinician avatar"
    />
  )
}
