import React from 'react'

import { Grid, Cell } from '@babylon/core-ui'

import DurationSelector from './DurationSelector'
import { PatientDetails } from '..'

import styles from './ModalHeader.module.css'

export default function ModalHeader({
  patient,
  disabled,
  appointmentClinic,
  consultationType,
  selectedDate,
  selectedDateUpdate,
  selectedWeek,
  selectedWeekUpdate,
  appointmentClinicList,
}) {
  return (
    <Grid columns="10" className={styles.headSection}>
      <Cell width="2">
        <h3 className={styles.dialogTitle}>Book an Appointment</h3>
      </Cell>
      <Cell width="4">
        <DurationSelector
          disabled={disabled}
          appointmentClinic={appointmentClinic}
          appointmentClinicList={appointmentClinicList}
          consultationType={consultationType}
          selectedDate={new Date(selectedDate)}
          selectedDateUpdate={(d) => selectedDateUpdate(new Date(d))}
          selectedWeek={selectedWeek}
          selectedWeekUpdate={selectedWeekUpdate}
        />
      </Cell>
      <Cell width="4" style={{ textAlign: 'right' }}>
        <PatientDetails patient={patient} />
      </Cell>
    </Grid>
  )
}
