import { defineMessages } from 'react-intl'

const baseId = 'member_search.member_card.details'

export default defineMessages({
  dob_copied: {
    id: `${baseId}.dob_copied`,
    defaultMessage: 'Date of birth copied',
  },
  address_copied: {
    id: `${baseId}.address_copied`,
    defaultMessage: 'Address copied',
  },
  phone_copied: {
    id: `${baseId}.phone_copied`,
    defaultMessage: 'Phone number copied',
  },
  email_copied: {
    id: `${baseId}.email_copied`,
    defaultMessage: 'Email copied',
  },
})
