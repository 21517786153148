import React, { Children, Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { withValue, withOnChange, compose } from '../forms'

const createPointerStyle = (width, left) => ({
  width: `${width}px`,
  transform: `translate3d(${left}px, -2px, 0)`,
})
/**
 * Container for `Tabs`.
 *
 * @example ./TabGroup.md
 */
class TabGroup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTabName: props.value || null,
      pointerStyle: createPointerStyle(0, 0),
    }
  }

  componentDidMount() {
    this.calculatePointerPosition()
  }

  // TO-DO: fix unsafe method:
  // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.activeTabName) {
      this.setState({ activeTabName: nextProps.value })
    }
  }

  calculatePointerPosition = () => {
    const { _activeButton = null } = this
    if (_activeButton) {
      const rect = _activeButton.getBoundingClientRect()
      const width = Math.round(rect.width)
      const left = _activeButton.offsetLeft

      this.setState({
        pointerStyle: createPointerStyle(width, left),
      })
    }
  }

  handleButtonClick = (activeTabName) => (event) => {
    const { onChange } = this.props
    const prevTabName = this.state.activeTabName
    if (prevTabName !== activeTabName) {
      this.setState({ activeTabName })
      onChange && onChange(event, activeTabName)
      setTimeout(this.calculatePointerPosition)
    }
  }

  setActiveButtonRef = (isActive) => (button) => {
    if (isActive) {
      this._activeButton = button
    }
  }

  render() {
    const { className } = this.props
    const children = Children.toArray(this.props.children)
    const { activeTabName, pointerStyle } = this.state
    if (children.length === 0) {
      return null
    }
    const buttons = children.map((child) => {
      const { name, title, disabled = false } = child.props
      const active = child.props.name === activeTabName
      return (
        <div
          key={name}
          role="tab"
          tabIndex={0}
          className={cx('tab', { active, disabled })}
          ref={this.setActiveButtonRef(active)}
          onClick={this.handleButtonClick(name)}
        >
          {title}
        </div>
      )
    })
    const content = activeTabName
      ? children.find((child) => child.props.name === activeTabName)
      : null
    return (
      <div className={cx('widget tab-group', className)}>
        <div className="tab-list-container">
          <div className="tab-list" role="tablist">
            {buttons}
          </div>
          <div className="pointer" style={pointerStyle} />
        </div>
        <div className="tab-panel" role="tabpanel">
          {content}
        </div>
      </div>
    )
  }
}

TabGroup.defaultProps = {
  value: null,
  onChange: null,
}

TabGroup.propTypes = {
  /** Default tab name */
  value: PropTypes.string,
  /** Event handler that will be triggered every tame a new tab is opened */
  onChange: PropTypes.func,
}

export default compose(withValue, withOnChange)(TabGroup)
