import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../../../../..'
import { FETCH_PRESCRIPTIONS } from '../../queries'
import { statuses } from '../../statuses'
import messages from './messages'

const PrescriptionLinks = ({
  patientId,
  className,
}: {
  patientId: string
  className: string
}) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()

  const { data, loading: loadingActive } = useQuery(FETCH_PRESCRIPTIONS, {
    variables: {
      patientId,
      statuses: statuses.notArchived,
    },
  })

  const { data: archivedData, loading: loadingArchived } = useQuery(
    FETCH_PRESCRIPTIONS,
    {
      variables: {
        patientId,
        statuses: statuses.archived,
      },
    }
  )

  return (
    <>
      {!loadingActive && (
        <Link
          to={`/admin/patients/${patientId}/prescriptions`}
          onClick={(e) => {
            trackClick({
              elementName: 'prescriptions-link',
              elementType: TrackingElementType.link,
              href: e.currentTarget.href,
            })
          }}
          className={className}
        >
          {fm(messages.active_prescriptions)}{' '}
          {`(${data?.prescriptions.totalItems || 0})`}
        </Link>
      )}
      {!loadingArchived && (
        <Link
          to={`/admin/patients/${patientId}/prescriptions?archived=true`}
          onClick={(e) => {
            trackClick({
              elementName: 'prescriptions-archive-link',
              elementType: TrackingElementType.link,
              href: e.currentTarget.href,
            })
          }}
          className={className}
        >
          {fm(messages.archived_prescriptions)}{' '}
          {`(${archivedData?.prescriptions.totalItems || 0})`}
        </Link>
      )}
    </>
  )
}

export default PrescriptionLinks
