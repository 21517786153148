import React, { FC, useState } from 'react'
import { Route } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Button, Cell, Grid, Snackbar } from '@babylon/core-ui'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import isUUID from 'validator/lib/isUUID'
import { useFormatMessage } from '@babylon/intl'
import { envFlag } from '@babylon/babylon-env'
import { useProductConfig } from '@babylon/product-config'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import {
  ConversationList,
  LiveConversationsProvider,
} from '@babylon/live-conversations'
import { DocumentRepository } from '@babylon/document-repository'
import {
  ErrorBoundary,
  useSnackBar,
  LegacyPortalScreen,
  LinkButton,
  Header,
  ConversationTimeline,
  MemberOpsModuleName,
} from '../../..'
import messages from './MemberView.messages'
import AccountServices from './components/AccountServices'
import AlertList from './components/AlertList'
import AssessmentCard from './components/AssessmentCard'
import CommentsCard from './components/CommentsCard'
import ConsumerNetworksCard from './components/ConsumerNetworksCard'
import ProfileHeader from './components/ProfileHeader'
import styles from './styles.module.scss'
import RelatedPersons from './components/RelatedPersonsCard'
import { TimelineManagement } from './components/TimelineManagement'
import MemberRelationshipsCard from './components/MemberRelationshipsCard'

import { fullPatientFields } from '../../../queries'
import { PATIENT_QUERY } from '../../../queries/timeline/queries'
import { PROFILE_QUERY } from '../queries'
import LoadingProfileHeader from './components/ProfileHeader/LoadingProfileHeader'
import {
  errorListFromQueryError,
  includesAuthenticationError,
  includesMissingRecordError,
} from '../../../util/helpers'
import useParseSupportInteraction from '../../../members-search/PlatformGatewaySearch/hooks/useParseSupportInteraction'
import SupportInteractionModal from './components/SupportInteractionModal'
import MembersTimeToggle from './components/MembersTimeToggle'
import { TimezoneContextProvider } from './components/TimezoneProvider'
import {
  LEGAL_CONSENTS_DECISIONS_QUERY,
  LegalConsentsDecisions,
  LegalConsentsDecisionsQueryVariables,
} from './components/ProfileHeader/queries'
import {
  LegalConsentsDecisionsState,
  MemberAddress,
} from './components/ProfileHeader/MemberProfile'

export interface MemberViewProps {
  patientId: string

  onSwitchToOldView(): void
}

export interface BannerProps {
  onClick: () => void
  onClose: () => void
}

const Banner: FC<BannerProps> = ({ onClick, onClose }: BannerProps) => (
  <div className={styles.BannerSpace}>
    <div className={styles.BannerWrapper}>
      <Snackbar open fill intent="warning" onClose={onClose} fixed={false}>
        <button
          type="button"
          data-testid="banner-button"
          className={styles.BannerLink}
          onClick={onClick}
        >
          <FormattedMessage {...messages.banner} />
        </button>
      </Snackbar>
    </div>
  </div>
)

export const MemberView = ({
  patientId,
  onSwitchToOldView,
}: MemberViewProps) => {
  const { getBlob } = useProductConfig()
  const {
    accountServiceCovid19HistoryEnabled,
    activityLogEnabled,
    appointmentCreditsEnabled,
    commentsCardEnabled,
    conversationHistoryTabConversationTypes,
    devicesEnabled,
    documentRepositoryEnabled,
    memberAlertsEnabled,
    memberRelationsEnabled,
    memberTimezoneToggleEnabled,
    prescriptionLinksEnabled,
    preregistrationManagementEnabled,
    redeemCodeEnabled,
    redeemedPromoCodesEnabled,
    supportInteractionsEnabled,
    sickNotesEnabled,
    transactionsEnabled,
  } = getBlob('memberOperations')

  const enableAccountServices =
    accountServiceCovid19HistoryEnabled ||
    activityLogEnabled ||
    appointmentCreditsEnabled ||
    devicesEnabled ||
    prescriptionLinksEnabled ||
    redeemCodeEnabled ||
    redeemedPromoCodesEnabled ||
    sickNotesEnabled ||
    transactionsEnabled

  const {
    snackBar: { message, open, autoHideDuration, intent },
    setSnackbarMessage,
  } = useSnackBar()

  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })

  const [activeTab, setActiveTab] = useState('activity')
  const supportInteraction = useParseSupportInteraction()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isUrlParamUuid = isUUID(patientId)

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
  } = useQuery(PROFILE_QUERY, {
    variables: {
      patientUuid: patientId,
    },
    skip: !isUrlParamUuid,
    errorPolicy: 'all',
    context: { clientName: 'platform-gateway' },
  })
  const profile = profileData?.patient ?? null
  const firstName = profile?.name?.given?.join(' ') ?? ''
  const lastName = profile?.name?.family ?? ''
  const fullName =
    firstName && lastName
      ? `${lastName.toUpperCase()}, ${firstName}`
      : firstName || lastName
  const profileCoreIdentifier = profile?.identifiers?.edges?.find(
    (edge) => edge.node.system === 'https://coreruby.bbl.health/Patient'
  )
  const patientCoreId = isUrlParamUuid
    ? profileCoreIdentifier?.node?.value
    : patientId
  const isStubbedProfile = !profileCoreIdentifier?.node?.value && isUrlParamUuid
  const isPendingRegistration = profile?.activationStatus === 'INVITATION_SENT'

  const {
    data: memberData,
    loading: memberLoading,
    error: memberError,
  } = useQuery(PATIENT_QUERY(fullPatientFields), {
    variables: { patientId: patientCoreId },
    skip: isUrlParamUuid && isStubbedProfile,
    onCompleted: () => {
      if (
        supportInteractionsEnabled &&
        supportInteraction &&
        !memberLoading &&
        !memberError
      ) {
        setIsModalOpen(true)
      }
    },
  })
  const member = get(memberData, 'patient')

  const isLegalConsentPreviewEnabled: boolean = !!envFlag(
    'ENABLE_MEMBER_LEGAL_CONSENT_PREVIEW'
  )

  const isLiveConversationHistoryInMemberViewEnabled: boolean = !!envFlag(
    'ENABLE_LIVE_CONVERSATION_HISTORY_IN_MEMBER_VIEW'
  )
  const isConversationHistoryInMemberViewEnabled: boolean = !!envFlag(
    'ENABLE_CONVERSATION_HISTORY_IN_MEMBER_VIEW'
  )

  const {
    data: legalConsentDecisions,
    loading: loadingConsentsDecisions,
  } = useQuery<LegalConsentsDecisions, LegalConsentsDecisionsQueryVariables>(
    LEGAL_CONSENTS_DECISIONS_QUERY,
    {
      variables:
        member?.uuid != null ? { patientUUID: member?.uuid } : undefined,
      skip: !isLegalConsentPreviewEnabled || member?.uuid == null,
    }
  )

  if (memberLoading || profileLoading) {
    return <LoadingProfileHeader />
  }

  if (memberError || profileError) {
    const parsedErrors = memberError
      ? errorListFromQueryError(memberError)
      : errorListFromQueryError(profileError)

    if (includesAuthenticationError(parsedErrors)) {
      return (
        <div className={styles.Error} data-testid="member-view-not-allowed">
          <FormattedMessage {...messages.forbidden} />
        </div>
      )
    }

    if (includesMissingRecordError(parsedErrors)) {
      return (
        <div className={styles.Error} data-testid="member-view-not-found">
          <FormattedMessage
            {...messages.patient_not_found}
            values={{ patientId }}
          />
        </div>
      )
    }
  }

  const makeLegalDecisionState = (): LegalConsentsDecisionsState => {
    if (!isLegalConsentPreviewEnabled) {
      return { state: 'featureDisabled' }
    }

    if (memberLoading || loadingConsentsDecisions) {
      return { state: 'loading' }
    }

    return { state: 'loaded', ...legalConsentDecisions }
  }

  if (!profile && !member) {
    return (
      <div className={styles.Error} data-testid="member-data-not-found">
        <FormattedMessage
          {...messages.patient_not_found}
          values={{ patientId }}
        />
      </div>
    )
  }

  const profileAddress = profile && profile.addresses?.edges[0]?.node

  // we need to use the profile member address even if patient isn't stubbed as the gql middleware address doesnt have the city
  const memberAddress: MemberAddress = {
    firstLine: profileAddress?.line[0] ?? member?.address_first_line,
    secondLine: profileAddress?.line[1] ?? member?.address_second_line,
    thirdLine: profileAddress?.line[3] ?? member?.address_third_line,
    postCode: profileAddress?.postalCode ?? member?.address_post_code,
    stateCode: profileAddress?.state ?? member?.address_state_code,
    city: profileAddress?.city ?? '', // does not exist in gql middleware member
  }

  if (isStubbedProfile) {
    const profileEmailAddressEdge = profile.telecoms?.edges?.find(
      (edge) => edge.node?.emailAddress
    )
    const profilePhoneNumberEdge = profile.telecoms?.edges?.find(
      (edge) => edge.node?.phoneNumber
    )

    const stubMember: Partial<Member> = {
      address_post_code: profileAddress?.postalCode,
      address_first_line: profileAddress?.line[0] ?? '',
      address_second_line: profileAddress?.line[1] ?? '',
      address_third_line: profileAddress?.line[2] ?? '',
      address_state_code: profileAddress?.state ?? '',
      address_city: profileAddress?.city ?? '',
      first_name: firstName,
      last_name: lastName,
      date_of_birth: profile.birthDate,
      email: profileEmailAddressEdge?.node?.emailAddress ?? '',
      full_phone_number: profilePhoneNumberEdge?.node?.phoneNumber ?? '',
      gender: profile.gender,
      minor: profile.isMinor,
      uuid: profile.id,
    }

    return (
      <div className={styles.innerLayout} data-testid="stubbed-member-view">
        <Snackbar
          className={styles.Snackbar}
          message={message}
          open={!!open}
          autoHideDuration={autoHideDuration}
          intent={intent}
          onClose={() => setSnackbarMessage('')}
        />
        <ErrorBoundary>
          <ProfileHeader
            member={stubMember}
            memberAddress={memberAddress}
            legalDecisionsState={makeLegalDecisionState()}
            setSnackbarMessage={setSnackbarMessage}
            onSwitchToOldView={onSwitchToOldView}
            isPendingRegistration={isPendingRegistration}
            isStubbedProfile
          />
        </ErrorBoundary>
        {preregistrationManagementEnabled && (
          <Grid className={styles.innerLayout} columns={12}>
            <Cell width={3} left={9}>
              <ErrorBoundary>
                <MemberRelationshipsCard
                  isMinor={stubMember.minor}
                  isStubbedProfile={isStubbedProfile}
                  memberId={stubMember.uuid}
                  isPendingRegistration={isPendingRegistration}
                  currentMemberName={fullName}
                />
              </ErrorBoundary>
            </Cell>
          </Grid>
        )}
      </div>
    )
  }

  return (
    <TimezoneContextProvider patientId={patientCoreId}>
      <div className={styles.innerLayout} data-testid="member-view">
        <Snackbar
          className={styles.Snackbar}
          message={message}
          open={!!open}
          autoHideDuration={autoHideDuration}
          intent={intent}
          onClose={() => setSnackbarMessage('')}
        />
        <ErrorBoundary>
          <SupportInteractionModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            patientUuid={member.uuid}
            transactionId={supportInteraction?.transactionId}
            transactionType={supportInteraction?.transactionType}
          />
          <ProfileHeader
            member={member}
            memberAddress={memberAddress}
            legalDecisionsState={makeLegalDecisionState()}
            setSnackbarMessage={setSnackbarMessage}
            onSwitchToOldView={onSwitchToOldView}
            isPendingRegistration={isPendingRegistration}
          />
        </ErrorBoundary>
        <Grid className={styles.innerLayout} gap={20} columns={12}>
          <Cell width={3}>
            <ErrorBoundary>
              <ConsumerNetworksCard patientId={patientCoreId} />
            </ErrorBoundary>
            <ErrorBoundary>
              <AssessmentCard patientId={patientCoreId} />
            </ErrorBoundary>
          </Cell>
          <Cell width={6}>
            <ErrorBoundary>
              {conversationHistoryTabConversationTypes &&
              conversationHistoryTabConversationTypes.length ? (
                <div>
                  <div className={styles.TimelineHeader}>
                    <LinkButton
                      onClick={() => setActiveTab('activity')}
                      className={
                        activeTab === 'activity'
                          ? ` ${styles.timelineTab}_active`
                          : styles.timelineTab
                      }
                    >
                      <FormattedMessage {...messages.patient_activity_title} />
                    </LinkButton>
                    {isConversationHistoryInMemberViewEnabled && (
                      <LinkButton
                        onClick={() => {
                          setActiveTab('conversation')
                          trackClick({
                            elementName: 'conversation-history-button',
                            elementType: TrackingElementType.button,
                          })
                        }}
                        className={
                          activeTab === 'conversation'
                            ? ` ${styles.timelineTab}_active`
                            : styles.timelineTab
                        }
                      >
                        <FormattedMessage
                          {...messages.conversation_history_title}
                        />
                      </LinkButton>
                    )}
                    {isLiveConversationHistoryInMemberViewEnabled && (
                      <LinkButton
                        onClick={() => {
                          setActiveTab('live-conversation')
                          trackClick({
                            elementName: 'live-conversation-button',
                            elementType: TrackingElementType.button,
                          })
                        }}
                        data-testid="live-conversation-history-title"
                        className={
                          activeTab === 'live-conversation'
                            ? ` ${styles.timelineTab}_active`
                            : styles.timelineTab
                        }
                      >
                        <FormattedMessage
                          {...messages.live_conversation_history_title}
                        />
                      </LinkButton>
                    )}

                    {documentRepositoryEnabled && (
                      <LinkButton
                        onClick={() => {
                          setActiveTab('documentRepository')
                          trackClick({
                            elementName: 'document-repository-button',
                            elementType: TrackingElementType.button,
                          })
                        }}
                        className={
                          activeTab === 'documentRepository'
                            ? ` ${styles.timelineTab}_active`
                            : styles.timelineTab
                        }
                      >
                        <FormattedMessage
                          {...messages.document_repository_title}
                        />
                      </LinkButton>
                    )}
                    {memberTimezoneToggleEnabled && (
                      <div className={styles.membersTimeToggle}>
                        <MembersTimeToggle />
                      </div>
                    )}
                  </div>
                  {activeTab === 'activity' && (
                    <div data-testid="member-view-patient-activity">
                      <TimelineManagement
                        patientId={patientCoreId}
                        patientUuid={member.uuid}
                      />
                    </div>
                  )}
                  {activeTab === 'conversation' && (
                    <div data-testid="member-view-conversation_history">
                      <ConversationTimeline
                        patientId={patientCoreId}
                        conversationTypes={
                          conversationHistoryTabConversationTypes
                        }
                        visible
                      />
                    </div>
                  )}
                  {activeTab === 'live-conversation' && (
                    <div data-testid="member-view-live-conversation_history">
                      <LiveConversationsProvider>
                        <ConversationList
                          memberId={member.uuid}
                          data-testid="live-conversation-history-content"
                        />
                      </LiveConversationsProvider>
                    </div>
                  )}
                  {activeTab === 'documentRepository' && (
                    <div data-testid="member-view-patient-documents">
                      <DocumentRepository
                        memberId={member.uuid}
                        memberName={`${member.first_name} ${member.last_name}`}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className={styles.noConversationHistoryHeader}>
                    <Header title={fm(messages.patient_activity_title)} />
                    {/* TODO - Determine if we need to fix the type signature on <TimelineManagement />
                    or if we need to pass in the the patientUUID here
                    */}
                    {memberTimezoneToggleEnabled && (
                      <div className={styles.membersTimeToggle}>
                        <MembersTimeToggle />
                      </div>
                    )}
                  </div>
                  <TimelineManagement
                    patientId={patientCoreId}
                    patientUuid={member.uuid}
                  />
                </div>
              )}
            </ErrorBoundary>
          </Cell>
          <Cell width={3}>
            {enableAccountServices && (
              <AccountServices
                patientId={patientCoreId}
                patientUuid={member.uuid}
              />
            )}
            {memberAlertsEnabled && (
              <ErrorBoundary>
                <AlertList
                  patientId={patientCoreId}
                  {...{ setSnackbarMessage }}
                />
              </ErrorBoundary>
            )}
            {commentsCardEnabled && (
              <ErrorBoundary>
                <CommentsCard
                  patientId={patientCoreId}
                  {...{ setSnackbarMessage }}
                />
              </ErrorBoundary>
            )}
            {memberRelationsEnabled && (
              <ErrorBoundary>
                <RelatedPersons patientId={patientCoreId} />
              </ErrorBoundary>
            )}
            {preregistrationManagementEnabled && (
              <ErrorBoundary>
                <MemberRelationshipsCard
                  isMinor={member.minor}
                  isStubbedProfile={isStubbedProfile}
                  memberId={member.uuid}
                  isPendingRegistration={isPendingRegistration}
                  currentMemberName={fullName}
                />
              </ErrorBoundary>
            )}
          </Cell>
        </Grid>
      </div>
    </TimezoneContextProvider>
  )
}

export interface OldMemberViewProps {
  onSwitchToNewView: () => void
  onCloseBanner: () => void
  hideBanner: boolean
}

export const OldMemberView: FC<OldMemberViewProps> = ({
  onSwitchToNewView,
  onCloseBanner,
  hideBanner,
}) => {
  const fm = useFormatMessage()
  const { getProp } = useProductConfig()
  const newMemberViewEnabled = getProp(
    'memberOperations',
    'newMemberViewEnabled'
  )

  const BannerOrButton = () =>
    hideBanner ? (
      <Button
        intent="secondary"
        className={styles.EnableNewViewButton}
        onClick={onSwitchToNewView}
      >
        {fm(messages.switch_new_view)}
      </Button>
    ) : (
      <Banner onClick={onSwitchToNewView} onClose={onCloseBanner} />
    )

  return (
    <div className={styles.oldViewWrapper}>
      {(envFlag('DISPLAY_MOP_MEMBER_VIEW_BANNER') || newMemberViewEnabled) && (
        <BannerOrButton />
      )}
      <Route component={LegacyPortalScreen} />
    </div>
  )
}

export default MemberView
