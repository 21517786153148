import React from 'react'
import { Field, FieldProps } from 'formik'
import get from 'lodash/fp/get'

import FormField from '../FormField'
import { CheckboxGroup } from '../../foundation'

export interface FormikCheckboxGroupProps {
  children: React.ReactNode | HTMLElement
  dataTestId?: string
  id: string
  label?: string
  name: string
  onChange?: (nextGroupValues: Array<string>) => void
  validate?: (value: any) => undefined | string | Promise<any>
}

const FormikCheckboxGroup = ({
  children,
  dataTestId,
  id,
  label,
  name,
  onChange,
  validate,
}: FormikCheckboxGroupProps) => (
  <Field name={name} validate={validate}>
    {({
      form: { errors, touched, setFieldValue },
      field: { value },
    }: FieldProps) => {
      const fieldName = get(name)
      const displayError = fieldName(touched) && !!fieldName(errors)
      const errorMessage = fieldName(errors)

      return (
        <FormField
          displayError={displayError}
          errorMessage={errorMessage}
          id={id}
          label={label}
        >
          <CheckboxGroup
            name={name}
            onChange={(newGroupValue) => {
              setFieldValue(name, newGroupValue, true)

              if (onChange) {
                onChange(newGroupValue)
              }
            }}
            testId={dataTestId}
            value={value}
          >
            {children}
          </CheckboxGroup>
        </FormField>
      )
    }}
  </Field>
)

export default FormikCheckboxGroup
