import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import { KebabMenu, KebabMenuRenderOptions } from '@babylon/core-ui'
import { envUrl } from '@babylon/babylon-env'
import { useProductConfig } from '@babylon/product-config'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../..'
import { useSnackBar } from '../../hooks/SnackBar'
import PopupDialog from '../PopupDialog'
import { isWarningStatus } from './utils'
import messages from './TimelineCard.messages'
import ConsultationNote from '../Timeline/ConsultationNote'
import Referrals from '../Timeline/Referrals'
import { SEND_REFERRAL_EMAIL } from './queries'
import { Referral } from '../types'

export interface TimelineCardMenuProps {
  id: string
  cancellable: boolean
  isInvitation: boolean
  patientId: string
  status: string
  onCancelAppointmentClick: () => void
  referrals?: Referral[]
  viewConsultationNotes?: boolean
}

const TimelineCardMenu = ({
  id,
  cancellable,
  isInvitation,
  patientId,
  status,
  onCancelAppointmentClick,
  referrals,
  viewConsultationNotes,
}: TimelineCardMenuProps) => {
  const { getProp } = useProductConfig()
  const sendReferralEmailEnabled = getProp(
    'memberOperations',
    'sendReferralEmailEnabled'
  )

  const showPdfLink = status === 'completed' && viewConsultationNotes
  const showConsultationNotesLink =
    status && !isWarningStatus(status) && viewConsultationNotes
  const { setSnackbarMessage } = useSnackBar()

  const renderMenu =
    showConsultationNotesLink || showPdfLink || cancellable || isInvitation

  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })

  const [sendReferralEmail] = useMutation(SEND_REFERRAL_EMAIL, {
    onCompleted: () => {
      setSnackbarMessage(fm(messages.send_referral_success))
    },
    onError: () => {
      setSnackbarMessage(fm(messages.send_referral_error))
    },
  })

  const [consultationVisible, setConsultationVisible] = useState(false)
  const [referralsVisible, setReferralsVisible] = useState(false)

  const handleSendReferralEmail = (referralId: string) =>
    sendReferralEmail({
      variables: {
        patientId,
        referralId,
      },
    })

  if (!renderMenu) {
    return null
  }

  return (
    <KebabMenu>
      {({ menuItemProps }: KebabMenuRenderOptions) => (
        <>
          {isInvitation && (
            <Link
              {...menuItemProps}
              to={`/admin/patients/${patientId}/schedule-appointment?invite=${id}`}
              onClick={(e) => {
                trackClick({
                  elementName: 'book-appointment-btn',
                  elementType: TrackingElementType.link,
                  href: e.currentTarget.href,
                })
              }}
            >
              <FormattedMessage {...messages.book_appointment} />
            </Link>
          )}
          {cancellable && (
            <button
              {...menuItemProps}
              data-testid="timeline-card-menu-cancel-appointment"
              type="button"
              onClick={() => {
                trackClick({
                  elementName: 'cancel-appointment-btn',
                  elementType: TrackingElementType.button,
                })
                onCancelAppointmentClick()
              }}
            >
              <FormattedMessage {...messages.cancel_appointment} />
            </button>
          )}
          {showConsultationNotesLink && (
            <button
              {...menuItemProps}
              data-testid="timeline-card-menu-view-consultation-notes"
              type="button"
              onClick={() => {
                trackClick({
                  elementName: 'show-consultation-notes-btn',
                  elementType: TrackingElementType.button,
                })
                setConsultationVisible(true)
              }}
            >
              <FormattedMessage {...messages.view_consultation_notes} />
            </button>
          )}
          {showPdfLink && (
            <a
              {...menuItemProps}
              data-testid="timeline-card-menu-view-pdf"
              target="_new"
              href={`${envUrl(
                'ADMIN_PORTAL_FRAME_LEGACY_PORTAL_URL'
              )}admin/appointments/${id}/pdf`}
              onClick={(e) => {
                trackClick({
                  elementName: 'show-pdf-btn',
                  elementType: TrackingElementType.link,
                  href: e.currentTarget.href,
                })
              }}
            >
              <FormattedMessage {...messages.view_consultation_summary_link} />
            </a>
          )}
          {referrals && referrals.length === 1 && (
            <>
              <a
                {...menuItemProps}
                target="blank"
                href={referrals[0].referralPdf}
                onClick={(e) => {
                  trackClick({
                    elementName: 'show-referral-pdf-btn',
                    elementType: TrackingElementType.link,
                    href: e.currentTarget.href,
                  })
                }}
              >
                <FormattedMessage {...messages.view_referral} />
              </a>
              {sendReferralEmailEnabled && (
                <button
                  {...menuItemProps}
                  type="button"
                  onClick={() => {
                    trackClick({
                      elementName: 'send-referral-email-btn',
                      elementType: TrackingElementType.button,
                    })
                    handleSendReferralEmail(referrals[0].id)
                  }}
                >
                  <FormattedMessage {...messages.send_referral_email} />
                </button>
              )}
            </>
          )}
          {referrals && referrals.length > 1 && (
            <>
              <button
                {...menuItemProps}
                type="button"
                onClick={() => {
                  trackClick({
                    elementName: 'view-referrals-btn',
                    elementType: TrackingElementType.button,
                  })
                  setReferralsVisible(true)
                }}
              >
                <FormattedMessage {...messages.view_referrals} />
              </button>
              <PopupDialog
                toggleVisible={() => setReferralsVisible((visible) => !visible)}
                visible={referralsVisible}
              >
                <Referrals
                  sendEnabled={sendReferralEmailEnabled}
                  referrals={referrals}
                  onSend={handleSendReferralEmail}
                  onClose={() => setReferralsVisible(() => false)}
                />
              </PopupDialog>
            </>
          )}
          <ConsultationNote
            id={id}
            toggleVisible={() => setConsultationVisible((visible) => !visible)}
            visible={consultationVisible}
          />
        </>
      )}
    </KebabMenu>
  )
}

export default TimelineCardMenu
