/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type AppointmentWaitTimeConfigsQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int']
  limit: Types.Scalars['Int']
  consumerNetworkIds?: Types.Maybe<Array<Types.Maybe<Types.Scalars['ID']>>>
  serviceTypeUuids?: Types.Maybe<Array<Types.Maybe<Types.Scalars['ID']>>>
}>

export type AppointmentWaitTimeConfigsQuery = {
  appointmentWaitTimeConfigs: Pick<
    Types.AppointmentWaitTimeConfigs,
    'total' | 'offset' | 'limit'
  > & {
    waitTimeConfigs: Array<{
      consumerNetwork: Pick<Types.ConsumerNetwork, 'id' | 'name'>
      serviceType?: Types.Maybe<Pick<Types.ServiceType, 'id' | 'name'>>
      waitTimeStatusThresholds: Array<
        Pick<Types.AppointmentWaitTimeThreshold, 'value' | 'type'>
      >
    }>
  }
}

export const AppointmentWaitTimeConfigsDocument = gql`
  query AppointmentWaitTimeConfigs(
    $offset: Int!
    $limit: Int!
    $consumerNetworkIds: [ID]
    $serviceTypeUuids: [ID]
  ) {
    appointmentWaitTimeConfigs(
      offset: $offset
      limit: $limit
      consumerNetworkIds: $consumerNetworkIds
      serviceTypeUuids: $serviceTypeUuids
    ) {
      waitTimeConfigs {
        consumerNetwork {
          id
          name
        }
        serviceType {
          id
          name
        }
        waitTimeStatusThresholds {
          value
          type
        }
      }
      total
      offset
      limit
    }
  }
`
export function useAppointmentWaitTimeConfigsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentWaitTimeConfigsQuery,
    AppointmentWaitTimeConfigsQueryVariables
  >
) {
  return Apollo.useQuery<
    AppointmentWaitTimeConfigsQuery,
    AppointmentWaitTimeConfigsQueryVariables
  >(AppointmentWaitTimeConfigsDocument, baseOptions)
}
export function useAppointmentWaitTimeConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentWaitTimeConfigsQuery,
    AppointmentWaitTimeConfigsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AppointmentWaitTimeConfigsQuery,
    AppointmentWaitTimeConfigsQueryVariables
  >(AppointmentWaitTimeConfigsDocument, baseOptions)
}
export type AppointmentWaitTimeConfigsQueryHookResult = ReturnType<
  typeof useAppointmentWaitTimeConfigsQuery
>
export type AppointmentWaitTimeConfigsLazyQueryHookResult = ReturnType<
  typeof useAppointmentWaitTimeConfigsLazyQuery
>
export type AppointmentWaitTimeConfigsQueryResult = Apollo.QueryResult<
  AppointmentWaitTimeConfigsQuery,
  AppointmentWaitTimeConfigsQueryVariables
>
