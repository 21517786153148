import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

const Icon = ({ name, className, ...rest }) => (
  <i className={cx(styles.icon, styles[name], className)} {...rest} />
)

Icon.defaultProps = {
  className: null,
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Icon
