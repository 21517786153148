import { gql } from '@apollo/client'

export default gql`
  mutation RemoveConsumerNetworkFromServiceType(
    $service_type_uuid: ID!
    $consumer_network_id: ID!
  ) {
    removeConsumerNetworkFromServiceType(
      service_type_uuid: $service_type_uuid
      consumer_network_id: $consumer_network_id
    )
  }
`
