import { defineMessages } from 'react-intl'

const baseId = 'assessment_view'

export default defineMessages({
  continue: {
    id: `${baseId}.continue_flow`,
    defaultMessage: 'Continue',
  },
  restart: {
    id: `${baseId}.restart_flow`,
    defaultMessage: 'Restart',
  },
})
