import React from 'react'
import { LoadingBlock } from '../../../../../../LoadingContent'
import styles from './LoadingRelatedPersonCard.module.scss'

const LoadingRelatedPersonCard = () => (
  <div
    className={styles.LoadingRelatedPersonsCard}
    data-testid="loading-related-persons-card"
  >
    <LoadingBlock className={styles.LoadingDependenciesCard__block} />
  </div>
)

export default LoadingRelatedPersonCard
