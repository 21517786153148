import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, QueryResult } from '@apollo/client'

import { Button, Card, Spinner, Page } from '@babylon/core-ui'

import { AppointmentFields } from '../AppointmentFields'
import PatientDetails from '../PatientDetails'
import SetupAppointment from '../SetupAppointment'
import { UnexpectedError } from '../Utils'

import styles from './index.module.css'
import CreateAppointmentQuery from './CreateAppointmentQuery'

interface Props {
  filters: AppointmentFields | null
  memberId: string
  includingDigital?: boolean
  onFiltersChange: (newFilters: AppointmentFields) => void
  onSkip: () => void
}

const CreateAppointment = ({
  filters,
  memberId,
  includingDigital,
  onFiltersChange,
  onSkip,
}: Props) => {
  const { data, loading, error }: QueryResult = useQuery(
    CreateAppointmentQuery,
    {
      variables: { memberId, includingDigital },
    }
  )

  if (loading) {
    return (
      <div className={styles.content}>
        <Spinner size="large" centered />
      </div>
    )
  }

  if (error) {
    return <UnexpectedError message={error.message} visible />
  }

  const { patient, consultantTypes, consultationTypes } = data

  if (!patient) {
    return <UnexpectedError message="Patient not found" visible />
  }

  return (
    <Page
      title="Book an Appointment"
      actions={<PatientDetails patient={patient} />}
      breadcrumbs={[
        <Link key="1" to="/admin/patients/search">
          Patients
        </Link>,
        <Link key="2" to={`/admin/patients/${memberId}/memberships`}>
          Member details
        </Link>,
        'Book Appointment',
      ]}
    >
      <Card
        actions={
          <Button
            testId="skip-create-appointment"
            intent="link"
            onClick={onSkip}
          >
            Skip this page
          </Button>
        }
      >
        <SetupAppointment
          filters={filters}
          consultantTypes={consultantTypes || []}
          consultationTypes={consultationTypes || []}
          onSubmit={onFiltersChange}
          serviceTypes={patient.service_types}
          memberId={memberId}
          memberUuid={patient.uuid}
        />
      </Card>
    </Page>
  )
}

export default CreateAppointment
