import React, { FC } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Modal, Heading, FormikTextarea } from '@babylon/core-ui'
import { Formik, Form } from 'formik'
import { useFormatMessage } from '@babylon/intl'
import {
  useTracking,
  TrackingActionType,
  TrackingElementType,
} from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../../..'
import messages from './CommentsCard.messages'
import styles from './CommentsCard.module.scss'
import { addCommentGQL } from './queries'

export interface AddCommentProps {
  patientId: string
  onCancel: () => void
  onSubmit: () => void
  visible?: boolean
}

const AddComment: FC<AddCommentProps> = ({
  patientId,
  onCancel,
  onSubmit,
  visible = false,
}) => {
  const fm = useFormatMessage()
  const { trackEvent } = useTracking()
  const [addComment] = useMutation(addCommentGQL, {
    onCompleted: onSubmit,
    onError: () => {
      trackEvent({
        patientId: patientId,
        elementName: 'add-comment-error',
        moduleName: MemberOpsModuleName.commentsList,
        elementType: TrackingElementType.form,
        actionType: TrackingActionType.formError,
      })
    },
  })

  if (!visible) {
    return null
  }

  // TODO - Validate needs to return an error, need to determine what the error should be
  const validate = (values: { commentTextArea: string }) => {
    if (values.commentTextArea.length === 0) {
      trackEvent({
        patientId: patientId,
        elementName: 'validate-comment-error',
        moduleName: MemberOpsModuleName.commentsList,
        elementType: TrackingElementType.form,
        actionType: TrackingActionType.formError,
      })
      return {
        commentTextArea: fm(messages.validation),
      }
    }
    return null
  }

  return (
    <Modal
      label={fm(messages.header)}
      open={visible}
      onClose={onCancel}
      includeClose={false}
    >
      <div data-testid="member-comments-add-comment-modal">
        <header className={styles.Comment__header}>
          <Heading level="h2">{fm(messages.header)}</Heading>
        </header>
        <Formik
          initialValues={{ commentTextArea: '' }}
          onSubmit={(values) => {
            addComment({
              variables: {
                patient_id: patientId,
                note: values.commentTextArea,
              },
            })
          }}
          validate={validate}
        >
          <Form>
            <div className={styles.Comment__AddNew__description}>
              <FormikTextarea
                id="comment_textarea"
                name="commentTextArea"
                data-testid="comment-text-area"
                placeholder={fm(messages.placeholder)}
              />
            </div>
            <div className={styles.Comment__AddNew__actions}>
              <Button
                inline
                intent="secondary"
                className={styles.Comment__AddNew__cancel_button}
                onClick={onCancel}
                testId="cancel-modal"
              >
                {fm(messages.cancel)}
              </Button>
              <Button type="submit" inline intent="primary" testId="submit">
                {fm(messages.submit)}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  )
}

export default AddComment
