/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type UpdateAppointmentWaitTimeConfigsMutationVariables = Types.Exact<{
  input: Types.AppointmentWaitTimeConfigsInput
}>

export type UpdateAppointmentWaitTimeConfigsMutation = Pick<
  Types.Mutation,
  'updateAppointmentWaitTimeConfigs'
>

export const UpdateAppointmentWaitTimeConfigsDocument = gql`
  mutation updateAppointmentWaitTimeConfigs(
    $input: AppointmentWaitTimeConfigsInput!
  ) {
    updateAppointmentWaitTimeConfigs(input: $input)
  }
`
export type UpdateAppointmentWaitTimeConfigsMutationFn = Apollo.MutationFunction<
  UpdateAppointmentWaitTimeConfigsMutation,
  UpdateAppointmentWaitTimeConfigsMutationVariables
>
export function useUpdateAppointmentWaitTimeConfigsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentWaitTimeConfigsMutation,
    UpdateAppointmentWaitTimeConfigsMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateAppointmentWaitTimeConfigsMutation,
    UpdateAppointmentWaitTimeConfigsMutationVariables
  >(UpdateAppointmentWaitTimeConfigsDocument, baseOptions)
}
export type UpdateAppointmentWaitTimeConfigsMutationHookResult = ReturnType<
  typeof useUpdateAppointmentWaitTimeConfigsMutation
>
export type UpdateAppointmentWaitTimeConfigsMutationResult = Apollo.MutationResult<UpdateAppointmentWaitTimeConfigsMutation>
export type UpdateAppointmentWaitTimeConfigsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentWaitTimeConfigsMutation,
  UpdateAppointmentWaitTimeConfigsMutationVariables
>
