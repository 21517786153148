/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from 'react'
import classnames from 'classnames'

import './Breadcrumbs.scss'

interface Props {
  items?: ReactNode[]
  noSeparator?: boolean
}

const Breadcrumbs = ({ items = [], noSeparator }: Props) => (
  <ul
    className={classnames('core-breadcrumbs', {
      'core-breadcrumbs--separator': !noSeparator,
    })}
  >
    {items.map((item, index) => (
      <li key={index} className="core-breadcrumbs__item">
        {item}
      </li>
    ))}
  </ul>
)

export default Breadcrumbs
