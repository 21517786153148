import { useProductConfig } from '@babylon/product-config'

const useAssessmentFilterFeatureFlags = () => {
  const { getProp } = useProductConfig()

  const assessmentYesFilterEnabled = getProp(
    'memberOperations',
    'b360V2Features.assessmentYesFilterEnabled'
  )

  const assessmentNotApplicableFilterEnabled = getProp(
    'memberOperations',
    'b360V2Features.assessmentNotApplicableFilterEnabled'
  )

  return { assessmentYesFilterEnabled, assessmentNotApplicableFilterEnabled }
}

export default useAssessmentFilterFeatureFlags
