import React, { useState, useCallback } from 'react'

import { useQueryParams } from '../../Utils'

import ChooseClinician, { ChooseClinicianProps } from '../ChooseClinician'
import ChooseClinicianModal from '../ChooseClinicianModal'
import ClinicianTimeline from '../ClinicianTimeline'

import { AvailabilitySlotType } from '../ClinicianTimeline/GetClinicianAvailability'
import getAllSlotsInShift from './getAllSlotsInShift'

export interface ClinicianShiftProps
  extends Pick<ChooseClinicianProps, 'clinicianType'> {
  onLoadingShift?: (appointments: AvailabilitySlotType[]) => void
  onChangeClinician?: () => void
}

export default function ClinicianShift({
  clinicianType,
  onChangeClinician,
  onLoadingShift,
}: ClinicianShiftProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { getQueryParams, setQueryParams } = useQueryParams()
  const clinicianIdPageParam = `${clinicianType}_clinician_id`

  const {
    [clinicianIdPageParam]: clinicianId,
    date,
    shift_start: shiftStart,
    shift_end: shiftEnd,
  } = getQueryParams()

  const onChooseClinician = (id) => {
    setQueryParams({ [clinicianIdPageParam]: id })

    if (onChangeClinician) {
      onChangeClinician()
    }
  }

  const onLoadTimeline = useCallback(
    (appointments) => {
      if (!onLoadingShift) {
        return appointments
      }

      const appointmentsInShift = getAllSlotsInShift(
        appointments,
        new Date(`${date} ${shiftStart}`),
        new Date(`${date} ${shiftEnd}`)
      )

      return onLoadingShift(appointmentsInShift)
    },
    [onLoadingShift, date, shiftStart, shiftEnd]
  )

  if (!clinicianId) {
    return (
      <>
        <ChooseClinician
          clinicianType={clinicianType}
          onClinicianButtonClick={() => {
            setIsModalOpen(true)
          }}
        />
        {isModalOpen && (
          <ChooseClinicianModal
            label="Choose clinician"
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onChooseClinician={onChooseClinician}
          />
        )}
      </>
    )
  }

  return (
    <>
      <ClinicianTimeline
        date={date as string}
        data-testid={`${clinicianType}-clinician-timeline`}
        shiftStart={shiftStart as string}
        shiftEnd={shiftEnd as string}
        clinicianId={clinicianId as number}
        onChangeClinician={() => setIsModalOpen(true)}
        onLoadTimeline={onLoadTimeline}
      />
      {isModalOpen && (
        <ChooseClinicianModal
          label="Change clinician"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onChooseClinician={onChooseClinician}
        />
      )}
    </>
  )
}
