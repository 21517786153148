import { gql } from '@apollo/client'

export const MESSAGES_QUERY = gql`
  query AP_Messages($userId: ID!, $conversationId: ID!) {
    chatscriptMessages(
      userId: $userId
      conversationId: $conversationId
      offset: 0
      limit: 1000
      order: "asc"
    ) {
      messages {
        id
        style
        author {
          id
          name
        }
        input {
          params {
            choices {
              label
            }
          }
        }
        sentAt
        sender
        type
        value {
          text
        }
      }
    }
  }
`

export const CONVERSATION_QUERY = gql`
  query AP_Conversation($userId: ID!, $conversationId: ID!) {
    chatscriptConversation(userId: $userId, conversationId: $conversationId) {
      id
      preview
      result {
        outcome
        type
      }
      startedAt
      status
      type
    }
  }
`
