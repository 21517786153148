import { gql } from '@apollo/client'

export const PrescriptionRegions = gql`
  query PrescriptionRegions {
    prescriptionRegions {
      regionCode
      name
    }
  }
`

const pharmacyPrescriptionFieldsFragment = gql`
  fragment PharmacyPrescriptionFieldsFragment on Prescription {
    id
    consumerNetwork
    patient {
      full_name
    }
    drugs {
      drugName
      quantity
      directions
      packagingSize
    }
    updatedAt
    pharmacyDetails
    deliveryAddress {
      fullAddress
    }
    patient {
      email
      phone_number
    }
    actions
  }
`

export const PharmacyPrescriptions = gql`
  query PharmacyPrescriptions(
    $size: Int!
    $page: Int!
    $sort: String
    $states: String
  ) {
    pharmacyPrescriptions(
      size: $size
      page: $page
      sort: $sort
      states: $states
    ) {
      pageIndex
      totalItems
      itemsPerPage
      items {
        ...PharmacyPrescriptionFieldsFragment
      }
    }
  }
  ${pharmacyPrescriptionFieldsFragment}
`

export const PrescriptionRejectPharmacySupplier = gql`
  mutation PrescriptionRejectPharmacySupplier($id: ID!, $reason: String!) {
    prescriptionRejectPharmacySupplier(id: $id, reason: $reason) {
      id
    }
  }
`

export const PrescriptionMarkDrugsDispatched = gql`
  mutation PrescriptionMarkDrugsDispatched($id: ID!) {
    prescriptionMarkDrugsDispatched(id: $id) {
      id
    }
  }
`
