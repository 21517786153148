/* eslint-disable react/no-children-prop, jsx-a11y/no-static-element-interactions */

import React from 'react'
import { Route } from 'react-router-dom'
import { AnimatedPopup, ModalOverlay } from '@babylon/member-operations'

const PopupRoute = ({ width, component, className, path }) => (
  <Route
    path={path}
    children={({ match, ...rest }) => {
      let prevMatch

      if (match) {
        prevMatch = match
      }

      return (
        <ModalOverlay onRequestClose={rest.history.goBack} visible={!!match}>
          <AnimatedPopup
            {...rest}
            render={component}
            visible={!!match}
            className={className}
            width={width || component.width}
            match={prevMatch}
            onClose={rest.history.goBack}
          />
        </ModalOverlay>
      )
    }}
  />
)

export default PopupRoute
