import React from 'react'
import { useField } from 'formik'
import { FormField, TimeRangePicker } from '@babylon/core-ui'
import type {
  FormikTimeRangePickerProps,
  FormikTimeRangeOnChangeValue,
} from './FormikTimeRangePicker.types'

export const FormikTimeRangePicker = ({
  id,
  name,
  startTimeName,
  endTimeName,
  label,
}: FormikTimeRangePickerProps) => {
  const [startTimeField, startTimeMeta, startTimeHelpers] = useField<
    Date | undefined
  >({
    name: startTimeName,
  })
  const [endTimeField, endTimeMeta, endTimeHelpers] = useField<
    Date | undefined
  >({
    name: endTimeName,
  })

  const shouldDisplayError = Boolean(startTimeField.value && endTimeField.value)

  const handleChange = (values: FormikTimeRangeOnChangeValue) => {
    if (values.startDate !== startTimeField.value) {
      startTimeHelpers.setValue(values.startDate)
    }

    if (values.endDate !== endTimeField.value) {
      endTimeHelpers.setValue(values.endDate)
    }
  }

  return (
    <FormField
      id={id}
      label={label}
      displayError={shouldDisplayError}
      errorMessage={startTimeMeta.error || endTimeMeta.error}
    >
      <TimeRangePicker
        id={id}
        name={name}
        onChange={handleChange}
        startDate={startTimeField.value}
        endDate={endTimeField.value}
        data-testid={name}
      />
    </FormField>
  )
}

export default FormikTimeRangePicker
