/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type PatientQueryVariables = Types.Exact<{
  patientId: Types.Scalars['ID']
  withNoticeDecisions: Types.Scalars['Boolean']
}>

export type PatientQuery = {
  patient?: Types.Maybe<{
    patient_alerts?: Types.Maybe<Array<Pick<Types.PatientAlert, 'id'>>>
    flags?: Types.Maybe<Array<Types.Maybe<Pick<Types.Flag, 'id'>>>>
  }>
  patientConsumerNetworks: Array<
    Types.Maybe<{
      consumer_network?: Types.Maybe<
        Pick<Types.ConsumerNetwork, 'name' | 'uuid'>
      >
    }>
  >
  informedConsent?: Types.Maybe<
    Pick<Types.NoticeDecision, 'decisionId' | 'accepted' | 'expired' | 'date'>
  >
  medicationHistory?: Types.Maybe<
    Pick<Types.NoticeDecision, 'decisionId' | 'accepted' | 'expired' | 'date'>
  >
}

export const PatientDocument = gql`
  query Patient($patientId: ID!, $withNoticeDecisions: Boolean!) {
    patient(id: $patientId) {
      patient_alerts {
        id
      }
      flags {
        id
      }
    }
    patientConsumerNetworks(id: $patientId) {
      consumer_network {
        name
        uuid
      }
    }
    informedConsent: memberDecisionForNotice(
      input: {
        patientUUID: $patientId
        appName: BABYLON_US
        noticeEnum: INFORMED_CONSENT
      }
    ) @include(if: $withNoticeDecisions) {
      decisionId
      accepted
      expired
      date
    }
    medicationHistory: memberDecisionForNotice(
      input: {
        patientUUID: $patientId
        appName: BABYLON_US
        noticeEnum: MEDICATION_HISTORY_AUTHORIZATION
      }
    ) @include(if: $withNoticeDecisions) {
      decisionId
      accepted
      expired
      date
    }
  }
`
export function usePatientQuery(
  baseOptions: Apollo.QueryHookOptions<PatientQuery, PatientQueryVariables>
) {
  return Apollo.useQuery<PatientQuery, PatientQueryVariables>(
    PatientDocument,
    baseOptions
  )
}
export function usePatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>
) {
  return Apollo.useLazyQuery<PatientQuery, PatientQueryVariables>(
    PatientDocument,
    baseOptions
  )
}
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>
export type PatientQueryResult = Apollo.QueryResult<
  PatientQuery,
  PatientQueryVariables
>
