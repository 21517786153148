import React from 'react'
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'
import type { AppointmentWaitTimeConfigsDeleteDialogProps } from './AppointmentWaitTimeConfigsDeleteDialog.types'
import messages from './AppointmentWaitTimeConfigsDeleteDialog.messages'
import { getErrorStatusCode } from '../Utils/GraphQLUtils'
import { useDeleteAppointmentWaitTimeConfigsMutation } from './DeleteAppointmentWaitTimeConfig.hooks'

const AppointmentWaitTimeConfigsDeleteDialog = ({
  isOpen,
  consumerNetwork,
  serviceType,
  handleDialogClickDelete,
  handleDialogClickClose,
}: AppointmentWaitTimeConfigsDeleteDialogProps) => {
  const [deleteWaitTimeConfigs] = useDeleteAppointmentWaitTimeConfigsMutation({
    variables: {
      input: {
        consumer_network_id: Number(consumerNetwork?.id),
        service_type_uuid: serviceType?.id,
      },
    },
    onCompleted: () => {
      handleDialogClickDelete('204')
    },
    onError: (error) => {
      handleDialogClickDelete(getErrorStatusCode(error))
    },
  })

  const handleOnDelete = async () => {
    await deleteWaitTimeConfigs()
  }

  const createDefaultContentText = (message: string) => (
    <DialogContentText>{message}</DialogContentText>
  )

  const createHighlightedContentText = (message: string) => (
    <DialogContentText variant="subtitle1" style={{ fontWeight: 600 }}>
      {message}
    </DialogContentText>
  )

  const createConsumerNetworkInfo = () =>
    consumerNetwork?.name !== undefined ? (
      <Box display="flex" gap="15px" paddingTop={2}>
        <Stack direction="row" spacing={1}>
          {createDefaultContentText(
            `${messages.consumerNetworkTitle.defaultMessage}: `
          )}
          {createHighlightedContentText(consumerNetwork?.name)}
        </Stack>
      </Box>
    ) : undefined

  const createServiceTypeInfo = () =>
    serviceType?.name !== undefined ? (
      <Box display="flex" gap="15px">
        <Stack direction="row" spacing={1}>
          {createDefaultContentText(
            `${messages.serviceTypeTitle.defaultMessage}: `
          )}
          {createHighlightedContentText(serviceType.name)}
        </Stack>
      </Box>
    ) : undefined

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleDialogClickClose()}
      maxWidth="md"
    >
      <DialogTitle>
        {messages.deleteWaitTimeThresholdTitle.defaultMessage}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText variant="subtitle1">
          {messages.deleteWaitTimeThresholdMessage.defaultMessage}
        </DialogContentText>
        {createConsumerNetworkInfo()}
        {createServiceTypeInfo()}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          style={{ textTransform: 'none' }}
          color="secondary"
          onClick={() => handleDialogClickClose()}
        >
          {messages.cancelButtonTitle.defaultMessage}
        </Button>
        <Button
          autoFocus
          variant="outlined"
          style={{ textTransform: 'none' }}
          color="secondary"
          onClick={handleOnDelete}
        >
          {messages.deleteButtonTitle.defaultMessage}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default AppointmentWaitTimeConfigsDeleteDialog
