import { defineMessages } from 'react-intl'

const baseId = 'assessment_flows_summary'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Assessment answers',
  },
})
