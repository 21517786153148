import * as Yup from 'yup'

const setupAppointmentValidation = Yup.object().shape({
  appointment_reason: Yup.string(),
  chaperone_required: Yup.boolean(),
  consultation_type: Yup.string().required(
    'Please choose the type of appointment'
  ),
  consultant_type: Yup.string().required(
    'Please choose the type of consultant'
  ),
  duration_minutes: Yup.number(),
  service_type_uuid: Yup.string().when(['consultation_type'], {
    is: 'physical',
    then: Yup.string().required(
      'Please select a service type when choosing Face to face'
    ),
    otherwise: Yup.string(),
  }),
})

export default setupAppointmentValidation
