import React from 'react'
import { useMutation, ApolloError } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { KebabMenuRenderOptions, Spinner } from '@babylon/core-ui'
import messages from './ActionButtons.messages'
import styles from './styles.module.scss'
import { RESEND_INVITATION, TRANSFER_OWNERSHIP } from './queries'
import { errorListFromQueryError } from '../../../../../util/helpers'

export enum Actions {
  TransferOwnership,
}

export interface TransferOwnershipData {
  new_owner_id: string
  old_owner_id: string
}

export interface TransferOwnershipResponse {
  transferOwnership: TransferOwnershipData
}

export interface ResendInvitationEmailData {
  id: string
}

export interface ResendInvitationEmailResponse {
  resendInvitation: ResendInvitationEmailData
}

export type ErrorHandler = (message: string) => void

export interface ServerError {
  response: {
    data: {
      errors: string[]
      status: number
      statusText: string
    }
  }
}

export interface TransferOwnershipButtonProps extends KebabMenuRenderOptions {
  onClick(action: Actions, mutation: any): () => void
  onComplete(data: TransferOwnershipData): void
  onError: ErrorHandler
}

export interface ResendInvitationEmailProps extends KebabMenuRenderOptions {
  onComplete(data: ResendInvitationEmailData): void
  onError: ErrorHandler
  patientId: string
}

const handleParsedError = (errorHandler: ErrorHandler) => (
  error: ApolloError
) => {
  try {
    const errors = errorListFromQueryError(error)
    const errorMessage = errors.reduce(
      (combinedMessage, err) => (combinedMessage += err?.message + '\n'),
      ''
    )
    errorHandler(errorMessage)
  } catch (e) {
    errorHandler(error.message)
  }
}

export const TransferOwnershipButton = ({
  menuItemProps,
  onClick,
  onComplete,
  onError,
}: TransferOwnershipButtonProps) => {
  const [transferOwnership] = useMutation(TRANSFER_OWNERSHIP, {
    onCompleted: (data: TransferOwnershipResponse) =>
      onComplete(data.transferOwnership),
    onError: handleParsedError(onError),
  })

  return (
    <button
      {...menuItemProps}
      type="button"
      data-testid="transfer-ownership"
      onClick={onClick(Actions.TransferOwnership, transferOwnership)}
    >
      <FormattedMessage {...messages.make_account_owner} />
    </button>
  )
}

export const ResendInvitationEmailButton = ({
  menuItemProps,
  onComplete,
  onError,
  patientId,
}: ResendInvitationEmailProps) => {
  const [resendInvitation, { loading }] = useMutation(RESEND_INVITATION, {
    onCompleted: (data: ResendInvitationEmailResponse) =>
      onComplete(data.resendInvitation),
    onError: handleParsedError(onError),
  })

  return (
    <button
      {...menuItemProps}
      type="button"
      disabled={loading}
      data-testid="resend-invitation-email"
      onClick={() => resendInvitation({ variables: { id: patientId } })}
    >
      <FormattedMessage {...messages.resend_invitation_email} />
      {loading && <Spinner className={styles.actionSpinner} size="small" />}
    </button>
  )
}
