import { gql } from '@apollo/client'

export const PATIENT_TIMEZONE_QUERY = gql`
  query PatientTimezone($patientId: ID!) {
    patient(id: $patientId) {
      id
      addressBasedTimezone
    }
  }
`
