import { defineMessages } from 'react-intl'

const baseId = 'member_pds_status'

export default defineMessages({
  heading: {
    id: `${baseId}.heading`,
    defaultMessage: 'PDS Status',
  },
  exact_match: {
    id: `${baseId}.exact_match`,
    defaultMessage: 'Exact match',
  },
  retry_button: {
    id: `${baseId}.retry_button`,
    defaultMessage: 'Retry',
  },
})
