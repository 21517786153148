import React from 'react'
import { Cell, Text } from '@babylon/core-ui'

interface RowProps {
  title: string
  value?: string | PracticeAddress | null
}

export default function DetailsRow({ title, value, ...rest }: RowProps) {
  return (
    <>
      <Cell width={1}>
        <Text color="grey-type" size="large" bold align="right" tag="div">
          {title}:
        </Text>
      </Cell>
      <Cell width={2}>
        <div {...rest}>
          <Text
            style={{ maxWidth: '230px' }}
            color="grey-type"
            size="large"
            bold
            tag="div"
          >
            {value}
          </Text>
        </div>
      </Cell>
    </>
  )
}
