import React, { ReactNode } from 'react'

import { Heading } from '@babylon/core-ui'

interface EntryFieldProps {
  label: string
  children: ReactNode
}

const EntryField = ({ label, children }: EntryFieldProps) => (
  <>
    <Heading level="h3" color="light-grey-type">
      {label}
    </Heading>
    {children}
  </>
)

export default EntryField
