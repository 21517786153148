import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { format } from 'date-fns'

import TimezoneDate from '@/components/TimezoneDate'

import ListHeaderView from './ListHeaderView'
import ActionsView from './ActionsView'

import styles from './styles.module.scss'

const getValue = (item, path) => {
  if (path.includes('.')) {
    const parts = path.split('.')
    let obj = item

    for (let i = 0; i < parts.length; i++) {
      obj = obj[parts[i]]
    }

    return obj
  }

  return item[path]
}

const DefaultItemView = ({ columns, toolbar, actions }) => ({ id, item }) => (
  <tr
    className={cx(item._isNew && 'new-item', item._isRemoved && 'removed-item')}
  >
    {columns.map((column) => {
      let value = getValue(item, column.name)

      if (typeof value === 'boolean') {
        value = value ? 'Yes' : 'No'
      }

      return (
        <td key={column.name}>
          {column.autoDateFormat ? (
            <TimezoneDate date={value} />
          ) : column.dateFormat ? (
            format(new Date(value), column.dateFormat)
          ) : (
            value
          )}
        </td>
      )
    })}
    {toolbar ? (
      <td className={styles.toolbar}>
        <ActionsView buttons={toolbar} actions={actions} id={id} />
      </td>
    ) : null}
  </tr>
)

const ListView = (props) => {
  const {
    className,
    columns,
    data,
    ItemView,
    idName,
    toolbar,
    hideHeaders,
    footer,
  } = props
  const ActualItemView = ItemView || DefaultItemView(props)
  const actualIdName = idName || 'id'

  return (
    <table className={cx(styles.listView, className)}>
      {hideHeaders ? null : (
        <thead>
          <tr>
            {columns.map((item) => (
              <ListHeaderView {...props} key={item.name} {...item} />
            ))}
            {toolbar ? <th /> : null}
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((item) => {
          const id = item[actualIdName]

          return <ActualItemView key={id} {...props} item={item} id={id} />
        })}
      </tbody>
      {footer ? <tfoot>{footer}</tfoot> : null}
    </table>
  )
}

ListView.defaultProps = {
  className: null,
  ItemView: null,
  data: [],
  actions: null,
  hideHeaders: false,
  Footer: null,
}

ListView.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  ItemView: PropTypes.func,
  idName: PropTypes.string,
  toolbar: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      icon: PropTypes.string,
    }).isRequired
  ),
  hideHeaders: PropTypes.bool,
  footer: PropTypes.node,
}

export default ListView
