import React from 'react'
import { IntlFormatters, FormattedDate } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { Unknown } from '../..'
import CopyToClipboardButton from '../../CopyToClipboardButton'
import commonStyles from '../styles.module.scss'
import messages from '../CopiableMemberInformation.messages'
import getMembersAge from '../utils/getMembersAge'

export interface GenderAgeProps {
  dateOfBirth: string | null
  gender: string | null
  onDateOfBirthCopy?: (message: string, value: any) => void
}

/**
 * Utility function localises a user's gender
 *
 * @private
 *
 * @param gender -  the member's gender
 * @param formatter - the localisation formatter
 */
const formatGender = (
  gender: string,
  formatter: IntlFormatters['formatMessage']
) => {
  switch (gender.toLocaleLowerCase()) {
    case 'female':
      return formatter(messages.gender_female)
    case 'male':
      return formatter(messages.gender_male)
    default:
      return null
  }
}

const GenderAge = ({
  dateOfBirth,
  gender,
  onDateOfBirthCopy,
}: GenderAgeProps) => {
  const fm = useFormatMessage()
  const dateOfBirthCopiedHandler = (value: any) => {
    onDateOfBirthCopy && onDateOfBirthCopy(fm(messages.date_of_birth), value)
  }

  const locale = (localStorage.getItem('locale') || 'en_GB').replace('_', '-') // todo: update with intl utilities

  return (
    <div data-testid="gender-age">
      <div data-testid="date-of-birth">
        {!!dateOfBirth && (
          <CopyToClipboardButton
            className={commonStyles.copiable__copyToClipboardButton}
            value={new Intl.DateTimeFormat(locale, {
              timeZone: 'UTC',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }).format(new Date(dateOfBirth))}
            onCopiedToClipboard={dateOfBirthCopiedHandler}
          >
            <span data-testid="copiable-dob">
              <FormattedDate
                timeZone="UTC"
                day="2-digit"
                month="short"
                year="numeric"
                value={new Date(dateOfBirth)}
              />
            </span>
          </CopyToClipboardButton>
        )}
      </div>
      <div>
        {!!gender && (
          <span data-testid="gender">{formatGender(gender, fm)}&nbsp;</span>
        )}
        <span data-testid="age">
          {getMembersAge(dateOfBirth, fm) || <Unknown />}
        </span>
      </div>
    </div>
  )
}

export default GenderAge
