import React from 'react'

import cx from 'classnames'
import ModalOverlay from '../ModalOverlay'
import AnimatedPopup from '../AnimatedPopup'
import styles from './styles.module.scss'

class PopupDialog extends React.Component {
  /**
   * This method allows to open a PopupDialog programmatically when an event occurs (click)
   * It takes the "id" of the dialog and the updated "props" for the nested component.
   *
   * Example:
   *
   * <pre>
   *  const clickHandler = () => { PopupDialog.open('my-component', {name: 'World'}) }
   *  const MyComponent = ({name}) => <div>Hello {name}</div>
   *  const App = <div>
   *    <PopupDialog id="my-component" component={MyComponent} />
   *    <button onClick={clickHandler}>Say Hello</button>
   *  </div>
   * </pre>
   *
   * @param id the id assigned to the PopupDialog
   * @param props the updated props to pass to the nested component
   */
  static open = (id, props) => {
    const dialog = PopupDialog._dialogs[id]

    if (dialog) {
      dialog.setState({ visible: true, props })
    }
  }
  constructor(props) {
    super(props)
    const { id, htmlId, scrollToTop, ...componentProps } = props
    this.state = { visible: false, props: componentProps }
    PopupDialog._dialogs[id] = this
  }

  dispose = () => this.setState({ visible: false })

  static _dialogs = {}

  render() {
    return (
      <ModalOverlay
        htmlId={this.props.htmlId}
        scrollToTop={this.props.scrollToTop}
        onRequestClose={this.dispose}
        visible={this.state.visible}
      >
        <AnimatedPopup
          render={this.props.component}
          className={cx(styles.popupDialog, this.props.className)}
          visible={this.state.visible}
          width={this.props.width}
          onClose={this.dispose}
          {...this.state.props}
        />
      </ModalOverlay>
    )
  }
}

export default PopupDialog
