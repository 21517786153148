import { gql } from '@apollo/client'

// eslint-disable-next-line import/prefer-default-export
export const SUBMIT_ASSESSMENT = gql`
  mutation AP_SubmitAssessment(
    $locale: String!
    $memberId: ID!
    $reason: String!
    $riskLevel: String!
    $timeZoneOffset: String!
  ) {
    healthCheckSubmitAssessment(
      locale: $locale
      memberId: $memberId
      reason: $reason
      riskLevel: $riskLevel
      timeZoneOffset: $timeZoneOffset
    )
  }
`

export const INVALIDATE_ANSWERS = gql`
  mutation AP_InvalidateAnswers(
    $locale: String!
    $memberId: ID!
    $timeZoneOffset: String!
  ) {
    healthCheckInvalidateAnswers(
      locale: $locale
      memberId: $memberId
      timeZoneOffset: $timeZoneOffset
    )
  }
`
