import { withApollo, graphql } from '@apollo/client/react/hoc'
import { compose, withStateHandlers, withHandlers, withProps } from 'recompose'

import { map, removeTypeNames, withSpinner, withForm } from '@/util'
import ListViewWidget from '@/components/old-forms/Form/ListViewWidget'
import SelectWidget from '@/components/old-forms/Form/SelectWidget'
import promptReason from '@/components/DrugBlacklistReasonDialog'
import withErrorMessage from '@/util/withErrorMessage'
import RegionsViewWidget from './RegionsViewWidget'

import {
  AdminDrug,
  DrugInfo,
  AdminActiveIngredient,
  AddAdminDrug,
  UpdateAdminDrug,
} from './queries'

import DrugEditView from './DrugEditView'
import formConfig from './formConfig.json'

let lastId = 0

const actionBroker = (handlers) => (props, action, ...args) => {
  const handler = handlers[action]

  if (handler) {
    return handler(props, ...args)
  }

  console.warn(`Warning: Unhandled action '${action}'`)
}

// DATA

const defaultData = ({ match }) => {
  const isNew = !match || !match.params || !match.params.id

  return {
    paramId: isNew ? null : match.params.id,
    isNew,
    data: {
      adminDrug: {
        name: '',
        activeIngredients: [],
        pharmaceuticalForm: 'N/A',
        strength: '',
        drugPreparation: '',
        note: '',
        vmpDmdId: '-1',
        vtmId: '-1',
        generic: false,
        drugRegions: [],
      },
    },
  }
}

export const adminDrugQueryOptions = {
  options: ({ paramId }) => ({ variables: { id: paramId } }),
  skip: ({ isNew }) => isNew,
}

export const drugInfoQueryOptions = {
  props: ({ ownProps, data }) => ({
    ...ownProps,
    drugInfo: {
      ...data,
      drugForms:
        data.drugForms &&
        data.drugForms.map((item) => ({ label: item, value: item })),
      drugUnits:
        data.drugUnits &&
        data.drugUnits.map((item) => ({ label: item, value: item })),
    },
  }),
}

const withData = compose(
  withProps(defaultData),
  graphql(DrugInfo, drugInfoQueryOptions),
  graphql(AdminDrug, adminDrugQueryOptions),
  graphql(AddAdminDrug, { name: 'addAdminDrug' }),
  graphql(UpdateAdminDrug, { name: 'updateAdminDrug' })
)

// ACTIONS

const withActions = withHandlers({
  searchActiveIngredient: (props) => (searchPhrase) =>
    props.client.query({
      query: AdminActiveIngredient,
      variables: { query: searchPhrase, limit: 50 },
    }),
})

// STATE

export const defaultState = () => ({
  selectedRegion: null,
  newRegion: null,
  insertAlert: false,
  alerts: null,
})

export const updateSelectedRegion = (state) => (selectedRegion) => ({
  ...state,
  selectedRegion,
})

export const updateNewRegion = (state) => (newRegion) => ({
  ...state,
  newRegion,
})

export const updateAlerts = (state) => (alerts) => ({
  ...state,
  alerts,
})

const withState = withStateHandlers(defaultState, {
  updateSelectedRegion,
  updateNewRegion,
  updateAlerts,
})

// FORM

const formData = (props) => props.data.adminDrug

const updateCurrentRegion = (props, region) => {
  props.setState({
    drugRegions: props.state.drugRegions.map((item) =>
      props.selectedRegion.id === item.id ? region : item
    ),
  })
  props.updateSelectedRegion(region)
}

const formatPackagingItem = (originalPackaging) => (packagingItem) => {
  const originalPackagingItem = originalPackaging.find(
    (item) => item.id === packagingItem.id
  )

  return {
    ...originalPackagingItem,
    ...packagingItem,
    id: packagingItem._isNew ? undefined : originalPackaging.id,
    _isNew: undefined,
    _isRemoved: packagingItem._isRemoved ? true : undefined,
  }
}

const formatDrugRegion = (originalRegions) => (region) => {
  const originalRegion = originalRegions.find((item) => item.id === region.id)

  return {
    ...originalRegion,
    ...region,
    region: region.region.regionCode,
    id: region._isNew ? undefined : originalRegion.id,
    _isNew: undefined,
    packaging: region.packaging
      .map(formatPackagingItem(region._isNew ? [] : originalRegion.packaging))
      .filter((packaging) => !packaging._isRemoved),
  }
}

const preparePayload = (props, state) => {
  const originalRegions = props.data.adminDrug.drugRegions

  return map(
    {
      ...props.data.adminDrug,
      ...state,
      drugRegions: state.drugRegions.map(formatDrugRegion(originalRegions)),
    },
    removeTypeNames
  )
}

const formActions = {
  onCancel: (props) => props.history.goBack(),
  onSave: (props, state) => {
    const name = state.name.trim()
    const nameAlert = name.length < 4
    const regionsAlert = !state.drugRegions.length

    if (nameAlert || regionsAlert) {
      props.updateAlerts({ name: nameAlert, drugRegions: regionsAlert })
    } else {
      const adminDrugInput = preparePayload({ ...props, name }, state)
      let mutate = props.updateAdminDrug
      let refetchQueries

      if (props.isNew) {
        mutate = props.addAdminDrug
        refetchQueries = ['AdminDrugs']
      }

      mutate({
        variables: { adminDrugInput },
        refetchQueries,
      })
        .then(() => {
          props.history.goBack()
        })
        .catch(() => {
          props.errorAlert()
        })
    }
  },
  onLoadOptions: (props, name, input) =>
    props
      .searchActiveIngredient(input)
      .then((result) => result.data.adminActiveIngredient),
  onAction: actionBroker({
    updateFlags: (props, value) => {
      const controlledFlagChanged =
        props.selectedRegion.controlled !== value.includes('controlled')
      const newReasonValue = controlledFlagChanged
        ? props.selectedRegion.blacklistReason
        : undefined
      const promise =
        props.selectedRegion.blacklistReason || controlledFlagChanged
          ? Promise.resolve(newReasonValue)
          : promptReason()
      promise.then(
        (blacklistReason) =>
          updateCurrentRegion(props, {
            ...props.selectedRegion,
            blacklistReason,
            blacklisted: value.includes('blacklisted'),
            controlled: value.includes('controlled'),
          }),
        () => null
      )
    },
    removePackaging: (props, id) =>
      updateCurrentRegion(props, {
        ...props.selectedRegion,
        packaging: props.selectedRegion.packaging
          .filter((item) => item.id !== id || !item._isNew)
          .map((item) =>
            item.id === id ? { ...item, _isRemoved: true } : item
          ),
      }),
    restorePackaging: (props, id) =>
      updateCurrentRegion(props, {
        ...props.selectedRegion,
        packaging: props.selectedRegion.packaging.map((item) =>
          item.id === id ? { ...item, _isRemoved: false } : item
        ),
      }),
    addPackaging: (props, data) => {
      const price = parseFloat(data.price.replace(/[^0-9.]/g, ''))
      const packageSize = parseFloat(data.packageSize.replace(/[^0-9.]/g, ''))
      const newPackaging = {
        drugId: props.data.adminDrug.id,
        id: `NEW${++lastId}`,
        ...data,
        price: `£${price.toFixed(2)}`,
        packageSize: `${packageSize}`,
        ampDmdId: '-1',
        _isNew: true,
      }
      updateCurrentRegion(props, {
        ...props.selectedRegion,
        packaging: [...props.selectedRegion.packaging, newPackaging],
      })
    },
    addRegion: (props) => {
      if (!props.newRegion) {
        return
      }

      const newItem = {
        id: `NEW${++lastId}`,
        region: {
          regionCode: props.newRegion.regionCode,
          name: props.newRegion.name,
        },
        blacklisted: false,
        controlled: false,
        packaging: [],
        _isNew: true,
      }
      props.setState({
        drugRegions: [...props.state.drugRegions, newItem],
      })
      props.updateNewRegion(null)
      props.updateSelectedRegion(newItem)
    },
    deleteRegion: (props, id) => {
      props.setState({
        drugRegions: props.state.drugRegions.filter(
          (item) => id !== item.region.regionCode
        ),
      })
      props.updateSelectedRegion(null)
    },
  }),
}

const formPlugins = {
  listView: ListViewWidget,
  selectView: SelectWidget,
  regionsView: RegionsViewWidget,
}

const withAdminDrugForm = withForm(
  formConfig,
  formData,
  formActions,
  formPlugins
)

// LOADER

export const isLoading = (props) =>
  !props.data ||
  !props.data.adminDrug ||
  !props.drugInfo ||
  !props.drugInfo.drugForms ||
  !props.drugInfo.drugUnits

const withLoader = withSpinner(isLoading)

const Container = compose(
  withApollo,
  withData,
  withErrorMessage({ name: ['data', 'drugInfo'] }),
  withActions,
  withState,
  withAdminDrugForm,
  withLoader
)(DrugEditView)

Container.width = '70%'

export default Container
