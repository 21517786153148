import { Queue } from '../../../../lib'

export const splitQueueAgentIdx = (
  queues: Queue[],
  totalIdx: number
): [number, number] => {
  let currIdx = 0

  for (let i = 0; i < queues.length; i++) {
    const queue = queues[i]

    if (currIdx === totalIdx) {
      return [i, -1]
    }

    currIdx += 1

    for (let j = 0; j < queue.agents.length; j++) {
      if (currIdx === totalIdx) {
        return [i, j]
      }

      currIdx += 1
    }
  }

  return [-1, -1]
}

export const getQueueByIdx = (
  systemQueues: Queue[],
  userQueues: Queue[],
  queueIdx: number,
  agentIdx: number
): Queue | undefined => {
  if (agentIdx === -1) {
    return systemQueues[queueIdx]
  }

  const agentId = systemQueues[queueIdx].agents[agentIdx].id
  const userQueue = userQueues.find((q) => q.id === agentId)

  return userQueue
}

export const nameToInitials = (name: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [first, second, ..._] = name.split(' ')

  return `${first[0]}${(second || '')[0] || ''}`.toUpperCase()
}
