import React from 'react'

import { FormikTextarea, Tooltip } from '@babylon/core-ui'
import ServiceTypeField from './ServiceTypeField'

interface Props {
  isEditing: boolean
  value?: string
  id: string
  name: string
  label: string
  placeholder: string
  tooltipText: string
}

const InstructionsField = ({
  isEditing = false,
  value = '',
  id,
  name,
  label,
  placeholder,
  tooltipText,
}: Props) =>
  isEditing ? (
    <FormikTextarea
      id={id}
      name={name}
      label={
        <>
          {label}
          {': '}
          <Tooltip
            info={tooltipText}
            placement="right"
            overlayStyle={{ width: 300 }}
          />
        </>
      }
      placeholder={placeholder}
    />
  ) : (
    <ServiceTypeField label={label} value={value} tooltipText={tooltipText} />
  )

export default InstructionsField
