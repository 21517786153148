import { gql } from '@apollo/client'

export const MESSAGES_QUERY = gql`
  query AP_Messages(
    $userId: ID!
    $conversationId: ID!
    $offset: Int
    $limit: Int
    $order: String
  ) {
    chatscriptMessages(
      userId: $userId
      conversationId: $conversationId
      offset: $offset
      limit: $limit
      order: $order
    ) {
      more
      messages {
        id
        style
        author {
          id
          name
        }
        input {
          params {
            choices {
              label
            }
          }
        }
        sentAt
        sender
        type
        value {
          text
        }
      }
    }
  }
`
