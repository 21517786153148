export const getRedirectPath = (user: any) => {
  if (user.pharmacist) {
    return '/admin/prescriptions'
  }

  if (
    user.admin ||
    user.clinopsSupportAssociate ||
    user.PrescribingPharmacist
  ) {
    return '/admin/patients'
  }

  return '/'
}
