import { gql } from '@apollo/client'

export const APPLY_REDEMPTIONS = gql`
  mutation AP_ApplyRedemption(
    $code: String!
    $patientId: Int!
    $redemptionType: RedemptionType!
  ) {
    applyRedemptionV1(
      code: $code
      patientId: $patientId
      redemptionType: $redemptionType
    )
  }
`
