import { gql } from '@apollo/client'

export default gql`
  query getMemberTestResultsThread($uuid: ID!) {
    memberThread(filter: { uuid: $uuid }) {
      state
      tasks {
        state
        logEntry
        createdAt
      }
    }
  }
`
