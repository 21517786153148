import React from 'react'
import { useQuery } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import {
  MemberWorkflowState,
  MemberThreadType,
  MemberThread,
} from '@babylon/graphql-middleware-types'
import MemberTaskDrawer from '../MemberTaskDrawer'
import TestResultsThreads from './TestResultsThreads'
import MemberThreadsQuery from './MemberThreadsQuery'
import { ErrorBlock } from '../../../../ErrorBoundary'
import useActiveThread from './useActiveThread'
import { LoadingBlock } from '../../../../LoadingContent'
import messages from './TestResults.messages'
import styles from './TestResultsTimeline.module.scss'

interface TestResultsTimelineProps {
  memberUuid: string
}

export type Thread = Omit<
  MemberThread,
  'currentTask' | 'tasks' | 'memberId' | 'type'
>

export default function TestResultsTimeline({
  memberUuid,
}: TestResultsTimelineProps) {
  const [activeThread, resetActiveThread, setActiveThread] = useActiveThread()
  const fm = useFormatMessage()

  const { data, loading, error } = useQuery(MemberThreadsQuery, {
    variables: {
      memberId: memberUuid,
      type: MemberThreadType.Test,
    },
    skip: !memberUuid,
  })

  if (loading) {
    return (
      <div
        data-testid="test-result-timeline-loading"
        aria-busy="true"
        aria-live="polite"
      >
        <LoadingBlock className={styles.loadingBlock} />
        <LoadingBlock className={styles.loadingBlock} />
      </div>
    )
  }

  const outstandingThreads: Thread[] = []
  const pastThreads: Thread[] = []

  data?.memberThreads?.forEach((thread) => {
    if (thread.state === MemberWorkflowState.Pending) {
      outstandingThreads.push(thread)
    } else {
      pastThreads.push(thread)
    }
  })

  return (
    <div>
      {error?.message && (
        <ErrorBlock>
          <ErrorBlock.Heading>{error.message}</ErrorBlock.Heading>
        </ErrorBlock>
      )}
      <MemberTaskDrawer
        title={activeThread?.title}
        threadUuid={activeThread?.id}
        closeDrawer={resetActiveThread}
      />
      {/* Outstanding Tests */}
      <TestResultsThreads
        setActiveThread={setActiveThread}
        threads={outstandingThreads}
        title={fm(messages.outstanding_tests)}
        info={fm(messages.outstanding_tests_tooltip)}
        testId="outstanding-test-results"
      />
      {/* Past Tests */}
      <TestResultsThreads
        setActiveThread={setActiveThread}
        threads={pastThreads}
        title={fm(messages.past_tests)}
        info={fm(messages.past_tests_tooltip)}
        testId="past-test-results"
      />
    </div>
  )
}
