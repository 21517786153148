import React from 'react'
import { Formik, FormikErrors, FormikValues } from 'formik'
import { useFormatMessage } from '@babylon/intl'
import {
  Box,
  ButtonReactRouterLink,
  ButtonSimple,
  ButtonVariant,
  Modal,
  RadioGroupWithValidation,
  useModalActions,
  withModalProvider,
} from '@babylon/medkit'

import { PatientInterface } from '../../types'
import SubmitForm from '../../components/SubmitForm'
import messages from './messages'

import styles from './RiskForm.module.scss'

const RiskForm = ({ member }: Props) => {
  const fm = useFormatMessage()
  const { closeModal, openModal } = useModalActions()

  const onValidate = ({ riskLevel }: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {}

    if (!riskLevel) {
      errors.riskLevel = fm(messages.selectLevelError)
    }

    return errors
  }

  const onSubmit = ({ riskLevel }: FormikValues) => {
    openModal(
      <SubmitForm
        member={member}
        onClose={closeModal}
        reason="completed"
        riskLevel={riskLevel}
      />
    )
  }

  return (
    <>
      <Formik
        initialValues={{ riskLevel: '' }}
        onSubmit={onSubmit}
        validate={onValidate}
      >
        {({ errors, handleChange, handleSubmit, values }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <RadioGroupWithValidation
              errors={errors.riskLevel ? [errors.riskLevel] : []}
              legend={fm(messages.selectLevel)}
              name="riskLevel"
              onChange={(riskLevel) => {
                handleChange({
                  target: { name: 'riskLevel', value: riskLevel },
                })
              }}
              options={[
                {
                  label: fm(messages.highRisk),
                  selected: values.riskLevel === 'high',
                  value: 'high',
                },
                {
                  label: fm(messages.mediumRisk),
                  selected: values.riskLevel === 'medium',
                  value: 'medium',
                },
                {
                  label: fm(messages.lowRisk),
                  selected: values.riskLevel === 'low',
                  value: 'low',
                },
              ]}
            />
            <Box className={styles.buttons}>
              <ButtonSimple type="submit">{fm(messages.submit)}</ButtonSimple>
              <ButtonReactRouterLink
                to={`/admin/patients/${member.id}/assessment`}
                variant={ButtonVariant.secondary}
              >
                {fm(messages.back)}
              </ButtonReactRouterLink>
            </Box>
          </form>
        )}
      </Formik>
      <Modal />
    </>
  )
}

interface Props {
  member: PatientInterface
}

const enhance = withModalProvider<Props>()

export default enhance(RiskForm)
