import React, { Fragment, ReactElement, useState, useEffect } from 'react'
import { Grid, Cell, Checkbox, Button, Spinner } from '@babylon/core-ui'
import { Entity, EntityRendererProps } from './index'
import { SelectableEntity } from './EntityItemsModal'
import titleCase from './titleCase'

const unselectByDefault = <E extends Entity>(items: SelectableEntity<E>[]) =>
  items.map((c: SelectableEntity<E>) => ({
    ...c,
    selected: c.selected === undefined ? false : c.selected,
  }))

interface EntityListProps<E extends Entity> {
  name: string
  loading: boolean
  items: SelectableEntity<E>[]
  entityRenderer: (props: EntityRendererProps<E>) => ReactElement
  onItemsAdded: (itemIds: string[]) => void
}

const EntityList = <E extends Entity>({
  name,
  loading,
  items,
  entityRenderer: Item,
  onItemsAdded,
}: EntityListProps<E>) => {
  const titleCaseName = titleCase(name)
  const [selectedItems, setSelectedItems] = useState(unselectByDefault(items))
  useEffect(() => {
    setSelectedItems(unselectByDefault(items))
  }, [items])

  return (
    <>
      <div
        style={{
          width: '408px',
          height: '300px',
        }}
      >
        {loading && <Spinner centered />}
        {!loading && (
          <Grid
            columns={12}
            style={{
              width: '100%',
              height: '100%',
              overflow: 'auto',
              paddingRight: '12px',
            }}
            gap="40px 5px"
            alignItems="center"
            alignContent="middle"
          >
            {selectedItems.map((item, i) => (
              <Fragment key={item.entity.id}>
                <Cell width={1} style={{ paddingTop: '5px' }}>
                  <Checkbox
                    checked={item.selected || item.readOnly}
                    disabled={item.readOnly}
                    onChange={() =>
                      setSelectedItems([
                        ...selectedItems.slice(0, i),
                        {
                          ...selectedItems[i],
                          selected: !selectedItems[i].selected,
                        },
                        ...selectedItems.slice(i + 1),
                      ])
                    }
                  />
                </Cell>
                <Cell width={11}>
                  <Item item={item.entity} />
                </Cell>
              </Fragment>
            ))}
          </Grid>
        )}
      </div>
      <Grid
        columns={3}
        style={{
          gridTemplateColumns: '1fr auto 1fr',
        }}
      >
        <Cell />
        <Cell>
          <Button
            disabled={selectedItems.every((sI) => !sI.selected)}
            onClick={() =>
              onItemsAdded(
                selectedItems
                  .filter((sI) => sI.selected)
                  .map((sI) => sI.entity.id)
              )
            }
          >
            Save {titleCaseName}(s)
          </Button>
        </Cell>
      </Grid>
      <Cell />
    </>
  )
}

export default EntityList
