import React from 'react'
import { useFormikContext, FormikValues } from 'formik'
import { useHistory } from 'react-router-dom'
import { useFormatMessage } from '@babylon/intl'
import { ButtonVariant, ButtonSimple } from '@babylon/medkit'
import { AppointmentFeatures } from '@babylon/graphql-middleware-types'
import messages from '../DigitalAppointmentForm.messages'
import { MediumTypes } from './AppointmentMedium'
import {
  findServiceTypeById,
  getAllowedMediumsForSelectedMedium,
} from './FormWithServiceTypes.utils'
import ServiceType from '../../types/ServiceType'

export interface BookForPatientProps {
  memberId: string
  selectedMedium: MediumTypes | null
  serviceTypes: ServiceType[]
  onClickTrack?: () => void
}

export default function BookForPatient({
  memberId,
  selectedMedium,
  serviceTypes,
  onClickTrack = () => {},
}: BookForPatientProps) {
  const { values, validateForm, submitForm } = useFormikContext<FormikValues>()
  const history = useHistory()
  const fm = useFormatMessage()

  const allowedMediums = getAllowedMediumsForSelectedMedium(
    selectedMedium,
    findServiceTypeById(serviceTypes, values.appointment_type)?.allowed_mediums
  )

  const filters = {
    allowed_professions: [null],
    service_type_uuid: values.appointment_type,
    appointment_reason: values.notes_for_member,
    duration_minutes: values.duration_minutes,
    appointment_features: AppointmentFeatures.ServiceTypeBooking,
    ...allowedMediums,
    ...(allowedMediums?.allowed_mediums?.length === 1
      ? {
          consultation_type: allowedMediums?.allowed_mediums[0],
        }
      : {}),
  }

  const handleClick = async () => {
    const errors = await validateForm()

    onClickTrack()

    if (Object.keys(errors).length === 0) {
      history.push(`/admin/patients/${memberId}/schedule-appointment`, {
        filters,
        originalFilters: values,
        selectedMedium,
      })
    } else {
      submitForm()
    }
  }

  return (
    <ButtonSimple
      onClick={() => {
        handleClick()
      }}
      variant={ButtonVariant.secondary}
    >
      {fm(messages.book_btn)}
    </ButtonSimple>
  )
}
