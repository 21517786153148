import React from 'react'
import { useQuery } from '@apollo/client'

import GetServiceTypeQuery from '../ServiceMix/ServiceType/GetServiceTypeQuery'
import { ConsultationTypeName } from '../types/ConsultationTypes'
import AppointmentDialog from '../AppointmentDialog'

export type Consultant = {
  name: string
  gender: string
}

export interface AppointmentConfirmationDialogProps {
  active: boolean
  onClose: () => void
  clinic?: Practice | null
  medium?: ConsultationTypeName
  appointmentId: string
  timezoneId?: string | null
  scheduledTime?: string | null
  duration: number
  consultant?: Consultant | null
  serviceTypeUuid?: string
}

export default function AppointmentConfirmationDialog({
  active,
  onClose,
  clinic,
  medium,
  appointmentId,
  timezoneId,
  scheduledTime,
  duration,
  consultant,
  serviceTypeUuid,
}: AppointmentConfirmationDialogProps): React.ReactElement | null {
  const { data, loading: loadingDetails } = useQuery(GetServiceTypeQuery, {
    variables: {
      uuid: serviceTypeUuid,
    },
    skip: !serviceTypeUuid,
  })

  return (
    <AppointmentDialog
      active={active}
      onClose={onClose}
      onConfirm={onClose}
      clinic={clinic}
      medium={medium}
      heading={
        <>
          Appointment #{appointmentId} <br /> successfully booked
        </>
      }
      timezoneId={timezoneId}
      scheduledTime={scheduledTime}
      duration={duration}
      consultant={consultant}
      includeCancel={false}
      alignButtons="center"
      confirmButtonText="Okay"
      serviceType={data?.serviceType?.name || ''}
      loadingDetails={loadingDetails}
    />
  )
}
