import React from 'react'
import PropTypes from 'prop-types'

import SortButtonView from './SortButtonView'
import ColumnSearchView from './ColumnSearchView'

const ListHeaderView = (props) => {
  const { label } = props

  return (
    <th>
      {label}
      <SortButtonView {...props} />
      <ColumnSearchView {...props} />
    </th>
  )
}

ListHeaderView.propTypes = {
  label: PropTypes.string.isRequired,
}

export default ListHeaderView
