import { defineMessages } from 'react-intl'

const baseId = 'digital_appointment'

export default defineMessages({
  patient_network_footnote: {
    id: `${baseId}.patient_network_footnote`,
    defaultMessage: 'Patient Consumer Network: ',
  },
})
