import ServiceType from '../ServiceType'

interface ServiceTypeData {
  serviceTypes: {
    [uuid: string]: ServiceType
  }
  serviceTypesUuids: string[]
  groupedServiceTypesUuids: {
    [group: string]: string[]
  }
  consultantTypes: string[]
}

const sortServiceTypes = (serviceTypes: ServiceType[] = []) =>
  [...serviceTypes].sort((serviceType1, serviceType2) => {
    const value1 = serviceType1.name.toUpperCase()
    const value2 = serviceType2.name.toUpperCase()

    if (value2 === 'OTHER') {
      return -1
    }

    return value1.localeCompare(value2)
  })

const normaliseServiceTypes = (
  serviceTypes: ServiceType[] = []
): ServiceTypeData => {
  const data: ServiceTypeData = {
    serviceTypes: {},
    serviceTypesUuids: [],
    groupedServiceTypesUuids: {},
    consultantTypes: [],
  }

  const sortedServiceTypes = sortServiceTypes(serviceTypes)

  sortedServiceTypes.forEach((serviceType: ServiceType) => {
    data.serviceTypes[serviceType.uuid] = serviceType
    data.serviceTypesUuids.push(serviceType.uuid)

    if (!data.consultantTypes.includes(serviceType.grouping)) {
      data.consultantTypes.push(serviceType.grouping)
      data.groupedServiceTypesUuids[serviceType.grouping] = []
    }

    data.groupedServiceTypesUuids[serviceType.grouping].push(serviceType.uuid)
  })

  return data
}

export { normaliseServiceTypes, sortServiceTypes }
