import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { Page, Card } from '@babylon/core-ui'

import CreateProfessionMutation from './CreateProfessionMutation'
import { UnexpectedError } from '../../Utils'
import { LoadingContainer } from '../../components'
import GetProfessions from '../GetProfessionsQuery'

import ProfessionForm from '../ProfessionForm'

interface CreateProfessionInput extends Omit<Profession, 'id' | 'key'> {}

interface Props {
  baseUrl: string
}

const AddProfession = ({ baseUrl }: Props) => {
  const [
    createProfession,
    { data: { createProfession: { id = null } = {} } = {}, loading, error },
  ] = useMutation(CreateProfessionMutation, {
    refetchQueries: () => [{ query: GetProfessions }],
  })

  if (id) {
    return <Redirect to={`${baseUrl}/${id}`} />
  }

  const handleSubmit = async (input: CreateProfessionInput) => {
    await createProfession({ variables: { input } })
  }

  return (
    <Page
      breadcrumbs={[
        <Link to={baseUrl}>Professions</Link>,
        'Add new profession',
      ]}
    >
      <Card title="Add Profession">
        {error && <UnexpectedError message={error.message} visible />}
        <LoadingContainer loading={loading}>
          <ProfessionForm onSubmit={handleSubmit} />
        </LoadingContainer>
      </Card>
    </Page>
  )
}

export default AddProfession
