import { defineMessages } from 'react-intl'

const baseId = 'member_relationships_card'

export default defineMessages({
  add_guardian_button_text: {
    id: `${baseId}.add_guardian_button`,
    defaultMessage: 'Add a guardian',
  },
  guardian_header: {
    id: `${baseId}.guardian_header`,
    defaultMessage: 'Guardian',
  },
  dependents_header: {
    id: `${baseId}.dependents_header`,
    defaultMessage: 'Dependents',
  },
  add_dependent_button_text: {
    id: `${baseId}.add_dependent_button`,
    defaultMessage: 'Add a dependent',
  },
  create_search_patient_message: {
    id: `${baseId}.create_search_patient_message`,
    defaultMessage:
      'You can create a new patient or search for a patient using one of the fields below.',
  },
  search_patient_message: {
    id: `${baseId}.search_patient_message`,
    defaultMessage: 'Please use a valid insurance id to search a patient.',
  },
  create_new_patient_button: {
    id: `${baseId}.create_new_patient_button`,
    defaultMessage: 'Create new patient',
  },

  search_button: {
    id: `${baseId}.search_button`,
    defaultMessage: 'Search',
  },
  uuid_text_input: {
    id: `${baseId}.uuid_text_input`,
    defaultMessage: 'UUID',
  },
  search_result_message: {
    id: `${baseId}.search_result_message`,
    defaultMessage:
      'If the patient is in pre-registered status, you will only be able to add them to the family',
  },
})
