export enum PDSStatuses {
  ACCEPTED = 'accepted',
  ERROR = 'errored',
  FAIL = 'failed',
  INCOMPLETE = 'incomplete',
  PENDING = 'pending',
  NO_RESULTS = 'no_results',
  REDACTED = 'redacted',
  REJECTED = 'rejected',
  RETRIEVED = 'retrieved',
  UNDER_REVIEW = 'under_review',
}

export type StatusText = { [s in PDSStatuses]: string }

const statusText: StatusText = {
  accepted: 'Match',
  errored: 'Error',
  failed: 'Fail',
  incomplete: 'Incomplete',
  pending: 'Pending',
  no_results: 'No results',
  redacted: 'Redacted',
  rejected: 'Rejected',
  retrieved: 'Retrieved',
  under_review: 'Under review',
}

export const isErrorStatus = (status: PDSStatuses) =>
  status === PDSStatuses.ERROR ||
  status === PDSStatuses.FAIL ||
  status === PDSStatuses.REJECTED

export const isWarningStatus = (status: PDSStatuses) =>
  status === PDSStatuses.INCOMPLETE ||
  status === PDSStatuses.PENDING ||
  status === PDSStatuses.UNDER_REVIEW

export const getStatusText = (status: PDSStatuses) =>
  status in statusText ? statusText[status] : ''
