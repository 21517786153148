import React, { ReactNode } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import { KebabMenuRenderOptions, SnackbarIntent } from '@babylon/core-ui'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import messages from './ProfileHeader.messages'
import { RESET_PASSWORD_BY_EMAIL_GQL } from './queries'
import { MemberOpsModuleName } from '../../../../..'

export interface ResetPasswordButtonProps extends KebabMenuRenderOptions {
  email: string
  setSnackbarMessage: (
    m: string,
    icon?: ReactNode,
    intent?: SnackbarIntent
  ) => void
  setSnackbarError: (m: string) => void
}

export const ResetPasswordButton = ({
  email,
  menuItemProps,
  setSnackbarMessage,
}: ResetPasswordButtonProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profilesHeader,
  })
  const fm = useFormatMessage()

  const setErrorSnackbar = (message) => {
    setSnackbarMessage(
      fm(messages.reset_password_error, {
        message,
      }),
      undefined,
      'error'
    )
  }

  const [resetPasswordViaAuth0] = useMutation(RESET_PASSWORD_BY_EMAIL_GQL, {
    variables: {
      input: {
        emailAddress: email,
      },
    },
    context: { clientName: 'platform-gateway' },
    onError: (error: ApolloError) => {
      if (error.networkError) {
        setErrorSnackbar(error.networkError.message)
      } else if (error.graphQLErrors) {
        const message = error.graphQLErrors
          .map((err) => err.message)
          .join(' , ')
        setErrorSnackbar(message)
      } else {
        setErrorSnackbar('')
      }
    },
    onCompleted: (data) => {
      if (
        data &&
        // eslint-disable-next-line no-underscore-dangle
        data.__typename &&
        // eslint-disable-next-line no-underscore-dangle
        data.__typename !== 'ResourceNotFoundError'
      ) {
        setErrorSnackbar(data.errorMessage)
      } else {
        setSnackbarMessage(fm(messages.reset_password_snack_message, { email }))
      }
    },
  })

  return (
    <button
      {...menuItemProps}
      data-testid="member-profile-reset-password-button"
      type="button"
      onClick={() => {
        resetPasswordViaAuth0()
        trackClick({
          elementName: 'member-profile-reset-password-btn',
          elementType: TrackingElementType.button,
        })
      }}
    >
      {fm(messages.reset_password)}
    </button>
  )
}
