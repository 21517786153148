import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { format, startOfDay } from 'date-fns'

import {
  Button,
  Card,
  Divider,
  Grid,
  Page,
  Cell,
  DateRangePicker,
  Radio,
  RadioGroup,
  Label,
  Text,
} from '@babylon/core-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { envFlag } from '@babylon/babylon-env'
import styles from './AvailabilitySyncPage.module.css'

import ClinicianSearcher from '../ClinicianSearcher'
import Overlay from '../Overlay'
import ProfessionSearcher from '../ProfessionSearcher'
import PracticeSearcher from '../PracticeSearcher'
import SupplyNetworkSearcher from '../SupplyNetworkSearcher'
import SyncStatus from '../SyncStatus'
import SyncRunChecker from '../SyncRunChecker'
import AvailabilityListing from '../AvailabilityListing'

import { useFindCliniciansQuery } from './FindCliniciansQuery.hooks'
import { useFindPracticesQuery } from './FindPracticesQuery.hooks'
import { SyncHumanityDataItem } from '../SyncStatus/SyncHumanityMutation'

type nullableDate = Date | null

export const radioValues = {
  individual: 'individual',
  all: 'all',
}

export enum SyncFetchState {
  Pending,
  FetchCreated,
  FetchUpdated,
}

const AvailabilitySyncPage = () => {
  const enableAvailabilitySyncPracticeFilter = envFlag(
    'ENABLE_AVAILABILITY_SYNC_PRACTICE_FILTER'
  )

  const autoSyncEnabled: boolean = envFlag('ENABLE_AUTOSYNC') ?? false

  const [startDate, setStartDate] = useState<nullableDate>(null)
  const [endDate, setEndDate] = useState<nullableDate>(null)
  const [selectClinician, setSelectClinician] = useState(radioValues.individual)
  const [clinicianSearchValue, setClinicianSearchValue] = useState('')
  const [updateListing, setUpdateListing] = useState<SyncFetchState>(
    SyncFetchState.Pending
  )
  const [selectedClinicians, setSelectedClinicians] = useState<
    SyncHumanityDataItem[]
  >([])

  const [selectSupplyNetwork, setSelectSupplyNetwork] = useState(
    radioValues.individual
  )
  const [selectedSupplyNetworks, setSelectedSupplyNetworks] = useState<
    SyncHumanityDataItem[]
  >([])

  const [selectProfession, setSelectProfession] = useState(
    radioValues.individual
  )
  const [selectedProfessions, setSelectedProfessions] = useState<
    SyncHumanityDataItem[]
  >([])

  const [selectPractice, setSelectPractice] = useState(radioValues.individual)
  const [selectedPractices, setSelectedPractices] = useState<
    SyncHumanityDataItem[]
  >([])

  const [practiceSearchValue, setPracticeSearchValue] = useState('')

  const [reviewDisabled, setReviewDisabled] = useState(true)

  useEffect(() => {
    if (!startDate || !endDate) {
      setReviewDisabled(true)

      return
    }

    if (selectClinician === radioValues.all) {
      setReviewDisabled(false)

      return
    }

    if (
      selectClinician === radioValues.individual &&
      selectedClinicians?.length > 0
    ) {
      setReviewDisabled(false)
    }
  }, [startDate, endDate, selectClinician, selectedClinicians])

  const skipClinicianSearch = clinicianSearchValue.length < 3
  const skipPracticeSearch = practiceSearchValue.length < 3

  const {
    data = { getEligibleConsultants: [] },
    loading,
  } = useFindCliniciansQuery({
    skip: skipClinicianSearch,
    variables: { name: clinicianSearchValue },
  })

  const {
    data: practiceData = { practicesByFilter: [] },
    loading: practiceLoading,
  } = useFindPracticesQuery({
    skip: skipPracticeSearch,
    variables: { filter: practiceSearchValue },
  })

  const handleDateRangeSelected = (e) => {
    setStartDate(e.startDate)
    setEndDate(e.endDate)
  }

  const sortByName = (objects: any[]): any[] =>
    objects.sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase()

      let comp = 0

      if (nameA > nameB) {
        comp = 1
      } else if (nameA < nameB) {
        comp = -1
      }

      return comp
    })

  const handleClinicianSelected = (id: string, name: string): void => {
    if (selectedClinicians.some((clinician) => clinician.id === id)) {
      return
    }

    setSelectedClinicians(sortByName([...selectedClinicians, { id, name }]))
  }

  const handleRemoveClinician = (id: string): void => {
    setSelectedClinicians(
      selectedClinicians.filter((clinician) => clinician.id !== id)
    )
  }

  const handleProfessionSelected = (id: string, name: string): void => {
    if (selectedProfessions.some((profession) => profession.id === id)) {
      return
    }

    setSelectedProfessions(sortByName([...selectedProfessions, { id, name }]))
  }

  const handleRemoveProfession = (id: string): void => {
    setSelectedProfessions(
      selectedProfessions.filter((profession) => profession.id !== id)
    )
  }

  const handlePracticeSelected = (
    id: string,
    name: string | null | undefined
  ): void => {
    if (selectedPractices.some((practice) => practice.id === id)) {
      return
    }

    setSelectedPractices(sortByName([...selectedPractices, { id, name }]))
  }

  const handleRemovePractice = (id: string): void => {
    setSelectedPractices(
      selectedPractices.filter((profession) => profession.id !== id)
    )
  }

  const handleSupplyNetworkSelected = (id: string, name: string): void => {
    if (selectedSupplyNetworks.some((network) => network.id === id)) {
      return
    }

    setSelectedSupplyNetworks(
      sortByName([...selectedSupplyNetworks, { id, name }])
    )
  }

  const handleRemoveSupplyNetwork = (id: string): void => {
    setSelectedSupplyNetworks(
      selectedSupplyNetworks.filter((network) => network.id !== id)
    )
  }

  const toggleUpdateListing = () => {
    switch (updateListing) {
      case SyncFetchState.Pending:
        setUpdateListing(SyncFetchState.FetchCreated)

        break
      case SyncFetchState.FetchCreated:
        setUpdateListing(SyncFetchState.FetchUpdated)

        break
      case SyncFetchState.FetchUpdated:
        setUpdateListing(SyncFetchState.FetchCreated)

        break
      default:
        setUpdateListing(SyncFetchState.Pending)

        break
    }
  }

  useEffect(() => {
    if (selectClinician === radioValues.all) {
      setSelectedClinicians([])
    }

    if (selectSupplyNetwork === radioValues.all) {
      setSelectedSupplyNetworks([])
    }

    if (selectProfession === radioValues.all) {
      setSelectedProfessions([])
    }
  }, [selectClinician, selectSupplyNetwork, selectProfession])

  useEffect(() => {
    if (
      updateListing === SyncFetchState.FetchCreated ||
      updateListing === SyncFetchState.FetchUpdated
    ) {
      setTimeout(() => {
        setUpdateListing(SyncFetchState.Pending)
      }, 2000)
    }
  }, [updateListing])

  const TimesIcon = <FontAwesomeIcon icon={faTimes} />

  return (
    <Page
      title="Availability Sync"
      actions={[
        <Link key="orphaned" to="/admin/orphaned_appointments_new">
          Orphaned Appointments
        </Link>,
      ]}
    >
      <Grid columns={3}>
        <Card className={styles.DisplayCard} title="1. What to sync">
          <Cell>
            <Label htmlFor="availability-sync-date-range">
              <Text tag="div">Date Range</Text>
            </Label>

            {!autoSyncEnabled && (
              <div className={styles.dateRangePicker}>
                <DateRangePicker
                  id="availability-sync-date-range"
                  startDate={startDate as Date}
                  endDate={endDate as Date}
                  minDate={startOfDay(new Date())}
                  onChange={handleDateRangeSelected}
                  withFormikField={false}
                  autoComplete="off"
                />
              </div>
            )}

            <Divider />

            <Grid columns={2}>
              <Cell>
                <Label htmlFor="select-clinicians">
                  <Text className={styles.AvailabilityLabel}>
                    Clinician filter
                  </Text>
                </Label>
              </Cell>
              <Cell>
                <RadioGroup
                  name="select-clinicians"
                  value={selectClinician}
                  onChange={setSelectClinician}
                >
                  <Radio
                    data-testid="select-clinicians-all"
                    value={radioValues.all}
                  >
                    All clinicians
                  </Radio>
                  <Radio
                    data-testid="select-clinicians-individual"
                    value={radioValues.individual}
                  >
                    Individual clinicians
                  </Radio>
                </RadioGroup>
              </Cell>
              <Cell width={2}>
                {selectClinician === radioValues.individual && (
                  <>
                    {!selectedClinicians.length && (
                      <Text color="error">Please select a Clinician</Text>
                    )}
                    <ClinicianSearcher
                      setSearchValue={setClinicianSearchValue}
                      handleClinicianSelected={handleClinicianSelected}
                      data={data}
                      loading={loading}
                      skipClinicianSearch={skipClinicianSearch}
                    />
                  </>
                )}
              </Cell>
              <Cell>
                <Label htmlFor="select-supply-networks">
                  <Text className={styles.AvailabilityLabel}>
                    Add supply networks
                  </Text>
                </Label>
              </Cell>
              <Cell>
                <RadioGroup
                  name="select-supply-networks"
                  value={selectSupplyNetwork}
                  onChange={setSelectSupplyNetwork}
                >
                  <Radio
                    data-testid="select-supply-networks-all"
                    value={radioValues.all}
                  >
                    All supply networks
                  </Radio>
                  <Radio
                    data-testid="select-supply-networks-individual"
                    value={radioValues.individual}
                  >
                    Individual supply networks
                  </Radio>
                </RadioGroup>
              </Cell>
              <Cell width={2}>
                {selectSupplyNetwork === radioValues.individual && (
                  <>
                    {!selectedSupplyNetworks.length && (
                      <Text color="error">Please select a supply network</Text>
                    )}
                    <SupplyNetworkSearcher
                      onSelect={handleSupplyNetworkSelected}
                    />
                  </>
                )}
              </Cell>
              <Cell>
                <Label htmlFor="select-professions">
                  <Text className={styles.AvailabilityLabel}>
                    Add professions
                  </Text>
                </Label>
              </Cell>
              <Cell>
                <RadioGroup
                  name="select-professions"
                  value={selectProfession}
                  onChange={setSelectProfession}
                >
                  <Radio
                    data-testid="select-professions-all"
                    value={radioValues.all}
                  >
                    All professions
                  </Radio>
                  <Radio
                    data-testid="select-professions-individual"
                    value={radioValues.individual}
                  >
                    Individual professions
                  </Radio>
                </RadioGroup>
              </Cell>
              <Cell width={2}>
                {selectProfession === radioValues.individual && (
                  <>
                    {!selectedProfessions.length && (
                      <Text color="error">Please select a profession</Text>
                    )}
                    <ProfessionSearcher onSelect={handleProfessionSelected} />
                  </>
                )}
              </Cell>
              {enableAvailabilitySyncPracticeFilter && (
                <>
                  <Cell>
                    <Label htmlFor="select-practices">
                      <Text className={styles.AvailabilityLabel}>
                        Add practices
                      </Text>
                    </Label>
                  </Cell>
                  <Cell>
                    <RadioGroup
                      name="select-practices"
                      value={selectPractice}
                      onChange={setSelectPractice}
                    >
                      <Radio
                        data-testid="select-practices-all"
                        value={radioValues.all}
                      >
                        All practices
                      </Radio>
                      <Radio
                        data-testid="select-practices-individual"
                        value={radioValues.individual}
                      >
                        Individual practices
                      </Radio>
                    </RadioGroup>
                  </Cell>
                  <Cell width={2}>
                    {selectPractice === radioValues.individual && (
                      <>
                        {!selectedPractices.length && (
                          <Text color="error">Please select a practice</Text>
                        )}
                        <PracticeSearcher
                          setSearchValue={setPracticeSearchValue}
                          handleSelected={handlePracticeSelected}
                          skipSearch={skipPracticeSearch}
                          practices={practiceData.practicesByFilter}
                          loading={practiceLoading}
                        />
                      </>
                    )}
                  </Cell>
                </>
              )}
            </Grid>
          </Cell>
          <SyncRunChecker />
          <Overlay data-testid="overlay-card4" hidden={!autoSyncEnabled} />
        </Card>
        <Card className={styles.DisplayCard} title="2. Review selections">
          <Overlay data-testid="overlay-card2" hidden={!reviewDisabled} />

          <Text size="large">Dates</Text>
          <ul>
            <li data-testid="display-start-date">
              <Text bold className={styles.AvailabilityLabel}>
                Start date:
              </Text>
              <Text italic>
                {startDate ? format(startDate, 'MMM dd, yyyy') : 'not set'}
              </Text>
            </li>
            <li data-testid="display-end-date">
              <Text bold className={styles.AvailabilityLabel}>
                End date:
              </Text>
              <Text italic>
                {endDate ? format(endDate, 'MMM dd, yyyy') : 'not set'}
              </Text>
            </li>
          </ul>

          <Divider />

          <Text size="large" tag="div">
            Clinicians
          </Text>

          <Text size="small">
            Selected clinicians for syncing availability:
          </Text>

          {selectClinician === radioValues.all && (
            <Text tag="div" data-testid="clinicians-all">
              <FontAwesomeIcon icon={faCheck} /> All
            </Text>
          )}

          {selectClinician === radioValues.individual && (
            <ul
              data-testid="show-clinicians-list"
              className={styles.ShowCliniciansList}
            >
              {selectedClinicians.map((clinician) => (
                <li key={clinician.id}>
                  <span className={styles.AvailabilityLabel}>
                    ID: {clinician.id} : {clinician.name}
                  </span>
                  <Button
                    intent="link"
                    data-testid="remove-clinician"
                    onClick={() => handleRemoveClinician(clinician.id)}
                    icon={TimesIcon}
                    inline
                  />
                </li>
              ))}
            </ul>
          )}

          <Divider />

          <Text size="large" tag="div">
            Supply networks
          </Text>
          <Text size="small">
            Selected supply networks for syncing availability:
          </Text>

          {selectSupplyNetwork === radioValues.all && (
            <Text tag="div">
              <FontAwesomeIcon icon={faCheck} /> All
            </Text>
          )}

          {selectSupplyNetwork === radioValues.individual && (
            <ul
              data-testid="show-supply-networks-list"
              className={styles.ShowSupplyNetworksList}
            >
              {selectedSupplyNetworks.map((network) => (
                <li key={network.id}>
                  <span className={styles.AvailabilityLabel}>
                    {network.name}
                  </span>
                  <Button
                    intent="link"
                    data-testid="remove-supply-network"
                    onClick={() => handleRemoveSupplyNetwork(network.id)}
                    icon={TimesIcon}
                    inline
                  />
                </li>
              ))}
            </ul>
          )}

          <Divider />

          <Text size="large" tag="div">
            Professions
          </Text>
          <Text size="small">
            Selected professions for syncing availability:
          </Text>

          {selectProfession === radioValues.all && (
            <Text tag="div">
              <FontAwesomeIcon icon={faCheck} /> All
            </Text>
          )}

          {selectProfession === radioValues.individual && (
            <ul
              data-testid="show-professions-list"
              className={styles.ShowProfessionsList}
            >
              {selectedProfessions.map((profession) => (
                <li key={profession.id}>
                  <span className={styles.AvailabilityLabel}>
                    {profession.name}
                  </span>
                  <Button
                    intent="link"
                    data-testid="remove-profession"
                    onClick={() => handleRemoveProfession(profession.id)}
                    icon={TimesIcon}
                    inline
                  />
                </li>
              ))}
            </ul>
          )}

          {enableAvailabilitySyncPracticeFilter && (
            <>
              <Divider />
              <Text size="large" tag="div">
                Practices
              </Text>
              <Text size="small">
                Selected practices for syncing availability:
              </Text>
              {selectPractice === radioValues.all && (
                <Text tag="div">
                  <FontAwesomeIcon icon={faCheck} /> All
                </Text>
              )}
              {selectPractice === radioValues.individual && (
                <ul
                  data-testid="show-practice-list"
                  className={styles.ShowProfessionsList}
                >
                  {selectedPractices.map((practice) => (
                    <li key={practice.id}>
                      <span className={styles.AvailabilityLabel}>
                        {practice.name}
                      </span>
                      <Button
                        intent="link"
                        data-testid="remove-practice"
                        onClick={() => handleRemovePractice(practice.id)}
                        icon={TimesIcon}
                        inline
                      />
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </Card>
        <SyncStatus
          autoSyncEnabled={autoSyncEnabled}
          overlayHidden={autoSyncEnabled || !reviewDisabled}
          startDate={startDate}
          endDate={endDate}
          clinicians={selectedClinicians}
          supplyNetworks={selectedSupplyNetworks}
          professions={selectedProfessions}
          afterSyncCreated={toggleUpdateListing}
          afterSyncUpdated={toggleUpdateListing}
          selectClinician={selectClinician}
          selectSupplyNetwork={selectSupplyNetwork}
          selectProfession={selectProfession}
          practices={selectedPractices}
          selectPractice={
            enableAvailabilitySyncPracticeFilter && selectPractice
          }
        />
      </Grid>

      <hr className={styles.ListingSeparator} />

      <AvailabilityListing update={updateListing} />
    </Page>
  )
}

export default AvailabilitySyncPage
