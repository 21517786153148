import React from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

const DetailsView = (props) => {
  const { data, selected, ItemView, idName, toolbar, details } = props
  const actualIdName = idName || 'id'

  return (
    <div className={cx(styles.detailsView, 'widget-border')}>
      <div className="list-wrapper">
        <ul>
          {data.map((item) => {
            const id = item[actualIdName]

            return (
              <ItemView
                key={id}
                {...props}
                item={item}
                id={id}
                className={cx({ selected: selected === item })}
              />
            )
          })}
        </ul>
        <div className="toolbar-wrapper">{toolbar}</div>
      </div>
      <div className="details-wrapper">{details}</div>
    </div>
  )
}

export default DetailsView
