/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type FindPracticesQueryVariables = Types.Exact<{
  filter?: Types.Maybe<Types.Scalars['String']>
}>

export type FindPracticesQuery = {
  practicesByFilter: Array<Pick<Types.Practice, 'id' | 'name'>>
}

export const FindPracticesDocument = gql`
  query FindPractices($filter: String) {
    practicesByFilter(filter: $filter) {
      id
      name
    }
  }
`
export function useFindPracticesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindPracticesQuery,
    FindPracticesQueryVariables
  >
) {
  return Apollo.useQuery<FindPracticesQuery, FindPracticesQueryVariables>(
    FindPracticesDocument,
    baseOptions
  )
}
export function useFindPracticesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPracticesQuery,
    FindPracticesQueryVariables
  >
) {
  return Apollo.useLazyQuery<FindPracticesQuery, FindPracticesQueryVariables>(
    FindPracticesDocument,
    baseOptions
  )
}
export type FindPracticesQueryHookResult = ReturnType<
  typeof useFindPracticesQuery
>
export type FindPracticesLazyQueryHookResult = ReturnType<
  typeof useFindPracticesLazyQuery
>
export type FindPracticesQueryResult = Apollo.QueryResult<
  FindPracticesQuery,
  FindPracticesQueryVariables
>
