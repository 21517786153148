import { useQuery } from '@apollo/client'
import {
  ClinicianProfileQueryResponse,
  CLINICIAN_PROFILE_QUERY,
  PortalLoginProfileQueryResponse,
  PORTAL_LOGIN_PROFILE_QUERY,
} from './queries'

const useAgentName = (id: string, isClinician: boolean) => {
  const portalLogin = useQuery<PortalLoginProfileQueryResponse>(
    PORTAL_LOGIN_PROFILE_QUERY,
    {
      fetchPolicy: isClinician ? 'standby' : 'network-only',
      variables: { id },
    }
  )

  const clinician = useQuery<ClinicianProfileQueryResponse>(
    CLINICIAN_PROFILE_QUERY,
    {
      fetchPolicy: isClinician ? 'network-only' : 'standby',
    }
  )

  if (isClinician) {
    return clinician.data?.viewer?.consultant?.name
  }

  return portalLogin.data?.portalUser?.fullName
}

export default useAgentName
