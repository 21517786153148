import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useLocation } from 'react-router-dom'
import { QUERY_GET_FILTERED_PHARMACIES } from './apollo/queries'
import { EDIT_PHARMACY } from '@/routes/Pharmacies/apollo/mutations'
import messages from './messages'
import {
  HealthcareServiceEdge,
  Location,
  PhoneNumberContactPoint,
  HealthgraphAddress,
  ContactPointSystem,
} from '@/gql/graphql'
import { HealthGraphIdentifierSystem } from '@/routes/Common/enums'
import {
  EditObjectConfig,
  EditObjectConfigField,
} from '@/components/DataTableFromGQL/types'
import { PharmacyView } from './PharmacyView'
import { PharmaciesDataGrid } from './PharmaciesDataGrid'

const getAddressString = (item: HealthcareServiceEdge): string => {
  const healthGraphAddress: HealthgraphAddress | undefined = (item.node
    ?.locations?.nodes?.[0] as Location).address as HealthgraphAddress
  return (
    (healthGraphAddress?.lines?.nodes?.join(',') || '') +
    (healthGraphAddress?.postalCode
      ? `, ${healthGraphAddress?.postalCode}`
      : '')
  )
}

const convertHealthcareServiceEdgeToPharmacy = (
  item: HealthcareServiceEdge
) => {
  return {
    id: item.node?.id as string,
    coreRubyId: (item.node?.identifiers?.nodes?.find(
      (identifier) =>
        identifier?.system ===
        HealthGraphIdentifierSystem.PHARMACY_HEALTHCARE_SERVICE_CORE_RUBY_ID
    )?.value as unknown) as number,
    blocked: !item.node?.isActive,
    block_reason: item.node?.comment as string,
    name: item.node?.name as string,
    phone_number: (item.node?.telecoms?.nodes.find(
      (telecoms) => telecoms?.system === ContactPointSystem.Phone
    ) as PhoneNumberContactPoint)?.phoneNumber as string,
    fax_number: (item.node?.telecoms?.nodes.find(
      (telecoms) => telecoms?.system === ContactPointSystem.Fax
    ) as PhoneNumberContactPoint)?.phoneNumber as string,
    address: getAddressString(item),
    post_code: ((item.node?.locations?.nodes?.[0] as Location)
      .address as HealthgraphAddress)?.postalCode as string,
    delivery_type:
      item.node?.referralMethods?.nodes[0]?.codings?.nodes[0]?.code === 'elec'
        ? 'online'
        : 'fax',
  }
}

export const Pharmacies = () => {
  const fm = useFormatMessage()
  const location = useLocation()
  const currentPath = location.pathname

  const editFields: EditObjectConfigField[] = [
    {
      name: 'blocked',
      type: 'checkbox',
      label: fm(messages.blocked),
    },
    {
      name: 'block_reason',
      type: 'text',
      label: fm(messages.blockedReason),
    },
    {
      name: 'delivery_type',
      type: 'radio',
      label: fm(messages.deliveryType),
      options: [
        {
          label: fm(messages.fax),
          value: 'fax',
        },
        {
          label: fm(messages.online),
          value: 'online',
        },
      ],
    },
    {
      name: 'fax_number',
      type: 'text',
      label: fm(messages.faxNumber),
    },
    {
      name: 'coreRubyId',
      hidden: true,
    },
    {
      name: 'id',
      hidden: true,
    },
  ]

  const editConfig: EditObjectConfig = {
    mutation: EDIT_PHARMACY,
    fields: editFields,
    headings: {
      header: 'name',
      subHeaders: ['address'],
    },
    mutationInputFormatter: (inputObject: any) => {
      return {
        id: inputObject.id,
        isActive: !inputObject.blocked,
        comment: inputObject.block_reason,
        fax: inputObject.fax_number,
        referralMethod: inputObject.delivery_type,
        coreRubyId: inputObject.coreRubyId,
      }
    },
  }

  return (
    <>
      {currentPath === '/admin/pharmacies_v2' ||
      currentPath === '/admin/pharmacies' ? (
        <PharmaciesDataGrid
          query={QUERY_GET_FILTERED_PHARMACIES}
          healthcareServiceDataConverter={
            convertHealthcareServiceEdgeToPharmacy
          }
          editConfig={editConfig}
        />
      ) : (
        <PharmacyView
          query={QUERY_GET_FILTERED_PHARMACIES}
          healthcareServiceDataConverter={
            convertHealthcareServiceEdgeToPharmacy
          }
          editConfig={editConfig}
        />
      )}
    </>
  )
}
