import React, { useCallback, useMemo, useRef, useState } from 'react'
import ReactTextareaAutosize from 'react-textarea-autosize'
import toast from 'react-hot-toast'
import { envFlag } from '@babylon/babylon-env'
import {
  useAtlas,
  useAtlasEvent,
  useConversationState,
} from '@babylon/atlas.js'
import styles from './styles.module.css'
import SendSVG from './SendSVG'
import debounce from './debounce'
import UploadSVG from './UploadSVG'
import { AttachmentMessage } from '../Attachment/AttachmentUploadingMessage'

type Props = {
  conversationId: string
  onSendMessage: (input: string, hasAttachment?: boolean) => void
  startTyping: () => void
  endTyping: () => void
  attachments?: ReturnType<ReturnType<typeof useAtlas>['attachments']>
  attachmentsOwner: string | undefined
  updateAttachmentMessage: (message: AttachmentMessage) => void
  disabled?: boolean
}

const TYPING_END_DELAY = 1000
const ALLOWED_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/bmp',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const ChatFooter = ({
  conversationId,
  onSendMessage,
  startTyping,
  endTyping,
  attachments,
  attachmentsOwner,
  updateAttachmentMessage,
  disabled = false,
}: Props) => {
  const [input, setInput] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const { isLoading, attachmentIsLoading } = useConversationState(
    conversationId
  )

  useAtlasEvent('AttachmentStatusUpdated', conversationId, (data) => {
    switch (data.type) {
      case 'UploadStarted':
        updateAttachmentMessage({
          uploading: true,
          fileName: '',
        })

        break
      case 'UploadFinished':
        onSendMessage(`[${data.name}](${data.url})`, true)
        updateAttachmentMessage({
          uploading: false,
          fileName: data.name,
        })

        break
      case 'UploadFailed': {
        let errorMessage = 'File upload failed.'

        try {
          errorMessage = `${errorMessage} ${data.error.response.data.detail[0].msg}`
        } catch {
          errorMessage = `${errorMessage} Please try again`
        }

        toast.error(errorMessage)
        updateAttachmentMessage({
          uploading: false,
        })
      }
    }
  })
  const handleFileChange = async (files: FileList) => {
    if (!attachments) {
      return
    }

    if (!attachmentsOwner) {
      throw new Error('Attachments owner not set')
    }

    if (!files || !files[0]) {
      throw new Error('Select a file to upload')
    }

    await attachments.upload(files[0], files[0].name, attachmentsOwner, {})
  }

  const debouncedEndTyping = useMemo(
    () => debounce(endTyping, TYPING_END_DELAY),
    [endTyping]
  )

  const type = (value: string) => {
    setInput(value)
    startTyping()
    debouncedEndTyping()
  }

  const send = useCallback(() => {
    onSendMessage(input)
    setInput('')
  }, [input, setInput, onSendMessage])

  const attachmentEnabled = !!envFlag(
    'ENABLE_ATTACHMENTS_IN_LIVE_CONVERSATIONS'
  )

  return (
    <div className={styles.chatFooter}>
      {attachmentEnabled && (
        <>
          <input
            type="file"
            ref={fileInputRef}
            accept={ALLOWED_FILE_TYPES.join(',')}
            style={{ display: 'none' }}
            onChange={(e) => e.target.files && handleFileChange(e.target.files)}
          />
          <button
            className={styles.attachmentButton}
            type="button"
            disabled={disabled || attachmentIsLoading || isLoading}
            onClick={() => fileInputRef.current?.click()}
          >
            <UploadSVG />
          </button>
        </>
      )}
      <ReactTextareaAutosize
        disabled={disabled}
        maxRows={4}
        minRows={1}
        className={styles.chatInput}
        placeholder="Type your message here"
        onChange={(e) => {
          type(e.currentTarget.value)
        }}
        value={input}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()

            if (input.length > 0) {
              send()
            }
          }
        }}
      />
      <button
        className={styles.sendButton}
        type="button"
        disabled={!input || disabled}
        onClick={send}
      >
        <SendSVG />
      </button>
    </div>
  )
}

export default ChatFooter
