import { gql } from '@apollo/client'

export const RESEND_INVITATION = gql`
  mutation AP_ResendInvitation($id: ID!) {
    resendInvitation(id: $id) {
      id
    }
  }
`
export const TRANSFER_OWNERSHIP = gql`
  mutation AP_TransferOwnership($newOwnerId: ID!, $oldOwnerId: ID!) {
    transferOwnership(new_owner_id: $newOwnerId, old_owner_id: $oldOwnerId) {
      new_owner_id
    }
  }
`
