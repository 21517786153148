import { gql } from '@apollo/client'
import { ConsentDecision } from '../../../types'

export const DELETE_PATIENT_CHECK_GQL = gql`
  mutation AP_DeletePatient($patientId: Int!) {
    deletePatient(id: $patientId) {
      message
    }
  }
`

export const RECREATE_USER_GQL = gql`
  mutation RecreateUser($input: RecreateUserInput!) {
    recreateUser(input: $input) {
      ... on ResourceNotFoundError {
        errorMessage
        resourceTypename
        unknownId
      }
    }
  }
`

export const RESET_PASSWORD_BY_EMAIL_GQL = gql`
  mutation Mutation($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      ... on ResourceNotFoundError {
        errorMessage
      }
    }
  }
`

export const OVERRIDE_IDENTITY_CHECK_GQL = gql`
  mutation AP_OverrideIdentityCheck($patientId: ID!, $identityCheckId: ID) {
    overrideIdentityCheck(
      patientId: $patientId
      identityCheckId: $identityCheckId
    ) {
      id
      status
    }
  }
`

export const MFA_GQL = gql`
  query GetUser($id: ID!, $first: NonNegativeInt!) {
    user(id: $id) {
      ... on User {
        id
        isMFAActive
        firstName
        lastName
        mfaEnrollments(first: $first) {
          edges {
            node {
              id
              type
              setupUrl
              status
            }
          }
        }
      }
      ... on ResourceNotFoundError {
        errorMessage
        resourceTypename
        unknownId
      }
    }
  }
`
export const DELETE_MFA_ENROLLMENT = gql`
  mutation deleteMFAEnrollment($id: ID!) {
    deleteMFAEnrollment(id: $id) {
      ... on MFAEnrollment {
        id
        type
        setupUrl
        status
      }
      ... on ResourceNotFoundError {
        errorMessage
        resourceTypename
        unknownId
      }
    }
  }
`

export const LEGAL_CONSENTS_DECISIONS_QUERY = gql`
  query AP_Legal_Consents_Decisions($patientUUID: ID!) {
    informedConsent: memberDecisionForNotice(
      input: {
        patientUUID: $patientUUID
        appName: BABYLON_US
        noticeEnum: INFORMED_CONSENT
      }
    ) {
      decisionId
      accepted
      expired
      date
    }

    medicationHistory: memberDecisionForNotice(
      input: {
        patientUUID: $patientUUID
        appName: BABYLON_US
        noticeEnum: MEDICATION_HISTORY_AUTHORIZATION
      }
    ) {
      decisionId
      accepted
      expired
      date
    }
  }
`

export const SEND_REGISTRATION_EMAIL = gql`
  mutation Mutation($input: RegisterPatientInput!) {
    registerPatient(input: $input) {
      ... on Patient {
        id
      }
      ... on UnrecoverableError {
        resourceTypename
        errorMessage
        unknownId
      }
      ... on EmailTakenError {
        errorMessage
      }
    }
  }
`

export type LegalConsentsDecisionsQueryVariables = {
  patientUUID: string
}

export type LegalConsentsDecisions = {
  informedConsent?: ConsentDecision
  medicationHistory?: ConsentDecision
}
