import React from 'react'
import { Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import messages from './ServiceTypeInfo.messages'

interface FormActions {
  editing: boolean
  isNew?: boolean
  onReset: () => void
  onEdit: () => void
  onCancel: () => void
}

export default function ServiceTypeFormActions({
  editing,
  onReset: handleReset,
  onEdit: handleEdit,
  onCancel: handleCancel,
  isNew,
}: FormActions) {
  const fm = useFormatMessage()

  if (!editing) {
    return (
      <Button inline intent="secondary" onClick={handleEdit}>
        {fm(messages.edit)}
      </Button>
    )
  }

  return (
    <>
      <Button
        inline
        intent="secondary"
        onClick={() => {
          handleReset()
          handleCancel()
        }}
      >
        {fm(messages.cancel)}
      </Button>
      <Button inline intent="primary" type="submit">
        {isNew ? fm(messages.create) : fm(messages.save)}
      </Button>
    </>
  )
}
