import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { getSelectedVal } from './InputFilter.utils'

export type Option = {
  name: string
  [key: string]: string
}

type Props = {
  label: string
  readOnly: boolean
  multiple: boolean
  defaultValue?: any[]
  options: Option[]
  loading: boolean
  onSelectedValuesChange: (values: Option[]) => void
  onInputSearchChange?: (value: string) => void
}

const InputFilter = ({
  label,
  readOnly,
  multiple,
  defaultValue,
  options,
  loading,
  onSelectedValuesChange,
  onInputSearchChange = () => {},
}: Props) => (
  <Autocomplete
    multiple={multiple}
    readOnly={readOnly}
    filterSelectedOptions
    sx={{ width: 400 }}
    getOptionLabel={(option) => option?.name || ''}
    options={options}
    loading={loading}
    onInputChange={(_, v) => onInputSearchChange(v)}
    onChange={(_, selectedVal) => {
      onSelectedValuesChange(getSelectedVal(multiple, selectedVal))
    }}
    defaultValue={defaultValue}
    renderOption={(props, option) => (
      <li {...props} key={option.id || option.uuid}>
        {option.name}
      </li>
    )}
    renderInput={(params) => (
      <TextField
        {...params}
        key={params.id}
        variant="outlined"
        label={label}
        InputProps={{
          ...params.InputProps,
          style: { minHeight: 50 },
          endAdornment: (
            <>
              {loading ? <CircularProgress size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
  />
)
export default InputFilter
