import React from 'react'
import { FormattedMessage } from 'react-intl'
import { WorkflowTaskVariable } from '@babylon/graphql-middleware-types'
import Unknown from '../../../Unknown'
import { getFormattedDateTime, getVariableValue } from '../../utils'
import messages from './WorkflowCard.messages'
import styles from './styles.module.scss'
import { Maybe } from '../../../types'

export interface DetailsProps {
  definitionKey: Maybe<string>
  variables: Maybe<Array<Maybe<WorkflowTaskVariable>>>
  show: boolean
  locale: string
}

const Details = ({
  definitionKey,
  variables = [],
  show = false,
  locale,
}: DetailsProps) => {
  if (!show) {
    return null
  }

  if (definitionKey === 'weightManagement') {
    const lastPaymentDate = getVariableValue('lastPaymentDate')(variables)
    const lastPaymentDateValidUntil = getVariableValue(
      'lastPaymentDateValidUntil'
    )(variables)
    const memberSince = getVariableValue('processStartDate')(variables)
    const latestProctorCallTimestamp = getVariableValue(
      'latestProctorCallTimestamp'
    )(variables)
    const proctorCallTimeoutDueDate = getVariableValue(
      'proctorCallTimeoutDueDate'
    )(variables)
    const prescriptionDrugDosage = getVariableValue('prescriptionDrugDosage')(
      variables
    )
    const prescriptionNumberOfDoses = getVariableValue(
      'prescriptionNumberOfDoses'
    )(variables)
    const numberOfProctorCallsReceived = getVariableValue(
      'numberOfProctorCallsReceived'
    )(variables)
    const prescriptionApprovalsCount = getVariableValue(
      'prescriptionApprovalsCount'
    )(variables)
    const prescriptionClinicianName = getVariableValue(
      'prescriptionClinicianName'
    )(variables)
    const numberOfInjectionsTakenSinceLastPrescription = getVariableValue(
      'numberOfInjectionsTakenSinceLastPrescription'
    )(variables)
    const prescriptionDateTimeString = getVariableValue(
      'prescriptionDateTimeString'
    )(variables)
    const latestProctorCallSessionType = getVariableValue(
      'latestProctorCallSessionType'
    )(variables)
    const latestProctorCallProctorName1 = getVariableValue(
      'latestProctorCallProctorName1'
    )(variables)
    const braze_event_name = getVariableValue('braze_event_name')(variables)

    const freeMembershipLabel = getVariableValue('skipPaymentCheck')(
      variables
    ) === '1' && <FormattedMessage {...messages.free_membership} />

    // Camunda uses a date far out in the future to indicate no check in due
    const TEN_YEARS_IN_MS = 315360000000
    const NOW = new Date()

    return (
      <div
        className={styles.Details__section}
        data-testid="timeline-card-more-details"
      >
        {/* Column One */}
        <div>
          <label className={styles.Details__label}>
            <FormattedMessage {...messages.weekly_check_in_due_date} />
          </label>
          <div
            className={styles.Details__value}
            data-testid="timeline-card-weekly-check-in-due-date"
          >
            {(proctorCallTimeoutDueDate &&
              proctorCallTimeoutDueDate - NOW < TEN_YEARS_IN_MS &&
              getFormattedDateTime({
                date: proctorCallTimeoutDueDate,
                locale,
                isIso: true,
              })) || <Unknown />}
          </div>
          <label className={styles.Details__label}>
            <FormattedMessage {...messages.last_payment_date} />
          </label>
          <div className={styles.Details__value}>
            <div data-testid="timeline-card-last-payment-date">
              {freeMembershipLabel ||
                (lastPaymentDate &&
                  getFormattedDateTime({
                    date: lastPaymentDate,
                    locale,
                    isIso: true,
                  })) || <Unknown />}
            </div>
            {lastPaymentDateValidUntil && (
              <div
                className={styles.Details__additionalDetails}
                data-testid="timeline-card-last-payment-date-valid-until"
              >
                <FormattedMessage
                  {...messages.valid_until}
                  values={{
                    validity: getFormattedDateTime({
                      date: lastPaymentDateValidUntil,
                      locale,
                      isIso: true,
                    }),
                  }}
                />
              </div>
            )}
          </div>
          <label className={styles.Details__label}>
            <FormattedMessage {...messages.programme_member_since} />
          </label>
          <div
            className={styles.Details__value}
            data-testid="timeline-card-programme-member-since"
          >
            {(memberSince &&
              getFormattedDateTime({
                date: memberSince,
                locale,
                isIso: true,
              })) || <Unknown />}
          </div>
        </div>

        {/* Column Two */}
        <div>
          <label
            className={styles.Details__label}
            data-testid="timeline-card-prescription-approvals-count"
          >
            <FormattedMessage
              {...messages.prescriptions_issued}
              values={{ total: prescriptionApprovalsCount }}
            />
          </label>
          <div className={styles.Details__value}>
            <div data-testid="timeline-card-prescription-date">
              <FormattedMessage {...messages.latest} />{' '}
              {prescriptionDateTimeString}
            </div>

            {prescriptionDrugDosage && (
              <div
                className={styles.Details__additionalDetails}
                data-testid="timeline-card-prescription-details"
              >
                <FormattedMessage
                  {...messages.doses}
                  values={{
                    doses: prescriptionNumberOfDoses,
                    strength: prescriptionDrugDosage,
                    clinician: prescriptionClinicianName,
                  }}
                />
              </div>
            )}

            <div data-testid="timeline-card-prescription-doses-used-up">
              <FormattedMessage
                {...messages.used_up}
                values={{ doses: numberOfInjectionsTakenSinceLastPrescription }}
              />
            </div>
          </div>

          <div>
            <label
              className={styles.Details__label}
              data-testid="timeline-card-number-of-proctor-calls"
            >
              <FormattedMessage
                {...messages.proctor_calls}
                values={{ total: numberOfProctorCallsReceived }}
              />
            </label>
          </div>

          <div className={styles.Details__value}>
            <div data-testid="timeline-card-proctor-calls-latest">
              <FormattedMessage {...messages.latest} />{' '}
              {latestProctorCallTimestamp &&
                getFormattedDateTime({
                  date: latestProctorCallTimestamp,
                  locale,
                  isIso: true,
                })}
            </div>
            <div
              className={styles.Details__additionalDetails}
              data-testid="timeline-card-proctor-call-details"
            >
              {latestProctorCallSessionType || <Unknown />},{' '}
              {latestProctorCallProctorName1}
            </div>
          </div>
          <label className={styles.Details__label}>
            <FormattedMessage {...messages.latest_email} />
          </label>
          <div
            className={styles.Details__value}
            data-testid="timeline-card-last-email"
          >
            {braze_event_name || <Unknown />}
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default Details
