import React, { ReactNode } from 'react'
import classnames from 'classnames'
import { PhoneDownFilled, VideoFilled } from '@babylon/icons'
import FaceToFaceIcon from './icons/FaceToFaceIcon'
import TextConsultationIcon from './icons/TextConsultationIcon'
import styles from './medium-icon.module.scss'
import { Medium } from '../types'

export interface IconProps {
  icon?: ReactNode
  medium?: Medium
  isWarning?: boolean
}

const MediumIcon = ({ icon, medium, isWarning }: IconProps) => {
  const styleModule = 'MediumIcon'

  const containerClassName = classnames(styles[`${styleModule}__medium`], {
    [styles[`${styleModule}__medium--warning`]]: isWarning,
  })
  const wrapperClassName = classnames(styles[`${styleModule}__iconWrapper`], {
    [styles[`${styleModule}__iconWrapper--warning`]]: isWarning,
  })
  const className = classnames(styles[`${styleModule}__icon`], {
    [styles[`${styleModule}__icon--warning`]]: isWarning,
  })

  const getIcon = () => {
    if (React.isValidElement(icon)) {
      return React.cloneElement(icon, { className })
    }

    switch (medium) {
      case 'physical': {
        return <FaceToFaceIcon className={className} />
      }
      case 'video': {
        return <VideoFilled className={className} />
      }
      case 'voice': {
        return <PhoneDownFilled className={className} />
      }
      case 'text': {
        return <TextConsultationIcon className={className} />
      }
      default: {
        return null
      }
    }
  }

  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>{getIcon()}</div>
    </div>
  )
}

export default MediumIcon
