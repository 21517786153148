import { gql } from '@apollo/client'

export default gql`
  query FindClinicians($filters: ConsultantSearchFilters!) {
    getConsultants(filters: $filters) {
      id
      full_name
      role
    }
  }
`
