export const convertMinutesToWaitTimeStatusThresholdMap = (
  waitTimeStatusThresholdInMinutes
) => {
  const waitTimeStatusThreshldMap = new Map<string, number>()
  waitTimeStatusThreshldMap.set(
    'days',
    Math.floor(waitTimeStatusThresholdInMinutes / (60 * 24))
  )
  waitTimeStatusThreshldMap.set(
    'hours',
    Math.floor((waitTimeStatusThresholdInMinutes % (60 * 24)) / 60)
  )
  waitTimeStatusThreshldMap.set(
    'minutes',
    Math.floor(waitTimeStatusThresholdInMinutes % 60)
  )

  return waitTimeStatusThreshldMap
}

export const filterWaitTimeStautsThresholdByType = (
  waitTimeStatusThresholds,
  type
) =>
  waitTimeStatusThresholds.find(
    (waitTimeStatusThreshold) => waitTimeStatusThreshold.type === type
  )

export const formatWaitTimeStatusThreshold = (waitTimeStatusThreshldMap) =>
  `${waitTimeStatusThreshldMap.get('days')}d` +
  `:${waitTimeStatusThreshldMap.get('hours')}h` +
  `:${waitTimeStatusThreshldMap.get('minutes')}m`
