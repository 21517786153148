import { defineMessages } from 'react-intl'

const baseId = 'transcript_summary'

export default defineMessages({
  started: {
    id: `${baseId}.started`,
    defaultMessage: 'Started',
  },
  ended: {
    id: `${baseId}.ended`,
    defaultMessage: 'Ended',
  },
  patient: {
    id: `${baseId}.patient`,
    defaultMessage: 'Patient',
  },
  agents: {
    id: `${baseId}.agents`,
    defaultMessage: 'Agent(s)',
  },
  babylonId: {
    id: `${baseId}.babylon_id`,
    defaultMessage: 'Babylon ID',
  },
})
