import React from 'react'
import cn from 'classnames'
import { format, parseISO } from 'date-fns'
import { ArrowRight } from '@babylon/icons'
import Covid19Tags from '../Conversations/Covid19Tags'
import SomeoneElseTag from '../Conversations/SomeoneElseTag'
import styles from './Entry.module.scss'
import { Entry as EntryType } from '../types'

export interface EntryProps extends EntryType {
  'data-testid'?: string
  onClick?: () => void
}

export default function Entry({
  'data-testid': dataTestId = 'entry',
  text,
  time,
  tag,
  onClick,
  userUuid,
  patientUuid,
}: EntryProps) {
  const isClickable = Boolean(onClick)
  const WrapperComponent = isClickable ? 'button' : 'div'
  const wrapperClassName = cn(styles.Entry__wrapper, {
    [styles['Entry__wrapper--btn']]: isClickable,
  })

  return (
    <div className={styles.Entry} data-testid={dataTestId}>
      <div className={styles.Entry__time}>
        {format(parseISO(time), 'dd MMM yyyy, HH:mm')}
      </div>
      <WrapperComponent
        data-testid={`${dataTestId}__container`}
        className={wrapperClassName}
        type={isClickable ? 'button' : undefined}
        onClick={isClickable ? onClick : undefined}
      >
        {tag && <Covid19Tags data-testid="entry-tag" tag={tag} />}
        <SomeoneElseTag
          data-testid="someone-else-tag"
          userUuid={userUuid}
          patientUuid={patientUuid}
        />
        <div className={styles.Entry__preview}>{text}</div>
        {onClick && <ArrowRight />}
      </WrapperComponent>
    </div>
  )
}
