import { defineMessages } from 'react-intl'

const baseId = 'call_list'

export default defineMessages({
  duration_in_sec: {
    id: `${baseId}.duration_in_sec`,
    defaultMessage:
      '{minutes}:{seconds, plural, =0 {} one {{seconds} sec} other {{seconds} secs}}',
  },
  no_calls: {
    id: `${baseId}.no_calls`,
    defaultMessage: 'No calls',
  },
})
