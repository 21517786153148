import React from 'react'
import { useFormikContext, FormikValues } from 'formik'
import { useHistory } from 'react-router-dom'
import { useFormatMessage } from '@babylon/intl'
import { ButtonVariant, ButtonSimple } from '@babylon/medkit'
import messages from '../DigitalAppointmentForm.messages'

interface BookForPatientProps {
  memberId: string
  professions: Profession[]
  onClickTrack?: () => void
}

export default function BookForPatient({
  memberId,
  professions = [],
  onClickTrack = () => {},
}: BookForPatientProps) {
  const { values, validateForm, submitForm } = useFormikContext<FormikValues>()
  const history = useHistory()
  const fm = useFormatMessage()

  const selectedProfession = professions.find(
    ({ id }) => id === values.appointment_type
  )

  const filters = {
    allowed_professions: [selectedProfession?.key],
    consultant_type: selectedProfession?.key?.toUpperCase(),
    appointment_reason: values.notes_for_member,
    duration_minutes: values.duration_minutes,
  }

  const handleClick = async () => {
    const errors = await validateForm()

    onClickTrack()

    if (Object.keys(errors).length === 0) {
      history.push(`/admin/patients/${memberId}/schedule-appointment`, {
        filters,
        originalFilters: values,
      })
    } else {
      submitForm()
    }
  }

  return (
    <ButtonSimple
      onClick={() => {
        handleClick()
      }}
      variant={ButtonVariant.secondary}
    >
      {fm(messages.book_btn)}
    </ButtonSimple>
  )
}
