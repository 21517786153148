import React from 'react'
import { TimezoneDate } from '../../TimezoneDate'
import styles from './Session.module.css'

export interface SessionProps {
  timezone: string
  detailText: string
  time: string
}

const Session = ({ timezone, detailText, time }: SessionProps) => (
  <article className={styles.sessionInfoBlock}>
    <div className={styles.sessionInfoTime}>
      <TimezoneDate date={time} timezone={timezone} format="HH:mm" />
    </div>
    <div className={styles.sessionInfoDetailContainer}>
      <header className={styles.sessionInfoDetails}>{detailText}</header>
    </div>
  </article>
)
export default Session
