import { gql } from '@apollo/client'

export const UPDATE_SUPPLY_NETWORK = gql`
  mutation UpdateSupplyNetwork($input: UpdateSupplyNetworkInput!) {
    updateSupplyNetwork(input: $input) {
      ... on SupplyNetwork {
        activeFeatureSwitches {
          edges {
            cursor
            node
          }
          totalCount
        }
        id
        name
        priority
        region
      }
    }
  }
`
export const CREATE_SUPPLY_NETWORK = gql`
  mutation CreateSupplyNetwork($input: CreateSupplyNetworkInput!) {
    createSupplyNetwork(input: $input) {
      ... on SupplyNetwork {
        activeFeatureSwitches {
          edges {
            cursor
            node
          }
          totalCount
        }
        id
        name
        priority
        region
      }
      ... on CreateSupplyNetworkError {
        errorMessage
      }
    }
  }
`
