import React from 'react'
import { Grid } from '@babylon/core-ui'

import { LoadingHeader, LoadingBlock } from '../../../..'
import styles from './CommentsCard.module.scss'

const LoadingCommentsCard = () => (
  <div data-testid="loading-comments">
    <LoadingHeader />
    <Grid rowGap={16}>
      <LoadingBlock className={styles.loadingBlock} />
      <LoadingBlock className={styles.loadingBlock} />
    </Grid>
  </div>
)

export default LoadingCommentsCard
