import React from 'react'

function NoSearchResultSVG(props) {
  return (
    <svg width={46} height={47} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6 34.95a15.67 15.67 0 01-17.35-3.52A16.52 16.52 0 014.8 13.7 15.91 15.91 0 0119.5 3.67c4.23 0 8.27 1.71 11.26 4.76a16.44 16.44 0 014.66 11.5c0 3.89-1.36 7.55-3.7 10.41L42 40.82c.56.57.56 1.5 0 2.08-.57.57-1.48.57-2.04 0L29.7 32.42a15.83 15.83 0 01-4.1 2.53zm3.08-5.55a12.84 12.84 0 01-18.4-.04 13.53 13.53 0 01-2.82-14.53 13.04 13.04 0 0112.05-8.22c3.45 0 6.77 1.4 9.21 3.9a13.47 13.47 0 013.82 9.42 13.47 13.47 0 01-3.86 9.47zM13.31 13.6a1.5 1.5 0 000 2.07l4.06 4.16-4.06 4.15a1.5 1.5 0 000 2.08c.56.57 1.47.57 2.03 0l4.07-4.15 4.06 4.15c.56.57 1.47.57 2.03 0a1.5 1.5 0 000-2.08l-4.06-4.15 4.06-4.16a1.5 1.5 0 000-2.07 1.42 1.42 0 00-2.03 0l-4.06 4.15-4.07-4.15a1.42 1.42 0 00-2.03 0z"
        fill="#000"
      />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={40}
        height={41}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.6 34.95a15.67 15.67 0 01-17.35-3.52A16.52 16.52 0 014.8 13.7 15.91 15.91 0 0119.5 3.67c4.23 0 8.27 1.71 11.26 4.76a16.44 16.44 0 014.66 11.5c0 3.89-1.36 7.55-3.7 10.41L42 40.82c.56.57.56 1.5 0 2.08-.57.57-1.48.57-2.04 0L29.7 32.42a15.83 15.83 0 01-4.1 2.53zm3.08-5.55a12.84 12.84 0 01-18.4-.04 13.53 13.53 0 01-2.82-14.53 13.04 13.04 0 0112.05-8.22c3.45 0 6.77 1.4 9.21 3.9a13.47 13.47 0 013.82 9.42 13.47 13.47 0 01-3.86 9.47zM13.31 13.6a1.5 1.5 0 000 2.07l4.06 4.16-4.06 4.15a1.5 1.5 0 000 2.08c.56.57 1.47.57 2.03 0l4.07-4.15 4.06 4.15c.56.57 1.47.57 2.03 0a1.5 1.5 0 000-2.08l-4.06-4.15 4.06-4.16a1.5 1.5 0 000-2.07 1.42 1.42 0 00-2.03 0l-4.06 4.15-4.07-4.15a1.42 1.42 0 00-2.03 0z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path fill="#637280" d="M0 0h46v47H0z" />
      </g>
    </svg>
  )
}

const MemoSvgComponent = React.memo(NoSearchResultSVG)

export default MemoSvgComponent
