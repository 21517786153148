import { gql } from '@apollo/client'
import { GetProfessionsFragment } from '../../Professions/GetProfessionsQuery'

export default gql`
  query GetConsultants {
    professions {
      ...professionType
      id
    }
  }
  ${GetProfessionsFragment}
`
