import React from 'react'
import cx from 'classnames'

import { withValue, withOnChange, compose } from '../forms'

const magnifier = '🔎'

const Text = ({
  name,
  value,
  className,
  onChange,
  placeholder,
  icon = magnifier,
}) => (
  <div className={cx('widget search-box border', className)}>
    <input
      name={name}
      type="search"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
    <span>{icon}</span>
  </div>
)

export default compose(withValue, withOnChange)(Text)
