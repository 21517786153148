import React from 'react'

import styles from './styles.module.css'

type Props = {
  sm?: boolean
  md?: boolean
  lg?: boolean
  className?: string
  highlight?: boolean
  underline?: boolean
  ellipsis?: boolean
  bold?: boolean
  inline?: boolean
  style?: React.CSSProperties
  children: React.ReactNode
}

const Text = ({
  sm,
  md,
  lg,
  highlight,
  underline,
  ellipsis,
  bold,
  inline,
  style,
  className,
  children,
}: Props) => {
  const classes = [styles.text]

  if (highlight) {
    classes.push(styles.highlight)
  }

  if (underline) {
    classes.push(styles.underline)
  }

  if (ellipsis) {
    classes.push(styles.ellipsis)
  }

  if (bold) {
    classes.push(styles.bold)
  }

  if (sm) {
    classes.push(styles.sm)
  }

  if (md) {
    classes.push(styles.md)
  }

  if (lg) {
    classes.push(styles.lg)
  }

  if (className) {
    classes.push(className)
  }

  if (inline) {
    return (
      <span className={classes.join(' ')} style={style}>
        {children}
      </span>
    )
  }

  return (
    <div className={classes.join(' ')} style={style}>
      {children}
    </div>
  )
}

export default Text
