/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type ClinicianAvailabilityShiftsQueryVariables = Types.Exact<{
  consultantUuid: Types.Scalars['ID']
  startDate: Types.Scalars['Date']
  endDate: Types.Scalars['Date']
}>

export type ClinicianAvailabilityShiftsQuery = {
  clinicianAvailabilityShifts: {
    items: Array<
      Pick<
        Types.ClinicianAvailabilityShift,
        'id' | 'shift_type' | 'shift_start_time' | 'shift_end_time'
      >
    >
  }
}

export const ClinicianAvailabilityShiftsDocument = gql`
  query ClinicianAvailabilityShifts(
    $consultantUuid: ID!
    $startDate: Date!
    $endDate: Date!
  ) {
    clinicianAvailabilityShifts(
      consultantId: $consultantUuid
      startDate: $startDate
      endDate: $endDate
    ) {
      items {
        id
        shift_type
        shift_start_time
        shift_end_time
      }
    }
  }
`
export function useClinicianAvailabilityShiftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClinicianAvailabilityShiftsQuery,
    ClinicianAvailabilityShiftsQueryVariables
  >
) {
  return Apollo.useQuery<
    ClinicianAvailabilityShiftsQuery,
    ClinicianAvailabilityShiftsQueryVariables
  >(ClinicianAvailabilityShiftsDocument, baseOptions)
}
export function useClinicianAvailabilityShiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClinicianAvailabilityShiftsQuery,
    ClinicianAvailabilityShiftsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ClinicianAvailabilityShiftsQuery,
    ClinicianAvailabilityShiftsQueryVariables
  >(ClinicianAvailabilityShiftsDocument, baseOptions)
}
export type ClinicianAvailabilityShiftsQueryHookResult = ReturnType<
  typeof useClinicianAvailabilityShiftsQuery
>
export type ClinicianAvailabilityShiftsLazyQueryHookResult = ReturnType<
  typeof useClinicianAvailabilityShiftsLazyQuery
>
export type ClinicianAvailabilityShiftsQueryResult = Apollo.QueryResult<
  ClinicianAvailabilityShiftsQuery,
  ClinicianAvailabilityShiftsQueryVariables
>
