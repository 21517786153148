import { gql } from '@apollo/client'

export const fetchAlertsGQL = gql`
  query AP_Alerts($patientId: ID!, $sortOptions: PatientAlertsSortOptions) {
    patient_alerts(patientId: $patientId, sortOptions: $sortOptions) {
      id
      message
      updatedAt
      updatedByName
    }
  }
`
export const addAlertGQL = gql`
  mutation AP_AddAlert($patientId: ID!, $description: String!) {
    addAlert(patientId: $patientId, description: $description) {
      message
    }
  }
`
export const removeAlertGQL = gql`
  mutation AP_RemoveAlert($id: ID!, $patientId: ID) {
    removePatientAlert(id: $id, patientId: $patientId)
  }
`
