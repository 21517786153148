import React from 'react'
import { Text, Button } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { Practice } from '@babylon/graphql-middleware-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import messages from './PracticeSearcher.messages'
import styles from './SearchResults.module.css'

export interface SearchResultsProps {
  hasSearched: boolean
  practices: Practice[]
  onSelect: (id: string, name: string | null | undefined) => void
}

export default function SearchResults({
  hasSearched,
  practices,
  onSelect,
}: SearchResultsProps) {
  const fm = useFormatMessage()

  if (!practices?.length && hasSearched) {
    return (
      <div className={styles.SearchNoResult}>
        <FontAwesomeIcon icon={faSearch} /> {fm(messages.no_results_found)}
      </div>
    )
  }

  return (
    <ul className={styles.ChooseList}>
      {practices.map((practice) => (
        <li key={practice.id} className={styles.ChooseListItem}>
          <Button
            onClick={() => onSelect(practice.id, practice.name)}
            className={styles.ChooseListItemButton}
          >
            <Text bold color="grey-type">
              {practice.id}: {practice.name}
            </Text>
          </Button>
        </li>
      ))}
    </ul>
  )
}
