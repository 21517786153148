import { microservicesClient } from '../client'
import { PGMReportInterface } from '../../constants/types'
import { getBaseChatscriptUrl } from '../util/baseUrls'

const getPgmAssessment = ({ conversationId, internal }: Props) => {
  const baseUrl = getBaseChatscriptUrl()

  const url = internal
    ? `${baseUrl}/conversations/${conversationId}/attachment-internal/triage_outcome`
    : `${baseUrl}/conversations/${conversationId}/attachment/triage_outcome`

  return microservicesClient
    .get<Response>(url)
    .then((response) => response.data)
}

interface Props {
  conversationId: string
  internal: boolean
}

type Response = PGMReportInterface

export default getPgmAssessment
