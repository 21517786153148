import { defineMessages } from 'react-intl'

const baseId = 'alert_list'

export default defineMessages({
  error: {
    id: `${baseId}.error`,
    defaultMessage: 'Error!',
  },
  header: {
    id: `${baseId}.header`,
    defaultMessage: 'Alerts',
  },
  view_more: {
    id: `${baseId}.view_more`,
    defaultMessage: 'View all alerts ({count})',
  },
  add_alert: {
    id: `${baseId}.add_alert`,
    defaultMessage: 'Add',
  },
  alert_added: {
    id: `${baseId}.alert_added`,
    defaultMessage: 'Alert added.',
  },
  alert_removed: {
    id: `${baseId}.alert_removed`,
    defaultMessage: 'Alert removed.',
  },
  no_alerts: {
    id: `${baseId}.no_alerts`,
    defaultMessage: 'No alerts',
  },
})
