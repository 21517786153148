import React from 'react'
import { Tag } from '@babylon/core-ui'

type TagType = {
  key: string | number
  name: string | number
}

export interface MultiTagProps {
  tags: TagType[]
  onChange?: (Tag) => void
}

export default function MultiTag({ tags = [], onChange }: MultiTagProps) {
  return (
    <>
      {tags.map(({ key, name }) => (
        <Tag
          data-testid={`${key}-tag`}
          closable
          uppercase
          key={key}
          onClose={() => {
            if (onChange) {
              onChange(tags?.filter(({ key: tagKey }) => tagKey !== key))
            }
          }}
        >
          {name}
        </Tag>
      ))}
    </>
  )
}
