import React, { useState } from 'react'
import { useMemberConversations } from '@babylon/atlas.js'
import { Avatar, Spinner } from '@babylon/medkit'
import { ModalOverlay } from '@babylon/member-operations'
import avatar from './blankAvatar.png'
import { List, ListItem } from '../list'

import { AnimatedButton } from '../button'
import { RelativeDateTimeWithOffset, WithTitle } from '../utils'
import { useLazyAgentName } from '../../hooks/useLazyAgentName'
import { capitalizeFirstLetter, intentFromTags } from '../../utils'
import { ConversationDetail } from '../ConversationDetail'
import styles from './ConversationList.module.css'

interface Props {
  memberId: string
  onlyFinished?: boolean
}

// eslint-disable-next-line import/prefer-default-export
export const ConversationList = ({ memberId, onlyFinished }: Props) => {
  const lazyAgentName = useLazyAgentName()
  const memberConversations = useMemberConversations(memberId)
  const [activeConversation, setActiveConversation] = useState<string | null>(
    null
  )

  if (memberConversations.state === 'loading') {
    return <Spinner variant="primary" />
  }

  if (memberConversations.state === 'error') {
    return <div>Error: {memberConversations.error}</div>
  }

  const getAgent = (conversation: {
    participants: { id: string; type?: 'withProfile' | 'bot' | undefined }[]
  }) => conversation.participants.find(({ id }) => id !== memberId)
  const handleClose = () => setActiveConversation(null)
  const conversations = memberConversations.conversations.filter(
    (conversation) => (onlyFinished ? conversation.state === 'finished' : true)
  )
  function nameFromConversation(conversation: typeof conversations[number]) {
    const agent = getAgent(conversation)

    return lazyAgentName(agent?.id, agent?.type)
  }

  return (
    <>
      <ModalOverlay
        visible={!!activeConversation}
        onRequestClose={handleClose}
        className={styles.modal}
      >
        {activeConversation && (
          <ConversationDetail
            memberId={memberId}
            conversationId={activeConversation}
            onClose={handleClose}
          />
        )}
      </ModalOverlay>
      {conversations.length === 0 ? (
        <p>Member has no conversation history</p>
      ) : (
        <List>
          {conversations.map((conversation) => (
            <ListItem data-testid="conversation-item" key={conversation.id}>
              <Avatar
                description={nameFromConversation(conversation)}
                isRounded
                highlightIcon
                src={avatar}
              />
              <WithTitle
                data-testid={`agent-${getAgent(conversation)?.id}`}
                title={nameFromConversation(conversation)}
                pills={[
                  capitalizeFirstLetter(intentFromTags(conversation.tags)),
                ]}
              >
                <RelativeDateTimeWithOffset
                  datetime={conversation.started_at}
                />
              </WithTitle>
              <AnimatedButton
                onClick={() => {
                  setActiveConversation(conversation.id)
                }}
              >
                View
              </AnimatedButton>
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
}
