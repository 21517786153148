/**
 * Load messages based on locale and add correct react-intl locale data
 *
 * @param {String} locale Locale string (ex: en_GB)
 * @returns {Promise<{ [key: string]: string }>} Messages
 */
export const getMessagesOnLocaleChange = async (locale: string) => {
  try {
    const messagesData = await import(`../messages/${locale}.json`)

    if (messagesData && messagesData.default) {
      return messagesData.default
    }
  } catch (error) {
    console.log(error)
  }

  return {}
}

export type TSupportedLocale = 'en_GB' | 'en_CA' | 'en_US' | 'en_TEST'

export const supportedLocales: TSupportedLocale[] = ['en_GB', 'en_CA', 'en_US']

export const DEFAULT_LOCALE: TSupportedLocale = 'en_GB'
const STORAGE_KEY = 'locale'

const compatibleLocals = navigator.languages.filter(
  (value) => supportedLocales.indexOf(value as any) !== -1
)

export const setLocale = (locale: TSupportedLocale) => {
  localStorage.setItem(STORAGE_KEY, locale)
}

export const getLocale = () =>
  localStorage.getItem(STORAGE_KEY) || compatibleLocals[0] || DEFAULT_LOCALE

export const clearLocale = () => localStorage.removeItem(STORAGE_KEY)
