import { defineMessages } from 'react-intl'

const baseId = 'appointment_wait_time_configs_table'

export default defineMessages({
  consumerNetworkTableHeading: {
    id: `${baseId}.consumer_network_table_heading`,
    defaultMessage: 'Consumer network',
  },
  serviceTypeTableHeading: {
    id: `${baseId}.service_type_table_heading`,
    defaultMessage: 'Service type',
  },
  criticalWaitTimeStatusThresholdTableHeading: {
    id: `${baseId}.critical_wait_time_status_threshold_table_heading`,
    defaultMessage: '40',
  },
  overloadedWaitTimeStatusThresholdTableHeading: {
    id: `${baseId}.overloaded_wait_time_status_threshold_table_heading`,
    defaultMessage: '30',
  },
  busyWaitTimeStatusThresholdTableHeading: {
    id: `${baseId}.busy_wait_time_status_threshold_table_heading`,
    defaultMessage: '20',
  },
  tableTitleLabel: {
    id: `${baseId}.table_title_label`,
    defaultMessage: 'Appointment Wait Time Configs Table',
  },
  updateWaitTimeThresholdButtonTitle: {
    id: `${baseId}.update_wait_time_threshold_button_title`,
    defaultMessage: 'Update',
  },
  deleteWaitTimeThresholdButtonTitle: {
    id: `${baseId}.delete_wait_time_threshold_button_title`,
    defaultMessage: 'Delete',
  },
})
