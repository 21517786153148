import { gql } from '@apollo/client'

export default gql`
  query GetServiceTypeCategories {
    serviceTypeCategories {
      uuid
      name
    }
  }
`
