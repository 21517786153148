import React from 'react'
import cx from 'classnames'

import { Text } from '@babylon/core-ui'

import { Slot } from '../../components'
import ShiftSlotTypes, { ShiftSlotTypeName } from './ShiftSlotTypes'
import ShiftSlotDuration, { ShiftSlotDurationTypes } from './ShiftSlotDuration'
import WARNING_ICONS, { ShiftSlotWarningTypes } from './ShiftSlotWarning'

import styles from './ShiftSlot.module.css'

export interface ShiftSlotProps {
  'data-testid'?: string
  duration?: ShiftSlotDurationTypes
  type: ShiftSlotTypeName
  time: string
  warning?: ShiftSlotWarningTypes | 'none'
}

const ShiftSlot = ({
  'data-testid': dataTestId = 'shift-slot',
  duration = 10,
  type,
  time,
  warning = 'none',
}: ShiftSlotProps) => {
  const ShiftSlotClassName = cx(styles.ShiftSlot, styles[`ShiftSlot${type}`], {
    [styles.ShiftSlotWarning]: warning !== 'none',
  })

  /*
   * Wrapping icon in Text component because FontAwesome gets the defined size from
   * the first parent that has a font-size defined (width is defined in em)
   */
  const WarningIcon = (
    <Text className={styles.ShiftSlotIcon}>{WARNING_ICONS[warning]}</Text>
  )

  return (
    <Slot
      data-testid={dataTestId}
      className={ShiftSlotClassName}
      time={time}
      warning={WarningIcon}
      style={{ height: `${ShiftSlotDuration[duration]}px` }}
    >
      {ShiftSlotTypes[type]}
    </Slot>
  )
}

export default ShiftSlot
