import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { envFlag } from '@babylon/babylon-env'
import * as Access from '@babylon/web-platform-access'
import { Button, Card, Page } from '@babylon/core-ui'

import { useFormatMessage } from '@babylon/intl'
import { UnexpectedError } from '../../Utils'
import { LoadingContainer } from '../../components'
import GetServiceTypes from './GetServiceTypesQuery'
import ServiceTypesList from './ServiceTypesList'
import messages from '../serviceTypes.messages'

type ServiceTypesProps = {
  includingDigital?: boolean
}

const ServiceTypes = ({ includingDigital }: ServiceTypesProps) => {
  const { loading, error, data, refetch } = useQuery(GetServiceTypes, {
    variables: {
      includingDigital,
      include: ['service_type_categories'],
    },
  })
  const fm = useFormatMessage()

  const [isCreateServiceTypeGranted] = Access.useDecide('create-service-type')

  const canCreateServiceType =
    envFlag('MANAGE_SERVICE_TYPE', false) && isCreateServiceTypeGranted

  const handleDelete = useCallback(() => {
    refetch()
  }, [refetch])

  return (
    <LoadingContainer loading={loading} fill>
      <Page breadcrumbs={[fm(messages.header)]}>
        {error && <UnexpectedError message={error.message} visible />}

        {data && (
          <Card
            title={fm(messages.header)}
            actions={
              canCreateServiceType ? (
                <Button href="/admin/service-mix/new">
                  Add new service type
                </Button>
              ) : null
            }
          >
            <ServiceTypesList
              items={data.serviceTypes}
              onDelete={handleDelete}
            />
          </Card>
        )}
      </Page>
    </LoadingContainer>
  )
}

export default ServiceTypes
