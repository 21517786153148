import React from 'react'
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
} from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getCsrfHeaders, logout } from '@babylon/babylon-auth'
import { envUrl, envVar } from '@babylon/babylon-env'
import { TrackingProvider, TealiumService } from '@babylon/tracking/react'
import loggerLink from 'apollo-link-logger'
import { getAuthClient, AuthClient } from '@babylon/web-platform-utils-auth'
import requestIdGenerator from '@/util/requestIdGenerator'
import ConnectionAlert from '@/components/ConnectionAlert'
import AppFrame from '@/components/AppFrame'
import generatedIntrospection from '@/generated/introspection-result'
import LocaleMessageLoader from '../components/LocaleMessageLoader'

let authClient: AuthClient

export const errorInfo = {
  errorCount: 0,
}

const isDevelopment = process.env.NODE_ENV !== 'production'
const ENABLE_NEW_AUTH = envVar('ENABLE_NEW_AUTH') === 'true' // envVar does not support toboolean

const errorLink = onError((error) => {
  const { graphQLErrors, networkError } = error
  errorInfo.errorCount++

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)

    // have to cast as any here as type definition is incorrect.
    if ((networkError as any).statusCode === 401) {
      logout()
    }
  }
})

const graphqlMiddlewareHttpLink = new HttpLink({
  uri: `${envUrl('GRAPHQL_URL')}/api`,
  credentials: 'include',
})

const platformGatewayHttpLink = new HttpLink({
  uri: envUrl('PLATFORM_GATEWAY_URL'),
  credentials: 'include',
})

const headersLink = setContext(async () => {
  const baseHeaders = {
    'babylon-request-Id': requestIdGenerator.generate(),
    ...getCsrfHeaders(),
  }
  if (!ENABLE_NEW_AUTH) {
    return {
      headers: baseHeaders,
    }
  }

  if (!authClient) {
    authClient = getAuthClient(true)
  }

  if (!(await authClient.isAuthenticated())) {
    return {
      headers: baseHeaders,
    }
  }

  const token = await authClient.getAuthToken()

  return {
    headers: {
      ...baseHeaders,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const graphqlMiddlewareHeadersLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-Platform': 'portal',
      'apollographql-client-name': 'Admin Portal',
      'apollographql-client-version': process.env.REACT_APP_VERSION,
    },
  }))

  return forward(operation)
})

const graphqlMiddlewareLinks = [
  errorLink,
  headersLink,
  graphqlMiddlewareHeadersLink,
  graphqlMiddlewareHttpLink,
]
const platformGatewayLinks = [errorLink, headersLink, platformGatewayHttpLink]

if (isDevelopment) {
  graphqlMiddlewareLinks.unshift(loggerLink)
  platformGatewayLinks.unshift(loggerLink)
}

const graphqlMiddlewareLink = ApolloLink.from(graphqlMiddlewareLinks)

const platformGatewayLink = ApolloLink.from(platformGatewayLinks)

const cache = new InMemoryCache({
  possibleTypes: generatedIntrospection.possibleTypes,
})

const apolloClient = new ApolloClient({
  cache,
  connectToDevTools: isDevelopment,
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'platform-gateway',
    platformGatewayLink,
    graphqlMiddlewareLink
  ),
})

const tealiumService = new TealiumService({
  app: envVar('TEALIUM_APP'),
  appName: 'Admin Portal',
  appVersion: process.env.REACT_APP_VERSION || '',
  env: envVar('ENVIRONMENT'),
  countryIsoCode: envVar('ENVIRONMENT_COUNTRY_ISO_CODE'),
})

const App = () => (
  <TrackingProvider service={tealiumService}>
    <ApolloProvider client={apolloClient}>
      <LocaleMessageLoader>
        <>
          <AppFrame />
          <ConnectionAlert />
        </>
      </LocaleMessageLoader>
    </ApolloProvider>
  </TrackingProvider>
)

export default App
