import React from 'react'
import cn from 'classnames'
import { Text } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import {
  MemberTask,
  MemberWorkflowState,
} from '@babylon/graphql-middleware-types'
import { LoadingBlock } from '../../../../LoadingContent'
import styles from './MemberTaskStatus.module.css'
import messages from './MemberTaskStatus.messages'
import getStatusStyles from '../MemberTaskDrawer/status'

interface MemberTaskStatusProps
  extends Omit<MemberTask, 'thread' | 'createdAt' | 'id' | 'state'> {
  loading?: boolean
  status?: MemberWorkflowState
}

export default function MemberTaskStatus({
  logEntry,
  status,
  loading = false,
}: MemberTaskStatusProps) {
  const fm = useFormatMessage()
  if (loading) {
    return (
      <div
        data-testid="member-current-task-loading"
        aria-busy="true"
        aria-live="polite"
      >
        <LoadingBlock className={styles.loadingBlock} />
      </div>
    )
  }

  return (
    <div
      data-testid="member-current-task-status"
      className={cn(styles.container, getStatusStyles(status))}
    >
      <Text variant="smallBody" className={styles.status}>
        {status && fm(messages[status])}
      </Text>
      <Text variant="body">{logEntry || ''}</Text>
    </div>
  )
}
