import React from 'react'

import { useFormatMessage } from '@babylon/intl'
import { DurationDropdown } from '../../../AppointmentFields'
import ServiceTypeField from './ServiceTypeField'
import messages from '../ServiceTypeInfo.messages'

interface Props {
  isEditing: boolean
  value?: number
}

const DurationField = ({ isEditing = false, value }: Props) => {
  const fm = useFormatMessage()

  return isEditing ? (
    <DurationDropdown
      fieldName="default_duration_minutes"
      defaultDuration={5}
    />
  ) : (
    <ServiceTypeField
      label="Duration"
      value={value ? `${value} minutes` : ''}
      tooltipText={fm(messages.durationInfo)}
    />
  )
}

export default DurationField
