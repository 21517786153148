import { useQuery } from '@apollo/client'
import logException from './logException'
import { Messages } from '../localization'
import { GetAppointmentClinicsQuery } from './GetAppointmentClinicsQuery'

const updateErrorMessage = (error: any, data: { error?: any }) => {
  if (!error && !!data?.error) {
    logException(data.error)

    return Messages.defaultErrorMessage
  }

  return error
}

export default function useAppointmentClinicData({
  consultantType,
  consultant,
  consultationType,
  memberId,
  serviceTypeUuid,
  appointmentClinic,
}: any) {
  const { data, loading, error } = useQuery(GetAppointmentClinicsQuery, {
    skip: consultationType !== 'physical',
    variables: {
      consultantType,
      consultant,
      consultationType,
      memberId,
      serviceTypeUuid,
    },
  })

  const clinics = data?.appointmentClinics

  return {
    appointmentClinicLoading: loading,
    appointmentClinicList: Array.isArray(clinics) ? clinics : [],
    error: updateErrorMessage(error, data),
    appointmentClinic: appointmentClinic || clinics?.[0]?.id || null,
  }
}
