import { useQuery } from '@apollo/client'
import { useProductConfig } from '@babylon/product-config'
import { ACTIVITY_LOG_QUERY } from './queries'

type TReportConfig = {
  url: string
  encryptedParamsName: string
  encryptedParams: string
}

const dataStudioUrlParams = (params: { [key: string]: unknown }) => {
  const paramsString = JSON.stringify(params)

  return encodeURIComponent(paramsString)
}

export const activityLogEmbedUrl = ({
  encryptedParams,
  url,
  encryptedParamsName,
}: TReportConfig) => {
  const params = dataStudioUrlParams({
    [encryptedParamsName]: encryptedParams,
  })

  return `${url}?params=${params}`
}

const useReportEncryptedParams = (patientId: string) => {
  const { data, loading } = useQuery(ACTIVITY_LOG_QUERY, {
    variables: {
      patientId,
    },
  })

  const { activityLog } = data || {}
  const reportParameters = activityLog?.reportParameters

  return {
    loading,
    encryptedParams: reportParameters,
  }
}

export const useReportUrl = (patientId: string) => {
  const { loading, encryptedParams } = useReportEncryptedParams(patientId)

  const { getBlob } = useProductConfig()
  const { activityLogEnabled, activityLogReport } = getBlob('memberOperations')

  const accessGranted = !!encryptedParams && activityLogEnabled

  return {
    loading,
    url:
      accessGranted &&
      activityLogEmbedUrl({
        ...activityLogReport,
        encryptedParams,
      }),
  }
}
