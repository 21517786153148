import React from 'react'

import { useFormatMessage } from '@babylon/intl'
import messages from './messages'
import ErrorBlock from './ErrorBlock'
import logException from './logException'

const Fallback = () => {
  const fm = useFormatMessage()

  return (
    <ErrorBlock>
      <ErrorBlock.Heading>{fm(messages.message)}</ErrorBlock.Heading>
    </ErrorBlock>
  )
}

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    logException(error)

    return { hasError: true }
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    return hasError ? <Fallback /> : children || null
  }
}

export default ErrorBoundary
