import React from 'react'

import {
  Alert,
  FormField,
  FormikRadioGroup,
  Radio,
  Text,
} from '@babylon/core-ui'

interface Props {
  serviceTypes?: ServiceType[]
  selected?: string
  onChange?: (value: string) => void
}

const findSelectedServiceType = (
  serviceTypes: ServiceType[] = [],
  selectedUuid: string
): ServiceType | undefined =>
  serviceTypes.find(
    (serviceType: ServiceType): boolean => serviceType.uuid === selectedUuid
  )

const ServiceTypeField = ({ serviceTypes = [], selected, onChange }: Props) => {
  let selectedServiceType

  if (selected) {
    selectedServiceType = findSelectedServiceType(serviceTypes, selected)
  }

  return (
    <FormField>
      <FormikRadioGroup
        columns={3}
        id="service_type_uuid"
        name="service_type_uuid"
        onChange={onChange}
      >
        {serviceTypes.map((serviceType) => (
          <Radio key={serviceType.uuid} value={serviceType.uuid}>
            <Text>{serviceType.name}</Text>
          </Radio>
        ))}
      </FormikRadioGroup>
      {selectedServiceType && selectedServiceType.member_instructions && (
        <Alert
          intent="primary"
          title={selectedServiceType.member_instructions}
        />
      )}
    </FormField>
  )
}

export default ServiceTypeField
