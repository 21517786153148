import { defineMessages } from 'react-intl'

const baseId = 'member_edit'

export default defineMessages({
  error: {
    id: `${baseId}.error`,
    defaultMessage:
      "Your changes can't be saved. Please address the errors below",
  },
  country_update_failed: {
    id: `${baseId}.country_update_failed`,
    defaultMessage: 'The country failed to update',
  },
  cant_be_blank: {
    id: `${baseId}.cant_be_blank`,
    defaultMessage: "Can't be blank",
  },
  invalid: {
    id: `${baseId}.invalid`,
    defaultMessage: 'Is invalid',
  },
  invalid_or_blank: {
    id: `${baseId}.invalid_or_blank`,
    defaultMessage: "Can't be blank or invalid",
  },
  locked: {
    id: `${baseId}.locked`,
    defaultMessage:
      'Edits are disabled. This member is managed by {lockType} organisation(s).',
  },
})
