import React from 'react'
import { Button, Heading, Modal, Text } from '@babylon/core-ui'

interface Props {
  isOpen: boolean
  name: string
  onConfirm: () => void
  onCancel: () => void
}

const DeleteServiceTypeConfirm = ({
  isOpen,
  name,
  onCancel,
  onConfirm,
}: Props) => (
  <Modal open={isOpen} shouldCloseOnOverlayClick ariaHideApp={false}>
    <div style={{ maxWidth: '400px' }}>
      <Heading>Are you sure you want to remove {name}?</Heading>
      <Text tag="p" color="grey-type">
        This action can not be undone.
      </Text>
      <Text align="right" tag="div">
        <Button inline intent="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button inline onClick={onConfirm}>
          Yes, remove
        </Button>
      </Text>
    </div>
  </Modal>
)

export default DeleteServiceTypeConfirm
