import React from 'react'
import { Grid } from '@babylon/core-ui'
import { LoadingHeader, LoadingBlock } from '../../../..'
import styles from './ConsumerNetworksCard.module.scss'

const LoadingConsumerNetworksCard = () => (
  <div data-testid="loading-consumer-networks">
    <LoadingHeader />
    <Grid rowGap={8}>
      <LoadingBlock className={styles.loadingBlock} />
      <LoadingBlock className={styles.loadingBlock} />
    </Grid>
  </div>
)

export default LoadingConsumerNetworksCard
