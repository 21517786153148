import React from 'react'
import { Button } from '@babylon/babylon-forms/forms'
import { DataGrid, Columns, Column } from '@babylon/babylon-forms/widgets'

import TimezoneDate from '@/components/TimezoneDate'

import styles from './styles.module.scss'

const formatDate = (fieldName) => (item) => (
  <span className={styles.noWrap}>
    <TimezoneDate date={item[fieldName]} />
  </span>
)

const removeUnderscores = (fieldName) => (state) =>
  (state[fieldName] || '').replace(/_/g, ' ')

const DrugAlertAuditsView = ({ data: { adminDrugAudits }, history }) => (
  <div>
    <div className={styles.audits}>
      <DataGrid value={adminDrugAudits} className="widget border">
        <Columns>
          <Column
            name="eventTimestamp"
            label="Date"
            value={formatDate('eventTimestamp')}
          />
          <Column name="performedByName" label="Performed by" />
          <Column
            name="eventType"
            label="Action type"
            value={removeUnderscores('eventType')}
          />
          <Column name="eventReason" label="Reason" />
          <Column name="region" label="Region" />
          <Column name="eventCategory" label="Category" />
        </Columns>
      </DataGrid>
    </div>
    <div className={styles.rightRow}>
      <Button outline onClick={() => history.goBack()}>
        Cancel
      </Button>
    </div>
  </div>
)

export default DrugAlertAuditsView
