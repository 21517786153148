import React, { ReactNode, useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { Card, Heading } from '@babylon/core-ui'
import { ConsultationStatus } from '@babylon/graphql-middleware-types'
import ClinicianName from '../../../ClinicianName'
import ConsumerNetworkTag from '../../../ConsumerNetworkTag'
import Tag from '../../../Tag'
import ViewMoreToggler from '../../../ViewMoreToggler'
import { MemberOpsModuleName } from '../../../..'
import MediumIcon from '../../MediumIcon'
import AppointmentCardDetails from '../AppointmentCardDetails'
import { AppointmentCardDetailsProps } from '../AppointmentCardDetails/AppointmentCardDetails'
import Duration from '../Duration'
import messages from './AppointmentCard.messages'
import styles from './styles.module.scss'
import sharedStyles from '../../shared.module.scss'
import { Consultant, Medium } from '../../../types'
import AppointmentDate from '../AppointmentDate'
import { useTimezoneContext } from '../../../Member/View/components/TimezoneProvider'

export interface AppointmentCardProps
  extends Omit<AppointmentCardDetailsProps, 'show' | 'isCompleted'> {
  menu: ReactNode
  title: ReactNode
  consultant?: Consultant | null
  consumerNetwork?: string | null
  guestName?: string | null
  duration?: number
  icon?: ReactNode
  isToggleBoxVisible?: boolean
  medium?: Medium
  scheduledTime?: string | null
  status?: string
}

const AppointmentCard = ({
  appointmentType,
  checkIn,
  clinic,
  guestName,
  consultant,
  consumerNetwork,
  duration,
  icon,
  id,
  isToggleBoxVisible = true,
  medium,
  menu,
  notes,
  reason,
  referrals,
  scheduledTime,
  status = '',
  title,
}: AppointmentCardProps) => {
  const { timezone } = useTimezoneContext()
  const fm = useFormatMessage()
  const [expanded, setExpanded] = useState<boolean>(false)
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })
  const isCompleted = status === ConsultationStatus.Completed

  const viewMoreTogglerHandler = () => {
    setExpanded((toggle) => !toggle)
    trackClick({
      elementName: 'view-more-btn',
      elementType: TrackingElementType.button,
    })
  }

  return (
    <div
      className={styles.AppointmentCard__wrapper}
      data-testid="timeline-card"
    >
      <MediumIcon icon={icon} medium={medium} />
      <Card className={styles.AppointmentCard}>
        <div className={styles.AppointmentCard__summary}>
          <AppointmentDate scheduledTime={scheduledTime} timezone={timezone} />

          <div>
            <ConsumerNetworkTag
              name={consumerNetwork}
              isPreferred
              className={styles.AppointmentCard__cardNetworkTag}
            />
            {guestName && (
              <Tag
                label={fm(messages.guestName_label)}
                className={sharedStyles.guestTag}
              />
            )}
          </div>

          <Heading
            level="h3"
            data-testid="timeline-card-title"
            className={styles.AppointmentCard__title}
          >
            {title}
          </Heading>

          {consultant && (
            <ClinicianName
              name={consultant.name}
              id={consultant.id}
              gender={consultant.gender}
            />
          )}

          <div className={styles.AppointmentCard__durationCell}>
            <Duration duration={duration} />
          </div>

          <div className={styles.AppointmentCard__viewMoreCell}>
            {isToggleBoxVisible && (
              <ViewMoreToggler
                testId="timeline-card-show-details"
                className={styles.AppointmentCard__viewMore}
                open={!!expanded}
                size="small"
                onToggle={viewMoreTogglerHandler}
              >
                {fm(messages.show_details)}
              </ViewMoreToggler>
            )}
          </div>

          <div className={styles.AppointmentCard__menu}>{menu}</div>
        </div>

        <AppointmentCardDetails
          appointmentType={appointmentType}
          checkIn={checkIn}
          clinic={clinic}
          guestName={guestName}
          id={id}
          isCompleted={isCompleted}
          notes={notes}
          reason={reason}
          referrals={referrals}
          show={expanded}
        />
      </Card>
    </div>
  )
}

export default AppointmentCard
