import { defineMessages } from 'react-intl'

const baseId = 'member_edit_header'

export default defineMessages({
  edit_profile: {
    id: `${baseId}.edit_profile`,
    defaultMessage: 'Edit profile',
  },
  patient_name: {
    id: `${baseId}.patient_name`,
    defaultMessage: '{lastName}, {firstName}',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  save: {
    id: `${baseId}.save`,
    defaultMessage: 'Save changes',
  },
})
