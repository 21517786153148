import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import { FormattedMessage, FormattedDate } from 'react-intl'
import get from 'lodash/get'
import {
  Card,
  Heading,
  Spinner,
  Table,
  TableCell,
  TableRow,
} from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import DrawerView from '../DrawerView'
import styles from './ConsultationNote.module.scss'
import messages from './ConsultationNote.messages'
import { CONSULTATION, SPECIALISM_CATEGORY } from './queries'
import { Consultation } from '../types'

export interface ConsultationNoteProps {
  id: string
  toggleVisible: () => void
  visible: boolean
}

const useConsultationQueries = (consultationId: string, visible: boolean) => {
  const variables = { consultationId }
  const consultation = useQuery<Consultation>(CONSULTATION, {
    skip: !visible,
    variables,
  })

  const specialismCategory = useQuery(SPECIALISM_CATEGORY, {
    notifyOnNetworkStatusChange: true,
    skip: !visible,
    variables,
  })

  return {
    loading: consultation.loading || specialismCategory.loading,
    consultation,
    specialismCategory,
  }
}

const ConsultationNote: FC<ConsultationNoteProps> = ({
  id: consultationId,
  toggleVisible,
  visible,
}) => {
  const queries = useConsultationQueries(consultationId, visible)
  const fm = useFormatMessage()

  if (!visible) {
    return null
  }

  const consultation: Consultation = get(
    queries,
    'consultation.data.consultation'
  )
  const specialismCategories = get(
    queries,
    'specialismCategory.data.specialismCategories',
    []
  )

  const {
    consultationNote,
    prescriptions,
    referrals,
    scheduledTime,
  } = consultation || {
    consultationNote: null,
    prescriptions: null,
    referrals: null,
    scheduledTime: null,
  }

  return (
    <DrawerView
      title={fm(messages.heading)}
      visible={visible}
      toggleVisible={toggleVisible}
    >
      {queries.loading ? (
        <Spinner centered />
      ) : (
        <div className={styles.ConsultationNote}>
          {scheduledTime && (
            <div className={styles.ConsultationNote__scheduledTime}>
              <FormattedDate
                timeZone="UTC"
                day="2-digit"
                month="short"
                year="numeric"
                value={scheduledTime}
              />
            </div>
          )}

          <div className={styles.ConsultationNote__wrapper}>
            {!!consultationNote && (
              <div data-testid="consultation-note-notes">
                <div>
                  <Heading
                    level="h2"
                    className={styles.ConsultationNote__subheading}
                  >
                    <FormattedMessage {...messages.told_by_patient} />
                  </Heading>
                  <Card className={styles.ConsultationNote__card}>
                    {consultationNote.toldByPatient ||
                      fm(messages.no_entries_placeholder)}
                  </Card>
                </div>
                <div>
                  <Heading
                    level="h2"
                    className={styles.ConsultationNote__subheading}
                  >
                    <FormattedMessage {...messages.explored_by_gp} />
                  </Heading>
                  <Card className={styles.ConsultationNote__card}>
                    {consultationNote.exploredByGp ||
                      fm(messages.no_entries_placeholder)}
                  </Card>
                </div>
                <div>
                  <Heading
                    level="h2"
                    className={styles.ConsultationNote__subheading}
                  >
                    <FormattedMessage {...messages.assessment} />
                  </Heading>
                  <Card className={styles.ConsultationNote__card}>
                    {consultationNote.assessment ||
                      fm(messages.no_entries_placeholder)}
                  </Card>
                </div>
                <div>
                  <Heading
                    level="h2"
                    className={styles.ConsultationNote__subheading}
                  >
                    <FormattedMessage {...messages.treatment_plan} />
                  </Heading>
                  <Card className={styles.ConsultationNote__card}>
                    {consultationNote.treatmentPlan ||
                      fm(messages.no_entries_placeholder)}
                  </Card>
                </div>
                <div>
                  <Heading
                    level="h2"
                    className={styles.ConsultationNote__subheading}
                  >
                    <FormattedMessage {...messages.fallback_plan} />
                  </Heading>
                  <Card className={styles.ConsultationNote__card}>
                    {consultationNote.fallbackPlan ||
                      fm(messages.no_entries_placeholder)}
                  </Card>
                </div>
              </div>
            )}
            <div data-testid="consultation-note-prescriptions">
              <Heading
                level="h2"
                className={styles.ConsultationNote__subheading}
              >
                <FormattedMessage {...messages.prescriptions} />
              </Heading>
              <Card className={styles.ConsultationNote__card}>
                <Table
                  {...{
                    fullWidth: true,
                    headers: [
                      fm(messages.drug_name_header),
                      fm(messages.drug_quantity_header),
                      fm(messages.drug_directions_header),
                    ],
                  }}
                >
                  {prescriptions &&
                    prescriptions.map((item) =>
                      item.drugs.map((drug) => (
                        <TableRow key={drug.drugName}>
                          <TableCell>{drug.drugName}</TableCell>
                          <TableCell>{drug.duration}</TableCell>
                          <TableCell>{drug.directions}</TableCell>
                        </TableRow>
                      ))
                    )}
                </Table>
              </Card>
            </div>
            <div data-testid="consultation-note-referrals">
              <Heading
                level="h2"
                className={styles.ConsultationNote__subheading}
              >
                <FormattedMessage {...messages.referrals} />
              </Heading>
              <Card className={styles.ConsultationNote__card}>
                <Table
                  {...{
                    fullWidth: true,
                    headers: [
                      fm(messages.refferals_category_header),
                      fm(messages.refferals_specialist_header),
                    ],
                  }}
                >
                  {referrals &&
                    referrals.map((item) => (
                      <TableRow key={item.specialismName}>
                        <TableCell>
                          {
                            specialismCategories.find(
                              (c: { id: string }) =>
                                c.id == item.specialismCategoryId
                            ).name
                          }
                        </TableCell>
                        <TableCell>{item.specialismName}</TableCell>
                      </TableRow>
                    ))}
                </Table>
              </Card>
            </div>
          </div>
        </div>
      )}
    </DrawerView>
  )
}

export default ConsultationNote
