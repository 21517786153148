import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import EntityCard from '../EntityCard'
import RemoveServiceTypeConsumerNetworkMutation from './RemoveServiceTypeConsumerNetworkMutation'
import AddServiceTypeConsumerNetworkMutation from './AddServiceTypeConsumerNetworkMutation'
import FindConsumerNetworksQuery from './FindConsumerNetworksQuery'

interface ConsumerNetwork {
  id: string
  name: string
}

interface Props {
  consumerNetworks?: ConsumerNetwork[]
  serviceTypeUuid: String
}

const ServiceTypeConsumerNetworks = ({
  serviceTypeUuid,
  consumerNetworks = [],
}: Props) => (
  <Mutation
    mutation={AddServiceTypeConsumerNetworkMutation}
    refetchQueries={['GetServiceType']}
    awaitRefetchQueries
  >
    {(addConsumerNetworksToServiceType: any) => (
      <Mutation
        mutation={RemoveServiceTypeConsumerNetworkMutation}
        refetchQueries={['GetServiceType']}
        awaitRefetchQueries
      >
        {(removeConsumerNetworkFromServiceType: any) => (
          <EntityCard
            name="consumer network"
            query={{
              query: FindConsumerNetworksQuery,
              variables: (name: string) => ({
                name,
              }),
              resultSelector: (data: any) => data.findConsumerNetworks,
            }}
            items={consumerNetworks}
            onItemsAdded={(consumer_network_ids: string[]) =>
              addConsumerNetworksToServiceType({
                variables: {
                  service_type_uuid: serviceTypeUuid,
                  consumer_network_ids,
                },
              })
            }
            onItemRemoved={(consumer_network_id: string) =>
              removeConsumerNetworkFromServiceType({
                variables: {
                  service_type_uuid: serviceTypeUuid,
                  consumer_network_id,
                },
              })
            }
          />
        )}
      </Mutation>
    )}
  </Mutation>
)

export default ServiceTypeConsumerNetworks
