import React, { useRef, useState } from 'react'
import { addMinutes, isBefore } from 'date-fns'
import {
  ClinicianVisibilityType,
  ClinicianVisibilityColours,
  ClinicianVisibilityServiceIcons,
} from '../ClinicianVisibility'
import { Popover } from '../../components'
import BookingTypes from '../../types/BookingTypes'

import styles from './ClinicianVisibilitySlot.module.css'
import ClinicianVisibilityAppointmentDetails from '../ClinicianVisibilityAppointmentDetails'

interface SlotType
  extends Pick<
    VisibilitySlot,
    'admin' | 'digital_bookable' | 'physical_bookable' | 'appointment'
  > {}

export function getSlotType({
  admin,
  digital_bookable,
  physical_bookable,
  appointment,
}: SlotType): ClinicianVisibilityType {
  if (admin) {
    return 'admin'
  }

  if (!appointment?.id) {
    if (digital_bookable && physical_bookable) {
      return 'digital_physical_available'
    }

    if (physical_bookable) {
      return 'physical_available'
    }

    if (digital_bookable) {
      return 'digital_available'
    }

    return 'externally_booked_physical'
  }

  if (appointment?.consultation_type === 'physical') {
    return 'physical_booked'
  }

  return 'digital_booked'
}

export interface Props {
  slot: VisibilitySlot
  'data-testid'?: string
  onCancelAppointment?: () => void
}

export default function ClinicianVisibilitySlot({
  slot,
  'data-testid': dataTestId,
  onCancelAppointment = () => {},
}: Props) {
  const slotType = getSlotType(slot)
  const anchorRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const openPopover = () => setIsOpen(true)

  const props = {
    style: { backgroundColor: ClinicianVisibilityColours[slotType] },
    className: styles.slot,
    'data-testid': dataTestId,
    'data-slot-time': slot?.slot_time,
  }

  if (!slot?.appointment) {
    return <div {...props} />
  }

  const serviceTypeIcon =
    slot?.appointment.service_type?.uuid &&
    ClinicianVisibilityServiceIcons[slot.appointment.service_type.uuid]

  props.className = `${props.className} ${styles.slotClickable}`

  const isPastAppointment = isBefore(
    addMinutes(new Date(slot.slot_time), slot.appointment.duration_minutes),
    new Date()
  )

  const handleCancelAppointment = () => {
    setIsOpen(false)
    onCancelAppointment()
  }

  return (
    <>
      <div
        ref={anchorRef}
        onClick={openPopover}
        onKeyDown={openPopover}
        role="button"
        tabIndex={0}
        {...props}
      >
        {serviceTypeIcon}
      </div>
      <Popover
        visible={isOpen}
        triggerRef={anchorRef.current}
        onClose={() => setIsOpen(false)}
        modifiers={[
          {
            name: 'eventListeners',
            options: {
              scroll: true,
              resize: false,
            },
          },
        ]}
      >
        <ClinicianVisibilityAppointmentDetails
          appointmentId={slot.appointment.id}
          cancellable={!isPastAppointment}
          onCancelAppointment={handleCancelAppointment}
          rtmSlot={slot.appointment?.booking_type === BookingTypes.RTM}
        />
      </Popover>
    </>
  )
}
