import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@apollo/client'
import { KebabMenuRenderOptions, KebabMenu, Button } from '@babylon/core-ui'
import * as Access from '@babylon/web-platform-access'
import { useProductConfig } from '@babylon/product-config'
import { useFormatMessage } from '@babylon/intl'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { useSnackBar } from '../../../../hooks/SnackBar'
import { MemberOpsModuleName } from '../../../..'
import { UserTemplateState } from '../../../types'
import messages from './RepeatTemplateCard.messages'
import { MARK_REPEAT_PRESCRIPTION_AS_STOPPED } from './queries'

interface MenuProps {
  pastActivitesRefetch?: () => void
  patientId: string
  repeatTemplateId: string
  userState: UserTemplateState
}

const Menu = ({
  pastActivitesRefetch,
  patientId,
  repeatTemplateId,
  userState,
}: MenuProps) => {
  const fm = useFormatMessage()
  const [canAccessStopRepeatPrescription] = Access.useDecide(
    'stop-repeat-prescription'
  )

  const { getProp } = useProductConfig()
  const stopRepeatPrescriptionButtonEnabled = getProp(
    'memberOperations',
    'repeatPrescriptionStopButtonEnabled'
  )

  const showStopRepeatPrescriptionButton =
    stopRepeatPrescriptionButtonEnabled &&
    canAccessStopRepeatPrescription &&
    userState !== UserTemplateState.STOPPED

  const showFollowUpButton = userState === UserTemplateState.FOLLOW_UP_NEEDED

  const renderKebabMenu = showFollowUpButton || showStopRepeatPrescriptionButton

  const { setSnackbarMessage } = useSnackBar()

  const [markRepeatPrescriptionAsStopped] = useMutation(
    MARK_REPEAT_PRESCRIPTION_AS_STOPPED,
    {
      onCompleted: () => {
        setSnackbarMessage(
          fm(messages.mark_as_stopped_success),
          null,
          'success'
        )
      },
      onError: () => {
        setSnackbarMessage(fm(messages.mark_as_stopped_failure), null, 'error')
      },
    }
  )

  const handleMarkRepeatPrescriptionAsStopped = () => {
    markRepeatPrescriptionAsStopped({
      variables: {
        id: repeatTemplateId,
      },
    })
  }

  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })

  if (!renderKebabMenu) {
    return null
  }

  return (
    <KebabMenu>
      {({ menuItemProps }: KebabMenuRenderOptions) => (
        <>
          {showFollowUpButton && (
            <Button
              fill
              intent="secondary"
              href={`/admin/patients/${patientId}/book-appointment`}
              onClick={() => {
                trackClick({
                  elementName: 'book-appointment-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              testId="member-timeline-book-appointment-button"
              {...menuItemProps}
            >
              <FormattedMessage {...messages.book_appointment} />
            </Button>
          )}
          {showStopRepeatPrescriptionButton && (
            <Button
              fill
              intent="secondary"
              onClick={() => {
                handleMarkRepeatPrescriptionAsStopped()
                pastActivitesRefetch && pastActivitesRefetch()
                trackClick({
                  elementName: 'mark-repeat-prescription-as-stopped-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              {...menuItemProps}
            >
              <FormattedMessage
                {...messages.mark_repeat_prescription_as_stopped}
              />
            </Button>
          )}
        </>
      )}
    </KebabMenu>
  )
}

export default Menu
