import React from 'react'

import cn from 'classnames'
import { Text, ToggleSwitch } from '@babylon/medkit'
import styles from './DataPointsFilter.module.scss'

const DataPointsFilter = ({ onClick, checked, label }: Props) => (
  <div data-testid="toggle-container" className={styles.toggleContainer}>
    <Text variant="h5" tag="label">
      {label}
    </Text>
    <ToggleSwitch
      data-testid="toggle"
      className={cn(checked ? styles.toggleOn : styles.toggleOff)}
      onClick={onClick}
      checked={checked}
    />
  </div>
)

interface Props {
  onClick: () => void
  checked: boolean
  label: string
}

export default DataPointsFilter
