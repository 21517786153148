import { defineMessages } from 'react-intl'
import { APPOINTMENTS_WORKFLOWS_TAB, TEST_RESULTS_TAB } from './constants'

const baseId = 'timeline_tabs'

export default defineMessages({
  [APPOINTMENTS_WORKFLOWS_TAB]: {
    id: `${baseId}.appointments_workflows_tab`,
    defaultMessage: 'Appointments, Prescriptions and Workflows',
  },
  [TEST_RESULTS_TAB]: {
    id: `${baseId}.test_results_tab`,
    defaultMessage: 'Test results',
  },
})
