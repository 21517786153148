import { gql } from '@apollo/client'

export const GetProfessionsFragment = gql`
  fragment professionType on Profession {
    key
    name
  }
`

export default gql`
  query GetProfessions {
    professions {
      id
      default_slot_duration_minutes
      ...professionType
    }
  }
  ${GetProfessionsFragment}
`
