const ASYNC_DROPDOWN_VALUE_DELIMITER = ';'

export const isValidAsyncDropdownValue = (
  queryParameter: string | undefined
) => {
  if (!queryParameter) {
    return false
  }

  const [value, label] = queryParameter.split(ASYNC_DROPDOWN_VALUE_DELIMITER)

  if (!value || !label) {
    return false
  }

  return true
}

export const parseAsyncDropdownValue = (queryParameter: string | undefined) => {
  if (!queryParameter) {
    return undefined
  }

  const [value, label] = queryParameter.split(ASYNC_DROPDOWN_VALUE_DELIMITER)

  if (!value || !label) {
    return undefined
  }

  return value
}

export const stringToAsyncDropdownOptions = (
  stringValue: string | undefined
) => {
  if (!stringValue) {
    return []
  }

  const [value, label] = stringValue.split(ASYNC_DROPDOWN_VALUE_DELIMITER)

  if (!value || !label) {
    return []
  }

  return [
    {
      value: stringValue,
      label,
    },
  ]
}
