import React from 'react'
import { Formik, Form } from 'formik'

import { Button, Grid, Text } from '@babylon/core-ui'
import { envFlag } from '@babylon/babylon-env'

import { ConsultationTypeName } from '../types/ConsultationTypes'
import setupAppointmentValidation from './setupAppointmentValidation'
import {
  AppointmentFields,
  ConsultantTypePills,
  ConsultationTypePills,
  DurationDropdown,
  ReasonField,
  ServiceTypeField,
  normaliseServiceTypes,
} from '../AppointmentFields'
import CreateInvite from './CreateInvite'
import { UnexpectedError } from '../Utils'
import useConsultantTypeDurations from '../useConsultantTypeDurations'

interface Props {
  consultationTypes: ConsultationTypeName[]
  consultantTypes: Array<string>
  filters: AppointmentFields | null
  serviceTypes: ServiceType[]
  memberId: string
  memberUuid: string
  onSubmit: (values: AppointmentFields) => void
}

const SetupAppointment = ({
  consultantTypes = [],
  consultationTypes = [],
  filters,
  serviceTypes = [],
  memberId,
  memberUuid,
  onSubmit,
}: Props) => {
  const serviceTypeData = normaliseServiceTypes(serviceTypes)
  const {
    appointment_reason,
    chaperone_required = false,
    consultation_type = consultationTypes[0],
    consultant_type = consultationTypes[0] === 'physical'
      ? serviceTypeData.consultantTypes[0]
      : consultantTypes[0],
    duration_minutes,
    service_type_uuid,
  } = (filters || {}) as AppointmentFields

  const { consultantTypesDuration, error } = useConsultantTypeDurations()

  return (
    <Formik
      initialValues={{
        appointment_reason,
        chaperone_required,
        consultation_type,
        consultant_type,
        duration_minutes,
        service_type_uuid,
      }}
      isInitialValid={setupAppointmentValidation.isValidSync(filters)}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values)
        setSubmitting(false)
      }}
      validationSchema={setupAppointmentValidation}
    >
      {({
        isSubmitting,
        isValid,
        setValues,
        values: {
          appointment_reason: appointmentReason,
          consultation_type: consultationType,
          consultant_type: consultantType,
          duration_minutes: durationMinutes,
          service_type_uuid: serviceTypeUuid,
        },
      }) => {
        const isFace2Face = consultationType === 'physical'
        const consultantTypeDurations =
          consultantTypesDuration[consultantType as string]

        return (
          <Form>
            <ConsultationTypePills
              consultationTypes={consultationTypes}
              onChange={(selectedConsultationType) => {
                const activeConsultantTypes =
                  selectedConsultationType === 'physical'
                    ? serviceTypeData.consultantTypes
                    : consultantTypes

                const defaultDuration =
                  consultantTypesDuration[activeConsultantTypes[0]]

                /*
                 * Setting all form values again because of an issue with validation in v2
                 * cf. https://github.com/jaredpalmer/formik/issues/2266
                 */
                setValues({
                  consultant_type: activeConsultantTypes[0],
                  service_type_uuid: undefined,
                  chaperone_required: false,
                  duration_minutes:
                    selectedConsultationType !== 'physical'
                      ? defaultDuration && defaultDuration.default
                      : undefined,
                  consultation_type: selectedConsultationType,
                  appointment_reason: appointmentReason,
                })
              }}
            />
            {error && <UnexpectedError visible message={error.message} />}
            <ConsultantTypePills
              consultantTypes={
                isFace2Face ? serviceTypeData.consultantTypes : consultantTypes
              }
              onChange={(selectedConsultantType) => {
                const defaultDuration =
                  consultantTypesDuration[selectedConsultantType]

                setValues({
                  consultant_type: selectedConsultantType,
                  service_type_uuid: undefined,
                  chaperone_required: false,
                  duration_minutes: !isFace2Face
                    ? defaultDuration && defaultDuration.default
                    : undefined,
                  consultation_type: consultationType,
                  appointment_reason: appointmentReason,
                })
              }}
            />

            {isFace2Face && serviceTypes && (
              <ServiceTypeField
                serviceTypes={
                  !consultantType
                    ? undefined
                    : serviceTypeData.groupedServiceTypesUuids[
                        consultantType
                      ].map((uuid) => serviceTypeData.serviceTypes[uuid])
                }
                selected={serviceTypeUuid}
                onChange={(selectedServiceTypeUuid) => {
                  const recommendedDuration =
                    serviceTypeData.serviceTypes[selectedServiceTypeUuid]
                      .default_duration_minutes

                  setValues({
                    consultant_type: consultantType,
                    service_type_uuid: selectedServiceTypeUuid,
                    chaperone_required: false,
                    duration_minutes: recommendedDuration,
                    consultation_type: consultationType,
                    appointment_reason: appointmentReason,
                  })
                }}
              />
            )}

            <Grid columns={2}>
              <DurationDropdown
                setRecommended
                durations={
                  !isFace2Face &&
                  consultantType &&
                  consultantTypesDuration.hasOwnProperty(consultantType)
                    ? consultantTypeDurations.available
                    : undefined
                }
                defaultDuration={
                  serviceTypeUuid
                    ? serviceTypeData.serviceTypes[serviceTypeUuid]
                        .default_duration_minutes
                    : consultantType &&
                      consultantTypesDuration.hasOwnProperty(consultantType)
                    ? consultantTypeDurations.default
                    : undefined
                }
              />
            </Grid>

            <ReasonField withTooltip />

            <Text tag="div" align="right">
              {envFlag('CREATE_APPOINTMENT_INVITE', false) && isFace2Face && (
                <CreateInvite
                  isDisabled={isSubmitting || !isValid || !appointmentReason}
                  filters={{
                    appointment_reason: appointmentReason,
                    intimate:
                      !!serviceTypeUuid &&
                      serviceTypeData.serviceTypes[serviceTypeUuid].intimate,
                    service_type_uuid: serviceTypeUuid,
                    consultation_type: consultationType,
                    duration_minutes: durationMinutes,
                  }}
                  memberId={memberId}
                  memberUuid={memberUuid}
                />
              )}
              <Button type="submit" disabled={isSubmitting || !isValid} inline>
                Continue
              </Button>
            </Text>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SetupAppointment
