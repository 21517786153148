import { gql } from '@apollo/client'

export const ASSESSMENT_STAGE_QUERY = gql`
  query AP_AssessmentStage(
    $locale: String!
    $memberId: ID!
    $fetchScores: Boolean!
    $fetchRiskScores: Boolean!
  ) {
    healthCheckAssessmentStage(locale: $locale, memberId: $memberId) {
      scores @include(if: $fetchRiskScores) {
        id
        name
        result {
          score
          severityText
          ragStatus
        }
      }
      callToActions {
        id
        title
      }
      content {
        body
        header
      }
      nextFlows {
        durationMinutes
        durationMinutesText
        id
        name
        status {
          conversationCompletedAt
          conversationState
          conversationStateText
          conversations {
            completedAt
            id
            startedAt
          }
          dataPointCollectionState
          dataPointCollectionStateText
          dataPointCompleteness {
            associatedDataPointDefinitions {
              description
              displayKey
              id
              unit
            }
            completedDataPoints {
              dateAdded
              dateCaptured
              displayValue
              id
              value
            }
            percentageComplete
            updatedAt
          }
        }
        scores @include(if: $fetchScores) {
          id
          name
          result {
            score
            severityText
          }
        }
      }
    }
  }
`

export const PATIENT_QUERY = gql`
  query AP_Patient($id: ID!) {
    patient(id: $id) {
      id
      uuid
      firstName: first_name
    }
  }
`
