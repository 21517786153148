import { defineMessages } from 'react-intl'

const baseId = 'timezone_provider'

export default defineMessages({
  members_time_enabled: {
    id: `${baseId}.members_time_enabled`,
    defaultMessage:
      "Updated times to display in the member's time zone of {timezone}. This has been calculated based on the member's address.",
  },
  members_time_disabled: {
    id: `${baseId}.members_time_disabled`,
    defaultMessage:
      "Updated times to display in your time zone of {timezone}. This has been calculated based on this device's time zone.",
  },
})
