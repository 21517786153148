import { gql } from '@apollo/client'

export const QUERY_GET_FILTERED_PHARMACIES = gql`
  query GetFilteredPharmacies(
    $first: NonNegativeInt!
    $after: Cursor
    $sort: [HealthcareServiceSort]
    $filter: HealthcareServiceFilter
  ) {
    healthcareServices(
      first: $first
      after: $after
      _sort: $sort
      _filter: $filter
    ) {
      ... on HealthcareServiceConnection {
        edges {
          cursor
          node {
            identifiers(first: 100) {
              nodes {
                value
                system
              }
            }
            id
            isActive
            locations(first: 10) {
              nodes {
                ... on Location {
                  address {
                    ... on HealthgraphAddress {
                      lines(first: 10) {
                        nodes
                      }
                      postalCode
                    }
                  }
                }
              }
            }
            name
            telecoms(first: 10) {
              nodes {
                ... on PhoneNumberContactPoint {
                  phoneNumber
                  system
                }
                ... on OtherContactPoint {
                  system
                  value
                }
              }
            }
            comment
            referralMethods(first: 10) {
              nodes {
                codings(first: 10) {
                  nodes {
                    code
                    display {
                      text
                    }
                  }
                }
              }
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`
