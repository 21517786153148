import { defineMessages } from 'react-intl'

const baseId = 'support_interaction'

export default defineMessages({
  isThisThePatient: {
    id: `${baseId}.is_this_the_patient`,
    defaultMessage: 'Is this the patient you are speaking to?',
  },
  yes: {
    id: `${baseId}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${baseId}.no`,
    defaultMessage: 'No',
  },
})
