import React, { useMemo, useRef, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { formatISO, endOfDay, getHours, getMinutes } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useBabylonUser, User } from '@babylon/babylon-user'
import ClinicianVisibilityGrid from '../ClinicianVisibilityGrid'
import { ClinicianVisibilitySearchFormTypes } from '../ClinicianVisibilitySearch/ClinicianVisibilitySearch'
import ClinicianVisibilityQuery from './ClinicianVisibilityQuery'
import GetServiceType from './GetServiceTypeQuery'
import styles from './ClinicianVisibilityResults.module.css'
import { TotalResults, NoResultsCard, LoadingContainer } from '../../components'
import { useQueryParams } from '../../hooks'
import VisibilityHeaderNavigation from '../VisibilityHeaderNavigation'
import { getEarliestSlotTime } from './utils'
import { groupByClinic, groupByProfession, groupByClinician } from './groupings'

const scrollToFirstSlot = (
  scrollContainer: React.MutableRefObject<HTMLDivElement | null>,
  earliestSlotTime?: string
) => {
  if (!scrollContainer.current || !earliestSlotTime) {
    return
  }

  const earliestTimeElement = scrollContainer.current.querySelector(
    `[data-slot-time="${earliestSlotTime}"]`
  ) as HTMLElement

  if (!earliestTimeElement) {
    return
  }

  const firstClinicianSlot = scrollContainer.current.querySelector(
    '.clinician-visibility-clinician-cell'
  )

  if (!firstClinicianSlot) {
    return
  }

  // the clinician name component overhangs the grid of slots, because of this it needs to be factored
  // into the scroll offset
  const { width } = firstClinicianSlot.getBoundingClientRect()
  const offset = earliestTimeElement.offsetLeft - width

  scrollContainer.current.scrollLeft = offset
}

export default function ClinicianVisibilityResults() {
  const scrollContainer = useRef(null)
  const [params] = useQueryParams<ClinicianVisibilitySearchFormTypes>({
    defaultProps: {
      dateTimeFrom: '',
      clinicianId: [],
      consultantId: [],
      clinicId: [],
      profession: [],
      serviceType: [],
    },
  })
  const { timezone } = useBabylonUser() as User
  // defaulting to todays date to prevent erroring with formatISO function
  const queryDate = params?.dateTimeFrom
    ? new Date(params.dateTimeFrom)
    : new Date()

  const selectedDayISO = formatISO(queryDate)
  const skipQuery = !params?.dateTimeFrom

  const queryVars = {
    slot_time_from: zonedTimeToUtc(queryDate, timezone),
    slot_time_to: zonedTimeToUtc(endOfDay(queryDate), timezone),
    clinician_id: [...new Set([...params.clinicianId, ...params.consultantId])],
    clinic_id: params.clinicId,
    profession: params.profession,
    service_type_uuid: params.serviceType,
  }

  const { data, loading, refetch } = useQuery(ClinicianVisibilityQuery, {
    variables: queryVars,
    skip: skipQuery,
    fetchPolicy: 'no-cache',
  })

  const { data: serviceTypeData } = useQuery(GetServiceType, {
    variables: {
      uuid: queryVars.service_type_uuid[0],
    },
    skip: !queryVars.service_type_uuid.length,
  })
  // TODO address the eslint error below
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const slotsPerClinician = data?.getClinicianVisibility?.clinician_slots ?? []

  const earliestSlotTime = useMemo(
    () => getEarliestSlotTime(slotsPerClinician),
    [slotsPerClinician]
  )

  const [clinicianVisibilityResults, total] = useMemo((): [
    ClinicianVisibilityGroup[],
    number
  ] => {
    const isFilteredByProfession = queryVars.profession.length > 0
    const isFilteredByLocation = queryVars.clinic_id.length > 0
    const isFilteredByServiceType = queryVars.service_type_uuid.length > 0

    if (isFilteredByLocation) {
      return groupByClinic(slotsPerClinician)
    }

    if (isFilteredByProfession) {
      return groupByProfession(slotsPerClinician)
    }

    if (isFilteredByServiceType) {
      const [[groupedByClinician], groupedTotal] = groupByClinician(
        slotsPerClinician
      )

      return [
        [
          {
            ...groupedByClinician,
            id: queryVars.service_type_uuid?.[0],
            name: serviceTypeData?.serviceType?.name,
          },
        ],
        groupedTotal,
      ]
    }

    return groupByClinician(slotsPerClinician)
  }, [
    slotsPerClinician,
    serviceTypeData,
    queryVars.clinic_id,
    queryVars.profession,
    queryVars.service_type_uuid,
  ])

  useEffect(() => {
    scrollToFirstSlot(scrollContainer, earliestSlotTime)
  }, [total, earliestSlotTime])

  return (
    <LoadingContainer fill loading={loading}>
      {!skipQuery && (
        <TotalResults className={styles.visibilityTotalResults} total={total} />
      )}
      <VisibilityHeaderNavigation disabled={loading} />
      <NoResultsCard
        data-testid="visibility-no-results"
        visible={!skipQuery && !loading && total === 0}
      />
      {total > 0 && (
        <div
          className={styles.wrapper}
          data-testid="clinician-visibility-results-container"
        >
          <div className={styles.scrollContainer} ref={scrollContainer}>
            {clinicianVisibilityResults.map((grouping) => (
              <ClinicianVisibilityGrid
                day={selectedDayISO}
                heading={grouping?.name}
                timezoneId={timezone}
                clinicians={grouping.clinicians}
                key={grouping?.id || grouping?.name}
                startAt={
                  queryDate && {
                    hours: getHours(queryDate),
                    mins: getMinutes(queryDate),
                  }
                }
                refetchResults={refetch}
              />
            ))}
          </div>
        </div>
      )}
    </LoadingContainer>
  )
}
