export const initConsultationType = ({
  consultationType,
  consultationTypeList,
}: {
  consultationType?: string
  consultationTypeList: string[]
}) => {
  if (consultationType) {
    return consultationType.toLowerCase()
  }

  if (consultationTypeList.length === 0) {
    return null
  }

  if (consultationTypeList.includes('video')) {
    return 'video'
  }

  return consultationTypeList[0]
}

export const initConsultantType = ({
  consultantType,
  consultantTypeList,
}: {
  consultantType?: string
  consultantTypeList: string[]
}) => {
  if (consultantType) {
    return consultantType
  }

  return consultantTypeList.length > 0 ? consultantTypeList[0] : null
}

export const initConsultationTimezone = (consultationType?: string) =>
  consultationType !== 'physical'
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : null

interface FiltersArgs {
  chaperone_required?: boolean
  consultation_type?: string
  consultant_type?: string
  duration_minutes?: number
  service_type_uuid?: string
  preferred_medium?: string
}

export const defaultValuesFromFilters = ({
  filters,
}: {
  filters: FiltersArgs
}) =>
  !filters
    ? {}
    : {
        isChaperoneRequired: filters.chaperone_required,
        consultationType: filters.consultation_type,
        consultantType: filters.consultant_type,
        slotDuration: filters.duration_minutes,
        serviceTypeUuid: filters.service_type_uuid,
      }
