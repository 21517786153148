import { defineMessages } from 'react-intl'

const baseId = 'copiable_member_information'

export default defineMessages({
  address: {
    id: `${baseId}.address`,
    defaultMessage: 'Address copied',
  },
  address_unknown: {
    id: `${baseId}.address_unknown`,
    defaultMessage: 'Address unknown',
  },
  date_of_birth: {
    id: `${baseId}.date_of_birth`,
    defaultMessage: 'Date of birth copied',
  },
  email: {
    id: `${baseId}.email`,
    defaultMessage: 'Email address copied',
  },
  email_unknown: {
    id: `${baseId}.email_unknown`,
    defaultMessage: 'Email unknown',
  },
  athena: {
    id: `${baseId}.athena`,
    defaultMessage: 'Athena',
  },
  babylon: {
    id: `${baseId}.babylon`,
    defaultMessage: 'Babylon',
  },
  public: {
    id: `${baseId}.public`,
    defaultMessage: 'Public',
  },
  private: {
    id: `${baseId}.private`,
    defaultMessage: 'Private',
  },
  user_uuid: {
    id: `${baseId}.uuid`,
    defaultMessage: 'UUID',
  },
  id_athena: {
    id: `${baseId}.id_athena`,
    defaultMessage: 'Athena ID copied',
  },
  id_babylon: {
    id: `${baseId}.id_babylon`,
    defaultMessage: 'Babylon ID copied',
  },
  id_private: {
    id: `${baseId}.id_private`,
    defaultMessage: 'Private healthcare ID copied',
  },
  id_public: {
    id: `${baseId}.id_public`,
    defaultMessage: 'Public healthcare ID copied',
  },
  id_uuid: {
    id: `${baseId}.id_uuid`,
    defaultMessage: 'Babylon uuid copied',
  },
  unconfirmed_id_public: {
    id: `${baseId}.unconfirmed_id_public`,
    defaultMessage: 'Unconfirmed public healthcare ID copied',
  },
  unconfirmed: {
    id: `${baseId}.unconfirmed`,
    defaultMessage: 'unconfirmed',
  },
  phone_number: {
    id: `${baseId}.phone_number`,
    defaultMessage: 'Phone number copied',
  },
  phone_number_unknown: {
    id: `${baseId}.phone_number_unknown`,
    defaultMessage: 'Phone unknown',
  },
  display_age_years: {
    id: `${baseId}.display_age_years`,
    defaultMessage: '{years}y',
  },
  display_age_months: {
    id: `${baseId}.display_age_months`,
    defaultMessage: '{months}mths',
  },
  gender_female: {
    id: `${baseId}.gender_female`,
    defaultMessage: 'Female',
  },
  gender_male: {
    id: `${baseId}.gender_male`,
    defaultMessage: 'Male',
  },
})
