import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { submitHandler, withFormContext } from './util'

const Button = ({
  label,
  children,
  onClick,
  small,
  outline,
  alert,
  className,
  style,
  'data-context': ctx,
  type,
  ...props
}) => {
  const handleClick = submitHandler(type, onClick, ctx)
  return (
    <div className="widget-wrapper" style={style}>
      <button
        className={cx(
          'widget button border',
          { small, outline, alert },
          className
        )}
        onClick={handleClick}
        type={type}
        {...props}
      >
        {label || children}
      </button>
    </div>
  )
}

Button.defaultProps = {
  type: 'submit',
  small: false,
  outline: false,
}

Button.propTypes = {
  /** Input type */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Input label */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Click event handler method
   * @param {SyntheticEvent} event The react `SyntheticEvent`
   */
  onClick: PropTypes.func,
  /** Render the button in a smaller size */
  small: PropTypes.bool,
  /** Render the button with outline */
  outline: PropTypes.bool,
  /** Render the button with alert color */
  alert: PropTypes.bool,
  /** The css class name of the component */
  className: PropTypes.string,
  /** The css style of the component */
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  /** @ignore */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** @ignore */
  'data-context': PropTypes.shape({
    submit: PropTypes.func,
    reset: PropTypes.func,
    data: PropTypes.object,
  }),
}

export default withFormContext(Button)
