import React from 'react'
import { useFormikContext } from 'formik'
import { format } from 'date-fns'
import {
  DatePicker,
  FormikDatePicker,
  FormikInput,
  FormikSelect,
} from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { PartialMemberForEdit, Region } from '../../../types'
import { FormSection, FieldRow } from '../FormFields'
import messages from './PersonalDetails.messages'
import styles from '../FormFields/FormFields.module.scss'

export interface PersonalDetailsProps {
  regions: Region[]
  isPublicHealthcareDetailsDisabled: boolean
  disableMemberEditForm?: boolean
}

const convertDateToLocalTimezone = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

const formatDate = (date: Date | string) =>
  date ? format(new Date(date), 'yyyy-MM-dd') : ''

const PersonalDetails = ({
  regions = [],
  isPublicHealthcareDetailsDisabled,
  disableMemberEditForm,
}: PersonalDetailsProps) => {
  const {
    values,
    errors,
    setFieldValue,
  } = useFormikContext<PartialMemberForEdit>()
  const { date_of_birth, public_healthcare_details } = values
  const fm = useFormatMessage()
  const genders = [
    {
      value: 'female',
      label: fm(messages.gender_female),
    },
    {
      value: 'male',
      label: fm(messages.gender_male),
    },
  ]

  const countries = regions.map((region) => ({
    value: region.iso_code,
    label: region.name,
  }))

  const arrivalDateError = errors?.public_healthcare_details?.arrival_date
  const armedForcesEnlistingDateError =
    errors?.public_healthcare_details?.armed_forces_enlisting_date

  return (
    <div data-testid="member-edit-personal-details">
      <FormSection title={fm(messages.title)}>
        <FieldRow testId="first_name">
          <FormikInput
            id="first_name"
            name="first_name"
            label={fm(messages.first_name)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="last_name">
          <FormikInput
            id="last_name"
            name="last_name"
            label={fm(messages.last_name)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="previous_names">
          <FormikInput
            id="previous_names"
            name="previous_names"
            label={fm(messages.previous_names)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow size="small" testId="date_of_birth">
          <FormikDatePicker
            className={styles.datePicker}
            dateFormat="dd MMM yyyy"
            id="date_of_birth"
            label={fm(messages.date_of_birth)}
            name="date_of_birth"
            selected={
              date_of_birth &&
              convertDateToLocalTimezone(new Date(date_of_birth))
            }
            stateFormat="yyyy-MM-dd"
            endDate={new Date()}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow size="small" testId="gender">
          <FormikSelect
            id="gender"
            name="gender"
            label={fm(messages.gender)}
            options={genders}
            isDisabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="town_of_birth">
          <FormikInput
            id="town_of_birth"
            name="birth_details.town"
            label={fm(messages.town_of_birth)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="country_of_birth">
          <FormikSelect
            id="country_of_birth"
            name="birth_details.country"
            label={fm(messages.country_of_birth)}
            options={countries}
            isDisabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow size="small" testId="arrival_date">
          <DatePicker
            className={styles.datePicker}
            dateFormat="dd MMM yyyy"
            disabled={
              isPublicHealthcareDetailsDisabled || disableMemberEditForm
            }
            displayError={!!arrivalDateError}
            errorMessage={arrivalDateError}
            id="arrival_date"
            label={fm(messages.arrival_date)}
            name="public_healthcare_details.arrival_date"
            selected={
              new Date(
                public_healthcare_details
                  ? public_healthcare_details.arrival_date
                  : ''
              )
            }
            endDate={new Date()}
            onChange={(selectedDate: Date) => {
              setFieldValue(
                'public_healthcare_details.arrival_date',
                formatDate(selectedDate)
              )
            }}
          />
        </FieldRow>
        <FieldRow testId="disability">
          <FormikInput
            disabled={
              isPublicHealthcareDetailsDisabled || disableMemberEditForm
            }
            id="disability"
            name="public_healthcare_details.disability"
            label={fm(messages.disability)}
          />
        </FieldRow>
        <FieldRow size="small" testId="armed_forces_enlisting_date">
          <DatePicker
            className={styles.datePicker}
            disabled={
              isPublicHealthcareDetailsDisabled || disableMemberEditForm
            }
            displayError={!!armedForcesEnlistingDateError}
            errorMessage={armedForcesEnlistingDateError}
            id="armed_forces_enlisting_date"
            label={fm(messages.armed_forces_enlisting_date)}
            name="public_healthcare_details.armed_forces_enlisting_date"
            selected={
              new Date(
                public_healthcare_details
                  ? public_healthcare_details.armed_forces_enlisting_date
                  : ''
              )
            }
            endDate={new Date()}
            onChange={(selectedDate: Date) => {
              setFieldValue(
                'public_healthcare_details.armed_forces_enlisting_date',
                formatDate(selectedDate)
              )
            }}
          />
        </FieldRow>
      </FormSection>
    </div>
  )
}

export default PersonalDetails
