import { gql } from '@apollo/client'

type AppointmentType = {
  id: string | null
}

export type AvailabilitySlotType = {
  id: string
  time: Date
  duration_minutes: number
  admin: boolean
  appointment: AppointmentType
}

type AvailabilitySlotFilterType = {
  consultantUUID: string
  startDate: string
  endDate: string
  clientCompatibleFlows: string
}

export interface ClinicianAvailabilityDataType {
  appointmentAvailabilitySlots: AvailabilitySlotType[]
}

export interface ClinicianAvailabilityFilterType {
  filter: AvailabilitySlotFilterType
}

export default gql`
  query GetClinicianAvailability($filter: AppointmentAvailabilitySlotsFilter) {
    appointmentAvailabilitySlots(filter: $filter) {
      id
      time
      duration_minutes
      admin
      appointment {
        id
      }
    }
  }
`
