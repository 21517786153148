import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import ReallocateShiftPage from './ReallocateShiftPage'

interface Props extends RouteComponentProps {}

const ReallocateShift = ({ match: { path } }: Props) => (
  <Switch>
    <Route exact path={path} component={ReallocateShiftPage} />
  </Switch>
)

export default ReallocateShift
