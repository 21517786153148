import { gql } from '@apollo/client'

export const fetchCommentsGQL = gql`
  query AP_Comments($patient_id: ID!, $pageIndex: Int) {
    comments(patient_id: $patient_id, pageIndex: $pageIndex) {
      pageIndex
      totalItems
      itemsPerPage
      items {
        id
        note
        created_at
        created_by {
          name
        }
      }
    }
  }
`
export const addCommentGQL = gql`
  mutation AP_AddComment($patient_id: ID!, $note: String!) {
    addComment(patient_id: $patient_id, note: $note) {
      message
    }
  }
`
