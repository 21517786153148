import { useMemo } from 'react'
import { useLocation, useHistory } from 'react-router'
import qs from 'qs'

// converts a get param search string to an object
export function paramsToObject<T = Object>(paramString: string): T {
  const parsedParams = qs.parse(paramString, { ignoreQueryPrefix: true })

  return Object.keys(parsedParams).reduce<T>((acc: T, key): T => {
    if (typeof parsedParams[key] !== 'undefined') {
      acc[key] = parsedParams[key]
    }

    return acc
  }, <T>{})
}

export function resetParams(history): void {
  history.push({
    search: '',
  })
}

// takes in an object with string values, converts it to a string, sets it as the get params
export function setQueryParams<T = Object>(params: T, history): void {
  const nextParams = Object.keys(params).reduce((acc, key) => {
    if (params[key] !== '') {
      acc[key] = params[key]
    }

    return acc
  }, {})

  history.push({
    search: qs.stringify(nextParams),
  })
}

// function overload types
interface NoDecoderOverload<T = Object> {
  defaultProps?: T | {}
  decoder?
}

interface WithDecoderOverload<T = Object, DecodedTypes = Object> {
  defaultProps?: T | {}
  decoder?: (paramsObject: T) => DecodedTypes
}

// function overloads
function useQueryParams<T = Object>({
  defaultProps,
  decoder,
}: NoDecoderOverload<T>): [T, (nextParams: T) => void, () => void]

function useQueryParams<T = Object, DecodedTypes = T>({
  defaultProps,
  decoder,
}: WithDecoderOverload<T, DecodedTypes>): [
  DecodedTypes,
  (nextParams: T) => void,
  () => void
]

// hook to get and set Url Get parameters as objects
function useQueryParams<T = Object, DecodedTypes = T>({
  defaultProps = {},
  decoder,
}: NoDecoderOverload<T> | WithDecoderOverload<T, DecodedTypes>): [
  T | DecodedTypes,
  (nextParams: T) => void,
  () => void
] {
  const location = useLocation()
  const history = useHistory()
  const params = useMemo(() => {
    const nextParams = {
      ...defaultProps,
      ...paramsToObject<T>(location.search),
    }

    if (decoder) {
      return decoder(nextParams)
    }

    return nextParams
  }, [location.search, defaultProps, decoder])
  const setParams = (newParams: T): void =>
    setQueryParams<T>({ ...defaultProps, ...newParams }, history)

  return [params, setParams, () => resetParams(history)]
}

export default useQueryParams
