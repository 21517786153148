import { defineMessages } from 'react-intl'

const baseId = 'assessment_call_to_actions'

export default defineMessages({
  unsupported: {
    id: `${baseId}.unsupported_action_error`,
    defaultMessage: 'Sorry this action is not supported.',
  },
})
