import { gql } from '@apollo/client'

type ConsultantType = {
  id: number
  name: string
}
export interface EligibleConsultantType {
  getEligibleConsultants: ConsultantType[]
}

export default gql`
  query FindClinicians($name: String) {
    getEligibleConsultants(name: $name) {
      id
      name
    }
  }
`
