import { defineMessages } from 'react-intl'

const baseId = 'member_send_registration_button'

export default defineMessages({
  send_registration_email: {
    id: `${baseId}.send_registration_email`,
    defaultMessage: 'Send registration email',
  },
  send_registration_email_success: {
    id: `${baseId}.send_registration_email_success`,
    defaultMessage: 'A registration email has been sent to {address}',
  },
  send_registration_email_error: {
    id: `${baseId}.send_registration_email_error`,
    defaultMessage:
      'The registration email could not be sent. Please try again later.',
  },
  send_registration_email_already_taken: {
    id: `${baseId}.send_registration_email_already_taken`,
    defaultMessage:
      'The email address you entered is already linked to an existing account. Please confirm with the patient and enter another email address.',
  },
  patient_email: {
    id: `${baseId}.patient_email`,
    defaultMessage: 'Patient email',
  },
  pre_registered_profile: {
    id: `${baseId}.pre_registered_profile`,
    defaultMessage:
      '<b>{name}</b> is currently a pre-registered profile. Please send them a registration email to access full patient options.',
  },
  send_email: {
    id: `${baseId}.send_email`,
    defaultMessage: 'Send email',
  },
  confirm_email_before_sending: {
    id: `${baseId}.confirm_email_before_sending`,
    defaultMessage:
      'Please confirm this email with the patient before sending.',
  },
})
