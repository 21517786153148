import { defineMessages } from 'react-intl'

const baseId = 'consultations_for_gps.consultations_for_gps'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Consultation summaries',
  },
  mark_unmark_all_button_label: {
    id: `${baseId}.mark_unmark_all_button_label`,
    defaultMessage:
      '{marked_as_sent, select, true {Un-mark all} false {Mark all}}',
  },
  mark_unmark_all_button_label_v2: {
    id: `${baseId}.mark_unmark_all_button_label_v2`,
    defaultMessage:
      '{marked_as_sent, select, true {Mark as Not Sent} false {Mark as Sent}}',
  },
  select_all_button_label: {
    id: `${baseId}.select_all_button_label`,
    defaultMessage:
      '{all_selected, select, true {Unselect all} false {Select all}}',
  },
  patient_search_criteria_too_broad_error_message: {
    id: `${baseId}.patient_search_criteria_too_broad_error_message`,
    defaultMessage:
      "Sorry, something's gone wrong. Please try the search again. We recommend entering more specific patient details.",
  },
  consultant_search_criteria_too_broad_error_message: {
    id: `${baseId}.consultant_search_criteria_too_broad_error_message`,
    defaultMessage:
      "Sorry, something's gone wrong. Please try the search again. We recommend entering more specific clinician details.",
  },
  filter_summary_filtered_items_meta_label: {
    id: `${baseId}.filter_summary_filtered_items_meta_label`,
    defaultMessage: 'filtered',
  },
  filter_summary_rows_per_page_label: {
    id: `${baseId}.filter_summary_rows_per_page_label`,
    defaultMessage: 'Show rows per page',
  },
  consultation: {
    id: `${baseId}.consultation`,
    defaultMessage: 'Consultation',
  },
  dates: {
    id: `${baseId}.dates`,
    defaultMessage: 'Dates',
  },
  consultation_id: {
    id: `${baseId}.consultation_id`,
    defaultMessage: 'Consultation ID',
  },
  consumer_network: {
    id: `${baseId}.consumer_network`,
    defaultMessage: 'Consumer Network',
  },
  consumer_network_placeholder: {
    id: `${baseId}.consumer_network_placeholder`,
    defaultMessage: 'Select consumer network...',
  },
  patient: {
    id: `${baseId}.patient`,
    defaultMessage: 'Patient',
  },

  family_name: {
    id: `${baseId}.family_name`,
    defaultMessage: 'Family name',
  },

  given_name: {
    id: `${baseId}.given_name`,
    defaultMessage: 'Given name',
  },

  gender: {
    id: `${baseId}.gender`,
    defaultMessage: 'Gender',
  },

  gender_placeholder: {
    id: `${baseId}.gender_placeholder`,
    defaultMessage: 'Select gender...',
  },

  male: {
    id: `${baseId}.male`,
    defaultMessage: 'Male',
  },
  female: {
    id: `${baseId}.female`,
    defaultMessage: 'Female',
  },

  patient_id: {
    id: `${baseId}.patient_id`,
    defaultMessage: 'Patient ID',
  },
  healthcare_id: {
    id: `${baseId}.healthcare_id`,
    defaultMessage: 'Healthcare ID',
  },

  filters: {
    id: `${baseId}.filters`,
    defaultMessage: 'Filters',
  },
  archived_appointments: {
    id: `${baseId}.archived_appointments`,
    defaultMessage: 'Marked as sent',
  },
  reset_filters: {
    id: `${baseId}.reset_filters`,
    defaultMessage: 'Reset filters',
  },
  apply_filters: {
    id: `${baseId}.apply_filters`,
    defaultMessage: 'Apply filters',
  },
  mark_as_sent_confirmation: {
    id: `${baseId}.mark_as_sent_confirmation`,
    defaultMessage:
      'Are you sure you wish to update all selected items mark as sent status?',
  },
})
