import React, { useReducer, Dispatch, SetStateAction } from 'react'
import type { MessageDescriptor } from 'react-intl'

import { ParsedQs } from 'qs'
import { RouteComponentProps, withRouter } from 'react-router'

import { useFormatMessage } from '@babylon/intl'

import { ApolloError } from '@apollo/client'
import { useProductConfig } from '@babylon/product-config'
import {
  activeWorkflowsQueryOptions,
  patientHistoryQueryOptions,
  outstandingRepeatTemplateQueryOptions,
  upcomingActivitiesQueryOptions,
  useCookiePersistedState,
} from '../..'
import Queries from './data'
import reducer, { initialState, Params } from './reducer'
import * as actions from './reducer/actions'
import MembersSearchView from './MembersSearchView'

import { useSearchFeaturesContext } from './SearchFeaturesContext'
import messages from './MembersSearchView.messages'
import { errorListFromQueryError } from '../../util/helpers'
import { useSupportInteractionQuery } from '../PlatformGatewaySearch/hooks'

export interface MembersSearchWrapperProps extends RouteComponentProps {
  goToPlatformGateway: VoidFunction
  goToCoreRuby: VoidFunction
}

const MAX_32BIT_UNSIGNED_INT = 2147483648

export const parseErrors = (error: ApolloError) => {
  const graphQLErrors = errorListFromQueryError(error)

  return graphQLErrors.map(
    (err) => err?.response?.error?.message ?? err.message
  )
}

const findSearchErrors = (params: { id?: string | null } = {}, fm): any[] => {
  const errors: MessageDescriptor[] = []

  if (params.id && typeof parseInt(params.id, 10) !== 'number') {
    errors.push(fm(messages.invalid_id))
  }

  if (params.id && parseInt(params.id, 10) > MAX_32BIT_UNSIGNED_INT) {
    errors.push(
      fm(messages.number_too_large, { limit: MAX_32BIT_UNSIGNED_INT })
    )
  }

  if (!Object.entries(params).some((keyValue) => !!keyValue[1])) {
    errors.push(fm(messages.missing_filter))
  }

  return errors
}

const checkAndSetCookieQuery = (
  cookieValue: boolean,
  setCookieValue: Dispatch<SetStateAction<boolean>>,
  name: string,
  queryParams: ParsedQs,
  query: any
) => {
  if (queryParams[name] === 'true' && !cookieValue) {
    setCookieValue(true)
  } else if (queryParams[name] === 'false' && cookieValue) {
    setCookieValue(false)
  }

  if (cookieValue && query) {
    query[name] = true
  }
}

const useSearchProps = ({
  location: { search, pathname, state: locationState },
}: RouteComponentProps) => {
  const path = `${pathname}${search}`

  const [state, dispatch] = useReducer(reducer, initialState)

  const mapStateToProps = {
    ...state,
    page: path,
    query: state.searchParams,
  }

  const { loading: supportInteractionLoading } = useSupportInteractionQuery({
    onCompleted: ({ supportInteraction }) => {
      const { email, phoneNumber } = supportInteraction ?? {}

      // eslint-disable-next-line camelcase
      dispatch(
        actions.updateForm({
          email,
          with_country_code: phoneNumber,
        })
      )

      dispatch(actions.submitSearch({ email, with_country_code: phoneNumber }))
    },
    onError: (error) => dispatch(actions.setErrors(parseErrors(error))),
  })

  const mapDispatchToProps = {
    completeSearch: (data: any) => {
      if (!data || !data.searchPatients) {
        return
      }

      if (state.isLoading || supportInteractionLoading) {
        dispatch(actions.completeSearch())
      }
    },
    submitSearch: (params: Params, fm: (messageDescriptor: any) => string) => {
      const errors = findSearchErrors(params, fm)

      if (errors.length === 0) {
        dispatch(actions.submitSearch(params))
      } else {
        dispatch(actions.setErrors(errors))
      }
    },
    updatePage: (page: number) => dispatch(actions.updatePage(page)),
    updateRowsPerPage: (perPage: number) =>
      dispatch(actions.updateRowsPerPage(perPage)),
    updateField: (name: string, value: any) =>
      dispatch(actions.updateField(name, value)),
    fillFormWithCache: () => dispatch(actions.updateForm(locationState)),
    clearSearch: () => {
      dispatch(actions.clearSearch())
    },
  }

  return {
    ...mapStateToProps,
    ...mapDispatchToProps,
  }
}

export default withRouter((props: MembersSearchWrapperProps) => {
  const {
    clearSearch,
    completeSearch,
    errors,
    params,
    query,
    searchableFields,
    submitSearch,
    updateField,
    updatePage,
    updateRowsPerPage,
  } = useSearchProps(props)

  const fm = useFormatMessage()

  const [
    searchUsingProfilesService,
    setSearchUsingProfilesService,
  ] = useCookiePersistedState('mop-search-using-profiles-service', false)
  const [searchWithUserToken, setSearchWithUserToken] = useCookiePersistedState(
    'mop-search-with-user-access-token',
    false
  )

  checkAndSetCookieQuery(
    searchUsingProfilesService,
    setSearchUsingProfilesService,
    'mop_search_using_profiles_service',
    {},
    query
  )
  checkAndSetCookieQuery(
    searchWithUserToken,
    setSearchWithUserToken,
    'mop_search_with_user_access_token',
    {},
    query
  )

  const { enablePreFetch } = useSearchFeaturesContext()
  const { getProp } = useProductConfig()
  const includeWorkflowsEnabled = getProp(
    'memberOperations',
    'includeWorkflowsEnabled'
  )
  const memberTimelineRepeatPrescriptionsEnabled = getProp(
    'memberOperations',
    'memberTimelineRepeatPrescriptionsEnabled'
  )

  return (
    <Queries variables={{ query }} onCompletedFormSearch={completeSearch}>
      {({ data, loading, error, client }: any) => {
        if (!loading && data) {
          const patients = data?.searchPatients?.patients ?? []

          if (enablePreFetch && patients.length === 1) {
            const patientId = patients[0].id
            client.query(
              patientHistoryQueryOptions({
                patientId,
              })
            )

            if (includeWorkflowsEnabled) {
              client.query(
                activeWorkflowsQueryOptions(patientId, includeWorkflowsEnabled)
              )
            }

            client.query(upcomingActivitiesQueryOptions(patientId))

            // skip is not a valid property in ApolloClient.query
            if (memberTimelineRepeatPrescriptionsEnabled) {
              client.query(outstandingRepeatTemplateQueryOptions(patientId))
            }
          }
        }

        return (
          <MembersSearchView
            data={data}
            loading={loading}
            errors={error ? [...errors, parseErrors(error)] : errors}
            params={params}
            searchableFields={searchableFields}
            onSearch={(searchParams) => submitSearch(searchParams, fm)}
            onPageChange={updatePage}
            onRowsPerPageChange={updateRowsPerPage}
            onParamChange={updateField}
            onClear={clearSearch}
            goToPlatformGateway={props.goToPlatformGateway}
            goToCoreRuby={props.goToCoreRuby}
          />
        )
      }}
    </Queries>
  )
})
