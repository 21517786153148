import { useRef, useEffect } from 'react'

export default function useScrollTo(scrollTo: number) {
  const elementRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!elementRef.current) {
      return
    }

    elementRef.current.scrollTo(0, scrollTo)
  }, [scrollTo])

  return elementRef
}
