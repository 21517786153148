import { ConfirmationModal, KebabMenuRenderOptions } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import messages from './ProfileHeader.messages'

import { DELETE_MFA_ENROLLMENT, MFA_GQL } from './queries'
import { logException } from '../../../..'
import { MemberOpsModuleName } from '../../../../..'

export interface DeleteSmsMfaButtonProps extends KebabMenuRenderOptions {
  smsMfaEnrollmentId: string
  setSnackbarMessage: (m: string) => void
  patientUuid: string
}

export const DeleteSmsMfaButton = ({
  smsMfaEnrollmentId,
  menuItemProps,
  setSnackbarMessage,
  patientUuid,
}: DeleteSmsMfaButtonProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profilesHeader,
  })
  const fm = useFormatMessage()
  const [showModal, setShowModal] = useState(false)
  const [deleteSmsMfaEnrollment] = useMutation(DELETE_MFA_ENROLLMENT, {
    context: { clientName: 'platform-gateway' },
    onCompleted: () => {
      setSnackbarMessage(fm(messages.disable_sms_mfa_success))
    },
    onError: (e) => {
      logException(e)
      setSnackbarMessage(fm(messages.disable_sms_mfa_failure))
    },
    refetchQueries: [
      {
        query: MFA_GQL,
        variables: {
          id: patientUuid,
          first: 1,
        },
        context: { clientName: 'platform-gateway' },
      },
    ],
    awaitRefetchQueries: true,
  })

  return (
    <>
      <ConfirmationModal
        confirmText={<span>{fm(messages.disable_sms_mfa_confirmation)}</span>}
        onClose={() => {
          setShowModal(false)
          trackClick({
            elementName: 'cancel-disable-mfa-button',
            elementType: TrackingElementType.button,
          })
        }}
        onConfirm={() => {
          setShowModal(false)
          deleteSmsMfaEnrollment({ variables: { id: smsMfaEnrollmentId } })
          trackClick({
            elementName: 'confirm-disable-mfa-button',
            elementType: TrackingElementType.button,
          })
        }}
        open={showModal}
        includeClose={false}
      >
        <ConfirmationModal.Heading>
          {fm(messages.disable_sms_mfa_modal_header)}
        </ConfirmationModal.Heading>
        <ConfirmationModal.Content>
          <p>{fm(messages.disable_sms_mfa_modal_content)}</p>
        </ConfirmationModal.Content>
      </ConfirmationModal>
      <button
        {...menuItemProps}
        onClick={() => {
          setShowModal(true)
          trackClick({
            elementName: 'disable-mfa-button',
            elementType: TrackingElementType.button,
          })
        }}
        data-testid="disable-sms-mfa-button"
      >
        {fm(messages.disable_sms_mfa_menu_option)}
      </button>
    </>
  )
}

export default DeleteSmsMfaButton
