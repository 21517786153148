import React from 'react'
import { DatePicker, registerLocale } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'
import enGB from 'date-fns/locale/en-GB'

import styles from '../styles.module.css'

export interface WeekCalendarProps {
  selectedWeek: {
    start: string
    end: string
  }
  selectedWeekUpdate: (arg: Date | null) => {}
}

registerLocale('en-GB', enGB)

export default function WeekCalendar({
  selectedWeek,
  selectedWeekUpdate,
}: WeekCalendarProps) {
  const intl = useIntl()

  return (
    <div data-testid="week-calendar-view">
      <DatePicker
        calendarClassName={styles.calendar}
        dayClassName={() => styles.calendarDay}
        inline
        locale={intl.locale}
        onChange={(date) => selectedWeekUpdate(date)}
        minDate={new Date()}
        startDate={new Date(selectedWeek.start)}
        endDate={new Date(selectedWeek.end)}
      />
    </div>
  )
}
