import * as Yup from 'yup'

const validation = Yup.object().shape({
  consultation_type: Yup.string().required(),
  consultant_id: Yup.string().when('consultation_type', {
    is: 'physical',
    then: Yup.string()
      .notOneOf(['any'], 'Please choose the clinician for the appointment')
      .required('Please choose the clinician for the appointment'),
    otherwhise: Yup.string(),
  }),
  appointment_reason: Yup.string().required(
    'Please enter an appointment reason'
  ),
  chaperone_required: Yup.boolean().default(false),
  chaperone_reason: Yup.string()
    .when('chaperone_required', {
      is: true,
      then: Yup.string()
        .trim()
        .required("GraphQL error: Chaperone reason can't be empty"),
    })
    .default(''),
  language_required: Yup.boolean(),
  language_requested: Yup.string(),
})

export default validation
