import { defineMessages } from 'react-intl'

const baseId = 'member_view'

export default defineMessages({
  banner: {
    id: `${baseId}.banner`,
    defaultMessage:
      'NEW MEMBER DETAILS – Click this banner to try the simplified member details. You can switch back at any time.',
  },
  switch_new_view: {
    id: `${baseId}.switch_new_view`,
    defaultMessage: 'Try new member details',
  },
  access_denied: {
    id: `${baseId}.access_denied`,
    defaultMessage: 'Access denied',
  },
  patient_not_found: {
    id: `${baseId}.patient_not_found`,
    defaultMessage: 'Patient with id {patientId} was not found',
  },
  forbidden: {
    id: `${baseId}.forbidden`,
    defaultMessage:
      '403 Forbidden: You do not have the correct access permissions to view this member',
  },
  patient_activity_title: {
    id: `${baseId}.patient_activity_title`,
    defaultMessage: 'Patient activity',
  },
  conversation_history_title: {
    id: `${baseId}.conversation_history_title`,
    defaultMessage: 'Conversation history',
  },
  conversation_filter_live_chat: {
    id: `${baseId}.conversation_filter_live_chat`,
    defaultMessage: 'Live chat',
  },
  conversation_filter_support: {
    id: `${baseId}.conversation_filter_support`,
    defaultMessage: 'Support',
  },
  members_time: {
    id: `${baseId}.members_time`,
    defaultMessage: "Member's time",
  },
  members_time_unknown: {
    id: `${baseId}.members_time_unknown`,
    defaultMessage: 'Unknown timezone',
  },
  members_time_same_as_agent: {
    id: `${baseId}.members_time_same_as_agent`,
    defaultMessage: 'Same timezone as you',
  },
  live_conversation_history_title: {
    id: `${baseId}.live_conversation_history_title`,
    defaultMessage: 'Live conversations',
  },
  document_repository_title: {
    id: `${baseId}.document_repository_title`,
    defaultMessage: 'Patient documents',
  },
})
