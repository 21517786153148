import { useFormatMessage } from '@babylon/intl'
import React from 'react'
import messages from './PaginationTotalCount.messages'
import styles from './PaginationTotalCount.module.css'
import type { PaginationTotalCountProps } from './PaginationTotalCount.types'

export const PaginationTotalCount = ({
  totalCount,
}: PaginationTotalCountProps) => {
  const fm = useFormatMessage()

  return (
    <span data-testid="pagination-total-count">
      {fm(messages.totalResults, {
        totalCount,
        element: <span className={styles.paginationTotal}>{totalCount}</span>,
      })}
    </span>
  )
}

export default PaginationTotalCount
