import { defineMessages } from 'react-intl'

const baseId = 'assessment_error'

export default defineMessages({
  description: {
    id: `${baseId}.assessment_stage_description`,
    defaultMessage:
      'There was an error loading this assessment. Please reload or try again later.',
  },
  reload: {
    id: `${baseId}.assessment_stage_reload_button`,
    defaultMessage: 'Reload',
  },
})
