import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useProductConfig } from '@babylon/product-config'
import styles from './AppBar.module.scss'
import messages from './AppBar.messages'

interface Props {
  leadingSection?: () => JSX.Element | null
  trailingSection?: () => JSX.Element | null
}

const AppBar = ({ leadingSection, trailingSection }: Props) => {
  const fm = useFormatMessage()
  const { getProp } = useProductConfig()
  const telusLogoVariant = getProp('memberOperations', 'telusLogoVariant')

  return (
    <div className={styles.appBar__wrapper}>
      <div>{leadingSection && leadingSection()}</div>
      <div>
        <img
          alt={fm(messages.logo_alt)}
          src={
            '/images/' + (telusLogoVariant ? 'logo_telusheart.png' : 'logo.png')
          }
          className={styles.appBar__logo}
        />
      </div>
      <div>{trailingSection && trailingSection()}</div>
    </div>
  )
}

export default AppBar
