import { DropdownSelectOption } from '@babylon/core-ui'

export function formatCancellationReasons(data: {
  adminCancelReasons: { id: string; reason: string }[]
}): DropdownSelectOption[] {
  if (!data || data.adminCancelReasons.length === 0) {
    return []
  }

  return data.adminCancelReasons.map(
    ({ id, reason }: { id: string; reason: string }) => {
      const formatted = {
        key: id,
        value: reason,
      }
      return formatted
    }
  )
}
