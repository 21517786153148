import { gql } from '@apollo/client'

export default gql`
  query getConsultantsPerId($id: [ID]) {
    getConsultantsPerId(id: $id) {
      id
      name
    }
  }
`
