import React, { FC } from 'react'
import classnames from 'classnames/bind'
import styles from './styles.module.scss'

export interface ViewMoreTogglerProps {
  open: React.AriaAttributes['aria-expanded']
  testId: string
  onToggle?(): void
  mode?: 'light' | 'dark'
  size?: 'small' | 'default'
  className?: string
  ariaControls?: React.AriaAttributes['aria-controls']
}

const cx = classnames.bind(styles)

const ViewMoreToggler: FC<ViewMoreTogglerProps> = ({
  children,
  mode = 'light',
  open,
  size = 'default',
  testId,
  onToggle,
  className,
  ariaControls,
}) => (
  <button
    aria-controls={ariaControls}
    aria-expanded={open}
    className={cx('button', className, {
      'button--dark': mode === 'dark',
      'button--open': open,
      'button--small': size === 'small',
      'button--no-content': !children,
    })}
    disabled={!onToggle}
    data-testid={testId}
    type="button"
    onClick={onToggle}
  >
    {children}
  </button>
)

export default ViewMoreToggler
