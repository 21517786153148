import React, { FC, useMemo, useCallback } from 'react'
import {
  Tag,
  KebabMenu,
  KebabMenuRenderOptions,
  Button,
} from '@babylon/core-ui'
import { getTagText } from './utils'
import styles from './RelatedPerson.module.scss'

export interface RelatedPersonProps {
  relationId: string
  id?: string
  actions: any
  type: string
  isPrimary: boolean
  profile: {
    last_name: string
    first_name: string
  }
  testid: string
}

const RelatedPerson: FC<RelatedPersonProps> = ({
  relationId,
  id,
  type,
  profile,
  isPrimary,
  actions,
  testid,
}) => {
  const tagText = useMemo(
    () =>
      getTagText({
        type: type.split('.')[1],
        isCarer: !!id,
        isPrimary,
      }),
    [id, type, isPrimary]
  )
  const renderMenu = useCallback(
    (actions) => (
      <KebabMenu>
        {({ menuItemProps, closeMenu = () => {} }: KebabMenuRenderOptions) =>
          actions.map((action: any) => (
            <div key={action.label}>
              <Button
                dataTestId="moreOptions"
                onClick={() => {
                  // close kebab menu
                  closeMenu()
                  // run click handler
                  action.handler(relationId)
                }}
                disabled={action.disabled}
                {...menuItemProps}
              >
                {action.label}
              </Button>
            </div>
          ))
        }
      </KebabMenu>
    ),
    [relationId]
  )

  return (
    <div className={styles.RelatedPerson} data-testid={testid}>
      <div className={styles.RelatedPerson__header}>
        <Tag className={styles.RelatedPerson__type}>{tagText}</Tag>
        {actions.length > 0 && renderMenu(actions)}
      </div>
      <p className={styles.RelatedPerson__info}>
        {profile.last_name}, {profile.first_name}
      </p>
    </div>
  )
}
export default RelatedPerson
