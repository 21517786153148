const EMPTY_VIEW_COPY = {
  first: {
    button: 'Choose first clinician',
    subtext:
      'This is the clinician who cannot attend their existing appointments',
  },
  covering: {
    button: 'Choose covering clinician',
    subtext:
      'This is the clinician who will take on the first clinican’s appointments',
  },
}

export default EMPTY_VIEW_COPY
