import React, { useState, useRef } from 'react'
import { Grid, Cell, FormikDatePicker } from '@babylon/core-ui'
import { useBabylonUser, User } from '@babylon/babylon-user'
import { validateYupSchema } from 'formik'
import { format } from 'date-fns'
import { getTimezoneGMT } from '../../Utils'
import { SearchForm } from '../..'
import { useQueryParams } from '../../hooks'
import ClinicianVisibilityValidation, {
  fieldLabelMap,
} from './ClinicianVisibilitySearchValidation'

import { ClinicianIdInput } from './ClinicianIdInput'
import ServiceTypeSelect from './ServiceTypeSelect'
import { ClinicNameFilter } from './ClinicNameFilter'
import { ProfessionsSelector } from './ProfessionsSelector'
import { ClinicianNameInput } from './ClinicianNameInput'

export interface ClinicianVisibilitySearchFormTypes {
  clinicianId: string[]
  consultantId: string[]
  clinicId: string[]
  profession: string[]
  dateTimeFrom: string | Date
  serviceType: string[]
}

export default function ClinicianVisibilitySearch() {
  const { timezone } = useBabylonUser() as User

  const [
    params,
    setParams,
    resetParams,
  ] = useQueryParams<ClinicianVisibilitySearchFormTypes>({
    defaultProps: {
      dateTimeFrom: '',
      consultantId: [],
      clinicianId: [],
      clinicId: [],
      profession: [],
      serviceType: [],
    },
    decoder: (values) => ({
      ...values,
      dateTimeFrom: values?.dateTimeFrom ?? '',
    }),
  })

  const getNextFormValues = (formValues) => {
    const { clinicianInfo, clinicId, ...rest } = formValues
    const nextForm = {
      ...params,
      ...rest,
      consultantId: clinicianInfo.map(({ id }) => id),
      clinicId: clinicId.map(({ id }) => id),
    }

    if (formValues?.dateTimeFrom) {
      // Formatting dateTimeFrom so it doesn't contain timezone info
      nextForm.dateTimeFrom = format(
        formValues.dateTimeFrom,
        'yyyy-MM-dd HH:mm:ss'
      )
    }

    return nextForm
  }

  const [errorMessage, setErrorMessage] = useState('')
  const clinicianIdRef = useRef<HTMLInputElement | null>(null)

  const clearErrorMessage = () => {
    setErrorMessage('')
  }

  const onSubmit = async (values, { setErrors }) => {
    clearErrorMessage()
    let newValues = values

    if (clinicianIdRef?.current?.value) {
      /*
       * When an input is set and the user submits the form, we need to set that input value as a tag.
       * Here it's copying that value into what will be validated and set in the url
       */
      newValues = {
        ...values,
        clinicianId: [
          ...new Set([...values.clinicianId, clinicianIdRef?.current?.value]),
        ],
      }

      clinicianIdRef.current.value = ''
    }

    /*
     * Running validation again on submit because formik only sets errors per field
     * and not on the form. The API requires some fields to be set, checking if at least the minimum
     * fields are set.
     */
    try {
      await validateYupSchema(newValues, ClinicianVisibilityValidation, true)

      setParams({ ...getNextFormValues(newValues) })
    } catch (e) {
      const formErrorMessage = e?.errors?.[0] || ' '
      setErrors(
        Object.keys(fieldLabelMap).reduce((acc, field) => {
          acc[field] = formErrorMessage

          return acc
        }, {})
      )

      setErrorMessage(e.message)
    }
  }

  const onReset = () => {
    clearErrorMessage()
    resetParams()
  }

  return (
    <SearchForm
      data-testid="clinician-visibility-search-form"
      initialValues={{
        clinicianId: params.clinicianId,
        clinicianInfo: params.consultantId.map((id) => ({
          id,
          name: '',
        })),
        clinicId: params.clinicId.map((id) => ({
          id,
          name: '',
        })),
        profession: params.profession,
        dateTimeFrom: params.dateTimeFrom ? new Date(params.dateTimeFrom) : '',
        serviceType: params.serviceType,
      }}
      errorMessage={errorMessage}
      onSubmit={onSubmit}
      resetForm={onReset}
      validationSchema={ClinicianVisibilityValidation}
      enableReinitialize
    >
      <Grid columns="7" gap="20">
        <Cell width="2">
          <FormikDatePicker
            data-testid="dateTime-from"
            name="dateTimeFrom"
            id="dateTimeFrom"
            label={`Date/Time ${getTimezoneGMT(new Date(), timezone)}`}
            required
            showTimeSelect
          />
        </Cell>
        <Cell width="5" />
        <Cell width="2">
          <ClinicianNameInput />
        </Cell>
        <Cell width="2">
          <ProfessionsSelector />
        </Cell>
        <Cell width="2">
          <ClinicNameFilter />
        </Cell>
        <Cell width="2">
          <ClinicianIdInput inputRef={clinicianIdRef} />
        </Cell>
        <Cell width="2">
          <ServiceTypeSelect />
        </Cell>
      </Grid>
    </SearchForm>
  )
}
