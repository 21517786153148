import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { useFormatMessage } from '@babylon/intl'

import {
  getPgmAssessment,
  submitRating,
  undoLastChatscriptMessage,
} from '../api'
import Layout from '../components/Layout'
import Overlay from '../components/Overlay'
import QuestionCard from '../components/QuestionCard'
import SlideTransition from '../components/SlideTransition'
import {
  AppviewActionTypes,
  CallToActionTypes,
  LocationActionTypes,
} from '../constants/enums'
import {
  AppviewActionMessage,
  ContextInterface,
  ContextStatus,
  GoToQuestionInterface,
  QuestionOptionInterface,
  QuestionInterface,
  conversationHistoryInterface,
  triageOutcomeInterface,
  PGMReportInterface,
} from '../constants/types'
import conditionSearch from '../questions/conditionSearch'
import findNearby from '../questions/findNearby'
import sendFeedback from '../questions/sendFeedback'
import { setStarRating } from '../redux/actions'
import actionMessages from '../util/callToActions/messages'
import {
  trackChatbotExited,
  trackConversationGoBack,
  trackFeedbackDisplayed,
  trackRatingSubmitted,
} from '../util/tracking'
import styleValueToNumber from '../util/styleValueToNumber'
import authAccessor from '../util/auth'
import {
  getCTA,
  trackCTAClick,
  trackCTAImpression,
} from '../util/tracking/trackCTAs'

import styles from './Chatbot.module.scss'

const TRANSITION_DURATION = styleValueToNumber(styles.transitionDuration)

let localImpressionHistory: { [key: string]: number } = {}

const originalWindowOpen = window.open

const isFlowOutcome = (option: QuestionOptionInterface) =>
  !!(option.action || option.params || option.type !== 'text')

const Chatbot = ({
  Accessory,
  actionHandler,
  answeredQuestions,
  context,
  didGoBack,
  goBack,
  goToNewConversation,
  goToQuestion,
  goToStart,
  initialQuestion,
  loading,
  LogoComponent,
  logoUrl,
  onConversationStatusChange,
  onTriageOutcome,
  onGoToStart,
  submitAnswer,
  question,
  setLoading,
  startInOverlay = false,
  SummaryAction,
}: Props) => {
  const auth = authAccessor()
  const [navigatingBack, setNavigatingBack] = useState(false)
  const [previousAnswer, setPreviousAnswer] = useState()
  const [ratedQuestions, setRatedQuestions] = useState<Record<string, boolean>>(
    {}
  )
  const [value, setValue] = useState<any>()
  const translate = useFormatMessage()
  const dispatch = useDispatch()

  const onOptionImpression = (option: QuestionOptionInterface) => {
    // Track impressions for CTAs & block CTA ID's being tracked on re-renders
    const cta = getCTA(option)

    if (isFlowOutcome(option) && cta && !localImpressionHistory[cta.action]) {
      localImpressionHistory[cta.action] = Date.now()
      trackCTAImpression(option, context.conversationId)
    }
  }

  useEffect(() => {
    const performEffect = async () => {
      const isTokenValid = await auth.tokenIsValid()
      const canTokenBeRefreshed = await auth.tokenCanBeRefreshed()

      if (!isTokenValid && canTokenBeRefreshed) {
        await auth.refreshToken()
      }
    }

    performEffect()
  }, [auth])

  useEffect(() => {
    if (question.value && !value) {
      setValue(question.value)
    }
  }, [question, value])

  const onBeforeUnload = useCallback(
    (event) => {
      const isFinished = question.progress === 1 || question.pgmReport

      if (answeredQuestions.length > 0 && !isFinished) {
        event.returnValue =
          'Are you sure you want to leave? Your conversation has not finished.'
      }
    },
    [answeredQuestions, question]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [onBeforeUnload])

  useEffect(() => {
    if (context && context.status && onConversationStatusChange) {
      onConversationStatusChange(context.status)
    }
  }, [context, onConversationStatusChange])

  useEffect(() => {
    if (onTriageOutcome && context.status === 'finished') {
      const answers: [
        { id: string; label: string; labelInternal: string }
      ] = answeredQuestions
        .map(({ question }: any) =>
          question.question.options.map(
            ({
              id,
              label,
              labelInternal,
            }: {
              id: string
              label: string
              labelInternal: string
            }) => ({
              id,
              label,
              labelInternal,
            })
          )
        )
        .flat(2)
      const getAnswer = (answerId: string, internal: boolean) =>
        answers
          .filter(
            (x: { id: string; label: string; labelInternal: string }) =>
              x.id === answerId
          )
          .map(
            ({
              label,
              labelInternal,
            }: {
              label: string
              labelInternal: string
            }) => (internal ? labelInternal : label)
          )
      const getAnswers = (
        answerObject:
          | { text: string; textInternal: string }
          | string[]
          | string,
        internal: boolean
      ) => {
        if (Array.isArray(answerObject)) {
          // array of answersIds
          return answerObject.map((answerId) => getAnswer(answerId, internal))
        } else if (typeof answerObject === 'object') {
          // object
          return [internal ? answerObject.textInternal : answerObject.text]
        } else {
          // single answerId
          return getAnswer(answerObject, internal)
        }
      }
      const triggerOnTriageOutcome = (
        pgmReport: PGMReportInterface,
        pgmReportInternal: PGMReportInterface
      ) => {
        const conversationHistory: [
          conversationHistoryInterface
        ] = answeredQuestions.map(({ question, answer }: any) => ({
          question: question.question.text,
          questionInternal: question.question.textInternal,
          answers: getAnswers(answer, false),
          answersInternal: getAnswers(answer, true),
        }))
        onTriageOutcome(
          {
            conversationHistory,
            pgmReport,
          },
          {
            conversationHistory,
            pgmReport: pgmReportInternal,
          }
        )
      }

      const getPgmReport: Promise<PGMReportInterface> = question.pgmReport
        ? Promise.resolve(question.pgmReport)
        : getPgmAssessment({
            conversationId: context.conversationId,
            internal: false,
          })
      const getPgmReportInternal = getPgmAssessment({
        conversationId: context.conversationId,
        internal: true,
      })
      Promise.all([getPgmReport, getPgmReportInternal]).then(
        ([pgmReport, pgmReportInternal]) => {
          triggerOnTriageOutcome(pgmReport, pgmReportInternal)
        }
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answeredQuestions, context, onTriageOutcome])

  const handleGoToStart = useCallback(() => {
    setValue(undefined)
    trackChatbotExited(context.conversationId)

    if (onGoToStart) {
      onGoToStart(context)
    } else {
      goToStart()
    }
  }, [context, goToStart, onGoToStart])

  const handleGoBack = useCallback(async () => {
    const previousAnsweredQuestion = answeredQuestions.slice(-1)[0]
    const previousContext =
      previousAnsweredQuestion &&
      previousAnsweredQuestion.question.conversationContext

    const createdElementId = previousContext && previousContext.createdElementId
    const conversationId = previousContext && previousContext.conversationId

    localImpressionHistory = {}
    setValue(undefined)
    setPreviousAnswer(undefined)
    setLoading(true)
    setNavigatingBack(true)

    if (conversationId && createdElementId) {
      const { lastEtag, memberUuid } = context
      const { etag } = await undoLastChatscriptMessage({
        conversationId,
        etag: lastEtag,
        memberUuid,
      })
      /**
       * chatscript uses etags to maintain state consistency between client & server. See
       * https://developers.babylonpartners.com/rest-apis/guides/chatbot#state-consistency-with-etags for details
       *
       * When `goBack()` is called, the state on the client will no longer match that on the server, we manually set it here.
       *
       * TODO:CW-1562 - Investigate a more sensible and sustainable approach to managing state within chatbot-web
       */
      previousContext.lastEtag = etag
    }

    setLoading(false)
    setNavigatingBack(false)
    trackConversationGoBack(context.conversationId)
    goBack()
  }, [answeredQuestions, context, goBack, setLoading])

  const handleFeedbackClick = useCallback(() => {
    goToQuestion(sendFeedback(translate))
    trackFeedbackDisplayed(context.conversationId)
  }, [context, goToQuestion, translate])

  const handleStarRatingSubmit = useCallback(
    ({ rating, comment = '' }: { rating: number; comment?: string }) => {
      dispatch(setStarRating(rating))

      trackRatingSubmitted(rating)

      if (!context) {
        return
      }

      setRatedQuestions({
        ...ratedQuestions,
        [context.elementId]: true,
      })

      submitRating({
        conversationId: context.conversationId,
        messageId: context.elementId,
        rating,
        comment,
        etag: context.lastEtag,
      })
    },
    [context, dispatch, ratedQuestions]
  )

  const answerQuestion = useCallback(
    (_answer: any) => {
      const answer = _answer?.target?.value || _answer || value

      if (!answer) {
        throw new Error('Tried to answer question with an empty value/answer')
      }

      submitAnswer(answer)
      setValue(undefined)
      setPreviousAnswer(answer)
    },
    [submitAnswer, value]
  )

  const defaultOptionSelectHandler = useCallback(
    async ({
      option,
    }: {
      context: ContextInterface
      option: QuestionOptionInterface
    }) => {
      const { action, type: optionType, value: selectedValue, params } = option

      localImpressionHistory = {}

      if (optionType === 'location') {
        switch (params?.target) {
          case LocationActionTypes.FindPlaceEmergencyRoom:
          case LocationActionTypes.FindPlaceEyeCasualty:
          case LocationActionTypes.FindPlaceHospital:
          case LocationActionTypes.FindPlacePharmacy:
          case LocationActionTypes.FindPlaceSTDClinic:
          case LocationActionTypes.FindPlaceUrgentCare:
            goToQuestion(findNearby(params.target, translate))
            return

          default:
            break
        }
      }

      if (action) {
        switch (action.type) {
          case CallToActionTypes.Appview: {
            const appViewAction = action as AppviewActionMessage

            switch (appViewAction.data.view) {
              case AppviewActionTypes.BookConsultation: {
                console.warn(
                  '"book-consultation" CTA must be handled by the consuming app'
                )

                break
              }

              case AppviewActionTypes.MonitorCovidCarePlan:
                originalWindowOpen(
                  'https://www.babylonhealth.com/download-babylon-app?pid=Email&c=COVID19PLAN',
                  '_blank',
                  'noopener,noreferrer'
                )

                break

              case AppviewActionTypes.StartConversation: {
                goToNewConversation(
                  appViewAction.data.parameters?.type,
                  appViewAction.data.parameters?.data
                )

                break
              }

              default:
                answerQuestion(selectedValue)
            }

            break
          }
          case CallToActionTypes.Phone:
          case CallToActionTypes.CallSamaritans:
            // eslint-disable-next-line no-alert
            if (window.confirm(action.data.confirmation_text)) {
              originalWindowOpen(`tel://${action.data.number}`)
            }

            break
          case CallToActionTypes.Email:
            originalWindowOpen(`mailto:${action.data.email}`)

            break
          case CallToActionTypes.Ask:
            answerQuestion(selectedValue)

            break
          case CallToActionTypes.SearchConditions: {
            goToQuestion(conditionSearch(translate))

            break
          }

          case CallToActionTypes.URL:
          case CallToActionTypes.WebView:
            originalWindowOpen(action.data.url, '_blank', 'noopener,noreferrer')

            break
          default:
            answerQuestion(selectedValue)
        }

        return
      }

      answerQuestion(selectedValue)
    },
    [answerQuestion, goToNewConversation, goToQuestion, translate]
  )

  const handleOptionSelect = useCallback(
    async (option: QuestionOptionInterface) => {
      const handler = actionHandler || defaultOptionSelectHandler

      if (isFlowOutcome(option)) {
        trackCTAClick(option, context.conversationId)
      }

      handler({
        context,
        handleAsDefault: defaultOptionSelectHandler,
        option,
        question,
      })
    },
    [actionHandler, context, defaultOptionSelectHandler, question]
  )

  useEffect(() => {
    // @ts-ignore
    window.open = (url: string) => {
      const telPrefix = 'tel://'

      if (url.startsWith(telPrefix)) {
        handleOptionSelect({
          action: {
            data: {
              number: url.replace(telPrefix, ''),
              confirmation_text: translate(
                actionMessages.callEmergencyServices
              ),
            },
            type: CallToActionTypes.Phone,
          },
          label: '',
          value: '',
        })
      } else {
        handleOptionSelect({
          action: { data: { url }, type: CallToActionTypes.URL },
          label: '',
          value: '',
        })
      }
    }
  }, [handleOptionSelect, translate])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line global-require
      const devAutoAnswer = require('../devAutoAnswer')
      devAutoAnswer(question, handleOptionSelect, answerQuestion)
    }
  }, [answerQuestion, handleOptionSelect, question])

  const hasRatedQuestion = question.id ? ratedQuestions[question.id] : false
  const canGoBack = answeredQuestions.length > 0 && question.undoable
  const canGiveFeedback = !!context?.elementId

  const isWelcomeScreen =
    question === (initialQuestion && initialQuestion.question) ||
    (question.fields &&
      question.fields.find((field) => field.type === 'welcome_screen'))

  const isOverlayOpen = startInOverlay || !isWelcomeScreen

  const keyText = question.id || question.text || 'loading'
  const key =
    keyText +
    (question.pgmReport && 'pgmReport') +
    (question.pgmDiagnosisId || '')

  const accessoryContent = Accessory && (
    <Accessory
      question={question}
      context={context}
      answeredQuestions={answeredQuestions}
    />
  )

  const questionCard = (
    <QuestionCard
      question={question}
      onSelect={handleOptionSelect}
      onValueChange={(fields) => setValue(fields)}
      onSubmit={answerQuestion}
      goToQuestion={goToQuestion}
      sendStarRating={handleStarRatingSubmit}
      hasRatedQuestion={hasRatedQuestion}
      previousAnswer={loading && previousAnswer}
      loading={loading}
      handleCallToAction={handleOptionSelect}
      value={value?.name || ''}
      onTextInputChange={(name) => setValue({ name })}
      SummaryAction={SummaryAction}
      reset={handleGoToStart}
      onOptionImpression={onOptionImpression}
      navigatingBack={navigatingBack}
      context={context}
      showFeedbackButton={canGiveFeedback}
      onFeedbackClick={handleFeedbackClick}
    />
  )

  return (
    <div>
      <div className={styles.content} aria-disabled={isOverlayOpen}>
        <CSSTransition
          in={isWelcomeScreen && !isOverlayOpen}
          timeout={TRANSITION_DURATION}
          classNames={styles.questionCardPopup}
          unmountOnExit
        >
          {isWelcomeScreen ? questionCard : <div />}
        </CSSTransition>
      </div>

      {isOverlayOpen && (
        <Overlay data-testid="chatbot-overlay" className={styles.overlayRoot}>
          <Layout
            accessoryContent={accessoryContent}
            loading={loading}
            LogoComponent={LogoComponent}
            logoUrl={logoUrl}
            onBackClick={handleGoBack}
            onExitClick={handleGoToStart}
            progress={question.progress}
            showBackButton={canGoBack}
          >
            <SlideTransition
              transitionKey={key} // Each question needs their own unique key so they can be animated
              didGoBack={didGoBack}
            >
              {questionCard}
            </SlideTransition>
          </Layout>
        </Overlay>
      )}
    </div>
  )
}

interface Props {
  Accessory?: React.ElementType
  actionHandler?: (params: {
    context: ContextInterface
    handleAsDefault?: any
    option: QuestionOptionInterface
    question: QuestionInterface
  }) => void
  answeredQuestions: any
  context: ContextInterface
  didGoBack: boolean
  goBack: () => void
  goToNewConversation: (type?: string, data?: any) => void
  goToQuestion: (newQuestion: GoToQuestionInterface) => void
  goToStart: () => void
  initialQuestion: {
    question: QuestionInterface
  }
  loading: boolean
  LogoComponent?: React.FC
  logoUrl?: string
  onGoToStart?: (context: ContextInterface) => void
  submitAnswer: (answer: any) => void
  question: QuestionInterface
  setLoading: (isLoading: boolean) => void
  startInOverlay?: boolean
  SummaryAction?: React.ElementType
  onConversationStatusChange?: (status: ContextStatus) => void
  onTriageOutcome?: (
    triageOutcome: triageOutcomeInterface,
    triageOutcomeInternal: triageOutcomeInterface
  ) => void
}

export default Chatbot
