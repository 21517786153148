import { useFormatMessage } from '@babylon/intl'
import React, { useState } from 'react'
import { ConfirmationModal, KebabMenuRenderOptions } from '@babylon/core-ui'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import * as Access from '@babylon/web-platform-access'
import { useMutation, ApolloError } from '@apollo/client'
import { AlertFilled } from '@babylon/icons'
import messages from './ProfileHeader.messages'
import messagesModalOne from './DeletePatientModalOne.messages'
import messagesModalTwo from './DeletePatientModalTwo.messages'
import { DELETE_PATIENT_CHECK_GQL } from './queries'
import { MemberOpsModuleName } from '../../../../..'

export interface DeletePatientCheckButtonProps extends KebabMenuRenderOptions {
  patientId: string
  setSnackbarMessage: (
    message: string,
    icon?: any,
    intent?: any,
    autoHideDuration?: number
  ) => void
}

export const DeletePatientCheckButton = ({
  patientId,
  menuItemProps,
  setSnackbarMessage,
}: DeletePatientCheckButtonProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profilesHeader,
  })
  const [canDeleteMember] = Access.useDecide('delete-member')
  const fm = useFormatMessage()
  const [showModalOne, setShowModalOne] = useState(false)
  const [showModalTwo, setShowModalTwo] = useState(false)
  const [deletePatientConfirm] = useMutation(DELETE_PATIENT_CHECK_GQL, {
    variables: { patientId },
    onCompleted: (data: any) => {
      setShowModalOne(false)
      setShowModalTwo(false)
      const text = JSON.stringify(data)
      console.log(
        'Delete Completed for patientId: %s, response: %s',
        patientId,
        text
      )
      setSnackbarMessage(fm(messagesModalTwo.success))
    },
  })

  return (
    <>
      {canDeleteMember && (
        <>
          {/*show me the button*/}
          <hr />
          <button
            {...menuItemProps}
            type="button"
            onClick={() => {
              setShowModalOne(true)
              setShowModalTwo(false)
              trackClick({
                elementName: 'delete-patient-check-btn',
                elementType: TrackingElementType.button,
              })
            }}
            data-testid="delete-patient-button"
          >
            {fm(messages.delete_patient_button)}
          </button>

          {/*Modal 1. are you sure?, confirm=cancel & cancel=confirm by design*/}
          <ConfirmationModal
            confirmText={<span>{fm(messagesModalOne.cancel)}</span>}
            cancelText={<span>{fm(messagesModalOne.confirm)}</span>}
            onClose={() => {
              setShowModalOne(false)
              setShowModalTwo(true)
            }}
            onConfirm={() => {
              setShowModalOne(false)
              setShowModalTwo(false)
            }}
            open={showModalOne}
            includeClose={false}
          >
            <ConfirmationModal.Heading>
              {fm(messagesModalOne.header)}
            </ConfirmationModal.Heading>
            <ConfirmationModal.Content>
              <p>{fm(messagesModalOne.content)}</p>
            </ConfirmationModal.Content>
          </ConfirmationModal>

          {/*Modal 2. Are you REALLY sure*/}
          <ConfirmationModal
            confirmText={<span>{fm(messagesModalTwo.confirm)}</span>}
            cancelText={<span>{fm(messagesModalTwo.cancel)}</span>}
            onClose={() => {
              setShowModalOne(false)
              setShowModalTwo(false)
            }}
            onConfirm={() => {
              deletePatientConfirm().catch(
                ({
                  message,
                  graphQLErrors,
                  networkError,
                  extraInfo,
                }: ApolloError) => {
                  console.error(
                    'ERROR message: %s, graphQLErrors: %s, networkError: %s, extraInfo: %s',
                    message,
                    graphQLErrors.map((e) => JSON.stringify(e)).join('\n'),
                    networkError,
                    extraInfo
                  )
                  setSnackbarMessage(
                    fm(messagesModalTwo.failed, { message }),
                    AlertFilled,
                    'error',
                    10000
                  )
                }
              )
            }}
            open={showModalTwo}
            includeClose={false}
          >
            <ConfirmationModal.Heading>
              {fm(messagesModalTwo.header)}
            </ConfirmationModal.Heading>
            <ConfirmationModal.Content>
              <p>{fm(messagesModalTwo.content)}</p>
            </ConfirmationModal.Content>
          </ConfirmationModal>
        </>
      )}
    </>
  )
}
