import { gql } from '@apollo/client'

export default gql`
  query GetServiceTypes(
    $includingDigital: Boolean
    $memberUuid: ID
    $include: [String!]
  ) {
    serviceTypes(
      including_digital: $includingDigital
      member_uuid: $memberUuid
      include: $include
    ) {
      id
      uuid
      name
      default_duration_minutes
      allowed_mediums
      member_instructions
    }
  }
`
