import { graphql } from '@apollo/client/react/hoc'
import { compose, defaultProps, withProps } from 'recompose'

import { withSpinner } from '@/util'
import withErrorMessage, { dialogErrorHandler } from '@/util/withErrorMessage'

import { AdminDrugAudits } from './queries'

import DrugAuditsView from './DrugAuditsView'
import columnConfig from './columnConfig.json'

// DEFAULT PROPS

const withDefaultProps = defaultProps({
  columns: columnConfig,
})

// DATA

const defaultData = ({
  match: { params: { id } } = { params: { id: null } },
}) => ({
  paramId: id,
})

export const adminDrugAuditsQueryOptions = {
  options: ({ paramId }) => ({ variables: { auditableId: paramId } }),
  skip: ({ paramId }) => !paramId,
}

const withData = compose(
  withProps(defaultData),
  graphql(AdminDrugAudits, adminDrugAuditsQueryOptions)
)

// LOADER

export const isLoading = (props) => !props.data || !props.data.adminDrugAudits

const withLoader = withSpinner(isLoading)

const Container = compose(
  withDefaultProps,
  withData,
  withErrorMessage(dialogErrorHandler),
  withLoader
)(DrugAuditsView)

Container.width = '90%'

export default Container
