import React, { useEffect, useState } from 'react'
import { Button, Spinner } from '@babylon/core-ui'
import { Modal } from '@mui/material'
import { useFormatMessage } from '@babylon/intl'
import { useParams } from 'react-router-dom'
import { DocumentNode, FieldNode, OperationDefinitionNode } from 'graphql'
import { useQuery } from '@apollo/client'
import { PageErrorMessage } from '@babylon/cce-shared-components'
import styles from './styles.module.scss'
import { Connection } from '@/gql/graphql'
import { messages } from './messages'
import { EditObjectConfig, ViewFieldSettingsObject } from './types'
import { EditModal } from './EditModal'

type URLParams = {
  id: string
}

type Props = {
  /* The graphql query to use to fetch the data */
  query: DocumentNode

  /* Function to format the rows before displaying them */
  dataFormatter: (data: Connection) => Record<string, any> | undefined

  /* Function to filter the query based on the id in the url */
  filter?: (id: string) => any

  /* The property to use as the header, e.g. the name of the object */
  headerProperty?: string

  /* The field settings for the view mode, i.e. the name of the field and the label to use */
  fieldSettings?: ViewFieldSettingsObject[]

  /* The label to use for the object type, e.g. Pharmacists. This will appear at the top of the page */
  objectTypeLabel?: string

  /* Config for the edit modal. If provided it will enable the edit button */
  editConfig?: EditObjectConfig
}

const getQueryNameFromData = (
  queryDefinition?: OperationDefinitionNode
): string | undefined =>
  (queryDefinition?.selectionSet?.selections?.find(
    (node) => node.kind === 'Field'
  ) as FieldNode | undefined)?.name.value

export const DataViewFromGQL: React.FC<Props> = ({
  query,
  filter,
  dataFormatter,
  headerProperty,
  editConfig,
  objectTypeLabel,
  fieldSettings: fieldSettingsProp,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const { id } = useParams<URLParams>()
  const fm = useFormatMessage()
  const filterResult = filter ? filter(id) : undefined

  const { data, error, loading, refetch } = useQuery<any>(query, {
    variables: {
      first: 1,
      filter: filter ? filter(id) : undefined,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    context: {
      clientName: 'platform-gateway',
    },
  })

  const eventType = `OBJECT_UPDATED_${id}`

  useEffect(() => {
    const eventHandler = () => refetch()
    window.addEventListener(eventType, eventHandler)

    return () => {
      window.removeEventListener(eventType, eventHandler)
    }
  })

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const queryName = getQueryNameFromData(
    query.definitions[0] as OperationDefinitionNode
  )
  const dataToDisplay =
    (!filter || filterResult) && data && queryName
      ? dataFormatter(data[queryName])
      : undefined
  const defaultFieldSettings = dataToDisplay
    ? Object.keys(dataToDisplay).map((key) => ({ name: key, label: key }))
    : []
  const fieldSettings =
    fieldSettingsProp || (defaultFieldSettings as ViewFieldSettingsObject[])

  if (error) {
    return (
      <div className={styles.dataTable}>
        <PageErrorMessage errorMessage="Error while loading data" />
      </div>
    )
  }

  if (loading) {
    return (
      <div className={styles.fillContent}>
        <Spinner />
      </div>
    )
  }

  const formattedItemValue = (valueForProperty: any) => {
    if (valueForProperty === '') return fm(messages.notProvided)
    if (valueForProperty === true) return 'Yes'
    if (valueForProperty === false) return 'No'
    return valueForProperty
  }

  if (dataToDisplay) {
    return (
      <div className={styles.fillContent}>
        {editConfig && (
          <Modal open={openModal} onClose={handleCloseModal}>
            <EditModal
              eventType={eventType}
              inputObject={dataToDisplay}
              editConfig={editConfig}
              closeModal={handleCloseModal}
              objectTypeLabel={objectTypeLabel}
            />
          </Modal>
        )}
        <div className={styles.header}>
          {headerProperty && (
            <h1 className={styles.title}>{dataToDisplay[headerProperty]}</h1>
          )}
        </div>
        <div className={styles.dataView}>
          {editConfig && (
            <Button
              data-testid="edit-button"
              onClick={() => {
                setOpenModal(true)
              }}
              intent="primary"
            >
              Edit
            </Button>
          )}
          {fieldSettings &&
            fieldSettings
              .filter((item: any) => !item.hidden)
              .map((item: any) => (
                <div key={item.name}>
                  <p className={styles.propertyName}>{item.label}</p>
                  <p className={styles.propertyValue}>
                    {formattedItemValue(dataToDisplay?.[item.name])}
                  </p>
                </div>
              ))}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.fillContent}>
      <p>{fm(messages.notFound)}</p>
    </div>
  )
}
