import { defineMessages } from 'react-intl'

const baseId = 'pharmacies_page'

export default defineMessages({
  new_pharmacies_switch_banner: {
    id: `${baseId}.new_pharmacies_switch_banner`,
    defaultMessage:
      'NEW PHARMACIES SEARCH - Click to go to the old pharmacies page. You can switch back at any time.',
  },
  pharmacies: {
    id: `${baseId}.pharmacies`,
    defaultMessage: 'Pharmacies',
  },
  edit: {
    id: `${baseId}.edit`,
    defaultMessage: 'Edit',
  },
  loading: {
    id: `${baseId}.loading`,
    defaultMessage: 'Loading Pharmacy Supplier Data',
  },
  error: {
    id: `${baseId}.error`,
    defaultMessage: 'Error loading Pharmacy Supplier Data:',
  },
  address: {
    id: `${baseId}.address`,
    defaultMessage: 'Address',
  },
  name: {
    id: `${baseId}.name`,
    defaultMessage: 'Name',
  },
  phoneNumber: {
    id: `${baseId}.phone_number`,
    defaultMessage: 'Phone Number',
  },
  blocked: {
    id: `${baseId}.blocked`,
    defaultMessage: 'Blocked',
  },
  blockedReason: {
    id: `${baseId}.blocked_reason`,
    defaultMessage: 'Blocked Reason',
  },
  deliveryType: {
    id: `${baseId}.delivery_type`,
    defaultMessage: 'Delivery Type',
  },
  faxNumber: {
    id: `${baseId}.fax_number`,
    defaultMessage: 'Fax Number',
  },
  fax: {
    id: `${baseId}.fax`,
    defaultMessage: 'Fax',
  },
  online: {
    id: `${baseId}.online`,
    defaultMessage: 'Online',
  },
  region: {
    id: `${baseId}.region`,
    defaultMessage: 'Region',
  },
  email: {
    id: `${baseId}.email`,
    defaultMessage: 'Email',
  },
  active: {
    id: `${baseId}.active`,
    defaultMessage: 'Active',
  },
  roles: {
    id: `${baseId}.roles`,
    defaultMessage: 'Roles',
  },
  pharmacist: {
    id: `${baseId}.pharmacist`,
    defaultMessage: 'Pharmacist',
  },
  pharmacySupplier: {
    id: `${baseId}.pharmacy_supplier`,
    defaultMessage: 'Pharmacy Supplier',
  },
  save: {
    id: `${baseId}.save`,
    defaultMessage: 'Save',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  successfulPharmacyEdit: {
    id: `${baseId}.successful_pharmacy_edit`,
    defaultMessage: 'Pharmacy has been updated successfully',
  },
  errorPharmacyEdit: {
    id: `${baseId}.error_pharmacy_edit`,
    defaultMessage: 'Something went wrong. Please try again.',
  },
  filter: {
    id: `${baseId}.filter`,
    defaultMessage: 'Filter',
  },
  reset: {
    id: `${baseId}.reset`,
    defaultMessage: 'Reset',
  },
  search: {
    id: `${baseId}.search`,
    defaultMessage: 'Search',
  },
  all: {
    id: `${baseId}.all`,
    defaultMessage: 'ALL',
  },
  true: {
    id: `${baseId}.true`,
    defaultMessage: 'TRUE',
  },
  false: {
    id: `${baseId}.false`,
    defaultMessage: 'FALSE',
  },
  exists: {
    id: `${baseId}.exists`,
    defaultMessage: 'EXISTS',
  },
  doesNotExist: {
    id: `${baseId}.doesNotExist`,
    defaultMessage: 'DOES NOT EXIST',
  },
  postCode: {
    id: `${baseId}.postCode`,
    defaultMessage: 'Post Code',
  },
  faxNumberTooltip: {
    id: `${baseId}.fax_number_tooltip`,
    defaultMessage:
      'Pharmacies with and without fax numbers are shown in this table. Change this to filter based on fax numbers.',
  },
})
