import React from 'react'

const CopyIcon = () => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
  >
    <path
      d="M9.33333 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667H1.33333V1.33333H9.33333V0ZM8.66667 2.66667H4C3.26667 2.66667 2.67333 3.26667 2.67333 4L2.66667 13.3333C2.66667 14.0667 3.26 14.6667 3.99333 14.6667H11.3333C12.0667 14.6667 12.6667 14.0667 12.6667 13.3333V6.66667L8.66667 2.66667ZM4 13.3333V4H8V7.33333H11.3333V13.3333H4Z"
      fill="#005870"
    />
  </svg>
)

export default CopyIcon
