import { gql } from '@apollo/client'

export default gql`
  query GetServiceType($uuid: ID!) {
    serviceType(uuid: $uuid) {
      uuid
      name
      member_facing_name
      us_ehr_appointment_type
      default_duration_minutes
      grouping
      member_instructions
      member_description
      preferred_medium
      allowed_mediums
      clinicians {
        id
        name
        role
      }
      clinics {
        id
        name
      }
      consumer_networks {
        id
        name
      }
      common
      categories {
        uuid
        name
      }
    }
  }
`
