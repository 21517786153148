import React, { ReactNode } from 'react'
import { addMinutes } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faCheckCircle,
} from '@fortawesome/pro-light-svg-icons'
import {
  Heading,
  Cell,
  Text,
  Grid,
  DropdownSelect,
  DropdownSelectOption,
} from '@babylon/core-ui'
import { colors } from '@babylon/core-ui/src/theme'
import { ConsultationTypeName } from '../types/ConsultationTypes'
import { Consultant } from './AppointmentDialog'
import DetailsRow from './AppointmentDialogDetailsRow'
import { getTimezoneGMT } from '../Utils'
import { getMediumValue } from './AppointmentUtils'

export interface AppointmentDialogDetailsProps {
  dropdownArray?: DropdownSelectOption[]
  selectedDropdownOption?: DropdownSelectOption | null
  error?: boolean
  onSelectDropdownItem?: (e: DropdownSelectOption) => void
  clinic?: Practice | null
  medium?: ConsultationTypeName
  heading: ReactNode
  timezoneId?: string | null
  scheduledTime?: string | null
  duration: number
  consultant?: Consultant | null
  icon: 'success' | 'warning'
  serviceType?: string
}

export default function AppointmentDetails({
  dropdownArray,
  onSelectDropdownItem,
  selectedDropdownOption,
  error,
  clinic,
  medium,
  heading,
  timezoneId,
  scheduledTime,
  duration,
  consultant,
  icon,
  serviceType,
}: AppointmentDialogDetailsProps) {
  let appointmentStart
  let timeString = ''
  let consultantString = ''

  if (consultant) {
    consultantString = `(${consultant.gender === 'male' ? 'M' : 'F'}) ${
      consultant.name
    }`
  }

  if (scheduledTime) {
    appointmentStart = utcToZonedTime(new Date(scheduledTime), timezoneId || '')
    const appointmentEnd = addMinutes(appointmentStart, duration)
    const startTime = format(appointmentStart, 'HH:mm', {
      timeZone: timezoneId || '',
    })
    const endTime = format(appointmentEnd, 'HH:mm', {
      timeZone: timezoneId || '',
    })
    const timezoneFormatted = getTimezoneGMT(appointmentEnd, timezoneId)

    timeString = `${startTime} - ${endTime} ${timezoneFormatted}`
  }

  return (
    <Grid
      columns={3}
      style={{ marginBottom: '30px' }}
      columnGap="52"
      rowGap="14"
    >
      <Cell width={3} center>
        <FontAwesomeIcon
          icon={icon === 'success' ? faCheckCircle : faExclamationCircle}
          size="4x"
          color={colors['action-green']}
        />
      </Cell>
      <Cell width={3}>
        <Heading
          color="black-type"
          align="center"
          style={{ paddingBottom: 20, paddingTop: 10, margin: '0 auto' }}
        >
          {heading}
        </Heading>
      </Cell>

      {appointmentStart && (
        <DetailsRow
          title="Date"
          value={format(appointmentStart, 'E dd-MMM-y')}
        />
      )}
      <DetailsRow title="Time" value={timeString} data-duration={duration} />
      {serviceType && <DetailsRow title="Type" value={serviceType} />}
      <DetailsRow title="Medium" value={getMediumValue(medium)} />
      <DetailsRow title="Consultant" value={consultantString} />
      {medium && medium === 'physical' && clinic && clinic.address && (
        <DetailsRow title="Location" value={clinic.address} />
      )}
      {dropdownArray && onSelectDropdownItem && (
        <>
          <Cell width={3}>
            <Text
              style={{ marginTop: 20 }}
              color={error ? 'error' : 'grey-type'}
              size="large"
              bold
              tag="div"
            >
              Select reason for cancellation
            </Text>
          </Cell>
          <Cell width={3}>
            <DropdownSelect
              name="Cancellation reason"
              hasError={error}
              selectedOption={selectedDropdownOption}
              options={dropdownArray}
              onChange={onSelectDropdownItem}
            />
          </Cell>
        </>
      )}
    </Grid>
  )
}
