import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Heading } from '@babylon/core-ui'
import { Close } from '@babylon/icons'
import ReferralListItem from './ReferralListItem'

import messages from './Referrals.messages'
import styles from './Referrals.module.scss'

export interface ReferralsProps {
  referrals: Referral[]
  onSend: (arg1: string) => any
  onClose?: () => void
  sendEnabled: Boolean
}

const Referrals = ({
  referrals,
  onSend,
  onClose,
  sendEnabled,
}: ReferralsProps) => (
  <div>
    <div className={styles.Referrals__heading}>
      <Heading level="h1">
        <FormattedMessage {...messages.heading} />
      </Heading>
      {onClose && (
        <button
          type="button"
          data-testid="close-button"
          className={styles.Referrals__closeButton}
          onClick={onClose}
        >
          <Close />
        </button>
      )}
    </div>
    {referrals.map((referral) => (
      <ReferralListItem
        key={referral.id}
        referral={referral}
        onSend={onSend}
        sendEnabled={sendEnabled}
      />
    ))}
  </div>
)

export default Referrals
