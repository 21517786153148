/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type GetHumanitySyncAllowedQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type GetHumanitySyncAllowedQuery = {
  humanitySyncAllowed?: Types.Maybe<
    Pick<Types.HumanitySyncAllowed, 'sync_allowed'>
  >
}

export const GetHumanitySyncAllowedDocument = gql`
  query GetHumanitySyncAllowed {
    humanitySyncAllowed {
      sync_allowed
    }
  }
`
export function useGetHumanitySyncAllowedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHumanitySyncAllowedQuery,
    GetHumanitySyncAllowedQueryVariables
  >
) {
  return Apollo.useQuery<
    GetHumanitySyncAllowedQuery,
    GetHumanitySyncAllowedQueryVariables
  >(GetHumanitySyncAllowedDocument, baseOptions)
}
export function useGetHumanitySyncAllowedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHumanitySyncAllowedQuery,
    GetHumanitySyncAllowedQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetHumanitySyncAllowedQuery,
    GetHumanitySyncAllowedQueryVariables
  >(GetHumanitySyncAllowedDocument, baseOptions)
}
export type GetHumanitySyncAllowedQueryHookResult = ReturnType<
  typeof useGetHumanitySyncAllowedQuery
>
export type GetHumanitySyncAllowedLazyQueryHookResult = ReturnType<
  typeof useGetHumanitySyncAllowedLazyQuery
>
export type GetHumanitySyncAllowedQueryResult = Apollo.QueryResult<
  GetHumanitySyncAllowedQuery,
  GetHumanitySyncAllowedQueryVariables
>
