import { defineMessages } from 'react-intl'

const baseId = 'assessment_early_exit_view'

export default defineMessages({
  memberDetails: {
    id: `${baseId}.member_details`,
    defaultMessage: 'Member details',
  },
  patientSearch: {
    id: `${baseId}.patient_search`,
    defaultMessage: 'Patients',
  },
  assessment: {
    id: `${baseId}.assessment`,
    defaultMessage: 'Assessment',
  },
  breadcrumbTitle: {
    id: `${baseId}.breadcrumb_title`,
    defaultMessage: 'Submit assessment early',
  },
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Submit assessment early',
  },
})
