import React, { useState } from 'react'
import { Snackbar } from '@babylon/core-ui'
import { AlertsInformationFill } from '@babylon/icons'

import { SnackBarProvider } from '../../../..'

import styles from './withSnackBar.module.scss'
import { Snack } from '../../../../app'

const withSnackBar = (
  WrappedComponent: React.JSXElementConstructor<unknown>
) => () => {
  const [snackBar, setSnackBar] = useState<Snack>({
    autoHideDuration: 5000,
    message: null,
    open: false,
  })

  const onClose = () => setSnackBar({ ...snackBar, message: null, open: false })

  const { message, open, autoHideDuration } = snackBar

  return (
    <SnackBarProvider value={{ snackBar, setSnackBar }}>
      <Snackbar
        className={styles.snackbar}
        message={message}
        open={!!open}
        autoHideDuration={autoHideDuration}
        icon={<AlertsInformationFill className={styles.icon} />}
        intent="error"
        onClose={onClose}
      />
      <WrappedComponent />
    </SnackBarProvider>
  )
}

export default withSnackBar
