import { useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { PROFILE_QUERY } from '../../../queries'

interface RefetchProfileOptions {
  patientUuid: string
  shouldRefetchProfile: boolean
}

const REFETCH_TIMEOUT_MS = 2500

const useRefetchProfile = ({
  patientUuid,
  shouldRefetchProfile,
}: RefetchProfileOptions) => {
  const { data: profileData, refetch } = useQuery(PROFILE_QUERY, {
    variables: {
      patientUuid,
    },
    errorPolicy: 'all',
    context: { clientName: 'platform-gateway' },
  })
  const profile = profileData?.patient ?? null
  const profileCoreIdentifier = profile?.identifiers?.edges?.find(
    (edge) => edge.node.system === 'https://coreruby.bbl.health/Patient'
  )
  const patientCoreId = profileCoreIdentifier?.node?.value
  const refetchProfile = !patientCoreId && shouldRefetchProfile
  const savedRefetch = useRef<Function>(() => {})

  useEffect(() => {
    savedRefetch.current = () => refetch()
  })

  useEffect(() => {
    if (!refetchProfile) {
      return undefined
    }

    const interval = setInterval(() => {
      savedRefetch.current()
    }, REFETCH_TIMEOUT_MS)

    return () => clearInterval(interval)
  }, [refetchProfile])

  return patientCoreId
}

export default useRefetchProfile
