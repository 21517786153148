export const routes = {
  clinicianCalendar: '/admin/consultants/:clinicianId/availability-new',
  oldClinicianCalendar: '/admin/consultants/:clinicianId/availability',
  appointmentWaitTimes: '/admin/appointment-wait-times',
  appointmentWaitTimeConfigs: '/admin/appointment-wait-time/configs',
}

export const externalRoutes = {
  consultantsSearch: '/admin/consultants/search',
}
