import React from 'react'
import { useFormikContext } from 'formik'

import { Pills, Fieldset, Select } from '@babylon/medkit'

import ArrangeAppointmentForm from './ArrangeAppointmentTypes'

import styles from './ClinicianSelection.module.css'

interface ClinicianSelectionProps {
  consultantOptions: {
    value: string
    label: string
  }[]
}

export default function ClinicianSelection({
  consultantOptions,
}: ClinicianSelectionProps) {
  const { values, setFieldValue } = useFormikContext<ArrangeAppointmentForm>()
  const {
    preferred_profession: preferredProfession,
    allowed_professions: allowedProfessions,
  } = values

  const handleAllowedClinicians = (selectedOptions) => {
    if (!selectedOptions.includes(preferredProfession)) {
      setFieldValue('preferred_profession', selectedOptions[0] || '')
    }

    setFieldValue('allowed_professions', selectedOptions)
  }

  const handlePreferredClinician = (event) =>
    setFieldValue('preferred_profession', parseInt(event.target.value, 10))

  const allowedConsultantPills = consultantOptions.map((option) => ({
    ...option,
    selected: allowedProfessions?.includes(option.value),
  }))

  const preferredCliniciansOptions = consultantOptions.reduce((acc, option) => {
    if (allowedProfessions?.includes(option.value)) {
      acc.push({
        value: option.value,
        label: option.label,
      })
    }

    return acc
  }, [])

  return (
    <>
      <Fieldset legend="Clinician" role="group">
        <Pills
          isControlledComponent
          name="clinicians"
          options={allowedConsultantPills}
          multiSelect
          onChange={handleAllowedClinicians}
        />
      </Fieldset>
      <Select
        className={styles.PreferredClinicianSelector}
        disabled={!preferredCliniciansOptions.length}
        label="Default clinician"
        options={preferredCliniciansOptions}
        onChange={handlePreferredClinician}
      />
    </>
  )
}
