import React, { useState } from 'react'
import { useField } from 'formik'
import {
  LegacyFormikDropdown,
  LegacyFormikDropdownOption,
} from '@babylon/core-ui'
import consultationTimezoneList from './whitelisted-timezones'

interface Props {
  className?: string
  onChange: (value: string | null) => void
  disabled?: boolean
}

export function formatTimezone(timezone: string) {
  if (!timezone) {
    return timezone
  }

  return timezone.split('/').reverse().join(', ').split('_').join(' ')
}

export function createTimezoneOption(timezoneId: string) {
  return {
    label: formatTimezone(timezoneId),
    value: timezoneId,
  }
}

export function addInitialTimezone(list: string[], timezoneId: string) {
  return Array.from(new Set([...list, timezoneId]))
}

export default function ConsultationTimezoneSelector({
  className,
  onChange,
  disabled = false,
}: Props) {
  const timezoneFieldName = 'timezone_id'
  const [{ value }, , { setValue }] = useField(timezoneFieldName)
  // state is used here to replicate onMount with the initial value
  // This is to prevent errors produced when using useMemo with an
  // empty dependency list.
  const [timezoneOptions] = useState(
    addInitialTimezone(consultationTimezoneList, value).map(
      createTimezoneOption
    )
  )

  return (
    <div className={className} data-testid="timezone-selector">
      <LegacyFormikDropdown
        label="Timezone"
        id={timezoneFieldName}
        name={timezoneFieldName}
        options={timezoneOptions}
        onChange={(option: LegacyFormikDropdownOption | null) => {
          const newTimezoneId = option?.value || null
          setValue(newTimezoneId)
          onChange?.(newTimezoneId)
        }}
        disabled={disabled}
        clearable={false}
        multi={false}
        searchable
        ignoreCase
      />
    </div>
  )
}
