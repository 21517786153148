import React, { KeyboardEvent, Ref } from 'react'
import { Field, FieldProps } from 'formik'
import { getUnixTime } from 'date-fns'

import { isPhysicalConsultation } from '../../types/ConsultationTypes'
import { CreateAppointmentData } from '../CreateAppointmentMutation'
import { Slot } from './GroupSlots'
import { Clinic } from './GroupSlotsByClinic'
import AvailabilitySlotContent from './AvailabilitySlotContent'

interface Props {
  clinics: Clinic[]
  disabled?: boolean
  innerRef: Ref<HTMLDivElement>
  slot: Slot
}

export const isSlotSelected = (slot: Slot, values: CreateAppointmentData) => {
  const isPhysical = isPhysicalConsultation(values.consultation_type)
  const isSelectedTimestamp = values.preferred_date_time === slot.UTCTimestamp
  const isSelectedClinic = values.clinic_id === slot.clinicId

  return isPhysical
    ? isSelectedTimestamp && isSelectedClinic
    : isSelectedTimestamp
}

const AvailabilitySlotField = ({
  clinics,
  disabled,
  innerRef,
  slot,
}: Props) => (
  <Field>
    {({ form: { values, setFieldValue } }: FieldProps) => {
      const isDisabled = !slot.available || disabled
      const isPhysical = isPhysicalConsultation(values.consultation_type)
      const unixTime = getUnixTime(new Date(slot.UTCTimestamp))
      const testId = `slot-${unixTime}${isPhysical ? `-${slot.clinicId}` : ''}`

      const setField = () => {
        setFieldValue('preferred_date_time', slot.UTCTimestamp)

        if (isPhysical) {
          const selectedClinic = clinics.find(
            (clinic) => clinic.id === slot.clinicId
          )
          setFieldValue('clinic_id', slot.clinicId)
          setFieldValue('timezone_id', selectedClinic?.timezone_id)

          if (slot.consultants.length === 1) {
            setFieldValue('consultant_id', slot.consultants[0].id)
          }
        }
      }

      const handleKeyDown = (event: KeyboardEvent) => {
        if (!isDisabled && (event.key === 'Space' || event.key === 'Enter')) {
          setField()
        }
      }

      const handleClick = () => {
        if (!isDisabled) {
          setField()
        }
      }

      return (
        <div
          ref={innerRef}
          data-testid={testId}
          role="radio"
          aria-checked={isSlotSelected(slot, values)}
          aria-disabled={isDisabled}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onClick={handleClick}
        >
          <AvailabilitySlotContent
            disabled={isDisabled}
            time={slot.time}
            clinicians={slot.consultants}
          />
        </div>
      )
    }}
  </Field>
)

export default AvailabilitySlotField
