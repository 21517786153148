import React, { useState, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { DropdownSelectOption } from '@babylon/core-ui'
import CancelAppointmentMutation from './CancelAppointmentMutation'
import GetCancellationDetails from './GetCancellationDetailsQuery'
import { formatCancellationReasons } from './CancelAppointmentUtils'

import AppointmentDialog from '../AppointmentDialog'

export interface AppointmentCancellationDetails {
  appointmentId: string
}

export interface CancelAppointmentDialogProps
  extends AppointmentCancellationDetails {
  active: boolean
  onClose?: () => void
  refetch?: () => void
}

export default function CancelAppointmentDialog({
  active,
  onClose,
  refetch,
  appointmentId,
}: CancelAppointmentDialogProps): React.ReactElement {
  const [cancelling, setCancelling] = useState(false)
  const [reason, setReason] = useState<DropdownSelectOption | null>(null)
  const [noReasonSelectedError, setNoReasonSelectedError] = useState(false)
  const showError = () => setNoReasonSelectedError(true)
  const hideError = () => setNoReasonSelectedError(false)
  const closeDialog = () => {
    if (active && cancelling) {
      setCancelling(false)
    }

    if (onClose && active) {
      onClose()
    }
  }
  const onFinish = async () => {
    if (refetch) {
      await refetch()
    }

    closeDialog()
  }

  const [onCancel, { error: cancellingError }] = useMutation(
    CancelAppointmentMutation,
    {
      onCompleted: onFinish,
      onError: onFinish,
    }
  )

  const { data, error, loading: loadingDetails } = useQuery(
    GetCancellationDetails,
    {
      variables: {
        appointmentId,
      },
    }
  )

  const { clinic, consultant, duration, medium, scheduledTime, timezoneId } =
    data?.newAppointment || {}

  const cancellationReasonsList = useMemo(
    () => formatCancellationReasons(data),
    [data]
  )

  return (
    <AppointmentDialog
      active={active}
      onClose={closeDialog}
      onConfirm={() => {
        if (!reason) {
          showError()

          return
        }

        setCancelling(true)

        onCancel({
          variables: {
            appointmentId,
            cancelReasonId: reason.key,
          },
        })
      }}
      clinic={clinic}
      medium={medium}
      heading={`Cancel Appointment #${appointmentId}`}
      timezoneId={
        timezoneId || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
      }
      scheduledTime={scheduledTime}
      duration={duration}
      consultant={consultant}
      confirmButtonText="Cancel the appointment"
      dropdownArray={cancellationReasonsList}
      dropdownError={noReasonSelectedError}
      snackbarError={cancellingError || error}
      loading={cancelling}
      selectedDropdownOption={reason}
      onSelectDropdownItem={(item: DropdownSelectOption) => {
        hideError()
        setReason(item)
      }}
      icon="warning"
      loadingDetails={loadingDetails}
    />
  )
}
