import React from 'react'
import cx from 'classnames'
import { SingleDatePicker as ReactSingleDatePicker } from 'react-dates'

import {
  withLabel,
  withValue,
  withValidation,
  withFormContext,
  withWrapper,
  compose,
  result,
} from '../forms'

class SingleDatePicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = { focused: null }
    this.updateCallbacks(props)
  }

  updateCallbacks(props) {
    const { 'data-context': ctx, onChange, name } = props
    if (onChange) {
      this.handleChange = (date) => {
        onChange(null, date)
      }
    } else if (ctx && ctx.store) {
      this.handleChange = (date) => {
        ctx.store.setState({
          [name]: date,
        })
      }
    }
  }
  updateFocus = ({ focused }) =>
    this.setState({
      focused,
    })

  render() {
    const {
      'data-context': ctx,
      name,
      value,
      id,
      className,
      onChange,
      ...rest
    } = this.props
    let date
    if (ctx && ctx.store) {
      const { state } = ctx.store
      date = value ? result(value, state) : state[name]
    }
    return (
      <div className={cx('single-date-picker', this.props.className)}>
        <ReactSingleDatePicker
          date={date}
          block
          onDateChange={this.handleChange}
          focused={this.state.focused}
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          firstDayOfWeek={1}
          noBorder
          displayFormat="ll"
          onFocusChange={this.updateFocus}
          {...rest}
        />
      </div>
    )
  }
}

export default compose(
  withLabel,
  withValue,
  withValidation,
  withWrapper,
  withFormContext
)(SingleDatePicker)
