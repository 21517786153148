import atlas from '@babylon/atlas.js'
import { envVar } from '@babylon/babylon-env'
import { Text } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'
import { Icon } from '@babylon/medkit'
import React, { useState } from 'react'
import DeleteAttachmentModal from '../Attachment/DeleteAttachmentModal'

import styles from './styles.module.css'
import TextWithLinks from './TextWithLinks'

type Props = {
  message: atlas.Message
  mine: boolean
  conversationId?: string
  attachments?: any
}

const Message = ({ message, mine, attachments, conversationId }: Props) => {
  const { formatTime } = useIntl()
  const { hasAttachment, message: chatMessage, sentAt } = message
  const [hoverChatBubble, setHoverChatBubble] = useState(false)
  const countryIsoCode = envVar('ENVIRONMENT_COUNTRY_ISO_CODE')
  const [showDeleteAttachment, setShowDeleteAttachment] = useState<
    'show' | 'hide' | 'initial'
  >('initial')
  const handleMessageHover = (value: boolean) => setHoverChatBubble(value)

  return (
    <>
      <div
        className={`${styles.message} ${mine ? styles.mine : ''}`}
        onMouseEnter={() => handleMessageHover(true)}
        onMouseLeave={() => handleMessageHover(false)}
      >
        <div className={`${hasAttachment ? styles.attachment : ''}`}>
          {hasAttachment && (
            <Icon
              title=""
              icon="File"
              height={20}
              width={20}
              fill="white"
              className={styles.attachmentIcon}
            />
          )}
          <TextWithLinks mine={mine} TextComponent={Text}>
            {chatMessage}
          </TextWithLinks>
        </div>

        <div className={styles.onlyPrint}>{mine ? 'Agent' : 'Member'}</div>
        <div className={styles.messageSubContent}>
          <Text tag="span" size="small">
            {formatTime(sentAt, {
              hour: 'numeric',
              minute: 'numeric',
              hour12: countryIsoCode !== 'GBR',
            })}
          </Text>
          {mine && hasAttachment && hoverChatBubble && (
            <span
              aria-hidden="true"
              onClick={() => {
                setShowDeleteAttachment('show')
              }}
            >
              <Icon
                title="Remove file"
                icon="Delete"
                height={15}
                width={15}
                fill="coral"
                className={styles.removeIcon}
              />
            </span>
          )}
        </div>
      </div>
      {mine && hasAttachment && (
        <DeleteAttachmentModal
          visible={showDeleteAttachment === 'show'}
          onRequestClose={() => setShowDeleteAttachment('hide')}
          conversationId={conversationId}
          message={chatMessage}
          attachments={attachments}
          messageId={message.messageId}
        />
      )}
    </>
  )
}

export default Message
