import { gql } from '@apollo/client'

const LinkSupportInteraction = gql`
  mutation AP_linkSupportInteraction(
    $patientUuid: ID!
    $transactionId: ID!
    $transactionType: String!
  ) {
    linkPatientToTransaction(
      patientUuid: $patientUuid
      transactionId: $transactionId
      transactionType: $transactionType
    )
  }
`

export default LinkSupportInteraction
