import qs from 'qs'

export interface QueryString {
  page?: string
  consultantId?: string
  consultantName?: string
  shiftStartDate?: string
  shiftEndDate?: string
  error?: string
  warning?: string
}

export interface PageParams {
  limit: number
  offset: number
}

export const validPageNumber = (
  value: string | number | undefined
): value is string | number => {
  if (!value) {
    return false
  }

  return true
}

export const handlePrevClick = (
  currentPage: number,
  history: any,
  queryString?: QueryString
) => {
  if (currentPage - 1 < 1 || !validPageNumber(currentPage - 1)) {
    return
  }

  history.push({
    search: qs.stringify({ ...queryString, page: currentPage - 1 }),
  })
}

export const handleNextClick = (
  currentPage: number,
  totalPages: number,
  history: any,
  queryString?: QueryString
) => {
  if (currentPage + 1 > totalPages || !validPageNumber(currentPage + 1)) {
    return
  }

  history.push({
    search: qs.stringify({ ...queryString, page: currentPage + 1 }),
  })
}
