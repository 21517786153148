import React, { useMemo } from 'react'
import { Field, FieldProps } from 'formik'
import { FormField, PillContainer } from '@babylon/core-ui'

import { useConsultantType } from '../hooks'
import { UnexpectedError } from '../Utils'
import { LoadingContainer } from '../components'

interface Props {
  consultantTypes?: Array<string>
  onChange?: (value: string) => void
}

const ConsultantTypePills = ({ consultantTypes = [], onChange }: Props) => {
  const { consultantTypeDict, loading, error } = useConsultantType()

  const pills = useMemo(
    () =>
      consultantTypes.map((consultantType) => {
        const consultantName = consultantTypeDict?.[consultantType]?.name

        return {
          name: consultantName,
          id: consultantType,
        }
      }),
    [consultantTypes, consultantTypeDict]
  )

  return (
    <Field name="consultant_type">
      {({ field: { value }, form: { setFieldValue } }: FieldProps<string>) => (
        <FormField id="consultant_type" label="Type of Appointment:">
          {error && <UnexpectedError visible message={error.message} />}
          <LoadingContainer loading={loading}>
            <PillContainer
              pills={pills}
              selected={value}
              onSelectPill={(selectedValue: string) => {
                setFieldValue('consultant_type', selectedValue)

                if (onChange) {
                  onChange(selectedValue)
                }
              }}
            />
          </LoadingContainer>
        </FormField>
      )}
    </Field>
  )
}

export default ConsultantTypePills
