export enum Actions {
  UPDATE_OWNER = 'UPDATE_OWNER',
  SET_FAMILY_MEMBERS = 'SET_FAMILY_MEMBERS',
}

export interface Action {
  type: Actions
}

export interface SetFamilyMembers {
  familyMembers: any[]
}

export interface UpdateOwner {
  newOwnerId: string
}

export type UpdateOwnerAction = UpdateOwner & Action
export type SetFamilyMembersAction = SetFamilyMembers & Action
export type AllActions = UpdateOwnerAction | SetFamilyMembersAction

export const updateOwner = (newOwnerId: string): UpdateOwnerAction => ({
  type: Actions.UPDATE_OWNER,
  newOwnerId,
})

export const setFamilyMembers = (
  familyMembers: any[]
): SetFamilyMembers & Action => ({
  type: Actions.SET_FAMILY_MEMBERS,
  familyMembers,
})
