import React, { ReactNode } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { CheckInStatus } from '@babylon/graphql-middleware-types'
import { formatTimeWithTimezone } from '@babylon/shared-utils'
import { AppointmentCardDetailsProps } from './components/AppointmentCardDetails/AppointmentCardDetails'
import AppointmentCard from './components/AppointmentCard'
import AppointmentInviteCard from './components/AppointmentInviteCard'
import { TimelineCardMenuProps } from './TimelineCardMenu'
import WarningCard from './components/WarningCard/WarningCard'
import messages from './TimelineCard.messages'
import {
  Maybe,
  Consultant,
  CancellationReason,
  Practice,
  Medium,
  Referral,
  CheckInData,
} from '../types'
import { useTimezoneContext } from '../Member/View/components/TimezoneProvider'
import { checkInFilter } from '../Timeline/utils'

export interface TimelineCardProps
  extends Omit<
    AppointmentCardDetailsProps,
    'show' | 'isCompleted' | 'checkIn'
  > {
  id: string
  isInvitation?: boolean
  patientId: string
  cancellable?: boolean
  cancelledAt?: string | null
  consultant?: Maybe<Consultant>
  cancellationReasons?: CancellationReason[]
  clinic?: Practice | null
  guestName?: string | null
  consumerNetwork?: string | null
  duration?: number
  medium?: Medium
  title?: string
  scheduledTime?: string | null
  status?: string
  referrals?: Referral[]
  onCancelAppointmentClick: (appointmentId: string) => void
  renderMenu?: (props: TimelineCardMenuProps) => JSX.Element
  isWarning?: boolean
  icon?: ReactNode
  isToggleBoxVisible?: boolean
  checkIn?: CheckInData | null
}

const TimelineCard = ({
  id,
  appointmentType,
  checkIn,
  cancellable,
  cancelledAt,
  cancellationReasons,
  consumerNetwork,
  consultant,
  guestName,
  duration = 0,
  isInvitation = false,
  medium,
  notes,
  patientId,
  reason,
  scheduledTime,
  status,
  clinic,
  onCancelAppointmentClick,
  referrals,
  renderMenu,
  isWarning = false,
  icon,
  title,
  isToggleBoxVisible,
}: TimelineCardProps) => {
  const fm = useFormatMessage()
  const { timezone } = useTimezoneContext()
  const mediumTitleKey = medium && messages[medium] ? medium : 'digital'
  const mediumTitle = fm(messages[mediumTitleKey])
  const twelveHourFormat = timezone.split('/')[0] === 'America'

  const checkInTime = (checkInData?: CheckInData | null): string => {
    const checkedInAt = checkInData?.checked_in_at
    const checkInStatus = checkInData?.check_in_status ?? ''

    const time = checkedInAt
      ? formatTimeWithTimezone(checkedInAt, {
          desiredTimeZone: timezone,
          twelveHourFormat,
        })
      : ''
    const theCheckInStatus = checkInFilter(checkInStatus)

    return checkInStatus.toUpperCase() === CheckInStatus.CheckedIn
      ? time
      : theCheckInStatus
  }

  const commonProps = {
    id,
    appointmentType,
    checkIn: checkInTime(checkIn),
    consultant,
    consumerNetwork,
    isInvitation,
    medium,
    notes,
    reason,
    scheduledTime,
    status,
    title: title || mediumTitle,
    cancellationReasons,
    clinic,
    guestName,
    referrals,
    isToggleBoxVisible,
    menu: renderMenu
      ? renderMenu({
          id,
          cancellable: !!cancellable,
          isInvitation,
          patientId,
          status: status || '',
          onCancelAppointmentClick: () => onCancelAppointmentClick(id),
          referrals,
        })
      : undefined,
  }

  if (isWarning) {
    return <WarningCard {...commonProps} cancelledAt={cancelledAt} />
  }

  if (isInvitation) {
    return (
      <AppointmentInviteCard
        appointmentType={appointmentType}
        clinic={clinic}
        guestName={guestName}
        consumerNetwork={consumerNetwork}
        duration={duration}
        isToggleBoxVisible={isToggleBoxVisible}
        medium={medium}
        menu={commonProps.menu}
        notes={notes}
        reason={reason}
        title={commonProps.title}
      />
    )
  }

  return <AppointmentCard {...commonProps} icon={icon} duration={duration} />
}

export default TimelineCard
