import { gql } from '@apollo/client'

export const PrescriptionRegions = gql`
  query PrescriptionRegions {
    prescriptionRegions {
      regionCode
      name
    }
  }
`

const gpPrescriptionFieldsFragment = gql`
  fragment GpPrescriptionFieldsFragment on Prescription {
    id
    consultation {
      id
    }
    createdAt
    prescriptionState
    actions
    patient {
      id
      first_name
      last_name
    }
    drugs {
      drugName
      directions
      quantity
      packagingSize
    }
  }
`

export const GpPrescriptions = gql`
  query GpPrescriptionsQuery(
    $consultantId: ID!
    $size: Int!
    $page: Int!
    $sort: String
    $statuses: [String]
  ) {
    prescriptions(
      consultantId: $consultantId
      size: $size
      page: $page
      sort: $sort
      statuses: $statuses
    ) {
      pageIndex
      totalItems
      itemsPerPage
      items {
        ...GpPrescriptionFieldsFragment
      }
    }
  }
  ${gpPrescriptionFieldsFragment}
`

export const PrescriptionChangeState = gql`
  mutation PrescriptionChangeState(
    $id: ID!
    $action: PrescriptionStateActionType!
    $reason: String
  ) {
    prescriptionChangeState(id: $id, action: $action, reason: $reason) {
      ...GpPrescriptionFieldsFragment
    }
  }
  ${gpPrescriptionFieldsFragment}
`
