import { defineMessages } from 'react-intl'

const baseId = 'account_credits'

export default defineMessages({
  appointment_credits_heading: {
    id: `${baseId}.appointment_credits_heading`,
    defaultMessage: 'Appointment credits',
  },
  consumer_network_heading: {
    id: `${baseId}.consumer_network_heading`,
    defaultMessage: 'Consumer network',
  },
  professions_heading: {
    id: `${baseId}.professions_heading`,
    defaultMessage: 'Professions',
  },
  redeemed_heading: {
    id: `${baseId}.redeemed_heading`,
    defaultMessage: 'Redeemed?',
  },
  redeemed_yes: {
    id: `${baseId}.redeemed_yes`,
    defaultMessage: 'Yes',
  },
  redeemed_no: {
    id: `${baseId}.redeemed_no`,
    defaultMessage: 'No',
  },
  toggle_details: {
    id: `${baseId}.toggle_details`,
    defaultMessage: 'Toggle appointment credit details',
  },
  credit_details_date_heading: {
    id: `${baseId}.credit_details_date_heading`,
    defaultMessage: 'Date',
  },
  credit_details_created_by_heading: {
    id: `${baseId}.credit_details_created_by_heading`,
    defaultMessage: 'Created by',
  },
  credit_details_reason_heading: {
    id: `${baseId}.credit_details_reason_heading`,
    defaultMessage: 'Reason',
  },
  credit_details_date_time_created: {
    id: `${baseId}.credit_details_date_time_created`,
    defaultMessage: '{date}, {time}',
  },
  credit_details_remove: {
    id: `${baseId}.credit_details_remove`,
    defaultMessage: 'Remove',
  },
  add_credit_button: {
    id: `${baseId}.add_credit_button`,
    defaultMessage: 'Add credit',
  },
  add_credit_modal_heading: {
    id: `${baseId}.add_credit_modal_heading`,
    defaultMessage: 'Add appointment credit',
  },
  add_credit_modal_consumer_network: {
    id: `${baseId}.add_credit_modal_consumer_network`,
    defaultMessage: 'Consumer network',
  },
  add_credit_modal_professions: {
    id: `${baseId}.add_credit_modal_professions`,
    defaultMessage: 'Professions',
  },
  add_credit_modal_reason: {
    id: `${baseId}.add_credit_modal_reason`,
    defaultMessage: 'Reason',
  },
  add_credit_modal_create_credit_button: {
    id: `${baseId}.add_credit_modal_created_credit_button`,
    defaultMessage: 'Create appointment credit',
  },
  add_credit_success: {
    id: `${baseId}.add_credit_success`,
    defaultMessage: 'Success! The appointment credit was added!',
  },
  add_credit_error: {
    id: `${baseId}.add_credit_error`,
    defaultMessage: 'The appointment credit wasn’t added. Please try again.',
  },
  remove_credit_modal_heading: {
    id: `${baseId}.remove_credit_modal_heading`,
    defaultMessage: 'Remove appointment credit',
  },
  remove_credit_modal_body: {
    id: `${baseId}.remove_credit_modal_body`,
    defaultMessage: 'This will be removed permenantly.',
  },
  remove_credit_modal_action_confirm: {
    id: `${baseId}.remove_credit_modal_action_confirm`,
    defaultMessage: 'Remove',
  },
  remove_credit_modal_action_cancel: {
    id: `${baseId}.remove_credit_modal_action_cancel`,
    defaultMessage: 'Cancel',
  },
  remove_credit_success: {
    id: `${baseId}.remove_credit_success`,
    defaultMessage: 'Success! The appointment credit was removed!',
  },
  remove_credit_error: {
    id: `${baseId}.remove_credit_error`,
    defaultMessage:
      'The appointment credit could not be removed. Please try again.',
  },
})
