import React from 'react'
import { Card } from '@babylon/core-ui'
import { envUrl } from '@babylon/babylon-env'

const ENTERPRISE_PORTAL_URL = envUrl('ENTERPRISE_PORTAL_URL')

const ServiceTypePartnerPortalLink = () => (
  <Card title="Consumer network(s)">
    Consumer network service type assignment is now managed in the{' '}
    <a href={ENTERPRISE_PORTAL_URL} target="_blank" rel="noopener noreferrer">
      Partner Portal
    </a>
  </Card>
)

export default ServiceTypePartnerPortalLink
