import { gql } from '@apollo/client'

const CancelAppointmentMutation = gql`
  mutation cancelAppointment($appointmentId: ID!, $cancelReasonId: Int) {
    cancelAppointmentWithMicroservice(
      appointmentId: $appointmentId
      cancelReasonId: $cancelReasonId
    ) {
      id
      member: patient {
        id
      }
    }
  }
`

export default CancelAppointmentMutation
