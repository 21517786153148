import React, { useState, useEffect } from 'react'
import get from 'lodash/fp/get'
import {
  AppointmentInvite,
  Maybe,
  RepeatTemplate,
} from '@babylon/graphql-middleware-types'
import { CancelAppointmentDialog } from '@babylon/appointments'
import { envFlag } from '@babylon/babylon-env'
import { useTimelineQueries, PendingPatientInvites } from './useTimelineQueries'
import ServiceErrors, { Services } from '../ServiceErrors'
import LoadingTimeline from './LoadingTimeline'
import PatientHistory from './PatientHistory'
import OutstandingActivities from './OutstandingActivities'
import UpcomingActivities from './UpcomingActivities'
import { getServicesFromAllErrors } from './utils'

export interface TimelineProps {
  patientId: string
  patientUuid: string
}

function isNonNullNonExpired(
  invite: Maybe<AppointmentInvite>
): invite is AppointmentInvite {
  return invite !== null && !invite.expired
}

const Timeline = ({ patientId, patientUuid }: TimelineProps) => {
  const [cancellationDialogActive, setCancellationDialogActive] = useState(
    false
  )
  const [
    cancellationDialogAppointmentId,
    setCancellationDialogAppointmentId,
  ] = useState('')
  const {
    loading,
    activeWorkflows,
    patientHistory,
    pagedHistory,
    upcomingActivities,
    outstandingRepeatPrescriptions,
  } = useTimelineQueries(patientId, patientUuid)

  // avoid toggle in order to remain clear
  const onCancelAppointmentClick = (appointmentId: string) => {
    setCancellationDialogAppointmentId(appointmentId)
    setCancellationDialogActive(true)
  }

  const closeCancellationDialog = () => {
    setCancellationDialogAppointmentId('')
    setCancellationDialogActive(false)
  }

  const [initialLoad, setInitialLoad] = useState(true)

  useEffect(() => {
    if (!loading) {
      setInitialLoad(false)
    }
  }, [loading])

  const { data: workflowsData, error: workflowsError } = activeWorkflows
  const {
    data: pagedHistoryData,
    error: patientHistoryError,
    refetch: pastActivitesRefetch,
    fetchMore,
  } = pagedHistory

  const { data: patientHistoryData } = patientHistory

  const {
    data: upcomingActivitiesData,
    error: upcomingActivitiesError,
    refetch: upcomingActivitesRefetch,
  } = upcomingActivities
  const {
    data: outstandingRepeatTemplatesData,
    error: outstandingRepeatTemplatesError,
  } = outstandingRepeatPrescriptions
  let brokenServices: Services[] = []

  if (initialLoad) {
    return <LoadingTimeline />
  }

  const consultations = upcomingActivitiesData?.consultations?.items
  const outstandingRepeatTemplates = get('repeatTemplates.items')(
    outstandingRepeatTemplatesData
  )

  const repeatTemplates = (
    outstandingRepeatTemplates || []
  ).filter((repeatTemplate: RepeatTemplate) =>
    ['CHECKING_REORDER', 'FOLLOW_UP_NEEDED', 'READY_TO_COLLECT'].includes(
      repeatTemplate.userState
    )
  )

  const reorderTemplates = (
    outstandingRepeatTemplates || []
  ).filter((reorderTemplate: RepeatTemplate) =>
    ['REORDER_NOW', 'REORDER_SOON'].includes(reorderTemplate.userState)
  )

  const isFilterExpiredInvitesEnabled: boolean = !!envFlag(
    'ENABLE_FILTER_EXPIRED_INVITES'
  )

  const history = get('pagedHistory')(pagedHistoryData)
  const workflowTasks = get('workflowTasks')(workflowsData)
  const pendingInvites =
    get<PendingPatientInvites, 'patient', 'pendingInvites'>([
      'patient',
      'pendingInvites',
    ])(patientHistoryData) || []
  const filteredExpiredInvites = isFilterExpiredInvitesEnabled
    ? pendingInvites.filter<AppointmentInvite>(isNonNullNonExpired)
    : pendingInvites

  if (
    !loading &&
    (workflowsError ||
      patientHistoryError ||
      upcomingActivitiesError ||
      outstandingRepeatTemplatesError)
  ) {
    brokenServices = getServicesFromAllErrors(
      ...[
        workflowsError,
        patientHistoryError,
        upcomingActivitiesError,
        outstandingRepeatTemplatesError,
      ]
    )
  }

  return (
    <div data-testid="member-view-timeline">
      <ServiceErrors services={brokenServices} />
      <div>
        {cancellationDialogAppointmentId && (
          <CancelAppointmentDialog
            appointmentId={cancellationDialogAppointmentId}
            active={cancellationDialogActive}
            onClose={closeCancellationDialog}
            refetch={(): void => {
              upcomingActivitesRefetch()
              pastActivitesRefetch()
            }}
          />
        )}
        <OutstandingActivities
          error={patientHistoryError}
          invites={filteredExpiredInvites}
          workflowTasks={workflowTasks}
          reorderTemplates={reorderTemplates}
          repeatTemplates={repeatTemplates}
          loading={loading}
          patientId={patientId}
          onCancelAppointmentClick={onCancelAppointmentClick}
          pastActivitesRefetch={pastActivitesRefetch}
        />
        <UpcomingActivities
          consultations={consultations}
          error={upcomingActivitiesError}
          loading={loading}
          patientId={patientId}
          onCancelAppointmentClick={onCancelAppointmentClick}
        />
        <PatientHistory
          error={patientHistoryError}
          history={history}
          loading={pagedHistory.loading}
          patientId={patientId}
          fetchMore={fetchMore}
          onCancelAppointmentClick={onCancelAppointmentClick}
        />
      </div>
    </div>
  )
}

export default Timeline
