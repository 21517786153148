import React from 'react'
import { format as formatDate } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { BabylonUser } from '@babylon/babylon-user'

// force the date to wrap before the time ('\u00a0' = &nbsp;)
// the expected wrapped look:
//
//  18/08/2018
//  8:25 pm
//

interface TimezoneDateProps {
  date: string
  timezone?: string
  format?: string
}

const TimezoneDate = ({ date, timezone, format }: TimezoneDateProps) => {
  const dateFormat = format || 'dd/MM/yyyy h:mm\u00a0a'

  return timezone ? (
    <>{formatDate(utcToZonedTime(date, timezone), dateFormat)}</>
  ) : (
    <BabylonUser>
      {(user) =>
        user?.timezone
          ? formatDate(utcToZonedTime(date, user.timezone), dateFormat)
          : formatDate(new Date(date), dateFormat)
      }
    </BabylonUser>
  )
}

export default TimezoneDate
