import React, { useCallback, useEffect, useState } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import {
  AgentLiveConversation,
  Queue,
  RequestStatus,
  useAtlas,
  useAtlasStore,
} from '@babylon/atlas.js'
import PatientConversation from './PatientConversation'
import { PatientProfile } from '../../usePatientProfile'
import { ConversationTabHeader } from '../ConversationTabs/AgentConversationTabs'

type Props = {
  liveConversations: AgentLiveConversation[]
  uuid: string
  sendMessageNotification: (patientProfile: PatientProfile | undefined) => void
  playNotificationSound: () => void
  windowFocused: boolean
  fetchQueues: () => void
  queues: Queue[]
  queuesStatus: RequestStatus
  playNewUserSound: () => void
  sendMatchNotification: () => void
  removeConversationTab: (memberId: string) => void
  activeConversationId: string | null
  setActiveConversationId: (conversationId: string | null) => void
}

const AgentLiveConversations = ({
  liveConversations,
  uuid,
  sendMessageNotification,
  playNotificationSound,
  windowFocused,
  fetchQueues,
  queues,
  queuesStatus,
  playNewUserSound,
  sendMatchNotification,
  removeConversationTab,
  activeConversationId,
  setActiveConversationId,
}: Props) => {
  const [memberNames, setMemberNames] = useState<{ [key: string]: string }>({})
  const conversationTabIndex = liveConversations.findIndex(
    (conversation) => conversation.conversationId === activeConversationId
  )
  const setConversationTabIndex = useCallback(
    (tabIndex: number) =>
      setActiveConversationId(liveConversations[tabIndex].conversationId),
    [liveConversations, setActiveConversationId]
  )
  const atlas = useAtlas()

  const updateMemberNameFromProfile = (
    memberIdToUpdate: string,
    memberName: string
  ) =>
    setMemberNames({
      ...memberNames,
      [memberIdToUpdate]: memberName,
    })

  const updateMessageCount = useAtlasStore(
    (state) => state.rosterUpdateConversation
  )

  useEffect(
    () =>
      atlas.on('InboundMessage', (event) => {
        const activeConversation = liveConversations[conversationTabIndex]

        if (
          activeConversation &&
          activeConversation.conversationId === event.conversationId
        ) {
          if (windowFocused) {
            updateMessageCount(event.conversationId, (stat) => ({
              ...stat,
              notSeenMessageCount: 0,
              lastSeenMessageId: event.message.messageId,
            }))
          }

          return
        }

        if (
          event.message.senderId !== '00000000-0000-0000-0000-00000000002a' &&
          event.message.messageType === 'TEXT'
        ) {
          updateMessageCount(event.conversationId, (stat) => ({
            ...stat,
            notSeenMessageCount: stat.notSeenMessageCount + 1,
          }))
        }
      }),
    [
      atlas,
      updateMessageCount,
      conversationTabIndex,
      liveConversations,
      windowFocused,
    ]
  )
  const handleNewConversationRequest = useCallback(
    (conversaitionId: string) => {
      playNewUserSound()
      sendMatchNotification()
      setActiveConversationId(conversaitionId)
    },
    [playNewUserSound, sendMatchNotification, setActiveConversationId]
  )

  return (
    <Tabs
      selectedIndex={conversationTabIndex}
      onSelect={setConversationTabIndex}
    >
      <TabList>
        {liveConversations.map((value) => (
          <Tab key={value.memberId}>
            <ConversationTabHeader
              assignStatus={value.status}
              conversationId={value.conversationId}
              requestedAt={value.timestamp!}
              onTimeout={() => removeConversationTab(value.memberId)}
              title={memberNames[value.memberId] || value.memberId}
            />
          </Tab>
        ))}
      </TabList>

      {liveConversations.map((value) => (
        <TabPanel forceRender key={value.memberId}>
          <PatientConversation
            assignStatus={value.status}
            conversationId={value.conversationId}
            agentId={uuid}
            memberId={value.memberId}
            requestedAt={value.timestamp!}
            sendMessageNotification={sendMessageNotification}
            playNotificationSound={playNotificationSound}
            windowFocused={windowFocused}
            fetchQueues={fetchQueues}
            queues={queues}
            queuesStatus={queuesStatus}
            onNewConversationRequest={handleNewConversationRequest}
            updateMemberNameFromProfile={updateMemberNameFromProfile}
            removeConversationTab={removeConversationTab}
          />
        </TabPanel>
      ))}
    </Tabs>
  )
}

export default AgentLiveConversations
