import React from 'react'
import { useFormikContext } from 'formik'

import {
  Card,
  ButtonSimple,
  ButtonVariant,
  Grid,
  LinkButton,
  ButtonReactRouterLink,
  Text,
  Icon,
  List,
  ListItem,
} from '@babylon/medkit'

import ArrangeAppointmentForm from './ArrangeAppointmentTypes'

import { OTHER_SERVICE_TYPE } from './utils'
import { LoadingContainer } from '../../components'
import ConsultationTypes from '../../types/ConsultationTypes'

import styles from './InviteReview.module.css'

const icons = {
  physical: 'FaceToFace',
  video: 'VideoFilled',
  voice: 'MediaSmartPhoneFilled',
  default: 'MediaSmartPhoneFilled',
}
interface InviteReviewProps {
  inviteSending?: boolean
  onPreviousStep?: () => void
  memberId: string
  consultantOptions?: {
    value: string
    label: string
  }[]
  enrichedWithServiceType: ArrangeAppointmentForm
}

export default function InviteReview({
  inviteSending = false,
  onPreviousStep,
  consultantOptions,
  memberId,
  enrichedWithServiceType,
}: InviteReviewProps) {
  const { values } = useFormikContext<ArrangeAppointmentForm>()

  const {
    allowed_mediums: allowedMediums,
    allowed_professions: allowedProfessions,
    service_type_name: serviceTypeName,
    duration_minutes: durationMinutes,
    notes_for_member: notesForMember,
  } = values

  const professionLookup =
    consultantOptions?.reduce(
      (acc, consultant) => ({
        ...acc,
        [consultant.value]: {
          label: consultant.label,
          key: consultant.key,
        },
      }),
      {}
    ) || {}

  const filters = {
    allowed_mediums: values.allowed_mediums,
    consultation_type: values?.preferred_medium,
    allowed_professions: values.allowed_professions?.map(
      (proffessionId) => professionLookup?.[proffessionId]?.key
    ),
    consultant_type: professionLookup?.[values.preferred_profession || '']?.key,
    appointment_reason: values.notes_for_member,
    duration_minutes: values.duration_minutes,
    ...(OTHER_SERVICE_TYPE.uuid !== values.service_type_uuid && {
      service_type_uuid: values.service_type_uuid,
    }),
  }

  return (
    <LoadingContainer loading={inviteSending}>
      <Card>
        <Grid container className={styles.upperGrid}>
          <Grid item className={styles.cells}>
            <List variant="ul" className={styles.list}>
              <ListItem className={styles.listItem}>
                <Icon
                  className={styles.svg}
                  icon="AppointmentFilled"
                  title="Service type"
                />
                <Text variant="h5">{serviceTypeName}</Text>
              </ListItem>
              <ListItem className={styles.listItem}>
                <Icon className={styles.svg} icon="Clock" title="Duration" />
                <Text variant="h5">{durationMinutes} minutes</Text>
              </ListItem>
              {!!allowedProfessions?.length && (
                <ListItem className={styles.listItem}>
                  <Icon
                    className={styles.svg}
                    icon="PeopleMedicalSpecialist"
                    title="Profession"
                  />
                  <Text className={styles.AllowedProfessions} variant="h5">
                    {allowedProfessions
                      ?.map((id) => professionLookup[id]?.label)
                      .join(', ')}
                  </Text>
                </ListItem>
              )}
              <ListItem className={styles.listItem}>
                <Icon
                  className={styles.svg}
                  icon={
                    icons[allowedMediums?.[0] || 'default'] || icons.default
                  }
                  title="Medium"
                />
                <Text variant="h5">
                  {allowedMediums
                    ?.map((medium) => ConsultationTypes[medium])
                    .join(', ')}
                </Text>
              </ListItem>
            </List>
          </Grid>
          <Grid item className={styles.cellEdit}>
            <LinkButton onClick={onPreviousStep}>Edit</LinkButton>
          </Grid>
        </Grid>
        <Text variant="h5" className={styles.text}>
          Reason for appointment
        </Text>
        <Text variant="body">{notesForMember}</Text>
      </Card>
      <div className={styles.buttonRow}>
        <ButtonReactRouterLink
          variant={ButtonVariant.secondary}
          to={{
            pathname: `/admin/patients/${memberId}/schedule-appointment`,
            state: {
              filters,
              originalFilters: values,
              enrichedWithServiceType,
            },
          }}
        >
          Book for patient
        </ButtonReactRouterLink>
        <ButtonSimple type="submit">Send to patient</ButtonSimple>
      </div>
    </LoadingContainer>
  )
}
