import { gql } from '@apollo/client'

export default gql`
  mutation cancelAppointment($appointmentId: ID!, $cancelReasonId: Int) {
    cancelAppointmentWithMicroservice(
      appointmentId: $appointmentId
      cancelReasonId: $cancelReasonId
    ) {
      id
    }
  }
`
