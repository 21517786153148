import React from 'react'
import cx from 'classnames'

const Spinner = ({ visible = true }) => (
  <div className={cx({ loaderContainer: visible })}>
    <div className={cx({ loader: visible })} />
  </div>
)

export default Spinner
