import React from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import {
  FormikInput,
  FormikDatePicker,
  Button,
  Divider,
} from '@babylon/core-ui'
import type {
  AvailabilitySyncShiftFiltersProps,
  AvailabilitySyncShiftFilterValues,
} from './AvailabilitySyncShiftFilters.types'
import styles from './AvailabilitySyncShiftFilters.module.css'
import { FormikAsyncDropdown } from '../FormikAsyncDropdown'
import { stringToAsyncDropdownOptions } from '../../utils/filters'

const validationSchema = Yup.object().shape({
  consultantId: Yup.string().transform((value) => value || undefined),
  consultantName: Yup.string().transform((value) => value || undefined),
  shiftStartDate: Yup.date()
    .nullable()
    .transform((value) => value || undefined),
  shiftEndDate: Yup.date()
    .nullable()
    .transform((value) => value || undefined),
  error: Yup.string()
    .max(100, 'Error filter has a maximum length of 100 characters.')
    .transform((value) => value || undefined),
  warning: Yup.string()
    .max(100, 'Warning filter has a maximum length of 100 characters.')
    .transform((value) => value || undefined),
})

const resetFormValues = {
  consultantId: undefined,
  consultantName: undefined,
  shiftStartDate: undefined,
  shiftEndDate: undefined,
  error: undefined,
  warning: undefined,
}

export const AvailabilitySyncShiftFilters = ({
  initialValues,
  onConsultantSearch,
  onSubmit,
}: AvailabilitySyncShiftFiltersProps) => {
  const handleSubmit = (values: AvailabilitySyncShiftFilterValues) => {
    const castValues = validationSchema.cast(values, { assert: false })

    onSubmit(castValues)
  }

  const handleReset = (
    _values: AvailabilitySyncShiftFilterValues,
    formikHelpers: FormikHelpers<AvailabilitySyncShiftFilterValues>
  ) => {
    formikHelpers.resetForm({
      values: resetFormValues,
    })

    onSubmit(resetFormValues)
  }

  return (
    <Formik<AvailabilitySyncShiftFilterValues>
      onSubmit={handleSubmit}
      onReset={handleReset}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form className={styles.form} name="Availability Sync Shift Filters">
        <h2 className={styles.heading}>Filters</h2>

        <Divider />

        <div className={styles.filters}>
          <div className={styles.filter}>
            <FormikInput
              id="consultantId"
              name="consultantId"
              label="Consultant ID"
            />
          </div>

          <div className={styles.filter}>
            <FormikAsyncDropdown
              name="consultantName"
              label="Consultant name"
              placeholder="Start typing to search..."
              onSearch={onConsultantSearch}
              initialOptions={stringToAsyncDropdownOptions(
                initialValues.consultantName
              )}
            />
          </div>

          <div className={styles.filter}>
            <FormikDatePicker
              id="shiftStartDate"
              name="shiftStartDate"
              label="Shift start date"
              displayError={false}
            />
          </div>

          <div className={styles.filter}>
            <FormikDatePicker
              id="shiftEndDate"
              name="shiftEndDate"
              label="Shift end date"
              displayError={false}
            />
          </div>

          <div className={styles.filter}>
            <FormikInput
              id="error"
              name="error"
              label="Error"
              maxLength={100}
            />
          </div>

          <div className={styles.filter}>
            <FormikInput
              id="warning"
              name="warning"
              label="Warning"
              maxLength={100}
            />
          </div>
        </div>

        <div className={styles.filterButtons}>
          <Button type="reset" intent="secondary">
            Reset filters
          </Button>
          <Button type="submit">Apply filters</Button>
        </div>
      </Form>
    </Formik>
  )
}

export default AvailabilitySyncShiftFilters
