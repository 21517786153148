import ServiceType from '../../types/ServiceType'
import { ConsultationTypeName } from '../../types/ConsultationTypes'
import { MediumTypes, Mediums } from './AppointmentMedium'

type MediumParamsForInvite = {
  allowed_mediums?: ConsultationTypeName[]
  preferred_medium?: ConsultationTypeName
}

const getAllowedMediumsForSelectedMedium = (
  selectedMedium: MediumTypes | null,
  serviceTypeMediums: ConsultationTypeName[] | undefined
): MediumParamsForInvite => {
  if (selectedMedium === Mediums.f2f) {
    return {
      allowed_mediums: ['physical'],
      preferred_medium: 'physical',
    }
  }

  if (selectedMedium === Mediums.digital) {
    const voiceOrDigitalMediums = serviceTypeMediums?.filter(
      (medium) => medium === 'video' || medium === 'voice'
    )

    return {
      allowed_mediums: voiceOrDigitalMediums,
      preferred_medium: voiceOrDigitalMediums?.[0],
    }
  }

  return {}
}

const findServiceTypeById = (
  serviceTypes: ServiceType[],
  serviceTypeId: string
): ServiceType | undefined => {
  const serviceType = serviceTypes.find(({ uuid }) => uuid === serviceTypeId)

  return serviceType
}

const filterByMediumServiceTypes = (
  serviceTypes: ServiceType[],
  medium: MediumTypes | null
): ServiceType[] => {
  if (!medium) {
    return serviceTypes
  }

  if (medium === Mediums.f2f) {
    return serviceTypes.filter(({ allowed_mediums }) =>
      allowed_mediums.includes('physical')
    )
  }

  if (medium === Mediums.digital) {
    return serviceTypes.filter(
      ({ allowed_mediums }) =>
        allowed_mediums.includes('video') || allowed_mediums.includes('voice')
    )
  }

  return serviceTypes
}

export {
  getAllowedMediumsForSelectedMedium,
  filterByMediumServiceTypes,
  findServiceTypeById,
}
