import { gql } from '@apollo/client'

const GetConsentDecisionForPatient = gql`
  query getConsentDecisionForPatient($patientUUID: ID!) {
    informedConsent: memberDecisionForNotice(
      input: {
        patientUUID: $patientUUID
        appName: BABYLON_US
        noticeEnum: INFORMED_CONSENT
      }
    ) {
      decisionId
      userId
      noticeVersionId
      accepted
      date
      expired
    }
    medicationHistory: memberDecisionForNotice(
      input: {
        patientUUID: $patientUUID
        appName: BABYLON_US
        noticeEnum: MEDICATION_HISTORY_AUTHORIZATION
      }
    ) {
      decisionId
      userId
      noticeVersionId
      accepted
      date
      expired
    }
  }
`

export default GetConsentDecisionForPatient
