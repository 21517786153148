import React from 'react'
import { useQuery } from '@apollo/client'
import { Grid, Cell } from '@babylon/core-ui'
import { Text } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { DrawerView } from '../../../../../..'
import { QUERY_SICK_NOTES } from './queries'
import messages from './messages'
import styles from './styles.module.scss'
import { MemberOpsModuleName } from '../../../../../../..'
/* eslint-disable react/no-unused-prop-types */
type SickNote = {
  appointmentId: string
  noteContent: string
  startDate: string
  endDate: string
  id: number
  url: string
}
/* eslint-enable react/no-unused-prop-types */

type Props = {
  patientId: string
  toggleVisible: () => void
}

const SickNotes = ({ patientId, toggleVisible }: Props) => {
  const { data, loading } = useQuery(QUERY_SICK_NOTES, {
    variables: {
      patientId,
    },
  })

  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })

  if (loading) {
    return null
  }

  const { getSickNotes: sickNotes } = data

  return (
    <DrawerView
      visible
      toggleVisible={toggleVisible}
      title={fm(messages.heading)}
    >
      {(!sickNotes || sickNotes.length === 0) && (
        <Text variant="body" className={styles.NoNotesMessage}>
          {fm(messages.no_sick_notes)}
        </Text>
      )}

      {sickNotes && sickNotes.length > 0 && (
        <>
          <Grid columns={4} className={styles.TitleContainer}>
            <Cell className={styles.Title}>{fm(messages.reasonHeading)}</Cell>
            <Cell className={styles.Title}>
              {fm(messages.startDateHeading)}
            </Cell>
            <Cell className={styles.Title}>{fm(messages.endDateHeading)}</Cell>
          </Grid>

          <div className={styles.Container}>
            {sickNotes.map(
              ({
                appointmentId,
                endDate,
                noteContent,
                startDate,
                url,
              }: SickNote) => (
                <Grid
                  columns={4}
                  className={styles.SickNote}
                  key={`${appointmentId}-${startDate}`}
                >
                  <Cell>{noteContent}</Cell>
                  <Cell>{startDate}</Cell>
                  <Cell>{endDate}</Cell>
                  <Cell>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={url}
                      onClick={() =>
                        trackClick({
                          elementName: 'download-sick-notes-button',
                          elementType: TrackingElementType.button,
                        })
                      }
                    >
                      {fm(messages.download)}
                    </a>
                  </Cell>
                </Grid>
              )
            )}
          </div>
        </>
      )}
    </DrawerView>
  )
}

export default SickNotes
