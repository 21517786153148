import { gql } from '@apollo/client'

export default gql`
  query GetSupplyNetworks {
    supplyNetworks {
      id
      uuid
      name
    }
  }
`
