import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Icon from '@/components/Icon'

import styles from './styles.module.scss'

const SortButtonView = ({ name, sort, sortState, onSort, loading }) => {
  if (sort) {
    let iconSuffix = ''
    const nextState = {
      column: name,
      order: 'asc',
    }
    let sorting = false

    if (sortState && sortState.column === name) {
      const desc = sortState.order === 'desc'
      iconSuffix = desc ? '-up' : '-down'
      nextState.order = desc ? 'asc' : 'desc'
      sorting = loading
    }

    return (
      <button
        className={styles.sortButton}
        onClick={() => onSort && onSort(nextState)}
      >
        <Icon name={`sort${iconSuffix}`} />
        <Icon
          className={cx(styles.spinner, sorting || styles.hidden)}
          name="spinner"
        />
      </button>
    )
  }

  return null
}

SortButtonView.defaultProps = {
  sort: false,
  sortState: null,
  onSort: null,
  loading: false,
}

SortButtonView.propTypes = {
  name: PropTypes.string.isRequired,
  sort: PropTypes.bool,
  sortState: PropTypes.shape({
    column: PropTypes.string.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }),
  onSort: PropTypes.func,
  loading: PropTypes.bool,
}

export default SortButtonView
