import { defineMessages } from 'react-intl'

const baseId = 'assessment_reset_form'

export default defineMessages({
  description: {
    id: `${baseId}.description`,
    defaultMessage:
      'This will delete all assessment data for this member. Any already existing PDF reports in athena will be unaffected. This action is not reversible.',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  reset: {
    id: `${baseId}.reset`,
    defaultMessage: 'Reset',
  },
  resetError: {
    id: `${baseId}.reset_error`,
    defaultMessage: 'Something went wrong. Please try resetting again.',
  },
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Are you sure you want to reset this assessment?',
  },
})
