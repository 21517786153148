import React from 'react'
import type { AppointmentsMessageProps } from './AppointmentsMessage.types'
import styles from './AppointmentsMessage.module.css'

const AppointmentsMessage = ({ title, message }: AppointmentsMessageProps) => (
  <div className={styles.appointmentsMessage}>
    <div className={styles.content}>
      <h2 className={styles.heading}>{title}</h2>
      <p className={styles.message}>{message}</p>
    </div>
  </div>
)

export default AppointmentsMessage
