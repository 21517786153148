export const QUERY_PARAMS_TO_IGNORE_FOR_FILTERS = [
  'currentPage',
  'pageSize',
  'filterPanelOpen',
]

export const DEFAULT_PAGINATION_CRITERIA = {
  currentPage: 0,
  pageSize: 20,
}

export const DEFAULT_FORM_VALUES = {
  dates: {
    startDate: null,
    endDate: null,
  },
  consultation_id: '',
  consumer_network: null,
  family_name: '',
  given_name: '',
  gender: null,
  patient_id: '',
  public_healthcare_id: '',
  marked_as_sent: false,
}

export const FILTER_DISPLAY_DATE_FORMAT = 'dd/MM/yy'
