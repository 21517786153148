import React from 'react'
import { Button } from '@babylon/babylon-forms/forms'
import {
  SearchBox,
  DataGrid,
  Actions,
  Columns,
  Column,
  Pagination,
  PaginationSize,
  Row,
} from '@babylon/babylon-forms/widgets'

import Icon from '@/components/Icon'
import LabelWithIcon from '@/components/LabelWithIcon'

import styles from './styles.module.scss'

const Spinner = ({ visible }) => (visible ? <Icon name="spinner" /> : null)

const sortLabelToColumnName = (label) =>
  label === 'activeIngredient.name' ? 'activeIngredient' : label

const parseSort = (sort) => {
  const [column, direction] = sort.split(',')

  return {
    column: sortLabelToColumnName(column),
    direction,
  }
}

const formatIri = ({ iri }) =>
  iri ? (
    <a
      className={styles.externalLink}
      href={iri}
      rel="noopener noreferrer"
      target="_blank"
    >
      {iri} <Icon name="externalLink" />
    </a>
  ) : null

const ActiveIngredientsView = ({
  spinnerType,
  data: {
    loading,
    adminActiveIngredients: { itemsPerPage, pageIndex, totalItems, items },
    variables: { sort },
  },
  handlePageSizeChange,
  handlePageChange,
  handleSearchChange,
  handleAuditClick,
  handleSort,
}) => (
  <div className={styles.alertView}>
    <Row className={styles.listHeader} stretch center>
      <Row>
        <SearchBox
          icon={<Icon name="search" />}
          className={styles.searchBox}
          onChange={handleSearchChange}
        />
        <PaginationSize
          value={itemsPerPage}
          onChange={handlePageSizeChange}
          totalItems={totalItems}
          spinner={<Spinner visible={spinnerType === 'page-size' && loading} />}
        />
      </Row>
    </Row>
    <DataGrid
      value={items}
      sort={{
        ...parseSort(sort),
        loading: spinnerType === 'sort' && loading,
      }}
      onSort={handleSort}
      icons={{
        asc: <Icon name="sort-up" />,
        desc: <Icon name="sort-down" />,
        sort: <Icon name="sort" />,
        spinner: <Spinner visible />,
      }}
    >
      <Columns>
        <Column name="name" label="Name" sort />
        <Column name="iri" label="Iri" value={formatIri} />
      </Columns>
      <Actions>
        <Button small onClick={handleAuditClick}>
          <LabelWithIcon icon="logs" label="Audits" />
        </Button>
      </Actions>
    </DataGrid>
    <div className={styles.listFooter}>
      <div />
      <div className={styles.pagination}>
        <div className={styles.spinner}>
          <Spinner visible={spinnerType === 'pagination' && loading} />
        </div>
        <Pagination
          page={pageIndex}
          size={itemsPerPage}
          totalElements={totalItems}
          onChange={handlePageChange}
        />
      </div>
    </div>
  </div>
)

export default ActiveIngredientsView
