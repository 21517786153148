/* eslint-disable curly */
/* eslint-disable padding-line-between-statements */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from '@babylon/core-ui'
import { uniqBy } from 'lodash'
import { Queue, RequestStatus } from '@babylon/atlas.js/dist/lib/types'
import Modal from '../Modal'

import { ReactComponent as UserSVG } from './user.svg'

import styles from './styles.module.css'

import Key from '../Key'
import { getQueueByIdx, splitQueueAgentIdx } from './utils'
import SystemQueueItem from './SystemQueueItem'
import AgentQueueItem from './AgentQueueItem'
import useKeys from './useKeys'
import QueueListItem from './QueueListItem'

type Props = {
  userId: string
  visible: boolean
  queues: Queue[]
  queuesStatus: RequestStatus
  onQueueSelect: (queue: Queue) => void
  onRequestClose: () => void
}

const QueueSearchList = ({
  queues,
  onQueueSelect,
  userId,
}: {
  queues: Queue[]
  onQueueSelect: (q: Queue) => void
  userId: string
}) => {
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const totalIdx = queues.length - 1
  useKeys({
    up: () => setSelectedIdx((idx) => (idx === -1 ? -1 : idx - 1)),
    down: () => setSelectedIdx((idx) => (idx === totalIdx ? idx : idx + 1)),
    enter: () => {
      onQueueSelect(queues[selectedIdx])
    },
  })

  return (
    <>
      <div className={styles.queueDescription}>Available queues:</div>
      <div className={styles.queueList}>
        {queues.length === 0 && <QueueListItem title="No search results" />}
        {queues.map((q, idx) => (
          <AgentQueueItem
            key={q.id}
            agent={q.agents[0]}
            highlighted={selectedIdx === idx}
            personalQueue={q}
            onQueueSelect={onQueueSelect}
            userId={userId}
          />
        ))}
      </div>
    </>
  )
}

const QueuesList = ({
  queues,
  onQueueSelect,
  userId,
}: {
  queues: Queue[]
  onQueueSelect: (q: Queue) => void
  userId: string
}) => {
  // TODO: Filter QA acceptance queues from endpoint instead of FE.
  const systemQueues = queues.filter(
    (q) => q.queue_type === 'system' && !q.id.includes('SUPPORTCoPTeR')
  )
  const userQueues = queues.filter((q) => q.queue_type === 'user')
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const [selectedQueueIdx, selectedAgentIdx] = useMemo(
    () => splitQueueAgentIdx(systemQueues, selectedIdx),
    [systemQueues, selectedIdx]
  )
  const totalIdx = systemQueues
    .map((q) => q.agents.length + 1)
    .reduce((a, b) => a + b, 0)

  useKeys({
    up: () => setSelectedIdx((idx) => (idx === -1 ? -1 : idx - 1)),
    down: () => setSelectedIdx((idx) => (idx === totalIdx - 1 ? idx : idx + 1)),
    enter: () => {
      const selectedQueue = getQueueByIdx(
        systemQueues,
        userQueues,
        selectedQueueIdx,
        selectedAgentIdx
      )
      if (selectedQueue) onQueueSelect(selectedQueue)
    },
  })

  return (
    <>
      <div className={styles.queueDescription}>Available queues:</div>
      {systemQueues.map((q, qIdx) => (
        <div className={styles.queueList} key={q.id}>
          <SystemQueueItem
            onQueueSelect={onQueueSelect}
            highlighted={qIdx === selectedQueueIdx && selectedAgentIdx === -1}
            queue={q}
          >
            {q.agents.map((a, aIdx) => (
              <AgentQueueItem
                key={a.id}
                agent={a}
                highlighted={
                  qIdx === selectedQueueIdx && aIdx === selectedAgentIdx
                }
                personalQueue={
                  userQueues.find((userQueue) => userQueue.id === a.id)!
                }
                onQueueSelect={onQueueSelect}
                userId={userId}
              />
            ))}
          </SystemQueueItem>
        </div>
      ))}
    </>
  )
}

const TransferModal = ({
  userId,
  queues,
  queuesStatus,
  visible,
  onQueueSelect,
  onRequestClose,
}: Props) => {
  const [search, setSearch] = useState<string>('')
  const userQueues = queues.filter(
    (q) => q.queue_type === 'user' && q.agents.length > 0
  )
  const userSearching = search.length > 0
  const foundQueues = userSearching
    ? uniqBy(
        userQueues.filter((q) => {
          // user queues always only have a single agent.
          // because they belong to a single user!
          const agent = q.agents[0]
          if (!agent) return false
          return (
            agent.nickname.toLowerCase().includes(search.toLowerCase()) &&
            agent.id !== userId
          )
        }),
        (q) => q.id
      )
    : []

  useKeys({
    enabled: visible,
    esc: () => onRequestClose(),
  })

  // react ref for html input element
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (visible) inputRef.current?.focus()
  }, [visible])

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div className={styles.searchContainer}>
        <UserSVG />
        <input
          ref={inputRef}
          className={styles.searchInput}
          placeholder="Type in or select your teammate"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
          }}
        />
      </div>
      {queuesStatus === 'PENDING' && (
        <div className={styles.spinner}>
          <Spinner size="large" />
        </div>
      )}

      {visible && queuesStatus === 'SUCCESS' && userSearching && (
        <QueueSearchList
          queues={foundQueues}
          onQueueSelect={onQueueSelect}
          userId={userId}
        />
      )}
      {visible && queuesStatus === 'SUCCESS' && !userSearching && (
        <QueuesList
          queues={queues}
          onQueueSelect={onQueueSelect}
          userId={userId}
        />
      )}
      <div className={styles.keyDescriptors}>
        <div className={styles.keyDescriptor}>
          <Key keyType="ESC" />
          <span>to close</span>
        </div>
        <div className={styles.keyDescriptor}>
          <Key keyType="UP" />
          <Key keyType="DOWN" />
          <span>to navigate</span>
        </div>
        <div className={styles.keyDescriptor}>
          <Key keyType="RETURN" />
          <span>to select</span>
        </div>
      </div>
    </Modal>
  )
}

export default TransferModal
