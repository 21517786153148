import { ColumnsVisibility } from '@/components/DataGrid'

export enum PHARMACIST_DATAGRID_COLUMNS {
  ID = 'id',
  NAME = 'name',
  PHARMACY_SUPPLIER = 'pharmacy_supplier',
  ACTIVE = 'active',
  EMAIL = 'email',
  REGION = 'region_name',
}

export const columnsToShow: ColumnsVisibility = {
  [PHARMACIST_DATAGRID_COLUMNS.ID]: false,
  [PHARMACIST_DATAGRID_COLUMNS.NAME]: true,
  [PHARMACIST_DATAGRID_COLUMNS.PHARMACY_SUPPLIER]: true,
  [PHARMACIST_DATAGRID_COLUMNS.ACTIVE]: false,
  [PHARMACIST_DATAGRID_COLUMNS.EMAIL]: true,
  [PHARMACIST_DATAGRID_COLUMNS.REGION]: true,
}

export const columns = [
  {
    fieldNameInRow: PHARMACIST_DATAGRID_COLUMNS.ID,
    headerName: 'ID',
    flex: 0.5,
  },
  {
    fieldNameInRow: PHARMACIST_DATAGRID_COLUMNS.NAME,
    headerName: 'Name',
    flex: 2,
  },
  {
    fieldNameInRow: PHARMACIST_DATAGRID_COLUMNS.PHARMACY_SUPPLIER,
    headerName: 'Pharmacy Supplier',
    flex: 2,
  },
  {
    fieldNameInRow: PHARMACIST_DATAGRID_COLUMNS.ACTIVE,
    headerName: 'Active',
    flex: 1,
  },
  {
    fieldNameInRow: PHARMACIST_DATAGRID_COLUMNS.EMAIL,
    headerName: 'Email',
    flex: 1,
  },
]
