import React, { FC, ReactNode, useRef } from 'react'
import classnames from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import restrictedClipboard from '@babylon/restricted-clipboard'
import messages from './CopyToClipboardButton.messages'
import styles from './styles.module.scss'

export interface CopyToClipboardButtonProps {
  className?: string
  value?: string | number
  onCopiedToClipboard?(value: ReactNode): void
}

const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = ({
  children,
  className,
  value = '',
  onCopiedToClipboard,
}) => {
  const fm = useFormatMessage()
  const inputRef = useRef(null)

  const copyToClipboardHandler = async () => {
    await restrictedClipboard.copy(value)
    if (onCopiedToClipboard) {
      onCopiedToClipboard(value)
    }
  }

  return (
    <button
      data-testid="copy-to-clipboard-button"
      type="button"
      title={fm(messages.label)}
      onClick={copyToClipboardHandler}
      className={classnames(styles.copyToClipboardButton, className)}
    >
      <input
        className={styles.copyToClipboardButton__hiddenInput}
        readOnly
        type="text"
        value={value || ''}
        ref={inputRef}
      />
      {children}
    </button>
  )
}

export default CopyToClipboardButton
