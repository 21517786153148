import React from 'react'
import cx from 'classnames'
import Checkbox from './Checkbox'

const ListView = ({
  items,
  selectedItems,
  onSelectionChanged,
  itemRenderer: ItemRenderer,
  itemRendererProps = {},
  className,
}) => (
  <div className={cx('widget list-view', className)}>
    {items.map((item) => {
      const selected = selectedItems && selectedItems.includes(item.id)
      return (
        <div className="list-item" key={item.id}>
          {selectedItems && (
            <Checkbox
              label=" "
              value={selected}
              onChange={() => {
                if (selected) {
                  onSelectionChanged(
                    selectedItems.filter((sel) => sel !== item.id)
                  )
                } else {
                  onSelectionChanged([...selectedItems, item.id])
                }
              }}
            />
          )}
          <ItemRenderer item={item} {...itemRendererProps} />
        </div>
      )
    })}
  </div>
)

export default ListView
