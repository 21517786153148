import { gql } from '@apollo/client'

export default gql`
  mutation AddConsumerNetworksToServiceType(
    $service_type_uuid: ID!
    $consumer_network_ids: [ID!]!
  ) {
    addConsumerNetworksToServiceType(
      service_type_uuid: $service_type_uuid
      consumer_network_ids: $consumer_network_ids
    )
  }
`
