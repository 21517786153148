import React from 'react'
import { confirmable, createConfirmation } from 'react-confirm'
import { Button } from '@babylon/babylon-forms/forms'
import { Row } from '@babylon/babylon-forms/widgets'

import { ModalOverlay } from '@babylon/member-operations'

import styles from './styles.module.scss'

const AlertDialog = ({ message, okLabel, proceed }) => (
  <ModalOverlay className={styles.dim} visible>
    <div className={styles.dialog}>
      <p>{message}</p>
      <Row className={styles.buttonRow}>
        <Button alert onClick={proceed} label={okLabel} />
      </Row>
    </div>
  </ModalOverlay>
)

const dialog = confirmable(AlertDialog)
const confirm = createConfirmation(dialog, 1)
const showAlertDialog = (message, okLabel = 'Ok') => () =>
  confirm({ message, okLabel })

export default showAlertDialog
