import React from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'

import styles from './styles.module.scss'

type ModalOverlayProps = {
  onRequestClose: () => void
  visible: boolean
  className: string
  htmlId?: string
  scrollToTop?: number
}

type DimProps = {
  onRequestClose: () => void
  visible: boolean
  el: HTMLElement
}

class ModalOverlay extends React.Component<ModalOverlayProps> {
  static show(dimProps: DimProps) {
    if (ModalOverlay.visibleChildren.length) {
      const idx = ModalOverlay.visibleChildren.length - 1
      const topChild = ModalOverlay.visibleChildren[idx]

      if (topChild.el) {
        topChild.el.style.zIndex = `${8000 + idx}`
      }
    }

    ModalOverlay.visibleChildren.push(dimProps)
    dimProps.el.style.zIndex = `${
      9000 + ModalOverlay.visibleChildren.length - 1
    }`
    ModalOverlay.elDim.style.opacity = '1'
    ModalOverlay.elRoot.style.pointerEvents = 'auto'
    document.body.classList.add('modal-open')
  }

  static hide(dimProps: DimProps) {
    const idx = ModalOverlay.visibleChildren.indexOf(dimProps)

    if (idx !== -1) {
      ModalOverlay.visibleChildren.splice(idx, 1)
    }

    if (ModalOverlay.visibleChildren.length) {
      const idx = ModalOverlay.visibleChildren.length - 1
      const topChild = ModalOverlay.visibleChildren[idx]

      if (topChild.el && topChild.visible) {
        topChild.el.style.zIndex = `${9000 + idx}`
      }
    } else {
      ModalOverlay.elDim.style.opacity = '0'
      ModalOverlay.elRoot.style.pointerEvents = 'none'
    }

    document.body.classList.remove('modal-open')
  }

  static closeTop() {
    const topChild =
      ModalOverlay.visibleChildren[ModalOverlay.visibleChildren.length - 1]

    if (topChild && topChild.onRequestClose) {
      topChild.onRequestClose()
    }
  }

  static handleKeyDown(event: KeyboardEvent) {
    // "Esc" is IE/Edge specific value
    const escapeKeyPressed = ['Escape', 'Esc'].includes(event.key)
    const hasVisibleChildren = ModalOverlay.visibleChildren.length > 0

    if (escapeKeyPressed && hasVisibleChildren) {
      ModalOverlay.closeTop()
      event.stopImmediatePropagation()
    }
  }

  dimProps: DimProps

  constructor(props: ModalOverlayProps) {
    super(props)
    const { onRequestClose, visible, className } = props
    const el = document.createElement('div')

    el.className = cx(styles.modal, className)
    this.dimProps = { onRequestClose, visible, el }
  }

  componentDidMount() {
    if (!ModalOverlay.elRoot) {
      ModalOverlay.elRoot = document.createElement('div')
      ModalOverlay.elRoot.className = styles.root
      document.body.appendChild(ModalOverlay.elRoot)

      ModalOverlay.elDim = document.createElement('div')
      ModalOverlay.elDim.className = styles.dim
      ModalOverlay.elDim.addEventListener('mousedown', ModalOverlay.closeTop)
      ModalOverlay.elRoot.appendChild(ModalOverlay.elDim)

      window.addEventListener('keydown', ModalOverlay.handleKeyDown)
    }

    ModalOverlay.elRoot.appendChild(this.dimProps.el)

    if (this.dimProps.visible) {
      ModalOverlay.show(this.dimProps)
    }
  }

  componentDidUpdate(prevProps: ModalOverlayProps) {
    this.dimProps.onRequestClose = this.props.onRequestClose
    this.dimProps.visible = this.props.visible

    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        ModalOverlay.show(this.dimProps)
      } else {
        ModalOverlay.hide(this.dimProps)
      }
    }
  }

  componentWillUnmount() {
    if (this.props.visible) {
      ModalOverlay.hide(this.dimProps)
    }

    ModalOverlay.elRoot.removeChild(this.dimProps.el)
  }

  static visibleChildren: DimProps[] = []

  static elRoot: HTMLElement
  static elDim: HTMLElement

  render() {
    // TODO: This is being done to set the popover in book appointment model in proper place. This need to be removed once popover problem is fixed
    // NOTE from 2021: We still need to verify this
    if (this.props.scrollToTop) {
      document.documentElement.scrollTop = 0
    }

    if (this.props.htmlId) {
      this.dimProps.el.id = this.props.htmlId
    }

    return ReactDOM.createPortal(this.props.children, this.dimProps.el)
  }
}

export default ModalOverlay
