import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getHoursAndMinutes } from '../../utils'
import StopwatchIcon from '../../icons/StopwatchIcon'
import messages from './Duration.messages'
import styles from './styles.module.scss'

const Duration = ({ duration }: { duration: number | undefined }) => {
  if (!duration) {
    return null
  }

  return (
    <div className={styles.Duration}>
      <div className={styles.Duration__icon}>
        <StopwatchIcon height={16} width={16} />
      </div>
      <div data-testid="timeline-card-duration">
        <FormattedMessage
          {...messages.duration}
          values={getHoursAndMinutes(duration)}
        />
      </div>
    </div>
  )
}

export default Duration
