import { defineMessages } from 'react-intl'

const baseId = 'timeline_card'

export default defineMessages({
  book_appointment: {
    id: `${baseId}.book_appointment`,
    defaultMessage: 'Book appointment',
  },
  cancel_appointment: {
    id: `${baseId}.cancel_appointment`,
    defaultMessage: 'Cancel appointment',
  },
  invitation: {
    id: `${baseId}.invitation`,
    defaultMessage: 'Invitation',
  },
  physical: {
    id: `${baseId}.physical`,
    defaultMessage: 'Face to face',
  },
  digital: {
    id: `${baseId}.digital`,
    defaultMessage: 'Digital consultation',
  },
  text: {
    id: `${baseId}.text`,
    defaultMessage: 'Text consultation',
  },
  cancelled: {
    id: `${baseId}.cancelled`,
    defaultMessage: 'Cancelled',
  },
  no_show: {
    id: `${baseId}.no_show`,
    defaultMessage: 'No show',
  },
  timed_out: {
    id: `${baseId}.timed_out`,
    defaultMessage: 'Timed out',
  },
  send_referral_success: {
    id: `${baseId}.send_referral_success`,
    defaultMessage: 'Referral successfully sent',
  },
  send_referral_error: {
    id: `${baseId}.send_referral_error`,
    defaultMessage: 'Error sending referral',
  },
  view_consultation_notes: {
    id: `${baseId}.view_consultation_notes`,
    defaultMessage: 'View consultation notes',
  },
  view_consultation_summary_link: {
    id: `${baseId}.view_consultation_summary_link`,
    defaultMessage: 'View PDF',
  },
  view_referral: {
    id: `${baseId}.view_referral`,
    defaultMessage: 'View referral letter',
  },
  view_referrals: {
    id: `${baseId}.view_referrals`,
    defaultMessage: 'View referrals',
  },
  send_referral_email: {
    id: `${baseId}.send_referral_email`,
    defaultMessage: 'Send referral email',
  },
})
