import React from 'react'
import { Doctor, Membership } from '@babylon/icons'
import { Admin, Commercial, Dashboard, LiveChat } from './icons'
import { IconKeys } from './types'

export interface IconProps {
  id?: IconKeys
}

const Icon = ({ id }: IconProps) => {
  if (!id) {
    return null
  }

  switch (id) {
    case IconKeys.Dashboard:
      return <Dashboard width={20} />
    case IconKeys.Patients:
      return <Membership width={20} />
    case IconKeys.Practitioners:
      return <Doctor width={20} />
    case IconKeys.Admin:
      return <Admin />
    case IconKeys.Commercial:
      return <Commercial width={20} />
    case IconKeys.LiveChat:
      return <LiveChat width={22} />
    default:
      return null
  }
}

export default Icon
