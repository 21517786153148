import React from 'react'

import { GroupedSlots, buildEmptySlot } from './GroupSlots'
import { Clinic } from './GroupSlotsByClinic'
import AvailabilitySlot from './AvailabilitySlot'
import AvailabilitySlotsHeader from './AvailabilitySlotsHeader'
import styles from './AvailabilitySlots.module.css'

interface Props {
  clinicList: Clinic[]
  groupedSlots: GroupedSlots
  slotDuration: number
  consentExpired?: boolean
}

const AvailabilitySlotsGrid = ({
  clinicList,
  groupedSlots,
  slotDuration,
  consentExpired,
}: Props) => (
  <div className={styles.grid} data-testid="availability-slots-grid">
    <table className={styles.gridTable}>
      <thead>
        <tr>
          {groupedSlots.headers.map((header) => (
            <th key={`${header.key}`} className={styles.gridHeader}>
              <AvailabilitySlotsHeader
                clinicName={header.clinicName}
                clinicTimezone={header.clinicTimezone}
                dayOfWeek={header.dayOfWeek}
                dayOfMonth={header.dayOfMonth}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {groupedSlots.times.map((time) => (
          <tr key={time}>
            {groupedSlots.headers.map((header) => (
              <td key={`${header.key}-${time}`} className={styles.gridCell}>
                <AvailabilitySlot
                  slot={
                    groupedSlots?.slotIndex?.[header.key]?.[time] ||
                    buildEmptySlot(header.date, time)
                  }
                  slotDuration={slotDuration}
                  time={time}
                  clinicList={clinicList}
                  consentExpired={consentExpired}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default AvailabilitySlotsGrid
