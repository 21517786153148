import React, { useMemo } from 'react'
import {
  LegacyFormikDropdown,
  LegacyFormikDropdownOption,
  LegacyFormikDropdownOptionValues,
} from '@babylon/core-ui'
import { useConsultantType } from '../../hooks'
import { UnexpectedError } from '../../Utils'

interface Props {
  className?: string
  consultantTypeList: string[]
  onChange: (value: LegacyFormikDropdownOptionValues | null) => void
  disabled?: boolean
}

export default function ConsultantTypeSelector({
  className,
  consultantTypeList,
  onChange,
  disabled = false,
}: Props) {
  const { consultantTypeDict, loading, error } = useConsultantType()
  const consultantOptions = useMemo(
    () =>
      consultantTypeList.map((value) => ({
        value,
        label: consultantTypeDict?.[value]?.name,
      })),
    [consultantTypeList, consultantTypeDict]
  )

  return (
    <div className={className} data-testid="consultant-type-selector">
      {error && <UnexpectedError visible message={error.message} />}
      <LegacyFormikDropdown
        id="consultant_type"
        name="consultant_type"
        label="Profession"
        isLoading={loading}
        options={consultantOptions}
        onChange={(option: LegacyFormikDropdownOption | null) =>
          onChange(option?.value || null)
        }
        searchable={false}
        disabled={disabled}
      />
    </div>
  )
}
