import React from 'react'
import { SvgProps } from '../../../app'

const StopwatchIcon = ({
  height = 12,
  width = 15,
  className,
  color = 'currentColor',
}: SvgProps) => (
  <svg height={height} width={width} viewBox={`0 0 ${width} ${height}`}>
    <g fill="none" fillRule="evenodd">
      <path
        className={className}
        fill={color}
        d="M8 .667H4V2h4V.667zM5.333 9.333h1.334v-4H5.333v4zm5.354-4.406l.946-.947c-.286-.34-.6-.66-.94-.94l-.946.947A5.974 5.974 0 0 0 6 2.667a6 6 0 1 0 6 6 5.975 5.975 0 0 0-1.313-3.74zM6 13.333a4.663 4.663 0 0 1-4.667-4.666A4.663 4.663 0 0 1 6 4a4.663 4.663 0 0 1 4.667 4.667A4.663 4.663 0 0 1 6 13.333z"
      />
    </g>
  </svg>
)

export default StopwatchIcon
