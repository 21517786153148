import { gql } from '@apollo/client'

export default gql`
  mutation RemoveClinicianFromServiceType(
    $service_type_uuid: ID!
    $clinician_id: ID!
  ) {
    removeClinicianFromServiceType(
      service_type_uuid: $service_type_uuid
      clinician_id: $clinician_id
    )
  }
`
