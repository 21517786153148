import { microservicesClient } from '../../client'
import { SuggestionInterface } from '../../../constants/types'
import { getBaseChatscriptUrl } from '../../util/baseUrls'

const getSymptomSuggestions = ({
  conversationId,
  query,
}: Props): Promise<SuggestionInterface[]> => {
  const baseUrl = getBaseChatscriptUrl()

  return microservicesClient
    .post<Response[]>(`${baseUrl}/conversations/${conversationId}/suggest`, {
      query,
    })
    .then((response) =>
      response.data.map(({ value }) => ({
        ...value,
        // unlike the /say endpoint, the /suggest endpoint does not
        // currently support sending a dual language object, so we need
        // to stub the textInternal for now until it is eventually done
        textInternal: value.text,
      }))
    )
}

type Props = {
  conversationId: string
  query: string
}

type Response = {
  value: SuggestionInterface
}

export default getSymptomSuggestions
