/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type DeleteAppointmentWaitTimeConfigsMutationVariables = Types.Exact<{
  input: Types.DeleteAppointmentWaitTimeConfigsInput
}>

export type DeleteAppointmentWaitTimeConfigsMutation = Pick<
  Types.Mutation,
  'deleteAppointmentWaitTimeConfigs'
>

export const DeleteAppointmentWaitTimeConfigsDocument = gql`
  mutation deleteAppointmentWaitTimeConfigs(
    $input: DeleteAppointmentWaitTimeConfigsInput!
  ) {
    deleteAppointmentWaitTimeConfigs(input: $input)
  }
`
export type DeleteAppointmentWaitTimeConfigsMutationFn = Apollo.MutationFunction<
  DeleteAppointmentWaitTimeConfigsMutation,
  DeleteAppointmentWaitTimeConfigsMutationVariables
>
export function useDeleteAppointmentWaitTimeConfigsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppointmentWaitTimeConfigsMutation,
    DeleteAppointmentWaitTimeConfigsMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteAppointmentWaitTimeConfigsMutation,
    DeleteAppointmentWaitTimeConfigsMutationVariables
  >(DeleteAppointmentWaitTimeConfigsDocument, baseOptions)
}
export type DeleteAppointmentWaitTimeConfigsMutationHookResult = ReturnType<
  typeof useDeleteAppointmentWaitTimeConfigsMutation
>
export type DeleteAppointmentWaitTimeConfigsMutationResult = Apollo.MutationResult<DeleteAppointmentWaitTimeConfigsMutation>
export type DeleteAppointmentWaitTimeConfigsMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppointmentWaitTimeConfigsMutation,
  DeleteAppointmentWaitTimeConfigsMutationVariables
>
