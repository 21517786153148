import { defineMessages } from 'react-intl'

const baseId = 'related_person_card.add_related_person_button'

export default defineMessages({
  add_dependent: {
    id: `${baseId}.add_dependent`,
    defaultMessage: 'Add dependent',
  },
  add_as_guardian: {
    id: `${baseId}.add_as_guardian`,
    defaultMessage: 'Add as guardian',
  },
  add_related_person_error: {
    id: `${baseId}.add_related_person_error`,
    defaultMessage:
      'An error occurred linking the profiles, please try again later',
  },
  add_guardian_success: {
    id: `${baseId}.add_guardian_success`,
    defaultMessage: 'Guardian added to {name}',
  },
  add_dependent_success: {
    id: `${baseId}.add_dependent_success`,
    defaultMessage: 'Dependent added to {name}',
  },
})
