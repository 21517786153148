import { gql } from '@apollo/client'

export const GetUuid = gql`
  query AP_MemberUuid($id: ID!) {
    patient(id: $id) {
      id
      uuid
    }
  }
`

export const CARERS_QUERY = gql`
  query AP_GetCarers($id: String!) {
    carers(id: $id) {
      id
      type
      isPrimary
      profile {
        first_name
        last_name
      }
    }
  }
`

export const RELATIONSHIPS_QUERY = gql`
  query AP_GetRelationship($id: String!) {
    relationships(id: $id) {
      dependant
      type
      profile {
        first_name
        last_name
      }
    }
  }
`
export const UPDATE_PRIMARY_GUARDIAN = gql`
  mutation AP_UpdatePrimaryGuardian(
    $dependantId: String!
    $dependantUuid: String!
    $carerRelationId: String!
  ) {
    updatePrimaryGuardian(
      dependantId: $dependantId
      dependantUuid: $dependantUuid
      carerUuid: $carerRelationId
    )
  }
`
export const REMOVE_CARER = gql`
  mutation AP_RemoveCarer($relationshipId: String!) {
    removeCarer(relationshipId: $relationshipId)
  }
`
