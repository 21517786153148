import React from 'react'
import { useField } from 'formik'
import ErrorsMessages from '@babylon/medkit/src/Forms/ErrorMessages'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { Select, Tooltip, Label } from '@babylon/core-ui'
import { Select as SelectMedkit } from '@babylon/medkit'
import { envFlag } from '@babylon/babylon-env'
import { AppointmentAnalyticsModuleName } from '../../analytics/types'
import ServiceType from '../../types/ServiceType'
import styles from './AppointmentTypeSelect.module.css'

interface AppointmentTypeSelectProps {
  className?: string
  appointmentTypes: ServiceType[]
  label: string
  placeholder?: string
  setSelectedAppointmentType: (apptType: ServiceType | undefined) => void
}

type ServiceTypeOption = {
  value: string
  label: string
}
const getAppointmentTypesOptions = (
  apptTypes: ServiceType[]
): ServiceTypeOption[] =>
  apptTypes.map((apptType) => ({
    value: apptType.uuid,
    label: apptType.name,
  }))

export default function AppointmentTypeSelect({
  className,
  appointmentTypes = [],
  label,
  placeholder,
  setSelectedAppointmentType,
}: AppointmentTypeSelectProps) {
  const [{ value }, meta, { setValue }] = useField('appointment_type')
  const [, , { setValue: setDurationValue }] = useField('duration_minutes')

  const { trackClick } = useTrackClick({
    moduleName: AppointmentAnalyticsModuleName.digitalInvite,
  })

  const globalInviteStyleEnabled = !!envFlag('ENABLE_GLOBAL_INVITE_STYLE')

  const appointmentTypesOptions = getAppointmentTypesOptions(appointmentTypes)

  const selectedOptionValue = appointmentTypesOptions?.find(
    (option) => option.value === value
  )
  const error = meta.touched && meta.error

  if (globalInviteStyleEnabled) {
    return (
      <>
        <div className={styles.title}>
          <Label htmlFor="appointment_type">{label}</Label>
          <Tooltip
            info="You can search for an appointment type in the dropdown list"
            placement="right"
            overlayStyle={{ width: 300 }}
          />
        </div>
        <Select
          options={appointmentTypesOptions}
          value={selectedOptionValue || null}
          defaultValue={null}
          placeholder={placeholder}
          className={className}
          id="appointment_type"
          data-testid="appointment_type"
          onChange={(selectedOption) => {
            const selectedOptionUuid = selectedOption?.value
            setValue(selectedOptionUuid)
            const selectedAppointmentType = appointmentTypes.find(
              ({ uuid }) => uuid === selectedOptionUuid
            )
            setDurationValue(selectedAppointmentType?.default_duration_minutes)
            setSelectedAppointmentType(selectedAppointmentType)

            trackClick({
              elementName: `appointment-type-${selectedAppointmentType?.name}`,
              elementType: TrackingElementType.dropdown,
            })
          }}
        />
        {error ? <ErrorsMessages formErrors={[error]} /> : null}
      </>
    )
  }

  return (
    <SelectMedkit
      label={label}
      options={appointmentTypesOptions}
      placeholder={placeholder}
      name="appointment_type"
      onChange={(event) => {
        const selectedOption = event.currentTarget.value
        setValue(selectedOption)
        const selectedAppointmentType = appointmentTypes.find(
          ({ uuid }) => uuid === selectedOption
        )
        setDurationValue(selectedAppointmentType?.default_duration_minutes)
        setSelectedAppointmentType(selectedAppointmentType)

        trackClick({
          elementName: `appointment-type-${selectedAppointmentType?.name}`,
          elementType: TrackingElementType.dropdown,
        })
      }}
      className={className}
      value={value}
      ErrorMessages={() =>
        error ? <ErrorsMessages formErrors={[error]} /> : null
      }
      error={!!error}
    />
  )
}
