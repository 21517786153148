import { useQuery } from '@apollo/client'
import { useIntl } from '@babylon/intl'
import { useProductConfig } from '@babylon/product-config'

import { ASSESSMENT_STAGE_QUERY, PATIENT_QUERY } from '../queries'

const useAssessmentStage = (memberId: string) => {
  const intl = useIntl()
  const { getProp } = useProductConfig()
  const validatedScoresEnabled = getProp(
    'memberOperations',
    'b360V2Features.validatedScoresEnabled'
  )
  const riskStratificationScoresEnabled = getProp(
    'memberOperations',
    'b360V2Features.riskStratificationScoresEnabled'
  )

  const {
    data: patientData,
    error: patientError,
    loading: patientLoading,
  } = useQuery(PATIENT_QUERY, {
    variables: { id: memberId },
  })
  const memberUuid = patientData?.patient.uuid

  const {
    data: assessmentStageData,
    error: assessmentStageError,
    loading: assessmentStageLoading,
    refetch,
  } = useQuery(ASSESSMENT_STAGE_QUERY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !memberUuid,
    variables: {
      locale: intl.locale,
      memberId: memberUuid,
      fetchScores: validatedScoresEnabled,
      fetchRiskScores: riskStratificationScoresEnabled,
    },
  })

  const error = patientError || assessmentStageError
  const loading = patientLoading || assessmentStageLoading

  return {
    data: {
      assessmentStage: assessmentStageData?.healthCheckAssessmentStage,
      member: patientData?.patient,
    },
    error,
    loading,
    refetch,
  }
}

export default useAssessmentStage
