import React, { ReactNode, useRef, useState, MutableRefObject } from 'react'
import { useQuery, ApolloError } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { Button } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'
import ErrorBoundary from '../ErrorBoundary'
import PopupDialog, { PopupDialogProps } from '../PopupDialog'
import EntriesTimeline from '../EntriesTimeline'
import LinkButton from '../LinkButton'

import { PATIENT_QUERY, CHAT_QUERY } from './queries'
import useQueryConversations from './useQueryConversations'
import Messages from './Messages'
import messages from './ConversationTimeline.messages'
import styles from './ConversationTimeline.module.scss'
import LoadingTimeline from '../Timeline/LoadingTimeline'
import { Entry } from '../types'

export interface EntriesHistoryProps
  extends Omit<PopupDialogProps, 'children'> {
  heading: string
  patientId: string
  printable?: boolean
  transcriptHeadingComponent?: (entry: Entry) => ReactNode
  loading?: boolean
  hasMore?: boolean
  loadMore: () => void
  entries: Entry[]
  error?: ApolloError
  onEntryClick?: () => void
}
export interface ConversationTimelineProps
  extends Pick<EntriesHistoryProps, 'visible' | 'patientId'> {
  transcriptHeadingComponent?: (entry: Entry) => ReactNode
  conversationTypes: string[]
}

const ConversationTimeline = ({
  patientId,
  transcriptHeadingComponent,
  conversationTypes,
}: ConversationTimelineProps) => {
  const { data } = useQuery(PATIENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { id: patientId },
  })

  const patient = data?.patient
  const patientUuid = data?.patient?.uuid
  const [selectedEntry, selectEntry] = useState<Entry | null>(null)
  const ref = useRef() as MutableRefObject<HTMLDivElement>
  const { getBlob } = useProductConfig()
  const { chatTranscriptPrintEnabled } = getBlob('memberOperations')

  const [activeConversationType, setActiveConversationType] = useState(
    conversationTypes[0] || ''
  )

  const ConversationFilter = () => (
    <div className={styles.ConversationFilter}>
      {conversationTypes.map((conversationType) => (
        <span
          className={
            activeConversationType === conversationType
              ? styles.ConversationFilter_item_active
              : styles.ConversationFilter_item
          }
        >
          <LinkButton
            className={
              activeConversationType === conversationType
                ? styles.ConversationFilter_link_active
                : styles.ConversationFilter_link
            }
            onClick={() => setActiveConversationType(conversationType)}
          >
            <FormattedMessage
              {...messages[`conversation_type_${conversationType}`]}
            />
          </LinkButton>
        </span>
      ))}
    </div>
  )

  const {
    data: conversations,
    loading,
    fetchMore,
    more: moreConversations,
  } = useQueryConversations(CHAT_QUERY, patientUuid, activeConversationType, {
    skip: !patientUuid,
  })

  return (
    <div>
      <ConversationFilter />
      {loading && <LoadingTimeline />}
      {!selectedEntry && (
        <ErrorBoundary>
          <EntriesTimeline
            onEntryClick={(entry: Entry) => {
              selectEntry(entry)
            }}
            entries={conversations || []}
            displayEntriesInTimeline
          />
          {moreConversations && (
            <div className={styles.conversation__viewMore}>
              <LinkButton
                disabled={loading}
                loading={loading}
                testId="conversation-view-more"
                onClick={fetchMore}
              >
                <FormattedMessage {...messages.conversation_view_more} />
              </LinkButton>
            </div>
          )}
        </ErrorBoundary>
      )}
      <PopupDialog
        ref={ref}
        visible={!!selectedEntry}
        toggleVisible={() => selectEntry(null)}
        appear={!!selectedEntry}
      >
        <ErrorBoundary>
          {selectedEntry && (
            <>
              <Messages
                heading={
                  transcriptHeadingComponent &&
                  transcriptHeadingComponent(selectedEntry)
                }
                scrollParentRef={ref.current}
                handleBack={() => selectEntry(null)}
                conversationId={selectedEntry.id}
                userId={patient.uuid}
                patient={patient}
              />
              {chatTranscriptPrintEnabled && (
                <div className={styles.ConversationActions}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/admin/patients/${patientId}/conversations/${selectedEntry.id}?nonav=true`}
                  >
                    <Button className={styles.ConversationActions__printButton}>
                      <FormattedMessage {...messages.printConversation} />
                    </Button>
                  </a>
                </div>
              )}
            </>
          )}
        </ErrorBoundary>
      </PopupDialog>
    </div>
  )
}

export default ConversationTimeline
