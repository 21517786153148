import { useMemo } from 'react'
import * as Yup from 'yup'
import { useFormatMessage } from '@babylon/intl'

import messages from '../MembersSearchView.messages'

export default function useMemberSearchValidation() {
  const fm = useFormatMessage()

  const schema = useMemo(
    () =>
      Yup.object()
        .shape({
          coreRubyPatientId: Yup.string()
            .matches(/^\d+$/, fm(messages.invalid_patient_id))
            .nullable(),
          dateOfBirth: Yup.date().nullable(),
          email: Yup.string().email(fm(messages.invalid_email)).nullable(),
          firstName: Yup.string().nullable(),
          lastName: Yup.string().nullable(),
          nationalId: Yup.string().nullable(),
          phoneNumber: Yup.string().nullable(),
          postCode: Yup.string().nullable(),
        })
        .test('at-least-one', fm(messages.missing_filter), (value) =>
          Object.values(value).some((fieldValue) => !!fieldValue)
        ),
    [fm]
  )

  return schema
}
