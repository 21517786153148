import React from 'react'

import { Dialog } from '@babylon/babylon-forms/widgets'

import { ModalOverlay } from '@babylon/member-operations'
import styles from './styles.module.css'
import CloseMenuIcon from '../CloseMenuIcon'

type Props = {
  visible: boolean
  onRequestClose: () => void
  Header?: React.ReactNode
  Footer?: React.ReactNode
  children?: React.ReactNode
  showCloseIcon?: boolean
}

const Modal = ({
  Header,
  Footer,
  visible,
  children,
  onRequestClose,
  showCloseIcon = true,
}: Props) => (
  <ModalOverlay
    visible={visible}
    onRequestClose={onRequestClose}
    className={styles.modal}
  >
    {visible && (
      <Dialog className={styles.dialog}>
        {Header && (
          <div className={styles.dialogHeader}>
            {Header}
            {showCloseIcon && <CloseMenuIcon onClick={onRequestClose} />}
          </div>
        )}
        {children}
        {Footer && <div className={styles.dialogFooter}>{Footer}</div>}
      </Dialog>
    )}
  </ModalOverlay>
)

export default Modal
