import { gql } from '@apollo/client'

export default gql`
  mutation AddClinicsToServiceType(
    $service_type_uuid: ID!
    $clinic_ids: [ID!]!
  ) {
    addClinicsToServiceType(
      service_type_uuid: $service_type_uuid
      clinic_ids: $clinic_ids
    )
  }
`
