import React from 'react'

import { useFormatMessage } from '@babylon/intl'
import { useProductConfig } from '@babylon/product-config'
import { Text, Box } from '@babylon/medkit'

import messages from './messages'

import styles from './Scores.module.scss'
import { FlowScoresInterface } from '../../types'

const Scores = ({ scores }: Props) => {
  const fm = useFormatMessage()
  const { getProp } = useProductConfig()
  const validatedScoresEnabled = getProp(
    'memberOperations',
    'b360V2Features.validatedScoresEnabled'
  )

  if (!validatedScoresEnabled || !scores || scores.length < 1) {
    return null
  }

  return (
    <div data-testid="scores-container" className={styles.container}>
      <Text className={styles.title} variant="h5">
        {fm(messages.title)}
      </Text>

      <Box className={styles.scores} padding="medium">
        {scores.map((score) => (
          <dl key={score.id} className={styles.list}>
            <Text className={styles.scoreName} tag="dt" variant="body">
              {score.name}
            </Text>

            {score.result && (
              <>
                <Text
                  className={styles.scoreInformation}
                  tag="dd"
                  variant="body"
                >
                  {fm(messages.score, { score: score.result.score })}
                </Text>

                <Text
                  className={styles.scoreInformation}
                  tag="dd"
                  variant="body"
                >
                  {fm(messages.whatThisMeans, {
                    severityText: score.result.severityText,
                  })}
                </Text>
              </>
            )}

            {!score.result && (
              <Text className={styles.scoreInformation} tag="dd" variant="body">
                {fm(messages.scoreIsNotAvailable)}
              </Text>
            )}
          </dl>
        ))}
      </Box>
    </div>
  )
}

interface Props {
  scores: FlowScoresInterface[] | null
}

export default Scores
