import { useQuery } from '@apollo/client'
import {
  NoticeDecision,
  MemberDecisionForNoticeInput,
} from '@babylon/graphql-middleware-types'
import { envFlag } from '@babylon/babylon-env'
import GetConsentDecisionForPatient from './GetConsentDecisionForPatient'

type ConsentDecisionForPatient = {
  informedConsent: NoticeDecision
  medicationHistory: NoticeDecision
}

export default function useConsentDecisionForPatient({
  patientUUID,
}: Pick<MemberDecisionForNoticeInput, 'patientUUID'>) {
  const consentTagsEnabled: boolean = !!envFlag(
    'ENABLE_MEMBER_LEGAL_CONSENT_PREVIEW'
  )
  const { data, loading } = useQuery<ConsentDecisionForPatient>(
    GetConsentDecisionForPatient,
    {
      variables: {
        patientUUID,
      },
      skip: !(patientUUID && consentTagsEnabled),
    }
  )
  const { informedConsent, medicationHistory } = data || {}

  return {
    expired: informedConsent?.expired || medicationHistory?.expired,
    loading,
  }
}
