import { envFlag } from '@babylon/babylon-env'

export const PATIENT_HISTORY_PAGE_SIZE = 10
export const commonPatientFields = `
  id
  uuid
  first_name
  last_name
  date_of_birth
  email
  address_first_line
  address_second_line
  address_third_line
  address_post_code
  full_phone_number
  gender
  emergency_contact_details {
    id
    phone_country_code
    first_name
    last_name
    phone_number
  }
  birth_details {
    country
    town
  }
  date_of_birth
  region {
    name
  }
  previous_names
  public_healthcare_details {
    id
    armed_forces_enlisting_date
    arrival_date
    disability
  }
  gp_details {
    gp_name
    gp_address_first_line
    gp_address_second_line
    gp_address_third_line
    gp_address_post_code
    gp_surgery_name
    gp_surgery_phone_number
  }
  healthcare_identifier {
    public_identifier
    private_identifier
    unconfirmed_public_identifier
  }
  previous_practice_name
  ${envFlag('ENABLE_APPOINTMENT_TIMEZONE') ? 'timezone_id' : ''}
`

export const fullPatientFields = `
  ${commonPatientFields}
  account_owner_id
  account_owner {
    first_name
    last_name
  }
  accounts_count
  address_state_code
  exact_match
  minor
  family_accounts {
    id
  }
  family_accounts_owner
  demographics {
    lookup_retry_attempts_left
    status
  }
  previous_addresses {
    first_line
    second_line
    third_line
    town
    county
    post_code
  }
  profile_locked
  profile_locks {
    name
    locked_at
  }
  identity_check {
    id
    status
    check_id
  }
  queued_for_activation
  public_healthcare_admission {
    id
    consumer_network_id
    pds_trace_status
  }
  region {
    name
    iso_code
  }
`

export const commonConsultationFields = `
  id
  participants {
    type
    userId
    participant {
      full_name
    }
  }
  appointment {
    id
    state
    medium
    ${envFlag('ENABLE_APPOINTMENT_TIMEZONE') ? 'timezone_id' : ''}
    clinic {
      id
      name
      address
      full_address {
        address_line_1
        address_line_2
        address_line_3
        postal_code
        country_code
      }
      email
      phone_number
      arrival_instructions
      timezone_id
    }
    slot_size
    checkIn {
      check_in_data {
        checked_in_at
        check_in_status
      }
    }
  }
  consultant {
    id
    name
    gender
    profession_name
  }
  reasons {
    reason
  }
  bookingType
  consumerNetworkName
  patientNote
  scheduledTime
  status
  cancelledAt
`

export const commonRepeatTemplateFields = `
  id
  daysToReorder
  drugName
  lastAvailabilityDateTime
  lastUserChange
  latestIssue {
    id
    durationDays
  }
  reviewDate
  repeatState: state
  userState
  latestPharmacy {
    id
    name
    phoneNumber
    address {
      addressLine
      city
      postalCode
      country
    }
  }
`
