import React from 'react'
import classnames from 'classnames'
import styles from './Transaction.module.scss'

interface StatusProps {
  message: string
  success: boolean
}

export default ({ message, success }: StatusProps) => {
  const successStyle = success
    ? styles.Transaction__Positive
    : styles.Transaction__Negative
  return (
    <div className={classnames(styles.Transaction__Status, successStyle)}>
      {message}
    </div>
  )
}
