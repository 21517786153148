import { AvailabilitySlot } from '@babylon/graphql-middleware-types'

export interface AppointmentManagementPageParameters {
  practitionerId: string
}

export interface AppointmentManagementPageQueryParameters {
  date?: string
  timezone?: string
}

export interface GetValidQueryParameterOptions {
  queryStringParameters: AppointmentManagementPageQueryParameters
  defaultDate: Date
  userTimezone?: string | null
}

export interface Appointment {
  id: string
  babylonId: string | null
  startTime: string | null
  endTime: string | null
  durationInMinutes: number | null
  medium: AppointmentMedium | null
  isTranslatorRequired: boolean
  patientConsumerNetworkId: string | null
  status: string | null
  appointmentReasons: string[]
  patientId: string | null
  patientBabylonId: string | null
  patientName: string | null
  isPatientMinor: boolean
  bookingAgent: string | null
}

export type AppointmentMedium = 'video' | 'voice' | 'physical' | 'text'

export enum AppointmentMediumOption {
  video = 'video',
  voice = 'voice',
  physical = 'physical',
  text = 'text',
}

export enum AppointmentSortOption {
  Date = 'DATE',
  None = 'NONE',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum AdministrativeGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
}

export enum AppointmentStatus {
  Arrived = 'ARRIVED',
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  CheckedIn = 'CHECKED_IN',
  EnteredInError = 'ENTERED_IN_ERROR',
  Fulfilled = 'FULFILLED',
  Noshow = 'NOSHOW',
  Pending = 'PENDING',
  Proposed = 'PROPOSED',
  Waitlist = 'WAITLIST',
}

export const AVAILABLE_SLOTS_FILTER_VALUE = 'AVAILABLE'

export type Slot = Pick<
  AvailabilitySlot,
  | 'id'
  | 'admin'
  | 'slot_time'
  | 'slot_size'
  | 'physical_bookable'
  | 'digital_bookable'
>
