import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useMutation } from '@apollo/client'
import { Close } from '@babylon/icons'
import { Modal, Grid, Cell } from '@babylon/core-ui'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { useSnackBar, MemberOpsModuleName } from '../../../../../../..'
import messages from './AppointmentCredits.messages'
import { REMOVE_APPOINTMENT_CREDIT_MUTATION } from './queries'
import styles from './RemoveAppointmentCreditModal.module.scss'

const RemoveAppointmentCreditModal = ({
  patientId,
  appointmentCreditId,
  isOpened,
  onClose,
  onRemovalSuccess,
}: {
  patientId: string
  appointmentCreditId: string | null
  isOpened: boolean
  onClose: () => void
  onRemovalSuccess: () => void
}) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()
  const { setSnackbarMessage } = useSnackBar()
  const [removeAppointmentCredit] = useMutation(
    REMOVE_APPOINTMENT_CREDIT_MUTATION
  )

  async function handleCodeRemoval() {
    try {
      await removeAppointmentCredit({
        variables: { patientId, appointmentCreditId },
      })
      trackClick({
        elementName: 'remove-appointment-credit-button',
        elementType: TrackingElementType.button,
      })
      setSnackbarMessage(fm(messages.remove_credit_success), null, 'success')
      onRemovalSuccess()
      onClose()
    } catch (e) {
      setSnackbarMessage(fm(messages.remove_credit_error), null, 'error')
    }
  }

  return (
    <Modal
      label={fm(messages.remove_credit_modal_heading)}
      open={isOpened}
      onClose={onClose}
      includeClose={false}
    >
      <div className={styles.ModalContent}>
        <header>
          <Grid columns={6}>
            <Cell width={4}>
              <h2>{fm(messages.remove_credit_modal_heading)}</h2>
            </Cell>
            <Cell center left={6}>
              <button
                type="button"
                onClick={onClose}
                className={styles.ModalContent__closeButton}
              >
                <Close />
              </button>
            </Cell>
          </Grid>
        </header>
        <p data-testid="modal-body">{fm(messages.remove_credit_modal_body)}</p>
        <footer>
          <ButtonSimple
            className={styles.ModalContent__Action}
            data-testid="confirm-removal-btn"
            variant={ButtonVariant.secondary}
            onClick={() => {
              handleCodeRemoval()
              trackClick({
                elementName: 'remove-appointment-submit-credit-btn',
                elementType: TrackingElementType.button,
              })
            }}
          >
            {fm(messages.remove_credit_modal_action_confirm)}
          </ButtonSimple>
          <ButtonSimple
            className={styles.ModalContent__Action}
            data-testid="reject-removal-btn"
            variant={ButtonVariant.primary}
            onClick={() => onClose()}
          >
            {fm(messages.remove_credit_modal_action_cancel)}
          </ButtonSimple>
        </footer>
      </div>
    </Modal>
  )
}

export default RemoveAppointmentCreditModal
