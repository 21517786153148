import React from 'react'
import { useQueryParams, useIsOnGPAtHandNetwork } from '@babylon/appointments'
import { useProductConfig } from '@babylon/product-config'
import Timeline from '../../../../Timeline'
import TimelineTabs from './TimelineTabs'
import { APPOINTMENTS_WORKFLOWS_TAB, TEST_RESULTS_TAB } from './constants'
import { TabId } from './types'
import TestResultsTimeline from '../TestResultsTimeline'

interface TimelineManagementProps {
  patientId: string
  patientUuid: string
}

interface QueryParams {
  tabview: TabId
}

const TimelineManagement = ({
  patientId,
  patientUuid,
}: TimelineManagementProps) => {
  const { getProp } = useProductConfig()

  const memberDetailsTimelineTabSplitEnabled = getProp(
    'memberOperations',
    'memberDetailsTimelineTabSplitEnabled'
  )

  const testResultsMemberTasksEnabled = getProp(
    'memberOperations',
    'testResultsMemberTasksEnabled'
  )

  const { isOnGPAtHandNetwork } = useIsOnGPAtHandNetwork(patientId, {
    skip: !testResultsMemberTasksEnabled,
  })

  const [params, setParams] = useQueryParams<QueryParams>({
    defaultProps: {
      tabview: APPOINTMENTS_WORKFLOWS_TAB,
    },
  })

  const allowedTabs: TabId[] = [APPOINTMENTS_WORKFLOWS_TAB]

  if (isOnGPAtHandNetwork && testResultsMemberTasksEnabled) {
    allowedTabs.push(TEST_RESULTS_TAB)
  }

  const { tabview } = params

  const selectedTab = allowedTabs.includes(tabview)
    ? tabview
    : APPOINTMENTS_WORKFLOWS_TAB

  const handleChangeTab = (nextSelectedTab: TabId): void => {
    setParams({
      ...params,
      tabview: nextSelectedTab,
    })
  }

  if (!memberDetailsTimelineTabSplitEnabled) {
    return <Timeline patientId={patientId} patientUuid={patientUuid} />
  }

  return (
    <>
      <TimelineTabs
        onChangeTab={handleChangeTab}
        allowedTabs={allowedTabs}
        selectedTab={selectedTab}
      />
      {selectedTab === APPOINTMENTS_WORKFLOWS_TAB && (
        <Timeline patientId={patientId} patientUuid={patientUuid} />
      )}
      {selectedTab === TEST_RESULTS_TAB && (
        <TestResultsTimeline memberUuid={patientUuid} />
      )}
    </>
  )
}

export default TimelineManagement
