import React, { useEffect, useCallback } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'
import {
  Button,
  Pagination,
  Spinner,
  Table,
  TableRow,
  TableCell,
  Text,
} from '@babylon/core-ui'
import { HumanitySyncStatusEnum } from '@babylon/graphql-middleware-types'
import { envFlag } from '@babylon/babylon-env'
import SyncCanceller from '../SyncCanceller'
import { SyncFetchState } from '../AvailabilitySyncPage'
import { useGetHumanitySyncsQuery } from './GetHumanitySyncs.hooks'
import TimezoneDate from '../Utils/TimezoneDate'
import {
  validPageNumber,
  handlePrevClick,
  handleNextClick,
  QueryString,
  PageParams,
} from '../Utils/PaginationUtils'
import styles from './AvailabilityListing.module.css'

const PER_PAGE = 10

interface AvailabilityListingProps {
  update?: SyncFetchState
}

const AvailabilityListing = ({ update }: AvailabilityListingProps) => {
  const history = useHistory()
  const location = useLocation()
  const querystring: QueryString = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 1,
  })

  const page = validPageNumber(querystring?.page)
    ? parseInt(querystring?.page, 10)
    : 1
  const pageParams: PageParams = {
    limit: PER_PAGE,
    offset: page * PER_PAGE - PER_PAGE,
  }
  const { loading, error, data, refetch } = useGetHumanitySyncsQuery({
    variables: {
      ...pageParams,
    },
    fetchPolicy: 'network-only',
  })
  const total = data?.humanitySyncs.total || 0
  const totalPages = data ? Math.ceil(total / PER_PAGE) : 1

  const handlePageClick = (selectedPage) => {
    if (!validPageNumber(selectedPage)) {
      return
    }

    history.push({
      search: qs.stringify({ page: selectedPage }),
    })
  }

  const handleRefetch = useCallback(() => {
    refetch().catch((err) => console.error(err))
  }, [refetch])

  useEffect(() => {
    if (update === SyncFetchState.Pending) {
      return
    }

    handleRefetch()
  }, [update, handleRefetch])

  const PaginationWrapper = ({
    currentPage = 1,
    maxPages = 1,
  }: {
    currentPage: number
    maxPages: number
  }) => (
    <Pagination
      currentPage={currentPage}
      totalPages={maxPages}
      onPrevClick={() => handlePrevClick(currentPage, history)}
      onNextClick={() => handleNextClick(currentPage, totalPages, history)}
      onPageClick={handlePageClick}
    />
  )

  const autoSyncEnabled = envFlag('ENABLE_AUTOSYNC', false)
  const headers = [
    'Date requested',
    'Sync ID',
    'User',
    'Clinicians affected',
    'Status',
    ...(autoSyncEnabled ? ['Last synced at'] : []),
    'Errors',
  ]
  const headerComponents: JSX.Element[] = headers.map((header) => (
    <Text key={header} bold>
      {header}
    </Text>
  ))

  return (
    <div
      id="availability-listing"
      className={styles.AvailabilityListingContainer}
    >
      <PaginationWrapper currentPage={page} maxPages={totalPages} />

      <Table fullWidth striped headers={[...headerComponents, <></>, <></>]}>
        {loading && (
          <TableRow>
            <td colSpan={9} className={styles.SingleCol}>
              <Spinner size="large" color="gray" testid="spinner" centered />
            </td>
          </TableRow>
        )}

        {error && (
          <TableRow>
            <td colSpan={9} className={styles.SingleCol}>
              There was an error fetching the list, please{' '}
              <Button
                loading={loading}
                type="button"
                inline
                onClick={() => handleRefetch()}
              >
                try reloading
              </Button>
            </td>
          </TableRow>
        )}

        {!loading && data?.humanitySyncs.syncs.length === 0 && (
          <TableRow>
            <td colSpan={9} className={styles.SingleCol}>
              <Text size="large" bold>
                No records to show
              </Text>
            </td>
          </TableRow>
        )}

        {!loading &&
          data?.humanitySyncs &&
          data.humanitySyncs.syncs.length > 0 &&
          data.humanitySyncs.syncs.map((sync) => {
            if (!sync) {
              return <></>
            }

            return (
              <TableRow key={`row_${sync.id}`}>
                <TableCell>
                  <Text data-testid="sync-created-at">
                    <TimezoneDate
                      date={sync.created_at}
                      format="MMM do, yyyy kk:mm:ss"
                    />
                  </Text>
                </TableCell>
                <TableCell>
                  <Text data-testid="sync-id">{sync.id}</Text>
                </TableCell>
                <TableCell>
                  {!sync.user_name && (
                    <Text
                      data-testid="sync-user-name"
                      className={styles.ColumnMissingDataText}
                    >
                      missing
                    </Text>
                  )}
                  {sync.user_name && (
                    <Text data-testid="sync-user-name">{sync.user_name}</Text>
                  )}
                </TableCell>
                <TableCell>
                  {sync.clinician_count === null && (
                    <Text
                      data-testid="sync-clinician-count"
                      className={styles.ColumnMissingDataText}
                    >
                      n/a
                    </Text>
                  )}
                  {sync.clinician_count !== null && (
                    <Text data-testid="sync-clinician-count">
                      {sync.clinician_count}
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  <Text data-testid="sync-status">{sync.status}</Text>
                </TableCell>
                {autoSyncEnabled && (
                  <>
                    <TableCell>
                      {sync.last_synced_at === null ||
                      sync.last_synced_at === undefined ? (
                        <Text data-testid="sync-last-synced-at">n/a</Text>
                      ) : (
                        <Text data-testid="sync-last-synced-at">
                          <TimezoneDate
                            date={sync.last_synced_at}
                            format="MMM do, yyyy kk:mm:ss"
                          />
                        </Text>
                      )}
                    </TableCell>
                  </>
                )}
                <TableCell>
                  {!sync.errors.length && (
                    <Text
                      data-testid="sync-errors"
                      className={styles.ColumnMissingDataText}
                    >
                      none
                    </Text>
                  )}
                  {sync.errors.length > 0 && (
                    <Text data-testid="sync-errors">
                      {sync.errors.join(', ')}
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  <Link to={`/admin/availability_sync/${sync.id}`}>view</Link>
                </TableCell>
                <TableCell>
                  {[
                    HumanitySyncStatusEnum.Pending,
                    HumanitySyncStatusEnum.InProgress,
                  ].includes(sync.status) && (
                    <SyncCanceller
                      syncStatus={sync.status}
                      syncId={sync.id}
                      disabled={
                        sync.status !== HumanitySyncStatusEnum.Pending &&
                        sync.status !== HumanitySyncStatusEnum.InProgress
                      }
                      callback={() => handleRefetch()}
                    />
                  )}
                </TableCell>
              </TableRow>
            )
          })}
      </Table>
    </div>
  )
}

export default AvailabilityListing
