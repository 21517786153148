/* eslint-disable react/prop-types, react/no-multi-comp, max-classes-per-file */
import React from 'react'
import cx from 'classnames'

const formContext = React.createContext()

const result = (value, ...args) =>
  typeof value === 'function' ? value(...args) : value

const nextIndex = ((index) => () => index++)(0) // eslint-disable-line no-plusplus

const preventEvent = (event) => {
  event.stopPropagation()
  event.preventDefault()
}

const submitHandler = (type, defaultHandler, ctx) => {
  if (type === 'submit' || type === 'reset' || type === 'button') {
    return (event) => {
      preventEvent(event)

      if (defaultHandler) {
        defaultHandler(event, ctx)
      }

      if (ctx) {
        if (type === 'submit') {
          ctx.submit && ctx.submit()
        } else if (type === 'reset') {
          ctx.reset && ctx.reset()
        }
      }
    }
  }

  return defaultHandler
}

const withFormContext = (Component) => (props) => (
  <formContext.Consumer>
    {(ctx) => <Component {...props} data-context={ctx} />}
  </formContext.Consumer>
)

const withLabel = (Component) =>
  class extends React.Component {
    constructor(props) {
      super(props)
      this.id = props.id || `${props.name || 'widget'}-${nextIndex()}`
    }

    render() {
      const { label, ...props } = this.props
      const labelElement = label ? (
        <label htmlFor={this.id}>{label}</label>
      ) : null

      return <Component {...props} id={this.id} label={labelElement} />
    }
  }

const getErrorMessage = (errorMessages, name, value) => {
  if (errorMessages) {
    let error = errorMessages[name]

    if (typeof error !== 'object') {
      error = {
        message: error,
        value,
      }
      errorMessages[name] = error
    }

    if (error.value === value && error.message !== true) {
      return error.message
    }
  }

  return undefined
}

const runValidation = (validate, value) => {
  if (Array.isArray(validate)) {
    for (let i = 0; i < validate.length; i++) {
      const message = validate[i](value)

      if (message !== true) {
        return message
      }
    }

    return true
  }

  return validate(value)
}

const withValidation = (Component) => ({ validate, ...props }) => (
  <formContext.Consumer>
    {(ctx) => {
      if (ctx && validate) {
        const message =
          getErrorMessage(ctx.errorMessages, props.name, props.value) ||
          runValidation(validate, props.value)

        if (message !== true) {
          ctx.isValid = false

          if (ctx.showValidationErrors) {
            props.validationMessage = message
          }
        }
      }

      return <Component {...props} />
    }}
  </formContext.Consumer>
)

const withWrapper = (Component) => ({
  label,
  wrapperClassName,
  validationMessage,
  ...props
}) => (
  <div
    className={cx('widget-wrapper', wrapperClassName, {
      'widget-alert': validationMessage,
    })}
    style={props.style}
  >
    {label}
    <Component {...props} />
    <span className="field-alert">{validationMessage}</span>
  </div>
)

const withGroup = (Component) => ({
  groupClassName,
  groupPrepend,
  groupAppend,
  ...props
}) =>
  groupPrepend || groupAppend ? (
    <div
      className={cx('widget-group', groupClassName, {
        disabled: props.disabled,
      })}
    >
      {groupPrepend}
      <Component {...props} />
      {groupAppend}
    </div>
  ) : (
    <Component {...props} />
  )

const withDefaultProps = (defaultProps) => (Component) => (props) => (
  <Component {...defaultProps} {...props} />
)

const withValue = (Component) => ({ value, ...props }) => (
  <formContext.Consumer>
    {(ctx) => {
      if (ctx && ctx.store) {
        const { state } = ctx.store
        const contextValue =
          typeof value !== 'undefined'
            ? result(value, state)
            : state[props.name]

        if (
          process.env.NODE_ENV !== 'production' &&
          contextValue === undefined
        ) {
          console.warn(`No value provided for '${props.name}' field.`)
        }

        return <Component {...props} value={contextValue} />
      }

      return <Component {...props} value={value} />
    }}
  </formContext.Consumer>
)

const getTargetValue = (target) =>
  target.type === 'checkbox' ? target.checked : target.value

const getEventValue = (event) =>
  event && event.target ? getTargetValue(event.target) : event

const withOnChange = (Component) => ({ onChange, ...props }) => (
  <formContext.Consumer>
    {(ctx) => {
      let handleChange

      if (onChange) {
        handleChange = (event, value) => {
          onChange(event, value || getEventValue(event))
        }
      } else if (ctx && ctx.store) {
        handleChange = (event, value = getEventValue(event)) => {
          onChange && onChange(event, value)
          ctx.store.setState({ [props.name]: value })
        }
      }

      return <Component {...props} onChange={handleChange} />
    }}
  </formContext.Consumer>
)

const keyPrevState = Symbol()

const withStore = (
  defaultState,
  { name = 'store', shouldStateUpdate } = {}
) => (Component) =>
  class extends React.Component {
    constructor(props) {
      super(props)
      const state = result(defaultState, props)
      this.state = { ...state, [keyPrevState]: state }
    }

    static getDerivedStateFromProps(props, state) {
      if (shouldStateUpdate) {
        if (shouldStateUpdate(props, state[keyPrevState])) {
          const newState = result(defaultState, props)

          return { ...newState, [keyPrevState]: newState }
        }

        return null
      }

      const newState = result(defaultState, props)

      if (newState !== state[keyPrevState]) {
        return { ...newState, [keyPrevState]: newState }
      }

      return null
    }

    render() {
      const props = {
        ...this.props,
        [name]: this,
      }

      return <Component {...props} />
    }
  }

const compose = (...fns) => (Component) => {
  const ComposedComponent = fns.reduceRight(
    (Component, fn) => fn(Component),
    Component
  )
  ComposedComponent.propTypes = Component.propTypes

  return ComposedComponent
}

export {
  formContext,
  result,
  nextIndex,
  preventEvent,
  submitHandler,
  withFormContext,
  withLabel,
  withValidation,
  withWrapper,
  withGroup,
  withDefaultProps,
  withValue,
  getTargetValue,
  getEventValue,
  withOnChange,
  withStore,
  compose,
}
