import { defineMessages } from 'react-intl'

const baseId = 'service_type_info'

export default defineMessages({
  durationInfo: {
    id: `${baseId}.duration_info`,
    defaultMessage:
      'The default duration has been set by the head office & clinicians',
  },
  instructionsInfo: {
    id: `${baseId}.instructions_info`,
    defaultMessage: 'For example, please bring your inhaler(s) with you.',
  },
  instructionsPlaceholder: {
    id: `${baseId}.instructions_placeholder`,
    defaultMessage: 'Enter instructions for the member',
  },
  edit: {
    id: `${baseId}.edit`,
    defaultMessage: 'Edit',
  },
  save: {
    id: `${baseId}.save`,
    defaultMessage: 'Save Changes',
  },
  create: {
    id: `${baseId}.create`,
    defaultMessage: 'Create Service Type',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  instruction: {
    id: `${baseId}.instruction`,
    defaultMessage: 'Member facing instructions',
  },
  name: {
    id: `${baseId}.name`,
    defaultMessage: 'Service Type Name',
  },
  memberDescription: {
    id: `${baseId}.member_description`,
    defaultMessage: 'Member facing description',
  },
  memberFacingName: {
    id: `${baseId}.member_facing_name`,
    defaultMessage: 'Member facing name',
  },
  memberFacingNameInfo: {
    id: `${baseId}.member_facing_name_info`,
    defaultMessage:
      'The service type name shown to members when booking an appointment',
  },
  memberDescriptionInfo: {
    id: `${baseId}.member_description_info`,
    defaultMessage:
      'The description of the service type shown to members when booking an appointment',
  },
  memberDescriptionPlaceholder: {
    id: `${baseId}.member_description_placeholder`,
    defaultMessage:
      'Enter the service type description that will be shown to the member',
  },
  nameError: {
    id: `${baseId}.service_type_name_error`,
    defaultMessage: 'Please type the name of the Service Type',
  },
  durationError: {
    id: `${baseId}.service_type_duration_error`,
    defaultMessage: 'Please specify a default duration for the service type',
  },
  groupingError: {
    id: `${baseId}.service_type_grouping_error`,
    defaultMessage: 'Please choose the group for the service type',
  },
  allowedMediumError: {
    id: `${baseId}.service_type_allowed_medium_error`,
    defaultMessage: 'Please select the allowed mediums for this service type',
  },
  preferredMediumError: {
    id: `${baseId}.service_type_preferred_medium_error`,
    defaultMessage: 'Please choose the default medium for this service type',
  },
  memberDescriptionError: {
    id: `${baseId}.service_type_member_description_error`,
    defaultMessage: 'Please type a description for this service type',
  },
  memberFacingNameError: {
    id: `${baseId}.service_type_member_facing_name_error`,
    defaultMessage: 'Please enter the member facing name for this service type',
  },
  serviceTypeCategoriesError: {
    id: `${baseId}.service_type_categories_error`,
    defaultMessage: 'Please select at least one category',
  },
  serviceTypeImmutableAlertTitle: {
    id: `${baseId}.service_type_immutable_alert_title`,
    defaultMessage: 'Information on managing service types',
  },
  serviceTypeImmutableAlertMessage: {
    id: `${baseId}.service_type_immutable_alert_message`,
    defaultMessage:
      'The service type name, duration, US EHR appointment type, medium(s), and default medium can not be modified after a service type is created',
  },
  serviceTypeUsEhrAppointmentType: {
    id: `${baseId}.service_type_us_ehr_appointment_type`,
    defaultMessage: 'US EHR appointment type',
  },
  serviceTypeUsEhrAppointmentTypeValidationError: {
    id: `${baseId}.service_type_us_ehr_appointment_type_validation_error`,
    defaultMessage:
      'Please select a US EHR appointment type for this service type',
  },
  serviceTypeUsEhrAppointmentTypeTooltipText: {
    id: `${baseId}.service_type_us_ehr_appointment_type_tooltip_text`,
    defaultMessage:
      "The US EHR appointment type name that should be mapped to this service type. For example 'GENERAL MEDICAL', 'PRIMARY CARE', etc.",
  },
  serviceTypeUsEhrAppointmentTypeNotSet: {
    id: `${baseId}.service_type_us_ehr_appointment_type_not_set`,
    defaultMessage: 'NOT SET',
  },
})
