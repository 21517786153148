import { ApolloError } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import {
  CheckInStatus,
  WorkflowTask,
  WorkflowV2,
} from '@babylon/graphql-middleware-types'
import { WorkflowCardProps } from '../TimelineCard/components/WorkflowCard/WorkflowCard'
import { Services } from '../ServiceErrors'
import checkinStatusMessages from './CheckinStatus.messages'

export const checkInFilter = (
  status = '',
  messageFormatter = useFormatMessage
): string => {
  const formatter = messageFormatter()
  switch (status.toUpperCase()) {
    case CheckInStatus.Cancelled:
    case CheckInStatus.CheckedIn:
    case CheckInStatus.Failed:
    case CheckInStatus.Missed:
    case CheckInStatus.NotOpened:
    case CheckInStatus.Open:
      return formatter(checkinStatusMessages[status.toUpperCase()])

    default:
      return formatter(checkinStatusMessages.default)
  }
}

export const isSupportedWorkflow = (
  workflow_definition_key?: string,
  task_definition_key?: string
): boolean =>
  workflow_definition_key === 'weightManagement' &&
  task_definition_key === 'UserTask_ClinicianReadOnly'

export const mapWorkflowTask = ({
  id,
  consumerNetwork,
  due_date: date,
  name: taskName,
  owner,
  workflow,
}: WorkflowTask): WorkflowCardProps => {
  const workflowDefName = get(
    workflow,
    'workflowDefinition.workflow_definition_name',
    ''
  )
  const workflowDefKey = get(workflow, 'workflow_definition_key', '')
  const state = get<WorkflowV2, 'state'>(workflow, 'state') || ''

  return {
    id: id!,
    date,
    consumerNetworkName: consumerNetwork ? consumerNetwork.name : '',
    state,
    taskName,
    owner,
    workflowName: workflowDefName || workflowDefKey,
  }
}

export const mapWorkflow = ({
  id,
  consumerNetwork,
  end_time: date,
  state,
  variables,
  workflow_definition_key,
  workflowDefinition,
}: WorkflowV2) => {
  const workflowDefName = get(
    workflowDefinition,
    'workflow_definition_name',
    workflow_definition_key || ''
  )

  return {
    id: id!,
    date,
    consumerNetworkName: consumerNetwork ? consumerNetwork.name : '',
    definitionKey: workflow_definition_key || '',
    state,
    variables: variables || [],
    workflowName: workflowDefName || '',
  }
}

export const getServicesFromError = (apolloError?: ApolloError): Services[] => {
  if (!apolloError) {
    return []
  }

  return apolloError.graphQLErrors.reduce<Services[]>((services, error) => {
    for (const service of Object.values(Services)) {
      const { message } = error
      if (
        message.toLowerCase().includes(service) &&
        !services.includes(service)
      ) {
        return [...services, service]
      }
    }
    return services
  }, [])
}

export const getServicesFromAllErrors = (
  ...apolloErrors: (ApolloError | undefined)[]
): Services[] =>
  uniq(
    apolloErrors.reduce<Services[]>(
      (services, apolloError) => [
        ...services,
        ...getServicesFromError(apolloError),
      ],
      []
    )
  )
