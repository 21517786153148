import { branch, renderComponent } from 'recompose'

import HistorylessIFrame from '../HistorylessIFrame'
import TestIFrame from './TestIFrame'

const enhance = branch(
  () => localStorage.getItem('iframes-disabled') === 'true',
  renderComponent(TestIFrame)
)

export default enhance(HistorylessIFrame)
