import React, { useState } from 'react'
import { Formik } from 'formik'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  FilterSummary,
  FilterPanel,
  ConsultationsForGpsFiltersForm,
} from '@babylon/cce-shared-components'
import { Button } from '@babylon/babylon-forms/forms'
import { ListView } from '@babylon/babylon-forms/widgets'
import { useFormatMessage } from '@babylon/intl'
import { Spinner, Snackbar } from '@babylon/core-ui'
import { logException, SnackBarProvider } from '@babylon/member-operations'

import { ConsultationPatientListItem } from './components'
import showConfirmDialog from '@/components/ConfirmDialog'

import {
  formatConsumerNetworkOptions,
  formatFiltersToSearchCriteria,
} from './utils'
import { DEFAULT_PAGINATION_CRITERIA, DEFAULT_FORM_VALUES } from './constants'
import {
  CONSULTATIONS_V2_QUERY,
  CONSUMER_NETWORKS_QUERY,
  GP_SURGERY_FAXES_QUERY,
} from './queries'
import { UPDATE_MARK_AS_SENT_MUTATION } from './mutations'

import styles from './styles.module.scss'
import messages from './ConsultationsForGps.messages'

const ConsultationsForGps = () => {
  const fm = useFormatMessage()
  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState([])
  const [snackBar, setSnackBar] = useState({
    autoHideDuration: 5000,
    icon: undefined,
    intent: 'primary',
    message: null,
    open: false,
  })
  const [searchParameters, setSearchParameters] = useState({
    searchCriteria: formatFiltersToSearchCriteria({}),
    paginationCriteria: DEFAULT_PAGINATION_CRITERIA,
  })

  const { autoHideDuration, icon, intent, message, open } = snackBar

  const [
    getConsultations,
    {
      data: {
        consultationsv2: {
          totalItems,
          itemsPerPage,
          pageIndex,
          items = [],
          showBroadWarning,
        } = {},
      } = {},
      loading,
      refetch,
    },
  ] = useLazyQuery(CONSULTATIONS_V2_QUERY, {
    variables: {
      searchCriteria: { ...searchParameters.searchCriteria },
      paginationCriteria: {
        ...DEFAULT_PAGINATION_CRITERIA,
        currentPage: searchParameters.paginationCriteria.currentPage,
        pageSize: searchParameters.paginationCriteria.pageSize,
      },
    },
  })

  const [
    getConsumerNetworks,
    { data: { consumerNetworks = [] } = {} },
  ] = useLazyQuery(CONSUMER_NETWORKS_QUERY)

  const [
    getGpSurgeryFaxes,
    { data: { gpSurgeryFaxes = [] } = {} },
  ] = useLazyQuery(GP_SURGERY_FAXES_QUERY)

  const [updateMarkedAsSent] = useMutation(UPDATE_MARK_AS_SENT_MUTATION)

  const { marked_as_sent: markedAsSent } = searchParameters.searchCriteria
  const [filterPanelOpen, setFilterPanelOpen] = useState(true)
  const allItemsSelected =
    items.length && items.length === selectedAppointmentIds.length
  const noItemsSelected = selectedAppointmentIds.length === 0
  const faxNumbers = gpSurgeryFaxes.map(({ name, faxNumber, id }) => ({
    label: `${name} (${faxNumber})`,
    value: id,
  }))

  const handleUpdateItemSummarySent = (consultationId, markedAsSent) =>
    updateMarkedAsSent({
      variables: {
        consultationId,
        markedAsSent,
      },
    }).then(refetch)

  const handleUpdateSelectedItemsSummarySent = async () => {
    if (!selectedAppointmentIds.length) {
      return
    }

    try {
      await showConfirmDialog(fm(messages.mark_as_sent_confirmation))
      const consultationIds = selectedAppointmentIds
        .map((appointmentId) => {
          const appointment = items.find((item) => item.id === appointmentId)
          return appointment ? appointment.consultationId : null
        })
        .filter(Boolean)

      const promises = consultationIds.map((consultationId) => {
        return updateMarkedAsSent({
          variables: {
            consultationId,
            markedAsSent: true,
          },
        })
      })

      await Promise.all(promises).then(refetch)
      setSelectedAppointmentIds([])
    } catch (error) {
      logException(error)
    }
  }

  const handleFormSubmit = async (values, { setSubmitting }) => {
    const newFormValues = formatFiltersToSearchCriteria(values)

    setSearchParameters({
      paginationCriteria: DEFAULT_PAGINATION_CRITERIA,
      searchCriteria: newFormValues,
    })
    await getConsultations()
    await getConsumerNetworks()
    await getGpSurgeryFaxes()
    await setSubmitting(false)
    setSelectedAppointmentIds([])
  }

  const handleFormReset = () => {
    setSearchParameters({
      searchCriteria: formatFiltersToSearchCriteria({}),
      paginationCriteria: DEFAULT_PAGINATION_CRITERIA,
    })
    setSelectedAppointmentIds([])
  }

  const handleFilterPanelOpenChange = () => {
    setFilterPanelOpen(!filterPanelOpen)
  }

  const handlePaginationChange = (currentPage) => {
    const currentPagination = searchParameters.paginationCriteria
    setSearchParameters({
      ...searchParameters,
      paginationCriteria: {
        ...currentPagination,
        currentPage: currentPage - 1,
      },
    })
    setSelectedAppointmentIds([])
  }

  const handleRowsPerPageChange = (rowsPerPage) => {
    setSearchParameters({
      ...searchParameters,
      paginationCriteria: {
        pageSize: rowsPerPage,
        currentPage: 0,
      },
    })
    setSelectedAppointmentIds([])
  }

  const handleToggleSelectAllItems = () => {
    const selectedItems = allItemsSelected ? [] : items.map(({ id }) => id)
    setSelectedAppointmentIds(selectedItems)
  }

  return (
    <div className={styles.pageStyle}>
      <div className={styles.filterSummaryWrapper}>
        <FilterSummary
          heading={fm(messages.title)}
          filteredCount={totalItems}
          filteredLabel={fm(messages.filter_summary_filtered_items_meta_label)}
          rowsPerPage={itemsPerPage}
          rowsPerPageLabel={fm(messages.filter_summary_rows_per_page_label)}
          currentPage={pageIndex ? pageIndex + 1 : 1}
          totalPages={Math.ceil(totalItems / itemsPerPage)}
          onPageChange={handlePaginationChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>

      <FilterPanel
        open={filterPanelOpen}
        onOpenChange={handleFilterPanelOpenChange}
      >
        <Formik
          initialValues={DEFAULT_FORM_VALUES}
          onSubmit={handleFormSubmit}
          onReset={handleFormReset}
        >
          {(props) => (
            <ConsultationsForGpsFiltersForm
              {...props}
              i18nMessages={{
                consultation: fm(messages.consultation),
                dates: fm(messages.dates),
                consultation_id: fm(messages.consultation_id),
                consumerNetwork: {
                  label: fm(messages.consumer_network),
                  placeholder: fm(messages.consumer_network_placeholder),
                },
                patient: fm(messages.patient),
                family_name: fm(messages.family_name),
                given_name: fm(messages.given_name),
                gender: {
                  label: fm(messages.gender),
                  placeholder: fm(messages.gender_placeholder),
                  male: fm(messages.male),
                  female: fm(messages.female),
                },
                patient_id: fm(messages.patient_id),
                healthcare_id: fm(messages.healthcare_id),
                filters: fm(messages.filters),
                archived_appointments: fm(messages.archived_appointments),
                reset_filters: fm(messages.reset_filters),
                apply_filters: fm(messages.apply_filters),
              }}
              consumerNetworks={formatConsumerNetworkOptions(consumerNetworks)}
            />
          )}
        </Formik>
      </FilterPanel>

      {showBroadWarning === 'patient' && (
        <span className={styles.broadWarningMessage}>
          {fm(messages.patient_search_criteria_too_broad_error_message)}
        </span>
      )}

      {showBroadWarning === 'consultant' && (
        <span className={styles.broadWarningMessage}>
          {fm(messages.consultant_search_criteria_too_broad_error_message)}
        </span>
      )}

      {loading ? (
        <Spinner centered />
      ) : (
        <>
          <Snackbar
            autoHideDuration={autoHideDuration}
            icon={icon}
            intent={intent}
            message={message}
            onClose={() => setSnackBar({ ...snackBar, open: false })}
            open={open}
            className={styles.Snackbar}
          />
          {items && items.length > 0 && (
            <>
              <SnackBarProvider value={{ snackBar, setSnackBar }}>
                <ListView
                  className={styles.listView}
                  items={items}
                  selectedItems={selectedAppointmentIds}
                  onSelectionChanged={setSelectedAppointmentIds}
                  itemRenderer={ConsultationPatientListItem}
                  itemRendererProps={{
                    handleUpdateItemSummarySent,
                    faxNumbers,
                  }}
                />
              </SnackBarProvider>

              <div className={styles.globalActionButtons}>
                <Button outline onClick={handleToggleSelectAllItems}>
                  {fm(messages.select_all_button_label, {
                    all_selected: !!allItemsSelected,
                  })}
                </Button>

                <Button
                  onClick={handleUpdateSelectedItemsSummarySent}
                  disabled={noItemsSelected}
                >
                  {fm(messages.mark_unmark_all_button_label_v2, {
                    marked_as_sent: !!markedAsSent,
                  })}
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ConsultationsForGps
