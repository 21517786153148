import React from 'react'
import { envFlag } from '@babylon/babylon-env'
import { LegacyFormikDropdownOptionValues } from '@babylon/core-ui/'
import ConsultationTypeSelector from './ConsultationTypeSelector'
import ConsultantTypeSelector from './ConsultantTypeSelector'
import ConsultationDurationSelector, {
  SlotDurationList,
} from './ConsultationDurationSelector'
import ConsultationTimezoneSelector from './ConsultationTimezoneSelector'
import ConsultantSelector from './ConsultantSelector'
import AppointmentClinicSelector from './AppointmentClinicSelector'
import { ConsultationTypeName } from '../types/ConsultationTypes'

interface Props {
  filters?: any
  consultant: string
  consultationTypeList: ConsultationTypeName[]
  consultantTypeList: string[]
  slotDurationList: SlotDurationList
  consultantList: Consultant[]
  appointmentClinicList: Practice[]
  consultationTypeUpdate: (
    value: LegacyFormikDropdownOptionValues | null
  ) => void
  consultantTypeUpdate: (value: LegacyFormikDropdownOptionValues | null) => void
  slotDurationUpdate: (value: LegacyFormikDropdownOptionValues | null) => void
  consultantUpdate: (value: LegacyFormikDropdownOptionValues | null) => void
  appointmentClinicUpdate: (
    value: LegacyFormikDropdownOptionValues | null
  ) => void
  consultationType: ConsultationTypeName
  consultantType: string
  consultantLoading?: boolean
  appointmentClinicLoading?: boolean
  disabled?: boolean
  sharedFilterClassName?: string
  consultationTimezoneUpdate: (value: string | null) => void
}

export default function AppointmentFilters({
  filters,
  consultant,
  consultationTypeList,
  consultationTimezoneUpdate,
  consultantTypeList,
  slotDurationList,
  consultantList,
  appointmentClinicList,
  consultationTypeUpdate,
  consultantTypeUpdate,
  slotDurationUpdate,
  consultantUpdate,
  appointmentClinicUpdate,
  consultationType,
  consultantType,
  consultantLoading,
  appointmentClinicLoading,
  disabled,
  sharedFilterClassName,
}: Props) {
  return (
    <div data-testid="appointment-filters">
      {consultationTypeList.length > 1 && (
        <ConsultationTypeSelector
          className={sharedFilterClassName}
          consultationTypeList={consultationTypeList}
          onChange={consultationTypeUpdate}
          disabled={disabled}
        />
      )}
      {consultantTypeList.length > 1 && (
        <ConsultantTypeSelector
          className={sharedFilterClassName}
          consultantTypeList={consultantTypeList}
          onChange={consultantTypeUpdate}
          disabled={disabled}
        />
      )}
      {!filters && (
        <ConsultationDurationSelector
          className={sharedFilterClassName}
          slotDurationList={slotDurationList}
          onChange={slotDurationUpdate}
          consultantType={consultantType}
          disabled={disabled}
        />
      )}
      <div>
        {envFlag('ENABLE_APPOINTMENT_TIMEZONE') &&
          consultationType !== 'physical' && (
            <ConsultationTimezoneSelector
              onChange={consultationTimezoneUpdate}
              className={sharedFilterClassName}
            />
          )}
        <ConsultantSelector
          className={sharedFilterClassName}
          consultantList={consultantList}
          onChange={consultantUpdate}
          loading={consultantLoading}
          disabled={disabled}
          value={consultant}
        />
        <AppointmentClinicSelector
          className={sharedFilterClassName}
          appointmentClinicList={appointmentClinicList}
          onChange={appointmentClinicUpdate}
          consultationType={consultationType}
          loading={appointmentClinicLoading}
          disabled={disabled || consultationType !== 'physical'}
        />
      </div>
    </div>
  )
}
