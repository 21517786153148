import { defineMessages } from 'react-intl'

const baseId = 'member_search.member_card.modal'

export default defineMessages({
  confirm: {
    id: `${baseId}.cta`,
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  transfer_ownership_question: {
    id: `${baseId}.transfer_ownership_question`,
    defaultMessage: 'Change family account owner?',
  },
  transfer_ownership_message: {
    id: `${baseId}.transfer_ownership_message`,
    defaultMessage:
      'Please confirm that the new account owner is aware that is account is about to be transferred to them.',
  },
  transfer_ownership_loading: {
    id: `${baseId}.transfer_ownership_loading`,
    defaultMessage: 'Changing owner',
  },
})
