import React, { FC, useState } from 'react'
import { useMutation } from '@apollo/client'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { BabylonTimeAgo } from '@babylon/intl'
import { KebabMenu, KebabMenuRenderOptions } from '@babylon/core-ui'
import { Card, Text } from '@babylon/medkit'
import { useProductConfig } from '@babylon/product-config'
import {
  TrackingElementType,
  useTracking,
  useTrackClick,
  TrackingActionType,
} from '@babylon/tracking/react'
import { AdminName } from '../../../..'
import AlertDeleteConfirmationModal from '../AlertDeleteConfirmationModal'
import styles from './AlertCard.module.scss'
import messages from './AlertCard.messages'
import { removeAlertGQL } from '../AlertList/queries'
import { MemberOpsModuleName } from '../../../../..'
import { TimezoneContextType, useTimezoneContext } from '../TimezoneProvider'

export interface AlertCardProps {
  id: number
  patientId: number
  message: string
  onRemove(): void
  updatedAt: string
  updatedByName: string
}

const AlertMenu = ({ alertId, patientId, onRemove }) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.alertList,
  })
  const { trackEvent } = useTracking()
  const [removeAlert] = useMutation(removeAlertGQL, {
    onCompleted: onRemove,
    onError: () => {
      trackEvent({
        patientId: patientId,
        elementName: 'remove-alert-error',
        moduleName: MemberOpsModuleName.alertList,
        elementType: TrackingElementType.form,
        actionType: TrackingActionType.formError,
      })
    },
  })
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { getProp } = useProductConfig()
  const memberAlertDeleteEnabled = getProp(
    'memberOperations',
    'memberAlertDeleteEnabled'
  )

  return (
    <div className={styles.Alert__actionMenu}>
      {memberAlertDeleteEnabled && (
        <KebabMenu>
          {({
            menuItemProps,
            closeMenu = () => {},
          }: KebabMenuRenderOptions) => (
            <div data-testid="alert-card-kebab-menu">
              <button
                {...menuItemProps}
                type="button"
                onClick={() => {
                  trackClick({
                    elementName: 'remove-alert-button',
                    elementType: TrackingElementType.button,
                  })
                  setShowConfirmation(true)
                  closeMenu()
                }}
              >
                <FormattedMessage {...messages.remove_alert_button} />
              </button>
            </div>
          )}
        </KebabMenu>
      )}

      {showConfirmation && (
        <AlertDeleteConfirmationModal
          showConfirmation={showConfirmation}
          onCloseHandler={() => {
            setShowConfirmation(false)
          }}
          onApproveHandler={() => {
            setShowConfirmation(false)
            removeAlert({
              variables: {
                id: alertId,
                patientId,
              },
            })
          }}
        />
      )}
    </div>
  )
}

const AlertCard: FC<AlertCardProps> = ({
  id,
  patientId,
  message,
  updatedAt,
  updatedByName,
  onRemove,
}) => {
  const timeZoneContext: TimezoneContextType = useTimezoneContext()

  return (
    <Card className={styles.Alert}>
      <AlertMenu alertId={id} patientId={patientId} onRemove={onRemove} />

      <div className={styles.Alert__datetime}>
        <div className={styles.Alert__timeRelative}>
          <BabylonTimeAgo date={new Date(updatedAt)} />
        </div>
        <div data-testid="member-alert-date">
          <FormattedDate
            timeZone={timeZoneContext.timezone}
            day="2-digit"
            month="short"
            year="numeric"
            hour="numeric"
            minute="numeric"
            value={updatedAt}
          />
        </div>
      </div>

      <Text
        variant="body"
        className={styles.Alert__message}
        data-testid="member-alert-message"
      >
        {message}
      </Text>

      <AdminName name={updatedByName} />
    </Card>
  )
}

export default AlertCard
