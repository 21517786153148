import React from 'react'

const Dashboard = ({
  width = 20,
  height = 18,
  color = 'currentColor',
}: SvgProps) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillRule="nonzero"
        d="M.896 13.103a.75.75 0 0 1 0-1.5h.04l.001-3.074a.75.75 0 0 1 .75-.75h2.375a.75.75 0 0 1 .75.75v3.073h.875V4.706a.75.75 0 0 1 .75-.75h2.375a.75.75 0 0 1 .75.75v6.896h.875V6.235a.75.75 0 0 1 .75-.75h2.376a.75.75 0 0 1 .75.75l-.001 5.367h.875V.882a.75.75 0 0 1 .75-.75h2.376a.75.75 0 0 1 .75.75l-.001 10.72h.042a.75.75 0 0 1 0 1.5H.896zm16.666-1.5v-9.97h-.875v9.97h.875zm-4.75-4.618h-.874l-.001 4.617h.875V6.985zm-4.75-1.53h-.874l-.001 6.147h.875V5.456zM3.313 9.28h-.874l-.001 2.323h.875V9.28z"
      />
    </g>
  </svg>
)

export default Dashboard
