import { defineMessages } from 'react-intl'

const baseId = 'member_search.member_card.actions'

export default defineMessages({
  make_account_owner: {
    id: `${baseId}.make_account_owner`,
    defaultMessage: 'Make account owner',
  },
  resend_invitation_email: {
    id: `${baseId}.resend_invitation_email`,
    defaultMessage: 'Resend invitation email',
  },
})
