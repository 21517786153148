import { defineMessages } from 'react-intl'

const baseId = 'member_search.member_card'

export default defineMessages({
  view_appointments: {
    id: `${baseId}.view_appointments`,
    defaultMessage: 'View appointments',
  },
  show_family_members: {
    id: `${baseId}.show_family_members`,
    defaultMessage: 'Show family members',
  },
  hide_family_members: {
    id: `${baseId}.hide_family_members`,
    defaultMessage: 'Hide family members',
  },
})
