import React from 'react'
import { Modal } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { useQuery } from '@apollo/client'
import styles from './styles.module.scss'
import messages from './ActivityLog.messages'
import { activityLogEmbedUrl } from '../../../../../../ActivityLog/utils'
import { PATIENT_QUERY, ACTIVITY_LOG_QUERY } from './queries'
import { CopyButton } from '../../../../../..'

const ActivityLogModal = ({
  encryptedParamsName,
  patientId,
  url,
  close,
}: {
  patientId: string
  url: string
  encryptedParamsName: string
  close: () => void
}) => {
  const fm = useFormatMessage()

  const { data } = useQuery(PATIENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { id: patientId },
  })

  const uuid = data?.patient?.uuid

  const { data: activityLogData } = useQuery(ACTIVITY_LOG_QUERY, {
    variables: {
      patientId: uuid,
    },
    skip: !uuid,
  })

  const { reportParameters } = activityLogData?.activityLog || {}

  const reportUrl = activityLogEmbedUrl({
    url,
    encryptedParams: reportParameters,
    encryptedParamsName,
  })

  return (
    <Modal
      open
      label={fm(messages.header)}
      onClose={close}
      className={styles.ModalContainer}
      overlayClassName={styles.ModalContainer__overlay}
      includeClose
    >
      {uuid ? (
        <>
          <CopyButton
            label={fm(messages.copyReportUrl)}
            copiableContent={`${window.location.protocol}//${window.location.host}/admin/patients/${uuid}/activity_log`}
            successMessage={fm(messages.reportUrlCopied)}
            className={styles.ModalContainer__copyButton}
          />
          <iframe
            title="activity_log"
            width={600}
            height={450}
            src={reportUrl}
            frameBorder={0}
            className={styles.ModalContainer__iframe}
            allowFullScreen
          />
        </>
      ) : null}
    </Modal>
  )
}

export default ActivityLogModal
