import React, { PropsWithChildren, useMemo } from 'react'

type MarkdownNode =
  | { type: 'link'; name: string; url: string }
  | { type: 'text'; content: string }

type MarkdowRule = {
  regex: RegExp
  process: (matches: string[]) => MarkdownNode
  groups: number
}

const parseRules: MarkdowRule[] = [
  {
    regex: /(\[([^\]]+)\]\((https?:\/\/[-a-zA-Z0-9@:%._+~#=]+\b[-a-zA-Z0-9@:%_+.~#?&/=]*)\))/gm,
    process: (matches: string[]) => ({
      type: 'link',
      name: matches[1] || '',
      url: matches[2] || '',
    }),
    groups: 3,
  },
  {
    regex: /<(https?:\/\/[-a-zA-Z0-9@:%._+~#=]+\b[-a-zA-Z0-9@:%_+.~#?&/=]*)>/gm,
    process: (matches: string[]) => ({
      type: 'link',
      name: matches[0] || '',
      url: matches[0] || '',
    }),
    groups: 1,
  },
  {
    regex: /(https?:\/\/[-a-zA-Z0-9@:%._+~#=]+\b[-a-zA-Z0-9@:%_+.~#?&/=]*)/gm,
    process: (matches: string[]) => ({
      type: 'link',
      name: matches[0] || '',
      url: matches[0] || '',
    }),
    groups: 1,
  },
]

export function* parse(
  input: string,
  rules = parseRules
): Generator<MarkdownNode> {
  if (rules.length > 0) {
    const rule = rules[0]!
    const nextRules = rules.slice(1, rules.length)
    const toProcess = input.split(rule.regex)

    while (toProcess.length > 0) {
      const current = toProcess.shift()

      if (current) {
        yield* parse(current, nextRules)
      }

      if (toProcess.length) {
        const matched = toProcess.splice(0, rule.groups)
        yield rule.process(matched)
      }
    }
  } else {
    yield {
      type: 'text',
      content: input,
    }
  }
}

const markdownRender = (node: MarkdownNode, mine: boolean) => {
  switch (node.type) {
    case 'link':
      return (
        <a
          href={node.url}
          key={node.url}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'underline',
            color: mine ? 'white' : '#4a4a4a',
          }}
        >
          {node.name}
        </a>
      )
    default:
      return ` ${node.content} `
  }
}

const TextWithLinks: React.FC<{
  children: string
  mine: boolean
  TextComponent: React.ComponentType<PropsWithChildren>
}> = ({ TextComponent, children, mine }) => {
  const parsed = useMemo(() => [...parse(children)], [children])

  return (
    <TextComponent tag="span" size="large">
      {parsed.map((node) => markdownRender(node, mine))}
    </TextComponent>
  )
}

export default TextWithLinks
