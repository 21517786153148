import React, { ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { Card, Heading, Tag } from '@babylon/core-ui'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import ConsumerNetworkTag from '../../../ConsumerNetworkTag'
import CaregiverTag from '../../../Tag'
import ViewMoreToggler from '../../../ViewMoreToggler'
import { MemberOpsModuleName } from '../../../..'
import InviteIcon from '../../../Timeline/InviteIcon'
import MediumIcon from '../../MediumIcon'
import Duration from '../Duration'
import Details from './Details'
import messages from './AppointmentInviteCard.messages'
import styles from './styles.module.scss'
import sharedStyles from '../../shared.module.scss'
import { Medium, Practice } from '../../../types'

export interface AppointmentInviteCardProps {
  menu: ReactNode
  title: ReactNode
  appointmentType?: string
  clinic?: Practice | null
  guestName?: string | null
  consumerNetwork?: string | null
  duration?: number
  isToggleBoxVisible?: boolean
  medium?: Medium
  notes?: string
  reason?: string | null
}

const AppointmentInviteCard = ({
  menu,
  title,
  appointmentType,
  clinic,
  guestName,
  consumerNetwork,
  duration,
  isToggleBoxVisible = true,
  medium,
  notes,
  reason,
}: AppointmentInviteCardProps) => {
  const fm = useFormatMessage()
  const [expanded, setExpanded] = useState<boolean>(false)
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })

  const viewMoreTogglerHandler = () => {
    setExpanded((toggle) => !toggle)
    trackClick({
      elementName: 'view-more-btn',
      elementType: TrackingElementType.button,
    })
  }

  return (
    <div
      className={styles.AppointmentInviteCard__wrapper}
      data-testid="timeline-card"
    >
      <MediumIcon icon={<InviteIcon />} medium={medium} />
      <Card className={styles.AppointmentInviteCard}>
        <div className={styles.AppointmentInviteCard__summary}>
          <div>
            <Tag className={styles.AppointmentInviteCard__invitation}>
              <FormattedMessage {...messages.invitation} />
            </Tag>
          </div>

          <div>
            <ConsumerNetworkTag
              name={consumerNetwork}
              isPreferred
              className={styles.AppointmentInviteCard__cardNetworkTag}
            />
            {guestName && (
              <CaregiverTag
                label={fm(messages.guest_label)}
                className={sharedStyles.guestTag}
              />
            )}
          </div>

          <Heading
            level="h3"
            data-testid="timeline-card-title"
            className={styles.AppointmentInviteCard__title}
          >
            {title}
          </Heading>

          <Duration duration={duration} />

          <div className={styles.AppointmentInviteCard__viewMoreCell}>
            {isToggleBoxVisible && (
              <ViewMoreToggler
                testId="timeline-card-show-details"
                className={styles.AppointmentInviteCard__viewMore}
                open={!!expanded}
                size="small"
                onToggle={viewMoreTogglerHandler}
              >
                {fm(messages.show_details)}
              </ViewMoreToggler>
            )}
          </div>

          <div className={styles.AppointmentInviteCard__menu}>{menu}</div>
        </div>

        <Details
          appointmentType={appointmentType}
          clinic={clinic}
          guestName={guestName}
          notes={notes}
          reason={reason}
          show={expanded}
        />
      </Card>
    </div>
  )
}

export default AppointmentInviteCard
