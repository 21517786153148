import React from 'react'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import take from 'lodash/take'
import { Button, Grid, Spinner } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { Header, LinkButton, MemberOpsModuleName } from '../../../../..'

import { PatientIdProps } from '../../../types'
import NoContent from '../NoContent'
import Comment from './Comment'
import LoadingCommentsCard from './LoadingCommentsCard'
import messages from './CommentsCard.messages'
import styles from './CommentsCard.module.scss'
import { fetchCommentsGQL } from './queries'

export interface RecentCommentsProps extends PatientIdProps {
  shouldRefetch: boolean
  onAddNewComment(): void
  onViewMoreComments(): void
  resetRefetch: () => void
  viewable?: number
}

const RecentComments = ({
  onAddNewComment,
  onViewMoreComments,
  patientId,
  shouldRefetch,
  resetRefetch,
  viewable = 2,
}: RecentCommentsProps) => {
  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })
  const { loading, error, data, refetch } = useQuery(fetchCommentsGQL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      patient_id: patientId,
      pageIndex: 0,
    },
  })

  const comments = get(data, 'comments.items', [])
  const totalItems = get(data, 'comments.totalItems')

  if (loading && !comments) {
    return <LoadingCommentsCard />
  }

  if (loading && comments) {
    return <Spinner centered />
  }

  if (error) {
    return <span>{`${fm(messages.error)} ${error} ${data}`}</span>
  }

  if (shouldRefetch) {
    refetch()
    resetRefetch()
  }

  const onAddCommentHandler = () => {
    onAddNewComment()
    trackClick({
      elementName: 'add-comment-btn',
      elementType: TrackingElementType.button,
    })
  }

  return (
    <div className={styles.Comments__wrapper}>
      <Header
        title={fm(messages.header)}
        info={fm(messages.tooltip)}
        actions={
          <Button
            type="button"
            inline
            intent="secondary"
            testId="member-comments-add-button"
            onClick={onAddCommentHandler}
          >
            {fm(messages.add_comment_button)}
          </Button>
        }
      />
      {!comments.length && (
        <NoContent testId="empty-comments-list">
          {fm(messages.empty_comments_list)}
        </NoContent>
      )}
      {take(comments, viewable).map((comment: any) => (
        <Comment key={comment.id} {...comment} />
      ))}
      {comments.length > viewable && (
        <Grid data-testid="member-comment" className={styles.Comment}>
          <div className={styles.Comment__ViewMore}>
            <LinkButton
              onClick={onViewMoreComments}
              testId="member-comments-view-more"
            >
              {`${fm(messages.view_more, { totalItems })}`}
            </LinkButton>
          </div>
        </Grid>
      )}
    </div>
  )
}

export default RecentComments
