import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { Button } from '@babylon/core-ui'

import CreateAppointmentInvite from './CreateInviteMutation'
import InviteSentConfirmation from './InviteSentConfirmation'
import { AppointmentFields } from '../../AppointmentFields'
import { UnexpectedError } from '../../Utils'

interface Props {
  filters: AppointmentFields
  isDisabled: boolean
  memberId: string
  memberUuid: string
}

const CreateInvite = ({ filters, isDisabled, memberId, memberUuid }: Props) => {
  const [createInvite, { loading, error, data }] = useMutation(
    CreateAppointmentInvite
  )

  const handleSendInvite = useCallback(() => {
    const { appointment_reason, ...otherFilters } = filters

    createInvite({
      variables: {
        input: {
          ...otherFilters,
          notes_for_member: appointment_reason,
          earliest_booking_date: new Date().toISOString(),
          member_uuid: memberUuid,
        },
      },
    })
  }, [createInvite, filters, memberUuid])

  const { createAppointmentInvite: createdInvite = null } = data || {}

  return (
    <>
      <UnexpectedError message={error && error.message} visible={!!error} />
      {createdInvite && (
        <InviteSentConfirmation
          durationMinutes={createdInvite.duration_minutes}
          intimate={createdInvite.intimate}
          isOpen
          memberId={memberId}
          notes={
            createdInvite.notes_for_member ||
            (createdInvite.service_type &&
              createdInvite.service_type.member_instructions)
          }
          serviceTypeName={
            createdInvite.service_type && createdInvite.service_type.name
          }
        />
      )}

      <Button
        disabled={isDisabled || loading}
        inline
        intent="secondary"
        loading={loading}
        onClick={handleSendInvite}
      >
        Send Invite
      </Button>
    </>
  )
}

export default CreateInvite
