import { createContext, Dispatch } from 'react'
import { SetFamilyMembersAction, UpdateOwnerAction } from './actions'

export interface MemberContextInitialState {
  member?: any | null
  familyMembers?: any[]
  dispatch?: Dispatch<SetFamilyMembersAction | UpdateOwnerAction>
}

const MemberContext = createContext<MemberContextInitialState>({})

export default MemberContext
