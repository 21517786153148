import React, { FC } from 'react'
import styles from './NoContent.module.scss'

export interface NoContentProps {
  testId: string
}

const NoContent: FC<NoContentProps> = ({ children, testId }) => (
  <div className={styles.noContent} data-testid={testId}>
    {children}
  </div>
)

export default NoContent
