import { gql } from '@apollo/client'

const GetCancellationDetails = gql`
  query getCancellationDetails($appointmentId: ID!) {
    newAppointment(appointmentId: $appointmentId) {
      clinic {
        name
        address
      }
      consultant {
        name
        gender
      }
      duration
      medium
      scheduledTime: time
      timezoneId: timezone_id
    }
    adminCancelReasons {
      id
      reason
    }
  }
`

export default GetCancellationDetails
