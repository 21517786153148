import React from 'react'
import { useQuery } from '@apollo/client'
import cn from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { Tag } from '@babylon/core-ui'
import DrawerView from '../../../../DrawerView'
import { ErrorBlock } from '../../../../ErrorBoundary'
import MemberTaskHistoryLog from '../MemberTaskHistoryLog'
import MemberTaskStatus from '../MemberTaskStatus'
import ThreadQuery from './ThreadQuery'
import styles from './MemberTaskDrawer.module.css'
import messages from './MemberTaskDrawer.messages'
import getStatusStyles from './status'

interface MemberTaskDrawerProps {
  title?: string
  closeDrawer: () => void
  threadUuid?: string | null
}

export default function MemberTaskDrawer({
  title = 'Member Task',
  closeDrawer,
  threadUuid,
}: MemberTaskDrawerProps) {
  const { data, loading, error } = useQuery(ThreadQuery, {
    variables: {
      uuid: threadUuid,
    },
    skip: !threadUuid,
  })

  const fm = useFormatMessage()

  return (
    <DrawerView
      visible={!!threadUuid}
      title={title}
      toggleVisible={closeDrawer}
      headerClassName={styles.header}
    >
      <>
        {!loading && data?.memberThread?.state && (
          <Tag
            className={cn(styles.tag, getStatusStyles(data.memberThread.state))}
          >
            {fm(messages[data.memberThread.state])}
          </Tag>
        )}

        {error?.message && (
          <ErrorBlock>
            <ErrorBlock.Heading>{error.message}</ErrorBlock.Heading>
          </ErrorBlock>
        )}
        <MemberTaskStatus
          logEntry={data?.memberThread?.tasks?.[0]?.logEntry} // current task will always be at position 0
          status={data?.memberThread?.tasks?.[0]?.state} // current task will always be at position 0
          loading={loading}
        />
        <MemberTaskHistoryLog
          tasks={data?.memberThread?.tasks || []}
          loading={loading}
        />
      </>
    </DrawerView>
  )
}
