import React from 'react'
import { Text } from '@babylon/core-ui'

export interface TotalResultsProps {
  total: number
  className?: string
}

export default function TotalResults({ className, total }: TotalResultsProps) {
  return (
    <Text tag="div" className={className} size="large" color="grey-type">
      <Text color="action-green-type" size="large" data-testid="total-results">
        {total}
      </Text>{' '}
      results found
    </Text>
  )
}
