import React, { useEffect } from 'react'
import cx from 'classnames'
import { Transition } from 'react-transition-group'

import styles from './styles.module.scss'

interface AnimatedPopupProps {
  visible?: boolean
  className?: string
  render: new () => React.Component<
    { state: unknown; popupTransitionState: unknown; onClose: () => void },
    unknown,
    unknown
  >
  width?: string
  onClose: () => void
  state: string
}

export default function AnimatedPopup({
  render: Component,
  visible,
  className,
  width,
  state,
  onClose,
  ...rest
}: AnimatedPopupProps) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27 && state === 'entered') {
        event.stopImmediatePropagation()
        onClose()
      }
    }

    window.addEventListener('keydown', handleKeyDown, true)

    return () => window.removeEventListener('keydown', handleKeyDown, true)
  }, [onClose, state])

  return (
    <Transition appear in={visible} timeout={300}>
      {(transitionState) => (
        <span className={styles[transitionState]}>
          <div
            className={cx(styles.popup, className)}
            style={{ width }}
            data-testid="popupContainer"
          >
            {transitionState === 'exited' ? null : (
              <Component
                {...rest}
                state={state}
                popupTransitionState={transitionState}
                onClose={onClose}
              />
            )}
          </div>
        </span>
      )}
    </Transition>
  )
}
