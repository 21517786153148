/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type CancelAppointmentMutationVariables = Types.Exact<{
  appointmentId: Types.Scalars['ID']
  cancelReasonId: Types.Scalars['Int']
}>

export type CancelAppointmentMutation = {
  cancelAppointmentWithMicroservice?: Types.Maybe<Pick<Types.Appointment, 'id'>>
}

export const CancelAppointmentDocument = gql`
  mutation CancelAppointment($appointmentId: ID!, $cancelReasonId: Int!) {
    cancelAppointmentWithMicroservice(
      appointmentId: $appointmentId
      cancelReasonId: $cancelReasonId
    ) {
      id
    }
  }
`
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >(CancelAppointmentDocument, baseOptions)
}
export type CancelAppointmentMutationHookResult = ReturnType<
  typeof useCancelAppointmentMutation
>
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>
