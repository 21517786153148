import React, { MutableRefObject } from 'react'
import { FieldInputProps } from 'formik'

import { IMedkitComponent } from '../..'
import { useSelectionControl, useMultiSelectionControl } from '../hooks'
import Pill from './Pill'

import styles from './index.module.scss'

import { pillsTheme } from './theme'

export interface IPillsOption {
  value: string
  label: string
  disabled?: boolean
  selected?: boolean
}

export interface PillsProps {
  name: string
  options: IPillsOption[]
  multiSelect?: boolean
  onChange?: (selectedOptions: string[] | string) => void
  ErrorMessages?: React.FC<any>
  field?: FieldInputProps<any>
  variant?: 'default' | 'stacked'
  fieldRef?: MutableRefObject<HTMLInputElement | null>
  isControlledComponent?: boolean
  'data-testid'?: string
}

const Pills: IMedkitComponent<PillsProps, typeof pillsTheme> = ({
  name,
  options,
  onChange,
  multiSelect = false,
  ErrorMessages,
  field,
  variant = 'default',
  fieldRef,
  isControlledComponent = false,
  'data-testid': dataTestId,
}: PillsProps) => {
  const parentRole = multiSelect ? 'group' : 'radiogroup'
  const type = multiSelect ? 'checkbox' : 'radio'

  const { selectOptions, onOptionChange } = (multiSelect
    ? useMultiSelectionControl
    : useSelectionControl)(options, onChange, isControlledComponent)

  return (
    <>
      {ErrorMessages && <ErrorMessages />}
      <div
        role={parentRole}
        aria-label={name}
        className={styles[`${variant}PillContainer`]}
        data-testid={dataTestId}
      >
        {selectOptions.map(
          ({ value, selected, disabled, label }: IPillsOption) => (
            <Pill
              {...field}
              ref={fieldRef}
              id={`${name}-${value}`}
              key={`${name}-${value}`}
              testHandle={`${dataTestId || name}-${value}`}
              name={name}
              value={value}
              checked={!!selected}
              onChange={(e: MouseEvent) => {
                onOptionChange(e)
                field?.onChange(e)
              }}
              disabled={disabled}
              type={type}
            >
              {label}
            </Pill>
          )
        )}
      </div>
    </>
  )
}

Pills.theme = pillsTheme

export default Pills
