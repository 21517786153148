/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { ReactNode, useState } from 'react'
import classNames from 'classnames/bind'
import { tail } from 'lodash'
import { Cell, Grid, Tag, Heading } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'
import { useFormatMessage } from '@babylon/intl'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { LinkButton } from '../../../..'
import { OnActionClick } from '../../../types'
import FormattedDate from '../FormattedDate'
import {
  CancelButton,
  DeregisterButton,
  ChangeCityDropdown,
  RejoinButton,
  RemoveButton,
  SelectButton,
} from './ActionButtons'
import styles from './Membership.module.scss'
import messages from './ConsumerNetworksCard.messages'
import CurrentConsumerNetworkIcon from './CurrentConsumerNetworkIcon'
import {
  ConsumerNetworkMembership,
  MembershipHistory,
} from '../../../../../app'
import { MemberOpsModuleName } from '../../../../..'
import {
  PortalCreditCard,
  Subscription,
  SubscriptionPlan,
} from '../../../../../../../graphql-middleware-types'
import SubscriptionActionModal from './components/SubscriptionActionModal'

export interface MembershipProps extends ConsumerNetworkMembership {
  patientId: string
  onActionClick: OnActionClick
  isQueued: boolean
  classNames?: string[]
}

export interface MembershipDataProps {
  name: ReactNode
  testId?: string
  value?: ReactNode
}

export interface NetworkProps {
  network: string
  isActive: boolean
  isQueued: boolean
}

export interface DatesProps {
  history?: MembershipHistory[]
  started_on?: string | null
}

export interface SubscriptionProps {
  creditCard: PortalCreditCard | null
  plan: SubscriptionPlan | null
  patientId: string
  subscription: Subscription | null
}

const cx = classNames.bind(styles)

const getDataTestId = (type: 'label' | 'value', testId?: string) =>
  testId ? `${testId}-${type}` : undefined

const MembershipData = ({
  name,
  testId,
  value = '\u2014',
}: MembershipDataProps) => (
  <>
    <Cell
      className={styles.membershipData__name}
      data-testid={getDataTestId('label', testId)}
    >
      {name}:
    </Cell>
    <Cell
      className={styles.membershipData__value}
      data-testid={getDataTestId('value', testId)}
    >
      {value}
    </Cell>
  </>
)

const Network = ({ isActive, isQueued, network }: NetworkProps) => {
  const fm = useFormatMessage()
  const className = cx('tag', {
    'tag--active': isActive && !isQueued,
    'tag--removed': !isActive || isQueued,
  })

  return (
    <MembershipData
      name={fm(messages.network_label)}
      value={
        <Tag className={className}>
          <span data-testid="consumer-network-tag">{network}</span>
        </Tag>
      }
    />
  )
}

const HistoryDates = ({ history, started_on }: DatesProps) => {
  const fm = useFormatMessage()

  if (!history || !history.length) {
    return null
  }

  const { created_at, deleted_at } = history[0]

  return (
    <>
      <MembershipData
        name={fm(messages.joined_label)}
        value={<FormattedDate value={created_at} />}
      />
      {!deleted_at && !!started_on && (
        <MembershipData
          name={fm(messages.started_label)}
          value={started_on ? <FormattedDate value={started_on} /> : undefined}
        />
      )}
      {!!deleted_at && (
        <MembershipData
          name={fm(messages.removed_label)}
          value={<FormattedDate value={deleted_at} />}
        />
      )}
    </>
  )
}

const ActionButtons = (props: MembershipProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.consumerNetworks,
  })
  const {
    active,
    can_be_cancelled,
    can_be_deregistered,
    gp_at_hand,
    history,
    is_default,
    is_preferred,
    is_retired,
    patientId,
    onActionClick,
    consumer_network,
  } = props

  const { getProp } = useProductConfig()
  const memberProfileMembershipActionsEnabled = getProp(
    'memberOperations',
    'memberProfileMembershipActionsEnabled'
  )

  if (!history || !history.length || !memberProfileMembershipActionsEnabled) {
    return null
  }

  const { id: networkId } = history[0]
  const selectable = !is_preferred && !is_retired && active
  const cancellable = can_be_cancelled && gp_at_hand
  const deregisterable = can_be_deregistered && gp_at_hand
  const can_change_city = gp_at_hand
  const removable = active && !is_default && !gp_at_hand
  const rejoinable = !active && !is_retired
  const variables = { networkId, patientId }
  const dropdownVariables = { patientId, networkId: consumer_network.id }

  return (
    <div data-testid="consumer-network-buttons">
      <Grid columns={2} rowGap={0}>
        <div className={styles.consumerNetwork__buttons}>
          {selectable && (
            <SelectButton
              {...variables}
              onClick={() => {
                onActionClick()
                trackClick({
                  elementName: 'select-consumer-network-btn',
                  elementType: TrackingElementType.button,
                })
              }}
            />
          )}
          {cancellable && (
            <CancelButton
              {...variables}
              onClick={() => {
                onActionClick()
                trackClick({
                  elementName: 'cancel-consumer-network-btn',
                  elementType: TrackingElementType.button,
                })
              }}
            />
          )}
          {deregisterable && (
            <DeregisterButton
              {...variables}
              onClick={() => {
                onActionClick()
                trackClick({
                  elementName: 'deregister-consumer-network-btn',
                  elementType: TrackingElementType.button,
                })
              }}
            />
          )}
          {can_change_city && (
            <ChangeCityDropdown
              {...dropdownVariables}
              onActionClick={onActionClick}
            />
          )}
          {removable && (
            <RemoveButton
              {...variables}
              onClick={() => {
                onActionClick()
                trackClick({
                  elementName: 'remove-consumer-network-btn',
                  elementType: TrackingElementType.button,
                })
              }}
            />
          )}
          {rejoinable && (
            <RejoinButton
              {...variables}
              onClick={() => {
                onActionClick()
                trackClick({
                  elementName: 'rejoin-consumer-network-btn',
                  elementType: TrackingElementType.button,
                })
              }}
            />
          )}
        </div>
      </Grid>
    </div>
  )
}

const Dates = (props: DatesProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.consumerNetworks,
  })
  const fm = useFormatMessage()
  const [expanded, setExpanded] = useState(false)
  const { history, started_on } = props

  if (!history || !history.length) {
    return null
  }

  const extraHistory = history.length - 1
  const historyToggler = () => {
    setExpanded(!expanded)
    trackClick({
      elementName: `${expanded ? 'hide' : 'view'}-past-dates-btn`,
      elementType: TrackingElementType.button,
    })
  }

  return (
    <>
      <Grid columns={2} rowGap={0}>
        <HistoryDates history={history} started_on={started_on} />
        {extraHistory > 0 && (
          <div
            className={styles.moreHistory}
            data-testid="consumer-network-history"
          >
            <LinkButton
              className={styles.moreHistory__button}
              onClick={historyToggler}
            >
              {expanded
                ? fm(messages.hide_past_dates, { extraHistory })
                : fm(messages.view_past_dates, { extraHistory })}
            </LinkButton>
          </div>
        )}
      </Grid>
      {expanded && (
        <div data-testid="consumer-network-history-past">
          {tail(history).map((hist) => (
            <Grid
              key={hist.created_at}
              className={styles.moreHistory__date}
              columns={2}
              rowGap={0}
            >
              <HistoryDates history={[hist]} />
            </Grid>
          ))}
        </div>
      )}
    </>
  )
}

const SubscriptionInfo = ({
  creditCard,
  plan,
  patientId,
  subscription,
}: SubscriptionProps) => {
  const fm = useFormatMessage()
  const { getProp } = useProductConfig()

  const showSubscription = plan !== null || creditCard !== null
  const showCancelButton = subscription?.can_be_cancelled ?? false

  const displaySubscriptionEnabled =
    getProp('memberOperations', 'displaySubscriptionEnabled') ?? false

  const paymentValue = creditCard
    ? `${creditCard.last4} ${creditCard.cardType}`
    : '\u2014'

  if (!showSubscription || !displaySubscriptionEnabled) {
    return null
  }

  return (
    <>
      <Grid columns={2} rowGap={0}>
        <MembershipData
          testId="subscription-plan-title"
          name={fm(messages.plan_label)}
          value={plan?.title}
        />
        <MembershipData
          testId="subscription-plan-payment"
          name={fm(messages.payment_label)}
          value={paymentValue}
        />
      </Grid>
      {showCancelButton && (
        <SubscriptionActionModal
          subscriptionId={subscription?.id}
          patientId={patientId}
        />
      )}
    </>
  )
}

const Membership = (props: MembershipProps) => {
  const fm = useFormatMessage()

  const {
    active,
    consumer_network,
    history,
    started_on,
    isQueued,
    is_preferred,
    plan,
    classNames: klassNames,
    credit_card: creditCard,
    patientId,
    subscription,
  } = props

  const name = isQueued
    ? `${consumer_network.name} - ${fm(messages.queued)}`
    : consumer_network.name

  return (
    <div
      className={cx(styles.membership, klassNames)}
      data-testid="consumer-network-membership"
    >
      {is_preferred && (
        <Heading
          level="h4"
          className={styles.currentMembership__headingWrapper}
        >
          <CurrentConsumerNetworkIcon
            className={cx(styles.currentMembership__headingIcon)}
          />
          <span className={styles.currentMembership__headingLabel}>
            {fm(messages.current_plan_header)}
          </span>
        </Heading>
      )}
      <Grid columns={2} rowGap={0}>
        <Network isActive={active} isQueued={isQueued} network={name} />
        <MembershipData
          testId="consumer-network-state"
          name={fm(messages.state_label)}
          value={
            active ? fm(messages.active_state) : fm(messages.removed_state)
          }
        />
      </Grid>
      <Dates history={history} started_on={started_on} />
      <ActionButtons {...props} />
      <SubscriptionInfo
        plan={plan}
        creditCard={creditCard}
        patientId={patientId}
        subscription={subscription}
      />
    </div>
  )
}

export default Membership
