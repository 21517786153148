import * as Types from '@babylon/graphql-middleware-types'

export interface AppointmentWaitTimeConfigsSaveDialogProps {
  isOpen: boolean
  operation: string
  consumerNetwork?: Pick<Types.ConsumerNetwork, 'id' | 'name'>
  serviceType?: Pick<Types.ServiceType, 'id' | 'name'>
  handleSaveWaitTimeConfigsAfterActions: Function
  handleSaveDialogClickClose: Function
}

export enum AppointmentWaitTimeConfigsSaveDialogOperations {
  Insert = 'insert',
  Update = 'update',
}
