import React, { FC } from 'react'

interface CurrentConsumerNetworkIconProps {
  className?: string
  width?: number
  height?: number
}

const CurrentConsumerNetworkIcon: FC<CurrentConsumerNetworkIconProps> = ({
  className,
  width = 16,
  height = 16,
}) => (
  <svg className={className} width={width} height={height} viewBox="0 0 16 16">
    <path
      fill="#400099"
      d="M8 0c4.416 0 8 3.584 8 8s-3.584 8-8 8-8-3.584-8-8 3.584-8 8-8zm3.922 4.37a.54.54 0 0 0-.781-.002L6.159 9.479 4.707 7.977a.54.54 0 0 0-.782 0 .585.585 0 0 0 0 .808l1.842 1.905a.54.54 0 0 0 .78.001L11.92 5.18a.585.585 0 0 0 .003-.808z"
    />
  </svg>
)

export default CurrentConsumerNetworkIcon
