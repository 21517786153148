/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type GetHumanitySyncsQueryVariables = Types.Exact<{
  offset?: Types.Maybe<Types.Scalars['Int']>
  limit?: Types.Maybe<Types.Scalars['Int']>
}>

export type GetHumanitySyncsQuery = {
  humanitySyncs: Pick<Types.PastHumanitySyncs, 'total' | 'limit' | 'offset'> & {
    syncs: Array<
      Types.Maybe<
        Pick<
          Types.PastHumanitySync,
          | 'id'
          | 'created_at'
          | 'user_name'
          | 'start_date'
          | 'end_date'
          | 'clinician_count'
          | 'status'
          | 'last_synced_at'
          | 'is_auto_sync'
          | 'errors'
        >
      >
    >
  }
}

export const GetHumanitySyncsDocument = gql`
  query GetHumanitySyncs($offset: Int, $limit: Int) {
    humanitySyncs(offset: $offset, limit: $limit) {
      total
      limit
      offset
      syncs {
        id
        created_at
        user_name
        start_date
        end_date
        clinician_count
        status
        last_synced_at
        is_auto_sync
        errors
      }
    }
  }
`
export function useGetHumanitySyncsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHumanitySyncsQuery,
    GetHumanitySyncsQueryVariables
  >
) {
  return Apollo.useQuery<GetHumanitySyncsQuery, GetHumanitySyncsQueryVariables>(
    GetHumanitySyncsDocument,
    baseOptions
  )
}
export function useGetHumanitySyncsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHumanitySyncsQuery,
    GetHumanitySyncsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetHumanitySyncsQuery,
    GetHumanitySyncsQueryVariables
  >(GetHumanitySyncsDocument, baseOptions)
}
export type GetHumanitySyncsQueryHookResult = ReturnType<
  typeof useGetHumanitySyncsQuery
>
export type GetHumanitySyncsLazyQueryHookResult = ReturnType<
  typeof useGetHumanitySyncsLazyQuery
>
export type GetHumanitySyncsQueryResult = Apollo.QueryResult<
  GetHumanitySyncsQuery,
  GetHumanitySyncsQueryVariables
>
