import React from 'react'
import panelStyles from './panel.module.css'

export const List = ({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLUListElement>) => (
  <ul {...props} className={panelStyles.panel}>
    {children}
  </ul>
)

export const ListItem = ({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLLIElement>) => (
  <li className={panelStyles.panelRow} {...props}>
    {children}
  </li>
)
