import React from 'react'
import { Page } from '@babylon/core-ui'
import { Link, useParams } from 'react-router-dom'
import { addMinutes, startOfDay, addHours } from 'date-fns'
import {
  buildClinicianCalendarPageUrl,
  buildConsultantSearchPageUrl,
} from '../../utils/page'
import type { ClinicianCalendarPageParameters } from './ClinicianCalendarPage.types'
import { UpdateAvailabilityForm } from '../../components/UpdateAvailabilityForm'
import type { UpdateAvailabilityFormValues } from '../../components/UpdateAvailabilityForm/UpdateAvailabilityForm.types'
import styles from './ClinicianCalendarPage.module.css'
import { ClinicianCalendar } from '../../components/ClinicianCalendar'
import type { CalendarEvent } from '../../components/ClinicianCalendar'

const events: CalendarEvent[] = [
  {
    title: 'Avail - Digital',
    start: startOfDay(new Date()),
    end: addMinutes(startOfDay(new Date()), 5),
    resource: {
      isAdmin: false,
    },
  },
  {
    title: 'Admin',
    start: addHours(startOfDay(new Date()), 1),
    end: addMinutes(addHours(startOfDay(new Date()), 1), 10),
    resource: {
      isAdmin: true,
    },
  },
]

const ClinicianCalendarPage = () => {
  const { clinicianId } = useParams<ClinicianCalendarPageParameters>()

  const handleAvailabilityFormSubmit = (
    values: UpdateAvailabilityFormValues
  ) => {
    console.log(values)
  }

  return (
    <Page
      breadcrumbs={[
        <Link key="1" to={buildConsultantSearchPageUrl()}>
          Practitioners
        </Link>,
        <Link key="2" to={buildClinicianCalendarPageUrl(clinicianId)}>
          Availability
        </Link>,
      ]}
    >
      <div className={styles.page}>
        <UpdateAvailabilityForm onSubmit={handleAvailabilityFormSubmit} />

        <div className={styles.calendar}>
          <ClinicianCalendar
            events={events}
            onEventSelected={() => {}}
            onNavigate={() => {}}
          />
        </div>
      </div>
    </Page>
  )
}

export default ClinicianCalendarPage
