import React from 'react'
import { useQuery } from '@apollo/client'
import { formatDistanceStrict, startOfDay, endOfDay, formatISO } from 'date-fns'
import { Grid, Cell, Button } from '@babylon/core-ui'
import { ScrollSyncPane } from 'react-scroll-sync'

import GetClinicianInformation, {
  ClinicianInformationDataType,
} from './GetClinicianInformation'
import GetClinicianAvailability, {
  ClinicianAvailabilityDataType,
  ClinicianAvailabilityFilterType,
  AvailabilitySlotType,
} from './GetClinicianAvailability'

import ShiftSlotDuration from '../ShiftSlot/ShiftSlotDuration'
import Timeline from './Timeline'
import { ClinicianAvatarAndName, LoadingContainer } from '../../components'
import ClinicianAvailability from './ClinicianAvailability'

import { UnexpectedError } from '../../Utils'

import { useScrollTo, usePrevious } from '../../hooks'

import styles from './ClinicianTimeline.module.css'

const TIMELINE_STEP = 30
// Timeline duration doesn't account for gap between slots
const TIMELINE_DURATION = ShiftSlotDuration[TIMELINE_STEP] + 4

const getTimeDifferenceInPixels = (startDate, endDate) => {
  const differenceInMinutes = parseFloat(
    formatDistanceStrict(startDate, endDate, {
      unit: 'minute',
      addSuffix: true,
    })
  )

  /*
   * Adding a suffix when start is after end date will make
   * parseFloat return NaN.
   * e.g. `123 minutes ago` will return 123
   * `in 123 minutes`will return NaN
   */
  if (Number.isNaN(differenceInMinutes)) {
    return 0
  }

  return (differenceInMinutes * TIMELINE_DURATION) / TIMELINE_STEP
}

const getSelectedRangeDimension = (rangeStart, rangeEnd) => {
  const beginningOfDay = startOfDay(rangeStart)

  // There's a gap between the timeline element and the actual line separator
  const startOfTimeGap = 13

  const distanceToTop =
    startOfTimeGap + getTimeDifferenceInPixels(beginningOfDay, rangeStart)
  const rangeDuration = getTimeDifferenceInPixels(rangeStart, rangeEnd)

  return [distanceToTop, rangeDuration]
}

interface Props {
  clinicianId: number
  date: string
  shiftStart: string
  shiftEnd: string
  'data-testid': string
  onChangeClinician: () => void
  onLoadTimeline?: (appointments: AvailabilitySlotType[]) => void
}

export default function ClinicianTimeline({
  clinicianId,
  date,
  shiftStart,
  shiftEnd,
  onChangeClinician,
  onLoadTimeline,
  'data-testid': dataTestId,
}: Props) {
  const {
    data: selectedClinicianInfo,
    loading: isSelectedClinicianInfoLoading,
  } = useQuery<ClinicianInformationDataType>(GetClinicianInformation, {
    variables: {
      id: clinicianId,
    },
  })

  const {
    data: clinicianAvailabilityData = { appointmentAvailabilitySlots: [] },
    loading: isClinicianAvailabilityLoading,
    error: clinicianAvailabilityError,
  } = useQuery<ClinicianAvailabilityDataType, ClinicianAvailabilityFilterType>(
    GetClinicianAvailability,
    {
      fetchPolicy: 'network-only',
      skip: !selectedClinicianInfo?.consultant?.uuid,
      variables: {
        filter: {
          consultantUUID: selectedClinicianInfo?.consultant?.uuid || '',
          startDate: formatISO(startOfDay(new Date(date))),
          endDate: formatISO(endOfDay(new Date(date))),
          clientCompatibleFlows: 'RTM',
        },
      },
    }
  )

  const rangeStartDateTime = new Date(`${date} ${shiftStart}`)
  const rangeEndDateTime = new Date(`${date} ${shiftEnd}`)

  const [distanceToTop, rangeDuration] = getSelectedRangeDimension(
    rangeStartDateTime,
    rangeEndDateTime
  )

  const scrollableElement = useScrollTo(distanceToTop - TIMELINE_DURATION / 4)

  const isLoadingClinicianTimeline =
    isSelectedClinicianInfoLoading || isClinicianAvailabilityLoading

  /*
   * Checking if the loading state changed from the previous render.
   * We only want to update the loading state in the ReallocateShiftPage when either
   * - The page has changed from loading to not loading
   * - The values in shift start and end changed
   */
  const previousLoadingClinicianValue = usePrevious(isLoadingClinicianTimeline)
  const previousShiftStart = usePrevious(shiftStart)
  const previousShiftEnd = usePrevious(shiftEnd)

  if (
    (isLoadingClinicianTimeline !== previousLoadingClinicianValue ||
      previousShiftStart !== shiftStart ||
      previousShiftEnd !== shiftEnd) &&
    !isLoadingClinicianTimeline &&
    onLoadTimeline
  ) {
    onLoadTimeline(clinicianAvailabilityData.appointmentAvailabilitySlots)
  }

  return (
    <>
      <LoadingContainer
        className={styles.ClinicianTimelineLoadingContainer}
        loading={isLoadingClinicianTimeline}
        fill
      >
        <Grid
          dataTestId={dataTestId}
          columns={1}
          className={styles.ClinicianTimelineWrapper}
          rowGap={0}
        >
          <Cell className={styles.ClinicianProfileSection}>
            <ClinicianAvatarAndName
              name={selectedClinicianInfo?.consultant?.name}
              avatarUrl={selectedClinicianInfo?.consultant?.avatar_full_url}
            />
            <Button
              dataTestId="clinician-avatar-and-name-on-change"
              onClick={onChangeClinician}
              intent="link"
            >
              Change
            </Button>
          </Cell>
          <ScrollSyncPane>
            <Cell ref={scrollableElement} className={styles.ClinicianTimeline}>
              <div className={styles.ClinicianTimelineSlotWrapper}>
                <div className={styles.ClinicianTimelineShiftSlot}>
                  {!isLoadingClinicianTimeline && (
                    <ClinicianAvailability
                      date={date}
                      availabilitySlots={
                        clinicianAvailabilityData.appointmentAvailabilitySlots
                      }
                      shiftStart={shiftStart}
                      shiftEnd={shiftEnd}
                    />
                  )}
                </div>
                <Timeline
                  step={TIMELINE_STEP}
                  distanceBetweenSteps={TIMELINE_DURATION}
                />
              </div>
              {rangeDuration && (
                <div
                  className={styles.ClinicianTimelineRange}
                  style={{
                    top: `${distanceToTop}px`,
                    height: `${rangeDuration}px`,
                  }}
                />
              )}
            </Cell>
          </ScrollSyncPane>
        </Grid>
      </LoadingContainer>
      {clinicianAvailabilityError && (
        <UnexpectedError message={clinicianAvailabilityError.message} visible />
      )}
    </>
  )
}
