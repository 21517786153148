import React from 'react'
import { confirmable, createConfirmation } from 'react-confirm'
import { Input, Select, Button } from '@babylon/ui'
import cx from 'classnames'

import { ModalOverlay } from '@babylon/member-operations'

import styles from './styles.module.scss'

const generateIndex = ((index) => () => index++)(0)

const OTHER = '_OTHER_'

class ReasonDialog extends React.Component {
  state = {
    reason: '',
    alert: false,
  }

  handleSubmit = () => {
    const { proceed } = this.props

    if (this.state.reason.trim().split(/[\s\t]+/).length < 2) {
      this.setState({ alert: true })
    } else {
      proceed(this.state.reason.trim())
    }
  }

  render() {
    const { predefinedReasons, cancel } = this.props

    return (
      <ModalOverlay className={styles.dim} visible onRequestClose={cancel}>
        <div
          className={cx(styles.dialog, {
            [styles.alert]: this.state.alert,
            [styles.placeholder]: !this.state.selectedReason,
          })}
        >
          <h3>Choose the reason for your action</h3>
          <h4>Reason (min 2 words)</h4>
          <Select
            className={cx({
              [styles.alone]: this.state.selectedReason !== OTHER,
            })}
            key={generateIndex()}
            onChange={(reason) =>
              this.setState({
                selectedReason: reason,
                reason: reason === OTHER ? '' : reason,
              })
            }
            placeholder="Select a predefined reason"
            value={this.state.selectedReason}
            options={[
              ...predefinedReasons.map((item) => ({
                label: item,
                value: item,
              })),
              { label: 'Other', value: OTHER },
            ]}
          />
          {this.state.selectedReason === OTHER ? (
            <div className={styles.otherReason}>
              <Input
                label=""
                value={this.state.reason}
                placeholder="Type your reason here"
                onChange={(event) =>
                  this.setState({ reason: event.target.value, alert: false })
                }
              />
            </div>
          ) : null}
          <div className={styles.buttonBar}>
            <Button
              buttonStyle="outlined"
              onClick={() => cancel()}
              label="Cancel"
            />
            <Button onClick={this.handleSubmit} label="Submit" />
          </div>
        </div>
      </ModalOverlay>
    )
  }
}

const dialog = confirmable(ReasonDialog)
const confirm = createConfirmation(dialog, 1)
const showReasonDialog = (predefinedReasons) => () =>
  confirm({ predefinedReasons })

export default showReasonDialog
