import { defineMessages } from 'react-intl'

const baseId = 'appointment_wait_times_filter_service_type'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Service types',
  },
})
