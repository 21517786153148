import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import className from 'classnames/bind'
import styles from './TopBar.module.scss'
import messages from './messages'

interface Props {
  initialState?: boolean
  onClick: () => void
}

const cx = className.bind(styles)

const OpenMenuIcon = ({
  width = 18,
  height = 12,
  color = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={cx(
      styles.topBarButton__icon,
      'topBarButton__icon--openMenuToggle'
    )}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-3-6h24v24H-3z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M1 12h16c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1s.45 1 1 1zM0 1c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H1C.45 0 0 .45 0 1z"
      />
    </g>
  </svg>
)

const CloseMenuIcon = ({
  width = 18,
  height = 18,
  color = 'currentColor',
}: SvgProps) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(
      styles.topBarButton__icon,
      'topBarButton__icon--closeMenuToggle'
    )}
  >
    <path
      d="M17.7167 0.284008C17.339 -0.0946693 16.725 -0.0946693 16.3473 0.284008L9.00036 7.63093L1.65344 0.284008C1.27574 -0.0946693 0.662685 -0.0946693 0.284008 0.284008C-0.0946693 0.661717 -0.0946693 1.27574 0.284008 1.65344L7.63093 9.00036L0.284008 16.3473C-0.0946693 16.725 -0.0946693 17.338 0.284008 17.7167C0.662685 18.0944 1.27574 18.0944 1.65344 17.7167L9.00036 10.3698L16.3473 17.7167C16.726 18.0944 17.339 18.0944 17.7167 17.7167C18.0944 17.338 18.0944 16.725 17.7167 16.3473L10.3698 9.00036L17.7167 1.65344C18.0944 1.27574 18.0944 0.661717 17.7167 0.284008Z"
      fill={color}
    />
  </svg>
)

const MenuToggleButton = ({ initialState = false, onClick }: Props) => {
  const cx = className.bind(styles)

  const [buttonActive, setButtonActive] = useState(initialState)

  const handleClick = () => {
    onClick()
    setButtonActive((buttonActive) => !buttonActive)
  }

  return (
    <a
      className={cx('topBarButton__link', {
        'topBarButton--active': buttonActive,
      })}
      onClick={handleClick}
    >
      {buttonActive ? <CloseMenuIcon /> : <OpenMenuIcon />}
      <FormattedMessage {...messages.menu} />
    </a>
  )
}

export default MenuToggleButton
