import { gql } from '@apollo/client'

export default gql`
  query getClinicianVisibility(
    $slot_time_from: String!
    $slot_time_to: String!
    $clinician_id: [ID]
    $clinic_id: [ID]
    $profession: [String]
    $service_type_uuid: [ID]
    $tenancy_plan_id: [ID]
  ) {
    getClinicianVisibility(
      slot_time_from: $slot_time_from
      slot_time_to: $slot_time_to
      clinician_id: $clinician_id
      clinic_id: $clinic_id
      profession: $profession
      service_type_uuid: $service_type_uuid
      tenancy_plan_id: $tenancy_plan_id
    ) {
      clinician_slots {
        clinician {
          id
          name
          profession_name
        }
        slots {
          visibility_slots {
            id
            consultant_id
            slot_time
            slot_size
            admin
            digital_bookable
            physical_bookable
            clinic_id
            rtm_slot
            appointment {
              id
              consultation_type
              member_id
              duration_minutes
              booking_type
              service_type {
                name
                uuid
              }
            }
          }
          clinic {
            id
            timezone_id
            name
          }
        }
      }
    }
  }
`
