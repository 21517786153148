import * as Yup from 'yup'

const serviceTypeValidation = (
  messages,
  formatMessage,
  digitalInvitesEnabled = false,
  memberFacingNameAndDescriptionEnabled = false,
  showUSEhrAppointmentType = false
) =>
  Yup.object().shape({
    name: Yup.string().required(formatMessage(messages.nameError)),
    default_duration_minutes: Yup.number().required(
      formatMessage(messages.durationError)
    ),
    grouping: Yup.string().required(formatMessage(messages.groupingError)),
    ...(digitalInvitesEnabled
      ? {
          allowed_mediums: Yup.array().required(
            formatMessage(messages.allowedMediumError)
          ),
          preferred_medium: Yup.string().required(
            formatMessage(messages.preferredMediumError)
          ),
        }
      : {}),
    ...(memberFacingNameAndDescriptionEnabled
      ? {
          member_description: Yup.string().required(
            formatMessage(messages.memberDescriptionError)
          ),
          member_facing_name: Yup.string().required(
            formatMessage(messages.memberFacingNameError)
          ),
        }
      : {}),
    ...(showUSEhrAppointmentType
      ? {
          us_ehr_appointment_type: Yup.string().required(
            formatMessage(
              messages.serviceTypeUsEhrAppointmentTypeValidationError
            )
          ),
        }
      : {}),
    member_instructions: Yup.string().nullable(),
    service_type_categories: Yup.array()
      .min(1, formatMessage(messages.serviceTypeCategoriesError))
      .ensure(),
  })

export default serviceTypeValidation
