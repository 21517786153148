import React from 'react'
import { Tag, Tooltip } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import messages from './Covid19Tags.messages'

const formatResultType = (resultType?: string, defaultValue?: string) => {
  if (resultType && resultType.match(/covid/)) {
    const formatted = resultType.replace(/covid(_?19)?/, '').replace('_', ' ')

    if (formatted.length) {
      return formatted
    }

    return defaultValue
  }

  if (resultType && resultType.match(/referral/)) {
    return 'referral'
  }

  return null
}

interface Covid19TagsProps {
  tag: string
  'data-testid'?: string
}

const Covid19Tags = ({
  'data-testid': dataTestId = 'covid-19-tags',
  tag,
}: Covid19TagsProps) => {
  const fm = useFormatMessage()
  const formattedTag = formatResultType(tag, fm(messages.covid_19))

  return (
    <Tooltip message={fm(messages.covid_19)}>
      <span data-testid={dataTestId}>
        <Tag color="white-on-red">{formattedTag}</Tag>
      </span>
    </Tooltip>
  )
}

export default Covid19Tags
