import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { nextIndex } from '../forms'

const Pagination = ({
  page,
  size,
  totalElements,
  maxPages,
  onChange,
  className,
  hasEdgeButtons,
  'data-testid': dataTestId,
}) => {
  const handleClick = (page) => () => onChange && onChange(page - 1)
  const currentPage = page + 1
  const length = Math.ceil(totalElements / size)
  const sideSize = Math.floor(maxPages / 2)
  let start = Math.max(1, currentPage - sideSize)
  const end = Math.min(length, start + maxPages - 1)
  start = Math.max(1, end - maxPages + 1)
  let buttons = []
  for (let i = start; i <= end; i++) {
    buttons.push(
      <button
        key={nextIndex()}
        disabled={currentPage === i}
        className={cx(currentPage === i && 'current-page')}
        onClick={handleClick(i)}
      >
        {i}
      </button>
    )
  }
  return (
    <div
      className={cx('pagination', className)}
      {...(dataTestId ? { 'data-testid': dataTestId } : {})}
    >
      {hasEdgeButtons && <button onClick={handleClick(1)}>{'<<'}</button>}
      <button onClick={handleClick(Math.max(1, currentPage - 1))}>{'<'}</button>
      {buttons}
      <button onClick={handleClick(Math.min(length, currentPage + 1))}>
        {'>'}
      </button>
      {hasEdgeButtons && <button onClick={handleClick(length)}>{'>>'}</button>}
    </div>
  )
}

Pagination.defaultProps = {
  loading: false,
  maxPages: 10,
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  maxPages: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  hasEdgeButtons: PropTypes.bool,
}

export default Pagination
