import { DropdownSelectOption } from '@babylon/core-ui'

interface formatCancellationReasonsProps {
  id: string
  reason: string
}

export default function formatCancellationReasons(
  reasons: formatCancellationReasonsProps[]
): DropdownSelectOption[] {
  return reasons.map(({ id, reason }) => ({ key: id, value: reason }))
}
