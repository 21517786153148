const fallbackClinic = {
  id: 'NoClinic',
  name: '',
}

export function groupByClinic(
  data: ClinicianSlots[]
): [ClinicianVisibilityGroup[], number] {
  const clinics = {}
  let total = 0

  data.forEach((clinicianSlot) => {
    clinicianSlot.slots.forEach((slot) => {
      total += 1

      // beacuse in ts the clinic can be null
      // so a fallback is needed to prevent ts issues (& other issues)
      const clinic = slot?.clinic || fallbackClinic

      if (!clinics[clinic?.id]) {
        clinics[clinic?.id] = {
          ...clinic,
          clinicians: [],
        }
      }

      clinics[clinic?.id].clinicians.push({
        ...(clinicianSlot?.clinician || {}),
        slots: slot.visibility_slots,
      })
    })
  })

  return [Object.values(clinics) || [], total]
}

export function groupByServiceType(
  data: ClinicianSlots[]
): [ClinicianVisibilityGroup[], number] {
  let total = 0
  const grouped = {}

  data.forEach((clinicianSlot) => {
    clinicianSlot.slots.forEach((slot) => {
      for (let i = 0; i < slot.visibility_slots.length; i++) {
        const next = slot.visibility_slots[i]
        const serviceTypeName = next?.appointment?.service_type?.name
        const serviceTypeUUID = next?.appointment?.service_type?.uuid

        if (serviceTypeName && !grouped[serviceTypeName]) {
          grouped[serviceTypeName] = {
            name: serviceTypeName,
            id: serviceTypeUUID,
            clinicians: [],
          }
        } else if (serviceTypeName) {
          total += 1
          grouped[serviceTypeName].clinicians.push({
            ...(clinicianSlot?.clinician || {}),
            slots: slot.visibility_slots,
          })

          break
        }
      }
    })
  })

  return [Object.values(grouped) || [], total]
}

export function groupByProfession(
  data: ClinicianSlots[]
): [ClinicianVisibilityGroup[], number] {
  const grouping = {}
  let total = 0

  data.forEach((clinicianSlot) => {
    const profession = clinicianSlot?.clinician?.profession_name

    if (!profession) {
      return
    }

    total += 1

    if (!grouping[profession]) {
      grouping[profession] = {
        name: profession,
        id: profession,
        clinicians: [],
      }
    }

    clinicianSlot.slots.forEach((slot) => {
      grouping[profession].clinicians.push({
        ...(clinicianSlot?.clinician || {}),
        slots: slot.visibility_slots,
      })
    })
  })

  return [Object.values(grouping) || [], total]
}

export function groupByClinician(
  data: ClinicianSlots[]
): [ClinicianVisibilityGroup[], number] {
  const grouping = {
    id: '1',
    clinicians: [],
  } as ClinicianVisibilityGroup

  data.forEach((clinicianSlot) => {
    const allVisibilitySlotsForClinician = (clinicianSlot?.slots || []).reduce(
      (acc, slot): VisibilitySlot[] => [...acc, ...slot?.visibility_slots],
      [] as VisibilitySlot[]
    )

    grouping.clinicians.push({
      ...clinicianSlot.clinician,
      slots: allVisibilitySlotsForClinician,
    })
  })

  return [[grouping], grouping.clinicians.length]
}
