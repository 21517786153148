import React, { Component } from 'react'
import Select from 'react-select'
import cx from 'classnames'

import ListView from '../ListView'
import ActionsView from '../ListView/ActionsView'

import styles from './ListViewWidget.module.scss'

const insertRowWidgets = {
  text: ({ name, placeholder, value, onChange }) => (
    <input
      name={name}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  ),
  select: ({ onChange, ...rest }) => (
    <Select
      clearable={false}
      backspaceRemoves={false}
      {...rest}
      simpleValue
      onChange={(value) => onChange({ target: { value } })}
    />
  ),
}

class InsertBar extends Component {
  constructor(props) {
    super(props)
    this.updateActions(props)
    this.defaultInsertValues = props.columns.reduce(
      (values, { name, type, options }) => {
        switch (type) {
          case 'select':
            values[name] = options[0].value

            break
          default:
            values[name] = ''
        }

        return values
      },
      {}
    )
    this.state = {
      insertValues: this.defaultInsertValues,
      insertAlert: false,
      insertValidationErrors: {},
    }
  }

  updateActions(props) {
    const { columns, onAction } = props
    this.actions = onAction
      ? {
          onAction: (action) => {
            const values = this.state.insertValues
            let hasError = false
            const errors = columns.reduce((errors, { name, validate }) => {
              if (!new RegExp(validate).test(values[name])) {
                errors[name] = true
                hasError = true
              }

              return errors
            }, {})

            if (hasError) {
              this.setState({
                insertAlert: true,
                insertValidationErrors: errors,
              })
              setTimeout(
                () =>
                  this.setState({
                    insertAlert: false,
                  }),
                1
              )
            } else {
              this.setState({ insertValues: this.defaultInsertValues })
              onAction(action, values)
            }
          },
        }
      : {}
  }

  render() {
    const { columns, toolbar } = this.props
    const { insertValidationErrors, insertValues, insertAlert } = this.state

    return (
      <tr className={cx(styles.insertRow, insertAlert && styles.insertAlert)}>
        {columns.map((column) => {
          const { name, type } = column

          return (
            <td
              key={name}
              className={cx(
                insertValidationErrors[name] && styles.validationError
              )}
            >
              {insertRowWidgets[type]({
                ...column,
                value: insertValues[name],
                onChange: (event) => {
                  this.setState({
                    insertValues: {
                      ...insertValues,
                      [name]: event.target.value,
                    },
                    insertValidationErrors: {
                      ...insertValidationErrors,
                      [name]: false,
                    },
                  })
                },
              })}
            </td>
          )
        })}
        {toolbar ? (
          <td className={styles.toolbar}>
            <ActionsView actions={this.actions} buttons={toolbar} />
          </td>
        ) : null}
      </tr>
    )
  }
}

const ListViewWidget = ({ name, label, value, insertRow, ...rest }) => {
  const footer = insertRow ? (
    <InsertBar {...insertRow} onAction={rest.actions.onAction} />
  ) : null

  return (
    <div className="babylon-component-library__asFormControl__control">
      <label className="label">{label}</label>
      <div className={styles.listViewWrapper}>
        <ListView {...rest} data={value} footer={footer} />
      </div>
    </div>
  )
}

export default ListViewWidget
