import React from 'react'
import { useField } from 'formik'
import { Select } from '@babylon/medkit'
import ErrorsMessages from '@babylon/medkit/src/Forms/ErrorMessages'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { AppointmentAnalyticsModuleName } from '../../analytics/types'

interface AppointmentTypeSelectProps {
  className?: string
  professions: Profession[]
  label: string
  placeholder?: string
  setSelectedProfession: (profession: Profession | undefined) => void
}

const getProfessionOptions = (professions) =>
  professions.map((profession) => ({
    value: profession.id,
    label: profession.name,
  }))

export default function AppointmentTypeSelect({
  className,
  professions = [],
  label,
  placeholder,
  setSelectedProfession,
}: AppointmentTypeSelectProps) {
  const [{ value }, meta, { setValue }] = useField('appointment_type')
  const [, , { setValue: setDurationValue }] = useField('duration_minutes')

  const { trackClick } = useTrackClick({
    moduleName: AppointmentAnalyticsModuleName.digitalInvite,
  })

  const professionsOptions = getProfessionOptions(professions)
  const error = meta.touched && meta.error

  return (
    <Select
      label={label}
      options={professionsOptions}
      placeholder={placeholder}
      name="appointment_type"
      onChange={(event) => {
        const selectedOption = event.currentTarget.value
        setValue(selectedOption)
        const selectedAppointmentType = professions.find(
          ({ id }) => id === selectedOption
        )
        setDurationValue(selectedAppointmentType?.default_slot_duration_minutes)
        setSelectedProfession(selectedAppointmentType)

        trackClick({
          elementName: 'appointment-type-' + selectedAppointmentType?.name,
          elementType: TrackingElementType.dropdown,
        })
      }}
      className={className}
      value={value}
      ErrorMessages={() =>
        error ? <ErrorsMessages formErrors={[error]} /> : null
      }
      error={!!error}
    />
  )
}
