import React from 'react'
import { useQuery } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import InfiniteScroll from 'react-infinite-scroller'
import { Spinner } from '@babylon/core-ui'
import { ArrowLeft } from '@babylon/icons'
import ErrorBlock from '../../ServiceErrors'
import Messages from '../../Conversations/Messages'
import { MESSAGES_QUERY } from './queries'
import intlMessages from './Messages.messages'
import styles from './Messages.module.scss'
import { Member } from '../../../app'

interface MessagesContentProps {
  conversationId?: string
  heading?: React.ReactNode
  userId?: string
  handleBack: () => void
  scrollParentRef: HTMLElement | null
  patient: Member
}

export default ({
  userId,
  heading,
  conversationId,
  scrollParentRef,
  handleBack,
  patient,
}: MessagesContentProps) => {
  const { error, data, loading, fetchMore } = useQuery(MESSAGES_QUERY, {
    variables: {
      conversationId,
      userId,
      order: 'asc',
      limit: 20,
      offset: 0,
    },
    skip: !conversationId || !userId,
  })

  const { messages, more } = data?.chatscriptMessages || {}

  if (error) {
    return (
      <div className={styles.Messages__error}>
        <ErrorBlock>{error.graphQLErrors.map((err) => err.message)}</ErrorBlock>
      </div>
    )
  }

  return (
    <div data-testid="messages">
      <div>
        <button
          type="button"
          data-testid="back-to-list-button"
          className={styles.Messages__backToList}
          onClick={handleBack}
        >
          <ArrowLeft />
          <div className={styles.Messages__backToList_label}>Back to List</div>
        </button>
        {heading}
      </div>
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        loader={
          <Spinner key={0} className={styles.Messages__spinner} size="medium" />
        }
        loadMore={() => {
          fetchMore({
            variables: {
              conversationId,
              userId,
              order: 'asc',
              limit: 20,
              offset: messages?.length || 0,
            },
            updateQuery: (prev: any, { fetchMoreResult }: any) => {
              const prevMessages = prev.chatscriptMessages.messages
              const nextMessages = fetchMoreResult.chatscriptMessages.messages
              return {
                chatscriptMessages: {
                  ...prev.chatscriptMessages,
                  more: fetchMoreResult.chatscriptMessages.more,
                  messages: [...prevMessages, ...nextMessages],
                },
              }
            },
          })
        }}
        hasMore={more}
        useWindow={!scrollParentRef}
        threshold={100}
        getScrollParent={() => scrollParentRef || null}
      >
        {loading || messages?.length ? (
          <Messages patient={patient} messages={messages || []} />
        ) : (
          <p>
            <FormattedMessage {...intlMessages.empty} />
          </p>
        )}
      </InfiniteScroll>
    </div>
  )
}
