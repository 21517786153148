import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Icon from '@/components/Icon'

import styles from './styles.module.scss'

let lastKey = 0

const Pagination = ({
  loading,
  page,
  size,
  totalElements,
  maxPages,
  onPageClick,
  className,
}) => {
  const onClick = (page) => () => onPageClick && onPageClick(page - 1)
  const currentPage = page + 1
  const length = Math.ceil(totalElements / size)
  const sideSize = Math.floor(maxPages / 2)
  let start = Math.max(1, currentPage - sideSize)
  const end = Math.min(length, start + maxPages - 1)
  start = Math.max(1, end - maxPages + 1)
  const buttons = []

  for (let i = start; i <= end; i++) {
    buttons.push(
      <button
        key={lastKey++}
        disabled={currentPage === i}
        className={currentPage === i ? styles.currentPage : null}
        onClick={onClick(i)}
      >
        {i}
      </button>
    )
  }

  return (
    <div className={cx(styles.pagination, className)}>
      {loading ? <Icon className={styles.spinner} name="spinner" /> : null}
      <button onClick={onClick(1)}>{'<<'}</button>
      <button onClick={onClick(Math.max(1, currentPage - 1))}>{'<'}</button>
      {buttons}
      <button onClick={onClick(Math.min(length, currentPage + 1))}>
        {'>'}
      </button>
      <button onClick={onClick(length)}>{'>>'}</button>
    </div>
  )
}

Pagination.defaultProps = {
  loading: false,
  maxPages: 10,
  className: null,
}

Pagination.propTypes = {
  loading: PropTypes.bool,
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  maxPages: PropTypes.number,
  onPageClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default Pagination
