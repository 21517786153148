import React from 'react'
import { ApolloError } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import TimelineEvents from './TimelineEvents'
import messages from './Timeline.messages'
import { Consultation } from '../types'

export interface UpcomingActivitiesProps {
  loading: boolean
  patientId: string
  onCancelAppointmentClick: (appointmentId: string) => void
  consultations?: Consultation[]
  error?: ApolloError
}

const UpcomingActivities = ({
  consultations,
  error,
  loading,
  patientId,
  onCancelAppointmentClick,
}: UpcomingActivitiesProps) => {
  const fm = useFormatMessage()
  const hasConsultations = !!consultations && consultations.length > 0

  const title = fm(
    messages[
      hasConsultations ? 'upcoming_activities' : 'no_upcoming_activities'
    ]
  )

  return (
    <TimelineEvents
      events={consultations || []}
      error={error}
      info={fm(messages.upcoming_activities_tooltip)}
      isUpcoming
      loading={loading}
      patientId={patientId}
      title={title}
      onCancelAppointmentClick={onCancelAppointmentClick}
    />
  )
}

export default UpcomingActivities
