import React from 'react'
import { useField } from 'formik'
import { Select } from '@babylon/medkit'

import {
  getOptionsFromDefaultDuration,
  setRecommendedOption,
  mapDurationsToOptions,
} from '../../AppointmentFields/DurationDropdownUtils'

interface DurationSelectorProps {
  className?: string
  defaultDuration?: number
  recommendDuration?: boolean
  helpText?: string
  onClickTrack?: (duration: string) => void
  alternativeDurationMinutes: number[]
  disabled?: boolean
}

function getOptions(
  defaultDuration,
  recommendDuration,
  alternativeDurationMinutes: number[] = []
) {
  const durationOptions = getOptionsFromDefaultDuration(defaultDuration).filter(
    (durationOption) =>
      !alternativeDurationMinutes.includes(durationOption.value as number)
  )

  const alternativeDurationOptions = mapDurationsToOptions(
    alternativeDurationMinutes
  )

  const options = [...durationOptions, ...alternativeDurationOptions]

  return recommendDuration
    ? setRecommendedOption(options, defaultDuration)
    : options
}

export default function DurationSelector({
  className,
  defaultDuration = 5,
  recommendDuration,
  helpText,
  onClickTrack = () => {},
  alternativeDurationMinutes = [],
  disabled = false,
}: DurationSelectorProps) {
  const [{ value }, , { setValue }] = useField('duration_minutes')

  const dropdownOptions = getOptions(
    defaultDuration,
    recommendDuration,
    alternativeDurationMinutes
  )

  return (
    <Select
      className={className}
      label="Duration"
      options={dropdownOptions.map((option) => ({
        ...option,
        value: `${option.value}`,
      }))}
      value={value || ''}
      onChange={(e) => {
        setValue(e.currentTarget.value)
        onClickTrack(e.currentTarget.value)
      }}
      helpText={helpText}
      disabled={disabled}
    />
  )
}
