/* eslint-disable camelcase */
import React, { FC } from 'react'
import compose from 'lodash/fp/compose'
import filter from 'lodash/fp/filter'
import get from 'lodash/fp/get'
import head from 'lodash/fp/head'
import { Grid, Cell } from '@babylon/core-ui'
import {
  AccountTags,
  IdentityVerificationStatus,
  PatientName,
} from '../../../../..'
import { TagList, ConsumerNetworkNames } from '../Tags'
import styles from './styles.module.scss'
import { useSearchFeaturesContext } from '../../../SearchFeaturesContext'

export type HeadlineProps = Member

const getPreferredConsumerNetwork = (
  consumerNetworks: ConsumerNetworkMembership[] | null
) =>
  compose(
    // @ts-ignore
    get('consumer_network'),
    head,
    filter<ConsumerNetworkMembership>(
      (consumerNetwork) =>
        consumerNetwork.is_preferred && consumerNetwork.active
    )
  )(consumerNetworks || [])

const Headline: FC<HeadlineProps> = ({
  consumer_networks,
  family_accounts_member_ids,
  family_accounts_owner,
  first_name,
  identity_check,
  last_name,
  minor,
  public_healthcare_admission,
  queued_for_activation,
  id,
}) => {
  const {
    enableResultTags,
    enableResultConsumerNetworks,
  } = useSearchFeaturesContext()

  return (
    <div className={styles.headline} data-testid="search-item-headline">
      <Grid columns={12} columnGap={16}>
        <Cell width={7}>
          {enableResultTags && (
            <AccountTags
              familyAccount={
                family_accounts_member_ids &&
                !!family_accounts_member_ids.length
              }
              familyAccountOwner={family_accounts_owner}
              minor={minor}
              idVerificationStatus={
                identity_check &&
                (identity_check.status as IdentityVerificationStatus)
              }
            />
          )}
          <PatientName
            firstName={first_name}
            lastName={last_name}
            id={id}
            dataTestId="search-result-fullName-link"
          />
        </Cell>
        <Cell width={2}>
          {enableResultConsumerNetworks && (
            <TagList>
              <ConsumerNetworkNames
                queuedForActivation={queued_for_activation}
                publicHealthcareAdmission={public_healthcare_admission}
                list={[getPreferredConsumerNetwork(consumer_networks)]}
              />
            </TagList>
          )}
        </Cell>
      </Grid>
    </div>
  )
}

export default Headline
