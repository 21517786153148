import { addMinutes, format, startOfDay, endOfDay } from 'date-fns'
import { getShiftSlotType } from '../../ShiftSlot/ShiftSlotTypes'
import { ShiftSlotProps } from '../../ShiftSlot/ShiftSlot'
import { AvailabilitySlotType } from '../GetClinicianAvailability'

export const DEFAULT_SLOT_DURATION = 5

export interface AppointmentSlotType
  extends AvailabilitySlotType,
    Pick<ShiftSlotProps, 'type'> {}

export default function formatAvailabilitySlots(availability, date) {
  const slotsPerTime = availability.reduce((acc, slot) => {
    const time = new Date(slot.time)
    acc[format(time, 'HH:mm')] = {
      ...slot,
      time,
    }

    return acc
  }, {})

  const slots: AppointmentSlotType[] = []
  let currentTime = startOfDay(new Date(date))
  const endTime = endOfDay(new Date(date))

  const appointmentsDuration = availability.reduce((acc, slot) => {
    if (slot?.appointment?.id) {
      const appointmentId = slot.appointment.id
      const slotDuration = slot.duration_minutes

      acc[appointmentId] = !acc[appointmentId]
        ? slotDuration
        : acc[appointmentId] + slotDuration
    }

    return acc
  }, {})

  while (currentTime < endTime) {
    const currentTimeOfDay = format(new Date(currentTime), 'HH:mm')
    const clinicianSlot = {
      // default duration is 5 minutes
      duration_minutes: DEFAULT_SLOT_DURATION,
      time: new Date(currentTime),
      ...slotsPerTime[currentTimeOfDay],
    }

    clinicianSlot.type = getShiftSlotType(slotsPerTime[currentTimeOfDay])

    const duration = appointmentsDuration[clinicianSlot?.appointment?.id]

    if (duration) {
      clinicianSlot.duration_minutes = duration
    }

    slots.push(clinicianSlot)

    currentTime = addMinutes(
      new Date(currentTime),
      clinicianSlot.duration_minutes
    )
  }

  return slots
}
