import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { envUrl } from '@babylon/babylon-env'
import { logException } from '../../../../..'
import { ISSUE_USER_ID_TOKEN } from './queries'

export const DAY_TO_DAY_URL = envUrl('DAY_TO_DAY_URL')

export const useDayToDayIdTokenUrl = (
  isFlagEnabled: boolean,
  isAuthorized: boolean,
  patientUuid: string
) => {
  const [dayToDayIdTokenUrl, setDayToDayUrl] = useState<URL | undefined>(
    undefined
  )
  const [issueUserIdToken] = useMutation(ISSUE_USER_ID_TOKEN, {
    context: { clientName: 'platform-gateway' },
    variables: { patientId: patientUuid, audience: DAY_TO_DAY_URL },
    onError: (error) => logException(error),
  })

  useEffect(() => {
    if (!DAY_TO_DAY_URL) {
      return
    }

    const getIdTokenUrl = async () => {
      try {
        const { data } = await issueUserIdToken()
        const idToken = data.issuePatientIdToken.token
        const url = new URL(DAY_TO_DAY_URL)
        url.hash = `id_token=${encodeURIComponent(idToken)}`

        setDayToDayUrl(url)
      } catch (err) {
        logException(err as Error)
      }
    }

    if (isFlagEnabled && isAuthorized) {
      getIdTokenUrl()
    }
  }, [isFlagEnabled, issueUserIdToken, isAuthorized])

  return dayToDayIdTokenUrl
}
