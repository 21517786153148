import React from 'react'

const Admin = ({
  width = 24,
  height = 18,
  color = 'currentColor',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <g fillRule="evenodd" transform="rotate(90 10.5 12.5)">
      <path
        fill={color}
        d="M9.327 15.008c.985 0 1.821.633 2.127 1.513h8.79a.75.75 0 0 1 0 1.5h-8.8a2.25 2.25 0 1 1-2.117-3.013zm-3.778 1.513a.75.75 0 0 1 0 1.5H1.733a.75.75 0 1 1 0-1.5H5.55zm3.778-.013a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5zm4.346-8.813c1 0 1.847.652 2.14 1.554l4.424.001a.75.75 0 0 1 0 1.5h-4.462a2.25 2.25 0 1 1-2.102-3.055zM9.836 9.25a.75.75 0 0 1 0 1.5H1.782a.75.75 0 1 1 0-1.5h8.054zm3.837-.055a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5zM6.393.531c.995 0 1.84.648 2.136 1.544l11.69.001a.75.75 0 0 1 0 1.5H8.498A2.25 2.25 0 1 1 6.392.531zM2.553 2.076a.75.75 0 1 1 0 1.5h-.806a.75.75 0 1 1 0-1.5h.806zm3.838-.045a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5z"
      />
    </g>
  </svg>
)

export default Admin
