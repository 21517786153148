import { QueryHookOptions } from '@apollo/client'

import { useGetPatientConsumerNetworkQuery } from '../../queries/PatientConsumerNetworksQuery.middleware.hooks'

interface useIsOnGPAtHandNetworkReturnProps {
  isOnGPAtHandNetwork: boolean
  loading: boolean
}

export default function useIsOnGPAtHandNetwork(
  memberId: string,
  options?: Pick<QueryHookOptions, 'skip'>
): useIsOnGPAtHandNetworkReturnProps {
  const { data, loading } = useGetPatientConsumerNetworkQuery({
    variables: {
      patientId: memberId,
    },
    skip: options?.skip,
  })

  const isOnGPAtHandNetwork = data?.patientConsumerNetworks?.find(
    (consumerNetwork) =>
      consumerNetwork &&
      consumerNetwork.is_preferred &&
      consumerNetwork.active &&
      consumerNetwork.gp_at_hand
  )

  return { isOnGPAtHandNetwork: !!isOnGPAtHandNetwork, loading }
}
