import React from 'react'
import moment from 'moment'

import { Form, Button } from '@babylon/babylon-forms/forms'
import {
  Text,
  CheckboxGroup,
  DateRangePicker,
} from '@babylon/babylon-forms/widgets'

import statuses from './statuses.json'
import presets from './statusPresets.json'

import styles from './styles.module.scss'

const visibleStatuses = statuses.filter((status) => !status.hidden)

const SmallButton = (props) => <Button small outline type="button" {...props} />

const Filters = ({ onChange, store, handleFilterReset, handlePresetClick }) => (
  <Form store={store} className={styles.filters} onSubmit={onChange}>
    <div className={styles.filterContent}>
      <div>
        <Text label="Patient ID" name="patientId" placeholder="e.g.: 1" />
        <Text
          label="Patient first name"
          name="firstName"
          placeholder="e.g.: John"
        />
        <Text
          label="Patient last name"
          name="lastName"
          placeholder="e.g.: Smith"
        />
        <Text
          label="Pharmacy"
          name="pharmacyDetails"
          placeholder="e.g.: Boots"
        />
      </div>
      <div>
        <DateRangePicker
          label="Date range"
          startName="startDate"
          endName="endDate"
          isOutsideRange={(date) => date.isAfter()}
          initialVisibleMonth={() => moment().subtract(1, 'month')}
          displayFormat="DD/MM/YYYY"
          showClearDates
          small
        />
        <Text
          label="Prescription ID"
          name="prescriptionId"
          placeholder="e.g.: 123e4567-e89b-12d3-a456-426655440000"
        />
        <Text
          label="Consultant ID"
          name="consultantId"
          placeholder="e.g.: bc966c50-c53b-4757-9cd3-d456b7ac18e1"
        />
        <Text
          label="Consultant Full Name"
          name="consultantName"
          placeholder="e.g.: John Lewis"
        />
      </div>
      <fieldset className={styles.statusFilter}>
        <legend>Statuses</legend>
        <div className={styles.statusFilterToolbar}>
          <SmallButton onClick={handlePresetClick('actionNeeded')}>
            Action needed
          </SmallButton>
          <SmallButton onClick={handlePresetClick('toBePrinted')}>
            To be printed
          </SmallButton>
          <SmallButton onClick={handlePresetClick('archived')}>
            Archived
          </SmallButton>
          <SmallButton onClick={handlePresetClick('none')}>None</SmallButton>
          <SmallButton onClick={handlePresetClick('all')}>All</SmallButton>
        </div>
        <CheckboxGroup
          name="statuses"
          config={visibleStatuses}
          onChange={(event, name, value) => {
            store.setState({
              statuses: {
                ...store.state.statuses,
                ...presets.hidden,
                [name]: value,
              },
            })
          }}
        />
      </fieldset>
    </div>
    <div className={styles.buttonRow}>
      <SmallButton onClick={handleFilterReset}>Reset all filters</SmallButton>
      <Button small>Apply filter</Button>
    </div>
  </Form>
)

export default Filters
