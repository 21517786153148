import React from 'react'
import { format } from 'date-fns'
import { Text } from '@babylon/core-ui'
import { formatLocation } from './DurationSelectorUtils'
import styles from './DurationSelector.module.css'

interface FormattedLocationWithTimezoneProps {
  clinic: Practice | null
  date: Date
}

export const FormattedLocationWithTimezone = ({
  clinic,
  date,
}: FormattedLocationWithTimezoneProps) =>
  clinic !== null ? (
    <Text
      color="grey-type"
      tag="span"
      uppercase
      className={styles.locationText}
      align="right"
    >
      {formatLocation(clinic, date)}
    </Text>
  ) : null

interface FormattedDateRangeProps {
  date: Date
  clinic: Practice | null
  formattedDate: string
}

const FormattedDateRange = ({
  date,
  formattedDate,
  clinic,
}: FormattedDateRangeProps) => (
  <Text
    bold
    tag="span"
    color="black-type"
    size="large"
    className={styles.dateRange}
  >
    {formattedDate}
    <br />
    <FormattedLocationWithTimezone clinic={clinic} date={date} />
  </Text>
)

interface FormattedDayViewProps {
  date: Date
  clinic: Practice | null
}

export const FormattedDayView = ({ date, clinic }: FormattedDayViewProps) => (
  <FormattedDateRange
    date={date}
    formattedDate={format(date, 'do MMM yyyy')}
    clinic={clinic}
  />
)

interface FormattedWeekViewProps {
  startDate: Date
  endDate: Date
  clinic: Practice | null
}

export const FormattedWeekView = ({
  startDate,
  endDate,
  clinic,
}: FormattedWeekViewProps) => (
  <FormattedDateRange
    date={startDate}
    formattedDate={`${format(startDate, 'dd MMM yyyy')} - ${format(
      endDate,
      'dd MMM yyyy'
    )}`}
    clinic={clinic}
  />
)

export default FormattedDateRange
