import { defineMessages } from 'react-intl'

const baseId = 'members_search.platform_gateway_search'

export default defineMessages({
  access_denied: {
    id: `${baseId}.access_denied`,
    defaultMessage: 'Access denied.',
  },
})
