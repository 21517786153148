import React from 'react'
import 'react-dates/initialize'
import { DateRangePicker as ReactDates } from 'react-dates'
import cx from 'classnames'

import {
  withLabel,
  withWrapper,
  withFormContext,
  compose,
  result,
} from '../forms'

export const nextId = ((id) => () => ++id)(0) // eslint-disable-line no-plusplus

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props)
    const id = nextId()
    this.startId = `drpStartId_${id}`
    this.endId = `drpEndId_${id}`
    this.state = { focusedInput: null }
    this.updateCallbacks(props)
  }

  // TO-DO: fix unsafe method:
  // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { props } = this

    if (
      props['data-context'] !== nextProps['data-context'] ||
      props.onChange !== nextProps.onChange ||
      props.startName !== nextProps.startName ||
      props.endName !== nextProps.endName
    ) {
      this.updateCallbacks(nextProps)
    }
  }

  updateCallbacks(props) {
    const { 'data-context': ctx, onChange, startName, endName } = props

    if (onChange) {
      this.handleChange = (dataRange) => {
        onChange(null, dataRange)
      }
    } else if (ctx && ctx.store) {
      this.handleChange = (dataRange) => {
        onChange && onChange(null, dataRange)
        ctx.store.setState({
          [startName]: dataRange.startDate,
          [endName]: dataRange.endDate,
        })
      }
    }
  }

  render() {
    const {
      'data-context': ctx,
      startName,
      startValue,
      endName,
      endValue,
      id,
      className,
      onChange,
      ...rest
    } = this.props
    let startDate
    let endDate

    if (ctx && ctx.store) {
      const { state } = ctx.store
      startDate = startValue ? result(startValue, state) : state[startName]
      endDate = endValue ? result(endValue, state) : state[endName]
    }

    return (
      <span className={cx('date-range-picker', className)}>
        <ReactDates
          startDate={startDate}
          startDateId={this.startId}
          endDate={endDate}
          endDateId={this.endId}
          onDatesChange={this.handleChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
          {...rest}
        />
      </span>
    )
  }
}

export default compose(withLabel, withWrapper, withFormContext)(DateRangePicker)
