import { defineMessages } from 'react-intl'

const baseId = 'appointment_wait_time_table'

export default defineMessages({
  statusTableHeading: {
    id: `${baseId}.status_table_heading`,
    defaultMessage: 'Status',
  },
  consumerNetworkTableHeading: {
    id: `${baseId}.consumer_network_table_heading`,
    defaultMessage: 'Consumer network',
  },
  serviceTypeTableHeading: {
    id: `${baseId}.service_type_table_heading`,
    defaultMessage: 'Service type',
  },
  waitingTimeTableHeading: {
    id: `${baseId}.waiting_time_table_heading`,
    defaultMessage: 'Waiting time (next avail. appointment)',
  },
  tableTitleLabel: {
    id: `${baseId}_table_title_label`,
    defaultMessage: 'Appointment Wait Time Table',
  },
})
