import { useEffect } from 'react'

type Props = {
  onFocus: () => void
  onBlur: () => void
}

const useWindowFocus = ({ onFocus, onBlur }: Props) => {
  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    // Calls onFocus when the window first loads
    onFocus()

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [onBlur, onFocus])
}
export default useWindowFocus
