import { Snackbar } from '@babylon/core-ui'
import React, { useState } from 'react'
import { useProductConfig } from '@babylon/product-config'
import { useFormatMessage } from '@babylon/intl'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import styles from './styles.module.scss'
import messages from './SwitchSearchBanner.messages'
import { MemberOpsModuleName } from '../..'

export interface SwitchSearchBannerProps {
  onClick: VoidFunction
  bannerOnNewSearch?: boolean
}

const SwitchSearchBanner = ({
  onClick,
  bannerOnNewSearch,
}: SwitchSearchBannerProps) => {
  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.membersSearchView,
  })

  const [showBanner, setShowBanner] = useState(true)

  const { getProp } = useProductConfig()
  const platformGatewaySearchEnabled = getProp(
    'memberOperations',
    'platformGatewaySearchEnabled'
  )
  const switchSearchBannerEnabled = getProp(
    'memberOperations',
    'switchSearchBannerEnabled'
  )

  const shouldShowBanner =
    platformGatewaySearchEnabled && switchSearchBannerEnabled

  return showBanner && shouldShowBanner ? (
    <Snackbar
      open
      fill
      intent="warning"
      fixed={false}
      onClose={() => setShowBanner(false)}
      className={styles.Banner}
    >
      <button
        type="button"
        className={styles.BannerButton}
        onClick={() => {
          onClick()
          trackClick({
            elementName: `${
              bannerOnNewSearch
                ? 'switch-to-old-search-btn'
                : 'switch-to-new-search-btn'
            }`,
            elementType: TrackingElementType.button,
          })
        }}
      >
        {bannerOnNewSearch
          ? fm(messages.new_search_switch_banner)
          : fm(messages.old_search_switch_banner)}
      </button>
    </Snackbar>
  ) : null
}

export default SwitchSearchBanner
