import { defineMessages } from 'react-intl'

const baseId = 'related_person_card'

export default defineMessages({
  send_registration_mail: {
    id: `${baseId}.send_registration_mail`,
    defaultMessage: 'Send registration email',
  },
  remove_guardian: {
    id: `${baseId}.remove_guardian`,
    defaultMessage: 'Remove guardian',
  },
  remove_dependent: {
    id: `${baseId}.remove_dependent`,
    defaultMessage: 'Remove dependent',
  },
  no_related_persons_exist: {
    id: `${baseId}.no_related_persons_exist`,
    defaultMessage: 'The member does not have any related persons',
  },
  error_retrieving_related_persons: {
    id: `${baseId}.error_retrieving_related_persons`,
    defaultMessage: 'Error retrieving related persons for the member',
  },
  error_loading_related_person: {
    id: `${baseId}.error_loading_related_person`,
    defaultMessage: 'Error loading patient with UUID: {uuid}',
  },
  minor_tag_label: {
    id: `${baseId}.minor_tag_label`,
    defaultMessage: 'MINOR',
  },
  preregistered_tag_label: {
    id: `${baseId}.preregistered_tag_label`,
    defaultMessage: 'PRE-REGISTERED',
  },
  no_related_person_found: {
    id: `${baseId}.no_data_available`,
    defaultMessage: 'No patient found with UUID: {uuid}',
  },
  pending_registration_tag_label: {
    id: `${baseId}.pending_registration_tag_label`,
    defaultMessage: 'Pending registration',
  },
  family_tag_label: {
    id: `${baseId}.family_tag_label`,
    defaultMessage: 'Family',
  },
})
