import React from 'react'
import { Link } from 'react-router-dom'
import { Snackbar, Text } from '@babylon/core-ui'
import { faCheckCircle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './PageMessage.module.css'

export type PageMessageType = 'success' | 'warning'

interface PageMessageProps {
  type: PageMessageType
  onClose: () => void
}

export default function PageMessage({ type, onClose }: PageMessageProps) {
  if (type === 'warning') {
    return (
      <Snackbar
        data-testid="reallocation-warning-page-message"
        icon={<FontAwesomeIcon icon={faInfoCircle} />}
        open
        intent="warning"
        onClose={onClose}
      >
        <Text className={styles.PageMessageWarning} bold>
          Problem reallocating some appointments
        </Text>
        <Link to="/admin/orphaned_appointments">
          <Text color="white" bold>
            Manage
          </Text>
        </Link>
      </Snackbar>
    )
  }

  return (
    <Snackbar
      data-testid="reallocation-success-page-message"
      icon={<FontAwesomeIcon icon={faCheckCircle} />}
      open
      onClose={onClose}
      message="Changes saved"
    />
  )
}
