import React from 'react'
import { useMutation } from '@apollo/client'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { Formik, Form } from 'formik'
import { FormattedMessage } from 'react-intl'
import {
  FormikInput,
  FormikRadioGroup,
  Grid,
  Radio,
  Theme,
} from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { ButtonVariant, ButtonSimple } from '@babylon/medkit'
import {
  logException,
  useSnackBar,
  MemberOpsModuleName,
} from '../../../../../../..'
import messages from './messages'
import styles from './RedemptionForm.module.scss'
import { APPLY_REDEMPTIONS } from './queries'

type Props = {
  patientId: string
  onRedemption: () => void
}

const RedemptionForm = ({ patientId, onRedemption }: Props) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()
  const { setSnackbarMessage } = useSnackBar()

  const [applyRedemptions] = useMutation(APPLY_REDEMPTIONS)

  const handleSubmit = async ({
    code,
    redemptionType,
  }: {
    code: string
    redemptionType: string
  }) => {
    try {
      const resp: any = await applyRedemptions({
        variables: { code, patientId, redemptionType },
      })

      onRedemption()

      resp.data.applyRedemptionV1 === 1
        ? setSnackbarMessage(fm(messages.redemption_success), null, 'success')
        : setSnackbarMessage(fm(messages.redemption_error), null, 'error')
    } catch (e) {
      logException(e)
      setSnackbarMessage(fm(messages.redemption_error), null, 'error')
    }
  }

  return (
    <Formik
      initialValues={{
        redemptionType: 'membership',
        code: '',
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className={styles.RedeemCodeForm}>
          <Theme className={styles.RedeemCodeForm__radioGroup}>
            <FormikRadioGroup
              label={
                <h2 className={styles.RedeemCodeForm__title}>
                  <FormattedMessage {...messages.redeem_code} />
                </h2>
              }
              name="redemptionType"
              id="redemptionType"
              className={styles.RedeemCodeForm__RedemptionType}
              data-testid="redeem-code-radio-group"
            >
              <Radio
                value="membership"
                data-testid="membership-radio"
                className={styles.RedeemCodeForm__radio}
              >
                <FormattedMessage {...messages.radio_membership} />
              </Radio>
              <Radio
                value="promotion"
                data-testid="promotion-radio"
                className={styles.RedeemCodeForm__radio}
              >
                <FormattedMessage {...messages.radio_promotional} />
              </Radio>
            </FormikRadioGroup>
          </Theme>
          <div className={styles.RedeemCodeForm__CodeInputWrapper}>
            <Grid columns={2} gap={24}>
              <FormikInput
                type="text"
                name="code"
                label={fm(messages.enter_code)}
                className={styles.RedeemCodeForm__CodeInput}
                data-testid="code-text-input"
              />
              <ButtonSimple
                type="submit"
                data-testid="redeem-code-submit"
                className={styles.RedeemCodeForm__cta}
                variant={ButtonVariant.primary}
                onClick={() => {
                  trackClick({
                    elementName: 'submit-redemption-code-btn',
                    elementType: TrackingElementType.button,
                  })
                }}
              >
                <FormattedMessage {...messages.confirm_code} />
              </ButtonSimple>
            </Grid>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default RedemptionForm
