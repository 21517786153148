import React from 'react'
import { Checkbox, Text } from '@babylon/core-ui'
import { useField } from 'formik'

const IsTranslationLanguageRequired = () => {
  const [{ value }, , { setValue }] = useField('language_required')

  return (
    <Checkbox
      testId="is-translation-language-required"
      onChange={(e) => setValue((e.target as HTMLInputElement).checked)}
      checked={value}
    >
      <Text bold color="grey-type">
        Translator
      </Text>
    </Checkbox>
  )
}

export default IsTranslationLanguageRequired
