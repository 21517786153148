import React from 'react'
import pick from 'lodash/pick'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import * as Access from '@babylon/web-platform-access'
import { Alert } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'
import { PATIENT_QUERY } from '../../../queries/timeline/queries'
import { memberEditFields } from '../types'
import MemberEdit from './MemberEdit'
import { fullPatientFields } from '../../../queries'
import { CONSUMER_NETWORKS_QUERY, REGIONS, STATES_QUERY } from './queries'

export interface MemberParams {
  id: string
}

export interface MemberEditContainerProps {
  patientId: string
}

const MemberEditContainer = ({ patientId }: MemberEditContainerProps) => {
  const { getBlob } = useProductConfig()
  const { memberProfileBupaTagEnabled } = getBlob('memberOperations')
  const {
    data: patientData,
    error: patientError,
    loading: patientLoading,
  } = useQuery(PATIENT_QUERY(fullPatientFields), {
    variables: { patientId },
    fetchPolicy: 'network-only',
  })

  const {
    data: consumerNetworkData,
    loading: consumerNetworkLoading,
  } = useQuery(CONSUMER_NETWORKS_QUERY, {
    variables: { patientId },
    fetchPolicy: 'network-only',
  })

  const { data: regionsData, loading: regionsLoading } = useQuery(REGIONS)
  const { data: { regionStates } = {} } = useQuery(STATES_QUERY, {
    variables: { regionIso: patientData?.patient?.region.iso_code },
    skip: !patientData?.patient.region.iso_code,
  })

  if (patientLoading || consumerNetworkLoading || regionsLoading) {
    return <div>Loading...</div>
  }

  if (patientError) {
    return (
      <Alert intent="error" title="Error">
        <p>{patientError.message}</p>
      </Alert>
    )
  }

  const { patient } = patientData
  const patientEditData = pick(patient, memberEditFields)
  const isPublicHealthcareDetailsDisabled =
    !patient.public_healthcare_admission ||
    !patient.public_healthcare_admission.id
  const useAccountOwnersEmergencyDetails =
    patient.minor ||
    !patient.emergency_contact_details ||
    !patient.emergency_contact_details.length

  const disableMemberEditForm =
    !!memberProfileBupaTagEnabled && patient.profile_locked

  return (
    <MemberEdit
      consumerNetworks={consumerNetworkData.patientConsumerNetworks}
      member={patientEditData}
      regions={regionsData.regions}
      states={regionStates || []}
      profileLocks={patient.profile_locks}
      isPublicHealthcareDetailsDisabled={isPublicHealthcareDetailsDisabled}
      useAccountOwnersEmergencyDetails={useAccountOwnersEmergencyDetails}
      disableMemberEditForm={disableMemberEditForm}
    />
  )
}

export default () => {
  const { id: patientId } = useParams<{ id: string }>()
  const [canAccessMemberEdit] = Access.useDecide('member-edit')

  if (!canAccessMemberEdit) {
    return <span>Access denied.</span>
  }

  return <MemberEditContainer patientId={patientId} />
}
