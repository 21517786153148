import React from 'react'
import { Field, FieldProps } from 'formik'
import { Manager, Reference, Popper } from 'react-popper'

import AvailabilitySlotField, { isSlotSelected } from './AvailabilitySlotField'
import BookSlotDialog from '../BookSlotDialog'
import { Slot } from './GroupSlots'
import { Clinic } from './GroupSlotsByClinic'
import { isPhysicalConsultation } from '../../types/ConsultationTypes'

interface Props {
  slot: Slot
  slotDuration: number
  clinicList: Clinic[]
  consentExpired?: boolean
}

const AvailabilitySlot = ({
  slot,
  slotDuration,
  clinicList,
  consentExpired,
}: Props) => (
  <Field>
    {({ form: { values, setFieldValue, isSubmitting } }: FieldProps) => {
      const isSelected = isSlotSelected(slot, values)
      const isPhysical = isPhysicalConsultation(values.consultation_type)

      const clearField = () => {
        setFieldValue('preferred_date_time', null)
        document.removeEventListener('keydown', handleEscPress)
      }

      const handleEscPress = (event) => {
        if (event.key === 'Escape') {
          clearField()
        }
      }

      if (isSelected) {
        document.addEventListener('keydown', handleEscPress)
      }

      return (
        <Manager>
          <Reference>
            {({ ref }) => (
              <AvailabilitySlotField
                innerRef={ref}
                clinics={clinicList}
                slot={slot}
              />
            )}
          </Reference>
          <Popper
            placement="left"
            modifiers={[
              {
                name: 'eventListeners',
                options: {
                  scroll: false,
                  resize: false,
                },
              },
              {
                name: 'flip',
                options: {
                  altBoundary: true,
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  altBoundary: true,
                  altAxis: true,
                },
              },
            ]}
          >
            {({ ref, style }) =>
              isSelected ? (
                <div ref={ref} style={{ ...style, zIndex: 1 }}>
                  <BookSlotDialog
                    clinicId={slot.clinicId}
                    clinics={clinicList}
                    clinicians={slot.consultants}
                    hideClinicians={!isPhysical}
                    onClose={clearField}
                    slotDuration={slotDuration}
                    UTCTimestamp={slot.UTCTimestamp}
                    timezoneId={values.timezone_id}
                    isSubmitting={isSubmitting}
                    consentExpired={consentExpired}
                  />
                </div>
              ) : null
            }
          </Popper>
        </Manager>
      )
    }}
  </Field>
)

export default AvailabilitySlot
