import React, { ReactNode } from 'react'
import qs from 'qs'
import { useLocation } from 'react-router'
import className from 'classnames/bind'
import { Transition } from 'react-transition-group'
import {
  useCookiePersistedState,
  ErrorBoundary,
} from '@babylon/member-operations'
import { useProductConfig } from '@babylon/product-config'
import SideNav from '../SideNav'
import TopBar from '../TopBar'
import styles from './AppLayout.module.scss'
import MenuToggleButton from '../TopBar/MenuToggleButton'

interface Props {
  children?: ReactNode
}

const AppLayout = ({ children }: Props) => {
  const { getProp } = useProductConfig()
  const sideNavEnabled = getProp('memberOperations', 'sideNavEnabled')

  const [sideNavExpanded, setSideNavExpanded] = useCookiePersistedState(
    'mop-side-nav-expanded',
    true
  )
  const location = useLocation()
  let { nonav } = qs.parse(location.search, { ignoreQueryPrefix: true })
  nonav = nonav && typeof nonav === 'string' && JSON.parse(nonav)

  if (nonav) {
    return <ErrorBoundary>{children}</ErrorBoundary>
  }

  const cx = className.bind(styles)

  return (
    <div className={styles.appLayout__wrapper}>
      <ErrorBoundary>
        <TopBar
          renderMenuToggleButton={() => (
            <MenuToggleButton
              initialState={sideNavExpanded}
              onClick={() => setSideNavExpanded((state) => !state)}
            />
          )}
        />
      </ErrorBoundary>
      <div className={styles.appLayout__main}>
        {sideNavEnabled && (
          <Transition in={sideNavExpanded} timeout={300}>
            {(state) => (
              <div
                className={cx(
                  styles.appLayout__nav,
                  `appLayout__nav--${state}-width`
                )}
              >
                <div
                  className={cx(
                    styles.appLayout__nav,
                    `appLayout__nav--${state}-opacity`
                  )}
                >
                  <ErrorBoundary>
                    <SideNav />
                  </ErrorBoundary>
                </div>
              </div>
            )}
          </Transition>
        )}
        <div className={styles.appLayout__content}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

export default AppLayout
