import { defineMessages } from 'react-intl'

const baseId = 'sick_notes'

export default defineMessages({
  heading: {
    id: `${baseId}.heading`,
    defaultMessage: 'Sick Notes',
  },
  reasonHeading: {
    id: `${baseId}.reasonHeading`,
    defaultMessage: 'Reason',
  },
  startDateHeading: {
    id: `${baseId}.startDateHeading`,
    defaultMessage: 'Start Date',
  },
  endDateHeading: {
    id: `${baseId}.endDateHeading`,
    defaultMessage: 'End Date',
  },
  download: {
    id: `${baseId}.download`,
    defaultMessage: 'Download',
  },
  no_sick_notes: {
    id: `${baseId}.no_sick_notes`,
    defaultMessage: 'This patient has no sick notes.',
  },
})
