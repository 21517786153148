/* eslint no-throw-literal:0 */
import { shouldUpdate, shallowEqual } from 'recompose'

export const map = (obj, fn) => {
  if (Array.isArray(obj)) {
    return obj.map(fn)
  }

  if (typeof obj === 'object') {
    const result = {}

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newValue = fn(obj[key], key)

        if (newValue !== undefined) {
          result[key] = newValue
        }
      }
    }

    return result
  }

  throw `${typeof obj} values cannot be mapped!`
}

export const removeTypeNames = (value, name) => {
  if (name !== '__typename') {
    return typeof value !== 'object' || value instanceof Date || value == null
      ? value
      : map(value, removeTypeNames)
  }
}

export const shouldRouteUpdate = shouldUpdate(
  (props, nextProps) =>
    !shallowEqual(props.match.params, nextProps.match.params) ||
    props.match.url !== nextProps.match.url
)

export { default as withForm } from './withForm'
export { default as withSpinner } from './withSpinner'
export { default as withErrorMessage } from './withErrorMessage'
