import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import GetConsultantsDurationQuery from './GetConsultantsDurationQuery'

interface ProfessionData {
  professions: Profession[]
}

export interface ConsultantTypeDurationDict {
  [key: string]: {
    available: number[]
    default: number
  }
}

interface ConsultantTypeDurationReturn {
  consultantTypesDuration: ConsultantTypeDurationDict
  loading: boolean
  error: any
}

const useConsultantTypeDurations = (): ConsultantTypeDurationReturn => {
  const { data, loading, error } = useQuery<ProfessionData>(
    GetConsultantsDurationQuery
  )

  const consultantTypesDuration = useMemo(() => {
    if (loading) {
      return {}
    }

    const { professions = [] } = data || {}

    return professions.reduce((acc, profession) => {
      acc[profession.key.toUpperCase()] = {
        available: profession.alternative_slot_durations_minutes,
        default: profession.default_slot_duration_minutes,
      }

      return acc
    }, {})
  }, [data, loading])

  return { consultantTypesDuration, loading, error }
}

export default useConsultantTypeDurations
