import { defineMessages } from 'react-intl'

const baseId = 'related_persons_card'

export default defineMessages({
  add_guardian_button_text: {
    id: `${baseId}.add_guardian_button_text`,
    defaultMessage: 'Add',
  },
  add_guardian_date_of_birth_error: {
    id: `${baseId}.add_guardian_date_of_birth_error`,
    defaultMessage:
      'The patient must have a date of birth in order to have a guardian assigned.',
  },
  add_guardian_error: {
    id: `${baseId}.add_guardian_error`,
    defaultMessage: 'Error while adding a guardian',
  },
  add_guardian_minor_error: {
    id: `${baseId}.add_guardian_minor_error`,
    defaultMessage: 'A Guardian cannot be a minor.',
  },
  add_guardian_not_found_error: {
    id: `${baseId}.add_guardian_not_found_error`,
    defaultMessage: 'The patient with Babylon ID {carerId} was not found',
  },
  add_guardian_header: {
    id: `${baseId}.add_guardian_header`,
    defaultMessage: 'Add a Guardian',
  },
  add_guardian_placeholder: {
    id: `${baseId}.add_guardian_placeholder`,
    defaultMessage: 'Enter Babylon ID',
  },
  add_guardian_relationship_already_exists_error: {
    id: `${baseId}.add_guardian_relationship_already_exists_error`,
    defaultMessage: 'A relationship already exists between these two members.',
  },
  dependants_header: {
    id: `${baseId}.dependants_header`,
    defaultMessage: 'Member dependants',
  },
  carers_header: {
    id: `${baseId}.carers_header`,
    defaultMessage: 'Member carers',
  },
  carers_tooltip: {
    id: `${baseId}.carers_tooltip`,
    defaultMessage: 'List of people that care for the member',
  },
  dependants_tooltip: {
    id: `${baseId}.dependants_tooltip`,
    defaultMessage: 'List of people the member cares for',
  },
  error: {
    id: `${baseId}.error`,
    defaultMessage: 'There was an error retrieving the data, please try again.',
  },
  no_relationships: {
    id: `${baseId}.no_relationships`,
    defaultMessage: 'No relationships',
  },
  remove_guardian_button_label: {
    id: `${baseId}.remove_guardian_button_label`,
    defaultMessage: 'Remove guardian',
  },
  remove_carer_button_label: {
    id: `${baseId}.remove_carer_button_label`,
    defaultMessage: 'Remove carer',
  },
  remove_guardian_confirm_title: {
    id: `${baseId}.remove_guardian_confirm_title`,
    defaultMessage: 'Remove guardian?',
  },
  remove_carer_confirm_title: {
    id: `${baseId}.remove_carer_confirm_title`,
    defaultMessage: 'Remove carer?',
  },
  remove_carer_confirm_description: {
    id: `${baseId}.remove_carer_confirm_description`,
    defaultMessage:
      'Please confirm that the member is aware that by removing this relationship they will no longer be able to care for this member.',
  },
  remove_guardian_error: {
    id: `${baseId}.remove_guardian_error`,
    defaultMessage: 'Error removing guardian. Please try again later.',
  },
  remove_guardian_success: {
    id: `${baseId}.remove_guardian_success`,
    defaultMessage: 'Guardian removed successfully',
  },
  remove_carer_error: {
    id: `${baseId}.remove_carer_error`,
    defaultMessage: 'Error removing carer. Please try again later.',
  },
  remove_carer_success: {
    id: `${baseId}.remove_carer_success`,
    defaultMessage: 'Carer removed successfully',
  },
  set_primary_guardian_button_label: {
    id: `${baseId}.set_primary_guardian_button_label`,
    defaultMessage: 'Make primary guardian',
  },
  set_primary_guardian_error: {
    id: `${baseId}.set_primary_guardian_error`,
    defaultMessage:
      'Error setting the guardian as primary. Please try again later.',
  },
  set_primary_guardian_success: {
    id: `${baseId}.set_primary_guardian_success`,
    defaultMessage: 'Guardian successfully set as primary.',
  },
  set_primary_carer_button_label: {
    id: `${baseId}.set_primary_carer_button_label`,
    defaultMessage: 'Make primary carer',
  },
  set_primary_carer_error: {
    id: `${baseId}.set_primary_carer_error`,
    defaultMessage:
      'Error setting the carer as primary. Please try again later.',
  },
  set_primary_carer_success: {
    id: `${baseId}.set_primary_carer_success`,
    defaultMessage: 'Carer successfully set as primary.',
  },
  set_primary_guardian_confirm_title: {
    id: `${baseId}.set_primary_guardian_confirm_title`,
    defaultMessage: 'Change primary guardian',
  },
  set_primary_carer_confirm_title: {
    id: `${baseId}.set_primary_carer_confirm_title`,
    defaultMessage: 'Change primary carer',
  },
  set_primary_carer_confirm_description: {
    id: `${baseId}.set_primary_carer_confirm_description`,
    defaultMessage:
      'Please confirm that the carer is aware that the responsibility of this member is about to be transferred.',
  },
})
