import { gql } from '@apollo/client'

export const QUERY_SICK_NOTES = gql`
  query AP_GetSickNotes($patientId: ID!) {
    getSickNotes(patientId: $patientId) {
      noteContent
      startDate
      endDate
      appointmentId
      url
      id
    }
  }
`
