import 'normalize.css'
import 'react-dates/lib/css/_datepicker.css'
import 'react-select/dist/react-select.css'
import 'rc-tooltip/assets/bootstrap_white.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { init } from '@sentry/browser'

import './dotenv'
import { setAuthConfig } from '@babylon/babylon-auth'
import { envUrl, envVar } from '@babylon/babylon-env'
import restrictedClipboard from '@babylon/restricted-clipboard'
import { Modal } from '@babylon/core-ui'
import { initialiseLogRocket } from '@babylon/tracking/logrocket'
import App from './App'

import './styles/styles.scss'
import './icons'

if (envVar('LOGROCKET_APP_ID')) {
  initialiseLogRocket(
    envVar('LOGROCKET_APP_ID'),
    envVar('REACT_APP_VERSION'),
    envVar('ENABLE_PARTIALLY_SANITIZED_LOGROCKET')
  )
}

if (envUrl('SENTRY_DSN')) {
  init({
    dsn: envUrl('SENTRY_DSN'),
    environment: envVar('SENTRY_ENVIRONMENT'),
  })
}

setAuthConfig({
  tokenRefreshPoint: 2 / 30, // 2 mins (assuming a 30 min token)
})

restrictedClipboard.init()

const appElement = document.getElementById('root')

Modal.setAppElement(appElement)

const loadApp = async () => {
  ReactDOM.render(<App />, appElement, () => {
    const appLoaderElement = document.querySelector('#app-loader')
    appLoaderElement.parentNode.removeChild(appLoaderElement)
  })
}

loadApp()
