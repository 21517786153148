function debounce<F extends (...args: never[]) => void>(
  func: F,
  timeout = 300
) {
  let timer: number | null = null

  return (...args: Parameters<F>) => {
    if (timer) {
      clearTimeout(timer)
    }

    timer = window.setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export default debounce
