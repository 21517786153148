import React, { FC, ReactNode } from 'react'
import classnames from 'classnames/bind'
import styles from './FormFields.module.scss'

const cx = classnames.bind(styles)

export interface FieldRowProps {
  size?: 'small' | 'default'
  testId?: string
}

export interface FormSectionProps {
  title: ReactNode
}

export const FieldRow: FC<FieldRowProps> = ({
  children,
  size = 'default',
  testId,
}) => {
  const classname = cx(styles.fieldRow, {
    'fieldRow--small': size === 'small',
  })

  return (
    <div className={classname} data-testid={testId}>
      {children}
    </div>
  )
}

export const FormSection: FC<FormSectionProps> = ({ children, title }) => (
  <div className={styles.formSection}>
    <h2 className={styles.formSection__title}>{title}</h2>
    {children}
  </div>
)
