import { graphql } from '@apollo/client/react/hoc'
import {
  compose,
  defaultProps,
  withStateHandlers,
  withHandlers,
} from 'recompose'
import { withRouter } from 'react-router'
import axios from 'axios'
import Cookies from 'js-cookie'

import { envUrl } from '@babylon/babylon-env'
import {
  loadPersistentData,
  savePersistentData,
  persistentDataNames,
} from '@/util/localStorage'
import requestIdGenerator from '@/util/requestIdGenerator'
import withSpinner from '@/util/withSpinner'
import withErrorMessage from '@/util/withErrorMessage'
import promptReason from '@/components/DrugBlacklistReasonDialog'
import { AdminDrugs, BlacklistDrug, PrescriptionRegions } from './queries'
import DrugsView from './DrugsView'
import columnConfig from './columnConfig.json'

// DEFAULT PROPS

const withDefaultProps = defaultProps({
  columns: columnConfig,
})

// VARIABLES

export const defaultVariables = {
  varSize: parseInt(
    loadPersistentData(persistentDataNames.DRUGS_PAGINATION_SIZE) || 10
  ),
  varPage: 0,
  varSearchPhrase: '',
  varSort: null,
}

export const setSize = (state) => (varSize) => {
  savePersistentData(persistentDataNames.DRUGS_PAGINATION_SIZE, varSize)

  return {
    ...state,
    varSize,
    varPage: 0,
    lastChangedVariable: 'varSize',
  }
}

export const setPage = (state) => (varPage) => ({
  ...state,
  varPage,
  lastChangedVariable: 'varPage',
})

export const setSearchPhrase = (state) => (varSearchPhrase) => ({
  ...state,
  varSearchPhrase,
  varPage: 0,
  lastChangedVariable: 'varSearchPhrase',
})

export const setSort = (state) => (varSort) => ({
  ...state,
  varSort,
  varPage: 0,
  lastChangedVariable: 'varSort',
})

const withVariables = withStateHandlers(defaultVariables, {
  setSize,
  setPage,
  setSearchPhrase,
  setSort,
})

// DATA

export const queryOptions = {
  options: ({ varSearchPhrase, varSize, varPage, varSort }) => ({
    variables: {
      size: varSize,
      page: varPage,
      query: varSearchPhrase,
      sort: varSort ? `${varSort.column},${varSort.order}` : '',
    },
  }),
}

const withData = compose(
  graphql(PrescriptionRegions, { name: 'regions' }),
  graphql(AdminDrugs, queryOptions),
  graphql(BlacklistDrug, { name: 'blacklist' })
)

// STATE

export const defaultState = (props) => ({
  searchPhrase: props.varSearchPhrase,
  downloadRegion: '',
})

export const updateSearchPhrase = (state) => (searchPhrase) => ({
  ...state,
  searchPhrase,
})

export const updateDownloadRegion = (state) => (downloadRegion) => ({
  ...state,
  downloadRegion,
})

const withState = withStateHandlers(defaultState, {
  updateSearchPhrase,
  updateDownloadRegion,
})

// ACTIONS

export const onSearchPhraseChange = (props) => props.updateSearchPhrase

export const onSearch = (props) => () => {
  props.setSearchPhrase(props.searchPhrase)
}

export const onSort = (props) => props.setSort

export const onAdd = (props) => () => {
  props.history.push('/admin/drugs/add')
}

export const onEdit = (props) => (id) => {
  props.history.push(`/admin/drugs/edit/${id}`)
}

export const onAudits = (props) => (id) => {
  props.history.push(`/admin/drugs/audits/${id}`)
}

export const onBlacklist = (props) => (id, region, value) => () => {
  promptReason().then(
    (reason) =>
      props
        .blacklist({
          variables: { id, region, value, reason },
        })
        .catch(() => {
          props.errorAlert()
        }),
    () => null
  )
}

export const downloadCSV = (props) => () => {
  window.open(
    `${envUrl('FILE_DOWNLOAD_URL')}/drugs-${props.downloadRegion}.csv`,
    '_self'
  )
}

export const uploadCSV = ({ downloadRegion }) => (file, options = {}) => {
  const reader = new FileReader()
  reader.onload = function handleLoad() {
    const showErrorMessage = (msg) => {
      alert(`Error on uploading the CSV file.\nThe server responded '${msg}'`)
    }
    const cookie = JSON.parse(Cookies.get('autologin_info')) || {}
    const { csrf_token } = cookie
    axios
      .post(reader.result, {
        headers: {
          'content-type': 'text/csv',
          'x-region': downloadRegion.substring(0, 3),
          'X-Security-Token': csrf_token,
          'babylon-request-id': requestIdGenerator.generate(),
        },
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const percent = progressEvent.lengthComputable
            ? (progressEvent.loaded * 100) / progressEvent.total
            : 0
          options.progress && options.progress(percent)
        },
      })
      .then((response) => {
        if (response.status >= 400) {
          showErrorMessage(response.statusText)
        }

        options.done && options.done()
      })
      .catch((error) => {
        showErrorMessage(
          error.response ? error.response.statusText : error.message
        )
        options.done && options.done()
      })
  }
  reader.readAsText(file)
}

const withActions = withHandlers({
  onSearchPhraseChange,
  onSearch,
  onSort,
  onAdd,
  onEdit,
  onAudits,
  onBlacklist,
  downloadCSV,
  uploadCSV,
})

// LOADER

export const isLoading = (props) => !props.data || !props.data.adminDrugs

const withLoader = withSpinner(isLoading)

export default compose(
  withRouter,
  withDefaultProps,
  withVariables,
  withState,
  withData,
  withErrorMessage(),
  withActions,
  withLoader
)(DrugsView)
