/* eslint import/no-duplicates: 0 */
import React, { useMemo } from 'react'
import { useFormatMessage } from '@babylon/intl'
import {
  Connection,
  ContactPointEdge,
  ContactPointSystem,
  FaxNumberContactPoint,
  GpOrganizationEdge,
} from '@/gql/graphql'
import { CoreDataTable } from '@/components/DataTableFromGQL'
import { ColumnsVisibility } from '@/components/DataGrid'
import { messages } from './messages'
import { QUERY_GET_GP_FAX_NUMBERS } from './apollo/queries'
import { EditObjectConfig } from '@/components/DataTableFromGQL/types'
import {
  EDIT_GP_FAX_NUMBERS,
  DELETE_GP_FAX_NUMBER,
  CREATE_GP_FAX_NUMBER,
} from '@/routes/GPFaxNumbers/apollo/mutations'

const rowFormatter = (data: Connection) => {
  return data?.edges.map((item: GpOrganizationEdge) => {
    const contactPoint = item.node?.telecom?.edges?.find(
      (edge: ContactPointEdge) => edge.node?.system === ContactPointSystem.Fax
    )?.node

    const faxNumberNode = contactPoint as FaxNumberContactPoint

    return {
      id: item.node?.id as string,
      name: item.node?.name as string,
      faxNumber: faxNumberNode.faxNumber as string,
      faxCountryCode: faxNumberNode.faxCountryCode as string,
      faxNumberFull: `+${faxNumberNode.faxCountryCode}${faxNumberNode.faxNumber}`,
    }
  })
}

const columns = [
  {
    fieldNameInRow: 'name',
    headerName: 'Name',
    flex: 2,
    sortable: false,
  },
  {
    fieldNameInRow: 'faxNumberFull',
    headerName: 'Fax Number',
    flex: 2,
    sortable: false,
  },
]

const columnsToShow: ColumnsVisibility = {
  name: true,
}

export const GPFaxNumbersDataGrid = () => {
  const fm = useFormatMessage()

  const editConfig: EditObjectConfig = useMemo(
    () => ({
      mutation: EDIT_GP_FAX_NUMBERS,
      fields: [
        {
          name: 'id',
          hidden: true,
        },
        {
          name: 'name',
          type: 'text',
          label: fm(messages.edit_name),
        },
        {
          name: 'faxCountryCode',
          type: 'text',
          label: fm(messages.edit_fax_country_code),
        },
        {
          name: 'faxNumber',
          type: 'text',
          label: fm(messages.edit_fax_number),
        },
      ],
      headings: {
        header: 'name',
      },
    }),
    [fm]
  )

  const addConfig: EditObjectConfig = useMemo(
    () => ({
      mutation: CREATE_GP_FAX_NUMBER,
      fields: [
        {
          name: 'name',
          type: 'text',
          label: fm(messages.edit_name),
        },
        {
          name: 'faxCountryCode',
          type: 'text',
          label: fm(messages.edit_fax_country_code),
        },
        {
          name: 'faxNumber',
          type: 'text',
          label: fm(messages.edit_fax_number),
        },
      ],
      headings: {
        header: 'name',
      },
    }),
    [fm]
  )

  const filterOptions = [
    {
      name: 'name',
      label: fm(messages.filter_by_name),
    },
    {
      name: 'faxNumber',
      label: fm(messages.filter_by_fax_number),
    },
  ]

  const filterFormatter = (filters: Record<string, string>) => {
    let faxNumberFilter: {
      // eslint-disable-next-line camelcase
      faxNumber?: string
      name?: string
    } = {}
    if (filters.name && filters.name.trim() !== '') {
      faxNumberFilter = {
        ...faxNumberFilter,
        name: filters.name,
      }
    }
    if (filters.faxNumber && filters.faxNumber.trim() !== '') {
      faxNumberFilter = {
        ...faxNumberFilter,
        faxNumber: filters.faxNumber,
      }
    }
    return faxNumberFilter
  }

  return (
    <div>
      <CoreDataTable
        id="gp_fax_numbers"
        objectTypeLabel={fm(messages.gpFaxNumbers)}
        columns={columns}
        initialColumnsToShow={columnsToShow}
        filterFormatter={filterFormatter}
        filterOptions={filterOptions}
        query={QUERY_GET_GP_FAX_NUMBERS}
        rowFormatter={rowFormatter}
        editConfig={editConfig}
        deleteMutation={DELETE_GP_FAX_NUMBER}
        addConfig={addConfig}
      />
    </div>
  )
}
