import { defineMessages } from 'react-intl'

const baseId = 'covid19_tags'

export default defineMessages({
  covid_19: {
    id: `${baseId}.covid_19`,
    defaultMessage: 'COVID 19',
  },
})
