import React from 'react'

import styles from './styles.module.css'

type Props = {
  initials: string
  available: boolean
}
const AgentAvatar = ({ initials, available }: Props) => (
  <div className={styles.avatar}>
    {initials}
    <div
      className={styles.availability}
      style={{
        background: available ? '#008725' : '#D13400',
      }}
    />
  </div>
)

export default AgentAvatar
