import { defineMessages } from 'react-intl'

const baseId = 'appointment_card'

export default defineMessages({
  show_details: {
    id: `${baseId}.show_details`,
    defaultMessage: 'Show details',
  },
  guestName_label: {
    id: `${baseId}.guestName_label`,
    defaultMessage: 'Caregiver Invited',
  },
})
