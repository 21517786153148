import { defineMessages } from 'react-intl'

const baseId = 'consumer_networks_card'

export default defineMessages({
  tooltip: {
    id: `${baseId}.tooltip`,
    defaultMessage: 'Current and past networks where patient has registered.',
  },
  header: {
    id: `${baseId}.header`,
    defaultMessage: 'Consumer networks',
  },
  current_plan_header: {
    id: `${baseId}.current_plan_header`,
    defaultMessage: 'Current plan',
  },
  past_consumer_networks_header: {
    id: `${baseId}.past_consumer_networks_header`,
    defaultMessage: 'Past consumer networks',
  },
  show_past_consumer_networks_button: {
    id: `${baseId}.show_past_consumer_networks_button`,
    defaultMessage: 'Show past consumer networks',
  },
  hide_past_consumer_networks: {
    id: `${baseId}.hide_past_consumer_networks`,
    defaultMessage: 'Hide past consumer networks',
  },
  rejoin_button: {
    id: `${baseId}.rejoin_button`,
    defaultMessage: 'Re-join',
  },
  cancel_button: {
    id: `${baseId}.cancel_button`,
    defaultMessage: 'Cancel',
  },
  select_button: {
    id: `${baseId}.select_button`,
    defaultMessage: 'Select',
  },
  deregister_button: {
    id: `${baseId}.deregister_button`,
    defaultMessage: 'Deregister',
  },
  change_city_dropdown: {
    id: `${baseId}.change_city_dropdown`,
    defaultMessage: 'Move',
  },
  error_fetching_consumer_networks: {
    id: `${baseId}.error_fetching_consumer_networks`,
    defaultMessage: 'Error while fetching gpah consumer networks',
  },
  error_adding_user_to_consumer_network: {
    id: `${baseId}.error_adding_user_to_consumer_network`,
    defaultMessage: 'Error while switching gpah city',
  },
  remove_button: {
    id: `${baseId}.remove_button`,
    defaultMessage: 'Remove',
  },
  state_label: {
    id: `${baseId}.state_label`,
    defaultMessage: 'State',
  },
  removed_label: {
    id: `${baseId}.removed_label`,
    defaultMessage: 'Removed',
  },
  started_label: {
    id: `${baseId}.started_label`,
    defaultMessage: 'Started',
  },
  joined_label: {
    id: `${baseId}.joined_label`,
    defaultMessage: 'Joined',
  },
  network_label: {
    id: `${baseId}.network_label`,
    defaultMessage: 'Network',
  },
  active_state: {
    id: `${baseId}.active_state`,
    defaultMessage: 'Active',
  },
  removed_state: {
    id: `${baseId}.removed_state`,
    defaultMessage: 'Removed',
  },
  hide_past_dates: {
    id: `${baseId}.hide_past_dates`,
    defaultMessage: 'Hide past dates ({extraHistory})',
  },
  view_past_dates: {
    id: `${baseId}.view_past_dates`,
    defaultMessage: 'View past dates ({extraHistory})',
  },
  queued: {
    id: `${baseId}.queued`,
    defaultMessage: 'queued',
  },
  plan_label: {
    id: `${baseId}.plan_label`,
    defaultMessage: 'Plan',
  },
  payment_label: {
    id: `${baseId}.payment_label`,
    defaultMessage: 'Payment',
  },
  unsub_snackbar_success: {
    id: `${baseId}.unsub_snackbar_success`,
    defaultMessage: 'Successfully removed the plan subscription',
  },
  unsub_snackbar_failure: {
    id: `${baseId}.unsub_snackbar_failure`,
    defaultMessage: 'Failed to remove the plan subscription',
  },
  unsub_button_label: {
    id: `${baseId}.unsub_button_label`,
    defaultMessage: 'Unsubscribe',
  },
  unsub_modal_title: {
    id: `${baseId}.unsub_modal_title`,
    defaultMessage: 'Unsubscribe to plan',
  },
  unsub_modal_body: {
    id: `${baseId}.unsub_modal_body`,
    defaultMessage:
      'Are you sure you would like to cancel the subscription for this plan?',
  },
  unsub_modal_submit_label: {
    id: `${baseId}.unsub_modal_submit_label`,
    defaultMessage: 'Yes, remove plan',
  },
  unsub_modal_cancel_label: {
    id: `${baseId}.unsub_modal_cancel_label`,
    defaultMessage: 'No, keep plan',
  },
})
