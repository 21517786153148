import React, { ReactNode } from 'react'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import { ApolloError } from '@apollo/client'
import { useProductConfig } from '@babylon/product-config'
import { TimelineEvent, WorkflowV2 } from '@babylon/graphql-middleware-types'
import ErrorBoundary from '../ErrorBoundary'
import LinkButton from '../LinkButton'
import TimelineCard from '../TimelineCard'
import WorkflowCard from '../TimelineCard/components/WorkflowCard/WorkflowCard'
import RepeatTemplateCard from '../TimelineCard/components/RepeatTemplateCard/RepeatTemplateCard'
import { RepeatTemplate, Consultation } from '../types'
import TimelineBlock from './TimelineBlock'
import { mapWorkflow } from './utils'
import styles from './TimelineEvents.module.scss'
import TimelineCardMenu, {
  TimelineCardMenuProps,
} from '../TimelineCard/TimelineCardMenu'
import { isWarningStatus } from '../TimelineCard/utils'
import LoadingTimeline from './LoadingTimeline'

export interface AppointmentInvite {
  appointment_id: string | null
  duration_minutes: number
}

export interface TimelineEventsProps {
  events: TimelineEvent[]
  isUpcoming: boolean
  loading: boolean
  patientId: string
  title: string
  error?: ApolloError
  info?: string
  viewAllText?: ReactNode
  onViewAll?(): void
  onCancelAppointmentClick: (appointmentId: string) => void
}

const isConsultation = (event: TimelineEvent): event is Consultation => {
  const consultationEvent = event as Consultation

  if (!consultationEvent) {
    return false
  }

  const isRTMBooking = consultationEvent.bookingType === 'rtm'

  return !!consultationEvent.consultant || isRTMBooking
}

const isWorkflow = (event: TimelineEvent): event is WorkflowV2 => {
  const workflowEvent = event as WorkflowV2

  if (!workflowEvent) {
    return false
  }

  return !!workflowEvent.workflow_definition_key
}

// Hide all workflow cards in past and upcoming activity
const isSupportedWf = (event: TimelineEvent): event is WorkflowV2 => false

const isRepeatTemplate = (event: TimelineEvent): event is RepeatTemplate => {
  const repeatTemplateEvent = event as RepeatTemplate

  if (!repeatTemplateEvent) {
    return false
  }

  return !!repeatTemplateEvent.drugName
}

export default function TimelineEvents({
  events,
  info,
  isUpcoming,
  loading,
  patientId,
  title,
  viewAllText,
  onViewAll,
  onCancelAppointmentClick,
}: TimelineEventsProps) {
  const testId = kebabCase(title)
  const { getBlob } = useProductConfig()
  const {
    restrictProfessionsAccessToConsultationNotes,
    viewConsultationNotesEnabled,
  } = getBlob('memberOperations')

  return (
    <TimelineBlock testId={testId} title={title} info={info}>
      <>
        {!loading ? (
          events.map((event) => {
            if (isConsultation(event)) {
              const checkIn = get(
                event,
                'appointment.checkIn.check_in_data',
                null
              )
              const guestName = event.participants?.find(
                (participant) => participant.type == 'guest'
              )?.participant?.full_name
              const status = get(event, 'appointment.state', event.status)
              const duration = get(event, 'appointment.slot_size', 0)
              const medium = get(event, 'appointment.medium', null)
              const clinic = get(event, 'appointment.clinic', null)

              const restrictedAccessToConsultationNotes = restrictProfessionsAccessToConsultationNotes?.some(
                (profession) =>
                  profession ===
                  event.consultant?.profession_name
                    .toLowerCase()
                    .split(' ')
                    .join('_')
              )

              return (
                <ErrorBoundary key={event.id}>
                  <TimelineCard
                    id={event.id}
                    cancellable={isUpcoming && status === 'paid'}
                    cancelledAt={event.cancelledAt}
                    cancellationReasons={event.reasons || []}
                    checkIn={checkIn}
                    consultant={event.consultant}
                    consumerNetwork={event.consumerNetworkName}
                    guestName={guestName}
                    duration={duration}
                    medium={medium}
                    clinic={clinic}
                    patientId={patientId}
                    reason={event.patientNote}
                    scheduledTime={event.scheduledTime}
                    status={status}
                    onCancelAppointmentClick={onCancelAppointmentClick}
                    referrals={event.referrals}
                    renderMenu={(props: TimelineCardMenuProps) => (
                      <TimelineCardMenu
                        {...props}
                        viewConsultationNotes={
                          viewConsultationNotesEnabled &&
                          !restrictedAccessToConsultationNotes
                        }
                      />
                    )}
                    isWarning={isWarningStatus(status)}
                  />
                </ErrorBoundary>
              )
            }

            if (isWorkflow(event) && isSupportedWf(event)) {
              return (
                <ErrorBoundary key={event.id}>
                  <WorkflowCard {...mapWorkflow(event)} />
                </ErrorBoundary>
              )
            }

            if (isRepeatTemplate(event)) {
              return (
                <ErrorBoundary key={event.id}>
                  <RepeatTemplateCard
                    {...event}
                    patientId={patientId}
                    duration={get(event, 'latestIssue.durationDays', null)}
                  />
                </ErrorBoundary>
              )
            }

            return null
          })
        ) : (
          <LoadingTimeline />
        )}
        {onViewAll && viewAllText && (
          <div className={styles.consultations__viewAll}>
            <LinkButton
              disabled={loading}
              loading={loading}
              testId={`${testId}-view-all`}
              onClick={onViewAll}
            >
              {viewAllText}
            </LinkButton>
          </div>
        )}
      </>
    </TimelineBlock>
  )
}
