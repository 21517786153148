import { useState, useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'

export default function useFormikError() {
  const { errors } = useFormikContext()

  const getAllErrorMessages = useMemo(() => Object.values(errors) as string[], [
    errors,
  ])

  const [errorMessage, setErrorMessage] = useState<string[] | []>(
    getAllErrorMessages
  )

  const clearErrorMessage = () => setErrorMessage([])

  const setAllErrorMessage = () => setErrorMessage(getAllErrorMessages)

  useEffect(() => {
    setErrorMessage(getAllErrorMessages)
  }, [getAllErrorMessages])

  return { errorMessage, clearErrorMessage, setAllErrorMessage }
}
