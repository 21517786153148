import { defineMessages } from 'react-intl'

const baseId = 'supply_networks'

export const messages = defineMessages({
  supplyNetworks: {
    id: `${baseId}.supply_networks`,
    defaultMessage: 'Supply Networks',
  },
  new_supply_networks_switch_banner: {
    id: `${baseId}.new_supply_networks_switch_banner`,
    defaultMessage:
      'NEW SUPPLY NETWORKS PAGE - Click to go to the old page. You can switch back at any time.',
  },
  name: {
    id: `${baseId}.name`,
    defaultMessage: 'Name',
  },
  region: {
    id: `${baseId}.region`,
    defaultMessage: 'Region',
  },
  priority: {
    id: `${baseId}.priority`,
    defaultMessage: 'Priority',
  },
  featureSwitches: {
    id: `${baseId}.featureSwitches`,
    defaultMessage: 'Feature Switches',
  },
})
