import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Heading, Button, Spinner } from '@babylon/core-ui'
import { File } from '@babylon/icons'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../..'
import { Referral } from '../types'

import messages from './Referrals.messages'
import styles from './Referrals.module.scss'

export interface ReferralListItemProps {
  referral: Referral
  onSend: (arg1: string) => any
  sendEnabled: Boolean
}

const ReferralListItem = ({
  referral,
  onSend,
  sendEnabled,
}: ReferralListItemProps) => {
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })

  return (
    <div>
      <Heading className={styles.Referrals__referralHeader} level="h2">
        {referral.specialismName}
      </Heading>
      <div className={styles.Referrals__referral}>
        <a
          target="blank"
          className={styles.Referrals__link}
          href={referral.referralPdf}
          onClick={() =>
            trackClick({
              elementName: 'referral-link',
              elementType: TrackingElementType.link,
            })
          }
        >
          <File />
          {`doctor_referral_to_${referral.specialismName
            .replace(' ', '_')
            .toLowerCase()}.pdf`}
        </a>
        {sendEnabled && (
          <Button
            type="button"
            disabled={loading}
            className={styles.Referrals__sendButton}
            onClick={() => {
              setLoading(true)
              onSend(referral.id).then(() => {
                setSent(true)
                setLoading(false)
              })
            }}
          >
            {loading ? (
              <Spinner testid="send-spinner" color="#400099" size="small" />
            ) : (
              <FormattedMessage {...messages[sent ? 'resend' : 'send']} />
            )}
          </Button>
        )}
      </div>
    </div>
  )
}

export default ReferralListItem
