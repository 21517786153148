import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'updateAvailabilityForm.title',
    defaultMessage: 'Update availability',
  },
  formAriaLabel: {
    id: 'updateAvailabilityForm.ariaLabel',
    defaultMessage: 'Update availability form',
  },
  formSubmitButton: {
    id: 'updateAvailabilityForm.submitButton',
    defaultMessage: 'Update availability',
  },
})
