import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { Button, Cell, Grid, Heading, Modal, Text } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import messages from './InviteSentConfirmation.messages'
import styles from './InviteSentConfirmation.module.css'

library.add(faCheckCircle)

interface Props {
  className?: string
  isOpen: boolean
  durationMinutes?: number | string
  medium?: string
  intimate?: boolean
  memberId: string
  notes?: string
  serviceTypeName?: string
  onTrackDoneBtn?: () => void
}

const InviteSentConfirmation = ({
  className,
  durationMinutes,
  medium,
  intimate,
  isOpen,
  memberId,
  notes,
  serviceTypeName,
  onTrackDoneBtn = () => {},
}: Props) => {
  const fm = useFormatMessage()

  return (
    <Modal className={className} open={isOpen} includeClose={false}>
      <div className={styles.modalContent}>
        <div className={styles.modalTopIcon}>
          <FontAwesomeIcon
            icon={['fal', 'check-circle']}
            size="3x"
            color="var(--button-primary-background)"
          />
        </div>
        <Heading margin>{fm(messages.title)}</Heading>
        <div className={styles.modalBody}>
          <Grid columns={3}>
            {serviceTypeName && (
              <>
                <Cell width={1}>
                  <Text color="grey-type">{fm(messages.type)}:</Text>
                </Cell>
                <Cell width={2}>
                  <Text>{serviceTypeName}</Text>
                </Cell>
              </>
            )}
            {durationMinutes && (
              <>
                <Cell width={1}>
                  <Text color="grey-type">{fm(messages.duration)}:</Text>
                </Cell>
                <Cell width={2}>
                  <Text>{durationMinutes}</Text>
                </Cell>
              </>
            )}

            {medium && (
              <>
                <Cell width={1}>
                  <Text color="grey-type">{fm(messages.medium)}:</Text>
                </Cell>
                <Cell width={2}>
                  <Text>{medium}</Text>
                </Cell>
              </>
            )}

            {intimate && (
              <>
                <Cell width={1}>
                  <Text color="grey-type">{fm(messages.intimate)}:</Text>
                </Cell>
                <Cell width={2}>
                  <Text>Yes</Text>
                </Cell>
              </>
            )}

            {notes && (
              <>
                <Cell width={1}>
                  <Text color="grey-type">{fm(messages.reason)}:</Text>
                </Cell>
                <Cell width={2}>
                  <Text>{notes}</Text>
                </Cell>
              </>
            )}
          </Grid>
        </div>
        <div className={styles.modalActions}>
          <Link
            to={`/admin/patients/${memberId}/memberships`}
            className={styles.modalConfirmLink}
          >
            <Button
              className={styles.modalConfirmButton}
              inline
              onClick={onTrackDoneBtn}
            >
              {fm(messages.done)}
            </Button>
          </Link>
        </div>
      </div>
    </Modal>
  )
}

export default InviteSentConfirmation
