import React from 'react'
import { Pills } from '@babylon/medkit'
import { TabId } from './types'
import useTabOptions from './useTabOptions'
import styles from './TimelineTabs.module.scss'

interface TimeLineTabsProps {
  onChangeTab: (selectedTab: TabId) => void
  allowedTabs: TabId[]
  selectedTab: TabId
}
const TimelineTabs = ({
  onChangeTab,
  allowedTabs,
  selectedTab,
}: TimeLineTabsProps) => {
  const tabOptions = useTabOptions(allowedTabs, selectedTab)

  return (
    <div className={styles.TimelineFilter}>
      <Pills
        name="timeline-tab-pills"
        onChange={onChangeTab as (selectedTab: string[] | string) => void}
        options={tabOptions}
        isControlledComponent
      />
    </div>
  )
}

export default TimelineTabs
