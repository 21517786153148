import { format, parseISO } from 'date-fns'

function generateHeaders(headers: string[]) {
  return `${headers.join('|')} \n ---|---|--- \n`
}

function formatObjectToTable(data: object) {
  return Object.values(data).join('|')
}

function formatListToTable(data: object[]) {
  const values = data.map((device) => Object.values(device).join('|'))
  return values.join('|\n|')
}

function formatToTable(tableData: object | object[], headers: string[]) {
  const tableHeaders = generateHeaders(headers)
  const table = Array.isArray(tableData)
    ? `${tableHeaders} ${formatListToTable(tableData)}`
    : `${tableHeaders} ${formatObjectToTable(tableData)}`

  return table
}

const formatDate = (date: string) => format(parseISO(date), 'dd LLL yyyy, H:mm')

export { formatDate, formatToTable }
