import { defineMessages } from 'react-intl'

const baseId = 'members_search.members_search_view'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Search for a patient',
  },
  invalid_patient_id: {
    id: `${baseId}.errors.invalid_patient_id`,
    defaultMessage: 'Please enter a valid patient ID',
  },
  invalid_national_id: {
    id: `${baseId}.errors.invalid_national_id`,
    defaultMessage: 'Please enter a valid national ID',
  },
  invalid_email: {
    id: `${baseId}.errors.invalid_email`,
    defaultMessage: 'Please enter a valid email',
  },
  invalid_date: {
    id: `${baseId}.errors.invalid_date`,
    defaultMessage: 'Please enter a valid date of birth',
  },
  missing_filter: {
    id: `${baseId}.errors.missing_filter`,
    defaultMessage:
      'Search incomplete. Please enter at least one piece of information',
  },
  next: {
    id: `${baseId}.next`,
    defaultMessage: 'Next',
  },
  previous: {
    id: `${baseId}.previous`,
    defaultMessage: 'Previous',
  },
  patient_found: {
    id: `${baseId}.patient_found`,
    defaultMessage: 'patient found',
  },
  patients_found: {
    id: `${baseId}.patients_found`,
    defaultMessage: 'patients found',
  },
  invalid_search: {
    id: `${baseId}.invalid_search`,
    defaultMessage:
      'Search incomplete. Please enter at least one piece of information',
  },
  rows_per_page_label: {
    id: `${baseId}.rows_per_page_label`,
    defaultMessage: 'Show rows per page',
  },
})
