import { defineMessages } from 'react-intl'

const baseId = 'account_services'

export default defineMessages({
  header: {
    id: `${baseId}.header`,
    defaultMessage: 'Account services',
  },
  view_covid19: {
    id: `${baseId}.view_covid19`,
    defaultMessage: 'View COVID-19 history',
  },
  view_chat_history: {
    id: `${baseId}.view_chat_history`,
    defaultMessage: 'View chat history',
  },
  redemption_codes: {
    id: `${baseId}.redemption_codes`,
    defaultMessage: 'Codes',
  },
  redeem_code: {
    id: `${baseId}.redeem_code`,
    defaultMessage: 'Redeem Code',
  },
  devices: {
    id: `${baseId}.devices`,
    defaultMessage: 'Devices',
  },
  patient_metrics: {
    id: `${baseId}.patient_metrics`,
    defaultMessage: 'Patient metrics',
  },
  view_transactions: {
    id: `${baseId}.view_transactions`,
    defaultMessage: 'View transactions',
  },
  view_sick_notes: {
    id: `${baseId}.view_sick_notes`,
    defaultMessage: 'View sick notes',
  },
  view_activity_log: {
    id: `${baseId}.view_activity_log`,
    defaultMessage: 'View activity log',
  },
  view_appointment_credits: {
    id: `${baseId}.view_appointment_credits`,
    defaultMessage: 'Appointment credits',
  },
  view_day_to_day: {
    id: `${baseId}.view_day_to_day`,
    defaultMessage: 'Create DayToDay profile',
  },
})
