import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Snackbar } from '@babylon/core-ui'
import { useHistory } from 'react-router-dom'

import messages from './messages'
import styles from './styles.module.scss'
import { PharmacistsDataGrid } from '@/routes/Pharmacists/PharmacistsDataGrid'

export const Pharmacists = () => {
  const fm = useFormatMessage()

  const history = useHistory()
  const [showBanner, setShowBanner] = useState(true)

  const switchPage = () => {
    history.push('/admin/pharmacists')
  }

  return (
    <>
      {showBanner && (
        <Snackbar
          open
          fill
          intent="warning"
          fixed={false}
          onClose={() => setShowBanner(false)}
          className={styles.Banner}
        >
          <button
            type="button"
            className={styles.BannerButton}
            onClick={switchPage}
          >
            {fm(messages.new_pharmacists_switch_banner)}
          </button>
        </Snackbar>
      )}
      <PharmacistsDataGrid />
    </>
  )
}
