import { defineMessages } from 'react-intl'

const baseId = 'workflow_card'

export default defineMessages({
  due_date: {
    id: `${baseId}.due_date`,
    defaultMessage: 'Due: {date}',
  },
  completed_date: {
    id: `${baseId}.completed_date`,
    defaultMessage: 'Completed: {date}',
  },
  title: {
    id: `${baseId}.title`,
    defaultMessage: '{title}',
  },
  completed: {
    id: `${baseId}.completed`,
    defaultMessage: 'Completed',
  },
  show_details: {
    id: `${baseId}.show_details`,
    defaultMessage: 'Show details',
  },
  assignee: {
    id: `${baseId}.assignee`,
    defaultMessage: 'Assignee: {name}',
  },
  assigned_to: {
    id: `${baseId}.assigned_to`,
    defaultMessage: 'Assignee',
  },
  view_in_camunda: {
    id: `${baseId}.view_in_camunda`,
    defaultMessage: 'View in Camunda',
  },
  clinician: {
    id: `${baseId}.clinician`,
    defaultMessage: 'Clinician',
  },
  consultation_id: {
    id: `${baseId}.consultation_id`,
    defaultMessage: 'Consultation ID',
  },
  last_payment_date: {
    id: `${baseId}.last_payment_date`,
    defaultMessage: 'Last payment',
  },
  proctor_calls: {
    id: `${baseId}.proctor_calls`,
    defaultMessage: 'Proctor calls: {total} in total',
  },
  used_up: {
    id: `${baseId}.used_up`,
    defaultMessage: 'Doses used up: {doses}',
  },
  latest_email: {
    id: `${baseId}.latest_email`,
    defaultMessage: 'Last email sent',
  },
  prescriptions_issued: {
    id: `${baseId}.prescriptions_issued`,
    defaultMessage: 'Prescriptions: {total} issued so far',
  },
  latest: {
    id: `${baseId}.latest`,
    defaultMessage: 'Latest:',
  },
  valid_until: {
    id: `${baseId}.valid_until`,
    defaultMessage: 'Valid until: {validity}',
  },
  doses: {
    id: `${baseId}.doses`,
    defaultMessage: 'Prescribed by {clinician}. {strength}, {doses} doses.',
  },
  programme_member_since: {
    id: `${baseId}.programme_member_since`,
    defaultMessage: 'Member since',
  },
  speciality: {
    id: `${baseId}.speciality`,
    defaultMessage: 'Speciality',
  },
  sub_speciality: {
    id: `${baseId}.sub_speciality`,
    defaultMessage: 'Sub-speciality',
  },
  consultation_notes: {
    id: `${baseId}.consultation_notes`,
    defaultMessage: 'Consultation notes',
  },
  previous_comments: {
    id: `${baseId}.previous_comments`,
    defaultMessage: 'Previous comments',
  },
  weekly_check_in_due_date: {
    id: `${baseId}.weekly_check_in_due_date`,
    defaultMessage: 'Weekly check-in due by',
  },
  dosage: {
    id: `${baseId}.dosage`,
    defaultMessage: 'Dosage',
  },
  free_membership: {
    id: `${baseId}.free_membership`,
    defaultMessage: 'Free membership',
  },
  active: {
    id: `${baseId}.active`,
    defaultMessage: 'Active',
  },
})
