import React from 'react'
import { PhoneNumber } from '../../../../CopiableMemberInformation'
import styles from './EmergencyContact.module.scss'

export interface EmergencyContactProps {
  name: string
  phoneNumber?: string
  onPhoneNumberCopy?: (message: string) => void
}

const EmergencyContact = ({
  name,
  phoneNumber,
  onPhoneNumberCopy,
}: EmergencyContactProps) => (
  <div className={styles.emergencyContact} data-testid="emergency-contact">
    <div data-testid="emergency-contact-name">{name}</div>
    <div data-testid="emergency-contact-number">
      <PhoneNumber
        phoneNumber={phoneNumber || ''}
        onPhoneNumberCopy={onPhoneNumberCopy}
      />
    </div>
  </div>
)

export default EmergencyContact
