import React from 'react'
import cx from 'classnames'
import { Input, Textarea, Button, Checkboxgroup } from '@babylon/ui'

import styles from './styles.module.scss'

const withOnChange = (Widget) => ({
  name,
  onChange,
  actions,
  formProps,
  ...props
}) => (
  <Widget
    {...props}
    onChange={(eventOrValue) =>
      actions.onChange &&
      actions.onChange(
        name,
        eventOrValue.target ? eventOrValue.target.value : eventOrValue
      )
    }
  />
)

const Checkbox = ({ label, value, disabled, onChange, className }) => (
  <div
    className={cx(
      'babylon-component-library__asFormControl__control',
      className
    )}
  >
    <div className="control-wrapper">
      <input
        id={`CHECKBOX_${label}`}
        name={label}
        type="checkbox"
        disabled={!!disabled}
        className={styles.checkbox}
        checked={value}
        onChange={() => onChange(!value)}
      />
      <label htmlFor={`CHECKBOX_${label}`} className="label">
        {label}
      </label>
    </div>
  </div>
)

export const baseWidgets = {
  hidden: () => null,
  text: withOnChange(Input),
  textarea: withOnChange(Textarea),
  checkbox: withOnChange(Checkbox),
  checkboxgroup: withOnChange(Checkboxgroup),
}

const widget = (widgets, props) => {
  const Widget = widgets[props.type]
  const { alerts, ...rest } = props
  const { name } = rest

  return (
    <div
      className={cx(
        styles.widgetWrap,
        `widget-${name}`,
        alerts && alerts[name] && 'alert'
      )}
      key={name}
    >
      <Widget {...rest} />
    </div>
  )
}

const Form = (props) => {
  const {
    className,
    title,
    plugins,
    config,
    state,
    alerts,
    actions: { onSave, onCancel, ...actions },
  } = props
  const allWidgets = { ...baseWidgets, ...plugins }

  return (
    <div className={cx(styles.form, className)}>
      {title ? <h1>{title}</h1> : null}
      {config.fields.map((options) =>
        widget(allWidgets, {
          ...options,
          actions,
          state,
          alerts,
          value: state[options.name],
          formProps: props,
        })
      )}
      {onSave || onCancel ? (
        <div className={styles.buttonBar}>
          {onSave ? (
            <Button type="button" label="Save" onClick={onSave} />
          ) : null}
          {onCancel ? (
            <Button
              type="button"
              label="Cancel"
              buttonStyle="outlined"
              onClick={onCancel}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default Form
