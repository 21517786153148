import React, { ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { useProductConfig } from '@babylon/product-config'
import {
  Alert,
  Snackbar,
  SnackbarIntent,
  Spinner,
  Heading,
} from '@babylon/core-ui'
import { useQuery } from '@apollo/client'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../..'
import SearchResultItem from '../SearchResultItem'
import type { SetSnackbarMessage } from '../../../../components/Member/types'
import { GetConsumerNetworks } from '../../queries'
import messages from './SearchResults.messages'
import styles from './styles.module.scss'

export interface SearchResultItemsProps {
  members: Member[]
  setSnackbarMessage: SetSnackbarMessage
  consumerNetworks: { [key: string]: string }
}

export interface MembersSearchResultsProps {
  errors?: string[]
  list?: Member[]
  loading?: boolean
  pristine: boolean
}

const SpinnerOverlay = () => (
  <div className={styles.SpinnerOverlay}>
    <Spinner
      centered
      size="large"
      color="var(--primary-color)"
      testid="search-results-spinner"
    />
  </div>
)

const SearchResultItems = ({
  members,
  setSnackbarMessage,
  consumerNetworks,
}: SearchResultItemsProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.membersSearchView,
  })
  const [expandedId, setExpandedId] = useState<string>('')
  const onFamilyMembersToggle = (memberId: string) => {
    const isExpanded = memberId === expandedId

    if (!isExpanded) {
      trackClick({
        elementName: 'view-family-accounts-btn',
        elementType: TrackingElementType.button,
      })
    } else {
      trackClick({
        elementName: 'hide-family-accounts-btn',
        elementType: TrackingElementType.button,
      })
    }

    setExpandedId(isExpanded ? '' : memberId)
  }

  return (
    <>
      {members.map(({ node }) => (
        <div key={node.id} className={styles.SearchResultItemWrapper}>
          <SearchResultItem
            consumerNetworkName={
              consumerNetworks?.[node.preferredConsumerNetworkUuid]
            }
            expanded={expandedId === node.id}
            onFamilyMembersToggle={onFamilyMembersToggle}
            setSnackbarMessage={setSnackbarMessage}
            {...node}
          />
        </div>
      ))}
    </>
  )
}

const useConsumerNetworkData = () => {
  const { getProp } = useProductConfig()
  const consumerNetworkTagEnabled = getProp(
    'memberOperations',
    'consumerNetworkTagEnabled'
  )

  const { data: consumerNetworkData } = useQuery(GetConsumerNetworks, {
    skip: !consumerNetworkTagEnabled,
  })

  const consumerNetworkHashTable = consumerNetworkData?.consumerNetworks.reduce(
    (acc, consumerNetwork) => {
      acc[consumerNetwork.uuid] = consumerNetwork.name

      return acc
    },
    {}
  )

  return consumerNetworkHashTable
}

const SearchResults = ({
  errors = [],
  list = [],
  loading,
  pristine,
}: MembersSearchResultsProps) => {
  const consumerNetworks = useConsumerNetworkData()

  const [snackbar, setSnackbar] = useState<Snack>({
    open: false,
    message: null,
    icon: undefined,
    intent: 'primary',
    autoHideDuration: 3000,
  })

  const hasError = !!errors.length
  const withResults = list.length > 0 && !hasError
  const noResults = !withResults && !hasError && !pristine && !loading

  const closeSnackbarHandler = () => {
    setSnackbar({
      ...snackbar,
      open: false,
      message: null,
    })
  }

  const setSnackbarMessageHandler = (
    message?: string,
    icon?: ReactNode,
    intent: SnackbarIntent = 'primary'
  ) => {
    setSnackbar({
      ...snackbar,
      message,
      open: !!message,
      icon,
      intent,
    })
  }
  const fm = useFormatMessage()

  return (
    <>
      <Snackbar
        data-testid="snackbar"
        autoHideDuration={snackbar.autoHideDuration}
        className={styles.snackbar}
        icon={snackbar.icon}
        intent={snackbar.intent}
        message={snackbar.message}
        open={snackbar.open}
        onClose={closeSnackbarHandler}
      />
      <div data-testid="search-results" className={styles.SearchResults}>
        {loading && <SpinnerOverlay />}
        {withResults && (
          <div className={loading ? styles.LoadingSearchResults : undefined}>
            <SearchResultItems
              consumerNetworks={consumerNetworks}
              members={list}
              setSnackbarMessage={setSnackbarMessageHandler}
            />
          </div>
        )}

        {noResults && (
          <Heading level="h2">{fm(messages.no_patients_found)}</Heading>
        )}

        {hasError && (
          <Alert className={styles.Alert} intent="error" title="Errors">
            <ul data-testid="search-results-errors">
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}

        {(hasError || noResults) && (
          <Alert
            className={styles.Alert}
            intent="primary"
            title={fm(messages.title_improve_search_results)}
          >
            <ul data-testid="search-results-alert-text">
              <li>
                <FormattedMessage
                  {...messages.body_improve_search_results}
                  values={{
                    b: (chunks: any) => <strong>{chunks}</strong>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  {...messages.body_improve_search_results_two}
                  values={{
                    b: (chunks: any) => <strong>{chunks}</strong>,
                  }}
                />
              </li>
            </ul>
          </Alert>
        )}
      </div>
    </>
  )
}

export default SearchResults
