import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Box, ButtonSimple, Text } from '@babylon/medkit'

import messages from './messages'

import styles from './ErrorAssessmentStage.module.scss'

const ErrorAssessmentStage = ({ loading, refetch }: Props) => {
  const fm = useFormatMessage()

  return (
    <Box className={styles.container}>
      <Text className={styles.description} variant="body">
        {fm(messages.description)}
      </Text>
      <ButtonSimple
        isLoading={loading}
        loadingLabel=""
        onClick={() => refetch()}
      >
        {fm(messages.reload)}
      </ButtonSimple>
    </Box>
  )
}

interface Props {
  loading: boolean
  refetch: () => void
}

export default ErrorAssessmentStage
