import React, { FC } from 'react'
import Header from '../Header'
import styles from './Timeline.module.scss'

export interface TimelineBlockProps {
  testId: string
  title: string
  info?: string
}

const TimelineBlock: FC<TimelineBlockProps> = ({
  children,
  info,
  testId,
  title,
}) => (
  <div className={styles.timeline__block} data-testid={`timeline-${testId}`}>
    <div className={styles.timeline__heading}>
      <Header title={title} info={info} level="h2" />
    </div>
    <div className={styles.timeline__content}>{children}</div>
  </div>
)

export default TimelineBlock
