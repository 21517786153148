import React from 'react'

import Form from '@/components/old-forms/Form'

import styles from './styles.module.scss'

const DrugEditView = (props) => {
  const defaultOptions = [{ label: 'N/A', value: 'N/A' }]
  props.adminDrugForm.config.fields[3].options =
    props.drugInfo.drugForms || defaultOptions
  props.adminDrugForm.config.fields[0].disabled = !props.isNew

  return (
    <div className={styles.drugEdit}>
      <Form {...props} {...props.adminDrugForm} />
    </div>
  )
}

export default DrugEditView
