import { gql } from '@apollo/client'

export const CONSUMER_NETWORKS_QUERY = gql`
  query AP_ConsumerNetworks($patientId: ID!) {
    patientConsumerNetworks(id: $patientId) {
      id
      active
      can_be_cancelled
      can_be_deregistered
      consumer_network {
        id
        name
      }
      gp_at_hand
      history {
        id
        created_at
        deleted_at
      }
      is_default
      is_preferred
      is_retired
      started_on
      plan {
        id
        title
      }
      subscription {
        id
        can_be_cancelled
      }
      credit_card {
        cardType
        last4
      }
    }
  }
`

export const PATIENT_QUERY = gql`
  query AP_Patient($patientId: ID!) {
    patient(id: $patientId) {
      id
      queued_for_activation
      public_healthcare_admission {
        consumer_network_id
      }
    }
  }
`
export const CANCEL_GP_AT_HAND = gql`
  mutation AP_CancelGPAtHand($patientId: ID!) {
    cancelGPAtHand(patientId: $patientId) {
      message
    }
  }
`
export const DEREGISTER_GP_AT_HAND = gql`
  mutation AP_DeregisterGPAtHand($patientId: ID!) {
    deregisterGPAtHand(patientId: $patientId) {
      message
    }
  }
`
export const REJOIN_CONSUMER_NETWORK = gql`
  mutation AP_RejoinConsumerNetwork($input: PatientConsumerNetworkPayload!) {
    rejoinConsumerNetwork(input: $input) {
      active
    }
  }
`
export const REMOVE_CONSUMER_NETWORK = gql`
  mutation AP_RemoveConsumerNetwork($input: PatientConsumerNetworkPayload!) {
    removeConsumerNetwork(input: $input) {
      active
    }
  }
`
export const SELECT_CONSUMER_NETWORK = gql`
  mutation AP_SelectConsumerNetwork($input: PatientConsumerNetworkPayload!) {
    selectConsumerNetwork(input: $input) {
      active
    }
  }
`
export const GPAH_CONSUMER_NETWORKS = gql`
  query AP_gpahConsumerNetworks {
    gpahConsumerNetworks {
      id
      name
    }
  }
`
export const ADD_PATIENT_TO_CONSUMER_NETWORK = gql`
  mutation AP_AddPatientToConsumerNetwork(
    $input: PatientConsumerNetworkPayload!
  ) {
    addPatientToConsumerNetwork(input: $input) {
      consumer_network {
        id
        name
      }
    }
  }
`
export const CANCEL_PATIENT_SUBSCRIPTION = gql`
  mutation AP_CancelPatientSubscription($patientId: ID!, $subscriptionId: ID!) {
    cancelPatientSubscription(
      patientId: $patientId
      subscriptionId: $subscriptionId
    ) {
      id
    }
  }
`
