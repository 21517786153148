import { defineMessages } from 'react-intl'

const baseId = 'practice_searcher'

export default defineMessages({
  no_results_found: {
    id: `${baseId}.no_results_found`,
    defaultMessage: 'No results found',
  },
  search_placeholder: {
    id: `${baseId}.search_placeholder`,
    defaultMessage: 'Search for a practice',
  },
})
