import { defineMessages } from 'react-intl'

const baseId = 'assessment_scores'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Scores',
  },
  score: {
    id: `${baseId}.score`,
    defaultMessage: 'Score: {score}',
  },
  whatThisMeans: {
    id: `${baseId}.whatThisMeans`,
    defaultMessage: 'What this means: {severityText}',
  },
  scoreIsNotAvailable: {
    id: `${baseId}.scoreIsNotAvailable`,
    defaultMessage: 'The score is not available',
  },
})
