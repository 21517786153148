/* eslint-disable curly */
/* eslint-disable padding-line-between-statements */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Page } from '@babylon/core-ui'
import { useBabylonUser } from '@babylon/babylon-user'

import { useAgentLiveConversations, useAtlasStore } from '@babylon/atlas.js'

import { Toaster } from 'react-hot-toast'
import styles from './styles.module.css'

import useAgentName from './useAgentName'
import useEnableNotifications from './useEnableNotifications'
import useWindowFocus from '../../hooks/useWindowFocus'
import useOnUnmount from '../../hooks/useOnUnmount'
import { getAgentNickname } from '../../utils'

import notificationSoundFile from './notification-sound.mp3'
import newUserSoundFile from './new-user-sound.mp3'
import AgentStatus from './components/AgentStatus/AgentStatus'
import { WaitingForPair } from './components'
import AgentLiveConversations from './components/Conversation/AgentLiveConversations'
import { LiveConversationsProvider } from '../..'

const notificationSound = new Audio(notificationSoundFile)
function playNotificationSound() {
  notificationSound.play()
}

const newUserSound = new Audio(newUserSoundFile)

function playNewUserSound() {
  newUserSound.play()
}

const LiveConversationPage = () => {
  const [windowFocused, setWindowFocused] = useState(true)
  const onFocus = useCallback(() => {
    setWindowFocused(true)
  }, [])
  const onBlur = useCallback(() => {
    setWindowFocused(false)
  }, [])
  useWindowFocus({ onFocus, onBlur })

  const {
    requestNotificationPermissions,
    sendMatchNotification,
    sendMessageNotification,
  } = useEnableNotifications()

  const { uuid, clinician } = useBabylonUser()
  const agentName = useAgentName(uuid, clinician)
  const agentNickname = getAgentNickname(agentName)

  const {
    allConversations,
    requestGoOffline,
    requestGoOnline,
    onlineStatus,
    queues,
    queuesStatus,
    fetchQueues,
    closeConversationTab,
  } = useAgentLiveConversations(agentNickname || 'Care Team Member')
  const online = onlineStatus === 'ONLINE'
  const [activeConversationId, setActiveConversationId] = useState<
    string | null
  >(null)

  const { conversations: stateConversations } = useAtlasStore((state) => state)
  const allActionableConversations = Object.values(allConversations).filter(
    (conversation) => {
      if (conversation.status === 'CLOSED') {
        return stateConversations[conversation.conversationId] !== undefined
      }

      return true
    }
  )

  useEffect(() => {
    const promptBeforeUnload = (event) => {
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''
      return ''
    }

    const offlineOnUnload = () => {
      requestGoOffline(true)
    }

    window.addEventListener('beforeunload', promptBeforeUnload)
    window.addEventListener('unload', offlineOnUnload)
    return () => {
      window.removeEventListener('beforeunload', promptBeforeUnload)
      window.removeEventListener('unload', offlineOnUnload)
    }
  }, [requestGoOffline])

  // called when page is unmounted (internal navigation change)
  useOnUnmount(() => {
    requestGoOffline(true)
  }, [requestGoOffline])

  const possibleConversation = allActionableConversations.length > 0

  const liveConversations = useMemo(
    () =>
      Object.values(allActionableConversations).sort(
        (a, b) => (a.timestamp?.getTime() || 0) - (b.timestamp?.getTime() || 0)
      ),
    [allActionableConversations]
  )

  const handleCloseConversation = useCallback(
    (memberId: string) => {
      const removedConversationIndex = liveConversations.findIndex(
        (conversation) => conversation.memberId === memberId
      )
      const existingConversations = (liveConversations || []).filter(
        (conversation) => conversation.memberId !== memberId
      )
      const newActiveConversation =
        existingConversations[removedConversationIndex] ||
        existingConversations[removedConversationIndex - 1] ||
        existingConversations[0]
      if (newActiveConversation) {
        setActiveConversationId(newActiveConversation.conversationId)
      } else {
        setActiveConversationId(null)
      }
      closeConversationTab(memberId)
    },
    [liveConversations, closeConversationTab]
  )

  useEffect(() => {
    if (activeConversationId === null && liveConversations.length > 0) {
      setActiveConversationId(liveConversations[0].conversationId)
    }
  }, [activeConversationId, liveConversations])

  return (
    <Page>
      <div className={styles.page}>
        <AgentStatus
          possibleConversation={possibleConversation}
          onlineStatus={onlineStatus}
          agentName={agentName}
          online={online}
          requestNotificationPermissions={requestNotificationPermissions}
          requestGoOffline={requestGoOffline}
          requestGoOnline={requestGoOnline}
        />
        {!possibleConversation ? (
          <WaitingForPair online={online} />
        ) : (
          <AgentLiveConversations
            liveConversations={liveConversations}
            uuid={uuid}
            sendMessageNotification={sendMessageNotification}
            playNotificationSound={playNotificationSound}
            windowFocused={windowFocused}
            fetchQueues={fetchQueues}
            queues={queues}
            queuesStatus={queuesStatus}
            playNewUserSound={playNewUserSound}
            sendMatchNotification={sendMatchNotification}
            removeConversationTab={handleCloseConversation}
            activeConversationId={activeConversationId}
            setActiveConversationId={setActiveConversationId}
          />
        )}
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          toastOptions={{
            duration: 5000,
            style: {
              background: '#222222',
              color: '#fff',
            },
          }}
        />
      </div>
    </Page>
  )
}

const LiveConversationPageWrapper = () => (
  <LiveConversationsProvider>
    <LiveConversationPage />
  </LiveConversationsProvider>
)

export default LiveConversationPageWrapper
