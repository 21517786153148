import React from 'react'
import { useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import { FormattedMessage } from 'react-intl'
import {
  Button,
  Modal,
  FormikInput,
  FormikRadioGroup,
  Radio,
} from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import {
  logException,
  useSnackBar,
  MemberOpsModuleName,
} from '../../../../../../..'

import messages from './messages'
import styles from './styles.module.scss'
import { APPLY_REDEMPTIONS } from './queries'

const PromoCodeModal = ({
  patientId,
  close,
}: {
  patientId: string
  close: () => void
}) => {
  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })
  const { setSnackbarMessage } = useSnackBar()

  const [applyRedemptions] = useMutation(APPLY_REDEMPTIONS, {
    onError: (e) => {
      logException(e)
      close()
      setSnackbarMessage(fm(messages.redemption_error), null, 'error')
    },
    onCompleted: (response) => {
      close()
      response.applyRedemptionV1
        ? setSnackbarMessage(fm(messages.redemption_success), null, 'success')
        : setSnackbarMessage(fm(messages.redemption_error), null, 'error')
    },
  })

  return (
    <Modal
      open
      label={fm(messages.redeem_code)}
      onClose={close}
      className={styles.ModalContainer}
      includeClose
    >
      <Formik
        initialValues={{
          redemptionType: 'membership',
          code: '',
        }}
        onSubmit={({ code, redemptionType }) => {
          applyRedemptions({
            variables: {
              code,
              patientId,
              redemptionType,
            },
          })
          trackClick({
            elementName: 'redeem-code-submit-button',
            elementType: TrackingElementType.button,
          })
        }}
      >
        {({ handleSubmit }) => (
          <Form>
            <FormikRadioGroup
              label={
                <h1 className={styles.ModalContainer__title}>
                  <FormattedMessage {...messages.redeem_code} />
                </h1>
              }
              name="redemptionType"
              id="redemptionType"
              columns={2}
            >
              <Radio value="membership">
                <FormattedMessage {...messages.radio_membership} />
              </Radio>
              <div data-testid="promotion">
                <Radio value="promotion">
                  <FormattedMessage {...messages.radio_promotional} />
                </Radio>
              </div>
            </FormikRadioGroup>
            <FormikInput
              type="text"
              name="code"
              label={fm(messages.enter_code)}
            />
            <Button
              className={styles.ModalContainer__cta}
              fill
              onClick={() => handleSubmit()}
            >
              <FormattedMessage {...messages.confirm_code} />
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default PromoCodeModal
