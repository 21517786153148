import React from 'react'

import { Spinner } from '@babylon/core-ui'
import styles from './styles.module.scss'

const withSpinner = (isLoading) => (Component) => (props) => {
  const loading = isLoading
    ? isLoading(props)
    : props.data && props.data.loading

  return loading ? (
    <div className={styles.spinnerContainer}>
      <Spinner size="large" centered />
    </div>
  ) : (
    <Component {...props} />
  )
}

export default withSpinner
