import React from 'react'
import { BabylonIntl } from '@babylon/intl'
import { SpinnerLoader } from '@babylon/core-ui'

import {
  DEFAULT_LOCALE,
  getMessagesOnLocaleChange,
  getLocale,
} from '@/util/intl'
import { usePseudotranslate } from '../../hooks'

interface LocaleMessageLoaderProps {
  children: JSX.Element
}

const OnIntlReady = () => {
  usePseudotranslate()
  return null
}

export default function LocaleMessageLoader({
  children,
}: LocaleMessageLoaderProps) {
  const initialLocale = getLocale()

  return (
    <BabylonIntl
      textComponent="span"
      initialLocale={
        initialLocale === 'en_TEST' ? DEFAULT_LOCALE : initialLocale
      }
      getMessagesOnLocaleChange={getMessagesOnLocaleChange}
      loadingComponent={SpinnerLoader}
    >
      <OnIntlReady />
      {children}
    </BabylonIntl>
  )
}
