import { defineMessages } from 'react-intl'

const baseId = 'profile_header.delete_patient.modal_one'

export default defineMessages({
  header: {
    id: `${baseId}.header`,
    defaultMessage:
      'Are you sure you want to delete personal data relating to this patient?',
  },
  content: {
    id: `${baseId}.content`,
    defaultMessage:
      'This will permanently delete the patients data. This action is irreversible.',
  },
  confirm: {
    id: `${baseId}.confirm`,
    defaultMessage: 'Yes, delete patients data',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'No, cancel and keep data',
  },
})
