/* eslint-disable react/prop-types */
/* eslint-disable curly */
/* eslint-disable padding-line-between-statements */
import React from 'react'

import { Heading } from '@babylon/core-ui'
import { ToggleSwitch } from '@babylon/medkit'

import styles from '../../styles.module.css'

const AgentStatus = ({
  possibleConversation,
  onlineStatus,
  agentName,
  online,
  requestNotificationPermissions,
  requestGoOffline,
  requestGoOnline,
}) => {
  const toggleDisabled =
    onlineStatus === 'CONNECTING' ||
    onlineStatus === 'DISCONNECTING' ||
    possibleConversation
  return (
    <header style={{ display: 'flex', alignItems: 'center' }}>
      <Heading level="h2">Live Chat</Heading>
      <div style={{ margin: 'auto' }} />
      {!possibleConversation && agentName !== undefined && (
        <>
          <Heading level="h3" style={{ marginRight: 12 }}>
            {online ? 'Online' : 'Offline'}
          </Heading>
          <ToggleSwitch
            className={styles.onlineToggle}
            checked={online}
            onClick={() => {
              requestNotificationPermissions()

              if (toggleDisabled) {
                return
              }

              if (online) {
                requestGoOffline()
              } else {
                requestGoOnline()
              }
            }}
          />
        </>
      )}
      {possibleConversation && (
        <Heading level="h3" className={styles.inConversation}>
          In Conversation
        </Heading>
      )}
    </header>
  )
}
export default AgentStatus
