import React, { useState, useReducer } from 'react'
import { useMutation } from '@apollo/client'
import { User, useBabylonUser } from '@babylon/babylon-user'
import { Link } from 'react-router-dom'
import {
  Card,
  Spinner,
  Text,
  Button,
  ConfirmationModal,
} from '@babylon/core-ui'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-regular-svg-icons'
import { useProductConfig } from '@babylon/product-config'
import { HumanitySyncStatusEnum } from '@babylon/graphql-middleware-types'
import Overlay from '../Overlay'
import { radioValues } from '../AvailabilitySyncPage/AvailabilitySyncPage'
import { DisplayCard } from '../AvailabilitySyncPage/AvailabilitySyncPage.module.css'
import styles from './SyncStatus.module.css'

import SyncCanceller from '../SyncCanceller'

import SyncHumanityMutation, {
  SyncHumanityData,
  SyncHumanityDataItem,
} from './SyncHumanityMutation'

import { useGetHumanitySyncStatusQuery } from './GetHumanitySyncStatusQuery.hooks'

const POLL_INTERVAL = 5000 // ms

export interface SyncStatusProps {
  autoSyncEnabled: boolean
  overlayHidden: boolean
  startDate: Date | null
  endDate: Date | null
  clinicians: SyncHumanityDataItem[]
  supplyNetworks: SyncHumanityDataItem[]
  professions: SyncHumanityDataItem[]
  selectClinician: string
  selectSupplyNetwork: string
  selectProfession: string
  selectPractice?: string
  practices: SyncHumanityDataItem[]
  afterSyncCreated?: () => void
  afterSyncUpdated?: () => void
}

const SyncStatus = ({
  autoSyncEnabled,
  overlayHidden,
  startDate,
  endDate,
  clinicians,
  supplyNetworks,
  professions,
  afterSyncCreated,
  afterSyncUpdated,
  selectClinician,
  selectSupplyNetwork,
  selectProfession,
  selectPractice,
  practices,
}: SyncStatusProps) => {
  const user = useBabylonUser() as User
  const [humanitySyncId, setHumanitySyncId] = useState<string | null>(null)
  const [syncStatus, setSyncStatus] = useState<HumanitySyncStatusEnum>(
    HumanitySyncStatusEnum.Pending
  )

  const [orphanedCreatedCount, setOrphanedCreatedCount] = useState<number>()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const initialState = {
    running: false,
    finished: false,
    error: false,
    cancelled: false,
  }
  const { getProp } = useProductConfig()

  const displayOrphanCountEnabled = getProp(
    'supplyAndScheduling',
    'displayOrphanCountEnabled'
  )

  const reducer = (_, action) => {
    switch (action.type) {
      case 'running': {
        return {
          ...initialState,
          running: true,
        }
      }
      case 'finished': {
        return {
          ...initialState,
          finished: true,
        }
      }
      case 'error': {
        return {
          ...initialState,
          finished: true,
          error: true,
        }
      }
      case 'cancel': {
        return {
          ...initialState,
          finished: true,
          cancelled: true,
        }
      }
      default: {
        return initialState
      }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const { startPolling, stopPolling } = useGetHumanitySyncStatusQuery({
    notifyOnNetworkStatusChange: true,
    skip: humanitySyncId == null,
    variables: humanitySyncId ? { id: humanitySyncId } : undefined,
    onCompleted: (res) => {
      if (
        !res.humanitySyncStatus?.status ||
        res.humanitySyncStatus?.orphan_count === null ||
        res.humanitySyncStatus?.orphan_count === undefined
      ) {
        return
      }

      const {
        humanitySyncStatus: { status, orphan_count },
      } = res

      setOrphanedCreatedCount(orphan_count)
      setSyncStatus(status)

      if (afterSyncUpdated) {
        afterSyncUpdated()
      }

      switch (status) {
        case HumanitySyncStatusEnum.Pending:
        case HumanitySyncStatusEnum.InProgress:
          break
        case HumanitySyncStatusEnum.Completed: {
          stopPolling()
          dispatch({ type: 'finished' })

          break
        }
        case HumanitySyncStatusEnum.Failed: {
          stopPolling()
          dispatch({ type: 'error' })

          break
        }
        case HumanitySyncStatusEnum.Cancelled: {
          stopPolling()
          dispatch({ type: 'cancel' })

          break
        }
        default: {
          stopPolling()
          dispatch({ type: 'reset' })

          break
        }
      }
    },
    onError: () => {
      stopPolling()
      dispatch({ type: 'error' })
    },
  })

  const [humanitySync] = useMutation(SyncHumanityMutation, {
    onCompleted: ({ syncHumanity }) => {
      setHumanitySyncId(syncHumanity.humanity_sync_id)
      startPolling(POLL_INTERVAL)

      if (afterSyncCreated) {
        afterSyncCreated()
      }
    },
    onError: () => {
      dispatch({ type: 'error' })
    },
  })

  const requiredAutoSyncFilter = { userId: user.id }

  const filters: Partial<SyncHumanityData> = autoSyncEnabled
    ? requiredAutoSyncFilter
    : {
        userId: user.id,
        fromDate: format(startDate || new Date(), 'yyyy-MM-dd'),
        toDate: format(endDate || new Date(), 'yyyy-MM-dd'),
        clinicians,
        supplyNetworks,
        professions,
      }

  if (selectPractice) {
    filters.practices = practices
  }

  const isSelectClinicianUnselected =
    selectClinician === radioValues.individual && !clinicians.length

  const isSupplyNetworksUnselected =
    selectSupplyNetwork === radioValues.individual && !supplyNetworks.length

  const isProfessionUnselected =
    selectProfession === radioValues.individual && !professions.length

  const isPracticeUnselected =
    selectPractice &&
    selectPractice === radioValues.individual &&
    !practices.length

  const unselectedFiltersExist = () =>
    isSupplyNetworksUnselected ||
    isSelectClinicianUnselected ||
    isProfessionUnselected ||
    isPracticeUnselected ||
    state.running

  const isSyncButtonDisabled = () => {
    if (autoSyncEnabled) {
      return state.running
    }

    return unselectedFiltersExist()
  }

  const handleStartSync = async () => {
    setIsConfirmationModalOpen(true)
  }

  const handleConfirmStartSync = async () => {
    setIsConfirmationModalOpen(false)
    dispatch({ type: 'running' })

    await humanitySync({ variables: { filters } })
  }

  // Only show confirmation modal when auto sync is disabled
  const handleSync = autoSyncEnabled ? handleConfirmStartSync : handleStartSync

  return (
    <Card
      className={DisplayCard}
      title="3. Status"
      actions={
        <Button
          data-testid="sync-button"
          onClick={handleSync}
          disabled={isSyncButtonDisabled()}
          icon={<FontAwesomeIcon icon={faSync} />}
        >
          Sync
        </Button>
      }
    >
      {state.running && (
        <div className={styles.StatusSpinner}>
          <Spinner centered />
          <Text tag="div" className={styles.StatusLabel}>
            Sync requested...
          </Text>
          <Text
            data-testid="sync-status"
            tag="div"
            className={styles.StatusLabel}
          >
            <strong>Status:</strong> {syncStatus}
          </Text>

          {humanitySyncId && (
            <SyncCanceller
              syncStatus={syncStatus}
              syncId={humanitySyncId}
              callback={afterSyncUpdated}
            />
          )}
        </div>
      )}

      {state.finished && (
        <div data-testid="finished-text">
          <Text size="medium" tag="div">
            {state.error && 'Sync has errors'}
            {state.cancelled && 'Sync was cancelled'}
            {!state.error && !state.cancelled && 'Sync complete'}
          </Text>
          {!state.error && !state.cancelled && (
            <>
              {startDate && endDate ? (
                <Text>
                  Your sync has completed for the dates{' '}
                  {format(startDate, 'MMM dd, yyyy')} -{' '}
                  {format(endDate, 'MMM dd, yyyy')}.
                </Text>
              ) : null}
              {displayOrphanCountEnabled && orphanedCreatedCount ? (
                <>
                  <br />
                  <Text>
                    <Link to="/admin/orphaned_appointments_new">
                      Orphaned appointments created: {orphanedCreatedCount}
                    </Link>

                    <Text size="small">
                      <br />
                      Note: This number confirms the total orphaned appointments
                      from this unique sync. The number of orphaned appointments
                      created by this sync will not change once it has been
                      completed. Please re-sync in order to see an updated
                      number of orphaned appointments.
                    </Text>
                  </Text>
                </>
              ) : null}
            </>
          )}
          {state.error && <Text>There were errors attempting the sync.</Text>}
          {state.cancelled && (
            <Text>The sync was manually cancelled by a user action.</Text>
          )}
        </div>
      )}
      <ConfirmationModal
        open={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false)
        }}
        onConfirm={handleConfirmStartSync}
      >
        <ConfirmationModal.Heading>
          Please confirm you are happy with the following:
        </ConfirmationModal.Heading>
        <ConfirmationModal.Content>
          <p>
            Start Date: {startDate && format(startDate, 'MMM dd, yyyy')} <br />
            End Date: {endDate && format(endDate, 'MMM dd, yyyy')} <br />
            Clinicians:{' '}
            {selectClinician === radioValues.all
              ? 'All'
              : clinicians.map((c) => c.name).join(', ')}
            <br />
            Supply Networks:{' '}
            {selectSupplyNetwork === radioValues.all
              ? 'All'
              : supplyNetworks.map((s) => s.name).join(', ')}
            <br />
            Professions:{' '}
            {selectProfession === radioValues.all
              ? 'All'
              : professions.map((p) => p.name).join(', ')}
            {selectPractice && (
              <>
                <br />
                Practices:{' '}
                {selectPractice === radioValues.all
                  ? 'All'
                  : practices.map((p) => p.name).join(', ')}
              </>
            )}
          </p>
        </ConfirmationModal.Content>
      </ConfirmationModal>
      <Overlay data-testid="overlay-card3" hidden={overlayHidden} />
    </Card>
  )
}

export default SyncStatus
