import { defineMessages } from 'react-intl'

const baseId = 'timeline_test_results_status'

export default defineMessages({
  PENDING: {
    id: `${baseId}.pending`,
    defaultMessage: 'Pending',
  },
  COMPLETED: {
    id: `${baseId}.completed`,
    defaultMessage: 'Completed',
  },
})
