import React, { useMemo } from 'react'

import { addMinutes, format, isWithinInterval, isEqual } from 'date-fns'

import { AvailabilitySlotType } from '../GetClinicianAvailability'
import formatAvailabilitySlot, {
  AppointmentSlotType,
} from './formatAvailabilitySlot'
import {
  useReallocateShiftErrorManager,
  ERROR_MESSAGES,
} from '../../ReallocateShiftErrorManager'
import ShiftSlot from '../../ShiftSlot'
import { ShiftSlotDurationTypes } from '../../ShiftSlot/ShiftSlotDuration'

export const isSlotOverflowingRange = (
  startRange: Date,
  endRange: Date,
  startSlot: Date,
  endSlot: Date
): boolean => {
  /*
   * isWithinInterval considers true if slot times match range times
   * Adding an extra check that the start and end range are not the same as
   * slot start and end time.
   */
  if (
    !isEqual(startRange, startSlot) &&
    !isEqual(startRange, endSlot) &&
    isWithinInterval(startRange, {
      start: startSlot,
      end: endSlot,
    })
  ) {
    return true
  }

  if (
    !isEqual(endRange, startSlot) &&
    !isEqual(endRange, endSlot) &&
    isWithinInterval(endRange, {
      start: startSlot,
      end: endSlot,
    })
  ) {
    return true
  }

  return false
}
interface ClinicianAvailabilityProps {
  availabilitySlots: AvailabilitySlotType[]
  date: string
  shiftStart: string
  shiftEnd: string
}

export default function ClinicianAvailability({
  availabilitySlots,
  date,
  shiftStart,
  shiftEnd,
}: ClinicianAvailabilityProps) {
  const appointmentSlots = useMemo<AppointmentSlotType[]>(
    () => formatAvailabilitySlot(availabilitySlots, date),
    [date, availabilitySlots]
  )

  const { setErrorMessage } = useReallocateShiftErrorManager()

  /*
   * Wrapping return in a fragment to bypass Typescript issue with components that return arrays
   * c.f. https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
   */
  return (
    <>
      {appointmentSlots.map((slot) => {
        const warningType = isSlotOverflowingRange(
          new Date(`${date} ${shiftStart}`),
          new Date(`${date} ${shiftEnd}`),
          slot.time,
          addMinutes(new Date(slot.time), slot.duration_minutes)
        )
          ? 'overflow'
          : undefined

        if (warningType) {
          setErrorMessage(ERROR_MESSAGES[warningType])
        }

        return (
          <ShiftSlot
            data-testid="appointment-slot"
            key={`${slot.time}`}
            time={format(new Date(slot.time), 'HH:mm')}
            type={slot.type}
            duration={slot?.duration_minutes as ShiftSlotDurationTypes}
            warning={warningType}
          />
        )
      })}
    </>
  )
}
