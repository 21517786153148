import { gql } from '@apollo/client'

export default gql`
  query MemberUuid($memberId: ID!) {
    patient(id: $memberId) {
      id
      uuid
    }
  }
`
