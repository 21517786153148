/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import classNames from 'classnames/bind'
import { Button, Heading, Spinner } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import partition from 'lodash/partition'
import { Header } from '../../../..'
import { isQueued } from '../../../utils'
import { OnActionClick } from '../../../types'
import LoadingConsumerNetworksCard from './LoadingConsumerNetworksCard'
import Membership from './Membership'
import styles from './ConsumerNetworksCard.module.scss'
import messages from './ConsumerNetworksCard.messages'
import { ConsumerNetworkMembership, Member } from '../../../../../app'
import { MemberOpsModuleName } from '../../../../..'
import { CONSUMER_NETWORKS_QUERY, PATIENT_QUERY } from './queries'

const cx = classNames.bind(styles)

function useConsumerNetworkQueries(patientId: string) {
  const [hasPatientData, setHasPatientData] = useState(false)
  const [hasNetworksData, setHasNetworksData] = useState(false)

  const {
    data: consumerNetworkData,
    loading: consumerNetworkLoading,
    error: consumerNetworkError,
    refetch: consumerNetworkRefetch,
  } = useQuery(CONSUMER_NETWORKS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { patientId },
    onCompleted: (data) => {
      if (data?.patientConsumerNetworks) {
        setHasNetworksData(true)
      }
    },
  })

  const {
    data: patientData,
    loading: patientLoading,
    error: patientError,
  } = useQuery(PATIENT_QUERY, {
    variables: { patientId },
    onCompleted: (data) => {
      if (data?.patientConsumerNetworks) {
        setHasPatientData(true)
      }
    },
  })

  return {
    patient: patientData?.patient,
    consumerNetworks: consumerNetworkData?.patientConsumerNetworks,
    consumerNetworkRefetch,
    isLoading: consumerNetworkLoading || patientLoading,
    isError: consumerNetworkError || patientError,
    hasData: hasNetworksData && hasPatientData,
  }
}

export interface ConsumerNetworkCardsProps {
  patientId: string
}

export interface ConsumerNetworksProps {
  networks: ConsumerNetworkMembership[]
  patient: Member
  onActionClick: OnActionClick
}

const ConsumerNetworks = ({
  patient,
  networks,
  onActionClick,
}: ConsumerNetworksProps) => {
  const [active, notActive] = partition(networks, (network) => network.active)
  const sortedActiveConsumerNetworks = active.sort(
    (a, b) => Number(b.is_preferred) - Number(a.is_preferred)
  )
  const [showPastConsumerNetworks, setShowPastConsumerNetworks] = useState(
    false
  )
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.consumerNetworks,
  })
  const fm = useFormatMessage()
  const { id, public_healthcare_admission, queued_for_activation } = patient

  return (
    <>
      <div
        data-testid="consumer-networks-active"
        className={cx('consumerNetworks--active')}
      >
        {sortedActiveConsumerNetworks.map((consumerNetwork) => (
          <Membership
            {...consumerNetwork}
            key={consumerNetwork.consumer_network.id}
            patientId={id}
            isQueued={isQueued(
              consumerNetwork.consumer_network,
              public_healthcare_admission,
              queued_for_activation
            )}
            onActionClick={onActionClick}
            classNames={[cx('separateConsumerNetwork')]}
          />
        ))}
      </div>
      {!!notActive.length &&
        (showPastConsumerNetworks ? (
          <>
            <Heading
              level="h2"
              className={cx('consumerNetworks--past__heading')}
            >
              {fm(messages.past_consumer_networks_header)}
            </Heading>
            <div
              data-testid="consumer-networks-past"
              className={styles.consumerNetworks}
            >
              {notActive.map((consumerNetwork) => (
                <Membership
                  {...consumerNetwork}
                  key={consumerNetwork.consumer_network.id}
                  patientId={id}
                  isQueued={isQueued(
                    consumerNetwork.consumer_network,
                    public_healthcare_admission,
                    queued_for_activation
                  )}
                  onActionClick={onActionClick}
                  classNames={[cx('membership--grouped')]}
                />
              ))}
            </div>
            <Button
              intent="link"
              className={styles.consumerNetworks__hidePastNetworks}
              onClick={() => {
                setShowPastConsumerNetworks(false)
                trackClick({
                  elementName: 'hide-past-consumer-networks-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              testId="consumer-networks-hide-past-networks"
            >
              {fm(messages.hide_past_consumer_networks)}
            </Button>
          </>
        ) : (
          <Button
            intent="link"
            className={styles.consumerNetworks__showPastNetworks}
            onClick={() => {
              setShowPastConsumerNetworks(true)
              trackClick({
                elementName: 'show-past-consumer-networks-btn',
                elementType: TrackingElementType.button,
              })
            }}
            testId="consumer-networks-show-past-networks"
          >
            {fm(messages.show_past_consumer_networks_button)}
          </Button>
        ))}
    </>
  )
}

const ConsumerNetworksCard = ({ patientId }: ConsumerNetworkCardsProps) => {
  const fm = useFormatMessage()

  const {
    patient,
    consumerNetworks,
    isLoading,
    isError,
    consumerNetworkRefetch,
    hasData,
  } = useConsumerNetworkQueries(patientId)

  if (isError) {
    return null
  }

  if (isLoading && hasData) {
    return <Spinner centered />
  }

  if (isLoading && !hasData) {
    return <LoadingConsumerNetworksCard />
  }

  return (
    <div data-testid="consumer-networks">
      <Header title={fm(messages.header)} info={fm(messages.tooltip)} />
      <ConsumerNetworks
        networks={consumerNetworks}
        patient={patient}
        onActionClick={consumerNetworkRefetch}
      />
    </div>
  )
}

export default ConsumerNetworksCard
