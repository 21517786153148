import { defineMessages } from 'react-intl'

const baseId = 'appointment_invite_card'

export default defineMessages({
  activityLog: {
    id: `${baseId}.activityLog`,
    defaultMessage: 'Activity log',
  },
  appointment_type: {
    id: `${baseId}.appointment_type`,
    defaultMessage: 'Appointment type',
  },
  invitation: {
    id: `${baseId}.invitation`,
    defaultMessage: 'Invitation',
  },
  show_details: {
    id: `${baseId}.show_details`,
    defaultMessage: 'Show details',
  },
  reason_for_appointment: {
    id: `${baseId}.reason_for_appointment`,
    defaultMessage: 'Reason for appointment',
  },
  arrival_instructions: {
    id: `${baseId}.arrival_instructions`,
    defaultMessage: 'Patient instructions',
  },
  no_arrival_instructions: {
    id: `${baseId}.no_arrival_instructions`,
    defaultMessage: 'N/A',
  },
  no_reason: {
    id: `${baseId}.no_reason`,
    defaultMessage: 'N/A',
  },
  notes: {
    id: `${baseId}.notes`,
    defaultMessage: 'Patient instructions',
  },
  guestName: {
    id: `${baseId}.guestName`,
    defaultMessage: 'Caregiver',
  },
  guest_label: {
    id: `${baseId}.guestName_label`,
    defaultMessage: 'Caregiver Invited',
  },
})
