import { gql } from '@apollo/client'

// for if we decide to move to using patient_id instead
export const memberRequest = gql`
  query patient($id: Int!) {
    patient(id: $id) {
      id
      first_name
      last_name
      full_name
      date_of_birth
    }
  }
`

export default gql`
  query appointment($appointmentId: ID!) {
    newAppointment(appointmentId: $appointmentId) {
      listing_details {
        appointment_reason
      }
      patient {
        id
        first_name
        last_name
        full_name
        date_of_birth
      }
    }
  }
`
