import React from 'react'
import Select, { Option, OptionValues } from 'react-select'
import { FormField } from '@babylon/core-ui'

interface Consultant {
  id: string
  name: string
}

const valueRenderedWithPlaceholder = (opt) => (
  <span>
    <span className="value">{opt.label}</span>
    <span className="Select-placeholder" placeholder="Search consultant" />
  </span>
)

const anyConsultant = {
  value: 'any',
  label: 'Any Consultant',
}

const getOptionsFromConsultants = (consultants: Consultant[]) => [
  anyConsultant,
  ...consultants.map((consultant) => ({
    value: consultant.id,
    label: consultant.name,
  })),
]

interface Props {
  className?: string
  disabled?: boolean
  value: string
  onChange: (option: Option<OptionValues>) => void
  consultantList: Consultant[]
  loading?: boolean
}

export default function ConsultantSelector({
  className,
  value,
  consultantList,
  disabled,
  loading,
  onChange,
}: Props) {
  return (
    <FormField
      id="consultant_id"
      label="Consultant"
      className={className}
      data-testid="consultant-selector"
    >
      <Select
        id="consultant_id"
        onChange={(option) => onChange(option?.value || null)}
        options={getOptionsFromConsultants(consultantList)}
        noResultsText="No matching results"
        menuStyle={{ maxHeight: '165px' }}
        backspaceRemoves={false}
        deleteRemoves={false}
        valueRenderer={valueRenderedWithPlaceholder}
        isLoading={loading}
        disabled={loading || disabled}
        value={value}
        searchable
        clearable={false}
      />
    </FormField>
  )
}
