import React, { useState } from 'react'
import { Button, DatePicker, Label, Select } from '@babylon/core-ui'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { SelectOptionType } from '@babylon/core-ui'
import { format, parseISO, startOfMonth, isEqual } from 'date-fns'
import { envFlag } from '@babylon/babylon-env'
import { useIntl } from '@babylon/intl'
import type { DateSelectorProps } from './DateSelector.types'
import {
  computeDayClassName,
  renderDayContents,
  DATE_FORMAT,
} from './DateSelector.utils'
import { useMonthlyCalendarScheduleQuery } from './MonthlyCalendarSchedule.middleware.hooks'

import styles from './DateSelector.module.css'

const DateSelector = ({
  date,
  currentDate,
  timezone,
  timezones,
  onChange,
  practitionerId,
}: DateSelectorProps) => {
  const [firstDayOfMonth, setFirstDayOfMonth] = useState<Date>(
    startOfMonth(parseISO(date))
  )

  const intl = useIntl()

  const appointmentManagementCalendarShiftStyleEnabled = !!envFlag(
    'ENABLE_APPOINTMENT_MANAGEMENT_CALENDAR_SHIFT_STYLE'
  )

  const { data: currentMonthData } = useMonthlyCalendarScheduleQuery({
    variables: {
      consultantId: practitionerId,
      date: firstDayOfMonth.toISOString(),
      timezone,
    },
    skip: !appointmentManagementCalendarShiftStyleEnabled || !practitionerId,
  })

  const shifts = currentMonthData?.monthlyCalendarScheduleV2.shifts ?? []
  const incompleteConsultations =
    currentMonthData?.monthlyCalendarScheduleV2.incompleteConsultations ?? []

  const timezoneOptions = timezones.map((timezoneOption) => ({
    value: timezoneOption,
    label: timezoneOption,
  }))

  const handleMonthChange = (newDate: Date): void => {
    const firstDay = startOfMonth(newDate)

    if (!isEqual(firstDay, firstDayOfMonth)) {
      setFirstDayOfMonth(firstDay)
    }
  }

  const handleDateChange = (newDate: Date | null) => {
    if (!newDate) {
      return
    }

    handleMonthChange(newDate)

    onChange({
      date: format(newDate, DATE_FORMAT),
      timezone,
    })
  }

  const handleTodayButtonClick = () => {
    const newDate = format(currentDate, DATE_FORMAT)

    onChange({
      date: newDate,
      timezone,
    })
  }

  const handleTimezoneChange = (
    option: SelectOptionType | null | undefined
  ) => {
    if (!option) {
      return
    }

    onChange({
      date,
      timezone: option.value,
    })
  }

  return (
    <div className={styles.dateSelector}>
      <DatePicker
        locale={intl.locale}
        id="date"
        label="Date"
        selected={parseISO(date)}
        dateFormat="dd/MM/yyyy"
        onMonthChange={handleMonthChange}
        onChange={handleDateChange}
        icon={
          <FontAwesomeIcon
            icon={faCalendar}
            className={styles.datePickerIcon}
          />
        }
        dayClassName={(date) =>
          computeDayClassName(date, shifts, incompleteConsultations)
        }
        renderDayContents={renderDayContents(shifts, incompleteConsultations)}
      />

      <Button type="button" intent="secondary" onClick={handleTodayButtonClick}>
        Today
      </Button>

      <div className={styles.timezoneInput}>
        <Label htmlFor="time-zone-input">Time Zone</Label>
        <Select
          inputId="time-zone-input"
          options={timezoneOptions}
          value={timezoneOptions.find(
            (timezoneOption) => timezoneOption.value === timezone
          )}
          onChange={handleTimezoneChange}
          isSearchable
        />
      </div>
    </div>
  )
}

export default DateSelector
