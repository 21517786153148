import React from 'react'
import { format, parseISO } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { ChatscriptMessage } from '@babylon/graphql-middleware-types'
import MessageContent from './MessageContent'
import { MessageSender } from './types'
import { groupMessagesByDateAndSender } from './utils'
import styles from './Message.module.scss'
import intlMessages from './Messages.messages'
import { Member } from '../../app'

export interface MessagesProps {
  messages: ChatscriptMessage[]
  patient: Member
}

interface AgentTransferProps {
  agent1: string
  agent2: string
  dateTime: string
}

const AgentTransfer = ({ agent1, agent2, dateTime }: AgentTransferProps) => (
  <div className={styles.ConnectToAgent}>
    <div className={styles.ConnectToAgent__time}>
      {format(parseISO(dateTime), 'HH:mm')}
    </div>
    <FormattedMessage
      {...intlMessages.transferredToAgent}
      values={{
        agent1: (
          <span className={styles.ConnectToAgent__agentName}>{agent1}</span>
        ),
        agent2: (
          <span className={styles.ConnectToAgent__agentName}>{agent2}</span>
        ),
      }}
    />
  </div>
)

type MessageSenderHeadingProps = {
  patientUrl: string
  senderName: string
  isUser: boolean
}

const MessageSenderHeading = ({
  patientUrl,
  senderName,
  isUser,
}: MessageSenderHeadingProps) =>
  isUser ? (
    <Link to={patientUrl} target="_blank">
      {senderName}
    </Link>
  ) : (
    <span>{senderName}</span>
  )

interface AgentConnectedProps {
  agent: string
  dateTime: string
}

const AgentConnected = ({ agent, dateTime }: AgentConnectedProps) => (
  <div className={styles.ConnectToAgent}>
    <div className={styles.ConnectToAgent__time}>
      {format(parseISO(dateTime), 'HH:mm')}
    </div>
    <FormattedMessage
      {...intlMessages.agentConnected}
      values={{
        agent: (
          <span className={styles.ConnectToAgent__agentName}>{agent}</span>
        ),
      }}
    />
  </div>
)

const Messages = ({ messages: rawMessages, patient }: MessagesProps) => {
  const patientUrl = `/admin/patients/${patient.id}/memberships`
  const patientFullname = `${patient.last_name.toLocaleUpperCase()}, ${
    patient.first_name
  }`
  const messagesGroupedByDate = Object.entries(
    groupMessagesByDateAndSender(rawMessages, patientFullname)
  )
  let currentAgent: MessageSender
  let prevAgent: MessageSender

  return (
    <>
      {messagesGroupedByDate.map(([date, groupedMessages]) => (
        <div key={date} data-testid="messages-by-date">
          <div className={styles.Message__date}>{date}</div>
          {groupedMessages.map(({ sender, messages }) => {
            const isUser = sender.type === 'user'
            const senderName = sender.name
            const right = isUser ? '' : '--right'
            let isTransferred = false
            let isConnected = false
            const senderClassName = classnames(styles.Message__name, {
              [styles['Message__name--user']]: isUser,
            })

            if (sender.type === 'agent' && sender.id !== currentAgent?.id) {
              prevAgent = currentAgent
              currentAgent = sender
              isTransferred = !!prevAgent
              isConnected = !prevAgent
            }

            return (
              <React.Fragment key={`${senderName}-${messages[0].id}`}>
                {isTransferred && (
                  <AgentTransfer
                    agent1={prevAgent?.name}
                    agent2={currentAgent?.name}
                    dateTime={messages[0].sentAt}
                  />
                )}
                {isConnected && (
                  <AgentConnected
                    agent={currentAgent?.name}
                    dateTime={messages[0].sentAt}
                  />
                )}
                <div
                  className={`${styles.Message}${right}`}
                  data-testid="messages-by-sender"
                >
                  <div className={senderClassName}>
                    <MessageSenderHeading
                      isUser={isUser}
                      patientUrl={patientUrl}
                      senderName={senderName}
                    />
                  </div>
                  {messages.map((message) => {
                    const time = format(parseISO(message.sentAt), 'HH:mm')

                    return (
                      <React.Fragment key={message.id}>
                        <div
                          data-testid="message-time"
                          className={styles.Message__time}
                        >
                          {time}
                        </div>
                        <div
                          data-testid="message-content"
                          className={`${styles.Message__bubble}${right}`}
                        >
                          <MessageContent {...message} />
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </React.Fragment>
            )
          })}
        </div>
      ))}
    </>
  )
}

export default Messages
