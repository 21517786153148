import { defineMessages } from 'react-intl'

const baseId = 'invite_sent_confirmation'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Invite successfully sent',
  },
  type: {
    id: `${baseId}.type`,
    defaultMessage: 'Type',
  },
  duration: {
    id: `${baseId}.duration`,
    defaultMessage: 'Duration',
  },
  medium: {
    id: `${baseId}.medium`,
    defaultMessage: 'Medium(s)',
  },
  intimate: {
    id: `${baseId}.intimate`,
    defaultMessage: 'Intimate',
  },
  reason: {
    id: `${baseId}.reason`,
    defaultMessage: 'Reason',
  },
  done: {
    id: `${baseId}.done`,
    defaultMessage: 'Done',
  },
})
