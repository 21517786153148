import React, { useState, useEffect } from 'react'
import { Card, ButtonSimple } from '@babylon/medkit'
import { Formik, Form, FormikValues } from 'formik'
import { useFormatMessage } from '@babylon/intl'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { Alert } from '@babylon/core-ui'
import { envFlag } from '@babylon/babylon-env'
import { LoadingContainer } from '../../components'
import { UnexpectedError } from '../../Utils'
import messages from '../DigitalAppointmentForm.messages'
import AppointmentTypeSelect from './AppointmentTypeSelect'
import AppointmentTypeNotes from '../AppointmentTypeNotes'
import { digitalAppointmentFormValidation } from '../digitalAppointmentFormValidation'
import ServiceType from '../../types/ServiceType'
import BookForPatient from './BookForPatient'
import { AppointmentAnalyticsModuleName } from '../../analytics/types'
import DurationSelector from '../../features/ArrangeAppointment/DurationSelector'
import AppointmentMedium, { MediumTypes } from './AppointmentMedium'

import styles from './CreateForm.module.css'
import { PreferredConsumerNetwork } from '../../hooks/usePreferredNetwork/usePreferredNetwork'
import PatientNetworkFootnote from '../PatientNetworkFootnote'

interface FormProps {
  loading: boolean
  appointmentTypes: ServiceType[]
  memberId: string
  initialValues: FormikValues
  onSubmit: (value: FormikValues) => void
  setSelectedMedium: (value: MediumTypes) => void
  selectedMedium: MediumTypes | null
  isSelectMediumDisplayed: boolean
  serviceTypes: ServiceType[]
  patientNetwork?: PreferredConsumerNetwork
}

export default function CreateForm({
  loading,
  onSubmit,
  appointmentTypes = [],
  memberId,
  initialValues,
  selectedMedium,
  setSelectedMedium,
  isSelectMediumDisplayed,
  serviceTypes,
  patientNetwork,
}: FormProps) {
  const [
    selectedAppointmentType,
    setSelectedAppointmentType,
  ] = useState<ServiceType>()
  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: AppointmentAnalyticsModuleName.digitalInvite,
  })

  const memberInstructionEnabled = !!envFlag(
    'ENABLE_GLOBAL_INVITE_MEMBER_INSTRUCTION'
  )

  const globalInviteStyleEnabled = !!envFlag('ENABLE_GLOBAL_INVITE_STYLE')

  const showPatientNetworkInfo = !!envFlag(
    'ENABLE_PATIENT_CONSUMER_NETWORK_INFO_ON_INVITE_SCREEN'
  )

  useEffect(() => {
    if (initialValues.appointment_type && !selectedAppointmentType) {
      const appointmentType = appointmentTypes.find(
        ({ uuid }) => uuid === initialValues.appointment_type
      )
      setSelectedAppointmentType(appointmentType)
    }
  }, [appointmentTypes, initialValues, selectedAppointmentType])

  const onChangeSelectorMedium = (newSelectedMedium: MediumTypes) => {
    setSelectedAppointmentType(undefined)
    setSelectedMedium(newSelectedMedium)
  }

  const memberInstruction = selectedAppointmentType?.member_instructions

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={digitalAppointmentFormValidation}
      onSubmit={onSubmit}
    >
      <Form>
        <UnexpectedError visible={false} onClose={() => {}} />
        <Card vertical>
          <LoadingContainer loading={loading}>
            {isSelectMediumDisplayed && (
              <AppointmentMedium
                selectedMedium={selectedMedium}
                onChange={onChangeSelectorMedium}
              />
            )}
            <AppointmentTypeSelect
              label={fm(messages.appointment_type)}
              appointmentTypes={appointmentTypes}
              placeholder={fm(messages.appointment_type_placeholder)}
              className={styles.Dropdown}
              setSelectedAppointmentType={setSelectedAppointmentType}
            />
            <AppointmentTypeNotes
              label={fm(messages.appointment_reason)}
              helpText={fm(messages.appointment_reason_helper)}
              labelClassName={
                globalInviteStyleEnabled ? styles.labelNotes : undefined
              }
            />
            <DurationSelector
              className={styles.Dropdown}
              defaultDuration={
                selectedAppointmentType?.default_duration_minutes
              }
              recommendDuration={
                !!selectedAppointmentType?.default_duration_minutes
              }
              alternativeDurationMinutes={[]}
              onClickTrack={(duration) => {
                trackClick({
                  elementName: `duration-${duration}-mins`,
                  elementType: TrackingElementType.dropdown,
                })
              }}
              disabled
            />

            {!!memberInstruction && memberInstructionEnabled && (
              <Alert intent="primary" title={memberInstruction} />
            )}
            {showPatientNetworkInfo && !!patientNetwork && (
              <PatientNetworkFootnote patientNetwork={patientNetwork} />
            )}
          </LoadingContainer>
        </Card>
        <div className={styles.buttonRow}>
          <BookForPatient
            onClickTrack={() => {
              trackClick({
                elementName: 'book-for-patient',
                elementType: TrackingElementType.button,
              })
            }}
            memberId={memberId}
            selectedMedium={selectedMedium}
            serviceTypes={serviceTypes}
          />

          <ButtonSimple type="submit">{fm(messages.send_btn)}</ButtonSimple>
        </div>
      </Form>
    </Formik>
  )
}
