enum UserTemplateState {
  CHECKING_REORDER = 'CHECKING_REORDER',
  COLLECTED = 'COLLECTED',
  FOLLOW_UP_NEEDED = 'FOLLOW_UP_NEEDED',
  ORDER_NOW = 'ORDER_NOW',
  READY_TO_COLLECT = 'READY_TO_COLLECT',
  REORDER_SOON = 'REORDER_SOON',
  REORDER_NOW = 'REORDER_NOW',
  STOPPED = 'STOPPED',
}

enum RepeatTemplateState {
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
  PENDING_ON_GP = 'PENDING_ON_GP',
  PENDING_ON_PRESCRIBING_PHARMACIST = 'PENDING_ON_PRESCRIBING_PHARMICIST',
  SUSPENDED = 'SUSPENDED',
}

enum WARNING_STATUSES {
  NO_SHOW = 'no_show',
  CANCELLED = 'cancelled',
  TIMED_OUT = 'timed_out',
}

export type Address = {
  addressLine: string
  city: string
  postalCode: string
  country: string
}

export interface RepeatTemplatePharmacy {
  id: number
  name: string
  phoneNumber: string
  address: Address
}

export interface RepeatTemplate {
  id: string
  daysToReorder: Maybe<number>
  drugName: string
  duration: Maybe<number>
  lastUserChange: string
  repeatState: Maybe<RepeatTemplateState>
  reviewDate: Maybe<string>
  userState: UserTemplateState
  lastAvailabilityDateTime?: string
  latestPharmacy: RepeatTemplatePharmacy | null
}

export interface RepeatTemplateCardProps extends RepeatTemplate {
  patientId: string
  pastActivitesRefetch?: () => void
}
export interface CheckInData {
  check_in_status: string
  checked_in_at: string
}
export interface Consultation {
  id: string
  appointment: {
    id: string
    timezone_id?: string | null
    state: string
    medium: Medium
    clinic: Practice
    checkIn: {
      check_in_data: CheckInData | null
    }
  }
  consultant: Consultant
  bookingType: string
  consumerNetworkName: string
  patientNote: string | null
  scheduledTime: string | null
  status: string
  consultationNote: ConsultationNote | null
  prescriptions: Prescription[]
  referrals: Referral[]
  cancelledAt?: string | null
  reasons: CancellationReason[]
  participants: [Participant]
}

export type Participant = {
  type: string
  userId: string
  participant?: Patient
}

export type Patient = {
  full_name: string
}

export interface Drug {
  directions: string
  duration: string
  drugName: string
}

export interface Prescription {
  drugs: Drug[]
}

export interface Referral {
  id: string
  specialismName: string
  specialismCategoryId: string
  referralPdf: string
}

export interface Practice {
  id: string
  name: string
  address?: string
  full_address: PracticeAddress
  phone_number: string
  email: string
  arrival_instructions: string
  timezone_id: string | null
}

export interface PracticeAddress {
  address_line_1: string
  address_line_2: string
  address_line_3: string
  postal_code: string
  country_code: string
}

export interface Consultant {
  id: string
  name: string
  gender: Gender
  profession_name: string
}

export type Gender = 'male' | 'female'

export interface ConsultationNote {
  id: string
  toldByPatient: string
  exploredByGp: string
  assessment: string
  treatmentPlan: string
  fallbackPlan: string
}

export interface CancellationReason {
  reason: string
}

export interface Entry {
  id?: Maybe<string>
  time: string
  text: string
  tag: string
  type?: string
  userUuid?: Maybe<string>
  patientUuid?: Maybe<string>
}

export type Medium = 'physical' | 'video' | 'voice' | 'text'

export type Maybe<T> = T | null

export { UserTemplateState, RepeatTemplateState, WARNING_STATUSES }
