import { useState, useEffect } from 'react'

import { UseSelectionControl, ISelectionControlOption } from '../form.types'

const useSelectionControl: UseSelectionControl = (
  options,
  onChange,
  isControlledComponent = false
) => {
  const [selectOptions, setSelectOptions] = useState(options)

  const currentOptions = isControlledComponent ? [...options] : selectOptions

  useEffect(() => {
    if (isControlledComponent) return

    const updatedOptions = selectOptions.map((option) => {
      const matchingOption = options.find(
        (incomingOption) => incomingOption.value === option.value
      )

      return {
        ...option,
        ...(matchingOption || {}),
      }
    })

    setSelectOptions(updatedOptions)
  }, [options]) // eslint-disable-line react-hooks/exhaustive-deps

  const onOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedSelectOptions = currentOptions.map((opt) => {
      if (opt.value === event.target.value) {
        opt.selected = true
      } else {
        opt.selected = false
      }

      return opt
    })

    if (!isControlledComponent) {
      setSelectOptions(changedSelectOptions)
    }

    if (onChange) {
      const selectedOptions = currentOptions
        .filter((option: ISelectionControlOption) => option.selected)
        .map((option: ISelectionControlOption) => option.value)

      onChange(selectedOptions[0])
    }
  }

  return { selectOptions: currentOptions, onOptionChange }
}

export default useSelectionControl
