import React, { ReactNode, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

export type QueryParamsContextType = {
  getQueryParams: () => { [key: string]: string | number | Date | null }
  setQueryParams: (queryParams: {
    [key: string]: string | number | Date | null
  }) => void
  resetQueryParams: () => void
}

export type PageParamsType = {
  [key: string]: {
    default: (() => string | number | null) | number | string | null
    decode?: (value: string) => string | number
    encode?: (value: any) => string | number
  }
}

interface queryParamsProviderProps {
  children: ReactNode
  pageParams: PageParamsType
}

const QueryParamsContext = React.createContext<QueryParamsContextType>({
  getQueryParams: () => ({}),
  setQueryParams: () => {},
  resetQueryParams: () => {},
})

export const useQueryParams = () =>
  useContext<QueryParamsContextType>(QueryParamsContext)

export default function QueryParamsProvider({
  children,
  pageParams = {},
}: queryParamsProviderProps) {
  const location = useLocation()
  const history = useHistory()

  const getQueryParams = () => {
    const defaultValues = Object.keys(pageParams).reduce((acc, value) => {
      acc[value] =
        typeof pageParams[value]?.default === 'function'
          ? pageParams[value]?.default()
          : pageParams[value]?.default

      return acc
    }, {})

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

    const currentParams = Object.keys(queryParams).reduce((acc, value) => {
      const paramValue = queryParams[value]

      acc[value] = pageParams?.[value]?.decode?.(paramValue) ?? paramValue

      return acc
    }, {})

    return {
      ...defaultValues,
      ...currentParams,
    }
  }

  const setQueryParams = (newParams) => {
    const currentQueryParams = getQueryParams()

    const newQueryParams = Object.keys(newParams).reduce((acc, value) => {
      const paramValue = newParams[value]

      acc[value] = pageParams?.[value]?.encode?.(paramValue) ?? paramValue

      return acc
    }, {})

    history.push({
      search: qs.stringify({
        ...currentQueryParams,
        ...newQueryParams,
      }),
    })
  }

  const resetQueryParams = () => {
    history.push({})
  }

  return (
    <QueryParamsContext.Provider
      value={{ getQueryParams, setQueryParams, resetQueryParams }}
    >
      {children}
    </QueryParamsContext.Provider>
  )
}
