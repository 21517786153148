import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Snackbar } from '@babylon/core-ui'
import { useHistory } from 'react-router-dom'

import { messages } from './messages'
import styles from './styles.module.scss'
import { GPFaxNumbersDataGrid } from './GPFaxNumbersDataGrid'

export const GPFaxNumbers = () => {
  const fm = useFormatMessage()

  const history = useHistory()
  const [showBanner, setShowBanner] = useState(true)

  return (
    <>
      {showBanner && (
        <Snackbar
          open
          fill
          intent="warning"
          fixed={false}
          onClose={() => setShowBanner(false)}
          className={styles.Banner}
        >
          <button
            type="button"
            className={styles.BannerButton}
            onClick={() => history.push('/admin/fax_numbers')}
          >
            {fm(messages.new_fax_numbers_switch_banner)}
          </button>
        </Snackbar>
      )}
      <GPFaxNumbersDataGrid />
    </>
  )
}
