import React, { ChangeEvent, FormEvent, useState } from 'react'
import {
  Box,
  Button,
  Heading,
  Icon,
  Text,
  Textarea,
  useColorTheme,
} from '@babylon/web-platform-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './messages'

import styles from './StarRatingV2.module.scss'

const ratingValues = [1, 2, 3, 4, 5]

const StarRatingV2 = ({ hasAlreadyRated, submitRating }: Props) => {
  const translate = useFormatMessage()
  const { colors } = useColorTheme()

  const [rating, setRating] = useState<number>()
  const [showComment, setShowComment] = useState(false)
  const [comment, setComment] = useState('')
  const [submissionComplete, setSubmissionComplete] = useState(false)

  const updateRating = (rating: number) => {
    !showComment && setShowComment(true)

    setRating(rating)
  }

  const updateCommentValue = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setComment(event.target.value)

  const completeSubmission = () => {
    rating && submitRating({ rating, comment })
    setSubmissionComplete(true)
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    completeSubmission()
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Heading as="h3" size="2xs">
          {translate(messages.subTitle)}
        </Heading>
      </div>

      {submissionComplete || hasAlreadyRated ? (
        <Text data-testid="feedback-thank-you-message">
          {translate(messages.thankYou)}
        </Text>
      ) : (
        <>
          <form onSubmit={onSubmit}>
            <fieldset>
              <legend className={styles.title}>
                <Text>{translate(messages.title)}</Text>
              </legend>
              <div className={styles.stars}>
                {ratingValues.map((num) => (
                  <div key={num}>
                    <input
                      checked={rating ? rating === num : false}
                      value={num}
                      id={`${num}-star`}
                      type="radio"
                      name="star-rating"
                      className={styles.hidden}
                      onChange={() => updateRating(num)}
                    />
                    <label
                      htmlFor={`${num}-star`}
                      className={styles.starButton}
                      data-testid={`${num}-star${
                        rating && rating >= num ? '-checked' : ''
                      }`}
                    >
                      <Box
                        className={styles.starContainer}
                        backgroundColor={colors.background.buttonSecondary}
                      >
                        <span className={styles.hidden}>
                          {translate(messages.starTitle, {
                            numberOfStars: num,
                          })}
                        </span>
                        <Icon
                          icon={
                            rating && rating >= num
                              ? 'RatingFilled'
                              : 'RatingOutline'
                          }
                          fontSize={22}
                        />
                      </Box>
                    </label>
                  </div>
                ))}
              </div>

              {showComment && (
                <div className={styles.textarea}>
                  <Textarea
                    label={translate(messages.textPlaceholder)}
                    onChange={updateCommentValue}
                    data-testid="textarea-input"
                  />
                </div>
              )}

              <Button
                type="submit"
                data-testid="submit-rating-button"
                isDisabled={!showComment}
              >
                {translate(messages.submit)}
              </Button>
            </fieldset>
          </form>
        </>
      )}
    </div>
  )
}

interface Props {
  hasAlreadyRated?: boolean
  submitRating: (ratingParams: { rating: number; comment?: string }) => void
}

export default StarRatingV2
