import React, { useState } from 'react'
import { Heading, Text } from '@babylon/core-ui'
import { ButtonSimple, ButtonVariant, Icon } from '@babylon/medkit'
import { Link } from 'react-router-dom'
import { AccountTags, getMembersAge, Unknown } from '@babylon/member-operations'
import { useFormatMessage } from '@babylon/intl'
import { PatientProfile } from '../../usePatientProfile'
import styles from './styles.module.css'
import { ReactComponent as MoreSVG } from './more.svg'
import { ReactComponent as CopySVG } from './copy.svg'
import { capitalizeFirstLetter } from './utils'
import { Menu, MenuButton, MenuDivider } from '../Menu'
import useClickOutside from '../../../../hooks/useClickOutside'
import Modal from '../Modal'

type Props = {
  conversationId: string
  patient: PatientProfile | undefined
  conversationEnded: boolean
  onCloseConversation: () => void
  onExitConversation: () => void
  onRequestTransfer: () => void
  patientId: string | undefined
  memberAppVersion: string | undefined
}

type ModalProps = {
  visible: boolean
  onRequestClose: () => void
  conversationId: string
}

const CopyConversationIdModal = ({
  visible,
  onRequestClose,
  conversationId,
}: ModalProps) => {
  const [copied, setCopied] = useState(false)
  const handleCopyConversationIdToClipboard = () => {
    navigator.clipboard.writeText(conversationId)
    setCopied(true)
  }

  const handlerRequestClose = () => {
    setCopied(false)
    onRequestClose()
  }

  return (
    <Modal
      visible={visible}
      onRequestClose={handlerRequestClose}
      Header={<Heading level="h2">View conversation ID</Heading>}
      Footer={
        <div className={styles.showConversationButtons}>
          {copied ? (
            <button
              className={`medkit__Button__ButtonGeneric ${styles.greenButton}`}
            >
              <span className="medkit__Button__ButtonLabel">Copied</span>
            </button>
          ) : (
            <ButtonSimple
              onClick={handleCopyConversationIdToClipboard}
              variant={ButtonVariant.secondary}
            >
              <CopySVG />
              &nbsp;Copy to clipboard
            </ButtonSimple>
          )}

          <ButtonSimple onClick={handlerRequestClose}>
            Close this dialog
          </ButtonSimple>
        </div>
      }
      showCloseIcon={false}
    >
      <div className={styles.showConversationIdModelBody}>{conversationId}</div>
    </Modal>
  )
}

const MemberAppVersion = ({
  memberAppVersion,
}: {
  memberAppVersion: string | undefined
}) => (
  <>
    {memberAppVersion && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          title={`Member App version: ${memberAppVersion}`}
          icon="MediaSmartPhoneFilled"
          height={15}
          width={15}
        />
        <Text>v{memberAppVersion}</Text>
      </div>
    )}
  </>
)

const PatientHeader = ({
  conversationId,
  patient,
  onCloseConversation,
  onExitConversation,
  onRequestTransfer,
  conversationEnded,
  patientId,
  memberAppVersion,
}: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [showConversationId, setShowConversationId] = useState<
    'show' | 'hide' | 'initial'
  >('initial')
  const fm = useFormatMessage()
  const { ref } = useClickOutside(() => {
    setShowMenu(false)
  })

  return (
    <>
      <div className={styles.patientHeader}>
        <>
          {patient ? (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Heading level="h3">{patient.summarizedName}</Heading>
                <AccountTags
                  className={styles.accountTags}
                  familyAccount={patient.hasFamilyAccounts}
                  familyAccountOwner={patient.hasFamilyAccountsOwner}
                  idVerificationStatus={patient.identityCheck || null}
                  minor={patient.isMinor}
                  pdsStatus={patient.pdsStatus}
                  preferredConsumerNetwork={patient.preferredConsumerNetwork}
                />
                <MemberAppVersion memberAppVersion={memberAppVersion} />
              </div>
              <Text color="grey-type">
                {capitalizeFirstLetter(patient.gender || '')}{' '}
                {getMembersAge(patient.birthDate || null, fm) || <Unknown />}
              </Text>
            </div>
          ) : (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Heading level="h3">Member UUID: {patientId}</Heading>
                <MemberAppVersion memberAppVersion={memberAppVersion} />
              </div>
            </div>
          )}
          <div style={{ display: 'flex', margin: 'auto' }} />
          <div style={{ position: 'relative' }}>
            <button
              type="button"
              className={styles.iconButton}
              onClick={() => setShowMenu(true)}
            >
              <MoreSVG />
            </button>
            <Menu
              ref={ref}
              style={{
                right: 0,
                marginTop: 8,
                display: showMenu ? 'flex' : 'none',
              }}
            >
              <MenuButton
                onClick={() => {
                  setShowConversationId(
                    showConversationId === 'show' ? 'hide' : 'show'
                  )
                  setShowMenu(false)
                }}
              >
                {showConversationId === 'show'
                  ? 'Hide conversation ID'
                  : 'View conversation ID'}
              </MenuButton>
              {patientId && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/admin/patients/${patientId}/memberships`}
                  data-testid="open-patient-profile"
                >
                  <MenuButton
                    onClick={() => {
                      setShowMenu(false)
                    }}
                  >
                    Open Patient profile
                  </MenuButton>
                </Link>
              )}
              {conversationEnded ? (
                <MenuButton
                  onClick={() => {
                    onExitConversation()
                    setShowMenu(false)
                  }}
                >
                  Close Chat
                </MenuButton>
              ) : (
                <>
                  <MenuButton
                    onClick={() => {
                      onRequestTransfer()
                      setShowMenu(false)
                    }}
                  >
                    Transfer Chat
                  </MenuButton>
                  <MenuDivider />
                  <MenuButton
                    type="alert"
                    onClick={() => {
                      onCloseConversation()
                      setShowMenu(false)
                    }}
                  >
                    End Chat
                  </MenuButton>
                </>
              )}
            </Menu>
          </div>
        </>
      </div>
      <CopyConversationIdModal
        visible={showConversationId === 'show'}
        onRequestClose={() => setShowConversationId('hide')}
        conversationId={conversationId}
      />
    </>
  )
}

export default PatientHeader
