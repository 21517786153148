import React from 'react'

import { Queue } from '@babylon/atlas.js/dist/lib/types'
import { ReactComponent as MessageSVG } from './message.svg'
import { ReactComponent as CareSVG } from './care.svg'
import QueueListItem from './QueueListItem'

const systemQueueToIcon = {
  SUPPORT: MessageSVG,
  CARE: CareSVG,
}

type Props = {
  queue: Queue
  onQueueSelect: (q: Queue) => void
  highlighted: boolean
  children: React.ReactNode
}

const SystemQueueItem = ({
  queue,
  onQueueSelect,
  highlighted,
  children,
}: Props) => {
  const Icon = systemQueueToIcon[queue.id] || (() => <></>)

  return (
    <QueueListItem
      Icon={<Icon />}
      title={queue.id}
      onSelected={() => onQueueSelect(queue)}
      highlighted={highlighted}
      description={`(${queue.conversations.length} members in queue)`}
    >
      {queue.agents.length === 0 && (
        <QueueListItem title="No agents available" />
      )}

      {children}
    </QueueListItem>
  )
}

export default SystemQueueItem
