import React from 'react'
import { Text } from '@babylon/core-ui'
import styles from './styles.module.css'

interface Props {
  color?: string
  icon?: React.ReactNode
  legend: string
}

export default function LegendKey({ color, icon, legend }: Props) {
  return (
    <div
      data-testid="legend-key"
      className={icon ? styles.iconKey : styles.legendKey}
    >
      <span
        data-testid="legend-box"
        className={styles.keyBox}
        style={{
          backgroundColor: color || '',
        }}
      >
        {icon}
      </span>
      <Text data-testid="legend-key-message" size="medium">
        {legend}
      </Text>
    </div>
  )
}
