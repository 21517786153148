import React from 'react'
import { Link } from 'react-router-dom'
import { IntlFormatters } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'

import styles from './styles.module.scss'
import messages from './ClinicianName.messages'

export interface Props {
  name: string
  id: string
  gender?: string | null
}

const formatGender = (
  gender: string,
  formatter: IntlFormatters['formatMessage']
) => {
  switch (gender.toLocaleLowerCase()) {
    case 'female':
      return formatter(messages.gender_female_short)
    case 'male':
      return formatter(messages.gender_male_short)
    default:
      return null
  }
}

const ClinicianName = ({ name, id, gender }: Props) => {
  const fm = useFormatMessage()

  return (
    <Link to={`/admin/consultants/${id}`} data-testid="clinician-name">
      <div className={styles.ClinicianName}>
        {!!gender && <span>{`(${formatGender(gender, fm)}) `}</span>}
        <span className={styles.ClinicianName__name}>{name}</span>
      </div>
    </Link>
  )
}

export default ClinicianName
