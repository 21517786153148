import React, { ReactNode, CSSProperties } from 'react'
import cn from 'classnames'

import { Text } from '@babylon/core-ui'

import styles from './Slot.module.css'

interface SlotProps {
  active?: boolean
  className?: string
  'data-testid'?: string
  disabled?: boolean
  children?: ReactNode
  style?: CSSProperties
  time: string
  warning?: ReactNode
}

const Slot = ({
  active = false,
  className,
  'data-testid': dataTestId = 'slot',
  disabled = false,
  children,
  style,
  time,
  warning,
}: SlotProps) => (
  <div
    className={cn(
      styles.slot,
      className,
      disabled && styles.slotDisabled,
      active && styles.slotActive
    )}
    data-testid={dataTestId}
    style={style}
  >
    <div className={styles.slotContent}>
      <Text bold tag="div" className={styles.slotTime}>
        {time}
      </Text>
      <Text tag="div">{children}</Text>
    </div>
    {warning}
  </div>
)

export default Slot
