// Enum doesn't accept number as name
// cf. https://github.com/Microsoft/TypeScript/issues/3572
const ShiftSlotDuration = {
  5: 25,
  10: 54,
  15: 83,
  20: 112,
  25: 141,
  30: 170,
  35: 199,
  40: 228,
  45: 257,
  50: 286,
  55: 315,
  60: 344,
}

export type ShiftSlotDurationTypes = keyof typeof ShiftSlotDuration

export default ShiftSlotDuration
