export enum ConversationState {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum DataPointCollectionState {
  NONE_COLLECTED = 'none_collected',
  PARTIALLY_COLLECTED = 'partially_collected',
  ALL_COLLECTED = 'all_collected',
}
