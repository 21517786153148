import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams, Link } from 'react-router-dom'
import { Page } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { Spinner } from '@babylon/medkit'

import { logException } from '../../../..'
import withSnackBar from '../../components/withSnackBar'
import { PATIENT_QUERY } from '../../queries'
import View from '../View'
import messages from './messages'

const PageWrapper = () => {
  const fm = useFormatMessage()
  const { id: memberId } = useParams<{ id: string }>()
  const { data, loading } = useQuery(PATIENT_QUERY, {
    onError: (error) => {
      logException(error)
    },
    variables: { id: memberId },
  })

  return (
    <Page
      breadcrumbs={[
        <Link key="1" to="/admin/patients/search">
          {fm(messages.memberDetails)}
        </Link>,
        <Link key="2" to={`/admin/patients/${memberId}/memberships`}>
          {fm(messages.patientSearch)}
        </Link>,
        <Link key="3" to={`/admin/patients/${memberId}/assessment`}>
          {fm(messages.assessment)}
        </Link>,
        fm(messages.breadcrumbTitle),
      ]}
      title={fm(messages.title)}
    >
      {loading && <Spinner center variant="primary" />}
      {data && <View member={data.patient} />}
    </Page>
  )
}

export default withSnackBar(PageWrapper)
