export const capitalizeFirstLetter = (sentence: string) =>
  sentence
    ? `${sentence.charAt(0).toUpperCase()}${sentence.slice(1)}`
    : sentence

export const intentFromTags = (tags: string[]) => {
  const tagsMap: Record<string, string> = tags.reduce((acc, tag) => {
    if (tag.includes(':')) {
      const [k, v] = tag.split(':')
      acc[k] = v
    }

    return acc
  }, {})

  return (tagsMap.intent || '').replace(/-/g, ' ')
}

export const getAgentNickname = (fullName?: string) => {
  if (!fullName) {
    return undefined
  }

  const names = fullName.split(' ')

  if (names.length === 1) {
    return names[0]
  }

  const lastName = names[names.length - 1]

  return `${names[0]} ${lastName.charAt(0)}.`
}
