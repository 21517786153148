/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import copy from 'clipboard-copy'
import Tooltip from 'rc-tooltip'
import {
  DataGrid,
  Columns,
  Column,
  Pagination,
  Actions,
} from '@babylon/babylon-forms/widgets'
// @ts-ignore
import { Button } from '@babylon/babylon-forms/forms'
import { AlertFilled } from '@babylon/icons'
import { Spinner, Snackbar } from '@babylon/core-ui'
import { PatientName } from '@babylon/member-operations'
import { ApolloError } from '@apollo/client'

import Icon from '@/components/Icon'
import TimezoneDate from '@/components/TimezoneDate'
import LabelWithIcon from '@/components/LabelWithIcon'
import PrescriptionActions from '@/routes/Prescriptions/AdminPrescriptions/PrescriptionActions'

import PaginationSize from './PaginationSize'
import { formatBadgeDelivery, formatDrug } from '../utils'
import styles from './styles.module.scss'

const copyUrlToClipboard = (id: string) => () => {
  copy(`${window.location.origin}/admin/prescriptions/${id}`)
}

type ActionTypes = 'VOID' | 'VIEW_PDF'

type Drug = {
  quantity: number
  drugName: string
  directions: string
  packagingSize: string
}

type Patient = {
  id: string
  first_name: string
  last_name: string
}

interface ItemViewType {
  item: {
    id: string
    patient: Patient
    drugs: Drug[]
    createdAt: Date
    actions: ActionTypes[]
    latestComment: string
  }
  actions: ActionTypes[]
}

const ItemView = ({ item, actions }: ItemViewType) => {
  const {
    id,
    patient: {
      id: patientId,
      first_name: patientFirstName,
      last_name: patientLastName,
    },
    drugs,
    createdAt,
    latestComment,
  } = item

  return (
    <tr data-testid="dataGridRow">
      <td className={styles.noWrap}>
        <Tooltip
          transitionName="tooltip-fade"
          mouseEnterDelay={0.05}
          mouseLeaveDelay={0}
          trigger={['hover']}
          overlay="Copy prescription URL"
          placement="top"
        >
          <Icon
            onClick={copyUrlToClipboard(id)}
            role="button"
            tabIndex="-1"
            name="clipboard"
            className={styles.icon}
          />
        </Tooltip>{' '}
        <strong>
          <PatientName
            firstName={patientFirstName}
            lastName={patientLastName}
            id={patientId}
          />
        </strong>
      </td>
      <td data-testid="drugData">{drugs.map(formatDrug)}</td>
      <td>{formatBadgeDelivery('FAILED_TO_SEND')}</td>
      <td>{latestComment ? <div>{latestComment}</div> : null}</td>
      <td className={styles.narrowRow}>
        <TimezoneDate date={createdAt} timezone={null} format={null} />
      </td>
      <DataGrid.ItemActions item={item} actions={actions} />
    </tr>
  )
}

export interface FailedViewProps {
  prescriptionFetchFailed: PrescriptionPage
  handleAction: any
  handleAudits: any
  handleComment: any
  loading: boolean
  error?: ApolloError
  handlePageSizeChange: (pageSize: number) => void
  handlePageChange: (pageNumber: number) => void
}

const FailedView = ({
  prescriptionFetchFailed,
  loading,
  error,
  handlePageSizeChange,
  handlePageChange,
  handleAction,
  handleAudits,
  handleComment,
}: FailedViewProps) => {
  const itemsPerPage = prescriptionFetchFailed?.itemsPerPage ?? 10
  const totalItems = prescriptionFetchFailed?.totalItems ?? 0
  const pageIndex = prescriptionFetchFailed?.pageIndex ?? 0
  const items = prescriptionFetchFailed?.items ?? []

  if (loading) {
    return (
      <div data-testid="loader" className={styles.prescriptionsView}>
        <div className={styles.spinnerContainer}>
          <Spinner size="large" centered />
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className={styles.prescriptionsView}>
        <div className={styles.spinnerContainer}>
          <Snackbar
            icon={<AlertFilled fill="white" />}
            open={!!error}
            className={styles.Snackbar}
            message="Failed to retrieve information. Please try again later."
            intent="error"
          />
        </div>
      </div>
    )
  }

  // @ts-ignore
  return (
    <div className={styles.prescriptionsView}>
      <div className={styles.listHeader}>
        <PaginationSize
          value={itemsPerPage}
          onChange={handlePageSizeChange}
          totalItems={totalItems}
          spinner={null}
        />
      </div>
      <DataGrid value={items} ItemView={ItemView}>
        <Columns>
          <Column name="patientName" label="Patient" />
          <Column name="drug" label="Drug" />
          <Column name="status" label="Status" />
          <Column name="latestComment" label="Latest comment" />
          <Column name="createdAt" label="Created At" />
          <Column name="actions" label="Actions" />
        </Columns>
        <Actions>
          {({ item: { actions } }: ItemViewType) => (
            <span>
              <PrescriptionActions
                actions={actions && actions.filter((a) => a === 'VOID')}
                onAction={handleAction}
              />
              <Button small onClick={handleAudits}>
                <LabelWithIcon icon="logs" label="History" />
              </Button>
              <Button small onClick={handleComment}>
                <LabelWithIcon icon="add" label="Add comment" />
              </Button>
            </span>
          )}
        </Actions>
      </DataGrid>
      <div className={styles.bottomToolbar}>
        <div />
        {itemsPerPage < totalItems && (
          <div className={styles.rowRight}>
            <Pagination
              page={pageIndex}
              size={itemsPerPage}
              totalElements={totalItems}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FailedView
