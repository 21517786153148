import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useHistory } from 'react-router-dom'
import { Snackbar } from '@babylon/core-ui'
import { DocumentNode } from 'graphql'
import { columns, columnsToShow } from './columns'
import { HealthcareServiceEdge, Connection } from '@/gql/graphql'
import { ExistsDropdownList } from './enums/ExistsDropdownList'
import { BooleanDropdownList } from '@/routes/Pharmacies/enums/BooleanDropdownList'
import messages from './messages'
import styles from './styles.module.scss'
import { EditObjectConfig } from '@/components/DataTableFromGQL/types'
import { CoreDataTable } from '@/components/DataTableFromGQL'

type Props = {
  query: DocumentNode
  healthcareServiceDataConverter: (item: HealthcareServiceEdge) => any
  editConfig: EditObjectConfig
}

const filterFormatter = (filters: Record<string, string>) => {
  const pharmacyFilter: any = {
    AND: [],
  }
  if (filters.name && filters.name.trim() !== '') {
    pharmacyFilter.AND?.push({
      name: {
        MATCH: {
          QUERY: filters.name,
        },
      },
    })
  }
  if (filters.postalCode && filters.postalCode.trim() !== '') {
    pharmacyFilter.AND?.push({
      postalCode: {
        MATCH: {
          QUERY: filters.postalCode,
        },
      },
    })
  }
  if (filters.blocked && filters.blocked !== BooleanDropdownList.ALL) {
    pharmacyFilter.AND?.push({
      active: {
        ONE_OF: [filters.blocked !== BooleanDropdownList.TRUE],
      },
    })
  }
  if (filters.faxNumber && filters.faxNumber === ExistsDropdownList.EXISTS) {
    pharmacyFilter.AND?.push({
      NOT: [
        {
          fax: {
            ONE_OF: [''],
          },
        },
      ],
    })
  }
  if (
    filters.faxNumber &&
    filters.faxNumber === ExistsDropdownList.DOES_NOT_EXIST
  ) {
    pharmacyFilter.AND?.push({
      fax: {
        ONE_OF: [''],
      },
    })
  }
  if (pharmacyFilter.AND?.length > 0) return pharmacyFilter
  return null
}

export const PharmaciesDataGrid: React.FC<Props> = ({
  query,
  healthcareServiceDataConverter,
  editConfig,
}) => {
  const fm = useFormatMessage()
  const [showBanner, setShowBanner] = useState(true)
  const history = useHistory()
  const switchPage = () => {
    history.push('/admin/pharmacies')
  }

  const rowFormatter = (data: Connection) => {
    return (
      data?.edges?.map((item: HealthcareServiceEdge) => {
        return healthcareServiceDataConverter(item)
      }) || []
    )
  }

  const defaultSort = {
    field: 'NAME',
    order: 'ASC',
  }

  const filterOptions = [
    {
      name: 'name',
      label: fm(messages.name),
    },
    {
      name: 'postCode',
      label: fm(messages.postCode),
    },
    {
      name: 'blocked',
      label: fm(messages.blocked),
      options: ['ALL', 'TRUE', 'FALSE'],
    },
    {
      name: 'faxNumber',
      label: fm(messages.faxNumber),
      options: ['ALL', 'EXISTS', 'DOES NOT EXIST'],
    },
  ]

  return (
    <>
      {showBanner && (
        <Snackbar
          open
          fill
          intent="warning"
          fixed={false}
          onClose={() => setShowBanner(false)}
          className={styles.Banner}
        >
          <button
            type="button"
            className={styles.BannerButton}
            onClick={switchPage}
          >
            {fm(messages.new_pharmacies_switch_banner)}
          </button>
        </Snackbar>
      )}
      <CoreDataTable
        id="pharmacies"
        objectTypeLabel={fm(messages.pharmacies)}
        columns={columns}
        initialColumnsToShow={columnsToShow}
        filterFormatter={filterFormatter}
        filterOptions={filterOptions}
        query={query}
        rowFormatter={rowFormatter}
        editConfig={editConfig}
        defaultSort={defaultSort}
      />
    </>
  )
}
