import {
  Button,
  FormikSelect,
  SelectOptionTypeBase,
  SelectValueType,
} from '@babylon/core-ui'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import styles from './CancelAppointmentForm.module.css'
import type {
  CancelAppointmentFormProps,
  CancelAppointmentFormValues,
} from './CancelAppointmentForm.types'

const validationSchema = Yup.object({
  appointmentId: Yup.string().required(),
  cancelReasonId: Yup.string().required(
    'Please select a reason for cancellation.'
  ),
})

const CancelAppointmentForm = ({
  appointmentId,
  cancelReasons,
  isSubmitting,
  onSubmit,
  trackSelectCancelReason,
}: CancelAppointmentFormProps) => {
  const initialValues = {
    appointmentId: appointmentId ?? '',
    cancelReasonId: '',
  }

  const handleFormSubmit = (values: CancelAppointmentFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      <Form aria-label="Cancel Appointment Form" className={styles.form}>
        <div className={styles.cancelReason}>
          <FormikSelect
            id="cancelReasonId"
            name="cancelReasonId"
            data-testid="cancel-reason-select"
            label="Select reason for cancellation"
            options={cancelReasons.map((cancelReason) => ({
              value: cancelReason.id,
              label: cancelReason.reason,
            }))}
            onChange={(value: SelectValueType) => {
              const reason = (value as SelectOptionTypeBase).label
              trackSelectCancelReason(appointmentId, reason)
            }}
          />
        </div>

        <Button
          type="submit"
          intent="primary"
          loading={isSubmitting}
          className={styles.submitButton}
        >
          Cancel appointment
        </Button>
      </Form>
    </Formik>
  )
}

export default CancelAppointmentForm
