import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import {
  withFormContext,
  withValue,
  compose,
  nextIndex,
  result,
} from '../forms'
import Columns from './Columns'
import Actions from './Actions'

export const ItemActions = ({ item, actions, className, ...props }) =>
  actions ? (
    <td key="__actions__" className={cx('actions', className)} {...props}>
      {React.cloneElement(actions, { value: item })}
    </td>
  ) : null

const DefaultItemView = (
  { columns, item, actions } // eslint-disable-line
) => (
  <tr>
    {columns.map(({ name, value }) => (
      <td key={name}>{value ? result(value, item) : item[name]}</td>
    ))}
    <ItemActions item={item} actions={actions} />
  </tr>
)

const SortButton = ({ name, sort, onSort, icons }) => {
  const sorted = sort && sort.column === name
  const loading = sorted && sort.loading
  const desc = sorted && sort.direction === 'desc'
  const ascIcon = (icons && icons.asc) || '▲'
  const descIcon = (icons && icons.desc) || '▼'
  const sortIcon = (icons && icons.sort) || '▽'
  const spinner = (icons && icons.spinner) || '…'
  return (
    <span
      role="button"
      tabIndex="0"
      onClick={() => onSort && onSort(name, desc ? 'asc' : 'desc')}
      className={cx('sort-button', {
        asc: !desc,
        desc,
      })}
    >
      {loading ? spinner : sorted ? (desc ? descIcon : ascIcon) : sortIcon}
    </span>
  )
}

const DataGrid = ({
  children,
  value,
  ItemView,
  idField,
  sort,
  onSort,
  className,
  icons,
  emptyListMessage,
  context,
}) => {
  const childArray = React.Children.toArray(children)
  const columns = childArray.find((child) => child.type === Columns)
  const columnArray = React.Children.toArray(columns.props.children).map(
    (column) => column.props
  )
  const actions = childArray.find((child) => child.type === Actions)
  return (
    <div className={cx('widget data-table', className)}>
      <table>
        <thead>
          <tr>
            {columnArray.map(({ name, label, sort: sortable }) => (
              <th key={name}>
                <span>{label}</span>
                {sortable ? (
                  <SortButton
                    onSort={onSort}
                    name={name}
                    sort={sort}
                    icons={icons}
                  />
                ) : null}
              </th>
            ))}
            {actions && <th className="actions" key="__actions__" />}
          </tr>
        </thead>
        <tbody>
          {value && value.length ? (
            value.map((item) => (
              <ItemView
                key={item[idField] || nextIndex()}
                columns={columnArray}
                item={item}
                actions={actions}
                context={context}
              />
            ))
          ) : (
            <tr>
              <td className="empty-list-message" colSpan="1000">
                {emptyListMessage}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

DataGrid.defaultProps = {
  ItemView: DefaultItemView,
  idField: 'id',
  emptyListMessage: 'No items',
}

DataGrid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  value: PropTypes.arrayOf(PropTypes.object),
  ItemView: PropTypes.func,
  idField: PropTypes.string,
  className: PropTypes.string,
  emptyListMessage: PropTypes.string,
}

const DataGridExport = compose(withFormContext, withValue)(DataGrid)

DataGridExport.ItemActions = ItemActions

export default DataGridExport
