import { gql } from '@apollo/client'

export default gql`
  query MemberUuid($id: ID!) {
    patient(id: $id) {
      id
      uuid
    }
  }
`
