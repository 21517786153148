import React from 'react'
import { withApollo, graphql } from '@apollo/client/react/hoc'
import { compose, withHandlers, withProps } from 'recompose'
import debounce from 'lodash/debounce' // eslint-disable-line

import { withStore } from '@babylon/babylon-forms/forms'

import withSpinner from '@/util/withSpinner'
import withErrorMessage from '@/util/withErrorMessage'
import { removeTypeNames } from '@/util'

import DrugAlertEditView from './DrugAlertEditView'

import {
  AdminDrugAlert,
  AdminActiveIngredient,
  PrescriptionRegions,
  AddAdminDrugAlert,
  UpdateAdminDrugAlert,
} from './queries'

// DATA

const defaultDrugAlert = {
  activeIngredient: '',
  regions: [],
  prescriptionLimit: '',
  timePeriod: '',
  allowPrescribe: false,
}

const withDefaultDrugAlert = withProps(({ location: { pathname } }) =>
  pathname === '/admin/drugs/alerts/add' ? { drugAlert: defaultDrugAlert } : {}
)

export const regionsQueryOptions = {
  props: ({ data, ownProps }) => ({
    ...ownProps,
    regions: data.prescriptionRegions ? data.prescriptionRegions : [],
  }),
}

export const adminDrugAlertQueryOptions = {
  options: ({ match = { params: {} } }) => ({
    variables: {
      id: match.params.id,
    },
  }),
  props: ({ data, ownProps }) => ({
    ...ownProps,
    data,
    drugAlert: data && data.adminDrugAlert,
  }),
  skip: ({ match }) => !match || !match.params || !match.params.id,
}

export const withData = compose(
  withDefaultDrugAlert,
  graphql(PrescriptionRegions, regionsQueryOptions),
  graphql(AdminDrugAlert, adminDrugAlertQueryOptions),
  graphql(AddAdminDrugAlert, { name: 'addAdminDrugAlert' }),
  graphql(UpdateAdminDrugAlert, { name: 'updateAdminDrugAlert' })
)

// FORM

const withForm = withStore(({ drugAlert }) => drugAlert)

// ACTIONS

export const fetchOptions = debounce((client, query, callback, error) => {
  if (query.trim().length) {
    client
      .query({
        query: AdminActiveIngredient,
        variables: { query, limit: 50 },
      })
      .then((response) => {
        callback(null, {
          options: response.data.adminActiveIngredient,
        })
      })
      .catch(error)
  } else {
    callback(null, {
      options: [],
    })
  }
}, 500)

export const getActiveIngredientOptions = ({ client }) => (
  query,
  callback,
  error
) => {
  fetchOptions(client, query, callback, error)
}

const sanitizeAdminDrugAlertInput = (input) => {
  const {
    createdAt,
    activeIngredient,
    regions,
    prescriptionLimit,
    ...rest
  } = input

  return removeTypeNames({
    ...rest,
    prescriptionLimit: parseInt(prescriptionLimit),
    activeIngredientId: activeIngredient.activeIngredientId,
    regions: regions.map((region) => region.regionCode || region),
  })
}

const handleSubmit = ({
  addAdminDrugAlert,
  updateAdminDrugAlert,
  history,
  errorAlert,
}) => (state) => {
  const mutate = state.id ? updateAdminDrugAlert : addAdminDrugAlert
  mutate({
    variables: { adminDrugAlertInput: sanitizeAdminDrugAlertInput(state) },
    refetchQueries: ['AdminDrugAlerts'],
  })
    .then(() => {
      history.goBack()
    })
    .catch(() => {
      errorAlert()
    })
}

const handleCancel = ({ history }) => () => history.goBack()

const withActions = withHandlers({
  getActiveIngredientOptions,
  handleSubmit,
  handleCancel,
})

// LOADER

export const isLoading = (props) => !props.drugAlert || !props.regions

const withLoader = withSpinner(isLoading)

export default compose(
  withApollo,
  withData,
  withForm,
  withErrorMessage(({ history }) => ({
    message: (
      <span>
        Error loading this page. <br /> Please try again later.
      </span>
    ),
    buttonLabel: 'Close',
    onButtonClick: () => history.goBack(),
  })),
  withActions,
  withLoader
)(DrugAlertEditView)
