import React from 'react'
import { SvgProps } from '../../../app'

const RepeatTemplateIcon = ({
  height = 24,
  width = 24,
  className,
}: SvgProps) => (
  <svg
    className={className}
    height={height}
    width={width}
    viewBox={`-4 0 ${width} ${height}`}
  >
    <path d="M12.026 16.25a.75.75 0 1 1 0 1.5H4.017a.75.75 0 1 1 0-1.5h8.01zm-.73-13.841H1.718a.281.281 0 0 0-.292.282v16.513c0 .148.134.282.289.282h12.618a.292.292 0 0 0 .289-.284V5.774L11.297 2.41zM8.024 20.91h-6.31A1.718 1.718 0 0 1 0 19.204V2.691C0 1.745.763.984 1.717.984h9.877c.19 0 .373.076.507.212l3.74 3.785a.712.712 0 0 1 .205.5v13.721c0 .943-.775 1.71-1.714 1.71H8.023zm3.19-15.299h3.668a.712.712 0 1 1 0 1.425H10.5a.712.712 0 0 1-.713-.712V2.158a.712.712 0 1 1 1.425 0v3.454zm-.873 4.464a.75.75 0 1 1 0 1.5H5.974a.75.75 0 0 1 0-1.5h4.366zM7.407 8.644a.75.75 0 0 1 1.5 0v4.365a.75.75 0 0 1-1.5 0V8.644z" />
  </svg>
)

export default RepeatTemplateIcon
