import { gql } from '@apollo/client'

export default gql`
  query getMemberThreads($memberId: ID!, $type: MemberThreadType!) {
    memberThreads(filter: { memberId: [$memberId], type: [$type] }) {
      id
      state
      createdAt
      subType
    }
  }
`
