/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type SaveAppointmentWaitTimeConfigsMutationVariables = Types.Exact<{
  input: Types.AppointmentWaitTimeConfigsInput
}>

export type SaveAppointmentWaitTimeConfigsMutation = Pick<
  Types.Mutation,
  'saveAppointmentWaitTimeConfigs'
>

export const SaveAppointmentWaitTimeConfigsDocument = gql`
  mutation saveAppointmentWaitTimeConfigs(
    $input: AppointmentWaitTimeConfigsInput!
  ) {
    saveAppointmentWaitTimeConfigs(input: $input)
  }
`
export type SaveAppointmentWaitTimeConfigsMutationFn = Apollo.MutationFunction<
  SaveAppointmentWaitTimeConfigsMutation,
  SaveAppointmentWaitTimeConfigsMutationVariables
>
export function useSaveAppointmentWaitTimeConfigsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAppointmentWaitTimeConfigsMutation,
    SaveAppointmentWaitTimeConfigsMutationVariables
  >
) {
  return Apollo.useMutation<
    SaveAppointmentWaitTimeConfigsMutation,
    SaveAppointmentWaitTimeConfigsMutationVariables
  >(SaveAppointmentWaitTimeConfigsDocument, baseOptions)
}
export type SaveAppointmentWaitTimeConfigsMutationHookResult = ReturnType<
  typeof useSaveAppointmentWaitTimeConfigsMutation
>
export type SaveAppointmentWaitTimeConfigsMutationResult = Apollo.MutationResult<SaveAppointmentWaitTimeConfigsMutation>
export type SaveAppointmentWaitTimeConfigsMutationOptions = Apollo.BaseMutationOptions<
  SaveAppointmentWaitTimeConfigsMutation,
  SaveAppointmentWaitTimeConfigsMutationVariables
>
