import { gql } from '@apollo/client'

export default gql`
  query GetServiceTypes($includingDigital: Boolean, $include: [String!]) {
    serviceTypes(including_digital: $includingDigital, include: $include) {
      uuid
      name
      allowed_mediums
      common
      default_duration_minutes
      categories {
        name
      }
    }
  }
`
