import React, { useState, useEffect, ChangeEvent } from 'react'
import { Button, Heading, Modal, FormikInput, Text } from '@babylon/core-ui'
import { Form, Formik } from 'formik'
import { useFormatMessage } from '@babylon/intl'
import { useMutation } from '@apollo/client'
import isEmail from 'validator/lib/isEmail'
import {
  TrackingElementType,
  useTracking,
  TrackingActionType,
} from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../../..'

import useRefetchProfile from './useRefetchProfile'
import { SEND_REGISTRATION_EMAIL } from './queries'
import { logException } from '../../../../ErrorBoundary'
import { SetSnackbarMessage } from '../../../types'

import styles from './SendRegistrationButton.module.scss'
import messages from './SendRegistrationButton.messages'

export interface SendRegistrationInviteModalProps {
  patientUuid: string
  firstName: string
  lastName: string
  email: string
  isModalVisible: boolean
  closeModal: () => void
  setSnackbarMessage: SetSnackbarMessage
}

export const INVITATION_REGISTRATION_STRATEGY = 'invitation-adult-stub'

const SendRegistrationInviteModal = ({
  patientUuid,
  firstName,
  lastName,
  email,
  isModalVisible,
  closeModal,
  setSnackbarMessage,
}: SendRegistrationInviteModalProps) => {
  const { trackEvent } = useTracking()
  const fm = useFormatMessage()
  const [isLoading, setIsLoading] = useState(false)
  const [emailValue, setEmailValue] = useState<string>(email)

  const [sendRegistrationEmail, { data: registrationData }] = useMutation(
    SEND_REGISTRATION_EMAIL,
    {
      context: { clientName: 'platform-gateway' },
    }
  )

  const patientCoreId = useRefetchProfile({
    patientUuid,
    shouldRefetchProfile: !!registrationData,
  })

  const handleEmailValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(event.target.value)
  }
  const trackError = () => {
    trackEvent({
      patientId: patientUuid,
      elementName: 'send-email-button',
      moduleName: MemberOpsModuleName.profilesHeader,
      elementType: TrackingElementType.form,
      actionType: TrackingActionType.formError,
    })
  }

  useEffect(() => {
    if (isLoading && patientCoreId) {
      setIsLoading(false)
      closeModal()
      setSnackbarMessage(
        fm(messages.send_registration_email_success, { address: emailValue })
      )
      trackEvent({
        patientId: patientUuid,
        elementName: 'send-email-button',
        moduleName: MemberOpsModuleName.profilesHeader,
        elementType: TrackingElementType.form,
        actionType: TrackingActionType.formSuccess,
      })
    }
  }, [
    isLoading,
    patientCoreId,
    emailValue,
    fm,
    setSnackbarMessage,
    closeModal,
    patientUuid,
    trackEvent,
  ])

  const handleSendRegistrationEmail = async (emailInput: string) => {
    setIsLoading(true)

    try {
      const response = await sendRegistrationEmail({
        variables: {
          input: {
            stubProfileId: patientUuid,
            primaryEmailAddress: emailInput,
            registrationStrategy: INVITATION_REGISTRATION_STRATEGY,
          },
        },
      })

      if (
        response.data.registerPatient.errorMessage ===
        'email: has already been taken'
      ) {
        setSnackbarMessage(
          fm(messages.send_registration_email_already_taken),
          null,
          'error'
        )
        closeModal()
        setIsLoading(false)
        trackError()

        return
      }

      if (response.data.registerPatient.errorMessage) {
        setSnackbarMessage(
          fm(messages.send_registration_email_error),
          null,
          'error'
        )
        closeModal()
        setIsLoading(false)
        trackError()

        return
      }
    } catch (err) {
      logException(err)
      setIsLoading(false)
      closeModal()
      setSnackbarMessage(
        fm(messages.send_registration_email_error),
        null,
        'error'
      )
      trackError()
    }
  }

  return (
    <Modal
      open={isModalVisible}
      label={fm(messages.send_registration_email)}
      onClose={closeModal}
      className={styles.sendEmailModal}
      includeClose={!isLoading}
    >
      <Formik
        initialValues={{
          email,
        }}
        onSubmit={({ email }) => {
          trackEvent({
            patientId: patientUuid,
            elementName: 'send-email-button',
            moduleName: MemberOpsModuleName.profilesHeader,
            elementType: TrackingElementType.form,
            actionType: TrackingActionType.formSubmitted,
          })

          if (isEmail(email)) {
            handleSendRegistrationEmail(email)
          }
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} name="send-registration-invite">
            <Heading level="h2" margin>
              {fm(messages.send_registration_email)}
            </Heading>
            <Text tag="p">
              {fm(messages.pre_registered_profile, {
                name: `${lastName?.toUpperCase()}, ${firstName}`,
                b: (chunks) => <strong>{chunks}</strong>,
              })}
            </Text>
            <FormikInput
              type="email"
              name="email"
              id="email"
              label={fm(messages.patient_email)}
              onChange={handleEmailValueChange}
              required
            />
            <Button
              data-testid="send-email"
              type="submit"
              loading={isLoading}
              className={styles.sendEmailButton}
              fill
            >
              {fm(messages.send_email)}
            </Button>
            <Text tag="p" align="center">
              {fm(messages.confirm_email_before_sending)}
            </Text>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default SendRegistrationInviteModal
