import React from 'react'

import { ReactComponent as KeyReturnSVG } from './key_return.svg'
import { ReactComponent as KeyEscSVG } from './key_esc.svg'
import { ReactComponent as KeyUpSVG } from './key_up.svg'
import { ReactComponent as KeyDownSVG } from './key_down.svg'

import styles from './styles.module.css'

type KeyType = 'ESC' | 'UP' | 'DOWN' | 'RETURN'

const keySVGs = {
  ESC: KeyEscSVG,
  RETURN: KeyReturnSVG,
  UP: KeyUpSVG,
  DOWN: KeyDownSVG,
}

const Key = ({ keyType }: { keyType: KeyType }) => {
  const KeyComponent = keySVGs[keyType]

  if (!KeyComponent) {
    return <></>
  }

  return (
    <div className={styles.key}>
      <KeyComponent />
    </div>
  )
}

export default Key
