import { DocumentNode, useMutation } from '@apollo/client'
import { ConfirmationModal } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { ErrorBlock } from '@babylon/member-operations'
import React, { useCallback } from 'react'
import { Paper } from '@mui/material'
import { messages } from './messages'

type Props = {
  id: string
  onComplete: () => void
  deleteMutation: DocumentNode
  onClose: () => void
}

export const DeleteObjectModal: React.FC<Props> = ({
  id,
  onComplete,
  onClose,
  deleteMutation,
}) => {
  const [deleteObject, { error }] = useMutation(deleteMutation, {
    context: {
      clientName: 'platform-gateway',
    },
  })

  const fm = useFormatMessage()

  const deleteById = useCallback(async () => {
    try {
      await deleteObject({
        variables: {
          input: {
            id,
          },
        },
      })
      onComplete()
    } catch (e) {
      /* Need this to catch 400 error that are not handled gracefully by Apollo */
      console.error(e)
    }
  }, [id, deleteObject, onComplete])

  return (
    <Paper elevation={3}>
      <ConfirmationModal
        confirmText={<span>{fm(messages.confirmDeleteButton)}</span>}
        cancelText={<span>{fm(messages.cancelButton)}</span>}
        onClose={() => {
          onClose()
        }}
        onConfirm={() => {
          deleteById()
        }}
        open
        includeClose={false}
      >
        <ConfirmationModal.Heading>
          {fm(messages.deleteConfirmationHeader)}
        </ConfirmationModal.Heading>
        <ConfirmationModal.Content>
          {error && (
            <ErrorBlock>
              <p>{`${fm(messages.deleteError)}`}</p>
            </ErrorBlock>
          )}
          <p>{fm(messages.deleteConfirmationContent)}</p>
        </ConfirmationModal.Content>
      </ConfirmationModal>
    </Paper>
  )
}
