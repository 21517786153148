import React, { useEffect } from 'react'

import { Text } from '@babylon/core-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons'
import Overlay from '../Overlay'

import styles from './SyncRunChecker.module.css'

import { useGetHumanitySyncAllowedQuery } from './GetHumanitySyncAllowedQuery.hooks'

const FIVE_SECONDS = 5000 // ms

const SyncRunChecker = () => {
  const { data, loading, stopPolling } = useGetHumanitySyncAllowedQuery({
    pollInterval: FIVE_SECONDS,
  })

  const syncAllowed = Boolean(data?.humanitySyncAllowed?.sync_allowed)

  useEffect(() => {
    if (syncAllowed) {
      stopPolling()
    }
  }, [stopPolling, syncAllowed])

  if (loading || syncAllowed) {
    return null
  }

  return (
    <div data-testid="sync-blocker">
      <Overlay opacity={0.9} />
      <div className={styles.Message}>
        <Text size="large" bold>
          <FontAwesomeIcon icon={faLockAlt} /> Sync unavailable or currently in
          progress
        </Text>

        <Text>
          <p>To cancel a sync, please use the sync listing table below.</p>
        </Text>
      </div>
    </div>
  )
}

export default SyncRunChecker
