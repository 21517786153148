// deprecate when graphql-middleware search is removed
import {
  ConsumerNetwork,
  PublicHealthcareAdmission,
} from '@babylon/graphql-middleware-types'
import get from 'lodash/get'

export const isQueued = (
  consumerNetwork: ConsumerNetwork,
  publicHealthcareAdmission: PublicHealthcareAdmission | null,
  queuedForActivation: boolean
) =>
  !!publicHealthcareAdmission &&
  publicHealthcareAdmission.consumer_network_id ===
    get(consumerNetwork, 'id') &&
  queuedForActivation
