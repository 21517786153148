import { useQuery, QueryHookOptions } from '@apollo/client'

import { REFERRAL_QUERY, MEMBERSHIP_PLANS } from './queries'

export default function useQueryReferrals(
  userId: string,
  options: Pick<QueryHookOptions, 'skip'> = {}
) {
  const {
    error: referralError,
    loading: loadingReferrals,
    data: referral,
  } = useQuery(REFERRAL_QUERY, {
    variables: {
      id: userId,
    },
    ...options,
  })

  const {
    error: membershipError,
    loading: loadingMemberships,
    data: memberships,
  } = useQuery(MEMBERSHIP_PLANS, {
    variables: {
      uuid: userId,
    },
    ...options,
  })

  const membershipNamePerUuid =
    memberships?.getMembershipPlans?.memberships.reduce(
      (
        acc: { [key: string]: string },
        { contract }: Pick<Membership, 'contract'>
      ) => {
        if (contract?.id) {
          acc[contract.id] = contract?.plan?.name
        }

        return acc
      },
      {}
    ) ?? {}

  const referralData =
    referral?.getPatientReferrals.map((referrals: PatientReferral) => ({
      time: referrals?.patient_email_sent_at,
      text: `Referral sent to ${
        membershipNamePerUuid[referrals.contract_uuid]
      }`,
      tag: 'referral',
    })) ?? []

  return {
    data: referralData,
    loading: loadingReferrals || loadingMemberships,
    error: referralError || membershipError,
  }
}
