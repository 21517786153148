import { formatISO } from 'date-fns'
import { utcToZonedTime, format } from 'date-fns-tz'

export interface Clinician {
  id: string
  name: string
  gender: string
  available?: boolean
}

export interface ClinicianAvailability {
  available: boolean
  clinicId: number | null
  consultant: Pick<Clinician, 'id' | 'name' | 'gender'>
}

export interface DataSlot {
  available: boolean
  consultantsAvailability: ClinicianAvailability[]
  UTCTimestamp: string
}

interface HeaderDate {
  date: string
  dayOfWeek: string
  dayOfMonth: string
}

export interface Header extends HeaderDate {
  key: string
  clinicName?: string
  clinicTimezone?: string
}

export interface Slot {
  available: boolean
  clinicId?: number
  consultants: Clinician[]
  date: string
  time: string
  UTCTimestamp: string
}

export interface GroupedSlots {
  headers: Header[]
  times: string[]
  slotIndex: {
    [key: string]: {
      [time: string]: Slot
    }
  }
}

export const flattenClinicianAvailability = ({
  available,
  consultant,
}: ClinicianAvailability): Clinician => ({ available, ...consultant })

export const getTimezoneInfoFromUTCTimestamp = (
  UTCTimestamp: string,
  selectedTimezoneId: string = ''
) => {
  const zonedTime = utcToZonedTime(UTCTimestamp, selectedTimezoneId)

  return {
    zonedTime,
    date: format(zonedTime, 'yyyy-MM-dd', {
      timeZone: selectedTimezoneId,
    }),
    time: format(zonedTime, 'HH:mm', {
      timeZone: selectedTimezoneId,
    }),
  }
}

export const buildEmptySlot = (date: string, time: string): Slot => ({
  available: false,
  consultants: [],
  date,
  time,
  UTCTimestamp: formatISO(new Date(`${date} ${time}`)),
})

export const formatHeaderDate = (date: Date): HeaderDate => ({
  date: format(date, 'yyyy-MM-dd'),
  dayOfWeek: format(date, 'EEE'),
  dayOfMonth: format(date, 'd'),
})
