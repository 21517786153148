import React, { useState } from 'react'
import classnames from 'classnames'
import {
  Anchor,
  Button,
  Text,
  DropdownSelect,
  DropdownSelectOption,
  Tag,
} from '@babylon/core-ui'
import { envFlag } from '@babylon/babylon-env'
import { useQuery, useMutation } from '@apollo/client'
import GetMemberDetailsQuery from './GetMemberDetailsQuery'

import { UnexpectedError } from '../../Utils'
import { LoadingContainer } from '../../components'
import CancelAppointmentMutation from './CancelAppointmentMutation'
import { useCancellationReason } from '../../hooks'

import styles from './styles.module.css'

export interface Props {
  appointmentId: string | number
  cancellable?: boolean
  onCancelAppointment?: () => void
  rtmSlot?: boolean
}

export default function ClinicianVisibilityAppointmentDetails({
  appointmentId,
  cancellable,
  onCancelAppointment = () => {},
  rtmSlot = false,
}: Props) {
  const [
    selectedReasonOption,
    setSelectedReasonOption,
  ] = useState<DropdownSelectOption>()
  const [selectionError, setSelectionError] = useState(false)
  const { data, loading, error: appointmentQueryError } = useQuery(
    GetMemberDetailsQuery,
    {
      variables: {
        appointmentId,
      },
    }
  )

  const [
    onCancel,
    { loading: ongoingCancellation, error: appointmentMutationError },
  ] = useMutation(CancelAppointmentMutation, {
    onCompleted: () => onCancelAppointment(),
  })

  const { data: cancellationReasons } = useCancellationReason({
    skip: !cancellable,
  })

  const handleCancellationClick = () => {
    if (!selectedReasonOption?.key || !appointmentId) {
      setSelectionError(true)

      return
    }

    onCancel({
      variables: {
        appointmentId,
        cancelReasonId: selectedReasonOption?.key,
      },
    })
  }

  const handleSelectedReasonChange = (option: DropdownSelectOption) => {
    if (selectionError) {
      setSelectionError(false)
    }

    setSelectedReasonOption(option)
  }

  let patientName = ''

  const appointmentData = data?.newAppointment ?? {}
  const patientData = appointmentData?.patient ?? {}

  if (patientData?.last_name && patientData?.first_name) {
    patientName = `${patientData.last_name.toUpperCase()}, ${
      patientData.first_name
    }`
  }

  const loadingClassName = classnames({ [styles.loader]: loading })

  return (
    <>
      <UnexpectedError
        visible={Boolean(
          appointmentQueryError?.message || appointmentMutationError?.message
        )}
        message={
          appointmentQueryError?.message || appointmentMutationError?.message
        }
      />
      <LoadingContainer loading={loading} className={loadingClassName}>
        {!loading && !appointmentQueryError && (
          <>
            <Text
              color="highlight-violet"
              size="large"
              bold
              tag="div"
              data-testid="cv-detail-popup-patient-name"
            >
              <Anchor
                href={`/admin/patients/${patientData.id}/memberships`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.anchor}
              >
                {patientName}
              </Anchor>
              {envFlag('CLINICIAN_VISIBILITY_RTM_SUPPORT') && rtmSlot && (
                <Tag data-testid="rtm-tag" color="light-teal">
                  RTM
                </Tag>
              )}
            </Text>
            <Text
              className={styles.text}
              tag="div"
              data-testid="cv-detail-popup-patient-dob"
            >
              DOB: {patientData?.date_of_birth}
            </Text>
            <Text
              color="grey-type"
              className={styles.text}
              tag="div"
              data-testid="cv-detail-popup-appointment-reason"
            >
              {appointmentData?.listing_details?.appointment_reason}
            </Text>
            <Text
              className={styles.cancelLabel}
              color={selectionError ? 'error' : 'grey-type'}
              size="large"
              tag="div"
            >
              Select reason for cancellation
            </Text>
            <DropdownSelect
              disabled={!cancellable || ongoingCancellation}
              hasError={selectionError}
              onChange={handleSelectedReasonChange}
              options={cancellationReasons}
              selectedOption={selectedReasonOption}
            />
            <Button
              dataTestId="cancel-cta"
              className={styles.cancelAppointment}
              fill
              disabled={!cancellable || ongoingCancellation}
              onClick={handleCancellationClick}
              loading={ongoingCancellation}
            >
              Cancel Appointment
            </Button>
          </>
        )}
      </LoadingContainer>
    </>
  )
}
