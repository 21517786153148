import { ChatscriptMessage } from '@babylon/graphql-middleware-types'
import React from 'react'
import styles from './Message.module.scss'

const MessageContent = ({ value, input }: ChatscriptMessage) => {
  const choices = input?.params?.choices

  return (
    <>
      <div className={styles.Message__body}>{value?.text}</div>
      {choices && (
        <ul className={styles.Message__choices}>
          {choices.map(({ id, label }) => (
            <li key={id}>
              <span>{label}</span>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default MessageContent
