import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import Unknown from '../../../Unknown'
import messages from './ReferralInfo.messages'
import styles from './styles.module.scss'
import { getSpecialismCategories } from './queries'
import { Referral } from '../../../types'

interface ReferralInfoProps {
  id: string
  referrals: Referral[]
}

const ReferralInfo = ({ id, referrals }: ReferralInfoProps) => {
  const variables = { consultationId: id }
  const { loading, data } = useQuery(getSpecialismCategories, {
    variables,
    skip: !id,
  })

  const specialismCategories = data?.specialismCategories ?? []

  if (loading) {
    return null
  }

  return (
    <>
      {referrals.map((referral) => (
        <div key={referral.id} className={styles.ReferralInfo}>
          <div className={styles.ReferralInfo__moreDetailsSpecialism}>
            <div className={styles.ReferralInfo__moreDetailsHeading}>
              <FormattedMessage {...messages.referral_speciality} />
            </div>
            <div data-testid="timeline-card-referral-speciality">
              {referral.specialismName}
            </div>
          </div>
          <div className={styles.ReferralInfo__moreDetailsSpecialismCategory}>
            <div className={styles.ReferralInfo__moreDetailsHeading}>
              <FormattedMessage {...messages.referred_to} />
            </div>
            <div data-testid="timeline-card-referral-referred-to">
              {specialismCategoryForReferral(referral, specialismCategories)}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

function specialismCategoryForReferral(referral, specialismCategories) {
  if (!specialismCategories || specialismCategories.length === 0) {
    return <Unknown />
  }
  const category = specialismCategories.find(
    (c: { id: string }) => c.id == referral.specialismCategoryId
  )
  return category ? category.name : <Unknown />
}

export default ReferralInfo
