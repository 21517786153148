import React from 'react'
import { Formik, Form, Field, FormikHelpers } from 'formik'
import { Button, FormikCheckboxGroup, Checkbox } from '@babylon/core-ui'
import { envFlag } from '@babylon/babylon-env'
import {
  AppointmentMediumOption,
  AppointmentStatus,
  AVAILABLE_SLOTS_FILTER_VALUE,
} from '../AppointmentManagementPage.types'
import { AppointmentFilters as FilterOptionsFormValues } from '../types'

import styles from './FilterOptionsForm.module.css'

export interface FilterOptionsFormProps {
  isSubmitting: boolean
  onSubmit: (value: FilterOptionsFormValues) => void
  appointmentFilters: FilterOptionsFormValues
}

export const MediumOptions = [
  {
    id: AppointmentMediumOption.video,
    label: 'Video',
    value: AppointmentMediumOption.video,
  },
  {
    id: AppointmentMediumOption.voice,
    label: 'Call',
    value: AppointmentMediumOption.voice,
  },
  {
    id: AppointmentMediumOption.physical,
    label: 'Face to Face',
    value: AppointmentMediumOption.physical,
  },
]

export const StatusOptions = [
  {
    id: AppointmentStatus.Booked,
    label: 'Booked',
    value: AppointmentStatus.Booked,
  },
  {
    id: AppointmentStatus.Fulfilled,
    label: 'Completed',
    value: AppointmentStatus.Fulfilled,
  },
  {
    id: AppointmentStatus.Noshow,
    label: 'No Show',
    value: AppointmentStatus.Noshow,
  },
  {
    id: AppointmentStatus.Cancelled,
    label: 'Cancelled',
    value: AppointmentStatus.Cancelled,
  },
]

export const AvailableSlotsOption = {
  id: AVAILABLE_SLOTS_FILTER_VALUE,
  label: 'Available',
  value: AVAILABLE_SLOTS_FILTER_VALUE,
}

const defaultValues: FilterOptionsFormValues = {
  appointmentStatus: [],
  appointmentMedium: [],
}

const resetAllFields = (form: FormikHelpers<FilterOptionsFormValues>) => {
  Object.keys(defaultValues).forEach((fieldValue) =>
    form.setFieldValue(fieldValue, [])
  )
}

const FilterOptionsForm = ({
  isSubmitting,
  onSubmit,
  appointmentFilters,
}: FilterOptionsFormProps) => {
  const handleFormSubmit = (values: FilterOptionsFormValues) => {
    onSubmit(values)
  }

  const appointmentManagementMediumFilterEnabled = !!envFlag(
    'ENABLE_APPOINTMENT_MANAGMENT_MEDIUM_FILTER'
  )

  const physicalAppointmentsEnabled = !!envFlag(
    'ENABLE_PHYSICAL_APPOINTMENTS_FILTER'
  )

  const appointmentManagementAvailableSlotsFilterEnabled = !!envFlag(
    'ENABLE_APPOINTMENT_MANAGMENT_AVAILABLE_SLOTS_FILTER'
  )

  const formMediumOptions = physicalAppointmentsEnabled
    ? MediumOptions
    : MediumOptions.filter(({ id }) => id !== AppointmentMediumOption.physical)

  const availableSlotsOptions = appointmentManagementAvailableSlotsFilterEnabled
    ? [AvailableSlotsOption]
    : []

  const statusAndAvailableSlotsOptions = [
    ...availableSlotsOptions,
    ...StatusOptions,
  ]

  return (
    <Formik
      initialValues={{ ...defaultValues, ...appointmentFilters }}
      onSubmit={handleFormSubmit}
    >
      <Form aria-label="Filter" className={styles.form}>
        {appointmentManagementMediumFilterEnabled && (
          <FormikCheckboxGroup
            id="medium"
            name="appointmentMedium"
            label="Appointment Medium"
          >
            {formMediumOptions.map((option) => (
              <Checkbox
                key={option.id}
                id={option.id}
                testId={`medium-${option.id}`}
                name={option.id}
                value={option.value}
                type="checkbox"
                inline
                className={styles.checkbox}
              >
                {option.label}
              </Checkbox>
            ))}
          </FormikCheckboxGroup>
        )}
        <FormikCheckboxGroup
          id="status"
          name="appointmentStatus"
          label="Appointment Status"
        >
          {statusAndAvailableSlotsOptions.map((option) => (
            <Checkbox
              key={option.id}
              id={option.id}
              testId={`status-${option.id}`}
              name={option.id}
              value={option.value}
              type="checkbox"
              inline
              className={styles.checkbox}
            >
              {option.label}
            </Checkbox>
          ))}
        </FormikCheckboxGroup>
        <div className={styles.formButtonsBlock}>
          <Field>
            {({ form }) => (
              <Button
                type="button"
                intent="secondary"
                onClick={() => resetAllFields(form)}
              >
                Clear
              </Button>
            )}
          </Field>
          <Button
            type="submit"
            intent="primary"
            loading={isSubmitting}
            className={styles.submitButton}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

export default FilterOptionsForm
