import { useEffect, useState } from 'react'

/**
 * Hook provides a way to monitor whether or not a component is mounted (or not)
 */

export default function useIsMounted() {
  const [isMounted, setisMounted] = useState(false)
  useEffect(() => {
    setisMounted(true)
    return () => setisMounted(false)
  }, [])

  return isMounted
}
