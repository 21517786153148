import React, { useCallback, useState, useContext, ReactNode } from 'react'

interface ErrorManagerProviderType {
  children: ReactNode
}

export interface ErrorManagerContextType {
  errorMessage: string
  resetErrorMessage: () => void
  setErrorMessage: (message: string) => void
}

const ErrorManagerContext = React.createContext<ErrorManagerContextType>({
  errorMessage: '',
  resetErrorMessage: () => {},
  setErrorMessage: () => {},
})

export const useErrorManager = () =>
  useContext<ErrorManagerContextType>(ErrorManagerContext)

export default function ErrorManagerProvider({
  children,
}: ErrorManagerProviderType) {
  const [errorMessage, setErrorMessage] = useState('')

  const resetErrorMessage = useCallback(() => setErrorMessage(''), [
    setErrorMessage,
  ])

  return (
    <ErrorManagerContext.Provider
      value={{
        errorMessage,
        resetErrorMessage,
        setErrorMessage,
      }}
    >
      {children}
    </ErrorManagerContext.Provider>
  )
}
