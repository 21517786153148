import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useField } from 'formik'
import OutsideClickHandler from 'react-outside-click-handler'
import { Input, Label, DropdownOptions } from '@babylon/core-ui'
import { fieldLabelMap } from '../ClinicianVisibilitySearchValidation'
import { useDebounce } from '../../../hooks'
import { MultiTag } from '../../../components'
import GetClinicsPerIdQuery from './GetClinicsPerIdQuery'
import GetClinicLocationQuery from './GetClinicLocationQuery'
import styles from './ClinicNameFilter.module.css'

export default function ClinicNameFilter() {
  const formField = 'clinicId'
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const debouncedValue = useDebounce(searchValue, 350)
  const [{ value }, { error }, { setValue }] = useField(formField)
  const { data } = useQuery(GetClinicLocationQuery, {
    variables: {
      name: debouncedValue,
    },
    skip: !debouncedValue,
  })

  // Only request Clinic info if information is missing from component state, e.g. page refresh
  const missingClinicIds = value
    ?.filter((clinic) => !clinic?.name)
    ?.map((clinic) => clinic?.id)

  const { data: clinic } = useQuery(GetClinicsPerIdQuery, {
    variables: { ids: missingClinicIds },
    skip: !missingClinicIds?.length,
  })

  /*
   * Formik has an issue with setValue where it has a diferent reference on every render
   * If it was part of the dependencies of the effect, it would make it run always.
   * cf. https://github.com/jaredpalmer/formik/issues/2268
   */
  useEffect(() => {
    if (clinic) {
      setValue(clinic?.practicesByIds ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic])

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  const onSuggestionClick = ({ key, value: clinicName }) => {
    const clinicAlreadyExists = value.find(
      ({ id: clinicId }) => clinicId === key
    )

    if (!clinicAlreadyExists) {
      setValue([
        ...value,
        {
          id: key,
          name: clinicName,
        },
      ])
    }

    setIsOpen(false)
    setSearchValue('')
  }

  return (
    <>
      <OutsideClickHandler
        disabled={!isOpen}
        onOutsideClick={() => {
          setSearchValue('')
          setIsOpen(false)
        }}
      >
        <Label htmlFor="clinic-name-input">{fieldLabelMap[formField]}</Label>
        <Input
          id="clinic-name-input"
          data-testid="clinic-name-input"
          onFocus={() => setIsOpen(true)}
          onChange={handleChange}
          value={searchValue}
          fill
          intent={error ? 'error' : undefined}
        />
        {isOpen && data?.practices?.length > 0 && (
          <div className={styles.SuggestionContainer}>
            <DropdownOptions
              options={data?.practices?.map((practice) => ({
                key: practice.id,
                value: practice.name,
              }))}
              onOptionSelect={onSuggestionClick}
            />
          </div>
        )}
      </OutsideClickHandler>
      <MultiTag
        tags={value?.map((practice) => ({
          key: practice?.id,
          name: practice?.name,
        }))}
        onChange={(tags) =>
          setValue(
            tags.map((tag) => ({
              id: tag.key,
              name: tag.name,
            }))
          )
        }
      />
    </>
  )
}
