import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import { TimezoneDate } from '../../TimezoneDate'
import styles from './Slot.module.css'
import { ReactComponent as TimeIcon } from './time.svg'

export interface SlotProps {
  timezone: string
  digitalBookable: boolean
  physicalBookable: boolean
  slotTime: string
  slotSize: number
  isAdmin: boolean
}

const getSlotTypeText = (
  digitalBookable: boolean,
  physicalBookable: boolean
): string => {
  if (digitalBookable && physicalBookable) {
    return 'Digital / Physical Appointment'
  }

  if (digitalBookable) {
    return 'Digital Appointment'
  }

  if (physicalBookable) {
    return 'Physical Appointment'
  }

  return ''
}

const Slot = ({
  timezone,
  slotSize,
  slotTime,
  physicalBookable,
  digitalBookable,
  isAdmin,
}: SlotProps) => {
  const detailText = isAdmin ? 'Admin Time' : 'Available Time'

  return (
    <article className={styles.slotBlock}>
      <div className={styles.times}>
        {!isAdmin && (
          <div className={styles.time}>
            {slotTime ? (
              <TimezoneDate
                date={slotTime}
                timezone={timezone}
                format="HH:mm"
              />
            ) : (
              '-'
            )}
          </div>
        )}
        <div className={styles.duration}>
          {slotSize ? <span>{slotSize} min</span> : '-'}
        </div>
      </div>
      <div
        className={classNames(styles.card, {
          [styles.cardAvailableSlot]: !isAdmin,
        })}
      >
        <div className={styles.type}>
          {isAdmin ? (
            <TimeIcon title="Physical appointment" />
          ) : (
            <FontAwesomeIcon icon={faCalendar} title="Slot" size="lg" />
          )}
        </div>
        <div className={styles.detailContainer}>
          <header className={styles.details}>
            <b> {detailText} </b>
            {!isAdmin && (
              <span>{getSlotTypeText(digitalBookable, physicalBookable)}</span>
            )}
          </header>
        </div>
      </div>
    </article>
  )
}
export default Slot
