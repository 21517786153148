import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Tag, Card, Heading } from '@babylon/core-ui'
import {
  Maybe,
  WorkflowState,
  WorkflowTaskVariable,
} from '@babylon/graphql-middleware-types'
import { envUrl } from '@babylon/babylon-env'
import ConsumerNetworkTag from '../../../ConsumerNetworkTag'
import Icon from '../../icons/WorkflowIcon'
import MediumIcon from '../../MediumIcon'
import Details from './Details'

import { getFormattedDate } from '../../utils'

import messages from './WorkflowCard.messages'
import styles from './styles.module.scss'
import Menu from './Menu'

export interface WorkflowCardProps {
  id: string
  consumerNetworkName: Maybe<string>
  workflowName: Maybe<string>
  definitionKey: Maybe<string>
  date?: Maybe<string>
  owner?: Maybe<string>
  state?: Maybe<string>
  variables?: Maybe<Array<Maybe<WorkflowTaskVariable>>>
}

const ResponsiveWorkflowCard = ({
  id,
  date,
  consumerNetworkName,
  workflowName,
  definitionKey,
  state,
  owner,
  variables,
}: WorkflowCardProps) => {
  const isCompleted = state === WorkflowState.Completed
  const locale = (localStorage.getItem('locale') || 'en_GB').replace('_', '-') // todo: update with intl utilities

  return (
    <div className={styles.WorkflowCard__wrapper} data-testid="timeline-card">
      <MediumIcon icon={<Icon className={styles.WorkflowCard__icon} />} />
      <Card className={styles.WorkflowCard}>
        <div className={styles.WorkflowCard__summary}>
          <div>
            <div
              className={styles.WorkflowCard__date}
              data-testid="timeline-card-scheduled-time"
            >
              {date && (
                <FormattedMessage
                  {...messages[isCompleted ? 'completed_date' : 'due_date']}
                  values={{ date: getFormattedDate({ date, locale }) }}
                />
              )}
              {!isCompleted && (
                <Tag className={styles.WorkflowCard__active}>
                  <FormattedMessage {...messages.active} />
                </Tag>
              )}
            </div>
          </div>

          <div className={styles.WorkflowCard__cardNetworkTagContainer}>
            <ConsumerNetworkTag
              name={consumerNetworkName}
              isPreferred
              className={styles.WorkflowCard__cardNetworkTag}
            />
          </div>

          <div>
            <Heading
              level="h3"
              data-testid="timeline-card-title"
              className={styles.WorkflowCard__title}
            >
              <FormattedMessage
                {...messages.title}
                values={{ title: workflowName }}
              />
            </Heading>
          </div>

          <div className={styles.WorkflowCard__menu}>
            <Menu
              camundaLink={`${envUrl('CAMUNDA_TASK_URL')}?task=${id}`}
              completed={isCompleted}
            />
          </div>
        </div>

        <Details
          owner={owner || null}
          definitionKey={definitionKey || null}
          variables={variables || []}
          show
          isCompleted={isCompleted}
          locale={locale}
        />
      </Card>
    </div>
  )
}

export default ResponsiveWorkflowCard
