import { NavCategory, NavItem, NavLink } from './types'
import { navStructure } from './navLinks'

const isNavCategory = (navItem: NavItem): navItem is NavCategory =>
  !!(navItem as NavCategory).links

const isNavLink = (navItem: NavItem): navItem is NavLink =>
  !!(navItem as NavLink).link

const createNavStructure = (nav: NavItem[], navItem: NavItem) => {
  if (isNavCategory(navItem) && !navItem.hidden) {
    const subLinks = navItem.links.filter((link) => !link.hidden)

    if (subLinks.length) {
      return [
        ...nav,
        {
          ...navItem,
          links: subLinks,
        },
      ]
    }
  }

  if (isNavLink(navItem) && !navItem.hidden) {
    return [...nav, navItem]
  }

  return nav
}

const getNavStructure = (navigationFlags: any) =>
  navStructure(navigationFlags).reduce(createNavStructure, [])

export { getNavStructure, isNavLink, isNavCategory }
