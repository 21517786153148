import { defineMessages } from 'react-intl'

const baseId = 'copy_to_clipboard_button'

export default defineMessages({
  label: {
    id: `${baseId}.label`,
    defaultMessage: 'Copy to clipboard',
  },
})
