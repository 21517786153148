import React, { ReactNode } from 'react'

import { ErrorManagerProvider, useErrorManager } from '../Utils'

export const ERROR_MESSAGES = {
  overflow:
    'The shift you selected cuts across an appointment. Select a different shift time to continue.',
  overlap:
    'Covering clinician has existing slots at this time. Change time or clinician.',
}

export const useReallocateShiftErrorManager = useErrorManager

export default function ReallocateShiftErrorManager({
  children,
}: {
  children: ReactNode
}) {
  return <ErrorManagerProvider>{children}</ErrorManagerProvider>
}
