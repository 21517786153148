import React, { FC } from 'react'
import classnames from 'classnames/bind'
import compose from 'lodash/fp/compose'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import { Tag } from '@babylon/core-ui'
import styles from './styles.module.scss'

export interface ConsumerNetworksTag {
  isPreferred: boolean
  name: string
  id?: string
}

export interface ConsumerNetworksTagsProps {
  consumerNetworks: ConsumerNetworksTag[]
  tagClassName?: string
}

const cx = classnames.bind(styles)

const ConsumerNetworksTags: FC<ConsumerNetworksTagsProps> = ({
  consumerNetworks,
  tagClassName,
}) => (
  <div className={styles.tags} data-testid="consumer-networks-tags">
    {compose(
      map(({ id, isPreferred, name }) => (
        <Tag
          key={id || name}
          className={cx('tag', tagClassName, {
            'tag--preferred': isPreferred,
          })}
        >
          {name}
        </Tag>
      )),
      sortBy(({ isPreferred }) => (isPreferred ? 0 : 1))
    )(consumerNetworks)}
  </div>
)

export default ConsumerNetworksTags
