import React from 'react'
import { useMutation, ApolloError } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { Button, Heading } from '@babylon/core-ui'
import { AlertFilled } from '@babylon/icons'
import {
  getStatusText,
  isErrorStatus,
  PDSStatuses,
} from '../../../../../util/pdsStatus'
import { errorListFromQueryError } from '../../../../../util/helpers'
import { fullPatientFields, PATIENT_QUERY } from '../../../../../queries'
import styles from './PDSStatus.module.scss'
import { PDS_LOOKUP } from './queries'
import messages from './PDSStatus.messages'

export interface PDSStatusProps {
  exactMatch: boolean | null
  patientId: string
  status: PDSStatuses | null
  lookupLimit: number
  onRetryPDSLookup?(error?: string[], newStatus?: string): void
}

const PDSStatus = ({
  patientId,
  status,
  exactMatch,
  lookupLimit,
  onRetryPDSLookup,
}: PDSStatusProps) => {
  const onMutationErrorHandler = (error: ApolloError) => {
    if (onRetryPDSLookup) {
      const parsedErrors = errorListFromQueryError(error)
      onRetryPDSLookup(parsedErrors.map((err) => err.message).join(', '))
    }
  }
  const onMutationCompleteHandler = ({ lookupDemographics }: any) => {
    if (onRetryPDSLookup) {
      onRetryPDSLookup(
        ['Retry successful, PDS pending'],
        lookupDemographics.status
      )
    }
  }
  const [pdsLookup] = useMutation(PDS_LOOKUP, {
    onCompleted: onMutationCompleteHandler,
    onError: onMutationErrorHandler,
    refetchQueries: [
      {
        query: PATIENT_QUERY(fullPatientFields),
        variables: {
          patientId,
        },
      },
    ],
  })

  if (!status) {
    return null
  }

  const retryable = isErrorStatus(status)
  const variables = { patientId }

  return (
    <div data-testid="pds-status">
      <Heading level="h3">
        <FormattedMessage {...messages.heading} />
      </Heading>
      <div className={styles.pdsError}>
        {retryable && <AlertFilled className={styles.pdsError__icon} />}
        <span data-testid="pds-status-text">
          {exactMatch ? (
            <FormattedMessage {...messages.exact_match} />
          ) : (
            getStatusText(status)
          )}
        </span>
        {retryable && lookupLimit > 0 && (
          <Button
            testId="pds-status-retry"
            intent="secondary"
            className={styles.pdsError__retry}
            onClick={() => pdsLookup({ variables })}
          >
            <FormattedMessage {...messages.retry_button} />
          </Button>
        )}
      </div>
    </div>
  )
}

export default PDSStatus
