import React, { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Cell, Grid, KebabMenu, KebabMenuRenderOptions } from '@babylon/core-ui'
import { ViewMoreToggler } from '../../../../..'
import {
  OnDetailsCopy,
  OnTransferOwnership,
  OnResendInvitationEmail,
} from '../../../types'
import Headline from '../Headline'
import Details from '../Details'
import { TransferOwnershipModal } from './Modals'
import {
  Actions,
  TransferOwnershipButton,
  ResendInvitationEmailButton,
} from './ActionButtons'
import messages from './MemberCard.messages'
import styles from './styles.module.scss'
import { useSearchFeaturesContext } from '../../../SearchFeaturesContext'

export interface MemberCardProps extends Member {
  id: string
  owner_id: string
  onDetailsCopy: OnDetailsCopy
  onTransferOwnership: OnTransferOwnership
  expanded?: boolean
  isMain?: boolean
  onFamilyMembersToggle?: (id: string) => void
  onResendInvitationEmail: OnResendInvitationEmail
}

export interface ExtendedProps extends MemberCardProps {
  canBeMadeOwner: boolean
  canHaveInvitationResent: boolean
  hasFamilyMembers: boolean
}

export interface AccountActionState {
  action?: Actions
  mutation?: any
}

const extendProps = (props: MemberCardProps): ExtendedProps => ({
  ...props,
  canBeMadeOwner: !props.minor && !!props.owner_id,
  canHaveInvitationResent: props.invitation_was_sent_but_not_accepted,
  hasFamilyMembers:
    props.family_accounts_member_ids &&
    props.family_accounts_member_ids.length > 0,
})

const MemberCard: FC<MemberCardProps> = (props) => {
  const [accountAction, setAccountAction] = useState<AccountActionState>({})
  const {
    id,
    canBeMadeOwner,
    canHaveInvitationResent,
    expanded = false,
    hasFamilyMembers,
    isMain = false,
    owner_id,
    onDetailsCopy,
    onFamilyMembersToggle,
    onTransferOwnership,
    onResendInvitationEmail,
  } = extendProps(props)

  const toggleClickHandler = () => {
    onFamilyMembersToggle && onFamilyMembersToggle(id)
  }

  const transferOwnershipHandler = (transferOwnership: any) => () => {
    if (transferOwnership) {
      transferOwnership({
        variables: {
          oldOwnerId: owner_id,
          newOwnerId: id,
        },
      })
    }
  }

  const actionHandler = (action: Actions, mutation: any) => () =>
    setAccountAction({ action, mutation })

  const renderKebabMenu = ({ menuItemProps }: KebabMenuRenderOptions) => (
    <div data-testid="member-card-more-menu">
      {canBeMadeOwner && (
        <TransferOwnershipButton
          menuItemProps={menuItemProps}
          onClick={actionHandler}
          onComplete={(data) => {
            onTransferOwnership(data.new_owner_id)
            setAccountAction({})
          }}
          onError={(error) => {
            onTransferOwnership(undefined, error)
            setAccountAction({})
          }}
        />
      )}
      {canHaveInvitationResent && (
        <ResendInvitationEmailButton
          menuItemProps={menuItemProps}
          patientId={id}
          onComplete={(data) => {
            onResendInvitationEmail(data.id)
          }}
          onError={(error) => {
            onResendInvitationEmail(undefined, error)
          }}
        />
      )}
    </div>
  )

  const {
    enableResultActions,
    enableFamilyAccounts,
  } = useSearchFeaturesContext()

  return (
    <Grid className={styles.memberCard} columns={12} gap={16}>
      <TransferOwnershipModal
        open={accountAction.action === Actions.TransferOwnership}
        onCancel={() => setAccountAction({})}
        onConfirm={transferOwnershipHandler(accountAction.mutation)}
      />
      <Cell width={12}>
        <Headline {...props} />
      </Cell>
      {enableResultActions && (
        <div className={styles.actions}>
          {(canBeMadeOwner || canHaveInvitationResent) && (
            <KebabMenu>{renderKebabMenu}</KebabMenu>
          )}
        </div>
      )}
      <Cell width={12}>
        <Details {...props} onDetailsCopy={onDetailsCopy} />
      </Cell>
      {enableFamilyAccounts && hasFamilyMembers && isMain ? (
        <Cell width={12}>
          <ViewMoreToggler
            open={expanded}
            testId="member-card-view-family-members"
            onToggle={toggleClickHandler}
          >
            {expanded ? (
              <FormattedMessage {...messages.hide_family_members} />
            ) : (
              <FormattedMessage {...messages.show_family_members} />
            )}
          </ViewMoreToggler>
        </Cell>
      ) : null}
    </Grid>
  )
}

export default MemberCard
