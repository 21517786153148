import React from 'react'
import { format as formatDate } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { BabylonUser } from '@babylon/babylon-user'
import { logException } from '@babylon/member-operations'

// force the date to wrap before the time ('\u00a0' = &nbsp;)
// the expected wrapped look:
//
//  18/08/2018
//  8:25 pm
//

const withErrorSafety = (wrappedFunction) => (...args) => {
  try {
    return wrappedFunction(...args)
  } catch (error) {
    logException(error)
    return 'N/A'
  }
}

const errorSafeFormatDate = withErrorSafety(formatDate)
const errorSafeUtcToZonedTime = withErrorSafety(utcToZonedTime)

const TimezoneDate = ({ date, timezone, format }) => {
  const dateFormat = format || 'dd/MM/yyyy h:mm\u00a0a'

  if (!date) {
    return <div>N/A</div>
  }

  return timezone ? (
    <div>
      {errorSafeFormatDate(errorSafeUtcToZonedTime(date, timezone), dateFormat)}
    </div>
  ) : (
    <div>
      <BabylonUser>
        {(user) =>
          user.timezone
            ? errorSafeFormatDate(
                errorSafeUtcToZonedTime(date, user.timezone),
                dateFormat
              )
            : errorSafeFormatDate(new Date(date), dateFormat)
        }
      </BabylonUser>
    </div>
  )
}

export default TimezoneDate
