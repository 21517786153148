import React from 'react'
import { Tooltip } from '@babylon/core-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faExclamationCircle,
} from '@fortawesome/pro-light-svg-icons'

const WarningIcon = ({ message }: { message?: string }) => (
  <Tooltip placement="left" message={message}>
    <FontAwesomeIcon
      data-testid="warning-slot-icon"
      icon={faExclamationTriangle}
      size="lg"
      color="red"
    />
  </Tooltip>
)

const InfoIcon = () => (
  <FontAwesomeIcon
    data-testid="info-slot-icon"
    icon={faExclamationCircle}
    size="lg"
  />
)

const WARNING_ICONS = {
  orphan: <WarningIcon />,
  overflow: (
    <WarningIcon message="The shift you selected cuts across this appointment" />
  ),
  other: <InfoIcon />,
}

export type ShiftSlotWarningTypes = keyof typeof WARNING_ICONS

export default WARNING_ICONS
