import React from 'react'
import { useQuery } from '@apollo/client'
import Select from 'react-select'
import { useField } from 'formik'
import { Label } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'

import { fieldLabelMap } from '../ClinicianVisibilitySearchValidation'

import GetServiceTypeQuery from './GetServiceTypeQuery'

export default function ServiceTypeSelect() {
  const { getProp } = useProductConfig()
  const digitalInviteExtensionEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteExtensionEnabled'
  )
  const { data, loading } = useQuery(GetServiceTypeQuery, {
    variables: {
      includingDigital: digitalInviteExtensionEnabled,
    },
  })
  const formField = 'serviceType'
  const [{ value }, { error }, { setValue }] = useField(formField)

  /*
   * Form value supports multi selection - keeping it as the plan is to
   * implement it in the near future
   */
  const [selectedOption] = value

  /*
   * react-select doesn't have a way to use classes to change the control style
   */
  const selectErrorBorder = error ? { borderColor: '#ff3b2f' } : {}

  return (
    <>
      <Label htmlFor={`${formField}-id`}>{fieldLabelMap[formField]}</Label>
      <Select
        disabled={loading}
        id={`${formField}-id`}
        options={data?.serviceTypes.map((serviceType) => ({
          key: serviceType.uuid,
          name: serviceType.name,
        }))}
        labelKey="name"
        valueKey="key"
        onChange={(option) => setValue(option?.key ? [option?.key] : [])}
        style={selectErrorBorder}
        matchProp="label"
        value={selectedOption}
      />
    </>
  )
}
