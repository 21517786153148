import { defineMessages } from 'react-intl'

const baseId = 'entries_history'

export default defineMessages({
  printConversation: {
    id: `${baseId}.print_conversation`,
    defaultMessage: 'Print conversation',
  },
})
