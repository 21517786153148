import React from 'react'
import { useField } from 'formik'

import { PillContainer, FormField } from '@babylon/core-ui'

export enum Mediums {
  f2f = 'f2f',
  digital = 'digital',
}

export type MediumTypes = keyof typeof Mediums

const pills = [
  { name: 'Face to face', id: Mediums.f2f },
  { name: 'Digital', id: Mediums.digital },
]
export type AppointmentMediumProps = {
  onChange: (value: MediumTypes) => void
  selectedMedium: MediumTypes | null
}

const AppointmentMedium = ({
  selectedMedium,
  onChange,
}: AppointmentMediumProps) => {
  const [, , { setValue: setAppointmentType }] = useField('appointment_type')
  const [, , { setValue: setDurationValue }] = useField('duration_minutes')

  return (
    <FormField id="appointment_medium" label="Appointment Medium">
      <PillContainer
        pills={pills}
        selected={selectedMedium}
        onSelectPill={(newSelectedMedium: MediumTypes) => {
          setAppointmentType('')
          setDurationValue(null)
          onChange(newSelectedMedium)
        }}
      />
    </FormField>
  )
}

export default AppointmentMedium
