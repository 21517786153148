import {
  addMinutes,
  format,
  startOfDay,
  getMinutes,
  formatISO,
  parseISO,
} from 'date-fns'

import { getTimezoneInfoFromUTCTimestamp } from '../AppointmentAvailability/AvailabilitySlots/GroupSlots'

const minSlotLength = 5

const getIntervals = (hours, interval: number) => (60 / interval) * hours

interface CreateTimeArgs {
  day: string
  timezoneId?: string
  interval?: number
  formatFunc?: (value: string | Date | number) => string
  hours?: number
  index?: number
  times?: string[]
  startAt?: {
    hours: number
    mins: number
  }
}

const formatToISO = (time, timezoneId) => {
  const nextTime = typeof time === 'string' ? new Date(time) : time
  let formattedNextTime = ''
  if (timezoneId === 'Etc/UTC') {
    formattedNextTime = nextTime.toISOString()
  } else {
    formattedNextTime = formatISO(nextTime)
  }
  return formattedNextTime.split('.000Z').join('Z')
}

export const createTimes = ({
  day,
  interval = 5,
  timezoneId,
  formatFunc,
  hours = 24,
  index = 0,
  times = [],
  startAt,
}: CreateTimeArgs) => {
  let nextIndex = index

  if (startAt && (startAt.hours > 0 || startAt.mins > 0) && index === 0) {
    const totalMins = startAt.hours * 60 + startAt.mins
    nextIndex = getIntervals(totalMins / 60, interval)
  }

  if (nextIndex === getIntervals(hours, interval)) return times
  const nextTime = addMinutes(startOfDay(parseISO(day)), nextIndex * interval)

  let formattedNextTime = ''
  if (formatFunc) {
    formattedNextTime = formatFunc(nextTime)
  } else {
    formattedNextTime = formatToISO(nextTime, timezoneId)
  }

  return createTimes({
    day,
    interval,
    timezoneId,
    formatFunc,
    hours,
    index: nextIndex + 1,
    times: [...times, formattedNextTime],
  })
}

export const formatTime = (time: string | Date | number) => {
  const dateTime = typeof time === 'string' ? parseISO(time) : new Date(time)

  if (getMinutes(dateTime) === 0) {
    return format(dateTime, 'ha').toLowerCase()
  }

  return format(dateTime, 'h.mma').toLowerCase()
}

export const formatTimes = (times: string[]) => times.map(formatTime)

interface FormattedSlot extends VisibilitySlot {
  isoZonedTime?: string
  timeline_slot?: string
}

export const mapClinicianSlotsToTimes = (
  times: string[],
  slots: VisibilitySlot[],
  timezoneId?: string
): FormattedSlot[] => {
  const slotsByTime = slots.reduce((acc, slot) => {
    const { zonedTime } = getTimezoneInfoFromUTCTimestamp(
      slot.slot_time,
      timezoneId
    )

    const isoZonedTime = formatToISO(zonedTime, timezoneId)

    acc[isoZonedTime] = {
      ...slot,
      isoZonedTime,
    }

    return acc
  }, {})

  const nextSlots = (times, output: any = [], index = 0) => {
    if (index >= times.length) return output

    let nextOutput: FormattedSlot | null = null

    const normFormatted = times[index]

    if (slotsByTime?.[normFormatted]) {
      nextOutput = {
        ...slotsByTime[normFormatted],
        timeline_slot: times[index],
      }
    }

    if (!nextOutput) {
      return nextSlots(
        times,
        [...output, { timeline_slot: times[index] }],
        index + 1
      )
    }

    const nextIndex = index + nextOutput.slot_size / minSlotLength

    return nextSlots(times, [...output, nextOutput], nextIndex)
  }

  return nextSlots(times)
}
