import React, { useState, useEffect } from 'react'
import { useProductConfig } from '@babylon/product-config'
import PlatformGatewaySearch from './PlatformGatewaySearch'
import GraphQLMiddlewareSearch from './GraphQLMiddlewareSearch'
import CoreRubySearch from './CoreRubySearch'
import { useCookiePersistedState } from '..'

const PG_SEARCH = '3'
const GQL_SEARCH = '2'
const CORE_RUBY = '1'

const MembersSearch = () => {
  const { getProp } = useProductConfig()
  const platformGatewaySearchEnabled = getProp(
    'memberOperations',
    'platformGatewaySearchEnabled'
  )

  const [showRubyBanner, setShowRubyBanner] = useCookiePersistedState(
    'mop-search-banner',
    true
  )

  const sessionStorageVersion = sessionStorage.getItem('version')

  // sets the current loaded version based on if the user has previously selected a version or not
  const [version, setVersion] = useState(
    sessionStorageVersion || (platformGatewaySearchEnabled ? '3' : '2')
  )

  /*
     when loading product config the value returned is updated in the hook, as we use state to store the version this needs to be updated
     if we get a new value for that flag.
  */

  useEffect(() => {
    setVersion(
      sessionStorageVersion || (platformGatewaySearchEnabled ? '3' : '2')
    )
  }, [platformGatewaySearchEnabled, sessionStorageVersion])

  const switchVersion = (searchVersion) => {
    setVersion(searchVersion)

    if (searchVersion !== CORE_RUBY) {
      sessionStorage.setItem('version', searchVersion)
    }
  }

  const goToPlatformGateway = () => switchVersion(PG_SEARCH)
  const goToGQL = () => switchVersion(GQL_SEARCH)
  const goToCoreRuby = () => switchVersion(CORE_RUBY)

  /* when coming back from platform gateway we need to return back to the existing version */
  const onSwitchToNewSearch = () =>
    setVersion(
      sessionStorageVersion || (platformGatewaySearchEnabled ? '3' : '2')
    )

  switch (version) {
    case PG_SEARCH:
      return (
        <PlatformGatewaySearch goToGQL={goToGQL} goToCoreRuby={goToCoreRuby} />
      )
    case GQL_SEARCH:
      return (
        <GraphQLMiddlewareSearch
          goToPlatformGateway={goToPlatformGateway}
          goToCoreRuby={goToCoreRuby}
        />
      )
    default:
      return (
        <CoreRubySearch
          showBanner={showRubyBanner}
          onCloseBanner={setShowRubyBanner}
          onSwitchToNewSearch={onSwitchToNewSearch}
        />
      )
  }
}

export default MembersSearch
