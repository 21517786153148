/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type FindConsumerNetworksQueryVariables = Types.Exact<{
  name: Types.Scalars['String']
}>

export type FindConsumerNetworksQuery = {
  findConsumerNetworks: Array<
    Types.Maybe<Pick<Types.ConsumerNetwork, 'id' | 'name'>>
  >
}

export const FindConsumerNetworksDocument = gql`
  query FindConsumerNetworks($name: String!) {
    findConsumerNetworks(name: $name) {
      id
      name
    }
  }
`
export function useFindConsumerNetworksQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindConsumerNetworksQuery,
    FindConsumerNetworksQueryVariables
  >
) {
  return Apollo.useQuery<
    FindConsumerNetworksQuery,
    FindConsumerNetworksQueryVariables
  >(FindConsumerNetworksDocument, baseOptions)
}
export function useFindConsumerNetworksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindConsumerNetworksQuery,
    FindConsumerNetworksQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FindConsumerNetworksQuery,
    FindConsumerNetworksQueryVariables
  >(FindConsumerNetworksDocument, baseOptions)
}
export type FindConsumerNetworksQueryHookResult = ReturnType<
  typeof useFindConsumerNetworksQuery
>
export type FindConsumerNetworksLazyQueryHookResult = ReturnType<
  typeof useFindConsumerNetworksLazyQuery
>
export type FindConsumerNetworksQueryResult = Apollo.QueryResult<
  FindConsumerNetworksQuery,
  FindConsumerNetworksQueryVariables
>
