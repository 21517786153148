import React from 'react'
import classNames from 'classnames'
import { Button } from '@babylon/core-ui'
import styles from './LinkButton.module.scss'

export default (props: any) => (
  <Button
    {...props}
    className={classNames(styles.Link, props.className)}
    intent="secondary"
    testId={props.testId}
  />
)
