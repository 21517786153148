import { gql } from '@apollo/client'

export default gql`
  query GetProfessionsInfo($memberId: ID!) {
    consultantTypes(memberId: $memberId)

    professions {
      id
      default_slot_duration_minutes
      key
      name
      alternative_slot_durations_minutes
    }
  }
`
