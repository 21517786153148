import qs from 'qs'
import { envFlag } from '@babylon/babylon-env'
import { getSessionId, getSessionIds } from '../utils'

export interface MemberViewPreference {
  newVersion: boolean
}

const NEW_MEMBER_VIEW = 'new_member_view'

const generatePreference = (newVersion: boolean) =>
  JSON.stringify({
    newVersion,
  })

const clearViewPreference = () => {
  localStorage.removeItem(NEW_MEMBER_VIEW)
}

const getViewPreference = (): null | MemberViewPreference => {
  const preference = localStorage.getItem(NEW_MEMBER_VIEW)

  if (preference) {
    return parseViewPreference(preference)
  }

  return null
}

export const parseViewPreference = (preference: string): MemberViewPreference =>
  JSON.parse(preference)

export const useNewVersion = (url: string, newMemberView: boolean): boolean => {
  const queryParams = qs.parse(url, {
    ignoreQueryPrefix: true,
  })

  const { version } = queryParams

  const viewPreference = getViewPreference()

  if (viewPreference) {
    return viewPreference.newVersion
  }

  clearViewPreference()
  const useOldFromUrl = version === '1'
  const useNewFromUrl = !useOldFromUrl
  const featureFlagEnabled = envFlag('DISPLAY_MOP_MEMBER_VIEW_BANNER')

  return (
    (newMemberView && useNewFromUrl) || (featureFlagEnabled && useNewFromUrl)
  )
}

export const saveViewPreferenceToStorage = (newVersion: boolean) => {
  const preference = generatePreference(newVersion)
  localStorage.setItem(NEW_MEMBER_VIEW, preference)
  return newVersion
}

export const clearViewPreferenceFromStorage = () => {
  const sessionId = getSessionId()
  const sessionIds = getSessionIds().filter((id: string) => id === sessionId)
  if (!sessionIds.length) {
    localStorage.removeItem(NEW_MEMBER_VIEW)
  }
}
