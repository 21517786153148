import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'

import { Modal, Heading, Button, Text } from '@babylon/core-ui'

import { LoadingContainer } from '../../components'

import GetClinicianInformation, {
  ClinicianInformationDataType,
} from '../ClinicianTimeline/GetClinicianInformation'

import styles from './ConfirmReallocationModal.module.css'

interface ReallocateShiftModalProps {
  firstClinicianId: string
  coveringClinicianId: string
  shiftStart: string
  shiftEnd: string
  date: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function ConfirmReallocationModal({
  firstClinicianId,
  coveringClinicianId,
  shiftStart,
  shiftEnd,
  date,
  isOpen,
  onClose,
  onConfirm,
}: ReallocateShiftModalProps) {
  const [isConfirming, setIsConfirming] = useState(false)
  const {
    data: firstClinicianInfo,
    loading: isFirstClinicianInfoLoading,
  } = useQuery<ClinicianInformationDataType>(GetClinicianInformation, {
    variables: {
      id: firstClinicianId,
    },
  })

  const {
    data: coveringClinicianInfo,
    loading: isCoveringClinicianInfoLoading,
  } = useQuery<ClinicianInformationDataType>(GetClinicianInformation, {
    variables: {
      id: coveringClinicianId,
    },
  })

  const isLoading =
    isFirstClinicianInfoLoading ||
    isCoveringClinicianInfoLoading ||
    isConfirming

  return (
    <Modal
      className={styles.ConfirmReallocationModal}
      open={isOpen}
      onClose={onClose}
      label="Review and confirm reallocation"
    >
      <Heading level="h1">Review and confirm</Heading>
      <LoadingContainer fill loading={isLoading}>
        <div className={styles.ConfirmReallocationModalDescription}>
          <div className={styles.ConfirmReallocationModalDescriptionEntry}>
            <Text
              className={styles.ConfirmReallocationModalDescriptionEntryTitle}
              color="grey-type"
              tag="div"
            >
              Reallocate all appointments between
            </Text>
            <Text tag="div">{`${format(
              new Date(`${date} ${shiftStart}`),
              'HH:mm'
            )} and ${format(
              new Date(`${date} ${shiftEnd}`),
              'HH:mm'
            )} on ${format(new Date(date), 'd MMMM yyyy')}`}</Text>
          </div>
          <div className={styles.ConfirmReallocationModalDescriptionEntry}>
            <Text
              className={styles.ConfirmReallocationModalDescriptionEntryTitle}
              color="grey-type"
              tag="div"
            >
              From
            </Text>
            <Text tag="div">{firstClinicianInfo?.consultant?.name}</Text>
          </div>
          <div className={styles.ConfirmReallocationModalDescriptionEntry}>
            <Text
              className={styles.ConfirmReallocationModalDescriptionEntryTitle}
              color="grey-type"
              tag="div"
            >
              To
            </Text>
            <Text tag="div">{coveringClinicianInfo?.consultant?.name}</Text>
          </div>
        </div>
        <div className={styles.ConfirmReallocationModalCTA}>
          <Button intent="secondary" onClick={onClose}>
            Go back
          </Button>
          <Button
            dataTestId="modal-confirm-reallocation"
            className={styles.ConfirmReallocationModalCTAConfirm}
            onClick={() => {
              setIsConfirming(true)
              onConfirm()
            }}
          >
            Confirm
          </Button>
        </div>
      </LoadingContainer>
    </Modal>
  )
}
