import React, { useState, useEffect } from 'react'
import {
  Heading,
  Tag,
  Text,
  KebabMenu,
  KebabMenuRenderOptions,
} from '@babylon/core-ui'
import { Card } from '@babylon/medkit'
import { useQuery } from '@apollo/client'
import { FormattedDate } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../../../../..'
import PatientName from '../../../../../../PatientName'
import SendRegistrationInviteModal from '../../../ProfileHeader/SendRegistrationInviteModal'
import { useSnackBar } from '../../../../../../../hooks'
import { PROFILE_QUERY } from '../../../../../queries'
import LoadingRelatedPersonCard from './LoadingRelatedPersonCard'
import AddRelatedPersonButton from './AddRelatedPersonButton'

import styles from './RelatedPersons.module.scss'
import messages from './RelatedPersonCard.messages'

interface RelatedPersonCardMenuProps {
  relatedPersonId: string
  currentMemberId: string
  isStubbedProfile: boolean
  isRelatedPersonMinor: boolean
  isCurrentMemberMinor: boolean
  relatedPersonFirstName: string
  relatedPersonLastName: string
  currentMemberName?: string
  email: string
  setIsLoading: (loading: boolean) => void
  hideDrawer?: () => void
}
const isPatientObjectValid = (patient) =>
  patient &&
  patient.hasOwnProperty('__typename') &&
  patient.__typename !== 'ResourceNotFoundError'

const RelatedPersonCardMenu = ({
  relatedPersonId,
  currentMemberId,
  isStubbedProfile,
  isRelatedPersonMinor,
  isCurrentMemberMinor,
  relatedPersonFirstName,
  relatedPersonLastName,
  currentMemberName,
  email,
  setIsLoading,
  hideDrawer,
}: RelatedPersonCardMenuProps) => {
  const { setSnackbarMessage } = useSnackBar()
  const fm = useFormatMessage()
  const [isRegistrationModalVisible, setIsRegistrationModalVisible] = useState(
    false
  )
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberRelatedPersons,
  })
  const showSendRegistrationInviteButton =
    isStubbedProfile && !isRelatedPersonMinor
  const showAddRelatedPersonButton =
    (!isStubbedProfile && !isRelatedPersonMinor) ||
    (isStubbedProfile && isRelatedPersonMinor)

  if (!showSendRegistrationInviteButton && !showAddRelatedPersonButton) {
    return null
  }

  return (
    <div
      className={styles.RelatedPersonCardMenu}
      data-testid="related-person-kebab-menu"
    >
      <KebabMenu>
        {({ menuItemProps }: KebabMenuRenderOptions) => (
          <div data-testid="related-person-card-kebab-menu">
            {showSendRegistrationInviteButton && (
              <button
                {...menuItemProps}
                data-testid="related-person-send-registration-email-button"
                type="button"
                onClick={() => {
                  trackClick({
                    elementName:
                      'related-person-send-registration-email-button',
                    elementType: TrackingElementType.button,
                  })
                  setIsRegistrationModalVisible(true)
                }}
              >
                {fm(messages.send_registration_mail)}
              </button>
            )}
            {showAddRelatedPersonButton && (
              <AddRelatedPersonButton
                relatedPersonId={relatedPersonId}
                currentMemberId={currentMemberId}
                isRelatedPersonMinor={isRelatedPersonMinor}
                isCurrentMemberMinor={isCurrentMemberMinor}
                currentMemberName={currentMemberName}
                setIsLoading={setIsLoading}
                hideDrawer={hideDrawer}
                setSnackbarMessage={setSnackbarMessage}
                menuItemProps={menuItemProps}
              />
            )}
          </div>
        )}
      </KebabMenu>
      <SendRegistrationInviteModal
        patientUuid={relatedPersonId}
        firstName={relatedPersonFirstName}
        lastName={relatedPersonLastName}
        email={email}
        isModalVisible={isRegistrationModalVisible}
        closeModal={() => setIsRegistrationModalVisible(false)}
        setSnackbarMessage={setSnackbarMessage}
      />
    </div>
  )
}

export interface RelatedPersonCardProps {
  relatedPersonId: string
  currentMemberId: string
  isCurrentMemberMinor: boolean
  currentMemberName?: string
  hideDrawer?: () => void
  hideMenu?: boolean
}

const renderNoContent = (message) => (
  <Card
    data-testid="no-data-related-person"
    className={styles.RelatedPersonCard}
  >
    <span>{message}</span>
  </Card>
)

const RelatedPersonCard = ({
  relatedPersonId,
  currentMemberId,
  isCurrentMemberMinor,
  currentMemberName,
  hideDrawer,
  hideMenu,
}: RelatedPersonCardProps) => {
  const fm = useFormatMessage()
  const [loadingRelatedPerson, setLoadingRelatedPerson] = useState(false)

  const { loading, data } = useQuery(PROFILE_QUERY, {
    context: { clientName: 'platform-gateway' },
    errorPolicy: 'all',
    variables: {
      patientUuid: relatedPersonId,
    },
  })

  useEffect(() => {
    if (loading) {
      setLoadingRelatedPerson(true)
    } else {
      setLoadingRelatedPerson(false)
    }
  }, [loading])

  if (loadingRelatedPerson) {
    return <LoadingRelatedPersonCard />
  }

  const patient = data?.patient

  if (!isPatientObjectValid(patient)) {
    return renderNoContent(
      fm(messages.no_related_person_found, { uuid: relatedPersonId })
    )
  }

  const {
    birthDate,
    name,
    telecoms,
    isMinor,
    identifiers,
    relatedPersons,
  } = patient

  const firstName = name?.given?.join(' ')
  const lastName = name?.family
  const familyAccount = relatedPersons?.edges?.length > 0

  const phoneNumber = telecoms?.edges?.find((edge) => edge.node?.phoneNumber)
    ?.node?.phoneNumber
  const email = telecoms?.edges?.find((edge) => edge.node?.emailAddress)?.node
    ?.emailAddress
  const isStubbedProfile = !identifiers?.edges?.some(
    (edge) => edge.node.system === 'https://coreruby.bbl.health/Patient'
  )
  const isPendingRegistration = patient?.activationStatus === 'INVITATION_SENT'

  return (
    <Card
      className={styles.RelatedPersonCard}
      data-testid="related-person-card"
    >
      <div className={styles.RelatedPersonCard__header}>
        <div>
          <div className={styles.RelatedPersonCard__tags}>
            {isMinor && (
              <Tag
                color="white-on-orange"
                className={styles.RelatedPersonCard__tag}
              >
                {fm(messages.minor_tag_label)}
              </Tag>
            )}
            {isStubbedProfile && !isPendingRegistration && (
              <Tag
                color="white-on-black"
                className={styles.RelatedPersonCard__tag}
              >
                {fm(messages.preregistered_tag_label)}
              </Tag>
            )}
            {isPendingRegistration && (
              <Tag
                color="white-on-black"
                className={styles.RelatedPersonCard__tag}
              >
                {fm(messages.pending_registration_tag_label)}
              </Tag>
            )}
            {familyAccount && (
              <Tag className={styles.RelatedPersonCard__familyTag}>
                {fm(messages.family_tag_label)}
              </Tag>
            )}
          </div>
          <Heading level="h2" data-testid="related-person-name">
            <PatientName
              firstName={firstName}
              lastName={lastName}
              id={relatedPersonId}
              tagName="span"
            />
          </Heading>
        </div>
        {!hideMenu && (
          <RelatedPersonCardMenu
            relatedPersonId={relatedPersonId}
            currentMemberId={currentMemberId}
            isStubbedProfile={isStubbedProfile}
            isRelatedPersonMinor={isMinor}
            isCurrentMemberMinor={isCurrentMemberMinor}
            relatedPersonFirstName={firstName}
            relatedPersonLastName={lastName}
            currentMemberName={currentMemberName}
            email={email}
            setIsLoading={setLoadingRelatedPerson}
            hideDrawer={hideDrawer}
          />
        )}
      </div>
      <div className={styles.RelatedPersonCard__content}>
        {birthDate && (
          <Text data-testid="related-person-dob">
            <FormattedDate
              timeZone="UTC"
              day="2-digit"
              month="short"
              year="numeric"
              value={new Date(birthDate)}
            />
          </Text>
        )}
        <Text
          data-testid="related-person-email"
          className={styles.RelatedPersonCard__email}
        >
          {email}
        </Text>
        <Text data-testid="related-person-phoneNumber">{phoneNumber}</Text>
      </div>
    </Card>
  )
}

export default RelatedPersonCard
