import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Spinner, Page } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import styles from './styles.module.scss'
import messages from './ActivityLog.messages'
import { useReportUrl } from './utils'
import { ErrorBlock } from '../ErrorBoundary'

export default (props: RouteComponentProps<{ id: string }>) => {
  const fm = useFormatMessage()
  const patientId = `${props.match.params.id}`
  const { loading, url } = useReportUrl(patientId)

  const accessGranted = !!url

  return (
    <Page
      title={fm(messages.header)}
      breadcrumbs={[
        <Link key="1" to="/admin/patients/search">
          {fm(messages.patients)}
        </Link>,
        <Link key="2" to={`/admin/patients/${patientId}/memberships`}>
          {fm(messages.memberDetails)}
        </Link>,
        fm(messages.header),
      ]}
    >
      <div className={styles.Container}>
        {loading && <Spinner />}
        {accessGranted && !loading ? (
          <iframe
            data-testid="report-iframe"
            title="activity_log"
            width={600}
            height={450}
            src={url as string}
            frameBorder={0}
            className={styles.Container__iframe}
            allowFullScreen
          />
        ) : (
          <ErrorBlock>{fm(messages.accessDenied)}</ErrorBlock>
        )}
      </div>
    </Page>
  )
}
