import { defineMessages } from 'react-intl'

const baseId = 'conversation_someone_else_tag'

export default defineMessages({
  someoneElse: {
    id: `${baseId}.someone_else`,
    defaultMessage: 'Someone else',
  },
})
