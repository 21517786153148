import React from 'react'
import cx from 'classnames'

import { nextIndex } from '../forms'

const Options = ({ children, options, className, onClick }) => {
  const optionArray =
    options ||
    React.Children.map(children, ({ props }) => ({
      ...props,
      label: props.label || props.children,
    }))

  const handleClick = (optionsClickHandler, value) => (event) => {
    optionsClickHandler && optionsClickHandler(event, value)
    onClick && onClick(event, value)
  }
  return (
    <div className={cx('widget options border', className)}>
      {optionArray.map(({ value, label, onClick }) => (
        <div
          key={value || nextIndex()}
          role="button"
          tabIndex="0"
          onClick={handleClick(onClick, value)}
        >
          {label || value}
        </div>
      ))}
    </div>
  )
}

export default Options
