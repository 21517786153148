import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import messages from './TestResults.messages'
import TimelineBlock from '../../../../Timeline/TimelineBlock'
import TimelineCard from '../../../../TimelineCard'
import MemberTaskMenu from './MemberTaskMenu'
import { SetActiveThread } from './useActiveThread'
import { Thread } from './TestResultsTimeline'

interface TestResultsThreadsProps {
  threads: Thread[]
  setActiveThread: (activeThread: SetActiveThread) => void
  info: string
  title: string
  testId: string
}
const TestResultsThreads = ({
  threads,
  setActiveThread,
  info,
  title,
  testId,
}: TestResultsThreadsProps) => {
  const fm = useFormatMessage()

  return (
    <TimelineBlock title={title} info={info} testId={testId}>
      {threads.map(({ id, createdAt, subType }) => {
        const title = fm(messages[subType])

        return (
          <TimelineCard
            key={id}
            id={id}
            title={title}
            renderMenu={() => (
              <MemberTaskMenu
                onClickViewHistory={() => {
                  setActiveThread({
                    id,
                    title,
                  })
                }}
              />
            )}
            scheduledTime={createdAt}
            isToggleBoxVisible={false}
            medium="physical"
            onCancelAppointmentClick={() => {}}
            patientId=""
          />
        )
      })}
    </TimelineBlock>
  )
}

export default TestResultsThreads
