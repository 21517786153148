import { useAtlasStore } from '@babylon/atlas.js'
import React, { useEffect, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { isNaN } from 'lodash'
import styles from '../../styles.module.css'
import './styles.tabs.css'

const getTimestampDiffInSeconds = (timestamp: Date): number => {
  const now = new Date()
  const diffInMillis = now.getTime() - timestamp.getTime()
  const diffInSeconds = diffInMillis / 1000

  return Math.abs(diffInSeconds)
}

type ConversationTabHeaderProps = {
  assignStatus: 'IDLE' | 'ASSIGNING' | 'ASSIGNED' | 'CLOSED'
  conversationId: string
  requestedAt: Date
  onTimeout: () => void
  title: string
}

// eslint-disable-next-line import/prefer-default-export
export const ConversationTabHeader = ({
  assignStatus,
  conversationId,
  requestedAt,
  onTimeout,
  title,
}: ConversationTabHeaderProps) => {
  const [matchTimeout, setMatchTimeout] = useState(0)
  const newMatch = assignStatus === 'IDLE'

  const updateMatchTimeout = () => {
    const timeRemaining = getTimestampDiffInSeconds(requestedAt!)
    setMatchTimeout(isNaN(timeRemaining) ? 30 : Math.ceil(timeRemaining))
  }

  useEffect(() => {
    updateMatchTimeout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedAt])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // update the time remaining to accept match when Tab has become active
        updateMatchTimeout()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const newMessagesCount = useAtlasStore((state) => {
    const conv = state.rosterConversations.find(
      (c) => c.conversationId === conversationId
    )

    return conv?.notSeenMessageCount || 0
  })

  return (
    <>
      <div className={styles.customTabs}>
        {newMessagesCount > 0 && (
          <span className={styles.messageCountPill}>{newMessagesCount}</span>
        )}
        {newMatch && (
          <CountdownCircleTimer
            isPlaying
            duration={matchTimeout}
            strokeWidth={3}
            size={22}
            colors={['#FFCE03', '#FD9A01', '#FD6104', '#FF2C05', '#F00505']}
            colorsTime={[30, 25, 20, 10, 0]}
            onComplete={() => {
              // remove the confirmation tab if 30 seconds elapsed
              onTimeout()
            }}
          >
            {({ remainingTime }) => (
              <small className={styles.timer}>{remainingTime}</small>
            )}
          </CountdownCircleTimer>
        )}
        &nbsp; {newMatch ? 'New chat' : title}
      </div>
    </>
  )
}
