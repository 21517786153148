import React from 'react'
import { Close } from '@babylon/icons'
import { Box, Text } from '@babylon/medkit'

import PopupDialog from '../../../PopupDialog'

import styles from './SidePanel.module.scss'

const SidePanel = ({ children, onClose, title }: Props) => (
  <PopupDialog appear toggleVisible={onClose} visible>
    <section className={styles.sidePanel}>
      <header className={styles.header}>
        <Box className={styles.title}>
          <Text tag="h1" variant="h2">
            {title}
          </Text>
          <button
            className={styles.closeButton}
            data-testid="close-side-panel"
            onClick={onClose}
            type="button"
          >
            <Close />
          </button>
        </Box>
      </header>
      {children}
    </section>
  </PopupDialog>
)

interface Props {
  children: React.ReactNode
  onClose: () => void
  title: string
}

export default SidePanel
