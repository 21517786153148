import React from 'react'
import { Input, Spinner } from '@babylon/core-ui'
import { FindCliniciansQuery } from '../AvailabilitySyncPage/FindCliniciansQuery.hooks'
import ClinicianSearchResults from '../ClinicianSearchResults'

import styles from './ClinicianSearcher.module.css'

interface ClinicianSearcherProps {
  setSearchValue: (value: string) => void
  loading: boolean
  skipClinicianSearch: boolean
  handleClinicianSelected: (id: string, name: string) => void
  data: FindCliniciansQuery
}

const ClinicianSearcher = ({
  setSearchValue,
  loading,
  skipClinicianSearch,
  handleClinicianSelected,
  data,
}: ClinicianSearcherProps) => (
  <>
    <Input
      data-testid="choose-clinician"
      fill
      placeholder="Search for a clinician"
      onChange={(e) => setSearchValue(e.target.value)}
    />

    <div className={styles.ClinicianContainer}>
      {loading && (
        <div className={styles.overlay}>
          <Spinner size="large" centered className={styles.spinner} />
        </div>
      )}
      <ClinicianSearchResults
        hasSearched={!skipClinicianSearch && !loading}
        data={data}
        onSelect={handleClinicianSelected}
      />
    </div>
  </>
)

export default ClinicianSearcher
