import React from 'react'
import PropTypes from 'prop-types'
import { envFlag } from '@babylon/babylon-env'
import { Button, Snackbar } from '@babylon/core-ui'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName, LegacyPortalScreen } from '../..'
import styles from './styles.module.scss'

const Banner = ({ onClick, onClose }) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.membersSearchView,
  })

  return (
    <Snackbar open fill intent="warning" onClose={onClose} fixed={false}>
      <button
        type="button"
        data-testid="banner-button"
        className={styles.BannerLink}
        onClick={() => {
          onClick()
          trackClick({
            elementName: 'switch-to-new-search-banner',
            elementType: TrackingElementType.button,
          })
        }}
      >
        NEW PATIENT SEARCH - Click this banner to try the simplified patient
        search. You can switch back at any time.
      </button>
    </Snackbar>
  )
}

const NewSearchOption = ({
  showBanner,
  onSwitchToNewSearch,
  onCloseBanner,
}) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.membersSearchView,
  })

  return showBanner ? (
    <Banner onClick={onSwitchToNewSearch} onClose={onCloseBanner} />
  ) : (
    <Button
      intent="secondary"
      className={styles.EnableNewSearchButton}
      onClick={() => {
        onSwitchToNewSearch()
        trackClick({
          elementName: 'switch-to-new-search-btn',
          elementType: TrackingElementType.button,
        })
      }}
    >
      Try new portal
    </Button>
  )
}

const CoreRubySearch = (props) => (
  <div data-testid="old-members-search">
    {envFlag('DISPLAY_MOP_SEARCH_BANNER') && (
      <div className={styles.BannerSpace}>
        <div className={styles.BannerWrapper}>
          <NewSearchOption {...props} />
        </div>
      </div>
    )}
    <LegacyPortalScreen />
  </div>
)

CoreRubySearch.propTypes = {
  showBanner: PropTypes.bool,
  onSwitchToNewSearch: PropTypes.func,
  onCloseBanner: PropTypes.func,
}

export default CoreRubySearch
