import React from 'react'
import { Link } from 'react-router-dom'

import { Page } from '@babylon/core-ui'
import TimelineItem from './TimelineItem'
import { ConsultationTypeName } from '../types/ConsultationTypes'

export interface Invite {
  id: string
  allowed_mediums: ConsultationTypeName[]
  consultation_type: ConsultationTypeName
  created_at: string
  duration_minutes: number
  member_uuid: string
  notes_for_member: string
  service_type: {
    id: string
    name: string
    member_instructions: string
  }
}

interface Props {
  invites?: Invite[]
  memberId: string
}

const sortInvites = (invites: Invite[] = []): Invite[] =>
  [...invites].sort((invite1, invite2) => {
    if (invite1.created_at > invite2.created_at) {
      return -1
    }

    if (invite1.created_at < invite2.created_at) {
      return 1
    }

    return 0
  })

const formatTime = (timeInMinutes: number): string => {
  const hours = Math.floor(timeInMinutes / 60)
  const minutes = timeInMinutes % 60

  if (hours) {
    return `${hours} hr ${minutes} mins`
  }

  return `${minutes} mins`
}

const AppointmentInvites = ({ invites = [], memberId }: Props) => (
  <Page
    title="Appointment Invites"
    breadcrumbs={[
      <Link key="1" to="/admin/patients/search">
        Patients
      </Link>,
      <Link key="2" to={`/admin/patients/${memberId}/memberships`}>
        Member Details
      </Link>,
      'Appointment Invites',
    ]}
  >
    <div style={{ maxWidth: '700px' }}>
      {sortInvites(invites).map((invite) => (
        <TimelineItem
          key={invite.id}
          actions={
            <Link
              to={`/admin/patients/${memberId}/schedule-appointment?invite=${invite.id}`}
            >
              Book appointment
            </Link>
          }
          item={{
            ...invite,
            duration: formatTime(invite.duration_minutes),
            type: 'INVITATION',
          }}
        />
      ))}
    </div>
  </Page>
)

export default AppointmentInvites
