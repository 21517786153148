/* eslint-disable curly */
// disabling the click-events-have-key-events rule
// because key handlers are dealt with, but at the QueueModal level.
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react'
import styles from './styles.module.css'

type Props = {
  Icon?: React.ReactNode
  title: string
  label?: string
  description?: string
  children?: React.ReactNode
  highlighted?: boolean
  disabled?: boolean
  onSelected?: () => void
}

const QueueListItem = ({
  Icon,
  title,
  label,
  description,
  children,
  highlighted = false,
  disabled = false,
  onSelected = () => undefined,
}: Props) => (
  <div className={`${styles.queueItem} ${disabled ? styles.disabled : ''}`}>
    <div
      onClick={() => {
        if (!disabled) onSelected()
      }}
      role="button"
      tabIndex={0}
      className={`${styles.queueItemRow} ${
        highlighted ? styles.highlighted : ''
      }`}
    >
      {Icon}
      <span className={styles.itemTitle}>{title}</span>
      {description && (
        <span className={styles.itemDescription}>{description}</span>
      )}
      {label && <span className={styles.itemLabel}>{label}</span>}
    </div>
    {children && <div className={styles.itemChildren}>{children}</div>}
  </div>
)

export default QueueListItem
