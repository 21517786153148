import React, { FC } from 'react'
import { FormattedDate, FormattedRelativeTime } from 'react-intl'
import { differenceInSeconds } from 'date-fns'
import { Grid, Tag } from '@babylon/core-ui'
import { ChatFilled } from '@babylon/icons'
import { AdminName } from '../../../..'
import { Comment as CommentType } from '../../../types'
import styles from './CommentsCard.module.scss'
import { useTimezoneContext, TimezoneContextType } from '../TimezoneProvider'

const Comment: FC<CommentType> = ({ created_at, note, created_by }) => {
  const timeZoneContext: TimezoneContextType = useTimezoneContext()

  return (
    <Grid data-testid="member-comment" className={styles.Comment}>
      <div className={styles.Comment__timeline}>
        <div className={styles.Comment__timeline__iconWrapper}>
          <ChatFilled className={styles.Comment__icon} />
        </div>
      </div>
      <div className={styles.Comment__wrapper}>
        <div className={styles.Comment__header}>
          <div data-testid="member-comment-date">
            <FormattedDate
              timeZone={timeZoneContext.timezone}
              day="2-digit"
              month="short"
              year="numeric"
              hour="numeric"
              minute="numeric"
              value={created_at}
            />
          </div>
          <div
            className={styles.Comment__timeRelative}
            data-testid="member-comment-time-relative"
          >
            <span>
              <FormattedRelativeTime
                value={differenceInSeconds(new Date(created_at), new Date())}
                numeric="auto"
                updateIntervalInSeconds={10}
              />
            </span>
          </div>
        </div>
        <p
          className={styles.Comment__message}
          data-testid="member-comment-message"
        >
          {note}
        </p>
        <Tag data-testid="member-comment-name">
          <AdminName name={created_by.name} />
        </Tag>
      </div>
    </Grid>
  )
}

export default Comment
