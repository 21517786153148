import { gql } from '@apollo/client'

export default gql`
  query getCancellationReasons {
    adminCancelReasons {
      id
      reason
    }
  }
`
