import { defineMessages } from 'react-intl'

const baseId = 'member_edit_healthcare_details'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Healthcare details',
  },
  id: {
    id: `${baseId}.id`,
    defaultMessage: 'Babylon ID',
  },
  public_id: {
    id: `${baseId}.public_id`,
    defaultMessage: 'Public healthcare ID',
  },
  private_id: {
    id: `${baseId}.private_id`,
    defaultMessage: 'Private healthcare ID',
  },
})
