import React from 'react'

import { FormikSelect, Tooltip } from '@babylon/core-ui'

import ServiceTypeField from './ServiceTypeField'
import { DropdownOption } from '../../../AppointmentFields/DurationDropdownUtils'

interface Props {
  isEditing: boolean
  label: string
  dropdownData: DropdownOption[]
  value?: string
  tooltipText?: string
}

const USEhrAppointmentTypeField = ({
  isEditing = false,
  label,
  dropdownData,
  value = '',
  tooltipText = '',
}: Props) =>
  isEditing ? (
    <FormikSelect
      name="us_ehr_appointment_type"
      id="us_ehr_appointment_type"
      label={
        <>
          {label}:{' '}
          <Tooltip
            info={tooltipText}
            placement="right"
            overlayStyle={{ width: 300 }}
          />
        </>
      }
      options={dropdownData}
    />
  ) : (
    <ServiceTypeField label={label} value={value} tooltipText={tooltipText} />
  )

export default USEhrAppointmentTypeField
