import { defineMessages } from 'react-intl'

const baseId = 'member_search_results'

export default defineMessages({
  active_label: {
    id: `${baseId}.active_label`,
    defaultMessage: 'patients found',
  },
  no_patients_found: {
    id: `${baseId}.no_patients_found`,
    defaultMessage: 'No patients found, try again',
  },
  title_improve_search_results: {
    id: `${baseId}.title_improve_search_results`,
    defaultMessage: 'Improve search results',
  },
  body_improve_search_results: {
    id: `${baseId}.body_improve_search_results`,
    defaultMessage:
      'Avoid only searching by <b>first name</b>. Try searching with another field such as <b>last name</b>, <b>date of birth</b> or <b>postcode</b>',
  },
  body_improve_search_results_two: {
    id: `${baseId}.body_improve_search_results_two`,
    defaultMessage:
      'When searching by <b>postcode</b>, try with and without spaces',
  },
})
