import { defineMessages } from 'react-intl'

const baseId = 'promo_code'

export default defineMessages({
  copied_device_to_clipboard: {
    id: `${baseId}.copied_device_to_clipboard`,
    defaultMessage: 'Copied Device to Clipboard',
  },
  copied_devices_to_clipboard: {
    id: `${baseId}.copied_devices_to_clipboard`,
    defaultMessage: 'Copied Devices to Clipboard',
  },
  copy_all: {
    id: `${baseId}.copy_all`,
    defaultMessage: 'Copy entire list',
  },
  date_last_used: {
    id: `${baseId}.date_last_used`,
    defaultMessage: 'Date Last Used',
  },
  device: {
    id: `${baseId}.device`,
    defaultMessage: 'Device',
  },
  devices: {
    id: `${baseId}.devices`,
    defaultMessage: 'Devices',
  },
  no_devices_found: {
    id: `${baseId}.no_devices_found`,
    defaultMessage: 'No Devices Found',
  },
  version: {
    id: `${baseId}.version`,
    defaultMessage: 'Version',
  },
  unknown: {
    id: `${baseId}.unknown`,
    defaultMessage: 'Unknown',
  },
})
