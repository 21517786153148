import { DateTime } from 'luxon'
import React from 'react'
import {
  ConversationStatus,
  MessageWithStatus,
  useConversation,
} from '@babylon/atlas.js'
import { Message } from '../index'
import styles from '../../styles.module.css'
import AttachmentUploadingMessage, {
  AttachmentMessage,
} from '../Attachment/AttachmentUploadingMessage'

interface PatientActiveConversationProps {
  conversationId: string
  attachmentMessage: AttachmentMessage
  attachments: ReturnType<typeof useConversation>['attachments']
  messageIds: string[]
  messages: { [p: string]: MessageWithStatus }
  showTypingIndicator: boolean
  status: ConversationStatus
  userId: string
  patientId: string
  waitTime: JSX.Element | null
}

const PatientActiveConversation = ({
  status,
  conversationId,
  attachmentMessage,
  messageIds,
  messages,
  userId,
  attachments,
  waitTime,
  patientId,
  showTypingIndicator,
}: PatientActiveConversationProps) => (
  <>
    <div className={styles.messages}>
      {status === 'closed' && (
        <Message
          message={{
            itemId: 'conversation-deleted',
            message: 'Conversation has been closed',
            senderId: 'admin',
            sentAt: DateTime.now().toISO(),
            messageType: 'TEXT',
            conversationId,
          }}
          mine={false}
        />
      )}
      {attachmentMessage.uploading && (
        <AttachmentUploadingMessage fileName={attachmentMessage.fileName} />
      )}
      {messageIds.map((id) => {
        const message = messages[id]

        if (!message) {
          return null
        }

        // all agent messages are mine, others are not
        const mine =
          message.senderId === userId ||
          (message.senderId.toUpperCase() !== 'ADMIN' &&
            message.senderId !== patientId)

        return (
          <Message
            key={id}
            message={message}
            mine={mine}
            attachments={attachments}
            conversationId={conversationId}
          />
        )
      })}
      {waitTime}
    </div>
    {showTypingIndicator && (
      <div className={styles.typingIndicator}>User is typing...</div>
    )}
  </>
)

export default PatientActiveConversation
