import React, { useCallback, useState, memo } from 'react'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'

import { envFlag } from '@babylon/babylon-env'
import {
  KebabMenu,
  KebabMenuRenderOptions,
  Table,
  TableCell,
  TableRow,
} from '@babylon/core-ui'

import ConsultationTypes from '../../types/ConsultationTypes'
import ServiceType from '../../types/ServiceType'

import { UnexpectedError } from '../../Utils'
import DeleteServiceTypeConfirm from './DeleteServiceTypeConfirm'
import DeleteServiceTypeMutation from './DeleteServiceTypeMutation'

interface ServiceTypeToDeleteType extends Pick<ServiceType, 'uuid' | 'name'> {}

type Items = Pick<
  ServiceType,
  | 'uuid'
  | 'name'
  | 'allowed_mediums'
  | 'common'
  | 'categories'
  | 'default_duration_minutes'
>[]
interface ServiceTypesListProps {
  items: Items
  onDelete?: () => void
}

interface TableListProps {
  items: Items
  handleDelete: ({ uuid, name }: any) => () => void
}

const ServiceTypesList = ({ items = [], onDelete }: ServiceTypesListProps) => {
  const [
    serviceTypeToDelete,
    setServiceTypeToDelete,
  ] = useState<ServiceTypeToDeleteType | null>(null)
  const [deleteServiceType, { error }] = useMutation(DeleteServiceTypeMutation)

  const handleDelete = useCallback(
    ({ uuid, name }) => () => {
      setServiceTypeToDelete({ uuid, name })
    },
    [setServiceTypeToDelete]
  )

  const cancelDelete = useCallback(() => {
    setServiceTypeToDelete(null)
  }, [setServiceTypeToDelete])

  const doDelete = useCallback(() => {
    if (serviceTypeToDelete) {
      deleteServiceType({
        variables: { uuid: serviceTypeToDelete.uuid },
      }).then(() => {
        setServiceTypeToDelete(null)

        if (onDelete) {
          onDelete()
        }
      })
    }
  }, [deleteServiceType, onDelete, serviceTypeToDelete, setServiceTypeToDelete])

  return (
    <>
      {error && <UnexpectedError message={error.message} visible />}
      <TableList items={items} handleDelete={handleDelete} />
      {serviceTypeToDelete && (
        <DeleteServiceTypeConfirm
          isOpen
          name={serviceTypeToDelete.name}
          onCancel={cancelDelete}
          onConfirm={doDelete}
        />
      )}
    </>
  )
}

const TableList = memo(({ items, handleDelete }: TableListProps) => (
  <Table fullWidth striped headers={['Name', 'Duration', 'Medium']}>
    {!!items.length &&
      items.map(({ name, uuid, allowed_mediums, default_duration_minutes }) => (
        <TableRow key={uuid}>
          <TableCell>
            <Link to={`/admin/service-mix/${uuid}`}>{name}</Link>
          </TableCell>
          <TableCell>{default_duration_minutes}</TableCell>
          <TableCell>
            {allowed_mediums
              .map((medium) => ConsultationTypes[medium])
              .join(' / ')}
          </TableCell>
          {envFlag('DELETE_SERVICE_TYPE', false) && (
            <TableCell>
              <KebabMenu>
                {({ menuItemProps }: KebabMenuRenderOptions) => (
                  <div>
                    <button
                      {...menuItemProps}
                      type="button"
                      onClick={handleDelete({ name, uuid })}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </KebabMenu>
            </TableCell>
          )}
        </TableRow>
      ))}
  </Table>
))
export default ServiceTypesList
