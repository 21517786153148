import { gql } from '@apollo/client'

export default gql`
  query GetServiceType($uuid: ID!) {
    serviceType(uuid: $uuid) {
      uuid
      name
    }
  }
`
