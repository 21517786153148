import React from 'react'
import { useProductConfig } from '@babylon/product-config'
import FormWithServiceTypes from './FormWithServiceTypes/FormWithServiceTypes'
import FormWithProfessions from './FormWithProfessions/FormWithProfessions'
import { useQueryParams } from '../hooks'
import { PreferredConsumerNetwork } from '../hooks/usePreferredNetwork/usePreferredNetwork'

interface Props {
  memberId: string
  faceToFaceInvitesEnabled: boolean
  patientNetwork?: PreferredConsumerNetwork
}

interface QueryParams {
  page: string
}

const WITH_SERVICE_TYPES_PAGE = 'with-service-types'
export default function DigitalAppointmentForm({
  memberId,
  faceToFaceInvitesEnabled,
  patientNetwork,
}: Props) {
  const { getProp } = useProductConfig()
  const [params] = useQueryParams<QueryParams>({})
  const { page } = params

  const isServiceTypesPage = page === WITH_SERVICE_TYPES_PAGE

  const digitalInviteWithServiceTypesEnabled =
    getProp(
      'clinicalCare.appointments.adminPortal',
      'digitalInviteWithServiceTypesEnabled'
    ) || isServiceTypesPage

  if (digitalInviteWithServiceTypesEnabled) {
    return (
      <FormWithServiceTypes
        memberId={memberId}
        faceToFaceInvitesEnabled={faceToFaceInvitesEnabled}
        patientNetwork={patientNetwork}
      />
    )
  }

  return <FormWithProfessions memberId={memberId} />
}
