import { useLocation } from 'react-router'
import qs from 'qs'

interface SupportInteraction {
  transactionId: string
  transactionType: string
}

export default function useParseSupportInteraction(): SupportInteraction | null {
  const { search: queryString } = useLocation()

  const {
    transaction_id: transactionId,
    transaction_type: transactionType,
  } = qs.parse(queryString, {
    ignoreQueryPrefix: true,
  }) as Record<string, string | undefined>

  return transactionId && transactionType
    ? { transactionId, transactionType }
    : null
}

export function stringifySupportInteractionParams(
  params: SupportInteraction
): string {
  return qs.stringify(params)
}
