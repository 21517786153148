import React from 'react'
import cx from 'classnames'
import { formContext } from '@babylon/babylon-forms/forms'
import {
  DataGrid,
  Columns,
  Column,
  Actions,
  Pagination,
  TabGroup,
  Tab,
  PaginationSize,
  MoreOptions,
  Option,
} from '@babylon/babylon-forms/widgets'

import Icon from '@/components/Icon'
import LabelWithIcon from '@/components/LabelWithIcon'
import PageHeader from '@/components/PageHeader'
import TimezoneDate from '@/components/TimezoneDate'

import actionTypes from './actionTypes.json'
import Spinner from './Spinner'
import { formatDrug, formatBadgeStatus } from '../utils'

import styles from './styles.module.scss'

const ItemView = ({ item, actions, context }) => {
  const {
    id,
    patient: { first_name: firstName, last_name: lastName },
    drugs,
    createdAt,
    prescriptionState,
  } = item
  const removing = context.indexOf(id) !== -1

  return (
    <tr className={cx({ [styles.removing]: removing })}>
      <td>
        <strong>{`${firstName} ${lastName}`}</strong>
      </td>
      <td>{drugs.map(formatDrug)}</td>
      <td>
        <TimezoneDate date={createdAt} />
      </td>
      <td className={styles.narrowRow}>
        {formatBadgeStatus(prescriptionState)}
      </td>
      <DataGrid.ItemActions item={item} actions={actions} />
    </tr>
  )
}

const ActionsView = ({ onAction }) => (
  <formContext.Consumer>
    {(prescription) => {
      const hasActions = prescription.actions && prescription.actions.length

      return hasActions ? (
        <span>
          <MoreOptions
            small
            label={<LabelWithIcon icon="edit" label="Actions" />}
            className={styles.options}
          >
            {prescription.actions
              .filter((action) => {
                if (!actionTypes[action]) {
                  console.warn(`Unknown prescription action: ${action}`)

                  return false
                }

                return true
              })
              .map((action) => (
                <Option
                  key={action}
                  onClick={() => onAction(action, prescription)}
                >
                  <LabelWithIcon
                    icon={actionTypes[action].icon}
                    label={actionTypes[action].label}
                  />
                </Option>
              ))}
          </MoreOptions>
        </span>
      ) : null
    }}
  </formContext.Consumer>
)

const PrescriptionsView = ({
  data: {
    loading,
    prescriptions: { itemsPerPage, pageIndex, totalItems, items },
    variables: { statuses, sort = '' },
  },
  handleSort,
  spinnerType,
  handlePageSizeChange,
  handlePageChange,
  handleStateChange,
  onAction,
  removedPrescriptionIds,
}) => {
  const [column, direction] = sort.split(',')

  return (
    <div>
      <PageHeader>Prescriptions</PageHeader>
      <div className={styles.prescriptionsView}>
        <TabGroup value={statuses.join(',')} onChange={handleStateChange}>
          <Tab name="CONFIRMED_RECEIVED" title="To Be Printed" />
          <Tab name="PRINTED" title="Printed" />
          <Tab name="VOIDED,REJECTED" title="To Be Reviewed" />
        </TabGroup>
        <div className={styles.listHeader}>
          <PaginationSize
            value={itemsPerPage}
            onChange={handlePageSizeChange}
            totalItems={totalItems}
            spinner={
              <Spinner visible={spinnerType === 'page-size' && loading} />
            }
          />
        </div>
        <DataGrid
          value={items}
          ItemView={ItemView}
          sort={{
            column,
            direction,
            loading: spinnerType === 'sort' && loading,
          }}
          onSort={handleSort}
          icons={{
            asc: <Icon name="sort-up" />,
            desc: <Icon name="sort-down" />,
            sort: <Icon name="sort" />,
            spinner: <Spinner visible />,
          }}
          context={removedPrescriptionIds}
        >
          <Columns>
            <Column name="patientName" label="Patient" />
            <Column name="drugs" label="Drugs" />
            <Column name="createdAt" label="Time" sort />
            <Column name="prescriptionState" label="State" />
          </Columns>
          <Actions>
            <ActionsView onAction={onAction} />
          </Actions>
        </DataGrid>
        <div className={styles.bottomToolbar}>
          <div />
          <div className={styles.rowRight}>
            <Spinner visible={spinnerType === 'pagination' && loading} />
            <Pagination
              page={pageIndex}
              size={itemsPerPage}
              totalElements={totalItems}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrescriptionsView
