/* eslint-disable jsx-a11y/label-has-associated-control */
/* Todo: look at eslint configuration, the rule disabled above shouldn't cause warnings in this file */
import 'focus-visible/dist/focus-visible'
import React from 'react'
import cn from 'classnames'

import { IMedkitComponent, HelpText } from '../..'

import styles from './index.module.scss'

export const Radio: IMedkitComponent<RadioProps, {}> = ({
  children,
  disabled = false,
  checked = false,
  onChange = () => {},
  className,
  error,
  testHandle,
  variant,
  helpText,
  id,
  ...props
}: RadioProps) => (
  <label
    className={cn(
      styles.Radio,
      variant ? styles[`Radio_${variant}`] : undefined,
      {
        [styles.Radio_checked]: checked,
        [styles.Radio_disabled]: disabled,
        [styles.Radio_error]: error,
      },
      className
    )}
    data-testid={testHandle}
    htmlFor={id}
  >
    <div className={styles.Radio__wrapper}>
      <input
        className={styles.Radio__input}
        type="radio"
        disabled={disabled}
        checked={checked}
        id={id}
        onChange={onChange}
        {...props}
      />
      <div className={styles.Radio__circle} />
    </div>
    <div className={styles.Radio__label}>
      {children && <div>{children}</div>}
      {helpText && <HelpText>{helpText}</HelpText>}
    </div>
  </label>
)

Radio.theme = {}

export interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  children?: React.ReactChild
  disabled?: boolean
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean | string
  testHandle?: string
  helpText?: string
  variant?: 'bordered'
}

export default Radio
