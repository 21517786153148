import React from 'react'
import { Grid } from '@babylon/core-ui'
import { TimezoneDate } from '../../TimezoneDate'
import styles from './CancelAppointmentInformation.module.css'
import type { CancelAppointmentInformationProps } from './CancelAppointmentInformation.types'

const CancelAppointmentInformation = ({
  appointmentId,
  appointmentReasons,
  startTime,
  durationInMinutes,
  timezone,
  patientName,
  patientBabylonId,
}: CancelAppointmentInformationProps) => (
  <Grid columns={3} rowGap={24} columnGap={24}>
    <div className={styles.column}>
      <section>
        <h3 className={styles.header}>Appointment Time</h3>
        <p className={styles.value}>
          {startTime ? (
            <TimezoneDate date={startTime} timezone={timezone} format="HH:mm" />
          ) : (
            '-'
          )}
        </p>
      </section>

      <section>
        <h3 className={styles.header}>Appointment Duration</h3>
        <p className={styles.value}>
          {durationInMinutes ? <>{durationInMinutes} min</> : '-'}
        </p>
      </section>
    </div>

    <div className={styles.column}>
      <section>
        <h3 className={styles.header}>Members Name</h3>
        <p className={styles.value}>{patientName ?? '-'}</p>
      </section>

      <section>
        <h3 className={styles.header}>Babylon ID</h3>
        <p className={styles.value}>{patientBabylonId ?? '-'}</p>
      </section>
    </div>

    <div className={styles.column}>
      <section>
        <h3 className={styles.header}>Appointment Reason</h3>
        <ul className={styles.value}>
          {appointmentReasons.length
            ? appointmentReasons.map((reason) => <li key={reason}>{reason}</li>)
            : '-'}
        </ul>
      </section>

      <section>
        <h3 className={styles.header}>Consultation ID</h3>
        <p className={styles.value}>
          {appointmentId ? <>{appointmentId}</> : '-'}
        </p>
      </section>
    </div>
  </Grid>
)

export default CancelAppointmentInformation
