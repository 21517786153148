import React, { useState, ReactElement } from 'react'
import {
  Formik,
  Form,
  FormikConfig,
  FormikValues,
  yupToFormErrors,
} from 'formik'

interface MultiStepFormProps extends FormikConfig<FormikValues> {
  children: ReactElement[]
  initialPage?: number
}

export default function MultiStepForm({
  children,
  initialValues,
  onSubmit,
  initialPage = 1,
}: MultiStepFormProps) {
  const [pageNumber, setPageNumber] = useState(initialPage)
  const activePage = children?.[pageNumber - 1]

  if (!activePage) {
    throw new Error('The active page was not found')
  }

  if (initialPage > children?.length) {
    throw new Error(
      `You passed in an initialPage of ${initialPage} when there are only ${children?.length} pages in this MultiStepForm`
    )
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setErrors }) => {
        const onPreviousStep = () =>
          setPageNumber((stateValue) => stateValue - 1)
        const onNextStep = (
          onValidStep?: () => void,
          onFailStep?: () => void
        ) => {
          try {
            activePage.props.validationSchema.validateSync(values, {
              abortEarly: false,
            })
            setPageNumber((stateValue) => stateValue + 1)

            if (onValidStep) {
              onValidStep()
            }
          } catch (e) {
            setErrors(yupToFormErrors(e))

            if (onFailStep) {
              onFailStep()
            }
          }
        }

        return (
          <Form>
            {React.cloneElement(activePage, { onPreviousStep, onNextStep })}
          </Form>
        )
      }}
    </Formik>
  )
}
