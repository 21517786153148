import { Spinner, Text } from '@babylon/core-ui'
import React from 'react'

import styles from './styles.module.css'

export type AttachmentMessage = {
  uploading: boolean
  fileName?: string
}

type Props = {
  fileName: string | undefined
}

const AttachmentUploadingMessage = ({ fileName }: Props) => (
  <div className={`${styles.message} ${styles.mine} ${styles.uploading}`}>
    <div className={styles.attachmentUpload}>
      <Spinner size="small" color="white" />
      <Text tag="span" size="large">
        {fileName}
      </Text>
    </div>
    <div>
      <Text tag="span" size="small">
        Uploading...
      </Text>
    </div>
  </div>
)

export default AttachmentUploadingMessage
