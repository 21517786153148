import React from 'react'
import { Text } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import messages from './MemberTaskHistoryLog.messages'
import styles from './MemberTaskHistoryLog.module.css'
import { LoadingBlock } from '../../../../LoadingContent'
import MemberTaskLogEntry, { MemberTaskLogItem } from './MemberTaskLogEntry'

interface MemberTaskHistoryLogProps {
  tasks: MemberTaskLogItem[]
  loading?: boolean
}

export default function MemberTaskHistoryLog({
  tasks,
  loading,
}: MemberTaskHistoryLogProps) {
  const fm = useFormatMessage()

  return (
    <div data-testid="member-task-history-log">
      <Text variant="h3" className={styles.heading}>
        {fm(messages.history)}
      </Text>
      {loading ? (
        <div
          data-testid="member-task-loading"
          aria-busy="true"
          aria-live="polite"
        >
          <LoadingBlock className={styles.loadingBlock} />
          <LoadingBlock className={styles.loadingBlock} />
          <LoadingBlock className={styles.loadingBlock} />
        </div>
      ) : (
        tasks.map(({ createdAt, logEntry }, index) => (
          <MemberTaskLogEntry
            key={createdAt}
            createdAt={createdAt}
            logEntry={logEntry}
            isCurrentTask={index === 0} // task at position 0 will always be the current task
          />
        ))
      )}
    </div>
  )
}
