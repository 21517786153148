import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import EntityCard from '../EntityCard'
import FindClinicsQuery from './FindClinicsQuery'
import AddClinicsToServiceType from './AddClinicsToServiceTypeMutation'
import RemoveClinicFromServiceType from './RemoveClinicFromServiceTypeMutation'

interface Clinic {
  id: string
  name: string
  email: string
}

interface Props {
  serviceTypeUuid: string
  clinics?: Clinic[]
}

const ServiceTypeClinics = ({ serviceTypeUuid, clinics = [] }: Props) => (
  <Mutation
    mutation={AddClinicsToServiceType}
    refetchQueries={['GetServiceType']}
    awaitRefetchQueries
  >
    {(addClinicsToServiceType: (params: any) => Promise<any>) => (
      <Mutation
        mutation={RemoveClinicFromServiceType}
        refetchQueries={['GetServiceType']}
        awaitRefetchQueries
      >
        {(removeClinicFromServiceType: (params: any) => Promise<any>) => (
          <EntityCard
            name="clinic"
            query={{
              query: FindClinicsQuery,
              variables: (filterText) => ({
                name: filterText,
              }),
              resultSelector: (data) =>
                data.practices.map((c: Clinic) => ({
                  ...c,
                  name: c.name,
                })),
            }}
            items={clinics}
            onItemsAdded={(clinic_ids: string[]) =>
              addClinicsToServiceType({
                variables: {
                  service_type_uuid: serviceTypeUuid,
                  clinic_ids,
                },
              })
            }
            onItemRemoved={(clinic_id: string) =>
              removeClinicFromServiceType({
                variables: {
                  service_type_uuid: serviceTypeUuid,
                  clinic_id,
                },
              })
            }
          />
        )}
      </Mutation>
    )}
  </Mutation>
)

export default ServiceTypeClinics
