import React from 'react'

import {
  withGroup,
  withLabel,
  withWrapper,
  withValue,
  withValidation,
  withOnChange,
  compose,
  withDefaultProps,
} from '../forms'

const Text = ({ name, value, rows = 1, onChange, placeholder, disabled }) =>
  rows < 2 ? (
    <input
      name={name}
      className="widget text-box border"
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  ) : (
    <textarea
      name={name}
      className="widget text-box border"
      rows={rows}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  )

export default compose(
  withLabel,
  withValue,
  withValidation,
  withWrapper,
  withOnChange,
  withDefaultProps({ groupClassName: 'widget-group-text-box border' }),
  withGroup
)(Text)
