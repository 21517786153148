import { gql } from '@apollo/client'

export const getSpecialismCategories = gql`
  query AP_Specialisms($consultationId: ID!) {
    specialismCategories(id: $consultationId) {
      name
      id
    }
  }
`
