import React from 'react'
import { pure } from 'recompose'

import styles from './styles.module.css'

const propVal = (val) => (val instanceof Function ? 'Function' : `"${val}"`)

const enhance = pure

const TestIFrame = (props) => {
  setTimeout(props.onLoad, 350)

  return (
    <div className={styles.testIFrame}>
      &lt;iframe
      {Object.keys(props).map((prop) => ` ${prop}=${propVal(props[prop])}`)}&gt;
    </div>
  )
}

export default enhance(TestIFrame)
