import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import {
  submitHandler,
  withFormContext,
  withLabel,
  withValidation,
  withValue,
  withOnChange,
  compose,
} from './util'

const Input = ({
  onClick,
  small,
  className,
  style,
  'data-context': ctx,
  type,
  label,
  value,
  radioValue,
  outline,
  validationMessage,
  ...props
}) => {
  const handleClick = submitHandler(type, onClick, ctx)
  let renderLabel = 'before'
  let labelText
  if (type === 'checkbox') {
    renderLabel = 'after'
    props.checked = value
  } else if (type === 'radio') {
    renderLabel = 'after'
    props.value = radioValue
    props.checked = value === radioValue
  } else if (type === 'button' || type === 'submit' || type === 'reset') {
    labelText = label ? label.props.children : value
    renderLabel = 'inside'
  }
  return (
    <div
      className={`widget-wrapper${validationMessage ? ' widget-alert' : ''}`}
      style={style}
    >
      {renderLabel === 'before' ? label : null}
      <input
        {...props}
        className={cx('widget input border', { small, outline }, className)}
        onClick={handleClick}
        type={type}
        value={renderLabel === 'inside' ? labelText : value}
      />
      {renderLabel === 'after' ? label : null}
      <span className="field-alert">{validationMessage}</span>
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
}

Input.propTypes = {
  /** Input type */
  type: PropTypes.oneOf([
    'text',
    'password',
    'checkbox',
    'radio',
    'button',
    'submit',
    'reset',
  ]),
  /** Input label */
  label: PropTypes.node,
  /** The value for radio input components */
  radioValue: PropTypes.string,
  /** The value */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.func,
  ]),
  /** Click event handler method
   * @param {SyntheticEvent} event The react `SyntheticEvent`
   */
  onClick: PropTypes.func,
  /** Change event handler method
   * @param {SyntheticEvent} event The react `SyntheticEvent`
   * @param {String} value The new value
   */
  onChange: PropTypes.func,
  /** Render the component in a smaller size */
  small: PropTypes.bool,
  /** Render the component with outline */
  outline: PropTypes.bool,
  /** The css class name of the component */
  className: PropTypes.string,
  /** The css style of the component */
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  /** Validation functions */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** @ignore */
  'data-context': PropTypes.shape({
    submit: PropTypes.func,
    reset: PropTypes.func,
    data: PropTypes.object,
  }),
  /** @ignore */
  validationMessage: PropTypes.string,
}

export default compose(
  withFormContext,
  withLabel,
  withValue,
  withValidation,
  withOnChange
)(Input)
