/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type FindCliniciansQueryVariables = Types.Exact<{
  name?: Types.Maybe<Types.Scalars['String']>
}>

export type FindCliniciansQuery = {
  getEligibleConsultants?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.Consultant, 'id' | 'name'>>>
  >
}

export const FindCliniciansDocument = gql`
  query FindClinicians($name: String) {
    getEligibleConsultants(name: $name) {
      id
      name
    }
  }
`
export function useFindCliniciansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCliniciansQuery,
    FindCliniciansQueryVariables
  >
) {
  return Apollo.useQuery<FindCliniciansQuery, FindCliniciansQueryVariables>(
    FindCliniciansDocument,
    baseOptions
  )
}
export function useFindCliniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCliniciansQuery,
    FindCliniciansQueryVariables
  >
) {
  return Apollo.useLazyQuery<FindCliniciansQuery, FindCliniciansQueryVariables>(
    FindCliniciansDocument,
    baseOptions
  )
}
export type FindCliniciansQueryHookResult = ReturnType<
  typeof useFindCliniciansQuery
>
export type FindCliniciansLazyQueryHookResult = ReturnType<
  typeof useFindCliniciansLazyQuery
>
export type FindCliniciansQueryResult = Apollo.QueryResult<
  FindCliniciansQuery,
  FindCliniciansQueryVariables
>
