import React from 'react'
import { Input, Spinner } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { Practice } from '@babylon/graphql-middleware-types'
import messages from './PracticeSearcher.messages'
import SearchResults from './SearchResults'
import styles from './PracticeSearcher.module.css'

interface PracticeSearcherProps {
  setSearchValue: (value: string) => void
  loading: boolean
  skipSearch: boolean
  handleSelected: (id: string, name: string | null | undefined) => void
  practices: Practice[]
}

const PracticeSearcher = ({
  setSearchValue,
  loading,
  skipSearch,
  handleSelected,
  practices,
}: PracticeSearcherProps) => {
  const fm = useFormatMessage()

  return (
    <>
      <Input
        fill
        placeholder={fm(messages.search_placeholder)}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <div className={styles.Container}>
        {loading && (
          <div className={styles.Overlay}>
            <Spinner size="large" centered className={styles.Spinner} />
          </div>
        )}
        <SearchResults
          hasSearched={!skipSearch && !loading}
          practices={practices}
          onSelect={handleSelected}
        />
      </div>
    </>
  )
}

export default PracticeSearcher
