import React, { ReactNode } from 'react'
import cn from 'classnames'

interface Props {
  tag?: typeof React.Component | 'span' | 'div'
  className?: string
  name?: string
  children?: ReactNode
}

const Theme = ({
  tag: Component = 'div',
  name = 'theme-default',
  className,
  children,
  ...props
}: Props) => (
  <Component className={cn(name, className)} {...props}>
    {children}
  </Component>
)

export default Theme
