import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import Cookies from 'js-cookie'

export const useCookiePersistedState = (
  cookieName: string,
  defaultValue = false
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const cookie = Cookies.get(cookieName)
  const [state, setState] = useState<boolean>(
    cookie ? cookie === 'true' : defaultValue
  )

  useEffect(() => {
    Cookies.set(cookieName, state as any, { expires: 1095 })
  }, [state, cookieName])

  return [state, setState]
}
