import React, { ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Card, Heading, Tag } from '@babylon/core-ui'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { formatDateTimeWithTimezone } from '@babylon/shared-utils'
import ViewMoreToggler from '../../../ViewMoreToggler'
import {
  CancellationReason,
  Consultant,
  Medium,
  Practice,
  WARNING_STATUSES,
} from '../../../types'
import { MemberOpsModuleName } from '../../../..'
import MediumIcon from '../../MediumIcon'
import Details from './Details'
import messages from './WarningCard.messages'
import styles from './styles.module.scss'
import { useTimezoneContext } from '../../../Member/View/components/TimezoneProvider'

const getScheduledDate = (timezone: string, timeStamp?: string | null) => {
  if (!timeStamp) {
    return null
  }

  const twelveHourFormat = timezone.split('/')[0] === 'America'

  return formatDateTimeWithTimezone(timeStamp, {
    desiredTimeZone: timezone,
    twelveHourFormat,
  })
}

export interface WarningCardProps {
  menu: ReactNode
  title: ReactNode
  appointmentType?: string
  cancelledAt?: string | null
  cancellationReasons?: CancellationReason[]
  guestName?: string | null
  id: string
  medium?: Medium
  notes?: string
  reason?: string | null
  consumerNetwork?: string | null
  consultant?: Consultant | null
  originalConsultation?: string | null
  scheduledTime?: string | null
  status?: string
  clinic?: Practice | null
}

const WarningCard = ({
  appointmentType,
  cancellationReasons,
  cancelledAt,
  guestName,
  clinic,
  consultant,
  consumerNetwork,
  id,
  medium,
  menu,
  notes,
  originalConsultation,
  reason,
  scheduledTime,
  status,
  title,
}: WarningCardProps) => {
  const { timezone } = useTimezoneContext()
  const [expanded, setExpanded] = useState<boolean>(false)
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })
  const viewMoreTogglerHandler = () => {
    setExpanded((toggle) => !toggle)
    trackClick({
      elementName: 'view-more-btn',
      elementType: TrackingElementType.button,
    })
  }

  const scheduledDate = getScheduledDate(timezone, cancelledAt || scheduledTime)

  return (
    <div className={styles.WarningCard__wrapper} data-testid="timeline-card">
      <MediumIcon medium={medium} isWarning />
      <Card className={styles.WarningCard}>
        <div className={styles.WarningCard__summary}>
          <ViewMoreToggler
            testId="timeline-card-show-details"
            open={!!expanded}
            size="small"
            onToggle={viewMoreTogglerHandler}
            className={styles.WarningCard__inlineToggler}
          />

          <div className={styles.WarningCard__summaryContent}>
            <div data-testid="timeline-card-status">
              {!!status && (
                <Tag className={styles.WarningCard__warningTag}>
                  <FormattedMessage {...messages[status as WARNING_STATUSES]} />
                </Tag>
              )}
            </div>
            <Heading
              level="h3"
              data-testid="timeline-card-title"
              className={styles.WarningCard__title}
            >
              {title}
            </Heading>
            {scheduledDate && (
              <div
                className={styles.WarningCard__date}
                data-testid="timeline-card-scheduled-time"
              >
                {scheduledDate}
              </div>
            )}
          </div>

          <div className={styles.WarningCard__menu}>{menu}</div>
        </div>

        <Details
          appointmentType={appointmentType}
          cancellationReasons={cancellationReasons}
          clinic={clinic}
          guestName={guestName}
          consultant={consultant}
          consumerNetwork={consumerNetwork}
          notes={notes}
          originalConsultation={originalConsultation}
          reason={reason}
          id={id}
          show={expanded}
        />
      </Card>
    </div>
  )
}

export default WarningCard
