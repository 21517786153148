import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { Text } from '@babylon/core-ui'
import EntityCard from '../EntityCard'
import FindCliniciansQuery from './FindCliniciansQuery'
import AddCliniciansToServiceType from './AddCliniciansToServiceTypeMutation'
import RemoveClinicianFromServiceType from './RemoveClinicianFromServiceTypeMutation'

export interface Clinician {
  id: string
  name: string
  role: string
}

export interface ClinicianResult extends Clinician {
  full_name: string
}

interface Props {
  serviceTypeUuid: string
  clinicians?: Clinician[]
}

const ServiceTypeClinicians = ({ serviceTypeUuid, clinicians = [] }: Props) => (
  <Mutation
    mutation={AddCliniciansToServiceType}
    refetchQueries={['GetServiceType']}
    awaitRefetchQueries
  >
    {(addCliniciansToServiceType: (params: any) => Promise<any>) => (
      <Mutation
        mutation={RemoveClinicianFromServiceType}
        refetchQueries={['GetServiceType']}
        awaitRefetchQueries
      >
        {(removeClinicianFromServiceType: (params: any) => Promise<any>) => (
          <EntityCard
            name="clinician"
            query={{
              query: FindCliniciansQuery,
              variables: (filterText) => ({
                filters: { full_name: filterText },
              }),
              resultSelector: (data) =>
                data.getConsultants.map((c: ClinicianResult) => ({
                  ...c,
                  name: c.full_name,
                })),
            }}
            entityRenderer={({ item }) => (
              <>
                <Text style={{ fontWeight: 'bold' }}>{item.name}</Text>
                <br />
                <Text>{item.role || 'Role Unknown'}</Text>
              </>
            )}
            extraColumns={{ role: 'Profession' }}
            items={clinicians}
            onItemsAdded={(clinician_ids: string[]) =>
              addCliniciansToServiceType({
                variables: {
                  service_type_uuid: serviceTypeUuid,
                  clinician_ids,
                },
              })
            }
            onItemRemoved={(clinician_id: string) =>
              removeClinicianFromServiceType({
                variables: {
                  service_type_uuid: serviceTypeUuid,
                  clinician_id,
                },
              })
            }
          />
        )}
      </Mutation>
    )}
  </Mutation>
)

export default ServiceTypeClinicians
