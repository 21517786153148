import React from 'react'
import { Formik, Form } from 'formik'

import { Button, FormikInput, FormikDropdownSelect } from '@babylon/core-ui'

import { DefaultDurationsField } from './ProfessionFormFields'

import professionValidation from './ProfessionFormValidation'

interface Props {
  onSubmit: Function
}

const initialValues = {
  name: '',
  default_slot_duration_minutes: '',
  alternative_slot_durations_minutes: [],
  medical_identifier_label: '',
  default_appointment_price_cents: 0,
}

function getDefaultDurationTimeOptions(times: Array<string | number>) {
  return times.map((time: string | number) => ({
    key: `${time}`,
    value: `${time} minutes`,
  }))
}

const ProfessionForm = ({ onSubmit }: Props) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values) => {
      /*
       * Normalizing values to correct type before submitting.
       * Form fields return all values as strings. Some of the fields have to be integers
       */
      const normalizedValues = {
        ...values,
        alternative_slot_durations_minutes: values.alternative_slot_durations_minutes.map(
          (value) => parseInt(value)
        ),
        default_slot_duration_minutes: parseInt(
          values.default_slot_duration_minutes
        ),
      }
      onSubmit(normalizedValues)
    }}
    validationSchema={professionValidation}
  >
    {({ values, setFieldValue }) => (
      <Form>
        <FormikInput id="name" name="name" label="Name" />
        <DefaultDurationsField
          fieldName="alternative_slot_durations_minutes"
          onChange={(selectedDurationValues) => {
            const {
              default_slot_duration_minutes: currentSelectedDefaultTime,
            }: { default_slot_duration_minutes: string } = values
            /*
             * Set value as null for the default duration time
             * when that value is removed from the optional times.
             */
            if (!selectedDurationValues.includes(currentSelectedDefaultTime)) {
              setFieldValue(
                'default_slot_duration_minutes',
                initialValues.default_slot_duration_minutes
              )
            }
          }}
        />
        <FormikDropdownSelect
          id="default_slot_duration_minutes"
          name="default_slot_duration_minutes"
          label="Default duration"
          options={getDefaultDurationTimeOptions(
            values.alternative_slot_durations_minutes
          )}
        />
        <FormikInput
          id="medical_identifier_label"
          name="medical_identifier_label"
          label="Medical identifier"
        />
        <Button type="submit">Save profession</Button>
      </Form>
    )}
  </Formik>
)

export default ProfessionForm
