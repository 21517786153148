import React, { useState, useCallback, useEffect, ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'

import { Snackbar } from '@babylon/core-ui'

library.add(faExclamationTriangle)

interface Props {
  message?: string
  'data-testid'?: string
  visible?: boolean
  onClose?: () => void
  className?: string
  children?: ReactNode
}

const UnexpectedError = ({
  message,
  'data-testid': dataTestId = 'error-message',
  onClose,
  className,
  visible = false,
  children,
}: Props) => {
  const [errorDisplayed, updateErrorDisplayed] = useState(visible)

  useEffect(() => {
    updateErrorDisplayed(visible)
  }, [visible, updateErrorDisplayed])

  const handleClose = useCallback(() => {
    updateErrorDisplayed(false)

    if (onClose) {
      onClose()
    }
  }, [onClose, updateErrorDisplayed])

  return (
    <Snackbar
      data-testid={dataTestId}
      icon={
        <FontAwesomeIcon icon={['fal', 'exclamation-triangle']} size="lg" />
      }
      className={className}
      intent="error"
      message={message}
      onClose={handleClose}
      open={errorDisplayed}
    >
      {children}
    </Snackbar>
  )
}

export default UnexpectedError
