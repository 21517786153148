import { gql } from '@apollo/client'

export const GetAppointmentCliniciansQuery = gql`
  query getAppointmentClinicians(
    $memberId: ID
    $consultantType: String
    $consultationType: String
    $serviceTypeUuid: ID
  ) {
    appointmentClinicians(
      memberId: $memberId
      consultantType: $consultantType
      consultationType: $consultationType
      serviceTypeUuid: $serviceTypeUuid
    ) {
      id
      gender
      name
    }
  }
`
