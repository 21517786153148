import React from 'react'
import { Formik, Form } from 'formik'
import {
  Divider,
  FormikDatePicker,
  FormikCheckboxGroup,
  Checkbox,
  Button,
} from '@babylon/core-ui'
import * as Yup from 'yup'
import { useFormatMessage } from '@babylon/intl'
import { startOfDay } from 'date-fns'
import { FormikTimeRangePicker } from '../FormikTimeRangePicker'
import styles from './UpdateAvailabilityForm.module.css'
import type {
  UpdateAvailabilityFormProps,
  UpdateAvailabilityFormValues,
} from './UpdateAvailabilityForm.types'
import messages from './UpdateAvailabilityForm.messages'

const availabilityOptions = [
  {
    id: 'training',
    label: 'Training',
    value: 'training',
  },
  {
    id: 'recurring',
    label: 'Recurring',
    value: 'recurring',
  },
]

const validationSchema = Yup.object({
  date: Yup.date().required().typeError('Please select a valid date.'),
  startTime: Yup.date().required('Please select a start time.'),
  endTime: Yup.date()
    .min(Yup.ref('startTime'), 'The end time cannot be before the start time.')
    .required('Please select an end time'),
  options: Yup.array().of(
    Yup.string().oneOf(availabilityOptions.map((option) => option.value))
  ),
})

const initialValues = {
  date: startOfDay(new Date()),
  startTime: undefined,
  endTime: undefined,
  options: [],
}

const UpdateAvailabilityForm = ({ onSubmit }: UpdateAvailabilityFormProps) => {
  const fm = useFormatMessage()

  const handleSubmit = (values: UpdateAvailabilityFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik<UpdateAvailabilityFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid }) => (
        <Form className={styles.form} aria-label={fm(messages.formAriaLabel)}>
          <h2 className={styles.heading}>{fm(messages.title)}</h2>
          <Divider />

          <div className={styles.filters}>
            <div className={styles.filter}>
              <FormikDatePicker
                id="date"
                name="date"
                label="Date"
                minDate={new Date()}
              />
            </div>

            <div className={styles.filter}>
              <FormikTimeRangePicker
                id="timeRange"
                name="timeRange"
                startTimeName="startTime"
                endTimeName="endTime"
                label="Time range"
              />
            </div>

            <div className={styles.filter}>
              <FormikCheckboxGroup id="options" name="options" label="Options">
                {availabilityOptions.map((option) => (
                  <Checkbox
                    key={option.id}
                    id={option.id}
                    name={option.id}
                    value={option.value}
                    type="pill"
                    inline
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </FormikCheckboxGroup>
            </div>
          </div>

          <div className={styles.filterButtons}>
            <Button type="submit" disabled={!isValid}>
              {fm(messages.formSubmitButton)}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default UpdateAvailabilityForm
