/* eslint-disable camelcase */
import React from 'react'
import { Grid, Cell } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import {
  Address,
  Email,
  GenderAge,
  IDs,
  PhoneNumber,
  MemberOpsModuleName,
} from '../../../../..'

import { OnDetailsCopy } from '../../../types'
import { useSearchFeaturesContext } from '../../../SearchFeaturesContext'
import { HealthcareIdentifier, Member } from '../../../../../app'

import messages from './Details.messages'

export interface DetailsProps extends Member {
  onDetailsCopy: OnDetailsCopy
}

function getIds(healthcareIdentifier: HealthcareIdentifier | null) {
  const {
    public_identifier = '',
    unconfirmed_public_identifier = '',
    private_identifier = '',
  } = healthcareIdentifier ?? {}

  return {
    publicId: public_identifier,
    unconfirmedPublicId: unconfirmed_public_identifier,
    privateId: private_identifier,
  }
}

const Details = ({
  date_of_birth,
  gender,
  address_first_line,
  address_second_line,
  address_third_line,
  address_state_code,
  address_post_code,
  full_phone_number,
  healthcare_identifier,
  email,
  id,
  onDetailsCopy,
  uuid,
}: DetailsProps) => {
  const fm = useFormatMessage()

  const { publicId, unconfirmedPublicId, privateId } = getIds(
    healthcare_identifier
  )
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.membersSearchView,
  })

  const copyToClipboardHandler = (message: string) => () => {
    onDetailsCopy(message)
    trackClick({
      elementName: 'copy-to-clipboard-btn',
      elementType: TrackingElementType.button,
    })
  }

  const copyIdToClipboardHandler = (message: string) => {
    onDetailsCopy(message)
    trackClick({
      elementName: 'copy-id-to-clipboard-btn',
      elementType: TrackingElementType.button,
    })
  }

  const { enableResultActions } = useSearchFeaturesContext()

  return (
    <Grid gap={16} columns={12}>
      <Cell width={enableResultActions ? 2 : 3}>
        <GenderAge
          dateOfBirth={date_of_birth}
          gender={gender}
          onDateOfBirthCopy={copyToClipboardHandler(fm(messages.dob_copied))}
        />
      </Cell>
      <Cell width={enableResultActions ? 2 : 3}>
        <Address
          line1={address_first_line}
          line2={address_second_line}
          line3={address_third_line}
          state={address_state_code}
          postcode={address_post_code}
          onAddressCopy={copyToClipboardHandler(fm(messages.address_copied))}
        />
      </Cell>
      <Cell width={3}>
        <div>
          <PhoneNumber
            phoneNumber={full_phone_number}
            onPhoneNumberCopy={copyToClipboardHandler(
              fm(messages.phone_copied)
            )}
          />
        </div>
        <div>
          <Email
            email={email}
            onEmailCopy={copyToClipboardHandler(fm(messages.email_copied))}
          />
        </div>
      </Cell>
      <Cell width={enableResultActions ? 2 : 3}>
        <IDs
          babylonId={id}
          privateId={privateId}
          publicId={publicId}
          unconfirmedPublicId={unconfirmedPublicId}
          babylonUserUuid={uuid}
          onIDCopy={copyIdToClipboardHandler}
        />
      </Cell>
    </Grid>
  )
}

export default Details
