import React from 'react'

import { Text } from '@babylon/core-ui'
import styles from './AvailabilitySlots.module.css'

interface Props {
  clinicName?: string
  clinicTimezone?: string
  dayOfWeek: string
  dayOfMonth: string
}

const AvailabilitySlotsHeader = ({
  clinicName,
  clinicTimezone,
  dayOfWeek,
  dayOfMonth,
}: Props) => (
  <div data-testid="availability-slots-header">
    {clinicName && (
      <div className={styles.gridHeaderClinic}>
        <Text align="center" bold tag="div" uppercase ellipsis>
          {clinicName}
        </Text>
        {clinicTimezone && (
          <Text align="center" bold tag="div" uppercase>
            ({clinicTimezone})
          </Text>
        )}
      </div>
    )}
    <Text align="center" bold color="grey-type" tag="div" uppercase>
      {dayOfWeek}
    </Text>
    <Text align="center" bold color="grey-type" tag="div">
      {dayOfMonth}
    </Text>
  </div>
)

export default AvailabilitySlotsHeader
