import { useContext } from 'react'
import { SnackbarIntent } from '@babylon/core-ui'
import SnackBarContext from './SnackBarContext'

const useSnackBar = () => {
  const { snackBar, setSnackBar } = useContext(SnackBarContext)

  const setSnackbarMessage = (
    message: string,
    icon?: any,
    intent?: SnackbarIntent,
    autoHideDuration = 5000
  ) => {
    setSnackBar({
      ...snackBar,
      message,
      open: !!message,
      icon,
      intent,
      autoHideDuration,
    })
  }

  return {
    snackBar,
    setSnackbarMessage,
  }
}

export default useSnackBar
