/* eslint-disable jsx-a11y/label-has-associated-control */
import 'focus-visible/dist/focus-visible'
import React from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { IMedkitComponent } from '../..'

export const Pill: IMedkitComponent<PillProps, {}> = ({
  children,
  disabled = false,
  checked = false,
  onChange = () => {},
  className,
  error,
  testHandle,
  ErrorMessages,
  hint,
  type,
  id,
  ...props
}: PillProps) => (
  <label
    className={cn(
      styles.pillElement,
      {
        [styles.pillDisabled]: disabled,
      },
      className
    )}
    data-testid={testHandle}
    htmlFor={id}
  >
    <input
      className={styles.pillInput}
      type={type}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      id={id}
      {...props}
    />
    <div className={styles.pillLabel}>{children}</div>
  </label>
)

Pill.theme = {}

export interface PillProps extends React.HTMLProps<HTMLInputElement> {
  children?: React.ReactChild
  disabled?: boolean
  checked?: boolean
  onChange?: (e: any) => any
  error?: boolean | string
  testHandle?: string
  ErrorMessages?: React.FC<any>
  hint?: string
  type: 'radio' | 'checkbox'
  variant?: 'bordered'
}

export default Pill
