import React from 'react'

// TODO: get rid of this hacky state sharing!!
import { errorInfo } from '@/App'

import styles from './styles.module.scss'

class ConnectionAlert extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      online: navigator.onLine,
    }
    this.errorCount = errorInfo.errorCount
    window.addEventListener('offline', this.offline)
    window.addEventListener('online', this.online)
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.online)
    window.removeEventListener('offline', this.offline)
  }

  online = () => {
    this.setState({ online: true })

    if (this.errorCount < errorInfo.errorCount) {
      window.location.replace(window.location.href)
    }
  }

  offline = () => {
    this.errorCount = errorInfo.errorCount
    this.setState({ online: false })
  }

  render() {
    return this.state.online ? null : (
      <div className={styles.alert}>
        <span>
          You are offline, please check <br /> your Internet connection.
        </span>
      </div>
    )
  }
}

export default ConnectionAlert
