import React from 'react'
import cx from 'classnames'
import {
  DataGrid,
  Columns,
  Column,
  Actions,
  Pagination,
  TabGroup,
  Tab,
  PaginationSize,
} from '@babylon/babylon-forms/widgets'

import { Button, formContext } from '@babylon/babylon-forms/forms'
import Icon from '@/components/Icon'
import LabelWithIcon from '@/components/LabelWithIcon'
import PageHeader from '@/components/PageHeader'
import TimezoneDate from '@/components/TimezoneDate'

import Spinner from './Spinner'
import { formatDrug, formatAddress } from '../utils'

import styles from './styles.module.scss'

const ItemView = ({ item, actions, context }) => {
  const {
    id,
    patient: { full_name: patientName, email, phone_number: phoneNumber },
    drugs,
    updatedAt,
    deliveryAddress: { fullAddress: deliveryAddress },
    pharmacyDetails,
  } = item
  const removing = context.indexOf(id) !== -1

  return (
    <tr className={cx({ [styles.removing]: removing })}>
      <td>
        <strong>{patientName}</strong>
      </td>
      <td>{email}</td>
      <td>{phoneNumber}</td>
      <td className={styles.narrowRow}>
        <TimezoneDate date={updatedAt} />
      </td>
      <td>{drugs.map(formatDrug)}</td>
      <td>
        <span className={styles.addressWrap}>
          {formatAddress(deliveryAddress)}
        </span>
      </td>
      <td>{formatAddress(pharmacyDetails)}</td>
      <DataGrid.ItemActions item={item} actions={actions} />
    </tr>
  )
}

const hasAction = (prescription, action) =>
  prescription.actions && prescription.actions.indexOf(action) !== -1

const ActionsView = ({ handleConfirm, handleCancel, viewPdf }) => (
  <formContext.Consumer>
    {(prescription) => (
      <span>
        {hasAction(prescription, 'MARK_DRUGS_DISPATCHED') ? (
          <Button small onClick={handleConfirm}>
            <LabelWithIcon icon="ok" label="Confirm dispatched" />
          </Button>
        ) : null}
        {hasAction(prescription, 'REJECT_PRESCRIPTION_DISPENSING') ? (
          <Button small onClick={handleCancel}>
            <LabelWithIcon icon="ban" label="Cancel delivery" />
          </Button>
        ) : null}
        {hasAction(prescription, 'VIEW_PDF') ? (
          <Button small onClick={viewPdf}>
            <LabelWithIcon icon="pdf" label="View pdf" />
          </Button>
        ) : null}
      </span>
    )}
  </formContext.Consumer>
)

const PrescriptionsView = ({
  data: {
    loading,
    pharmacyPrescriptions: { itemsPerPage, pageIndex, totalItems, items },
    variables: { states, sort = '' },
  },
  handleSort,
  spinnerType,
  handlePageSizeChange,
  handlePageChange,
  handleStateChange,
  handleConfirm,
  handleCancel,
  viewPdf,
  removedPrescriptionIds,
}) => {
  const [column, direction] = sort.split(',')

  return (
    <div>
      <PageHeader>Prescriptions</PageHeader>
      <div className={styles.prescriptionsView}>
        <TabGroup value={states} onChange={handleStateChange}>
          <Tab
            name="DELIVERY_PENDING"
            title={
              <span>
                Delivery Pending{' '}
                <Spinner
                  visible={spinnerType === 'DELIVERY_PENDING' && loading}
                />
              </span>
            }
          />
          <Tab
            name="CONFIRMED_RECEIVED"
            title={
              <span>
                Delivery Confirmed{' '}
                <Spinner
                  visible={spinnerType === 'CONFIRMED_RECEIVED' && loading}
                />
              </span>
            }
          />
        </TabGroup>
        <div className={styles.listHeader}>
          <PaginationSize
            value={itemsPerPage}
            onChange={handlePageSizeChange}
            totalItems={totalItems}
            spinner={
              <Spinner visible={spinnerType === 'page-size' && loading} />
            }
          />
        </div>
        <DataGrid
          value={items}
          ItemView={ItemView}
          sort={{
            column,
            direction,
            loading: spinnerType === 'sort' && loading,
          }}
          onSort={handleSort}
          icons={{
            asc: <Icon name="sort-up" />,
            desc: <Icon name="sort-down" />,
            sort: <Icon name="sort" />,
            spinner: <Spinner visible />,
          }}
          context={removedPrescriptionIds}
        >
          <Columns>
            <Column name="patientName" label="Patient" />
            <Column name="email" label="Email" />
            <Column name="phoneNumber" label="Phone number" />
            <Column name="updatedAt" label="Last Updated" sort />
            <Column name="drugs" label="Drugs" />
            <Column name="deliveryAddress" label="Delivery address" />
            <Column name="pharmacyDetails" label="Pharmacy details" />
          </Columns>
          <Actions>
            <ActionsView
              handleConfirm={handleConfirm}
              handleCancel={handleCancel}
              viewPdf={viewPdf}
            />
          </Actions>
        </DataGrid>
        <div className={styles.bottomToolbar}>
          <div />
          <div className={styles.rowRight}>
            <Spinner visible={spinnerType === 'pagination' && loading} />
            <Pagination
              page={pageIndex}
              size={itemsPerPage}
              totalElements={totalItems}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrescriptionsView
