import React from 'react'
import { Query } from '@apollo/client/react/components'
import { SearchPatients } from './queries'

export default ({ variables, onCompletedFormSearch, children }) => (
  <Query
    notifyOnNetworkStatusChange
    partialRefetch
    fetchPolicy="network-only"
    query={SearchPatients}
    skip={!variables.query}
    variables={variables}
    onCompleted={onCompletedFormSearch}
  >
    {children}
  </Query>
)
