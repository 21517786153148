import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import ProfessionsManagement from './ProfessionsManagement'
import AddProfession from './AddProfession'
import ProfessionInfo from './ProfessionInfo'

interface Props extends RouteComponentProps {}

const Professions = ({ match: { path } }: Props) => (
  <Switch>
    <Route
      exact
      path={`${path}/add`}
      render={() => <AddProfession baseUrl={path} />}
    />
    <Route exact path={`${path}/:id`} component={ProfessionInfo} />
    <Route exact path={path} component={ProfessionsManagement} />
  </Switch>
)

export default Professions
