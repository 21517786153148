import React from 'react'
import { FormattedMessage } from 'react-intl'
import { KebabMenu, KebabMenuRenderOptions } from '@babylon/core-ui'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../..'
import messages from './WorkflowCard.messages'

interface MenuProps {
  camundaLink: string
  completed: boolean
}

const Menu = ({ camundaLink, completed }: MenuProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })

  if (completed) {
    return null
  }

  return (
    <KebabMenu>
      {({ menuItemProps }: KebabMenuRenderOptions) => (
        <div>
          <a
            {...menuItemProps}
            data-testid="timeline-card-camunda-link"
            href={camundaLink}
            onClick={(e) => {
              trackClick({
                elementName: 'timeline-card-camunda-link',
                elementType: TrackingElementType.link,
                href: e.currentTarget.href,
              })
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage {...messages.view_in_camunda} />
          </a>
        </div>
      )}
    </KebabMenu>
  )
}

export default Menu
