import React from 'react'
import { Text, Heading, Button } from '@babylon/core-ui'

import { AppointmentFields } from '../AppointmentFields'
import ConsultationTypes, {
  ConsultationTypeName,
} from '../types/ConsultationTypes'

import { UnexpectedError } from '../Utils'
import { useConsultantType } from '../hooks'
import { LoadingContainer } from '../components'

import style from './AppointmentDetails.module.css'

interface Props {
  filters?: AppointmentFields | null
  serviceTypes?: ServiceType[]
  onEdit?: () => void
}

const AppointmentDetails = ({ filters, serviceTypes = [], onEdit }: Props) => {
  const {
    allowed_mediums: allowedMediums = [],
    allowed_professions: allowedProfessions = [],
    consultation_type: consultationType,
    consultant_type: consultantType,
    service_type_uuid: serviceTypeUuid,
    duration_minutes: durationMinutes,
  } = (filters || {}) as AppointmentFields

  const selectedServiceType = serviceTypes.find(
    (serviceType) => serviceType.uuid === serviceTypeUuid
  )

  const { consultantTypeDict, loading, error } = useConsultantType({
    skip: allowedProfessions.length > 1,
  })
  const consultantName = consultantTypeDict?.[consultantType || '']?.name

  return (
    <div className={style.appointmentDetails}>
      {error && <UnexpectedError visible message={error.message} />}
      <div className={style.appointmentDetailsHeader}>
        <Heading level="h3">Appointment Details</Heading>
        {filters && onEdit && (
          <Button
            intent="secondary"
            onClick={onEdit}
            dataTestId="edit-appointment-details"
          >
            Edit
          </Button>
        )}
      </div>
      <LoadingContainer loading={loading} fill>
        {filters && (
          <div className={style.appointmentDetailsItems}>
            {allowedMediums.length === 1 && consultationType && (
              <Text
                data-testid="appointment-details-consultation-type"
                tag="div"
                className={style.appointmentDetailsItem}
              >
                {ConsultationTypes[consultationType as ConsultationTypeName]}
              </Text>
            )}
            {allowedProfessions.length <= 1 && consultantName && (
              <Text
                data-testid="appointment-details-consultant-name"
                tag="div"
                className={style.appointmentDetailsItem}
              >
                {consultantName}
              </Text>
            )}
            {serviceTypeUuid && selectedServiceType && (
              <Text
                data-testid="appointment-details-service-name"
                tag="div"
                className={style.appointmentDetailsItem}
              >
                {selectedServiceType.name}
              </Text>
            )}
            {durationMinutes && (
              <Text
                data-testid="appointment-details-duration"
                tag="div"
                className={style.appointmentDetailsItem}
              >
                {durationMinutes} minutes
              </Text>
            )}
          </div>
        )}
      </LoadingContainer>
    </div>
  )
}
export default AppointmentDetails
