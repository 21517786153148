import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useProductConfig } from '@babylon/product-config'
import CopyToClipboardButton from '../../CopyToClipboardButton'
import Unknown from '../../Unknown'
import commonStyles from '../styles.module.scss'
import messages from '../CopiableMemberInformation.messages'
import useEhrPatient from '../../../hooks/useEhrPatient'

export enum IDTypes {
  BABYLON = 'babylon',
  PRIVATE = 'private',
  PUBLIC = 'public',
  USER_UUID = 'babylonUserId',
  EXTERNAL_EHR_ID = 'externalEhrId',
  UNCONFIRMED_PUBLIC = 'unconfirmed_public',
}

export interface IDsProps {
  babylonId: string
  privateId?: string | null
  publicId?: string | null
  unconfirmedPublicId?: string | null
  babylonUserUuid?: string
  onIDCopy: (message: string, type: IDTypes, id: string) => void
}

interface CopiableIdProps {
  id: string | null
  copyHandler: (value: string) => void
  'data-testid': string
}

const CopiableID = ({
  id,
  copyHandler,
  'data-testid': dataTestId,
}: CopiableIdProps) =>
  id ? (
    <CopyToClipboardButton
      className={commonStyles.copiable__copyToClipboardButton}
      value={id}
      onCopiedToClipboard={copyHandler}
    >
      <span data-testid={dataTestId}>{id}</span>
    </CopyToClipboardButton>
  ) : (
    <Unknown />
  )

const IDs = ({ babylonId, onIDCopy, ...ids }: IDsProps) => {
  const fm = useFormatMessage()
  const { ehrPatient } = useEhrPatient(babylonId)
  const { getProp } = useProductConfig()
  const viewUuid = getProp('memberOperations', 'viewUuid')

  const idCopiedHandler = (type: IDTypes, message: string) => (id: string) => {
    onIDCopy(message, type, id)
  }
  const privateId = ids.privateId ? ids.privateId.trim() : null
  const publicId = ids.publicId ? ids.publicId.trim() : null
  const unconfirmedPublicId = ids.unconfirmedPublicId
    ? ids.unconfirmedPublicId.trim()
    : null
  const userUuid = ids.babylonUserUuid ? ids.babylonUserUuid.trim() : null
  const showUnconfirmedPublicId = !publicId && unconfirmedPublicId

  return (
    <dl className={commonStyles.IDList}>
      <dt>{fm(messages.babylon)}</dt>
      <dd data-testid="babylon-id-value">
        <CopiableID
          id={babylonId}
          data-testid="copiable-babylon-id"
          copyHandler={idCopiedHandler(
            IDTypes.BABYLON,
            fm(messages.id_babylon)
          )}
        />
      </dd>
      <dt>{fm(messages.public)}</dt>
      <dd data-testid="public-id-value">
        {showUnconfirmedPublicId ? (
          <>
            <CopiableID
              id={unconfirmedPublicId}
              data-testid="copiable-public-id"
              copyHandler={idCopiedHandler(
                IDTypes.UNCONFIRMED_PUBLIC,
                fm(messages.unconfirmed_id_public)
              )}
            />
            <div className={commonStyles.unconfirmedLabel}>
              ({fm(messages.unconfirmed)})
            </div>
          </>
        ) : (
          <CopiableID
            id={publicId}
            data-testid="copiable-public-id"
            copyHandler={idCopiedHandler(
              IDTypes.PUBLIC,
              fm(messages.id_public)
            )}
          />
        )}
      </dd>
      <dt>{fm(messages.private)}</dt>
      <dd data-testid="private-id-value">
        <CopiableID
          id={privateId}
          data-testid="copiable-private-id"
          copyHandler={idCopiedHandler(
            IDTypes.PRIVATE,
            fm(messages.id_private)
          )}
        />
      </dd>
      {viewUuid && (
        <>
          <dt>{fm(messages.user_uuid)}</dt>
          <dd
            className={commonStyles.babylonUuid}
            data-testid="user-uuid-value"
          >
            <CopiableID
              id={userUuid}
              data-testid="copiable-user-uuid"
              copyHandler={idCopiedHandler(
                IDTypes.USER_UUID,
                fm(messages.id_uuid)
              )}
            />
          </dd>
        </>
      )}
      {ehrPatient && (
        <>
          <dt>{fm(messages.athena)}</dt>
          <dd data-testid="athena-id-value">
            <CopiableID
              id={ehrPatient.externalEhrId}
              data-testid="copiable-athena-id"
              copyHandler={idCopiedHandler(
                IDTypes.EXTERNAL_EHR_ID,
                fm(messages.id_athena)
              )}
            />
          </dd>
        </>
      )}
    </dl>
  )
}

export default IDs
