import React, { useEffect, useState } from 'react'
import { ConfirmationModal } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import styles from './styles.module.scss'
import messages from './Modals.messages'

export interface ModalProps {
  open: boolean
  onCancel(): void
  onConfirm(): void
}

export const TransferOwnershipModal = ({
  open = false,
  onCancel,
  onConfirm,
}: ModalProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => () => {
    if (!open) {
      setLoading(false)
    }
  })

  const confirmHandler = () => {
    setLoading(true)
    onConfirm()
  }

  const fm = useFormatMessage()
  const {
    cancel,
    confirm,
    transfer_ownership_loading,
    transfer_ownership_message,
    transfer_ownership_question,
  } = messages

  return (
    <ConfirmationModal
      cancelText={fm(cancel)}
      confirmText={fm(loading ? transfer_ownership_loading : confirm)}
      includeClose={false}
      loading={loading}
      open={open}
      onClose={onCancel}
      onConfirm={confirmHandler}
    >
      <div className={styles.modal}>
        <ConfirmationModal.Heading>
          {fm(transfer_ownership_question)}
        </ConfirmationModal.Heading>
        <ConfirmationModal.Content>
          {fm(transfer_ownership_message)}
        </ConfirmationModal.Content>
      </div>
    </ConfirmationModal>
  )
}
