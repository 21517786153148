import { gql } from '@apollo/client'

export const PrescriptionAudit = gql`
  query PrescriptionAudit($prescriptionId: ID!) {
    prescriptionAudit(prescriptionId: $prescriptionId) {
      id
      eventTimestamp
      performedByName
      loginType
      eventType
      eventDetails
    }
    prescriptionFaxStatus(prescriptionId: $prescriptionId) {
      id
      faxableId
      status
      pageCount
      errorId
      errorType
      errorMessage
      createdAt
      sentAt
      failedAt
    }
  }
`
