import React from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons'

import { Heading, Text } from '@babylon/core-ui'

import {
  ConsultationTypeName,
  isPhysicalConsultation,
} from '../../types/ConsultationTypes'
import AvailabilitySlotsGrid from './AvailabilitySlotsGrid'
import groupSlotsByDay from './GroupSlotsByDay'
import groupSlotsByClinic, {
  Clinic,
  buildClinicIndex,
} from './GroupSlotsByClinic'
import GetAvailabilitySlotsQuery from './GetAvailabilitySlotsQuery'
import { UnexpectedError } from '../../Utils'
import { LoadingContainer } from '../../components'
import styles from './AvailabilitySlots.module.css'

interface Props {
  memberId: string
  clinicList: Clinic[]
  consultationType: ConsultationTypeName
  consultantType: string
  selectedDate: string
  selectedWeek: {
    start: string
    end: string
  }
  selectedClinicId: string
  selectedClinicianId?: string
  serviceTypeUuid?: string
  slotDuration: number
  timezoneId: string
  appointmentFeatures: string
  consentExpired?: boolean
}

const AvailabilitySlots = ({
  memberId,
  clinicList,
  consultationType,
  consultantType,
  selectedClinicId,
  selectedClinicianId,
  selectedDate,
  selectedWeek,
  serviceTypeUuid,
  slotDuration,
  timezoneId,
  appointmentFeatures,
  consentExpired,
}: Props) => {
  const { loading, error, data } = useQuery(GetAvailabilitySlotsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      appointmentSlotsRequest: {
        memberId,
        duration: slotDuration,
        startDate: selectedWeek.start,
        endDate: selectedWeek.end,
        clinicId: selectedClinicId,
        consultationType,
        consultantId: selectedClinicianId,
        consultantType,
        serviceTypeUuid,
        appointmentFeatures,
        include: 'consultants_availability',
      },
    },
  })

  const slots = data?.appointmentSlotsV2?.appointmentSlots

  const isPhysical = isPhysicalConsultation(consultationType)
  const isGroupedByClinics = isPhysical && selectedClinicId === 'any'

  const groupedSlots = isGroupedByClinics
    ? groupSlotsByClinic(
        slots,
        selectedDate,
        clinicList,
        selectedClinicianId !== 'any' ? selectedClinicianId : undefined
      )
    : groupSlotsByDay(
        slots,
        selectedWeek.start,
        selectedWeek.end,
        buildClinicIndex(clinicList)?.[selectedClinicId]?.timezone_id ||
          timezoneId,
        selectedClinicId !== 'any' ? selectedClinicId : undefined,
        selectedClinicianId !== 'any' ? selectedClinicianId : undefined
      )

  return (
    <>
      <LoadingContainer loading={loading} fill>
        {!!groupedSlots.times.length && (
          <AvailabilitySlotsGrid
            loading={loading}
            slotDuration={slotDuration}
            groupedSlots={groupedSlots}
            clinicList={clinicList}
            consentExpired={consentExpired}
          />
        )}
        {groupedSlots.times.length === 0 && !loading && (
          <div className={styles.gridEmpty}>
            <Text tag="div" align="center">
              <FontAwesomeIcon size="3x" icon={faCalendarTimes} />
            </Text>
            <Heading level="h2" align="center">
              There are no time slots available
            </Heading>
            <Text tag="div" align="center">
              Please choose a different date
            </Text>
          </div>
        )}
      </LoadingContainer>
      {error && <UnexpectedError message={error.message} visible />}
    </>
  )
}

export default AvailabilitySlots
