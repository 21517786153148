import { defineMessages } from 'react-intl'

const baseId = 'members_search.members_search_view'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Search for a patient',
  },
  invalid_id: {
    id: `${baseId}.errors.invalid_id`,
    defaultMessage: 'Please enter a valid ID',
  },
  number_too_large: {
    id: `${baseId}.errors.number_too_large`,
    defaultMessage: 'Please enter a number smaller than {limit}',
  },
  missing_filter: {
    id: `${baseId}.errors.missing_filter`,
    defaultMessage:
      'Search incomplete. Please enter at least one piece of information',
  },
})
