import React, { ReactNode, useState } from 'react'
import { Formik, Form } from 'formik'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useProductConfig } from '@babylon/product-config'
import { envFlag } from '@babylon/babylon-env'
import { ClientCompatibleFlows } from '@babylon/graphql-middleware-types'
import { ConsultationTypeName } from '../types/ConsultationTypes'
import { AppointmentFields } from '../AppointmentFields'
import CreateAppointmentMutation, {
  CreateAppointmentData,
} from './CreateAppointmentMutation'
import AppointmentConfirmationDialog from '../AppointmentConfirmationDialog'
import AppointmentAvailabilityValidation from './AppointmentAvailabilityValidation'
import { UnexpectedError } from '../Utils'

interface Props {
  appointmentClinic: string
  appointmentClinicList: any
  children: (FormikProps) => ReactNode
  consultant: string
  consultantType: string
  consultationType: ConsultationTypeName
  inviteId: string
  filters: AppointmentFields | null
  memberId: string
  isChaperoneRequired: boolean
  recordingConsent: boolean
  serviceTypeUuid: string
  selectedSlot: any
  slotDuration: number
  selectedDate: Date
  selectedWeek: { start: Date; end: Date; days: Date[] }
  consultationTimezone: string | null
}

export interface ConfirmedAppointment {
  appointmentId: string
  member: {
    id: string
  }
  appointmentDate: string
  consultationType?: ConsultationTypeName
  consultant: {
    name: string
    gender: string
  }
  clinicId: {
    id: string
  }
  clinicLocation: Practice
  service_type_uuid: string
  timezone_id: Pick<Props, 'consultationTimezone'>
}

interface AppointmentAvailabilityData extends CreateAppointmentData {
  appointmentClinic: string
  language_required: boolean
  payment_required: boolean
}

const AppointmentAvailabilityForm = ({
  appointmentClinic,
  appointmentClinicList,
  children,
  consultant,
  consultantType,
  consultationType,
  filters,
  inviteId,
  isChaperoneRequired,
  memberId,
  recordingConsent,
  selectedSlot,
  serviceTypeUuid,
  slotDuration,
  selectedWeek,
  selectedDate,
  consultationTimezone,
}: Props) => {
  const { getProp } = useProductConfig()
  const paymentRequiredCheckboxEnabled = getProp(
    'clinicalCare',
    'appointments.adminPortal.paymentRequiredCheckboxEnabled'
  )

  const bookWithServiceTypesEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'bookWithServiceTypesEnabled'
  )

  const history = useHistory()
  const bookAppointmentCheckInEnabled = !!envFlag(
    'ENABLE_BOOK_APPOINTMENT_CHECK_IN'
  )

  const [
    confirmedAppointment,
    setConfirmedAppointment,
  ] = useState<ConfirmedAppointment | null>(null)
  const [createAppointment, { error }] = useMutation(
    CreateAppointmentMutation,
    {
      onCompleted: ({ bookAppointmentWithMicroservice }) => {
        const { clinicId } = bookAppointmentWithMicroservice
        const data = {
          ...bookAppointmentWithMicroservice,
          clinicLocation:
            appointmentClinicList.find((clinic) => clinic.id === clinicId.id) ||
            {},
        }

        if (!data.timezone_id) {
          data.timezone_id =
            consultationTimezone ||
            Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        setConfirmedAppointment(data)
      },
      onError: () => {},
    }
  )

  const handleSubmit = async (
    values: AppointmentAvailabilityData,
    { resetForm }
  ) => {
    const { appointment_features, service_types, service_type_uuid } = values

    const input = {
      appointment_features:
        bookWithServiceTypesEnabled && appointment_features
          ? [appointment_features?.toLowerCase()]
          : undefined,
      appointment_invite_id: values.appointment_invite_id,
      appointment_reason: values.appointment_reason,
      clinic_id: values.clinic_id,
      consultant_id: values.consultant_id,
      consultant_type: values.consultant_type,
      consultation_type: values.consultation_type,
      timezone_id: values.timezone_id,
      member_id: values.member_id,
      preferred_date_time: values.preferred_date_time,
      recording_consent: values.recording_consent,
      duration_minutes: values.duration_minutes,
      language_requested: null,
      client_compatible_flows: bookAppointmentCheckInEnabled
        ? [ClientCompatibleFlows.CheckIn]
        : undefined,
      service_type_uuid:
        bookWithServiceTypesEnabled && appointment_features
          ? undefined
          : service_type_uuid,
      service_types:
        bookWithServiceTypesEnabled && appointment_features
          ? service_types
          : undefined,
      ...(paymentRequiredCheckboxEnabled && {
        needs_payment: values.payment_required,
      }),
    }

    if (values.clinic_id === 'any') {
      input.clinic_id = null
    }

    if (values.consultant_id === 'any') {
      input.consultant_id = null
    }

    if (
      !envFlag('ENABLE_APPOINTMENT_TIMEZONE') ||
      (envFlag('ENABLE_APPOINTMENT_TIMEZONE') &&
        values.consultation_type === 'physical')
    ) {
      input.timezone_id = null
    }

    if (values.language_required && values.language_requested) {
      input.language_requested = values.language_requested
    }

    await createAppointment({ variables: { input } })

    resetForm()
  }

  const goToMemberPage = (id: string): void => {
    history.push(`/admin/patients/${id}/memberships`)
  }

  return (
    <Formik
      initialValues={{
        appointment_features: filters?.appointment_features,
        appointment_invite_id: inviteId,
        appointment_reason: filters?.appointment_reason,
        chaperone_reason: '',
        chaperone_required:
          consultationType === 'physical' && isChaperoneRequired,
        clinic_id: appointmentClinic,
        consultant_id: consultant,
        appointmentClinic,
        consultant_type: consultantType,
        consultation_type: consultationType,
        duration_minutes: slotDuration,
        language_required: false,
        language_requested: '',
        member_id: parseInt(memberId, 10),
        payment_required: true,
        preferred_date_time: selectedSlot?.UTCTimestamp,
        recording_consent: recordingConsent,
        service_type_uuid: serviceTypeUuid,
        service_types: serviceTypeUuid ? [serviceTypeUuid] : undefined,
        timezone_id: consultationTimezone,
        selected_date: selectedDate,
        selected_week: selectedWeek,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={AppointmentAvailabilityValidation}
    >
      <Form>
        {children}
        {confirmedAppointment && (
          <AppointmentConfirmationDialog
            active
            onClose={() => {
              setConfirmedAppointment(null)

              if (confirmedAppointment?.member) {
                goToMemberPage(confirmedAppointment.member.id)
              }
            }}
            clinic={confirmedAppointment?.clinicLocation}
            scheduledTime={confirmedAppointment?.appointmentDate}
            appointmentId={confirmedAppointment?.appointmentId}
            consultant={confirmedAppointment?.consultant}
            duration={slotDuration}
            medium={confirmedAppointment?.consultationType}
            serviceTypeUuid={confirmedAppointment?.service_type_uuid}
            timezoneId={confirmedAppointment?.timezone_id}
          />
        )}
        {error && <UnexpectedError message={error.message} visible />}
      </Form>
    </Formik>
  )
}

export default AppointmentAvailabilityForm
