import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  Stack,
  Accordion,
  AccordionSummary,
  Button,
  TextField,
  AccordionDetails,
  MenuItem,
  Modal,
} from '@mui/material'
import { useFormatMessage } from '@babylon/intl'
import { useHistory } from 'react-router'
import { DocumentNode, FieldNode, OperationDefinitionNode } from 'graphql'
import { useQuery } from '@apollo/client'
import { PageErrorMessage } from '@babylon/cce-shared-components'
import { EditModal } from './EditModal'
import Icon from '@/components/Icon'
import { messages } from './messages'
import styles from './styles.module.scss'
import {
  Action,
  ActionType,
  ColumnDefinition,
  ColumnsVisibility,
  DataGrid,
  PaginationState,
  RowData,
} from '../DataGrid'
import { Connection } from '@/gql/graphql'

import { EditObjectConfig, FilterOption } from './types'
import { DeleteObjectModal } from './DeleteObjectModal'

type Props = {
  /* Unique ID of the table */
  id: String

  /* Columns definition */
  columns: ColumnDefinition[]

  /* Initial columns to show. All other data should be hidden */
  initialColumnsToShow?: ColumnsVisibility

  /* Function to format the filters before sending them to the server */
  filterFormatter?: (filters: Record<string, string>) => any

  /* Function to format the rows before displaying them */
  rowFormatter: (data: Connection) => RowData[]

  /* Filter options to display in the filter accordion */
  filterOptions?: FilterOption[]

  /* The graphql query to use to fetch the data */
  query: DocumentNode

  /* The default sort to use in the query */
  defaultSort?: Record<string, string>

  /* The default filter to use in the query */
  defaultFilter?: Record<string, any>

  /* The default page size to use */
  defaultPageSize?: number

  /* Config for the edit modal. If provided it will enable the edit icon on the rows */
  editConfig?: EditObjectConfig

  /* Config for the add modal. If provided it will enable the add button at the top */
  addConfig?: EditObjectConfig

  /* The label to use for the object type, e.g. Pharmacists. This will appear at the top of the page */
  objectTypeLabel?: string

  /* Temporary while we migrate actions from old pages */
  editUrl?: string

  /* Temporary while we migrate actions from old pages */
  addUrl?: string

  /* Delete url */
  deleteMutation?: DocumentNode

  /* enable users to select the row to be routed to the detail page (N.B. this route will need to be configured in the AppRouting file */
  enableRowSelect?: boolean
}

export const CoreDataTable: React.FC<Props> = ({
  id,
  columns,
  initialColumnsToShow,
  filterOptions,
  query,
  objectTypeLabel,
  filterFormatter,
  rowFormatter,
  defaultSort,
  defaultFilter,
  defaultPageSize,
  editConfig,
  addConfig,
  editUrl,
  addUrl,
  deleteMutation,
  enableRowSelect,
}) => {
  const [filters, setFilters] = useState<Record<string, string>>({})
  const [appliedFilters, setAppliedFilters] = useState()
  const [endCursorStack, setEndCursorStack] = useState<string[]>([])
  const [gqlData, setGqlData] = useState<Connection>()
  const [openModal, setOpenModal] = useState(false)
  const [objectToEdit, setObjectToEdit] = useState<any>()
  const [idToDelete, setIdToDelete] = useState<string>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [editConfigToUse, setEditConfigToUse] = useState<
    EditObjectConfig | undefined
  >(editConfig ? { ...editConfig } : undefined)
  const [paginationState, setPaginationState] = useState({
    pageSize: defaultPageSize || 25,
    page: 0,
  })
  const fm = useFormatMessage()
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const { loading, error, data, refetch } = useQuery<any>(query, {
    variables: {
      first: paginationState.pageSize,
      sort: defaultSort,
      filter: appliedFilters || defaultFilter,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    context: {
      clientName: 'platform-gateway',
    },
  })

  const history = useHistory()

  const eventType = `TABLE_UPDATED_${id}`

  useEffect(() => {
    const eventHandler = () => refetch()
    window.addEventListener(eventType, eventHandler)

    return () => {
      window.removeEventListener(eventType, eventHandler)
    }
  })

  const getQueryName = useCallback(() => {
    const firstDefinition = query.definitions[0] as
      | OperationDefinitionNode
      | undefined

    if (firstDefinition) {
      const selection = firstDefinition.selectionSet?.selections?.find(
        (node) => node.kind === 'Field'
      ) as FieldNode | undefined

      if (selection) {
        return selection.name.value
      }
    }
  }, [query])

  useEffect(() => {
    setPaginationState({
      page: 0,
      pageSize: paginationState.pageSize,
    })
    setEndCursorStack([])
    refetch({
      after: null,
    })
  }, [paginationState.pageSize, refetch])

  useEffect(() => {
    if (objectToEdit) {
      setOpenModal(true)
    } else {
      setOpenModal(false)
    }
  }, [objectToEdit])

  useEffect(() => {
    const queryName = getQueryName()
    if (data && queryName) {
      setGqlData(data[queryName])
    }
  }, [data, getQueryName])

  const filterResults = () => {
    const formattedFilters = filterFormatter
      ? filterFormatter(filters) || {}
      : filters
    setAppliedFilters(formattedFilters)
    setPaginationState({
      page: 0,
      pageSize: paginationState.pageSize,
    })
    setEndCursorStack([])
    refetch({
      first: paginationState.pageSize,
      after: null,
      filter: formattedFilters,
    })
  }

  const handlePagination = (newState: PaginationState) => {
    // Changed rows per page
    if (newState.page === paginationState.page) {
      setPaginationState({
        page: 0,
        pageSize: newState.pageSize,
      })
      setEndCursorStack([])

      refetch({
        first: newState.pageSize,
        after: null,
      })
    } else if (newState.page > paginationState.page) {
      // Next page
      setEndCursorStack([...endCursorStack, gqlData?.pageInfo?.endCursor])
      setPaginationState(newState)
      refetch({
        first: newState.pageSize,
        after: gqlData?.pageInfo?.endCursor,
        filter: appliedFilters,
      })
    } else {
      // Previous page
      setEndCursorStack(endCursorStack.slice(0, -1))
      setPaginationState(newState)
      refetch({
        first: newState.pageSize,
        after: endCursorStack[endCursorStack.length - 2],
        filter: appliedFilters,
      })
    }
  }

  const shouldShowAction = (action: Action) => {
    return (
      (action.type === ActionType.EDIT && (editConfig || editUrl)) ||
      (action.type === ActionType.DELETE && deleteMutation) ||
      (action.type === ActionType.VIEW && enableRowSelect)
    )
  }

  const actions: Action[] = [
    {
      text: fm(messages.edit),
      icon: <Icon data-testid="edit-icon" name="edit" className="" />,
      onClick: (row: any) => {
        if (editUrl) {
          history.push(`${editUrl}/${row.id}/edit`)
        } else {
          setEditConfigToUse(editConfig)
          setObjectToEdit(row)
        }
      },
      onlyShowInDropdownMenu: false,
      type: ActionType.EDIT,
    },
    {
      text: fm(messages.delete),
      icon: <Icon data-testid="delete-icon" name="delete" className="" />,
      onClick: (row: any) => {
        setIdToDelete(row.id)
        setShowDeleteModal(true)
      },
      onlyShowInDropdownMenu: false,
      type: ActionType.DELETE,
    },
    {
      icon: (
        <Icon
          data-testid="view-chevron-icon"
          name="chevron-right"
          className=""
        />
      ),
      text: fm(messages.view),
      onClick: (row: any) => {
        history.push(`${history.location.pathname}/${row.id}`)
      },
      onlyShowInDropdownMenu: false,
      type: ActionType.VIEW,
    },
  ].filter(shouldShowAction)

  if (error) {
    return (
      <div className={styles.dataTable}>
        <PageErrorMessage
          errorMessage={`Error while loading data: ${error.message}`}
        />
      </div>
    )
  }

  return (
    <div className={styles.fillContent}>
      <div className={styles.header}>
        <h1 className={styles.title}>{objectTypeLabel}</h1>

        {(addConfig || addUrl) && (
          <div className={styles.newButtonWrapper}>
            <Button
              onClick={() => {
                if (addUrl) {
                  history.push(`${addUrl}`)
                } else {
                  setObjectToEdit({})
                  setEditConfigToUse(addConfig)
                  setOpenModal(true)
                }
              }}
              data-testid="create-new-button"
            >
              {`Add new ${objectTypeLabel}`}
            </Button>
          </div>
        )}
      </div>
      {deleteMutation && showDeleteModal && idToDelete && (
        <DeleteObjectModal
          deleteMutation={deleteMutation}
          id={idToDelete}
          onComplete={() => {
            setShowDeleteModal(false)
            refetch()
          }}
          onClose={() => setShowDeleteModal(false)}
        />
      )}

      {objectToEdit && editConfigToUse && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <EditModal
            eventType={eventType}
            inputObject={objectToEdit}
            editConfig={editConfigToUse}
            closeModal={handleCloseModal}
            objectTypeLabel={objectTypeLabel}
          />
        </Modal>
      )}
      <Stack
        direction="row"
        spacing={1}
        sx={{ mb: 1 }}
        className={styles.fillContent}
      >
        <div data-testid="data-grid" className={styles.dataTable}>
          {filterOptions && (
            <Accordion expanded>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h4>{fm(messages.filter)}</h4>
              </AccordionSummary>
              <AccordionDetails className={styles.accordionDetails}>
                {filterOptions.map((filter: any) => (
                  <div
                    key={filter.name}
                    className={styles.filterInputContainer}
                  >
                    <TextField
                      select={!!filter.options?.length}
                      fullWidth
                      className={styles.textField}
                      label={filter.label}
                      data-testid={`input-${filter.name}`}
                      value={filters[filter.name] || ''}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newFilterValue = event.target.value
                        setFilters((prevState) => ({
                          ...prevState,
                          [filter.name]: newFilterValue,
                        }))
                      }}
                      sx={{ m: 1, width: '100%' }}
                    >
                      {filter.options &&
                        filter.options?.map((option: string) => {
                          return (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          )
                        })}
                    </TextField>
                  </div>
                ))}

                <Button
                  color="secondary"
                  onClick={() => {
                    setFilters({})
                    setAppliedFilters(undefined)
                  }} // Add onClick event handler
                >
                  {fm(messages.reset)}
                </Button>
                <Button
                  color="primary"
                  title="search-button"
                  onClick={() => {
                    filterResults()
                  }}
                >
                  {fm(messages.search)}
                </Button>
              </AccordionDetails>
            </Accordion>
          )}
          {gqlData && (
            <DataGrid
              data-testid="data-grid"
              dimensions={{ dynamic: true }}
              pagination={{
                mode: 'server',
                rowCount: gqlData?.totalCount || 0,
                pageSizeOptions: [10, 25, 50],
                initialPageSize: 25,
                paginationState,
                handlePagination,
              }}
              initialColumnsToShow={initialColumnsToShow}
              rows={loading ? [] : rowFormatter(gqlData)}
              columns={columns}
              actions={actions}
              loading={loading}
              rowsSettings={{ dynamicHeight: true }}
            />
          )}
        </div>
      </Stack>
    </div>
  )
}
