import React, { useEffect, ReactNode, Ref } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import cx from 'classnames'
import styles from './styles.module.scss'

export interface PopupDialogProps {
  children: ReactNode
  toggleVisible: () => any
  visible: boolean
  appear?: boolean
  disableCloseOnClickOut?: boolean
  className?: string
}

const PopupDialog = React.forwardRef(
  (
    {
      children,
      toggleVisible,
      visible,
      appear,
      disableCloseOnClickOut,
      className,
    }: PopupDialogProps,
    ref: Ref<HTMLDivElement>
  ) => {
    useEffect(() => {
      if (visible) document.body.classList.add('modalOpen')
      else document.body.classList.remove('modalOpen')

      return () => document.body.classList.remove('modalOpen')
    }, [visible])

    const rootElement = document.getElementById('root')

    if (rootElement) {
      return ReactDOM.createPortal(
        <div
          role="button"
          tabIndex={0}
          onKeyPress={() => null}
          className={cx({
            [styles.modalOverlay]: visible,
          })}
          onClick={() => {
            if (visible && disableCloseOnClickOut) {
              // dialog is open and we don't want it to close when clicking out
              return
            }

            toggleVisible()
            document.body.classList.add('modalOpen')
          }}
        >
          <CSSTransition
            in={visible}
            timeout={300}
            classNames={styles.popup}
            key="popup-dialog"
            appear={appear}
          >
            <div
              role="button"
              tabIndex={0}
              data-testid="popup-dialog"
              onKeyPress={() => null}
              ref={ref}
              className={cx(styles.popup, className)}
              onClick={(e) => e.stopPropagation()}
            >
              {visible && children}
            </div>
          </CSSTransition>
        </div>,
        rootElement
      )
    }

    return null
  }
)

export default PopupDialog
