import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
import {
  Text,
  Card,
  ButtonSimple,
  ButtonVariant,
  Textarea,
  Seperator,
  Grid,
} from '@babylon/medkit'
import { AppointmentFilled } from '@babylon/icons'

import DurationSelector from './DurationSelector'
import ClinicianSelection from './ClinicianSelection'
import { useHandleReset, OTHER_SERVICE_TYPE } from './utils'

import ArrangeAppointmentForm from './ArrangeAppointmentTypes'
import MediumSelection from './MediumSelection'

import { LoadingContainer } from '../../components'
import { UnexpectedError } from '../../Utils'
import { useFormikError } from '../../hooks'

import styles from './Recommendation.module.css'
import { baseValues } from './ArrangeAppointment'

interface RecommendationProps {
  onPreviousStep?: () => void
  onNextStep?: (validFn?: () => void, failFn?: () => void) => void
  enrichedWithServiceType: ArrangeAppointmentForm
  consultantOptionsLoading?: boolean
  memberId: string | number
  consultantOptions?: {
    value: string
    label: string
  }[]
}

export default function Recommendation({
  onPreviousStep,
  onNextStep,
  enrichedWithServiceType,
  consultantOptions,
  memberId,
  consultantOptionsLoading = false,
}: RecommendationProps) {
  const {
    values,
    setValues,
    setFieldValue,
    resetForm,
  } = useFormikContext<ArrangeAppointmentForm>()

  const history = useHistory()

  const {
    allowed_mediums: allowedMediums,
    intimate,
    notes_for_member: notesForMember,
    service_type_name: serviceTypeName,
  } = values

  const {
    errorMessage,
    setAllErrorMessage,
    clearErrorMessage,
  } = useFormikError()

  const intimacy = !!intimate

  useEffect(() => {
    setFieldValue('chaperone_required', intimacy)
  }, [intimacy, setFieldValue])

  const handleSave = () => {
    if (onNextStep) {
      onNextStep(undefined, setAllErrorMessage)
    }
  }

  const handlePrevStep = () => {
    history.replace(`/admin/patients/${memberId}/book-appointment`, {})

    resetForm({
      values: baseValues,
    })

    if (onPreviousStep) {
      onPreviousStep()
    }
  }

  const handleReset = useHandleReset({
    values,
    enrichedWithServiceType,
    setValues,
  })

  const isOtherServiceType =
    OTHER_SERVICE_TYPE.uuid === values?.service_type_uuid

  return (
    <>
      <UnexpectedError
        visible={!!errorMessage.length}
        onClose={clearErrorMessage}
      >
        {errorMessage.map((error) => (
          <div key={error}>{error}</div>
        ))}
      </UnexpectedError>
      <Text variant="body">Recommendation:</Text>
      <Card vertical>
        <LoadingContainer loading={consultantOptionsLoading}>
          <Grid container>
            <Grid item>
              <AppointmentFilled title="title" />
            </Grid>
            <Grid item>
              <Text
                className={styles.AppointmentTypeName}
                variant="h5"
                tag="span"
              >
                {serviceTypeName}
              </Text>
            </Grid>
          </Grid>
          <Seperator />
          <Textarea
            name="notes_for_member"
            id="notes_for_member"
            label="Reason for appointment"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setFieldValue('notes_for_member', e.target.value)
            }
            value={notesForMember}
          />
          {!consultantOptionsLoading &&
            consultantOptions &&
            isOtherServiceType && (
              <ClinicianSelection consultantOptions={consultantOptions} />
            )}
          <MediumSelection
            options={enrichedWithServiceType?.allowed_mediums}
            selectedMediums={allowedMediums || []}
            onChange={(selectedAllowedMediums) => {
              setFieldValue('allowed_mediums', selectedAllowedMediums)

              if (!enrichedWithServiceType?.preferred_medium) {
                setFieldValue('preferred_medium', selectedAllowedMediums[0])

                return
              }

              const selectPreferredMedium = selectedAllowedMediums.includes(
                enrichedWithServiceType.preferred_medium
              )
                ? enrichedWithServiceType.preferred_medium
                : selectedAllowedMediums[0]
              setFieldValue('preferred_medium', selectPreferredMedium)
            }}
          />
          <Grid container>
            <Grid item>
              <DurationSelector
                className={styles.DurationDropdown}
                defaultDuration={enrichedWithServiceType?.duration_minutes}
                recommendDuration={
                  !!enrichedWithServiceType?.duration_minutes &&
                  !isOtherServiceType
                }
              />
            </Grid>
          </Grid>
        </LoadingContainer>
      </Card>
      <div className={styles.buttonRow}>
        <ButtonSimple
          onClick={handlePrevStep}
          variant={ButtonVariant.secondary}
        >
          Back
        </ButtonSimple>
        <ButtonSimple onClick={handleReset} variant={ButtonVariant.secondary}>
          Reset
        </ButtonSimple>
        <ButtonSimple onClick={handleSave}>Save</ButtonSimple>
      </div>
    </>
  )
}
