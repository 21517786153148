import React from 'react'
import { confirmable, createConfirmation } from 'react-confirm'
import { withHandlers } from 'recompose'
import {
  Form,
  Textarea,
  Button,
  withStore,
  compose,
} from '@babylon/babylon-forms/forms'
import { Dialog } from '@babylon/babylon-forms/widgets'
import cx from 'classnames'

import { ModalOverlay } from '@babylon/member-operations'

import styles from './styles.module.scss'

const withFormStore = withStore({
  comment: '',
  alert: false,
})

const withActions = withHandlers({
  handleSubmit: ({ proceed, store }) => ({ comment }) => {
    if (comment.trim().split(/[\s\t]+/).length < 2) {
      store.setState({ alert: true })
    } else {
      proceed(comment.trim())
    }
  },
})

const CommentDialog = ({ store, cancel, handleSubmit }) => (
  <ModalOverlay visible onRequestClose={cancel}>
    <Dialog
      className={cx(styles.commentDialog, {
        [styles.alert]: store.state.alert,
      })}
    >
      <p>Please add comment (min 2 words)</p>
      <Form store={store} onSubmit={handleSubmit}>
        <Textarea name="comment" rows="3" />
        <div className={styles.buttonRow}>
          <Button label="Add comment" />
          <Button type="button" outline label="Cancel" onClick={cancel} />
        </div>
      </Form>
    </Dialog>
  </ModalOverlay>
)

const enhance = compose(withFormStore, withActions)

const dialog = confirmable(enhance(CommentDialog))
const confirm = createConfirmation(dialog, 1)

export default confirm
