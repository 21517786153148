import React from 'react'

import { Heading, Text, Tooltip } from '@babylon/core-ui'

interface Props {
  label: string
  value?: string
  tooltipText?: string
}

const ServiceTypeField = ({ label, value, tooltipText }: Props) =>
  !value ? null : (
    <>
      <Heading level="h3" color="light-grey-type">
        {label}:{' '}
        {tooltipText && (
          <Tooltip
            info={tooltipText}
            placement="right"
            overlayStyle={{ width: 300 }}
          />
        )}
      </Heading>
      <Text tag="p">{value}</Text>
    </>
  )

export default ServiceTypeField
