import { gql } from '@apollo/client'

export default gql`
  query AppointmentInvites(
    $member_uuid: ID!
    $including_digital: Boolean
    $unused: Boolean
  ) {
    appointmentInvites(
      filter: {
        member_uuid: $member_uuid
        including_digital: $including_digital
        unused: $unused
      }
    ) {
      id
      consultation_type
      preferred_medium
      allowed_mediums
      created_at
      notes_for_member
      duration_minutes
      service_type {
        id
        name
        member_instructions
      }
    }
  }
`
