import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import { logException } from '@babylon/sentry'

import { getConversationUser, questionWithError } from '../api'
import Chatbot from './chatbot'
import CardContent from '../components/CardContent'
import { setConversationId } from '../redux/actions'
import { trackConversationStarted } from '../util/tracking/events'
import messages from './messages'

const DELAY_TIME = 400

export default ({
  initialQuestion,
  answerProcessors,
  actionHandler,
  translate,
}) => (
  InputComponent,
  chatbot = Chatbot(answerProcessors, initialQuestion, {}, translate)
) => {
  const ChatbotProvider = (props) => {
    const [conversation, setConversation] = useState(chatbot.conversation)
    const [didGoBack, setDidGoBack] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const dispatchConversationId = useCallback(
      (id) => dispatch(setConversationId(id)),
      [dispatch]
    )

    const conversationId = get(
      conversation,
      'nextQuestion.conversationContext.conversationId'
    )
    const memberUuid = get(
      conversation,
      'nextQuestion.conversationContext.memberUuid'
    )
    const userUuid = get(
      conversation,
      'nextQuestion.conversationContext.userUuid'
    )

    useEffect(() => {
      if (
        conversationId &&
        !userUuid &&
        process.env?.REACT_APP_APP_ID !== 'rwanda_web'
      ) {
        getConversationUser({ conversationId, memberUuid })
          .then(({ user }) => {
            window.globalTrackingProps = {
              patient_uuid: user.uuid,
            }
            trackConversationStarted(conversationId)

            // Add conversation user UUID to context
            setConversation(
              conversation,
              (conversation.nextQuestion.conversationContext.userUuid =
                user.uuid)
            )
          })
          .catch((error) => {
            logException(error)
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationId, memberUuid, setConversation, userUuid])

    // conversationId is only dispatched when the conversation changes. E.g. goes to null when
    // we are in feedback modal. On back the conversationId changes from null and so the
    // dispatch is fired.

    useEffect(() => {
      if (conversationId) {
        dispatchConversationId(conversationId)
      }
    }, [conversationId, dispatchConversationId])

    function answerQuestion(answerValue) {
      if (!loading) {
        const startCallTime = new Date()
        let endCallTime
        let delayChangeView
        setLoading(true)

        chatbot.answer({ answerValue, translate }).then(() => {
          endCallTime = new Date()
          delayChangeView = endCallTime - startCallTime < DELAY_TIME

          if (delayChangeView) {
            setTimeout(() => {
              setConversationState()
            }, DELAY_TIME)
          } else {
            setConversationState()
          }
        })
      }
    }

    function setConversationState() {
      setDidGoBack(false)
      setConversation(chatbot.conversation)
      setLoading(false)
    }

    function goBack() {
      chatbot.back().then(() => {
        setDidGoBack(true)
        setConversation(chatbot.conversation)
        setLoading(false)
      })
    }

    function goToStart() {
      chatbot.reset().then(() => {
        setConversationState()
      })
    }

    function goToNewConversation(type, data) {
      setLoading(true)
      chatbot
        .startNewConversation(type, data)
        .then(() => {
          setConversationState()
        })
        .catch(() => {
          setConversation({
            ...chatbot.conversation,
            nextQuestion: questionWithError(
              chatbot.conversation.nextQuestion,
              translate(messages.chatbotErrorMessage)
            ),
          })
          setLoading(false)
        })
    }

    function goToQuestion(question, ...args) {
      setLoading(true)
      chatbot
        .goToQuestion(question, ...args)
        .then(() => {
          setConversationState()
        })
        .catch(() => {
          setConversation({
            ...chatbot.conversation,
            nextQuestion: questionWithError(
              chatbot.conversation.nextQuestion,
              'Oops, something went wrong.'
            ),
          })
          setLoading(false)
        })
    }

    const { feedbackModalData } = conversation.nextQuestion

    return (
      <>
        <InputComponent
          {...props}
          actionHandler={actionHandler}
          answeredQuestions={conversation.answeredQuestions}
          context={conversation.nextQuestion.conversationContext}
          didGoBack={didGoBack}
          goBack={goBack}
          goToQuestion={goToQuestion}
          goToNewConversation={goToNewConversation}
          goToStart={goToStart}
          loading={loading}
          LogoComponent={props.LogoComponent}
          question={conversation.nextQuestion.question}
          setLoading={setLoading}
          submitAnswer={answerQuestion}
        />
        {feedbackModalData && (
          <CardContent
            callToActionText={feedbackModalData.dismiss_text}
            IconAnimationData={feedbackModalData.animation}
            title={feedbackModalData.title}
            content={feedbackModalData.description}
          />
        )}
      </>
    )
  }

  return ChatbotProvider
}
