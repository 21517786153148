import { useState } from 'react'

import { UseSelectionControl, ISelectionControlOption } from '../form.types'

const useMultiSelectionControl: UseSelectionControl = (
  options,
  onChange,
  isControlledComponent = false
) => {
  const [selectOptions, setSelectOptions] = useState(options)

  const currentOptions = isControlledComponent ? [...options] : selectOptions

  const onOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedSelectOptions = currentOptions.map((opt) => {
      if (opt.value === event.target.value) {
        opt.selected = !opt.selected
      } else {
        opt.selected = !!opt.selected
      }

      return opt
    })

    if (!isControlledComponent) {
      setSelectOptions(changedSelectOptions)
    }

    if (onChange) {
      const selectedOptions = currentOptions
        .filter((option: ISelectionControlOption) => option.selected)
        .map((option: ISelectionControlOption) => option.value)

      onChange(selectedOptions)
    }
  }

  return { selectOptions: currentOptions, onOptionChange }
}

export default useMultiSelectionControl
