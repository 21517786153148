import { defineMessages } from 'react-intl'

const baseId = 'wait_time_configs_save_dialog'

export default defineMessages({
  createWaitTimeThresholdTitle: {
    id: `${baseId}.create_wait_time_threhsold_title`,
    defaultMessage: 'Create threshold',
  },
  updateWaitTimeThresholdTitle: {
    id: `${baseId}.update_wait_time_threhsold_title`,
    defaultMessage: 'Update threshold',
  },
  consumerNetworkServiceTypeFormTitle: {
    id: `${baseId}.consumer_network_service_type_form_title`,
    defaultMessage:
      'Choose the Consumer Network and Service Type you want to define waiting times thresholds for.',
  },
  waitTimeThresholdsFormTitle: {
    id: `${baseId}.wait_time_thresholds_form_title`,
    defaultMessage:
      'Define the minimum waiting time per each state in (Days)d/(Hours)h/(Mins)m format (e.g. 10d/12h/59m).',
  },
  saveButtonTitle: {
    id: `${baseId}.save_button_title`,
    defaultMessage: 'Save',
  },
  cancelButtonTitle: {
    id: `${baseId}.cancel_button_title`,
    defaultMessage: 'Cancel',
  },
  criticalTextFieldTitle: {
    id: `${baseId}.critical_text_field_title`,
    defaultMessage: 'Critical',
  },
  overloadedTextFieldTitle: {
    id: `${baseId}.overloaded_text_field_title`,
    defaultMessage: 'Overloaded',
  },
  busyTextFieldTitle: {
    id: `${baseId}.busy_text_field_title`,
    defaultMessage: 'Busy',
  },
  waitTimeThresholdTextFieldErrorMessage: {
    id: `${baseId}.wait_time_threshold_text_field_error_message`,
    defaultMessage: 'Incorrect wait time threshold format.',
  },
  invalidConsumerNetworkErrorMessage: {
    id: `${baseId}.invalid_consumer_network_error_message`,
    defaultMessage: 'Please choose one consumer network *',
  },
  invalidServiceTypeErrorMessage: {
    id: `${baseId}.invalid_service_type_error_message`,
    defaultMessage: 'Please choose one or no service type *',
  },
  invalidCriticalWaitTimeThresholdErrorMessage: {
    id: `${baseId}.invalid_critical_wait_time_threshold_error_message`,
    defaultMessage:
      'Please provide critical wait time threshold in (Days)d/(Hours)h/(Mins)m format *',
  },
  invalidOverloadedWaitTimeThresholdErrorMessage: {
    id: `${baseId}.invalid_overloaded_wait_time_threshold_error_message`,
    defaultMessage:
      'Please provide overloaded wait time threshold in (Days)d/(Hours)h/(Mins)m format *',
  },
  invalidBusyWaitTimeThresholdErrorMessage: {
    id: `${baseId}.invalid_busy_wait_time_threshold_error_message`,
    defaultMessage:
      'Please provide busy wait time threshold in (Days)d/(Hours)h/(Mins)m format *',
  },
  invalidWaitTimeThresholdsErrorMessage: {
    id: `${baseId}.invalid_wait_time_threhsolds_error_message`,
    defaultMessage:
      'Wait time thresholds must be greater than 0 and critical > overloaded > busy *',
  },
})
