import { ApolloClient, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { create } from 'zustand'
import {
  PORTAL_LOGIN_PROFILE_QUERY,
  PortalLoginProfileQueryResponse,
} from '../pages/LiveConversationPage/queries'

const inProgress = Symbol('loading agent')

type Agent = string | typeof inProgress

interface KnownAgentsStore {
  knownAgents: Map<string, Agent>
  addAgent: (agentId: string, agent: Agent) => void
  clearAgent: (agentId) => void
  fetchAgent: (agentId: string, client: ApolloClient<Object>) => Promise<void>
}

const AGENT_NAME_FETCH_RETRY_INTERVAL_MS = 60_000

const useKnownAgentsStore = create<KnownAgentsStore>((set, get) => ({
  knownAgents: new Map<string, string | typeof inProgress>(),
  addAgent: (agentId, agentName) => {
    set({
      knownAgents: new Map(get().knownAgents).set(agentId, agentName),
    })
  },
  clearAgent: (agentId) => {
    const newKnownAgents = new Map(get().knownAgents)
    newKnownAgents.delete(agentId)
    set({ knownAgents: newKnownAgents })
  },
  fetchAgent: async (agentId: string, client: ApolloClient<Object>) => {
    const state = get()

    if (state.knownAgents.has(agentId)) {
      return
    }

    get().addAgent(agentId, inProgress)

    try {
      const result = await client.query<PortalLoginProfileQueryResponse>({
        query: PORTAL_LOGIN_PROFILE_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          id: agentId,
        },
      })

      if (result.error) {
        get().addAgent(agentId, "Couldn't load agent name")
        setTimeout(() => {
          get().clearAgent(agentId)
        }, AGENT_NAME_FETCH_RETRY_INTERVAL_MS)
      } else {
        get().addAgent(agentId, result.data.portalUser.fullName)
      }
    } catch (e) {
      console.error('failed to load agent name', e)
      get().addAgent(agentId, "Couldn't load agent name")
      setTimeout(() => {
        get().clearAgent(agentId)
      }, AGENT_NAME_FETCH_RETRY_INTERVAL_MS)
    }
  },
}))

export const useLazyAgentName = ({
  noAgent = 'no agent joined',
  loading = 'loading...',
  virtualAgent = 'Virtual Agent',
} = {}) => {
  const fetchAgent = useKnownAgentsStore(({ fetchAgent: fetch }) => fetch)
  const client = useApolloClient()
  const agentNames = useKnownAgentsStore(({ knownAgents }) => knownAgents)

  return useCallback(
    (agentId: string | undefined, type: 'withProfile' | 'bot' | undefined) => {
      if (agentId === undefined) {
        return noAgent
      }

      if (type === undefined) {
        return loading
      }

      if (type === 'bot') {
        return virtualAgent
      }

      const agentName = agentNames.get(agentId)

      if (agentName === undefined) {
        fetchAgent(agentId, client)

        return loading
      }

      if (agentName === inProgress) {
        return loading
      }

      return agentName
    },
    [agentNames, client, fetchAgent, loading, noAgent, virtualAgent]
  )
}
