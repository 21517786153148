import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client'
import { useProductConfig } from '@babylon/product-config'
import { GetSupportInteraction } from '../queries'
import useParseSupportInteraction from './useParseSupportInteraction'

interface SupportInteractionParams {
  transactionId: string
  transactionType: string
}

interface SupportInteraction extends SupportInteractionParams {
  email?: string
  phoneNumber?: string
}

interface SupportInteractionResult {
  supportInteraction: SupportInteraction
}

export default function useSupportInteraction(
  options: QueryHookOptions<SupportInteractionResult, SupportInteractionParams>
): QueryResult<SupportInteractionResult, SupportInteractionParams> {
  const { getProp } = useProductConfig()

  // `getProp` is not `null-safe` despite it's return signature
  // TODO: fix this in `@babylon/product-config` if possible
  const supportInteractionsEnabled =
    getProp('memberOperations', 'supportInteractionsEnabled') ?? false

  const supportInteraction = useParseSupportInteraction()

  // Skip if the flag is disabled *or* the support interaction query string is null
  const skipQuery =
    supportInteractionsEnabled === false || supportInteraction === null

  const queryOptions: QueryHookOptions<
    SupportInteractionResult,
    SupportInteractionParams
  > = {
    skip: skipQuery,
    ...options,
  }

  if (supportInteraction !== null) {
    queryOptions.variables = supportInteraction
  }

  return useQuery(GetSupportInteraction, queryOptions)
}
