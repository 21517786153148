import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import AdminPortalFrame from '@babylon/admin-portal-frame'
import { useTrackView } from '@babylon/tracking/react'
import PopupDialog from '../PopupDialogDeprecated'
import { usePrevious } from '../../hooks'

const scrollTo = (scrollOpts: ScrollToOptions) => {
  try {
    window.scrollTo(scrollOpts)
  } catch (e) {
    window.scrollTo(scrollOpts.left, scrollOpts.top)
  }
}

const resizeIframe = (width: number, height: number) => {
  const iframe = document.querySelector('iframe')

  if (iframe) {
    const iframeStyle = iframe.style
    iframeStyle.width = `${width}px`
    iframeStyle.height = `${height}px`
  }
}

const logicalAppPath = (href: string): string => {
  const { pathname, searchParams } = new URL(href)
  searchParams.delete('embed')

  return searchParams.toString() ? `${pathname}?${searchParams}` : pathname
}

const onFormSubmitted = () => {
  scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

const userActivity = () => {
  /*
   * Dispatching event as mousemove so kong token is refresh when
   * there's activity inside an iframe.
   * Activity is measured by events that buble to the document. As ruby pages are loaded inside
   * an iframe, the events don't buble to the page root.
   * As ruby pages are server side rendered, this content load will be dispatched in most page actions.
   * See lib/babylon-auth/src/PortalLoginProvider/use-user-activity-notifier.ts
   */
  const event = new Event('mousemove')
  document?.dispatchEvent(event)
}

const onContentLoaded = (dimensions) => {
  userActivity()
  resizeIframe(dimensions.width, dimensions.height)
}

const onPageSizeChanged = (dimensions) => {
  resizeIframe(dimensions.width, dimensions.height)
}

const onModalOpened = () => {
  scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export default function LegacyPortalScreen() {
  const location = useLocation()
  const history = useHistory()
  const { trackView } = useTrackView()

  const src = {
    src: location.pathname,
  }

  const previousLocation = usePrevious(location)

  useEffect(() => {
    if (location != previousLocation) {
      scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [previousLocation, location])

  const onLinkClicked = (link: string) => {
    if (link.startsWith('/')) {
      // NOTE: we change the location here because our use of `window.history.replaceState`
      // (see below) means that React Router's state is no longer in sync with the browser's
      // location, so otherwise wouldn't re-render the page if it felt the URL wasn't changing
      if (link === history.location.pathname) {
        history.replace('/')
      }

      history.push(link)
    } else {
      window.location.href = link
    }
  }

  const onScriptLoaded = (href: string) => {
    const iframePath = logicalAppPath(href)
    const appPath = logicalAppPath(window.location.href)
    userActivity()

    if (appPath !== iframePath) {
      // NOTE: we natively update the browser's location (instead of using `props.history.replace`)
      // to prevent a re-render from occurring
      window.history.replaceState({}, 'Admin Portal', iframePath)
      trackView({
        pathname: location.pathname,
        destination: iframePath,
        isRedirect: true,
      })
    }
  }

  const onMessageSent = (
    source: Window,
    props: {
      type:
        | 'OPEN_BOOK_APPOINTMENT_DIALOG'
        | 'OPEN_NEW_CANCEL_APPOINTMENT_DIALOG'
      patientId: number
      appointmentId: number
    }
  ) => {
    const { type } = props

    userActivity()

    if (type === 'OPEN_BOOK_APPOINTMENT_DIALOG') {
      history.push(`/admin/patients/${props.patientId}/book-appointment`)
    } else if (type === 'OPEN_NEW_CANCEL_APPOINTMENT_DIALOG') {
      const { appointmentId } = props

      PopupDialog.open('/dialog/cancelAppointment', {
        appointmentId,
      })
      const iframeUrl = document.querySelector('iframe')?.src as string
      source.postMessage(
        { type: 'NEW_CANCEL_APPOINTMENT_DIALOG_OPENED' },
        iframeUrl
      )
    }
  }

  return (
    <AdminPortalFrame
      src={src}
      location={location}
      onMessageSent={onMessageSent}
      onModalOpened={onModalOpened}
      onPageSizeChanged={onPageSizeChanged}
      onScriptLoaded={onScriptLoaded}
      onFormSubmitted={onFormSubmitted}
      onLinkClicked={onLinkClicked}
      onContentLoaded={onContentLoaded}
    />
  )
}
