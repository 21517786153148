import { envVar } from '@babylon/babylon-env'

export const braintreeUrl = (token: string) => {
  const environment = envVar('BRAINTREE_ENVIRONMENT')
  const merchantId = envVar('BRAINTREE_MERCHANT_ID')
  const subdomain = environment ? `${environment}.` : ''

  if (merchantId == false) {
    return ''
  }

  return `https://${subdomain}braintreegateway.com/merchants/${merchantId}/transactions/${token}`
}
