import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  ReactNode,
} from 'react'
import classnames from 'classnames'
import { usePopper, Modifier } from 'react-popper'
import OutsideClickHandler from 'react-outside-click-handler'

import styles from './Popover.module.css'

interface PopoverProps {
  children?: ReactNode
  'data-testid'?: string
  onClose?: () => void
  triggerRef: HTMLElement | null
  visible?: boolean
  modifiers?: Modifier<any, any>[]
}

export default function Popover({
  children,
  'data-testid': dataTestId = 'popover-dialog',
  onClose = () => {},
  triggerRef,
  visible,
  modifiers = [],
}: PopoverProps) {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const arrowElement = useRef<HTMLDivElement | null>(null)
  const { styles: popperStyles, attributes } = usePopper(
    triggerRef,
    popperElement,
    {
      placement: 'left',
      modifiers: [
        {
          name: 'eventListeners',
          options: {
            scroll: false,
            resize: false,
          },
        },
        {
          name: 'flip',
          options: {
            altBoundary: true,
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altBoundary: true,
            altAxis: true,
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowElement.current,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        ...modifiers,
      ],
    }
  )

  const handleEscPress = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (!visible) {
      return undefined
    }

    window.document.addEventListener('keydown', handleEscPress)

    return () => window.document.removeEventListener('keydown', handleEscPress)
  }, [visible, handleEscPress])

  const componentClass = classnames(styles.PopoverDialog, {
    [styles.PopoverDialogReferenceHidden]:
      attributes?.popper?.['data-popper-reference-hidden'],
  })

  return (
    <>
      {visible && (
        <OutsideClickHandler onOutsideClick={onClose}>
          <div
            className={componentClass}
            data-testid={dataTestId}
            ref={setPopperElement}
            style={{ ...popperStyles.popper, zIndex: 1 }}
            {...attributes.popper}
          >
            <div
              className={styles.PopoverArrow}
              ref={arrowElement}
              style={popperStyles.arrow}
              {...attributes.arrow}
            />
            {children}
          </div>
        </OutsideClickHandler>
      )}
    </>
  )
}
