import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useProductConfig } from '@babylon/product-config'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import * as Access from '@babylon/web-platform-access'
import { Header, MemberOpsModuleName } from '../../../../..'
import AppointmentCreditDrawer from './components/AppointmentCredits'
import RedeemedPromoCodesDrawer from './components/RedeemedPromoCodes'
import Covid19History from './components/Covid19History'
import messages from './AccountServices.messages'
import styles from './AccountServices.module.scss'
import { PromoCodeModal } from './components/PromoCode'
import { ActivityLogModal } from './components/ActivityLog'
import { DevicesDialog } from './components/Devices'
import Transactions from './components/Transactions'
import PrescriptionLinks from './components/PrescriptionLinks'
import { SickNotes } from './components/SickNotes'
import { useDayToDayIdTokenUrl } from './hooks'
import PatientMetricsModal from './components/PatientMetrics'

export interface AccountServicesProps {
  patientId: string
  patientUuid: string
}

export default ({ patientId, patientUuid }: AccountServicesProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()
  const [showCovid19History, setShowCovid19History] = useState(false)
  const [promoCodeVisible, setPromoCodeVisible] = useState(false)
  const [devicesVisible, setDevicesVisible] = useState(false)
  const [transactionsVisible, setTransactionsVisible] = useState(false)
  const [sickNotesVisible, setSickNotesVisible] = useState(false)
  const [activityLogVisible, setActivityLogVisible] = useState(false)
  const [showAppointmentCredit, setShowAppointmentCredit] = useState(false)
  const [showRedeemedPromoCodes, setShowRedeemedPromoCodes] = useState(false)
  const [showPatientMetrics, setShowPatientMetrics] = useState(false)

  const { getBlob } = useProductConfig()
  const {
    accountServiceCovid19HistoryEnabled,
    activityLogEnabled,
    activityLogReport,
    appointmentCreditsEnabled,
    devicesEnabled,
    patientMetricsEnabled,
    prescriptionLinksEnabled,
    redeemCodeEnabled,
    redeemedPromoCodesEnabled,
    sickNotesEnabled,
    transactionsEnabled,
    viewCreateDayToDayProfileEnabled,
  } = getBlob('memberOperations')

  const [canAccessDayToDay] = Access.useDecide('issue-day-to-day-profile')

  const dayToDayIdTokenUrl = useDayToDayIdTokenUrl(
    viewCreateDayToDayProfileEnabled,
    canAccessDayToDay,
    patientUuid
  )

  return (
    <div className={styles.AccountServices} data-testid="account-services">
      <Header title={fm(messages.header)} />
      <div className={styles.AccountServices__card}>
        <>
          {prescriptionLinksEnabled && (
            <PrescriptionLinks
              patientId={patientId}
              className={styles.AccountServices__link}
            />
          )}
          {appointmentCreditsEnabled && (
            <button
              type="button"
              data-testid="show-appointment-credits-button"
              onClick={() => {
                setShowAppointmentCredit(true)
                trackClick({
                  elementName: 'appointment-credits-open-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              className={styles.AccountServices__link}
            >
              {fm(messages.view_appointment_credits)}
            </button>
          )}
          {accountServiceCovid19HistoryEnabled && (
            <button
              type="button"
              onClick={() => {
                setShowCovid19History(true)
                trackClick({
                  elementName: 'covid-19-history-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              className={styles.AccountServices__link}
            >
              {fm(messages.view_covid19)}
            </button>
          )}
          {redeemedPromoCodesEnabled && (
            <button
              type="button"
              onClick={() => {
                setShowRedeemedPromoCodes(true)
                trackClick({
                  elementName: 'open-redeem-promo-codes-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              className={styles.AccountServices__link}
            >
              {fm(messages.redemption_codes)}
            </button>
          )}
          {redeemCodeEnabled && (
            <button
              type="button"
              onClick={() => {
                setPromoCodeVisible(true)
                trackClick({
                  elementName: 'open-redeem-code-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              className={styles.AccountServices__link}
              data-testid="redeem-code"
            >
              {fm(messages.redeem_code)}
            </button>
          )}
          {devicesEnabled && (
            <button
              type="button"
              onClick={() => {
                setDevicesVisible(true)
                trackClick({
                  elementName: 'open-devices-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              className={styles.AccountServices__link}
            >
              {fm(messages.devices)}
            </button>
          )}
          {patientMetricsEnabled && (
            <button
              type="button"
              onClick={() => {
                setShowPatientMetrics(true)
                trackClick({
                  elementName: 'open-patient-metrics-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              className={styles.AccountServices__link}
            >
              {fm(messages.patient_metrics)}
            </button>
          )}
          {transactionsEnabled && (
            <button
              type="button"
              onClick={() => {
                setTransactionsVisible(true)
                trackClick({
                  elementName: 'open-transactions-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              className={styles.AccountServices__link}
            >
              {fm(messages.view_transactions)}
            </button>
          )}
          {sickNotesEnabled && (
            <button
              type="button"
              onClick={() => {
                setSickNotesVisible(true)
                trackClick({
                  elementName: 'open-sick-notes-btn',
                  elementType: TrackingElementType.button,
                })
              }}
              className={styles.AccountServices__link}
            >
              {fm(messages.view_sick_notes)}
            </button>
          )}
          {activityLogEnabled && (
            <button
              type="button"
              onClick={() => {
                setActivityLogVisible(true)
              }}
              className={styles.AccountServices__link}
            >
              {fm(messages.view_activity_log)}
            </button>
          )}
          {dayToDayIdTokenUrl && (
            <a
              href={dayToDayIdTokenUrl.toString()}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.AccountServices__link}
            >
              {fm(messages.view_day_to_day)}
            </a>
          )}
          {showRedeemedPromoCodes && (
            <RedeemedPromoCodesDrawer
              patientId={patientId}
              visible={showRedeemedPromoCodes}
              toggleVisible={() => setShowRedeemedPromoCodes(false)}
            />
          )}
          {showAppointmentCredit && (
            <AppointmentCreditDrawer
              patientId={patientId}
              visible={showAppointmentCredit}
              toggleVisible={() => {
                trackClick({
                  elementName: 'view-appointment-credits-toggle',
                  elementType: TrackingElementType.button,
                })
                setShowAppointmentCredit(false)
              }}
            />
          )}
          {accountServiceCovid19HistoryEnabled && (
            <Covid19History
              patientId={patientId}
              visible={showCovid19History}
              toggleVisible={() => setShowCovid19History(false)}
            />
          )}
          {redeemCodeEnabled && promoCodeVisible && (
            <PromoCodeModal
              patientId={patientId}
              close={() => setPromoCodeVisible(false)}
            />
          )}
          {devicesEnabled && devicesVisible && (
            <DevicesDialog
              patientId={patientId}
              onClose={() => setDevicesVisible(false)}
            />
          )}
          {showPatientMetrics && (
            <PatientMetricsModal
              patientUuid={patientUuid}
              onClose={() => setShowPatientMetrics(false)}
            />
          )}
          {transactionsVisible && (
            <Transactions
              patientId={patientId}
              visible={transactionsVisible}
              toggleVisible={() => setTransactionsVisible(false)}
            />
          )}
          {sickNotesEnabled && sickNotesVisible && (
            <SickNotes
              patientId={patientId}
              toggleVisible={() => setSickNotesVisible(false)}
            />
          )}
          {activityLogEnabled && activityLogVisible && (
            <ActivityLogModal
              patientId={patientId}
              {...activityLogReport}
              close={() => setActivityLogVisible(false)}
            />
          )}
        </>
      </div>
    </div>
  )
}
