import React from 'react'
import cn from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { Grid, Cell, Tag, Spinner } from '@babylon/core-ui'
import { FormattedDate } from 'react-intl'
import { ButtonVariant, ButtonSimple } from '@babylon/medkit'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import InfiniteScroll from 'react-infinite-scroller'
import messages from './messages'
import styles from './RedeemedCodeEntries.module.scss'
import { ActionType } from './ConfirmRedemptionActionModal'
import { Redemption } from './types'
import { MemberOpsModuleName } from '../../../../../../..'

const NoContent = () => <span>&ndash;</span>

const AppointmentRedemptionCell = ({
  bookedCount,
  leftCount,
}: {
  bookedCount: string
  leftCount: string
}) => {
  const fm = useFormatMessage()

  if ([bookedCount, leftCount].includes('N/A')) {
    return <NoContent />
  }

  return (
    <>
      {bookedCount ? (
        <span data-testid="appointments-redeemed">
          {fm(messages.appointments_redeemed, { count: bookedCount })}
        </span>
      ) : (
        <NoContent />
      )}
      {leftCount ? (
        <span data-testid="appointments-left">
          {fm(messages.appointments_left, { count: leftCount })}
        </span>
      ) : (
        <NoContent />
      )}
    </>
  )
}

const StartEndCell = ({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) => {
  if (!startDate && !endDate) {
    return <NoContent />
  }

  const DisplayDate = ({ value }: { value: string }) =>
    value ? (
      <FormattedDate
        timeZone="UTC"
        day="2-digit"
        month="short"
        year="numeric"
        value={value}
      />
    ) : (
      <NoContent />
    )

  return (
    <div data-testid="display-date">
      <DisplayDate value={startDate} />
      <DisplayDate value={endDate} />
    </div>
  )
}

const StatusTag = ({ status }: { status: string }) => {
  let statusClass: string | null = null

  switch (status) {
    case 'Active':
      statusClass = styles.StatusTag__Active

      break
    case 'Removed':
    case 'Expired':
      statusClass = styles.StatusTag__Inactive

      break
  }

  return <Tag className={cn(styles.StatusTag, statusClass)}>{status}</Tag>
}

export type EntryProps = Redemption & {
  onAction: (id: string, redemptionName: string, actionType: ActionType) => void
}

const RedeemedCodeEntry = ({
  id,
  status,
  code,
  promotion_name,
  activated_at,
  promotion_type,
  plan_title,
  redemption_end_date,
  appointments_left,
  appointments_booked,
  onAction,
  is_reactivatable,
}: EntryProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()

  const isRemoved = ['removed', 'cancelled', 'expired'].includes(
    status.toLocaleLowerCase()
  )

  return (
    <Grid
      key={id}
      columns={8}
      className={styles.RedeemedCodeEntries__Entry}
      dataTestId="redeemed-code-entry"
    >
      <Cell>
        <StatusTag status={status} />
      </Cell>
      <Cell data-testid="promotion-name">{promotion_name}</Cell>
      <Cell data-testid="plan-title">{plan_title}</Cell>
      <Cell data-testid="promotion-type">{promotion_type}</Cell>
      <Cell data-testid="code">{code}</Cell>
      <Cell className={styles.StackedCell}>
        <StartEndCell startDate={activated_at} endDate={redemption_end_date} />
      </Cell>
      <Cell className={styles.StackedCell}>
        <AppointmentRedemptionCell
          bookedCount={appointments_booked}
          leftCount={appointments_left}
        />
      </Cell>
      <Cell>
        {!isRemoved && (
          <ButtonSimple
            data-testid="remove-redemption"
            className={styles.RedeemedCodeEntries__action}
            variant={ButtonVariant.destructive}
            onClick={() => {
              onAction(id, promotion_name, ActionType.removePromo)
              trackClick({
                elementName: 'remove-redeemed-code-entry-btn',
                elementType: TrackingElementType.button,
              })
            }}
          >
            {fm(messages.action_remove)}
          </ButtonSimple>
        )}
        {is_reactivatable && (
          <ButtonSimple
            data-testid="reactivate-redemption"
            className={styles.RedeemedCodeEntries__action}
            variant={ButtonVariant.destructive}
            onClick={() => {
              onAction(id, promotion_name, ActionType.reactivatePromo)
              trackClick({
                elementName: 'reactivate-redeemed-code-entry-btn',
                elementType: TrackingElementType.button,
              })
            }}
          >
            {fm(messages.action_reactivate)}
          </ButtonSimple>
        )}
      </Cell>
    </Grid>
  )
}

const RedeemedCodeEntries = ({
  entriesPayload = [],
  onAction,
  fetchNextEntries,
  hasMoreEntries,
  getScrollParent,
}: {
  entriesPayload: Redemption[]
  onAction: (id: string, redemptionName: string, actionType: ActionType) => void
  fetchNextEntries: (page: number) => void
  hasMoreEntries: boolean
  getScrollParent: () => HTMLElement | null
}) => {
  const fm = useFormatMessage()

  const entries = entriesPayload.map((payload: Redemption) => (
    <RedeemedCodeEntry key={payload.id} {...payload} onAction={onAction} />
  ))

  return (
    <div className={styles.RedeemedCodeEntries}>
      <h2 className={styles.RedeemedCodeEntries__Heading}>
        {fm(messages.redeemed_table_heading)}
      </h2>
      <Grid columns={8} className={styles.TableHeading}>
        <Cell>{fm(messages.status_heading)}</Cell>
        <Cell>{fm(messages.promotion_heading)}</Cell>
        <Cell>{fm(messages.plan_heading)}</Cell>
        <Cell>{fm(messages.type_heading)}</Cell>
        <Cell>{fm(messages.code_heading)}</Cell>
        <Cell>{fm(messages.started_end_heading)}</Cell>
        <Cell>{fm(messages.appointments_heading)}</Cell>
        <Cell>{fm(messages.actions_heading)}</Cell>
      </Grid>
      <InfiniteScroll
        loadMore={fetchNextEntries}
        hasMore={hasMoreEntries}
        loader={<Spinner testid="pagination-loader" key="infinite-loader" />}
        initialLoad={false}
        useWindow={false}
        getScrollParent={getScrollParent}
        threshold={0}
      >
        <div data-testid="redeemed-codes-entries">{entries}</div>
      </InfiniteScroll>
    </div>
  )
}

export default RedeemedCodeEntries
