import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

import {
  withLabel,
  withWrapper,
  withValue,
  withValidation,
  withOnChange,
  compose,
} from '../../forms'

const pad = (num, size) => {
  let s = `${num}`
  while (s.length < size) s = `${0}${s}`
  return s
}

const days = []

for (let i = 1; i <= 31; i++) {
  days.push({ label: pad(i, 2), value: i })
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const monthOptions = months.map((m, idx) => ({ label: m, value: idx }))

const yearOptions = []

const today = new Date()

for (let i = today.getFullYear(); i > today.getFullYear() - 100; i--) {
  yearOptions.push({ label: pad(i, 4), value: i })
}

class DatePicker extends React.Component {
  state = {}

  static getDerivedStateFromProps({ value }) {
    if (!DatePicker.isValidValue(value)) {
      throw `DatePicker: Date is not valid (${value})`
    }

    const isDate = value instanceof Date
    const date = isDate ? value : new Date(value)
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    return {
      day,
      month,
      year,
      maxDay: DatePicker.calcMaxDay(month, year),
      isDate,
    }
  }

  static isValidValue(value) {
    return value instanceof Date || !Number.isNaN(new Date(value))
  }

  static calcMaxDay(month = 0, year) {
    return new Date(year, month + 1, 0).getDate()
  }

  update(newDay, newMonth, newYear) {
    const { onChange } = this.props
    const day = newDay || this.state.day
    const month = newMonth == null ? this.state.month : newMonth
    const year = newYear || this.state.year

    if (day && month != null && year && onChange) {
      onChange(
        this.state.isDate
          ? new Date(year, month, day)
          : `${pad(year, 4)}-${pad(month + 1, 2)}-${pad(day, 2)}`
      )
    }
  }

  updateDay = ({ value: day }) => {
    this.setState({ day })
    this.update(day)
  }

  updateMonth = ({ value }) => {
    const { day, year } = this.state
    const maxDay = DatePicker.calcMaxDay(value, year)
    const newDay = day ? Math.min(day, maxDay) : null

    this.setState({
      month: value,
      day: newDay,
      maxDay,
    })

    this.update(newDay, value)
  }

  updateYear = ({ value }) => {
    const { day } = this.state
    const maxDay = DatePicker.calcMaxDay(null, value)
    const newDay = day ? Math.min(day, maxDay) : null
    this.setState({
      year: value,
      day: newDay,
      maxDay,
    })
    this.update(newDay, null, value)
  }

  render() {
    const { day, month, year, maxDay } = this.state
    return (
      <div className="dobPicker">
        <Select
          className="day"
          placeholder="Day"
          name="region"
          value={day}
          onChange={this.updateDay}
          options={days.slice(0, maxDay)}
          clearable={false}
        />
        <Select
          className="month"
          placeholder="Month"
          name="region"
          value={month}
          onChange={this.updateMonth}
          options={monthOptions}
          clearable={false}
        />
        <Select
          className="year"
          placeholder="Year"
          name="region"
          value={year}
          onChange={this.updateYear}
          options={yearOptions}
          clearable={false}
        />
      </div>
    )
  }
}

/* eslint-disable react/no-unused-prop-types */

const DatePickerWidget = compose(
  withLabel,
  withValue,
  withValidation,
  withWrapper,
  withOnChange
)(DatePicker)

DatePickerWidget.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
}

export default DatePickerWidget
