import { gql } from '@apollo/client'

export const QUERY_GET_GP_FAX_NUMBERS = gql`
  query GetGPFaxNumbers(
    $filter: GpOrganizationsFilter
    $first: NonNegativeInt!
    $after: Cursor
  ) {
    gpOrganizations(filter: $filter, first: $first, after: $after) {
      edges {
        node {
          id
          name
          telecom {
            edges {
              node {
                system
                ... on FaxNumberContactPoint {
                  faxNumber
                  faxCountryCode
                }
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`
