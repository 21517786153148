/* eslint-disable camelcase */
import { parsePhoneNumber } from 'libphonenumber-js'
import messages from './messages'

export interface SearchableField {
  type?: string
  maxWidth: number | string
  cellWidth: number
  paramName: string
  label?: any
  placeholder?: string
}

export const searchableFields: SearchableField[] = [
  {
    type: 'text',
    maxWidth: 328,
    cellWidth: 4,
    paramName: 'first_name',
    label: messages.first_name,
  },
  { cellWidth: 1, maxWidth: 'auto', paramName: 'spacer1' },
  {
    type: 'text',
    maxWidth: 328,
    cellWidth: 4,
    paramName: 'last_name',
    label: messages.last_name,
  },
  { cellWidth: 1, maxWidth: 'auto', paramName: 'spacer2' },
  {
    type: 'text',
    maxWidth: 157,
    cellWidth: 2,
    paramName: 'id',
    label: messages.babylon_id,
  },
  {
    type: 'text',
    maxWidth: 130,
    cellWidth: 2,
    paramName: 'date_of_birth',
    placeholder: 'DD/MM/YYYY',
    label: messages.date_of_birth,
  },
  {
    type: 'text',
    maxWidth: 100,
    cellWidth: 3,
    paramName: 'post_code',
    label: messages.post_code,
  },
  {
    type: 'phone',
    maxWidth: 166,
    cellWidth: 2,
    paramName: 'full_phone_number',
    label: messages.full_phone_number,
  },
  {
    type: 'text',
    maxWidth: 238,
    cellWidth: 3,
    paramName: 'email',
    label: messages.email,
  },
  {
    type: 'text',
    maxWidth: 157,
    cellWidth: 2,
    paramName: 'public_healthcare_identifier',
    label: messages.public_healthcare_identifier,
  },
]

const VALID_PARAMS = [
  ...searchableFields,
  { paramName: 'phone_country_code' },
  { paramName: 'page' },
  { paramName: 'per_page' },
]
  .map((field) => field.paramName)
  .filter((field) => field)

const INVALID_VALUES = [null, undefined, '']

const validParam = (param: string, value: any) =>
  !INVALID_VALUES.includes(value) && VALID_PARAMS.includes(param)

const parseFullPhoneNumber = (fullPhoneNumber: string) => {
  let params: {
    phone_number: string
    phone_country_code?: string
  }

  try {
    const { countryCallingCode, nationalNumber } = parsePhoneNumber(
      fullPhoneNumber
    )
    params = {
      phone_country_code: `+${countryCallingCode}`,
      phone_number: nationalNumber as string,
    }
  } catch (error) {
    if (fullPhoneNumber) {
      // eslint-disable-next-line no-param-reassign
      fullPhoneNumber = fullPhoneNumber.replace(/ /g, '')
    }

    if (fullPhoneNumber && fullPhoneNumber[0] === '0') {
      params = { phone_number: fullPhoneNumber.slice(1) }
    } else {
      params = { phone_number: fullPhoneNumber }
    }
  }

  return params
}

export const generateFullPhoneNumber = ({
  phone_number: phoneNumber,
  phone_country_code: phoneCountryCode,
  with_country_code: withCountryCode,
}: {
  phone_number?: string | null
  phone_country_code?: string | null
  with_country_code?: string | null
}) => {
  if (withCountryCode) {
    return withCountryCode
  }

  if (!phoneNumber) {
    return ''
  }

  const leadingZero = phoneNumber && !phoneCountryCode ? '0' : ''

  return `${leadingZero}${phoneCountryCode || ''}${phoneNumber || ''}`
}

type GenericObject = { [key: string]: any }
export const parseSearchParams = (params: GenericObject) =>
  Object.entries(params).reduce((acc: GenericObject, [param, value]) => {
    if (param === 'full_phone_number') {
      acc = { ...acc, ...parseFullPhoneNumber(value) }
    } else if (param === 'email' && value) {
      acc[param] = value.replace(/[<>]/g, '')
    } else if (validParam(param, value)) {
      acc[param] = value
    }
    return acc
  }, {})
