import { defineMessages } from 'react-intl'

const baseId = 'search_result_item.tags'

export default defineMessages({
  queued: {
    id: `${baseId}.queued`,
    defaultMessage: 'queued',
  },
})
