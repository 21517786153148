import { generatePath } from 'react-router-dom'
import { routes, externalRoutes } from '../constants/routes'

export const buildClinicianCalendarPageUrl = (clinicianId: string) =>
  generatePath(routes.clinicianCalendar, { clinicianId })

export const buildOldClinicianCalendarPageUrl = (clinicianId: string) =>
  generatePath(routes.oldClinicianCalendar, { clinicianId })

export const buildConsultantSearchPageUrl = () =>
  generatePath(externalRoutes.consultantsSearch)
