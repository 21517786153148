import React from 'react'
import { FormattedMessage } from 'react-intl'
import { format, parseISO } from 'date-fns'
import {
  Card,
  Grid,
  Cell,
  Tag,
  KebabMenu,
  KebabMenuRenderOptions,
} from '@babylon/core-ui'
import Covid19Tags from '../Conversations/Covid19Tags'
import SomeoneElseTag from '../Conversations/SomeoneElseTag'
import styles from './EntryV2.module.scss'
import messages from './EntryV2.messages'
import { Entry } from '../types'

export interface EntryV2Props extends Entry {
  'data-testid'?: string
  onClick?: () => void
}

export default function EntryV2({
  'data-testid': dataTestId = 'entry',
  text,
  time,
  tag,
  type,
  onClick,
  userUuid,
  patientUuid,
}: EntryV2Props) {
  const isClickable = Boolean(onClick)

  return (
    <div className={styles.Entry} data-testid={dataTestId}>
      <Card className={styles.Entry__card}>
        <Grid columns={6}>
          <Cell width={3}>
            <div className={styles.Entry__time}>
              {format(parseISO(time), 'dd MMM yyyy, HH:mm')}
            </div>
          </Cell>
          <Cell width={3}>
            {tag && <Covid19Tags data-testid="entry-tag" tag={tag} />}
            <SomeoneElseTag
              data-testid="someone-else-tag"
              userUuid={userUuid}
              patientUuid={patientUuid}
            />
            {messages[`${type}`] && (
              <Tag data-testid="entry-type-tag" color="light-teal">
                <FormattedMessage {...messages[`${type}`]} />
              </Tag>
            )}
          </Cell>
          <Cell width={5}>
            <div className={styles.Entry__preview}>{text}</div>
          </Cell>
          <Cell width={1} className={styles.Entry__flexend}>
            {isClickable && (
              <KebabMenu>
                {({ menuItemProps }: KebabMenuRenderOptions) => (
                  <div>
                    <button
                      {...menuItemProps}
                      data-testid="view-conversation"
                      type="button"
                      onClick={() => {
                        if (onClick) {
                          onClick()
                        }
                      }}
                    >
                      <FormattedMessage {...messages.viewConversation} />
                    </button>
                  </div>
                )}
              </KebabMenu>
            )}
          </Cell>
        </Grid>
      </Card>
    </div>
  )
}
