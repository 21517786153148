import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { Autocomplete } from '@mui/material'
import TextField from '@mui/material/TextField'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { LoadingButton } from '@mui/lab'
import React, {
  useState,
  ChangeEvent,
  useCallback,
  useEffect,
  forwardRef,
} from 'react'
import { useMutation } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import styles from './styles.module.scss'
import { messages } from './messages'
import {
  EditObjectConfig,
  EditObjectConfigField,
  EditableObject,
} from './types'

type EditModalProps = {
  eventType: string
  inputObject: EditableObject
  closeModal: () => void
  editConfig: EditObjectConfig
  objectTypeLabel?: string
}

export const EditModal: React.FC<EditModalProps> = forwardRef(
  ({ eventType, inputObject, editConfig, closeModal, objectTypeLabel }) => {
    const fm = useFormatMessage()
    const [isObjectValid, setIsObjectValid] = useState(false)
    /* Set the object to use when editing. Will create an empty object based on the config if adding a new object */
    const setInitialObject = () => {
      if (Object.keys(inputObject).length === 0) {
        const newObject: { [key: string]: any } = {}
        editConfig.fields.forEach((config: EditObjectConfigField) => {
          newObject[config.name] = config.defaultValue || ''
        })
        return newObject
      }

      return { ...inputObject }
    }

    const [objectToEdit, setObjectToEdit] = useState(setInitialObject())
    const [snackBarOpen, setSnackbarOpen] = useState(false)
    const [mutateObject, { loading, error }] = useMutation(
      editConfig.mutation,
      {
        context: {
          clientName: 'platform-gateway',
        },
      }
    )

    useEffect(() => {
      // Ensuring that isObjectValid is set to true only if mandatory fields are filled in
      if (Object.keys(objectToEdit).length !== 0) {
        let validity = true
        editConfig.fields
          .filter((fieldObj) => fieldObj.required)
          .forEach((fieldObj) => {
            if (
              objectToEdit[fieldObj.name] === '' ||
              objectToEdit[fieldObj.name] === null ||
              typeof objectToEdit[fieldObj.name] === 'undefined'
            )
              validity = false
          })
        setIsObjectValid(validity)
      }
    }, [editConfig.fields, objectToEdit])

    const handleCloseSnackBar = (
      event: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === 'clickaway') {
        return
      }

      if (!error) {
        closeModal()
        window.dispatchEvent(new CustomEvent(eventType))
      }

      setSnackbarOpen(false)
    }

    const editObject = useCallback(async () => {
      let input: any = {}
      if (editConfig.mutationInputFormatter) {
        input = editConfig.mutationInputFormatter(objectToEdit)
      } else {
        editConfig.fields.forEach((config: EditObjectConfigField) => {
          input[config.dataField || config.name] = objectToEdit[config.name]
        })
      }
      await mutateObject({
        variables: {
          input,
        },
      })
    }, [mutateObject, editConfig, objectToEdit])

    return (
      <div>
        <Paper elevation={3} className={styles.modal} key="edit-modal">
          <div className={styles.modalContent}>
            {Object.keys(inputObject).length === 0 ? (
              <h1>
                {fm(messages.add)} {objectTypeLabel}
              </h1>
            ) : (
              <div>
                <h1>
                  {fm(messages.edit)} {objectTypeLabel}
                </h1>
                <h2>{objectToEdit[editConfig.headings.header]}</h2>
                {editConfig.headings?.subHeaders?.map((subheader: string) => (
                  <p key={subheader}>{objectToEdit[subheader]}</p>
                ))}
              </div>
            )}
            <Divider className={styles.divider} />
            {editConfig.fields
              .filter((config) => !config.hidden)
              .map((config) => {
                if (config.type === 'checkbox') {
                  return (
                    <FormControlLabel
                      label={config.label}
                      key={config.name}
                      control={
                        <Checkbox
                          data-testid={`edit-${config.name}-checkbox`}
                          checked={objectToEdit[config.name]}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setObjectToEdit((prevState: EditableObject) => ({
                              ...prevState,
                              [config.name]: event.target.checked,
                            }))
                          }}
                        />
                      }
                    />
                  )
                }

                if (config.type === 'checkbox-multi-select') {
                  return (
                    <div className={styles.textFieldWrapper} key={config.name}>
                      <label className={styles.formLabel}>{config.label}</label>
                      {config?.options?.map((option: any) => {
                        return (
                          <div>
                            <FormControlLabel
                              label={option.label}
                              key={option.label}
                              control={
                                <Checkbox
                                  data-testid={`edit-${option.value}-checkbox`}
                                  checked={objectToEdit[config.name].includes(
                                    option.value
                                  )}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const newArray = event.target.checked
                                      ? [
                                          ...objectToEdit[config.name],
                                          option.value,
                                        ]
                                      : objectToEdit[config.name].filter(
                                          (value: string) =>
                                            value !== option.value
                                        )

                                    setObjectToEdit({
                                      ...objectToEdit,
                                      [config.name]: newArray,
                                    })
                                  }}
                                />
                              }
                            />
                          </div>
                        )
                      })}
                    </div>
                  )
                }

                if (config.type === 'text') {
                  return (
                    <div className={styles.textFieldWrapper} key={config.name}>
                      <TextField
                        data-testid={`edit-${config.name}-input`}
                        fullWidth
                        required={config.required}
                        helperText={config.helperText}
                        label={config.label}
                        value={objectToEdit[config.name]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setObjectToEdit((prevState: EditableObject) => ({
                            ...prevState,
                            [config.name]: event.target.value,
                          }))
                        }}
                      />
                    </div>
                  )
                }

                if (config.type === 'number') {
                  return (
                    <div className={styles.textFieldWrapper} key={config.name}>
                      <TextField
                        data-testid={`edit-${config.name}-number-input`}
                        fullWidth
                        type="number"
                        required={config.required}
                        helperText={config.helperText}
                        label={config.label}
                        value={objectToEdit[config.name]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setObjectToEdit((prevState: EditableObject) => ({
                            ...prevState,
                            [config.name]: parseFloat(event.target.value),
                          }))
                        }}
                      />
                    </div>
                  )
                }

                if (config.type === 'radio') {
                  return (
                    <div className={styles.textFieldWrapper} key={config.name}>
                      <label
                        className={styles.formLabel}
                        htmlFor={`edit-${config.name}-radio`}
                      >
                        {config.label}
                      </label>
                      <RadioGroup
                        data-testid={`edit-${config.name}-radio`}
                        aria-label={config.label}
                        id={`edit-${config.name}-radio`}
                        name={config.name}
                        value={objectToEdit[config.name]}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setObjectToEdit((prevState: EditableObject) => ({
                            ...prevState,
                            [config.name]: event.target.value,
                          }))
                        }}
                      >
                        {config?.options?.map((option: any) => {
                          return (
                            <FormControlLabel
                              data-testid={`edit-${config.name}-radio-${option.value}`}
                              value={option.value}
                              control={<Radio />}
                              label={option.label}
                              key={option.value}
                            />
                          )
                        })}
                      </RadioGroup>
                    </div>
                  )
                }

                if (config.type === 'select') {
                  return (
                    <div className={styles.textFieldWrapper} key={config.name}>
                      <Autocomplete
                        data-testid={`edit-${config.name}-select`}
                        fullWidth
                        options={config.options || []}
                        value={objectToEdit[config.name] || null}
                        onChange={(
                          event: any,
                          newValue: { option: string; label: string } | null
                        ) => {
                          setObjectToEdit((prevState: EditableObject) => ({
                            ...prevState,
                            [config.name]: config.options?.find(
                              (option) => option.label === newValue?.label
                            ),
                          }))
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={config.label}
                            required={config.required}
                            helperText={config.helperText}
                          />
                        )}
                      />
                    </div>
                  )
                }

                return null
              })}
            <div className={styles.footer}>
              <div className={styles.buttonWrapper}>
                <LoadingButton
                  data-testid="edit-save-button"
                  onClick={() => {
                    editObject().finally(() => {
                      setSnackbarOpen(true)
                    })
                  }}
                  loading={loading}
                  variant="contained"
                  disabled={!isObjectValid}
                >
                  {fm(messages.save)}
                </LoadingButton>
                <Button onClick={closeModal}>{fm(messages.cancel)}</Button>
              </div>
              {!isObjectValid && (
                <p className={styles.invalidFormWarning}>
                  {fm(messages.invalidForm)}
                </p>
              )}
            </div>
            <Snackbar
              data-testid="edit-snackbar"
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackBarOpen}
              autoHideDuration={1000}
              onClose={handleCloseSnackBar}
            >
              <MuiAlert
                elevation={6}
                severity={!error ? 'success' : 'error'}
                variant="filled"
              >
                {!error ? fm(messages.successfulEdit) : fm(messages.errorEdit)}
              </MuiAlert>
            </Snackbar>
          </div>
        </Paper>
      </div>
    )
  }
)
