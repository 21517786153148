import { defineMessages } from 'react-intl'

const baseId = 'assessment_risk_view'

export default defineMessages({
  instruction: {
    id: `${baseId}.instruction`,
    defaultMessage:
      'Select a risk level for this member then submit the assessment.',
  },
  adultInstructionTitle: {
    id: `${baseId}.adult_instruction_title`,
    defaultMessage: 'For adults who have completed the onboarding assessment',
  },
  adultInstruction: {
    id: `${baseId}.adult_instruction`,
    defaultMessage: 'Use the automatic risk level',
  },
  pregnantInstructionTitle: {
    id: `${baseId}.pregnant_instruction_title`,
    defaultMessage: 'For pregnant people',
  },
  pregnantInstruction: {
    id: `${baseId}.pregnant_instruction`,
    defaultMessage: 'Select high risk',
  },
  partiallyCompletedAssessmentInstructionTitle: {
    id: `${baseId}.partially_completed_assessment_instruction_title`,
    defaultMessage: 'For adults with partially completed assessments',
  },
  partiallyCompletedAssessmentInstruction: {
    id: `${baseId}.partially_completed_assessment_instruction`,
    defaultMessage:
      'Complete their assessment in Athena and select their risk level based on that assessment',
  },
  summary: {
    id: `${baseId}.summary`,
    defaultMessage: 'View patient assessment summary',
  },
})
