import React from 'react'
import * as Access from '@babylon/web-platform-access'
import { useFormatMessage } from '@babylon/intl'
import { appLayout__inner as innerLayoutStyle } from './styles.module.scss'
import MembersSearchWrapper from './MembersSearchWrapper'
import messages from './PlatformGatewaySearch.messages'

export interface PlatformGatewaySearchProps {
  goToGQL: VoidFunction
  goToCoreRuby: VoidFunction
}

const PlatformGatewaySearch = ({
  goToGQL,
  goToCoreRuby,
}: PlatformGatewaySearchProps) => {
  const fm = useFormatMessage()
  const [canAccessMemberSearch] = Access.useDecide('member-search')

  return (
    <div className={innerLayoutStyle} data-testid="new-members-search">
      {canAccessMemberSearch ? (
        <MembersSearchWrapper goToGQL={goToGQL} goToCoreRuby={goToCoreRuby} />
      ) : (
        <span>{fm(messages.access_denied)}</span>
      )}
    </div>
  )
}

export default PlatformGatewaySearch
