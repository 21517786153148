import { useEffect, useRef } from 'react'
import { pseudotranslate } from '@shopify/i18n'
import { useIntl } from 'react-intl'

import { getLocale } from '../util/intl'

const pseudoOptions = {
  prepend: '[[!',
  append: '!]]',
}

export default function usePseudotranslate() {
  const initialLocale = getLocale()
  const intl = useIntl()
  const fm = useRef(intl.formatMessage)

  useEffect(() => {
    if (initialLocale === 'en_TEST') {
      intl.formatMessage = (...args) =>
        // @ts-expect-error
        pseudotranslate(fm.current(...args), pseudoOptions)
    } else {
      intl.formatMessage = fm.current
    }
  }, [intl, initialLocale])
}
