import { defineMessages } from 'react-intl'

const baseId = 'assessment_chatbot'

export default defineMessages({
  startConversationError: {
    id: `${baseId}.start_conversation_error`,
    defaultMessage: 'Something went wrong. Please try again.',
  },
})
