import { gql } from '@apollo/client'

export const QUERY_GET_FILTERED_PHARMACISTS = gql`
  query GetFilteredPharmacists(
    $first: NonNegativeInt!
    $filter: PractitionerRoleFilter
    $after: Cursor
    $sort: [PractitionerRoleSort]
  ) {
    practitionerRoles(
      first: $first
      _filter: $filter
      after: $after
      _sort: $sort
    ) {
      ... on PractitionerRoleConnection {
        edges {
          node {
            id
            identifiers(first: 10) {
              nodes {
                system
                value
              }
            }
            practitioner {
              ... on Practitioner {
                id
                isActive
                names(first: 10) {
                  nodes {
                    text
                  }
                }
                identifiers(first: 10) {
                  nodes {
                    system
                    value
                  }
                }
                practitionerRole(first: 10) {
                  nodes
                }
                telecoms(first: 10) {
                  nodes {
                    ... on EmailAddressContactPoint {
                      emailAddress
                    }
                  }
                }
              }
            }
            organization {
              ... on Organization {
                id
                name
                addresses(first: 10) {
                  nodes {
                    country
                  }
                }
              }
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`

export const QUERY_GET_PHARMACY_SUPPLIERS = gql`
  query Organizations($first: NonNegativeInt!) {
    organizations(first: $first) {
      ... on OrganizationConnection {
        edges {
          node {
            id
            name
            identifiers(first: 10) {
              edges {
                node {
                  system
                  value
                }
              }
            }
            addresses(first: 1) {
              nodes {
                country
              }
            }
          }
        }
        totalCount
      }
    }
  }
`

export const QUERY_GET_PORTAL_ROLES = gql`
  query PortalRoles($first: NonNegativeInt!) {
    portalRoles(first: $first) {
      edges {
        node {
          id
          key
          value
        }
        cursor
      }
    }
  }
`

export const QUERY_GET_REGIONS = gql`
  query CoreRubyRegions($first: NonNegativeInt!) {
    coreRubyRegions(first: $first) {
      edges {
        cursor
        node {
          name
          id
          code
        }
      }
    }
  }
`
