/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type AvailabilitySlotsQueryVariables = Types.Exact<{
  availabilitySlotsRequest: Types.AvailabilitySlotsRequest
}>

export type AvailabilitySlotsQuery = {
  availabilitySlots: Pick<
    Types.AvailabilitySlots,
    'total' | 'more' | 'offset' | 'limit'
  > & {
    availability_slots: Array<
      Pick<
        Types.AvailabilitySlot,
        | 'id'
        | 'admin'
        | 'slot_time'
        | 'slot_size'
        | 'physical_bookable'
        | 'digital_bookable'
      >
    >
  }
}

export const AvailabilitySlotsDocument = gql`
  query AvailabilitySlots(
    $availabilitySlotsRequest: AvailabilitySlotsRequest!
  ) {
    availabilitySlots(request: $availabilitySlotsRequest) {
      total
      more
      offset
      limit
      availability_slots {
        id
        admin
        slot_time
        slot_size
        physical_bookable
        digital_bookable
      }
    }
  }
`
export function useAvailabilitySlotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailabilitySlotsQuery,
    AvailabilitySlotsQueryVariables
  >
) {
  return Apollo.useQuery<
    AvailabilitySlotsQuery,
    AvailabilitySlotsQueryVariables
  >(AvailabilitySlotsDocument, baseOptions)
}
export function useAvailabilitySlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilitySlotsQuery,
    AvailabilitySlotsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AvailabilitySlotsQuery,
    AvailabilitySlotsQueryVariables
  >(AvailabilitySlotsDocument, baseOptions)
}
export type AvailabilitySlotsQueryHookResult = ReturnType<
  typeof useAvailabilitySlotsQuery
>
export type AvailabilitySlotsLazyQueryHookResult = ReturnType<
  typeof useAvailabilitySlotsLazyQuery
>
export type AvailabilitySlotsQueryResult = Apollo.QueryResult<
  AvailabilitySlotsQuery,
  AvailabilitySlotsQueryVariables
>
