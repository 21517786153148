import React from 'react'
import cn from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { ToggleSwitch } from '@babylon/medkit'
import messages from '../../MemberView.messages'
import styles from './styles.module.scss'
import { useTimezoneContext } from '../TimezoneProvider'

export const MembersTimeToggle = () => {
  const fm = useFormatMessage()

  const {
    toggleTimezone,
    timezoneToShow,
    timezone,
    error,
    memberAndAgentSameTimezone,
  } = useTimezoneContext()

  const isPatientTimezone = timezoneToShow === 'patient'
  const isToggleDisabled = error
  const displayMemberTimeSameAsAgent =
    memberAndAgentSameTimezone && !isPatientTimezone && !error

  return (
    <div data-testid="timezone-toggle">
      <div className={styles.wrapper}>
        <ToggleSwitch
          label={fm(messages.members_time)}
          checked={isPatientTimezone}
          onClick={() => toggleTimezone()}
          disabled={isToggleDisabled}
          className={styles.toggleSwitch}
        />
        <div className={cn(isPatientTimezone && styles.active)}>
          {fm(messages.members_time)}
        </div>
      </div>
      <div className={styles.timezone}>
        {isPatientTimezone && timezone.replace(/_/gi, ' ')}
        {displayMemberTimeSameAsAgent &&
          fm(messages.members_time_same_as_agent)}
        {isToggleDisabled && fm(messages.members_time_unknown)}
      </div>
    </div>
  )
}

export default MembersTimeToggle
