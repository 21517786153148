import { defineMessages } from 'react-intl'

const baseId = 'timeline_test_results_history'

export default defineMessages({
  history: {
    id: `${baseId}.history`,
    defaultMessage: 'History',
  },
})
