import React from 'react'
import styles from './styles.module.css'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
}

const Menu = React.forwardRef<HTMLDivElement, Props>(
  ({ children, style }: Props, ref) => (
    <div ref={ref} className={styles.dropdownMenu} style={style}>
      {children}
    </div>
  )
)

export default Menu
