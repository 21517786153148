import { gql } from '@apollo/client'

export const GetAppointmentClinicsQuery = gql`
  query getAppointmentClinics(
    $consultantType: String
    $consultant: ID
    $consultationType: String
    $memberId: ID
    $serviceTypeUuid: ID
  ) {
    appointmentClinics(
      consultantType: $consultantType
      consultant: $consultant
      consultationType: $consultationType
      memberId: $memberId
      serviceTypeUuid: $serviceTypeUuid
    ) {
      id
      name
      address
      full_address {
        address_line_1
        address_line_2
        address_line_3
        postal_code
        country_code
      }
      timezone_id
    }
  }
`
