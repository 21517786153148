import React, { useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { envFlag } from '@babylon/babylon-env'
import { Page } from '@babylon/core-ui'
import { LinkReactRouter } from '@babylon/medkit'

import {
  MultiStepForm,
  MultiStepFormPage,
  MedkitGlobalThemeProvider,
} from '../../components'

import CreateAppointmentInvite from './CreateInviteMutation'
import MemberQuery from './MemberQuery'
import SelectAppointment from './SelectAppointment'
import {
  arrangeAppointmentValidation,
  recommendedAppointmentValidation,
} from './arrangeAppointmentValidation'
import Recommendation from './Recommendation'
import InviteReview from './InviteReview'
import ArrangeAppointmentForm from './ArrangeAppointmentTypes'
import { OTHER_SERVICE_TYPE } from './utils'
import { useConsultantTypeOptionsForMember } from '../../hooks'
import InviteSentConfirmation from '../../SetupAppointment/CreateInvite/InviteSentConfirmation'
import ConsultationTypes from '../../types/ConsultationTypes'

import styles from './ArrangeAppointment.module.css'

export const baseValues = {
  member_uuid: 0,
  notes_for_member: '',
  allowed_professions: [],
  intimate: false,
  chaperone_required: false,
  preferred_profession: '',
  service_type_uuid: '',
  service_type_name: '',
  allowed_mediums: [],
  preferred_medium: '',
}

export default function ArrangeAppointment({
  skipLink,
}: {
  skipLink?: string
}) {
  const { id: memberId } = useParams<{ id: string }>()

  const { state } = useLocation()

  const [
    enrichedWithServiceType,
    setEnrichedWithServiceType,
  ] = useState<ArrangeAppointmentForm>({
    ...(baseValues as ArrangeAppointmentForm),
    ...(state?.enrichedWithServiceType || {}),
  })

  const isOtherServiceType =
    OTHER_SERVICE_TYPE.uuid === enrichedWithServiceType?.service_type_uuid

  const {
    consultantOptions,
    loading: consultantOptionsLoading,
  } = useConsultantTypeOptionsForMember(memberId, { skip: !isOtherServiceType })

  const { data: patientData } = useQuery(MemberQuery, {
    variables: { memberId },
  })

  const [createInvite, { loading: inviteSending, error, data }] = useMutation(
    CreateAppointmentInvite
  )

  const invite = data?.createAppointmentInvite

  return (
    <MedkitGlobalThemeProvider>
      <Page
        breadcrumbs={[
          <Link to="/admin/patients/search">Patients</Link>,
          <Link to={`/admin/patients/${memberId}/memberships`}>
            Member details
          </Link>,
          'Book Appointment',
        ]}
        title="Book Appointment"
        actions={
          !envFlag('ENABLE_CREATION_WITHOUT_SERVICE_TYPE', false) && (
            <LinkReactRouter to={skipLink}>Skip this page</LinkReactRouter>
          )
        }
      >
        <InviteSentConfirmation
          className={styles.ModalButton}
          memberId={memberId}
          isOpen={!inviteSending && !error && !!invite}
          serviceTypeName={invite?.service_type?.name}
          durationMinutes={`${invite?.duration_minutes} minutes`}
          notes={invite?.notes_for_member}
          intimate={invite?.intimate}
          medium={
            invite?.allowed_mediums
              ?.map((m) => ConsultationTypes[m])
              .join(', ') || ConsultationTypes[invite?.consultation_type]
          }
        />
        <MultiStepForm
          initialPage={state?.originalFilters ? 2 : 1}
          initialValues={{
            ...baseValues,
            ...(state?.originalFilters || {}),
          }}
          onSubmit={(values) => {
            if (!patientData?.patient?.uuid) {
              return
            }

            const createInviteForm = {
              member_uuid: patientData.patient.uuid,
              notes_for_member: values.notes_for_member,
              allowed_mediums: values.allowed_mediums,
              preferred_medium: values.preferred_medium,
              duration_minutes: values.duration_minutes,
              intimate: values.intimate,
              earliest_booking_date: new Date().toISOString(),
            } as ArrangeAppointmentForm

            if (values?.service_type_uuid === OTHER_SERVICE_TYPE.uuid) {
              createInviteForm.preferred_profession =
                values.preferred_profession
              createInviteForm.allowed_professions = values.allowed_professions
            } else {
              createInviteForm.service_type_uuid = values.service_type_uuid
            }

            createInvite({
              variables: {
                input: createInviteForm,
              },
            })
          }}
        >
          <MultiStepFormPage validationSchema={arrangeAppointmentValidation}>
            <SelectAppointment
              memberId={memberId}
              setEnrichedWithServiceType={setEnrichedWithServiceType}
            />
          </MultiStepFormPage>
          <MultiStepFormPage
            validationSchema={recommendedAppointmentValidation}
          >
            <Recommendation
              enrichedWithServiceType={enrichedWithServiceType}
              consultantOptions={consultantOptions}
              consultantOptionsLoading={consultantOptionsLoading}
              memberId={memberId}
            />
          </MultiStepFormPage>
          <MultiStepFormPage>
            <InviteReview
              memberId={memberId}
              inviteSending={inviteSending}
              consultantOptions={consultantOptions}
              enrichedWithServiceType={enrichedWithServiceType}
            />
          </MultiStepFormPage>
        </MultiStepForm>
      </Page>
    </MedkitGlobalThemeProvider>
  )
}
