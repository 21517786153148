import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {
  faVideo,
  faTrash,
  faPhoneAlt,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons'
import { envFlag } from '@babylon/babylon-env'
import { AppointmentStatus } from '..'
import { AppointmentStatusBadge } from '../AppointmentStatusBadge'
import { TimezoneDate } from '../../TimezoneDate'
import type { AppointmentProps } from './Appointment.types'
import styles from './Appointment.module.css'
import { ExpandButton } from '../ExpandButton'
import { ReactComponent as PhysicalIcon } from './physical.svg'
import { ReactComponent as TextIcon } from './text.svg'
import { PatientQuery, usePatientQuery } from './Patient.middleware.hooks'

const buildMembershipPageUrl = (patientId: string) =>
  `/admin/patients/${patientId}/memberships`

const buildConsultationPageUrl = (consultationId: string) =>
  `/admin/appointments/${consultationId}`

const ConsultationLink = ({
  consultationId,
  disabled,
}: {
  consultationId: string
  disabled: boolean
}) =>
  disabled ? (
    <div role="treeitem">{consultationId}</div>
  ) : (
    <a href={buildConsultationPageUrl(consultationId)} className={styles.link}>
      {consultationId}
    </a>
  )

const Appointment = ({
  id,
  babylonId,
  startTime,
  durationInMinutes,
  medium,
  timezone,
  isTranslatorRequired,
  status,
  patientId,
  patientBabylonId,
  patientName,
  patientConsumerNetworkId,
  isPatientMinor,
  appointmentReasons,
  bookingAgent,
  onCancel,
  trackShowDetails,
}: AppointmentProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const consentTagsEnabled: boolean = !!envFlag(
    'ENABLE_MEMBER_LEGAL_CONSENT_PREVIEW'
  )

  const { data: patientData } = usePatientQuery({
    skip: !patientId,
    variables: patientId
      ? {
          patientId,
          withNoticeDecisions: consentTagsEnabled,
        }
      : undefined,
  })

  const consumerNetwork = patientData?.patientConsumerNetworks.find(
    (patientConsumerNetwork) =>
      patientConsumerNetwork?.consumer_network?.uuid ===
      patientConsumerNetworkId
  )?.consumer_network

  const isFulfilled = status === AppointmentStatus.Fulfilled
  const isStatusCancelledOrNoShow =
    status === AppointmentStatus.Cancelled ||
    status === AppointmentStatus.Noshow
  const isCancellable = !isFulfilled && !isStatusCancelledOrNoShow

  const shouldShowConsentRequiredTag = (
    consentType: keyof Pick<
      PatientQuery,
      'informedConsent' | 'medicationHistory'
    >
  ) => {
    const memberDecision = patientData?.[consentType]

    return (
      consentTagsEnabled &&
      patientData != null &&
      (memberDecision == null ||
        memberDecision.expired ||
        !memberDecision.accepted)
    )
  }

  const hasAlerts = Boolean(patientData?.patient?.patient_alerts?.length)
  const hasTags =
    status === AppointmentStatus.Booked ||
    isPatientMinor ||
    isTranslatorRequired ||
    hasAlerts ||
    shouldShowConsentRequiredTag('informedConsent') ||
    shouldShowConsentRequiredTag('medicationHistory')

  const handleExpandButtonClick = () => {
    if (!isExpanded) {
      trackShowDetails(babylonId)
    }

    setIsExpanded((prevState) => !prevState)
  }

  const handleCancelButtonClick = () => {
    onCancel(id, babylonId)
  }

  return (
    <article className={styles.appointment}>
      <div className={styles.times}>
        <div className={styles.time}>
          {startTime ? (
            <TimezoneDate date={startTime} timezone={timezone} format="HH:mm" />
          ) : (
            '-'
          )}
        </div>
        <div className={styles.duration}>
          {durationInMinutes ? <span>{durationInMinutes} min</span> : '-'}
        </div>
      </div>

      <div className={styles.card}>
        <div
          className={classNames({
            [styles.type]: true,
            [styles.typeNonCancellable]: !isCancellable,
          })}
        >
          {medium === 'video' && (
            <FontAwesomeIcon icon={faVideo} title="Video appointment" />
          )}
          {medium === 'voice' && (
            <FontAwesomeIcon icon={faPhoneAlt} title="Phone appointment" />
          )}
          {medium === 'physical' && (
            <PhysicalIcon title="Physical appointment" />
          )}
          {medium === 'text' && <TextIcon title="Text appointment" />}
        </div>

        <div className={styles.detailContainer}>
          <header
            className={classNames({
              [styles.details]: true,
              [styles.detailsCollapsed]: isCancellable,
            })}
          >
            <div
              className={classNames({
                [styles.expander]: isCancellable,
              })}
            >
              <ExpandButton
                text="Show details"
                variant={isCancellable ? 'small' : 'large'}
                isExpanded={isExpanded}
                onClick={handleExpandButtonClick}
              />
            </div>

            {status && isStatusCancelledOrNoShow && (
              <AppointmentStatusBadge status={status} />
            )}

            {patientId && patientName ? (
              <a
                href={buildMembershipPageUrl(patientId)}
                className={styles.patientName}
              >
                {patientName}
              </a>
            ) : (
              '-'
            )}

            {consumerNetwork && (
              <div
                className={classNames({
                  [styles.tag]: true,
                  [styles.blueTag]: true,
                })}
              >
                {consumerNetwork?.name}
              </div>
            )}
          </header>

          {isExpanded && (
            <div
              data-testid="appointment-expanded-content"
              className={styles.expandContent}
            >
              <div className={styles.contentBox}>
                <div className={styles.contentArea}>
                  <div>
                    <h3 className={styles.subHeading}>Babylon ID</h3>
                    <div>
                      {patientBabylonId ? <>{patientBabylonId}</> : '-'}
                    </div>
                  </div>
                </div>

                <div className={styles.contentArea}>
                  <div>
                    {hasTags && (
                      <ul className={styles.tags}>
                        {isTranslatorRequired && (
                          <li
                            className={classNames({
                              [styles.tag]: true,
                              [styles.orangeTag]: true,
                            })}
                          >
                            Translator
                          </li>
                        )}

                        {isPatientMinor && (
                          <li
                            className={classNames({
                              [styles.tag]: true,
                              [styles.orangeTag]: true,
                            })}
                          >
                            Minor
                          </li>
                        )}

                        {hasAlerts && (
                          <li>
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              color="#d53232"
                              title="Patient has alerts"
                            />
                          </li>
                        )}

                        {status === AppointmentStatus.Booked && (
                          <li
                            className={classNames({
                              [styles.tag]: true,
                              [styles.blueTag]: true,
                            })}
                          >
                            Paid
                          </li>
                        )}
                        {shouldShowConsentRequiredTag('medicationHistory') ? (
                          <li
                            className={classNames({
                              [styles.tag]: true,
                              [styles.redTag]: true,
                            })}
                          >
                            AUTHORIZATION REQUIRED
                          </li>
                        ) : null}
                        {shouldShowConsentRequiredTag('informedConsent') ? (
                          <li
                            className={classNames({
                              [styles.tag]: true,
                              [styles.redTag]: true,
                            })}
                          >
                            CONSENT REQUIRED
                          </li>
                        ) : null}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.contentBox}>
                <div className={styles.contentArea}>
                  <div>
                    <h3 className={styles.subHeading}>Appointment reason</h3>

                    {appointmentReasons.length ? (
                      <ul className={styles.reasonsList}>
                        {appointmentReasons.map((reason) => (
                          <li key={reason}>{reason}</li>
                        ))}
                      </ul>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>

                <div className={styles.contentArea}>
                  <div>
                    <h3 className={styles.subHeading}>Consultation ID</h3>

                    <div>
                      {babylonId ? (
                        <ConsultationLink
                          consultationId={babylonId}
                          disabled={!isFulfilled}
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>

                  <div>
                    <h3 className={styles.subHeading}>Booking Agent</h3>

                    <div>{bookingAgent ?? '-'}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={styles.buttons}>
          {isCancellable && (
            <button
              type="button"
              onClick={handleCancelButtonClick}
              className={styles.cancelButton}
            >
              <FontAwesomeIcon icon={faTrash} />

              <span className={styles.hide}>Cancel appointment</span>
            </button>
          )}
        </div>
      </div>
    </article>
  )
}
export default Appointment
