import { defineMessages } from 'react-intl'

const baseId = 'service_errors'

export default defineMessages({
  heading: {
    id: `${baseId}.heading`,
    defaultMessage: "We can't load all the patient's information",
  },
  body: {
    id: `${baseId}.body`,
    defaultMessage: 'We are unable to show the following service(s):',
  },
  appointments: {
    id: `${baseId}.appointments`,
    defaultMessage: 'Appointments',
  },
  workflows: {
    id: `${baseId}.workflows`,
    defaultMessage: 'Workflows',
  },
  repeatTemplates: {
    id: `${baseId}.repeatTemplates`,
    defaultMessage: 'Repeat prescriptions',
  },
  next_steps: {
    id: `${baseId}.next_steps`,
    defaultMessage: 'Refresh the page, or contact your Team Lead.',
  },
})
