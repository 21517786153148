import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useField } from 'formik'
import OutsideClickHandler from 'react-outside-click-handler'
import { Input, Label, DropdownOptions } from '@babylon/core-ui'

import GetClinicianQuery from './GetClinicianQuery'
import getConsultantsPerId from './GetConsultantQuery'

import { fieldLabelMap } from '../ClinicianVisibilitySearchValidation'

import { MultiTag } from '../../../components'
import { useDebounce } from '../../../hooks'

import styles from './ClinicianNameInput.module.css'

export default function ClinicianNameInput() {
  const formField = 'clinicianInfo'
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const debouncedValue = useDebounce(searchValue, 350)
  const [{ value }, { error }, { setValue }] = useField(formField)
  const { data } = useQuery(GetClinicianQuery, {
    variables: {
      name: debouncedValue,
    },
    skip: !debouncedValue,
  })

  // Only request Clinician info if information is missing from component state, e.g. page refresh
  const missingCliniciansId = value
    ?.filter((clinician) => !clinician?.name)
    ?.map((clinician) => clinician?.id)

  const { data: consultant } = useQuery(getConsultantsPerId, {
    variables: { id: missingCliniciansId },
    skip: !missingCliniciansId?.length,
  })

  /*
   * Formik has an issue with setValue where it has a diferent reference on every render
   * If it was part of the dependencies of the effect, it would make it run always.
   * cf. https://github.com/jaredpalmer/formik/issues/2268
   */
  useEffect(() => {
    if (consultant) {
      setValue(consultant?.getConsultantsPerId ?? [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultant])

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  const onSuggestionClick = ({ key, value: clinicianName }) => {
    const clinicianAlreadyExists = value.find(
      ({ id: clinicianId }) => clinicianId === key
    )

    if (!clinicianAlreadyExists) {
      setValue([
        ...value,
        {
          id: key,
          name: clinicianName,
        },
      ])
    }

    setIsOpen(false)
    setSearchValue('')
  }

  return (
    <>
      <OutsideClickHandler
        disabled={!isOpen}
        onOutsideClick={() => {
          setSearchValue('')
          setIsOpen(false)
        }}
      >
        <Label htmlFor="clinician-name-input">{fieldLabelMap[formField]}</Label>
        <Input
          id="clinician-name-input"
          data-testid="clinician-name-input"
          onFocus={() => setIsOpen(true)}
          onChange={handleChange}
          value={searchValue}
          fill
          intent={error ? 'error' : undefined}
        />
        {isOpen && data?.getEligibleConsultants?.length > 0 && (
          <div className={styles.SuggestionContainer}>
            <DropdownOptions
              options={data?.getEligibleConsultants?.map((clinician) => ({
                key: clinician.id,
                value: clinician.name,
              }))}
              onOptionSelect={onSuggestionClick}
            />
          </div>
        )}
      </OutsideClickHandler>
      <MultiTag
        tags={value?.map((clinician) => ({
          key: clinician?.id,
          name: clinician?.name,
        }))}
        onChange={(tags) =>
          setValue(
            tags.map((tag) => ({
              id: tag.key,
              name: tag.name,
            }))
          )
        }
      />
    </>
  )
}
