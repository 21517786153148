import { gql } from '@apollo/client'

export default gql`
  query getClinicsPerId($ids: [ID]) {
    practicesByIds(ids: $ids) {
      id
      name
    }
  }
`
