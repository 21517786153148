import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import { envUrl } from '@babylon/babylon-env'
import { LinkAnchor, LinkButton } from '@babylon/medkit'
import { useProductConfig } from '@babylon/product-config'
import { format } from 'date-fns'
import Select from 'react-select'

import { Button, Cell, Grid, Text } from '@babylon/core-ui'
import { logException, useSnackBar } from '@babylon/member-operations'
import showConfirmDialog from '@/components/ConfirmDialog'

import styles from './styles.module.scss'
import messages from './ConsultationPatientListItem.messages'

import { UPDATE_SEND_FAX } from '../../mutations'

const ConsultationPatientListItem = ({
  item: {
    id,
    consultationId,
    scheduledTime,
    sentToGp,
    consumerNetworkName,
    patient: {
      __typename,
      id: patientId,
      first_name: firstName,
      last_name: lastName,
      gp_surgery_name: practiceName,
      gp_address_first_line: addressFirstLine,
      gp_address_second_line: addressSecondLine,
      gp_address_third_line: addressThirdLine,
      email,
      public_healthcare_identifier: publicHealthcareIdentifier,
    },
  },
  faxNumbers,
  handleUpdateItemSummarySent,
}) => {
  const fm = useFormatMessage()
  const [selectedFaxNumber, setSelectedFaxNumber] = useState('')
  const markSentLabel = sentToGp
    ? fm(messages.mark_as_unsent_button_label)
    : fm(messages.mark_as_sent_button_label)
  const { getProp } = useProductConfig()
  const isFaxEnabled = getProp(
    'memberOperations',
    'consultationSummaryFaxEnabled'
  )
  const gridColumns = isFaxEnabled
    ? '1fr 1fr 1fr 1fr 1fr 135px'
    : '1fr 1fr 1fr 1fr 135px'

  const [gpSurgerySendFax] = useMutation(UPDATE_SEND_FAX)

  const { setSnackbarMessage } = useSnackBar()

  const selectFaxNumber = ({ value }) => setSelectedFaxNumber(value)

  const handleSendFax = async () => {
    if (selectedFaxNumber) {
      try {
        await gpSurgerySendFax({
          variables: {
            id,
            faxNumberId: selectedFaxNumber,
          },
        })
        setSnackbarMessage(
          `Appointment #${id} faxed and moved to the “Archived Appointments”.`
        )
        handleSummarySent()
      } catch (error) {
        setSnackbarMessage(
          `Appointment #${id} was not faxed.`,
          undefined,
          'error',
          99999999999
        )
        console.log(error)
      }
    }
  }

  const handleSummarySent = async () => {
    try {
      showConfirmDialog(fm(messages.mark_as_sent_confirmation))
      await handleUpdateItemSummarySent(consultationId, !sentToGp)
    } catch (error) {
      logException(error)
    }
  }

  return (
    <Grid
      columns={isFaxEnabled ? '6' : '5'}
      templateColumns={gridColumns}
      gap={24}
      className={styles.consultationGrid}
    >
      <Cell>
        <Text
          tag="div"
          size="small"
          className={styles.groupTitle}
          bold
          uppercase
        >
          {fm(messages.patient_info_title)}
        </Text>
        {__typename !== 'Patient' ? (
          <Text tag="div" size="large" color="error" bold>
            {fm(messages.error_loading_patient)}
          </Text>
        ) : (
          <>
            <Text tag="div" size="large">
              {firstName} {lastName}
            </Text>
            <Text tag="div" size="large">
              {fm(messages.user_id)} {patientId}
            </Text>
            <Text tag="div" size="large">
              {fm(messages.healthcare_id)} {publicHealthcareIdentifier}
            </Text>
          </>
        )}
      </Cell>

      <Cell>
        <Text
          tag="div"
          size="small"
          className={styles.groupTitle}
          bold
          uppercase
        >
          {fm(messages.consultation_info_title)}
        </Text>
        {scheduledTime && (
          <>
            <Text tag="div" size="large">
              {format(new Date(scheduledTime), 'dd MMM yyyy')},
            </Text>
            <Text tag="div" size="large">
              {format(new Date(scheduledTime), 'p')}
            </Text>
          </>
        )}
      </Cell>

      <Cell>
        <Text
          tag="div"
          size="small"
          className={styles.groupTitle}
          bold
          uppercase
        >
          {fm(messages.consumer_network_group_title)}
        </Text>
        <Text tag="div" size="large">
          {consumerNetworkName}
        </Text>
      </Cell>

      <Cell>
        <Text
          tag="div"
          size="small"
          className={styles.groupTitle}
          bold
          uppercase
        >
          {fm(messages.gp_practice_group_title)}
        </Text>

        {practiceName && (
          <Text tag="div" size="large">
            {practiceName}
          </Text>
        )}
        {addressFirstLine && (
          <Text tag="div" size="large">
            {addressFirstLine}
          </Text>
        )}
        {addressSecondLine && (
          <Text tag="div" size="large">
            {addressSecondLine}
          </Text>
        )}
        {addressThirdLine && (
          <Text tag="div" size="large">
            {addressThirdLine}
          </Text>
        )}

        {email && (
          <Button
            href={`mailto:${email}`}
            intent="link"
            className={styles.emailLink}
          >
            {email}
          </Button>
        )}
      </Cell>

      {isFaxEnabled && (
        <Cell>
          <Text
            tag="div"
            size="small"
            className={styles.groupTitle}
            bold
            uppercase
          >
            {fm(messages.fax_number_title)}
          </Text>
          <Select
            placeholder={fm(messages.search_fax_placeholder)}
            value={selectedFaxNumber}
            options={faxNumbers}
            onChange={selectFaxNumber}
            clearable={false}
          />
        </Cell>
      )}

      <Cell>
        <Text
          tag="div"
          size="small"
          className={styles.groupTitle}
          bold
          uppercase
        >
          {fm(messages.actions_title)}
        </Text>
        <LinkAnchor
          data-testid="download-pdf"
          target="_blank"
          rel="noopener noreferrer"
          href={`${envUrl(
            'ADMIN_PORTAL_FRAME_LEGACY_PORTAL_URL'
          )}admin/appointments/${id}/pdf`}
        >
          {fm(messages.download_button_label)}
        </LinkAnchor>
        <LinkButton onClick={handleSummarySent}>{markSentLabel}</LinkButton>
        <LinkButton disabled={!isFaxEnabled} onClick={handleSendFax}>
          {fm(messages.send_and_complete_button_label)}
        </LinkButton>
      </Cell>
    </Grid>
  )
}

export default ConsultationPatientListItem
