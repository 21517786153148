import React from 'react'

import { withFormContext } from '@babylon/babylon-forms/forms'
import { MoreOptions, Option } from '@babylon/babylon-forms/widgets'
import LabelWithIcon from '@/components/LabelWithIcon'

import actionTypes from './actionTypes.json'

import styles from './styles.module.scss'

const actionBlacklist = {
  ACCEPT_EPS_CODE: true,
  REJECT_PRESCRIPTION_DISPENSING: true,
  MARK_DRUGS_DISPATCHED: true,
}

const PrescriptionActions = ({ actions, onAction, 'data-context': ctx }) =>
  actions.length > 0 ? (
    <MoreOptions
      small
      label={<LabelWithIcon icon="edit" label="Actions" />}
      className={styles.options}
    >
      {actions
        .filter((action) => {
          if (!actionTypes[action]) {
            console.warn(`Unknown prescription action: ${action}`)

            return false
          }

          return !actionBlacklist[action]
        })
        .map((action) => (
          <Option key={action} onClick={() => onAction(action, ctx)}>
            <LabelWithIcon
              icon={actionTypes[action].icon}
              label={actionTypes[action].label}
            />
          </Option>
        ))}
    </MoreOptions>
  ) : null

export default withFormContext(PrescriptionActions)
