import { useEffect, useCallback } from 'react'
import { FormikContextType } from 'formik'

import ConsultationTypes from '../../types/ConsultationTypes'
import ArrangeAppointmentForm from './ArrangeAppointmentTypes'

type UseHandleResetParam = {
  enrichedWithServiceType: ArrangeAppointmentForm
  values: ArrangeAppointmentForm
  setValues: FormikContextType<ArrangeAppointmentForm>['setValues']
}

const useHandleReset = ({
  setValues,
  enrichedWithServiceType,
  values,
}: UseHandleResetParam) => {
  const { preferred_medium: preferredMedium } = enrichedWithServiceType

  const handleReset = useCallback(
    () =>
      setValues({
        ...enrichedWithServiceType,
        allowed_mediums: preferredMedium ? [preferredMedium] : [],
      }),
    [enrichedWithServiceType, preferredMedium, setValues]
  )

  useEffect(() => {
    const isTouched =
      values.allowed_mediums !== enrichedWithServiceType.allowed_mediums

    !isTouched && handleReset()
  }, [handleReset, values, enrichedWithServiceType])

  return handleReset
}

export { useHandleReset }

export const OTHER_SERVICE_TYPE = {
  uuid: 'other',
  name: 'Other',
  allowed_mediums: Object.keys(ConsultationTypes).filter(
    (type) => type !== 'text'
  ),
  default_duration_minutes: 5,
}
