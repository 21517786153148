import React from 'react'
import { DatePicker, registerLocale } from '@babylon/core-ui'
import { useIntl } from '@babylon/intl'
import enGB from 'date-fns/locale/en-GB'

import styles from '../styles.module.css'

export interface DayCalendarProps {
  selectedDate: string
  selectedDateUpdate: (arg: Date | null) => {}
}

registerLocale('en-GB', enGB)

export default function DayCalendar({
  selectedDate,
  selectedDateUpdate,
}: DayCalendarProps) {
  const intl = useIntl()

  return (
    <div className="bookAppointmentCalendar" data-testid="day-calendar-view">
      <DatePicker
        calendarClassName={styles.calendar}
        dayClassName={() => styles.calendarDay}
        inline
        locale={intl.locale}
        selected={new Date(selectedDate)}
        onChange={(date) => selectedDateUpdate(date)}
        minDate={new Date()}
      />
    </div>
  )
}
