import React from 'react'

import { Spinner } from '@babylon/core-ui'
import { ButtonSimple } from '@babylon/medkit'

import { AccountTags, getMembersAge, Unknown } from '@babylon/member-operations'
import { useFormatMessage } from '@babylon/intl'
import styles from './styles.module.css'
import { PatientProfile } from '../../usePatientProfile'
import Text from './Text'
import { capitalizeFirstLetter, intentFromTags } from '../../../../utils'

type Props = {
  patientProfile: PatientProfile | undefined
  loading?: boolean
  error: boolean
  tags: string[]
  assignmentConfirming?: boolean
  onConfirmAssignment: () => void
  patientId: string | undefined
}

const ConfirmAssignment = ({
  patientProfile,
  onConfirmAssignment,
  tags,
  error,
  assignmentConfirming = false,
  loading = false,
  patientId,
}: Props) => {
  const fm = useFormatMessage()

  if (loading && !error) {
    return (
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.cardSpinner}>
            <Spinner />
          </div>
        </div>
        <ButtonSimple
          className={styles.confirmButton}
          onClick={onConfirmAssignment}
          isLoading={assignmentConfirming}
          loadingLabel="Confirming assignment"
        >
          Start conversation
        </ButtonSimple>
        <Text>
          If you go offline the member will be matched with the next available
          agent
        </Text>
      </div>
    )
  }

  const intent = capitalizeFirstLetter(intentFromTags(tags))

  return (
    <div className={styles.container}>
      {intent ? (
        <Text md className={styles.intent}>
          New member request:{' '}
          <Text md bold inline className={styles.quoted}>
            {intent}
          </Text>
        </Text>
      ) : null}
      {patientProfile ? (
        <div className={styles.card}>
          <AccountTags
            familyAccount={patientProfile.hasFamilyAccounts}
            familyAccountOwner={patientProfile.hasFamilyAccountsOwner}
            idVerificationStatus={patientProfile.identityCheck || null}
            minor={patientProfile.isMinor}
            pdsStatus={patientProfile.pdsStatus}
          />
          <Text lg highlight underline className={styles.memberName}>
            {patientProfile?.summarizedName}
          </Text>
          <div className={styles.columns}>
            <div className={styles.patientDetailsColumn}>
              <Text>
                <Text highlight inline>
                  {patientProfile?.birthDate}
                </Text>
                <Text inline>
                  , {patientProfile?.gender}{' '}
                  {getMembersAge(patientProfile.birthDate || null, fm) || (
                    <Unknown />
                  )}
                </Text>
              </Text>

              <Text highlight ellipsis>
                {patientProfile?.emailAddress}
              </Text>
              <Text highlight>+1 (603) 432-6425</Text>
            </div>
            <div className={styles.idsColumn}>
              <div className={styles.labels}>
                <Text sm bold>
                  Babylon
                </Text>
                <Text sm bold>
                  Public
                </Text>
                <Text sm bold>
                  Private
                </Text>
                <Text sm bold>
                  UUID
                </Text>
              </div>
              <div className={styles.values}>
                <Text sm highlight ellipsis>
                  {patientProfile?.babylonId}
                </Text>
                <Text sm ellipsis>
                  {patientProfile?.identifiers.public || 'UNKNOWN'}
                </Text>
                <Text sm ellipsis>
                  {patientProfile?.identifiers.private || 'UNKNOWN'}
                </Text>
                <Text sm ellipsis highlight>
                  {patientProfile?.id}
                </Text>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.card}>
          <Text lg highlight className={styles.memberName}>
            Member UUID: {patientId}
          </Text>
        </div>
      )}

      <ButtonSimple
        className={styles.confirmButton}
        onClick={onConfirmAssignment}
        isLoading={assignmentConfirming}
        loadingLabel="Confirming assignment"
      >
        Start conversation
      </ButtonSimple>
      <Text>
        If you go offline the member will be matched with the next available
        agent
      </Text>
    </div>
  )
}

export default ConfirmAssignment
