import React from 'react'
import cn from 'classnames'
import { Tooltip } from '@babylon/core-ui'
import styles from './Tag.module.scss'

type Props = {
  className?: string
  'data-testid'?: string
  label: string
  type?: TagTypes
  variant?: TagVariants
}

export enum TagTypes {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum TagVariants {
  SUBTLE = 'subtle',
  OUTLINE = 'outline',
  SOLID = 'solid',
}

/**
 * Component renders a tag, with a tooltip displaying the
 * full tag label when it has to truncate the inline label.
 *
 * @example ```
 *  <Tag label="Babylon" />
 * ```
 *
 */
const Tag = ({
  className,
  'data-testid': dataTestId,
  label,
  type = TagTypes.DEFAULT,
  variant = TagVariants.SUBTLE,
}: Props) => (
  <Tooltip message={label} placement="top">
    <span
      className={cn([
        styles.Tag,
        styles[`TagType__${type}`],
        styles[`TagVariant__${variant}`],
        className,
      ])}
      data-testid={dataTestId}
    >
      {label}
    </span>
  </Tooltip>
)

export default Tag
