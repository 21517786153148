import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilter as fasFilter } from '@fortawesome/pro-solid-svg-icons'
import {
  faSortAmountDown as farSortAmountDown,
  faFilter as farFilter,
} from '@fortawesome/pro-regular-svg-icons'

library.add(farFilter)
library.add(fasFilter)
library.add(farSortAmountDown)
