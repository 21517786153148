import React from 'react'
import { Card, Text } from '@babylon/core-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { colors } from '@babylon/core-ui/src/theme'
import styles from './NoResultsCard.module.css'

interface NoResultsCardInterface {
  'data-testid'?: string
  visible: boolean
}

export default function NoResultsCard({
  'data-testid': dataTestId,
  visible,
}: NoResultsCardInterface) {
  if (!visible) {
    return null
  }

  return (
    <Card
      data-testid={dataTestId || 'no-results-card'}
      className={styles.notFound}
    >
      <FontAwesomeIcon
        icon={faSearch}
        size="3x"
        color={colors['highlight-violet-type']}
      />
      <Text
        color="highlight-violet-type"
        size="large"
        className={styles.notFoundText}
      >
        No results
      </Text>
    </Card>
  )
}
