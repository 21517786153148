import React from 'react'
import { format, getDate, isBefore, startOfDay } from 'date-fns'
import cn from 'classnames'
import styles from './DateSelector.module.css'

export const DATE_FORMAT = 'yyyy-MM-dd'

export const hasShiftsOrIncompleteConsultations = (
  date: Date,
  shifts: string[],
  incompleteConsulations: string[]
) => {
  const formattedDate = format(date, DATE_FORMAT)
  const startOfToday = startOfDay(new Date())
  const isBeforeToday = isBefore(new Date(formattedDate), startOfToday)

  return {
    hasShifts: shifts.includes(formattedDate),
    hasIncompleteConsultations:
      incompleteConsulations.includes(formattedDate) && isBeforeToday,
  }
}

export const getDayType = (
  hasIncompleteConsultations: boolean,
  hasShifts: boolean
) => {
  if (hasIncompleteConsultations) {
    return 'incomplete-consultations'
  }

  if (hasShifts) {
    return 'shifts'
  }

  return ''
}

export const renderDayContents = (
  shifts: string[],
  incompleteConsultations: string[]
) => (day: Number, date: Date): JSX.Element | null => {
  const {
    hasIncompleteConsultations,
    hasShifts,
  } = hasShiftsOrIncompleteConsultations(date, shifts, incompleteConsultations)
  const hasEvents = hasShifts || hasIncompleteConsultations ? '-with-' : ''
  const dayType = getDayType(hasIncompleteConsultations, hasShifts)

  const label = `day${hasEvents}${dayType}`

  return <span aria-label={label}>{getDate(date)}</span>
}

export const computeDayClassName = (
  date: Date,
  shifts: string[],
  incompleteConsultations: string[]
): string | null => {
  const { hasShifts } = hasShiftsOrIncompleteConsultations(
    date,
    shifts,
    incompleteConsultations
  )

  return cn(hasShifts && styles.dayHasShift)
}
