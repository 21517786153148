import React from 'react'
import {
  LegacyFormikDropdown,
  LegacyFormikDropdownOption,
  LegacyFormikDropdownOptionValues,
} from '@babylon/core-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'
import { ConsultationTypeName } from '../../types/ConsultationTypes'

const valueRenderedWithPlaceholder = (opt: LegacyFormikDropdownOption) => (
  <span>
    <span className="value">
      <span>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <span style={{ marginLeft: 5 }}>{opt.label}</span>
      </span>
    </span>
    <span
      className="Select-placeholder"
      placeholder="Search/Select Appointment Clinic"
    />
  </span>
)

const anyClinic = {
  value: 'any',
  label: 'Any clinic',
}

const getClinicsOptionsForConsultantionType = (
  clinics: Practice[],
  consultationType: ConsultationTypeName
) => [
  ...(consultationType === 'physical' ? [anyClinic] : []),
  ...clinics.map((clinic) => {
    let addressStr = ''

    if (clinic.full_address) {
      addressStr = [
        clinic.full_address.address_line_1,
        clinic.full_address.address_line_2,
        clinic.full_address.address_line_3,
        clinic.full_address.postal_code,
      ]
        .filter((isAddressValid) => isAddressValid)
        .join(' ')
    } else if (clinic?.address) {
      addressStr = clinic.address.replace(/\\n/g, ' ')
    }

    return {
      value: clinic.id,
      label: `${clinic.name} - ${addressStr}`,
    }
  }),
]

interface Props {
  className?: string
  disabled?: boolean
  onChange: (option: LegacyFormikDropdownOptionValues | null) => void
  appointmentClinicList: Practice[]
  loading?: boolean
  consultationType: ConsultationTypeName
}

export default function AppointmentClinicSelector({
  className,
  appointmentClinicList,
  onChange,
  consultationType,
  loading,
  disabled,
}: Props) {
  return (
    <div className={className} data-testid="appointment-clinic-selector">
      <LegacyFormikDropdown
        id="clinic_id"
        name="appointmentClinic"
        label="Clinic"
        options={getClinicsOptionsForConsultantionType(
          appointmentClinicList,
          consultationType
        )}
        onChange={(option: LegacyFormikDropdownOption | null) =>
          onChange(option?.value || null)
        }
        valueRenderer={valueRenderedWithPlaceholder}
        noResultsText="No matching results"
        backspaceRemoves={false}
        disabled={loading || disabled}
        isLoading={loading}
        searchable
      />
    </div>
  )
}
