import React from 'react'
import { withFormContext, compose } from '../forms'

class ValidStateTracker extends React.Component {
  componentDidMount() {
    this.handleValidStateChange()
  }

  shouldComponentUpdate(nextProps) {
    const {
      'data-context': { isValid },
    } = this.props
    const {
      'data-context': { isValid: nextIsValid },
    } = nextProps

    return isValid !== nextIsValid
  }

  componentDidUpdate() {
    this.handleValidStateChange()
  }

  // Render should be a pure function which does not generate any side-effect.
  // Therefore we need to go for didMount/didUpdate combo in order
  // to dispatch `onValidStateChange`.
  handleValidStateChange() {
    const {
      onValidStateChange,
      'data-context': { isValid },
    } = this.props

    onValidStateChange(isValid)
  }

  render() {
    return null
  }
}

ValidStateTracker.defaultProps = {
  onValidStateChange() {},
}

export default compose(withFormContext)(ValidStateTracker)
