import { gql } from '@apollo/client'

export default gql`
  query CreateAppointment($memberId: ID!, $includingDigital: Boolean) {
    consultantTypes(memberId: $memberId)
    consultationTypes(memberId: $memberId)
    patient(id: $memberId) {
      id
      uuid
      first_name
      last_name
      address_post_code
      date_of_birth
      preferred_consumer_network {
        id
        enable_recording_consent
        language_requested
      }
      service_types(including_digital: $includingDigital) {
        uuid
        name
        default_duration_minutes
        intimate
        member_instructions
        grouping
        first_available_slot {
          time
          clinician_id
          clinic_id
        }
      }
    }
  }
`
