import React from 'react'
import Select from 'react-select'
import cx from 'classnames'

import Icon from '@/components/Icon'
import Form from '@/components/old-forms/Form'
import ActionsView from '@/components/old-forms/ListView/ActionsView'
import DetailsView from './DetailsView'

import styles from './RegionsViewWidget.module.scss'

const ItemView = ({
  onClick,
  item: {
    region: { regionCode, name },
    _isNew,
  },
  className,
}) => (
  // TO-DO: find solution for accessibility
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <li
    onClick={() => onClick && onClick(regionCode)}
    className={cx(className, _isNew && styles.newRegion)}
  >
    <div>
      <strong>{regionCode}</strong>
    </div>
    {name}
  </li>
)

const RegionsViewWidget = ({
  name,
  label,
  form,
  value,
  state,
  formProps: {
    drugInfo,
    plugins,
    selectedRegion,
    updateSelectedRegion,
    newRegion,
    updateNewRegion,
    insertAlert,
  },
  toolbar,
  ...rest
}) => {
  const defaultOptions = [{ label: 'N/A', value: 'N/A' }]
  form.fields[1].insertRow.columns[1].options =
    drugInfo.drugForms || defaultOptions
  form.fields[1].insertRow.columns[2].options =
    drugInfo.drugUnits || defaultOptions
  const details = selectedRegion ? (
    <span>
      {selectedRegion._isNew ? (
        <button
          className={styles.deleteButton}
          onClick={() =>
            rest.actions.onAction(
              'deleteRegion',
              selectedRegion.region.regionCode
            )
          }
        >
          <Icon name="delete" />
        </button>
      ) : null}
      <Form
        key={selectedRegion.region.regionCode}
        className={cx(insertAlert && styles.insertAlert)}
        title={selectedRegion.region.name}
        plugins={plugins}
        config={form}
        state={{
          flags: selectedRegion,
          packaging: selectedRegion.packaging,
        }}
        actions={{
          onChange: (name, value) =>
            rest.actions.onAction('updateFlags', value),
          onAction: rest.actions.onAction,
        }}
      />
    </span>
  ) : null
  const selectedRegionCodes = value.map((item) => item.region.regionCode)
  const tb = (
    <div>
      <Select
        className={styles.regionSelect}
        value={newRegion}
        placeholder="GBR"
        onChange={updateNewRegion}
        valueKey="name"
        labelKey="regionCode"
        clearable={false}
        optionRenderer={(option) => (
          <span>
            <strong>{option.regionCode}</strong> - {option.name}
          </span>
        )}
        options={drugInfo.prescriptionRegions || []}
        filterOptions={(options, filter) => {
          const lwFilter = filter.toLowerCase()

          return options.filter(
            (item) =>
              `${item.regionCode}${item.name}`
                .toLowerCase()
                .includes(lwFilter) &&
              !selectedRegionCodes.includes(item.regionCode)
          )
        }}
      />
      <ActionsView actions={rest.actions} buttons={toolbar} />
    </div>
  )

  return (
    <div className="babylon-component-library__asFormControl__control">
      <label className="label">{label}</label>
      <div className={styles.regionsViewWrapper}>
        <DetailsView
          {...rest}
          onClick={(regionCode) => {
            updateSelectedRegion(
              value.find((item) => item.region.regionCode === regionCode)
            )
          }}
          data={value}
          selected={selectedRegion}
          ItemView={ItemView}
          details={details}
          toolbar={tb}
        />
      </div>
    </div>
  )
}

export default RegionsViewWidget
