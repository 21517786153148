import React from 'react'
import classNames from 'classnames'
import { Box, Text, TextVariant } from '@babylon/medkit'

import styles from './MultiLineText.module.scss'

const MultiLineText = ({ className, text, variant = 'body' }: Props) => (
  <Box className={styles.container}>
    {text.split(/\\n|\n/).map((line) => (
      <Text
        className={classNames(styles.line, className)}
        data-testid="multi_line_text"
        key={line}
        variant={variant}
      >
        {line}
      </Text>
    ))}
  </Box>
)

interface Props {
  className?: string
  text: string
  variant?: TextVariant
}

export default MultiLineText
