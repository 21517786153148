import { useReducer } from 'react'

export interface ActiveThread {
  title?: string
  id?: string
}

export interface SetActiveThread {
  title: string
  id: string
}

export enum Actions {
  SET_ACTIVE_THREAD = 'SET_ACTIVE_THREAD',
  RESET_ACTIVE_THREAD = 'RESET_ACTIVE_THREAD',
}

export interface ActiveThreadReducerArgs {
  type: Actions
  payload?: SetActiveThread
}

export function activeThreadReducer(
  state: ActiveThread,
  action: ActiveThreadReducerArgs
) {
  switch (action.type) {
    case Actions.SET_ACTIVE_THREAD:
      if (!action.payload) {
        return state
      }
      return {
        ...state,
        id: action.payload.id,
        title: action.payload.title,
      }
    case Actions.RESET_ACTIVE_THREAD:
      return {}
    default:
      return state
  }
}

export default function useActiveThread(
  initialState?: ActiveThread
): [
  activeThread: ActiveThread,
  resetActiveThread: () => void,
  setActiveThread: (payload: SetActiveThread) => void
] {
  const [state, dispatch] = useReducer(activeThreadReducer, initialState || {})

  const setActiveThread = (payload: SetActiveThread) =>
    dispatch({ type: Actions.SET_ACTIVE_THREAD, payload })

  const resetActiveThread = () =>
    dispatch({ type: Actions.RESET_ACTIVE_THREAD })

  return [state, resetActiveThread, setActiveThread]
}
