import { isSameDay } from 'date-fns'
import { format } from 'date-fns-tz'

import {
  DataSlot,
  GroupedSlots,
  Header,
  flattenClinicianAvailability,
  formatHeaderDate,
  getTimezoneInfoFromUTCTimestamp,
} from './GroupSlots'

interface Address {
  line1: string
  line2?: string
  line3?: string
  postalCode: string
}

export interface Clinic {
  id: number
  address?: string
  fullAddress?: Address
  name: string
  timezone_id?: string
}

interface ClinicIndex {
  [id: string]: Clinic
}

export const buildClinicIndex = (clinicList: Clinic[] = []): ClinicIndex =>
  clinicList.reduce((acc, clinic) => ({ ...acc, [clinic.id]: clinic }), {})

export const buildClinicHeaders = (
  clinicIds: string[] = [],
  clinics: ClinicIndex = {},
  date: Date
): Header[] =>
  clinicIds
    .map((clinicId) => ({
      key: clinicId,
      ...formatHeaderDate(date),
      clinicName: clinics[clinicId]?.name || '',
      clinicTimezone: format(date, 'OOO', {
        timeZone: clinics[clinicId]?.timezone_id,
      }),
    }))
    .sort((prev, next) => {
      if (prev.clinicName < next.clinicName) {
        return -1
      }

      if (prev.clinicName > next.clinicName) {
        return 1
      }

      return 0
    })

const groupByClinic = (
  slots: DataSlot[] = [],
  selectedDate: string,
  clinicList: Clinic[] = [],
  selectedClinicianId?: string
): GroupedSlots => {
  const slotIndex = {}
  const times = new Set<string>()

  const clinicIds = new Set<string>()

  const clinics = buildClinicIndex(clinicList)

  slots.forEach((slot) => {
    const slotClinicIds = new Set<string>()

    if (slot.consultantsAvailability) {
      slot.consultantsAvailability.forEach((availability) => {
        // Filter clinics that are not in the dropdown
        // due to an potential issue in service that brings slots
        // with clinics not associated to the service type
        if (availability.clinicId && clinics[availability.clinicId]) {
          slotClinicIds.add(`${availability.clinicId}`)
        }
      })
    }

    slotClinicIds.forEach((clinicId) => {
      const timezoneId = clinics[clinicId]?.timezone_id
      const { date, time, zonedTime } = getTimezoneInfoFromUTCTimestamp(
        slot.UTCTimestamp,
        timezoneId
      )

      if (
        !isSameDay(zonedTime, new Date(selectedDate)) ||
        !isSameDay(zonedTime, new Date(slot.UTCTimestamp))
      ) {
        return
      }

      let consultants = (slot.consultantsAvailability || [])
        .filter((availability) => `${availability.clinicId}` === clinicId)
        .map(flattenClinicianAvailability)

      if (selectedClinicianId) {
        consultants = consultants.filter(
          (consultant) => `${consultant.id}` === selectedClinicianId
        )
      }

      if (consultants.length > 0) {
        times.add(time)
        clinicIds.add(clinicId)

        slotIndex[clinicId] = slotIndex[clinicId] || {}
        slotIndex[clinicId][time] = {
          available: slot.available,
          clinicId,
          consultants,
          date,
          time,
          UTCTimestamp: slot.UTCTimestamp,
        }
      }
    })
  })

  return {
    headers: buildClinicHeaders(
      Array.from(clinicIds),
      clinics,
      new Date(selectedDate)
    ),
    times: Array.from(times).sort(),
    slotIndex,
  }
}

export default groupByClinic
