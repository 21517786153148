import { useState } from 'react'
import { envFlag } from '@babylon/babylon-env'
import {
  AppointmentMedium,
  AVAILABLE_SLOTS_FILTER_VALUE,
  AppointmentStatus,
} from './AppointmentManagementPage.types'
import { AppointmentFilter } from '../types/generated/federated-graph'
import { AppointmentFilters } from './types'

type AppointmenFilterWithMediumParam = AppointmentFilter & {
  medium?: { ONE_OF: AppointmentMedium[] }
  status?: {
    ONE_OF: (AppointmentStatus | typeof AVAILABLE_SLOTS_FILTER_VALUE)[]
  }
}
type AppointmentFilterHookReturnType = {
  appointmentFilters: AppointmentFilters
  setAppointmentFilters: (filterSettings: AppointmentFilters) => void
  appointmentFilterQueryVariables: AppointmenFilterWithMediumParam
}

const useAppointmentFilters = (): AppointmentFilterHookReturnType => {
  const [
    appointmentFilters,
    setAppointmentFilters,
  ] = useState<AppointmentFilters>({
    appointmentStatus: [],
    appointmentMedium: [],
  })
  const appointmentManagementFilterEnabled = !!envFlag(
    'ENABLE_APPOINTMENT_MANAGMENT_FILTER'
  )

  const appointmentManagementMediumFilterEnabled = !!envFlag(
    'ENABLE_APPOINTMENT_MANAGMENT_MEDIUM_FILTER'
  )

  const acceptedAppointmentStatus = appointmentFilters.appointmentStatus.filter(
    (status) => status !== AVAILABLE_SLOTS_FILTER_VALUE
  ) as AppointmentStatus[]

  const filterAppointmentStatus =
    appointmentManagementFilterEnabled && acceptedAppointmentStatus.length > 0
      ? { ONE_OF: acceptedAppointmentStatus }
      : undefined

  const filterAppointmentMedium =
    appointmentManagementMediumFilterEnabled &&
    appointmentFilters.appointmentMedium.length
      ? { ONE_OF: appointmentFilters.appointmentMedium }
      : undefined

  const appointmentFilterQueryVariables = {
    status: filterAppointmentStatus,
    medium: filterAppointmentMedium,
  }

  return {
    appointmentFilters,
    setAppointmentFilters,
    appointmentFilterQueryVariables,
  }
}

export default useAppointmentFilters
