import React, { ReactElement } from 'react'
import { FormikConfig, FormikValues } from 'formik'

export interface MultiStepFormPageProps
  extends Pick<FormikConfig<FormikValues>, 'validationSchema'> {
  children: ReactElement
  onPreviousStep?: () => void
  onNextStep?: () => void
}

export default function MultiStepFormPage({
  children,
  onPreviousStep,
  onNextStep,
}: MultiStepFormPageProps): ReactElement {
  if (!React.isValidElement(children)) {
    throw new Error('Invalid child elements')
  }
  return (
    <>
      {React.cloneElement(children as ReactElement, {
        onPreviousStep,
        onNextStep,
      })}
    </>
  )
}
