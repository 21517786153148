import React from 'react'

import styles from './Overlay.module.css'

interface OverlayProps {
  'data-testid'?: string
  hidden?: boolean
  opacity?: number
}

export default function Overlay({
  'data-testid': dataTestId,
  hidden = false,
  opacity = 0.8,
}: OverlayProps) {
  return (
    <div
      data-testid={dataTestId}
      className={hidden ? styles.hidden : styles.overlay}
      style={{ backgroundColor: `rgba(255, 255, 255, ${opacity}` }}
    />
  )
}
