import { defineMessages } from 'react-intl'

const baseId = 'assessment_card'

export default defineMessages({
  buttonAllCollected: {
    id: `${baseId}.button_all_collected`,
    defaultMessage: 'View',
  },
  buttonNoneCollected: {
    id: `${baseId}.button_none_collected`,
    defaultMessage: 'Start',
  },
  buttonPartiallyCollected: {
    id: `${baseId}.button_partially_collected`,
    defaultMessage: 'Continue',
  },
  tagAllCollected: {
    id: `${baseId}.tag_all_collected`,
    defaultMessage: 'Complete',
  },
  tagNoneCollected: {
    id: `${baseId}.tag_none_collected`,
    defaultMessage: 'Not started',
  },
  tagPartiallyCollected: {
    id: `${baseId}.tag_partially_collected`,
    defaultMessage: 'In progress',
  },
})
