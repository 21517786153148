import { format, parseISO } from 'date-fns'
import { SenderMessages } from '../types'

export default (messages: ChatscriptMessage[], userName: string) => {
  let prevSender: SenderMessages['sender'] | null = null

  return messages.reduce(
    (agg: { [key: string]: SenderMessages[] }, message: ChatscriptMessage) => {
      if (message.type === 'text' && message.style !== 'hidden') {
        const key = format(parseISO(message.sentAt), 'dd MMM yyyy')
        let currentSender = {
          id: null,
          name: message.sender === 'user' ? userName : message.sender,
          type: message.sender,
        }

        if (message.author && message.sender !== 'user') {
          currentSender = {
            ...message.author,
            type: 'agent',
          }
        }

        agg[key] = agg[key] || []

        if (
          currentSender.id !== prevSender?.id ||
          currentSender.type !== prevSender?.type ||
          !agg[key].length
        ) {
          prevSender = currentSender
          agg[key].push({ sender: currentSender, messages: [] })
        }

        agg[key][agg[key].length - 1].messages.push(message)
      }

      return agg
    },
    {}
  )
}
