import { gql } from '@apollo/client'

export const ConsultationForGpFragment = gql`
  fragment ConsultationForGpFragment on Consultation {
    id
    consultationId
    scheduledTime
    sentToGp
    consumerNetworkName

    patient: patientV2 {
      ... on Patient {
        __typename
        id
        first_name
        last_name
        gp_surgery_name
        gp_address_first_line
        gp_address_second_line
        gp_address_third_line
        email
        public_healthcare_identifier
      }
      ... on RetrievalError {
        __typename
        message
      }
    }
  }
`
