import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { Heading } from '@babylon/core-ui'
import { useQuery } from '@apollo/client'
import { min, isBefore, isAfter } from 'date-fns'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import {
  Covid19Tags,
  SomeoneElseTag,
  MemberOpsModuleName,
} from '../../../../../../..'
import { COVID_19_QUERY } from './queries'
import { PATIENT_QUERY } from '../EntriesHistory/queries'
import EntriesHistory, { EntriesHistoryProps } from '../EntriesHistory'
import useQueryReferrals from './useQueryReferrals'
import useQueryConversations from './useQueryConversations'
import messages from './Covid19History.messages'
import styles from './Covid19History.module.scss'

interface Covid19HistoryProps
  extends Pick<
    EntriesHistoryProps,
    'visible' | 'patientId' | 'toggleVisible'
  > {}

const Summary = ({
  tag,
  text,
  userUuid,
  patientUuid,
}: Pick<Entry, 'tag' | 'text' | 'userUuid' | 'patientUuid'>) => (
  <div className={styles.Messages__outcome}>
    <div className={styles.Messages__outcomeHeading}>
      <Heading level="h3" color="light-grey-type">
        <FormattedMessage {...messages.outcomeHeader} />
      </Heading>
      <Covid19Tags tag={tag} />
      <SomeoneElseTag userUuid={userUuid} patientUuid={patientUuid} />
    </div>
    <div>{text}</div>
  </div>
)

const Covid19History = ({
  visible,
  patientId,
  toggleVisible,
}: Covid19HistoryProps) => {
  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })
  const { data } = useQuery(PATIENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { id: patientId },
  })

  const userId = data?.patient?.uuid
  const {
    error: conversationsError,
    data: conversations,
    loading: loadingConversations,
    fetchMore,
    more: moreConversations,
  } = useQueryConversations(COVID_19_QUERY, userId, {
    skip: !visible || !userId,
  })

  const {
    data: referralsData,
    loading: loadingReferrals,
    error: referralsError,
  } = useQueryReferrals(userId, { skip: !visible || !userId })

  const minDate = min(
    conversations.map(({ time }: Pick<Entry, 'time'>) => new Date(time))
  )

  const covidHistory = [
    ...conversations,
    ...referralsData
      // Remove referrals that happen after the last conversation as those results
      // are paginated
      .filter(({ time }: Pick<Entry, 'time'>) =>
        isBefore(minDate, new Date(time))
      ),
  ].sort((a, b) => {
    if (isAfter(new Date(a?.time), new Date(b?.time))) {
      return -1
    }

    return 1
  })

  return (
    <EntriesHistory
      transcriptHeadingComponent={Summary}
      heading={fm(messages.covidHeader)}
      loading={loadingConversations || loadingReferrals}
      entries={covidHistory}
      loadMore={fetchMore}
      patientId={patientId}
      toggleVisible={toggleVisible}
      visible={visible}
      onEntryClick={() =>
        trackClick({
          elementName: 'covid19-transcript-link',
          elementType: TrackingElementType.link,
        })
      }
      hasMore={moreConversations || loadingConversations || loadingReferrals}
      error={conversationsError || referralsError}
    />
  )
}

export default Covid19History
