import React from 'react'
import { getMembersAge, Unknown } from '@babylon/member-operations'
import { useFormatMessage } from '@babylon/intl'
import cx from 'classnames'
import { ConversationList } from '../../../../components/ConversationList'
import styles from './PatientPanel.module.css'
import CloseIcon from './CloseIcon'
import OpenIcon from './OpenIcon'
import { PatientProfile } from '../../usePatientProfile'

interface PatientPanelProps {
  patientId: string
  patient?: PatientProfile
}

const Toggable = ({
  children,
  title,
  className,
}: {
  children: React.ReactNode
  title: string
  className?: string
}) => {
  const [open, setOpen] = React.useState(true)

  return (
    <div className={cx(className, styles.toggleable)}>
      <h2>
        {title}{' '}
        <button type="button" onClick={() => setOpen(!open)}>
          {open ? <OpenIcon /> : <CloseIcon />}
        </button>
      </h2>
      {open && children}
    </div>
  )
}

const PatientRow = ({
  label,
  value,
}: {
  label: string
  value: React.ReactNode | undefined
}) =>
  value ? (
    <>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </>
  ) : null

const PatientPanel = ({ patientId, patient }: PatientPanelProps) => {
  const fm = useFormatMessage()

  return (
    <div className={styles.patientPanel}>
      <header>
        <h1>Details</h1>
      </header>

      <div className={styles.panelBody}>
        <Toggable title="Member info">
          <dl>
            <PatientRow label="Full name" value={patient?.summarizedName} />
            <PatientRow label="ID" value={patientId} />
            <PatientRow label="Email" value={patient?.emailAddress} />
            <PatientRow label="Gender" value={patient?.gender} />
            <PatientRow
              label="Age"
              value={
                getMembersAge(patient?.birthDate || null, fm) || <Unknown />
              }
            />
          </dl>
        </Toggable>
        <Toggable title="Chat history">
          <ConversationList memberId={patientId} onlyFinished />
        </Toggable>
      </div>
    </div>
  )
}

export default PatientPanel
