import React from 'react'
import { AppointmentFields } from '../..'
import { LoadingContainer } from '../../components'

import { useBookAppointmentData, useConsentDecisionForPatient } from './hooks'
import { ConsultantTypeDurationDict } from '../../useConsultantTypeDurations'
import BookAppointment from './BookAppointment'

interface BookAppointmentViewProps {
  memberId: string
  inviteId: string | null
  onEdit?: () => void
  filters: AppointmentFields
  slotDurationList: ConsultantTypeDurationDict
}

export default function BookAppointmentView(props: BookAppointmentViewProps) {
  const { loading, ...bookingData } = useBookAppointmentData(props)
  const { patient } = bookingData
  const { expired } = useConsentDecisionForPatient({
    patientUUID: patient?.uuid,
  })

  if (loading) {
    return <LoadingContainer loading={loading} />
  }

  return (
    <BookAppointment
      {...props}
      {...bookingData}
      loading={loading}
      consentExpired={expired}
    />
  )
}
