import { gql } from '@apollo/client'

export default gql`
  mutation CreateProfession($input: CreateProfessionInput!) {
    createProfession(input: $input) {
      id
      name
      default_slot_duration_minutes
      alternative_slot_durations_minutes
      default_appointment_price_cents
      medical_identifier_label
    }
  }
`
