import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Practice } from '../../../types'
import Unknown from '../../../Unknown'
import messages from './AppointmentInviteCard.messages'
import styles from './styles.module.scss'

export interface DetailsProps {
  appointmentType?: string
  clinic?: Practice | null
  guestName?: string | null
  notes?: string
  reason?: string | null
  show: boolean
}

const Details = ({
  appointmentType,
  clinic,
  guestName,
  notes,
  reason,
  show = false,
}: DetailsProps) => {
  if (!show) {
    return null
  }

  return (
    <div data-testid="timeline-card-more-details" className={styles.Details}>
      <div className={styles.Details__section}>
        <div>
          <div>
            <label className={styles.Details__label}>
              <FormattedMessage {...messages.appointment_type} />
            </label>
            <div
              className={styles.Details__value}
              data-testid="timeline-card-appointment-type"
            >
              {appointmentType || <Unknown />}
            </div>
          </div>

          {!!notes && (
            <div>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.notes} />
              </label>
              <div
                className={styles.Details__value}
                data-testid="timeline-card-notes"
              >
                {notes}
              </div>
            </div>
          )}
        </div>

        <div>
          {!!clinic && (
            <div>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.arrival_instructions} />
              </label>
              <div
                className={styles.Details__value}
                data-testid="timeline-card-arrival-instructions"
              >
                {clinic.arrival_instructions || (
                  <FormattedMessage {...messages.no_arrival_instructions} />
                )}
              </div>
            </div>
          )}

          <div>
            <label className={styles.Details__label}>
              <FormattedMessage {...messages.reason_for_appointment} />
            </label>
            <div
              className={styles.Details__value}
              data-testid="timeline-card-reason-for-appointment"
            >
              {reason || <FormattedMessage {...messages.no_reason} />}
            </div>
          </div>

          {guestName && (
            <div>
              <label className={styles.Details__label}>
                <FormattedMessage {...messages.guestName} />
              </label>
              <div className={styles.Details__value}>{guestName}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Details
