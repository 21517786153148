import React from 'react'
import * as Access from '@babylon/web-platform-access'
import { appLayout__inner as innerLayoutStyle } from './styles.module.scss'
import MembersSearchWrapper from './MembersSearchWrapper'
import SearchFeaturesContext, {
  internalFeatures,
} from './SearchFeaturesContext'

export interface GraphQLMiddlewareSearchProps {
  goToPlatformGateway: VoidFunction
  goToCoreRuby: VoidFunction
}

const GraphQLMiddlewareSearch = ({
  goToPlatformGateway,
  goToCoreRuby,
}: GraphQLMiddlewareSearchProps) => {
  const [canAccessMemberSearch] = Access.useDecide('member-search')

  return (
    <div className={innerLayoutStyle} data-testid="new-members-search">
      {canAccessMemberSearch ? (
        <SearchFeaturesContext.Provider value={internalFeatures}>
          <MembersSearchWrapper
            goToPlatformGateway={goToPlatformGateway}
            goToCoreRuby={goToCoreRuby}
          />
        </SearchFeaturesContext.Provider>
      ) : (
        <span>Access denied.</span>
      )}
    </div>
  )
}

export default GraphQLMiddlewareSearch
