import React from 'react'
import { ApolloError } from '@apollo/client'
import get from 'lodash/get'
import size from 'lodash/size'
import { useFormatMessage } from '@babylon/intl'
import { useProductConfig } from '@babylon/product-config'
import { useIsOnGPAtHandNetwork } from '@babylon/appointments'
import {
  AppointmentInvite,
  WorkflowTask,
  WorkflowV2,
} from '@babylon/graphql-middleware-types'
import compact from 'lodash/compact'
import { getUnixTime, parseISO } from 'date-fns'
import ErrorBoundary from '../ErrorBoundary'
import TimelineCard, {
  RepeatTemplateCard,
  WorkflowCard,
  WorkflowCardProps,
} from '../TimelineCard'
import { Maybe, RepeatTemplate } from '../types'
import TimelineBlock from './TimelineBlock'
import messages from './Timeline.messages'
import InviteIcon from './InviteIcon'
import { isSupportedWorkflow } from './utils'
import TimelineCardMenu, {
  TimelineCardMenuProps,
} from '../TimelineCard/TimelineCardMenu'

export interface OutstandingActivitiesProps {
  loading: boolean
  patientId: string
  onCancelAppointmentClick: (appointmentId: string) => void
  repeatTemplates?: RepeatTemplate[]
  reorderTemplates?: RepeatTemplate[]
  invites?: AppointmentInvite[]
  workflowTasks?: Maybe<WorkflowTask>[]
  error?: ApolloError
  pastActivitesRefetch: () => void
}

const getDataSize = (
  invites?: AppointmentInvite[],
  workflowTasks?: Maybe<WorkflowTask>[],
  repeatTemplates?: RepeatTemplate[],
  reorderTemplates?: RepeatTemplate[]
) =>
  size(invites) +
  size(workflowTasks) +
  size(repeatTemplates) +
  size(reorderTemplates)

const workflowToProps = ({
  id,
  consumerNetwork,
  due_date: date,
  name: taskName,
  owner,
  workflow,
  ...rest
}: WorkflowTask): WorkflowCardProps => {
  const workflowDefName = get(
    workflow,
    'workflowDefinition.workflow_definition_name',
    ''
  )
  const workflowDefKey = get(workflow, 'workflow_definition_key', '')
  const state = get<WorkflowV2, 'state'>(workflow, 'state') || ''
  const taskType = get(workflow, 'workflowDefinition.key', '')

  return {
    ...rest,
    id: id!,
    date,
    consumerNetworkName: consumerNetwork ? consumerNetwork.name : '',
    state,
    taskName,
    taskType,
    owner,
    definitionKey: workflowDefKey,
    workflowName: workflowDefName || workflowDefKey,
  }
}

type Activities =
  | AppointmentInvite
  | WorkflowTask
  | RepeatTemplate
  | RepeatTemplate

const wrapType = (
  activities: Activities[],
  type: OutstandingActivityTypes,
  pathToDate: string
): {
  type: OutstandingActivityTypes
  activity: Activities
  date: string
}[] =>
  activities.map((activity) => ({
    date: get(activity, pathToDate, ''),
    type,
    activity,
  }))

enum OutstandingActivityTypes {
  INVITE,
  WORKFLOW_TASK,
  REORDER_TEMPLATE,
  REPEAT_TEMPLATE,
}

const OutstandingActivities = ({
  invites,
  loading,
  patientId,
  onCancelAppointmentClick,
  reorderTemplates = [],
  repeatTemplates = [],
  workflowTasks = [],
  pastActivitesRefetch,
}: OutstandingActivitiesProps) => {
  const { getProp } = useProductConfig()
  const memberTimelineRepeatPrescriptionsEnabled = getProp(
    'memberOperations',
    'memberTimelineRepeatPrescriptionsEnabled'
  )
  const digitalInviteExtensionEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteExtensionEnabled'
  )

  const digitalInviteEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteEnabled'
  )

  const { isOnGPAtHandNetwork } = useIsOnGPAtHandNetwork(patientId, {
    skip: !(digitalInviteExtensionEnabled || digitalInviteEnabled),
  })

  const fm = useFormatMessage()
  const includeWorkflowsEnabled = getProp(
    'memberOperations',
    'includeWorkflowsEnabled'
  )
  const areWorkflowsDisabled = !includeWorkflowsEnabled
  const activities = wrapType(
    compact(workflowTasks),
    OutstandingActivityTypes.WORKFLOW_TASK,
    'due_date'
  )
    .concat(
      wrapType(
        reorderTemplates,
        OutstandingActivityTypes.REORDER_TEMPLATE,
        'lastAvailabilityDateTime'
      )
    )
    .concat(
      wrapType(
        repeatTemplates,
        OutstandingActivityTypes.REPEAT_TEMPLATE,
        'lastAvailabilityDateTime'
      )
    )
    .concat(
      wrapType(invites || [], OutstandingActivityTypes.INVITE, 'scheduledTime')
    )
    .sort(
      ({ date: dateA }, { date: dateB }) =>
        getUnixTime(parseISO(dateB)) - getUnixTime(parseISO(dateA))
    )
  const hasActivities =
    getDataSize(invites, workflowTasks, repeatTemplates, reorderTemplates) > 0

  const title = fm(
    messages[
      loading ||
      (!invites &&
        !workflowTasks &&
        areWorkflowsDisabled &&
        !repeatTemplates &&
        !memberTimelineRepeatPrescriptionsEnabled) ||
      hasActivities
        ? 'outstanding_activities'
        : 'no_outstanding_activities'
    ]
  )

  return (
    <TimelineBlock
      testId="outstanding-activities"
      title={title}
      info={fm(messages.outstanding_activities_tooltip)}
    >
      {activities.map(({ type, activity }) => {
        if (type === OutstandingActivityTypes.WORKFLOW_TASK) {
          const task = activity as WorkflowTask

          if (
            !isSupportedWorkflow(
              task.workflow.workflow_definition_key,
              task.task_definition_key
            )
          ) {
            return null
          }

          return (
            <ErrorBoundary key={task.id}>
              <WorkflowCard {...workflowToProps(task)} />
            </ErrorBoundary>
          )
        }

        if (
          type === OutstandingActivityTypes.REORDER_TEMPLATE ||
          type === OutstandingActivityTypes.REPEAT_TEMPLATE
        ) {
          const repeatTemplate = activity as RepeatTemplate

          return (
            <ErrorBoundary key={repeatTemplate.id}>
              <RepeatTemplateCard
                {...repeatTemplate}
                patientId={patientId}
                duration={get(repeatTemplate, 'latestIssue.durationDays', null)}
                pastActivitesRefetch={pastActivitesRefetch}
              />
            </ErrorBoundary>
          )
        }

        if (type === OutstandingActivityTypes.INVITE) {
          const invite = activity as AppointmentInvite
          const renderMenu = (props: TimelineCardMenuProps) => (
            <TimelineCardMenu {...props} />
          )

          if (digitalInviteExtensionEnabled || digitalInviteEnabled) {
            const allowedMediums = invite?.allowed_mediums ?? ['physical']
            const allowedMedia = allowedMediums
              .map((value: Maybe<string>) =>
                fm(
                  messages[`allowed_mediums_${value}` as keyof typeof messages]
                )
              )
              .join(' / ')
            const canBookFromInvite =
              isOnGPAtHandNetwork ||
              (allowedMediums.length === 1 &&
                allowedMediums.includes('physical'))

            return (
              <ErrorBoundary key={invite.id}>
                <TimelineCard
                  id={invite.id}
                  appointmentType={
                    get(invite, 'service_type.name') ||
                    get(invite, 'preferred_profession_name')
                  }
                  duration={invite.duration_minutes}
                  isInvitation
                  icon={<InviteIcon />}
                  notes={get(invite, 'service_type.member_instructions')}
                  patientId={patientId}
                  reason={invite.notes_for_member}
                  title={allowedMedia}
                  onCancelAppointmentClick={onCancelAppointmentClick}
                  renderMenu={canBookFromInvite ? renderMenu : undefined}
                />
              </ErrorBoundary>
            )
          }

          return (
            <ErrorBoundary key={invite.id}>
              <TimelineCard
                id={invite.id}
                appointmentType={
                  get(invite, 'service_type.name') ||
                  get(invite, 'preferred_profession_name')
                }
                duration={invite.duration_minutes}
                isInvitation
                medium={invite.consultation_type}
                notes={get(invite, 'service_type.member_instructions')}
                patientId={patientId}
                reason={invite.notes_for_member}
                onCancelAppointmentClick={onCancelAppointmentClick}
                renderMenu={renderMenu}
              />
            </ErrorBoundary>
          )
        }

        return null
      })}
    </TimelineBlock>
  )
}

export default OutstandingActivities
