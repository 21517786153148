import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Grid } from '@babylon/core-ui'
import {
  BloodPressureSingle,
  BMISingle,
  HeightSingle,
  SmokingStatusSingle,
  WeightSingle,
} from '@babylon/health-graph-components'
import { HealthGraphComponent } from '@babylon/health-graph-components/dist/types'
import { DrawerView } from '../../../../../..'
import messages from './messages'

const chartComponents: { [key: string]: HealthGraphComponent } = {
  BMI: BMISingle,
  HEIGHT: HeightSingle,
  WEIGHT: WeightSingle,
  SMOKING_STATUS: SmokingStatusSingle,
  BLOOD_PRESSURE: BloodPressureSingle,
}

interface PatientMetricsProps {
  patientUuid: string
  onClose: () => void
}

const PatientMetricsModal = ({ patientUuid, onClose }: PatientMetricsProps) => {
  const fm = useFormatMessage()

  return (
    <DrawerView
      visible
      title={fm(messages.modal_title)}
      toggleVisible={onClose}
    >
      <Grid gap={16} flow="row" columns={2}>
        {Object.entries(chartComponents).map(([key, Component]) => (
          <Component
            key={key}
            data={{
              patientId: patientUuid,
              // As BMI values are calculated, they are not treated as clinician-entered
              // therefore they should not be filtered by provenance
              provenance:
                Component === BMISingle ? undefined : ['clinical-portal'],
            }}
            queryContext={{ clientName: 'platform-gateway' }}
          />
        ))}
      </Grid>
    </DrawerView>
  )
}

export default PatientMetricsModal
