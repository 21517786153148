import React from 'react'

let preventComponentReuseKey = 0

const withForcedRemount = (Component) => {
  return (props) => (
    <Component {...props} key={`remount:${preventComponentReuseKey++}`} />
  )
}

export default withForcedRemount
