import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import {
  withLabel,
  withValue,
  withValidation,
  withOnChange,
  withWrapper,
  compose,
} from './util'

const Select = ({ small, className, 'data-context': ctx, ...props }) => (
  <div className={cx('widget select border', { small }, className)}>
    <select {...props} />
  </div>
)

Select.propTypes = {
  /** Change event handler method
   * @param {SyntheticEvent} event The react `SyntheticEvent`
   */
  onChange: PropTypes.func,
  /** Render the Select component in a smaller size */
  small: PropTypes.bool,
  /** The css class name of the component */
  className: PropTypes.string,
  /** The css style of the component */
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  /** Validation functions */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** @ignore */
  'data-context': PropTypes.shape({
    submit: PropTypes.func,
    reset: PropTypes.func,
    data: PropTypes.object,
  }),
}

export default compose(
  withLabel,
  withValue,
  withValidation,
  withWrapper,
  withOnChange
)(Select)
