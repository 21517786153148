import React from 'react'
import { useField } from 'formik'
import { FormikInput, FormikSelect } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'
import { useFormatMessage } from '@babylon/intl'
import { State, Region } from '../../../types'
import { FormSection, FieldRow } from '../FormFields'
import messages from './ContactDetails.messages'

export interface ContactDetailsProps {
  useAccountOwnersEmergencyDetails: boolean
  states: State[]
  regions: Region[]
  disableMemberEditForm?: boolean
}

const ContactDetails = ({
  useAccountOwnersEmergencyDetails,
  states,
  regions = [],
  disableMemberEditForm,
}: ContactDetailsProps) => {
  const fm = useFormatMessage()
  const [{ value }] = useField('emergency_contact_details')
  const { getProp } = useProductConfig()
  const editMemberCountryEnabled = getProp(
    'memberOperations',
    'editMemberCountryEnabled'
  )

  const countries = regions.map((region) => ({
    value: region.iso_code,
    label: region.name,
  }))

  return (
    <div data-testid="member-edit-contact-details">
      <FormSection title={fm(messages.title)}>
        <FieldRow testId="email">
          <FormikInput
            id="email"
            name="email"
            label={fm(messages.email)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="full_phone_number">
          <FormikInput
            id="full_phone_number"
            name="full_phone_number"
            label={fm(messages.phone_number)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="address">
          <FormikInput
            id="address_first_line"
            name="address_first_line"
            label={fm(messages.address)}
            disabled={disableMemberEditForm}
          />
          <FormikInput
            id="address_second_line"
            name="address_second_line"
            disabled={disableMemberEditForm}
          />
          <FormikInput
            id="address_third_line"
            name="address_third_line"
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        {states.length > 0 && (
          <FieldRow testId="address_state_code">
            <FormikSelect
              id="address_state_code"
              name="address_state_code"
              label={fm(messages.address_state_code)}
              options={states.map((s) => ({ label: s.name, value: s.code }))}
              isDisabled={disableMemberEditForm}
            />
          </FieldRow>
        )}
        <FieldRow size="small" testId="address_post_code">
          <FormikInput
            id="address_post_code"
            name="address_post_code"
            label={fm(messages.postcode)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="country">
          <FormikSelect
            id="country"
            name="region.iso_code"
            label={fm(messages.country)}
            options={countries}
            isDisabled={!editMemberCountryEnabled || disableMemberEditForm}
          />
        </FieldRow>
      </FormSection>
      {value.map((contact, idx) => (
        <FormSection
          key={contact.id}
          title={fm(messages.emergency_title, { number: idx + 1 })}
        >
          <FieldRow testId="emergency_first_name">
            <FormikInput
              id={`emergency_first_name-${idx}`}
              name={`emergency_contact_details.${idx}.first_name`}
              label={fm(messages.emergency_first_name)}
              disabled={
                useAccountOwnersEmergencyDetails || disableMemberEditForm
              }
            />
          </FieldRow>
          <FieldRow testId="emergency_last_name">
            <FormikInput
              id={`emergency_last_name-${idx}`}
              name={`emergency_contact_details.${idx}.last_name`}
              label={fm(messages.emergency_last_name)}
              disabled={
                useAccountOwnersEmergencyDetails || disableMemberEditForm
              }
            />
          </FieldRow>
          <FieldRow testId="emergency_full_phone_number">
            <FormikInput
              id={`emergency_full_phone_number-${idx}`}
              name={`emergency_contact_details.${idx}.full_phone_number`}
              label={fm(messages.emergency_phone_number)}
              disabled={
                useAccountOwnersEmergencyDetails || disableMemberEditForm
              }
            />
          </FieldRow>
        </FormSection>
      ))}
    </div>
  )
}

export default ContactDetails
