import React, { useState } from 'react'
import { Page } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { Box, Pagination, Button, Alert } from '@mui/material'
import { AppointmentWaitTimeConfigsSaveDialog } from '../../AvailabilitySync/AppointmentWaitTimeConfigsSaveDialog'
import { AppointmentWaitTimeConfigsSaveDialogOperations } from '../../AvailabilitySync/AppointmentWaitTimeConfigsSaveDialog/AppointmentWaitTimeConfigsSaveDialog.types'
import { useAppointmentWaitTimeConfigsQuery } from './AppointmentWaitTimeConfigs.middleware.hooks'
import { AppointmentWaitTimeConfigsTable } from '../../AvailabilitySync/AppointmentWaitTimeConfigsTable'
import { ConsumerNetworkSearcher } from '../../AvailabilitySync/ConsumerNetworkSearcher'
import { ServiceTypesSearcher } from '../../AvailabilitySync/ServiceTypesSearcher'
import {
  getAlertMessageForInsert,
  getServerity,
} from './AppointmentWaitTimeConfigs.utils'
import { Option } from '../../components/InputFilter'
import messages from './AppointmentWaitTimeConfigs.messages'
import styles from './AppointmentWaitTimeConfigs.module.css'

const defaultPage = 1
const fetchLimit = 15
type Severity = 'error' | 'success' | 'info' | 'warning' | undefined
export const AppointmentWaitTimeConfigsPage = () => {
  const [selectedConsumerNetworks, setSelectedConsumerNetworks] = useState<
    Option[]
  >([])
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<Option[]>([])
  const [
    createWaitTimeConfigsDialogOpen,
    setCreateWaitTimeConfigsDialogOpen,
  ] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertName, setAlertName] = useState('')
  const [alertSeverity, setAlertSeverity] = useState<Severity>('success')
  const handleCreateWaitTimeConfigsDialogOpen = () => {
    setCreateWaitTimeConfigsDialogOpen(true)
  }
  const handleCreateWaitTimeConfigsDialogClose = () => {
    setCreateWaitTimeConfigsDialogOpen(false)
  }
  const handleSnackBarClose = () => {
    setAlertOpen(false)
  }

  const displayErrorMessage = (name: string, serverity: Severity) => {
    setAlertName(name)
    setAlertSeverity(serverity)
    setAlertOpen(true)
  }
  const refreshPage = () => window.location.reload()

  const handleSaveWaitTimeConfigsAfterActions = (status: string) => {
    const name = getAlertMessageForInsert(status)
    const serverity = getServerity(status)
    setCreateWaitTimeConfigsDialogOpen(false)

    if (serverity === 'success') {
      refreshPage()
    } else {
      displayErrorMessage(name, serverity)
    }
  }

  const handleWaitTimeConfigsDialogDelete = (status: string) => {
    const serverity = getServerity(status)

    if (serverity === 'success') {
      refreshPage()
    } else {
      displayErrorMessage(
        messages.deleteWaitTimeConfigsErrorMessage.defaultMessage,
        serverity
      )
    }
  }

  const fm = useFormatMessage()
  const [page, setPage] = useState(defaultPage)
  const offset = (page - 1) * fetchLimit
  const {
    data: appointmentWaitTimeConfigsData,
    loading: isAppointmentWaitTimesLoading,
  } = useAppointmentWaitTimeConfigsQuery({
    variables: {
      offset,
      limit: fetchLimit,
      consumerNetworkIds: selectedConsumerNetworks.map(({ id }) => id),
      serviceTypeUuids: selectedServiceTypes.map(({ uuid }) => uuid),
    },
    fetchPolicy: 'network-only',
  })
  const total =
    appointmentWaitTimeConfigsData?.appointmentWaitTimeConfigs.total || 0
  const createWaitTimeThresholdAlert = () =>
    alertOpen ? (
      <Box display="flex" gap="15px" paddingBottom={2}>
        <Alert
          onClose={handleSnackBarClose}
          severity={alertSeverity}
          sx={{ width: '100%' }}
        >
          {alertName}
        </Alert>
      </Box>
    ) : undefined

  return (
    <Page>
      {createWaitTimeThresholdAlert()}
      <h1 className={styles.heading}>{fm(messages.pageTitle)}</h1>
      <p className={styles.intro}>{fm(messages.pageIntro)}</p>
      <p className={styles.create_wait_time_configs_button}>
        <Button
          variant="outlined"
          style={{ textTransform: 'none' }}
          color="secondary"
          onClick={handleCreateWaitTimeConfigsDialogOpen}
        >
          {messages.createWaitTimeConfigsButtonTitle.defaultMessage}
        </Button>
      </p>
      <Box display="flex" gap="15px">
        <ConsumerNetworkSearcher
          readOnly={false}
          multiple
          onSelectedConsumerNetworksChanged={(consumerNetworks) => {
            setPage(defaultPage)
            setSelectedConsumerNetworks(consumerNetworks)
          }}
        />
        <ServiceTypesSearcher
          readOnly={false}
          multiple
          onSelectedServiceTypesChanged={(serviceTypes) => {
            setPage(defaultPage)
            setSelectedServiceTypes(serviceTypes)
          }}
        />
      </Box>
      <AppointmentWaitTimeConfigsTable
        waitTimeConfigs={
          appointmentWaitTimeConfigsData?.appointmentWaitTimeConfigs
            .waitTimeConfigs ?? []
        }
        isLoading={isAppointmentWaitTimesLoading}
        handleSaveWaitTimeConfigsAfterActions={
          handleSaveWaitTimeConfigsAfterActions
        }
        handleWaitTimeConfigsDialogDelete={handleWaitTimeConfigsDialogDelete}
      />
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Pagination
          count={Math.floor(total / fetchLimit) + 1}
          onChange={(_, pageNumber) => setPage(pageNumber)}
          color="primary"
          page={page}
        />
      </Box>
      <AppointmentWaitTimeConfigsSaveDialog
        isOpen={createWaitTimeConfigsDialogOpen}
        handleSaveWaitTimeConfigsAfterActions={
          handleSaveWaitTimeConfigsAfterActions
        }
        handleSaveDialogClickClose={handleCreateWaitTimeConfigsDialogClose}
        operation={AppointmentWaitTimeConfigsSaveDialogOperations.Insert}
      />
    </Page>
  )
}

export default AppointmentWaitTimeConfigsPage
