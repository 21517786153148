import React, { useState, useEffect } from 'react'
import {
  FormikInput,
  Grid,
  Cell,
  FormikDatePicker,
  FormikDropdownSelect,
} from '@babylon/core-ui'
import {
  TrackingElementType,
  useTracking,
  TrackingActionType,
} from '@babylon/tracking/react'
import { subDays } from 'date-fns'
import { Formik, Form } from 'formik'
import { useFormatMessage } from '@babylon/intl'
import { useMutation } from '@apollo/client'
import { ButtonSimple, ButtonVariant, Text } from '@babylon/medkit'
import { envVar } from '@babylon/babylon-env'
import { MemberOpsModuleName } from '../../../../../../..'
import { useSnackBar } from '../../../../../../../hooks'
import { logException } from '../../../../../../ErrorBoundary'
import messages from './messages'
import {
  CREATE_PATIENT_PROFILE,
  REGISTER_PATIENT_PROFILE,
  LINK_DEPENDENT_TO_GUARDIAN,
} from '../../queries'
import styles from './styles.module.scss'
import validationSchema, {
  variablesForCreatePatientMutation,
  FORM_INITIAL_VALUES,
  CreateNewPatientFormValuesType,
  getPatientLinkingInput,
} from './utils'
import useRefetchProfile from '../../../ProfileHeader/useRefetchProfile'
import states from './states'

export type CreateNewPatientFormProps = {
  isMinor: boolean
  hideCreateNewPatientForm: () => void
  memberId: string
  onSuccess: () => void
  onError: (id: string) => void
}

const EIGHTEEN_YEARS_IN_DAYS = 18 * 365.25
const ADMINISTRATIVE_COUNTRY = envVar('ADMINISTRATIVE_COUNTRY')

const CreateNewPatientForm = ({
  isMinor,
  hideCreateNewPatientForm,
  memberId,
  onSuccess,
  onError,
}: CreateNewPatientFormProps) => {
  const { trackEvent } = useTracking()
  const fm = useFormatMessage()
  const { setSnackbarMessage } = useSnackBar()
  const [isLoading, setIsLoading] = useState(false)
  const maxSelectedDOB = subDays(Date.now(), EIGHTEEN_YEARS_IN_DAYS)
  const [createPatient] = useMutation(CREATE_PATIENT_PROFILE, {
    context: { clientName: 'platform-gateway' },
  })
  const [registerPatient] = useMutation(REGISTER_PATIENT_PROFILE, {
    context: { clientName: 'platform-gateway' },
  })
  const [addRelatedPerson, { data: linkingData }] = useMutation(
    LINK_DEPENDENT_TO_GUARDIAN,
    {
      context: { clientName: 'platform-gateway' },
    }
  )
  const shouldRefetchProfile = !!linkingData
  const patientCoreId = useRefetchProfile({
    patientUuid: memberId,
    shouldRefetchProfile,
  })

  useEffect(() => {
    if (isLoading && patientCoreId) {
      setIsLoading(false)
      onSuccess()
      trackEvent({
        patientId: memberId,
        elementName: 'link-profiles',
        moduleName: MemberOpsModuleName.memberRelatedPersons,
        elementType: TrackingElementType.form,
        actionType: TrackingActionType.formSuccess,
      })
    }
  }, [
    isLoading,
    patientCoreId,
    fm,
    setSnackbarMessage,
    onSuccess,
    memberId,
    trackEvent,
  ])
  const trackError = (elementName) => {
    trackEvent({
      patientId: memberId,
      elementName,
      moduleName: MemberOpsModuleName.memberRelatedPersons,
      elementType: TrackingElementType.form,
      actionType: TrackingActionType.formError,
    })
  }
  const onSubmit = async (values: CreateNewPatientFormValuesType) => {
    let stubProfileId: string
    setIsLoading(true)
    trackEvent({
      patientId: memberId,
      elementName: 'link-profiles',
      moduleName: MemberOpsModuleName.memberRelatedPersons,
      elementType: TrackingElementType.form,
      actionType: TrackingActionType.formSubmitted,
    })

    try {
      const { data } = await createPatient({
        variables: variablesForCreatePatientMutation(values),
      })
      stubProfileId = data?.createPatient?.id

      if (!stubProfileId) {
        setIsLoading(false)
        setSnackbarMessage(fm(messages.create_patient_error), null, 'error')
        trackError('create-patient')

        return
      }
    } catch (err) {
      setIsLoading(false)
      logException(err)
      setSnackbarMessage(fm(messages.create_patient_error), null, 'error')
      trackError('create-patient')

      return
    }

    try {
      const response = await registerPatient({
        variables: {
          input: {
            stubProfileId,
            primaryEmailAddress: values.emailAddress,
            registrationStrategy: 'invitation-adult-stub',
          },
        },
      })

      if (
        response.data.registerPatient.errorMessage ===
        'email: has already been taken'
      ) {
        setSnackbarMessage(
          fm(messages.email_already_taken_error),
          null,
          'error'
        )
        onError(stubProfileId)
        trackError('register-patient')

        return
      }

      if (response.data.registerPatient.errorMessage) {
        setSnackbarMessage(
          fm(messages.register_patient_error, { stubProfileId }),
          null,
          'error'
        )
        onError(stubProfileId)
        trackError('register-patient')

        return
      }
    } catch (err) {
      logException(err)
      setSnackbarMessage(
        fm(messages.register_patient_error, { stubProfileId }),
        null,
        'error'
      )
      onError(stubProfileId)
      trackError('register-patient')

      return
    }

    try {
      const response = await addRelatedPerson({
        variables: getPatientLinkingInput(memberId, stubProfileId),
      })

      const data =
        response.data._registration_registerPatientAndCreateGuardianRelationship
      const name = data?.name
      const fullName = `${name?.family}, ${name?.given?.join(' ')}`

      if (data.errorMessage) {
        setSnackbarMessage(fm(messages.linking_profiles_error), null, 'error')
        onError(stubProfileId)
        trackError('link-profiles')

        return
      }

      setSnackbarMessage(fm(messages.linking_profiles_success, { fullName }))
    } catch (err) {
      logException(err)
      setSnackbarMessage(fm(messages.linking_profiles_error), null, 'error')
      onError(stubProfileId)
      trackError('link-profiles')
    }
  }

  return (
    <div data-testid="create-new-patient-profile">
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        onSubmit={onSubmit}
        validationSchema={validationSchema(fm)}
      >
        {() => (
          <Form data-testid="create-new-patient-form">
            <Text
              variant="body"
              className={styles.CreateNewPatientForm__instructions}
            >
              {fm(messages.create_new_patient_form_text)}
            </Text>
            <FormikInput
              type="text"
              name="firstname"
              label={fm(messages.firstname_text_input)}
              id="firstname"
            />
            <FormikInput
              type="text"
              name="lastname"
              label={fm(messages.lastname_text_input)}
              id="lastname"
            />
            <FormikInput
              type="text"
              name="addressFirstLine"
              label={fm(messages.address_1_text_input)}
              id="addressFirstLine"
            />
            <FormikInput
              type="text"
              name="addressSecondLine"
              label={fm(messages.address_2_text_input)}
              id="addressSecondLine"
            />
            <Grid columns={2}>
              <Cell>
                <FormikInput
                  type="text"
                  name="city"
                  label={fm(messages.city_text_input)}
                  id="city"
                />
              </Cell>
              <Cell>
                {ADMINISTRATIVE_COUNTRY === 'US' ? (
                  <FormikDropdownSelect
                    id="state"
                    name="state"
                    label={fm(messages.state_text_input)}
                    placeholder={fm(messages.state_text_input)}
                    options={states}
                  />
                ) : (
                  <FormikInput
                    type="state"
                    name="state"
                    label={fm(messages.state_text_input)}
                    id="state"
                  />
                )}
              </Cell>
              <Cell>
                <FormikInput
                  type="text"
                  name="postalCode"
                  label={fm(messages.postal_code_text_input)}
                  id="postalCode"
                />
              </Cell>
              <Cell>
                <FormikInput
                  type="text"
                  name="phoneNumber"
                  label={fm(messages.phone_number_text_input)}
                  id="phoneNumber"
                />
              </Cell>
              <Cell>
                <FormikDatePicker
                  id="dateOfBirth"
                  name="dateOfBirth"
                  locale={navigator.language}
                  dateFormat="yyyy-MM-dd"
                  label={fm(messages.date_of_birth)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  data-testid="dateOfBirth"
                  maxDate={maxSelectedDOB}
                />
              </Cell>
              <Cell>
                <FormikInput
                  type="text"
                  name="emailAddress"
                  label={fm(messages.email_text_input)}
                  id="emailAddress"
                />
              </Cell>
            </Grid>
            <Grid templateColumns="1fr 3fr">
              <ButtonSimple
                type="button"
                variant={ButtonVariant.secondary}
                onClick={hideCreateNewPatientForm}
                className={styles.CreateNewPatientForm__backButton}
              >
                {fm(messages.back)}
              </ButtonSimple>
              <ButtonSimple
                type="submit"
                variant={ButtonVariant.primary}
                loadingLabel=""
                isLoading={isLoading}
                className={styles.CreateNewPatientForm__submitButton}
              >
                {fm(
                  isMinor ? messages.create_guardian : messages.create_dependent
                )}
              </ButtonSimple>
            </Grid>
            <Text
              variant="body"
              className={styles.CreateNewPatientForm__instructions}
            >
              {fm(messages.create_new_patient_form_text_2)}
            </Text>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateNewPatientForm
