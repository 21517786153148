import { gql } from '@apollo/client'

export const CONSULTATION = gql`
  query AP_Consultation($consultationId: ID!) {
    consultation(id: $consultationId) {
      consultationNote {
        id
        toldByPatient
        exploredByGp
        assessment
        treatmentPlan
        fallbackPlan
      }
      prescriptions {
        drugs {
          directions
          duration
          drugName
        }
      }
      referrals {
        id
        specialismName
        specialismCategoryId
      }
      scheduledTime
    }
  }
`

export const SPECIALISM_CATEGORY = gql`
  query AP_Specialisms($consultationId: ID!) {
    specialismCategories(id: $consultationId) {
      name
      id
    }
  }
`
