import { defineMessages } from 'react-intl'

const baseId = 'member_edit_gp_details'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'GP details',
  },
  previous_practice_name: {
    id: `${baseId}.previous_practice_name`,
    defaultMessage: 'Previous practice name',
  },
  gp_name: {
    id: `${baseId}.gp_name`,
    defaultMessage: 'GP name',
  },
  gp_surgery_name: {
    id: `${baseId}.gp_surgery_name`,
    defaultMessage: 'Clinic name',
  },
  gp_surgery_phone_number: {
    id: `${baseId}.gp_surgery_phone_number`,
    defaultMessage: 'Clinic phone number',
  },
  gp_address: {
    id: `${baseId}.gp_address`,
    defaultMessage: 'Clinic Address',
  },
  gp_postcode: {
    id: `${baseId}.gp_postcode`,
    defaultMessage: 'Clinic postcode',
  },
})
