import React from 'react'
import {
  LegacyFormikDropdown,
  LegacyFormikDropdownOption,
  LegacyFormikDropdownOptionValues,
} from '@babylon/core-ui'

export type SlotDurationList = {
  [key: string]: {
    available: string[]
    default: number
  }
}

interface Props {
  className?: string
  disabled?: boolean
  onChange: (option: LegacyFormikDropdownOptionValues | null) => void
  slotDurationList: SlotDurationList
  consultantType: string
}

const getSelectOptionsFromDurations = (
  slotDurations: SlotDurationList,
  consultantType: string
) =>
  slotDurations[consultantType]?.available.map((value) => ({
    value,
    label: value,
  }))

export default function ConsultationDurationSelector({
  className,
  consultantType,
  slotDurationList,
  onChange,
  disabled = false,
}: Props) {
  return (
    <div className={className} data-testid="consultation-duration-selector">
      <LegacyFormikDropdown
        label="Duration"
        id="duration_minutes"
        name="duration_minutes"
        options={getSelectOptionsFromDurations(
          slotDurationList,
          consultantType
        )}
        disabled={disabled}
        onChange={(option: LegacyFormikDropdownOption | null) =>
          onChange(option?.value || null)
        }
        searchable={false}
      />
    </div>
  )
}
