import React from 'react'
import { Tag } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import styles from './SomeoneElse.module.scss'
import messages from './SomeoneElseTag.messages'
import { Entry } from '../types'

interface SomeoneElseTagProps extends Pick<Entry, 'userUuid' | 'patientUuid'> {
  'data-testid'?: string
}

const SomeoneElseTag = ({
  'data-testid': dataTestId,
  userUuid,
  patientUuid,
}: SomeoneElseTagProps) => {
  const fm = useFormatMessage()
  const isSomeoneElse = userUuid && patientUuid && userUuid !== patientUuid
  const tag = fm(messages.someoneElse)

  return isSomeoneElse ? (
    <Tag
      data-testid={dataTestId}
      className={styles.SomeoneElseTag}
      color="light-teal"
    >
      {tag}
    </Tag>
  ) : null
}

export default SomeoneElseTag
