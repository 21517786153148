/**
 * Format consumer network dropdown options
 *
 * @param {Array} consumerNetworks Consumer networks
 * @returns {Array} Formated consumer network dropdown options
 *
 * @author Tameem Safi
 */
export const formatConsumerNetworkOptions = (consumerNetworks) =>
  consumerNetworks.map(({ id, name }) => ({
    key: id,
    value: name,
  }))

/**
 * Format filter values from form into graphql search criteria
 *
 * @param {Object} formValues Submitted filter form values
 * @returns {Object} Formatted search criteria to send to graphql endpoint
 *
 * @author Tameem Safi
 */
export const formatFiltersToSearchCriteria = ({
  dates: { startDate, endDate } = {},
  consultation_id,
  consumer_network,
  family_name,
  given_name,
  gender,
  patient_id,
  marked_as_sent,
  public_healthcare_id,
}) => ({
  gpConsent: true,
  status: 'completed',
  dateRange: {
    startDate: startDate || null,
    endDate: endDate || null,
  },
  consumerNetworkIds: consumer_network ? [consumer_network] : [],
  consultationIds: consultation_id ? [consultation_id] : [],
  markedAsSent: String(marked_as_sent) === 'true',
  patientSearchCriteria: {
    id: patient_id || null,
    first_name: given_name || null,
    last_name: family_name || null,
    public_healthcare_identifier: public_healthcare_id || null,
    gender: gender || null,
  },
})
