import { ApolloError } from '@apollo/client'
import { formatISO } from 'date-fns'
import messages from './MembersSearchView.messages'
import { errorListFromQueryError } from '../../util/helpers'

export const API_RESULT_LIMIT = 10000

export const resultsFoundMessage = (fm, totalResults: number): string => {
  switch (totalResults) {
    case 1:
      return `${totalResults} ${fm(messages.patient_found)}`
    case API_RESULT_LIMIT:
      return `${API_RESULT_LIMIT}+ ${fm(messages.patients_found)}`
    default:
      return `${totalResults} ${fm(messages.patients_found)}`
  }
}

export const parseErrors = (error: ApolloError): [string] => {
  const graphQLErrors = errorListFromQueryError(error)

  return graphQLErrors
    .filter(({ serviceName }) => !serviceName || serviceName !== 'profile')
    .map(({ response, extensions, exception, message, code, serviceName }) => {
      if ((response?.body?.errors ?? []).length > 0) {
        return response.body.errors[0].message || response.body.errors[0].error
      }

      if (response?.body?.message) {
        return response.body.message
      }

      if (extensions?.response?.body?.message) {
        return message
      }

      if (response?.body?.error) {
        return response.body.error
      }

      if (exception?.message) {
        return `${code} - ${serviceName}: ${exception.message}`
      }

      return message
    })
}

const convertDateToISOFormat = (date: Date) =>
  formatISO(date, { representation: 'date' })

export const formatParams = (params) => {
  if (!params.dateOfBirth) {
    return params
  }

  const paramsClone = { ...params }

  try {
    paramsClone.dateOfBirth = convertDateToISOFormat(paramsClone.dateOfBirth)
  } catch {
    return params
  }

  return paramsClone
}
