import React from 'react'
import classnames from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { Unknown } from '../..'
import CopyToClipboardButton from '../../CopyToClipboardButton'
import commonStyles from '../styles.module.scss'
import messages from '../CopiableMemberInformation.messages'
import styles from './styles.module.scss'

export interface EmailProps {
  email: string | null
  onEmailCopy?: (message: string, email: string) => void
}

const Email = ({ email, onEmailCopy }: EmailProps) => {
  const fm = useFormatMessage()

  if (!email || (email && !email.trim())) {
    return <Unknown label={fm(messages.email_unknown)} />
  }

  const emailCopiedHandler = (copiedEmail: string) => {
    onEmailCopy && onEmailCopy(fm(messages.email), copiedEmail)
  }

  return (
    <CopyToClipboardButton
      className={classnames(styles.email, commonStyles.copyToClipboardButton)}
      value={email}
      onCopiedToClipboard={emailCopiedHandler}
    >
      <span data-testid="copiable-email">{email}</span>
    </CopyToClipboardButton>
  )
}

export default Email
