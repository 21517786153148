import { format } from 'date-fns-tz'
import { isBefore, addDays, addWeeks, subDays, startOfToday } from 'date-fns'

export const getClinicById = (list: Practice[], id: string): Practice | null =>
  list.find(({ id: clinicId }) => id === clinicId) || null

export const formatLocation = (
  appointmentClinic: Practice,
  startDate: Date
): string => {
  if (!appointmentClinic || !appointmentClinic.name) {
    return ''
  }

  if (!appointmentClinic.timezone_id) {
    return appointmentClinic.name
  }

  const timeZoneCode = format(new Date(startDate), 'OOO', {
    timeZone: appointmentClinic.timezone_id,
  })

  return `${appointmentClinic.name} (${timeZoneCode})`
}

export const getPreviousDate = (date: Date): Date => subDays(date, 1)

export const getNextDate = (date: Date): Date => addDays(date, 1)

export const getNextWeekDate = (date: Date): Date => addWeeks(date, 1)

export const checkIfPreviousDateIsDisabled = (date: Date): boolean =>
  isBefore(date, startOfToday())
