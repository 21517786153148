import React from 'react'

const OpenIcon = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.58711 2.23408L5.98011 5.61786C5.43697 6.12738 4.55959 6.12738 4.01646 5.61786L0.40946 2.23408C-0.467917 1.411 0.158781 0 1.39825 0L8.61224 0C9.85171 0 10.4645 1.411 9.58711 2.23408Z"
      fill="#022345"
    />
  </svg>
)

export default OpenIcon
