import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'
import { envFlag } from '@babylon/babylon-env'
import { useProductConfig } from '@babylon/product-config'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import ConditionalWrapper from '../../../ConditionalWrapper'
import Unknown from '../../../Unknown'
import { Practice, Referral } from '../../../types'
import { MemberOpsModuleName } from '../../../..'
import CallList from '../CallList'
import ReferralInfo from '../ReferralInfo'
import ShareNotes from '../ShareNotes'
import styles from './styles.module.scss'
import messages from './AppointmentCardDetails.messages'

export interface AppointmentCardDetailsProps {
  appointmentType?: string
  checkIn?: string
  clinic?: Practice | null
  guestName?: string | null
  id: string
  isCompleted: boolean
  notes?: string
  reason?: string | null
  referrals?: Referral[] | null
  show: boolean
}

const AppointmentCardDetails = ({
  appointmentType,
  checkIn,
  clinic,
  guestName,
  id,
  isCompleted = false,
  notes,
  reason,
  referrals,
  show = false,
}: AppointmentCardDetailsProps) => {
  const { getBlob } = useProductConfig()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.timeline,
  })

  if (!show) {
    return null
  }

  const enableLinkToClinic = envFlag('TIMELINE_F2F_LINK_TO_CLINIC')

  const {
    shareNotesWithGpEnabled,
    timelineCallListEnabled,
    timelineAppointmentLinkEnabled,
  } = getBlob('memberOperations')

  return (
    <div data-testid="timeline-card-more-details">
      <div
        className={cx(
          styles.AppointmentCardDetails__section,
          styles.AppointmentCardDetails__onecolumn
        )}
      >
        <label className={styles.AppointmentCardDetails__label}>
          <FormattedMessage {...messages.reason_for_appointment} />
        </label>
        <div
          data-testid="timeline-card-reason-for-appointment"
          className={styles.AppointmentCardDetails__value}
        >
          {reason || <FormattedMessage {...messages.no_reason} />}
        </div>
      </div>

      <div className={styles.AppointmentCardDetails__section}>
        {/* Column One */}
        <div>
          {!!appointmentType && (
            <div>
              <label className={styles.AppointmentCardDetails__label}>
                <FormattedMessage {...messages.appointment_type} />
              </label>
              <div
                data-testid="timeline-card-appointment-type"
                className={styles.AppointmentCardDetails__value}
              >
                {appointmentType}
              </div>
            </div>
          )}

          {!!clinic && (
            <>
              <div>
                <label className={styles.AppointmentCardDetails__label}>
                  <FormattedMessage {...messages.clinic_location} />
                </label>
                <div
                  data-testid="timeline-card-clinic-name"
                  className={styles.AppointmentCardDetails__value}
                >
                  <ConditionalWrapper
                    condition={enableLinkToClinic}
                    wrapper={(children) => (
                      <Link
                        to={`/admin/practices/${clinic.id}`}
                        className={styles.AppointmentCard__link}
                      >
                        {children}
                      </Link>
                    )}
                  >
                    {clinic.name}
                  </ConditionalWrapper>

                  {!!clinic.full_address && (
                    <div data-testid="timeline-card-clinic-address">
                      <div>{clinic.full_address.address_line_1}</div>
                      <div>{clinic.full_address.address_line_2}</div>
                      <div>{clinic.full_address.address_line_3}</div>
                      <div>{clinic.full_address.postal_code}</div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {!!checkIn && (
            <div>
              <label className={styles.AppointmentCardDetails__label}>
                <FormattedMessage {...messages.check_in} />
              </label>
              <div
                data-testid="timeline-card-check-in"
                className={styles.AppointmentCardDetails__value}
              >
                {checkIn || <FormattedMessage {...messages.check_in_status} />}
              </div>
            </div>
          )}

          {!!clinic && (
            <div>
              <label className={styles.AppointmentCardDetails__label}>
                <FormattedMessage {...messages.arrival_instructions} />
              </label>
              <div
                data-testid="timeline-card-arrival-instructions"
                className={styles.AppointmentCardDetails__value}
              >
                {clinic.arrival_instructions || (
                  <FormattedMessage {...messages.no_arrival_instructions} />
                )}
              </div>
            </div>
          )}

          {!!notes && (
            <div>
              <label className={styles.AppointmentCardDetails__label}>
                <FormattedMessage {...messages.notes} />
              </label>
              <div
                data-testid="timeline-card-notes"
                className={styles.AppointmentCardDetails__value}
              >
                {notes}
              </div>
            </div>
          )}

          {referrals && referrals.length > 0 && (
            <ReferralInfo id={id} referrals={referrals} />
          )}
        </div>

        {/* Column Two */}
        <div>
          <label className={styles.AppointmentCardDetails__label}>
            <FormattedMessage {...messages.consultation_id} />
          </label>
          <div className={styles.AppointmentCardDetails__value}>
            <ConditionalWrapper
              condition={timelineAppointmentLinkEnabled && isCompleted && id}
              wrapper={(children) => (
                <Link
                  to={`/admin/appointments/${id}`}
                  onClick={(evt) => {
                    trackClick({
                      elementName: 'appointment-details-link',
                      elementType: TrackingElementType.link,
                      href: evt.currentTarget.href,
                    })
                  }}
                >
                  {children}
                </Link>
              )}
            >
              {id || <Unknown />}
            </ConditionalWrapper>
          </div>

          {timelineCallListEnabled && (
            <>
              <label className={styles.AppointmentCardDetails__label}>
                <FormattedMessage {...messages.calls_made} />
              </label>
              <div className={styles.AppointmentCardDetails__value}>
                <CallList appointmentId={id} />
              </div>
            </>
          )}

          {shareNotesWithGpEnabled && (
            <>
              <label className={styles.AppointmentCardDetails__label}>
                <FormattedMessage {...messages.share_notes} />
              </label>
              <div className={styles.AppointmentCardDetails__value}>
                <ShareNotes consultationId={id} />
              </div>
            </>
          )}
          {guestName && (
            <>
              <label className={styles.AppointmentCardDetails__label}>
                <FormattedMessage {...messages.guestName} />
              </label>
              <div className={styles.AppointmentCardDetails__value}>
                {guestName}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AppointmentCardDetails
