import { isAfter, isBefore, isEqual, addMinutes } from 'date-fns'

export default function getAllSlotsInShift(
  appointments,
  startOfShift,
  endOfShift
) {
  return appointments.filter((appointment) => {
    if (
      isAfter(new Date(appointment.time), endOfShift) ||
      isBefore(
        addMinutes(new Date(appointment.time), appointment.duration_minutes),
        startOfShift
      ) ||
      isEqual(new Date(appointment.time), endOfShift) ||
      isEqual(
        addMinutes(new Date(appointment.time), appointment.duration_minutes),
        startOfShift
      )
    ) {
      return false
    }

    return true
  })
}
