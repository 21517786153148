import React, { useMemo } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { DocumentNode } from 'graphql'
import { HealthcareServiceEdge, Connection } from '@/gql/graphql'
import messages from './messages'
import { DataViewFromGQL } from '@/components/DataTableFromGQL/DataViewFromGQL'
import {
  EditObjectConfig,
  ViewFieldSettingsObject,
} from '@/components/DataTableFromGQL/types'

type Props = {
  query: DocumentNode
  healthcareServiceDataConverter: (item: HealthcareServiceEdge) => any
  editConfig?: EditObjectConfig
}

export const PharmacyView: React.FC<Props> = ({
  query,
  healthcareServiceDataConverter,
  editConfig,
}) => {
  const fm = useFormatMessage()

  const pharmaciesDataFormatter = (data: Connection) => {
    if (!data?.edges[0]) return undefined
    return healthcareServiceDataConverter(
      data?.edges[0] as HealthcareServiceEdge
    )
  }

  const viewPageFieldSettings: ViewFieldSettingsObject[] = useMemo(() => {
    return [
      {
        name: 'id',
        hidden: true,
      },
      {
        name: 'coreRubyId',
        hidden: true,
      },
      {
        name: 'name',
        label: fm(messages.name),
      },
      {
        name: 'address',
        label: fm(messages.address),
      },
      {
        name: 'phone_number',
        label: fm(messages.phoneNumber),
      },
      {
        name: 'fax_number',
        label: fm(messages.faxNumber),
      },
      {
        name: 'delivery_type',
        label: fm(messages.deliveryType),
      },
      {
        name: 'blocked',
        label: fm(messages.blocked),
      },
      {
        name: 'block_reason',
        label: fm(messages.blockedReason),
      },
    ]
  }, [fm])

  const filter = (id?: string) => {
    if (!id) return undefined
    return {
      identifier: {
        ONE_OF: [
          {
            value: id,
            system: 'https://coreruby.pharmacy.bbl.health/HealthcareService',
          },
        ],
      },
    }
  }

  return (
    <DataViewFromGQL
      query={query}
      filter={filter}
      dataFormatter={pharmaciesDataFormatter}
      fieldSettings={viewPageFieldSettings}
      headerProperty="name"
      objectTypeLabel={fm(messages.pharmacies)}
      editConfig={editConfig}
    />
  )
}
