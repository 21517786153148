import React, { FC, useState, useEffect, ReactNode } from 'react'
import {
  DropdownSelect,
  DropdownSelectOption,
  Heading,
  Snackbar,
  Text,
} from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'
import { AlertFilled } from '@babylon/icons'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MembersSearchForm, SearchResults } from './components'
import messages from './MembersSearchView.messages'
import styles from './styles.module.scss'
import { resultsFoundMessage } from './utils'
import { MemberOpsModuleName } from '../..'
import SwitchSearchBanner from '../../components/SwitchSearchBanner'

export interface MembersSearchViewProps {
  data: {
    members: {
      totalResults: number
      results: {
        edges: object[]
        pageInfo: {
          startCursor: string
          endCursor: string
          hasNextPage: boolean
          hasPreviousPage: boolean
        }
      }
      decryptedSearch: {
        dateOfBirth: string
        email: string
        firstName: string
        lastName: string
        phoneNumber: string
        postCode: string
        coreRubyPatientId: string
        nationalId: string
      }
    }
  }
  errors?: string[]
  loading: boolean
  onChangePage: (params: any, variables: object) => void
  onClear: () => void
  onSearch: (params: any, variables: object) => void
  goToGQL: VoidFunction
  goToCoreRuby: VoidFunction
  searchParamsSnapshot: object
  fieldValues: object
}

const MembersSearchView: FC<MembersSearchViewProps> = ({
  data,
  errors = [],
  loading,
  onChangePage,
  onClear,
  onSearch,
  goToGQL,
  goToCoreRuby,
  searchParamsSnapshot,
  fieldValues,
}: MembersSearchViewProps) => {
  const fm = useFormatMessage()

  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.membersSearchView,
  })

  const { members } = data || {}
  const { results, totalResults } = members || {}
  const { edges, pageInfo } = results || {}
  const { startCursor, endCursor, hasNextPage, hasPreviousPage } =
    pageInfo || {}

  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState('')
  const [rowsPerPageOption, setRowsPerPageOption] = useState<ReactNode>(10)

  useEffect(() => {
    setErrorSnackbarMessage(
      errors?.length > 1 ? `${errors.length} errors found` : errors[0]
    )
  }, [errors])

  const onPrevious = (elementName: string) => {
    trackClick({
      elementName,
      elementType: TrackingElementType.button,
    })
    onChangePage(searchParamsSnapshot, {
      last: rowsPerPageOption,
      before: startCursor,
    })
  }
  const onNext = (elementName: string) => {
    trackClick({
      elementName,
      elementType: TrackingElementType.button,
    })
    onChangePage(searchParamsSnapshot, {
      first: rowsPerPageOption,
      after: endCursor,
    })
  }

  const onRowsPerPageChange = (option: DropdownSelectOption) => {
    trackClick({
      elementName: `search-results-rows-per-page-${option.value}`,
      elementType: TrackingElementType.dropdown,
    })
    onChangePage(searchParamsSnapshot, { first: option.value })
    setRowsPerPageOption(option.value)
  }

  const calculatePageSize = () =>
    // eslint-disable-next-line radix
    parseInt(endCursor) - parseInt(startCursor) + 1

  const rowsPerPage = endCursor && startCursor ? calculatePageSize() : 10
  const rowOptions = [5, 10, 15, 20, 25]

  const renderNavigationButtons = (elementType: string) =>
    errors.length === 0 && (
      <>
        {(hasNextPage || hasPreviousPage) && (
          <div
            className="core-ui-filter-summary__rowsPerPageWrapper"
            data-testid="rows-per-page-filter"
            style={{ marginRight: '15px' }}
          >
            <span className="core-ui-filter-summary__rowsPerPageText">
              {fm(messages.rows_per_page_label)}
            </span>
            <DropdownSelect
              selectedOption={{
                key: rowsPerPage,
                value: rowsPerPage,
              }}
              options={rowOptions.map((number) => ({
                key: number,
                value: number,
              }))}
              onChange={onRowsPerPageChange}
            />
          </div>
        )}
        {hasPreviousPage && (
          <ButtonSimple
            variant={ButtonVariant.secondary}
            onClick={() => onPrevious(`search-results-previous-${elementType}`)}
          >
            {fm(messages.previous)}
          </ButtonSimple>
        )}
        {hasNextPage && (
          <ButtonSimple
            variant={ButtonVariant.secondary}
            onClick={() => onNext(`search-results-next-${elementType}`)}
          >
            {fm(messages.next)}
          </ButtonSimple>
        )}
      </>
    )

  return (
    <div data-testid="members-search-view">
      <Snackbar
        icon={<AlertFilled fill="white" />}
        open={!!errorSnackbarMessage}
        className={styles.Snackbar}
        onClose={() => setErrorSnackbarMessage('')}
        message={errorSnackbarMessage}
        intent="error"
      />

      <SwitchSearchBanner bannerOnNewSearch onClick={goToGQL} />

      <Heading className={styles.membersSearchView__header} level="h1">
        {fm(messages.title)}
      </Heading>

      <MembersSearchForm
        onSubmit={(formData) => onSearch(formData, { first: 10 })}
        onClear={onClear}
        loading={loading}
        fieldValues={fieldValues}
        goToCoreRuby={goToCoreRuby}
      />

      <div className={styles.searchResultHeaderContainer}>
        <div className={styles.searchResultHeader}>
          {errors.length === 0 && !!totalResults && (
            <Text data-testid="number-of-patients-found">
              {resultsFoundMessage(fm, totalResults)}
            </Text>
          )}
        </div>
        <div className={styles.searchResultHeader}>
          {renderNavigationButtons('header')}
        </div>
      </div>

      <SearchResults
        errors={errors}
        list={edges || []}
        loading={loading}
        pristine={!edges}
      />

      <div className={styles.searchResultFooter}>
        {renderNavigationButtons('footer')}
      </div>
    </div>
  )
}

export default MembersSearchView
