import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { useProductConfig } from '@babylon/product-config'
import { Button, FormikCheckbox, Text } from '@babylon/core-ui'

import styles from './BookSlotDialog.module.css'
import BookSlotCliniciansField from './BookSlotCliniciansField'
import BookSlotDetails from './BookSlotDetails'
import { findClinicById } from './BookSlotUtils'
import { Clinician } from '../AvailabilitySlots/GroupSlots'
import { Clinic } from '../AvailabilitySlots/GroupSlotsByClinic'
import { ReasonField } from '../../AppointmentFields'
import { LoadingContainer } from '../../components'

interface Props {
  clinicId?: number | 'any'
  clinics?: Clinic[]
  clinicians?: Clinician[]
  hideClinicians?: boolean
  slotDuration: number
  onClose: () => void
  UTCTimestamp: string
  timezoneId: string
  isSubmitting?: boolean
  consentExpired?: boolean
}

const BookSlotDialog = ({
  clinicId,
  clinics = [],
  clinicians = [],
  hideClinicians = false,
  slotDuration,
  onClose,
  UTCTimestamp,
  timezoneId,
  isSubmitting,
  consentExpired,
}: Props) => {
  const { getProp } = useProductConfig()
  const paymentRequiredCheckboxEnabled = getProp(
    'clinicalCare',
    'appointments.adminPortal.paymentRequiredCheckboxEnabled'
  )

  const recordingConsentByPatient = getProp(
    'clinicalCare',
    'appointments.adminPortal.recordingConsentByPatient'
  )

  return (
    <div className={styles.dialog} data-testid="book-slot-dialog">
      <LoadingContainer loading={isSubmitting}>
        <button
          className={styles.close}
          onClick={onClose}
          type="button"
          data-testid="book-slot-dialog-close"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>

        <div className={styles.details}>
          <BookSlotDetails
            clinic={findClinicById(clinics, clinicId)}
            clinician={
              clinicians.length === 1 && !hideClinicians ? clinicians[0] : null
            }
            slotDuration={slotDuration}
            UTCTimestamp={UTCTimestamp}
            timezoneId={timezoneId}
          />
        </div>

        {clinicians.length > 1 && !hideClinicians && (
          <BookSlotCliniciansField clinicians={clinicians} />
        )}

        <ReasonField />
        {paymentRequiredCheckboxEnabled && (
          <FormikCheckbox
            id="payment_required"
            name="payment_required"
            dataTestId="payment-required-checkbox"
          >
            <Text color="grey-type">Payment required</Text>
          </FormikCheckbox>
        )}
        {recordingConsentByPatient && (
          <FormikCheckbox
            id="recording_consent"
            name="recording_consent"
            dataTestId="recording-consent-checkbox"
          >
            <Text color="grey-type">
              Patient agrees to be recorded during consultation
            </Text>
          </FormikCheckbox>
        )}
        {consentExpired && (
          <div className={styles.consentExpiredNotice}>
            The member&#39;s informed consent or medical history authorization
            requires renewal. Please prompt them to sign both before this
            appointment.
          </div>
        )}
        <Button type="submit" testId="confirm-appointment-button">
          Confirm Appointment
        </Button>
      </LoadingContainer>
    </div>
  )
}

export default BookSlotDialog
