/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type GetHumanitySyncStatusQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
}>

export type GetHumanitySyncStatusQuery = {
  humanitySyncStatus?: Types.Maybe<
    Pick<Types.HumanitySyncStatus, 'status' | 'orphan_count'>
  >
}

export const GetHumanitySyncStatusDocument = gql`
  query GetHumanitySyncStatus($id: ID!) {
    humanitySyncStatus(id: $id) {
      status
      orphan_count
    }
  }
`
export function useGetHumanitySyncStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHumanitySyncStatusQuery,
    GetHumanitySyncStatusQueryVariables
  >
) {
  return Apollo.useQuery<
    GetHumanitySyncStatusQuery,
    GetHumanitySyncStatusQueryVariables
  >(GetHumanitySyncStatusDocument, baseOptions)
}
export function useGetHumanitySyncStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHumanitySyncStatusQuery,
    GetHumanitySyncStatusQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetHumanitySyncStatusQuery,
    GetHumanitySyncStatusQueryVariables
  >(GetHumanitySyncStatusDocument, baseOptions)
}
export type GetHumanitySyncStatusQueryHookResult = ReturnType<
  typeof useGetHumanitySyncStatusQuery
>
export type GetHumanitySyncStatusLazyQueryHookResult = ReturnType<
  typeof useGetHumanitySyncStatusLazyQuery
>
export type GetHumanitySyncStatusQueryResult = Apollo.QueryResult<
  GetHumanitySyncStatusQuery,
  GetHumanitySyncStatusQueryVariables
>
