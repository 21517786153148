import React, { useState } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import {
  useProductConfig,
  usePatientProductConfig,
} from '@babylon/product-config'
import { Spinner } from '@babylon/core-ui'

import { useQueryParams } from '../../hooks'
import { AppointmentFields, ArrangeAppointment } from '../..'
import CreateAppointment from '../../CreateAppointment'
import DigitalAppointmentForm from '../../DigitalAppointmentForm'
import usePreferredNetwork from '../../hooks/usePreferredNetwork'

interface QueryParams {
  page: string
}

const WITH_SERVICE_TYPES_PAGE = 'with-service-types'

export default function CreateAppointmentRoute() {
  const { getProp } = useProductConfig()
  const history = useHistory()
  const { state } = useLocation()
  const { id: memberId } = useParams<{ id: string }>()
  const [filters] = useState<AppointmentFields>(state?.filters)
  const [params] = useQueryParams<QueryParams>({})

  const { page } = params

  const isServiceTypesPage = page === WITH_SERVICE_TYPES_PAGE

  const digitalInviteEnabled =
    getProp('clinicalCare.appointments.adminPortal', 'digitalInviteEnabled') ||
    isServiceTypesPage

  const { preferredNetwork, loading } = usePreferredNetwork(memberId)

  const { getProp: getPatientConfigProp } = usePatientProductConfig(
    preferredNetwork?.consumer_network?.uuid
  )

  const faceToFaceInvitesEnabledForPatient = getPatientConfigProp(
    'clinicalCare.appointments.adminPortal',
    'faceToFaceInvitesEnabled'
  )

  if (loading) {
    return <Spinner size="large" centered />
  }

  if (digitalInviteEnabled) {
    return (
      <DigitalAppointmentForm
        memberId={memberId}
        faceToFaceInvitesEnabled={faceToFaceInvitesEnabledForPatient}
        patientNetwork={preferredNetwork}
      />
    )
  }

  const skipCreateLink = `/admin/patients/${memberId}/schedule-appointment`

  const skipCreate = () => {
    history.push(skipCreateLink)
  }

  const updateFilters = (newFilters: AppointmentFields) => {
    history.push(`/admin/patients/${memberId}/schedule-appointment`, {
      filters: newFilters,
    })
  }

  const isOnGPAtHandNetwork = !!(
    preferredNetwork &&
    preferredNetwork.active &&
    preferredNetwork.gp_at_hand
  )

  if (isOnGPAtHandNetwork) {
    return <ArrangeAppointment skipLink={skipCreateLink} />
  }

  return (
    <CreateAppointment
      filters={filters}
      memberId={memberId}
      onFiltersChange={updateFilters}
      onSkip={skipCreate}
    />
  )
}
