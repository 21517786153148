import { gql } from '@apollo/client'

export const QUERY_GET_SUPPLY_NETWORKS = gql`
  query SupplyNetworks(
    $first: NonNegativeInt!
    $after: Cursor
    $filter: SupplyNetworksFilter
  ) {
    supplyNetworks(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          name
          region
          priority
          activeFeatureSwitches {
            edges {
              cursor
              node
            }
          }
        }
      }
    }
  }
`
export const QUERY_GET_FEATURE_SWITCHES = gql`
  query featureSwitches {
    coreRubyFeatureSwitches(filter: { resource: "supply_network" }) {
      edges {
        cursor
        node {
          description
          ownedBy
          name
        }
      }
      totalCount
    }
  }
`
export const QUERY_GET_PAGE_QUERY = gql`
  query PageQuery {
    coreRubyRegions(first: 500) {
      edges {
        cursor
        node {
          name
          id
          code
        }
      }
    }
    coreRubyFeatureSwitches(filter: { resource: "supply_network" }) {
      edges {
        cursor
        node {
          description
          ownedBy
          name
        }
      }
      totalCount
    }
  }
`
