import React from 'react'
import {
  Route,
  Switch,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'
import { Theme } from '@babylon/core-ui'

import NewServiceType from './NewServiceType'
import ServiceTypes from './ServiceTypes'
import ServiceType from './ServiceType'
import styles from './ServiceMix.module.css'

interface Props extends RouteComponentProps {
  includingDigital?: boolean
}

const ServiceMix = ({ match: { path }, includingDigital }: Props) => (
  <Theme className={styles.MedKitTheme}>
    <Switch>
      <Route
        exact
        path={`${path}/new`}
        render={() => <NewServiceType baseUrl={path} />}
      />
      <Route exact path={`${path}/:uuid`} component={ServiceType} />
      <Route exact path={path}>
        <ServiceTypes includingDigital={includingDigital} />
      </Route>
    </Switch>
  </Theme>
)

export default withRouter(ServiceMix)
