import { gql } from '@apollo/client'

export const COVID_19_QUERY = gql`
  query AP_Covid19TriageFlows(
    $userId: ID!
    $offset: Int
    $limit: Int
    $order: String
  ) {
    chatscriptConversations(
      userId: $userId
      offset: $offset
      limit: $limit
      order: $order
      covidOnly: true
      status: finished
    ) {
      more
      conversations {
        id
        preview
        patientUuid
        userUuid
        result {
          outcome
          type
        }
        startedAt
        status
        type
      }
    }
  }
`

export const REFERRAL_QUERY = gql`
  query AP_REFERRAL_HISTORY($id: ID!) {
    getPatientReferrals(id: $id) {
      contract_uuid
      patient_email_sent_at
    }
  }
`

export const MEMBERSHIP_PLANS = gql`
  query AP_MEMBERSHIP_PLANS($uuid: ID!) {
    getMembershipPlans(member_uuid: $uuid) {
      memberships {
        contract {
          id
          plan {
            name
          }
        }
      }
    }
  }
`
