import React, { useRef } from 'react'
import { UnexpectedError } from '../..'
import {
  AppointmentAvailabilityForm,
  AppointmentAvailability,
} from '../../AppointmentAvailability'

import {
  useAppointmentClinicData,
  useBookingState,
  useConsultantListData,
} from './hooks'

import { buildWeekFromDay } from '../../Utils'

import styles from './styles.module.css'

const BookAppointment = (props) => {
  const bookingState = useBookingState(props)

  const consultantListData = useConsultantListData(bookingState)
  const appointmentClinicData = useAppointmentClinicData(bookingState)

  const bookingData = {
    ...bookingState,
    ...consultantListData,
    ...appointmentClinicData,
  }

  const { error, errorUpdate } = bookingState
  const dismissError = () => errorUpdate()

  const { today } = props

  // TODO: Keeping a reference to initial value because formik is always updated when props change
  // Move to formik state when hook state disappears so we can then rely on formik initialValues
  const initialSelectedDateRef = useRef(new Date())
  const initialSelectedWeekRef = useRef(buildWeekFromDay(today))

  return (
    <div data-test-id="book-appointment-view" className={styles.view}>
      {error && (
        <UnexpectedError visible message={error} onClose={dismissError} />
      )}
      <AppointmentAvailabilityForm
        {...bookingData}
        selectedDate={initialSelectedDateRef.current}
        selectedWeek={initialSelectedWeekRef.current}
      >
        <AppointmentAvailability
          {...bookingData}
          consentExpired={props.consentExpired}
          initialSelectedDate={initialSelectedDateRef.current}
          initialSelectedWeek={initialSelectedWeekRef.current}
        />
      </AppointmentAvailabilityForm>
    </div>
  )
}
export default BookAppointment
