import React, { ReactNode } from 'react'
import { DropdownSelectOption, ConfirmationModal } from '@babylon/core-ui'

import styles from './AppointmentDialog.module.css'
import { ConsultationTypeName } from '../types/ConsultationTypes'
import AppointmentDialogDetails from './AppointmentDialogDetails'
import { UnexpectedError } from '../Utils'
import { LoadingContainer } from '../components'

export type Consultant = {
  name: string
  gender: string
}

export type AppointmentDetails = {
  clinic?: Practice | null
  medium?: ConsultationTypeName
  heading: ReactNode
  timezoneId?: string | null
  scheduledTime?: string | null
  duration: number
  consultant?: Consultant | null
  serviceType?: string
}

export interface AppointmentDialogProps extends AppointmentDetails {
  active: boolean
  onClose: () => void
  snackbarError?: {
    message: string
  }
  onConfirm?: () => void
  loading?: boolean
  onSelectDropdownItem?: (e: DropdownSelectOption) => void
  selectedDropdownOption?: DropdownSelectOption | null
  dropdownError?: boolean
  dropdownArray?: any
  alignButtons?: 'left' | 'center' | 'right'
  includeCancel?: boolean
  includeConfirm?: boolean
  includeClose?: boolean
  confirmButtonText?: string
  icon?: 'success' | 'warning'
  loadingDetails?: boolean
}

export default function AppointmentDialog({
  active,
  onClose,
  clinic,
  medium,
  heading,
  timezoneId,
  scheduledTime,
  duration,
  consultant,
  snackbarError,
  loading,
  onSelectDropdownItem,
  selectedDropdownOption,
  dropdownError,
  dropdownArray,
  alignButtons,
  confirmButtonText,
  serviceType = '',
  onConfirm = () => {},
  includeCancel = true,
  includeConfirm = true,
  includeClose = false,
  icon = 'success',
  loadingDetails = false,
}: AppointmentDialogProps): React.ReactElement | null {
  return (
    <>
      <UnexpectedError
        className={styles.errorWrapper}
        visible={snackbarError && !!snackbarError.message}
        message={snackbarError && snackbarError.message}
      />
      <ConfirmationModal
        includeClose={includeClose}
        includeCancel={includeCancel}
        includeConfirm={includeConfirm}
        label="test"
        onClose={onClose}
        onConfirm={onConfirm}
        loading={loading}
        open={active}
        cancelText="Close"
        confirmText={confirmButtonText || 'Okay'}
        alignButtons={alignButtons}
      >
        <LoadingContainer loading={loadingDetails}>
          <AppointmentDialogDetails
            icon={icon}
            clinic={clinic}
            medium={medium}
            heading={heading}
            timezoneId={timezoneId}
            scheduledTime={scheduledTime}
            duration={duration}
            consultant={consultant}
            dropdownArray={dropdownArray}
            onSelectDropdownItem={onSelectDropdownItem}
            selectedDropdownOption={selectedDropdownOption}
            error={dropdownError}
            serviceType={serviceType}
          />
        </LoadingContainer>
      </ConfirmationModal>
    </>
  )
}
