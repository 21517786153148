import React, { useState, useEffect } from 'react'
import {
  Box,
  LinkButton,
  Modal,
  useModalActions,
  withModalProvider,
} from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import { useProductConfig } from '@babylon/product-config'

import MultiLineText from '../../components/MultiLineText'
import ResetForm from '../../components/ResetForm'
import CallToActions from '../CallToActions'
import Chatbot from '../Chatbot'
import FlowList from '../FlowList'
import FlowPanel from '../FlowPanel'
import {
  AssessmentStageInterface,
  FlowInterface,
  PatientInterface,
} from '../../types'
import messages from './messages'

import styles from './View.module.scss'
import AssessmentRisksScores from '../../AssessmentRisksScores'

const getNextFlow = (flows: FlowInterface[]) =>
  flows.find((flow) => flow.status.dataPointCollectionState !== 'all_collected')

const initialChatbotState = { isOpen: false, shouldSkip: false }

const View = ({ assessmentStage, isLoading, member, refetchFlows }: Props) => {
  const fm = useFormatMessage()
  const { content, nextFlows: flows } = assessmentStage
  const [selectedFlow, setSelectedFlow] = useState(getNextFlow(flows))
  const [chatbot, setChatbot] = useState(initialChatbotState)
  const [isFlowPanelOpen, setIsFlowPanelOpen] = useState(false)
  const { closeModal, openModal } = useModalActions()
  const { getProp } = useProductConfig()

  const assessmentsResetEnabled = getProp(
    'memberOperations',
    'assessmentsResetEnabled'
  )

  useEffect(() => {
    setSelectedFlow(getNextFlow(flows))
  }, [flows])

  const onClosePanel = () => {
    setIsFlowPanelOpen(false)
    setSelectedFlow(getNextFlow(flows))
  }

  const onOpenChatbot = (shouldSkip = false) => {
    setChatbot({ isOpen: true, shouldSkip })
  }

  const onCloseChatbot = () => {
    setChatbot(initialChatbotState)
    onClosePanel()
    refetchFlows()
  }

  const onClickFlow = (id: string) => {
    const newFlow = flows?.find((flow) => flow.id === id)
    setSelectedFlow(newFlow)

    if (newFlow?.status.dataPointCollectionState === 'none_collected') {
      onOpenChatbot()
    } else {
      setIsFlowPanelOpen(true)
    }
  }

  const onSuccessResetAssessment = () => {
    refetchFlows()
  }

  const onResetAssessment = () => {
    openModal(
      <ResetForm
        member={member}
        onClose={closeModal}
        onSuccess={onSuccessResetAssessment}
      />
    )
  }

  return (
    <>
      <Box className={styles.view}>
        {content.body && (
          <Box className={styles.instruction}>
            <MultiLineText text={content.body} />
          </Box>
        )}
        {!isLoading && (
          <>
            <AssessmentRisksScores risksScores={assessmentStage.scores} />

            <CallToActions
              callToActions={assessmentStage.callToActions}
              member={member}
              onOpenChatbot={onOpenChatbot}
            />
            <FlowList
              flows={flows}
              onClickFlow={onClickFlow}
              selectedFlow={selectedFlow}
              shouldShowLoading={chatbot.isOpen && !isFlowPanelOpen}
            />
            {assessmentsResetEnabled && (
              <LinkButton
                data-testid="open-reset-assessment"
                onClick={onResetAssessment}
              >
                {fm(messages.resetAssessment)}
              </LinkButton>
            )}
            {selectedFlow && isFlowPanelOpen && (
              <FlowPanel
                flow={selectedFlow}
                isChatbotOpen={chatbot.isOpen}
                onClose={onClosePanel}
                onOpenChatbot={onOpenChatbot}
                shouldChatbotSkip={chatbot.shouldSkip}
              />
            )}
            {selectedFlow && chatbot.isOpen && (
              <Chatbot
                flowId={selectedFlow.id}
                memberUuid={member.uuid}
                shouldSkip={chatbot.shouldSkip}
                onClose={onCloseChatbot}
              />
            )}
          </>
        )}
      </Box>
      <Modal />
    </>
  )
}

interface Props {
  assessmentStage: AssessmentStageInterface
  isLoading: boolean
  member: PatientInterface
  refetchFlows: () => void
}

const enhance = withModalProvider<Props>()

export default enhance(View)
