import { gql } from '@apollo/client'
import { envFlag } from '@babylon/babylon-env'
import {
  AppointmentFeatures,
  ClientCompatibleFlows,
} from '@babylon/graphql-middleware-types'
import { ConsultationTypeName } from '../types/ConsultationTypes'

export interface CreateAppointmentData {
  appointment_features?: AppointmentFeatures
  appointment_invite_id?: string
  appointment_reason?: string
  chaperone_gender?: string
  chaperone_reason?: string
  chaperone_required?: boolean
  clinic_id?: string | null
  consultant_id?: string | null
  consultant_type?: string
  consultation_type?: ConsultationTypeName
  duration_minutes: number
  language_requested: string | null
  member_id: number
  preferred_date_time: string
  recording_consent: boolean
  service_type_uuid: string
  service_types?: string[]
  timezone_id: string | null
  needs_payment?: boolean
  client_compatible_flows?: [ClientCompatibleFlows]
}

const CreateAppointmentMutation = gql`
  mutation CreateAppointmentMutation(
    $input: AppointmentRequestInputForMicroservice!
  ) {
    bookAppointmentWithMicroservice(input: $input) {
      appointmentId: id
      member: patient {
        id
      }
      appointmentDate: time
      consultationType: medium
      consultant: consultant {
        name
        gender
      }
      clinicId: clinic {
        id
      }
      service_type_uuid
      ${envFlag('ENABLE_APPOINTMENT_TIMEZONE') ? 'timezone_id' : ''}
    }
  }
`

export default CreateAppointmentMutation
