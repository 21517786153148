import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Anchor, Table, TableCell, TableRow, Tooltip } from '@babylon/core-ui'

interface Props {
  items: Profession[]
}

const alphabeticSort = (list: Array<any>, key: string) =>
  [...list].sort((a, b) => a[key].localeCompare(b[key]))

const ProfessionsManagementList = ({ items = [] }: Props) => {
  const sortedList = useMemo(() => alphabeticSort(items, 'key'), [items])

  return (
    <Table fullWidth striped headers={['Name', 'Default duration']}>
      {sortedList.map(({ default_slot_duration_minutes, id, name, key }) => (
        <TableRow key={key}>
          <TableCell>
            <ProfessionName id={id} name={name} />
          </TableCell>
          <TableCell>{`${default_slot_duration_minutes} minutes`}</TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

export default ProfessionsManagementList

const ProfessionName = ({ id, name }: { id: string; name: string }) => {
  if (!parseInt(id, 10)) {
    const TooltipInfo = (
      <>
        Cannot be edited. Contact{' '}
        <Anchor href="mailto:supply-and-scheduling@babylonpartners.com">
          supply-and-scheduling@babylonpartners.com
        </Anchor>
      </>
    )

    return (
      <>
        <span>{name}</span>{' '}
        <Tooltip
          info={TooltipInfo}
          placement="right"
          overlayStyle={{ width: 300 }}
        />
      </>
    )
  }

  return <Link to={`professions/${id}`}>{name}</Link>
}
