export enum EnrollmentTypes {
  sms = 'SMS',
  email = 'EMAIL',
}

interface getMfaStatusProps {
  isMFAActive?: boolean
  enrollmentType: EnrollmentTypes
  mfaEnrollments?: {
    edges: {
      node: {
        id: string
        type: string
        setupUrl: string | null
        status: string
      }
    }[]
  }
}

export const getMfaStatus = ({
  isMFAActive,
  mfaEnrollments,
  enrollmentType,
}: getMfaStatusProps): boolean | undefined => {
  if (isMFAActive === undefined) {
    return undefined
  }

  const mfaEnrollment = mfaEnrollments?.edges?.find(
    (enrollment) => enrollment.node.type === enrollmentType
  )

  return isMFAActive && !!mfaEnrollment
}

export const getMfaEnrollmentId = (
  mfaEnrollments: getMfaStatusProps['mfaEnrollments'],
  enrollmentType: EnrollmentTypes
): string | undefined =>
  mfaEnrollments?.edges?.find(
    (enrollment) => enrollment.node.type === enrollmentType
  )?.node?.id
