import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { Modal, Heading, Text, Input } from '@babylon/core-ui'
import { LoadingContainer } from '../../components'
import { useDebounce } from '../../hooks'
import { NoSearchResultSVG } from '../../icons'

import GetCliniciansQuery, {
  EligibleConsultantType,
} from './GetCliniciansQuery'

import styles from './ChooseClinicianModal.module.css'

interface ChooseClinicianModalProps {
  label: string
  isOpen: boolean
  onChooseClinician: (id: number) => void
  onClose: () => void
}

export default function ChooseClinicianModal({
  label,
  isOpen,
  onChooseClinician,
  onClose,
}: ChooseClinicianModalProps) {
  const [searchValue, setSearchValue] = useState('')
  const debounceSearchValue = useDebounce(searchValue, 350)
  const willSkipSearch = debounceSearchValue.length < 3
  const {
    data = { getEligibleConsultants: [] },
    loading,
  } = useQuery<EligibleConsultantType>(GetCliniciansQuery, {
    skip: willSkipSearch,
    variables: {
      name: debounceSearchValue,
    },
  })

  const handleOnSelect = (id: number): void => {
    onChooseClinician(id)
    onClose()
  }

  return (
    <Modal
      className={styles.ChooseClinicianModal}
      label={label}
      open={isOpen}
      onClose={onClose}
    >
      <Heading level="h1">Select clinician</Heading>
      <Text>Choose a clinician to see their schedule</Text>
      <Input
        autoFocus
        data-testid="choose-clinician-search"
        className={styles.ChooseClinicianModalInputSearch}
        fill
        placeholder="Search for a clinician or enter clinician ID"
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <LoadingContainer fill loading={loading}>
        <ul
          data-testid="choose-clinician-list"
          className={styles.ChooseClinicianModalList}
        >
          {!loading && (
            <ClinicianSearchResults
              hasSearched={!willSkipSearch && !loading}
              data={data}
              onSelect={handleOnSelect}
            />
          )}
        </ul>
      </LoadingContainer>
    </Modal>
  )
}

const ClinicianSearchResults = ({
  hasSearched,
  data,
  onSelect,
}: {
  hasSearched: boolean
  data: EligibleConsultantType
  onSelect: (id: number) => void
}) => {
  if (!data?.getEligibleConsultants?.length && hasSearched) {
    return (
      <div
        data-testid="clinician-search-no-result"
        className={styles.ClinicianSearchNoResult}
      >
        <NoSearchResultSVG />
        <div className={styles.ClinicianSearchNoResultMessage}>
          No results found
        </div>
      </div>
    )
  }

  /*
   * Wrapping return in a fragment to bypass Typescript issue with components that return arrays
   * c.f. https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
   */
  return (
    <>
      {data.getEligibleConsultants.map((clinician) => (
        <li
          key={clinician.id}
          data-testid="choose-clinician-list-item"
          className={styles.ChooseClinicianModalListItem}
        >
          <button
            className={styles.ChooseClinicianModalListItemButton}
            onClick={() => onSelect(clinician.id)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSelect(clinician.id)
              }
            }}
            type="button"
            value={clinician.id}
          >
            <Text bold color="grey-type">
              {clinician.name}
            </Text>
          </button>
        </li>
      ))}
    </>
  )
}
