import { defineMessages } from 'react-intl'

const baseId = 'side_nav'

export default defineMessages({
  category_dashboard: {
    id: `${baseId}.category_dashboard`,
    defaultMessage: 'Dashboard',
  },
  category_live_chat: {
    id: `${baseId}.category_live_chat`,
    defaultMessage: 'Live Chat',
  },
  category_patients: {
    id: `${baseId}.category_patients`,
    defaultMessage: 'Patients',
  },
  category_clinicians: {
    id: `${baseId}.category_clinicians`,
    defaultMessage: 'Clinicians',
  },
  category_commercial: {
    id: `${baseId}.category_commercial`,
    defaultMessage: 'Commercial',
  },
  category_admin: {
    id: `${baseId}.category_admin`,
    defaultMessage: 'Admin',
  },
  link_all_appointments: {
    id: `${baseId}.link_all_appointments`,
    defaultMessage: 'All appointments',
  },
  link_appointments_new: {
    id: `${baseId}.link_appointments_new`,
    defaultMessage: 'Appointments',
  },
  link_appointments_old: {
    id: `${baseId}.link_appointments_old`,
    defaultMessage: 'Appointments',
  },
  link_app_settings: {
    id: `${baseId}.link_app_settings`,
    defaultMessage: 'AppSettings',
  },
  link_availability: {
    id: `${baseId}.link_availability`,
    defaultMessage: 'Availability',
  },
  link_consumer_networks: {
    id: `${baseId}.link_consumer_networks`,
    defaultMessage: 'Consumer networks',
  },
  link_drugs: {
    id: `${baseId}.link_drugs`,
    defaultMessage: 'Drugs',
  },
  link_gp_surgery_fax: {
    id: `${baseId}.link_gp_surgery_fax`,
    defaultMessage: 'GP surgery fax',
  },
  link_hospital_referrals: {
    id: `${baseId}.link_hospital_referrals`,
    defaultMessage: 'Hospital referrals',
  },
  link_nhs_registrations: {
    id: `${baseId}.link_nhs_registrations`,
    defaultMessage: 'NHS registrations',
  },
  link_network_relationships: {
    id: `${baseId}.link_network_relationships`,
    defaultMessage: 'Network relationships',
  },
  link_pharmacies: {
    id: `${baseId}.link_pharmacies`,
    defaultMessage: 'Pharmacies',
  },
  link_pharmacies_v2: {
    id: `${baseId}.link_pharmacies_v2`,
    defaultMessage: 'Pharmacies v2',
  },
  link_pharmacists: {
    id: `${baseId}.link_pharmacists`,
    defaultMessage: 'Pharmacists',
  },
  link_plans: {
    id: `${baseId}.link_plans`,
    defaultMessage: 'Plans',
  },
  link_portal_logins: {
    id: `${baseId}.link_portal_logins`,
    defaultMessage: 'Portal logins',
  },
  link_find_clinician: {
    id: `${baseId}.link_find_clinician`,
    defaultMessage: 'Find a clinician',
  },
  link_prescriptions: {
    id: `${baseId}.link_prescriptions`,
    defaultMessage: 'Prescriptions',
  },
  link_promotion_validations: {
    id: `${baseId}.link_promotion_validations`,
    defaultMessage: 'Promotion validations',
  },
  link_promotions: {
    id: `${baseId}.link_promotions`,
    defaultMessage: 'Promotions',
  },
  link_regions: {
    id: `${baseId}.link_regions`,
    defaultMessage: 'Regions',
  },
  link_reports: {
    id: `${baseId}.link_reports`,
    defaultMessage: 'Reports',
  },
  link_referrals: {
    id: `${baseId}.link_referrals`,
    defaultMessage: 'Referrals',
  },
  link_service_mix: {
    id: `${baseId}.link_service_mix`,
    defaultMessage: 'Service Types Management',
  },
  link_specialism_categories: {
    id: `${baseId}.link_specialism_categories`,
    defaultMessage: 'Specialism categories',
  },
  link_consultation_summaries: {
    id: `${baseId}.link_consultation_summaries`,
    defaultMessage: 'Consultation summaries',
  },
  link_supply_networks: {
    id: `${baseId}.link_supply_networks`,
    defaultMessage: 'Supply networks',
  },
  link_upload_promotion_codes: {
    id: `${baseId}.link_upload_promotion_codes`,
    defaultMessage: 'Upload promotion codes',
  },
  link_professions: {
    id: `${baseId}.link_professions`,
    defaultMessage: 'Professions',
  },
  link_reallocate_shift: {
    id: `${baseId}.link_reallocate_shift`,
    defaultMessage: 'Reallocate shift',
  },
  link_clinician_visibility: {
    id: `${baseId}.link_clinician_visibility`,
    defaultMessage: 'Clinician visibility',
  },
  link_care_journey: {
    id: `${baseId}.link_care_journey`,
    defaultMessage: 'Care Journey',
  },
})
