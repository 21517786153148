import { gql } from '@apollo/client'

export const APPOINTMENT_CREDIT_REASONS = gql`
  query getAppointmentCreditReasons {
    appointmentCreditReasons {
      id
      reason
    }
  }
`

export const QUERY_APPOINTMENT_CREDITS = gql`
  query AP_GetAppointmentCredits($patientId: String!) {
    appointmentCredits(patientId: $patientId) {
      id
      is_redeemed
      professions
      consumer_network_name
      show_reason
      created_at
      created_by {
        id
        fullName
      }
    }
  }
`

export const CONSUMER_NETWORKS_QUERY = gql`
  query getPatientConsumerNetwork($patientId: ID!) {
    patientConsumerNetworks(id: $patientId) {
      id
      consumer_network {
        id
        name
      }
    }
  }
`

export const PROFESSIONS_QUERY = gql`
  query getProfessions {
    professions {
      id
      name
    }
  }
`

export const CREATE_APPOINTMENT_CREDIT_MUTATION = gql`
  mutation addAppointmentCredit(
    $patientId: String!
    $consumerNetworkId: String!
    $reasonId: String!
    $professions: [String!]
  ) {
    createAppointmentCredit(
      patientId: $patientId
      consumerNetworkId: $consumerNetworkId
      reasonId: $reasonId
      professions: $professions
    ) {
      id
    }
  }
`

export const REMOVE_APPOINTMENT_CREDIT_MUTATION = gql`
  mutation removeAppointmentCredit($patientId: ID!, $appointmentCreditId: ID!) {
    removeAppointmentCredit(
      patientId: $patientId
      appointmentCreditId: $appointmentCreditId
    )
  }
`
