/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type ServiceTypesQueryVariables = Types.Exact<{ [key: string]: never }>

export type ServiceTypesQuery = {
  serviceTypes: Array<Pick<Types.ServiceType, 'uuid' | 'name'>>
}

export const ServiceTypesDocument = gql`
  query ServiceTypes {
    serviceTypes(including_digital: true) {
      uuid
      name
    }
  }
`
export function useServiceTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ServiceTypesQuery,
    ServiceTypesQueryVariables
  >
) {
  return Apollo.useQuery<ServiceTypesQuery, ServiceTypesQueryVariables>(
    ServiceTypesDocument,
    baseOptions
  )
}
export function useServiceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceTypesQuery,
    ServiceTypesQueryVariables
  >
) {
  return Apollo.useLazyQuery<ServiceTypesQuery, ServiceTypesQueryVariables>(
    ServiceTypesDocument,
    baseOptions
  )
}
export type ServiceTypesQueryHookResult = ReturnType<
  typeof useServiceTypesQuery
>
export type ServiceTypesLazyQueryHookResult = ReturnType<
  typeof useServiceTypesLazyQuery
>
export type ServiceTypesQueryResult = Apollo.QueryResult<
  ServiceTypesQuery,
  ServiceTypesQueryVariables
>
