import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { envFlag, envUrl } from '@babylon/babylon-env'
import { useBabylonUser } from '@babylon/babylon-user'
import { spinnerLoaderInstance } from '@babylon/core-ui'
import {
  AppointmentInvitesPage,
  CancelAppointmentDialog,
  Professions,
  ServiceMix,
  ReallocateShift,
  ClinicianVisibility,
  CreateAppointment,
  ScheduleAppointment,
  AppointmentManagementPage,
} from '@babylon/appointments'
import {
  ActivityLog,
  AssessmentView,
  AssessmentRiskView,
  AssessmentEarlyExitView,
  Experiments,
  LegacyPortalScreen,
  PopupDialogDeprecated,
  MembersSearch,
  MemberView,
  MemberEdit,
} from '@babylon/member-operations'
import { LiveConversationPage } from '@babylon/live-conversations'
import {
  supplyAndSchedulingRoutes,
  AvailabilitySync,
  ClinicianCalendarPage,
  AppointmentWaitTimesPage,
  AppointmentWaitTimeConfigsPage,
} from '@babylon/supply-and-scheduling'
import { useProductConfig, usePreFetchConfig } from '@babylon/product-config'
import { useTrackRoute } from '@babylon/tracking/react'
import AppLayout from '@/components/AppLayout'
import PopupRoute from '@/components/PopupRoute'
import ConsultationsForGps from '@/routes/ConsultationsForGps'
import Drugs from '@/routes/Drugs'
import Prescriptions from '@/routes/Prescriptions'
import PrescriptionAudits from '@/routes/PrescriptionAudits'
import ConversationTranscript from '@/routes/ConversationTranscript'
import styles from './styles.module.css'

import { useAccessControl } from '../../hooks'
import { getRedirectPath } from './utils'
import { Pharmacies } from '@/routes/Pharmacies'
import { Pharmacists } from '@/routes/Pharmacists'
import { GPFaxNumbers } from '@/routes/GPFaxNumbers'
import { SupplyNetworks } from '@/routes/SupplyNetworks'

const ENTERPRISE_PORTAL_URL = envUrl('ENTERPRISE_PORTAL_URL')
const INTERNAL_WEB_URL = envUrl('INTERNAL_WEB_URL')

export default function AppRouting() {
  const user = useBabylonUser()
  useTrackRoute()
  // Pre-fetching product config blob and caching it
  usePreFetchConfig()
  const { getProp } = useProductConfig()
  const assessmentsEnabled = getProp('memberOperations', 'assessmentsEnabled')
  const sideNavEnabled = getProp('memberOperations', 'sideNavEnabled')
  const digitalInviteExtensionEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteExtensionEnabled'
  )
  const [
    {
      accessConsultationSummaries,
      accessClinicianVisibility,
      accessDrugs,
      accessPrescriptions,
      accessProfessions,
      accessReallocateShift,
      accessServiceMix,
      accessLiveConversations,
      accessCareJourney,
    },
    accessControlLoading,
  ] = useAccessControl()

  if (accessControlLoading) {
    return spinnerLoaderInstance
  }

  const redirectPath = getRedirectPath(user)

  return (
    <div className={styles.appContent}>
      <AppLayout>
        <Switch>
          <Route path="/" exact>
            <Redirect to={redirectPath} />
          </Route>

          {sideNavEnabled && accessConsultationSummaries && (
            <Route
              path="/admin/appointments/to_send_to_gp"
              component={ConsultationsForGps}
            />
          )}

          {sideNavEnabled && accessClinicianVisibility && (
            <Route
              exact
              path="/admin/clinicians/clinician-visibility"
              component={ClinicianVisibility}
            />
          )}

          {sideNavEnabled && accessDrugs && (
            <Route path="/admin/drugs" component={Drugs} />
          )}

          {sideNavEnabled &&
            accessPrescriptions && [
              <Route
                key="/admin/prescriptions"
                path="/admin/prescriptions"
                render={({ match }) => (
                  <Switch>
                    <Route
                      path={match.path}
                      key={match.path}
                      component={Prescriptions}
                      exact
                    />
                    <Route
                      path={`${match.path}/:prescriptionId`}
                      key={`${match.path}/:prescriptionId`}
                      component={Prescriptions}
                    />
                  </Switch>
                )}
              />,
              <Route
                key="/admin/patients/:patientId/prescriptions"
                path="/admin/patients/:patientId/prescriptions"
                component={Prescriptions}
              />,
            ]}

          <Route
            path="/admin/patients/search"
            track={false}
            exact
            component={MembersSearch}
          />

          <Route
            path="/admin/patients"
            track={false}
            exact
            component={MembersSearch}
          />

          <Route
            path="/admin/patients/:id/memberships"
            component={MemberView}
          />
          <Route path="/admin/pharmacies_v2/:id" component={Pharmacies} />
          <Route path="/admin/pharmacies_v2" component={Pharmacies} />
          <Route path="/admin/pharmacists_v2" component={Pharmacists} />
          <Route path="/admin/fax_numbers_v2" component={GPFaxNumbers} />
          <Route path="/admin/supply_networks_v2" component={SupplyNetworks} />

          {envFlag('ENABLE_PHARMACIES_VIEW_NEW_PAGE') && (
            <Route path="/admin/pharmacies/:id" component={Pharmacies} />
          )}

          <Route
            path="/admin/patients/:patientId/conversations/:id"
            component={ConversationTranscript}
          />

          <Route path="/admin/patients/:id/invites">
            <AppointmentInvitesPage
              includingDigital={digitalInviteExtensionEnabled}
            />
          </Route>

          <Route path="/admin/patients/:id/edit" component={MemberEdit} />

          <Route
            path="/admin/patients/:id/activity_log"
            component={ActivityLog}
          />

          {assessmentsEnabled && (
            <Route
              path="/admin/patients/:id/assessment"
              render={({ match }) => (
                <>
                  <Route path={match.path} component={AssessmentView} exact />
                  <Route
                    path={`${match.path}/early-exit`}
                    component={AssessmentEarlyExitView}
                  />
                  <Route
                    path={`${match.path}/risk`}
                    component={AssessmentRiskView}
                  />
                </>
              )}
            />
          )}

          <Route
            path="/admin/patients/:id/book-appointment"
            exact
            component={CreateAppointment}
          />
          <Route
            path="/admin/patients/:id/schedule-appointment"
            exact
            component={ScheduleAppointment}
          />
          {sideNavEnabled && accessLiveConversations && (
            <Route path="/live-chat" exact component={LiveConversationPage} />
          )}

          {sideNavEnabled && accessProfessions && (
            <Route path="/admin/professions" component={Professions} />
          )}

          {sideNavEnabled && accessServiceMix && (
            <Route path="/admin/service-mix">
              <ServiceMix includingDigital={digitalInviteExtensionEnabled} />
            </Route>
          )}

          <Route path="/admin/experiments" component={Experiments} />

          {sideNavEnabled && accessReallocateShift && (
            <Route path="/admin/reallocate-shift" component={ReallocateShift} />
          )}

          {sideNavEnabled &&
            envFlag('ENABLE_APPOINTMENT_MANAGEMENT_PAGE', false) && (
              <Route
                path="/admin/practitioner/:practitionerId/appointments"
                component={AppointmentManagementPage}
              />
            )}

          {sideNavEnabled &&
            envFlag('ENABLE_APPOINTMENT_WAIT_TIMES_PAGE', false) && (
              <Route
                path={supplyAndSchedulingRoutes.appointmentWaitTimes}
                component={AppointmentWaitTimesPage}
              />
            )}

          {envFlag('ENABLE_APPOINTMENT_WAIT_TIME_CONFIGS_PAGE', false) && (
            <Route
              path={supplyAndSchedulingRoutes.appointmentWaitTimeConfigs}
              component={AppointmentWaitTimeConfigsPage}
            />
          )}

          {sideNavEnabled &&
            envFlag('ENABLE_ENTERPRISE_REDIRECT_PROMO_CODE', false) && (
              <Route
                path="/admin/promotion_code_upload"
                render={({ history }) => {
                  // The `history.replace` is there to make sure,
                  // that when the user lands on Enterprise Portal
                  // and hits the back button in the browser
                  // they will not end up redirected to EP again.
                  history.replace('/')
                  window.location.assign(
                    `${ENTERPRISE_PORTAL_URL}member-management`
                  )

                  return null
                }}
              />
            )}
          {sideNavEnabled && accessCareJourney && (
            <Route
              path="/patient-care-portal"
              render={() => {
                window.open(`${INTERNAL_WEB_URL}patient-care-portal`)
                return null
              }}
            />
          )}

          {sideNavEnabled &&
            envFlag('ENABLE_AVAILABILITY_SYNC', false) && [
              <Route
                path="/admin/availability_sync"
                key="/admin/availability_sync"
                component={AvailabilitySync}
              />,
              <Route
                path="/admin/orphaned_appointments_new"
                key="/admin/orphaned_appointments_new"
                component={AvailabilitySync}
              />,
            ]}

          {sideNavEnabled &&
            envFlag('ENABLE_CLINICIAN_CALENDAR', false) && [
              <Route
                path={supplyAndSchedulingRoutes.clinicianCalendar}
                component={ClinicianCalendarPage}
              />,
            ]}

          <Route component={LegacyPortalScreen} />
        </Switch>

        {/* prescriptions popups */}
        <PopupRoute
          path="/admin/prescriptions/audits/:id"
          component={PrescriptionAudits}
          width={undefined}
          className={undefined}
        />

        {/* cancel popups */}
        <PopupDialogDeprecated
          id="/dialog/cancelAppointment"
          component={(props: any) => (
            <CancelAppointmentDialog
              active
              {...props}
              onClose={() => {
                // window.location.reload is required to refresh legacy feed
                window.location.reload()
              }}
            />
          )}
        />
      </AppLayout>
    </div>
  )
}
