import React from 'react'
import cx from 'classnames'
import styles from './panel.module.css'

export const Panel = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.panel}>{children}</div>
)
type PanelRowProps = React.HTMLAttributes<HTMLDivElement> & {
  type?: 'default' | 'header'
}

export const PanelRow = ({
  children,
  type = 'default',
  className,
  ...props
}: PanelRowProps) => {
  const component = type === 'header' ? 'header' : 'div'

  return React.createElement(
    component,
    {
      className: cx(className, styles.panelRow),
      ...props,
    },
    children
  )
}
