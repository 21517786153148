import React, { useEffect, useState } from 'react'
import ChatbotWeb, { startNewConversation } from '@babylon/chatbot-web'
import { useFormatMessage } from '@babylon/intl'

import { logException, useSnackBar } from '../../../..'
import messages from './messages'

const Chatbot = ({ flowId, memberUuid, onClose, shouldSkip }: Props) => {
  const fm = useFormatMessage()
  const { setSnackbarMessage } = useSnackBar()
  const [initialQuestion, setInitialQuestion] = useState()

  const handleChatbotAction = (methods) => {
    const { option, handleAsDefault } = methods
    const { action } = option

    if (action) {
      if (action.type === 'appview' && action.data.view === 'healthcheck') {
        onClose()
        return
      }
    }

    handleAsDefault(methods)
  }

  useEffect(() => {
    if (!initialQuestion) {
      const conversation = {
        memberUuid,
        options: {
          data: {
            flags: {
              skip_previously_answered: shouldSkip,
            },
            flow_id: flowId,
          },
          type: 'healthcheck',
        },
      }

      startNewConversation(conversation)
        .then(setInitialQuestion)
        .catch((error: Error) => {
          logException(error)
          onClose()
          setSnackbarMessage(fm(messages.startConversationError))
        })
    }
  }, [
    flowId,
    fm,
    initialQuestion,
    memberUuid,
    onClose,
    setSnackbarMessage,
    shouldSkip,
  ])

  if (!initialQuestion) {
    return null
  }

  return (
    <ChatbotWeb
      actionHandler={handleChatbotAction}
      initialQuestion={initialQuestion}
      onGoToStart={onClose}
      startInOverlay
      trackingEnabled={false}
      wrappers={{ redux: true, theme: true }}
    />
  )
}

interface Props {
  flowId: string
  memberUuid: string
  onClose: () => void
  shouldSkip: boolean
}

export default Chatbot
