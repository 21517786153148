import { defineMessages } from 'react-intl'

const baseId = 'account_tags'

export default defineMessages({
  minor: {
    id: `${baseId}.minor`,
    defaultMessage: 'Under 16',
  },
  family: {
    id: `${baseId}.family`,
    defaultMessage: 'Family',
  },
  owner: {
    id: `${baseId}.owner`,
    defaultMessage: 'Owner',
  },
  bupa: {
    id: `${baseId}.bupa`,
    defaultMessage: 'Managed by BUPA',
  },
  id_verified: {
    id: `${baseId}.id_verified`,
    defaultMessage: 'ID verified',
  },
  id_verification_pending: {
    id: `${baseId}.id_verification_pending`,
    defaultMessage: 'ID verification pending',
  },
  id_verification_failed: {
    id: `${baseId}.id_verification_failed`,
    defaultMessage: 'ID verification failed',
  },
  no_id_verification: {
    id: `${baseId}.no_id_verification`,
    defaultMessage: 'No ID submitted',
  },
  pds_status: {
    id: `${baseId}.pds_status`,
    defaultMessage: 'PDS - {status}',
  },
  sms_mfa_enabled: {
    id: `${baseId}.sms_mfa_enabled`,
    defaultMessage: 'SMS MFA Enabled',
  },
  sms_mfa_disabled: {
    id: `${baseId}.sms_mfa_disabled`,
    defaultMessage: 'SMS MFA Disabled',
  },
  informed_consent_required: {
    id: `${baseId}.informed_consent_required`,
    defaultMessage: 'CONSENT REQUIRED',
  },
  medical_history_auth_consent_required: {
    id: `${baseId}.medical_history_auth_required`,
    defaultMessage: 'AUTHORIZATION REQUIRED',
  },
  preregistered: {
    id: `${baseId}.preregistered`,
    defaultMessage: 'Pre-registered',
  },
  pending_registration: {
    id: `${baseId}.pending_registration`,
    defaultMessage: 'Pending registration',
  },
  preferred_consumer_network: {
    id: `${baseId}.preferred_consumer_network`,
    defaultMessage: '{preferredCN}',
  },
})
