import { ColumnsVisibility } from '@/components/DataGrid'

export enum PHARMACY_DATAGRID_COLUMNS {
  ID = 'id',
  NAME = 'name',
  ADDRESS = 'address',
  BLOCKED = 'blocked',
  BLOCK_REASON = 'block_reason',
  DELIVERY_TYPE = 'delivery_type',
  FAX_NUMBER = 'fax_number',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  PHONE_NUMBER = 'phone_number',
  GOOGLE_ID = 'google_id',
  GOOGLE_REFERENCE = 'google_reference',
  LAT = 'lat',
  LNG = 'lng',
  REFERENCE = 'reference',
  OPENING_HOURS = 'opening_hours',
  RATING = 'rating',
}

export const columnsToShow: ColumnsVisibility = {
  [PHARMACY_DATAGRID_COLUMNS.ID]: false,
  [PHARMACY_DATAGRID_COLUMNS.NAME]: true,
  [PHARMACY_DATAGRID_COLUMNS.ADDRESS]: true,
  [PHARMACY_DATAGRID_COLUMNS.BLOCKED]: true,
  [PHARMACY_DATAGRID_COLUMNS.BLOCK_REASON]: true,
  [PHARMACY_DATAGRID_COLUMNS.DELIVERY_TYPE]: true,
  [PHARMACY_DATAGRID_COLUMNS.FAX_NUMBER]: true,
  [PHARMACY_DATAGRID_COLUMNS.CREATED_AT]: false,
  [PHARMACY_DATAGRID_COLUMNS.UPDATED_AT]: false,
  [PHARMACY_DATAGRID_COLUMNS.PHONE_NUMBER]: false,
  [PHARMACY_DATAGRID_COLUMNS.GOOGLE_ID]: false,
  [PHARMACY_DATAGRID_COLUMNS.GOOGLE_REFERENCE]: false,
  [PHARMACY_DATAGRID_COLUMNS.LAT]: false,
  [PHARMACY_DATAGRID_COLUMNS.LNG]: false,
  [PHARMACY_DATAGRID_COLUMNS.REFERENCE]: false,
  [PHARMACY_DATAGRID_COLUMNS.OPENING_HOURS]: false,
  [PHARMACY_DATAGRID_COLUMNS.RATING]: false,
}

export const columns = [
  {
    fieldNameInRow: PHARMACY_DATAGRID_COLUMNS.NAME,
    headerName: 'Name',
    flex: 2,
    sortable: false,
  },
  {
    fieldNameInRow: PHARMACY_DATAGRID_COLUMNS.ADDRESS,
    headerName: 'Address',
    flex: 3,
    sortable: false,
  },
  {
    fieldNameInRow: PHARMACY_DATAGRID_COLUMNS.BLOCKED,
    headerName: 'Blocked',
    flex: 1,
    sortable: false,
  },
  {
    fieldNameInRow: PHARMACY_DATAGRID_COLUMNS.BLOCK_REASON,
    headerName: 'Block Reason',
    flex: 2,
    sortable: false,
  },
  {
    fieldNameInRow: PHARMACY_DATAGRID_COLUMNS.DELIVERY_TYPE,
    headerName: 'Delivery Type',
    flex: 1,
    sortable: false,
  },
  {
    fieldNameInRow: PHARMACY_DATAGRID_COLUMNS.FAX_NUMBER,
    headerName: 'Fax number',
    flex: 1,
    sortable: false,
  },
]
