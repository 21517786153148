import { microservicesClient } from '../../client'
import {
  replyWithMessageResponseRest,
  replyWithMessageApiProps,
} from '../../../constants/types'
import { getBaseChatscriptUrl } from '../../util/baseUrls'

export const convertDualLanguageMessage = (message: any) => {
  const convert = (
    key: string,
    stringOrObject: string | { internal: string; member: string }
  ) => {
    if (stringOrObject === undefined) {
      return undefined
    }

    if (
      typeof stringOrObject === 'string' ||
      stringOrObject instanceof String
    ) {
      return {
        [key]: stringOrObject,
        [`${key}Internal`]: stringOrObject,
      }
    }

    return {
      [key]: stringOrObject.member,
      [`${key}Internal`]: stringOrObject.internal,
    }
  }

  return {
    ...message,
    input: message.input
      ? {
          ...message.input,
          params: {
            ...message.input.params,
            choices: message.input.params.choices.map((choice: any) => ({
              ...choice,
              ...convert('label', choice.label),
            })),
          },
        }
      : undefined,
    value: {
      ...message.value,
      ...convert('text', message.value.text),
      ...convert('help_text', message.value.help_text),
    },
  }
}

const replyWithMessageRest = async ({
  conversationId,
  etag,
  memberUuid,
  message,
}: replyWithMessageApiProps) => {
  const baseUrl = getBaseChatscriptUrl(memberUuid)

  return microservicesClient
    .post<Omit<replyWithMessageResponseRest, 'etag'>>(
      `${baseUrl}/conversations/${conversationId}/say`,
      message,
      {
        headers: {
          'If-Match': etag,
        },
      }
    )
    .then((response) => ({
      ...response.data,
      messages: response.data.messages.map((_message) =>
        convertDualLanguageMessage(_message)
      ),
      etag: response.headers.etag as string,
    }))
}

export default replyWithMessageRest
