import { useCallback } from 'react'
import { ClickEventPayload, TrackingActionType } from '../types'
import { useTracking } from './useTracking'

type Props = {
  moduleName: string
}

/**
 * Hook provides a convience method to track tealium clicks
 */
export function useTrackClick({ moduleName }: Props) {
  const { trackEvent } = useTracking()

  const trackClick = useCallback(
    ({ elementName, elementType, href }: ClickEventPayload) => {
      const withHref = href && { link_destination: href }

      trackEvent({
        actionType: TrackingActionType.click,
        elementName,
        elementType,
        moduleName,
        ...withHref,
      })
    },
    [moduleName, trackEvent]
  )

  return { trackClick }
}
