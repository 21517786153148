import { defineMessages } from 'react-intl'

const baseId = 'wait_time_configs_delete_dialog'

export default defineMessages({
  deleteWaitTimeThresholdTitle: {
    id: `${baseId}.delete_wait_time_threhsold_title`,
    defaultMessage: 'Are you sure to delete wait time threshold?',
  },
  deleteWaitTimeThresholdMessage: {
    id: `${baseId}.delete_wait_time_threhsold_message`,
    defaultMessage:
      'Deleting wait time threshold will result in wait time status to be generated with default wait time threshold.',
  },
  consumerNetworkTitle: {
    id: `${baseId}.consumer_network_title`,
    defaultMessage: 'Consumer Network',
  },
  serviceTypeTitle: {
    id: `${baseId}.service_type_title`,
    defaultMessage: 'Service Type',
  },
  deleteButtonTitle: {
    id: `${baseId}.delete_button_title`,
    defaultMessage: 'Delete',
  },
  cancelButtonTitle: {
    id: `${baseId}.cancel_button_title`,
    defaultMessage: 'Cancel',
  },
})
