import React from 'react'
import { PortalLoginProvider } from '@babylon/babylon-auth'

interface AuthenticationProps {
  children: React.ReactNode
}

const handleError = (msg: string) => {
  console.error(msg)
  // TODO: we should also display the error to the user too
}

export const CoreRubyAuthProvider = ({ children }: AuthenticationProps) => (
  <PortalLoginProvider onError={(err) => handleError(err.message)}>
    {children}
  </PortalLoginProvider>
)
