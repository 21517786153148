import React from 'react'
import { Link } from 'react-router-dom'
import copy from 'clipboard-copy'
import Tooltip from 'rc-tooltip'
import {
  DataGrid,
  Columns,
  Column,
  Actions,
  Pagination,
  Select,
  Spinner,
} from '@babylon/babylon-forms/widgets'
import { Button } from '@babylon/babylon-forms/forms'
import { PatientName } from '@babylon/member-operations'

import Icon from '@/components/Icon'
import LabelWithIcon from '@/components/LabelWithIcon'
import TimezoneDate from '@/components/TimezoneDate'

import Filters from './Filters'
import PrescriptionActions from './PrescriptionActions'
import PaginationSize from './PaginationSize'
import {
  formatDrug,
  formatBadgeStatus,
  formatBadgeDelivery,
  formatAddress,
  formatBadgeSignatureState,
} from '../utils'

import styles from './styles.module.scss'

const copyUrlToClipboard = (id) => () => {
  copy(`${location.origin}/admin/prescriptions/${id}`) //eslint-disable-line
}

const ItemView = ({ item, actions, context: { handleConsultantIdClick } }) => {
  const {
    id,
    consultationId,
    patient: {
      id: patientId,
      first_name: patientFirstName,
      last_name: patientLastName,
    },
    drugs,
    createdAt,
    updatedAt,
    consultant: { id: consultantId, name: consultantName },
    pharmacyId,
    pharmacyDetails,
    prescriptionState,
    signatureState,
    deliveryMethod,
    latestComment,
  } = item

  return [
    <tr key="1">
      <td rowSpan={2} className={styles.noWrap}>
        <Tooltip
          transitionName="tooltip-fade"
          mouseEnterDelay={0.05}
          mouseLeaveDelay={0}
          trigger={['hover']}
          overlay="Copy prescription URL"
          placement="top"
        >
          <Icon
            onClick={copyUrlToClipboard(id)}
            role="button"
            tabIndex="-1"
            name="clipboard"
            className={styles.icon}
          />
        </Tooltip>{' '}
        <strong>
          <PatientName
            firstName={patientFirstName}
            lastName={patientLastName}
            id={patientId}
          />
        </strong>
        <br />
        <Link
          className={styles.buttonLink}
          to={`/admin/appointments/${consultationId}`}
        >
          View consultation
        </Link>
      </td>
      <td rowSpan={2}>{drugs.map(formatDrug)}</td>
      <td rowSpan={2} className={styles.narrowRow}>
        <TimezoneDate date={createdAt} />
      </td>
      <td rowSpan={2} className={styles.narrowRow}>
        <TimezoneDate date={updatedAt} />
      </td>
      <td className={styles.innerRow}>
        {/* TO-DO: find solution for accessibility */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          role="button"
          tabIndex="0"
          onClick={handleConsultantIdClick(consultantId)}
        >
          {consultantName}
        </a>
      </td>
      <td className={styles.innerRow}>
        {pharmacyId ? (
          <Link to={`/admin/pharmacies/${pharmacyId}`}>
            <span className={styles.addressWrap}>
              {formatAddress(pharmacyDetails)}
            </span>
          </Link>
        ) : (
          formatAddress(pharmacyDetails)
        )}
      </td>
      <td className={styles.innerRow}>
        {formatBadgeStatus(prescriptionState)}
        <br />
        {formatBadgeDelivery(deliveryMethod)}
        <br />
        {formatBadgeSignatureState(signatureState)}
      </td>
      <DataGrid.ItemActions item={item} actions={actions} rowSpan={2} />
    </tr>,
    <tr key="2">
      <td colSpan={3} className={styles.commentRow}>
        {latestComment ? <div>{latestComment}</div> : null}
      </td>
    </tr>,
  ]
}

const PrescriptionsView = ({
  varPage,
  varSize,
  varSort,
  setPage,
  setSize,
  varTimePreset,
  lastChangedVariable,
  data: {
    loading,
    prescriptions: { items, totalItems },
  },
  handleAction,
  filter,
  showFilter,
  setFilterVisible,
  setTimePreset,
  updateFilter,
  hasFilter,
  handleAudits,
  handleComment,
  handleSort,
  downloadCSV,
  regions,
  downloadRegion,
  updateDownloadRegion,
  handleConsultantIdClick,
  handleFilterReset,
  handlePresetClick,
}) => {
  const DownloadButton = ({ period, label }) => (
    <Button
      type="button"
      small
      onClick={downloadCSV(period)}
      disabled={!downloadRegion}
    >
      {label}
    </Button>
  )

  return (
    <div className={styles.prescriptionsView}>
      <div className={styles.listHeader}>
        <PaginationSize
          value={varSize}
          onChange={setSize}
          totalItems={totalItems}
          spinner={
            <Spinner visible={lastChangedVariable === 'varSize' && loading} />
          }
        />
        <div className={styles.filterToolbar}>
          <Spinner
            visible={lastChangedVariable === 'varTimePreset' && loading}
          />
          <Select
            small
            value={varTimePreset}
            onChange={(_, value) => setTimePreset(value)}
            placeholder="Select time preset"
          >
            <option value="">None</option>
            <option value="15 minutes">Last 15 minutes</option>
            <option value="60 minutes">Last 60 minutes</option>
            <option value="2 hours">Last 2 hours</option>
            <option value="4 hours">Last 4 hours</option>
            <option value="8 hours">Last 8 hours</option>
            <option value="24 hours">Last 24 hours</option>
            <option value="2 days">Last 2 days</option>
            <option value="3 days">Last 3 days</option>
            <option value="5 days">Last 5 days</option>
            <option value="7 days">Last 7 days</option>
          </Select>
          <Button
            small
            outline={!hasFilter}
            alert={hasFilter}
            onClick={() => setFilterVisible(!showFilter)}
          >
            {showFilter ? 'Hide filters' : 'Show filters'}
          </Button>
          <Spinner visible={lastChangedVariable === 'varFilter' && loading} />
        </div>
      </div>
      {showFilter ? (
        <Filters
          store={filter}
          onChange={updateFilter}
          handleFilterReset={handleFilterReset}
          handlePresetClick={handlePresetClick}
        />
      ) : null}
      <DataGrid
        value={items}
        ItemView={ItemView}
        sort={{
          ...varSort,
          loading: lastChangedVariable === 'varSort' && loading,
        }}
        onSort={handleSort}
        icons={{
          asc: <Icon name="sort-up" />,
          desc: <Icon name="sort-down" />,
          sort: <Icon name="sort" />,
          spinner: <Spinner visible />,
        }}
        context={{ handleConsultantIdClick }}
      >
        <Columns>
          <Column name="patientName" label="Patient" />
          <Column name="drugs" label="Drugs" />
          <Column name="createdAt" label="Issue Date" sort />
          <Column name="updatedAt" label="Last Updated" sort />
          <Column name="consultantName" label="Consultant" />
          <Column name="pharmacyDetails" label="Pharmacy" />
          <Column name="prescriptionState" label="Status" />
        </Columns>
        <Actions>
          {({ item: { actions } }) => (
            <span>
              <PrescriptionActions actions={actions} onAction={handleAction} />
              <Button small onClick={handleAudits}>
                <LabelWithIcon icon="logs" label="History" />
              </Button>
              <Button small onClick={handleComment}>
                <LabelWithIcon icon="add" label="Add comment" />
              </Button>
            </span>
          )}
        </Actions>
      </DataGrid>
      <div className={styles.bottomToolbar}>
        <div className={styles.download}>
          <p>Download Drug Prescriptions as CSV for:</p>
          <div>
            {regions ? (
              <Select
                autocomplete
                optionsAbove
                small
                value={downloadRegion}
                onChange={(_, value) => updateDownloadRegion(value)}
                options={regions.prescriptionRegions || []}
                labelKey="name"
                valueKey="regionCode"
              />
            ) : null}
            <DownloadButton period="day" label="Today" />
            <DownloadButton period="week" label="This week" />
            <DownloadButton period="month" label="This month" />
            <DownloadButton period="year" label="This year" />
          </div>
        </div>
        <div className={styles.rowRight}>
          <Spinner visible={lastChangedVariable === 'varPage' && loading} />
          <Pagination
            page={varPage}
            size={varSize}
            onChange={setPage}
            totalElements={totalItems}
          />
        </div>
      </div>
    </div>
  )
}

export default PrescriptionsView
