import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { envFlag } from '@babylon/babylon-env'
import { Page } from '@babylon/core-ui'
import { Box, Button, Pagination, Grid } from '@mui/material'
import { useFormatMessage } from '@babylon/intl'
import { routes } from '../../constants/routes'
import { useAppointmentWaitTimesQuery } from './AppointmentWaitTimes.middleware.hooks'
import { AppointmentWaitTimeTable } from '../../AvailabilitySync/AppointmentWaitTimeTable'
import messages from './AppointmentWaitTimes.messages'
import styles from './AppointmentWaitTimes.module.css'
import { ConsumerNetworkSearcher } from '../../AvailabilitySync/ConsumerNetworkSearcher'
import { ServiceTypesSearcher } from '../../AvailabilitySync/ServiceTypesSearcher'
import { Option } from '../../components/InputFilter'

const defaultPage = 1
const refetchIntervalMs = 30000
const fetchLimit = 15
const manageWaitTimeConfigurationsButtonName = 'Manage configurations'

export const AppointmentWaitTimesPage = () => {
  const [selectedConsumerNetworks, setSelectedConsumerNetworks] = useState<
    Option[]
  >([])
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<Option[]>([])

  const fm = useFormatMessage()
  const [page, setPage] = useState(defaultPage)
  const offset = (page - 1) * fetchLimit

  const {
    data: appointmentWaitTimesData,
    loading: isAppointmentWaitTimesLoading,
  } = useAppointmentWaitTimesQuery({
    variables: {
      offset,
      limit: fetchLimit,
      consumerNetworkIds: selectedConsumerNetworks.map(({ id }) => id),
      serviceTypeUuids: selectedServiceTypes.map(({ uuid }) => uuid),
    },
    fetchPolicy: 'network-only',
    pollInterval: refetchIntervalMs,
  })

  const total = appointmentWaitTimesData?.appointmentWaitTimes.total || 0

  return (
    <Page>
      <h1 className={styles.heading}>{fm(messages.pageTitle)}</h1>
      <p className={styles.intro}>{fm(messages.pageIntro)}</p>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Box display="flex" gap="15px">
            <ConsumerNetworkSearcher
              readOnly={false}
              multiple
              onSelectedConsumerNetworksChanged={(consumerNetworks) => {
                setPage(defaultPage)
                setSelectedConsumerNetworks(consumerNetworks)
              }}
            />
            <ServiceTypesSearcher
              readOnly={false}
              multiple
              onSelectedServiceTypesChanged={(serviceTypes) => {
                setPage(defaultPage)
                setSelectedServiceTypes(serviceTypes)
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid>
              {envFlag('ENABLE_APPOINTMENT_WAIT_TIME_CONFIGS_PAGE', false) ? (
                <Link to={routes.appointmentWaitTimeConfigs}>
                  <Button
                    variant="outlined"
                    style={{ textTransform: 'none' }}
                    color="secondary"
                  >
                    {manageWaitTimeConfigurationsButtonName}
                  </Button>
                </Link>
              ) : undefined}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AppointmentWaitTimeTable
        waitTimes={
          appointmentWaitTimesData?.appointmentWaitTimes.waitTimes ?? []
        }
        isLoading={isAppointmentWaitTimesLoading}
      />

      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Pagination
          count={Math.floor(total / fetchLimit) + 1}
          onChange={(_, pageNumber) => setPage(pageNumber)}
          color="primary"
          page={page}
        />
      </Box>
    </Page>
  )
}

export default AppointmentWaitTimesPage
