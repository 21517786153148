import React, { useState } from 'react'
import { format } from 'date-fns'
import { useMutation } from '@apollo/client'
import { useFormatMessage, useIntl } from '@babylon/intl'
import { Box, ButtonSimple, ButtonVariant, Text } from '@babylon/medkit'

import { logException, useSnackBar } from '../../../..'
import { INVALIDATE_ANSWERS } from '../../mutations'
import { PatientInterface } from '../../types'
import messages from './messages'

import styles from './ResetForm.module.scss'

const ResetForm = ({ member, onClose, onSuccess }: Props) => {
  const fm = useFormatMessage()
  const intl = useIntl()
  const { setSnackbarMessage } = useSnackBar()
  const [isLoading, setIsLoading] = useState(false)
  const [invalidateAnswers] = useMutation(INVALIDATE_ANSWERS)

  const onReset = async () => {
    setIsLoading(true)
    const timeZoneOffset = format(new Date(), 'xxx')
    try {
      await invalidateAnswers({
        variables: {
          locale: intl.locale,
          memberId: member.uuid,
          timeZoneOffset: timeZoneOffset,
        },
      })
    } catch (error) {
      logException(error)
      setSnackbarMessage(fm(messages.resetError))
      setIsLoading(false)

      return
    }

    onClose()
    onSuccess()
  }

  return (
    <>
      <Text className={styles.title} tag="h1" variant="h3">
        {fm(messages.title)}
      </Text>
      <Text className={styles.description} variant="body">
        {fm(messages.description)}
      </Text>
      <Box className={styles.buttons}>
        <ButtonSimple
          data-testid="reset-form-reset"
          loadingLabel=""
          isLoading={isLoading}
          fullWidth
          onClick={() => {
            onReset()
          }}
        >
          {fm(messages.reset)}
        </ButtonSimple>
        <ButtonSimple
          data-testid="reset-form-cancel"
          disabled={isLoading}
          fullWidth
          onClick={onClose}
          variant={ButtonVariant.secondary}
        >
          {fm(messages.cancel)}
        </ButtonSimple>
      </Box>
    </>
  )
}

interface Props {
  member: PatientInterface
  onClose: () => void
  onSuccess: () => void
}

export default ResetForm
