import React, { useState } from 'react'
import { Formik } from 'formik'
import { format } from 'date-fns'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useFormatMessage, useIntl } from '@babylon/intl'
import { Box, ButtonSimple, ButtonVariant, Text } from '@babylon/medkit'

import { logException, useSnackBar } from '../../../..'
import { SUBMIT_ASSESSMENT } from '../../mutations'
import { PatientInterface } from '../../types'
import messages from './messages'

import styles from './SubmitForm.module.scss'

const SubmitForm = ({ member, onClose, reason, riskLevel = '' }: Props) => {
  const fm = useFormatMessage()
  const history = useHistory()
  const intl = useIntl()
  const { setSnackbarMessage } = useSnackBar()
  const [isLoading, setIsLoading] = useState(false)
  const [submitAssessment] = useMutation(SUBMIT_ASSESSMENT)

  const onSubmit = async () => {
    setIsLoading(true)
    const timeZoneOffset = format(new Date(), 'xxx')

    try {
      await submitAssessment({
        variables: {
          locale: intl.locale,
          memberId: member.uuid,
          reason,
          riskLevel,
          timeZoneOffset,
        },
      })
      history.push(`/admin/patients/${member.id}/memberships`)
    } catch (error) {
      logException(error)
      setSnackbarMessage(fm(messages.submitError))
      setIsLoading(false)
    }
  }

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          <Text className={styles.title} tag="h1" variant="h3">
            {fm(messages.title)}
          </Text>
          <Text className={styles.description} variant="body">
            {fm(messages.description)}
          </Text>

          <Box className={styles.buttons}>
            <ButtonSimple
              data-testid="submit-form-submit"
              loadingLabel=""
              isLoading={isLoading}
              fullWidth
              type="submit"
            >
              {fm(messages.submit)}
            </ButtonSimple>
            <ButtonSimple
              data-testid="submit-form-cancel"
              disabled={isLoading}
              fullWidth
              onClick={onClose}
              variant={ButtonVariant.secondary}
            >
              {fm(messages.cancel)}
            </ButtonSimple>
          </Box>
        </form>
      )}
    </Formik>
  )
}

interface Props {
  member: PatientInterface
  onClose: () => void
  reason: string
  riskLevel?: string
}

export default SubmitForm
