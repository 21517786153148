import React from 'react'

import cn from 'classnames'
import { useProductConfig } from '@babylon/product-config'

import { Text } from '@babylon/medkit'
import { FlowScoresInterface } from '../types'
import styles from './AssessmentRisksScores.module.scss'

const AssessmentRisksScores = ({ risksScores }: Props) => {
  const { getProp } = useProductConfig()
  const riskStratificationScoresEnabled = getProp(
    'memberOperations',
    'b360V2Features.riskStratificationScoresEnabled'
  )

  if (
    !riskStratificationScoresEnabled ||
    !risksScores ||
    risksScores.length < 1
  ) {
    return null
  }

  return (
    <div data-testid="risks-scores-container" className={styles.risksContainer}>
      <dl>
        {risksScores?.map((riskScore) => (
          <div
            key={riskScore.id}
            data-testid="risks-score-item"
            className={styles.risksItemContainer}
          >
            <Text className={styles.risksItem} tag="dt" variant="h5">
              {riskScore.name}
            </Text>
            <Text
              className={cn(
                styles.risksItem,
                riskScore.result?.ragStatus &&
                  styles[`risksScoreColour_${riskScore.result?.ragStatus}`]
              )}
              tag="dd"
              variant="body"
            >
              {riskScore.result?.severityText || '-'}
            </Text>
          </div>
        ))}
      </dl>
    </div>
  )
}

interface Props {
  risksScores: FlowScoresInterface[] | null
}

export default AssessmentRisksScores
