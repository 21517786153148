import React from 'react'
import { ArrowRight } from '@babylon/icons'
import { useFormatMessage } from '@babylon/intl'
import { Box, Spinner, Text } from '@babylon/medkit'

import DataPointStateText from '../../components/DataPointStateText'
import { FlowInterface } from '../../types'
import messages from './messages'

import styles from './FlowList.module.scss'

const FlowList = ({
  flows,
  onClickFlow,
  selectedFlow,
  shouldShowLoading,
}: Props) => {
  const fm = useFormatMessage()

  return (
    <ul className={styles.flowList} data-testid="assessment-flow-list">
      {flows.map((flow) => {
        const isFlowLoading = selectedFlow?.id === flow.id && shouldShowLoading
        const percentageComplete =
          flow.status.dataPointCompleteness?.percentageComplete || 0

        return (
          <li className={styles.flowListItem} key={flow.id}>
            <button
              className={styles.flowWrapper}
              data-testid={flow.id}
              onClick={() => onClickFlow(flow.id)}
              type="button"
            >
              <Box className={styles.flow} padding="medium">
                <div className={styles.flowText}>
                  <Text tag="span" variant="h4">
                    {flow.name}
                  </Text>
                  <DataPointStateText
                    state={flow.status.dataPointCollectionState}
                    text={fm(messages.percentageComplete, {
                      percentageComplete,
                    })}
                  />
                </div>
                {isFlowLoading ? (
                  <Spinner variant="primary" />
                ) : (
                  <ArrowRight height={36} width={36} />
                )}
              </Box>
            </button>
          </li>
        )
      })}
    </ul>
  )
}

interface Props {
  flows: FlowInterface[]
  onClickFlow: (id: string) => void
  selectedFlow?: FlowInterface
  shouldShowLoading: boolean
}

export default FlowList
