import { defineMessages } from 'react-intl'

const baseId = 'conversation_history_messages'

export default defineMessages({
  outcomeHeader: {
    id: `${baseId}.outcome_header`,
    defaultMessage: 'Outcome',
  },
  empty: {
    id: `${baseId}.empty`,
    defaultMessage: 'No results',
  },
})
