import React, { useCallback } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'

import {
  Button,
  Page,
  Pagination,
  Spinner,
  Text,
  Table,
  TableRow,
  TableCell,
} from '@babylon/core-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import TimezoneDate from '../Utils/TimezoneDate'
import {
  validPageNumber,
  QueryString,
  PageParams,
  handlePrevClick,
  handleNextClick,
} from '../Utils/PaginationUtils'
import styles from './OrphanedAppointmentsPage.module.css'
import { useGetOrphanedSlotsQuery } from './GetOrphanedSlots.hooks'
import { PaginationTotalCount } from '../../components/PaginationTotalCount'
import { buildOldClinicianCalendarPageUrl } from '../../utils/page'

const PER_PAGE = 20

const OrphanedAppointmentsPage = () => {
  const history = useHistory()
  const location = useLocation()
  const querystring: QueryString = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 1,
  })

  const page = validPageNumber(querystring?.page)
    ? parseInt(querystring?.page, 10)
    : 1
  const pageParams: PageParams = {
    limit: PER_PAGE,
    offset: page * PER_PAGE - PER_PAGE,
  }

  const { loading, error, data, refetch } = useGetOrphanedSlotsQuery({
    variables: {
      ...pageParams,
    },
    fetchPolicy: 'network-only',
  })
  const total = data?.orphanedSlots.total || 0
  const totalPages = data ? Math.ceil(total / PER_PAGE) : 1

  const handlePageClick = (selectedPage: number) => {
    if (!validPageNumber(selectedPage)) {
      return
    }

    history.push({
      search: qs.stringify({ page: selectedPage }),
    })
  }

  const handleRefetch = useCallback(() => {
    refetch().catch((err) => console.error(err))
  }, [refetch])

  return (
    <Page title="Orphaned Appointments">
      <div className={styles.pagination}>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPrevClick={() => handlePrevClick(page, history)}
          onNextClick={() => handleNextClick(page, totalPages, history)}
          onPageClick={handlePageClick}
        />

        <div>
          <PaginationTotalCount totalCount={total} />
        </div>
      </div>

      <Table
        fullWidth
        striped
        headers={[
          <Text bold>Orphaned at</Text>,
          <Text bold>Appointment date</Text>,
          <Text bold>Appointment id</Text>,
          <Text bold>Clinician</Text>,
          <Text bold>Patient</Text>,
        ]}
      >
        {loading && (
          <TableRow>
            <td colSpan={6} className={styles.SingleCol}>
              <Spinner size="large" color="gray" testid="spinner" centered />
            </td>
          </TableRow>
        )}

        {error && (
          <TableRow>
            <td colSpan={9} className={styles.SingleCol}>
              There was an error fetching the list, please{' '}
              <Button
                loading={loading}
                type="button"
                inline
                onClick={() => handleRefetch()}
              >
                try reloading
              </Button>
            </td>
          </TableRow>
        )}

        {!loading &&
          data?.orphanedSlots &&
          data.orphanedSlots.slots.length > 0 &&
          data.orphanedSlots.slots.map((slot) => (
            <TableRow key={`row_${slot.appointment_id}_${slot.time}`}>
              <TableCell>
                <Text data-testid="orphaned-at">
                  <TimezoneDate
                    date={slot.updated_at}
                    format="MMM do, yyyy kk:mm:ss"
                  />
                </Text>
              </TableCell>
              <TableCell>
                <Text data-testid="appointment-date">
                  <TimezoneDate date={slot.time} format="MMM do, yyyy kk:mm" />
                </Text>
              </TableCell>
              <TableCell>
                <Text data-testid="appointment-id">{slot.appointment_id}</Text>
              </TableCell>
              <TableCell>
                <div className={styles.clinicianCell}>
                  <Text data-testid="clinician">
                    {slot.consultant_name} ({slot.consultant_id})
                  </Text>
                  {slot.consultant_id && (
                    <Link
                      to={buildOldClinicianCalendarPageUrl(slot.consultant_id)}
                      className={styles.consultantAvailabilityLink}
                    >
                      <FontAwesomeIcon
                        icon={faCalendar}
                        title="View consultant availability"
                      />
                    </Link>
                  )}
                </div>
              </TableCell>
              <TableCell>
                {slot.patient_name !== null && (
                  <Text data-testid="patient">
                    {slot.patient_name} ({slot.patient_id})
                  </Text>
                )}
                {slot.patient_name === null && (
                  <Text
                    data-testid="patient"
                    className={styles.ColumnMissingDataText}
                  >
                    missing
                  </Text>
                )}
              </TableCell>
            </TableRow>
          ))}
      </Table>
    </Page>
  )
}

export default OrphanedAppointmentsPage
