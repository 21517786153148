import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Box, LinkButton, Text } from '@babylon/medkit'

import FlowsSummary from '../../components/FlowsSummary'
import { PatientInterface } from '../../types'
import RiskForm from '../RiskForm'
import messages from './messages'

import styles from './View.module.scss'

const View = ({ member }: Props) => {
  const fm = useFormatMessage()
  const [isSummaryOpen, setIsSummaryOpen] = useState(false)

  return (
    <Box className={styles.view}>
      <Text className={styles.instruction} variant="body">
        {fm(messages.instruction)}
      </Text>

      <Text className={styles.instructionTitle} variant="body" tag="h4">
        {fm(messages.adultInstructionTitle)}
      </Text>
      <Text className={styles.instruction} variant="body">
        {fm(messages.adultInstruction)}
      </Text>

      <Text className={styles.instructionTitle} variant="body" tag="h4">
        {fm(messages.pregnantInstructionTitle)}
      </Text>
      <Text className={styles.instruction} variant="body">
        {fm(messages.pregnantInstruction)}
      </Text>

      <Text className={styles.instructionTitle} variant="body" tag="h4">
        {fm(messages.partiallyCompletedAssessmentInstructionTitle)}
      </Text>
      <Text className={styles.instruction} variant="body">
        {fm(messages.partiallyCompletedAssessmentInstruction)}
      </Text>

      <LinkButton
        data-testid="open-assessment-summary"
        onClick={() => setIsSummaryOpen(true)}
      >
        {fm(messages.summary)}
      </LinkButton>
      <RiskForm member={member} />
      {isSummaryOpen && (
        <FlowsSummary onClose={() => setIsSummaryOpen(false)} />
      )}
    </Box>
  )
}

interface Props {
  member: PatientInterface
}

export default View
