import { defineMessages } from 'react-intl'

const baseId = 'clinician_name'

export default defineMessages({
  gender_female_short: {
    id: `${baseId}.gender_female_short`,
    defaultMessage: 'F',
  },
  gender_male_short: {
    id: `${baseId}.gender_male_short`,
    defaultMessage: 'M',
  },
})
