import { ReactNode } from 'react'
import { SnackbarIntent } from '@babylon/core-ui'

export const memberEditFields = [
  'id',
  'uuid',
  'first_name',
  'last_name',
  'date_of_birth',
  'email',
  'address_first_line',
  'address_second_line',
  'address_third_line',
  'address_state_code',
  'address_post_code',
  'full_phone_number',
  'gender',
  'emergency_contact_details',
  'birth_details',
  'region',
  'region.iso_code',
  'public_healthcare_details',
  'gp_details',
  'healthcare_identifier',
  'previous_names',
  'previous_practice_name',
] as const

export interface Alert {
  id: string
  message: string
  updatedAt: string
  updatedByName: string
}

export interface Comment {
  id: string
  created_at: string
  note: string
  created_by: {
    name: string
  }
}

export type PartialMemberForEditKeys = typeof memberEditFields[number]

export type PartialMemberForEdit = Pick<Member, PartialMemberForEditKeys>

export interface Region {
  id: string
  iso_code: string
  name: string
}

export interface State {
  code: string
  name: string
}

export type PatientId = string

export interface PatientIdProps {
  patientId: PatientId
}

export type OnActionClick = () => void

export type SetSnackbarMessage = (
  message: string,
  icon?: ReactNode,
  intent?: SnackbarIntent
) => void

export type ConsentDecision = {
  decisionId: string
  date: string
  accepted: boolean
  expired: boolean
}
