import React from 'react'

import { Checkbox, FormikCheckboxGroup } from '@babylon/core-ui'

import { DurationOptions } from '../../components'

interface Props {
  fieldName: string
  onChange: (string) => void
}

const DefaultDurationsField = ({ fieldName, onChange = () => {} }: Props) => (
  <FormikCheckboxGroup
    id={fieldName}
    label="Duration options"
    name={fieldName}
    onChange={onChange}
  >
    <DurationOptions>
      {(value) => <Checkbox value={`${value}`}>{`${value} minutes`}</Checkbox>}
    </DurationOptions>
  </FormikCheckboxGroup>
)

export default DefaultDurationsField
