import { gql } from '@apollo/client'
import ConsultationTypes from '../../types/ConsultationTypes'

export default gql`
  mutation SaveServiceType($input: SaveServiceTypeInput!) {
    saveServiceType(input: $input) {
      uuid
      name
      member_facing_name
      member_description
      default_duration_minutes
      us_ehr_appointment_type
      member_instructions
      preferred_medium
      allowed_mediums
      common
    }
  }
`

export interface SaveServiceTypeInput {
  default_duration_minutes?: number
  member_instructions?: string
  name?: string
  uuid: string
  common?: boolean
  allowed_mediums?: ConsultationTypes[]
  preferred_medium?: ConsultationTypes
  member_facing_name?: string
  member_description?: string
  us_ehr_appointment_type?: string
}
