import React, { FormEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Card, Button, Grid, Cell } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'

import FormField from './FormField'
import messages from './Form.messages'
import styles from './styles.module.scss'
import { SearchableField } from '../../reducer/utils'

export interface FieldProps extends SearchableField {
  loading: boolean
  value: any
  onChange(name: string, value: any): void
}

export interface SearchFormProps extends RouteComponentProps {
  fields: SearchableField[]
  loading: boolean
  params: {
    [key: string]: any
  }
  onClear(): void
  onParamChange(name: string, value: any): void
  onSubmit(params: object): void
  goToCoreRuby: VoidFunction
}

const ClearButton = ({ onClear }: Pick<SearchFormProps, 'onClear'>) => (
  <button
    data-testid="member-search-clear"
    type="button"
    onClick={() => {
      onClear()
    }}
    className={styles.ClearButton}
  >
    <FormattedMessage {...messages.clear} />
  </button>
)

const SearchButton = ({ loading }: Pick<SearchFormProps, 'loading'>) => (
  <Button
    testId="member-search-submit"
    type="submit"
    disabled={loading}
    className={styles.SubmitButton}
  >
    <FormattedMessage {...messages.search} />
  </Button>
)

const Field = (props: FieldProps) => {
  if (!props.type) {
    return <Cell width={props.cellWidth} />
  }

  return (
    <FormField
      {...props}
      onChange={(event) => props.onChange(props.paramName, event.target.value)}
    />
  )
}

const SearchForm = ({
  fields,
  loading,
  params,
  onClear,
  onParamChange,
  onSubmit,
  goToCoreRuby,
}: SearchFormProps) => {
  const { getProp } = useProductConfig()
  const legacySearchToggleEnabled = getProp(
    'memberOperations',
    'legacySearchToggleEnabled'
  )
  const submitHandler = (event: FormEvent) => {
    event.preventDefault()

    Object.keys(params).forEach((fieldName) => {
      if (typeof params[fieldName] === 'string') {
        params[fieldName] = params[fieldName].trim()
      }

      if (fieldName === 'id' && params[fieldName]) {
        params[fieldName] = params[fieldName].replace(/\D+/g, '')
      }
    })
    onSubmit(params)
  }

  return (
    <Card className={styles.Form}>
      {legacySearchToggleEnabled && (
        <button
          type="button"
          className={styles.switchButton}
          data-testid="use-old-portal-button"
          onClick={() => goToCoreRuby()}
        >
          <FormattedMessage {...messages.use_old_portal} />
        </button>
      )}
      <form autoComplete="off" onSubmit={submitHandler}>
        <Grid gap={20} columns={12}>
          <Cell width={10}>
            <Grid flow="row" columns={12} rows={2} gap={20}>
              {fields.map((field) => (
                <Field
                  key={field.paramName}
                  {...field}
                  loading={loading}
                  value={params[field.paramName]}
                  onChange={onParamChange}
                />
              ))}
            </Grid>
          </Cell>
          <Cell width={2} className={styles.ActionsCell}>
            <ClearButton onClear={onClear} />
            <SearchButton loading={loading} />
          </Cell>
        </Grid>
      </form>
    </Card>
  )
}

export default withRouter(SearchForm)
