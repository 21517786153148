import { useQuery } from '@apollo/client'

import getConsultantTypesForMemberQuery from './getConsultantTypesForMemberQuery'

import { useConsultantType } from '..'

interface useConsultantOptionsReturnProps {
  consultantOptions: {
    value: string
    label: string
  }[]
  loading: boolean
}

export default function useConsultantTypeOptionsForMember(
  memberId: string,
  options?: { skip: boolean | undefined }
): useConsultantOptionsReturnProps {
  const { data, loading } = useQuery(getConsultantTypesForMemberQuery, {
    variables: {
      memberId,
    },
    skip: options?.skip,
  })

  const { consultantTypeDict, loading: loadingDict } = useConsultantType({
    skip: options?.skip,
  })

  const consultantOptions =
    data?.consultantTypes.reduce((acc, professionKey) => {
      acc.push({
        value: consultantTypeDict[professionKey]?.id,
        label: consultantTypeDict[professionKey]?.name,
        key: professionKey,
      })

      return acc
    }, []) ?? []

  return {
    consultantOptions,
    loading: loading || loadingDict,
  }
}
