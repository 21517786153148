import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { Page } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { Spinner } from '@babylon/medkit'

import { logException } from '../../../..'
import ErrorAssessmentStage from '../../components/ErrorAssessmentStage'
import withSnackBar from '../../components/withSnackBar'
import useAssessmentStage from '../../utils/useAssessmentStage'
import View from '../View'

import messages from './messages'

const PageWrapper = () => {
  const fm = useFormatMessage()
  const { id: memberId } = useParams<{ id: string }>()

  const { data, error, loading, refetch } = useAssessmentStage(memberId)

  if (error) {
    logException(error)
  }

  const { assessmentStage, member } = data

  return (
    <Page
      breadcrumbs={[
        <Link key="1" to="/admin/patients/search">
          {fm(messages.memberDetails)}
        </Link>,
        <Link key="2" to={`/admin/patients/${memberId}/memberships`}>
          {fm(messages.patientSearch)}
        </Link>,
        fm(messages.title),
      ]}
      title={assessmentStage?.content.header}
    >
      {!error && assessmentStage && (
        <View
          assessmentStage={assessmentStage}
          isLoading={loading}
          member={member}
          refetchFlows={() => refetch()}
        />
      )}
      {loading && <Spinner center variant="primary" />}
      {error && <ErrorAssessmentStage loading={loading} refetch={refetch} />}
    </Page>
  )
}

export default withSnackBar(PageWrapper)
