import React from 'react'
import { SvgProps } from '../../app'

export default function InviteIcon({
  height = 23,
  width = 24,
  className,
}: SvgProps) {
  return (
    <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
      <path
        d="M14 10.5a4.75 4.75 0 004.75-4.75v-1a4.75 4.75 0 00-9.5 0v1A4.75 4.75 0 0014 10.5zm0 1.4c-1.59.02-3.03.24-4.29.66A6.48 6.48 0 0110.97 21H24c0-5.96-3.89-9.05-10-9.1zm-8.5.1a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm.35 8.35l-.7-.7L6.79 18H3v-1h3.8l-1.65-1.65.7-.7 2.86 2.85-2.86 2.85z"
        fill="#400099"
        className={className}
      />
    </svg>
  )
}
