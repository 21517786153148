import React, { FC, ReactNode } from 'react'
import { Heading, Tooltip } from '@babylon/core-ui'
import classNames from 'classnames'

import styles from './Header.module.scss'

export type Level = 'h1' | 'h2'

export interface HeaderProps {
  title: string
  info?: string
  level?: Level
  actions?: ReactNode
  className?: string
}

const getWidthAndHeight = (level: Level) => ({
  height: level === 'h1' ? 20 : 16,
  width: level === 'h1' ? 20 : 16,
})

const Header: FC<HeaderProps> = ({
  level = 'h1',
  title,
  info,
  actions,
  className,
}) => (
  <header className={classNames(styles.Header__header, className)}>
    <div className={styles.Header__wrapper}>
      <Heading className={styles.Header__heading} level={level}>
        {title}
      </Heading>
      {info && (
        <div className={styles.Header__tooltip}>
          <Tooltip info={info} {...getWidthAndHeight(level)} />
        </div>
      )}
    </div>
    <div className={styles.Header__actions}>{actions}</div>
  </header>
)

export default Header
