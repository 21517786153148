import { defineMessages } from 'react-intl'

const baseId = 'profile_header.delete_patient.modal_two'

export default defineMessages({
  header: {
    id: `${baseId}.header`,
    defaultMessage: 'Deleting patient data',
  },
  content: {
    id: `${baseId}.content`,
    defaultMessage:
      'Confirming this action will permanently delete all patient data. Are you sure you want to continue?',
  },
  confirm: {
    id: `${baseId}.confirm`,
    defaultMessage: 'Delete patient data',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  success: {
    id: `${baseId}.success`,
    defaultMessage: 'Successfully deleted patient',
  },
  failed: {
    id: `${baseId}.failed`,
    defaultMessage: 'Failure deleting patient, error: {message}',
  },
})
