import React from 'react'
import cx from 'classnames'

const PaginationSize = ({
  totalItems,
  spinner,
  value,
  className,
  onChange,
}) => {
  const handleChange = (event) =>
    onChange && onChange(parseInt(event.target.value))
  return (
    <span className={cx('pagination-size', className)}>
      <label htmlFor="pageSize">Display</label>
      <select
        id="pageSize"
        onBlur={handleChange}
        onChange={handleChange}
        value={value}
      >
        <option value="10"> 10</option>
        <option value="20"> 20</option>
        <option value="50"> 50</option>
        <option value="100">100</option>
      </select>
      <span>
        of <strong>{totalItems}</strong>
      </span>
      {spinner}
    </span>
  )
}

export default PaginationSize
