import React, { FC, useState, useEffect } from 'react'
import { Heading, Snackbar } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'

import { SearchResults, SearchForm } from './components'
import messages from './MembersSearchView.messages'
import styles from './styles.module.scss'
import { SearchableField } from './reducer/utils'
import SwitchSearchBanner from '../../components/SwitchSearchBanner'

export interface MembersSearchViewProps {
  data: {
    searchPatients?: any
  }
  loading: boolean
  params: any
  searchableFields: SearchableField[]
  onClear: () => void
  onParamChange: (paramName: string, value: any) => void
  onSearch: (params: any) => void
  errors?: string[]
  onPageChange?: (pageNumber: number) => void
  onRowsPerPageChange?: (rows: number) => void
  goToPlatformGateway: VoidFunction
  goToCoreRuby: VoidFunction
}

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <path
        id="a"
        d="M3 20.25a.75.75 0 0 1-.643-1.136l9-15a.75.75 0 0 1 1.286 0l9 15A.75.75 0 0 1 21 20.25H3zm8.947-2.85c.259 0 .494-.095.676-.277a.938.938 0 0 0 .277-.676.93.93 0 0 0-.277-.67.938.938 0 0 0-.676-.277.93.93 0 0 0-.67.277.93.93 0 0 0-.277.67c0 .259.095.494.277.676a.93.93 0 0 0 .67.277zm.803-3.355v-3.892a.75.75 0 1 0-1.5 0v3.892a.75.75 0 1 0 1.5 0z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#ffffff" fillRule="nonzero" xlinkHref="#a" />
      <g fill="#ffffff" mask="url(#b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
)

const MembersSearchView: FC<MembersSearchViewProps> = ({
  data = {},
  loading,
  params,
  searchableFields,
  errors,
  onSearch,
  onClear,
  onPageChange,
  onRowsPerPageChange,
  onParamChange,
  goToPlatformGateway,
  goToCoreRuby,
}) => {
  const fm = useFormatMessage()
  const { searchPatients = undefined } = data

  let patients = null
  let pagination = {
    page: 1,
    perPage: 10,
    total: 0,
  }

  if (searchPatients) {
    pagination = searchPatients.pagination
    patients = searchPatients.patients
  }

  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    setErrorSnackbarMessage(
      errors &&
        (errors.length > 1 ? `${errors.length} errors found` : errors[0])
    )
  }, [errors])

  return (
    <div data-testid="members-search-view" className={styles.membersSearchView}>
      <Snackbar
        icon={<Icon />}
        open={!!errorSnackbarMessage}
        className={styles.Snackbar}
        onClose={() => setErrorSnackbarMessage(undefined)}
        message={errorSnackbarMessage}
        intent="error"
      />
      <div>
        <SwitchSearchBanner onClick={goToPlatformGateway} />
        <header className={styles.membersSearchView__header}>
          <Heading level="h1">{fm(messages.title)}</Heading>
        </header>
        <SearchForm
          onSubmit={onSearch}
          onClear={onClear}
          fields={searchableFields}
          onParamChange={onParamChange}
          params={params}
          loading={loading}
          goToCoreRuby={goToCoreRuby}
        />
      </div>
      <SearchResults
        pristine={!searchPatients}
        loading={loading}
        errors={errors}
        list={patients || []}
        currentPage={pagination.page}
        total={pagination.total}
        totalPages={Math.ceil(pagination.total / pagination.perPage)}
        rowsPerPage={pagination.perPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </div>
  )
}

export default MembersSearchView
