import messages from './AppointmentWaitTimeConfigs.messages'

export const getAlertMessageForInsert = (status: string) => {
  switch (status) {
    case '409':
      return messages.createWaitTimeConfigsConflictErrorMessage.defaultMessage
    case '400':
      return messages.createWaitTimeConfigsBadRequestErrorMessage.defaultMessage
    default:
      return messages.createWaitTimeConfigsInternalServertErrorMessage
        .defaultMessage
  }
}

export const getServerity = (status: string) =>
  status === '201' || status === '204' ? 'success' : 'error'
