import { useQuery, QueryHookOptions } from '@apollo/client'
import { useMemo } from 'react'

import formatCancellationReasons from './formatCancellationReasons'

import getCancellationReasonQuery from './getCancellationReasonQuery'

export default function useCancellationReason(queryOptions: QueryHookOptions) {
  const { data, error, loading } = useQuery(
    getCancellationReasonQuery,
    queryOptions
  )

  const cancellationReasonsList = useMemo(
    () => formatCancellationReasons(data?.adminCancelReasons ?? []),
    [data]
  )

  return {
    data: cancellationReasonsList,
    error,
    loading,
  }
}
