import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { preventEvent, result, withFormContext, formContext } from './util'

/**
 * The Form component.
 */
class Form extends React.Component {
  static propTypes = {
    /** The form store */
    store: PropTypes.shape({
      state: PropTypes.object.isRequired,
      setState: PropTypes.func.isRequired,
    }),
    /** The initial value of the internal store */
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    /** Submit event handler method
     * @param {Object} state The form state
     */
    onSubmit: PropTypes.func,
    /** @ignore */
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    /** The css class name of the component */
    className: PropTypes.string,
    /** The css style of the component */
    style: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    /** show the validation error messages */
    showValidationErrors: PropTypes.oneOf(['show', 'hide', 'auto']),
    /** call the `onSubmit` even when the validations fails */
    alwaysCallOnSubmit: PropTypes.bool,
    /** validation error messages */
    errorMessages: PropTypes.objectOf(PropTypes.string),
    /** @ignore */
    'data-context': PropTypes.shape({
      submit: PropTypes.func,
      reset: PropTypes.func,
      data: PropTypes.object,
    }),
  }

  static defaultProps = {
    showValidationErrors: 'auto',
  }

  constructor(props) {
    super(props)
    const { showValidationErrors, errorMessages, store = this } = props
    if (props.value) {
      this.state = result(props.value, this)
    }
    this.defaultState = store.state
    this.showValidationErrors = showValidationErrors === 'show'
    this.errorMessages = errorMessages
  }

  updateErrorMessages = (errorMessages) => {
    this.errorMessages =
      errorMessages && Object.keys(errorMessages).length ? errorMessages : null
    this.forceUpdate()
  }

  handleSubmit = () => {
    const {
      onSubmit,
      showValidationErrors,
      alwaysCallOnSubmit,
      store = this,
    } = this.props
    if (this.ctx.isValid || alwaysCallOnSubmit) {
      this.errorMessages = null
      onSubmit && onSubmit(store.state, this.updateErrorMessages)
      this.showValidationErrors = this.errorMessages
        ? showValidationErrors === 'show'
        : showValidationErrors !== 'hide'
    } else {
      this.showValidationErrors = showValidationErrors !== 'hide'
      this.forceUpdate()
    }
  }

  handleReset = () => {
    const { store = this } = this.props
    this.showValidationErrors = false
    this.errorMessages = null
    store.setState(this.defaultState)
  }

  render() {
    const {
      children,
      'data-context': ctx,
      store = this,
      className,
      showValidationErrors,
      ...props
    } = this.props
    /* when we use a form inside an other form it should be rendered as
    a div tag as html doesn't allow form tags inside other form tag */
    const FormTag = ctx ? 'div' : 'form'
    this.ctx = {
      store,
      submit: this.handleSubmit,
      reset: this.handleReset,
      isValid: true,
      showValidationErrors: this.showValidationErrors,
      errorMessages: this.errorMessages,
    }
    return (
      <FormTag
        role="form"
        {...props}
        className={cx('form', className)}
        onSubmit={preventEvent}
      >
        <formContext.Provider value={this.ctx}>{children}</formContext.Provider>
      </FormTag>
    )
  }
}

export default withFormContext(Form)
