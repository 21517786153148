import { graphql } from '@apollo/client/react/hoc'
import { compose, withProps } from 'recompose'

import { withSpinner } from '@/util'
import withErrorMessage, { dialogErrorHandler } from '@/util/withErrorMessage'

import { AdminDrugAudits } from './queries'
import DrugAlertAuditsView from './DrugAlertAuditsView'

// DATA

const defaultData = ({
  match: { params: { id } } = { params: { id: null } },
}) => ({
  paramId: id,
})

export const auditsQueryOptions = {
  options: ({ paramId }) => ({ variables: { auditableId: paramId } }),
  skip: ({ paramId }) => !paramId,
}

const withData = compose(
  withProps(defaultData),
  graphql(AdminDrugAudits, auditsQueryOptions)
)

// LOADER

export const isLoading = (props) => !props.data || !props.data.adminDrugAudits

const withLoader = withSpinner(isLoading)

const Container = compose(
  withData,
  withLoader,
  withErrorMessage(dialogErrorHandler)
)(DrugAlertAuditsView)

Container.width = '90%'

export default Container
