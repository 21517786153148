import { gql } from '@apollo/client'

export default gql`
  mutation CreateAppointmentInvite($input: AppointmentInviteInput!) {
    createAppointmentInvite(input: $input) {
      id
      duration_minutes
      intimate
      notes_for_member
      service_type {
        id
        name
        member_instructions
      }
    }
  }
`
