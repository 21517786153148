import { gql } from '@apollo/client'

export const TRANSACTIONS_QUERY = gql`
  query AP_Transactions($patientId: ID!, $page: Int) {
    getTransactions(patientId: $patientId, page: $page) {
      id
      purchaseType
      price
      currencySymbol
      createdAt
      braintreeToken
      braintreeStatus
      refundable
      refundedAt
      showReason
      creditCard {
        id
        last4
        cardType
      }
    }
  }
`
export const GET_TRANSACTION_REASONS = gql`
  query AP_GetTransactionReasons {
    getTransactionReasons {
      id
      reason
    }
  }
`

export const APPLY_REFUND = gql`
  mutation AP_ApplyRefund($input: applyRefundInput!) {
    applyRefund(input: $input) {
      id
      purchaseType
      price
      currencySymbol
      createdAt
      braintreeToken
      braintreeStatus
      refundable
      refundedAt
      showReason
      creditCard {
        id
        last4
        cardType
      }
    }
  }
`
