import React from 'react'
import { Button } from '@babylon/babylon-forms/forms'

import styles from './styles.module.scss'

class FileUpload extends React.Component {
  state = {
    showProgress: false,
    progress: 0,
  }

  saveRef = (ref) => (this.fileUpload = ref)

  handleChange = (event) => {
    const { onUpload } = this.props
    onUpload &&
      onUpload(event.target.files[0], {
        progress: this.handleProgressChange,
        done: this.handleDone,
      })
    this.setState({ showProgress: true, progress: 0 })
  }

  handleProgressChange = (progress) => {
    this.setState({ progress })
  }

  handleDone = () => {
    this.setState({ showProgress: false })
  }

  handleClick = (event) => {
    event.preventDefault()
    this.fileUpload.click()
  }

  render() {
    const { disabled, label, children } = this.props

    return (
      <>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={this.saveRef}
          onChange={this.handleChange}
        />
        <Button
          type="button"
          small
          onClick={this.handleClick}
          disabled={disabled}
        >
          {label || children}
        </Button>
        {this.state.showProgress ? (
          <div className={styles.progressBar}>
            <div style={{ width: `${this.state.progress}%` }} />
          </div>
        ) : null}
      </>
    )
  }
}

export default FileUpload
