import React from 'react'
import cx from 'classnames'
import { useSnackBar } from '../..'
import styles from './styles.module.scss'
import CopyIcon from './CopyIcon'
import { copyToClipboard } from './utils'

interface Props {
  label?: string
  successMessage: string
  copiableContent: string
  className?: string
  onClick?: () => void
}

const CopyButton = ({
  label,
  successMessage,
  copiableContent,
  className,
  onClick,
}: Props) => {
  const { setSnackbarMessage } = useSnackBar()

  return (
    <div
      role="button"
      tabIndex={0}
      className={cx(styles.CopyButton__button, className)}
      onClick={() => {
        copyToClipboard(copiableContent, () => {
          setSnackbarMessage(successMessage, null, 'success')
          if (onClick) {
            onClick()
          }
        })
      }}
    >
      <CopyIcon />
      {label ? <button>{label}</button> : null}
    </div>
  )
}

export default CopyButton
