import { defineMessages } from 'react-intl'

const baseId = 'member_edit_personal_details'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Personal details',
  },
  first_name: {
    id: `${baseId}.first_name`,
    defaultMessage: 'First name',
  },
  last_name: {
    id: `${baseId}.last_name`,
    defaultMessage: 'Last name',
  },
  previous_names: {
    id: `${baseId}.previous_names`,
    defaultMessage: 'Previous names',
  },
  date_of_birth: {
    id: `${baseId}.date_of_birth`,
    defaultMessage: 'Date of birth',
  },
  gender: {
    id: `${baseId}.gender`,
    defaultMessage: 'Gender',
  },
  gender_male: {
    id: `${baseId}.gender_male`,
    defaultMessage: 'Male',
  },
  gender_female: {
    id: `${baseId}.gender_female`,
    defaultMessage: 'Female',
  },
  town_of_birth: {
    id: `${baseId}.town_of_birth`,
    defaultMessage: 'Town of birth',
  },
  country_of_birth: {
    id: `${baseId}.country_of_birth`,
    defaultMessage: 'Country of birth',
  },
  arrival_date: {
    id: `${baseId}.arrival_date`,
    defaultMessage: 'Arrival date',
  },
  disability: {
    id: `${baseId}.disability`,
    defaultMessage: 'Disability',
  },
  armed_forces_enlisting_date: {
    id: `${baseId}.armed_forces_enlisting_date`,
    defaultMessage: 'Armed forces enlisting date',
  },
  cant_be_blank: {
    id: `${baseId}.cant_be_blank`,
    defaultMessage: "Can't be blank",
  },
})
