import { gql } from '@apollo/client'

export default gql`
  query FindConsumerNetworks($name: String!) {
    findConsumerNetworks(name: $name) {
      id
      name
    }
  }
`
