import React from 'react'
import cx from 'classnames'

import { nextIndex } from '@babylon/babylon-forms/forms'

import Icon from '@/components/Icon'

import styles from './styles.module.scss'

export const formatDrug = ({
  quantity,
  drugName,
  directions,
  packagingSize,
}) => (
  <div key={nextIndex()} className={styles.noWrap}>
    <hr />
    <div className={styles.wrap}>
      {drugName}
      {packagingSize ? ` ${packagingSize}` : ''}
    </div>
    <div className={styles.wrapSmall}>{directions}</div>
    <div>Quantity: {quantity}</div>
  </div>
)

export const formatBadge = (iconName) => (value) => (
  <span className={cx(styles.badge, styles[value])}>
    <Icon name={iconName} /> <span>{value.replace(/_/g, ' ')}</span>
  </span>
)

export const formatBadgeStatus = formatBadge('status')

const formatBadgeHomeDelivery = formatBadge('delivery')

const formatBadgePharmacy = formatBadge('plus')

export const formatBadgeSignatureState = formatBadge('edit')

export const formatBadgeDelivery = (value) =>
  value === 'HOME_DELIVERY'
    ? formatBadgeHomeDelivery(value)
    : formatBadgePharmacy(value)

export const formatAddress = (address) =>
  // wrap after ',' or before '(' characters only
  address &&
  address
    .replace(/[ \t]/g, '\u00a0')
    .replace(/,\u00a0/g, ', ')
    .replace(/\u00a0\(/g, ' (')
