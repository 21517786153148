import { defineMessages } from 'react-intl'

const baseId = 'datagrid'

export const messages = defineMessages({
  edit: {
    id: `${baseId}.edit`,
    defaultMessage: 'Edit',
  },
  delete: {
    id: `${baseId}.delete`,
    defaultMessage: 'Delete',
  },
  view: {
    id: `${baseId}.view`,
    defaultMessage: 'View',
  },
  deleteError: {
    id: `${baseId}.delete_error`,
    defaultMessage: 'Error deleting data',
  },
  loading: {
    id: `${baseId}.loading`,
    defaultMessage: 'Loading Pharmacy Supplier Data',
  },
  error: {
    id: `${baseId}.error`,
    defaultMessage: 'Error loading Pharmacy Supplier Data:',
  },
  blocked: {
    id: `${baseId}.blocked`,
    defaultMessage: 'Blocked',
  },
  blockedReason: {
    id: `${baseId}.blocked_reason`,
    defaultMessage: 'Blocked Reason',
  },
  deliveryType: {
    id: `${baseId}.delivery_type`,
    defaultMessage: 'Delivery Type',
  },
  faxNumber: {
    id: `${baseId}.fax_number`,
    defaultMessage: 'Fax Number',
  },
  fax: {
    id: `${baseId}.fax`,
    defaultMessage: 'Fax',
  },
  online: {
    id: `${baseId}.online`,
    defaultMessage: 'Online',
  },
  region: {
    id: `${baseId}.region`,
    defaultMessage: 'Region',
  },
  name: {
    id: `${baseId}.name`,
    defaultMessage: 'Name',
  },
  add: {
    id: `${baseId}.add`,
    defaultMessage: 'Add',
  },
  email: {
    id: `${baseId}.email`,
    defaultMessage: 'Email',
  },
  active: {
    id: `${baseId}.active`,
    defaultMessage: 'Active',
  },
  roles: {
    id: `${baseId}.roles`,
    defaultMessage: 'Roles',
  },
  pharmacist: {
    id: `${baseId}.pharmacist`,
    defaultMessage: 'Pharmacist',
  },
  pharmacySupplier: {
    id: `${baseId}.pharmacy_supplier`,
    defaultMessage: 'Pharmacy Supplier',
  },
  save: {
    id: `${baseId}.save`,
    defaultMessage: 'Save',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  invalidForm: {
    id: `${baseId}.invalidForm`,
    defaultMessage: '*Please fill in all required fields',
  },
  successfulEdit: {
    id: `${baseId}.successful_edit`,
    defaultMessage: 'Saved!',
  },
  errorEdit: {
    id: `${baseId}.error_edit`,
    defaultMessage: 'Something went wrong. Please try again.',
  },
  filter: {
    id: `${baseId}.filter`,
    defaultMessage: 'Filter',
  },
  reset: {
    id: `${baseId}.reset`,
    defaultMessage: 'Reset',
  },
  search: {
    id: `${baseId}.search`,
    defaultMessage: 'Search',
  },
  all: {
    id: `${baseId}.all`,
    defaultMessage: 'ALL',
  },
  true: {
    id: `${baseId}.true`,
    defaultMessage: 'TRUE',
  },
  false: {
    id: `${baseId}.false`,
    defaultMessage: 'FALSE',
  },
  exists: {
    id: `${baseId}.exists`,
    defaultMessage: 'EXISTS',
  },
  doesNotExist: {
    id: `${baseId}.doesNotExist`,
    defaultMessage: 'DOES NOT EXIST',
  },
  postCode: {
    id: `${baseId}.postCode`,
    defaultMessage: 'Post Code',
  },
  faxNumberTooltip: {
    id: `${baseId}.fax_number_tooltip`,
    defaultMessage:
      'Pharmacies with and without fax numbers are shown in this table. Change this to filter based on fax numbers.',
  },
  confirmDeleteButton: {
    id: `${baseId}.confirm_delete_button`,
    defaultMessage: 'Delete',
  },
  cancelButton: {
    id: `${baseId}.cancel_button`,
    defaultMessage: 'Cancel',
  },
  deleteConfirmationHeader: {
    id: `${baseId}.delete_confirmation_header`,
    defaultMessage: 'Delete',
  },
  deleteConfirmationContent: {
    id: `${baseId}.delete_confirmation_content`,
    defaultMessage: 'Are you sure you want to delete?',
  },
  notFound: {
    id: `${baseId}.not_found`,
    defaultMessage: 'Not found',
  },
  notProvided: {
    id: `${baseId}.not_provided`,
    defaultMessage: 'Not Set',
  },
})
