/* eslint-disable curly */
/* eslint-disable padding-line-between-statements */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Prompt } from 'react-router-dom'

import {
  Queue,
  useAgentLiveConversation,
  useConversation,
  RequestStatus,
  useAtlasEvent,
  useAtlasStore,
} from '@babylon/atlas.js'

import toast from 'react-hot-toast'
import styles from '../../styles.module.css'
import { ChatFooter, PatientHeader, ConfirmAssignment, TransferModal } from '..'

import usePatientProfile, { PatientProfile } from '../../usePatientProfile'
import { AttachmentMessage } from '../Attachment/AttachmentUploadingMessage'
import EndConversationConfirmationModal from '../EndConversationModal/EndConversationModal'
import PatientActiveConversation from './PatientActiveConversation'
import PatientPanel from './PatientPanel'

interface PatientConversationProps {
  assignStatus: 'IDLE' | 'ASSIGNING' | 'ASSIGNED' | 'CLOSED'
  conversationId: string
  playNotificationSound: () => void
  agentId: string
  memberId: string
  requestedAt: Date
  sendMessageNotification: (patientProfile: PatientProfile | undefined) => void
  windowFocused: boolean
  fetchQueues: () => void
  queues: Queue[]
  queuesStatus: RequestStatus
  onNewConversationRequest: (conversationId: string) => void
  updateMemberNameFromProfile: (memberId: string, name: string) => void
  removeConversationTab: (memberId: string) => void
}

const PatientConversation = ({
  assignStatus,
  conversationId,
  playNotificationSound,
  windowFocused,
  requestedAt,
  agentId,
  memberId,
  sendMessageNotification,
  fetchQueues,
  queues,
  queuesStatus,
  onNewConversationRequest,
  updateMemberNameFromProfile,
  removeConversationTab,
}: PatientConversationProps) => {
  useEffect(() => {
    if (assignStatus === 'IDLE') {
      onNewConversationRequest(conversationId)
    }
  }, [assignStatus, onNewConversationRequest, conversationId])

  const patientProfileRef = useRef<PatientProfile>()

  const {
    rosterNewConversation: addConversationToRoster,
    rosterRemoveConversation: removeConversationFromRoster,
  } = useAtlasStore((state) => state)

  useEffect(() => {
    addConversationToRoster(conversationId)
  }, [conversationId, addConversationToRoster])

  const {
    closeActiveConversation,
    conversationDetails,
    confirmConversation,
    transfer,
  } = useAgentLiveConversation(conversationId)

  useAtlasEvent('AgentConfirmConversation', conversationId, (event) => {
    if (event.state === 'CLOSED_BY_MEMBER') {
      toast(`${patientProfileRef.current?.summarizedName} left the queue`)
    }
  })

  const [showTransferModal, setShowTransferModal] = useState<boolean>(false)
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState<boolean>(
    false
  )
  const [attachmentMessage, setAttachmentMessage] = useState<AttachmentMessage>(
    {
      uploading: false,
    }
  )
  const waitTime = useMemo(() => {
    const activeConversationStartTimestamp = requestedAt!
    const timeDifference =
      new Date().getTime() - activeConversationStartTimestamp.getTime()
    const timeDifferenceMin = Math.ceil(timeDifference / (1000 * 60))
    return (
      <div className={styles.memberWaitTimeContainer}>
        <hr className={styles.separator} style={{ flexGrow: 1 }} />
        <div className={styles.memberWaitTime} style={{ flexGrow: 1 }}>
          <div
            className={styles.memberWaitTimeBanner}
            style={{ margin: 'auto' }}
          >
            Member wait time
          </div>
          <div style={{ margin: 'auto' }}>{`${timeDifferenceMin} min`}</div>
        </div>
        <hr className={styles.separator} style={{ flexGrow: 1 }} />
      </div>
    )
  }, [requestedAt])

  const {
    messages,
    messageIds,
    sendMessage,
    userIdsTyping,
    startTyping,
    endTyping,
    status,
    attachments,
  } = useConversation(conversationId, agentId)

  useAtlasEvent('InboundMessage', conversationId, (event) => {
    if (
      event.message.senderId === 'ADMIN' ||
      event.message.senderId === agentId
    ) {
      return
    }
    playNotificationSound()
    if (windowFocused) {
      return
    }
    sendMessageNotification(patientProfileRef.current)
  })

  const showTypingIndicator = userIdsTyping.length > 0

  const {
    loading: profileLoading,
    patientProfile,
    error: profileError,
  } = usePatientProfile(memberId)
  patientProfileRef.current = patientProfile || {
    id: memberId || 'Member ID',
    summarizedName: 'Member',
    givenNames: [],
    familyName: 'Member',
    hasFamilyAccounts: false,
    hasFamilyAccountsOwner: false,
    isMinor: false,
    identifiers: {},
  }

  useEffect(() => {
    if (patientProfile) {
      updateMemberNameFromProfile(
        memberId,
        patientProfile?.summarizedName || memberId
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileLoading])

  // messages are shown with inverted flex direction (bottom up),
  // so to render them in the right order, the message list itself
  // must be inverted.
  const reversedMessageIds = useMemo(() => [...messageIds].reverse(), [
    messageIds,
  ])

  const onShowConfirmClose = () => setShowConfirmCloseModal(true)
  const onConfirmClose = async () => {
    await closeActiveConversation()
    removeConversationFromRoster(conversationId)
    removeConversationTab(memberId)
  }

  const patientConversationTabRef = useRef<HTMLDivElement>(null)
  const resetMessagesCount = useAtlasStore(
    (state) => state.rosterUpdateConversation
  )

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            resetMessagesCount(conversationId, (stat) => ({
              ...stat,
              notSeenMessageCount: 0,
              lastSeenMessageId: reversedMessageIds[0],
            }))
          }
        })
      },
      { threshold: 0.5 }
    )

    if (patientConversationTabRef.current) {
      observer.observe(patientConversationTabRef.current)
    }

    return () => {
      if (patientConversationTabRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(patientConversationTabRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetMessagesCount])

  return (
    <>
      <div className={styles.page} ref={patientConversationTabRef}>
        <div className={`${styles.messagesBody}`}>
          {(assignStatus === 'IDLE' || assignStatus === 'ASSIGNING') && (
            <ConfirmAssignment
              patientProfile={patientProfile}
              loading={profileLoading}
              error={profileError !== undefined}
              tags={conversationDetails?.tags || []}
              assignmentConfirming={assignStatus === 'ASSIGNING'}
              onConfirmAssignment={confirmConversation}
              patientId={memberId}
            />
          )}
          {(assignStatus === 'ASSIGNED' || assignStatus === 'CLOSED') && (
            <div className={styles.activeConversationContainer}>
              <div>
                <PatientHeader
                  conversationId={conversationId}
                  patient={patientProfile}
                  conversationEnded={status === 'closed'}
                  onCloseConversation={onShowConfirmClose}
                  onExitConversation={onConfirmClose}
                  patientId={memberId}
                  onRequestTransfer={() => {
                    fetchQueues()
                    setShowTransferModal(true)
                  }}
                  memberAppVersion={conversationDetails?.member_app_version}
                />
                <PatientActiveConversation
                  conversationId={conversationId}
                  status={status}
                  attachmentMessage={attachmentMessage}
                  messageIds={reversedMessageIds}
                  messages={messages}
                  userId={agentId}
                  patientId={memberId}
                  attachments={attachments}
                  waitTime={waitTime}
                  showTypingIndicator={showTypingIndicator}
                />
                {assignStatus !== 'CLOSED' && (
                  <ChatFooter
                    conversationId={conversationId}
                    startTyping={startTyping}
                    endTyping={endTyping}
                    disabled={assignStatus !== 'ASSIGNED'}
                    attachments={attachments}
                    attachmentsOwner={memberId}
                    updateAttachmentMessage={setAttachmentMessage}
                    onSendMessage={(message, hasAttachment) => {
                      sendMessage(message, hasAttachment)
                    }}
                  />
                )}
              </div>
              <PatientPanel patientId={memberId} patient={patientProfile} />
            </div>
          )}
        </div>
      </div>

      <TransferModal
        userId={agentId}
        visible={showTransferModal}
        queues={queues}
        queuesStatus={queuesStatus}
        onRequestClose={() => setShowTransferModal(false)}
        onQueueSelect={(queue) => {
          if (assignStatus === 'ASSIGNED') {
            const promise = transfer(queue.id)
            toast.promise(promise, {
              loading: 'Transferring...',
              success: 'Conversation transferred',
              error: 'Failed to transfer conversation',
            })
            promise.then(() => {
              removeConversationTab(memberId)
            })
            setShowTransferModal(false)
          }
        }}
      />

      <EndConversationConfirmationModal
        showConfirmCloseModal={showConfirmCloseModal}
        setShowConfirmCloseModal={setShowConfirmCloseModal}
        onConfirmClose={onConfirmClose}
      />
      <Prompt message="Are you sure you want to leave? Any active conversations you are engaged in will be transferred to a different agent." />
    </>
  )
}

export default PatientConversation
