import { defineMessages } from 'react-intl'

const baseId = 'checkin_status'

export default defineMessages({
  CANCELLED: {
    id: `${baseId}.cancelled`,
    defaultMessage: 'Cancelled',
  },
  CHECKED_IN: {
    id: `${baseId}.checked_in`,
    defaultMessage: 'Checked in',
  },
  FAILED: {
    id: `${baseId}.failed`,
    defaultMessage: 'Failed',
  },
  MISSED: {
    id: `${baseId}.missed`,
    defaultMessage: 'Missed',
  },
  NOT_OPENED: {
    id: `${baseId}.not_opened`,
    defaultMessage: 'Not opened',
  },
  OPEN: {
    id: `${baseId}.open`,
    defaultMessage: '-- : --',
  },
  default: {
    id: `${baseId}.default`,
    defaultMessage: 'N/A',
  },
})
