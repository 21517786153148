import React, { FC } from 'react'
import { FormattedDate as FD } from 'react-intl'

export interface FormattedDateProps {
  value?: string | number | Date | undefined
}

const FormattedDate: FC<FormattedDateProps> = ({ value }) =>
  value ? <FD value={value} day="2-digit" month="short" year="numeric" /> : null

export default FormattedDate
