import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLongArrowLeft,
  faLongArrowRight,
} from '@fortawesome/pro-light-svg-icons'
import { Button } from '@babylon/core-ui'
import styles from './DurationSelector.module.css'
import { FormattedDayView, FormattedWeekView } from './FormattedDateViews'
import { ConsultationTypeName } from '../../types/ConsultationTypes'
import {
  getClinicById,
  getPreviousDate,
  getNextDate,
  getNextWeekDate,
  checkIfPreviousDateIsDisabled,
} from './DurationSelectorUtils'
import { shouldRenderDayView } from '../../Utils'

export type SelectedWeek = {
  days: string[]
  end: string
  start: string
}

export interface DurationSelectorProps {
  appointmentClinic: string
  appointmentClinicList: Practice[]
  consultationType: ConsultationTypeName
  selectedDate: Date
  selectedDateUpdate: (d: Date) => void
  selectedWeek: SelectedWeek
  selectedWeekUpdate: (d: string) => void
  disabled?: boolean
}

export default function DurationSelector({
  appointmentClinic,
  consultationType,
  selectedDate,
  selectedDateUpdate,
  selectedWeek,
  selectedWeekUpdate,
  appointmentClinicList,
  disabled,
}: DurationSelectorProps): React.ReactElement {
  const dayView = shouldRenderDayView(consultationType, appointmentClinic)
  const startDate = new Date(selectedWeek.start)
  const endDate = new Date(selectedWeek.end)
  const previousDate = getPreviousDate(selectedDate)
  const previousWeekDate = getPreviousDate(startDate)
  const nextDate = getNextDate(selectedDate)
  const nextWeekDate = getNextWeekDate(startDate)
  const previousDateDisabled =
    disabled || checkIfPreviousDateIsDisabled(previousDate)
  const previousWeekDateDisabled =
    disabled || checkIfPreviousDateIsDisabled(previousWeekDate)

  const onPreviousClick = (): void => {
    if (dayView && !previousDateDisabled) {
      selectedDateUpdate(previousDate)
    } else if (!dayView && !previousWeekDateDisabled) {
      selectedWeekUpdate(previousWeekDate.toISOString())
    }
  }

  const onNextClick = (): void =>
    dayView
      ? selectedDateUpdate(nextDate)
      : selectedWeekUpdate(nextWeekDate.toISOString())

  return (
    <div
      className={styles.durationSelector}
      data-testid={dayView ? 'day-selector' : 'week-selector'}
    >
      <div className={styles.buttonContainer}>
        <Button
          inline
          intent="secondary"
          disabled={dayView ? previousDateDisabled : previousWeekDateDisabled}
          onClick={onPreviousClick}
          dataTestId="previous-button"
        >
          <FontAwesomeIcon icon={faLongArrowLeft} size="lg" />
        </Button>
        <Button
          inline
          intent="secondary"
          disabled={disabled}
          onClick={onNextClick}
          dataTestId="next-button"
        >
          <FontAwesomeIcon icon={faLongArrowRight} size="lg" />
        </Button>
      </div>

      {dayView ? (
        <FormattedDayView
          date={new Date(selectedDate)}
          clinic={getClinicById(appointmentClinicList, appointmentClinic)}
        />
      ) : (
        <FormattedWeekView
          startDate={startDate}
          endDate={endDate}
          clinic={getClinicById(appointmentClinicList, appointmentClinic)}
        />
      )}
    </div>
  )
}
