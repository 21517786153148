import React from 'react'
import { LoadingBlock } from '../../../..'
import styles from './ProfileHeader.module.scss'

const LoadingConsumerNetworksCard = () => (
  <div data-testid="loading-profile-header">
    <LoadingBlock className={styles.loadingBlock} />
  </div>
)

export default LoadingConsumerNetworksCard
