import { gql } from '@apollo/client'

export const PATIENT_QUERY = gql`
  query AP_Patient($id: ID!) {
    patient(id: $id) {
      id
      uuid
      first_name
      last_name
    }
  }
`

export const ACTIVITY_LOG_QUERY = gql`
  query AP_ActivityLog($patientId: ID!) {
    activityLog(patientId: $patientId) {
      reportParameters
    }
  }
`
