import React from 'react'
import { useQuery } from '@apollo/client'
import { Grid, Cell } from '@babylon/core-ui'
import cx from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { formatDateTimeWithTimezone } from '@babylon/shared-utils'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { DrawerView, MemberOpsModuleName } from '../../../../../../..'
import styles from './styles.module.scss'
import { QUERY_DEVICES } from './queries'
import messages from './messages'
import Device from './Device'
import { formatToTable } from './utils'
import { CopyButton } from '../../../../../..'

type DevicesProps = {
  patientId: String
  onClose: () => void
}

const Devices = ({ patientId, onClose }: DevicesProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const twelveHourFormat = userTimezone.split('/')[0] === 'America'

  const headers = [
    fm(messages.device),
    fm(messages.version),
    fm(messages.date_last_used),
  ]

  const { loading, data: { getDevices: deviceList = [] } = {} } = useQuery(
    QUERY_DEVICES,
    {
      variables: {
        patientId,
      },
    }
  )

  function formatDateLastUsed(dateLastUsed: string | undefined) {
    if (!dateLastUsed) {
      return fm(messages.unknown)
    }

    return formatDateTimeWithTimezone(dateLastUsed, {
      desiredTimeZone: userTimezone,
      twelveHourFormat,
    })
  }

  return (
    <DrawerView
      visible
      title={fm(messages.devices)}
      toggleVisible={onClose}
      headerItem={
        loading ? undefined : (
          <CopyButton
            label={fm(messages.copy_all)}
            successMessage={fm(messages.copied_devices_to_clipboard)}
            copiableContent={formatToTable(
              deviceList.map(({ platform, appVersion, dateLastUsed }) => ({
                platform,
                appVersion,
                dateLastUsed: formatDateLastUsed(dateLastUsed),
              })),
              headers
            )}
            className={styles.Device__copyAll}
            onClick={() => {
              trackClick({
                elementName: 'copy-device-btn',
                elementType: TrackingElementType.button,
              })
            }}
          />
        )
      }
    >
      <div className={styles.Container}>
        <Grid className={cx(styles.Grid, styles.Grid__title)} columns="7">
          <Cell width={2}>{fm(messages.device)}</Cell>
          <Cell width={2}>{fm(messages.version)}</Cell>
          <Cell width={2}>{fm(messages.date_last_used)}</Cell>
        </Grid>
        {!loading &&
          (deviceList.length >= 1 ? (
            deviceList.map(({ platform, appVersion, dateLastUsed }) => (
              <Device
                platform={platform || fm(messages.unknown)}
                appVersion={appVersion || fm(messages.unknown)}
                dateLastUsed={formatDateLastUsed(dateLastUsed)}
                headers={headers}
                key={dateLastUsed}
              />
            ))
          ) : (
            <Grid columns="7">
              <Cell center width={6}>
                <h1>{fm(messages.no_devices_found)}</h1>
              </Cell>
            </Grid>
          ))}
      </div>
    </DrawerView>
  )
}

export default Devices
