import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import WithQuery from '../Utils/WithQuery'
import AppointmentInvites, { Invite } from './AppointmentInvites'
import AppointmentInvitesQuery from './AppointmentInvitesQuery'
import MemberUuidQuery from './MemberUuidQuery'

interface Props extends RouteComponentProps<{ id: string }> {
  includingDigital?: boolean
}

interface Patient {
  uuid: string
}

interface Result {
  unused?: boolean
}

const parseSearch = (search: string): Result => {
  const variables: Result = {}
  const params = new URLSearchParams(search)
  const state = params.get('state')

  if (state) {
    variables.unused = true
  }

  return variables
}

const AppointmentInvitesPage = ({
  match: {
    params: { id },
  },
  location: { search },
  includingDigital,
}: Props) => (
  <WithQuery query={MemberUuidQuery} variables={{ id }}>
    {(predata: { patient: Patient }) => (
      <WithQuery
        query={AppointmentInvitesQuery}
        variables={{
          member_uuid: predata.patient.uuid,
          including_digital: includingDigital,
          ...parseSearch(search),
        }}
        fetchPolicy="no-cache"
      >
        {(data: { appointmentInvites: Invite[] }) => (
          <AppointmentInvites invites={data.appointmentInvites} memberId={id} />
        )}
      </WithQuery>
    )}
  </WithQuery>
)

export default withRouter(AppointmentInvitesPage)
