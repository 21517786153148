import React, { useState, useEffect } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { InputFilter, Option } from '../../components/InputFilter'
import { useFindConsumerNetworksQuery } from './FindConsumerNetworksQuery.middleware.hooks'
import messages from './ConsumerNetworkSearcher.messages'

type Props = {
  readOnly: boolean
  multiple: boolean
  defaultValue?: any[]
  onSelectedConsumerNetworksChanged: (
    selectedConsumerNetworks: Option[]
  ) => void
}

const ConsumerNetworkSearcher = ({
  readOnly,
  multiple,
  defaultValue,
  onSelectedConsumerNetworksChanged,
}: Props) => {
  const [searchInputValue, setSearchInputValue] = useState('')

  const { data, loading, refetch } = useFindConsumerNetworksQuery({
    variables: {
      name: '',
    },
    fetchPolicy: 'network-only',
  })
  const fm = useFormatMessage()

  useEffect(() => {
    refetch({ name: searchInputValue })
  }, [searchInputValue, refetch])

  const options = (loading ? [] : data?.findConsumerNetworks || []) as Option[]

  return (
    <InputFilter
      label={fm(messages.title)}
      readOnly={readOnly}
      multiple={multiple}
      defaultValue={defaultValue}
      options={options}
      loading={loading}
      onSelectedValuesChange={onSelectedConsumerNetworksChanged}
      onInputSearchChange={(text) => {
        setSearchInputValue(text)
      }}
    />
  )
}

export default ConsumerNetworkSearcher
