import React from 'react'
import { Button, result } from '@babylon/babylon-forms/forms'

import showConfirmDialog from '@/components/ConfirmDialog'
import showAlertDialog from '@/components/AlertDialog'

import styles from './styles.module.scss'

const showFullErrorDialog = showConfirmDialog(
  'Internal server error',
  'Reload',
  'Ignore'
)

const fullErrorAlert = () => (
  showFullErrorDialog().then(
    // Reload
    () => window.location.replace(window.location.href)
  ),
  // Ignore
  () => {}
)

const showFatalErrorDialog = showAlertDialog(
  <span>
    We are sorry, an internal server error has occurred. <br />
    Please reload the page and try again later
  </span>,
  'Reload'
)

const fatalErrorAlert = () => {
  showFatalErrorDialog().then(() =>
    window.location.replace(window.location.href)
  )
}

const showErrorDialog = showAlertDialog(
  <span>
    We are sorry, an internal server error has occurred. <br />
    Please try again later
  </span>,
  'Ok'
)

const showCustomErrorDialog = (message) =>
  showAlertDialog(<span>{message}</span>, 'Ok')

export const errorAlert = () => {
  showErrorDialog().then(() => {})
}

export const customErrorAlert = (message) => {
  showCustomErrorDialog(message)().then(() => {})
}

const DefaultErrorMessage = ({ message, buttonLabel, onButtonClick }) => (
  <div className={styles.errorMessage}>
    <p>
      {message || (
        <span>
          Error loading this page. <br /> Please reload or try another page and
          come back later.
        </span>
      )}
    </p>
    <Button
      small
      onClick={
        onButtonClick ||
        (() => {
          window.location.replace(window.location.href)
        })
      }
      label={buttonLabel || 'Reload'}
    />
  </div>
)

export const dialogErrorHandler = ({ history }) => ({
  message: (
    <span>
      Error loading this dialog. <br /> Please try again later.
    </span>
  ),
  buttonLabel: 'Close',
  onButtonClick: () => history.goBack(),
})

const withErrorMessage = (options) => (Component) => (props) => {
  const { name: names, message, component, buttonLabel, onButtonClick } =
    result(options, props) || {}

  const nameList = Array.isArray(names) ? names : [names || 'data']
  const error = nameList.find((name) => {
    const data = props[name]

    return data && data.error
  })

  if (error) {
    const Message = component || DefaultErrorMessage

    return (
      <Message
        message={message}
        buttonLabel={buttonLabel}
        onButtonClick={onButtonClick}
      />
    )
  }

  return (
    <Component
      {...props}
      customErrorAlert={customErrorAlert}
      errorAlert={errorAlert}
      fullErrorAlert={fullErrorAlert}
      fatalErrorAlert={fatalErrorAlert}
    />
  )
}
export default withErrorMessage
