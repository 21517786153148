import React from 'react'
import ReactSelect from 'react-select'
import cx from 'classnames'

import {
  withLabel,
  withValue,
  withValidation,
  withOnChange,
  withWrapper,
  compose,
  nextIndex,
} from '../forms'

const cache = new WeakMap()

const wrapStringOptions = (options) => {
  let result = cache.get(options)
  if (!result) {
    result = options.map((option) => ({ label: option, value: option }))
    cache.set(options, result)
  }
  return result
}

const optionLoader = (loadOptions) =>
  loadOptions
    ? (query, callback) => {
        loadOptions(query, callback, () => {
          callback(null, {
            options: [
              {
                $ERROR: true,
                disabled: true,
              },
            ],
          })
        })
      }
    : null

const Select = ({
  options,
  loadOptions,
  optionRenderer,
  autocomplete,
  className,
  valueKey,
  labelKey,
  small,
  optionsAbove,
  placeholder,
  children,
  objectValue,
  ...props
}) => {
  if (autocomplete) {
    const SelectComponent = options ? ReactSelect : ReactSelect.Async
    return (
      <div
        className={cx(
          'widget autocomplete',
          { small, optionsAbove },
          className
        )}
      >
        <SelectComponent
          simpleValue={!objectValue}
          filterOption={options ? undefined : () => true}
          {...props}
          valueKey={valueKey}
          labelKey={labelKey}
          placeholder={placeholder}
          options={
            options && typeof options[0] === 'string'
              ? wrapStringOptions(options)
              : options
          }
          loadOptions={optionLoader(loadOptions)}
          optionRenderer={(option) =>
            option.$ERROR ? (
              <span className="option-error">
                Error loading the options, please try again later
              </span>
            ) : optionRenderer ? (
              optionRenderer(option)
            ) : (
              option[labelKey || 'label']
            )
          }
        />
      </div>
    )
  } else {
    return (
      <div
        className={cx(
          'widget select border',
          { small, optionsAbove },
          className
        )}
      >
        <select {...props} className={cx({ empty: props.value === '' })}>
          {placeholder ? (
            <option value="" hidden>
              {placeholder}
            </option>
          ) : null}
          {options
            ? options.map((option) => (
                <option
                  key={nextIndex()}
                  value={option[valueKey] || option.value || option}
                >
                  {option[labelKey] || option.label || option}
                </option>
              ))
            : children}
        </select>
      </div>
    )
  }
}

export default compose(
  withLabel,
  withValue,
  withValidation,
  withWrapper,
  withOnChange
)(Select)
