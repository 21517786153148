import { graphql } from '@apollo/client/react/hoc'
import {
  compose,
  withStateHandlers,
  withHandlers,
  withPropsOnChange,
} from 'recompose'
import { withRouter } from 'react-router'
import deepEqual from 'deep-equal'
import {
  format,
  sub,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
} from 'date-fns'
import { withStore } from '@babylon/babylon-forms/forms'
import { parse } from 'query-string'

import { envUrl } from '@babylon/babylon-env'
import { withSpinner, shouldRouteUpdate } from '@/util'
import {
  loadPersistentData,
  savePersistentData,
  persistentDataNames,
} from '@/util/localStorage'
import withErrorMessage from '@/util/withErrorMessage'
import promptReason from '@/components/ReasonDialog'
import showConfirmDialog from '@/components/ConfirmDialog'

import {
  PrescriptionRegions,
  Prescriptions,
  PrescriptionChangeState,
  PrescriptionRetryPdf,
  PrescriptionRetryFax,
  AddCommentToPrescription,
} from './queries'
import PrescriptionsView from './PrescriptionsView'
import statusPresets from './statusPresets.json'
import actionTypes from './actionTypes.json'
import showCommentDialog from './CommentDialog'

// VARIABLES

const defaultFilter = {
  statuses: {
    ...statusPresets.none,
    ...statusPresets.actionNeeded,
  },
  startDate: null,
  endDate: null,
  patientId: '',
  firstName: '',
  lastName: '',
  pharmacyDetails: '',
  consultantId: '',
  consultantName: '',
}

// increase this version number, when you change the structure of the
// persistent filter data. On the client the old filter will be
// discarded to avoid errors
const FILTER_VERSION = 3

const loadPersistentFilter = () => {
  const json = window.localStorage.getItem('prescription-filter') || '{}'
  const filter = JSON.parse(json)

  return filter.version === FILTER_VERSION ? filter : null
}

const persistentFilter = loadPersistentFilter()

const defaultVariables = ({ location, match }) => {
  const varFilter = persistentFilter
    ? {
        startDate: null,
        endDate: null,
        prescriptionId: '',
        patientId: '',
        firstName: '',
        lastName: '',
        pharmacyDetails: '',
        consultantId: '',
        consultantName: '',
        ...persistentFilter,
      }
    : defaultFilter
  const { prescriptionId } = match.params

  if (prescriptionId) {
    varFilter.prescriptionId = prescriptionId
  }

  const { patientId } = match.params

  if (patientId) {
    varFilter.patientId = patientId
    const { archived } = parse(location.search)

    if (archived === 'true') {
      varFilter.statuses = {
        ...statusPresets.none,
        ...statusPresets.archived,
      }
    } else {
      varFilter.statuses = {
        ...statusPresets.none,
        ...statusPresets.notArchived,
      }
    }
  }

  return {
    varSize: parseInt(
      loadPersistentData(persistentDataNames.PRESCRIPTION_PAGINATION_SIZE) || 10
    ),
    varPage: 0,
    varSearchPhrase: '',
    varSort: {
      column: 'createdAt',
      direction: 'desc',
    },
    varTimePreset: '',
    varFilter,
  }
}

export const hasVoidDispensedPrescriptionError = (exception) => {
  if (exception?.graphQLErrors && exception?.graphQLErrors?.length > 0) {
    return exception.graphQLErrors.some(
      (e) => e?.extensions?.response?.status === 409
    )
  }

  return false
}

const setSize = (state) => (varSize) => {
  savePersistentData(persistentDataNames.PRESCRIPTION_PAGINATION_SIZE, varSize)

  return {
    ...state,
    varSize,
    varPage: 0,
    lastChangedVariable: 'varSize',
  }
}

const setPage = (state) => (varPage) => ({
  ...state,
  varPage,
  lastChangedVariable: 'varPage',
})

const setSort = (state) => (varSort) => ({
  ...state,
  varSort,
  varPage: 0,
  lastChangedVariable: 'varSort',
})

const setFilter = (state) => (varFilter) => {
  window.localStorage.setItem(
    'prescription-filter',
    JSON.stringify({
      ...varFilter,
      startDate: null,
      endDate: null,
      prescriptionId: '',
      patientId: '',
      firstName: '',
      lastName: '',
      pharmacyDetails: '',
      consultantId: '',
      consultantName: '',
      version: FILTER_VERSION,
    })
  )

  return {
    ...state,
    varFilter,
    varTimePreset: varFilter.startDate ? '' : state.varTimePreset,
    varPage: 0,
    lastChangedVariable: 'varFilter',
  }
}

const setTimePreset = (state) => (varTimePreset) => ({
  ...state,
  varTimePreset,
  varFilter: varTimePreset
    ? { ...state.varFilter, startDate: null, endDate: null }
    : state.varFilter,
  varPage: 0,
  lastChangedVariable: 'varTimePreset',
})

const withVariables = withStateHandlers(defaultVariables, {
  setSize,
  setPage,
  setSort,
  setFilter,
  setTimePreset,
})

// DATA

const getTimePresetParam = (timePreset) => {
  if (timePreset) {
    const strToSubOptions = (timePreset) => {
      const subOptions = timePreset.split(' ')
      return { [subOptions[1].trim()]: subOptions[0].trim() }
    }

    return sub(new Date(), strToSubOptions(timePreset))
  }
}

export const queryOptions = {
  options: ({ varSize, varPage, varSort, varFilter, varTimePreset }) => {
    const prescriptionId =
      varFilter.prescriptionId && varFilter.prescriptionId.trim() !== ''
        ? varFilter.prescriptionId.trim()
        : null
    const variables = prescriptionId
      ? {
          prescriptionId,
        }
      : {
          size: varSize,
          page: varPage,
          sort: `${varSort.column},${varSort.direction}`,
          statuses: Object.keys(varFilter.statuses).filter(
            (name) => varFilter.statuses[name]
          ),
          dateFrom: varFilter.startDate || getTimePresetParam(varTimePreset),
          dateTo: varFilter.endDate,
          firstName: varFilter.firstName,
          lastName: varFilter.lastName,
          pharmacyDetails: varFilter.pharmacyDetails,
          consultantId: varFilter.consultantId,
          consultantName: varFilter.consultantName,
        }

    if (varFilter.patientId.trim().length > 0) {
      variables.patientId = varFilter.patientId
    }

    return { variables }
  },
}

const withData = compose(
  graphql(PrescriptionRegions, { name: 'regions' }),
  graphql(Prescriptions, queryOptions),
  graphql(PrescriptionChangeState, { name: 'prescriptionChangeState' }),
  graphql(PrescriptionRetryPdf, { name: 'prescriptionRetryPdf' }),
  graphql(PrescriptionRetryFax, { name: 'prescriptionRetryFax' }),
  graphql(AddCommentToPrescription, { name: 'addCommentToPrescription' })
)

// FILTERS

const withFilterStore = withStore((props) => props.varFilter, {
  name: 'filter',
})

// STATE

const defaultState = {
  showFilter: JSON.parse(
    window.localStorage.getItem('prescription-filter-visible') || 'false'
  ),
  downloadRegion: '',
}

const setFilterVisible = (state) => (showFilter) => {
  window.localStorage.setItem(
    'prescription-filter-visible',
    JSON.stringify(showFilter)
  )

  return {
    ...state,
    showFilter,
  }
}

export const updateDownloadRegion = (state) => (downloadRegion) => ({
  ...state,
  downloadRegion,
})

const withState = withStateHandlers(defaultState, {
  setFilterVisible,
  updateDownloadRegion,
})

// PROPS

const withProps = withPropsOnChange(['varFilter'], (props) => ({
  hasFilter: !deepEqual(props.varFilter, defaultFilter),
}))

// ACTIONS

export const handleAction = (props) => (action, { id }) => {
  switch (action) {
    case 'VIEW_PDF':
      window.open(`${envUrl('FILE_DOWNLOAD_URL')}/prescription/${id}.pdf`, id)

      break
    case 'RETRY_CREATING_PRESCRIPTION':
      props.prescriptionRetryPdf({ variables: { id } }).catch(() => {
        props.errorAlert()
      })

      break
    case 'FAX_RETRY':
      props.prescriptionRetryFax({ variables: { id } }).catch(() => {
        props.errorAlert()
      })

      break
    default: {
      const processAction = (reason) => {
        props
          .prescriptionChangeState({
            variables: { id, action, reason },
            refetchQueries: ['PrescriptionAudit'],
          })
          .catch((error) => {
            if (hasVoidDispensedPrescriptionError(error)) {
              props.customErrorAlert(
                "We can't void this prescription because the patient has already collected their medications."
              )
            } else {
              props.errorAlert()
            }
          })
      }

      if (actionTypes[action].reasons) {
        promptReason(actionTypes[action].reasons)().then(
          processAction,
          () => null
        )
      } else if (actionTypes[action].confirm) {
        showConfirmDialog(actionTypes[action].confirm)().then(
          () => processAction(),
          () => null
        )
      } else {
        processAction()
      }
    }
  }
}

const updateFilter = ({ filter, setFilter, setFilterVisible }) => () => {
  setFilter(filter.state)
  setFilterVisible(false)
}

export const handleAudits = (props) => (_, { id }) => {
  props.history.push(`/admin/prescriptions/audits/${id}`)
}

export const handleComment = (props) => (_, { id: prescriptionId }) => {
  showCommentDialog().then(
    // Save
    (comment) =>
      props
        .addCommentToPrescription({
          variables: { prescriptionId, comment },
          refetchQueries: ['PrescriptionAudit'],
        })
        .catch(() => {
          props.errorAlert()
        }),
    // Cancel
    () => null
  )
}

const handleSort = (props) => (column, direction) => {
  props.setSort({ column, direction })
}

const downloadCSV = (props) => (period) => {
  const dateFormat = 'yyyy-MM-dd'
  var date

  switch (period) {
    case 'day':
      date = format(startOfDay(new Date()), dateFormat)
      break
    case 'week':
      date = format(startOfWeek(new Date()), dateFormat)
      break
    case 'month':
      date = format(startOfMonth(new Date()), dateFormat)
      break
    case 'year':
      date = format(startOfYear(new Date()), dateFormat)
      break
  }

  return () => {
    window.open(
      `${envUrl('FILE_DOWNLOAD_URL')}/prescription-drug-list-${
        props.downloadRegion
      }-${date}.csv`,
      '_self'
    )
  }
}

const handleConsultantIdClick = ({ filter, setFilter }) => (id) => () => {
  filter.setState(
    {
      startDate: null,
      endDate: null,
      prescriptionId: '',
      patientId: '',
      firstName: '',
      lastName: '',
      pharmacyDetails: '',
      consultantName: '',
      consultantId: id,
    },
    () => setFilter(filter.state)
  )
}

const handleFilterReset = ({ filter, setFilter, setFilterVisible }) => () => {
  filter.setState(
    {
      startDate: null,
      endDate: null,
      prescriptionId: '',
      patientId: '',
      firstName: '',
      lastName: '',
      pharmacyDetails: '',
      consultantId: '',
      consultantName: '',
      statuses: {
        ...statusPresets.none,
        ...statusPresets.actionNeeded,
      },
    },
    () => {
      setFilter(filter.state)
      setFilterVisible(false)
    }
  )
}

const handlePresetClick = ({ filter }) => (name) => () => {
  filter.setState({
    statuses: {
      ...statusPresets.none,
      ...statusPresets[name],
    },
  })
}

const withActions = withHandlers({
  handleAction,
  updateFilter,
  handleAudits,
  handleComment,
  handleSort,
  downloadCSV,
  handleConsultantIdClick,
  handleFilterReset,
  handlePresetClick,
})

// LOADER

export const isLoading = (props) => !props.data || !props.data.prescriptions

const withLoader = withSpinner(isLoading)

export default compose(
  withRouter,
  shouldRouteUpdate,
  withVariables,
  withData,
  withFilterStore,
  withState,
  withProps,
  withErrorMessage(),
  withActions,
  withLoader
)(PrescriptionsView)
