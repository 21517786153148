import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import { Spinner, Modal, Pagination, Heading } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { PatientIdProps, Comment as CommentType } from '../../../types'
import Comment from './Comment'
import styles from './CommentsCard.module.scss'
import messages from './CommentsCard.messages'
import { fetchCommentsGQL } from './queries'

export interface PaginatedCommentsProps extends PatientIdProps {
  open: boolean
  onCloseHandler: () => void
}

const onLoadMore = (
  pageIndex: number,
  fetchMore: (arg0: any) => void,
  patientId: string
) => {
  fetchMore({
    variables: {
      patient_id: patientId,
      pageIndex,
    },
    updateQuery: (prev: any, { fetchMoreResult }: { fetchMoreResult: any }) => {
      if (!fetchMoreResult) {
        return prev
      }

      return {
        ...prev,
        comments: {
          ...prev.comments,
          items: fetchMoreResult.comments.items,
          pageIndex,
        },
      }
    },
  })
}

const PaginatedComments = ({ patientId }: PatientIdProps) => {
  const fm = useFormatMessage()
  const { loading, error, data, fetchMore } = useQuery(fetchCommentsGQL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      patient_id: patientId,
    },
  })

  if (loading) {
    return <Spinner centered />
  }

  if (error) {
    return <span>{`${fm(messages.error)} ${error} ${data}`}</span>
  }

  const {
    comments: { items: comments, totalItems, pageIndex, itemsPerPage },
  } = data

  const totalPages = Math.ceil(totalItems / itemsPerPage)

  return (
    <div data-testid="paginated-comments">
      <div className={styles.Comment__list}>
        {comments.map((comment: CommentType) => (
          <Comment key={comment.id} {...comment} />
        ))}
      </div>
      {totalItems > itemsPerPage && (
        <Pagination
          currentPage={pageIndex}
          onNextClick={() => onLoadMore(pageIndex + 1, fetchMore, patientId)}
          onPageClick={(pageIndex: number) =>
            onLoadMore(pageIndex, fetchMore, patientId)
          }
          onPrevClick={() => onLoadMore(pageIndex - 1, fetchMore, patientId)}
          totalPages={totalPages}
          pageDelta={2}
        />
      )}
    </div>
  )
}

const CommentsModal: FC<PaginatedCommentsProps> = ({
  onCloseHandler,
  open,
  patientId,
}) => {
  const fm = useFormatMessage()

  return (
    <Modal label={fm(messages.header)} open={open} onClose={onCloseHandler}>
      <div data-testid="comments-modal" className={styles.Comment__modal}>
        <header className={styles.Comment__header}>
          <Heading level="h2">{fm(messages.header)}</Heading>
        </header>
        <PaginatedComments patientId={patientId} />
      </div>
    </Modal>
  )
}

export default CommentsModal
