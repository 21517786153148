import React from 'react'

import { Heading, Text } from '@babylon/core-ui'

import styles from './PatientDetails.module.css'

interface PatientInfo {
  first_name: string
  last_name: string
  date_of_birth?: string
  address_post_code?: string
}

interface Props {
  patient: PatientInfo
}

const EMPTY_DETAILS = '-'

const PatientDetails = ({ patient }: Props) => (
  <div className={styles.container} data-testid="patient-details">
    <Heading level="h3" color="black-type">
      <span className={styles.lastName}>{patient.last_name}</span>,{' '}
      <span className={styles.firstName}>{patient.first_name}</span>
    </Heading>
    <Text size="large" color="grey-type">
      {patient.date_of_birth
        ? new Intl.DateTimeFormat('en-GB', {
            timeZone: 'UTC',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          }).format(new Date(patient.date_of_birth))
        : EMPTY_DETAILS}
    </Text>
    <Text size="large" color="grey-type">
      <span className={styles.postalCode}>
        {patient.address_post_code || EMPTY_DETAILS}
      </span>
    </Text>
  </div>
)

export default PatientDetails
