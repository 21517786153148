import { defineMessages } from 'react-intl'

const baseId = 'promo_code'

export default defineMessages({
  confirm_code: {
    id: `${baseId}.confirm_code`,
    defaultMessage: 'Confirm code',
  },
  enter_code: {
    id: `${baseId}.enter_code`,
    defaultMessage: 'Enter membership code',
  },
  radio_membership: {
    id: `${baseId}.radio_membership`,
    defaultMessage: 'Membership',
  },
  radio_promotional: {
    id: `${baseId}.radio_promotional`,
    defaultMessage: 'Promotional',
  },
  redeem_code: {
    id: `${baseId}.redeem_code`,
    defaultMessage: 'Redeem Code',
  },
  redemption_success: {
    id: `${baseId}.redemption_success`,
    defaultMessage: 'Success! The promotional code has been redeemed!',
  },
  redemption_error: {
    id: `${baseId}.redemption_error`,
    defaultMessage:
      "This code wasn't redeemed. Check the correct option was selected.",
  },
})
