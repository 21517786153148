import useProductConfig from './useConfig'
import usePreFetchConfig from './usePrefetchConfig'
import getProductConfig from './getConfig'

const useBoundPreFetchConfig = usePreFetchConfig.bind(
  {},
  {
    getConfig: getProductConfig,
  }
)

const useBoundProductConfig = useProductConfig.bind(
  {},
  {
    getConfig: getProductConfig,
  }
)

const useBoundPatientProductConfig = useProductConfig.bind(
  {},
  {
    getConfig: getProductConfig,
    shouldUseContractId: true,
  }
)

export {
  useBoundProductConfig as useProductConfig,
  useBoundPreFetchConfig as usePreFetchConfig,
  useBoundPatientProductConfig as usePatientProductConfig,
}
