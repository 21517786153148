export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * A URL such as 'https://google.com/'.
   *
   * See [Uniform Resource Locators (URL)](https://www.w3.org/Addressing/URL/url-spec.txt).
   */
  Url: any
  /** A type returned by successful operations that have no data to provide to the caller. */
  Void: any
  /**
   * A string representation of a timestamp such as '2007-12-03T10:15:30Z' or '2007-12-03T10:15:30.239+02:00'.
   *
   * Refer to the `date-time` format from [section 5.6 of RFC 3339](https://datatracker.ietf.org/doc/html/rfc3339#section-5.6).
   */
  DateTime: any
  /**
   * An opaque cursor to a position in a GraphQL Connection.
   *
   * See [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm#sec-Cursor).
   */
  Cursor: any
  /** An integer number greater than or equal to zero. */
  NonNegativeInt: any
  /**
   * A field whose value conforms to the national postal code format for any of 117 countries that use postal codes.
   *
   * See <https://en.wikipedia.org/wiki/Postal_code>.
   */
  PostalCode: any
  /**
   * A two-letter country code such as 'GB'.
   *
   * See [ISO 3166 - Country Codes](https://www.iso.org/iso-3166-country-codes.html).
   */
  CountryCode: any
  /**
   * A language tag such as 'en' or 'en-GB'.
   * See [Language Tags and Locale Identifiers for the World Wide Web](https://www.w3.org/TR/ltli/#language-terminology).
   */
  Language: any
  /**
   * A time zone identifier such as 'Europe/Berlin' or 'Australia/Sydney'.
   *
   * See [IANA Time Zone Database](https://www.iana.org/time-zones).
   */
  TimezoneId: any
  /**
   * A string representation of a date such as '2007-12-03'.
   *
   * Refer to the `full-date` format from [section 5.6 of RFC 3339](https://datatracker.ietf.org/doc/html/rfc3339#section-5.6).
   */
  Date: any
  /** A string containing HL7 Fhir json. Validation requires it to be a well formed JSON. */
  FhirJson: any
  /** A JSON object conforming to the `LocalizedStringServerContext` input type */
  LocalizationContext: any
  /** A IETF BCP 47 language tag */
  Locale: any
  /** An Int scalar that must be a positive value */
  PositiveInt: any
  /**
   * A URI such as 'http://snomed.info/sct' or 'urn:isbn:978-0544003415'.
   *
   * See [Uniform Resource Identifier (URI): Generic Syntax](https://datatracker.ietf.org/doc/html/rfc3986).
   */
  Uri: any
  /**
   * A value taken from a set of controlled strings defined elsewhere.
   *
   * See [FHIR Data Types - Code](https://www.hl7.org/fhir/datatypes.html#code).
   */
  FhirCode: any
  /** A JSON object conforming to the `CodeableConceptInput` input type. */
  CodeableConceptInfo: any
  /**
   * An instant in time in the format YYYY-MM-DDThh:mm:ss.sss+zz:zz (e.g. 2015-02-07T13:28:17.239+02:00 or 2017-01-01T00:00:00Z).
   * The time SHALL specified at least to the second and SHALL include a time zone.
   * Note: This is intended for when precisely observed times are required (typically system logs etc.), and not human-reported times - for those, use date or dateTime (which can be as precise as instant, but is not required to be). instant is a more constrained dateTime
   *
   * Regex: ([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00))
   */
  FhirInstant: any
  /**
   * A type created to accept dfhir data from mutation requests so that healthgraph can return the up-to-date data.
   * It is a map with {id: ID!, items: [IdAndDfhirItem!]!}, where
   * IdAndDfhirItem = {templateName: String!, domain: String!, templateVersion: String!, dfhir: String!, ownerUuid: String}
   */
  IdAndDfhir: any
  /**
   * A date, date-time or partial date (e.g. just year or year + month) as used in human communication.
   * The format is YYYY, YYYY-MM, YYYY-MM-DD or YYYY-MM-DDThh:mm:ss+zz:zz, e.g. 2018, 1973-06, 1905-08-23, 2015-02-07T13:28:17-05:00 or 2017-01-01T00:00:00.000Z.
   * If hours and minutes are specified, a time zone SHALL be populated.
   * Seconds must be provided due to schema type constraints but may be zero-filled and may be ignored at receiver discretion.
   * Dates SHALL be valid dates. The time "24:00" is not allowed. Leap Seconds are allowed - see below
   *
   * Regex: ([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])(T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00)))?)?)?
   */
  FhirDateTime: any
  /**
   * A SNOMED CT expression constraint such as `<< 73211009 | Diabetes Mellitus (disorder) |`.
   *
   * Codes can be either SNOMED CT codes or Babylon IRIs. For example: `46635009 OR https://bbl.health/YoTs_GRdm8`.
   *
   * See [Expression Constraint Language](https://confluence.ihtsdotools.org/display/DOCECL) for more information.
   */
  ExpressionConstraint: any
  /**
   * A date, or partial date (e.g. just year or year + month) as used in human communication.
   * The format is YYYY, YYYY-MM, or YYYY-MM-DD, e.g. 2018, 1973-06, or 1905-08-23.
   * There SHALL be no time zone. Dates SHALL be valid dates
   *
   * Regex: ([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00))
   */
  FhirDate: any
  /**
   * A document written in Markdown format.
   * See [CommonMark](https://commonmark.org/).
   */
  Markdown: any
  /** A UUID (aka GUID) represented as a URI (RFC 4122); e.g. urn:uuid:c757873d-ec9a-4326-a141-556f43239520 */
  Uuid: any
  /**
   * A time during the day, in the format hh:mm:ss. There is no date specified.
   * Seconds must be provided due to schema type constraints but may be zero-filled and may be ignored at receiver discretion.
   * The time "24:00" SHALL NOT be used. A time zone SHALL NOT be present. Times can be converted to a Duration since midnight.
   *
   * Regex: ([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?
   */
  FhirTime: any
  /**
   * A field whose value is a JSON Web Token (JWT).
   *
   * See [RFC 7519](https://datatracker.ietf.org/doc/html/rfc7519).
   */
  Jwt: any
  /**
   * An email address such as 'jane.smith@babylon.com'.
   *
   * Refer to the `addr-spec` format from [section 3.4.1 of RFC 5322](https://datatracker.ietf.org/doc/html/rfc5322#section-3.4.1).
   */
  EmailAddress: any
  /**
   * A telephone number such as '+17895551234'.
   *
   * See [ITU-T E.164 - The international public telecommunication numbering plan](https://www.itu.int/rec/dologin_pub.asp?lang=e&id=T-REC-E.164-201011-I!!PDF-E&type=items).
   */
  PhoneNumber: any
  FieldSet: any
  /**
   * A resource uri is a combination of a domain and an id within that domain.
   * Resource URIs are used to precisely specify which resource you are refering to.
   * It should be of the form https://<domain>.bbl.health/<resourceType>/<id>
   *
   * You can read more here https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  ResourceUri: any
}

/** Data relevant to the action undertaken */
export type ActionMessageValue = {
  __typename?: 'ActionMessageValue'
  /** details of the action to be taken when user selects the card */
  data: ActionMessageValueData
}

export type ActionMessageValueData =
  | CardActionMessageDatum
  | ChatPlatformAppActionMessageDatum
  | DownloadMessageDatum
  | EmailActionMessageData
  | PhoneActionMessageData
  | WebviewActionMessageData

/** Used to specify the type of the challenge action. */
export enum ActionType {
  /** The type used for appointment actions */
  Appointments = 'APPOINTMENTS',
  /** The type used for a completed action */
  Confirmation = 'CONFIRMATION',
  /** The type used for download actions */
  Download = 'DOWNLOAD',
  /** The type used for the get started health check */
  Getstarted = 'GETSTARTED',
  /** The type used for monitoring data */
  Monitor = 'MONITOR',
  /** The type used for verification checks */
  Verified = 'VERIFIED',
}

/** Details to add a contact info to a patient */
export type AddPatientAddressInput = {
  /** The content of the address to add */
  address: AddressInput
  /** Whether or not this is the primary contact point of this type */
  isPrimary?: Maybe<Scalars['Boolean']>
  /** The id of the patient to update. */
  patient: Scalars['ID']
  /** A string to describe the source of the contact point */
  source?: Maybe<Scalars['String']>
  /** The dateTime at which the contact point was ingested at the source */
  sourceIngestionTimestamp?: Maybe<Scalars['String']>
  /** A string to describe the status of the contact point */
  status?: Maybe<Scalars['String']>
}

export type AddPatientAddressResult = Patient | ResourceNotFoundError

/** Details to add a contact info to a patient */
export type AddPatientEmailAddressInput = {
  /** The content of the address to add */
  emailAddress: Scalars['EmailAddress']
  /** Whether or not this is the primary contact point of this type */
  isPrimary?: Maybe<Scalars['Boolean']>
  /** The id of the patient to update. */
  patient: Scalars['ID']
  /** A string to describe the source of the contact point */
  source?: Maybe<Scalars['String']>
  /** The dateTime at which the contact point was ingested at the source */
  sourceIngestionTimestamp?: Maybe<Scalars['String']>
  /** A string to describe the status of the contact point */
  status?: Maybe<Scalars['String']>
}

export type AddPatientEmailAddressResult = Patient | ResourceNotFoundError

/** Details to add a patient identifier. */
export type AddPatientIdentifierInput = {
  /** Identifier associated with this patient. */
  identifier: IdentifierInput
  /** The id of the patient to update. */
  patient: Scalars['ID']
}

export type AddPatientIdentifierResult =
  | Patient
  | ResourceAlreadyExistsError
  | ResourceNotFoundError

/** Details to add a contact info to a patient */
export type AddPatientPhoneNumberInput = {
  /** Whether or not this is the primary contact point of this type */
  isPrimary?: Maybe<Scalars['Boolean']>
  /** The id of the patient to update. */
  patient: Scalars['ID']
  /** A phone number. */
  phoneNumber: Scalars['PhoneNumber']
  /** A string to describe the source of the contact point */
  source?: Maybe<Scalars['String']>
  /** The dateTime at which the contact point was ingested at the source */
  sourceIngestionTimestamp?: Maybe<Scalars['String']>
  /** A string to describe the status of the contact point */
  status?: Maybe<Scalars['String']>
  /** A string to describe the contact point is used. */
  use?: Maybe<Scalars['FhirCode']>
}

export type AddPatientPhoneNumberResult = Patient | ResourceNotFoundError

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats) */
export type Address = {
  __typename?: 'Address'
  /** Name of city, town etc. */
  city?: Maybe<Scalars['String']>
  /** Country (e.g. can be ISO 3166 2 or 3 letter code) */
  country?: Maybe<Scalars['CountryCode']>
  /** The dateTime at which the address was created in our systems */
  createdAt: Scalars['DateTime']
  /** District name (aka county) */
  district?: Maybe<Scalars['String']>
  /** Identifier for this address */
  id: Scalars['ID']
  /** Whether or not this is the primary address */
  isPrimary: Scalars['Boolean']
  /** Street name, number, direction & P.O. Box etc. */
  line: Array<Scalars['String']>
  /** Postal code for area */
  postalCode?: Maybe<Scalars['PostalCode']>
  /** A string to describe the source of the address */
  source?: Maybe<Scalars['String']>
  /** The dateTime at which the address was ingested at the source */
  sourceIngestionTimestamp?: Maybe<Scalars['DateTime']>
  /** Sub-unit of country (abbreviations ok) */
  state?: Maybe<Scalars['String']>
  /** A string to describe the status of the address */
  status: Scalars['String']
  /** Text representation of the address */
  text: Scalars['String']
  /** The dateTime at which the address was updated in our systems */
  updatedAt?: Maybe<Scalars['DateTime']>
}

/**
 * A page/slice of a list of nodes of type `Address`, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type AddressConnection = Connection & {
  __typename?: 'AddressConnection'
  /** Edges in the current connection page. */
  edges: Array<AddressEdge>
  /** Nodes in the current connection page. */
  nodes: Array<Maybe<Address>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Address segments taken from a specific place ID" */
export type AddressDetail = {
  __typename?: 'AddressDetail'
  /** Displays a list of address lines */
  lines: StringConnection
  /** Returns the postcode for the address */
  postcode?: Maybe<Scalars['PostalCode']>
}

/** Address segments taken from a specific place ID" */
export type AddressDetailLinesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An edge in a connection page for a node of type `Address`.
 *
 * See the [GraphQL Edges Specification](https://relay.dev/graphql/connections.htm#sec-Edges) for guidance on how to use.
 */
export type AddressEdge = Edge & {
  __typename?: 'AddressEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<Address>
}

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats). */
export type AddressInput = {
  /** Name of city, town etc. */
  city?: Maybe<Scalars['String']>
  /** Country (e.g. can be ISO 3166 2 or 3 letter code) */
  country?: Maybe<Scalars['CountryCode']>
  /** District name (aka county) */
  district?: Maybe<Scalars['String']>
  /** Street name, number, direction & P.O. Box etc. */
  line?: Maybe<Array<Scalars['String']>>
  /** Postal code for area */
  postalCode?: Maybe<Scalars['PostalCode']>
  /** Sub-unit of country (abbreviations ok) */
  state?: Maybe<Scalars['String']>
}

/** Autocomplete API response */
export type AddressPrediction = {
  __typename?: 'AddressPrediction'
  /** Preictions of address from address string */
  predictions: PredictionConnection
  /**
   * status is an enum from Google Places Autocomplete API. Not controlled by us to must remain String type.
   *
   * See [Google Places Autocomplete statuses](https://developers.google.com/maps/documentation/places/web-service/autocomplete#PlacesAutocompleteStatus)
   */
  status?: Maybe<Scalars['String']>
}

/** Autocomplete API response */
export type AddressPredictionPredictionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**  The use of an address.  */
export enum AddressUse {
  /**  An address to be used to send bills, invoices, receipts etc.  */
  Billing = 'BILLING',
  /**  A communication address at a home.  */
  Home = 'HOME',
  /**  This address is no longer in use (or was never correct but retained for records).  */
  Old = 'OLD',
  /**  A temporary address. The period can provide more detailed information.  */
  Temp = 'TEMP',
  /**  An office address. First choice for business related contacts during business hours.  */
  Work = 'WORK',
}

/**  The use of an address.  */
export type AddressUseFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<AddressUse>>>
}

/** The gender of a person used for administrative purposes. */
export enum AdministrativeGender {
  /** Female. */
  Female = 'FEMALE',
  /** Male. */
  Male = 'MALE',
  /** Other. */
  Other = 'OTHER',
  /** Unknown. */
  Unknown = 'UNKNOWN',
}

/**  The gender of a person used for administrative purposes.  */
export type AdministrativeGenderFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<AdministrativeGender>>>
}

/** Contains the information needed to display an animation */
export type AnimationMessageValue = {
  __typename?: 'AnimationMessageValue'
  /** short description about content of animation */
  description?: Maybe<Scalars['String']>
  /** text to display on close button */
  dismissText?: Maybe<Scalars['String']>
  /** unique id of animation */
  id: Scalars['String']
  /** animation title */
  title: Scalars['String']
  /** animation source */
  url?: Maybe<Scalars['String']>
}

/**  A text note which also contains information about who made the statement and when. */
export type Annotation = {
  __typename?: 'Annotation'
  /**  The text of the annotation in markdown format. */
  text?: Maybe<Scalars['Markdown']>
  /**  Indicates when this particular annotation was made. */
  time?: Maybe<Scalars['FhirDateTime']>
}

/**
 * A page/slice of a list of Annotation nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type AnnotationConnection = Connection & {
  __typename?: 'AnnotationConnection'
  /** Edges in the current connection page. */
  edges: Array<AnnotationEdge>
  /** List of Annotation object nodes in the current connection page. */
  nodes: Array<Maybe<Annotation>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Annotation connection page. */
export type AnnotationEdge = Edge & {
  __typename?: 'AnnotationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Annotation object in this edge. */
  node?: Maybe<Annotation>
}

/**
 * A booking of a healthcare event among patient(s), practitioner(s), related
 * person(s) and/or device(s) for a specific date/time. This may result in one or
 * more Encounter(s).
 */
export type Appointment = HealthgraphResource &
  Resource & {
    __typename?: 'Appointment'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** A list of actions for the appointment. */
    actions?: Maybe<AppointmentActionConnection>
    /**
     * The coded reason for the appointment being cancelled. This is often used in
     * reporting/billing/futher processing to determine if further actions are
     * required, or specific fees apply.
     */
    cancelationReason?: Maybe<AppointmentCancelationReasonResult>
    /** Date/Time that the appointment is to conclude. */
    end?: Maybe<Scalars['FhirInstant']>
    /** ID */
    id: Scalars['ID']
    /**
     * The id field and a list of dfhir items.
     * Intended to be used by incoming mutation requests to pass updated data so that healthgraph can return the up-to-date
     * data back. This field is not meant to be read and in fact would return an empty result for dfhir if it was requested.
     * It is in the form of a json scalar. Each dfhir item consists of template name, domain, template version, the actual
     * dfhir data, and the uuid of the owner of the data.
     */
    idAndDfhir?: Maybe<Scalars['IdAndDfhir']>
    /**
     * This records identifiers associated with this appointment concern that are
     * defined by business processes and/or used to refer to it when a direct URL
     * reference to the resource itself is not appropriate (e.g. in CDA documents, or
     * in written / printed documentation).
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * Checks whether this appointment is reschedulable. If it is, it will be open to
     * be rescheduled for a different time or provider
     */
    isReschedulable: Scalars['Boolean']
    /** The way in which the appointment will be delivered, for example, video or voice */
    medium?: Maybe<Scalars['String']>
    /**
     * Number of minutes that the appointment is to take. This can be less than the
     * duration between the start and end times.  For example, where the actual time
     * of appointment is only an estimate or if a 30 minute appointment is being
     * requested, but any time would work.  Also, if there is, for example, a planned
     * 15 minute break in the middle of a long appointment, the duration may be 15
     * minutes less than the difference between the start and end.
     */
    minutesDuration?: Maybe<Scalars['PositiveInt']>
    /** List of participants involved in the appointment. */
    participants?: Maybe<AppointmentParticipantConnection>
    /** The preferred consumer network of the patient when the appointment was booked. */
    patientConsumerNetwork?: Maybe<Scalars['Uuid']>
    /** The coded reason that this appointment is being scheduled. This is more clinical than administrative. */
    reasonCodes?: Maybe<CodeableConceptConnection>
    /** Patient's requested language */
    requestedLanguage?: Maybe<Scalars['String']>
    /** Date/Time that the appointment is to take place. */
    start?: Maybe<Scalars['FhirInstant']>
    /**
     * The overall status of the Appointment. Each of the participants has their own
     * participation status which indicates their involvement in the process, however
     * this status indicates the shared status.
     */
    status?: Maybe<Scalars['FhirCode']>
  }

/**
 * A booking of a healthcare event among patient(s), practitioner(s), related
 * person(s) and/or device(s) for a specific date/time. This may result in one or
 * more Encounter(s).
 */
export type AppointmentActionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A booking of a healthcare event among patient(s), practitioner(s), related
 * person(s) and/or device(s) for a specific date/time. This may result in one or
 * more Encounter(s).
 */
export type AppointmentIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A booking of a healthcare event among patient(s), practitioner(s), related
 * person(s) and/or device(s) for a specific date/time. This may result in one or
 * more Encounter(s).
 */
export type AppointmentParticipantsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A booking of a healthcare event among patient(s), practitioner(s), related
 * person(s) and/or device(s) for a specific date/time. This may result in one or
 * more Encounter(s).
 */
export type AppointmentReasonCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** An action that needs to occur for the appointment. Inspired by the Task FHIR model https://www.hl7.org/fhir/task.html */
export type AppointmentAction = Resource & {
  __typename?: 'AppointmentAction'
  /** A value that defines what type of action needs to occur. */
  code: Scalars['String']
  /** A localized action description for display */
  description?: Maybe<LocalizedString>
  /** Duration in minutes to complete action */
  expectedDurationMinutes?: Maybe<Scalars['PositiveInt']>
  /** ID of the appointment action */
  id: Scalars['ID']
  /** A list of action parameters for the appointment. */
  parameters?: Maybe<AppointmentActionParameterConnection>
  /** The current state of the action, e.g. pending or completed. */
  status: AppointmentActionStatus
  /** A localized action title for display */
  title?: Maybe<LocalizedString>
}

/** An action that needs to occur for the appointment. Inspired by the Task FHIR model https://www.hl7.org/fhir/task.html */
export type AppointmentActionParametersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A collection of appointment actions. */
export type AppointmentActionConnection = Connection & {
  __typename?: 'AppointmentActionConnection'
  /** Edges */
  edges: Array<AppointmentActionEdge>
  /** Nodes */
  nodes: Array<Maybe<AppointmentAction>>
  /** Page Info */
  pageInfo: PageInfo
  /** Total Count */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge for an appointment action. */
export type AppointmentActionEdge = Edge & {
  __typename?: 'AppointmentActionEdge'
  /** Cursor */
  cursor: Scalars['Cursor']
  /** Node */
  node?: Maybe<AppointmentAction>
}

/** An appointment action additional parameter */
export type AppointmentActionParameter = {
  __typename?: 'AppointmentActionParameter'
  /** Appointment action key */
  key: Scalars['String']
  /** Appointment action value */
  value: Scalars['String']
}

/** A collection of appointment action parameters */
export type AppointmentActionParameterConnection = Connection & {
  __typename?: 'AppointmentActionParameterConnection'
  /** Edges */
  edges: Array<AppointmentActionParameterEdge>
  /** Nodes */
  nodes: Array<Maybe<AppointmentActionParameter>>
  /** Page Info */
  pageInfo: PageInfo
  /** Total Count */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge for an appointment action parameter */
export type AppointmentActionParameterEdge = Edge & {
  __typename?: 'AppointmentActionParameterEdge'
  /** Cursor */
  cursor: Scalars['Cursor']
  /** Node */
  node?: Maybe<AppointmentActionParameter>
}

/** Status of an Appointment Action */
export enum AppointmentActionStatus {
  /** Completed */
  Completed = 'COMPLETED',
  /** Pending */
  Pending = 'PENDING',
}

/**
 * The coded reason for the appointment being cancelled. This is often used in
 * reporting/billing/futher processing to determine if further actions are
 * required, or specific fees apply.
 */
export type AppointmentCancelationReasonResult = CodeableConcept | None

/**
 * A page/slice of a list of Appointment nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type AppointmentConnection = Connection & {
  __typename?: 'AppointmentConnection'
  /** Edges in the current connection page. */
  edges: Array<AppointmentEdge>
  /** List of Appointment object nodes in the current connection page. */
  nodes: Array<Maybe<Appointment>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Appointment connection page. */
export type AppointmentEdge = Edge & {
  __typename?: 'AppointmentEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Appointment object in this edge. */
  node?: Maybe<Appointment>
}

/**  Specifies a filter to apply to a list of Appointment  */
export type AppointmentFilter = {
  /**
   * Filters on Appointment.participant.actor
   * Any one of the individuals participating in the appointment
   */
  actorUri?: Maybe<ResourceUriFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<AppointmentFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Appointment.start
   * Appointment date/time.
   */
  date?: Maybe<DateFilter>
  /**
   * Filters on Appointment.identifier
   * An Identifier of the Appointment
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on Appointment.participant.actor.where(resolve() is Location)
   * This location is listed in the participants of the appointment
   */
  locationUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Appointment.extension('https://fhir.bbl.health/StructureDefinition/BblAppointmentMedium')
   * The way in which the appointment will be delivered, for example, video or voice
   */
  medium?: Maybe<StringFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<AppointmentFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<AppointmentFilter>>
  /**
   * Filters on Appointment.participant.status
   * The Participation status of the subject, or other participant on the
   * appointment. Can be used to locate participants that have not responded to
   * meeting requests.
   */
  partStatus?: Maybe<ParticipationStatusFilter>
  /**
   * Filters on Appointment.participant.actor.where(resolve() is Patient)
   * One of the individuals of the appointment is this patient
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Appointment.participant.actor.where(resolve() is Practitioner)
   * One of the individuals of the appointment is this practitioner
   */
  practitionerUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Filters on Appointment.reasonCode
   * Coded reason this appointment is scheduled
   */
  reasonCode?: Maybe<CodeFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Appointment.status
   * The overall status of the appointment
   */
  status?: Maybe<AppointmentStatusFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** The possible media for an appointment. */
export enum AppointmentMedium {
  /** Physical */
  Physical = 'PHYSICAL',
  /** Text */
  Text = 'TEXT',
  /** Video */
  Video = 'VIDEO',
  /** Voice */
  Voice = 'VOICE',
}

/**  List of participants involved in the appointment.  */
export type AppointmentParticipant = {
  __typename?: 'AppointmentParticipant'
  /** A Person, Location/HealthcareService or Device that is participating in the appointment. */
  actor?: Maybe<AppointmentParticipantActorResult>
  /** Participation status of the actor. */
  status?: Maybe<Scalars['FhirCode']>
  /** Role of participant in the appointment. */
  types?: Maybe<CodeableConceptConnection>
}

/**  List of participants involved in the appointment.  */
export type AppointmentParticipantTypesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A Person, Location/HealthcareService or Device that is participating in the appointment. */
export type AppointmentParticipantActorResult =
  | ExternalResource
  | HealthcareService
  | None
  | NonFederatedResource
  | Patient
  | Practitioner

/**
 * A page/slice of a list of AppointmentParticipant nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type AppointmentParticipantConnection = Connection & {
  __typename?: 'AppointmentParticipantConnection'
  /** Edges in the current connection page. */
  edges: Array<AppointmentParticipantEdge>
  /** List of AppointmentParticipant object nodes in the current connection page. */
  nodes: Array<Maybe<AppointmentParticipant>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a AppointmentParticipant connection page. */
export type AppointmentParticipantEdge = Edge & {
  __typename?: 'AppointmentParticipantEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the AppointmentParticipant object in this edge. */
  node?: Maybe<AppointmentParticipant>
}

export type AppointmentResult =
  | Appointment
  | AuthorizationError
  | ResourceNotFoundError

export type AppointmentsByIdentifierResult =
  | AppointmentConnection
  | AuthorizationError

/**  A sort on Appointment - consists of the field to sort on and the ordering  */
export type AppointmentSort = {
  /**  Which field to sort by  */
  field: AppointmentSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Appointment can be sorted by  */
export enum AppointmentSortOption {
  /**
   * Sorts on Appointment.start
   * Appointment date/time.
   */
  Date = 'DATE',
  /**  Do not sort  */
  None = 'NONE',
}

/**  The free/busy status of an appointment.  */
export enum AppointmentStatus {
  /**  The patient/patients has/have arrived and is/are waiting to be seen.  */
  Arrived = 'ARRIVED',
  /**  All participant(s) have been considered and the appointment is confirmed to go ahead at the date/times specified.  */
  Booked = 'BOOKED',
  /**  The appointment has been cancelled.  */
  Cancelled = 'CANCELLED',
  /**
   *  When checked in, all pre-encounter administrative work is complete, and the
   * encounter may begin. (where multiple patients are involved, they are all present).
   */
  CheckedIn = 'CHECKED_IN',
  /**  This instance should not have been part of this patient's medical record.  */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**
   *  The planning stages of the appointment are now complete, the encounter
   * resource will exist and will track further status changes. Note that an
   * encounter may exist before the appointment status is fulfilled for many reasons.
   */
  Fulfilled = 'FULFILLED',
  /**  Some or all of the participant(s) have not/did not appear for the appointment (usually the patient).  */
  Noshow = 'NOSHOW',
  /**  Some or all of the participant(s) have not finalized their acceptance of the appointment request.  */
  Pending = 'PENDING',
  /**
   *  None of the participant(s) have finalized their acceptance of the appointment
   * request, and the start/end time might not be set yet.
   */
  Proposed = 'PROPOSED',
  /**
   * The appointment has been placed on a waitlist, to be scheduled/confirmed in the future when a slot/service is available.
   * A specific time might or might not be pre-allocated.
   */
  Waitlist = 'WAITLIST',
}

/**  The free/busy status of an appointment.  */
export type AppointmentStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<AppointmentStatus>>>
}

/** Input required to assign a new Data Collection Journey */
export type AssignDataCollectionJourneyInput = {
  /** Journey Definition ID */
  journeyDefinitionId: Scalars['ID']
  /** Patient ID */
  patientId: Scalars['ID']
}

export type AssignDataCollectionJourneyResult =
  | DataCollectionJourney
  | ResourceNotFoundError

/**  This type is for containing or referencing attachments - additional data content defined in other formats. */
export type Attachment = {
  __typename?: 'Attachment'
  /**  Unique id for inter-element referencing */
  attachmentId?: Maybe<Scalars['String']>
  /**  Label to display in place of the data */
  title?: Maybe<Scalars['String']>
  /**  Uri where the data can be found */
  url?: Maybe<Scalars['Url']>
}

/**
 * A page/slice of a list of Attachment nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type AttachmentConnection = Connection & {
  __typename?: 'AttachmentConnection'
  /** Edges in the current connection page. */
  edges: Array<AttachmentEdge>
  /** List of Attachment object nodes in the current connection page. */
  nodes: Array<Maybe<Attachment>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Attachment connection page. */
export type AttachmentEdge = Edge & {
  __typename?: 'AttachmentEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Attachment object in this edge. */
  node?: Maybe<Attachment>
}

/** Audit event. */
export type AuditEvent = {
  __typename?: 'AuditEvent'
  /** Date. */
  date: Scalars['DateTime']
  /** User. */
  user?: Maybe<User>
}

/** An error indicating that the user does not have permissions to access the given resource. */
export type AuthorizationError = Error & {
  __typename?: 'AuthorizationError'
  /** Error message */
  errorMessage: Scalars['String']
}

/**  Specifies appointment filter to apply to the slot result */
export type AvailabilitySlotsAppointmentFilter = {
  /**  Search for booked slots. Defaults to unbooked slots if absent. */
  BOOKED?: Maybe<Scalars['Boolean']>
  /**  Search for unbooked slots. */
  UNBOOKED?: Maybe<Scalars['Boolean']>
}

/**
 * Filters the results by the booking constraints
 * All constraints are mutually exclusive.
 * I.E a slot can only be one of the provided `BookingConstraintFilterOption`.
 * If missing, return slots of all types
 */
export type AvailabilitySlotsBookingConstraintFilter = {
  /** Return slots matching any booking constraint in the provided array */
  ONE_OF?: Maybe<Array<BookingConstraintFilterOption>>
  /** Search only slots of a particular booking constraint. */
  ONLY?: Maybe<BookingConstraintFilterOption>
}

/** Used to search for slots between a given period */
export type AvailabilitySlotsDateFilter = {
  BETWEEN?: Maybe<TimePeriod>
}

/** Contains all filter params for querying slots */
export type AvailabilitySlotsFilter = {
  /** Filter slots that have booked appointments. */
  appointment?: Maybe<AvailabilitySlotsAppointmentFilter>
  /** Filter the slot results by the booking constraints. If missing returns all slots. */
  bookingConstraint?: Maybe<AvailabilitySlotsBookingConstraintFilter>
  /** Filter the slot results by applicable dates */
  date?: Maybe<AvailabilitySlotsDateFilter>
}

/** A list of availability slots. */
export type AvailabilitySlotsResult = AuthorizationError | SlotConnection

export type BabylonIdentifiersFromExternalIdentifiersResult =
  | AuthorizationError
  | HealthgraphIdentifierConnection

/** An error indicating that the birth date specified has not been found in stub profiles. */
export type BirthDateNoMatchError = Error & {
  __typename?: 'BirthDateNoMatchError'
  /**
   * Message to assist a developer in understanding and fixing the error.
   *
   * Not intended to be shown directly to an end-user.
   */
  errorMessage: Scalars['String']
}

/** Contains all information needed to book an appointment. */
export type BookAppointmentInput = {
  /** The ID of the clinician who this appointment will be booked with. */
  clinicianId: Scalars['ID']
  /**
   * The ID of the clinic where the appointment will take place. This is only
   * required when the medium of the appointment is AppointmentMedium.PHYSICAL
   */
  clinicId?: Maybe<Scalars['ID']>
  /** The way in which the appointment will be delivered. */
  medium: AppointmentMedium
  /** The duration of the appointment. */
  minutesDuration: Scalars['PositiveInt']
  /** The ID of the patient whom this appointment will be booked for. */
  patientId: Scalars['ID']
  /** The reason this appointment is being booked. Free text input provided by the appointment booking agent */
  reason: Scalars['String']
  /** The language requested by the patient. */
  requestedLanguage?: Maybe<Scalars['Language']>
  /** The time of the appointment. This cannot be in the past. */
  start: Scalars['DateTime']
}

export type BookAppointmentResult = Appointment | AuthorizationError

/**
 * Booking constraints associated to a slot.
 * A slot can be either ADMIN or TEXT or PHYSICAL or DIGITAL
 */
export enum BookingConstraintFilterOption {
  /** Admin */
  Admin = 'ADMIN',
  /** Digital */
  Digital = 'DIGITAL',
  /** Physical */
  Physical = 'PHYSICAL',
  /** Text */
  Text = 'TEXT',
}

/**
 * A page/slice of a list of Boolean nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type BooleanConnection = Connection & {
  __typename?: 'BooleanConnection'
  /** Edges in the current connection page. */
  edges: Array<BooleanEdge>
  /** List of Boolean object nodes in the current connection page. */
  nodes: Array<Maybe<Scalars['Boolean']>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Boolean connection page. */
export type BooleanEdge = Edge & {
  __typename?: 'BooleanEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Boolean object in this edge. */
  node?: Maybe<Scalars['Boolean']>
}

/**  Used to filter on a Boolean */
export type BooleanFilter = {
  /**  The field must match at least one of the given options */
  ONE_OF?: Maybe<Array<Scalars['Boolean']>>
}

/** Contains all information needed to cancel an appointment. */
export type CancelAppointmentInput = {
  /** The ID of the appointment to be cancelled. This is the ID of the Appointment Entity assigned by the domain system. */
  appointmentId: Scalars['ID']
  /** Additional free text comment describing why the appointment is cancelled. */
  cancelationReasonComment?: Maybe<Scalars['String']>
  /**
   * The ID of the system provided reason for the cancellation. For Babylon
   * internal services, this can be either an admin or patient cancel reason ID.
   */
  cancelationReasonId?: Maybe<Scalars['ID']>
}

export type CancelAppointmentResult =
  | Appointment
  | AuthorizationError
  | ResourceNotFoundError

/** Object of card values */
export type CardActionMessageDatum = {
  __typename?: 'CardActionMessageDatum'
  /** Banner image for the card */
  banner: ConversationSuggestionBanner
  /** Text shown on the card button */
  buttonText?: Maybe<Scalars['String']>
  /** Id of the card action */
  id: Scalars['ID']
  /** Description text of the card */
  text?: Maybe<Scalars['String']>
  /** Title of the card */
  title?: Maybe<Scalars['String']>
}

/** Contains the id of the card used in the most recent message */
export type CardInputMessage = {
  /** id of card selected by member */
  id: Scalars['ID']
}

/** Contains all information needed to display a card */
export type CardMessageInput = {
  __typename?: 'CardMessageInput'
  /** action executed if card is clicked */
  action?: Maybe<ActionMessageValue>
  /** id of animation to display */
  bannerId?: Maybe<Scalars['String']>
  /** text on the button for the card */
  buttonText?: Maybe<Scalars['String']>
  /** unique id of card */
  id: Scalars['String']
  /** body message of card */
  text: Scalars['String']
  /** title of the card */
  title?: Maybe<Scalars['String']>
}

/**
 *  Describes the intention of how one or more practitioners intend to deliver care
 * for a particular patient, group or community for a period of time, possibly
 * limited to care for a specific condition or set of conditions.
 */
export type CarePlan = HealthgraphResource &
  Resource & {
    __typename?: 'CarePlan'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * Identifies a planned action to occur as part of the plan.  For example, a
     * medication to be used, lab tests to perform, self-monitoring, education, etc.
     */
    activities?: Maybe<CarePlanActivityConnection>
    /** A care plan that is fulfilled in whole or in part by this care plan. */
    basedOns?: Maybe<CarePlanBasedOnResultConnection>
    /** Identifies the individual(s) or organization who provided the contents of the care plan. */
    contributors?: Maybe<CarePlanContributorResultConnection>
    /** Represents when this particular CarePlan record was created in the system, which is often a system-generated date. */
    created?: Maybe<Scalars['FhirDateTime']>
    /** The date that the subject became inactive in this care plan */
    disenrolleds?: Maybe<FhirDateTimeConnection>
    /** The Encounter during which this CarePlan was created or to which the creation of this record is tightly associated. */
    encounter?: Maybe<CarePlanEncounterResult>
    /** The date that the subject became active in the care plan */
    enrolled?: Maybe<Scalars['FhirDateTime']>
    /** Describes the intended objective(s) of carrying out the care plan. */
    goals?: Maybe<CarePlanGoalResultConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Business identifiers assigned to this care plan by the performer or other
     * systems which remain constant as the resource is updated and propagates from
     * server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * Indicates the level of authority/intentionality associated with the care plan
     * and where the care plan fits into the workflow chain.
     */
    intent?: Maybe<Scalars['FhirCode']>
    /** General notes about the care plan not covered elsewhere. */
    notes?: Maybe<AnnotationConnection>
    /** Indicates whether the plan is currently being acted upon, represents future intentions or is now a historical record. */
    status?: Maybe<Scalars['FhirCode']>
    /** Details of the status of the care plan */
    statusDetails?: Maybe<CarePlanCarePlanStatusDetailConnection>
    /** Identifies the patient or group whose intended care is described by the plan. */
    subject?: Maybe<CarePlanSubjectResult>
    /** Human-friendly name for the care plan. */
    title?: Maybe<Scalars['String']>
  }

/**
 *  Describes the intention of how one or more practitioners intend to deliver care
 * for a particular patient, group or community for a period of time, possibly
 * limited to care for a specific condition or set of conditions.
 */
export type CarePlanActivitiesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Describes the intention of how one or more practitioners intend to deliver care
 * for a particular patient, group or community for a period of time, possibly
 * limited to care for a specific condition or set of conditions.
 */
export type CarePlanBasedOnsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Describes the intention of how one or more practitioners intend to deliver care
 * for a particular patient, group or community for a period of time, possibly
 * limited to care for a specific condition or set of conditions.
 */
export type CarePlanContributorsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Describes the intention of how one or more practitioners intend to deliver care
 * for a particular patient, group or community for a period of time, possibly
 * limited to care for a specific condition or set of conditions.
 */
export type CarePlanDisenrolledsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Describes the intention of how one or more practitioners intend to deliver care
 * for a particular patient, group or community for a period of time, possibly
 * limited to care for a specific condition or set of conditions.
 */
export type CarePlanGoalsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Describes the intention of how one or more practitioners intend to deliver care
 * for a particular patient, group or community for a period of time, possibly
 * limited to care for a specific condition or set of conditions.
 */
export type CarePlanIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Describes the intention of how one or more practitioners intend to deliver care
 * for a particular patient, group or community for a period of time, possibly
 * limited to care for a specific condition or set of conditions.
 */
export type CarePlanNotesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Describes the intention of how one or more practitioners intend to deliver care
 * for a particular patient, group or community for a period of time, possibly
 * limited to care for a specific condition or set of conditions.
 */
export type CarePlanStatusDetailsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Identifies a planned action to occur as part of the plan.  For example, a
 * medication to be used, lab tests to perform, self-monitoring, education, etc.
 */
export type CarePlanActivity = {
  __typename?: 'CarePlanActivity'
  /**
   * A simple summary of a planned activity suitable for a general care plan system
   * (e.g. form driven) that doesn't know about specific resources such as procedure etc.
   */
  detail?: Maybe<CarePlanActivityDetailResult>
}

/**
 * A page/slice of a list of CarePlanActivity nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CarePlanActivityConnection = Connection & {
  __typename?: 'CarePlanActivityConnection'
  /** Edges in the current connection page. */
  edges: Array<CarePlanActivityEdge>
  /** List of CarePlanActivity object nodes in the current connection page. */
  nodes: Array<Maybe<CarePlanActivity>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 *  A simple summary of a planned activity suitable for a general care plan system
 * (e.g. form driven) that doesn't know about specific resources such as procedure etc.
 */
export type CarePlanActivityDetail = {
  __typename?: 'CarePlanActivityDetail'
  /**
   * This provides a textual description of constraints on the intended activity
   * occurrence, including relation to other activities.  It may also include
   * objectives, pre-conditions and end-conditions.  Finally, it may convey
   * specifics about the activity such as body site, method, route, etc.
   */
  description?: Maybe<Scalars['String']>
  /** Internal reference that identifies the goals that this activity is intended to contribute towards meeting. */
  goals?: Maybe<CarePlanActivityDetailGoalResultConnection>
  /** Identifies what progress is being made for the specific activity. */
  status?: Maybe<Scalars['FhirCode']>
}

/**
 *  A simple summary of a planned activity suitable for a general care plan system
 * (e.g. form driven) that doesn't know about specific resources such as procedure etc.
 */
export type CarePlanActivityDetailGoalsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Internal reference that identifies the goals that this activity is intended to contribute towards meeting. */
export type CarePlanActivityDetailGoalResult =
  | ExternalResource
  | Goal
  | NonFederatedResource

/**
 * A page/slice of a list of CarePlanActivityDetailGoalResult nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CarePlanActivityDetailGoalResultConnection = Connection & {
  __typename?: 'CarePlanActivityDetailGoalResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CarePlanActivityDetailGoalResultEdge>
  /** List of CarePlanActivityDetailGoalResult object nodes in the current connection page. */
  nodes: Array<Maybe<CarePlanActivityDetailGoalResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CarePlanActivityDetailGoalResult connection page. */
export type CarePlanActivityDetailGoalResultEdge = Edge & {
  __typename?: 'CarePlanActivityDetailGoalResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CarePlanActivityDetailGoalResult object in this edge. */
  node?: Maybe<CarePlanActivityDetailGoalResult>
}

/**
 * A simple summary of a planned activity suitable for a general care plan system
 * (e.g. form driven) that doesn't know about specific resources such as procedure etc.
 */
export type CarePlanActivityDetailResult = CarePlanActivityDetail | None

/** An edge in a CarePlanActivity connection page. */
export type CarePlanActivityEdge = Edge & {
  __typename?: 'CarePlanActivityEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CarePlanActivity object in this edge. */
  node?: Maybe<CarePlanActivity>
}

/** A care plan that is fulfilled in whole or in part by this care plan. */
export type CarePlanBasedOnResult =
  | CarePlan
  | ExternalResource
  | NonFederatedResource

/**
 * A page/slice of a list of CarePlanBasedOnResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CarePlanBasedOnResultConnection = Connection & {
  __typename?: 'CarePlanBasedOnResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CarePlanBasedOnResultEdge>
  /** List of CarePlanBasedOnResult object nodes in the current connection page. */
  nodes: Array<Maybe<CarePlanBasedOnResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CarePlanBasedOnResult connection page. */
export type CarePlanBasedOnResultEdge = Edge & {
  __typename?: 'CarePlanBasedOnResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CarePlanBasedOnResult object in this edge. */
  node?: Maybe<CarePlanBasedOnResult>
}

/**  Details of the status of the care plan  */
export type CarePlanCarePlanStatusDetail = {
  __typename?: 'CarePlanCarePlanStatusDetail'
  /** Date the recorded status was most recently updated */
  effectiveDate?: Maybe<Scalars['FhirDateTime']>
  /** Date the recorded status ended and was replaced by another status */
  endDate?: Maybe<Scalars['FhirDateTime']>
  /** A detailed description of the status of the care plan as indicated from the source data */
  fromSource?: Maybe<Scalars['FhirCode']>
  /** Free text comments about the status */
  notes?: Maybe<Scalars['String']>
  /** Details of the status of the care plan */
  statusDetailCreatedBy?: Maybe<CarePlanCarePlanStatusDetailStatusDetailCreatedByResult>
  /** Details of the status of the care plan */
  statusDetailUpdatedBy?: Maybe<CarePlanCarePlanStatusDetailStatusDetailUpdatedByResult>
}

/**
 * A page/slice of a list of CarePlanCarePlanStatusDetail nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CarePlanCarePlanStatusDetailConnection = Connection & {
  __typename?: 'CarePlanCarePlanStatusDetailConnection'
  /** Edges in the current connection page. */
  edges: Array<CarePlanCarePlanStatusDetailEdge>
  /** List of CarePlanCarePlanStatusDetail object nodes in the current connection page. */
  nodes: Array<Maybe<CarePlanCarePlanStatusDetail>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CarePlanCarePlanStatusDetail connection page. */
export type CarePlanCarePlanStatusDetailEdge = Edge & {
  __typename?: 'CarePlanCarePlanStatusDetailEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CarePlanCarePlanStatusDetail object in this edge. */
  node?: Maybe<CarePlanCarePlanStatusDetail>
}

/** Details of the status of the care plan */
export type CarePlanCarePlanStatusDetailStatusDetailCreatedByResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

/** Details of the status of the care plan */
export type CarePlanCarePlanStatusDetailStatusDetailUpdatedByResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

/**
 * A page/slice of a list of CarePlan nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CarePlanConnection = Connection & {
  __typename?: 'CarePlanConnection'
  /** Edges in the current connection page. */
  edges: Array<CarePlanEdge>
  /** List of CarePlan object nodes in the current connection page. */
  nodes: Array<Maybe<CarePlan>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Identifies the individual(s) or organization who provided the contents of the care plan. */
export type CarePlanContributorResult =
  | CareTeam
  | Device
  | ExternalResource
  | HealthgraphRelatedPerson
  | NonFederatedResource
  | Organization
  | Patient
  | Practitioner
  | PractitionerRole

/**
 * A page/slice of a list of CarePlanContributorResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CarePlanContributorResultConnection = Connection & {
  __typename?: 'CarePlanContributorResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CarePlanContributorResultEdge>
  /** List of CarePlanContributorResult object nodes in the current connection page. */
  nodes: Array<Maybe<CarePlanContributorResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CarePlanContributorResult connection page. */
export type CarePlanContributorResultEdge = Edge & {
  __typename?: 'CarePlanContributorResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CarePlanContributorResult object in this edge. */
  node?: Maybe<CarePlanContributorResult>
}

/** An edge in a CarePlan connection page. */
export type CarePlanEdge = Edge & {
  __typename?: 'CarePlanEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CarePlan object in this edge. */
  node?: Maybe<CarePlan>
}

/** The Encounter during which this CarePlan was created or to which the creation of this record is tightly associated. */
export type CarePlanEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  Specifies a filter to apply to a list of CarePlan  */
export type CarePlanFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<CarePlanFilter>>
  /**
   * Filters on CarePlan.basedOn
   * Fulfills CarePlan
   */
  basedOnUri?: Maybe<ResourceUriFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on CarePlan.encounter
   * Encounter created as part of
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on CarePlan.goal
   * Desired outcome of plan
   */
  goalUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on CarePlan.identifier
   * External Ids for this plan
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on CarePlan.intent
   * proposal | plan | order | option
   */
  intent?: Maybe<CarePlanIntentFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<CarePlanFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<CarePlanFilter>>
  /**
   * Filters on CarePlan.subject.where(resolve() is Patient)
   * Who the care plan is for
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on CarePlan.status
   * draft | active | on-hold | revoked | completed | entered-in-error | unknown
   */
  status?: Maybe<RequestStatusFilter>
  /**
   * Filters on CarePlan.subject
   * Who the care plan is for
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** Describes the intended objective(s) of carrying out the care plan. */
export type CarePlanGoalResult = ExternalResource | Goal | NonFederatedResource

/**
 * A page/slice of a list of CarePlanGoalResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CarePlanGoalResultConnection = Connection & {
  __typename?: 'CarePlanGoalResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CarePlanGoalResultEdge>
  /** List of CarePlanGoalResult object nodes in the current connection page. */
  nodes: Array<Maybe<CarePlanGoalResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CarePlanGoalResult connection page. */
export type CarePlanGoalResultEdge = Edge & {
  __typename?: 'CarePlanGoalResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CarePlanGoalResult object in this edge. */
  node?: Maybe<CarePlanGoalResult>
}

/**  Codes indicating the degree of authority/intentionality associated with a care plan.  */
export enum CarePlanIntent {
  /**
   *  The request represents a component or option for a RequestGroup that
   * establishes timing, conditionality and/or other constraints among a set of
   * requests.  Refer to [[[RequestGroup]]] for additional information on how this
   * status is used.
   */
  Option = 'OPTION',
  /**  The request represents a request/demand and authorization for action by a Practitioner.  */
  Order = 'ORDER',
  /**  The request represents an intention to ensure something occurs without providing an authorization for others to act.  */
  Plan = 'PLAN',
  /**
   *  The request is a suggestion made by someone/something that does not have an
   * intention to ensure it occurs and without providing an authorization to act.
   */
  Proposal = 'PROPOSAL',
}

/**  Codes indicating the degree of authority/intentionality associated with a care plan.  */
export type CarePlanIntentFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<CarePlanIntent>>>
}

export type CarePlanResult =
  | AuthorizationError
  | CarePlan
  | ResourceNotFoundError

export type CarePlansByIdentifierResult =
  | AuthorizationError
  | CarePlanConnection

/**  A sort on CarePlan - consists of the field to sort on and the ordering  */
export type CarePlanSort = {
  /**  Which field to sort by  */
  field: CarePlanSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that CarePlan can be sorted by  */
export enum CarePlanSortOption {
  /**  Do not sort  */
  None = 'NONE',
}

/** Identifies the patient or group whose intended care is described by the plan. */
export type CarePlanSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**
 *  The Care Team includes all the people and organizations who plan to participate
 * in the coordination and delivery of care for a patient.
 */
export type CareTeam = HealthgraphResource &
  Resource & {
    __typename?: 'CareTeam'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Business identifiers assigned to this care team by the performer or other
     * systems which remain constant as the resource is updated and propagates from
     * server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** A label for human use intended to distinguish like teams.  E.g. the "red" vs. "green" trauma teams. */
    name?: Maybe<Scalars['String']>
    /** Identifies all people and organizations who are expected to be involved in the care team. */
    participants?: Maybe<CareTeamParticipantConnection>
    /** Identifies the patient or group whose intended care is handled by the team. */
    subject?: Maybe<CareTeamSubjectResult>
  }

/**
 *  The Care Team includes all the people and organizations who plan to participate
 * in the coordination and delivery of care for a patient.
 */
export type CareTeamIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  The Care Team includes all the people and organizations who plan to participate
 * in the coordination and delivery of care for a patient.
 */
export type CareTeamParticipantsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of CareTeam nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CareTeamConnection = Connection & {
  __typename?: 'CareTeamConnection'
  /** Edges in the current connection page. */
  edges: Array<CareTeamEdge>
  /** List of CareTeam object nodes in the current connection page. */
  nodes: Array<Maybe<CareTeam>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CareTeam connection page. */
export type CareTeamEdge = Edge & {
  __typename?: 'CareTeamEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CareTeam object in this edge. */
  node?: Maybe<CareTeam>
}

/**  Specifies a filter to apply to a list of CareTeam  */
export type CareTeamFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<CareTeamFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on CareTeam.identifier
   * External Ids for this team
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<CareTeamFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<CareTeamFilter>>
  /**
   * Filters on CareTeam.participant.member
   * Who is involved
   */
  participantUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on CareTeam.subject.where(resolve() is Patient)
   * Who care team is for
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on CareTeam.subject
   * Who care team is for
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/**  Identifies all people and organizations who are expected to be involved in the care team.  */
export type CareTeamParticipant = {
  __typename?: 'CareTeamParticipant'
  /** The specific person or organization who is participating/expected to participate in the care team. */
  member?: Maybe<CareTeamParticipantMemberResult>
  /** The organization of the practitioner. */
  onBehalfOf?: Maybe<CareTeamParticipantOnBehalfOfResult>
  /**
   * Indicates specific responsibility of an individual within the care team, such
   * as "Primary care physician", "Trained social worker counselor", "Caregiver", etc.
   */
  roles?: Maybe<CodeableConceptConnection>
}

/**  Identifies all people and organizations who are expected to be involved in the care team.  */
export type CareTeamParticipantRolesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of CareTeamParticipant nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CareTeamParticipantConnection = Connection & {
  __typename?: 'CareTeamParticipantConnection'
  /** Edges in the current connection page. */
  edges: Array<CareTeamParticipantEdge>
  /** List of CareTeamParticipant object nodes in the current connection page. */
  nodes: Array<Maybe<CareTeamParticipant>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CareTeamParticipant connection page. */
export type CareTeamParticipantEdge = Edge & {
  __typename?: 'CareTeamParticipantEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CareTeamParticipant object in this edge. */
  node?: Maybe<CareTeamParticipant>
}

/** The specific person or organization who is participating/expected to participate in the care team. */
export type CareTeamParticipantMemberResult =
  | CareTeam
  | ExternalResource
  | HealthgraphRelatedPerson
  | None
  | NonFederatedResource
  | Organization
  | Patient
  | Practitioner
  | PractitionerRole

/** The organization of the practitioner. */
export type CareTeamParticipantOnBehalfOfResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Organization

export type CareTeamResult =
  | AuthorizationError
  | CareTeam
  | ResourceNotFoundError

export type CareTeamsByIdentifierResult =
  | AuthorizationError
  | CareTeamConnection

/**  A sort on CareTeam - consists of the field to sort on and the ordering  */
export type CareTeamSort = {
  /**  Which field to sort by  */
  field: CareTeamSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that CareTeam can be sorted by  */
export enum CareTeamSortOption {
  /**  Do not sort  */
  None = 'NONE',
}

/** Identifies the patient or group whose intended care is handled by the team. */
export type CareTeamSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/** Conversation communication with descriptive fields. */
export type ChatConversation = Resource & {
  __typename?: 'ChatConversation'
  /** The list of events that have occurred as part of the conversation. */
  events: ConversationEventConnection
  /** The ID of the chat conversation. */
  id: Scalars['ID']
  /** The list of messages sent as part of the conversation. */
  messages: ConversationMessageConnection
  /** The list of participants taking part in the conversation. */
  participants: ConversationParticipantResultConnection
  /** The time at which a conversation was first started. */
  startedAt: Scalars['DateTime']
  /** The current status of the conversation. */
  status: ConversationState
}

/** Edge for conversation hub communication. */
export type ChatConversationEdge = Edge & {
  __typename?: 'ChatConversationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the chat-conversation object in this edge. */
  node?: Maybe<ChatConversation>
}

/** Connection for conversation hub communication. */
export type ChatConversationResult = ChatConversation | ResourceNotFoundError

/**
 * # ChatInitiateConversationInput
 * Input type to be used when initiating a conversation.
 */
export type ChatInitiateConversationInput = {
  /** stringified json of the config, when starting conversation in the test mode. */
  configOverrides?: Maybe<Scalars['String']>
  /** Context for start conversation */
  data?: Maybe<ConversationData>
  /** List of entities linked to the conversation */
  links?: Maybe<Array<ConversationLinkInput>>
  /** conversation type, e.g. 'healthcheck' */
  type: Scalars['String']
}

/** Information about details of action message, the view and arbitrary data metadata */
export type ChatPlatformAppActionMessageDatum = {
  __typename?: 'ChatPlatformAppActionMessageDatum'
  /** Parameters for the view */
  parameters?: Maybe<Array<Maybe<ChatPlatformRecord>>>
  /** View to be shown to the user */
  view: ChatPlatformAppviewActionView
}

/** Contains information about screen where user will be send after executing an action */
export enum ChatPlatformAppviewActionView {
  /** go to book consulation screen */
  BookConsultation = 'BOOK_CONSULTATION',
  /** go to booking tell us more screen */
  BookingTellUsMore = 'BOOKING_TELL_US_MORE',
  /** go to booking text screen */
  BookingText = 'BOOKING_TEXT',
  /** go to booking we recommend screen */
  BookingWeRecommend = 'BOOKING_WE_RECOMMEND',
  /** start clinical chat */
  ClinicalChat = 'CLINICAL_CHAT',
  /** end conversation */
  EndConversation = 'END_CONVERSATION',
  /** go to healtcheck section */
  Healthcheck = 'HEALTHCHECK',
  /** go to monitor section */
  Monitor = 'MONITOR',
  /** show monitor covid care plan */
  MonitorCovidCarePlan = 'MONITOR_COVID_CARE_PLAN',
  /** show setup covid plan reminders field */
  SetupCovidPlanReminders = 'SETUP_COVID_PLAN_REMINDERS',
  /** start different conversation */
  StartConversation = 'START_CONVERSATION',
}

/** List of choices selected by user */
export type ChatPlatformChoicesInputMessageValue = {
  /** all choices selected by user */
  choices?: Maybe<Array<ChatPlatformInputChoice>>
}

/** Choice option selected by user */
export type ChatPlatformInputChoice = {
  /** additional payload for choice */
  data?: Maybe<ChoicesInputMessage>
  /** id of choice input */
  id: Scalars['ID']
  /** type of the choice anseer */
  type?: Maybe<ChatPlatformInputChoiceType>
}

/** Location, if user respond with location value */
export enum ChatPlatformInputChoiceType {
  /** answer to the choice contains location */
  Location = 'LOCATION',
  /** standard choice */
  Text = 'TEXT',
}

/** Key, Value structure used for generic message datum parameters */
export type ChatPlatformRecord = {
  __typename?: 'ChatPlatformRecord'
  /** key of the record */
  key: Scalars['String']
  /** value of the record */
  value: Scalars['String']
}

/** A name of a human broken into its constituent parts and usage information. */
export type CheckPatientRegistrationDetailsHumanName = {
  __typename?: 'CheckPatientRegistrationDetailsHumanName'
  /** Whether family name (often called 'Surname') matches. */
  isFamilyMatching?: Maybe<Scalars['Boolean']>
  /** Whether first given name matches. */
  isGivenFirstMatching?: Maybe<Scalars['Boolean']>
}

/** A name of a human broken into its constituent parts and usage information. */
export type CheckPatientRegistrationDetailsHumanNameInput = {
  /** Family name (often called 'Surname') */
  family: Scalars['String']
  /** Given names (not always 'first'). Includes middle names. */
  given: Array<Scalars['String']>
}

/** Details for registration to check. */
export type CheckPatientRegistrationDetailsInput = {
  /**
   * The primary name for the patient (if provided).
   * Additional names can be added using the `addPatientName` mutation.
   */
  primaryName: CheckPatientRegistrationDetailsHumanNameInput
  /** Authorisation token for a user's account (if provided). The email for checking will be extracted from the token. */
  token: Scalars['Jwt']
}

/** Patient registration details check result. */
export type CheckPatientRegistrationDetailsOutcome = {
  __typename?: 'CheckPatientRegistrationDetailsOutcome'
  /** Whether the email address matches. */
  isEmailAddressMatching?: Maybe<Scalars['Boolean']>
  /** Whether the primary name for the patient (if provided) matches. */
  primaryName?: Maybe<CheckPatientRegistrationDetailsHumanName>
}

export type CheckPatientRegistrationDetailsResult = CheckPatientRegistrationDetailsOutcome

/** Contains information regarding the choices made on a card */
export type ChoiceMessageInput = {
  __typename?: 'ChoiceMessageInput'
  /** action executed after user selects a choice */
  action?: Maybe<ActionMessageValue>
  /** unique id of the choice */
  id: Scalars['ID']
  /** semantic id of the choice */
  label: Scalars['String']
  /** can the choice be selected with other choice */
  multiselect: Scalars['Boolean']
  /** additional information about the choice */
  params?: Maybe<ChoiceMessageInputParams>
  /** is the choice preselected */
  preselected: Scalars['Boolean']
}

/** Contains information regarding the location referred to in chat */
export type ChoiceMessageInputLocationParams = {
  __typename?: 'ChoiceMessageInputLocationParams'
  /** additional description */
  additionalText?: Maybe<Scalars['String']>
  /** type of the location */
  target: ChoiceMessageInputLocationTarget
}

/** Possible locations */
export enum ChoiceMessageInputLocationTarget {
  /** eye causaulty */
  EyeCasualty = 'eyeCasualty',
  /** hospital */
  Hospital = 'hospital',
  /** pharmacy */
  Pharmacy = 'pharmacy',
  /** clinic */
  StdClinic = 'stdClinic',
}

export type ChoiceMessageInputParams =
  | ChoiceMessageInputLocationParams
  | ChoiceMessageInputTextParams

/** Text of choice in message */
export type ChoiceMessageInputTextParams = {
  __typename?: 'ChoiceMessageInputTextParams'
  /** text of the choice */
  text: Scalars['String']
}

/** Member input message for choices */
export type ChoicesInputMessage = {
  /** location content of the choice */
  location?: Maybe<Array<Maybe<LocationChoiceInputMessage>>>
  /** text content of choice */
  text?: Maybe<Array<Maybe<TextChoiceInputMessage>>>
}

/** All choice options */
export type ChoicesMessageInput = SuggestableInput & {
  __typename?: 'ChoicesMessageInput'
  /** possible choices for the user to select */
  choices: Array<ChoiceMessageInput>
  /** if true, suggestion will be shown to the user */
  suggest?: Maybe<Scalars['Boolean']>
}

/**
 *  A provider issued list of professional services and products which have been
 * provided, or are to be provided, to a patient which is sent to an insurer for
 * reimbursement.
 */
export type Claim = HealthgraphResource &
  Resource & {
    __typename?: 'Claim'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * negotiated rate between the payer and the facility (provider). Allowed amount
     * is the full amount, in contrast to the paid amount which takes out the
     * deductible, co-pay etc.
     */
    allowedAmount?: Maybe<ClaimAllowedAmountResult>
    /** The attending provider's specialty */
    attendingProviderTaxonomy?: Maybe<ClaimAttendingProviderTaxonomyResult>
    /** The period for which charges are being submitted. */
    billablePeriod?: Maybe<ClaimBillablePeriodResult>
    /** The date this resource was created. */
    created?: Maybe<Scalars['FhirDateTime']>
    /** Information about diagnoses relevant to the claim items. */
    diagnoses?: Maybe<ClaimDiagnosisConnection>
    /** An identifier for the location to which the patient was discharged as a result f the services in this claim */
    dischargeLocationID?: Maybe<Scalars['String']>
    /** Individual who created the claim, predetermination or preauthorization. */
    enterer?: Maybe<ClaimEntererResult>
    /** Facility where the services were provided. */
    facility?: Maybe<ClaimFacilityResult>
    /** An identifier for the file that this claim was received under */
    fileDataExtractId?: Maybe<ClaimFileDataExtractIdResult>
    /** The post period */
    fileDataPostPeriod?: Maybe<Scalars['FhirDate']>
    /** The date when the claim was reported */
    fileDataReported?: Maybe<Scalars['FhirDate']>
    /** Total IBNR amount for the claim */
    ibnr?: Maybe<Scalars['String']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** A unique identifier assigned to this claim. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Financial instruments for reimbursement for the health care products and services specified on the claim. */
    insurances?: Maybe<ClaimInsuranceConnection>
    /**
     * A claim line. Either a simple  product or service or a 'group' of details
     * which can each be a simple items or groups of sub-details.
     */
    items?: Maybe<ClaimItemConnection>
    /** The medical cost category for this claim */
    medicalCostCategory?: Maybe<Scalars['String']>
    /** <<<ADD DESCRIPTION HERE>>> */
    medicationDispense?: Maybe<ClaimMedicationDispenseResult>
    /** The month in which the member became eligible */
    memberEligibilityMonth?: Maybe<Scalars['FhirDate']>
    /**
     * The party to whom the professional services and/or products have been supplied
     * or are being considered and for whom actual or forecast reimbursement is sought.
     */
    patient?: Maybe<ClaimPatientResult>
    /** The party to be reimbursed for cost of the products and services according to the terms of the policy. */
    payee?: Maybe<ClaimPayeeResult>
    /** A description of the plan that this claim was paid under */
    planDesc?: Maybe<Scalars['String']>
    /** The entity identifier of the plan that this claim was paid under */
    planEntityId?: Maybe<HealthgraphIdentifierConnection>
    /** The detail of the prescription that this claim relates to */
    prescriptionDetail?: Maybe<ClaimPrescriptionDetailResult>
    /** The provider-required urgency of processing the request. Typical values include: stat, routine deferred. */
    priority?: Maybe<ClaimPriorityResult>
    /** Procedures performed on the patient relevant to the billing items with the claim. */
    procedures?: Maybe<ClaimProcedureConnection>
    /** The provider which is responsible for the claim, predetermination or preauthorization. */
    provider?: Maybe<ClaimProviderResult>
    /**
     * Other claims which are related to this claim such as prior submissions or
     * claims for related services or for the same event.
     */
    relateds?: Maybe<ClaimRelatedConnection>
    /** The status of the resource instance. */
    status?: Maybe<Scalars['FhirCode']>
    /**
     * A finer grained suite of claim type codes which may convey additional
     * information such as Inpatient vs Outpatient and/or a specialty service.
     */
    subType?: Maybe<ClaimSubTypeResult>
    /**
     * Additional information codes regarding exceptions, special considerations, the
     * condition, situation, prior or concurrent issues.
     */
    supportingInfos?: Maybe<ClaimSupportingInfoConnection>
    /** The total value of the all the items in the claim. */
    total?: Maybe<ClaimTotalResult>
    /** The category of claim, e.g. oral, pharmacy, vision, institutional, professional. */
    type?: Maybe<ClaimTypeResult>
    /**
     * A code to indicate whether the nature of the request is: to request
     * adjudication of products and services previously rendered; or requesting
     * authorization and adjudication for provision in the future; or requesting the
     * non-binding adjudication of the listed products and services which could be
     * provided in the future.
     */
    use?: Maybe<Scalars['FhirCode']>
  }

/**
 *  A provider issued list of professional services and products which have been
 * provided, or are to be provided, to a patient which is sent to an insurer for
 * reimbursement.
 */
export type ClaimDiagnosesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A provider issued list of professional services and products which have been
 * provided, or are to be provided, to a patient which is sent to an insurer for
 * reimbursement.
 */
export type ClaimIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A provider issued list of professional services and products which have been
 * provided, or are to be provided, to a patient which is sent to an insurer for
 * reimbursement.
 */
export type ClaimInsurancesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A provider issued list of professional services and products which have been
 * provided, or are to be provided, to a patient which is sent to an insurer for
 * reimbursement.
 */
export type ClaimItemsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A provider issued list of professional services and products which have been
 * provided, or are to be provided, to a patient which is sent to an insurer for
 * reimbursement.
 */
export type ClaimPlanEntityIdArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A provider issued list of professional services and products which have been
 * provided, or are to be provided, to a patient which is sent to an insurer for
 * reimbursement.
 */
export type ClaimProceduresArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A provider issued list of professional services and products which have been
 * provided, or are to be provided, to a patient which is sent to an insurer for
 * reimbursement.
 */
export type ClaimRelatedsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A provider issued list of professional services and products which have been
 * provided, or are to be provided, to a patient which is sent to an insurer for
 * reimbursement.
 */
export type ClaimSupportingInfosArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * negotiated rate between the payer and the facility (provider). Allowed amount is
 * the full amount, in contrast to the paid amount which takes out the deductible, co-pay etc.
 */
export type ClaimAllowedAmountResult = Money | None

/** The attending provider's specialty */
export type ClaimAttendingProviderTaxonomyResult = Coding | None

/** The period for which charges are being submitted. */
export type ClaimBillablePeriodResult = None | Period

/**  The prescriber for the medication that this claim relates to  */
export type ClaimClaimPrescriber = {
  __typename?: 'ClaimClaimPrescriber'
  /** The name of the prescriber for the medication that this claim relates to */
  prescribingName?: Maybe<Scalars['String']>
  /** National Provider Identifier (NPI) of the prescriber for the medication that this claim relates to */
  prescribingNPI?: Maybe<Scalars['String']>
  /**
   * The code that represents the role of the prescriber for the medication that
   * this claim relates to. E.g. Family Medicine, Nurse Practitioner
   */
  prescribingTaxonomyCode?: Maybe<Scalars['String']>
  /**
   * A description of the role of the prescriber for the medication that this claim
   * relates to. E.g. Family Medicine, Nurse Practitioner
   */
  prescribingTaxonomyDescription?: Maybe<Scalars['String']>
}

/**
 * A page/slice of a list of ClaimClaimPrescriber nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimClaimPrescriberConnection = Connection & {
  __typename?: 'ClaimClaimPrescriberConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimClaimPrescriberEdge>
  /** List of ClaimClaimPrescriber object nodes in the current connection page. */
  nodes: Array<Maybe<ClaimClaimPrescriber>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClaimClaimPrescriber connection page. */
export type ClaimClaimPrescriberEdge = Edge & {
  __typename?: 'ClaimClaimPrescriberEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClaimClaimPrescriber object in this edge. */
  node?: Maybe<ClaimClaimPrescriber>
}

/**
 * A page/slice of a list of Claim nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimConnection = Connection & {
  __typename?: 'ClaimConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimEdge>
  /** List of Claim object nodes in the current connection page. */
  nodes: Array<Maybe<Claim>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**  Information about diagnoses relevant to the claim items.  */
export type ClaimDiagnosis = {
  __typename?: 'ClaimDiagnosis'
  /** The nature of illness or problem in a coded form or as a reference to an external defined Condition. */
  diagnosisReference?: Maybe<ClaimDiagnosisDiagnosisReferenceResult>
  /** Indication of whether the diagnosis was present on admission to a facility. */
  onAdmission?: Maybe<ClaimDiagnosisOnAdmissionResult>
  /**
   * A package billing code or bundle code used to group products and services to a
   * particular health condition (such as heart attack) which is based on a
   * predetermined grouping code system.
   */
  packageCode?: Maybe<ClaimDiagnosisPackageCodeResult>
  /** A number to uniquely identify diagnosis entries. */
  sequence?: Maybe<Scalars['PositiveInt']>
}

/**
 * A page/slice of a list of ClaimDiagnosis nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimDiagnosisConnection = Connection & {
  __typename?: 'ClaimDiagnosisConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimDiagnosisEdge>
  /** List of ClaimDiagnosis object nodes in the current connection page. */
  nodes: Array<Maybe<ClaimDiagnosis>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** The nature of illness or problem in a coded form or as a reference to an external defined Condition. */
export type ClaimDiagnosisDiagnosisReferenceResult =
  | Condition
  | ExternalResource
  | None
  | NonFederatedResource

/** An edge in a ClaimDiagnosis connection page. */
export type ClaimDiagnosisEdge = Edge & {
  __typename?: 'ClaimDiagnosisEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClaimDiagnosis object in this edge. */
  node?: Maybe<ClaimDiagnosis>
}

/** Indication of whether the diagnosis was present on admission to a facility. */
export type ClaimDiagnosisOnAdmissionResult = CodeableConcept | None

/**
 * A package billing code or bundle code used to group products and services to a
 * particular health condition (such as heart attack) which is based on a
 * predetermined grouping code system.
 */
export type ClaimDiagnosisPackageCodeResult = CodeableConcept | None

/** An edge in a Claim connection page. */
export type ClaimEdge = Edge & {
  __typename?: 'ClaimEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Claim object in this edge. */
  node?: Maybe<Claim>
}

/** Individual who created the claim, predetermination or preauthorization. */
export type ClaimEntererResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

/** Facility where the services were provided. */
export type ClaimFacilityResult =
  | ExternalResource
  | Location
  | None
  | NonFederatedResource

/** An identifier for the file that this claim was received under */
export type ClaimFileDataExtractIdResult = HealthgraphIdentifier | None

/**  Specifies a filter to apply to a list of Claim  */
export type ClaimFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<ClaimFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Claim.created
   * The creation date for the Claim
   */
  created?: Maybe<DateFilter>
  /**
   * Filters on Claim.item.encounter
   * Encounters associated with a billed line item
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Claim.enterer
   * The party responsible for the entry of the Claim
   */
  entererUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Claim.facility
   * Facility where the products or services have been or will be provided
   */
  facilityUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Claim.identifier
   * The primary identifier of the financial resource
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<ClaimFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<ClaimFilter>>
  /**
   * Filters on Claim.patient
   * Patient receiving the products or services
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Claim.priority
   * Processing priority requested
   */
  priority?: Maybe<CodeFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Filters on Claim.provider
   * Provider responsible for the Claim
   */
  providerUri?: Maybe<ResourceUriFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Claim.status
   * The status of the Claim instance.
   */
  status?: Maybe<FinancialResourceStatusCodeFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Claim.use
   * The kind of financial resource
   */
  use?: Maybe<UseFilter>
}

/**  Financial instruments for reimbursement for the health care products and services specified on the claim.  */
export type ClaimInsurance = {
  __typename?: 'ClaimInsurance'
  /**
   * Reference to the insurance card level information contained in the Coverage
   * resource. The coverage issuing insurer will use these details to locate the
   * patient's actual coverage within the insurer's information system.
   */
  coverage?: Maybe<ClaimInsuranceCoverageResult>
  /** The business identifier to be used when the claim is sent for adjudication against this insurance policy. */
  identifier?: Maybe<ClaimInsuranceIdentifierResult>
  /** A flag to indicate that this Coverage is to be used for adjudication of this claim when set to true. */
  isFocal?: Maybe<Scalars['Boolean']>
  /**
   * A number to uniquely identify insurance entries and provide a sequence of
   * coverages to convey coordination of benefit order.
   */
  sequence?: Maybe<Scalars['PositiveInt']>
}

/**
 * A page/slice of a list of ClaimInsurance nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimInsuranceConnection = Connection & {
  __typename?: 'ClaimInsuranceConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimInsuranceEdge>
  /** List of ClaimInsurance object nodes in the current connection page. */
  nodes: Array<Maybe<ClaimInsurance>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * Reference to the insurance card level information contained in the Coverage
 * resource. The coverage issuing insurer will use these details to locate the
 * patient's actual coverage within the insurer's information system.
 */
export type ClaimInsuranceCoverageResult =
  | Coverage
  | ExternalResource
  | None
  | NonFederatedResource

/** An edge in a ClaimInsurance connection page. */
export type ClaimInsuranceEdge = Edge & {
  __typename?: 'ClaimInsuranceEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClaimInsurance object in this edge. */
  node?: Maybe<ClaimInsurance>
}

/** The business identifier to be used when the claim is sent for adjudication against this insurance policy. */
export type ClaimInsuranceIdentifierResult = HealthgraphIdentifier | None

/**
 *  A claim line. Either a simple  product or service or a 'group' of details which
 * can each be a simple items or groups of sub-details.
 */
export type ClaimItem = {
  __typename?: 'ClaimItem'
  /** The Encounters during which this Claim was created or to which the creation of this record is tightly associated. */
  encounters?: Maybe<ClaimItemEncounterResultConnection>
  /** An indicator of whether the services in the claim are related to behavioural health */
  isBehaviouralHealthIndicator?: Maybe<Scalars['Boolean']>
  /**
   * Indicates whether this drug is included in the specified customers coverage or
   * formulary, as defined in plan specifications
   */
  isFormularyIndicator?: Maybe<Scalars['Boolean']>
  /** Where the product or service was provided. */
  locationCodeableConcept?: Maybe<ClaimItemLocationCodeableConceptResult>
  /** Item typification or modifiers codes to convey additional context for the product or service. */
  modifiers?: Maybe<CodeableConceptConnection>
  /** The quantity times the unit price for an additional service or product or charge. */
  net?: Maybe<ClaimItemNetResult>
  /**
   * When the value is a group code then this item collects a set of related claim
   * details, otherwise this contains the product, service, drug or other billing
   * code for the item.
   */
  productOrService?: Maybe<ClaimItemProductOrServiceResult>
  /** The number of repetitions of a service or product. */
  quantity?: Maybe<ClaimItemQuantityResult>
  /** The type of revenue or cost center providing the product and/or service. */
  revenue?: Maybe<ClaimItemRevenueResult>
  /** A number to uniquely identify item entries. */
  sequence?: Maybe<Scalars['PositiveInt']>
  /** The date or dates when the service or product was supplied, performed or completed. */
  servicedPeriod?: Maybe<ClaimItemServicedPeriodResult>
  /** A description of the type of treatment that is being undertaken as part of this claim */
  treatmentTypeDesc?: Maybe<Scalars['String']>
}

/**
 *  A claim line. Either a simple  product or service or a 'group' of details which
 * can each be a simple items or groups of sub-details.
 */
export type ClaimItemEncountersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A claim line. Either a simple  product or service or a 'group' of details which
 * can each be a simple items or groups of sub-details.
 */
export type ClaimItemModifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of ClaimItem nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimItemConnection = Connection & {
  __typename?: 'ClaimItemConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimItemEdge>
  /** List of ClaimItem object nodes in the current connection page. */
  nodes: Array<Maybe<ClaimItem>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClaimItem connection page. */
export type ClaimItemEdge = Edge & {
  __typename?: 'ClaimItemEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClaimItem object in this edge. */
  node?: Maybe<ClaimItem>
}

/** The Encounters during which this Claim was created or to which the creation of this record is tightly associated. */
export type ClaimItemEncounterResult =
  | Encounter
  | ExternalResource
  | NonFederatedResource

/**
 * A page/slice of a list of ClaimItemEncounterResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimItemEncounterResultConnection = Connection & {
  __typename?: 'ClaimItemEncounterResultConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimItemEncounterResultEdge>
  /** List of ClaimItemEncounterResult object nodes in the current connection page. */
  nodes: Array<Maybe<ClaimItemEncounterResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClaimItemEncounterResult connection page. */
export type ClaimItemEncounterResultEdge = Edge & {
  __typename?: 'ClaimItemEncounterResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClaimItemEncounterResult object in this edge. */
  node?: Maybe<ClaimItemEncounterResult>
}

/** Where the product or service was provided. */
export type ClaimItemLocationCodeableConceptResult = CodeableConcept | None

/** The quantity times the unit price for an additional service or product or charge. */
export type ClaimItemNetResult = Money | None

/**
 * When the value is a group code then this item collects a set of related claim
 * details, otherwise this contains the product, service, drug or other billing
 * code for the item.
 */
export type ClaimItemProductOrServiceResult = CodeableConcept | None

/** The number of repetitions of a service or product. */
export type ClaimItemQuantityResult = None | Quantity

/** The type of revenue or cost center providing the product and/or service. */
export type ClaimItemRevenueResult = CodeableConcept | None

/** The date or dates when the service or product was supplied, performed or completed. */
export type ClaimItemServicedPeriodResult = None | Period

/** <<<ADD DESCRIPTION HERE>>> */
export type ClaimMedicationDispenseResult =
  | ExternalResource
  | MedicationDispense
  | None
  | NonFederatedResource

/**
 * The party to whom the professional services and/or products have been supplied
 * or are being considered and for whom actual or forecast reimbursement is sought.
 */
export type ClaimPatientResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**  The party to be reimbursed for cost of the products and services according to the terms of the policy.  */
export type ClaimPayee = {
  __typename?: 'ClaimPayee'
  /** Type of Party to be reimbursed: subscriber, provider, other. */
  type?: Maybe<ClaimPayeeTypeResult>
}

/** The party to be reimbursed for cost of the products and services according to the terms of the policy. */
export type ClaimPayeeResult = ClaimPayee | None

/** Type of Party to be reimbursed: subscriber, provider, other. */
export type ClaimPayeeTypeResult = CodeableConcept | None

/**  The detail of the prescription that this claim relates to  */
export type ClaimPrescriptionDetail = {
  __typename?: 'ClaimPrescriptionDetail'
  /** The prescriber for the medication that this claim relates to */
  prescriber?: Maybe<ClaimPrescriptionDetailPrescriberResult>
  /** The date of the prescription that this claim relates to */
  prescriptionDate?: Maybe<Scalars['FhirDateTime']>
  /** The number that identifies the prescription that this claim relates to */
  prescriptionNBR?: Maybe<Scalars['String']>
  /** The number of refills that were authorised in the prescription that this claim relates to */
  refillsAuthorized?: Maybe<Scalars['Int']>
}

/**
 * A page/slice of a list of ClaimPrescriptionDetail nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimPrescriptionDetailConnection = Connection & {
  __typename?: 'ClaimPrescriptionDetailConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimPrescriptionDetailEdge>
  /** List of ClaimPrescriptionDetail object nodes in the current connection page. */
  nodes: Array<Maybe<ClaimPrescriptionDetail>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClaimPrescriptionDetail connection page. */
export type ClaimPrescriptionDetailEdge = Edge & {
  __typename?: 'ClaimPrescriptionDetailEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClaimPrescriptionDetail object in this edge. */
  node?: Maybe<ClaimPrescriptionDetail>
}

/** The prescriber for the medication that this claim relates to */
export type ClaimPrescriptionDetailPrescriberResult =
  | ClaimClaimPrescriber
  | None

/** The detail of the prescription that this claim relates to */
export type ClaimPrescriptionDetailResult = ClaimPrescriptionDetail | None

/** The provider-required urgency of processing the request. Typical values include: stat, routine deferred. */
export type ClaimPriorityResult = CodeableConcept | None

/**  Procedures performed on the patient relevant to the billing items with the claim.  */
export type ClaimProcedure = {
  __typename?: 'ClaimProcedure'
  /** Date and optionally time the procedure was performed. */
  date?: Maybe<Scalars['FhirDateTime']>
  /** The code or reference to a Procedure resource which identifies the clinical intervention performed. */
  procedureReference?: Maybe<ClaimProcedureProcedureReferenceResult>
  /** A number to uniquely identify procedure entries. */
  sequence?: Maybe<Scalars['PositiveInt']>
}

/**
 * A page/slice of a list of ClaimProcedure nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimProcedureConnection = Connection & {
  __typename?: 'ClaimProcedureConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimProcedureEdge>
  /** List of ClaimProcedure object nodes in the current connection page. */
  nodes: Array<Maybe<ClaimProcedure>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClaimProcedure connection page. */
export type ClaimProcedureEdge = Edge & {
  __typename?: 'ClaimProcedureEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClaimProcedure object in this edge. */
  node?: Maybe<ClaimProcedure>
}

/** The code or reference to a Procedure resource which identifies the clinical intervention performed. */
export type ClaimProcedureProcedureReferenceResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Procedure

/** The provider which is responsible for the claim, predetermination or preauthorization. */
export type ClaimProviderResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Organization
  | Practitioner

/**
 *  Other claims which are related to this claim such as prior submissions or claims
 * for related services or for the same event.
 */
export type ClaimRelated = {
  __typename?: 'ClaimRelated'
  /** Reference to a related claim. */
  claim?: Maybe<ClaimRelatedClaimResult>
  /** An alternate organizational reference to the case or file to which this particular claim pertains. */
  reference?: Maybe<ClaimRelatedReferenceResult>
  /** A code to convey how the claims are related. */
  relationship?: Maybe<ClaimRelatedRelationshipResult>
}

/** Reference to a related claim. */
export type ClaimRelatedClaimResult =
  | Claim
  | ExternalResource
  | None
  | NonFederatedResource

/**
 * A page/slice of a list of ClaimRelated nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimRelatedConnection = Connection & {
  __typename?: 'ClaimRelatedConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimRelatedEdge>
  /** List of ClaimRelated object nodes in the current connection page. */
  nodes: Array<Maybe<ClaimRelated>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClaimRelated connection page. */
export type ClaimRelatedEdge = Edge & {
  __typename?: 'ClaimRelatedEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClaimRelated object in this edge. */
  node?: Maybe<ClaimRelated>
}

/** An alternate organizational reference to the case or file to which this particular claim pertains. */
export type ClaimRelatedReferenceResult = HealthgraphIdentifier | None

/** A code to convey how the claims are related. */
export type ClaimRelatedRelationshipResult = CodeableConcept | None

export type ClaimResult = AuthorizationError | Claim | ResourceNotFoundError

export type ClaimsByIdentifierResult = AuthorizationError | ClaimConnection

/**  A sort on Claim - consists of the field to sort on and the ordering  */
export type ClaimSort = {
  /**  Which field to sort by  */
  field: ClaimSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Claim can be sorted by  */
export enum ClaimSortOption {
  /**
   * Sorts on Claim.created
   * The creation date for the Claim
   */
  Created = 'CREATED',
  /**  Do not sort  */
  None = 'NONE',
}

/**
 * A finer grained suite of claim type codes which may convey additional
 * information such as Inpatient vs Outpatient and/or a specialty service.
 */
export type ClaimSubTypeResult = CodeableConcept | None

/**
 *  Additional information codes regarding exceptions, special considerations, the
 * condition, situation, prior or concurrent issues.
 */
export type ClaimSupportingInfo = {
  __typename?: 'ClaimSupportingInfo'
  /** The general class of the information supplied: information; exception; accident, employment; onset, etc. */
  category?: Maybe<ClaimSupportingInfoCategoryResult>
  /**
   * System and code pertaining to the specific information regarding special
   * conditions relating to the setting, treatment or patient  for which care is sought.
   */
  code?: Maybe<ClaimSupportingInfoCodeResult>
  /** A number to uniquely identify supporting information entries. */
  sequence?: Maybe<Scalars['PositiveInt']>
  /** The date when or period to which this information refers. */
  timingDate?: Maybe<Scalars['FhirDate']>
  /**
   * Additional data or information such as resources, documents, images etc.
   * including references to the data or the actual inclusion of the data.
   */
  valueString?: Maybe<Scalars['String']>
}

/** The general class of the information supplied: information; exception; accident, employment; onset, etc. */
export type ClaimSupportingInfoCategoryResult = CodeableConcept | None

/**
 * System and code pertaining to the specific information regarding special
 * conditions relating to the setting, treatment or patient  for which care is sought.
 */
export type ClaimSupportingInfoCodeResult = CodeableConcept | None

/**
 * A page/slice of a list of ClaimSupportingInfo nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClaimSupportingInfoConnection = Connection & {
  __typename?: 'ClaimSupportingInfoConnection'
  /** Edges in the current connection page. */
  edges: Array<ClaimSupportingInfoEdge>
  /** List of ClaimSupportingInfo object nodes in the current connection page. */
  nodes: Array<Maybe<ClaimSupportingInfo>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClaimSupportingInfo connection page. */
export type ClaimSupportingInfoEdge = Edge & {
  __typename?: 'ClaimSupportingInfoEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClaimSupportingInfo object in this edge. */
  node?: Maybe<ClaimSupportingInfo>
}

/** The total value of the all the items in the claim. */
export type ClaimTotalResult = Money | None

/** The category of claim, e.g. oral, pharmacy, vision, institutional, professional. */
export type ClaimTypeResult = CodeableConcept | None

/**
 *  A record of a clinical assessment performed to determine what problem(s) may
 * affect the patient and before planning the treatments or management strategies
 * that are best to manage a patient's condition. Assessments are often 1:1 with a
 * clinical consultation / encounter,  but this varies greatly depending on the
 * clinical workflow. This resource is called "ClinicalImpression" rather than
 * "ClinicalAssessment" to avoid confusion with the recording of assessment tools
 * such as Apgar score.
 */
export type ClinicalImpression = HealthgraphResource &
  Resource & {
    __typename?: 'ClinicalImpression'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The clinician performing the assessment. */
    assessor?: Maybe<ClinicalImpressionAssessorResult>
    /** Categorizes the type of clinical assessment performed. */
    code?: Maybe<ClinicalImpressionCodeResult>
    /**
     * A summary of the context and/or cause of the assessment - why / where it was
     * performed, and what patient events/status prompted it.
     */
    description?: Maybe<Scalars['String']>
    /** The point in time or period over which the subject was assessed. */
    effectiveDateTime?: Maybe<Scalars['FhirDateTime']>
    /** Specific findings or diagnoses that were considered likely or relevant to ongoing treatment. */
    findings?: Maybe<ClinicalImpressionFindingConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Business identifiers assigned to this clinical impression by the performer or
     * other systems which remain constant as the resource is updated and propagates
     * from server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Identifies the workflow status of the assessment. */
    status?: Maybe<Scalars['FhirCode']>
    /** The patient or group of individuals assessed as part of this record. */
    subject?: Maybe<ClinicalImpressionSubjectResult>
    /** Information supporting the clinical impression. */
    supportingInfos?: Maybe<ClinicalImpressionSupportingInfoResultConnection>
  }

/**
 *  A record of a clinical assessment performed to determine what problem(s) may
 * affect the patient and before planning the treatments or management strategies
 * that are best to manage a patient's condition. Assessments are often 1:1 with a
 * clinical consultation / encounter,  but this varies greatly depending on the
 * clinical workflow. This resource is called "ClinicalImpression" rather than
 * "ClinicalAssessment" to avoid confusion with the recording of assessment tools
 * such as Apgar score.
 */
export type ClinicalImpressionFindingsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A record of a clinical assessment performed to determine what problem(s) may
 * affect the patient and before planning the treatments or management strategies
 * that are best to manage a patient's condition. Assessments are often 1:1 with a
 * clinical consultation / encounter,  but this varies greatly depending on the
 * clinical workflow. This resource is called "ClinicalImpression" rather than
 * "ClinicalAssessment" to avoid confusion with the recording of assessment tools
 * such as Apgar score.
 */
export type ClinicalImpressionIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A record of a clinical assessment performed to determine what problem(s) may
 * affect the patient and before planning the treatments or management strategies
 * that are best to manage a patient's condition. Assessments are often 1:1 with a
 * clinical consultation / encounter,  but this varies greatly depending on the
 * clinical workflow. This resource is called "ClinicalImpression" rather than
 * "ClinicalAssessment" to avoid confusion with the recording of assessment tools
 * such as Apgar score.
 */
export type ClinicalImpressionSupportingInfosArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The clinician performing the assessment. */
export type ClinicalImpressionAssessorResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

/** Categorizes the type of clinical assessment performed. */
export type ClinicalImpressionCodeResult = CodeableConcept | None

/**
 * A page/slice of a list of ClinicalImpression nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClinicalImpressionConnection = Connection & {
  __typename?: 'ClinicalImpressionConnection'
  /** Edges in the current connection page. */
  edges: Array<ClinicalImpressionEdge>
  /** List of ClinicalImpression object nodes in the current connection page. */
  nodes: Array<Maybe<ClinicalImpression>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClinicalImpression connection page. */
export type ClinicalImpressionEdge = Edge & {
  __typename?: 'ClinicalImpressionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClinicalImpression object in this edge. */
  node?: Maybe<ClinicalImpression>
}

/**  Specific findings or diagnoses that were considered likely or relevant to ongoing treatment.  */
export type ClinicalImpressionFinding = {
  __typename?: 'ClinicalImpressionFinding'
  /** Specific text or code for finding or diagnosis, which may include ruled-out or resolved conditions. */
  itemCodeableConcept?: Maybe<ClinicalImpressionFindingItemCodeableConceptResult>
}

/**
 * A page/slice of a list of ClinicalImpressionFinding nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClinicalImpressionFindingConnection = Connection & {
  __typename?: 'ClinicalImpressionFindingConnection'
  /** Edges in the current connection page. */
  edges: Array<ClinicalImpressionFindingEdge>
  /** List of ClinicalImpressionFinding object nodes in the current connection page. */
  nodes: Array<Maybe<ClinicalImpressionFinding>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClinicalImpressionFinding connection page. */
export type ClinicalImpressionFindingEdge = Edge & {
  __typename?: 'ClinicalImpressionFindingEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClinicalImpressionFinding object in this edge. */
  node?: Maybe<ClinicalImpressionFinding>
}

/** Specific text or code for finding or diagnosis, which may include ruled-out or resolved conditions. */
export type ClinicalImpressionFindingItemCodeableConceptResult =
  | CodeableConcept
  | None

export type ClinicalImpressionResult =
  | AuthorizationError
  | ClinicalImpression
  | ResourceNotFoundError

export type ClinicalImpressionsByIdentifierResult =
  | AuthorizationError
  | ClinicalImpressionConnection

/**  Codes that reflect the current state of a clinical impression within its overall lifecycle.  */
export enum ClinicalImpressionStatus {
  /**  The event has now concluded.  */
  Completed = 'COMPLETED',
  /**
   *  This electronic record should never have existed, though it is possible that
   * real-world decisions were based on it.  (If real-world activity has occurred,
   * the status should be "stopped" rather than "entered-in-error".).
   */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**  The event is currently occurring.  */
  InProgress = 'IN_PROGRESS',
}

/**  Codes that reflect the current state of a clinical impression within its overall lifecycle.  */
export type ClinicalImpressionStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<ClinicalImpressionStatus>>>
}

/** The patient or group of individuals assessed as part of this record. */
export type ClinicalImpressionSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/** Information supporting the clinical impression. */
export type ClinicalImpressionSupportingInfoResult =
  | ExternalResource
  | NonFederatedResource
  | QuestionnaireResponse

/**
 * A page/slice of a list of ClinicalImpressionSupportingInfoResult nodes, allowing
 * for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ClinicalImpressionSupportingInfoResultConnection = Connection & {
  __typename?: 'ClinicalImpressionSupportingInfoResultConnection'
  /** Edges in the current connection page. */
  edges: Array<ClinicalImpressionSupportingInfoResultEdge>
  /** List of ClinicalImpressionSupportingInfoResult object nodes in the current connection page. */
  nodes: Array<Maybe<ClinicalImpressionSupportingInfoResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ClinicalImpressionSupportingInfoResult connection page. */
export type ClinicalImpressionSupportingInfoResultEdge = Edge & {
  __typename?: 'ClinicalImpressionSupportingInfoResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ClinicalImpressionSupportingInfoResult object in this edge. */
  node?: Maybe<ClinicalImpressionSupportingInfoResult>
}

/**
 *  A CodeableConcept represents a value that is usually supplied by providing a
 * reference to one or more terminologies or ontologies but may also be defined by
 * the provision of text. This is a common pattern in healthcare data.
 */
export type CodeableConcept = {
  __typename?: 'CodeableConcept'
  /**  A reference to a code defined by a specific terminology system. */
  coding: CodingResult
  /** Codes defined by a terminology system. */
  codings?: Maybe<CodingConnection>
  /** The values needed to define the `CodeableConcept`. */
  conceptInfo: Scalars['CodeableConceptInfo']
  /** Plain text representation of the concept. */
  text?: Maybe<LocalizedString>
}

/**
 *  A CodeableConcept represents a value that is usually supplied by providing a
 * reference to one or more terminologies or ontologies but may also be defined by
 * the provision of text. This is a common pattern in healthcare data.
 */
export type CodeableConceptCodingArgs = {
  input: CodeableConceptCodingInput
}

/**
 *  A CodeableConcept represents a value that is usually supplied by providing a
 * reference to one or more terminologies or ontologies but may also be defined by
 * the provision of text. This is a common pattern in healthcare data.
 */
export type CodeableConceptCodingsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The coding system to translate a coding into, and optionally the concept-map to use for this purpose. */
export type CodeableConceptCodingInput = {
  conceptMap?: Maybe<Scalars['Uri']>
  system: Scalars['Uri']
}

/**
 * A page/slice of a list of CodeableConcept nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CodeableConceptConnection = Connection & {
  __typename?: 'CodeableConceptConnection'
  /** Edges in the current connection page. */
  edges: Array<CodeableConceptEdge>
  /** List of CodeableConcept object nodes in the current connection page. */
  nodes: Array<Maybe<CodeableConcept>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CodeableConcept connection page. */
export type CodeableConceptEdge = Edge & {
  __typename?: 'CodeableConceptEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CodeableConcept object in this edge. */
  node?: Maybe<CodeableConcept>
}

/**
 * A localizable codeable concept.
 * Conforms to https://www.hl7.org/fhir/datatypes.html#CodeableConcept, except codings is plural instead of singular.
 */
export type CodeableConceptInput = {
  /** Codes defined by a terminology system. */
  codings: Array<CodingInput>
  /** The plain text representation of the concept in a format that can be converted into a `LocalizedString` */
  text: Scalars['LocalizationContext']
}

/**
 * Used to filter a coded field.
 *
 * Only one of `ONE_OF` and `MATCHES_ECL_QUERY` can be set in the filter.
 */
export type CodeFilter = {
  /**  The field must have a code matching the given expression constraint */
  MATCHES_ECL_QUERY?: Maybe<Scalars['ExpressionConstraint']>
  /**  The field must have a code matching at least one of the given options */
  ONE_OF?: Maybe<Array<CodeFilterOption>>
}

/**
 * An option for what a Code can be. It is permissible to provide a system or a code or both.
 * It is generally preferable to provide both to maximise precision.
 * If the mapping-code-search feature flag is enabled and both system and code are provided,
 * the search will be extended to include mapping codes as reported by fast-ct.
 */
export type CodeFilterOption = {
  /**
   *  A symbol in syntax defined by the system. The symbol may be a predefined code
   * or an expression in a syntax defined by the coding system
   */
  code?: Maybe<Scalars['FhirCode']>
  /**  The identification of the code system that defines the meaning of the symbol in the code. */
  system?: Maybe<Scalars['Uri']>
}

/** Used to filter a coded field */
export type CodeSearch = {
  /** The field must have a code matching at least one of the given options */
  oneOf?: Maybe<Array<Maybe<CodeSearchOption>>>
}

/**
 * An option for what a Code can be. It is permissible to provide a system or a code or both.
 *
 * It is generally preferable to provide both to maximise precision.
 */
export type CodeSearchOption = {
  /**
   * A symbol in syntax defined by the system. The symbol may be a predefined code
   * or an expression in a syntax defined by the coding system
   */
  code?: Maybe<Scalars['FhirCode']>
  /** The identification of the code system that defines the meaning of the symbol in the code. */
  system?: Maybe<Scalars['Uri']>
}

/**
 * A Coding is a representation of a defined concept using a symbol from a defined "code system".
 *
 * See [Coding](https://www.hl7.org/fhir/datatypes.html#Coding) for guidance on how to use.
 */
export type Coding = {
  __typename?: 'Coding'
  /**
   * A symbol in syntax defined by the system. The symbol may be a predefined code
   * or an expression in a syntax defined by the coding system (e.g.
   * post-coordination).
   */
  code?: Maybe<Scalars['FhirCode']>
  /** A representation of the meaning of the code in the system, following the rules of the system. */
  display?: Maybe<LocalizedString>
  /** The identification of the code system that defines the meaning of the symbol in the code. */
  system?: Maybe<Scalars['Uri']>
}

/**
 * A page/slice of a list of nodes of type `Coding`, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CodingConnection = Connection & {
  __typename?: 'CodingConnection'
  /** Edges in the current connection page. */
  edges: Array<CodingEdge>
  /** List of Coding object nodes in the current connection page. */
  nodes: Array<Maybe<Coding>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An edge in a connection page for a node of type `Coding`.
 *
 * See the [GraphQL Edges Specification](https://relay.dev/graphql/connections.htm#sec-Edges) for guidance on how to use.
 */
export type CodingEdge = Edge & {
  __typename?: 'CodingEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<Coding>
}

/**
 * A localizable coding input.
 * Conforms to a subset of https://www.hl7.org/fhir/datatypes.html#Coding
 */
export type CodingInput = {
  /** A symbol in the syntax defined by the terminology system. */
  code: Scalars['FhirCode']
  /** A plain text representation in a format that can be converted into a `LocalizedString`. */
  display?: Maybe<Scalars['LocalizationContext']>
  /** The identity of the terminology system. */
  system: Scalars['Uri']
}

/** A code defined by a terminology system. */
export type CodingResult = Coding | None

/**
 *  Basic is used for handling concepts not yet defined in FHIR, narrative-only
 * resources that don't map to an existing resource, and custom resources not
 * appropriate for inclusion in the FHIR specification.
 */
export type CohortMember = HealthgraphResource &
  Resource & {
    __typename?: 'CohortMember'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** Identifies the 'type' of resource - equivalent to the resource name for other resources. */
    code?: Maybe<CohortMemberCodeResult>
    /** <<<ADD DESCRIPTION HERE>>> */
    cohort?: Maybe<CohortMemberCohortResult>
    /** The time at which the cohort was created */
    created?: Maybe<Scalars['FhirDateTime']>
    /** The id of the cohort definition */
    definitionId?: Maybe<Scalars['Uuid']>
    /** <<<ADD DESCRIPTION HERE>>> */
    definitionVersion?: Maybe<CohortMemberDefinitionVersionResult>
    /** After this time the patient should not be considered part of this cohort/segment */
    expires?: Maybe<Scalars['FhirDateTime']>
    /** Extracted field for more details about the results of a query */
    extractedField?: Maybe<CohortMemberCohortExtractedFieldConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Identifier assigned to the resource for business purposes, outside the context of FHIR. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Count of how many times the member appears in the Cohort definition query */
    incidenceCount?: Maybe<Scalars['NonNegativeInt']>
    /** Was the patient in the latest version of this cohort (also check expires) */
    isActive?: Maybe<Scalars['Boolean']>
    /** Hash of the latest changes related to the patient membership to the Cohort */
    latestChanges?: Maybe<Scalars['String']>
    /** <<<ADD DESCRIPTION HERE>>> */
    memberCohortHistories?: Maybe<CohortMemberMemberCohortHistoryResultConnection>
    /** String label for the cohort */
    name?: Maybe<Scalars['String']>
    /**
     * Short name at most 26 chars containing only lowercase alphanumeric characters
     * and hyphen. More stable than name and is effectively immutable if present.
     */
    shortName?: Maybe<Scalars['String']>
    /** Identifies the patient, practitioner, device or any other resource that is the "focus" of this resource. */
    subject?: Maybe<CohortMemberSubjectResult>
    /** Total number of patients in the Cohort group */
    totalActiveMembers?: Maybe<Scalars['NonNegativeInt']>
    /** Total number of patients changed as a result of this cohort run (in or out) */
    totalAffectedMembers?: Maybe<Scalars['NonNegativeInt']>
  }

/**
 *  Basic is used for handling concepts not yet defined in FHIR, narrative-only
 * resources that don't map to an existing resource, and custom resources not
 * appropriate for inclusion in the FHIR specification.
 */
export type CohortMemberExtractedFieldArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Basic is used for handling concepts not yet defined in FHIR, narrative-only
 * resources that don't map to an existing resource, and custom resources not
 * appropriate for inclusion in the FHIR specification.
 */
export type CohortMemberIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Basic is used for handling concepts not yet defined in FHIR, narrative-only
 * resources that don't map to an existing resource, and custom resources not
 * appropriate for inclusion in the FHIR specification.
 */
export type CohortMemberMemberCohortHistoriesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Identifies the 'type' of resource - equivalent to the resource name for other resources. */
export type CohortMemberCodeResult = CodeableConcept | None

/**  Extracted field for more details about the results of a query  */
export type CohortMemberCohortExtractedField = {
  __typename?: 'CohortMemberCohortExtractedField'
  /** extracted boolean value */
  isValueBoolean?: Maybe<BooleanConnection>
  /** field name, e.g.: param.code.as-keyword */
  name?: Maybe<Scalars['String']>
  /** extracted value */
  value?: Maybe<StringConnection>
  /** extracted date time value */
  valueDateTime?: Maybe<FhirDateTimeConnection>
  /** extracted numeric value */
  valueDecimal?: Maybe<FloatConnection>
}

/**  Extracted field for more details about the results of a query  */
export type CohortMemberCohortExtractedFieldIsValueBooleanArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Extracted field for more details about the results of a query  */
export type CohortMemberCohortExtractedFieldValueArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Extracted field for more details about the results of a query  */
export type CohortMemberCohortExtractedFieldValueDateTimeArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Extracted field for more details about the results of a query  */
export type CohortMemberCohortExtractedFieldValueDecimalArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of CohortMemberCohortExtractedField nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CohortMemberCohortExtractedFieldConnection = Connection & {
  __typename?: 'CohortMemberCohortExtractedFieldConnection'
  /** Edges in the current connection page. */
  edges: Array<CohortMemberCohortExtractedFieldEdge>
  /** List of CohortMemberCohortExtractedField object nodes in the current connection page. */
  nodes: Array<Maybe<CohortMemberCohortExtractedField>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CohortMemberCohortExtractedField connection page. */
export type CohortMemberCohortExtractedFieldEdge = Edge & {
  __typename?: 'CohortMemberCohortExtractedFieldEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CohortMemberCohortExtractedField object in this edge. */
  node?: Maybe<CohortMemberCohortExtractedField>
}

/** <<<ADD DESCRIPTION HERE>>> */
export type CohortMemberCohortResult =
  | ExternalResource
  | Group
  | None
  | NonFederatedResource

/**
 * A page/slice of a list of CohortMember nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CohortMemberConnection = Connection & {
  __typename?: 'CohortMemberConnection'
  /** Edges in the current connection page. */
  edges: Array<CohortMemberEdge>
  /** List of CohortMember object nodes in the current connection page. */
  nodes: Array<Maybe<CohortMember>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** <<<ADD DESCRIPTION HERE>>> */
export type CohortMemberDefinitionVersionResult =
  | ExternalResource
  | Group
  | None
  | NonFederatedResource

/** An edge in a CohortMember connection page. */
export type CohortMemberEdge = Edge & {
  __typename?: 'CohortMemberEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CohortMember object in this edge. */
  node?: Maybe<CohortMember>
}

/**  Specifies a filter to apply to a list of CohortMember  */
export type CohortMemberFilter = {
  /**
   * Filters on Basic.extension('https://fhir.bbl.health/StructureDefinition/BblCohortMemberActive')
   * Was the patient in the latest version of this cohort (also check expires)
   */
  _active?: Maybe<BooleanFilter>
  /**
   * Filters on Basic.extension('https://fhir.bbl.health/StructureDefinition/BblCohortDefinitionId')
   * The id of the cohort definition
   */
  _definitionId?: Maybe<KeywordFilter>
  /**
   * Filters on Basic.extension('https://fhir.bbl.health/StructureDefinition/BblCohortShortName')
   * Short name at most 26 chars containing only lowercase alphanumeric characters
   * and hyphen. More stable than name and is effectively immutable if present
   */
  _shortName?: Maybe<StringFilter>
  /**
   * Filters on Basic.meta.tag
   * Tags for organising the cohorts
   */
  _tags?: Maybe<KeywordFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<CohortMemberFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<CohortMemberFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<CohortMemberFilter>>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** <<<ADD DESCRIPTION HERE>>> */
export type CohortMemberMemberCohortHistoryResult =
  | ExternalResource
  | Group
  | NonFederatedResource

/**
 * A page/slice of a list of CohortMemberMemberCohortHistoryResult nodes, allowing
 * for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CohortMemberMemberCohortHistoryResultConnection = Connection & {
  __typename?: 'CohortMemberMemberCohortHistoryResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CohortMemberMemberCohortHistoryResultEdge>
  /** List of CohortMemberMemberCohortHistoryResult object nodes in the current connection page. */
  nodes: Array<Maybe<CohortMemberMemberCohortHistoryResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CohortMemberMemberCohortHistoryResult connection page. */
export type CohortMemberMemberCohortHistoryResultEdge = Edge & {
  __typename?: 'CohortMemberMemberCohortHistoryResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CohortMemberMemberCohortHistoryResult object in this edge. */
  node?: Maybe<CohortMemberMemberCohortHistoryResult>
}

export type CohortMemberResult =
  | AuthorizationError
  | CohortMember
  | ResourceNotFoundError

export type CohortMembersByIdentifierResult =
  | AuthorizationError
  | CohortMemberConnection

/**  A sort on CohortMember - consists of the field to sort on and the ordering  */
export type CohortMemberSort = {
  /**  Which field to sort by  */
  field: CohortMemberSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that CohortMember can be sorted by  */
export enum CohortMemberSortOption {
  /**  Do not sort  */
  None = 'NONE',
}

/** Identifies the patient, practitioner, device or any other resource that is the "focus" of this resource. */
export type CohortMemberSubjectResult =
  | Appointment
  | CarePlan
  | CareTeam
  | Claim
  | ClinicalImpression
  | Communication
  | CommunicationRequest
  | Composition
  | Condition
  | Coverage
  | Device
  | DiagnosticReport
  | DocumentReference
  | Encounter
  | ExternalResource
  | Goal
  | Group
  | HealthcareService
  | HealthgraphRelatedPerson
  | InsurancePlan
  | Location
  | Measure
  | MeasureReport
  | Media
  | MedicationDispense
  | MedicationRequest
  | MedicationStatement
  | None
  | NonFederatedResource
  | Observation
  | Organization
  | Patient
  | Practitioner
  | PractitionerRole
  | Procedure
  | Questionnaire
  | QuestionnaireResponse
  | RequestGroup
  | RiskAssessment
  | ServiceRequest

/**
 *  An occurrence of information being transmitted; e.g. an alert that was sent to a
 * responsible provider, a public health agency that was notified about a
 * reportable condition.
 */
export type Communication = HealthgraphResource &
  Resource & {
    __typename?: 'Communication'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The type of message conveyed such as alert, notification, reminder, instruction, etc. */
    categories?: Maybe<CodeableConceptConnection>
    /** The Encounter during which this Communication was created or to which the creation of this record is tightly associated. */
    encounter?: Maybe<CommunicationEncounterResult>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Business identifiers assigned to this communication by the performer or other
     * systems which remain constant as the resource is updated and propagates from
     * server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** The base language in which the resource is written. */
    language?: Maybe<Scalars['FhirCode']>
    /** Text, attachment(s), or resource(s) that was communicated to the recipient. */
    payloads?: Maybe<CommunicationPayloadConnection>
    /**
     * The entity (e.g. person, organization, clinical information system, care team
     * or device) which was the target of the communication. If receipts need to be
     * tracked by an individual, a separate resource instance will need to be created
     * for each recipient.  Multiple recipient communications are intended where
     * either receipts are not tracked (e.g. a mass mail-out) or a receipt is
     * captured in aggregate (all emails confirmed received by a particular time).
     */
    recipients?: Maybe<CommunicationRecipientResultConnection>
    /** The entity (e.g. person, organization, clinical information system, or device) which was the source of the communication. */
    sender?: Maybe<CommunicationSenderResult>
    /** The time when this communication was sent. */
    sent?: Maybe<Scalars['FhirDateTime']>
    /** The status of the transmission. */
    status?: Maybe<Scalars['FhirCode']>
    /** The patient or group that was the focus of this communication. */
    subject?: Maybe<CommunicationSubjectResult>
    /** Description of the purpose/content, similar to a subject line in an email. */
    topic?: Maybe<CommunicationTopicResult>
  }

/**
 *  An occurrence of information being transmitted; e.g. an alert that was sent to a
 * responsible provider, a public health agency that was notified about a
 * reportable condition.
 */
export type CommunicationCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An occurrence of information being transmitted; e.g. an alert that was sent to a
 * responsible provider, a public health agency that was notified about a
 * reportable condition.
 */
export type CommunicationIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An occurrence of information being transmitted; e.g. an alert that was sent to a
 * responsible provider, a public health agency that was notified about a
 * reportable condition.
 */
export type CommunicationPayloadsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An occurrence of information being transmitted; e.g. an alert that was sent to a
 * responsible provider, a public health agency that was notified about a
 * reportable condition.
 */
export type CommunicationRecipientsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of Communication nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CommunicationConnection = Connection & {
  __typename?: 'CommunicationConnection'
  /** Edges in the current connection page. */
  edges: Array<CommunicationEdge>
  /** List of Communication object nodes in the current connection page. */
  nodes: Array<Maybe<Communication>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Communication connection page. */
export type CommunicationEdge = Edge & {
  __typename?: 'CommunicationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Communication object in this edge. */
  node?: Maybe<Communication>
}

/** The Encounter during which this Communication was created or to which the creation of this record is tightly associated. */
export type CommunicationEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  Specifies a filter to apply to a list of Communication  */
export type CommunicationFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<CommunicationFilter>>
  /**
   * Filters on Communication.category
   * Message category
   */
  category?: Maybe<CodeFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Communication.encounter
   * Encounter created as part of
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Communication.identifier
   * Unique identifier
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<CommunicationFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<CommunicationFilter>>
  /**
   * Filters on Communication.subject.where(resolve() is Patient)
   * Focus of message
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Filters on Communication.recipient
   * Message recipient
   */
  recipientUri?: Maybe<ResourceUriFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Communication.sender
   * Message sender
   */
  senderUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Communication.sent
   * When sent
   */
  sent?: Maybe<DateFilter>
  /**
   * Filters on Communication.status
   * preparation | in-progress | not-done | on-hold | stopped | completed | entered-in-error | unknown
   */
  status?: Maybe<EventStatusFilter>
  /**
   * Filters on Communication.subject
   * Focus of message
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/**  Text, attachment(s), or resource(s) that was communicated to the recipient.  */
export type CommunicationPayload = {
  __typename?: 'CommunicationPayload'
  /** Babylon Extension to Communication to allow the payload to be a CodeableConcept */
  contentCodeableConcept?: Maybe<CommunicationPayloadContentCodeableConceptResult>
  /** A communicated content (or for multi-part communications, one portion of the communication). */
  contentString?: Maybe<Scalars['String']>
}

/**
 * A page/slice of a list of CommunicationPayload nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CommunicationPayloadConnection = Connection & {
  __typename?: 'CommunicationPayloadConnection'
  /** Edges in the current connection page. */
  edges: Array<CommunicationPayloadEdge>
  /** List of CommunicationPayload object nodes in the current connection page. */
  nodes: Array<Maybe<CommunicationPayload>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Babylon Extension to Communication to allow the payload to be a CodeableConcept */
export type CommunicationPayloadContentCodeableConceptResult =
  | CodeableConcept
  | None

/** An edge in a CommunicationPayload connection page. */
export type CommunicationPayloadEdge = Edge & {
  __typename?: 'CommunicationPayloadEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CommunicationPayload object in this edge. */
  node?: Maybe<CommunicationPayload>
}

/**
 * The entity (e.g. person, organization, clinical information system, care team or
 * device) which was the target of the communication. If receipts need to be
 * tracked by an individual, a separate resource instance will need to be created
 * for each recipient.  Multiple recipient communications are intended where either
 * receipts are not tracked (e.g. a mass mail-out) or a receipt is captured in
 * aggregate (all emails confirmed received by a particular time).
 */
export type CommunicationRecipientResult =
  | ExternalResource
  | NonFederatedResource
  | Patient

/**
 * A page/slice of a list of CommunicationRecipientResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CommunicationRecipientResultConnection = Connection & {
  __typename?: 'CommunicationRecipientResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CommunicationRecipientResultEdge>
  /** List of CommunicationRecipientResult object nodes in the current connection page. */
  nodes: Array<Maybe<CommunicationRecipientResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CommunicationRecipientResult connection page. */
export type CommunicationRecipientResultEdge = Edge & {
  __typename?: 'CommunicationRecipientResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CommunicationRecipientResult object in this edge. */
  node?: Maybe<CommunicationRecipientResult>
}

/**
 *  A request to convey information; e.g. the CDS system proposes that an alert be
 * sent to a responsible provider, the CDS system proposes that the public health
 * agency be notified about a reportable condition.
 */
export type CommunicationRequest = HealthgraphResource &
  Resource & {
    __typename?: 'CommunicationRequest'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Business identifiers assigned to this communication request by the performer
     * or other systems which remain constant as the resource is updated and
     * propagates from server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Describes why the request is being made in coded or textual form. */
    reasonCodes?: Maybe<CodeableConceptConnection>
    /** Indicates another resource whose existence justifies this request. */
    reasonReferences?: Maybe<CommunicationRequestReasonReferenceResultConnection>
    /**
     * The entity (e.g. person, organization, clinical information system, or device)
     * which is to be the source of the communication.
     */
    sender?: Maybe<CommunicationRequestSenderResult>
    /** The status of the proposal or order. */
    status?: Maybe<Scalars['FhirCode']>
    /** The patient or group that is the focus of this communication request. */
    subject?: Maybe<CommunicationRequestSubjectResult>
  }

/**
 *  A request to convey information; e.g. the CDS system proposes that an alert be
 * sent to a responsible provider, the CDS system proposes that the public health
 * agency be notified about a reportable condition.
 */
export type CommunicationRequestIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A request to convey information; e.g. the CDS system proposes that an alert be
 * sent to a responsible provider, the CDS system proposes that the public health
 * agency be notified about a reportable condition.
 */
export type CommunicationRequestReasonCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A request to convey information; e.g. the CDS system proposes that an alert be
 * sent to a responsible provider, the CDS system proposes that the public health
 * agency be notified about a reportable condition.
 */
export type CommunicationRequestReasonReferencesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of CommunicationRequest nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CommunicationRequestConnection = Connection & {
  __typename?: 'CommunicationRequestConnection'
  /** Edges in the current connection page. */
  edges: Array<CommunicationRequestEdge>
  /** List of CommunicationRequest object nodes in the current connection page. */
  nodes: Array<Maybe<CommunicationRequest>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CommunicationRequest connection page. */
export type CommunicationRequestEdge = Edge & {
  __typename?: 'CommunicationRequestEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CommunicationRequest object in this edge. */
  node?: Maybe<CommunicationRequest>
}

/** Indicates another resource whose existence justifies this request. */
export type CommunicationRequestReasonReferenceResult =
  | Condition
  | ExternalResource
  | NonFederatedResource
  | Observation

/**
 * A page/slice of a list of CommunicationRequestReasonReferenceResult nodes,
 * allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CommunicationRequestReasonReferenceResultConnection = Connection & {
  __typename?: 'CommunicationRequestReasonReferenceResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CommunicationRequestReasonReferenceResultEdge>
  /** List of CommunicationRequestReasonReferenceResult object nodes in the current connection page. */
  nodes: Array<Maybe<CommunicationRequestReasonReferenceResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CommunicationRequestReasonReferenceResult connection page. */
export type CommunicationRequestReasonReferenceResultEdge = Edge & {
  __typename?: 'CommunicationRequestReasonReferenceResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CommunicationRequestReasonReferenceResult object in this edge. */
  node?: Maybe<CommunicationRequestReasonReferenceResult>
}

export type CommunicationRequestResult =
  | AuthorizationError
  | CommunicationRequest
  | ResourceNotFoundError

export type CommunicationRequestsByIdentifierResult =
  | AuthorizationError
  | CommunicationRequestConnection

/**
 * The entity (e.g. person, organization, clinical information system, or device)
 * which is to be the source of the communication.
 */
export type CommunicationRequestSenderResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

/** The patient or group that is the focus of this communication request. */
export type CommunicationRequestSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

export type CommunicationResult =
  | AuthorizationError
  | Communication
  | ResourceNotFoundError

export type CommunicationsByIdentifierResult =
  | AuthorizationError
  | CommunicationConnection

/** The entity (e.g. person, organization, clinical information system, or device) which was the source of the communication. */
export type CommunicationSenderResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

/**  A sort on Communication - consists of the field to sort on and the ordering  */
export type CommunicationSort = {
  /**  Which field to sort by  */
  field: CommunicationSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Communication can be sorted by  */
export enum CommunicationSortOption {
  /**  Do not sort  */
  None = 'NONE',
  /**
   * Sorts on Communication.sent
   * When sent
   */
  Sent = 'SENT',
}

/** The patient or group that was the focus of this communication. */
export type CommunicationSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/** Description of the purpose/content, similar to a subject line in an email. */
export type CommunicationTopicResult = CodeableConcept | None

/** Input for the complete action mutation */
export type CompleteAppointmentActionInput = {
  /** ID of the appointment action */
  actionId: Scalars['ID']
}

export type CompleteAppointmentActionResult =
  | AppointmentAction
  | AuthorizationError
  | ResourceNotFoundError

/** Input required to complete a journey */
export type CompleteDataCollectionJourneyStageInput = {
  /** Token to confirm stage completion */
  completionToken: Scalars['String']
  /** Data Collection Journey ID */
  journeyId: Scalars['ID']
  /** Id of Patient going through the Data Collection Journey */
  patientId: Scalars['ID']
}

export type CompleteDataCollectionJourneyStageResult =
  | DataCollectionJourney
  | ResourceNotFoundError

/** Input for CompleteUserChallengeAction mutation */
export type CompleteUserChallengeActionInput = {
  /** The user challenge action id to complete */
  userChallengeActionId: Scalars['ID']
}

/** `CompleteUserChallengeActionResult` is a union for one of the following types. */
export type CompleteUserChallengeActionResult =
  | AuthorizationError
  | ResourceNotFoundError
  | UserChallenge

/**
 *  A set of healthcare-related information that is assembled together into a single
 * logical package that provides a single coherent statement of meaning,
 * establishes its own context and that has clinical attestation with regard to who
 * is making the statement. A Composition defines the structure and narrative
 * content necessary for a document. However, a Composition alone does not
 * constitute a document. Rather, the Composition must be the first entry in a
 * Bundle where Bundle.type=document, and any other resources referenced from
 * Composition must be included as subsequent entries in the Bundle (for example
 * Patient, Practitioner, Encounter, etc.).
 */
export type Composition = HealthgraphResource &
  Resource & {
    __typename?: 'Composition'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** Identifies who is responsible for the information in the composition, not necessarily who typed it in. */
    authors?: Maybe<CompositionAuthorResultConnection>
    /** The composition editing time, when the composition was last logically changed by the author. */
    date?: Maybe<Scalars['FhirDateTime']>
    /** the date of a patient's last appointment (if they've had one) */
    dateOfLastAppointment?: Maybe<Scalars['FhirDateTime']>
    /** Describes the clinical encounter or type of care this documentation is associated with. */
    encounter?: Maybe<CompositionEncounterResult>
    /** The clinical service, such as a colonoscopy or an appendectomy, being documented. */
    events?: Maybe<CompositionEventConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * A version-independent identifier for the Composition. This identifier stays
     * constant as the composition is changed over time.
     */
    identifier?: Maybe<CompositionIdentifierResult>
    /** The root of the sections that make up the composition. */
    sections?: Maybe<CompositionSectionConnection>
    /** The workflow/clinical status of this composition. The status is a marker for the clinical standing of the document. */
    status?: Maybe<Scalars['FhirCode']>
    /**
     * Who or what the composition is about. The composition can be about a person,
     * (patient or healthcare practitioner), a device (e.g. a machine) or even a
     * group of subjects (such as a document about a herd of livestock, or a set of
     * patients that share a common exposure).
     */
    subject?: Maybe<CompositionSubjectResult>
    /** Official human-readable label for the composition. */
    title?: Maybe<Scalars['String']>
    /**
     * Specifies the particular kind of composition (e.g. History and Physical,
     * Discharge Summary, Progress Note). This usually equates to the purpose of
     * making the composition.
     */
    type?: Maybe<CompositionTypeResult>
  }

/**
 *  A set of healthcare-related information that is assembled together into a single
 * logical package that provides a single coherent statement of meaning,
 * establishes its own context and that has clinical attestation with regard to who
 * is making the statement. A Composition defines the structure and narrative
 * content necessary for a document. However, a Composition alone does not
 * constitute a document. Rather, the Composition must be the first entry in a
 * Bundle where Bundle.type=document, and any other resources referenced from
 * Composition must be included as subsequent entries in the Bundle (for example
 * Patient, Practitioner, Encounter, etc.).
 */
export type CompositionAuthorsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A set of healthcare-related information that is assembled together into a single
 * logical package that provides a single coherent statement of meaning,
 * establishes its own context and that has clinical attestation with regard to who
 * is making the statement. A Composition defines the structure and narrative
 * content necessary for a document. However, a Composition alone does not
 * constitute a document. Rather, the Composition must be the first entry in a
 * Bundle where Bundle.type=document, and any other resources referenced from
 * Composition must be included as subsequent entries in the Bundle (for example
 * Patient, Practitioner, Encounter, etc.).
 */
export type CompositionEventsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A set of healthcare-related information that is assembled together into a single
 * logical package that provides a single coherent statement of meaning,
 * establishes its own context and that has clinical attestation with regard to who
 * is making the statement. A Composition defines the structure and narrative
 * content necessary for a document. However, a Composition alone does not
 * constitute a document. Rather, the Composition must be the first entry in a
 * Bundle where Bundle.type=document, and any other resources referenced from
 * Composition must be included as subsequent entries in the Bundle (for example
 * Patient, Practitioner, Encounter, etc.).
 */
export type CompositionSectionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Identifies who is responsible for the information in the composition, not necessarily who typed it in. */
export type CompositionAuthorResult =
  | ExternalResource
  | NonFederatedResource
  | Practitioner

/**
 * A page/slice of a list of CompositionAuthorResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CompositionAuthorResultConnection = Connection & {
  __typename?: 'CompositionAuthorResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CompositionAuthorResultEdge>
  /** List of CompositionAuthorResult object nodes in the current connection page. */
  nodes: Array<Maybe<CompositionAuthorResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CompositionAuthorResult connection page. */
export type CompositionAuthorResultEdge = Edge & {
  __typename?: 'CompositionAuthorResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CompositionAuthorResult object in this edge. */
  node?: Maybe<CompositionAuthorResult>
}

/**
 * A page/slice of a list of Composition nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CompositionConnection = Connection & {
  __typename?: 'CompositionConnection'
  /** Edges in the current connection page. */
  edges: Array<CompositionEdge>
  /** List of Composition object nodes in the current connection page. */
  nodes: Array<Maybe<Composition>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Composition connection page. */
export type CompositionEdge = Edge & {
  __typename?: 'CompositionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Composition object in this edge. */
  node?: Maybe<Composition>
}

/** Describes the clinical encounter or type of care this documentation is associated with. */
export type CompositionEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  The clinical service, such as a colonoscopy or an appendectomy, being documented.  */
export type CompositionEvent = {
  __typename?: 'CompositionEvent'
  /**
   * This list of codes represents the main clinical acts, such as a colonoscopy or
   * an appendectomy, being documented. In some cases, the event is inherent in the
   * typeCode, such as a "History and Physical Report" in which the procedure being
   * documented is necessarily a "History and Physical" act.
   */
  codes?: Maybe<CodeableConceptConnection>
  /**
   * The description and/or reference of the event(s) being documented. For
   * example, this could be used to document such a colonoscopy or an appendectomy.
   */
  details?: Maybe<CompositionEventDetailResultConnection>
  /**
   * The period of time covered by the documentation. There is no assertion that
   * the documentation is a complete representation for this period, only that it
   * documents events during this time.
   */
  period?: Maybe<CompositionEventPeriodResult>
}

/**  The clinical service, such as a colonoscopy or an appendectomy, being documented.  */
export type CompositionEventCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  The clinical service, such as a colonoscopy or an appendectomy, being documented.  */
export type CompositionEventDetailsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of CompositionEvent nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CompositionEventConnection = Connection & {
  __typename?: 'CompositionEventConnection'
  /** Edges in the current connection page. */
  edges: Array<CompositionEventEdge>
  /** List of CompositionEvent object nodes in the current connection page. */
  nodes: Array<Maybe<CompositionEvent>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * The description and/or reference of the event(s) being documented. For example,
 * this could be used to document such a colonoscopy or an appendectomy.
 */
export type CompositionEventDetailResult =
  | Appointment
  | CarePlan
  | CareTeam
  | Claim
  | ClinicalImpression
  | Communication
  | CommunicationRequest
  | Composition
  | Condition
  | Coverage
  | Device
  | DiagnosticReport
  | DocumentReference
  | Encounter
  | ExternalResource
  | Goal
  | Group
  | HealthcareService
  | HealthgraphRelatedPerson
  | InsurancePlan
  | Location
  | Measure
  | MeasureReport
  | Media
  | MedicationDispense
  | MedicationRequest
  | MedicationStatement
  | NonFederatedResource
  | Observation
  | Organization
  | Patient
  | Practitioner
  | PractitionerRole
  | Procedure
  | Questionnaire
  | QuestionnaireResponse
  | RequestGroup
  | RiskAssessment
  | ServiceRequest

/**
 * A page/slice of a list of CompositionEventDetailResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CompositionEventDetailResultConnection = Connection & {
  __typename?: 'CompositionEventDetailResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CompositionEventDetailResultEdge>
  /** List of CompositionEventDetailResult object nodes in the current connection page. */
  nodes: Array<Maybe<CompositionEventDetailResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CompositionEventDetailResult connection page. */
export type CompositionEventDetailResultEdge = Edge & {
  __typename?: 'CompositionEventDetailResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CompositionEventDetailResult object in this edge. */
  node?: Maybe<CompositionEventDetailResult>
}

/** An edge in a CompositionEvent connection page. */
export type CompositionEventEdge = Edge & {
  __typename?: 'CompositionEventEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CompositionEvent object in this edge. */
  node?: Maybe<CompositionEvent>
}

/**
 * The period of time covered by the documentation. There is no assertion that the
 * documentation is a complete representation for this period, only that it
 * documents events during this time.
 */
export type CompositionEventPeriodResult = None | Period

/**  Specifies a filter to apply to a list of Composition  */
export type CompositionFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<CompositionFilter>>
  /**
   * Filters on Composition.author
   * Who and/or what authored the composition
   */
  authorUri?: Maybe<ResourceUriFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Composition.event.code
   * Code(s) that apply to the event being documented
   */
  context?: Maybe<CodeFilter>
  /**
   * Filters on Composition.date
   * Composition editing time
   */
  date?: Maybe<DateFilter>
  /**
   * Filters on Composition.encounter
   * Context of the Composition
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Composition.section.entry
   * A reference to data that supports this section
   */
  entryUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Composition.identifier
   * Version-independent identifier for the Composition
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<CompositionFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<CompositionFilter>>
  /**
   * Filters on Composition.subject.where(resolve() is Patient)
   * Who and/or what the composition is about
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Composition.event.period
   * The period covered by the documentation
   */
  period?: Maybe<DateFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Composition.section.code
   * Classification of section (recommended)
   */
  section?: Maybe<CodeFilter>
  /**
   * Filters on Composition.status
   * preliminary | final | amended | entered-in-error
   */
  status?: Maybe<CompositionStatusFilter>
  /**
   * Filters on Composition.subject
   * Who and/or what the composition is about
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Composition.title
   * Human Readable name/title
   */
  title?: Maybe<StringFilter>
  /**
   * Filters on Composition.type
   * Kind of composition (LOINC if possible)
   */
  type?: Maybe<CodeFilter>
}

/**
 * A version-independent identifier for the Composition. This identifier stays
 * constant as the composition is changed over time.
 */
export type CompositionIdentifierResult = HealthgraphIdentifier | None

export type CompositionResult =
  | AuthorizationError
  | Composition
  | ResourceNotFoundError

export type CompositionsByIdentifierResult =
  | AuthorizationError
  | CompositionConnection

/**  The root of the sections that make up the composition.  */
export type CompositionSection = {
  __typename?: 'CompositionSection'
  /** The latest time this section of notes was updated */
  authored?: Maybe<Scalars['FhirDateTime']>
  /** Identifies who is responsible for the information in this section, not necessarily who typed it in. */
  authors?: Maybe<CompositionSectionAuthorResultConnection>
  /** A code identifying the kind of content contained within the section. This must be consistent with the section title. */
  code?: Maybe<CompositionSectionCodeResult>
  /** A reference to the actual resource from which the narrative in the section is derived. */
  entries?: Maybe<CompositionSectionEntryResultConnection>
  /** A nested sub-section within this section. */
  sections?: Maybe<CompositionSectionConnection>
  /**
   * A human-readable narrative that contains the attested content of the section,
   * used to represent the content of the resource to a human. The narrative need
   * not encode all the structured data, but is required to contain sufficient
   * detail to make it "clinically safe" for a human to just read the narrative.
   */
  text?: Maybe<CompositionSectionTextResult>
  /**
   * The label for this particular section.  This will be part of the rendered
   * content for the document, and is often used to build a table of contents.
   */
  title?: Maybe<Scalars['String']>
}

/**  The root of the sections that make up the composition.  */
export type CompositionSectionAuthorsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  The root of the sections that make up the composition.  */
export type CompositionSectionEntriesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  The root of the sections that make up the composition.  */
export type CompositionSectionSectionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Identifies who is responsible for the information in this section, not necessarily who typed it in. */
export type CompositionSectionAuthorResult =
  | ExternalResource
  | NonFederatedResource
  | Practitioner

/**
 * A page/slice of a list of CompositionSectionAuthorResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CompositionSectionAuthorResultConnection = Connection & {
  __typename?: 'CompositionSectionAuthorResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CompositionSectionAuthorResultEdge>
  /** List of CompositionSectionAuthorResult object nodes in the current connection page. */
  nodes: Array<Maybe<CompositionSectionAuthorResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CompositionSectionAuthorResult connection page. */
export type CompositionSectionAuthorResultEdge = Edge & {
  __typename?: 'CompositionSectionAuthorResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CompositionSectionAuthorResult object in this edge. */
  node?: Maybe<CompositionSectionAuthorResult>
}

/** A code identifying the kind of content contained within the section. This must be consistent with the section title. */
export type CompositionSectionCodeResult = CodeableConcept | None

/**
 * A page/slice of a list of CompositionSection nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CompositionSectionConnection = Connection & {
  __typename?: 'CompositionSectionConnection'
  /** Edges in the current connection page. */
  edges: Array<CompositionSectionEdge>
  /** List of CompositionSection object nodes in the current connection page. */
  nodes: Array<Maybe<CompositionSection>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CompositionSection connection page. */
export type CompositionSectionEdge = Edge & {
  __typename?: 'CompositionSectionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CompositionSection object in this edge. */
  node?: Maybe<CompositionSection>
}

/** A reference to the actual resource from which the narrative in the section is derived. */
export type CompositionSectionEntryResult =
  | Condition
  | ExternalResource
  | NonFederatedResource

/**
 * A page/slice of a list of CompositionSectionEntryResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CompositionSectionEntryResultConnection = Connection & {
  __typename?: 'CompositionSectionEntryResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CompositionSectionEntryResultEdge>
  /** List of CompositionSectionEntryResult object nodes in the current connection page. */
  nodes: Array<Maybe<CompositionSectionEntryResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CompositionSectionEntryResult connection page. */
export type CompositionSectionEntryResultEdge = Edge & {
  __typename?: 'CompositionSectionEntryResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CompositionSectionEntryResult object in this edge. */
  node?: Maybe<CompositionSectionEntryResult>
}

/**
 * A human-readable narrative that contains the attested content of the section,
 * used to represent the content of the resource to a human. The narrative need not
 * encode all the structured data, but is required to contain sufficient detail to
 * make it "clinically safe" for a human to just read the narrative.
 */
export type CompositionSectionTextResult = Narrative | None

/**  A sort on Composition - consists of the field to sort on and the ordering  */
export type CompositionSort = {
  /**  Which field to sort by  */
  field: CompositionSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Composition can be sorted by  */
export enum CompositionSortOption {
  /**
   * Sorts on Composition.date
   * Composition editing time
   */
  Date = 'DATE',
  /**  Do not sort  */
  None = 'NONE',
  /**
   * Sorts on Composition.event.period
   * The period covered by the documentation
   */
  Period = 'PERIOD',
  /**
   * Sorts on Composition.title
   * Human Readable name/title
   */
  Title = 'TITLE',
}

/**  The workflow/clinical status of the composition.  */
export enum CompositionStatus {
  /**
   *  The composition content or the referenced resources have been modified (edited
   * or added to) subsequent to being released as "final" and the composition is
   * complete and verified by an authorized person.
   */
  Amended = 'AMENDED',
  /**
   *  The composition or document was originally created/issued in error, and this
   * is an amendment that marks that the entire series should not be considered as valid.
   */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**
   *  This version of the composition is complete and verified by an appropriate
   * person and no further work is planned. Any subsequent updates would be on a
   * new version of the composition.
   */
  Final = 'FINAL',
  /**
   *  This is a preliminary composition or document (also known as initial or
   * interim). The content may be incomplete or unverified.
   */
  Preliminary = 'PRELIMINARY',
}

/**  The workflow/clinical status of the composition.  */
export type CompositionStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<CompositionStatus>>>
}

/**
 * Who or what the composition is about. The composition can be about a person,
 * (patient or healthcare practitioner), a device (e.g. a machine) or even a group
 * of subjects (such as a document about a herd of livestock, or a set of patients
 * that share a common exposure).
 */
export type CompositionSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**
 * Specifies the particular kind of composition (e.g. History and Physical,
 * Discharge Summary, Progress Note). This usually equates to the purpose of making
 * the composition.
 */
export type CompositionTypeResult = CodeableConcept | None

/**
 *  A clinical condition, problem, diagnosis, or other event, situation, issue, or
 * clinical concept that has risen to a level of concern.
 */
export type Condition = HealthgraphResource &
  Resource & {
    __typename?: 'Condition'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** Individual who is making the condition statement. */
    asserter?: Maybe<ConditionAsserterResult>
    /** A category assigned to the condition. */
    categories?: Maybe<CodeableConceptConnection>
    /** Identification of the condition, problem or diagnosis. */
    code?: Maybe<ConditionCodeResult>
    /** Reason why the condition is not asserted as true. */
    conditionNegation?: Maybe<ConditionConditionNegationResult>
    /**
     * The original code given when the condition was recorded if it is not a current
     * valid babylon IRI. As the code field must contain a valid babylon IRI, if the
     * original code needs converting to be a valid babylon IRI then the original
     * value is stored here
     */
    convertedCode?: Maybe<ConditionConvertedCodeResult>
    /** The Encounter during which this Condition was created or to which the creation of this record is tightly associated. */
    encounter?: Maybe<ConditionEncounterResult>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Business identifiers assigned to this condition by the performer or other
     * systems which remain constant as the resource is updated and propagates from
     * server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * The recordedDate represents when this particular Condition record was created
     * in the system, which is often a system-generated date.
     */
    recordedDate?: Maybe<Scalars['FhirDateTime']>
    /** Individual who recorded the record and takes responsibility for its content. */
    recorder?: Maybe<ConditionRecorderResult>
    /** Indicates the patient or group who the condition record is associated with. */
    subject?: Maybe<ConditionSubjectResult>
    /**  Information about how trustworthy the resource is; how likely it is to be accurate  */
    trust?: Maybe<ConditionTrustResult>
    /** The verification status to support the clinical status of the condition. */
    verificationStatus?: Maybe<ConditionVerificationStatusResult>
  }

/**
 *  A clinical condition, problem, diagnosis, or other event, situation, issue, or
 * clinical concept that has risen to a level of concern.
 */
export type ConditionCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A clinical condition, problem, diagnosis, or other event, situation, issue, or
 * clinical concept that has risen to a level of concern.
 */
export type ConditionIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Individual who is making the condition statement. */
export type ConditionAsserterResult =
  | ExternalResource
  | HealthgraphRelatedPerson
  | None
  | NonFederatedResource
  | Patient
  | Practitioner

/** Identification of the condition, problem or diagnosis. */
export type ConditionCodeResult = CodeableConcept | None

/** Reason why the condition is not asserted as true. */
export type ConditionConditionNegationResult = Coding | None

/**
 * A page/slice of a list of Condition nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ConditionConnection = Connection & {
  __typename?: 'ConditionConnection'
  /** Edges in the current connection page. */
  edges: Array<ConditionEdge>
  /** List of Condition object nodes in the current connection page. */
  nodes: Array<Maybe<Condition>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * The original code given when the condition was recorded if it is not a current
 * valid babylon IRI. As the code field must contain a valid babylon IRI, if the
 * original code needs converting to be a valid babylon IRI then the original value is stored here
 */
export type ConditionConvertedCodeResult = Coding | None

/** An edge in a Condition connection page. */
export type ConditionEdge = Edge & {
  __typename?: 'ConditionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Condition object in this edge. */
  node?: Maybe<Condition>
}

/** The Encounter during which this Condition was created or to which the creation of this record is tightly associated. */
export type ConditionEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  Specifies a filter to apply to a list of Condition  */
export type ConditionFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<ConditionFilter>>
  /**
   * Filters on Condition.asserter
   * Person who asserts this condition
   */
  asserterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Condition.category
   * The category of the condition
   */
  category?: Maybe<CodeFilter>
  /**
   * Filters on Condition.code
   * Code for the condition
   */
  code?: Maybe<CodeFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Condition.encounter
   * Encounter created as part of
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Condition.identifier
   * A unique identifier of the condition record
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<ConditionFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<ConditionFilter>>
  /**
   * Filters on Condition.subject.where(resolve() is Patient)
   * Who has the condition?
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Filters on Condition.recordedDate
   * Date record was first recorded
   */
  recordedDate?: Maybe<DateFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Condition.subject
   * Who has the condition?
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**  Filter by trust values, lets you limit to results that have a certain likelihood of being true  */
  trust?: Maybe<TrustFilter>
  /**
   * Filters on Condition.verificationStatus
   * unconfirmed | provisional | differential | confirmed | refuted | entered-in-error
   */
  verificationStatus?: Maybe<ConditionVerificationStatusFilter>
}

/** Individual who recorded the record and takes responsibility for its content. */
export type ConditionRecorderResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

export type ConditionResult =
  | AuthorizationError
  | Condition
  | ResourceNotFoundError

export type ConditionsByIdentifierResult =
  | AuthorizationError
  | ConditionConnection

/**  A sort on Condition - consists of the field to sort on and the ordering  */
export type ConditionSort = {
  /**  Which field to sort by  */
  field: ConditionSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Condition can be sorted by  */
export enum ConditionSortOption {
  /**  Do not sort  */
  None = 'NONE',
  /**
   * Sorts on Condition.recordedDate
   * Date record was first recorded
   */
  RecordedDate = 'RECORDED_DATE',
  /**  Sort by how trustworthy the resource is  */
  Trust = 'TRUST',
}

/** Indicates the patient or group who the condition record is associated with. */
export type ConditionSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**  Trust information or None if the Trust data is not present  */
export type ConditionTrustResult = None | Trust

/**  The verification status to support or decline the clinical status of the condition or diagnosis.  */
export enum ConditionVerificationStatus {
  /**  There is sufficient diagnostic and/or clinical evidence to treat this as a confirmed condition.  */
  Confirmed = 'CONFIRMED',
  /**
   *  One of a set of potential (and typically mutually exclusive) diagnoses
   * asserted to further guide the diagnostic process and preliminary treatment.
   */
  Differential = 'DIFFERENTIAL',
  /**  The statement was entered in error and is not valid.  */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**  This is a tentative diagnosis - still a candidate that is under consideration.  */
  Provisional = 'PROVISIONAL',
  /**  This condition has been ruled out by diagnostic and clinical evidence.  */
  Refuted = 'REFUTED',
  /**  There is not sufficient diagnostic and/or clinical evidence to treat this as a confirmed condition.  */
  Unconfirmed = 'UNCONFIRMED',
}

/**  The verification status to support or decline the clinical status of the condition or diagnosis.  */
export type ConditionVerificationStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<ConditionVerificationStatus>>>
}

/** The verification status to support the clinical status of the condition. */
export type ConditionVerificationStatusResult = CodeableConcept | None

/**
 * A page/slice of a list of nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type Connection = {
  /** Edges in the current connection page. */
  edges: Array<Edge>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Information about a consent decision. */
export type Consent = {
  __typename?: 'Consent'
  /** Whether or not consent has been accepted. */
  hasBeenAccepted: Scalars['Boolean']
}

/** A technology-mediated contact point for a person or organization. */
export type ContactPoint = {
  /** System used for this contact point. */
  system: ContactPointSystem
}

/**
 * A page/slice of a list of ContactPoint nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ContactPointConnection = Connection & {
  __typename?: 'ContactPointConnection'
  /** Edges in the current connection page. */
  edges: Array<ContactPointEdge>
  /** List of ContactPoint object nodes in the current connection page. */
  nodes: Array<Maybe<ContactPoint>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ContactPoint connection page. */
export type ContactPointEdge = Edge & {
  __typename?: 'ContactPointEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ContactPoint object in this edge. */
  node?: Maybe<ContactPoint>
}

/**  Used to filter on a ContactPoint */
export type ContactPointFilter = {
  /**  The field must match at least one of the given options */
  ONE_OF?: Maybe<Array<Scalars['String']>>
}

/** System used by a ContactPoint. */
export enum ContactPointSystem {
  /** ContactPoint supports messages via Email. */
  Email = 'EMAIL',
  /** ContactPoint supports messages via Fax. */
  Fax = 'FAX',
  /** ContactPoint is a different system (which can't be expressed as any other supported system). */
  Other = 'OTHER',
  /** ContactPoint supports telephone voice calls. */
  Phone = 'PHONE',
  /** ContactPoint supports messages via SMS. */
  Sms = 'SMS',
  /** ContactPoint is expressed as a URL (for example, a contact form or social media profile URL). */
  Url = 'URL',
}

/** The Conversation resource represents any conversation done using Babylon's Chat-Platform */
export type Conversation = Resource & {
  __typename?: 'Conversation'
  /**
   * The step to be used for the next mutation (either createConversationMessage or undoLastConversationStep).
   * The absence of this field means that the conversation has finished.
   */
  currentStep?: Maybe<ConversationStep>
  /** Unique identifier of the conversation resource */
  id: Scalars['ID']
  /** A paginated sequence of messages. To be used for conversation history purposes */
  messages: MessageConnection
  /** The progress of the conversation in % */
  progress?: Maybe<Scalars['NonNegativeInt']>
  /** Result of the conversation */
  result?: Maybe<ConversationResult>
  /** Point in time when the resource was first generated */
  startedAt: Scalars['DateTime']
  /** The status of the conversation, either open or finished */
  status: ConversationStatus
  /** The topic of the conversation */
  type: Scalars['String']
  /** Point in time when the resource was last updated */
  updatedAt: Scalars['DateTime']
}

/** The Conversation resource represents any conversation done using Babylon's Chat-Platform */
export type ConversationMessagesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** Attachment to a conversation */
export type ConversationAttachment = {
  __typename?: 'ConversationAttachment'
  /** mimetype of the attachment */
  mimeType?: Maybe<Scalars['String']>
  /** content of the attachment */
  value?: Maybe<Scalars['String']>
}

/** The medium by which conversation messages are sent. */
export enum ConversationChannel {
  /** Conversation message sent by email. */
  Email = 'EMAIL',
  /** Conversation message sent by SMS. */
  Sms = 'SMS',
}

/** Conversation event edge */
export type ConversationChannelEdge = Edge & {
  __typename?: 'ConversationChannelEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the coversation-channel object in this edge. */
  node?: Maybe<ConversationChannel>
}

/** Contains information relevant to the current state of a conversation */
export type ConversationConnection = Connection & {
  __typename?: 'ConversationConnection'
  /** Edges in the current connection page. */
  edges: Array<ConversationEdge>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Contains content type of a conversation */
export type ConversationContent = {
  __typename?: 'ConversationContent'
  /** mimetype of the additional contnet */
  mimeType?: Maybe<Scalars['String']>
  /** base64 encoded content */
  value?: Maybe<Scalars['String']>
}

/** Possible type of conversations */
export type ConversationData = {
  /** triage conversation */
  general?: Maybe<GeneralConversationData>
  /** assessment conversation */
  healthcheck?: Maybe<HealthcheckConversationData>
  /** qna conversation */
  information?: Maybe<InformationConversationData>
  /** prebooking conversation */
  prebooking?: Maybe<PrebookingConversationData>
  /** async support conversation */
  supportTicket?: Maybe<SupportTicketConversationConfig>
  /** booking test kits */
  testKits?: Maybe<TestKitsConversationData>
}

/** An edge exposed for a conversation */
export type ConversationEdge = Edge & {
  __typename?: 'ConversationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<Conversation>
}

/** A conversation event marking an action in the conversation */
export type ConversationEvent = {
  __typename?: 'ConversationEvent'
  participants: ConversationParticipantResultConnection
}

/** Conversation event connection. */
export type ConversationEventConnection = Connection & {
  __typename?: 'ConversationEventConnection'
  /** Edges in the current connection page. */
  edges: Array<ConversationEventEdge>
  /** List of conversation-event object nodes in the current connection page. */
  nodes: Array<Maybe<ConversationEvent>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Conversation event edge. */
export type ConversationEventEdge = Edge & {
  __typename?: 'ConversationEventEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the conversation-event object in this edge. */
  node?: Maybe<ConversationEvent>
}

/** Input for conversation event */
export type ConversationEventInput = {
  /** The ID of the participant responsible for generating a conversation event. */
  participant: ConversationParticipantId
  /** The type of the event being generated. */
  type?: Maybe<ConversationEventType>
}

/** Event triggered by participant for a conversation */
export enum ConversationEventType {
  /** Event signalling that a participant is willing to partake in a conversation. */
  Join = 'JOIN',
  /** Event signalling that a participant is now longer willing to partake in a conversation. */
  Leave = 'LEAVE',
}

/** An error indicating that the requested conversation has been closed. */
export type ConversationFinishedError = Error & {
  __typename?: 'ConversationFinishedError'
  errorMessage: Scalars['String']
}

/** Message input sent within a conversation */
export type ConversationInputMessage = {
  /** The medium by which the message is being sent. */
  medium: ConversationChannel
  /** Any other metadata associated with the message. */
  metadata?: Maybe<Array<MessageMetaDataInput>>
  /** The message payload. */
  payload?: Maybe<InputMessagePayload>
  /** The ID of the particpant sending the message. */
  sender: ConversationParticipantId
  /** The time at which the message is being sent. */
  sent: Scalars['DateTime']
}

/** Id and type of a conversation link */
export type ConversationLinkInput = {
  /** id of element to link to */
  id: Scalars['ID']
  /** type of the conversation link */
  type: Scalars['String']
}

/** Message sent back as part of a conversation */
export type ConversationMessage = {
  __typename?: 'ConversationMessage'
  /** The message payload. */
  body?: Maybe<Scalars['String']>
  /** The medium by which the message was sent. */
  channel: ConversationChannel
  /** The ID of this converstaion message. */
  id: Scalars['ID']
  /** Any metadata that was associated with the message. */
  metadata: MessageMetaDataConnection
  /** The conversation, within which, this message took place. */
  partOf: ChatConversation
  /** The conversation particpant that sent the message. */
  sender: ConversationParticipantResult
  /** The time at which the message was sent. */
  sent: Scalars['DateTime']
}

/** Conversation message connection. */
export type ConversationMessageConnection = Connection & {
  __typename?: 'ConversationMessageConnection'
  /** Edges in the current connection page. */
  edges: Array<ConversationMessageEdge>
  /** List of conversation-message object nodes in the current connection page. */
  nodes: Array<Maybe<ConversationMessage>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Conversation message edge. */
export type ConversationMessageEdge = Edge & {
  __typename?: 'ConversationMessageEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the converstaion-message object in this edge. */
  node?: Maybe<ConversationMessage>
}

/** Possible outcomes of a conversation */
export enum ConversationOutcome {
  /** conversation did not provide value (triage was not possible etc) */
  Failure = 'failure',
  /** conversation provided value */
  Success = 'success',
  /** value cannot be determined yet */
  Undefined = 'undefined',
}

/** Conversation participant edge. */
export type ConversationParticipantEdge = Edge & {
  __typename?: 'ConversationParticipantEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the conversation-participant object in this edge. */
  node?: Maybe<ConversationParticipantResult>
}

/** ID of the message sender, either a patient or practitioner */
export type ConversationParticipantId = {
  /** The ID of the patient in the case that the conversation participant is a patient. */
  patientId?: Maybe<Scalars['ID']>
  /** The ID of the practitioner in the case that the conversation participant is a practitioner. */
  practitionerId?: Maybe<Scalars['ID']>
}

/** Participant in the conversation */
export type ConversationParticipantResult = Patient | Practitioner

/** Conversation participant connection. */
export type ConversationParticipantResultConnection = Connection & {
  __typename?: 'ConversationParticipantResultConnection'
  /** Edges in the current connection page. */
  edges: Array<ConversationParticipantEdge>
  /** List of conversation-participant object nodes in the current connection page. */
  nodes: Array<Maybe<ConversationParticipantResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Reference for conversation */
export type ConversationReference = {
  __typename?: 'ConversationReference'
  identifier: Scalars['String']
  type?: Maybe<Scalars['String']>
}

/** Result of conversation */
export type ConversationResult = {
  __typename?: 'ConversationResult'
  /** does member get what they want in this conversation */
  outcome: ConversationOutcome
  /** additional information about the result */
  type: Scalars['String']
}

/** Enum showing the current state of a conversation */
export enum ConversationState {
  /** Conversation finished. */
  Finished = 'FINISHED',
  /** Conversation started. */
  Started = 'STARTED',
}

/** Possible statuses of a conversation */
export enum ConversationStatus {
  /** member is not allowed to send any new messages */
  Finished = 'finished',
  /** conversation is open */
  Started = 'started',
}

/**
 * # Conversation Step
 * Object expressing the state of the conversation at the end of any mutating transaction.
 * Should be used to construct the inputs of the next conversation mutating transaction.
 */
export type ConversationStep = MutableResource & {
  __typename?: 'ConversationStep'
  /** any additional way user can interact with the conversation at the current step, (cards) */
  additionalInputs?: Maybe<Array<CardMessageInput>>
  /** need be provided to mutate the conversation */
  idempotencyKey: Scalars['String']
  /**
   * defined what user is required to provide, to progress the conversation. It
   * contains all the data to render the input form too
   */
  input?: Maybe<MessageInput>
  /** true if user can rate the curent step */
  isRateable: Scalars['Boolean']
  /** true if user can report current step */
  isReportable: Scalars['Boolean']
  /** true if user is allowed to undo the last step */
  isUndoable: Scalars['Boolean']
  /** list of elements to render in the conversation. Contain the content for current step */
  messages?: Maybe<Array<Message>>
}

/** Suggestion possible in current conversation */
export type ConversationSuggestion = {
  __typename?: 'ConversationSuggestion'
  /** list of banners to be displayed */
  banners?: Maybe<Array<ConversationSuggestionBanner>>
  /** shord summary of the suggestion */
  description?: Maybe<Scalars['String']>
  /** id of the suggestion */
  id: Scalars['ID']
  /** name of the suggestion */
  name?: Maybe<Scalars['String']>
  /** long content of the suggestion */
  text?: Maybe<Scalars['String']>
  /** all the places, the user input is located in suggestion */
  tokens?: Maybe<Array<ConversationSuggestionToken>>
}

/** Banner id for a suggestion */
export type ConversationSuggestionBanner = {
  __typename?: 'ConversationSuggestionBanner'
  /** the id of the banner */
  id: Scalars['ID']
}

/** Contains information where to find the suggestion */
export type ConversationSuggestionToken = {
  __typename?: 'ConversationSuggestionToken'
  /** where suggestion ends */
  end: Scalars['Int']
  /** where suggestion starts */
  start: Scalars['Int']
}

/** Feature Switch in the Core Ruby domain */
export type CoreRubyFeatureSwitch = {
  __typename?: 'CoreRubyFeatureSwitch'
  /** Description of the Feature Switch */
  description: Scalars['String']
  /** Name of the feature switch */
  name: Scalars['String']
  /** Domain which owns the Feature Switch */
  ownedBy: Scalars['String']
  /** Core Ruby Resource */
  resource: Scalars['String']
}

/**
 * A page/slice of a list of Core Ruby Feature Switch nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CoreRubyFeatureSwitchConnection = Connection & {
  __typename?: 'CoreRubyFeatureSwitchConnection'
  /** Edges in the current connection page. */
  edges: Array<CoreRubyFeatureSwitchEdge>
  /** List of ContactPoint object nodes in the current connection page. */
  nodes: Array<Maybe<CoreRubyFeatureSwitch>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Core Ruby Feature Switch Connection connection page */
export type CoreRubyFeatureSwitchEdge = Edge & {
  __typename?: 'CoreRubyFeatureSwitchEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Core Ruby Feature Switch object in this edge. */
  node?: Maybe<CoreRubyFeatureSwitch>
}

/** Filter for Core Ruby Feature Switches Query */
export type CoreRubyFeatureSwitchesFilter = {
  /** Resource */
  resource?: Maybe<Scalars['String']>
}

/** Core Ruby Feature Switch Setting - attached to some sort of an entity */
export type CoreRubyFeatureSwitchSetting = {
  __typename?: 'CoreRubyFeatureSwitchSetting'
  /** Indicates whether or not the feature is turned on */
  isTurnedOn: Scalars['Boolean']
  /** Name of the feature switch */
  name: Scalars['String']
}

/**
 * A page/slice of a list of Core Ruby Feature Switch Setting nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CoreRubyFeatureSwitchSettingConnection = Connection & {
  __typename?: 'CoreRubyFeatureSwitchSettingConnection'
  /** Edges in the current connection page. */
  edges: Array<CoreRubyFeatureSwitchSettingEdge>
  /** List of ContactPoint object nodes in the current connection page. */
  nodes: Array<Maybe<CoreRubyFeatureSwitchSetting>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Core Ruby Feature Switch Setting Connection connection page */
export type CoreRubyFeatureSwitchSettingEdge = Edge & {
  __typename?: 'CoreRubyFeatureSwitchSettingEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Core Ruby Feature Switch Setting object in this edge. */
  node?: Maybe<CoreRubyFeatureSwitchSetting>
}

/** Core Ruby Feature Switch Setting for updating */
export type CoreRubyFeatureSwitchSettingInput = {
  /** Indicates whether or not the feature is turned on */
  isTurnedOn: Scalars['Boolean']
  /** Name of the feature switch */
  name: Scalars['String']
}

/** Response type for core ruby region */
export type CoreRubyRegion = {
  __typename?: 'CoreRubyRegion'
  /** ISO code of the region */
  code: Scalars['String']
  /** Babylon unique Identifier */
  id: Scalars['ID']
  /** Name of the Region */
  name: Scalars['String']
}

/**
 * A page of core ruby regions.
 *
 * An implementation of [Connection Type](https://relay.dev/graphql/connections.htm#sec-Connection-Types)
 * for edges of type `CoreRubyRegionEdge`.
 */
export type CoreRubyRegionConnection = Connection & {
  __typename?: 'CoreRubyRegionConnection'
  /** Edges in the current connection page. */
  edges: Array<CoreRubyRegionEdge>
  /** List of core ruby region object nodes in the current connection page. */
  nodes: Array<Maybe<CoreRubyRegion>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An implementation of [Edges](https://relay.dev/graphql/connections.htm#sec-Edges)
 * for a node of type `CoreRubyRegionEdge`.
 */
export type CoreRubyRegionEdge = Edge & {
  __typename?: 'CoreRubyRegionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<CoreRubyRegion>
}

/**
 *  Financial instrument which may be used to reimburse or pay for health care
 * products and services. Includes both insurance and self-payment.
 */
export type Coverage = HealthgraphResource &
  Resource & {
    __typename?: 'Coverage'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The party who benefits from the insurance coverage; the patient when products and/or services are provided. */
    beneficiary?: Maybe<CoverageBeneficiaryResult>
    /** A suite of underwriter specific classifiers. */
    classes?: Maybe<CoverageClassConnection>
    /** The Babylon variant of Coverage */
    coverageInsurancePlan?: Maybe<CoverageCoverageInsurancePlanResult>
    /** The date when the patients eligibility status changed to this state */
    eligibilityStatusChanged?: Maybe<Scalars['FhirDate']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** A unique identifier assigned to this coverage. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * The program or plan underwriter or payor including both insurance and
     * non-insurance agreements, such as patient-pay agreements.
     */
    payors?: Maybe<CoveragePayorResultConnection>
    /**
     * Time period during which the coverage is in force. A missing start date
     * indicates the start date isn't known, a missing end date means the coverage is
     * continuing to be in force.
     */
    period?: Maybe<CoveragePeriodResult>
    /** The relationship of beneficiary (patient) to the subscriber. */
    relationship?: Maybe<CoverageRelationshipResult>
    /** The status of the resource instance. */
    status?: Maybe<Scalars['FhirCode']>
    /** The insurer assigned ID for the Subscriber. */
    subscriberId?: Maybe<Scalars['String']>
    /**
     * The type of coverage: social program, medical plan, accident coverage (workers
     * compensation, auto), group health or payment by an individual or organization.
     */
    type?: Maybe<CoverageTypeResult>
  }

/**
 *  Financial instrument which may be used to reimburse or pay for health care
 * products and services. Includes both insurance and self-payment.
 */
export type CoverageClassesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Financial instrument which may be used to reimburse or pay for health care
 * products and services. Includes both insurance and self-payment.
 */
export type CoverageIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Financial instrument which may be used to reimburse or pay for health care
 * products and services. Includes both insurance and self-payment.
 */
export type CoveragePayorsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The party who benefits from the insurance coverage; the patient when products and/or services are provided. */
export type CoverageBeneficiaryResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**  A suite of underwriter specific classifiers.  */
export type CoverageClass = {
  __typename?: 'CoverageClass'
  /** A short description for the class. */
  name?: Maybe<Scalars['String']>
  /**
   * The type of classification for which an insurer-specific class label or number
   * and optional name is provided, for example may be used to identify a class of
   * coverage or employer group, Policy, Plan.
   */
  type?: Maybe<CoverageClassTypeResult>
  /** The alphanumeric string value associated with the insurer issued label. */
  value?: Maybe<Scalars['String']>
}

/**
 * A page/slice of a list of CoverageClass nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CoverageClassConnection = Connection & {
  __typename?: 'CoverageClassConnection'
  /** Edges in the current connection page. */
  edges: Array<CoverageClassEdge>
  /** List of CoverageClass object nodes in the current connection page. */
  nodes: Array<Maybe<CoverageClass>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CoverageClass connection page. */
export type CoverageClassEdge = Edge & {
  __typename?: 'CoverageClassEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CoverageClass object in this edge. */
  node?: Maybe<CoverageClass>
}

/**
 * The type of classification for which an insurer-specific class label or number
 * and optional name is provided, for example may be used to identify a class of
 * coverage or employer group, Policy, Plan.
 */
export type CoverageClassTypeResult = CodeableConcept | None

/**
 * A page/slice of a list of Coverage nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CoverageConnection = Connection & {
  __typename?: 'CoverageConnection'
  /** Edges in the current connection page. */
  edges: Array<CoverageEdge>
  /** List of Coverage object nodes in the current connection page. */
  nodes: Array<Maybe<Coverage>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** The Babylon variant of Coverage */
export type CoverageCoverageInsurancePlanResult =
  | ExternalResource
  | InsurancePlan
  | None
  | NonFederatedResource

/** An edge in a Coverage connection page. */
export type CoverageEdge = Edge & {
  __typename?: 'CoverageEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Coverage object in this edge. */
  node?: Maybe<Coverage>
}

/**  Specifies a filter to apply to a list of Coverage  */
export type CoverageFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<CoverageFilter>>
  /**
   * Filters on Coverage.beneficiary
   * Covered party
   */
  beneficiaryUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Coverage.class.type
   * Coverage class (eg. plan, group)
   */
  classType?: Maybe<CodeFilter>
  /**
   * Filters on Coverage.class.value
   * Value of the class (eg. Plan number, group number)
   */
  classValue?: Maybe<StringFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Coverage.identifier
   * The primary identifier of the insured and the coverage
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<CoverageFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<CoverageFilter>>
  /**
   * Filters on Coverage.beneficiary
   * Retrieve coverages for a patient
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Coverage.payor
   * The identity of the insurer or party paying for services
   */
  payorUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Coverage.status
   * The status of the Coverage
   */
  status?: Maybe<FinancialResourceStatusCodeFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Coverage.type
   * The kind of coverage (health plan, auto, Workers Compensation)
   */
  type?: Maybe<CodeFilter>
}

/**
 * The program or plan underwriter or payor including both insurance and
 * non-insurance agreements, such as patient-pay agreements.
 */
export type CoveragePayorResult =
  | ExternalResource
  | NonFederatedResource
  | Organization

/**
 * A page/slice of a list of CoveragePayorResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type CoveragePayorResultConnection = Connection & {
  __typename?: 'CoveragePayorResultConnection'
  /** Edges in the current connection page. */
  edges: Array<CoveragePayorResultEdge>
  /** List of CoveragePayorResult object nodes in the current connection page. */
  nodes: Array<Maybe<CoveragePayorResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a CoveragePayorResult connection page. */
export type CoveragePayorResultEdge = Edge & {
  __typename?: 'CoveragePayorResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CoveragePayorResult object in this edge. */
  node?: Maybe<CoveragePayorResult>
}

/**
 * Time period during which the coverage is in force. A missing start date
 * indicates the start date isn't known, a missing end date means the coverage is
 * continuing to be in force.
 */
export type CoveragePeriodResult = None | Period

/** The relationship of beneficiary (patient) to the subscriber. */
export type CoverageRelationshipResult = CodeableConcept | None

export type CoverageResult =
  | AuthorizationError
  | Coverage
  | ResourceNotFoundError

export type CoveragesByIdentifierResult =
  | AuthorizationError
  | CoverageConnection

/**  A sort on Coverage - consists of the field to sort on and the ordering  */
export type CoverageSort = {
  /**  Which field to sort by  */
  field: CoverageSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Coverage can be sorted by  */
export enum CoverageSortOption {
  /**
   * Sorts on Coverage.class.value
   * Value of the class (eg. Plan number, group number)
   */
  ClassValue = 'CLASS_VALUE',
  /**  Do not sort  */
  None = 'NONE',
}

export type CoveragesResult = AuthorizationError | CoverageConnection

/**
 * The type of coverage: social program, medical plan, accident coverage (workers
 * compensation, auto), group health or payment by an individual or organization.
 */
export type CoverageTypeResult = CodeableConcept | None

/** Input types available for a message. Define what user can input in the current state of conversation */
export type CreateConversationMessageInput = {
  /** card will be used to progress the conversation */
  card?: Maybe<CardInputMessage>
  /** user is allowed to asnwer only with a given set of choices */
  choices?: Maybe<ChatPlatformChoicesInputMessageValue>
  /** user should select date */
  date?: Maybe<DateInputMessage>
  /** user should answer with number */
  number?: Maybe<NumberInputMessage>
  /** user should answer with phone number */
  phone?: Maybe<PhoneInputMessage>
  /** user should answer with symptom */
  symptom?: Maybe<SymptomInputMessage>
  /** user should answer with text */
  text?: Maybe<TextInputMessage>
  /** user should answer with text and filed */
  textAndFiles?: Maybe<TextAndFilesInputMessage>
}

/** Error type for creating GPOrganization */
export type CreateGpOrganizationError = Error & {
  __typename?: 'CreateGPOrganizationError'
  /** Error message */
  errorMessage: Scalars['String']
}

/** Input for Creating a GP Organization */
export type CreateGpOrganizationInput = {
  /** Country code for the fax number */
  faxCountryCode: Scalars['String']
  /**
   * Fax Number of the GP surgery
   * For the simplicity, we added faxNumber directly, instead of a ContactPoint array
   */
  faxNumber: Scalars['String']
  /** Name of the GP surgery */
  name: Scalars['String']
}

/** Result from updating GP Organization */
export type CreateGpOrganizationResult =
  | CreateGpOrganizationError
  | GpOrganization

/** Details to create a patient. */
export type CreatePatientInput = {
  /**
   * Additional Addresses for the patient (if provided)
   *
   * Additional Addresses can be added using the `addContactInfo` mutation
   */
  additionalAddresses?: Maybe<Array<AddressInput>>
  /**
   * Additional Email Addresses for the patient (if provided)
   *
   * Additional Email Addresses can be added using the `addContactInfo` mutation
   */
  additionalEmailAddresses?: Maybe<Array<Scalars['EmailAddress']>>
  /**
   * Additional Phone Numbers for the patient (if provided)
   *
   * Additional Phone Numbers can be added using the `addContactInfo` mutation
   */
  additionalPhoneNumbers?: Maybe<Array<Scalars['PhoneNumber']>>
  /**
   * Identifiers for the patient (if provided).
   *
   * Additional identifiers can be added using the `addPatientIdentifier` mutation.
   */
  identifiers?: Maybe<Array<IdentifierInput>>
  /** General information about the patient. */
  info: PatientInfoInput
  /**
   * The primary address for the patient (if provided).
   *
   * Additional addresses can be added using the `addPatientAddress` mutation.
   */
  primaryAddress?: Maybe<AddressInput>
  /**
   * The primary email address for the patient (if provided).
   *
   * Note that email addresses are mandatory in some regions. Additional email
   * addresses can be added using the `addPatientEmailAddress` mutation.
   */
  primaryEmailAddress?: Maybe<Scalars['EmailAddress']>
  /**
   * The primary name for the patient (if provided).
   *
   * Additional names can be added using the `addPatientName` mutation.
   */
  primaryName?: Maybe<HumanNameInput>
  /**
   * The primary phone number for the patient (if provided).
   *
   * Additional phone numbers can be added using the `addPatientPhoneNumber` mutation.
   */
  primaryPhoneNumber?: Maybe<Scalars['PhoneNumber']>
}

export type CreatePatientResult = Patient

/** Input for the CreatePatientUserChallenge mutation */
export type CreatePatientUserChallengeInput = {
  /** The Challenge Template ID to create the challenge from */
  challengeTemplateId: Scalars['ID']
  /** The patient ID for the patient to create the challenge for */
  patientId: Scalars['ID']
}

/** `CreatePatientUserChallengeResult` is a union for one of the following types. */
export type CreatePatientUserChallengeResult =
  | AuthorizationError
  | ResourceNotFoundError
  | UserChallenge

/** Error type for creating pharmacist practitioner */
export type CreatePharmacistPractitionerError = Error & {
  __typename?: 'CreatePharmacistPractitionerError'
  /** Error message */
  errorMessage: Scalars['String']
}

/** Input for creating a pharmacist practitioner */
export type CreatePharmacistPractitionerInput = {
  /** Email id of the pharmacist */
  email: Scalars['EmailAddress']
  /** Whether/Not this Pharmacy is active/blocked */
  isActive: Scalars['Boolean']
  /** Name of the pharmacist */
  name: Scalars['String']
  /** Pharmacy supplier for the given pharmacist */
  pharmacySupplierId: Scalars['ID']
  /** Region code of the pharmacist */
  regionCode: Scalars['String']
  /** Role of the pharmacist */
  roles: Array<Scalars['String']>
}

/** Result from creating a pharmacist practitioner */
export type CreatePharmacistPractitionerResult =
  | CreatePharmacistPractitionerError
  | Practitioner

/** Input required to create an SMS challenge */
export type CreateSmsmfaChallengeInput = {
  /** The token that will be used to challenge the User (e.g. 034230) */
  token: Scalars['String']
  /** The Auth0 ID that uniquely identifies a User */
  userId: Scalars['ID']
}

export type CreateSmsmfaChallengeResult = None | ResourceNotFoundError

/** Input required to enroll to SMS MFA */
export type CreateSmsmfaEnrollmentInput = {
  /** The Auth0 ID that uniquely identifies a User */
  userId: Scalars['ID']
}

export type CreateSmsmfaEnrollmentResult = MfaEnrollment | ResourceNotFoundError

/** Error type for creating Supply Network */
export type CreateSupplyNetworkError = Error & {
  __typename?: 'CreateSupplyNetworkError'
  /** Error message */
  errorMessage: Scalars['String']
}

/** Input for creating a Supply Network */
export type CreateSupplyNetworkInput = {
  /** Feature switch settings for the Supply Network. It is enough to only pass those ones that are to be changed */
  featureSwitchSettings?: Maybe<Array<CoreRubyFeatureSwitchSettingInput>>
  /** Name of the Supply Network */
  name: Scalars['String']
  /** Priority of the Supply Network */
  priority: Scalars['NonNegativeInt']
  /** Region ISO code of the Supply Network */
  regionCode: Scalars['String']
  /** RegionId of the Supply Network */
  regionId: Scalars['ID']
}

/** Result from creating Supply Network */
export type CreateSupplyNetworkResult = CreateSupplyNetworkError | SupplyNetwork

/** Welcome Appointment Invite information. */
export type CreateWelcomeAppointmentInvite = {
  __typename?: 'CreateWelcomeAppointmentInvite'
  /** The invite id. */
  id?: Maybe<Scalars['ID']>
}

/** Welcome Appointment Invite request error information. */
export type CreateWelcomeAppointmentInviteError = Error & {
  __typename?: 'CreateWelcomeAppointmentInviteError'
  /** Readable error message. */
  errorMessage: Scalars['String']
  /** HTTP response code. */
  statusCode: Scalars['Int']
}

export type CreateWelcomeAppointmentInviteResult =
  | CreateWelcomeAppointmentInvite
  | CreateWelcomeAppointmentInviteError

/** Information about a Data Collection Journey */
export type DataCollectionJourney = Resource & {
  __typename?: 'DataCollectionJourney'
  /** Current and not completed stage of a journey */
  currentStage?: Maybe<DataCollectionJourneyStage>
  /** Definition of a journey */
  definition?: Maybe<DataCollectionJourneyDefinition>
  /** Unique, canonical identifier for the resource */
  id: Scalars['ID']
}

/** Assessment stage to be completed */
export type DataCollectionJourneyAssessmentStage = DataCollectionJourneyStage & {
  __typename?: 'DataCollectionJourneyAssessmentStage'
  /** Bundle ID of an assessment */
  bundleId: Scalars['String']
  /** Assessment won't ask the questions when the data is already known */
  canSkipPreviouslyAnswered: Scalars['Boolean']
  /** Token to confirm stage completion */
  completionToken: Scalars['String']
  /** Flow ID of an assessment */
  flowId: Scalars['String']
}

/** Definition of a Data Collection Journey */
export type DataCollectionJourneyDefinition = Resource & {
  __typename?: 'DataCollectionJourneyDefinition'
  /** Unique, canonical identifier for the resource */
  id: Scalars['ID']
  /** Localised title of a journey */
  title: Scalars['String']
}

/** Informative stage for a patient */
export type DataCollectionJourneyInformationPageStage = DataCollectionJourneyStage & {
  __typename?: 'DataCollectionJourneyInformationPageStage'
  /** Action title or a title for an actionable element */
  actionTitle: Scalars['String']
  /** Information page body */
  body: Scalars['String']
  /** Token to confirm stage completion */
  completionToken: Scalars['String']
  /** Information page title */
  title: Scalars['String']
}

/** Interface for all the journey stage implementations */
export type DataCollectionJourneyStage = {
  /** Token to confirm stage completion */
  completionToken: Scalars['String']
}

/**
 * Used to filter dates, usually to between two values.
 * Uses the elasticsearch date math syntax, which means values like now or now-7d are valid
 * https://www.elastic.co/guide/en/elasticsearch/reference/current/common-options.html#date-math
 */
export type DateFilter = {
  /**  Only find dates after this value */
  GT?: Maybe<Scalars['String']>
  /**  Only find dates after or equal to this value */
  GTE?: Maybe<Scalars['String']>
  /**  Only find dates before this value */
  LT?: Maybe<Scalars['String']>
  /**  Only find dates before or equal to this value */
  LTE?: Maybe<Scalars['String']>
  /**  Search for dates that are EXACTLY equal to one of the listed values */
  ONE_OF?: Maybe<Array<Scalars['String']>>
}

/** String of date */
export type DateInputMessage = {
  /** date selected by user */
  date: Scalars['String']
}

/** Range of date */
export type DateMessageInput = {
  __typename?: 'DateMessageInput'
  /** the maximud date that can be selected */
  maxDate: Scalars['String']
  /** The minimum date that can be selected */
  minDate: Scalars['String']
}

/** Error type for deleting GPOrganization */
export type DeleteGpOrganizationError = Error & {
  __typename?: 'DeleteGpOrganizationError'
  /** Error message */
  errorMessage: Scalars['String']
}

/** Input for Deleting a GP Organization */
export type DeleteGpOrganizationInput = {
  /** ID of GP Organization */
  id: Scalars['ID']
}

/** Result from delete GP Organization */
export type DeleteGpOrganizationResult =
  | DeleteGpOrganizationError
  | GpOrganization

export type DeleteMfaEnrollmentResult = MfaEnrollment | ResourceNotFoundError

/** Input for DeleteUserChallenge mutation */
export type DeleteUserChallengeInput = {
  /** The user challenge id to delete */
  userChallengeId: Scalars['ID']
}

/** `DeleteUserChallengeResult` is a union for one of the following types. */
export type DeleteUserChallengeResult =
  | AuthorizationError
  | ResourceNotFoundError
  | UserChallengeDeleted

/** Input for `UserGoal` deletion. */
export type DeleteUserGoalInput = {
  userGoalId: Scalars['ID']
}

/** `DeleteUserGoalResult` is a union for one of the following types. */
export type DeleteUserGoalResult =
  | AuthorizationError
  | ResourceNotFoundError
  | UserGoal

/** A name of a human with text, parts and usage information. */
export type DependentHumanName = {
  __typename?: 'DependentHumanName'
  /** Family name (often called 'Surname') */
  family?: Maybe<Scalars['String']>
  /** Given names (not always 'first'). Includes middle names. */
  given?: Maybe<StringConnection>
}

/** A name of a human with text, parts and usage information. */
export type DependentHumanNameGivenArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * https://www.hl7.org/fhir/relatedperson.html
 *
 * Rename to RelatedPerson once member_relationships is removed
 */
export type DependentRelatedPerson = {
  __typename?: 'DependentRelatedPerson'
  /**
   * The date on which the dependent was born.
   * @deprecated Do not use this field as it will be removed in the future (see ticket ONB-317).
   */
  birthDate: Scalars['Date']
  /** ID of a dependent's stub profile */
  id: Scalars['ID']
  /** Preferred name of the patient. May be null if there are no known names. */
  name?: Maybe<DependentHumanName>
  /** The patient who this RelatedPerson is related to */
  patient?: Maybe<Patient>
}

/**
 * https://www.hl7.org/fhir/relatedperson.html
 *
 * Rename to RelatedPerson once member_relationships is removed
 */
export type DependentRelatedPersonNameArgs = {
  preferredUse?: Maybe<NameUse>
}

/**
 * A page/slice of a list of nodes of type `Relationship`, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 *
 * Rename it to RelatedPersonConnection once member_relationships is removed.
 */
export type DependentRelatedPersonConnection = Connection & {
  __typename?: 'DependentRelatedPersonConnection'
  /** Edges in the current connection page. */
  edges: Array<DependentRelatedPersonEdge>
  /** List of RelatedPerson object nodes in the current connection page. */
  nodes: Array<Maybe<DependentRelatedPerson>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An edge in a connection page for a node of type `Relationship`.
 *
 * See the [GraphQL Edges Specification](https://relay.dev/graphql/connections.htm#sec-Edges) for guidance on how to use.
 *
 * Rename it to RelatedPersonEdge once member_relationships is removed.
 */
export type DependentRelatedPersonEdge = Edge & {
  __typename?: 'DependentRelatedPersonEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<DependentRelatedPerson>
}

/**
 *  A type of a manufactured item that is used in the provision of healthcare
 * without being substantially changed through that activity. The device may be a
 * medical or non-medical device.
 */
export type Device = HealthgraphResource &
  Resource & {
    __typename?: 'Device'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Unique instance identifiers assigned to a device by manufacturers other organizations or owners. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * Unique device identifier (UDI) assigned to device label or package.  Note that
     * the Device may include multiple udiCarriers as it either may include just the
     * udiCarrier for the jurisdiction it is sold, or for multiple jurisdictions it
     * could have been sold.
     */
    udiCarriers?: Maybe<DeviceUdiCarrierConnection>
  }

/**
 *  A type of a manufactured item that is used in the provision of healthcare
 * without being substantially changed through that activity. The device may be a
 * medical or non-medical device.
 */
export type DeviceIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A type of a manufactured item that is used in the provision of healthcare
 * without being substantially changed through that activity. The device may be a
 * medical or non-medical device.
 */
export type DeviceUdiCarriersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of Device nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type DeviceConnection = Connection & {
  __typename?: 'DeviceConnection'
  /** Edges in the current connection page. */
  edges: Array<DeviceEdge>
  /** List of Device object nodes in the current connection page. */
  nodes: Array<Maybe<Device>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Device connection page. */
export type DeviceEdge = Edge & {
  __typename?: 'DeviceEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Device object in this edge. */
  node?: Maybe<Device>
}

export type DeviceResult = AuthorizationError | Device | ResourceNotFoundError

export type DevicesByIdentifierResult = AuthorizationError | DeviceConnection

/**
 *  Unique device identifier (UDI) assigned to device label or package.  Note that
 * the Device may include multiple udiCarriers as it either may include just the
 * udiCarrier for the jurisdiction it is sold, or for multiple jurisdictions it
 * could have been sold.
 */
export type DeviceUdiCarrier = {
  __typename?: 'DeviceUdiCarrier'
  /**
   * The device identifier (DI) is a mandatory, fixed portion of a UDI that
   * identifies the labeler and the specific version or model of a device.
   */
  deviceIdentifier?: Maybe<Scalars['String']>
}

/**
 * A page/slice of a list of DeviceUdiCarrier nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type DeviceUdiCarrierConnection = Connection & {
  __typename?: 'DeviceUdiCarrierConnection'
  /** Edges in the current connection page. */
  edges: Array<DeviceUdiCarrierEdge>
  /** List of DeviceUdiCarrier object nodes in the current connection page. */
  nodes: Array<Maybe<DeviceUdiCarrier>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a DeviceUdiCarrier connection page. */
export type DeviceUdiCarrierEdge = Edge & {
  __typename?: 'DeviceUdiCarrierEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the DeviceUdiCarrier object in this edge. */
  node?: Maybe<DeviceUdiCarrier>
}

/** Message object for a diagnosis */
export type DiagnosisMessageValue = {
  __typename?: 'DiagnosisMessageValue'
  /** unique identifier of the diagnosis */
  id: Scalars['ID']
  /** summary */
  text: Scalars['String']
  /** report url */
  url?: Maybe<Scalars['String']>
  /** diagnosis version */
  version: Scalars['Int']
}

/**
 *  The findings and interpretation of diagnostic  tests performed on patients,
 * groups of patients, devices, and locations, and/or specimens derived from these.
 * The report includes clinical context such as requesting and provider
 * information, and some mix of atomic results, images, textual and coded
 * interpretations, and formatted representation of diagnostic reports.
 */
export type DiagnosticReport = HealthgraphResource &
  Resource & {
    __typename?: 'DiagnosticReport'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** Details concerning a service requested. */
    basedOn?: Maybe<DiagnosticReportBasedOnResultConnection>
    /** A indication of whether the Diagnostic report has been processed by Babylon or not. */
    bblnProcessState?: Maybe<Scalars['FhirCode']>
    /**
     * A code that classifies the clinical discipline, department or diagnostic
     * service that created the report (e.g. cardiology, biochemistry, hematology,
     * MRI). This is used for searching, sorting and display purposes.
     */
    categories?: Maybe<CodeableConceptConnection>
    /** A code or name that describes this diagnostic report. */
    code?: Maybe<DiagnosticReportCodeResult>
    /** Diagnostic report comment extension */
    comment?: Maybe<Scalars['String']>
    /** Concise and clinically contextualized summary conclusion (interpretation/impression) of the diagnostic report. */
    conclusion?: Maybe<Scalars['String']>
    /**
     * The time or time-period the observed values are related to. When the subject
     * of the report is a patient, this is usually either the time of the procedure
     * or of specimen collection(s), but very often the source of the date/time is
     * not known, only the date/time itself.
     */
    effectiveDateTime?: Maybe<Scalars['FhirDateTime']>
    /** The healthcare event  (e.g. a patient and healthcare provider interaction) which this DiagnosticReport is about. */
    encounter?: Maybe<DiagnosticReportEncounterResult>
    /** Follow up action */
    followUpAction?: Maybe<Scalars['String']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Identifiers assigned to this report by the performer or other systems. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** An indication of whether the contents of the Diagnostic Report are confidential or not (True, False or NULL) */
    isIsConfidential?: Maybe<Scalars['Boolean']>
    /**
     * The date and time that this version of the report was made available to
     * providers, typically after the report was reviewed and verified.
     */
    issued?: Maybe<Scalars['FhirInstant']>
    /** Namespace for external id */
    namespace?: Maybe<Scalars['String']>
    /** The date when the order was created. */
    orderedDate?: Maybe<Scalars['FhirDateTime']>
    /** Original Resource containing one Diagnostic Report and associated Observations */
    originalResource?: Maybe<Scalars['String']>
    /** An indication of the priority level of the diagnostic report (URGENT | NOT URGENT) */
    priority?: Maybe<Scalars['FhirCode']>
    /**
     * An indication of what action, if any, is required after the results have been
     * issued in terms of booking an appointment (e.g. book appointment, no follow up
     * required). Patient decision indicates the clinician has left the choice to the
     * patient choice as to whether they would like to book an appointment.
     */
    recommendedBookAction?: Maybe<Scalars['FhirCode']>
    /** [Observations](observation.html)  that are part of this diagnostic report. */
    results?: Maybe<DiagnosticReportResultResultConnection>
    /** An indication of how the DiagnosticReport was received (e.g. fax, interface, manual, upload) */
    route?: Maybe<Scalars['String']>
    /** The status of the diagnostic report. */
    status?: Maybe<Scalars['FhirCode']>
    /**
     * The subject of the report. Usually, but not always, this is a patient.
     * However, diagnostic services also perform analyses on specimens collected from
     * a variety of other sources.
     */
    subject?: Maybe<DiagnosticReportSubjectResult>
    /**  Information about how trustworthy the resource is; how likely it is to be accurate  */
    trust?: Maybe<DiagnosticReportTrustResult>
  }

/**
 *  The findings and interpretation of diagnostic  tests performed on patients,
 * groups of patients, devices, and locations, and/or specimens derived from these.
 * The report includes clinical context such as requesting and provider
 * information, and some mix of atomic results, images, textual and coded
 * interpretations, and formatted representation of diagnostic reports.
 */
export type DiagnosticReportBasedOnArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  The findings and interpretation of diagnostic  tests performed on patients,
 * groups of patients, devices, and locations, and/or specimens derived from these.
 * The report includes clinical context such as requesting and provider
 * information, and some mix of atomic results, images, textual and coded
 * interpretations, and formatted representation of diagnostic reports.
 */
export type DiagnosticReportCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  The findings and interpretation of diagnostic  tests performed on patients,
 * groups of patients, devices, and locations, and/or specimens derived from these.
 * The report includes clinical context such as requesting and provider
 * information, and some mix of atomic results, images, textual and coded
 * interpretations, and formatted representation of diagnostic reports.
 */
export type DiagnosticReportIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  The findings and interpretation of diagnostic  tests performed on patients,
 * groups of patients, devices, and locations, and/or specimens derived from these.
 * The report includes clinical context such as requesting and provider
 * information, and some mix of atomic results, images, textual and coded
 * interpretations, and formatted representation of diagnostic reports.
 */
export type DiagnosticReportResultsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Details concerning a service requested. */
export type DiagnosticReportBasedOnResult =
  | ExternalResource
  | NonFederatedResource
  | ServiceRequest

/**
 * A page/slice of a list of DiagnosticReportBasedOnResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type DiagnosticReportBasedOnResultConnection = Connection & {
  __typename?: 'DiagnosticReportBasedOnResultConnection'
  /** Edges in the current connection page. */
  edges: Array<DiagnosticReportBasedOnResultEdge>
  /** List of DiagnosticReportBasedOnResult object nodes in the current connection page. */
  nodes: Array<Maybe<DiagnosticReportBasedOnResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a DiagnosticReportBasedOnResult connection page. */
export type DiagnosticReportBasedOnResultEdge = Edge & {
  __typename?: 'DiagnosticReportBasedOnResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the DiagnosticReportBasedOnResult object in this edge. */
  node?: Maybe<DiagnosticReportBasedOnResult>
}

/** A code or name that describes this diagnostic report. */
export type DiagnosticReportCodeResult = CodeableConcept | None

/**
 * A page/slice of a list of DiagnosticReport nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type DiagnosticReportConnection = Connection & {
  __typename?: 'DiagnosticReportConnection'
  /** Edges in the current connection page. */
  edges: Array<DiagnosticReportEdge>
  /** List of DiagnosticReport object nodes in the current connection page. */
  nodes: Array<Maybe<DiagnosticReport>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a DiagnosticReport connection page. */
export type DiagnosticReportEdge = Edge & {
  __typename?: 'DiagnosticReportEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the DiagnosticReport object in this edge. */
  node?: Maybe<DiagnosticReport>
}

/** The healthcare event  (e.g. a patient and healthcare provider interaction) which this DiagnosticReport is about. */
export type DiagnosticReportEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  Specifies a filter to apply to a list of DiagnosticReport  */
export type DiagnosticReportFilter = {
  /**
   * Filters on DiagnosticReport.extension('https://fhir.bbl.health/StructureDefinition/BblBBLNProcessState')
   * Whether or not the diagnostic report has been processed
   */
  _processed?: Maybe<KeywordFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<DiagnosticReportFilter>>
  /**
   * Filters on DiagnosticReport.basedOn
   * Reference to the service request.
   */
  basedOnUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on DiagnosticReport.category
   * Which diagnostic discipline/department created the report
   */
  category?: Maybe<CodeFilter>
  /**
   * Filters on DiagnosticReport.code
   * The code for the report, as opposed to codes for the atomic results, which are
   * the names on the observation resource referred to from the result
   */
  code?: Maybe<CodeFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on DiagnosticReport.effective
   * The clinically relevant time of the report
   */
  date?: Maybe<DateFilter>
  /**
   * Filters on DiagnosticReport.encounter
   * The Encounter when the order was made
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on DiagnosticReport.identifier
   * An identifier for the report
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on DiagnosticReport.issued
   * When the report was issued
   */
  issued?: Maybe<DateFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<DiagnosticReportFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<DiagnosticReportFilter>>
  /**
   * Filters on DiagnosticReport.subject.where(resolve() is Patient)
   * The subject of the report if a patient
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on DiagnosticReport.result
   * Link to an atomic result (observation resource)
   */
  resultUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on DiagnosticReport.status
   * The status of the report
   */
  status?: Maybe<DiagnosticReportStatusFilter>
  /**
   * Filters on DiagnosticReport.subject
   * The subject of the report
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**  Filter by trust values, lets you limit to results that have a certain likelihood of being true  */
  trust?: Maybe<TrustFilter>
}

export type DiagnosticReportResult =
  | AuthorizationError
  | DiagnosticReport
  | ResourceNotFoundError

/** [Observations](observation.html)  that are part of this diagnostic report. */
export type DiagnosticReportResultResult =
  | ExternalResource
  | NonFederatedResource
  | Observation

/**
 * A page/slice of a list of DiagnosticReportResultResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type DiagnosticReportResultResultConnection = Connection & {
  __typename?: 'DiagnosticReportResultResultConnection'
  /** Edges in the current connection page. */
  edges: Array<DiagnosticReportResultResultEdge>
  /** List of DiagnosticReportResultResult object nodes in the current connection page. */
  nodes: Array<Maybe<DiagnosticReportResultResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a DiagnosticReportResultResult connection page. */
export type DiagnosticReportResultResultEdge = Edge & {
  __typename?: 'DiagnosticReportResultResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the DiagnosticReportResultResult object in this edge. */
  node?: Maybe<DiagnosticReportResultResult>
}

export type DiagnosticReportsByIdentifierResult =
  | AuthorizationError
  | DiagnosticReportConnection

/**  A sort on DiagnosticReport - consists of the field to sort on and the ordering  */
export type DiagnosticReportSort = {
  /**  Which field to sort by  */
  field: DiagnosticReportSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that DiagnosticReport can be sorted by  */
export enum DiagnosticReportSortOption {
  /**
   * Sorts on DiagnosticReport.effective
   * The clinically relevant time of the report
   */
  Date = 'DATE',
  /**
   * Sorts on DiagnosticReport.issued
   * When the report was issued
   */
  Issued = 'ISSUED',
  /**  Do not sort  */
  None = 'NONE',
  /**  Sort by how trustworthy the resource is  */
  Trust = 'TRUST',
}

/**  The status of the diagnostic report.  */
export enum DiagnosticReportStatus {
  /**
   *  Subsequent to being final, the report has been modified.  This includes any
   * change in the results, diagnosis, narrative text, or other content of a report
   * that has been issued.
   */
  Amended = 'AMENDED',
  /**  Subsequent to being final, the report has been modified by adding new content. The existing content is unchanged.  */
  Appended = 'APPENDED',
  /**  The report is unavailable because the measurement was not started or not completed (also sometimes called "aborted").  */
  Cancelled = 'CANCELLED',
  /**  Subsequent to being final, the report has been modified  to correct an error in the report or referenced results.  */
  Corrected = 'CORRECTED',
  /**
   *  The report has been withdrawn following a previous final release.  This
   * electronic record should never have existed, though it is possible that
   * real-world decisions were based on it. (If real-world activity has occurred,
   * the status should be "cancelled" rather than "entered-in-error".).
   */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**  The report is complete and verified by an authorized person.  */
  Final = 'FINAL',
  /**  This is a partial (e.g. initial, interim or preliminary) report: data in the report may be incomplete or unverified.  */
  Partial = 'PARTIAL',
  /**  Verified early results are available, but not all  results are final.  */
  Preliminary = 'PRELIMINARY',
  /**  The existence of the report is registered, but there is nothing yet available.  */
  Registered = 'REGISTERED',
  /**
   *  The authoring/source system does not know which of the status values currently
   * applies for this observation. Note: This concept is not to be used for "other"
   * - one of the listed statuses is presumed to apply, but the authoring/source
   * system does not know which.
   */
  Unknown = 'UNKNOWN',
}

/**  The status of the diagnostic report.  */
export type DiagnosticReportStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<DiagnosticReportStatus>>>
}

/**
 * The subject of the report. Usually, but not always, this is a patient. However,
 * diagnostic services also perform analyses on specimens collected from a variety
 * of other sources.
 */
export type DiagnosticReportSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**  Trust information or None if the Trust data is not present  */
export type DiagnosticReportTrustResult = None | Trust

/**
 *  A reference to a document of any kind for any purpose. Provides metadata about
 * the document so that the document can be discovered and managed. The scope of a
 * document is any seralized object with a mime-type, so includes formal patient
 * centric documents (CDA), cliical notes, scanned paper, and non-patient specific
 * documents like policy text.
 */
export type DocumentReference = HealthgraphResource &
  Resource & {
    __typename?: 'DocumentReference'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The document and format referenced. There may be multiple content element repetitions, each with a different format. */
    contents?: Maybe<DocumentReferenceContentConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Other identifiers associated with the document, including version independent identifiers. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** The status of this document reference. */
    status?: Maybe<Scalars['FhirCode']>
  }

/**
 *  A reference to a document of any kind for any purpose. Provides metadata about
 * the document so that the document can be discovered and managed. The scope of a
 * document is any seralized object with a mime-type, so includes formal patient
 * centric documents (CDA), cliical notes, scanned paper, and non-patient specific
 * documents like policy text.
 */
export type DocumentReferenceContentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A reference to a document of any kind for any purpose. Provides metadata about
 * the document so that the document can be discovered and managed. The scope of a
 * document is any seralized object with a mime-type, so includes formal patient
 * centric documents (CDA), cliical notes, scanned paper, and non-patient specific
 * documents like policy text.
 */
export type DocumentReferenceIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of DocumentReference nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type DocumentReferenceConnection = Connection & {
  __typename?: 'DocumentReferenceConnection'
  /** Edges in the current connection page. */
  edges: Array<DocumentReferenceEdge>
  /** List of DocumentReference object nodes in the current connection page. */
  nodes: Array<Maybe<DocumentReference>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**  The document and format referenced. There may be multiple content element repetitions, each with a different format.  */
export type DocumentReferenceContent = {
  __typename?: 'DocumentReferenceContent'
  /** The document or URL of the document along with critical metadata to prove content has integrity. */
  attachment?: Maybe<DocumentReferenceContentAttachmentResult>
}

/** The document or URL of the document along with critical metadata to prove content has integrity. */
export type DocumentReferenceContentAttachmentResult = Attachment | None

/**
 * A page/slice of a list of DocumentReferenceContent nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type DocumentReferenceContentConnection = Connection & {
  __typename?: 'DocumentReferenceContentConnection'
  /** Edges in the current connection page. */
  edges: Array<DocumentReferenceContentEdge>
  /** List of DocumentReferenceContent object nodes in the current connection page. */
  nodes: Array<Maybe<DocumentReferenceContent>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a DocumentReferenceContent connection page. */
export type DocumentReferenceContentEdge = Edge & {
  __typename?: 'DocumentReferenceContentEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the DocumentReferenceContent object in this edge. */
  node?: Maybe<DocumentReferenceContent>
}

/** An edge in a DocumentReference connection page. */
export type DocumentReferenceEdge = Edge & {
  __typename?: 'DocumentReferenceEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the DocumentReference object in this edge. */
  node?: Maybe<DocumentReference>
}

export type DocumentReferenceResult =
  | AuthorizationError
  | DocumentReference
  | ResourceNotFoundError

export type DocumentReferencesByIdentifierResult =
  | AuthorizationError
  | DocumentReferenceConnection

/**  The status of the document reference.  */
export enum DocumentReferenceStatus {
  /**  This is the current reference for this document.  */
  Current = 'CURRENT',
  /**  This reference was created in error.  */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**  This reference has been superseded by another reference.  */
  Superseded = 'SUPERSEDED',
}

/**  The status of the document reference.  */
export type DocumentReferenceStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<DocumentReferenceStatus>>>
}

/**  How the medication is/was taken or should be taken. */
export type Dosage = {
  __typename?: 'Dosage'
  /**  Amount of medication administered. */
  doseAndRate?: Maybe<DosageDoseAndRateConnection>
  /**  A boolean specifying if the medication should be taken as the need arises by the subject. */
  isAsNeededBoolean?: Maybe<Scalars['Boolean']>
  /**  How drug should enter body. */
  route?: Maybe<CodeableConcept>
  /**  When medication should be administered. */
  timing?: Maybe<Timing>
}

/**
 * A page/slice of a list of Dosage nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type DosageConnection = Connection & {
  __typename?: 'DosageConnection'
  /** Edges in the current connection page. */
  edges: Array<DosageEdge>
  /** List of Dosage object nodes in the current connection page. */
  nodes: Array<Maybe<Dosage>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**  The amount of medication administered. */
export type DosageDoseAndRate = {
  __typename?: 'DosageDoseAndRate'
  /**  Amount of medication per dose. */
  doseQuantity?: Maybe<Quantity>
  /**  Amount of medication per unit of time. */
  rateQuantity?: Maybe<Quantity>
}

/**
 * A page/slice of a list of DosageDoseAndRate nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type DosageDoseAndRateConnection = Connection & {
  __typename?: 'DosageDoseAndRateConnection'
  /** Edges in the current connection page. */
  edges: Array<DosageDoseAndRateEdge>
  /** List of DosageDoseAndRate object nodes in the current connection page. */
  nodes: Array<Maybe<DosageDoseAndRate>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a DosageDoseAndRate connection page. */
export type DosageDoseAndRateEdge = Edge & {
  __typename?: 'DosageDoseAndRateEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the DosageDoseAndRate object in this edge. */
  node?: Maybe<DosageDoseAndRate>
}

/** An edge in a Dosage connection page. */
export type DosageEdge = Edge & {
  __typename?: 'DosageEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Dosage object in this edge. */
  node?: Maybe<Dosage>
}

/** Object of download data */
export type DownloadMessageDatum = {
  __typename?: 'DownloadMessageDatum'
  /** Confirmation text to be displayed before downloading the file */
  confirmationText?: Maybe<Scalars['String']>
  /** The name of the file */
  title?: Maybe<Scalars['String']>
  /** URL to download the file */
  url: Scalars['Url']
}

/** Day to Day Care Plan */
export type DtdCarePlan = {
  __typename?: 'DtdCarePlan'
  /** Identifies the encounter the care plan was created in */
  encounter?: Maybe<PatientCareReference>
  /**
   * Unique, canonical identifier for the resource.
   * An opaque string, unless the implementing resource explicitly defines a format.
   * Note, the canonical ID for a given resource may change over time, but an ID
   * will never be reassigned to a different resource.
   */
  id: Scalars['ID']
  /** Identifies the patient or group whose intended care is described by the plan. */
  subject?: Maybe<PatientCareReference>
  /** Title of the care plan */
  title?: Maybe<Scalars['String']>
}

/**  A duration */
export type Duration = {
  __typename?: 'Duration'
  /**  A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['String']>
  /**  How the value should be understood and represented. Can be <, <=, >= or >. Considered "=" if there's no comparator. */
  comparator?: Maybe<Scalars['FhirCode']>
  /**  The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['String']>
  /**  A human-readable form of the unit. */
  unit?: Maybe<Scalars['String']>
  /**  The value of the measured amount. The value includes an implicit precision in the presentation of the value. */
  value?: Maybe<Scalars['Float']>
}

/** An edge in a connection page. */
export type Edge = {
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
}

/** Message object for email */
export type EmailActionMessageData = {
  __typename?: 'EmailActionMessageData'
  /** email address to send the email to */
  email: Scalars['String']
}

/** An email address based ContactPoint */
export type EmailAddressContactPoint = ContactPoint & {
  __typename?: 'EmailAddressContactPoint'
  /** The dateTime at which the contact point was created in our systems */
  createdAt?: Maybe<Scalars['DateTime']>
  /** Value of the contact point as an email address. */
  emailAddress: Scalars['EmailAddress']
  /** Identifier for this email address */
  id?: Maybe<Scalars['ID']>
  /** Whether or not this is the primary contact point of this type */
  isPrimary?: Maybe<Scalars['Boolean']>
  /** A string to describe the source of the contact point */
  source?: Maybe<Scalars['String']>
  /** The dateTime at which the contact point was ingested at the source */
  sourceIngestionTimestamp?: Maybe<Scalars['DateTime']>
  /** A string to describe the status of the contact point */
  status?: Maybe<Scalars['String']>
  /** @inherit */
  system: ContactPointSystem
  /** The dateTime at which the contact point was updated in our systems */
  updatedAt?: Maybe<Scalars['DateTime']>
}

/** An error indicating that a user with this email address is not found. */
export type EmailAddressNotFoundError = Error & {
  __typename?: 'EmailAddressNotFoundError'
  /** The error message. */
  errorMessage: Scalars['String']
}

/** An error indicating that the email specified has not been found in stub profiles. */
export type EmailNoMatchError = Error & {
  __typename?: 'EmailNoMatchError'
  /**
   * Message to assist a developer in understanding and fixing the error.
   *
   * Not intended to be shown directly to an end-user.
   */
  errorMessage: Scalars['String']
}

/** An error indicating that the email specified has already been taken by another patient. */
export type EmailTakenError = Error & {
  __typename?: 'EmailTakenError'
  /**
   * Message to assist a developer in understanding and fixing the error.
   *
   * Not intended to be shown directly to an end-user.
   */
  errorMessage: Scalars['String']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type Encounter = HealthgraphResource &
  Resource & {
    __typename?: 'Encounter'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The appointment that scheduled this encounter. */
    appointments?: Maybe<EncounterAppointmentResultConnection>
    /** The approximated end time of encounter, this is not the actual end time */
    approximatedEndTime?: Maybe<Scalars['FhirDateTime']>
    /**
     * Concepts representing classification of patient encounter such as ambulatory
     * (outpatient), inpatient, emergency, home health or others due to local variations.
     */
    class?: Maybe<EncounterClassResult>
    /**  Communications that this Encounter is the encounter of  */
    communications?: Maybe<CommunicationConnection>
    /**  Compositions that this Encounter is the encounter of  */
    compositions?: Maybe<CompositionConnection>
    /**  Conditions that this Encounter is the encounter of  */
    conditions?: Maybe<ConditionConnection>
    /** The list of diagnosis relevant to this encounter. */
    diagnoses?: Maybe<EncounterDiagnosisConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Identifier(s) by which this encounter is known. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** The base language in which the resource is written. */
    language?: Maybe<Scalars['FhirCode']>
    /** Quantity of time the encounter lasted. This excludes the time during leaves of absence. */
    length?: Maybe<EncounterLengthResult>
    /** List of locations where  the patient has been during this encounter. */
    locations?: Maybe<EncounterLocationConnection>
    /**  Observations that this Encounter is the encounter of  */
    observations?: Maybe<ObservationConnection>
    /** The list of people responsible for providing the service. */
    participants?: Maybe<EncounterParticipantConnection>
    /** The start and end time of the encounter. */
    period?: Maybe<EncounterPeriodResult>
    /** Indicates the urgency of the encounter. */
    priority?: Maybe<EncounterPriorityResult>
    /**  QuestionnaireResponses that this Encounter is the encounter of  */
    questionnaireResponses?: Maybe<QuestionnaireResponseConnection>
    /** Reason the encounter takes place, expressed as a code. For admissions, this can be used for a coded admission diagnosis. */
    reasonCodes?: Maybe<CodeableConceptConnection>
    /**  RiskAssessments that this Encounter is the encounter of  */
    riskAssessments?: Maybe<RiskAssessmentConnection>
    /** The scheduled start time of encounter, this is not the actual start time */
    scheduledStartTime?: Maybe<Scalars['FhirDateTime']>
    /** Broad categorization of the service that is to be provided (e.g. cardiology). */
    serviceType?: Maybe<EncounterServiceTypeResult>
    /** planned | arrived | triaged | in-progress | onleave | finished | cancelled +. */
    status?: Maybe<Scalars['FhirCode']>
    /** The patient or group present at the encounter. */
    subject?: Maybe<EncounterSubjectResult>
    /** Specific type of encounter (e.g. e-mail consultation, surgical day-care, skilled nursing, rehabilitation). */
    types?: Maybe<CodeableConceptConnection>
  }

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterAppointmentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterCommunicationsArgs = {
  _filter?: Maybe<CommunicationFilter>
  _sort?: Maybe<Array<Maybe<CommunicationSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterCompositionsArgs = {
  _filter?: Maybe<CompositionFilter>
  _sort?: Maybe<Array<Maybe<CompositionSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterConditionsArgs = {
  _filter?: Maybe<ConditionFilter>
  _sort?: Maybe<Array<Maybe<ConditionSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterDiagnosesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterLocationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterObservationsArgs = {
  _filter?: Maybe<ObservationFilter>
  _sort?: Maybe<Array<Maybe<ObservationSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterParticipantsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterQuestionnaireResponsesArgs = {
  _filter?: Maybe<QuestionnaireResponseFilter>
  _sort?: Maybe<Array<Maybe<QuestionnaireResponseSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterReasonCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterRiskAssessmentsArgs = {
  _filter?: Maybe<RiskAssessmentFilter>
  _sort?: Maybe<Array<Maybe<RiskAssessmentSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An interaction between a patient and healthcare provider(s) for the purpose of
 * providing healthcare service(s) or assessing the health status of a patient.
 */
export type EncounterTypesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The appointment that scheduled this encounter. */
export type EncounterAppointmentResult =
  | Appointment
  | ExternalResource
  | NonFederatedResource

/**
 * A page/slice of a list of EncounterAppointmentResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type EncounterAppointmentResultConnection = Connection & {
  __typename?: 'EncounterAppointmentResultConnection'
  /** Edges in the current connection page. */
  edges: Array<EncounterAppointmentResultEdge>
  /** List of EncounterAppointmentResult object nodes in the current connection page. */
  nodes: Array<Maybe<EncounterAppointmentResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a EncounterAppointmentResult connection page. */
export type EncounterAppointmentResultEdge = Edge & {
  __typename?: 'EncounterAppointmentResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the EncounterAppointmentResult object in this edge. */
  node?: Maybe<EncounterAppointmentResult>
}

/**
 * Concepts representing classification of patient encounter such as ambulatory
 * (outpatient), inpatient, emergency, home health or others due to local variations.
 */
export type EncounterClassResult = Coding | None

/**
 * A page/slice of a list of Encounter nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type EncounterConnection = Connection & {
  __typename?: 'EncounterConnection'
  /** Edges in the current connection page. */
  edges: Array<EncounterEdge>
  /** List of Encounter object nodes in the current connection page. */
  nodes: Array<Maybe<Encounter>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**  The list of diagnosis relevant to this encounter.  */
export type EncounterDiagnosis = {
  __typename?: 'EncounterDiagnosis'
  /**
   * Reason the encounter takes place, as specified using information from another
   * resource. For admissions, this is the admission diagnosis. The indication will
   * typically be a Condition (with other resources referenced in the
   * evidence.detail), or a Procedure.
   */
  condition?: Maybe<EncounterDiagnosisConditionResult>
  /** Role that this diagnosis has within the encounter (e.g. admission, billing, discharge …). */
  use?: Maybe<EncounterDiagnosisUseResult>
}

/**
 * Reason the encounter takes place, as specified using information from another
 * resource. For admissions, this is the admission diagnosis. The indication will
 * typically be a Condition (with other resources referenced in the
 * evidence.detail), or a Procedure.
 */
export type EncounterDiagnosisConditionResult =
  | Condition
  | ExternalResource
  | None
  | NonFederatedResource

/**
 * A page/slice of a list of EncounterDiagnosis nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type EncounterDiagnosisConnection = Connection & {
  __typename?: 'EncounterDiagnosisConnection'
  /** Edges in the current connection page. */
  edges: Array<EncounterDiagnosisEdge>
  /** List of EncounterDiagnosis object nodes in the current connection page. */
  nodes: Array<Maybe<EncounterDiagnosis>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a EncounterDiagnosis connection page. */
export type EncounterDiagnosisEdge = Edge & {
  __typename?: 'EncounterDiagnosisEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the EncounterDiagnosis object in this edge. */
  node?: Maybe<EncounterDiagnosis>
}

/** Role that this diagnosis has within the encounter (e.g. admission, billing, discharge …). */
export type EncounterDiagnosisUseResult = CodeableConcept | None

/** An edge in a Encounter connection page. */
export type EncounterEdge = Edge & {
  __typename?: 'EncounterEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Encounter object in this edge. */
  node?: Maybe<Encounter>
}

/**  Specifies a filter to apply to a list of Encounter  */
export type EncounterFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<EncounterFilter>>
  /**
   * Filters on Encounter.appointment
   * The appointment that scheduled this encounter
   */
  appointmentUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Encounter.class
   * Classification of patient encounter
   */
  class?: Maybe<CodeFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Encounter.period
   * A date within the period the Encounter lasted
   */
  date?: Maybe<DateFilter>
  /**
   * Filters on Encounter.diagnosis.condition
   * The diagnosis or procedure relevant to the encounter
   */
  diagnosisUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Encounter.identifier
   * Identifier(s) by which this encounter is known
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on Encounter.location.location
   * Location the encounter takes place
   */
  locationUri?: Maybe<ResourceUriFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<EncounterFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<EncounterFilter>>
  /**
   * Filters on Encounter.participant.type
   * Role of participant in encounter
   */
  participantType?: Maybe<CodeFilter>
  /**
   * Filters on Encounter.participant.individual
   * Persons involved in the encounter other than the patient
   */
  participantUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Encounter.subject.where(resolve() is Patient)
   * The patient or group present at the encounter
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Encounter.participant.individual.where(resolve() is Practitioner)
   * Persons involved in the encounter other than the patient
   */
  practitionerUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Filters on Encounter.reasonCode
   * Coded reason the encounter takes place
   */
  reasonCode?: Maybe<CodeFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Encounter.status
   * planned | arrived | triaged | in-progress | onleave | finished | cancelled +
   */
  status?: Maybe<EncounterStatusFilter>
  /**
   * Filters on Encounter.subject
   * The patient or group present at the encounter
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Encounter.type
   * Specific type of encounter
   */
  type?: Maybe<CodeFilter>
}

/** Quantity of time the encounter lasted. This excludes the time during leaves of absence. */
export type EncounterLengthResult = Duration | None

/**  List of locations where  the patient has been during this encounter.  */
export type EncounterLocation = {
  __typename?: 'EncounterLocation'
  /** The location where the encounter takes place. */
  location?: Maybe<EncounterLocationLocationResult>
}

/**
 * A page/slice of a list of EncounterLocation nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type EncounterLocationConnection = Connection & {
  __typename?: 'EncounterLocationConnection'
  /** Edges in the current connection page. */
  edges: Array<EncounterLocationEdge>
  /** List of EncounterLocation object nodes in the current connection page. */
  nodes: Array<Maybe<EncounterLocation>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a EncounterLocation connection page. */
export type EncounterLocationEdge = Edge & {
  __typename?: 'EncounterLocationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the EncounterLocation object in this edge. */
  node?: Maybe<EncounterLocation>
}

/** The location where the encounter takes place. */
export type EncounterLocationLocationResult =
  | ExternalResource
  | Location
  | None
  | NonFederatedResource

/**  The list of people responsible for providing the service.  */
export type EncounterParticipant = {
  __typename?: 'EncounterParticipant'
  /** Persons involved in the encounter other than the patient. */
  individual?: Maybe<EncounterParticipantIndividualResult>
  /**
   * The period of time that the specified participant participated in the
   * encounter. These can overlap or be sub-sets of the overall encounter's period.
   */
  period?: Maybe<EncounterParticipantPeriodResult>
  /** Role of participant in encounter. */
  types?: Maybe<CodeableConceptConnection>
}

/**  The list of people responsible for providing the service.  */
export type EncounterParticipantTypesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of EncounterParticipant nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type EncounterParticipantConnection = Connection & {
  __typename?: 'EncounterParticipantConnection'
  /** Edges in the current connection page. */
  edges: Array<EncounterParticipantEdge>
  /** List of EncounterParticipant object nodes in the current connection page. */
  nodes: Array<Maybe<EncounterParticipant>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a EncounterParticipant connection page. */
export type EncounterParticipantEdge = Edge & {
  __typename?: 'EncounterParticipantEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the EncounterParticipant object in this edge. */
  node?: Maybe<EncounterParticipant>
}

/** Persons involved in the encounter other than the patient. */
export type EncounterParticipantIndividualResult =
  | ExternalResource
  | HealthgraphRelatedPerson
  | None
  | NonFederatedResource
  | Practitioner
  | PractitionerRole

/**
 * The period of time that the specified participant participated in the encounter.
 * These can overlap or be sub-sets of the overall encounter's period.
 */
export type EncounterParticipantPeriodResult = None | Period

/** The start and end time of the encounter. */
export type EncounterPeriodResult = None | Period

/** Indicates the urgency of the encounter. */
export type EncounterPriorityResult = CodeableConcept | None

export type EncounterResult =
  | AuthorizationError
  | Encounter
  | ResourceNotFoundError

export type EncountersByIdentifierResult =
  | AuthorizationError
  | EncounterConnection

/** Broad categorization of the service that is to be provided (e.g. cardiology). */
export type EncounterServiceTypeResult = CodeableConcept | None

/**  A sort on Encounter - consists of the field to sort on and the ordering  */
export type EncounterSort = {
  /**  Which field to sort by  */
  field: EncounterSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Encounter can be sorted by  */
export enum EncounterSortOption {
  /**
   * Sorts on Encounter.period
   * A date within the period the Encounter lasted
   */
  Date = 'DATE',
  /**  Do not sort  */
  None = 'NONE',
}

/**  Current state of the encounter.  */
export enum EncounterStatus {
  /**  The Patient is present for the encounter, however is not currently meeting with a practitioner.  */
  Arrived = 'ARRIVED',
  /**  The Encounter has ended before it has begun.  */
  Cancelled = 'CANCELLED',
  /**  This instance should not have been part of this patient's medical record.  */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**  The Encounter has ended.  */
  Finished = 'FINISHED',
  /**  The Encounter has begun and the patient is present / the practitioner and the patient are meeting.  */
  InProgress = 'IN_PROGRESS',
  /**  The Encounter has begun, but the patient is temporarily on leave.  */
  Onleave = 'ONLEAVE',
  /**  The Encounter has not yet started.  */
  Planned = 'PLANNED',
  /**  The patient has been assessed for the priority of their treatment based on the severity of their condition.  */
  Triaged = 'TRIAGED',
  /**
   *  The encounter status is unknown. Note that "unknown" is a value of last resort
   * and every attempt should be made to provide a meaningful value other than "unknown".
   */
  Unknown = 'UNKNOWN',
}

/**  Current state of the encounter.  */
export type EncounterStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<EncounterStatus>>>
}

/** The patient or group present at the encounter. */
export type EncounterSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/** An error which may occur during normal usage of the API. */
export type Error = {
  /**
   * Message to assist a developer in understanding and fixing the error.
   *
   * Not intended to be shown directly to an end-user.
   */
  errorMessage: Scalars['String']
}

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN',
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   * fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN',
}

/**  Codes identifying the lifecycle stage of an event.  */
export enum EventStatus {
  /**  The event has now concluded.  */
  Completed = 'COMPLETED',
  /**
   *  This electronic record should never have existed, though it is possible that
   * real-world decisions were based on it.  (If real-world activity has occurred,
   * the status should be "stopped" rather than "entered-in-error".).
   */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**  The event is currently occurring.  */
  InProgress = 'IN_PROGRESS',
  /**
   *  The event was terminated prior to any activity beyond preparation.  I.e. The
   * 'main' activity has not yet begun.  The boundary between preparatory and the
   * 'main' activity is context-specific.
   */
  NotDone = 'NOT_DONE',
  /**  The event has been temporarily stopped but is expected to resume in the future.  */
  OnHold = 'ON_HOLD',
  /**
   *  The core event has not started yet, but some staging activities have begun
   * (e.g. surgical suite preparation).  Preparation stages may be tracked for
   * billing purposes.
   */
  Preparation = 'PREPARATION',
  /**
   *  The event was terminated prior to the full completion of the intended activity
   * but after at least some of the 'main' activity (beyond preparation) has occurred.
   */
  Stopped = 'STOPPED',
  /**
   *  The authoring/source system does not know which of the status values currently
   * applies for this event.  Note: This concept is not to be used for "other" -
   * one of the listed statuses is presumed to apply,  but the authoring/source
   * system does not know which.
   */
  Unknown = 'UNKNOWN',
}

/**  Codes identifying the lifecycle stage of an event.  */
export type EventStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<EventStatus>>>
}

/**  A reference to an external FHIR resource that is not stored in babylon or is not accessible through this graph */
export type ExternalResource = {
  __typename?: 'ExternalResource'
  /**  Text alternative for the resource */
  display?: Maybe<Scalars['String']>
  /**  Logical reference, when literal reference is not known */
  identifier?: Maybe<HealthgraphIdentifier>
  /**  Literal reference, Relative, internal or absolute URL */
  reference?: Maybe<Scalars['String']>
  /**  Type the reference refers to (e.g. "Patient") */
  type?: Maybe<Scalars['Uri']>
}

/** A fax-number based ContactPoint */
export type FaxNumberContactPoint = ContactPoint & {
  __typename?: 'FaxNumberContactPoint'
  /** The dateTime at which the contact point was created in our systems */
  createdAt?: Maybe<Scalars['DateTime']>
  /** Country code for the fax number, e.g. 44 */
  faxCountryCode: Scalars['String']
  /** Value of the contact point as a fax number. */
  faxNumber: Scalars['String']
  /** @inherit */
  system: ContactPointSystem
  /** The dateTime at which the contact point was updated in our systems */
  updatedAt?: Maybe<Scalars['DateTime']>
}

/**
 * A page/slice of a list of FhirCode nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type FhirCodeConnection = Connection & {
  __typename?: 'FhirCodeConnection'
  /** Edges in the current connection page. */
  edges: Array<FhirCodeEdge>
  /** List of FhirCode object nodes in the current connection page. */
  nodes: Array<Maybe<Scalars['FhirCode']>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a FhirCode connection page. */
export type FhirCodeEdge = Edge & {
  __typename?: 'FhirCodeEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the FhirCode object in this edge. */
  node?: Maybe<Scalars['FhirCode']>
}

/**
 * A page/slice of a list of FhirDateTime nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type FhirDateTimeConnection = Connection & {
  __typename?: 'FhirDateTimeConnection'
  /** Edges in the current connection page. */
  edges: Array<FhirDateTimeEdge>
  /** List of FhirDateTime object nodes in the current connection page. */
  nodes: Array<Maybe<Scalars['FhirDateTime']>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a FhirDateTime connection page. */
export type FhirDateTimeEdge = Edge & {
  __typename?: 'FhirDateTimeEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the FhirDateTime object in this edge. */
  node?: Maybe<Scalars['FhirDateTime']>
}

/**  This value set includes Status codes.  */
export enum FinancialResourceStatusCode {
  /**  The instance is currently in-force.  */
  Active = 'ACTIVE',
  /**  The instance is withdrawn, rescinded or reversed.  */
  Cancelled = 'CANCELLED',
  /**  A new instance the contents of which is not complete.  */
  Draft = 'DRAFT',
  /**  The instance was entered in error.  */
  EnteredInError = 'ENTERED_IN_ERROR',
}

/**  This value set includes Status codes.  */
export type FinancialResourceStatusCodeFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<FinancialResourceStatusCode>>>
}

/** Patient matches for registration. */
export type FindPatientRegistrationMatch = {
  __typename?: 'FindPatientRegistrationMatch'
  /** Identifiers for the adult patient matches. */
  adultIdentifiers?: Maybe<FindPatientRegistrationMatchIdentifierConnection>
  /** Whether any adult profiles have matched using the full details for the given member. */
  hasAdultMatched: Scalars['Boolean']
  /** Whether any minor profiles have matched using the address details for the given member. */
  hasDependentMatched: Scalars['Boolean']
}

/** Patient matches for registration. */
export type FindPatientRegistrationMatchAdultIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats). */
export type FindPatientRegistrationMatchAddressInput = {
  /** Street name, number, direction & P.O. Box etc. */
  line: Array<Scalars['String']>
  /** Postal code for area */
  postalCode: Scalars['PostalCode']
}

/** A name of a human broken into its constituent parts and usage information. */
export type FindPatientRegistrationMatchHumanNameInput = {
  /** Family name (often called 'Surname') */
  family: Scalars['String']
  /** Given names (not always 'first'). Includes middle names. */
  given: Array<Scalars['String']>
}

/** A numeric or alphanumeric string that is associated with a single object or entity within a given system. */
export type FindPatientRegistrationMatchIdentifier = {
  __typename?: 'FindPatientRegistrationMatchIdentifier'
  /** The system which defines this identifier. */
  system: Scalars['Uri']
}

/**
 * A page/slice of a list of nodes of type
 * `FindPatientRegistrationMatchIdentifier`, allowing for standardised
 * pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type FindPatientRegistrationMatchIdentifierConnection = Connection & {
  __typename?: 'FindPatientRegistrationMatchIdentifierConnection'
  /** Edges in the current connection page. */
  edges: Array<FindPatientRegistrationMatchIdentifierEdge>
  /** List of FindPatientRegistrationMatchIdentifier object nodes in the current connection page. */
  nodes: Array<Maybe<FindPatientRegistrationMatchIdentifier>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An edge in a connection page for a node of type `FindPatientRegistrationMatchIdentifier`.
 *
 * See the [GraphQL Edges Specification](https://relay.dev/graphql/connections.htm#sec-Edges) for guidance on how to use.
 */
export type FindPatientRegistrationMatchIdentifierEdge = Edge & {
  __typename?: 'FindPatientRegistrationMatchIdentifierEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<FindPatientRegistrationMatchIdentifier>
}

/** Details to find a matching patient for registration. */
export type FindPatientRegistrationMatchInput = {
  /** The date on which the patient was born. */
  birthDate: Scalars['Date']
  /**
   * The primary address for the patient (if provided).
   *
   * Additional addresses can be added using the `addPatientAddress` mutation.
   */
  primaryAddress: FindPatientRegistrationMatchAddressInput
  /**
   * The primary name for the patient (if provided).
   *
   * Additional names can be added using the `addPatientName` mutation.
   */
  primaryName: FindPatientRegistrationMatchHumanNameInput
}

export type FindPatientRegistrationMatchResult = FindPatientRegistrationMatch

/** Settings used when requesting the deletion of a patient. */
export type FirstmileRequestPatientAccountDeletionInput = {
  instructions?: Maybe<Scalars['String']>
}

/**
 * A page/slice of a list of Float nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type FloatConnection = Connection & {
  __typename?: 'FloatConnection'
  /** Edges in the current connection page. */
  edges: Array<FloatEdge>
  /** List of Float object nodes in the current connection page. */
  nodes: Array<Maybe<Scalars['Float']>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Float connection page. */
export type FloatEdge = Edge & {
  __typename?: 'FloatEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Float object in this edge. */
  node?: Maybe<Scalars['Float']>
}

/** Message objecct for forms */
export type FormMessageInput = {
  __typename?: 'FormMessageInput'
  /**
   * deprecated
   * @deprecated Not intended for external usage
   */
  empty?: Maybe<Scalars['Void']>
}

export type GdprDeleteUserResult = ResourceNotFoundError | User

/** Input required to perform GDPR deletion */
export type GdprDeletionInput = {
  id: Scalars['ID']
}

/**
 * Administrative Gender
 *
 * see: https://www.hl7.org/fhir/valueset-administrative-gender.html
 */
export type Gender = {
  __typename?: 'Gender'
  /**
   * User-friendly name of this gender
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `gender` from the `Patient` type instead.
   */
  displayName: Scalars['String']
  /**
   * Unique Gender ID
   *
   * Supported values: male | female
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `gender` from the `Patient` type instead.
   */
  id: Scalars['ID']
}

/** General conversation object */
export type GeneralConversationData = {
  /** if true, the conversation will be created in debug mode */
  debug?: Maybe<Scalars['Boolean']>
}

/**  Filter to specify which identifiers to request. */
export type GetByIdentifierFilter = {
  /**  Selected fields must match at least one of the given options. */
  ONE_OF: Array<GetByIdentifierFilterOption>
}

/**  An option for what an Identifier can be. You must provide a system and a value. */
export type GetByIdentifierFilterOption = {
  /**  The namespace for the identifier value. */
  system: Scalars['Uri']
  /**  The identifier value. */
  value: Scalars['String']
}

/**
 *  Describes the intended objective(s) for a patient, group or organization care,
 * for example, weight loss, restoring an activity of daily living, obtaining herd
 * immunity via immunization, meeting a process improvement objective, etc.
 */
export type Goal = HealthgraphResource &
  Resource & {
    __typename?: 'Goal'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** Describes the progression, or lack thereof, towards the goal against the target. */
    achievementStatus?: Maybe<GoalAchievementStatusResult>
    /** Indicates a category the goal falls within. */
    categories?: Maybe<CodeableConceptConnection>
    /**
     * Human-readable and/or coded description of a specific desired objective of
     * care, such as "control blood pressure" or "negotiate an obstacle course" or
     * "dance with child at wedding".
     */
    description?: Maybe<GoalDescriptionResult>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Business identifiers assigned to this goal by the performer or other systems
     * which remain constant as the resource is updated and propagates from server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** The state of the goal throughout its lifecycle. */
    lifecycleStatus?: Maybe<Scalars['FhirCode']>
    /** The date or event after which the goal should begin being pursued. */
    startDate?: Maybe<Scalars['FhirDate']>
    /** Identifies the patient, group or organization for whom the goal is being established. */
    subject?: Maybe<GoalSubjectResult>
  }

/**
 *  Describes the intended objective(s) for a patient, group or organization care,
 * for example, weight loss, restoring an activity of daily living, obtaining herd
 * immunity via immunization, meeting a process improvement objective, etc.
 */
export type GoalCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Describes the intended objective(s) for a patient, group or organization care,
 * for example, weight loss, restoring an activity of daily living, obtaining herd
 * immunity via immunization, meeting a process improvement objective, etc.
 */
export type GoalIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Describes the progression, or lack thereof, towards the goal against the target. */
export type GoalAchievementStatusResult = CodeableConcept | None

/**
 * A page/slice of a list of Goal nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type GoalConnection = Connection & {
  __typename?: 'GoalConnection'
  /** Edges in the current connection page. */
  edges: Array<GoalEdge>
  /** List of Goal object nodes in the current connection page. */
  nodes: Array<Maybe<Goal>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * Human-readable and/or coded description of a specific desired objective of care,
 * such as "control blood pressure" or "negotiate an obstacle course" or "dance
 * with child at wedding".
 */
export type GoalDescriptionResult = CodeableConcept | None

/** An edge in a Goal connection page. */
export type GoalEdge = Edge & {
  __typename?: 'GoalEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Goal object in this edge. */
  node?: Maybe<Goal>
}

/**  Codes that reflect the current state of a goal and whether the goal is still being targeted.  */
export enum GoalLifecycleStatus {
  /**  A proposed goal was accepted or acknowledged.  */
  Accepted = 'ACCEPTED',
  /**  The goal is being sought actively.  */
  Active = 'ACTIVE',
  /**  The goal has been abandoned.  */
  Cancelled = 'CANCELLED',
  /**  The goal is no longer being sought.  */
  Completed = 'COMPLETED',
  /**  The goal was entered in error and voided.  */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**  The goal remains a long term objective but is no longer being actively pursued for a temporary period of time.  */
  OnHold = 'ON_HOLD',
  /**  A goal is planned for this patient.  */
  Planned = 'PLANNED',
  /**  A goal is proposed for this patient.  */
  Proposed = 'PROPOSED',
  /**  A proposed goal was rejected.  */
  Rejected = 'REJECTED',
}

/**  Codes that reflect the current state of a goal and whether the goal is still being targeted.  */
export type GoalLifecycleStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<GoalLifecycleStatus>>>
}

export type GoalResult = AuthorizationError | Goal | ResourceNotFoundError

export type GoalsByIdentifierResult = AuthorizationError | GoalConnection

/** Identifies the patient, group or organization for whom the goal is being established. */
export type GoalSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/** Available details of a GP Surgery */
export type GpOrganization = {
  __typename?: 'GPOrganization'
  /** ID */
  id: Scalars['ID']
  /** Name of the GP surgery */
  name: Scalars['String']
  /** Contact Details */
  telecom?: Maybe<ContactPointConnection>
}

/** Available details of a GP Surgery */
export type GpOrganizationTelecomArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * A page/slice of a list of GPOrganization nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type GpOrganizationConnection = Connection & {
  __typename?: 'GPOrganizationConnection'
  /** Edges in the current connection page. */
  edges: Array<GpOrganizationEdge>
  /** List of ContactPoint object nodes in the current connection page. */
  nodes: Array<Maybe<GpOrganization>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ContactPoint connection page. */
export type GpOrganizationEdge = Edge & {
  __typename?: 'GPOrganizationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the GPOrganization object in this edge. */
  node?: Maybe<GpOrganization>
}

/** Input for filtering GP Organizations */
export type GpOrganizationsFilter = {
  /** Filters on the faxNumber of GPOrganization */
  faxNumber?: Maybe<Scalars['String']>
  /** Filters on the name of GPOrganization */
  name?: Maybe<Scalars['String']>
}

/**
 *  Represents a defined collection of entities that may be discussed or acted upon
 * collectively but which are not expected to act collectively, and are not
 * formally or legally recognized; i.e. a collection of entities that isn't an Organization.
 */
export type Group = HealthgraphResource &
  Resource & {
    __typename?: 'Group'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The time at which the cohort was created */
    created?: Maybe<Scalars['FhirDateTime']>
    /** Cron tab for defining when this cohort should be run */
    cron?: Maybe<Scalars['String']>
    /** The id of the cohort definition */
    definitionId?: Maybe<Scalars['Uuid']>
    /** <<<ADD DESCRIPTION HERE>>> */
    definitionVersion?: Maybe<GroupDefinitionVersionResult>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** A unique business identifier for this group. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * If true, indicates that the resource refers to a specific group of real
     * individuals.  If false, the group defines a set of intended individuals.
     */
    isActual?: Maybe<Scalars['Boolean']>
    /** Is this cohort archived? */
    isArchived?: Maybe<Scalars['Boolean']>
    /** Was the cohort manually curated (as opposed to the result of a query run) */
    isIsManual?: Maybe<Scalars['Boolean']>
    /** Entity responsible for defining and maintaining Group characteristics and/or registered members. */
    managingEntity?: Maybe<GroupManagingEntityResult>
    /** The team managing the cohort */
    managingTeam?: Maybe<Scalars['String']>
    /** A label assigned to the group for human identification and communication. */
    name?: Maybe<Scalars['String']>
    /** Opensearch path in a query result to deduplicate a result on. Multiple paths mean ALL must match */
    openSearchDeduplicationPaths?: Maybe<StringConnection>
    /** The OpenSearch index on which the query is executed */
    openSearchIndex?: Maybe<Scalars['String']>
    /** The Opensearch query used to define and fetch the cohort */
    openSearchQuery?: Maybe<Scalars['String']>
    /** A count of the number of resource instances that are part of the group. */
    quantity?: Maybe<Scalars['NonNegativeInt']>
    /** <<<ADD DESCRIPTION HERE>>> */
    reviewingEntity?: Maybe<GroupReviewingEntityResult>
    /**
     * Short name at most 26 chars containing only lowercase alphanumeric characters
     * and hyphen. More stable than name and is effectively immutable if present.
     */
    shortName?: Maybe<Scalars['String']>
    /** Is the cohort running, ingesting, or completed */
    status?: Maybe<Scalars['FhirCode']>
    /**
     * A human-readable narrative that contains a summary of the resource and can be
     * used to represent the content of the resource to a human. The narrative need
     * not encode all the structured data, but is required to contain sufficient
     * detail to make it "clinically safe" for a human to just read the narrative.
     * Resource definitions may define what content should be represented in the
     * narrative to ensure clinical safety.
     */
    text?: Maybe<GroupTextResult>
    /** Identifies the broad classification of the kind of resources the group includes. */
    type?: Maybe<Scalars['FhirCode']>
  }

/**
 *  Represents a defined collection of entities that may be discussed or acted upon
 * collectively but which are not expected to act collectively, and are not
 * formally or legally recognized; i.e. a collection of entities that isn't an Organization.
 */
export type GroupIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Represents a defined collection of entities that may be discussed or acted upon
 * collectively but which are not expected to act collectively, and are not
 * formally or legally recognized; i.e. a collection of entities that isn't an Organization.
 */
export type GroupOpenSearchDeduplicationPathsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of Group nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type GroupConnection = Connection & {
  __typename?: 'GroupConnection'
  /** Edges in the current connection page. */
  edges: Array<GroupEdge>
  /** List of Group object nodes in the current connection page. */
  nodes: Array<Maybe<Group>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** <<<ADD DESCRIPTION HERE>>> */
export type GroupDefinitionVersionResult =
  | ExternalResource
  | Group
  | None
  | NonFederatedResource

/** An edge in a Group connection page. */
export type GroupEdge = Edge & {
  __typename?: 'GroupEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Group object in this edge. */
  node?: Maybe<Group>
}

/** Entity responsible for defining and maintaining Group characteristics and/or registered members. */
export type GroupManagingEntityResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

export type GroupResult = AuthorizationError | Group | ResourceNotFoundError

/** <<<ADD DESCRIPTION HERE>>> */
export type GroupReviewingEntityResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

export type GroupsByIdentifierResult = AuthorizationError | GroupConnection

/**
 * A human-readable narrative that contains a summary of the resource and can be
 * used to represent the content of the resource to a human. The narrative need not
 * encode all the structured data, but is required to contain sufficient detail to
 * make it "clinically safe" for a human to just read the narrative. Resource
 * definitions may define what content should be represented in the narrative to
 * ensure clinical safety.
 */
export type GroupTextResult = Narrative | None

/**  Types of resources that are part of group.  */
export enum GroupType {
  /**  Group contains "animal" Patient resources.  */
  Animal = 'ANIMAL',
  /**  Group contains Device resources.  */
  Device = 'DEVICE',
  /**  Group contains Medication resources.  */
  Medication = 'MEDICATION',
  /**  Group contains "person" Patient resources.  */
  Person = 'PERSON',
  /**  Group contains healthcare practitioner resources (Practitioner or PractitionerRole).  */
  Practitioner = 'PRACTITIONER',
  /**  Group contains Substance resources.  */
  Substance = 'SUBSTANCE',
}

/**  Types of resources that are part of group.  */
export type GroupTypeFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<GroupType>>>
}

export type HealthcareIdentifier = {
  __typename?: 'HealthcareIdentifier'
  id?: Maybe<Scalars['NonNegativeInt']>
  /** User's private healthcare identifier */
  privateIdentifier?: Maybe<Scalars['String']>
  /** User's public healthcare identifier */
  publicIdentifier?: Maybe<Scalars['String']>
  /** User's unconfirmed public healthcare identifier */
  unconfirmedPublicIdentifier?: Maybe<Scalars['String']>
}

/** The details of a healthcare service available at a location. */
export type HealthcareService = HealthgraphResource &
  Resource & {
    __typename?: 'HealthcareService'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** A collection of times that the Service Site is available. */
    availableTimes?: Maybe<HealthcareServiceAvailableTimeConnection>
    /**
     * Any additional description of the service and/or any specific issues not
     * covered by the other attributes, which can be displayed as further detail
     * under the serviceName.
     */
    comment?: Maybe<Scalars['String']>
    /** Babylon unique Identifier */
    id: Scalars['ID']
    /**
     * The id field and a list of dfhir items.
     * Intended to be used by incoming mutation requests to pass updated data so that healthgraph can return the up-to-date
     * data back. This field is not meant to be read and in fact would return an empty result for dfhir if it was requested.
     * It is in the form of a json scalar. Each dfhir item consists of template name, domain, template version, the actual
     * dfhir data, and the uuid of the owner of the data.
     */
    idAndDfhir?: Maybe<Scalars['IdAndDfhir']>
    /** External identifiers for this item. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * This flag is used to mark the record to not be used. This is not used when a
     * center is closed for maintenance, or for holidays, the notAvailable period is
     * to be used for this.
     */
    isActive?: Maybe<Scalars['Boolean']>
    /** The location(s) where this healthcare service may be provided. */
    locations?: Maybe<HealthcareServiceLocationResultConnection>
    /** Further description of the service as it would be presented to a consumer while searching. */
    name?: Maybe<Scalars['String']>
    /** The organization that provides this healthcare service. */
    providedBy?: Maybe<HealthcareServiceProvidedByResult>
    /** Ways that the service accepts referrals, if this is not provided then it is implied that no referral is required. */
    referralMethods?: Maybe<CodeableConceptConnection>
    /** List of contacts related to this specific healthcare service. */
    telecoms?: Maybe<ContactPointConnection>
    /** The specific type of service that may be delivered or performed. */
    types?: Maybe<CodeableConceptConnection>
  }

/** The details of a healthcare service available at a location. */
export type HealthcareServiceAvailableTimesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The details of a healthcare service available at a location. */
export type HealthcareServiceIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The details of a healthcare service available at a location. */
export type HealthcareServiceLocationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The details of a healthcare service available at a location. */
export type HealthcareServiceReferralMethodsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The details of a healthcare service available at a location. */
export type HealthcareServiceTelecomsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The details of a healthcare service available at a location. */
export type HealthcareServiceTypesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A collection of times that the Service Site is available.  */
export type HealthcareServiceAvailableTime = {
  __typename?: 'HealthcareServiceAvailableTime'
  /** Indicates which days of the week are available between the start and end Times. */
  daysOfWeeks?: Maybe<FhirCodeConnection>
  /** Is this always available? (hence times are irrelevant) e.g. 24 hour service. */
  isAllDay?: Maybe<Scalars['Boolean']>
}

/**  A collection of times that the Service Site is available.  */
export type HealthcareServiceAvailableTimeDaysOfWeeksArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of HealthcareServiceAvailableTime nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type HealthcareServiceAvailableTimeConnection = Connection & {
  __typename?: 'HealthcareServiceAvailableTimeConnection'
  /** Edges in the current connection page. */
  edges: Array<HealthcareServiceAvailableTimeEdge>
  /** List of HealthcareServiceAvailableTime object nodes in the current connection page. */
  nodes: Array<Maybe<HealthcareServiceAvailableTime>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a HealthcareServiceAvailableTime connection page. */
export type HealthcareServiceAvailableTimeEdge = Edge & {
  __typename?: 'HealthcareServiceAvailableTimeEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the HealthcareServiceAvailableTime object in this edge. */
  node?: Maybe<HealthcareServiceAvailableTime>
}

/**
 * A page/slice of a list of HealthcareService nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type HealthcareServiceConnection = Connection & {
  __typename?: 'HealthcareServiceConnection'
  /** Edges in the current connection page. */
  edges: Array<HealthcareServiceEdge>
  /** List of HealthcareService object nodes in the current connection page. */
  nodes: Array<Maybe<HealthcareService>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a HealthcareService connection page. */
export type HealthcareServiceEdge = Edge & {
  __typename?: 'HealthcareServiceEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the HealthcareService object in this edge. */
  node?: Maybe<HealthcareService>
}

/**  Specifies a filter to apply to a list of HealthcareService  */
export type HealthcareServiceFilter = {
  /**
   * Filters on HealthcareService.active
   * The Healthcare Service is currently marked as active
   */
  active?: Maybe<BooleanFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<HealthcareServiceFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on PharmacyHealthcareService.extension('https://fhir.bbl.health/StructureDefinition/BblPharmacyHealthcareService')
   * Fax number e.g. +1122334455
   */
  fax?: Maybe<StringFilter>
  /**
   * Filters on HealthcareService.identifier
   * External identifiers for this item
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on HealthcareService.location
   * The location of the Healthcare Service
   */
  locationUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on HealthcareService.name
   * A portion of the Healthcare service name
   */
  name?: Maybe<StringFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<HealthcareServiceFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<HealthcareServiceFilter>>
  /**
   * Filters on HealthcareService.providedBy
   * The organization that provides this Healthcare Service
   */
  organizationUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on PharmacyHealthcareService.extension('https://fhir.bbl.health/StructureDefinition/BblPharmacyOrganizationAddress')
   * Postal code e.g. SW3 3DD
   */
  postalCode?: Maybe<StringFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on HealthcareService.type
   * The type of service provided by this healthcare service
   */
  serviceType?: Maybe<CodeFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** The location(s) where this healthcare service may be provided. */
export type HealthcareServiceLocationResult =
  | ExternalResource
  | Location
  | NonFederatedResource

/**
 * A page/slice of a list of HealthcareServiceLocationResult nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type HealthcareServiceLocationResultConnection = Connection & {
  __typename?: 'HealthcareServiceLocationResultConnection'
  /** Edges in the current connection page. */
  edges: Array<HealthcareServiceLocationResultEdge>
  /** List of HealthcareServiceLocationResult object nodes in the current connection page. */
  nodes: Array<Maybe<HealthcareServiceLocationResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a HealthcareServiceLocationResult connection page. */
export type HealthcareServiceLocationResultEdge = Edge & {
  __typename?: 'HealthcareServiceLocationResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the HealthcareServiceLocationResult object in this edge. */
  node?: Maybe<HealthcareServiceLocationResult>
}

/** The organization that provides this healthcare service. */
export type HealthcareServiceProvidedByResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Organization

export type HealthcareServiceResult =
  | AuthorizationError
  | HealthcareService
  | ResourceNotFoundError

export type HealthcareServicesByIdentifierResult =
  | AuthorizationError
  | HealthcareServiceConnection

/**  A sort on HealthcareService - consists of the field to sort on and the ordering  */
export type HealthcareServiceSort = {
  /**  Which field to sort by  */
  field: HealthcareServiceSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that HealthcareService can be sorted by  */
export enum HealthcareServiceSortOption {
  /**
   * Sorts on HealthcareService.name
   * A portion of the Healthcare service name
   */
  Name = 'NAME',
  /**  Do not sort  */
  None = 'NONE',
}

export type HealthcareServicesResult =
  | AuthorizationError
  | HealthcareServiceConnection

/** Healthcheck conversation object */
export type HealthcheckConversationData = {
  /** if true, the conversation will be created in debug mode */
  debug?: Maybe<Scalars['Boolean']>
  /** additional options for the healthcheck conversation */
  flags?: Maybe<HealthcheckConversationDataFlags>
  /** flow id */
  flowId: Scalars['String']
  /** flow type */
  flowType?: Maybe<Scalars['String']>
}

/** Skip previous questions that are answered */
export type HealthcheckConversationDataFlags = {
  /** if true, previous questions that are answered will be skipped */
  skipPreviouslyAnswered: Scalars['Boolean']
}

/**
 * An address expressed using postal conventions (as opposed to GPS or other location definition formats).
 * This data type may be used to convey addresses for use in delivering mail as
 * well as for visiting locations which might not be valid for mail delivery.
 * There are a variety of postal address formats defined around the world.
 */
export type HealthgraphAddress = {
  __typename?: 'HealthgraphAddress'
  /**  A City which designates the town or city that the address is in. */
  city?: Maybe<Scalars['String']>
  /**  A country designating the country that the address is in. */
  country?: Maybe<Scalars['String']>
  /**  A district which is a part of a town or city. */
  district?: Maybe<Scalars['String']>
  /**
   *  This component contains the house number, apartment number, street name,
   * street direction, P.O. Box number, delivery hints, and similar address information.
   */
  lines?: Maybe<StringConnection>
  /**
   * A postal code designating a region defined by the postal service.
   * Alternate names: Zip
   */
  postalCode?: Maybe<Scalars['PostalCode']>
  /**  A state, prvoince, county or other region within a country. */
  state?: Maybe<Scalars['String']>
  /**
   * Distinguishes between physical addresses (those you can visit) and mailing
   * addresses (e.g. PO Boxes and care-of addresses). Most addresses are both.
   * postal | physical | both
   */
  type?: Maybe<Scalars['FhirCode']>
  /**
   * The purpose of this address.
   * Allows an appropriate address to be chosen from a list of many.
   * home | work | temp | old | billing
   */
  use?: Maybe<Scalars['FhirCode']>
}

/**
 * An address expressed using postal conventions (as opposed to GPS or other location definition formats).
 * This data type may be used to convey addresses for use in delivering mail as
 * well as for visiting locations which might not be valid for mail delivery.
 * There are a variety of postal address formats defined around the world.
 */
export type HealthgraphAddressLinesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of HealthgraphAddress nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type HealthgraphAddressConnection = Connection & {
  __typename?: 'HealthgraphAddressConnection'
  /** Edges in the current connection page. */
  edges: Array<HealthgraphAddressEdge>
  /** List of HealthgraphAddress object nodes in the current connection page. */
  nodes: Array<Maybe<HealthgraphAddress>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a HealthgraphAddress connection page. */
export type HealthgraphAddressEdge = Edge & {
  __typename?: 'HealthgraphAddressEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the HealthgraphAddress object in this edge. */
  node?: Maybe<HealthgraphAddress>
}

/**  A name of a human with text, parts and usage information. */
export type HealthgraphHumanName = {
  __typename?: 'HealthgraphHumanName'
  /**
   *  The part of a name that links to the genealogy. In some cultures (e.g.
   * Eritrea) the family name of a son is the first name of his father.
   */
  family?: Maybe<Scalars['String']>
  /**  Given Names appear in the correct order for presenting the name */
  given?: Maybe<StringConnection>
  /**
   *  Part of the name that is acquired as a title due to academic, legal,
   * employment or nobility status, etc. and that appears at the start of the name.
   */
  prefixes?: Maybe<StringConnection>
  /**
   *  Part of the name that is acquired as a title due to academic, legal,
   * employment or nobility status, etc. and that appears at the end of the name.
   */
  suffixes?: Maybe<StringConnection>
  /**  Text representation of the full name */
  text?: Maybe<Scalars['String']>
}

/**  A name of a human with text, parts and usage information. */
export type HealthgraphHumanNameGivenArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A name of a human with text, parts and usage information. */
export type HealthgraphHumanNamePrefixesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A name of a human with text, parts and usage information. */
export type HealthgraphHumanNameSuffixesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of HealthgraphHumanName nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type HealthgraphHumanNameConnection = Connection & {
  __typename?: 'HealthgraphHumanNameConnection'
  /** Edges in the current connection page. */
  edges: Array<HealthgraphHumanNameEdge>
  /** List of HealthgraphHumanName object nodes in the current connection page. */
  nodes: Array<Maybe<HealthgraphHumanName>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a HealthgraphHumanName connection page. */
export type HealthgraphHumanNameEdge = Edge & {
  __typename?: 'HealthgraphHumanNameEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the HealthgraphHumanName object in this edge. */
  node?: Maybe<HealthgraphHumanName>
}

/**  An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type HealthgraphIdentifier = {
  __typename?: 'HealthgraphIdentifier'
  /**  Time period during which identifier is/was valid for use. */
  period?: Maybe<IdentifierPeriodResult>
  /**  Establishes the namespace for the value - that is, a URL that describes a set values that are unique. */
  system?: Maybe<Scalars['Uri']>
  /**  A coded type for the identifier that can be used to determine which identifier to use for a specific purpose. */
  type?: Maybe<IdentifierTypeResult>
  /**  The purpose of this identifier. */
  use?: Maybe<Scalars['String']>
  /**  The portion of the identifier typically relevant to the user and which is unique within the context of the system. */
  value?: Maybe<Scalars['String']>
}

/**
 * A page/slice of a list of HealthgraphIdentifier nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type HealthgraphIdentifierConnection = Connection & {
  __typename?: 'HealthgraphIdentifierConnection'
  /** Edges in the current connection page. */
  edges: Array<HealthgraphIdentifierEdge>
  /** List of HealthgraphIdentifier object nodes in the current connection page. */
  nodes: Array<Maybe<HealthgraphIdentifier>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a HealthgraphIdentifier connection page. */
export type HealthgraphIdentifierEdge = Edge & {
  __typename?: 'HealthgraphIdentifierEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the HealthgraphIdentifier object in this edge. */
  node?: Maybe<HealthgraphIdentifier>
}

/**  Metadata - data of the resource other than the FHIR object. */
export type HealthgraphMeta = {
  __typename?: 'HealthgraphMeta'
  /**  The name of a service that transformed the data into it's current format */
  assembler?: Maybe<Scalars['String']>
  /**  The person responsible for entering the data */
  author?: Maybe<Scalars['String']>
  /**  The profiles that the data has been verified to conform to */
  conformedProfiles?: Maybe<StringConnection>
  /**  The dFhir representation of this resource, as a JSON string */
  dfhir?: Maybe<Scalars['String']>
  /**  The name of the service creating the data e.g. monitor or clinical-portal */
  enterer?: Maybe<Scalars['String']>
  /**  Partner ids of the contracts the resource belongs to */
  partnerIds?: Maybe<StringConnection>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfiles?: Maybe<StringConnection>
  /**  The name of the domain which verifies the correctness and appropriateness of an activity eg monitor-observations */
  verifier?: Maybe<Scalars['String']>
}

/**  Metadata - data of the resource other than the FHIR object. */
export type HealthgraphMetaConformedProfilesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**  Metadata - data of the resource other than the FHIR object. */
export type HealthgraphMetaPartnerIdsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**  Metadata - data of the resource other than the FHIR object. */
export type HealthgraphMetaTaggedProfilesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**
 *  Information about a person that is involved in the care for a patient, but who
 * is not the target of healthcare, nor has a formal responsibility in the care process.
 */
export type HealthgraphRelatedPerson = HealthgraphResource &
  Resource & {
    __typename?: 'HealthgraphRelatedPerson'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Identifier for a person within a particular scope. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** A name associated with the person. */
    names?: Maybe<HealthgraphHumanNameConnection>
    /** The patient this person is related to. */
    patient?: Maybe<RelatedPersonPatientResult>
    /** The nature of the relationship between a patient and the related person. */
    relationships?: Maybe<CodeableConceptConnection>
  }

/**
 *  Information about a person that is involved in the care for a patient, but who
 * is not the target of healthcare, nor has a formal responsibility in the care process.
 */
export type HealthgraphRelatedPersonIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Information about a person that is involved in the care for a patient, but who
 * is not the target of healthcare, nor has a formal responsibility in the care process.
 */
export type HealthgraphRelatedPersonNamesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Information about a person that is involved in the care for a patient, but who
 * is not the target of healthcare, nor has a formal responsibility in the care process.
 */
export type HealthgraphRelatedPersonRelationshipsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of RelatedPerson nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type HealthgraphRelatedPersonConnection = Connection & {
  __typename?: 'HealthgraphRelatedPersonConnection'
  /** Edges in the current connection page. */
  edges: Array<HealthgraphRelatedPersonEdge>
  /** List of RelatedPerson object nodes in the current connection page. */
  nodes: Array<Maybe<HealthgraphRelatedPerson>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a RelatedPerson connection page. */
export type HealthgraphRelatedPersonEdge = Edge & {
  __typename?: 'HealthgraphRelatedPersonEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RelatedPerson object in this edge. */
  node?: Maybe<HealthgraphRelatedPerson>
}

export type HealthgraphRelatedPersonResult =
  | AuthorizationError
  | HealthgraphRelatedPerson
  | ResourceNotFoundError

export type HealthgraphRelatedPersonsByIdentifierResult =
  | AuthorizationError
  | HealthgraphRelatedPersonConnection

/**  A Resource stored in the Healthgraph. This guarantees the presence of several additional fields. */
export type HealthgraphResource = {
  /**  Metadata - data of the resource other than the FHIR object. */
  _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
  /**
   * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  _healthgraph_resourceUri?: Maybe<Scalars['ID']>
  /**
   * The logical id of the resource, this identifies the resource within a given namespace.
   * For globally unique identification, you should instead read the resourceUri field.
   */
  id: Scalars['ID']
}

/** Input for HideUserChallengeAction mutation */
export type HideUserChallengeActionInput = {
  /** The user challenge action id to hide */
  userChallengeActionId: Scalars['ID']
}

/** `HideUserChallengeActionResult` is a union for one of the following types. */
export type HideUserChallengeActionResult =
  | AuthorizationError
  | ResourceNotFoundError
  | UserChallenge

/** Input for HideUserChallenge mutation */
export type HideUserChallengeInput = {
  /** The user challenge id to hide */
  userChallengeId: Scalars['ID']
}

/** `HideUserChallengeResult` is a union for one of the following types. */
export type HideUserChallengeResult =
  | AuthorizationError
  | ResourceNotFoundError
  | UserChallenge

/** A name of a human with text, parts and usage information. */
export type HumanName = {
  __typename?: 'HumanName'
  /** Family name (often called 'Surname') */
  family?: Maybe<Scalars['String']>
  /** Given names (not always 'first'). Includes middle names. */
  given?: Maybe<Array<Scalars['String']>>
  /** Parts that come before the name */
  prefix?: Maybe<Array<Scalars['String']>>
  /** Parts that come after the name */
  suffix?: Maybe<Array<Scalars['String']>>
  /**
   * Text representation of the person's name.
   *
   * Apps should use this field when displaying a name, to avoid the complexity of handling names from different cultures.
   */
  text: Scalars['String']
}

/** A name of a human broken into its constituent parts and usage information. */
export type HumanNameInput = {
  /** Family name (often called 'Surname') */
  family?: Maybe<Scalars['String']>
  /** Given names (not always 'first'). Includes middle names. */
  given?: Maybe<Array<Scalars['String']>>
  /** Parts that come before the name */
  prefix?: Maybe<Array<Scalars['String']>>
  /** Parts that come after the name */
  suffix?: Maybe<Array<Scalars['String']>>
  /**
   * Identifies the purpose for this name.
   *
   * Will be assigned a default value if no value is provided, and where the chosen
   * default may vary depending on region or other factors.
   */
  use?: Maybe<NameUse>
}

/**  Used to filter on an Identifier */
export type IdentifierFilter = {
  /**  The field must match at least one of the given options */
  ONE_OF?: Maybe<Array<IdentifierFilterOption>>
}

/**  An option for what an Identifier can be. You must provide a system and would usually provide a value */
export type IdentifierFilterOption = {
  /**  The namespace for the identifier value */
  system: Scalars['Uri']
  /**  The value that is unique */
  value?: Maybe<Scalars['String']>
}

/** A numeric or alphanumeric string that is associated with a single object or entity within a given system. */
export type IdentifierInput = {
  /** The system which defines this identifier. */
  system: Scalars['Uri']
  /** The raw value of the identifier. */
  value: Scalars['String']
}

/** An error indicating that the dependent identifier specified has not been found in stub profiles. */
export type IdentifierNoMatchError = Error & {
  __typename?: 'IdentifierNoMatchError'
  /**
   * Message to assist a developer in understanding and fixing the error.
   *
   * Not intended to be shown directly to an end-user.
   */
  errorMessage: Scalars['String']
}

/**  Time period during which identifier is/was valid for use. */
export type IdentifierPeriodResult = None | Period

/**
 * A system of identifiers. For example, an organisation which issues identifiers
 * (e.g., a government agency that issues National IDs or Passport Numbers)
 * or a technical system for generating them (e.g., UUIDs).
 */
export type IdentifierSystem = Resource & {
  __typename?: 'IdentifierSystem'
  /** Internal ID of the system. */
  id: Scalars['ID']
}

/**  A coded type for the identifier that can be used to determine which identifier to use for a specific purpose. */
export type IdentifierTypeResult = CodeableConcept | None

export type IdentityCheck = {
  __typename?: 'IdentityCheck'
  id?: Maybe<Scalars['NonNegativeInt']>
  /** Identity check status */
  status?: Maybe<Scalars['String']>
}

/** The identity provider access token */
export type IdpToken = {
  __typename?: 'IdpToken'
  /** The system identifier */
  system: Scalars['String']
  /** The actual access token string */
  token: Scalars['String']
}

/** An error indicating that the fetching of the access token failed */
export type IdpTokenError = Error & {
  __typename?: 'IdpTokenError'
  /** The error message. */
  errorMessage: Scalars['String']
}

/** ID Token */
export type IdToken = {
  __typename?: 'IdToken'
  /** The actual token string. */
  token?: Maybe<Scalars['Jwt']>
}

export type IdTokenResult =
  | IdToken
  | InvalidAudienceError
  | ResourceNotFoundError

/** An image that can be displayed to a user. */
export type Image = {
  __typename?: 'Image'
  /** This value can be a URL or a relative path */
  url?: Maybe<Scalars['String']>
}

/** Url to image in message */
export type ImageMessageValue = {
  __typename?: 'ImageMessageValue'
  /** url where the image is located */
  url?: Maybe<Scalars['String']>
}

/** Inbox concept */
export type InboxCodeableConcept = {
  __typename?: 'InboxCodeableConcept'
  text?: Maybe<Scalars['String']>
}

/** Inbox communication with decriptive fields */
export type InboxCommunication = Resource & {
  __typename?: 'InboxCommunication'
  about?: Maybe<InboxConversationReferenceConnection>
  categories?: Maybe<Array<Maybe<InboxCodeableConcept>>>
  hasAttachment?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  isRead?: Maybe<Scalars['Boolean']>
  payloads?: Maybe<Array<Maybe<InboxCommunicationPayload>>>
  sent?: Maybe<Scalars['DateTime']>
}

/** Inbox communication with decriptive fields */
export type InboxCommunicationAboutArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** Connection for inbox communication */
export type InboxCommunicationConnection = {
  __typename?: 'InboxCommunicationConnection'
  edges: Array<InboxCommunicationEdge>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Edge for inbox communication */
export type InboxCommunicationEdge = Edge & {
  __typename?: 'InboxCommunicationEdge'
  cursor: Scalars['Cursor']
  node: InboxCommunication
}

/** Payload of communication */
export type InboxCommunicationPayload = {
  __typename?: 'InboxCommunicationPayload'
  contentCodeableConcept?: Maybe<InboxCodeableConcept>
  contentString?: Maybe<Scalars['String']>
}

/** Union of conversation reference */
export type InboxConversationReference = Conversation | ConversationReference

/** Inbox conversation with edges and page info */
export type InboxConversationReferenceConnection = {
  __typename?: 'InboxConversationReferenceConnection'
  edges: Array<Maybe<InboxConversationReferenceEdge>>
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in an InboxConversationReference connection page. */
export type InboxConversationReferenceEdge = Edge & {
  __typename?: 'InboxConversationReferenceEdge'
  cursor: Scalars['Cursor']
  node?: Maybe<InboxConversationReference>
}

/** Topic for a conversation */
export type InformationConversationData = {
  /** true if conversation is in debug mode */
  debug?: Maybe<Scalars['Boolean']>
  /** topic of the conversation */
  topic: Scalars['String']
}

/** Input message payload. Contains actual message sent. */
export type InputMessagePayload = {
  /** The content of the message being sent. */
  content?: Maybe<Scalars['String']>
}

/**  Details of a Health Insurance product/plan provided by an organization.  */
export type InsurancePlan = HealthgraphResource &
  Resource & {
    __typename?: 'InsurancePlan'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The geographic region in which a health insurance product's benefits apply. */
    coverageAreas?: Maybe<InsurancePlanCoverageAreaResultConnection>
    /** A textual description for the InsurancePlan */
    description?: Maybe<Scalars['String']>
    /** The number of funded lives the InsurancePlan provides */
    fundedLives?: Maybe<Scalars['NonNegativeInt']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Business identifiers assigned to this health insurance product which remain
     * constant as the resource is updated and propagates from server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Official name of the health insurance product as designated by the owner. */
    name?: Maybe<Scalars['String']>
    /**
     * The entity that is providing  the health insurance product and underwriting
     * the risk.  This is typically an insurance carriers, other third-party payers,
     * or health plan sponsors comonly referred to as 'payers'.
     */
    ownedBy?: Maybe<InsurancePlanOwnedByResult>
    /** The InsurancePlan that this one depends on */
    partOf?: Maybe<InsurancePlanPartOfResult>
    /** The regions where the InsurancePlan applies */
    regions?: Maybe<StringConnection>
    /** The current state of the health insurance product. */
    status?: Maybe<Scalars['FhirCode']>
    /** The Babylon variant of InsurancePlan */
    variant?: Maybe<Scalars['FhirCode']>
  }

/**  Details of a Health Insurance product/plan provided by an organization.  */
export type InsurancePlanCoverageAreasArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Details of a Health Insurance product/plan provided by an organization.  */
export type InsurancePlanIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Details of a Health Insurance product/plan provided by an organization.  */
export type InsurancePlanRegionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of InsurancePlan nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type InsurancePlanConnection = Connection & {
  __typename?: 'InsurancePlanConnection'
  /** Edges in the current connection page. */
  edges: Array<InsurancePlanEdge>
  /** List of InsurancePlan object nodes in the current connection page. */
  nodes: Array<Maybe<InsurancePlan>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** The geographic region in which a health insurance product's benefits apply. */
export type InsurancePlanCoverageAreaResult =
  | ExternalResource
  | Location
  | NonFederatedResource

/**
 * A page/slice of a list of InsurancePlanCoverageAreaResult nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type InsurancePlanCoverageAreaResultConnection = Connection & {
  __typename?: 'InsurancePlanCoverageAreaResultConnection'
  /** Edges in the current connection page. */
  edges: Array<InsurancePlanCoverageAreaResultEdge>
  /** List of InsurancePlanCoverageAreaResult object nodes in the current connection page. */
  nodes: Array<Maybe<InsurancePlanCoverageAreaResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a InsurancePlanCoverageAreaResult connection page. */
export type InsurancePlanCoverageAreaResultEdge = Edge & {
  __typename?: 'InsurancePlanCoverageAreaResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the InsurancePlanCoverageAreaResult object in this edge. */
  node?: Maybe<InsurancePlanCoverageAreaResult>
}

/** An edge in a InsurancePlan connection page. */
export type InsurancePlanEdge = Edge & {
  __typename?: 'InsurancePlanEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the InsurancePlan object in this edge. */
  node?: Maybe<InsurancePlan>
}

/**  Specifies a filter to apply to a list of InsurancePlan  */
export type InsurancePlanFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<InsurancePlanFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on InsurancePlan.identifier
   * Any identifier for the organization (not the accreditation issuer's identifier)
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on InsurancePlan.name
   * A portion of the organization's name or alias
   */
  name?: Maybe<StringFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<InsurancePlanFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<InsurancePlanFilter>>
  /**
   * Filters on InsurancePlan.ownedBy
   * An organization of which this organization forms a part
   */
  ownedByUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on InsurancePlan.status
   * Is the Organization record active
   */
  status?: Maybe<PublicationStatusFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/**
 * The entity that is providing  the health insurance product and underwriting the
 * risk.  This is typically an insurance carriers, other third-party payers, or
 * health plan sponsors comonly referred to as 'payers'.
 */
export type InsurancePlanOwnedByResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Organization

/** The InsurancePlan that this one depends on */
export type InsurancePlanPartOfResult =
  | ExternalResource
  | InsurancePlan
  | None
  | NonFederatedResource

export type InsurancePlanResult =
  | AuthorizationError
  | InsurancePlan
  | ResourceNotFoundError

export type InsurancePlansByIdentifierResult =
  | AuthorizationError
  | InsurancePlanConnection

/**  A sort on InsurancePlan - consists of the field to sort on and the ordering  */
export type InsurancePlanSort = {
  /**  Which field to sort by  */
  field: InsurancePlanSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that InsurancePlan can be sorted by  */
export enum InsurancePlanSortOption {
  /**
   * Sorts on InsurancePlan.name
   * A portion of the organization's name or alias
   */
  Name = 'NAME',
  /**  Do not sort  */
  None = 'NONE',
}

export type InsurancePlansResult = AuthorizationError | InsurancePlanConnection

/** An error indicating that the audience for the idToken is not valid. */
export type InvalidAudienceError = Error & {
  __typename?: 'InvalidAudienceError'
  /** The error message. */
  errorMessage: Scalars['String']
  /** Requested invalid audience. */
  invalidAudience: Scalars['String']
}

/** An error indicating that there are issues with the users request. Should be retried with correct values. */
export type InvalidPatientRegistrationRequest = Error & {
  __typename?: 'InvalidPatientRegistrationRequest'
  /**
   * Message to assist a developer in understanding and fixing the error.
   *
   * Not intended to be shown directly to an end-user.
   */
  errorMessage: Scalars['String']
}

/** Input required to verify if an IAM user exists with the given email */
export type IsIamUserExistingInput = {
  /** Email of the user */
  emailAddress: Scalars['EmailAddress']
}

/**  Used to filter on a keyword */
export type KeywordFilter = {
  /**  The field must match at least one of the given options */
  ONE_OF?: Maybe<Array<Scalars['String']>>
}

/** A mapping between the MyHealthId and observation fhir code for Lab Test Metrics */
export type LabTestMetricConfig = {
  __typename?: 'LabTestMetricConfig'
  /** The unique identifier for the My Health Metric */
  myHealthId: Scalars['String']
  /** The fhir code associated with the metric to fetch observation data with */
  observationFhirCode: Scalars['String']
}

/** Information contained in leaflet */
export type LeafletMessageValue = {
  __typename?: 'LeafletMessageValue'
  /** where the leaflet comes from */
  source?: Maybe<Scalars['String']>
  /** short summary of the leaflet */
  summary?: Maybe<Scalars['String']>
  /** long description of the leaflet */
  text: Scalars['String']
  /** title to be displayed */
  title?: Maybe<Scalars['String']>
  /** url to the leaflet */
  url?: Maybe<Scalars['String']>
}

/** Template defining levels and ranges for a specific lifestyle category. Example: Activity, PROGRESSING, 50-100 */
export type LifestyleCategoryLevelTemplate = {
  __typename?: 'LifestyleCategoryLevelTemplate'
  /** Lifestyle category template that this lifestyle category level template is associated with */
  categoryTemplate: LifestyleCategoryTemplate
  /** Date of LifestyleCategoryLevelTemplate creation */
  created: Scalars['DateTime']
  /** ID of LifestyleCategoryLevelTemplate */
  id: Scalars['ID']
  /** Upper bound for category level range (exclusive). Null implies positive ∞. */
  maxScore?: Maybe<Scalars['Int']>
  /** Lower bound for category level range (inclusive). Null implies negative ∞. */
  minScore?: Maybe<Scalars['Int']>
  /** For Internal Use Only. Name of lifestyle category level. Example: STARTING, PROGRESSING, MAINTAINING */
  name: Scalars['String']
}

/** A edge for a `LifestyleCategoryLevelTemplate`. */
export type LifestyleCategoryLevelTemplateEdge = Edge & {
  __typename?: 'LifestyleCategoryLevelTemplateEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<LifestyleCategoryLevelTemplate>
}

/** Template defines a lifestyle category. Example: Activity, Nutrition */
export type LifestyleCategoryTemplate = {
  __typename?: 'LifestyleCategoryTemplate'
  /** Date of LifestyleCategoryTemplate creation */
  created: Scalars['DateTime']
  /** ID of LifestyleCategoryTemplate */
  id: Scalars['ID']
  /** Lifestyle Category Level Templates associated with this lifestyle category template */
  lifestyleCategoryLevelTemplates: LifestyleCategoryTemplateConnection
  /** Describes this `LifestyleCategoryTemplate` */
  name: LocalizedString
}

/** Template defines a lifestyle category. Example: Activity, Nutrition */
export type LifestyleCategoryTemplateLifestyleCategoryLevelTemplatesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** A connection for a `LifestyleCategoryLevelTemplate`. */
export type LifestyleCategoryTemplateConnection = Connection & {
  __typename?: 'LifestyleCategoryTemplateConnection'
  /** Edges in the current connection page. */
  edges: Array<LifestyleCategoryLevelTemplateEdge>
  /** List of `LifestyleCategoryTemplate` nodes in the current connection page. */
  nodes: Array<Maybe<LifestyleCategoryTemplate>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Input required to link an IAM provider user id to a patient id */
export type LinkUserToPatientInput = {
  /** Patient id. */
  patientId: Scalars['ID']
  /** User id (defined by the IAM provider). */
  userId: Scalars['ID']
}

/** The clock format to use for all times. */
export enum LocalizationClockFormat {
  /** 1am to 12am and then 1pm to 12pm */
  TwelveHourClock = 'TWELVE_HOUR_CLOCK',
  /** 00:00 to 23:59 */
  TwentyFourHourClock = 'TWENTY_FOUR_HOUR_CLOCK',
}

/** Keys used to unambiguously refer to a piece of content with LOCuS. */
export type LocalizationCodingInput = {
  code: Scalars['FhirCode']
  system: Scalars['Uri']
  version: Scalars['String']
}

/** The gender of a person. */
export enum LocalizationGender {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Not known */
  NotKnown = 'NOT_KNOWN',
  /** Not specified */
  NotSpecified = 'NOT_SPECIFIED',
  /** Other specific */
  OtherSpecific = 'OTHER_SPECIFIC',
}

/** The grammatical form of speech to use. */
export enum LocalizationGrammaticalPerson {
  /** First person */
  FirstPerson = 'FIRST_PERSON',
  /** Second person */
  SecondPerson = 'SECOND_PERSON',
  /** Third person */
  ThirdPerson = 'THIRD_PERSON',
}

/** Localized String */
export type LocalizedString = {
  __typename?: 'LocalizedString'
  /** Context for Localization */
  context: Scalars['LocalizationContext']
  /** Text */
  text: Scalars['String']
}

/** Localized String */
export type LocalizedStringTextArgs = {
  clientContext?: Maybe<LocalizedStringTextClientContext>
}

/**
 * Allows the consumer to override default localization context provided by the API
 * and to override `LocalizedStringTextClientContext`.
 */
export type LocalizedStringServerContext = {
  /** User's age. */
  age?: Maybe<Scalars['NonNegativeInt']>
  /** The format to display times in (e.g. '2:36 PM' or '14:36'). */
  clockFormat?: Maybe<LocalizationClockFormat>
  /**
   * The coding identifier used to identify the content to be localized.
   *
   * You must provide either this field or `unlocalizableContent`.
   */
  contentCoding?: Maybe<LocalizationCodingInput>
  /**
   * The grammatical form of speech to use.
   *
   * For example, if set to `FIRST_PERSON` then the localized string might be "I am
   * experiencing a headache", whereas if set to `THIRD_PERSON` then the localized
   * string might instead be "She is experiencing a headache".
   */
  grammaticalPerson?: Maybe<LocalizationGrammaticalPerson>
  /**
   * Comma-separated list of language locale ISO codes that may be accepted in a response (often used for language fallback).
   *
   * The order of preference is evaluated first to last.
   */
  language?: Maybe<Array<Scalars['Locale']>>
  partnerId?: Maybe<Scalars['ID']>
  regionId?: Maybe<Scalars['ID']>
  /**
   * The gender of the person interacting with the practitioner.
   *
   * For example:
   *
   * 1. When a mother (speaker) uses symptom checker to check for her son's
   * (subject) symptoms, `speakerGender` would be `FEMALE` and `subjectGender`
   * would be `MALE`.
   * 2. When a woman (speaker) uses symptom checker to check for her own (subject)
   * symptoms, `speakerGender` as well as `subjectGender` would be `FEMALE`.
   */
  speakerGender?: Maybe<LocalizationGender>
  /**
   * The gender of the patient being talked about.
   *
   * Particularly useful when the person using the app is distinct from the patient (the subject) being talked about.
   */
  subjectGender?: Maybe<LocalizationGender>
  /** User's timezone. */
  timezone?: Maybe<Scalars['TimezoneId']>
  /**
   * An alternative to `contentCoding` for cases where the input to
   * `unlocalizableContent` is the desired localized content to be returned
   */
  unlocalizableContent?: Maybe<Scalars['String']>
}

/** Allows the consumer to override the default localization context provided by the API. */
export type LocalizedStringTextClientContext = {
  /** User's age. */
  age?: Maybe<Scalars['NonNegativeInt']>
  /** The format to display times in (e.g. '2:36 PM' or '14:36'). */
  clockFormat?: Maybe<LocalizationClockFormat>
  /**
   * The grammatical form of speech to use.
   *
   * For example, if set to `FIRST_PERSON` then the localized string might be "I am
   * experiencing a headache", whereas if set to `THIRD_PERSON` then the localized
   * string might instead be "She is experiencing a headache".
   */
  grammaticalPerson?: Maybe<LocalizationGrammaticalPerson>
  /**
   * Comma-separated list of language locale ISO codes that may be accepted in a response (often used for language fallback).
   *
   * The order of preference is evaluated first to last.
   */
  language?: Maybe<Array<Scalars['Locale']>>
  /**
   * The gender of the person interacting with the practitioner.
   *
   * For example:
   *
   * 1. When a mother (speaker) uses symptom checker to check for her son's
   * (subject) symptoms, `speakerGender` would be `FEMALE` and `subjectGender`
   * would be `MALE`.
   * 2. When a woman (speaker) uses symptom checker to check for her own (subject)
   * symptoms, `speakerGender` as well as `subjectGender` would be `FEMALE`.
   */
  speakerGender?: Maybe<LocalizationGender>
  /**
   * The gender of the patient being talked about.
   *
   * Particularly useful when the person using the app is distinct from the patient (the subject) being talked about.
   */
  subjectGender?: Maybe<LocalizationGender>
  /** User's timezone. */
  timezone?: Maybe<Scalars['TimezoneId']>
}

/**
 *  Details and position information for a physical place where services are
 * provided and resources and participants may be stored, found, contained, or accommodated.
 */
export type Location = HealthgraphResource &
  Resource & {
    __typename?: 'Location'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** Physical location. */
    address?: Maybe<LocationAddressResult>
    /** A list of alternate names that the location is known as, or was known as, in the past. */
    aliases?: Maybe<StringConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Unique code or number identifying the location to its users. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Name of the location as used by humans. Does not need to be unique. */
    name?: Maybe<Scalars['String']>
    /** Another Location of which this Location is physically a part of. */
    partOf?: Maybe<LocationPartOfResult>
    /** Physical form of the location, e.g. building, room, vehicle, road. */
    physicalType?: Maybe<LocationPhysicalTypeResult>
    /**
     * The absolute geographic location of the Location, expressed using the WGS84
     * datum (This is the same co-ordinate system used in KML).
     */
    position?: Maybe<LocationPositionResult>
  }

/**
 *  Details and position information for a physical place where services are
 * provided and resources and participants may be stored, found, contained, or accommodated.
 */
export type LocationAliasesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Details and position information for a physical place where services are
 * provided and resources and participants may be stored, found, contained, or accommodated.
 */
export type LocationIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Physical location. */
export type LocationAddressResult = HealthgraphAddress | None

/** Information of location object */
export type LocationChoiceInputMessage = {
  /** id of the choice */
  id: Scalars['ID']
  /** latitide selected by member */
  latitude?: Maybe<Scalars['String']>
  /** longitude selected by member */
  longitude?: Maybe<Scalars['String']>
}

/**
 * A page/slice of a list of Location nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type LocationConnection = Connection & {
  __typename?: 'LocationConnection'
  /** Edges in the current connection page. */
  edges: Array<LocationEdge>
  /** List of Location object nodes in the current connection page. */
  nodes: Array<Maybe<Location>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Location connection page. */
export type LocationEdge = Edge & {
  __typename?: 'LocationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Location object in this edge. */
  node?: Maybe<Location>
}

/**  Specifies a filter to apply to a list of Location  */
export type LocationFilter = {
  /**
   * Filters on Location.address
   * A (part of the) address of the location
   */
  address?: Maybe<StringFilter>
  /**
   * Filters on Location.address.city
   * A city specified in an address
   */
  addressCity?: Maybe<StringFilter>
  /**
   * Filters on Location.address.country
   * A country specified in an address
   */
  addressCountry?: Maybe<StringFilter>
  /**
   * Filters on Location.address.postalCode
   * A postal code specified in an address
   */
  addressPostalcode?: Maybe<StringFilter>
  /**
   * Filters on Location.address.state
   * A state specified in an address
   */
  addressState?: Maybe<StringFilter>
  /**
   * Filters on Location.address.use
   * A use code specified in an address
   */
  addressUse?: Maybe<AddressUseFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<LocationFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Location.identifier
   * An identifier for the location
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on Location.name
   * A portion of the location's name or alias
   */
  name?: Maybe<StringFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<LocationFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<LocationFilter>>
  /**
   * Filters on Location.partOf
   * A location of which this location is a part
   */
  partofUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** Information about location */
export type LocationMessageValue = {
  __typename?: 'LocationMessageValue'
  /** latitude of location */
  latitude: Scalars['String']
  /** longitude of location */
  longitude: Scalars['String']
  /** description of the location */
  text: Scalars['String']
}

/** Another Location of which this Location is physically a part of. */
export type LocationPartOfResult =
  | ExternalResource
  | Location
  | None
  | NonFederatedResource

/** Physical form of the location, e.g. building, room, vehicle, road. */
export type LocationPhysicalTypeResult = CodeableConcept | None

/**
 *  The absolute geographic location of the Location, expressed using the WGS84
 * datum (This is the same co-ordinate system used in KML).
 */
export type LocationPosition = {
  __typename?: 'LocationPosition'
  /**
   * Latitude. The value domain and the interpretation are the same as for the text
   * of the latitude element in KML (see notes below).
   */
  latitude?: Maybe<Scalars['Float']>
  /**
   * Longitude. The value domain and the interpretation are the same as for the
   * text of the longitude element in KML (see notes below).
   */
  longitude?: Maybe<Scalars['Float']>
}

/**
 * The absolute geographic location of the Location, expressed using the WGS84
 * datum (This is the same co-ordinate system used in KML).
 */
export type LocationPositionResult = LocationPosition | None

export type LocationResult =
  | AuthorizationError
  | Location
  | ResourceNotFoundError

export type LocationsByIdentifierResult =
  | AuthorizationError
  | LocationConnection

/**  A sort on Location - consists of the field to sort on and the ordering  */
export type LocationSort = {
  /**  Which field to sort by  */
  field: LocationSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Location can be sorted by  */
export enum LocationSortOption {
  /**
   * Sorts on Location.address
   * A (part of the) address of the location
   */
  Address = 'ADDRESS',
  /**
   * Sorts on Location.address.city
   * A city specified in an address
   */
  AddressCity = 'ADDRESS_CITY',
  /**
   * Sorts on Location.address.country
   * A country specified in an address
   */
  AddressCountry = 'ADDRESS_COUNTRY',
  /**
   * Sorts on Location.address.postalCode
   * A postal code specified in an address
   */
  AddressPostalcode = 'ADDRESS_POSTALCODE',
  /**
   * Sorts on Location.address.state
   * A state specified in an address
   */
  AddressState = 'ADDRESS_STATE',
  /**
   * Sorts on Location.name
   * A portion of the location's name or alias
   */
  Name = 'NAME',
  /**  Do not sort  */
  None = 'NONE',
}

export type LocationsResult = AuthorizationError | LocationConnection

/** Marked down message value */
export type MarkdownMessageValue = {
  __typename?: 'MarkdownMessageValue'
  /** exmplanation to member, about message content */
  helpText?: Maybe<Scalars['String']>
  /** markdown to render */
  markdown: Scalars['String']
}

/** Location of formatting of string matches. Return from Google Places API */
export type MatchedSubstring = {
  __typename?: 'MatchedSubstring'
  /** add description */
  length?: Maybe<Scalars['Int']>
  /** add description */
  offset?: Maybe<Scalars['Int']>
}

/**
 * Matching substrings length and offset from Google Places API.
 *
 * An implementation of [Connection Type](https://relay.dev/graphql/connections.htm#sec-Connection-Types)
 * for edges of type `PredictionEdge`.
 */
export type MatchedSubstringConnection = Connection & {
  __typename?: 'MatchedSubstringConnection'
  /** add description */
  edges: Array<MatchedSubstringEdge>
  /** add description */
  nodes: Array<Maybe<MatchedSubstring>>
  /** add description */
  pageInfo: PageInfo
  /** add description */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An implementation of [Edges](https://relay.dev/graphql/connections.htm#sec-Edges)
 * for a node of type `Prediction`.
 */
export type MatchedSubstringEdge = Edge & {
  __typename?: 'MatchedSubstringEdge'
  /** add description */
  cursor: Scalars['Cursor']
  /** add description */
  node?: Maybe<MatchedSubstring>
}

/**  The Measure resource provides the definition of a quality measure.  */
export type Measure = HealthgraphResource &
  Resource & {
    __typename?: 'Measure'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * A formal identifier that is used to identify this measure when it is
     * represented in other formats, or referenced in a specification, model, design
     * or an instance.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * A natural language name identifying the measure. This name should be usable as
     * an identifier for the module by machine processing applications such as code generation.
     */
    name?: Maybe<Scalars['String']>
    /** Explanation of why this measure is needed and why it has been designed as it has. */
    purpose?: Maybe<Scalars['Markdown']>
    /** The status of this measure. Enables tracking the life-cycle of the content. */
    status?: Maybe<Scalars['FhirCode']>
    /** A short, descriptive, user-friendly title for the measure. */
    title?: Maybe<Scalars['String']>
    /**
     * Descriptive topics related to the content of the measure. Topics provide a
     * high-level categorization grouping types of measures that can be useful for
     * filtering and searching.
     */
    topics?: Maybe<CodeableConceptConnection>
  }

/**  The Measure resource provides the definition of a quality measure.  */
export type MeasureIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  The Measure resource provides the definition of a quality measure.  */
export type MeasureTopicsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of Measure nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MeasureConnection = Connection & {
  __typename?: 'MeasureConnection'
  /** Edges in the current connection page. */
  edges: Array<MeasureEdge>
  /** List of Measure object nodes in the current connection page. */
  nodes: Array<Maybe<Measure>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Measure connection page. */
export type MeasureEdge = Edge & {
  __typename?: 'MeasureEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Measure object in this edge. */
  node?: Maybe<Measure>
}

/** Depracated */
export type MeasurementMessageInput = {
  __typename?: 'MeasurementMessageInput'
  /**
   * deprecated
   * @deprecated Not intended for external usage
   */
  empty?: Maybe<Scalars['Void']>
}

/**
 *  The MeasureReport resource contains the results of the calculation of a measure;
 * and optionally a reference to the resources involved in that calculation.
 */
export type MeasureReport = HealthgraphResource &
  Resource & {
    __typename?: 'MeasureReport'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The date this measure report was generated. */
    date?: Maybe<Scalars['FhirDateTime']>
    /** A reference to a Bundle containing the Resources that were used in the calculation of this measure. */
    evaluatedResources?: Maybe<MeasureReportEvaluatedResourceResultConnection>
    /** The results of the calculation, one for each population group in the measure. */
    groups?: Maybe<MeasureReportGroupConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * A formal identifier that is used to identify this MeasureReport when it is
     * represented in other formats or referenced in a specification, model, design
     * or an instance.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** A reference to the Measure that was calculated to produce this report. */
    measure?: Maybe<MeasureReportMeasureResult>
    /** The reporting period for which the report was calculated. */
    period?: Maybe<MeasureReportPeriodResult>
    /** The individual, location, or organization that is reporting the data. */
    reporter?: Maybe<MeasureReportReporterResult>
    /** The MeasureReport status. No data will be available until the MeasureReport status is complete. */
    status?: Maybe<Scalars['FhirCode']>
    /** Optional subject identifying the individual or individuals the report is for. */
    subject?: Maybe<MeasureReportSubjectResult>
    /**
     * The type of measure report. This may be an individual report, which provides
     * the score for the measure for an individual member of the population; a
     * subject-listing, which returns the list of members that meet the various
     * criteria in the measure; a summary report, which returns a population count
     * for each of the criteria in the measure; or a data-collection, which enables
     * the MeasureReport to be used to exchange the data-of-interest for a quality measure.
     */
    type?: Maybe<Scalars['FhirCode']>
  }

/**
 *  The MeasureReport resource contains the results of the calculation of a measure;
 * and optionally a reference to the resources involved in that calculation.
 */
export type MeasureReportEvaluatedResourcesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  The MeasureReport resource contains the results of the calculation of a measure;
 * and optionally a reference to the resources involved in that calculation.
 */
export type MeasureReportGroupsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  The MeasureReport resource contains the results of the calculation of a measure;
 * and optionally a reference to the resources involved in that calculation.
 */
export type MeasureReportIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of MeasureReport nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MeasureReportConnection = Connection & {
  __typename?: 'MeasureReportConnection'
  /** Edges in the current connection page. */
  edges: Array<MeasureReportEdge>
  /** List of MeasureReport object nodes in the current connection page. */
  nodes: Array<Maybe<MeasureReport>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a MeasureReport connection page. */
export type MeasureReportEdge = Edge & {
  __typename?: 'MeasureReportEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the MeasureReport object in this edge. */
  node?: Maybe<MeasureReport>
}

/** A reference to a Bundle containing the Resources that were used in the calculation of this measure. */
export type MeasureReportEvaluatedResourceResult =
  | Appointment
  | CarePlan
  | CareTeam
  | Claim
  | ClinicalImpression
  | Communication
  | CommunicationRequest
  | Composition
  | Condition
  | Coverage
  | Device
  | DiagnosticReport
  | DocumentReference
  | Encounter
  | ExternalResource
  | Goal
  | Group
  | HealthcareService
  | HealthgraphRelatedPerson
  | InsurancePlan
  | Location
  | Measure
  | MeasureReport
  | Media
  | MedicationDispense
  | MedicationRequest
  | MedicationStatement
  | NonFederatedResource
  | Observation
  | Organization
  | Patient
  | Practitioner
  | PractitionerRole
  | Procedure
  | Questionnaire
  | QuestionnaireResponse
  | RequestGroup
  | RiskAssessment
  | ServiceRequest

/**
 * A page/slice of a list of MeasureReportEvaluatedResourceResult nodes, allowing
 * for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MeasureReportEvaluatedResourceResultConnection = Connection & {
  __typename?: 'MeasureReportEvaluatedResourceResultConnection'
  /** Edges in the current connection page. */
  edges: Array<MeasureReportEvaluatedResourceResultEdge>
  /** List of MeasureReportEvaluatedResourceResult object nodes in the current connection page. */
  nodes: Array<Maybe<MeasureReportEvaluatedResourceResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a MeasureReportEvaluatedResourceResult connection page. */
export type MeasureReportEvaluatedResourceResultEdge = Edge & {
  __typename?: 'MeasureReportEvaluatedResourceResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the MeasureReportEvaluatedResourceResult object in this edge. */
  node?: Maybe<MeasureReportEvaluatedResourceResult>
}

/**  The results of the calculation, one for each population group in the measure.  */
export type MeasureReportGroup = {
  __typename?: 'MeasureReportGroup'
  /**
   * The Adjusted Risk Lift (the incremental value contributed to the member's
   * total risk score, adjusted with the member's cost sharing reduction factor)
   * reported for this measure
   */
  adjRiskLift?: Maybe<Scalars['Float']>
  /**
   * The Adjusted Risk Weight (the weight of the measure adjusted with the
   * subject's cost sharing reduction factor) reported for this measure
   */
  adjRiskWeight?: Maybe<Scalars['Float']>
  /** The meaning of the population group as defined in the measure definition. */
  code?: Maybe<MeasureReportGroupCodeResult>
  /** Date of Service */
  dateOfService?: Maybe<Scalars['FhirDateTime']>
  /** The date the member became eligible for this measure */
  eligibilityEffectiveDate?: Maybe<Scalars['FhirDateTime']>
  /**
   * Boolean value indicating if the member is compliant with the measure indicated
   * in this group within this specific measureReport
   */
  isMemberCompliant?: Maybe<Scalars['Boolean']>
  /**
   * An indication as to whether this measure is deemed to be top priority for the
   * company at the time that this measureReport was processed. The company is paid
   * for performance on these top priority measures
   */
  isTopPriorityMeasure?: Maybe<Scalars['Boolean']>
  /** The deadline by which the measure must be closed */
  measureAnchorDate?: Maybe<Scalars['FhirDateTime']>
  /**
   * An indication as to the status of the condition that was used to identify the
   * measure. PRST GAP: Persistent gap which is a condition that has been coded in
   * the past; PRD GAP: Predictive gap which is a condition that has been suspected
   * based on claim history, but the member has not necessarily been documented
   * with this condition in the past; NEW: A new condition that has been captured
   * through current claims data; RECAPTURED: A condition that has been coded in
   * the past and recaptured through current claims data; RECAPTURED LOW: A lower
   * severity condition in the same grouping has been captured; RECAPTURED HIGH: A
   * higher severity condition in the same grouping has been captured.
   */
  measureConditionStatus?: Maybe<Scalars['String']>
  /** The code that was evaluated to identify the measure within this measureReport */
  reportEvaluatedCodes?: Maybe<CodeableConceptConnection>
  /** How the measure was identified */
  source?: Maybe<Scalars['String']>
}

/**  The results of the calculation, one for each population group in the measure.  */
export type MeasureReportGroupReportEvaluatedCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The meaning of the population group as defined in the measure definition. */
export type MeasureReportGroupCodeResult = CodeableConcept | None

/**
 * A page/slice of a list of MeasureReportGroup nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MeasureReportGroupConnection = Connection & {
  __typename?: 'MeasureReportGroupConnection'
  /** Edges in the current connection page. */
  edges: Array<MeasureReportGroupEdge>
  /** List of MeasureReportGroup object nodes in the current connection page. */
  nodes: Array<Maybe<MeasureReportGroup>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a MeasureReportGroup connection page. */
export type MeasureReportGroupEdge = Edge & {
  __typename?: 'MeasureReportGroupEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the MeasureReportGroup object in this edge. */
  node?: Maybe<MeasureReportGroup>
}

/** A reference to the Measure that was calculated to produce this report. */
export type MeasureReportMeasureResult =
  | ExternalResource
  | Measure
  | None
  | NonFederatedResource

/** The reporting period for which the report was calculated. */
export type MeasureReportPeriodResult = None | Period

/** The individual, location, or organization that is reporting the data. */
export type MeasureReportReporterResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Organization

export type MeasureReportResult =
  | AuthorizationError
  | MeasureReport
  | ResourceNotFoundError

export type MeasureReportsByIdentifierResult =
  | AuthorizationError
  | MeasureReportConnection

/**  The status of the measure report.  */
export enum MeasureReportStatus {
  /**  The report is complete and ready for use.  */
  Complete = 'COMPLETE',
  /**  An error occurred attempting to generate the report.  */
  Error = 'ERROR',
  /**  The report is currently being generated.  */
  Pending = 'PENDING',
}

/**  The status of the measure report.  */
export type MeasureReportStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<MeasureReportStatus>>>
}

/** Optional subject identifying the individual or individuals the report is for. */
export type MeasureReportSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

export type MeasureResult = AuthorizationError | Measure | ResourceNotFoundError

export type MeasuresByIdentifierResult = AuthorizationError | MeasureConnection

/**
 *  A photo, video, or audio recording acquired or used in healthcare. The actual
 * content may be inline or provided by direct reference.
 */
export type Media = HealthgraphResource &
  Resource & {
    __typename?: 'Media'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The actual content of the media - inline or by direct reference to the media source file. */
    content?: Maybe<MediaContentResult>
    /** The date and time(s) at which the media was collected. */
    createdDateTime?: Maybe<Scalars['FhirDateTime']>
    /** The encounter that establishes the context for this media. */
    encounter?: Maybe<MediaEncounterResult>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Identifiers associated with the image - these may include identifiers for the
     * image itself, identifiers for the context of its collection (e.g. series ids)
     * and context ids such as accession numbers or other workflow identifiers.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** The person who administered the collection of the image. */
    operator?: Maybe<MediaOperatorResult>
    /** The current state of the {{title}}. */
    status?: Maybe<Scalars['FhirCode']>
    /** Who/What this Media is a record of. */
    subject?: Maybe<MediaSubjectResult>
    /** A code that classifies whether the media is an image, video or audio recording or some other media category. */
    type?: Maybe<MediaTypeResult>
  }

/**
 *  A photo, video, or audio recording acquired or used in healthcare. The actual
 * content may be inline or provided by direct reference.
 */
export type MediaIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of Media nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MediaConnection = Connection & {
  __typename?: 'MediaConnection'
  /** Edges in the current connection page. */
  edges: Array<MediaEdge>
  /** List of Media object nodes in the current connection page. */
  nodes: Array<Maybe<Media>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** The actual content of the media - inline or by direct reference to the media source file. */
export type MediaContentResult = Attachment | None

/** An edge in a Media connection page. */
export type MediaEdge = Edge & {
  __typename?: 'MediaEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Media object in this edge. */
  node?: Maybe<Media>
}

/** The encounter that establishes the context for this media. */
export type MediaEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/** The person who administered the collection of the image. */
export type MediaOperatorResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

export type MediaResult = AuthorizationError | Media | ResourceNotFoundError

export type MediasByIdentifierResult = AuthorizationError | MediaConnection

/** Who/What this Media is a record of. */
export type MediaSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/** A code that classifies whether the media is an image, video or audio recording or some other media category. */
export type MediaTypeResult = CodeableConcept | None

/**
 *  Indicates that a medication product is to be or has been dispensed for a named
 * person/patient.  This includes a description of the medication product (supply)
 * provided and the instructions for administering the medication.  The medication
 * dispense is the result of a pharmacy system responding to a medication order.
 */
export type MedicationDispense = HealthgraphResource &
  Resource & {
    __typename?: 'MedicationDispense'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** Indicates the medication order that is being dispensed against. */
    authorizingPrescriptions?: Maybe<MedicationDispenseAuthorizingPrescriptionResultConnection>
    /** Indicates if the medication dispensed is brand, generic, brand generic or multi-source brand */
    brandGenericIndicator?: Maybe<Scalars['FhirCode']>
    /**
     * Indicates the type of medication dispense (for example, where the medication
     * is expected to be consumed or administered (i.e. inpatient or outpatient)).
     */
    category?: Maybe<MedicationDispenseCategoryResult>
    /** The encounter or episode of care that establishes the context for this event. */
    context?: Maybe<MedicationDispenseContextResult>
    /** The amount of medication expressed as a timing amount. */
    daysSupply?: Maybe<MedicationDispenseDaysSupplyResult>
    /** Indicates how the medication is to be used by the patient. */
    dosageInstructions?: Maybe<DosageConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Identifiers associated with this Medication Dispense that are defined by
     * business processes and/or used to refer to it when a direct URL reference to
     * the resource itself is not appropriate. They are business identifiers assigned
     * to this resource by the performer or other systems and remain constant as the
     * resource is updated and propagates from server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * Identifies the medication being administered. This is either a link to a
     * resource representing the details of the medication or a simple attribute
     * carrying a code that identifies the medication from a known list of medications.
     */
    medicationCodeableConcept?: Maybe<MedicationDispenseMedicationCodeableConceptResult>
    /** Extra information about the dispense that could not be conveyed in the other attributes. */
    notes?: Maybe<AnnotationConnection>
    /** Indicates who or what performed the event. */
    performers?: Maybe<MedicationDispensePerformerConnection>
    /** The amount of medication that has been dispensed. Includes unit of measure. */
    quantity?: Maybe<MedicationDispenseQuantityResult>
    /**
     * Identifies the person who picked up the medication.  This will usually be a
     * patient or their caregiver, but some cases exist where it can be a healthcare professional.
     */
    receivers?: Maybe<MedicationDispenseReceiverResultConnection>
    /** The number that represents how many times this medication has been dispensed */
    refillNumber?: Maybe<Scalars['Int']>
    /** Where the data for this medicationDispense was received from e.g. Ambetter Claims, Prescription Provider */
    source?: Maybe<Scalars['String']>
    /** A code specifying the state of the set of dispense events. */
    status?: Maybe<Scalars['FhirCode']>
    /** A link to a resource representing the person or the group to whom the medication will be given. */
    subject?: Maybe<MedicationDispenseSubjectResult>
    /**
     * Indicates whether or not substitution was made as part of the dispense.  In
     * some cases, substitution will be expected but does not happen, in other cases
     * substitution is not expected but does happen.  This block explains what
     * substitution did or did not happen and why.  If nothing is specified,
     * substitution was not done.
     */
    substitution?: Maybe<MedicationDispenseSubstitutionResult>
    /**
     * Indicates the type of dispensing event that is performed. For example, Trial
     * Fill, Completion of Trial, Partial Fill, Emergency Fill, Samples, etc.
     */
    type?: Maybe<MedicationDispenseTypeResult>
    /** The time the dispensed product was provided to the patient or their representative. */
    whenHandedOver?: Maybe<Scalars['FhirDateTime']>
    /** The time when the dispensed product was packaged and reviewed. */
    whenPrepared?: Maybe<Scalars['FhirDateTime']>
  }

/**
 *  Indicates that a medication product is to be or has been dispensed for a named
 * person/patient.  This includes a description of the medication product (supply)
 * provided and the instructions for administering the medication.  The medication
 * dispense is the result of a pharmacy system responding to a medication order.
 */
export type MedicationDispenseAuthorizingPrescriptionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Indicates that a medication product is to be or has been dispensed for a named
 * person/patient.  This includes a description of the medication product (supply)
 * provided and the instructions for administering the medication.  The medication
 * dispense is the result of a pharmacy system responding to a medication order.
 */
export type MedicationDispenseDosageInstructionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Indicates that a medication product is to be or has been dispensed for a named
 * person/patient.  This includes a description of the medication product (supply)
 * provided and the instructions for administering the medication.  The medication
 * dispense is the result of a pharmacy system responding to a medication order.
 */
export type MedicationDispenseIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Indicates that a medication product is to be or has been dispensed for a named
 * person/patient.  This includes a description of the medication product (supply)
 * provided and the instructions for administering the medication.  The medication
 * dispense is the result of a pharmacy system responding to a medication order.
 */
export type MedicationDispenseNotesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Indicates that a medication product is to be or has been dispensed for a named
 * person/patient.  This includes a description of the medication product (supply)
 * provided and the instructions for administering the medication.  The medication
 * dispense is the result of a pharmacy system responding to a medication order.
 */
export type MedicationDispensePerformersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  Indicates that a medication product is to be or has been dispensed for a named
 * person/patient.  This includes a description of the medication product (supply)
 * provided and the instructions for administering the medication.  The medication
 * dispense is the result of a pharmacy system responding to a medication order.
 */
export type MedicationDispenseReceiversArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Indicates the medication order that is being dispensed against. */
export type MedicationDispenseAuthorizingPrescriptionResult =
  | ExternalResource
  | MedicationRequest
  | NonFederatedResource

/**
 * A page/slice of a list of MedicationDispenseAuthorizingPrescriptionResult nodes,
 * allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MedicationDispenseAuthorizingPrescriptionResultConnection = Connection & {
  __typename?: 'MedicationDispenseAuthorizingPrescriptionResultConnection'
  /** Edges in the current connection page. */
  edges: Array<MedicationDispenseAuthorizingPrescriptionResultEdge>
  /** List of MedicationDispenseAuthorizingPrescriptionResult object nodes in the current connection page. */
  nodes: Array<Maybe<MedicationDispenseAuthorizingPrescriptionResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a MedicationDispenseAuthorizingPrescriptionResult connection page. */
export type MedicationDispenseAuthorizingPrescriptionResultEdge = Edge & {
  __typename?: 'MedicationDispenseAuthorizingPrescriptionResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the MedicationDispenseAuthorizingPrescriptionResult object in this edge. */
  node?: Maybe<MedicationDispenseAuthorizingPrescriptionResult>
}

/**
 * Indicates the type of medication dispense (for example, where the medication is
 * expected to be consumed or administered (i.e. inpatient or outpatient)).
 */
export type MedicationDispenseCategoryResult = CodeableConcept | None

/**
 * A page/slice of a list of MedicationDispense nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MedicationDispenseConnection = Connection & {
  __typename?: 'MedicationDispenseConnection'
  /** Edges in the current connection page. */
  edges: Array<MedicationDispenseEdge>
  /** List of MedicationDispense object nodes in the current connection page. */
  nodes: Array<Maybe<MedicationDispense>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** The encounter or episode of care that establishes the context for this event. */
export type MedicationDispenseContextResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/** The amount of medication expressed as a timing amount. */
export type MedicationDispenseDaysSupplyResult = None | Quantity

/** An edge in a MedicationDispense connection page. */
export type MedicationDispenseEdge = Edge & {
  __typename?: 'MedicationDispenseEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the MedicationDispense object in this edge. */
  node?: Maybe<MedicationDispense>
}

/**
 * Identifies the medication being administered. This is either a link to a
 * resource representing the details of the medication or a simple attribute
 * carrying a code that identifies the medication from a known list of medications.
 */
export type MedicationDispenseMedicationCodeableConceptResult =
  | CodeableConcept
  | None

/**  Indicates who or what performed the event.  */
export type MedicationDispensePerformer = {
  __typename?: 'MedicationDispensePerformer'
  /**
   * The device, practitioner, etc. who performed the action.  It should be assumed
   * that the actor is the dispenser of the medication.
   */
  actor?: Maybe<MedicationDispensePerformerActorResult>
  /** Distinguishes the type of performer in the dispense.  For example, date enterer, packager, final checker. */
  function?: Maybe<MedicationDispensePerformerFunctionResult>
}

/**
 * The device, practitioner, etc. who performed the action.  It should be assumed
 * that the actor is the dispenser of the medication.
 */
export type MedicationDispensePerformerActorResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Organization
  | Patient
  | Practitioner

/**
 * A page/slice of a list of MedicationDispensePerformer nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MedicationDispensePerformerConnection = Connection & {
  __typename?: 'MedicationDispensePerformerConnection'
  /** Edges in the current connection page. */
  edges: Array<MedicationDispensePerformerEdge>
  /** List of MedicationDispensePerformer object nodes in the current connection page. */
  nodes: Array<Maybe<MedicationDispensePerformer>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a MedicationDispensePerformer connection page. */
export type MedicationDispensePerformerEdge = Edge & {
  __typename?: 'MedicationDispensePerformerEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the MedicationDispensePerformer object in this edge. */
  node?: Maybe<MedicationDispensePerformer>
}

/** Distinguishes the type of performer in the dispense.  For example, date enterer, packager, final checker. */
export type MedicationDispensePerformerFunctionResult = CodeableConcept | None

/** The amount of medication that has been dispensed. Includes unit of measure. */
export type MedicationDispenseQuantityResult = None | Quantity

/**
 * Identifies the person who picked up the medication.  This will usually be a
 * patient or their caregiver, but some cases exist where it can be a healthcare professional.
 */
export type MedicationDispenseReceiverResult =
  | ExternalResource
  | NonFederatedResource
  | Patient
  | Practitioner

/**
 * A page/slice of a list of MedicationDispenseReceiverResult nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MedicationDispenseReceiverResultConnection = Connection & {
  __typename?: 'MedicationDispenseReceiverResultConnection'
  /** Edges in the current connection page. */
  edges: Array<MedicationDispenseReceiverResultEdge>
  /** List of MedicationDispenseReceiverResult object nodes in the current connection page. */
  nodes: Array<Maybe<MedicationDispenseReceiverResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a MedicationDispenseReceiverResult connection page. */
export type MedicationDispenseReceiverResultEdge = Edge & {
  __typename?: 'MedicationDispenseReceiverResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the MedicationDispenseReceiverResult object in this edge. */
  node?: Maybe<MedicationDispenseReceiverResult>
}

export type MedicationDispenseResult =
  | AuthorizationError
  | MedicationDispense
  | ResourceNotFoundError

export type MedicationDispensesByIdentifierResult =
  | AuthorizationError
  | MedicationDispenseConnection

/**  MedicationDispense Status Codes  */
export enum MedicationDispenseStatusCode {
  /**  The dispensed product was not and will never be picked up by the patient.  */
  Cancelled = 'CANCELLED',
  /**  The dispensed product has been picked up.  */
  Completed = 'COMPLETED',
  /**  The dispense was declined and not performed.  */
  Declined = 'DECLINED',
  /**  The dispense was entered in error and therefore nullified.  */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**  The dispensed product is ready for pickup.  */
  InProgress = 'IN_PROGRESS',
  /**
   *  The dispense process is paused while waiting for an external event to
   * reactivate the dispense.  For example, new stock has arrived or the prescriber has called.
   */
  OnHold = 'ON_HOLD',
  /**
   *  The core event has not started yet, but some staging activities have begun
   * (e.g. initial compounding or packaging of medication). Preparation stages may
   * be tracked for billing purposes.
   */
  Preparation = 'PREPARATION',
  /**  Actions implied by the dispense have been permanently halted, before all of them occurred.  */
  Stopped = 'STOPPED',
  /**
   *  The authoring system does not know which of the status values applies for this
   * medication dispense.  Note: this concept is not to be used for other - one of
   * the listed statuses is presumed to apply, it's just now known which one.
   */
  Unknown = 'UNKNOWN',
}

/**  MedicationDispense Status Codes  */
export type MedicationDispenseStatusCodeFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<MedicationDispenseStatusCode>>>
}

/** A link to a resource representing the person or the group to whom the medication will be given. */
export type MedicationDispenseSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**
 *  Indicates whether or not substitution was made as part of the dispense.  In some
 * cases, substitution will be expected but does not happen, in other cases
 * substitution is not expected but does happen.  This block explains what
 * substitution did or did not happen and why.  If nothing is specified,
 * substitution was not done.
 */
export type MedicationDispenseSubstitution = {
  __typename?: 'MedicationDispenseSubstitution'
  /** True if the dispenser dispensed a different drug or product from what was prescribed. */
  isWasSubstituted?: Maybe<Scalars['Boolean']>
}

/**
 * Indicates whether or not substitution was made as part of the dispense.  In some
 * cases, substitution will be expected but does not happen, in other cases
 * substitution is not expected but does happen.  This block explains what
 * substitution did or did not happen and why.  If nothing is specified,
 * substitution was not done.
 */
export type MedicationDispenseSubstitutionResult =
  | MedicationDispenseSubstitution
  | None

/**
 * Indicates the type of dispensing event that is performed. For example, Trial
 * Fill, Completion of Trial, Partial Fill, Emergency Fill, Samples, etc.
 */
export type MedicationDispenseTypeResult = CodeableConcept | None

/**
 *  An order or request for both supply of the medication and the instructions for
 * administration of the medication to a patient. The resource is called
 * "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder" to
 * generalize the use across inpatient and outpatient settings, including care
 * plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequest = HealthgraphResource &
  Resource & {
    __typename?: 'MedicationRequest'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The date (and perhaps time) when the prescription was initially written or authored on. */
    authoredOn?: Maybe<Scalars['FhirDateTime']>
    /**
     * Indicates the specific details for the dispense or medication supply part of a
     * medication request (also known as a Medication Prescription or Medication
     * Order).  Note that this information is not always sent with the order.  There
     * may be in some settings (e.g. hospitals) institutional or system support for
     * completing the dispense details in the pharmacy department.
     */
    dispenseRequest?: Maybe<MedicationRequestDispenseRequestResult>
    /** Indicates how the medication is to be used by the patient. */
    dosageInstructions?: Maybe<DosageConnection>
    /** The Encounter during which this [x] was created or to which the creation of this record is tightly associated. */
    encounter?: Maybe<MedicationRequestEncounterResult>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Identifiers associated with this medication request that are defined by
     * business processes and/or used to refer to it when a direct URL reference to
     * the resource itself is not appropriate. They are business identifiers assigned
     * to this resource by the performer or other systems and remain constant as the
     * resource is updated and propagates from server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Whether the request is a proposal, plan, or an original order. */
    intent?: Maybe<Scalars['FhirCode']>
    /** If true indicates that the provider is asking for the medication request not to occur. */
    isDoNotPerform?: Maybe<Scalars['Boolean']>
    /**
     * Identifies the medication being requested. This is a link to a resource that
     * represents the medication which may be the details of the medication or simply
     * an attribute carrying a code that identifies the medication from a known list
     * of medications.
     */
    medicationCodeableConcept?: Maybe<MedicationRequestMedicationCodeableConceptResult>
    /** Extra information about the prescription that could not be conveyed by the other attributes. */
    notes?: Maybe<AnnotationConnection>
    /** The specified desired performer of the medication treatment (e.g. the performer of the medication administration). */
    performer?: Maybe<MedicationRequestPerformerResult>
    /**
     * This field contains the detail of the prescription state as it is received
     * from the source data. The status field provides a mapped short list of these
     * detailed prescriptionStates
     */
    prescriptionStateDetail?: Maybe<Scalars['FhirCode']>
    /** Indicates how quickly the Medication Request should be addressed with respect to other requests. */
    priority?: Maybe<Scalars['FhirCode']>
    /** The reason or the indication for ordering or not ordering the medication. */
    reasonCodes?: Maybe<CodeableConceptConnection>
    /** The individual, organization, or device that initiated the request and has responsibility for its activation. */
    requester?: Maybe<MedicationRequestRequesterResult>
    /** A code specifying the current state of the order.  Generally, this will be active or completed state. */
    status?: Maybe<Scalars['FhirCode']>
    /** Captures the reason for the current state of the MedicationRequest. */
    statusReason?: Maybe<MedicationRequestStatusReasonResult>
    /** A link to a resource representing the person or set of individuals to whom the medication will be given. */
    subject?: Maybe<MedicationRequestSubjectResult>
  }

/**
 *  An order or request for both supply of the medication and the instructions for
 * administration of the medication to a patient. The resource is called
 * "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder" to
 * generalize the use across inpatient and outpatient settings, including care
 * plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequestDosageInstructionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An order or request for both supply of the medication and the instructions for
 * administration of the medication to a patient. The resource is called
 * "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder" to
 * generalize the use across inpatient and outpatient settings, including care
 * plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequestIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An order or request for both supply of the medication and the instructions for
 * administration of the medication to a patient. The resource is called
 * "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder" to
 * generalize the use across inpatient and outpatient settings, including care
 * plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequestNotesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  An order or request for both supply of the medication and the instructions for
 * administration of the medication to a patient. The resource is called
 * "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder" to
 * generalize the use across inpatient and outpatient settings, including care
 * plans, etc., and to harmonize with workflow patterns.
 */
export type MedicationRequestReasonCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of MedicationRequest nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MedicationRequestConnection = Connection & {
  __typename?: 'MedicationRequestConnection'
  /** Edges in the current connection page. */
  edges: Array<MedicationRequestEdge>
  /** List of MedicationRequest object nodes in the current connection page. */
  nodes: Array<Maybe<MedicationRequest>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 *  Indicates the specific details for the dispense or medication supply part of a
 * medication request (also known as a Medication Prescription or Medication
 * Order).  Note that this information is not always sent with the order.  There
 * may be in some settings (e.g. hospitals) institutional or system support for
 * completing the dispense details in the pharmacy department.
 */
export type MedicationRequestDispenseRequest = {
  __typename?: 'MedicationRequestDispenseRequest'
  /** Indicates the quantity or duration for the first dispense of the medication. */
  initialFill?: Maybe<MedicationRequestDispenseRequestInitialFillResult>
  /** This indicates the validity period of a prescription (stale dating the Prescription). */
  validityPeriod?: Maybe<MedicationRequestDispenseRequestValidityPeriodResult>
}

/**  Indicates the quantity or duration for the first dispense of the medication.  */
export type MedicationRequestDispenseRequestInitialFill = {
  __typename?: 'MedicationRequestDispenseRequestInitialFill'
  /** The length of time that the first dispense is expected to last. */
  duration?: Maybe<MedicationRequestDispenseRequestInitialFillDurationResult>
  /** The amount or quantity to provide as part of the first dispense. */
  quantity?: Maybe<MedicationRequestDispenseRequestInitialFillQuantityResult>
}

/** The length of time that the first dispense is expected to last. */
export type MedicationRequestDispenseRequestInitialFillDurationResult =
  | Duration
  | None

/** The amount or quantity to provide as part of the first dispense. */
export type MedicationRequestDispenseRequestInitialFillQuantityResult =
  | None
  | Quantity

/** Indicates the quantity or duration for the first dispense of the medication. */
export type MedicationRequestDispenseRequestInitialFillResult =
  | MedicationRequestDispenseRequestInitialFill
  | None

/**
 * Indicates the specific details for the dispense or medication supply part of a
 * medication request (also known as a Medication Prescription or Medication
 * Order).  Note that this information is not always sent with the order.  There
 * may be in some settings (e.g. hospitals) institutional or system support for
 * completing the dispense details in the pharmacy department.
 */
export type MedicationRequestDispenseRequestResult =
  | MedicationRequestDispenseRequest
  | None

/** This indicates the validity period of a prescription (stale dating the Prescription). */
export type MedicationRequestDispenseRequestValidityPeriodResult = None | Period

/** An edge in a MedicationRequest connection page. */
export type MedicationRequestEdge = Edge & {
  __typename?: 'MedicationRequestEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the MedicationRequest object in this edge. */
  node?: Maybe<MedicationRequest>
}

/** The Encounter during which this [x] was created or to which the creation of this record is tightly associated. */
export type MedicationRequestEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  MedicationRequest Intent Codes  */
export enum MedicationRequestIntent {
  /**
   *  The request represents the view of an authorization instantiated by a
   * fulfilling system representing the details of the fulfiller's intention to act
   * upon a submitted order.
   */
  FillerOrder = 'FILLER_ORDER',
  /**  The request represents an instance for the particular order, for example a medication administration record.  */
  InstanceOrder = 'INSTANCE_ORDER',
  /**
   *  The request represents a component or option for a RequestGroup that
   * establishes timing, conditionality and/or  other constraints among a set of requests.
   */
  Option = 'OPTION',
  /**  The request represents a request/demand and authorization for action  */
  Order = 'ORDER',
  /**  The request represents the original authorization for the medication request.  */
  OriginalOrder = 'ORIGINAL_ORDER',
  /**  The request represents an intention to ensure something occurs without providing an authorization for others to act.  */
  Plan = 'PLAN',
  /**
   *  The request is a suggestion made by someone/something that doesn't have an
   * intention to ensure it occurs and without providing an authorization to act
   */
  Proposal = 'PROPOSAL',
  /**
   *  The request represents an automatically generated supplemental authorization
   * for action based on a parent authorization together with initial results of
   * the action taken against that parent authorization..
   */
  ReflexOrder = 'REFLEX_ORDER',
}

/**  MedicationRequest Intent Codes  */
export type MedicationRequestIntentFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<MedicationRequestIntent>>>
}

/**
 * Identifies the medication being requested. This is a link to a resource that
 * represents the medication which may be the details of the medication or simply
 * an attribute carrying a code that identifies the medication from a known list of medications.
 */
export type MedicationRequestMedicationCodeableConceptResult =
  | CodeableConcept
  | None

/** The specified desired performer of the medication treatment (e.g. the performer of the medication administration). */
export type MedicationRequestPerformerResult =
  | CareTeam
  | Device
  | ExternalResource
  | HealthgraphRelatedPerson
  | None
  | NonFederatedResource
  | Organization
  | Patient
  | Practitioner
  | PractitionerRole

/** The individual, organization, or device that initiated the request and has responsibility for its activation. */
export type MedicationRequestRequesterResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

export type MedicationRequestResult =
  | AuthorizationError
  | MedicationRequest
  | ResourceNotFoundError

export type MedicationRequestsByIdentifierResult =
  | AuthorizationError
  | MedicationRequestConnection

/**  MedicationRequest Status Codes  */
export enum MedicationrequestStatus {
  /**  The prescription is 'actionable', but not all actions that are implied by it have occurred yet.  */
  Active = 'ACTIVE',
  /**  The prescription has been withdrawn before any administrations have occurred  */
  Cancelled = 'CANCELLED',
  /**  All actions that are implied by the prescription have occurred.  */
  Completed = 'COMPLETED',
  /**
   *  The prescription is not yet 'actionable', e.g. it is a work in progress,
   * requires sign-off, verification or needs to be run through decision support process.
   */
  Draft = 'DRAFT',
  /**
   *  Some of the actions that are implied by the medication request may have
   * occurred.  For example, the medication may have been dispensed and the patient
   * may have taken some of the medication.  Clinical decision support systems
   * should take this status into account
   */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**
   *  Actions implied by the prescription are to be temporarily halted, but are
   * expected to continue later.  May also be called 'suspended'.
   */
  OnHold = 'ON_HOLD',
  /**
   *  Actions implied by the prescription are to be permanently halted, before all
   * of the administrations occurred. This should not be used if the original order
   * was entered in error
   */
  Stopped = 'STOPPED',
  /**
   *  The authoring/source system does not know which of the status values currently
   * applies for this observation. Note: This concept is not to be used for 'other'
   * - one of the listed statuses is presumed to apply, but the authoring/source
   * system does not know which.
   */
  Unknown = 'UNKNOWN',
}

/**  MedicationRequest Status Codes  */
export type MedicationrequestStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<MedicationrequestStatus>>>
}

/** Captures the reason for the current state of the MedicationRequest. */
export type MedicationRequestStatusReasonResult = CodeableConcept | None

/** A link to a resource representing the person or set of individuals to whom the medication will be given. */
export type MedicationRequestSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**
 *  A record of a medication that is being consumed by a patient.   A
 * MedicationStatement may indicate that the patient may be taking the medication
 * now or has taken the medication in the past or will be taking the medication in
 * the future.  The source of this information can be the patient, significant
 * other (such as a family member or spouse), or a clinician.  A common scenario
 * where this information is captured is during the history taking process during a
 * patient visit or stay.   The medication information may come from sources such
 * as the patient's memory, from a prescription bottle,  or from a list of
 * medications the patient, clinician or other party maintains. The primary
 * difference between a medication statement and a medication administration is
 * that the medication administration has complete administration information and
 * is based on actual administration information from the person who administered
 * the medication.  A medication statement is often, if not always, less specific.
 * There is no required date/time when the medication was administered, in fact we
 * only know that a source has reported the patient is taking this medication,
 * where details such as time, quantity, or rate or even medication product may be
 * incomplete or missing or less precise.  As stated earlier, the medication
 * statement information may come from the patient's memory, from a prescription
 * bottle or from a list of medications the patient, clinician or other party
 * maintains.  Medication administration is more formal and is not missing detailed information.
 */
export type MedicationStatement = HealthgraphResource &
  Resource & {
    __typename?: 'MedicationStatement'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The encounter or episode of care that establishes the context for this MedicationStatement. */
    context?: Maybe<MedicationStatementContextResult>
    /** The date when the medication statement was asserted by the information source. */
    dateAsserted?: Maybe<Scalars['FhirDateTime']>
    /** Indicates how the medication is/was or should be taken by the patient. */
    dosages?: Maybe<DosageConnection>
    /**
     * The interval of time during which it is being asserted that the patient
     * is/was/will be taking the medication (or was not taking, when the
     * MedicationStatement.taken element is No).
     */
    effectiveDateTime?: Maybe<Scalars['FhirDateTime']>
    /**
     * The interval of time during which it is being asserted that the patient
     * is/was/will be taking the medication (or was not taking, when the
     * MedicationStatement.taken element is No).
     */
    effectivePeriod?: Maybe<MedicationStatementEffectivePeriodResult>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Identifiers associated with this Medication Statement that are defined by
     * business processes and/or used to refer to it when a direct URL reference to
     * the resource itself is not appropriate. They are business identifiers assigned
     * to this resource by the performer or other systems and remain constant as the
     * resource is updated and propagates from server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * The person or organization that provided the information about the taking of
     * this medication. Note: Use derivedFrom when a MedicationStatement is derived
     * from other resources, e.g. Claim or MedicationRequest.
     */
    informationSource?: Maybe<MedicationStatementInformationSourceResult>
    /**
     * Identifies the medication being administered. This is either a link to a
     * resource representing the details of the medication or a simple attribute
     * carrying a code that identifies the medication from a known list of medications.
     */
    medicationCodeableConcept?: Maybe<MedicationStatementMedicationCodeableConceptResult>
    /** Provides extra information about the medication statement that is not conveyed by the other attributes. */
    notes?: Maybe<AnnotationConnection>
    /**
     * A code representing the patient or other source's judgment about the state of
     * the medication used that this statement is about.  Generally, this will be
     * active or completed.
     */
    status?: Maybe<Scalars['FhirCode']>
    /** The person, animal or group who is/was taking the medication. */
    subject?: Maybe<MedicationStatementSubjectResult>
  }

/**
 *  A record of a medication that is being consumed by a patient.   A
 * MedicationStatement may indicate that the patient may be taking the medication
 * now or has taken the medication in the past or will be taking the medication in
 * the future.  The source of this information can be the patient, significant
 * other (such as a family member or spouse), or a clinician.  A common scenario
 * where this information is captured is during the history taking process during a
 * patient visit or stay.   The medication information may come from sources such
 * as the patient's memory, from a prescription bottle,  or from a list of
 * medications the patient, clinician or other party maintains. The primary
 * difference between a medication statement and a medication administration is
 * that the medication administration has complete administration information and
 * is based on actual administration information from the person who administered
 * the medication.  A medication statement is often, if not always, less specific.
 * There is no required date/time when the medication was administered, in fact we
 * only know that a source has reported the patient is taking this medication,
 * where details such as time, quantity, or rate or even medication product may be
 * incomplete or missing or less precise.  As stated earlier, the medication
 * statement information may come from the patient's memory, from a prescription
 * bottle or from a list of medications the patient, clinician or other party
 * maintains.  Medication administration is more formal and is not missing detailed information.
 */
export type MedicationStatementDosagesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A record of a medication that is being consumed by a patient.   A
 * MedicationStatement may indicate that the patient may be taking the medication
 * now or has taken the medication in the past or will be taking the medication in
 * the future.  The source of this information can be the patient, significant
 * other (such as a family member or spouse), or a clinician.  A common scenario
 * where this information is captured is during the history taking process during a
 * patient visit or stay.   The medication information may come from sources such
 * as the patient's memory, from a prescription bottle,  or from a list of
 * medications the patient, clinician or other party maintains. The primary
 * difference between a medication statement and a medication administration is
 * that the medication administration has complete administration information and
 * is based on actual administration information from the person who administered
 * the medication.  A medication statement is often, if not always, less specific.
 * There is no required date/time when the medication was administered, in fact we
 * only know that a source has reported the patient is taking this medication,
 * where details such as time, quantity, or rate or even medication product may be
 * incomplete or missing or less precise.  As stated earlier, the medication
 * statement information may come from the patient's memory, from a prescription
 * bottle or from a list of medications the patient, clinician or other party
 * maintains.  Medication administration is more formal and is not missing detailed information.
 */
export type MedicationStatementIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A record of a medication that is being consumed by a patient.   A
 * MedicationStatement may indicate that the patient may be taking the medication
 * now or has taken the medication in the past or will be taking the medication in
 * the future.  The source of this information can be the patient, significant
 * other (such as a family member or spouse), or a clinician.  A common scenario
 * where this information is captured is during the history taking process during a
 * patient visit or stay.   The medication information may come from sources such
 * as the patient's memory, from a prescription bottle,  or from a list of
 * medications the patient, clinician or other party maintains. The primary
 * difference between a medication statement and a medication administration is
 * that the medication administration has complete administration information and
 * is based on actual administration information from the person who administered
 * the medication.  A medication statement is often, if not always, less specific.
 * There is no required date/time when the medication was administered, in fact we
 * only know that a source has reported the patient is taking this medication,
 * where details such as time, quantity, or rate or even medication product may be
 * incomplete or missing or less precise.  As stated earlier, the medication
 * statement information may come from the patient's memory, from a prescription
 * bottle or from a list of medications the patient, clinician or other party
 * maintains.  Medication administration is more formal and is not missing detailed information.
 */
export type MedicationStatementNotesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of MedicationStatement nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MedicationStatementConnection = Connection & {
  __typename?: 'MedicationStatementConnection'
  /** Edges in the current connection page. */
  edges: Array<MedicationStatementEdge>
  /** List of MedicationStatement object nodes in the current connection page. */
  nodes: Array<Maybe<MedicationStatement>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** The encounter or episode of care that establishes the context for this MedicationStatement. */
export type MedicationStatementContextResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/** An edge in a MedicationStatement connection page. */
export type MedicationStatementEdge = Edge & {
  __typename?: 'MedicationStatementEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the MedicationStatement object in this edge. */
  node?: Maybe<MedicationStatement>
}

/**
 * The interval of time during which it is being asserted that the patient
 * is/was/will be taking the medication (or was not taking, when the
 * MedicationStatement.taken element is No).
 */
export type MedicationStatementEffectivePeriodResult = None | Period

/**  Specifies a filter to apply to a list of MedicationStatement  */
export type MedicationStatementFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<MedicationStatementFilter>>
  /**
   * Filters on (MedicationStatement.medication as CodeableConcept)
   * Return statements of this medication code
   */
  code?: Maybe<CodeFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on MedicationStatement.context
   * Returns statements for a specific context (episode or episode of Care).
   */
  contextUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on MedicationStatement.effective
   * Date when patient was taking (or not taking) the medication
   */
  effective?: Maybe<DateFilter>
  /**
   * Filters on MedicationStatement.identifier
   * Return statements with this external identifier
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<MedicationStatementFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<MedicationStatementFilter>>
  /**
   * Filters on MedicationStatement.subject.where(resolve() is Patient)
   * Returns statements for a specific patient.
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on MedicationStatement.informationSource
   * Who or where the information in the statement came from
   */
  sourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on MedicationStatement.status
   * Return statements that match the given status
   */
  status?: Maybe<MedicationStatusCodeFilter>
  /**
   * Filters on MedicationStatement.subject
   * The identity of a patient, animal or group to list statements for
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/**
 * The person or organization that provided the information about the taking of
 * this medication. Note: Use derivedFrom when a MedicationStatement is derived
 * from other resources, e.g. Claim or MedicationRequest.
 */
export type MedicationStatementInformationSourceResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Organization
  | Practitioner

/**
 * Identifies the medication being administered. This is either a link to a
 * resource representing the details of the medication or a simple attribute
 * carrying a code that identifies the medication from a known list of medications.
 */
export type MedicationStatementMedicationCodeableConceptResult =
  | CodeableConcept
  | None

export type MedicationStatementResult =
  | AuthorizationError
  | MedicationStatement
  | ResourceNotFoundError

export type MedicationStatementsByIdentifierResult =
  | AuthorizationError
  | MedicationStatementConnection

/**  A sort on MedicationStatement - consists of the field to sort on and the ordering  */
export type MedicationStatementSort = {
  /**  Which field to sort by  */
  field: MedicationStatementSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that MedicationStatement can be sorted by  */
export enum MedicationStatementSortOption {
  /**
   * Sorts on MedicationStatement.effective
   * Date when patient was taking (or not taking) the medication
   */
  Effective = 'EFFECTIVE',
  /**  Do not sort  */
  None = 'NONE',
}

/** The person, animal or group who is/was taking the medication. */
export type MedicationStatementSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**  Medication Status Codes  */
export enum MedicationStatusCode {
  /**  The medication is still being taken.  */
  Active = 'ACTIVE',
  /**  The medication is no longer being taken.  */
  Completed = 'COMPLETED',
  /**
   *  Some of the actions that are implied by the medication statement may have
   * occurred.  For example, the patient may have taken some of the medication.
   * Clinical decision support systems should take this status into account.
   */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**  The medication may be taken at some time in the future.  */
  Intended = 'INTENDED',
  /**  The medication was not consumed by the patient  */
  NotTaken = 'NOT_TAKEN',
  /**
   *  Actions implied by the statement have been temporarily halted, but are
   * expected to continue later. May also be called 'suspended'.
   */
  OnHold = 'ON_HOLD',
  /**
   *  Actions implied by the statement have been permanently halted, before all of
   * them occurred. This should not be used if the statement was entered in error.
   */
  Stopped = 'STOPPED',
  /**  The state of the medication use is not currently known.  */
  Unknown = 'UNKNOWN',
}

/**  Medication Status Codes  */
export type MedicationStatusCodeFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<MedicationStatusCode>>>
}

/** Member experience domain. */
export type MemberExperience = {
  __typename?: 'MemberExperience'
  /** Get memberships in the supported experience groups. */
  groupMemberships?: Maybe<MemberExperienceGroupMembershipConnection>
}

/** Member experience domain. */
export type MemberExperienceGroupMembershipsArgs = {
  after?: Maybe<Scalars['Cursor']>
  filter?: Maybe<MemberExperienceGroupMembershipsFilter>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** Definition of the member experience group. */
export type MemberExperienceGroup = {
  __typename?: 'MemberExperienceGroup'
  /** Codename of the group. */
  codename: Scalars['String']
}

/** Active membership in an experience group that the member was assigned to. */
export type MemberExperienceGroupMembership = {
  __typename?: 'MemberExperienceGroupMembership'
  /** Assigned member experience group. */
  group?: Maybe<MemberExperienceGroup>
}

/** Member experience group membership connection. */
export type MemberExperienceGroupMembershipConnection = Connection & {
  __typename?: 'MemberExperienceGroupMembershipConnection'
  /** Edges in the current connection page. */
  edges: Array<MemberExperienceGroupMembershipEdge>
  /** List of metadata object nodes in the current connection page. */
  nodes: Array<Maybe<MemberExperienceGroupMembership>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Member experience group membership edge. */
export type MemberExperienceGroupMembershipEdge = Edge & {
  __typename?: 'MemberExperienceGroupMembershipEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the membership in member experience group in this edge. */
  node?: Maybe<MemberExperienceGroupMembership>
}

/** Filter the member experience groups. */
export type MemberExperienceGroupMembershipsFilter = {
  /**
   * Filter by one or many codenames of the groups.
   * The provided codes can match some, but not necessarily all.
   */
  codeNames?: Maybe<Array<Scalars['String']>>
  /** Filter by partner ID. */
  partnerId?: Maybe<Scalars['String']>
}

/** Membership of the patient. */
export type Membership = {
  __typename?: 'Membership'
  /** The contract associated to the membership (more details [here](https://platform.ops.babylontech.co.uk/products/partner_management/tenancy/#contract)). */
  contract: MembershipContract
  /** The ID of the membership. */
  id: Scalars['ID']
}

/**
 * A page/slice of a list of nodes of type `Membership`, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type MembershipConnection = {
  __typename?: 'MembershipConnection'
  /** Edges in the current connection page. */
  edges: Array<MembershipEdge>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
}

/** Contract of the patient (more details [here](https://platform.ops.babylontech.co.uk/products/partner_management/tenancy/#contract)). */
export type MembershipContract = {
  __typename?: 'MembershipContract'
  /** The ID of the membership contract. */
  id: Scalars['ID']
  /** ID of the partner (more details [here](https://platform.ops.babylontech.co.uk/products/partner_management/tenancy/#partner)). */
  partnerId: Scalars['ID']
  /** ID of the associated plan (more details [here](https://platform.ops.babylontech.co.uk/products/partner_management/tenancy/#plan)). */
  planId: Scalars['ID']
  /** Name of the associated plan (more details [here](https://platform.ops.babylontech.co.uk/products/partner_management/tenancy/#plan)). */
  planName: Scalars['String']
  /** ISO 3166-1 alpha-3 code of the region (more details [here](https://platform.ops.babylontech.co.uk/products/partner_management/tenancy/#region)). */
  regionId: Scalars['ID']
}

/**
 * An edge in a connection page for a node of type `Membership`.
 *
 * See the [GraphQL Edges Specification](https://relay.dev/graphql/connections.htm#sec-Edges) for guidance on how to use.
 */
export type MembershipEdge = {
  __typename?: 'MembershipEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node: Membership
}

export type MembershipsResourceBadRequestError = {
  __typename?: 'MembershipsResourceBadRequestError'
  /** The error message. */
  errorMessage: Scalars['String']
  /** __typename of the resource which results in a bad request. */
  resourceTypename?: Maybe<Scalars['String']>
  /** Requested resource ID. */
  unknownId: Scalars['ID']
}

export type MembershipsResourceNotFoundError = {
  __typename?: 'MembershipsResourceNotFoundError'
  /** The error message. */
  errorMessage: Scalars['String']
  /** __typename of the resource which results in a bad request. */
  resourceTypename?: Maybe<Scalars['String']>
  /** Requested resource ID. */
  unknownId: Scalars['ID']
}

/** Membership results. */
export type MembershipsResult = {
  __typename?: 'MembershipsResult'
  /** Membership results. */
  results: MembershipConnection
  /** The total number of items in the underlying data set. */
  totalResults: Scalars['NonNegativeInt']
}

/** Search parameters. */
export type MembersSearch = {
  /** Patient ID in Core Ruby (this overrides all other search parameters). */
  coreRubyPatientId?: Maybe<Scalars['NonNegativeInt']>
  /** Patient date of birth. */
  dateOfBirth?: Maybe<Scalars['Date']>
  /** Patient email address. */
  email?: Maybe<Scalars['EmailAddress']>
  /** Patient first name. */
  firstName?: Maybe<Scalars['String']>
  /** Patient identifier value (this overrides all other search parameters). */
  identifierValue?: Maybe<Scalars['String']>
  /** Patient last name. */
  lastName?: Maybe<Scalars['String']>
  /** Patient national ID (this overrides all other search parameters). */
  nationalId?: Maybe<Scalars['String']>
  /** Patient phone number. */
  phoneNumber?: Maybe<Scalars['String']>
  /** Patient post code. */
  postCode?: Maybe<Scalars['PostalCode']>
}

/** Search results. */
export type MembersSearchResponse = {
  __typename?: 'MembersSearchResponse'
  /** Search results. */
  results: UserConnection
  /**
   * The total number of items in the underlying data set.
   *
   * Capped at 10000 as it is infeasible to efficiently calculate the value beyond this threshold.
   */
  totalResults: Scalars['NonNegativeInt']
}

/**
 * # Message
 * The Message resource is the block unit resource of the Conversation.
 * It is an immutable, renderable item that also includes metadata such as the sender, CRUD timestamps, and more.
 */
export type Message = {
  __typename?: 'Message'
  /** Card message input. Cards allows to answer message after conversation progression */
  card?: Maybe<CardMessageInput>
  /** if true, the message is not stored in the conversation */
  ephemeral?: Maybe<Scalars['Boolean']>
  /** group id. Messages from the same group will be rendered together */
  group?: Maybe<Scalars['ID']>
  /** unique id */
  id: Scalars['ID']
  /** semantic information about message */
  label?: Maybe<Scalars['String']>
  /** who send th emessage */
  sender: MessageSender
  /** sent at timestamp */
  sentAt: Scalars['DateTime']
  /** Style string used by frontend to render the message */
  style?: Maybe<Scalars['String']>
  /** Value of the message to show to user */
  value: MessageValue
}

/** Connection to message */
export type MessageConnection = Connection & {
  __typename?: 'MessageConnection'
  /** edges */
  edges: Array<MessageEdge>
  nodes: Array<Maybe<Message>>
  /** information used for pagination */
  pageInfo: PageInfo
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Message edge, points to message */
export type MessageEdge = Edge & {
  __typename?: 'MessageEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node: Message
}

/**
 * # Message Input
 * Object that dictates how the client should collect input for the next createConversationMessage mutation.
 */
export type MessageInput =
  | CardMessageInput
  | ChoicesMessageInput
  | DateMessageInput
  | FormMessageInput
  | MeasurementMessageInput
  | NumberMessageInput
  | PhoneMessageInput
  | SymptomMessageInput
  | TextAndFilesMessageInput
  | TextMessageInput

/** Metadata associated with a message */
export type MessageMetaData = {
  __typename?: 'MessageMetaData'
  /** The metadata field key. */
  key?: Maybe<Scalars['String']>
  /** The metadata field value. */
  value?: Maybe<Scalars['String']>
}

/** Conversation message meta data connection */
export type MessageMetaDataConnection = Connection & {
  __typename?: 'MessageMetaDataConnection'
  /** Edges in the current connection page. */
  edges: Array<MessageMetaDataEdge>
  /** List of metadata object nodes in the current connection page. */
  nodes: Array<Maybe<MessageMetaData>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Conversation message meta data edge */
export type MessageMetaDataEdge = Edge & {
  __typename?: 'MessageMetaDataEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the metadata object in this edge. */
  node?: Maybe<MessageMetaData>
}

/** Input message metadata. */
export type MessageMetaDataInput = {
  /** The metadata field key. */
  key?: Maybe<Scalars['String']>
  /** The metadata field value. */
  value?: Maybe<Scalars['String']>
}

/** Possible sender of message */
export enum MessageSender {
  /** message is an answer to member message */
  Babylon = 'babylon',
  /** message is send by member */
  User = 'user',
}

/**
 * # Message Value
 * Object that provides semantics on how the Message should be rendered by the client.
 */
export type MessageValue =
  | ActionMessageValue
  | AnimationMessageValue
  | DiagnosisMessageValue
  | ImageMessageValue
  | LeafletMessageValue
  | LocationMessageValue
  | MarkdownMessageValue
  | NoticeMessageValue
  | TextMessageValue

/** Information about an MFA enrollment */
export type MfaEnrollment = {
  __typename?: 'MFAEnrollment'
  /** The MFA enrollment ID from Auth0 */
  id: Scalars['ID']
  /**
   * Magic link to start the enrollment process to MFA (like a password reset flow)
   *
   * Available only after a request to `createSMSMFAEnrollment`
   */
  setupUrl?: Maybe<Scalars['Url']>
  /** Status of the MFA enrollment (e.g. PENDING, CONFIRMED, DELETED) */
  status?: Maybe<MfaEnrollmentStatus>
  /** Type of the MFA enrollment (e.g. SMS, OTHER) */
  type?: Maybe<MfaEnrollmentType>
}

/** A page/slice of a list of nodes of type `MFAEnrollment`, allowing for standardised pagination/navigation of the list. */
export type MfaEnrollmentConnection = Connection & {
  __typename?: 'MFAEnrollmentConnection'
  /** Edges in the current connection page. */
  edges: Array<MfaEnrollmentEdge>
  /** List of `MFAEnrollment` nodes in the current connection page. */
  nodes: Array<Maybe<MfaEnrollment>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An edge in a connection page for a node of type `MFAEnrollment`.
 *
 * See the [GraphQL Edges Specification](https://relay.dev/graphql/connections.htm#sec-Edges) for guidance on how to use.
 */
export type MfaEnrollmentEdge = Edge & {
  __typename?: 'MFAEnrollmentEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   *
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<MfaEnrollment>
}

/** MFA Enrollment status */
export enum MfaEnrollmentStatus {
  /** Confirmed */
  Confirmed = 'CONFIRMED',
  /** Deleted */
  Deleted = 'DELETED',
  /** Pending */
  Pending = 'PENDING',
}

/** MFA Enrollment type */
export enum MfaEnrollmentType {
  /** Enrolment by some other means. */
  Other = 'OTHER',
  /** Enrolment using an SMS message. */
  Sms = 'SMS',
}

/**  An amount of economic utility in some recognized currency. */
export type Money = {
  __typename?: 'Money'
  /**  An ISO 4217 code for the currency */
  currency?: Maybe<Scalars['FhirCode']>
  /**  The amount of the currency, including an implicit precision */
  value?: Maybe<Scalars['Float']>
}

/** Idempotency Key */
export type MutableResource = {
  /** Obtained from previous response. Used to prevent duplicate mutations. */
  idempotencyKey: Scalars['String']
}

/** Root mutation type. */
export type Mutation = {
  __typename?: 'Mutation'
  /**
   * # Create a new input message on the Conversation
   * Appends the caller's input message to the Conversation, and returns the next ConversationStep.
   */
  _chat_createConversationMessage: Conversation
  /** # Give feedback about a Message in a Conversation */
  _chat_createConversationMessageFeedback?: Maybe<Scalars['Void']>
  /** # Rate a Conversation */
  _chat_createConversationMessageRating?: Maybe<Scalars['Void']>
  /**
   * # Start a new Conversation
   *
   * Generate a new Conversation resource, along with its first ConversationStep.
   */
  _chat_initiateConversation: Conversation
  /**
   * # Start a new Conversation
   *
   * Generate a new Conversation resource, along with its first ConversationStep.
   * @deprecated replaced by _chat_initiateConversation
   */
  _chat_startConversation: Conversation
  /**
   * # Undo the last step of the conversation (if isUndoable)
   *
   * Pops the messages of the last ConversationStep of the Conversation.
   */
  _chat_undoLastConversationStep: Conversation
  /** Create invite for welcome appointment */
  _firstmile_createWelcomeAppointmentInvite: CreateWelcomeAppointmentInviteResult
  /** Create request to delete user account */
  _firstmile_requestPatientAccountDeletion: RequestPatientAccountDeletionResult
  /**
   * # eslint-disable-line @babylon/graphql/mutation-belongs-to
   * Mark given inbox communication as read
   */
  _inbox_updateInboxCommunicationIsRead: UpdateInboxCommunicationIsReadResponse
  /** Adds a new metric to the logged in user's record */
  _myhealth_healthStatusAddMetricDatapoint?: Maybe<Scalars['Void']>
  /** Notify external EHR's that a care plan was assigned to a patient */
  _patientcare_notifyEhrCarePlanAssigned?: Maybe<PatientCareNotifyEhrCarePlanAssignedResult>
  /** Register a patient and create a guardian relationship from another patient. */
  _registration_registerPatientAndCreateGuardianRelationship: RegistrationRegisterPatientAndCreateGuardianRelationshipResult
  /** Add an address to the `addresses` list */
  addPatientAddress: AddPatientAddressResult
  /** Add an email address to the `telecoms` list */
  addPatientEmailAddress: AddPatientEmailAddressResult
  /**
   * Add an identifier to the `identifiers` list.
   *
   * If the provided patient ID does not exist, a ResourceNotFoundError will be returned.
   *
   * If the provided identifier already exists on the patient, its value will be overwritten.
   */
  addPatientIdentifier: AddPatientIdentifierResult
  /** Add a phone number to the `telecoms` list */
  addPatientPhoneNumber: AddPatientPhoneNumberResult
  /** Assign a new journey to be completed by patient */
  assignDataCollectionJourney?: Maybe<AssignDataCollectionJourneyResult>
  /** Creates a new Appointment Resource. DO NOT USE - Not yet implemented */
  bookAppointment?: Maybe<BookAppointmentResult>
  /** Cancels a booked appointment. */
  cancelAppointment?: Maybe<CancelAppointmentResult>
  /** Updates the status of the provided appointment action to be 'COMPLETED'. */
  completeAppointmentAction?: Maybe<CompleteAppointmentActionResult>
  /** Complete current stage */
  completeDataCollectionJourneyStage?: Maybe<CompleteDataCollectionJourneyStageResult>
  /**
   * Completes a challenge action (by updating the status to `COMPLETED` and
   * setting the `completed` timestamp to the current time).
   *
   * If all the challenge actions have been completed or hidden, the overall challenge status will be marked as `COMPLETED`,
   * and the corresponding completed timestamp will be updated to reflect the time at which this occurred. Note that you can
   * not complete challenges at the `UserChallenge` level, and that challenges can
   * only be completed by completing their constituent
   * actions.
   *
   * This mutation returns an `AuthorizationError` if the logged in user is not
   * authorized to complete challenges on behalf of the user associated with
   * challenge action.
   */
  completeUserChallengeAction?: Maybe<CompleteUserChallengeActionResult>
  /** Create a GP Organization. */
  createGpOrganization?: Maybe<CreateGpOrganizationResult>
  /** Create a new patient. */
  createPatient: CreatePatientResult
  /**
   * Creates a new user challenge for a patient.
   *
   * The challenge will be provisioned as per the actions in the given challenge template. The challenge begins life in the
   * `ACCEPTED` state. The client may only hide a challenge, or complete challenge
   * actions, in order to update the challenge state.
   *
   * This mutation returns an `AuthorizationError` if the logged in user is not
   * authorized to accept challenges on behalf of the patient.
   */
  createPatientUserChallenge?: Maybe<CreatePatientUserChallengeResult>
  /** Create a new pharmacist */
  createPharmacistPractitioner?: Maybe<CreatePharmacistPractitionerResult>
  /** Challenge a User via SMS to complete a Multi-factor Authentication */
  createSMSMFAChallenge?: Maybe<CreateSmsmfaChallengeResult>
  /** Enroll a User to Multi-factor Authentication via SMS */
  createSMSMFAEnrollment?: Maybe<CreateSmsmfaEnrollmentResult>
  /** Create Supply Network */
  createSupplyNetwork?: Maybe<CreateSupplyNetworkResult>
  /** Delete GP Organization */
  deleteGpOrganization?: Maybe<DeleteGpOrganizationResult>
  /** Delete a MFA enrollment using the enrollment ID */
  deleteMFAEnrollment?: Maybe<DeleteMfaEnrollmentResult>
  /**
   * Deletes a user challenge for a patient.
   *
   * This mutation returns an `AuthorizationError` if the logged in user is not
   * authorized to accept challenges on behalf of the patient.
   */
  deleteUserChallenge?: Maybe<DeleteUserChallengeResult>
  /**
   * This mutation returns an `AuthorizationError` if the logged in user is not
   * authorized to accept challenges on behalf of the patient.
   */
  deleteUserGoal?: Maybe<DeleteUserGoalResult>
  /** Deletes user from IAM provider */
  gdprDeleteUser?: Maybe<GdprDeleteUserResult>
  /**
   * Hides a user challenge, updating the status to `HIDDEN` and updating the
   * corresponding `hidden` timestamp to the current time.
   *
   * This mutation returns an `AuthorizationError` if the logged in user is not
   * authorized to hide challenges on behalf of the user.
   */
  hideUserChallenge?: Maybe<HideUserChallengeResult>
  /**
   * Hides a challenge action (by updating the status to `HIDDEN` and setting the `hidden` timestamp to the current time).
   *
   * If all the challenge actions have been completed or hidden, the overall challenge status will be marked as `COMPLETED`,
   * and the corresponding completed timestamp will be updated to reflect the time at which this ocurred.
   *
   * This mutation returns an `AuthorizationError` if the logged in user is not
   * authorized to hide challenges on behalf of the user associated with challenge action.
   */
  hideUserChallengeAction?: Maybe<HideUserChallengeActionResult>
  /** Issues an ID Token for the patient containing the profile information */
  issuePatientIdToken?: Maybe<IdTokenResult>
  /** Links an IAM provider user id to a patient id */
  linkUserToPatient?: Maybe<UserResult>
  /**
   * Deletes existing user records from IAM provider and recreates a single
   * consolidated record by sending a password reset email
   */
  recreateUser?: Maybe<RecreateUserResult>
  /** Register a patient. */
  registerPatient: RegisterPatientResult
  /** Revoke all the active sessions of a User given a user id. */
  revokeAllUserSessions?: Maybe<RevokeAllUserSessionsResult>
  /** Revoke the session with the specified ID. */
  revokeUserSession?: Maybe<RevokeUserSessionResult>
  /**
   * Add new conversation event.
   *
   * Update conversation resource with new event resource.
   */
  sendEvent: SendEventResult
  /**
   * Send a new converstaion message.
   *
   * Update conversation resource with new message resource.
   */
  sendMessage: SendMessageResult
  /** Sends an email to the User with a link to reset their password */
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailResult>
  /**
   * Start a new conversation.
   *
   * Generate new conversation resource.
   */
  startConversation: StartConversationResult
  /**
   * Starts a user challenge, updating the status to `STARTED` and updating the
   * corresponding `started` timestamp to the current time.
   *
   * This mutation returns an `AuthorizationError` if the logged in user is not
   * authorized to hide challenges on behalf of the user.
   */
  startUserChallenge?: Maybe<StartUserChallengeResult>
  /**
   * Starts a challenge action (by updating the status to `STARTED` and setting the `started` timestamp to the current time).
   *
   * This mutation returns an `AuthorizationError` if the logged in user is not
   * authorized to complete challenges on behalf of the user associated with
   * challenge action.
   */
  startUserChallengeAction?: Maybe<StartUserChallengeActionResult>
  /**
   * Unlock a User account
   *
   * There is currently no `User.isLocked` field available, but it is possible for
   * a user who's entered their password too many times to be in this state, in
   * which case this mutation can be used to unlock the user's account.
   */
  unlockUser?: Maybe<UnlockUserResult>
  /** Update a patient's default pharmacy. */
  updateDefaultPatientPharmacy?: Maybe<UpdateDefaultPatientPharmacyResult>
  /**
   * Update a GP Organization.
   * The UpdateGPOrganizationInput accepts the ID as well as fax_number because
   * ContactPointConnection does not have ID of its own.
   */
  updateGpOrganization?: Maybe<UpdateGpOrganizationResult>
  /** @deprecated Supports outdated naming, consumers should make use of _inbox_updateInboxCommunicationIsRead instead */
  updateInboxCommunicationIsRead: UpdateInboxCommunicationIsReadResponse
  /** Update the general information associated with a patient. */
  updatePatient: UpdatePatientResult
  /** Update an address from the `addresses` list */
  updatePatientAddress: UpdatePatientAddressResult
  /** Update an email address from the `telecoms` list */
  updatePatientEmailAddress: UpdatePatientEmailAddressResult
  /** Update a phone number from the `telecoms` list */
  updatePatientPhoneNumber: UpdatePatientPhoneNumberResult
  /**
   * Update the primary (i.e. first) address within the `addresses` list.
   *
   * The `Patient.addresses` list currently has a maximum capacity of one. Should
   * this increase in the future then this mutation will be deprecated in favor of
   * replacement add/update/remove mutations, but will continue to allow the first
   * item in the list to be updated.
   */
  updatePatientPrimaryAddress: UpdatePatientPrimaryAddressResult
  /**
   * Update the primary (i.e. first) email contact-point within the `telecoms` list.
   *
   * The `Patient.telecoms` list currently has a maximum capacity of one. Should
   * this increase in the future then this mutation will be deprecated in favor of
   * replacement add/update/remove mutations, but will continue to allow the first
   * item in the list to be updated.
   */
  updatePatientPrimaryEmailAddress: UpdatePatientPrimaryEmailAddressResult
  /**
   * Update the primary (i.e. first) name within the `names` list.
   *
   * The `Patient.names` list currently has a maximum capacity of one. Should this
   * increase in the future then this mutation will be deprecated in favor of
   * replacement add/update/remove mutations, but will continue to allow the first
   * item in the list to be updated.
   */
  updatePatientPrimaryName: UpdatePatientPrimaryNameResult
  /**
   * Update the primary (i.e. first) phone contact-point within the `telecoms` list.
   *
   * The `Patient.telecoms` list currently has a maximum capacity of one. Should
   * this increase in the future then this mutation will be deprecated in favor of
   * replacement add/update/remove mutations, but will continue to allow the first
   * item in the list to be updated.
   */
  updatePatientPrimaryPhoneNumber: UpdatePatientPrimaryPhoneNumberResult
  /** Update details for a pharmacist */
  updatePharmacistPractitioner?: Maybe<UpdatePharmacistPractitionerResult>
  /** Update details for a pharmacy. */
  updatePharmacyHealthcareService?: Maybe<UpdatePharmacyHealthcareServiceResult>
  /** Update Supply Network */
  updateSupplyNetwork?: Maybe<UpdateSupplyNetworkResult>
  /** Update the email for a user */
  updateUserEmailAddress?: Maybe<UserResult>
}

/** Root mutation type. */
export type Mutation_Chat_CreateConversationMessageArgs = {
  conversationId: Scalars['ID']
  idempotencyKey: Scalars['String']
  input: CreateConversationMessageInput
}

/** Root mutation type. */
export type Mutation_Chat_CreateConversationMessageFeedbackArgs = {
  comment?: Maybe<Scalars['String']>
  conversationId: Scalars['ID']
  issue: Scalars['String']
  messageId: Scalars['ID']
}

/** Root mutation type. */
export type Mutation_Chat_CreateConversationMessageRatingArgs = {
  comment?: Maybe<Scalars['String']>
  conversationId: Scalars['ID']
  messageId: Scalars['ID']
  value: Scalars['Int']
}

/** Root mutation type. */
export type Mutation_Chat_InitiateConversationArgs = {
  input: ChatInitiateConversationInput
}

/** Root mutation type. */
export type Mutation_Chat_StartConversationArgs = {
  data?: Maybe<ConversationData>
  links?: Maybe<Array<ConversationLinkInput>>
  type?: Maybe<Scalars['String']>
}

/** Root mutation type. */
export type Mutation_Chat_UndoLastConversationStepArgs = {
  conversationId: Scalars['ID']
  idempotencyKey: Scalars['String']
}

/** Root mutation type. */
export type Mutation_Firstmile_RequestPatientAccountDeletionArgs = {
  input?: Maybe<FirstmileRequestPatientAccountDeletionInput>
}

/** Root mutation type. */
export type Mutation_Inbox_UpdateInboxCommunicationIsReadArgs = {
  id: Scalars['ID']
  isRead: Scalars['Boolean']
}

/** Root mutation type. */
export type Mutation_Myhealth_HealthStatusAddMetricDatapointArgs = {
  appName: Scalars['String']
  datapoint: MyhealthHealthStatusAddMetricDatapointDatapoint
}

/** Root mutation type. */
export type Mutation_Patientcare_NotifyEhrCarePlanAssignedArgs = {
  input: PatientCareNotifyEhrCarePlanAssignedInput
}

/** Root mutation type. */
export type Mutation_Registration_RegisterPatientAndCreateGuardianRelationshipArgs = {
  input: RegistrationRegisterPatientAndCreateGuardianRelationshipInput
}

/** Root mutation type. */
export type MutationAddPatientAddressArgs = {
  input: AddPatientAddressInput
}

/** Root mutation type. */
export type MutationAddPatientEmailAddressArgs = {
  input: AddPatientEmailAddressInput
}

/** Root mutation type. */
export type MutationAddPatientIdentifierArgs = {
  input: AddPatientIdentifierInput
}

/** Root mutation type. */
export type MutationAddPatientPhoneNumberArgs = {
  input: AddPatientPhoneNumberInput
}

/** Root mutation type. */
export type MutationAssignDataCollectionJourneyArgs = {
  input: AssignDataCollectionJourneyInput
}

/** Root mutation type. */
export type MutationBookAppointmentArgs = {
  input: BookAppointmentInput
}

/** Root mutation type. */
export type MutationCancelAppointmentArgs = {
  input: CancelAppointmentInput
}

/** Root mutation type. */
export type MutationCompleteAppointmentActionArgs = {
  input: CompleteAppointmentActionInput
}

/** Root mutation type. */
export type MutationCompleteDataCollectionJourneyStageArgs = {
  input: CompleteDataCollectionJourneyStageInput
}

/** Root mutation type. */
export type MutationCompleteUserChallengeActionArgs = {
  input: CompleteUserChallengeActionInput
}

/** Root mutation type. */
export type MutationCreateGpOrganizationArgs = {
  input: CreateGpOrganizationInput
}

/** Root mutation type. */
export type MutationCreatePatientArgs = {
  input: CreatePatientInput
}

/** Root mutation type. */
export type MutationCreatePatientUserChallengeArgs = {
  input: CreatePatientUserChallengeInput
}

/** Root mutation type. */
export type MutationCreatePharmacistPractitionerArgs = {
  input: CreatePharmacistPractitionerInput
}

/** Root mutation type. */
export type MutationCreateSmsmfaChallengeArgs = {
  input: CreateSmsmfaChallengeInput
}

/** Root mutation type. */
export type MutationCreateSmsmfaEnrollmentArgs = {
  input: CreateSmsmfaEnrollmentInput
}

/** Root mutation type. */
export type MutationCreateSupplyNetworkArgs = {
  input: CreateSupplyNetworkInput
}

/** Root mutation type. */
export type MutationDeleteGpOrganizationArgs = {
  input: DeleteGpOrganizationInput
}

/** Root mutation type. */
export type MutationDeleteMfaEnrollmentArgs = {
  id: Scalars['ID']
}

/** Root mutation type. */
export type MutationDeleteUserChallengeArgs = {
  input: DeleteUserChallengeInput
}

/** Root mutation type. */
export type MutationDeleteUserGoalArgs = {
  input: DeleteUserGoalInput
}

/** Root mutation type. */
export type MutationGdprDeleteUserArgs = {
  input: GdprDeletionInput
}

/** Root mutation type. */
export type MutationHideUserChallengeArgs = {
  input: HideUserChallengeInput
}

/** Root mutation type. */
export type MutationHideUserChallengeActionArgs = {
  input: HideUserChallengeActionInput
}

/** Root mutation type. */
export type MutationIssuePatientIdTokenArgs = {
  audience: Scalars['Url']
  patientId: Scalars['ID']
}

/** Root mutation type. */
export type MutationLinkUserToPatientArgs = {
  input: LinkUserToPatientInput
}

/** Root mutation type. */
export type MutationRecreateUserArgs = {
  input: RecreateUserInput
}

/** Root mutation type. */
export type MutationRegisterPatientArgs = {
  input: RegisterPatientInput
}

/** Root mutation type. */
export type MutationRevokeAllUserSessionsArgs = {
  user: Scalars['ID']
}

/** Root mutation type. */
export type MutationRevokeUserSessionArgs = {
  input: RevokeUserSessionInput
}

/** Root mutation type. */
export type MutationSendEventArgs = {
  input: SendEventInput
}

/** Root mutation type. */
export type MutationSendMessageArgs = {
  input: SendMessageInput
}

/** Root mutation type. */
export type MutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput
}

/** Root mutation type. */
export type MutationStartConversationArgs = {
  input: StartConversationInput
}

/** Root mutation type. */
export type MutationStartUserChallengeArgs = {
  input: StartUserChallengeInput
}

/** Root mutation type. */
export type MutationStartUserChallengeActionArgs = {
  input: StartUserChallengeActionInput
}

/** Root mutation type. */
export type MutationUnlockUserArgs = {
  input: UnlockUserInput
}

/** Root mutation type. */
export type MutationUpdateDefaultPatientPharmacyArgs = {
  input: UpdateDefaultPatientPharmacyInput
}

/** Root mutation type. */
export type MutationUpdateGpOrganizationArgs = {
  input: UpdateGpOrganizationInput
}

/** Root mutation type. */
export type MutationUpdateInboxCommunicationIsReadArgs = {
  id: Scalars['ID']
  isRead: Scalars['Boolean']
}

/** Root mutation type. */
export type MutationUpdatePatientArgs = {
  input: UpdatePatientInput
}

/** Root mutation type. */
export type MutationUpdatePatientAddressArgs = {
  input: UpdatePatientAddressInput
}

/** Root mutation type. */
export type MutationUpdatePatientEmailAddressArgs = {
  input: UpdatePatientEmailAddressInput
}

/** Root mutation type. */
export type MutationUpdatePatientPhoneNumberArgs = {
  input: UpdatePatientPhoneNumberInput
}

/** Root mutation type. */
export type MutationUpdatePatientPrimaryAddressArgs = {
  input: UpdatePatientPrimaryAddressInput
}

/** Root mutation type. */
export type MutationUpdatePatientPrimaryEmailAddressArgs = {
  input: UpdatePatientPrimaryEmailAddressInput
}

/** Root mutation type. */
export type MutationUpdatePatientPrimaryNameArgs = {
  input: UpdatePatientPrimaryNameInput
}

/** Root mutation type. */
export type MutationUpdatePatientPrimaryPhoneNumberArgs = {
  input: UpdatePatientPrimaryPhoneNumberInput
}

/** Root mutation type. */
export type MutationUpdatePharmacistPractitionerArgs = {
  input: UpdatePharmacistPractitionerInput
}

/** Root mutation type. */
export type MutationUpdatePharmacyHealthcareServiceArgs = {
  input: UpdatePharmacyHealthcareServiceInput
}

/** Root mutation type. */
export type MutationUpdateSupplyNetworkArgs = {
  input: UpdateSupplyNetworkInput
}

/** Root mutation type. */
export type MutationUpdateUserEmailAddressArgs = {
  input: UpdateUserEmailAddressInput
}

/** A set of content that explains a metric */
export type MyHealthAbout = {
  __typename?: 'MyHealthAbout'
  /** The body of the about content */
  description: Scalars['String']
  /** The title of the about content */
  title: Scalars['String']
}

/** A blood pressure summary datapoint */
export type MyHealthAggregate = {
  __typename?: 'MyHealthAggregate'
  /** The result of the aggregation summary */
  aggregationResult: MyHealthSummaryAggregation
  /** The end time for the aggregation */
  endTime: Scalars['String']
  /** The start time for the aggregation */
  startTime: Scalars['String']
}

/** A set of aggregates and datapoints for a specified grouping, aggregation and timeframe */
export type MyHealthAggregation = {
  __typename?: 'MyHealthAggregation'
  /** Aggregations in the newer aggregate format, it currently only provides aggregations for blood pressure values */
  aggregates: Array<MyHealthAggregate>
  /** Aggregations in the older datapoint format, it provides aggregations for the numeric values */
  datapoints: Array<MyHealthDatapoint>
}

/** The different groupings for an aggregation */
export enum MyHealthAggregationGrouping {
  /** Group by daily buckets */
  Daily = 'DAILY',
  /** Group by hourly buckets */
  Hourly = 'HOURLY',
  /** Group by monthly buckets */
  Monthly = 'MONTHLY',
  /** Group by quarterly buckets */
  Quarterly = 'QUARTERLY',
  /** Group by weekly buckets */
  SevenDaily = 'SEVEN_DAILY',
}

/** The different timeframes that should be displayed for a metric */
export enum MyHealthAggregationTimeframe {
  /** Display a day view */
  Daily = 'DAILY',
  /** Display a month view */
  Monthly = 'MONTHLY',
  /** Display a week view */
  Weekly = 'WEEKLY',
  /** Display a year view */
  Yearly = 'YEARLY',
}

/** The aggregation to perform for the metric */
export enum MyHealthAggregationType {
  /** Returns the average of all values for the metric in the given bucket */
  Average = 'AVERAGE',
  /** Returns the latest value for the metric in the given bucket */
  Latest = 'LATEST',
  /** Returns a sum of all values for the metric in the given bucket */
  Sum = 'SUM',
  /** Returns the min/max/average of all values for the metric in the given bucket */
  Summary = 'SUMMARY',
}

/** The different aggregation types for a metric */
export type MyHealthAvailableAggregation = {
  __typename?: 'MyHealthAvailableAggregation'
  /** The way the metric has been grouped */
  aggregationGrouping: MyHealthAggregationGrouping
  /** The timeframe of the aggregation */
  aggregationTimeframe: MyHealthAggregationTimeframe
  /** The type of aggregation */
  aggregationType: MyHealthAggregationType
}

/** A blood pressure value */
export type MyHealthBloodPressure = {
  __typename?: 'MyHealthBloodPressure'
  /** The bottom number, the amount of pressure in the arteries between beats */
  diastolic: Scalars['Float']
  /** The top number, the maximum pressure the heart exerts while beating */
  systolic: Scalars['Float']
}

/** A blood pressure datapoint value */
export type MyHealthBloodPressureValue = {
  __typename?: 'MyHealthBloodPressureValue'
  /** The bottom number, the amount of pressure in the arteries between beats */
  diastolic: Scalars['Int']
  /** The top number, the maximum pressure the heart exerts while beating */
  systolic: Scalars['Int']
}

/** A set of fields that describes a blood pressure value */
export type MyHealthBloodPressureValueInput = {
  diastolic: Scalars['Int']
  systolic: Scalars['Int']
}

/** The period to calculate the metrics' trend over */
export enum MyHealthComparisonPeriod {
  /** The trend comparing the current month to the month before it */
  MonthOnMonth = 'MONTH_ON_MONTH',
  /** The trend comparing the current week to the week before it */
  WeekOnWeek = 'WEEK_ON_WEEK',
}

/** A metric datapoint */
export type MyHealthDatapoint = {
  __typename?: 'MyHealthDatapoint'
  /** The end time for the data point. */
  endTime: Scalars['Date']
  /** The value of the datapoint */
  value: MyHealthValue
}

/** The date interval for a representative aggregate time span */
export type MyHealthDateInterval = {
  __typename?: 'MyHealthDateInterval'
  /** End date. */
  end: Scalars['Date']
  /** Start date. */
  start: Scalars['Date']
}

/** A collection of data describing attributes of a metric */
export type MyHealthDescriptor = {
  __typename?: 'MyHealthDescriptor'
  /** A recommendation of how the metric should be displayed when requesting aggregations */
  availableAggregations: Array<MyHealthAvailableAggregation>
  /** The localised unit of measurement for this metric */
  displayUnit?: Maybe<Scalars['String']>
  /** If the metric supports being manually entered */
  isManualEntry?: Maybe<Scalars['Boolean']>
  /**
   * The maximum value accepted for this metric
   * @deprecated Deprecated! Use validation.max
   */
  max?: Maybe<Scalars['Float']>
  /**
   * The minimum value accepted for this metric
   * @deprecated Deprecated! Use validation.min
   */
  min?: Maybe<Scalars['Float']>
  /** The name of the metric */
  shortName?: Maybe<Scalars['String']>
  /** The unit of measurement for this metric */
  unit?: Maybe<Scalars['String']>
  /** The number of decimal places to display for this metric */
  unitPrecision?: Maybe<Scalars['Int']>
  /** Validation rules for this metric */
  validation?: Maybe<MyHealthMetricValidation>
}

/** A set of content that describes a sync source and its connection status */
export type MyHealthDeviceSyncSource = {
  __typename?: 'MyHealthDeviceSyncSource'
  /** The logo or icon to display for the device sync source */
  imageUrl: Scalars['String']
  /** The current connection status of the device sync source */
  isConnected: Scalars['Boolean']
  /** The display name of the device sync source */
  title: Scalars['String']
  /** An identifier for the device sync source */
  type: Scalars['String']
  /** A URL which will either connect or disconnect a device to the current user's Babylon account */
  url: Scalars['String']
}

/** A set of content that describes the current completion status of the user's health check */
export type MyHealthHealthCheck = {
  __typename?: 'MyHealthHealthCheck'
  /** The localised description of how far a member is through their health check */
  description: Scalars['String']
  /** An image to display alongside the health check description */
  imageUrl: Scalars['String']
  /** The localised title of the health check */
  title: Scalars['String']
}

/** The data for an answer from a user's health check */
export type MyHealthHealthcheckAnswer = {
  __typename?: 'MyHealthHealthcheckAnswer'
  /** The localised text of the answer provided by the user */
  answer: Scalars['String']
  /** The date the answer was provided */
  date: Scalars['Date']
  /** The ID of the answer */
  id: Scalars['ID']
  /** The localised text of the question */
  question: Scalars['String']
}

/** A detailed explanation of a metrics' current risk level */
export type MyHealthHealthcheckExplanation = {
  __typename?: 'MyHealthHealthcheckExplanation'
  /** The localised text of the overview of the explanation */
  overview: Scalars['String']
  /** The localised text of the recommendation the user should take to improve this metric */
  recommendation: Scalars['String']
  /** The localised text of the summary of the explanation */
  summary: Scalars['String']
  /** The localised title of the explanation */
  title: Scalars['String']
}

/** Describes the overall status of a members health and provides content to encourage improvement. */
export type MyHealthHealthScore = {
  __typename?: 'MyHealthHealthScore'
  /** Action that results in the maximum score gain. Derived from components' actions. */
  biggestOpportunity?: Maybe<MyHealthHealthScoreComponentAction>
  /** Time of the score calculation */
  calculationTime: Scalars['Date']
  /** Components used in score calculation */
  components?: Maybe<MyHealthHealthScoreComponentConnection>
  /** The effectiveTime of its component that was most recently updated */
  effectiveTime: Scalars['Date']
  /** Expiration time of the score calculation */
  expirationTime: Scalars['Date']
  /** A positive integer value between 1 & 100 (both inclusive) */
  score: Scalars['NonNegativeInt']
  /** State of healthscore */
  validityState?: Maybe<MyHealthHealthScoreValidityState>
  /** Version of score */
  version: Scalars['String']
}

/** Describes the overall status of a members health and provides content to encourage improvement. */
export type MyHealthHealthScoreComponentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A set of content that explains healthscore */
export type MyHealthHealthScoreAbout = {
  __typename?: 'MyHealthHealthScoreAbout'
  /** The body of the about content */
  description: Scalars['String']
  /** The title of the about content */
  title: Scalars['String']
}

/** Describes a component that contributes to a HealthScore */
export type MyHealthHealthScoreComponent = {
  __typename?: 'MyHealthHealthScoreComponent'
  /** Recommended actions for this component */
  actions?: Maybe<MyHealthHealthScoreComponentActionConnection>
  /** Expiration time of this component's data */
  expirationTime?: Maybe<Scalars['Date']>
  /** The unique identifier for this component */
  id: Scalars['ID']
  /** Corresponding myHealthMetric */
  myHealthMetric?: Maybe<MyHealthMyHealthMetric>
  /** Recorded time or Observed time of this component's data */
  recordedTime?: Maybe<Scalars['Date']>
  /** State of this component */
  validityState?: Maybe<MyHealthHealthScoreValidityState>
}

/** Describes a component that contributes to a HealthScore */
export type MyHealthHealthScoreComponentActionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Describes an action for a member to improve their HealthScore */
export type MyHealthHealthScoreComponentAction = {
  __typename?: 'MyHealthHealthScoreComponentAction'
  /** Additional info for this action. e.g. If you have a health condition, speak to your doctor first. */
  caption?: Maybe<Scalars['String']>
  /** Short desc. of the action */
  headline: Scalars['String']
  /** Icon for this action. ID or URL? */
  icon: Scalars['String']
  /** myHealthMetric linked with this action */
  myHealthMetric?: Maybe<MyHealthMyHealthMetric>
  /** Priority of an action, 1 to 100, 1 being highest */
  priority: Scalars['NonNegativeInt']
  /** Why and how this action will improve your score */
  rationale: Scalars['String']
  /** Title to use for this action */
  spotlight?: Maybe<Scalars['String']>
}

/** Connection to MyHealthHealthScoreComponentAction */
export type MyHealthHealthScoreComponentActionConnection = Connection & {
  __typename?: 'MyHealthHealthScoreComponentActionConnection'
  /** Edges in the current connection page. */
  edges: Array<MyHealthHealthScoreComponentActionEdge>
  /** List of action nodes in the current connection page. */
  nodes: Array<Maybe<MyHealthHealthScoreComponentAction>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Edge for MyHealthHealthScoreComponentAction connection */
export type MyHealthHealthScoreComponentActionEdge = Edge & {
  __typename?: 'MyHealthHealthScoreComponentActionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the component in this edge. */
  node?: Maybe<MyHealthHealthScoreComponentAction>
}

/** Connection to MyHealthHealthScoreComponent */
export type MyHealthHealthScoreComponentConnection = Connection & {
  __typename?: 'MyHealthHealthScoreComponentConnection'
  /** Edges in the current connection page. */
  edges: Array<MyHealthHealthScoreComponentEdge>
  /** List of component nodes in the current connection page. */
  nodes: Array<Maybe<MyHealthHealthScoreComponent>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Edge for MyHealthHealthScoreComponent connection */
export type MyHealthHealthScoreComponentEdge = Edge & {
  __typename?: 'MyHealthHealthScoreComponentEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the component in this edge. */
  node?: Maybe<MyHealthHealthScoreComponent>
}

/** Connection to MyHealthHealthScore */
export type MyHealthHealthScoreConnection = Connection & {
  __typename?: 'MyHealthHealthScoreConnection'
  /** Edges in the current connection page. */
  edges: Array<MyHealthHealthScoreEdge>
  /** List of MyHealthHealthScore nodes in the current connection page. */
  nodes: Array<Maybe<MyHealthHealthScore>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Edge for MyHealthHealthScore connection */
export type MyHealthHealthScoreEdge = Edge & {
  __typename?: 'MyHealthHealthScoreEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the component in this edge. */
  node?: Maybe<MyHealthHealthScore>
}

/** An Error type whereby a user has no HealthScore and is asked to add data for its components */
export type MyHealthHealthScoreNotFoundError = Error & {
  __typename?: 'MyHealthHealthScoreNotFoundError'
  /**
   * Message to assist a developer in understanding and fixing the error.
   * Not intended to be shown directly to an end-user.
   */
  errorMessage: Scalars['String']
}

/** A set of content items for HealthScore */
export type MyHealthHealthScoreOverview = {
  __typename?: 'MyHealthHealthScoreOverview'
  /** The heading of healthscore about. e.g. More from health score */
  aboutHeading: Scalars['String']
  /** The text telling user to complete their healthscore */
  completeScore: Scalars['String']
  /** The body of the complete title */
  completeScoreHow: Scalars['String']
  /** The heading of the healthscore components. e.g. What affects your healthscore? */
  componentHeading: Scalars['String']
  /** The content for when score is updating. e.g. Your score is updating */
  scoreUpdating: Scalars['String']
  /** The body of the warning content. e.g. If you have a medical condition */
  warning: Scalars['String']
}

/** Top level healthscore type */
export type MyHealthHealthScoreT = {
  __typename?: 'MyHealthHealthScoreT'
  /** Content explaining what health score is and how it is calculated */
  about?: Maybe<MyHealthHealthScoreAbout>
  /** Default components, to be used for users with no data */
  defaultComponents?: Maybe<MyHealthHealthScoreComponentConnection>
  /** latest health score */
  latest?: Maybe<MyHealthHealthScore>
  /** Content explaining how to complete healthscore */
  overview?: Maybe<MyHealthHealthScoreOverview>
  /** history of health scores */
  scores?: Maybe<MyHealthHealthScoreConnection>
}

/** Top level healthscore type */
export type MyHealthHealthScoreTDefaultComponentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Top level healthscore type */
export type MyHealthHealthScoreTScoresArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The different states a score/component can take */
export enum MyHealthHealthScoreValidityState {
  /** The score/component is being calculated */
  InvalidLoading = 'INVALID_LOADING',
  /** The score/component is missing from the members health record */
  InvalidMissing = 'INVALID_MISSING',
  /** The score/component is present on the members health record but is outdated */
  InvalidOutdated = 'INVALID_OUTDATED',
  /** The score/component is present on the members health record but is unusable for other reasons */
  InvalidUnusableOther = 'INVALID_UNUSABLE_OTHER',
  /** The score/component is valid */
  Valid = 'VALID',
}

/** The top level field for all My Health data */
export type MyHealthHealthStatus = {
  __typename?: 'MyHealthHealthStatus'
  /** A set of content that describes devices that the user can connect with to track their health */
  deviceSyncSources: Array<MyHealthDeviceSyncSource>
  /** A set of content that describes the current completion status of the user's health check */
  healthCheck?: Maybe<MyHealthHealthCheck>
  /** Describes the overall status of a member's health and provides suggestions to encourage improvement. */
  healthScore?: Maybe<MyHealthHealthStatusHealthScoreResult>
  /** Describes groupings of metrics by condition */
  myHealthMetricGroups: Array<MyHealthMetricGroup>
  /** Describes information about metrics that the user can track */
  myHealthMetrics: Array<MyHealthMyHealthMetric>
}

/** The top level field for all My Health data */
export type MyHealthHealthStatusDeviceSyncSourcesArgs = {
  acceptLanguage?: Maybe<Scalars['Language']>
  appName?: Maybe<Scalars['String']>
  isConnected?: Maybe<Scalars['Boolean']>
  redirectUrl?: Maybe<Scalars['String']>
}

/** The top level field for all My Health data */
export type MyHealthHealthStatusHealthCheckArgs = {
  acceptLanguage?: Maybe<Scalars['Language']>
  appName: Scalars['String']
}

/** The top level field for all My Health data */
export type MyHealthHealthStatusHealthScoreArgs = {
  acceptLanguage: Scalars['Language']
  appName: Scalars['String']
  appVersion: Scalars['ID']
  patientUuid?: Maybe<Scalars['ID']>
  timeZoneOffset: Scalars['TimezoneId']
}

/** The top level field for all My Health data */
export type MyHealthHealthStatusMyHealthMetricGroupsArgs = {
  acceptLanguage: Scalars['Language']
}

/** The top level field for all My Health data */
export type MyHealthHealthStatusMyHealthMetricsArgs = {
  acceptLanguage: Scalars['Language']
  appName: Scalars['String']
  appVersion: Scalars['ID']
  ids: Array<Scalars['ID']>
  patientUuid?: Maybe<Scalars['ID']>
  timeZoneOffset: Scalars['TimezoneId']
}

/**
 * HealthStatusAddMetricDatapointDatapoint allows for single or multiple values to be attached to a metric
 * e.g.:
 * - Blood pressure requires systolic, diastolic and time values;
 * - BMI requires height and weight
 */
export type MyhealthHealthStatusAddMetricDatapointDatapoint = {
  /** dateTime is ISO DateTime */
  dateTime: Scalars['Date']
  eventId: Scalars['String']
  metricId: Scalars['ID']
  /**
   * - required when sending data to patient metrics
   * - not required when sending data to
   */
  unit?: Maybe<Scalars['String']>
  value: MyHealthHealthStatusMetricValueInput
}

export type MyHealthHealthStatusHealthScoreResult =
  | MyHealthHealthScoreNotFoundError
  | MyHealthHealthScoreT

/** The metric value to add. One and only one field is required. */
export type MyHealthHealthStatusMetricValueInput = {
  /**
   * bloodPressureValue is used for blood pressure values
   * blood pressure is a composite type of metric
   */
  bloodPressureValue?: Maybe<MyHealthBloodPressureValueInput>
  /** numericValue is used for metrics with single  value */
  numericValue?: Maybe<Scalars['Float']>
}

/** A link with a text value to display and url to open */
export type MyHealthLink = {
  __typename?: 'MyHealthLink'
  /** The text to display */
  text: Scalars['String']
  /** The url to open when the link is clicked */
  url: Scalars['String']
}

/** A set of content that provides help for a metrics' manual entry */
export type MyHealthManualEntryHelp = {
  __typename?: 'MyHealthManualEntryHelp'
  /** The body of the manual entry help */
  body: Scalars['String']
  /** The title of the manual entry help */
  title: Scalars['String']
}

/** A collection of data that represents a group of metrics */
export type MyHealthMetricGroup = {
  __typename?: 'MyHealthMetricGroup'
  /** e.g. highBloodPressure */
  id: Scalars['ID']
  /** e.g. High Blood Pressure */
  name: Scalars['String']
}

/** A range used to validate metric manual entry values */
export type MyHealthMetricValidation = {
  __typename?: 'MyHealthMetricValidation'
  /** Maximum range value. */
  max?: Maybe<MyHealthValue>
  /** Minimum range value. */
  min?: Maybe<MyHealthValue>
}

/** The moment for a representative aggregate time span */
export type MyHealthMoment = {
  __typename?: 'MyHealthMoment'
  /** The date of the moment. */
  date: Scalars['Date']
}

/** A collection of data relating to a metric */
export type MyHealthMyHealthMetric = {
  __typename?: 'MyHealthMyHealthMetric'
  /** Content explaining what the metric is and how it is calculated */
  about?: Maybe<MyHealthAbout>
  /** Returns aggregations for this metric between the given dates */
  aggregation?: Maybe<MyHealthAggregation>
  /** The answers the member gave to the questions for this metric, if calculated by a questionnaire */
  answers: Array<MyHealthHealthcheckAnswer>
  /** A collection of data describing attributes of a metric */
  descriptor?: Maybe<MyHealthDescriptor>
  /** Content explaining that early detection is enabled for this metric */
  earlyDetectionNotice?: Maybe<MyHealthNotice>
  /** The unique identifier for this metric */
  id: Scalars['ID']
  /** The latest values of this metric */
  lastDatapoints: Array<MyHealthDatapoint>
  /** The latest modification time of this metric; using answers or lastDatapoints */
  latestUpdatedTime?: Maybe<Scalars['Date']>
  /** Content explaining to the user how to manually add a datapoint for this metric */
  manualEntryHelp?: Maybe<MyHealthManualEntryHelp>
  /** The localised name of this metric */
  name: Scalars['String']
  /** Content explaining additional notices for this metric */
  notice?: Maybe<MyHealthNotice>
  /** The personalised RAG status of this metric based on it's value */
  rag?: Maybe<MyHealthRag>
  /** Returns a single aggregation for this metric between the given dates */
  representativeAggregation?: Maybe<MyHealthRepresentativeAggregate>
  /** Content explaining the RAG status of this metric */
  resultExplanation?: Maybe<MyHealthHealthcheckExplanation>
  /** Additional metrics related to this metric */
  supportingMetrics: MyHealthSupportingMetricConnection
  /** The trend of this metric of the current week compared to the previous, along with additional context */
  trend?: Maybe<MyHealthTrendDetail>
  /**
   * The trend of this metric of the current week compared to the previous
   * @deprecated Deprecated! Use trend.
   */
  trending?: Maybe<MyHealthTrend>
}

/** A collection of data relating to a metric */
export type MyHealthMyHealthMetricAggregationArgs = {
  aggregationGrouping: MyHealthAggregationGrouping
  aggregationType: MyHealthAggregationType
  end: Scalars['Date']
  limit?: Maybe<Scalars['Int']>
  start?: Maybe<Scalars['Date']>
}

/** A collection of data relating to a metric */
export type MyHealthMyHealthMetricLastDatapointsArgs = {
  count: Scalars['Int']
}

/** A collection of data relating to a metric */
export type MyHealthMyHealthMetricRepresentativeAggregationArgs = {
  aggregationGrouping?: Maybe<MyHealthAggregationGrouping>
  aggregationType: MyHealthAggregationType
  end: Scalars['Date']
  start: Scalars['Date']
}

/** A collection of data relating to a metric */
export type MyHealthMyHealthMetricTrendArgs = {
  period: MyHealthComparisonPeriod
  relativeDate?: Maybe<Scalars['Date']>
}

/** A set of content for an early detection notice */
export type MyHealthNotice = {
  __typename?: 'MyHealthNotice'
  /** The body of the notice */
  body: Scalars['String']
  /** The title of the notice */
  header: Scalars['String']
  /** The link to display */
  link?: Maybe<MyHealthLink>
}

/** A numeric datapoint value */
export type MyHealthNumericValue = {
  __typename?: 'MyHealthNumericValue'
  /** The value datapoint. */
  value: Scalars['Float']
}

/** A numeric datapoint value with a label */
export type MyHealthNumericWithLabelValue = {
  __typename?: 'MyHealthNumericWithLabelValue'
  /** The localised label of the value */
  label: Scalars['String']
  /** The numeric value */
  value: Scalars['Float']
}

/** The different risk levels for a metric */
export enum MyHealthRag {
  /** The metric is in the amber zone - there is a slight risk */
  Amber = 'AMBER',
  /** The metric is in the green zone - there is no cause for concern */
  Green = 'GREEN',
  /** The metric is in the red zone - there is a higher risk */
  Red = 'RED',
}

/** The aggregate details to use for displaying graph highlights */
export type MyHealthRepresentativeAggregate = {
  __typename?: 'MyHealthRepresentativeAggregate'
  /** The time span the aggregation covers */
  timeSpan: MyHealthTimeSpan
  /** The value of the aggregation */
  value: MyHealthValue
}

/** An aggregation type which returns the min/max/average of a metric over a time period */
export type MyHealthSummaryAggregation = {
  __typename?: 'MyHealthSummaryAggregation'
  /** The average value over the aggregation period */
  average: MyHealthBloodPressure
  /** The maximum value over the aggregation period */
  max: MyHealthBloodPressure
  /** The minimum value over the aggregation period */
  min: MyHealthBloodPressure
}

/**
 * A My Health metric which appears alongside, or 'in support of', another My Health metric.
 *
 * Primarily this includes content appropriate for display
 *
 * e.g.
 * - Baseline Peak Flow supports Peak Flow.
 * - Height & Weight support Body Mass Index.
 */
export type MyHealthSupportingMetric = {
  __typename?: 'MyHealthSupportingMetric'
  /** The My Health metric to which this content is relevant. */
  metric: MyHealthMyHealthMetric
  /** The user's status of this supporting metric, and content appropriate for this status. */
  status: MyHealthSupportingMetricStatusResult
}

/** Connection to MyHealthSupportingMetric */
export type MyHealthSupportingMetricConnection = Connection & {
  __typename?: 'MyHealthSupportingMetricConnection'
  /** Edges in the current connection page. */
  edges: Array<MyHealthSupportingMetricEdge>
  /** List of supporting metric nodes in the current connection page. */
  nodes: Array<Maybe<MyHealthSupportingMetric>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** A status whereby a user has no existing data for the supporting metric and is asked to add data */
export type MyHealthSupportingMetricDatapointRequired = {
  __typename?: 'MyHealthSupportingMetricDatapointRequired'
  /** The call to action to display */
  callToAction: Scalars['String']
  /** The details of the supporting metric */
  detail: Scalars['String']
  /** The title of the supporting metric */
  title: Scalars['String']
}

/** Edge for MyHealthSupportingMetric connection */
export type MyHealthSupportingMetricEdge = Edge & {
  __typename?: 'MyHealthSupportingMetricEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the supporting metric in this edge. */
  node?: Maybe<MyHealthSupportingMetric>
}

/** A status whereby a user has existing data for the supporting metric and is shown their latest datapoint */
export type MyHealthSupportingMetricLatestDatapoint = {
  __typename?: 'MyHealthSupportingMetricLatestDatapoint'
  /** The call to action to display */
  callToAction: Scalars['String']
  /** The latest value of the supporting metric */
  latestDatapoint: MyHealthDatapoint
  /** The title of the supporting metric */
  title: Scalars['String']
}

export type MyHealthSupportingMetricStatusResult =
  | MyHealthSupportingMetricDatapointRequired
  | MyHealthSupportingMetricLatestDatapoint

/** The different time span types for a representative aggregate */
export type MyHealthTimeSpan = MyHealthDateInterval | MyHealthMoment

/** The different trends a metric can have */
export enum MyHealthTrend {
  /** The metric trend has decreased over the time period */
  Decreased = 'DECREASED',
  /** The metric trend has increased over the time period */
  Increased = 'INCREASED',
  /** There has been no change in the metric */
  NoChange = 'NO_CHANGE',
  /** There isn't enough data to determine a trend */
  NoTrend = 'NO_TREND',
}

/** The change of a metric over a time period */
export type MyHealthTrendChangeValue = {
  __typename?: 'MyHealthTrendChangeValue'
  /** The absolute change over the comparison period */
  absolute: Scalars['Float']
}

/** A set of content that describes a metrics' current trend */
export type MyHealthTrendDetail = {
  __typename?: 'MyHealthTrendDetail'
  /** The change of the metric over the time period */
  change?: Maybe<MyHealthTrendChangeValue>
  /** From what date the trend is calculated to */
  end: Scalars['Date']
  /** From what date the trend is calculated from */
  start: Scalars['Date']
  /** The trend of the metric over the time period */
  status: MyHealthTrend
  /** The localised unit of measurement for the change */
  unit?: Maybe<Scalars['String']>
}

/** The different types of values a metric datapoint can have */
export type MyHealthValue =
  | MyHealthBloodPressureValue
  | MyHealthNumericValue
  | MyHealthNumericWithLabelValue

/** Identifies the purpose of this name. */
export enum NameUse {
  /**
   * Anonymous assigned name, alias, or pseudonym
   * (used to protect a person's identity for privacy reasons)
   */
  Anonymous = 'ANONYMOUS',
  /**
   * A name used prior to changing name because of marriage.
   * This name use is for use by applications that collect and store names that were used prior to a marriage.
   * Marriage naming customs vary greatly around the world, and are constantly changing.
   * This term is not gender specific. The use of this term does not
   * imply any particular history for a person's name.
   */
  Maiden = 'MAIDEN',
  /**
   * A name that is used to address the person in an informal manner,
   * but is not part of their formal or usual name.
   */
  Nickname = 'NICKNAME',
  /**
   * The formal name as registered in an official (government) registry,
   * but which name might not be commonly used. May be called 'legal name'
   */
  Official = 'OFFICIAL',
  /** This name is no longer in use (or was never correct, but retained for records) */
  Old = 'OLD',
  /**
   * A temporary name. Name.period can provide more detailed information.
   * This may also be used for temporary names assigned at birth or in emergency situations
   */
  Temp = 'TEMP',
  /** Known as/conventional/the one you normally use */
  Usual = 'USUAL',
}

/**  A human-readable summary of the resource conveying the essential clinical and business information for the resource. */
export type Narrative = {
  __typename?: 'Narrative'
  /**  The actual narrative content, a stripped down version of XHTML. */
  div?: Maybe<Scalars['String']>
  /**
   *  The status of the narrative - whether it's entirely generated (from just the
   * defined data or the extensions too), or whether a human authored it and it may
   * contain additional data.
   */
  status?: Maybe<Scalars['FhirCode']>
}

/** Status of a NHS patient record check (using the UK personal demographics service). */
export enum NhsEnrollmentRequestStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Deregistered = 'DEREGISTERED',
  HaAccepted = 'HA_ACCEPTED',
  HaQuery = 'HA_QUERY',
  New = 'NEW',
  Pending = 'PENDING',
  ReApplied = 'RE_APPLIED',
  Rejected = 'REJECTED',
  WaitingInfo = 'WAITING_INFO',
  Welcomed = 'WELCOMED',
}

/** Type representing an empty/non-existant value in an [Option type](https://en.wikipedia.org/wiki/Option_type). */
export type None = {
  __typename?: 'None'
  /**
   * A type with no fields is syntactically invalid, so an unused field is returned instead.
   * @deprecated Not a real field.
   */
  _: Scalars['Void']
}

/**
 * A resource that is not currently accessible through GraphQL.
 * You should NOT use this type directly, but instead use ...on Resource to access it.
 * This way once it is federated any existing queries will continue to work.
 */
export type NonFederatedResource = Resource & {
  __typename?: 'NonFederatedResource'
  /**  IMPORTANT: This will be the resourceUri currently until the BabylonID spec is implemented! */
  id: Scalars['ID']
}

/** Message object for a notice */
export type NoticeMessageValue = {
  __typename?: 'NoticeMessageValue'
  /** id of the notice */
  id: Scalars['ID']
  /** content of the notice */
  text: Scalars['String']
}

/** Input message value for a number */
export type NumberInputMessage = {
  /** numberic answer from user. It's a string because it can be a decimal number */
  value: Scalars['String']
}

/** Object for a number range and accuracy */
export type NumberMessageInput = {
  __typename?: 'NumberMessageInput'
  /** number of decimal places */
  accuracy: Scalars['Int']
  /** The maximum value of the number. It's a string because it can be a decimal number */
  max?: Maybe<Scalars['String']>
  /** minimum value allowed. It's a string because it can be a decimal number */
  min?: Maybe<Scalars['String']>
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type Observation = HealthgraphResource &
  Resource & {
    __typename?: 'Observation'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** A code that classifies the general type of observation being made. */
    categories?: Maybe<CodeableConceptConnection>
    /** Describes what was observed. Sometimes this is called the observation "name". */
    code?: Maybe<ObservationCodeResult>
    /**
     * Some observations have multiple component observations.  These component
     * observations are expressed as separate code value pairs that share the same
     * attributes.  Examples include systolic and diastolic component observations
     * for blood pressure measurement and multiple component observations for
     * genetics observations.
     */
    components?: Maybe<ObservationComponentConnection>
    /**
     * The target resource that represents a measurement from which this observation
     * value is derived. For example, a calculated anion gap or a fetal measurement
     * based on an ultrasound image.
     */
    derivedFrom?: Maybe<ObservationDerivedFromResultConnection>
    /** The device used to generate the observation data. */
    device?: Maybe<ObservationDeviceResult>
    /**  DiagnosticReports that this Observation is the result of  */
    diagnosticReports?: Maybe<DiagnosticReportConnection>
    /**
     * The time or time-period the observed value is asserted as being true. For
     * biological subjects - e.g. human patients - this is usually called the
     * "physiologically relevant time". This is usually either the time of the
     * procedure or of specimen collection, but very often the source of the
     * date/time is not known, only the date/time itself.
     */
    effectiveDateTime?: Maybe<Scalars['FhirDateTime']>
    /** The healthcare event  (e.g. a patient and healthcare provider interaction) during which this observation is made. */
    encounter?: Maybe<ObservationEncounterResult>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** A unique identifier assigned to this observation. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** A categorical assessment of an observation value.  For example, high, low, normal. */
    interpretations?: Maybe<CodeableConceptConnection>
    /**
     * The date and time this version of the observation was made available to
     * providers, typically after the results have been reviewed and verified.
     */
    issued?: Maybe<Scalars['FhirInstant']>
    /** Namespace for external id */
    namespace?: Maybe<Scalars['String']>
    /** Comments about the observation or the results. */
    notes?: Maybe<AnnotationConnection>
    /** Who was responsible for asserting the observed value as "true". */
    performers?: Maybe<ObservationPerformerResultConnection>
    /** ID of the question or other provenance, for example '2020-08-11-prudential-mys-v2/activity_onboarding/activity/intense-exercise-minutes-weekly/question/questionnaire.uk_intense_exercise_minutes_per_week' */
    questionId?: Maybe<Scalars['String']>
    /** The RAG status is the indicator for this metric (e.g., blood pressure). */
    ragStatusBaseMetricConcept?: Maybe<ObservationRagStatusBaseMetricConceptResult>
    /** The model version used to calculate a RAG status */
    ragStatusModelVersion?: Maybe<Scalars['String']>
    /**
     * The references to some data points (not necessarily all) from the member’s
     * Health Graph profile that were used to produce this RAG status value.
     */
    ragStatusReferences?: Maybe<ObservationRagStatusReferencesResultConnection>
    /**
     * Guidance on how to interpret the value by comparison to a normal or
     * recommended range.  Multiple reference ranges are interpreted as an "OR".   In
     * other words, to represent two distinct target populations, two
     * `referenceRange` elements would be used.
     */
    referenceRanges?: Maybe<ObservationReferenceRangeConnection>
    /** Source application name for the body measurement */
    sourceApplicationName?: Maybe<Scalars['String']>
    /** The status of the result value. */
    status?: Maybe<Scalars['FhirCode']>
    /**
     * The patient, or group of patients, location, or device this observation is
     * about and into whose record the observation is placed. If the actual focus of
     * the observation is different from the subject (or a sample of, part, or region
     * of the subject), the `focus` element or the `code` itself specifies the actual
     * focus of the observation.
     */
    subject?: Maybe<ObservationSubjectResult>
    /**  Information about how trustworthy the resource is; how likely it is to be accurate  */
    trust?: Maybe<ObservationTrustResult>
    /** The information determined as a result of making the observation, if the information has a simple value. */
    valueCodeableConcept?: Maybe<ObservationValueCodeableConceptResult>
    /** The information determined as a result of making the observation, if the information has a simple value. */
    valueQuantity?: Maybe<ObservationValueQuantityResult>
    /** The information determined as a result of making the observation, if the information has a simple value. */
    valueRange?: Maybe<ObservationValueRangeResult>
    /** The information determined as a result of making the observation, if the information has a simple value. */
    valueString?: Maybe<Scalars['String']>
  }

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationComponentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationDerivedFromArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationDiagnosticReportsArgs = {
  _filter?: Maybe<DiagnosticReportFilter>
  _sort?: Maybe<Array<Maybe<DiagnosticReportSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationInterpretationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationNotesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationPerformersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationRagStatusReferencesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Measurements and simple assertions made about a patient, device or other subject.  */
export type ObservationReferenceRangesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Describes what was observed. Sometimes this is called the observation "name". */
export type ObservationCodeResult = CodeableConcept | None

/**
 *  Some observations have multiple component observations.  These component
 * observations are expressed as separate code value pairs that share the same
 * attributes.  Examples include systolic and diastolic component observations for
 * blood pressure measurement and multiple component observations for genetics observations.
 */
export type ObservationComponent = {
  __typename?: 'ObservationComponent'
  /** Describes what was observed. Sometimes this is called the observation "code". */
  code?: Maybe<ObservationComponentCodeResult>
  /** The information determined as a result of making the observation, if the information has a simple value. */
  valueDateTime?: Maybe<Scalars['FhirDateTime']>
  /** The information determined as a result of making the observation, if the information has a simple value. */
  valueQuantity?: Maybe<ObservationComponentValueQuantityResult>
  /** The information determined as a result of making the observation, if the information has a simple value. */
  valueString?: Maybe<Scalars['String']>
}

/** Describes what was observed. Sometimes this is called the observation "code". */
export type ObservationComponentCodeResult = CodeableConcept | None

/**
 * A page/slice of a list of ObservationComponent nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ObservationComponentConnection = Connection & {
  __typename?: 'ObservationComponentConnection'
  /** Edges in the current connection page. */
  edges: Array<ObservationComponentEdge>
  /** List of ObservationComponent object nodes in the current connection page. */
  nodes: Array<Maybe<ObservationComponent>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ObservationComponent connection page. */
export type ObservationComponentEdge = Edge & {
  __typename?: 'ObservationComponentEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ObservationComponent object in this edge. */
  node?: Maybe<ObservationComponent>
}

/** The information determined as a result of making the observation, if the information has a simple value. */
export type ObservationComponentValueQuantityResult = None | Quantity

/**
 * A page/slice of a list of Observation nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ObservationConnection = Connection & {
  __typename?: 'ObservationConnection'
  /** Edges in the current connection page. */
  edges: Array<ObservationEdge>
  /** List of Observation object nodes in the current connection page. */
  nodes: Array<Maybe<Observation>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * The target resource that represents a measurement from which this observation
 * value is derived. For example, a calculated anion gap or a fetal measurement
 * based on an ultrasound image.
 */
export type ObservationDerivedFromResult =
  | ExternalResource
  | NonFederatedResource
  | Observation

/**
 * A page/slice of a list of ObservationDerivedFromResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ObservationDerivedFromResultConnection = Connection & {
  __typename?: 'ObservationDerivedFromResultConnection'
  /** Edges in the current connection page. */
  edges: Array<ObservationDerivedFromResultEdge>
  /** List of ObservationDerivedFromResult object nodes in the current connection page. */
  nodes: Array<Maybe<ObservationDerivedFromResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ObservationDerivedFromResult connection page. */
export type ObservationDerivedFromResultEdge = Edge & {
  __typename?: 'ObservationDerivedFromResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ObservationDerivedFromResult object in this edge. */
  node?: Maybe<ObservationDerivedFromResult>
}

/** The device used to generate the observation data. */
export type ObservationDeviceResult =
  | Device
  | ExternalResource
  | None
  | NonFederatedResource

/** An edge in a Observation connection page. */
export type ObservationEdge = Edge & {
  __typename?: 'ObservationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Observation object in this edge. */
  node?: Maybe<Observation>
}

/** The healthcare event  (e.g. a patient and healthcare provider interaction) during which this observation is made. */
export type ObservationEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  Specifies a filter to apply to a list of Observation  */
export type ObservationFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<ObservationFilter>>
  /**
   * Filters on Observation.category
   * The classification of the type of observation
   */
  category?: Maybe<CodeFilter>
  /**
   * Filters on Observation.code
   * The code of the observation type
   */
  code?: Maybe<CodeFilter>
  /**
   * Filters on Observation.code
   * The code of the observation type or component type
   */
  comboCode?: Maybe<CodeFilter>
  /**
   * Filters on (Observation.value as CodeableConcept)
   * The value or component value of the observation, if the value is a CodeableConcept
   */
  comboValueConcept?: Maybe<CodeFilter>
  /**
   * Filters on Observation.component.code
   * The component code of the observation type
   */
  componentCode?: Maybe<CodeFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Observation.effective
   * Obtained date/time. If the obtained element is a period, a date that falls in the period
   */
  date?: Maybe<DateFilter>
  /**
   * Filters on Observation.derivedFrom
   * Related measurements the observation is made from
   */
  derivedFromUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Observation.device
   * The Device that generated the observation data.
   */
  deviceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Observation.encounter
   * Encounter related to the observation
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Observation.identifier
   * The unique id for a particular observation
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<ObservationFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<ObservationFilter>>
  /**
   * Filters on Observation.subject.where(resolve() is Patient)
   * The subject that the observation is about (if patient)
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Observation.performer
   * Who performed the observation
   */
  performerUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Observation.status
   * The status of the observation
   */
  status?: Maybe<ObservationStatusFilter>
  /**
   * Filters on Observation.subject
   * The subject that the observation is about
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**  Filter by trust values, lets you limit to results that have a certain likelihood of being true  */
  trust?: Maybe<TrustFilter>
  /**
   * Filters on (Observation.value as CodeableConcept)
   * The value of the observation, if the value is a CodeableConcept
   */
  valueConcept?: Maybe<CodeFilter>
  /**
   * Filters on (Observation.value as string)
   * The value of the observation, if the value is a string, and also searches in CodeableConcept.text
   */
  valueString?: Maybe<StringFilter>
}

/** Who was responsible for asserting the observed value as "true". */
export type ObservationPerformerResult =
  | ExternalResource
  | NonFederatedResource
  | Patient
  | Practitioner

/**
 * A page/slice of a list of ObservationPerformerResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ObservationPerformerResultConnection = Connection & {
  __typename?: 'ObservationPerformerResultConnection'
  /** Edges in the current connection page. */
  edges: Array<ObservationPerformerResultEdge>
  /** List of ObservationPerformerResult object nodes in the current connection page. */
  nodes: Array<Maybe<ObservationPerformerResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ObservationPerformerResult connection page. */
export type ObservationPerformerResultEdge = Edge & {
  __typename?: 'ObservationPerformerResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ObservationPerformerResult object in this edge. */
  node?: Maybe<ObservationPerformerResult>
}

/** The RAG status is the indicator for this metric (e.g., blood pressure). */
export type ObservationRagStatusBaseMetricConceptResult = CodeableConcept | None

/**
 * The references to some data points (not necessarily all) from the member’s
 * Health Graph profile that were used to produce this RAG status value.
 */
export type ObservationRagStatusReferencesResult =
  | Condition
  | ExternalResource
  | NonFederatedResource
  | Observation

/**
 * A page/slice of a list of ObservationRagStatusReferencesResult nodes, allowing
 * for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ObservationRagStatusReferencesResultConnection = Connection & {
  __typename?: 'ObservationRagStatusReferencesResultConnection'
  /** Edges in the current connection page. */
  edges: Array<ObservationRagStatusReferencesResultEdge>
  /** List of ObservationRagStatusReferencesResult object nodes in the current connection page. */
  nodes: Array<Maybe<ObservationRagStatusReferencesResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ObservationRagStatusReferencesResult connection page. */
export type ObservationRagStatusReferencesResultEdge = Edge & {
  __typename?: 'ObservationRagStatusReferencesResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ObservationRagStatusReferencesResult object in this edge. */
  node?: Maybe<ObservationRagStatusReferencesResult>
}

/**
 *  Guidance on how to interpret the value by comparison to a normal or recommended
 * range.  Multiple reference ranges are interpreted as an "OR".   In other words,
 * to represent two distinct target populations, two `referenceRange` elements
 * would be used.
 */
export type ObservationReferenceRange = {
  __typename?: 'ObservationReferenceRange'
  /**
   * The value of the high bound of the reference range.  The high bound of the
   * reference range endpoint is inclusive of the value (e.g.  reference range is
   * >=5 - <=9). If the high bound is omitted,  it is assumed to be meaningless
   * (e.g. reference range is >= 2.3).
   */
  high?: Maybe<ObservationReferenceRangeHighResult>
  /**
   * The value of the low bound of the reference range.  The low bound of the
   * reference range endpoint is inclusive of the value (e.g.  reference range is
   * >=5 - <=9). If the low bound is omitted,  it is assumed to be meaningless
   * (e.g. reference range is <=2.3).
   */
  low?: Maybe<ObservationReferenceRangeLowResult>
  /**
   * Text based reference range in an observation which may be used when a
   * quantitative range is not appropriate for an observation.  An example would be
   * a reference value of "Negative" or a list or table of "normals".
   */
  text?: Maybe<Scalars['String']>
  /**
   * Codes to indicate the what part of the targeted reference population it
   * applies to. For example, the normal or therapeutic range.
   */
  type?: Maybe<ObservationReferenceRangeTypeResult>
}

/**
 * A page/slice of a list of ObservationReferenceRange nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ObservationReferenceRangeConnection = Connection & {
  __typename?: 'ObservationReferenceRangeConnection'
  /** Edges in the current connection page. */
  edges: Array<ObservationReferenceRangeEdge>
  /** List of ObservationReferenceRange object nodes in the current connection page. */
  nodes: Array<Maybe<ObservationReferenceRange>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ObservationReferenceRange connection page. */
export type ObservationReferenceRangeEdge = Edge & {
  __typename?: 'ObservationReferenceRangeEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ObservationReferenceRange object in this edge. */
  node?: Maybe<ObservationReferenceRange>
}

/**
 * The value of the high bound of the reference range.  The high bound of the
 * reference range endpoint is inclusive of the value (e.g.  reference range is >=5
 * - <=9). If the high bound is omitted,  it is assumed to be meaningless (e.g.
 * reference range is >= 2.3).
 */
export type ObservationReferenceRangeHighResult = None | Quantity

/**
 * The value of the low bound of the reference range.  The low bound of the
 * reference range endpoint is inclusive of the value (e.g.  reference range is >=5
 * - <=9). If the low bound is omitted,  it is assumed to be meaningless (e.g.
 * reference range is <=2.3).
 */
export type ObservationReferenceRangeLowResult = None | Quantity

/**
 * Codes to indicate the what part of the targeted reference population it applies
 * to. For example, the normal or therapeutic range.
 */
export type ObservationReferenceRangeTypeResult = CodeableConcept | None

export type ObservationResult =
  | AuthorizationError
  | Observation
  | ResourceNotFoundError

export type ObservationsByIdentifierResult =
  | AuthorizationError
  | ObservationConnection

/**  A sort on Observation - consists of the field to sort on and the ordering  */
export type ObservationSort = {
  /**  Which field to sort by  */
  field: ObservationSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Observation can be sorted by  */
export enum ObservationSortOption {
  /**
   * Sorts on Observation.effective
   * Obtained date/time. If the obtained element is a period, a date that falls in the period
   */
  Date = 'DATE',
  /**  Do not sort  */
  None = 'NONE',
  /**  Sort by how trustworthy the resource is  */
  Trust = 'TRUST',
  /**
   * Sorts on (Observation.value as string)
   * The value of the observation, if the value is a string, and also searches in CodeableConcept.text
   */
  ValueString = 'VALUE_STRING',
}

/**  Codes providing the status of an observation.  */
export enum ObservationStatus {
  /**
   *  Subsequent to being Final, the observation has been modified subsequent.  This
   * includes updates/new information and corrections.
   */
  Amended = 'AMENDED',
  /**
   *  The observation is unavailable because the measurement was not started or not
   * completed (also sometimes called "aborted").
   */
  Cancelled = 'CANCELLED',
  /**  Subsequent to being Final, the observation has been modified to correct an error in the test result.  */
  Corrected = 'CORRECTED',
  /**
   *  The observation has been withdrawn following previous final release.  This
   * electronic record should never have existed, though it is possible that
   * real-world decisions were based on it. (If real-world activity has occurred,
   * the status should be "cancelled" rather than "entered-in-error".).
   */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**
   *  The observation is complete and there are no further actions needed.
   * Additional information such "released", "signed", etc would be represented
   * using [Provenance](provenance.html) which provides not only the act but also
   * the actors and dates and other related data. These act states would be
   * associated with an observation status of `preliminary` until they are all
   * completed and then a status of `final` would be applied.
   */
  Final = 'FINAL',
  /**  This is an initial or interim observation: data may be incomplete or unverified.  */
  Preliminary = 'PRELIMINARY',
  /**  The existence of the observation is registered, but there is no result yet available.  */
  Registered = 'REGISTERED',
  /**
   *  The authoring/source system does not know which of the status values currently
   * applies for this observation. Note: This concept is not to be used for "other"
   * - one of the listed statuses is presumed to apply, but the authoring/source
   * system does not know which.
   */
  Unknown = 'UNKNOWN',
}

/**  Codes providing the status of an observation.  */
export type ObservationStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<ObservationStatus>>>
}

/**
 * The patient, or group of patients, location, or device this observation is about
 * and into whose record the observation is placed. If the actual focus of the
 * observation is different from the subject (or a sample of, part, or region of
 * the subject), the `focus` element or the `code` itself specifies the actual
 * focus of the observation.
 */
export type ObservationSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**  Trust information or None if the Trust data is not present  */
export type ObservationTrustResult = None | Trust

/** The information determined as a result of making the observation, if the information has a simple value. */
export type ObservationValueCodeableConceptResult = CodeableConcept | None

/** The information determined as a result of making the observation, if the information has a simple value. */
export type ObservationValueQuantityResult = None | Quantity

/** The information determined as a result of making the observation, if the information has a simple value. */
export type ObservationValueRangeResult = None | Range

/**
 *  A formally or informally recognized grouping of people or organizations formed
 * for the purpose of achieving some form of collective action.  Includes
 * companies, institutions, corporations, departments, community groups, healthcare
 * practice groups, payer/insurer, etc.
 */
export type Organization = HealthgraphResource &
  Resource & {
    __typename?: 'Organization'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** An address for the organization. */
    addresses?: Maybe<HealthgraphAddressConnection>
    /** A list of alternate names that the organization is known as, or was known as in the past. */
    aliases?: Maybe<StringConnection>
    /** Contact for the organization for a certain purpose. */
    contacts?: Maybe<OrganizationContactConnection>
    /** Text summary of delivery cost and delivery time for the pharmacy */
    deliveryDetails?: Maybe<Scalars['String']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Identifier for the organization that is used to identify the organization across multiple disparate systems. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Whether the organization's record is still in active use. */
    isActive?: Maybe<Scalars['Boolean']>
    /** A name associated with the organization. */
    name?: Maybe<Scalars['String']>
    /** Cutoff time for daily order submission */
    orderSubmissionCutoffTime?: Maybe<Scalars['FhirDateTime']>
    /** The organization of which this organization forms a part. */
    partOf?: Maybe<OrganizationPartOfResult>
    /** A contact detail for the organization. */
    telecoms?: Maybe<ContactPointConnection>
    /** The kind(s) of organization that this is. */
    types?: Maybe<CodeableConceptConnection>
  }

/**
 *  A formally or informally recognized grouping of people or organizations formed
 * for the purpose of achieving some form of collective action.  Includes
 * companies, institutions, corporations, departments, community groups, healthcare
 * practice groups, payer/insurer, etc.
 */
export type OrganizationAddressesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A formally or informally recognized grouping of people or organizations formed
 * for the purpose of achieving some form of collective action.  Includes
 * companies, institutions, corporations, departments, community groups, healthcare
 * practice groups, payer/insurer, etc.
 */
export type OrganizationAliasesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A formally or informally recognized grouping of people or organizations formed
 * for the purpose of achieving some form of collective action.  Includes
 * companies, institutions, corporations, departments, community groups, healthcare
 * practice groups, payer/insurer, etc.
 */
export type OrganizationContactsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A formally or informally recognized grouping of people or organizations formed
 * for the purpose of achieving some form of collective action.  Includes
 * companies, institutions, corporations, departments, community groups, healthcare
 * practice groups, payer/insurer, etc.
 */
export type OrganizationIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A formally or informally recognized grouping of people or organizations formed
 * for the purpose of achieving some form of collective action.  Includes
 * companies, institutions, corporations, departments, community groups, healthcare
 * practice groups, payer/insurer, etc.
 */
export type OrganizationTelecomsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A formally or informally recognized grouping of people or organizations formed
 * for the purpose of achieving some form of collective action.  Includes
 * companies, institutions, corporations, departments, community groups, healthcare
 * practice groups, payer/insurer, etc.
 */
export type OrganizationTypesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of Organization nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type OrganizationConnection = Connection & {
  __typename?: 'OrganizationConnection'
  /** Edges in the current connection page. */
  edges: Array<OrganizationEdge>
  /** List of Organization object nodes in the current connection page. */
  nodes: Array<Maybe<Organization>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**  Contact for the organization for a certain purpose.  */
export type OrganizationContact = {
  __typename?: 'OrganizationContact'
  /** Visiting or postal addresses for the contact. */
  address?: Maybe<OrganizationContactAddressResult>
  /** A name associated with the contact. */
  name?: Maybe<OrganizationContactNameResult>
  /** A contact detail (e.g. a telephone number or an email address) by which the party may be contacted. */
  telecoms?: Maybe<ContactPointConnection>
}

/**  Contact for the organization for a certain purpose.  */
export type OrganizationContactTelecomsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Visiting or postal addresses for the contact. */
export type OrganizationContactAddressResult = HealthgraphAddress | None

/**
 * A page/slice of a list of OrganizationContact nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type OrganizationContactConnection = Connection & {
  __typename?: 'OrganizationContactConnection'
  /** Edges in the current connection page. */
  edges: Array<OrganizationContactEdge>
  /** List of OrganizationContact object nodes in the current connection page. */
  nodes: Array<Maybe<OrganizationContact>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a OrganizationContact connection page. */
export type OrganizationContactEdge = Edge & {
  __typename?: 'OrganizationContactEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the OrganizationContact object in this edge. */
  node?: Maybe<OrganizationContact>
}

/** A name associated with the contact. */
export type OrganizationContactNameResult = HealthgraphHumanName | None

/** An edge in a Organization connection page. */
export type OrganizationEdge = Edge & {
  __typename?: 'OrganizationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Organization object in this edge. */
  node?: Maybe<Organization>
}

/**  Specifies a filter to apply to a list of Organization  */
export type OrganizationFilter = {
  /**
   * Filters on Organization.active
   * Is the Organization record active
   */
  active?: Maybe<BooleanFilter>
  /**
   * Filters on Organization.address
   * A server defined search that may match any of the string fields in the
   * Address, including line, city, district, state, country, postalCode, and/or text
   */
  address?: Maybe<StringFilter>
  /**
   * Filters on Organization.address.city
   * A city specified in an address
   */
  addressCity?: Maybe<StringFilter>
  /**
   * Filters on Organization.address.country
   * A country specified in an address
   */
  addressCountry?: Maybe<StringFilter>
  /**
   * Filters on Organization.address.postalCode
   * A postal code specified in an address
   */
  addressPostalcode?: Maybe<StringFilter>
  /**
   * Filters on Organization.address.state
   * A state specified in an address
   */
  addressState?: Maybe<StringFilter>
  /**
   * Filters on Organization.address.use
   * A use code specified in an address
   */
  addressUse?: Maybe<AddressUseFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<OrganizationFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Organization.identifier
   * Any identifier for the organization (not the accreditation issuer's identifier)
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on Organization.name
   * A portion of the organization's name or alias
   */
  name?: Maybe<StringFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<OrganizationFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<OrganizationFilter>>
  /**
   * Filters on Organization.partOf
   * An organization of which this organization forms a part
   */
  partofUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Organization.type
   * A code for the type of organization
   */
  type?: Maybe<CodeFilter>
}

/** The organization of which this organization forms a part. */
export type OrganizationPartOfResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Organization

export type OrganizationResult =
  | AuthorizationError
  | Organization
  | ResourceNotFoundError

export type OrganizationsByIdentifierResult =
  | AuthorizationError
  | OrganizationConnection

/**  A sort on Organization - consists of the field to sort on and the ordering  */
export type OrganizationSort = {
  /**  Which field to sort by  */
  field: OrganizationSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Organization can be sorted by  */
export enum OrganizationSortOption {
  /**
   * Sorts on Organization.address
   * A server defined search that may match any of the string fields in the
   * Address, including line, city, district, state, country, postalCode, and/or text
   */
  Address = 'ADDRESS',
  /**
   * Sorts on Organization.address.city
   * A city specified in an address
   */
  AddressCity = 'ADDRESS_CITY',
  /**
   * Sorts on Organization.address.country
   * A country specified in an address
   */
  AddressCountry = 'ADDRESS_COUNTRY',
  /**
   * Sorts on Organization.address.postalCode
   * A postal code specified in an address
   */
  AddressPostalcode = 'ADDRESS_POSTALCODE',
  /**
   * Sorts on Organization.address.state
   * A state specified in an address
   */
  AddressState = 'ADDRESS_STATE',
  /**
   * Sorts on Organization.name
   * A portion of the organization's name or alias
   */
  Name = 'NAME',
  /**  Do not sort  */
  None = 'NONE',
}

export type OrganizationsResult = AuthorizationError | OrganizationConnection

/** A ContactPoint which can't be expressed as a phone-number, email address or URL. */
export type OtherContactPoint = ContactPoint & {
  __typename?: 'OtherContactPoint'
  /** @inherit */
  system: ContactPointSystem
  /** Value of the contact point as an opaque string. */
  value: Scalars['String']
}

/**
 * Metadata about a page in a connection result.
 *
 * See [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo)
 */
export type PageInfo = {
  __typename?: 'PageInfo'
  /**
   * Cursor of the last edge in the page. Used when paginating forwards (via the `after` argument).
   * If the page is empty, the value is undefined.
   */
  endCursor: Scalars['Cursor']
  /**
   * Is there another page after the current one?
   * If true, the page where `after` is set to `endCursor` will return the next page.
   * If false, the page where `after` is set to `endCursor` will return an empty page.
   */
  hasNextPage: Scalars['Boolean']
  /**
   * Is there another page before the current one?
   * If true, the page where `before` is set to `startCursor` will return the previous page.
   * If false, the page where `before` is set to `startCursor` will return an empty page.
   */
  hasPreviousPage: Scalars['Boolean']
  /**
   * Cursor of the first edge in the page. Used when paginating backwards (via the `before` argument).
   * If the page is empty, the value is undefined.
   */
  startCursor: Scalars['Cursor']
}

/**  The Participation status of an appointment.  */
export enum ParticipationStatus {
  /**  The participant has accepted the appointment.  */
  Accepted = 'ACCEPTED',
  /**  The participant has declined the appointment and will not participate in the appointment.  */
  Declined = 'DECLINED',
  /**  The participant needs to indicate if they accept the appointment by changing this status to one of the other statuses.  */
  NeedsAction = 'NEEDS_ACTION',
  /**
   *  The participant has  tentatively accepted the appointment. This could be
   * automatically created by a system and requires further processing before it
   * can be accepted. There is no commitment that attendance will occur.
   */
  Tentative = 'TENTATIVE',
}

/**  The Participation status of an appointment.  */
export type ParticipationStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<ParticipationStatus>>>
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type Patient = HealthgraphResource &
  Resource & {
    __typename?: 'Patient'
    /** At which point in the Babylon account lifecycle is the patient at? */
    _activationStatus?: Maybe<PatientActivationStatus>
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** ID of the patient record check */
    _NhsEnrollmentRequest_id?: Maybe<Scalars['NonNegativeInt']>
    /** Has the public healthcare admission location been checked? */
    _NhsEnrollmentRequest_isLocationChecked?: Maybe<Scalars['Boolean']>
    /** Has the PDS trace status been overwritten by clinops? */
    _NhsEnrollmentRequest_isVerificationOverwrittenByOps?: Maybe<
      Scalars['Boolean']
    >
    /** Status of patient record check in the Personal Demograpbics Service in the UK */
    _NhsEnrollmentRequest_status?: Maybe<NhsEnrollmentRequestStatus>
    /** Has the patient been queued for registration to GPatHand? */
    _NhsEnrollmentResponse_isQueuedForActivation?: Maybe<Scalars['Boolean']>
    /** ID of the patient identity check */
    _VerificationResult_id?: Maybe<Scalars['NonNegativeInt']>
    /** Status of the patient identity check */
    _VerificationResult_status?: Maybe<PatientVerificationResultStatus>
    /** Addresses associated with this patient. */
    addresses: AddressConnection
    /**  Appointments that this Patient is the patient of  */
    appointments?: Maybe<AppointmentConnection>
    /**
     * The date on which the patient was born.
     *
     * May be null if unknown.
     */
    birthDate?: Maybe<Scalars['Date']>
    /**  CarePlans that this Patient is the subject of  */
    carePlans?: Maybe<CarePlanConnection>
    /**  CareTeams that this Patient is the subject of  */
    careTeams?: Maybe<CareTeamConnection>
    /** Retrieves a paginated list of challenges for the given User. */
    challenges: UserChallengeConnection
    /**  Claims that this Patient is the patient of  */
    claims?: Maybe<ClaimConnection>
    /**  Cohorts that this Patient is the subject of  */
    cohortMemberships?: Maybe<CohortMemberConnection>
    /**  Communications that this Patient is the subject of  */
    communications?: Maybe<CommunicationConnection>
    /**  Conditions that this Patient is the subject of  */
    conditions?: Maybe<ConditionConnection>
    /**  Coverages that this Patient is the beneficiary of  */
    coverages?: Maybe<CoverageConnection>
    /** When was this Patient resource created */
    createdAt?: Maybe<Scalars['DateTime']>
    /** Default pharmacy of the patient (currently only support US region, Athena) */
    defaultPharmacy?: Maybe<PatientDefaultPharmacyResult>
    /**  DiagnosticReports that this Patient is the subject of  */
    diagnosticReports?: Maybe<DiagnosticReportConnection>
    /**  Encounters that this Patient is the subject of  */
    encounters?: Maybe<EncounterConnection>
    /** Administrative Gender of the patient. */
    gender: AdministrativeGender
    /** Retrieves a paginated list of goals for the given User. */
    goals: UserGoalConnection
    /** @inherit */
    id: Scalars['ID']
    /** An identifier for this patient. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Similar to Patient.link except it only returns objects of RelatedPerson type and not Patient type */
    identities?: Maybe<RelatedPersonConnection>
    /** Is this patient record in active use? */
    isActive: Scalars['Boolean']
    /** Is this patient a minor (based on their region's age of consent)? */
    isMinor?: Maybe<Scalars['Boolean']>
    /** Find latest journey by its definition ID. Returns None when nothing is found. */
    latestDataCollectionJourneyByDefinition?: Maybe<PatientLatestDataCollectionJourneyByDefinitionResult>
    /** Retrieves a paginated list of lifestyle categories for the given User. */
    lifestyleCategories: UserLifestyleCategoryLevelConnection
    /**  MedicationStatements that this Patient is the patient of  */
    medicationStatements?: Maybe<MedicationStatementConnection>
    /** Query member experience domain. */
    memberExperience?: Maybe<MemberExperience>
    /**
     * Active memberships of the patient (more details [here](https://platform.ops.babylontech.co.uk/products/partner_management/tenancy/#membership)).
     * @deprecated Alpha version
     */
    memberships?: Maybe<MembershipsResult>
    /** Preferred name of the patient. May be null if there are no known names. */
    name?: Maybe<HumanName>
    /**  Observations that this Patient is the subject of  */
    observations?: Maybe<ObservationConnection>
    /**
     * Image of the patient.
     *
     * May be null if unknown.
     */
    photo?: Maybe<Image>
    /** Gets details to match a potential dependent for registration. */
    potentialDependents?: Maybe<DependentRelatedPersonConnection>
    /**
     * Preferred membership of the patient (more details [here](https://platform.ops.babylontech.co.uk/domains/membership/preferred_membership)).
     * @deprecated Alpha version
     */
    preferredMembership?: Maybe<PreferredMembershipResult>
    /**  Procedures that this Patient is the patient of  */
    procedures?: Maybe<ProcedureConnection>
    /**  QuestionnaireResponses that this Patient is the subject of  */
    questionnaireResponses?: Maybe<QuestionnaireResponseConnection>
    /**  Communications that this Patient is the recipient of  */
    receivedCommunications?: Maybe<CommunicationConnection>
    /**
     * The RelatedPersons of this patient
     * Inverse of RelatedPerson.patient
     */
    relatedPersons?: Maybe<RelatedPersonConnection>
    /**  RequestGroups that this Patient is the patient of  */
    requestGroups?: Maybe<RequestGroupConnection>
    /**  RiskAssessments that this Patient is the subject of  */
    riskAssessments?: Maybe<RiskAssessmentConnection>
    /**  ServiceRequests that this Patient is the patient of  */
    serviceRequests?: Maybe<ServiceRequestConnection>
    /** The patient's sex at birth. */
    sexAtBirth: SexAtBirth
    /** Contact detail for the patient, e.g. a telephone number or an email address. */
    telecoms: ContactPointConnection
  }

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientAddressesArgs = {
  cursor?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientAppointmentsArgs = {
  _filter?: Maybe<AppointmentFilter>
  _sort?: Maybe<Array<Maybe<AppointmentSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientCarePlansArgs = {
  _filter?: Maybe<CarePlanFilter>
  _sort?: Maybe<Array<Maybe<CarePlanSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientCareTeamsArgs = {
  _filter?: Maybe<CareTeamFilter>
  _sort?: Maybe<Array<Maybe<CareTeamSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientChallengesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientClaimsArgs = {
  _filter?: Maybe<ClaimFilter>
  _sort?: Maybe<Array<Maybe<ClaimSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientCohortMembershipsArgs = {
  _filter?: Maybe<CohortMemberFilter>
  _sort?: Maybe<Array<Maybe<CohortMemberSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientCommunicationsArgs = {
  _filter?: Maybe<CommunicationFilter>
  _sort?: Maybe<Array<Maybe<CommunicationSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientConditionsArgs = {
  _filter?: Maybe<ConditionFilter>
  _sort?: Maybe<Array<Maybe<ConditionSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientCoveragesArgs = {
  _filter?: Maybe<CoverageFilter>
  _sort?: Maybe<Array<Maybe<CoverageSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientDiagnosticReportsArgs = {
  _filter?: Maybe<DiagnosticReportFilter>
  _sort?: Maybe<Array<Maybe<DiagnosticReportSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientEncountersArgs = {
  _filter?: Maybe<EncounterFilter>
  _sort?: Maybe<Array<Maybe<EncounterSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientGoalsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientIdentitiesArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  filter?: Maybe<PatientIdentitiesFilter>
  first: Scalars['NonNegativeInt']
  last?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientLatestDataCollectionJourneyByDefinitionArgs = {
  id: Scalars['ID']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientLifestyleCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientMedicationStatementsArgs = {
  _filter?: Maybe<MedicationStatementFilter>
  _sort?: Maybe<Array<Maybe<MedicationStatementSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientNameArgs = {
  preferredUse?: Maybe<NameUse>
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientObservationsArgs = {
  _filter?: Maybe<ObservationFilter>
  _sort?: Maybe<Array<Maybe<ObservationSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientPotentialDependentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientPreferredMembershipArgs = {
  appName: Scalars['String']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientProceduresArgs = {
  _filter?: Maybe<ProcedureFilter>
  _sort?: Maybe<Array<Maybe<ProcedureSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientQuestionnaireResponsesArgs = {
  _filter?: Maybe<QuestionnaireResponseFilter>
  _sort?: Maybe<Array<Maybe<QuestionnaireResponseSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientReceivedCommunicationsArgs = {
  _filter?: Maybe<CommunicationFilter>
  _sort?: Maybe<Array<Maybe<CommunicationSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientRelatedPersonsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  filter?: Maybe<PatientRelatedPersonsFilter>
  first: Scalars['NonNegativeInt']
  last?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientRequestGroupsArgs = {
  _filter?: Maybe<RequestGroupFilter>
  _sort?: Maybe<Array<Maybe<RequestGroupSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientRiskAssessmentsArgs = {
  _filter?: Maybe<RiskAssessmentFilter>
  _sort?: Maybe<Array<Maybe<RiskAssessmentSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientServiceRequestsArgs = {
  _filter?: Maybe<ServiceRequestFilter>
  _sort?: Maybe<Array<Maybe<ServiceRequestSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Demographics and other administrative information about an individual or animal
 * receiving care or other health-related services.
 */
export type PatientTelecomsArgs = {
  cursor?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Patient account deletion request information. */
export type PatientAccountDeletionRequest = {
  __typename?: 'PatientAccountDeletionRequest'
  /** The request id. */
  id?: Maybe<Scalars['ID']>
}

/** Patient account deletion request error information. */
export type PatientAccountDeletionRequestError = Error & {
  __typename?: 'PatientAccountDeletionRequestError'
  /** Readable error message. */
  errorMessage: Scalars['String']
  /** HTTP response code. */
  statusCode: Scalars['Int']
}

/** The point at which an account is within the Babylon patient onboarding lifecycle. */
export enum PatientActivationStatus {
  Active = 'ACTIVE',
  InvitationSent = 'INVITATION_SENT',
}

/**  An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type PatientCareIdentifier = {
  __typename?: 'PatientCareIdentifier'
  /**  Establishes the namespace for the value - that is, a URL that describes a set values that are unique. */
  system?: Maybe<Scalars['Uri']>
  /**  The portion of the identifier typically relevant to the user and which is unique within the context of the system. */
  value?: Maybe<Scalars['String']>
}

/**  An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type PatientCareIdentifierInput = {
  /**  Establishes the namespace for the value - that is, a URL that describes a set values that are unique. */
  system?: Maybe<Scalars['Uri']>
  /**  The portion of the identifier typically relevant to the user and which is unique within the context of the system. */
  value?: Maybe<Scalars['String']>
}

/** An error indicating that something went wrong while handling the assigned care plan */
export type PatientCareNotifyEhrCarePlanAssignedError = Error & {
  __typename?: 'PatientCareNotifyEhrCarePlanAssignedError'
  /** Error message from the server */
  errorMessage: Scalars['String']
}

/** Details of the Day to day care plan that was assigned to a patient */
export type PatientCareNotifyEhrCarePlanAssignedInput = {
  /** Identifies the encounter the care plan was created in */
  encounter?: Maybe<PatientCareReferenceInput>
  /**
   * Unique, canonical identifier for the resource.
   * An opaque string, unless the implementing resource explicitly defines a format.
   * Note, the canonical ID for a given resource may change over time, but an ID
   * will never be reassigned to a different resource.
   */
  id: Scalars['ID']
  /** Identifies the patient or group whose intended care is described by the plan. */
  subject?: Maybe<PatientCareReferenceInput>
  /** Title of the care plan */
  title?: Maybe<Scalars['String']>
}

/**  AssignedDtdCarePlanResult  */
export type PatientCareNotifyEhrCarePlanAssignedResult =
  | DtdCarePlan
  | PatientCareNotifyEhrCarePlanAssignedError

/** A reference from one resource to another */
export type PatientCareReference = {
  __typename?: 'PatientCareReference'
  /** Logical reference, when literal reference is not known */
  identifier?: Maybe<PatientCareIdentifier>
  /** Type the reference refers to (e.g. Patient) - must be a resource in resources */
  type?: Maybe<Scalars['String']>
}

/** A reference from one resource to another */
export type PatientCareReferenceInput = {
  /** Logical reference, when literal reference is not known */
  identifier?: Maybe<PatientCareIdentifierInput>
  /** Type the reference refers to (e.g. Patient) - must be a resource in resources */
  type?: Maybe<Scalars['String']>
}

/**
 * A page/slice of a list of Patient nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type PatientConnection = Connection & {
  __typename?: 'PatientConnection'
  /** Edges in the current connection page. */
  edges: Array<PatientEdge>
  /** List of Patient object nodes in the current connection page. */
  nodes: Array<Maybe<Patient>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

export type PatientDefaultPharmacyResult = None | PharmacyOrganization

/** An edge in a Patient connection page. */
export type PatientEdge = Edge & {
  __typename?: 'PatientEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Patient object in this edge. */
  node?: Maybe<Patient>
}

/**  Specifies a filter to apply to a list of Patient  */
export type PatientFilter = {
  /**
   * Filters on Patient.address
   * A server defined search that may match any of the string fields in the
   * Address, including line, city, district, state, country, postalCode, and/or text
   */
  address?: Maybe<StringFilter>
  /**
   * Filters on Patient.address.city
   * A city specified in an address
   */
  addressCity?: Maybe<StringFilter>
  /**
   * Filters on Patient.address.postalCode
   * A postalCode specified in an address
   */
  addressPostalcode?: Maybe<StringFilter>
  /**
   * Filters on Patient.address.state
   * A state specified in an address
   */
  addressState?: Maybe<StringFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<PatientFilter>>
  /**
   * Filters on Patient.birthDate
   * The patient's date of birth
   */
  birthdate?: Maybe<DateFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Patient.telecom.where(system='email')
   * A value in an email contact
   */
  email?: Maybe<ContactPointFilter>
  /**
   * Filters on Patient.name.family
   * A portion of the family name of the patient
   */
  family?: Maybe<StringFilter>
  /**
   * Filters on Patient.gender
   * Gender of the patient
   */
  gender?: Maybe<AdministrativeGenderFilter>
  /**
   * Filters on Patient.name.given
   * A portion of the given name of the patient
   */
  given?: Maybe<StringFilter>
  /**
   * Filters on Patient.identifier
   * A patient identifier
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on Patient.name
   * A server defined search that may match any of the string fields in the
   * HumanName, including family, give, prefix, suffix, suffix, and/or text
   */
  name?: Maybe<StringFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<PatientFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<PatientFilter>>
  /**
   * Filters on Patient.telecom.where(system='phone')
   * A value in a phone contact
   */
  phone?: Maybe<ContactPointFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Patient.telecom
   * The value in any kind of telecom details of the patient
   */
  telecom?: Maybe<ContactPointFilter>
}

/** Filter to apply to a connection list of RelatedPerson */
export type PatientIdentitiesFilter = {
  /**
   * Searches on Patient.identities
   *
   * The relationship between the patient and the relatedperson
   */
  relationships?: Maybe<CodeSearch>
}

/** Details to create a patient. */
export type PatientInfoInput = {
  /**
   * The date on which the patient was born.
   *
   * May be null if unknown.
   */
  birthDate?: Maybe<Scalars['Date']>
  /** Administrative Gender of the patient. */
  gender?: Maybe<AdministrativeGender>
  /** Sex at birth of the patient */
  sexAtBirth?: Maybe<SexAtBirthInput>
}

export type PatientLatestDataCollectionJourneyByDefinitionResult =
  | DataCollectionJourney
  | None
  | ResourceNotFoundError

/** Filter to apply to a connection list of RelatedPerson */
export type PatientRelatedPersonsFilter = {
  relationships?: Maybe<CodeSearch>
}

export type PatientResult = Patient | ResourceNotFoundError

export type PatientsByIdentifierResult = AuthorizationError | PatientConnection

/**  A sort on Patient - consists of the field to sort on and the ordering  */
export type PatientSort = {
  /**  Which field to sort by  */
  field: PatientSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Patient can be sorted by  */
export enum PatientSortOption {
  /**
   * Sorts on Patient.address
   * A server defined search that may match any of the string fields in the
   * Address, including line, city, district, state, country, postalCode, and/or text
   */
  Address = 'ADDRESS',
  /**
   * Sorts on Patient.address.city
   * A city specified in an address
   */
  AddressCity = 'ADDRESS_CITY',
  /**
   * Sorts on Patient.address.postalCode
   * A postalCode specified in an address
   */
  AddressPostalcode = 'ADDRESS_POSTALCODE',
  /**
   * Sorts on Patient.address.state
   * A state specified in an address
   */
  AddressState = 'ADDRESS_STATE',
  /**
   * Sorts on Patient.birthDate
   * The patient's date of birth
   */
  Birthdate = 'BIRTHDATE',
  /**
   * Sorts on Patient.name.family
   * A portion of the family name of the patient
   */
  Family = 'FAMILY',
  /**
   * Sorts on Patient.name.given
   * A portion of the given name of the patient
   */
  Given = 'GIVEN',
  /**
   * Sorts on Patient.name
   * A server defined search that may match any of the string fields in the
   * HumanName, including family, give, prefix, suffix, suffix, and/or text
   */
  Name = 'NAME',
  /**  Do not sort  */
  None = 'NONE',
}

export type PatientsResult = AuthorizationError | PatientConnection

/** Status of a patient's identity check. */
export enum PatientVerificationResultStatus {
  Failed = 'FAILED',
  New = 'NEW',
  Pending = 'PENDING',
  Stale = 'STALE',
  Succeeded = 'SUCCEEDED',
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  /** The id of the payment method. */
  id: Scalars['ID']
  /**
   * Short-lived, secure, one-time-use reference to payment information in BrainTree.
   *
   * It will allow the client to communicate with BrainTree without passing sensitive information.
   *
   * If not used, it expires 3 hours after being created (more details [here](https://developer.paypal.com/braintree/docs/guides/payment-method-nonces)).
   */
  nonce: Scalars['String']
  /** Token stored in Core Ruby for each Core Ruby payment method ID (more details [here](https://developer.paypal.com/braintree/docs/guides/3d-secure/server-side/node#create-a-transaction-with-an-authentication-id)). */
  token: Scalars['String']
}

export type PaymentMethodResult = PaymentMethod | ResourceNotFoundError

/**  A time period defined by a start and end date/time. */
export type Period = {
  __typename?: 'Period'
  /**
   *  The end of the period. If the end of the period is missing, it means no end
   * was known or planned at the time the instance was created. The start may be in
   * the past, and the end date in the future, which means that period is
   * expected/planned to end at that time.
   */
  end?: Maybe<Scalars['FhirDateTime']>
  /**  The start of the period. The boundary is inclusive. */
  start?: Maybe<Scalars['FhirDateTime']>
}

/**  A unit of time (units from UCUM). */
export enum PeriodUnit {
  /**  year */
  A = 'A',
  /**  day */
  D = 'D',
  /**  hour */
  H = 'H',
  /**  minute */
  Min = 'MIN',
  /**  month */
  Mo = 'MO',
  /**  second */
  S = 'S',
  /**  week */
  Wk = 'WK',
}

/** An external consumer/client system identifier for an organization. */
export type PharmacyIdentifier = {
  __typename?: 'PharmacyIdentifier'
  /** The external ID system URL. */
  system: Scalars['Uri']
  /** The external ID value. */
  value: Scalars['String']
}

/**
 * A page of pharmacy identifiers.
 *
 * An implementation of [Connection Type](https://relay.dev/graphql/connections.htm#sec-Connection-Types)
 * for edges of type `PharmacyIdentifierEdge`.
 */
export type PharmacyIdentifierConnection = Connection & {
  __typename?: 'PharmacyIdentifierConnection'
  /** Edges in the current connection page. */
  edges: Array<PharmacyIdentifierEdge>
  /** List of pharmacy identifier nodes in the current connection page. */
  nodes: Array<Maybe<PharmacyIdentifier>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An implementation of [Edges](https://relay.dev/graphql/connections.htm#sec-Edges)
 * for a node of type `PharmacyIdentifier`.
 */
export type PharmacyIdentifierEdge = Edge & {
  __typename?: 'PharmacyIdentifierEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the String object in this edge. */
  node?: Maybe<PharmacyIdentifier>
}

/** A pharmacy organization that the patient uses/has used in the past. */
export type PharmacyOrganization = Resource & {
  __typename?: 'PharmacyOrganization'
  /** Optional Athena pharmacy type */
  _medication_athenaPharmacyType?: Maybe<Scalars['String']>
  /** Address of the pharmacy */
  address?: Maybe<PharmacyOrganizationAddressResult>
  /** The unique identifier for the pharmacy */
  id: Scalars['ID']
  /** Identifiers used by external systems to reference the pharmacy (e.g. US Official `ncpdpId`). */
  identifiers?: Maybe<PharmacyIdentifierConnection>
  /** The name of the pharmacy */
  name?: Maybe<Scalars['String']>
  /** Contact detail for the pharmacy, e.g. a telephone number or an email address. */
  telecoms?: Maybe<ContactPointConnection>
}

/** A pharmacy organization that the patient uses/has used in the past. */
export type PharmacyOrganizationIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** A pharmacy organization that the patient uses/has used in the past. */
export type PharmacyOrganizationTelecomsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats) */
export type PharmacyOrganizationAddress = {
  __typename?: 'PharmacyOrganizationAddress'
  /** Name of city, town etc. */
  city?: Maybe<Scalars['String']>
  /** Country (e.g. can be ISO 3166 2 or 3 letter code) */
  country?: Maybe<Scalars['CountryCode']>
  /** District name (aka county) */
  district?: Maybe<Scalars['String']>
  /** Street name, number, direction & P.O. Box etc. */
  lines?: Maybe<StringConnection>
  /** Postal code for area */
  postalCode?: Maybe<Scalars['PostalCode']>
  /** Sub-unit of country (abbreviations ok) */
  state?: Maybe<Scalars['String']>
  /** Text representation of the address */
  text: Scalars['String']
}

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats) */
export type PharmacyOrganizationAddressLinesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

export type PharmacyOrganizationAddressResult =
  | None
  | PharmacyOrganizationAddress

/**
 * A page of pharmacy identifiers.
 *
 * An implementation of [Connection Type](https://relay.dev/graphql/connections.htm#sec-Connection-Types)
 * for edges of type `PharmacyOrganizationEdge`.
 */
export type PharmacyOrganizationConnection = Connection & {
  __typename?: 'PharmacyOrganizationConnection'
  /** Edges in the current connection page. */
  edges: Array<PharmacyOrganizationEdge>
  /** List of pharmacy organization object nodes in the current connection page. */
  nodes: Array<Maybe<PharmacyOrganization>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An implementation of [Edges](https://relay.dev/graphql/connections.htm#sec-Edges)
 * for a node of type `PharmacyOrganization`.
 */
export type PharmacyOrganizationEdge = Edge & {
  __typename?: 'PharmacyOrganizationEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<PharmacyOrganization>
}

export type PharmacyOrganizationResult =
  | PharmacyOrganization
  | ResourceNotFoundError

/** Filter for searching for pharmacies. */
export type PharmacyOrganizationsFilter = {
  phoneNumber?: Maybe<Scalars['String']>
}

/** Input type for number inputs */
export type PhoneActionMessageData = {
  __typename?: 'PhoneActionMessageData'
  /** confirmation text show to userd */
  confirmationText?: Maybe<Scalars['String']>
  /** phone number to call */
  number: Scalars['String']
}

/** Phone number */
export type PhoneInputMessage = {
  /** phone number */
  phone: Scalars['String']
}

/** Depracated */
export type PhoneMessageInput = {
  __typename?: 'PhoneMessageInput'
  /**
   * not used anymore to deprecate and remove
   * @deprecated Not intended for external usage
   */
  empty?: Maybe<Scalars['Void']>
}

/** A telephone-number based ContactPoint */
export type PhoneNumberContactPoint = ContactPoint & {
  __typename?: 'PhoneNumberContactPoint'
  /** The dateTime at which the contact point was created in our systems */
  createdAt?: Maybe<Scalars['DateTime']>
  /** Identifier for this phone number */
  id?: Maybe<Scalars['ID']>
  /** Whether or not this is the primary contact point of this type */
  isPrimary?: Maybe<Scalars['Boolean']>
  /** Value of the contact point as a phone number. */
  phoneNumber: Scalars['PhoneNumber']
  /** A string to describe the source of the contact point */
  source?: Maybe<Scalars['String']>
  /** The dateTime at which the contact point was ingested at the source */
  sourceIngestionTimestamp?: Maybe<Scalars['DateTime']>
  /** A string to describe the status of the contact point */
  status?: Maybe<Scalars['String']>
  /** @inherit */
  system: ContactPointSystem
  /** The dateTime at which the contact point was updated in our systems */
  updatedAt?: Maybe<Scalars['DateTime']>
  /**
   * Identifies the purpose for the contact point.
   * Allows an appropriate phone to be chosen from a list of many.
   * home | work | temp | old | mobile
   */
  use?: Maybe<Scalars['FhirCode']>
}

/** Response type for portal role */
export type PortalRole = {
  __typename?: 'PortalRole'
  /** Babylon unique Identifier */
  id: Scalars['ID']
  /** Key for the Portal Role */
  key: Scalars['String']
  /** Name of the Portal Role */
  value: Scalars['String']
}

/**
 * A page of portal roles.
 *
 * An implementation of [Connection Type](https://relay.dev/graphql/connections.htm#sec-Connection-Types)
 * for edges of type `PortalRoleEdge`.
 */
export type PortalRoleConnection = Connection & {
  __typename?: 'PortalRoleConnection'
  /** Edges in the current connection page. */
  edges: Array<PortalRoleEdge>
  /** List of portal role object nodes in the current connection page. */
  nodes: Array<Maybe<PortalRole>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /** The total number of items in the underlying data set. */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An implementation of [Edges](https://relay.dev/graphql/connections.htm#sec-Edges)
 * for a node of type `PortalRoleEdge`.
 */
export type PortalRoleEdge = Edge & {
  __typename?: 'PortalRoleEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<PortalRole>
}

/** Error type for fetching portal roles */
export type PortalRolesError = Error & {
  __typename?: 'PortalRolesError'
  /** Error message */
  errorMessage: Scalars['String']
}

/** The Clinic, Practice, or HealthcareService */
export type PracticeHealthcareService = Resource & {
  __typename?: 'PracticeHealthcareService'
  /** Instructions presented to members on arrival at the Healthcare service. */
  arrivalInstructions?: Maybe<LocalizedString>
  /**
   * Unique, canonical identifier for the resource.
   * An opaque string, unless the implementing resource explicitly defines a format.
   * Note, the canonical ID for a given resource may change over time, but an ID
   * will never be reassigned to a different resource.
   */
  id: Scalars['ID']
  /** External identifiers for this slot. */
  identifiers: HealthgraphIdentifierConnection
  /** The location information of the Healthcare service. */
  location?: Maybe<PracticeHealthcareServiceLocationResult>
  /** The name of the health care service. */
  name?: Maybe<Scalars['String']>
  /** The timezone of the healthcare service in the ISO 8601 format. */
  timezone?: Maybe<Scalars['TimezoneId']>
}

/** The Clinic, Practice, or HealthcareService */
export type PracticeHealthcareServiceIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** The location information of the Healthcare service. */
export type PracticeHealthcareServiceLocation = {
  __typename?: 'PracticeHealthcareServiceLocation'
  /** The address information of the Healthcare service. */
  address?: Maybe<PracticeHealthcareServiceLocationAddress>
}

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats) */
export type PracticeHealthcareServiceLocationAddress = {
  __typename?: 'PracticeHealthcareServiceLocationAddress'
  /** Name of city, town etc. */
  city?: Maybe<Scalars['String']>
  /** Country (e.g. can be ISO 3166 2 or 3 letter code) */
  country?: Maybe<Scalars['CountryCode']>
  /** District name (aka county) */
  district?: Maybe<Scalars['String']>
  /** Street name, number, direction & P.O. Box etc. */
  lines?: Maybe<StringConnection>
  /** Postal code for area */
  postalCode?: Maybe<Scalars['PostalCode']>
  /** Sub-unit of country (abbreviations ok) */
  state?: Maybe<Scalars['String']>
  /** Text representation of the address */
  text: Scalars['String']
}

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats) */
export type PracticeHealthcareServiceLocationAddressLinesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** The result for a Healthcare service location. */
export type PracticeHealthcareServiceLocationResult =
  | None
  | PracticeHealthcareServiceLocation

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type Practitioner = HealthgraphResource &
  Resource & {
    __typename?: 'Practitioner'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * Address(es) of the practitioner that are not role specific (typically home address).
     * Work addresses are not typically entered in this property as they are usually role dependent.
     */
    addresses?: Maybe<HealthgraphAddressConnection>
    /**  Appointments that this Practitioner is the practitioner of  */
    appointments?: Maybe<AppointmentConnection>
    /** Fetch available slots associated with this practitioner in the stipulated period. */
    availabilitySlots?: Maybe<AvailabilitySlotsResult>
    /** The date of birth for the practitioner. */
    birthDate?: Maybe<Scalars['FhirDate']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * The id field and a list of dfhir items.
     * Intended to be used by incoming mutation requests to pass updated data so that healthgraph can return the up-to-date
     * data back. This field is not meant to be read and in fact would return an empty result for dfhir if it was requested.
     * It is in the form of a json scalar. Each dfhir item consists of template name, domain, template version, the actual
     * dfhir data, and the uuid of the owner of the data.
     */
    idAndDfhir?: Maybe<Scalars['IdAndDfhir']>
    /** An identifier that applies to this person in this role. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Whether this practitioner's record is in active use. */
    isActive?: Maybe<Scalars['Boolean']>
    /** Practitioner can treat NHS patients? */
    isCanTreatNhsPatients?: Maybe<Scalars['Boolean']>
    /** The name(s) associated with the practitioner. */
    names?: Maybe<HealthgraphHumanNameConnection>
    /** Image of the person. */
    photos?: Maybe<AttachmentConnection>
    /**
     * The clinical or business role that a practitioner can fulfil at Babylon (e.g.
     * GP, nurse, developer, general admin, promotion csv manager etc.)
     */
    practitionerRole?: Maybe<FhirCodeConnection>
    /** A contact detail for the practitioner, e.g. a telephone number or an email address. */
    telecoms?: Maybe<ContactPointConnection>
  }

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerAddressesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerAppointmentsArgs = {
  _filter?: Maybe<AppointmentFilter>
  _sort?: Maybe<Array<Maybe<AppointmentSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerAvailabilitySlotsArgs = {
  after?: Maybe<Scalars['Cursor']>
  filter?: Maybe<AvailabilitySlotsFilter>
  first: Scalars['NonNegativeInt']
}

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerNamesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerPhotosArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerPractitionerRoleArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A person who is directly or indirectly involved in the provisioning of healthcare. */
export type PractitionerTelecomsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of Practitioner nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type PractitionerConnection = Connection & {
  __typename?: 'PractitionerConnection'
  /** Edges in the current connection page. */
  edges: Array<PractitionerEdge>
  /** List of Practitioner object nodes in the current connection page. */
  nodes: Array<Maybe<Practitioner>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Practitioner connection page. */
export type PractitionerEdge = Edge & {
  __typename?: 'PractitionerEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Practitioner object in this edge. */
  node?: Maybe<Practitioner>
}

/**  Specifies a filter to apply to a list of Practitioner  */
export type PractitionerFilter = {
  /**
   * Filters on Practitioner.active
   * Whether the practitioner record is active
   */
  active?: Maybe<BooleanFilter>
  /**
   * Filters on Practitioner.address
   * A server defined search that may match any of the string fields in the
   * Address, including line, city, district, state, country, postalCode, and/or text
   */
  address?: Maybe<StringFilter>
  /**
   * Filters on Practitioner.address.city
   * A city specified in an address
   */
  addressCity?: Maybe<StringFilter>
  /**
   * Filters on Practitioner.address.country
   * A country specified in an address
   */
  addressCountry?: Maybe<StringFilter>
  /**
   * Filters on Practitioner.address.postalCode
   * A postalCode specified in an address
   */
  addressPostalcode?: Maybe<StringFilter>
  /**
   * Filters on Practitioner.address.state
   * A state specified in an address
   */
  addressState?: Maybe<StringFilter>
  /**
   * Filters on Practitioner.address.use
   * A use code specified in an address
   */
  addressUse?: Maybe<AddressUseFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<PractitionerFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Practitioner.telecom.where(system='email')
   * A value in an email contact
   */
  email?: Maybe<ContactPointFilter>
  /**
   * Filters on Practitioner.name.family
   * A portion of the family name
   */
  family?: Maybe<StringFilter>
  /**
   * Filters on Practitioner.name.given
   * A portion of the given name
   */
  given?: Maybe<StringFilter>
  /**
   * Filters on Practitioner.identifier
   * A practitioner's Identifier
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on Practitioner.name
   * A server defined search that may match any of the string fields in the
   * HumanName, including family, give, prefix, suffix, suffix, and/or text
   */
  name?: Maybe<StringFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<PractitionerFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<PractitionerFilter>>
  /**
   * Filters on Practitioner.telecom.where(system='phone')
   * A value in a phone contact
   */
  phone?: Maybe<ContactPointFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Practitioner.telecom
   * The value in any kind of contact
   */
  telecom?: Maybe<ContactPointFilter>
}

export type PractitionerResult =
  | AuthorizationError
  | Practitioner
  | ResourceNotFoundError

/**
 *  A specific set of Roles/Locations/specialties/services that a practitioner may
 * perform at an organization for a period of time.
 */
export type PractitionerRole = HealthgraphResource &
  Resource & {
    __typename?: 'PractitionerRole'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** Roles which this practitioner is authorized to perform for the organization. */
    codes?: Maybe<CodeableConceptConnection>
    /** The list of healthcare services that this worker provides for this role's Organization/Location(s). */
    healthcareServices?: Maybe<PractitionerRoleHealthcareServiceResultConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Business Identifiers that are specific to a role/location. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Whether this practitioner role record is in active use. */
    isActive?: Maybe<Scalars['Boolean']>
    /** The organization where the Practitioner performs the roles associated. */
    organization?: Maybe<PractitionerRoleOrganizationResult>
    /** Practitioner that is able to provide the defined services for the organization. */
    practitioner?: Maybe<PractitionerRolePractitionerResult>
  }

/**
 *  A specific set of Roles/Locations/specialties/services that a practitioner may
 * perform at an organization for a period of time.
 */
export type PractitionerRoleCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A specific set of Roles/Locations/specialties/services that a practitioner may
 * perform at an organization for a period of time.
 */
export type PractitionerRoleHealthcareServicesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A specific set of Roles/Locations/specialties/services that a practitioner may
 * perform at an organization for a period of time.
 */
export type PractitionerRoleIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of PractitionerRole nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type PractitionerRoleConnection = Connection & {
  __typename?: 'PractitionerRoleConnection'
  /** Edges in the current connection page. */
  edges: Array<PractitionerRoleEdge>
  /** List of PractitionerRole object nodes in the current connection page. */
  nodes: Array<Maybe<PractitionerRole>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a PractitionerRole connection page. */
export type PractitionerRoleEdge = Edge & {
  __typename?: 'PractitionerRoleEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the PractitionerRole object in this edge. */
  node?: Maybe<PractitionerRole>
}

/**  Specifies a filter to apply to a list of PractitionerRole  */
export type PractitionerRoleFilter = {
  /**
   * Filters on PractitionerRole.active
   * Whether this practitioner role record is in active use
   */
  active?: Maybe<BooleanFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<PractitionerRoleFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on PractitionerRole.identifier
   * A practitioner's Identifier
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<PractitionerRoleFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<PractitionerRoleFilter>>
  /**
   * Filters on PractitionerRole.organization
   * The identity of the organization the practitioner represents / acts on behalf of
   */
  organizationUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on PractitionerRole.practitioner
   * Practitioner that is able to provide the defined services for the organization
   */
  practitionerUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on PractitionerRole.code
   * The practitioner can perform this role at for the organization
   */
  role?: Maybe<CodeFilter>
  /**
   * Filters on PractitionerRole.healthcareService
   * The list of healthcare services that this worker provides for this role's Organization/Location(s)
   */
  serviceUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** The list of healthcare services that this worker provides for this role's Organization/Location(s). */
export type PractitionerRoleHealthcareServiceResult =
  | ExternalResource
  | HealthcareService
  | NonFederatedResource

/**
 * A page/slice of a list of PractitionerRoleHealthcareServiceResult nodes,
 * allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type PractitionerRoleHealthcareServiceResultConnection = Connection & {
  __typename?: 'PractitionerRoleHealthcareServiceResultConnection'
  /** Edges in the current connection page. */
  edges: Array<PractitionerRoleHealthcareServiceResultEdge>
  /** List of PractitionerRoleHealthcareServiceResult object nodes in the current connection page. */
  nodes: Array<Maybe<PractitionerRoleHealthcareServiceResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a PractitionerRoleHealthcareServiceResult connection page. */
export type PractitionerRoleHealthcareServiceResultEdge = Edge & {
  __typename?: 'PractitionerRoleHealthcareServiceResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the PractitionerRoleHealthcareServiceResult object in this edge. */
  node?: Maybe<PractitionerRoleHealthcareServiceResult>
}

/** The organization where the Practitioner performs the roles associated. */
export type PractitionerRoleOrganizationResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Organization

/** Practitioner that is able to provide the defined services for the organization. */
export type PractitionerRolePractitionerResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

export type PractitionerRoleResult =
  | AuthorizationError
  | PractitionerRole
  | ResourceNotFoundError

export type PractitionerRolesByIdentifierResult =
  | AuthorizationError
  | PractitionerRoleConnection

/**  A sort on PractitionerRole - consists of the field to sort on and the ordering  */
export type PractitionerRoleSort = {
  /**  Which field to sort by  */
  field: PractitionerRoleSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that PractitionerRole can be sorted by  */
export enum PractitionerRoleSortOption {
  /**  Do not sort  */
  None = 'NONE',
}

export type PractitionerRolesResult =
  | AuthorizationError
  | PractitionerRoleConnection

export type PractitionersByIdentifierResult =
  | AuthorizationError
  | PractitionerConnection

/**  A sort on Practitioner - consists of the field to sort on and the ordering  */
export type PractitionerSort = {
  /**  Which field to sort by  */
  field: PractitionerSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Practitioner can be sorted by  */
export enum PractitionerSortOption {
  /**
   * Sorts on Practitioner.address
   * A server defined search that may match any of the string fields in the
   * Address, including line, city, district, state, country, postalCode, and/or text
   */
  Address = 'ADDRESS',
  /**
   * Sorts on Practitioner.address.city
   * A city specified in an address
   */
  AddressCity = 'ADDRESS_CITY',
  /**
   * Sorts on Practitioner.address.country
   * A country specified in an address
   */
  AddressCountry = 'ADDRESS_COUNTRY',
  /**
   * Sorts on Practitioner.address.postalCode
   * A postalCode specified in an address
   */
  AddressPostalcode = 'ADDRESS_POSTALCODE',
  /**
   * Sorts on Practitioner.address.state
   * A state specified in an address
   */
  AddressState = 'ADDRESS_STATE',
  /**
   * Sorts on Practitioner.name.family
   * A portion of the family name
   */
  Family = 'FAMILY',
  /**
   * Sorts on Practitioner.name.given
   * A portion of the given name
   */
  Given = 'GIVEN',
  /**
   * Sorts on Practitioner.name
   * A server defined search that may match any of the string fields in the
   * HumanName, including family, give, prefix, suffix, suffix, and/or text
   */
  Name = 'NAME',
  /**  Do not sort  */
  None = 'NONE',
}

export type PractitionersResult = AuthorizationError | PractitionerConnection

/** Input for prebooking conversation type */
export type PrebookingConversationData = {
  /** true if prebooking is about appointment */
  appointmentType: Scalars['Boolean']
  /** debug flag */
  debug?: Maybe<Scalars['Boolean']>
}

/** Prediction object for each predicted address from string matches. Return from Google Places API */
export type Prediction = {
  __typename?: 'Prediction'
  /** add description */
  description?: Maybe<Scalars['String']>
  /** add description */
  matchedSubstrings?: Maybe<MatchedSubstringConnection>
  /** Google Places placeId used to fetch specific address details */
  placeId?: Maybe<Scalars['String']>
  /** add description */
  reference?: Maybe<Scalars['String']>
  /** add description */
  structuredFormatting?: Maybe<StructuredFormatting>
}

/**
 * A page of address predictions.
 *
 * An implementation of [Connection Type](https://relay.dev/graphql/connections.htm#sec-Connection-Types)
 * for edges of type `PredictionEdge`.
 */
export type PredictionConnection = Connection & {
  __typename?: 'PredictionConnection'
  /** add description */
  edges: Array<PredictionEdge>
  /** add description */
  nodes: Array<Maybe<Prediction>>
  /** add description */
  pageInfo: PageInfo
  /** add description */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An implementation of [Edges](https://relay.dev/graphql/connections.htm#sec-Edges)
 * for a node of type `Prediction`.
 */
export type PredictionEdge = Edge & {
  __typename?: 'PredictionEdge'
  /** add description */
  cursor: Scalars['Cursor']
  /** add description */
  node?: Maybe<Prediction>
}

export type PreferredMembershipResult =
  | Membership
  | MembershipsResourceBadRequestError
  | MembershipsResourceNotFoundError

/**
 *  An action that is or was performed on or for a patient. This can be a physical
 * intervention like an operation, or less invasive like long term services,
 * counseling, or hypnotherapy.
 */
export type Procedure = HealthgraphResource &
  Resource & {
    __typename?: 'Procedure'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * The specific procedure that is performed. Use text if the exact nature of the
     * procedure cannot be coded (e.g. "Laparoscopic Appendectomy").
     */
    code?: Maybe<ProcedureCodeResult>
    /**
     * The Encounter during which this Procedure was created or performed or to which
     * the creation of this record is tightly associated.
     */
    encounter?: Maybe<ProcedureEncounterResult>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * Business identifiers assigned to this procedure by the performer or other
     * systems which remain constant as the resource is updated and is propagated
     * from server to server.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** The location where the procedure actually happened.  E.g. a newborn at home, a tracheostomy at a restaurant. */
    location?: Maybe<ProcedureLocationResult>
    /**
     * Estimated or actual date, date-time, period, or age when the procedure was
     * performed.  Allows a period to support complex procedures that span more than
     * one date, and also allows for the length of the procedure to be captured.
     */
    performedPeriod?: Maybe<ProcedurePerformedPeriodResult>
    /** A code specifying the state of the procedure. Generally, this will be the in-progress or completed state. */
    status?: Maybe<Scalars['FhirCode']>
    /** The person, animal or group on which the procedure was performed. */
    subject?: Maybe<ProcedureSubjectResult>
  }

/**
 *  An action that is or was performed on or for a patient. This can be a physical
 * intervention like an operation, or less invasive like long term services,
 * counseling, or hypnotherapy.
 */
export type ProcedureIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * The specific procedure that is performed. Use text if the exact nature of the
 * procedure cannot be coded (e.g. "Laparoscopic Appendectomy").
 */
export type ProcedureCodeResult = CodeableConcept | None

/**
 * A page/slice of a list of Procedure nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ProcedureConnection = Connection & {
  __typename?: 'ProcedureConnection'
  /** Edges in the current connection page. */
  edges: Array<ProcedureEdge>
  /** List of Procedure object nodes in the current connection page. */
  nodes: Array<Maybe<Procedure>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Procedure connection page. */
export type ProcedureEdge = Edge & {
  __typename?: 'ProcedureEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Procedure object in this edge. */
  node?: Maybe<Procedure>
}

/**
 * The Encounter during which this Procedure was created or performed or to which
 * the creation of this record is tightly associated.
 */
export type ProcedureEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  Specifies a filter to apply to a list of Procedure  */
export type ProcedureFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<ProcedureFilter>>
  /**
   * Filters on Procedure.code
   * A code to identify a  procedure
   */
  code?: Maybe<CodeFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on Procedure.performed
   * When the procedure was performed
   */
  date?: Maybe<DateFilter>
  /**
   * Filters on Procedure.encounter
   * Encounter created as part of
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Procedure.identifier
   * A unique identifier for a procedure
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on Procedure.location
   * Where the procedure happened
   */
  locationUri?: Maybe<ResourceUriFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<ProcedureFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<ProcedureFilter>>
  /**
   * Filters on Procedure.subject.where(resolve() is Patient)
   * Search by subject - a patient
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on Procedure.status
   * preparation | in-progress | not-done | on-hold | stopped | completed | entered-in-error | unknown
   */
  status?: Maybe<EventStatusFilter>
  /**
   * Filters on Procedure.subject
   * Search by subject
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** The location where the procedure actually happened.  E.g. a newborn at home, a tracheostomy at a restaurant. */
export type ProcedureLocationResult =
  | ExternalResource
  | Location
  | None
  | NonFederatedResource

/**
 * Estimated or actual date, date-time, period, or age when the procedure was
 * performed.  Allows a period to support complex procedures that span more than
 * one date, and also allows for the length of the procedure to be captured.
 */
export type ProcedurePerformedPeriodResult = None | Period

export type ProcedureResult =
  | AuthorizationError
  | Procedure
  | ResourceNotFoundError

export type ProceduresByIdentifierResult =
  | AuthorizationError
  | ProcedureConnection

/**  A sort on Procedure - consists of the field to sort on and the ordering  */
export type ProcedureSort = {
  /**  Which field to sort by  */
  field: ProcedureSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that Procedure can be sorted by  */
export enum ProcedureSortOption {
  /**
   * Sorts on Procedure.performed
   * When the procedure was performed
   */
  Date = 'DATE',
  /**  Do not sort  */
  None = 'NONE',
}

/** The person, animal or group on which the procedure was performed. */
export type ProcedureSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**
 * Physical address of an entity
 *
 * see: https://www.hl7.org/fhir/datatypes.html#address
 */
export type ProfileAddress = {
  __typename?: 'ProfileAddress'
  /** Name of city, town etc. */
  city?: Maybe<Scalars['String']>
  /** District name (aka county) */
  district?: Maybe<Scalars['String']>
  /**
   * Street name, number, direction & P.O. Box etc.
   * This repeating element order: The order in which lines should appear in an address label
   */
  line1?: Maybe<Scalars['String']>
  /**
   * Street name, number, direction & P.O. Box etc.
   * This repeating element order: The order in which lines should appear in an address label
   */
  line2?: Maybe<Scalars['String']>
  /**
   * Street name, number, direction & P.O. Box etc.
   * This repeating element order: The order in which lines should appear in an address label
   */
  line3?: Maybe<Scalars['String']>
  /**
   * Street name, number, direction & P.O. Box etc.
   * This repeating element order: The order in which lines should appear in an address label
   */
  line4?: Maybe<Scalars['String']>
  /**
   * Street name, number, direction & P.O. Box etc.
   * This repeating element order: The order in which lines should appear in an address label
   */
  line5?: Maybe<Scalars['String']>
  /**
   * Post/Zip Code
   *
   * Note: Had to make this a String, as values like `WE24RSB` will throw error
   */
  postalCode?: Maybe<Scalars['String']>
  /** Sub-unit of country (abbreviations ok) */
  state?: Maybe<Scalars['String']>
  /**
   * Postal | Physical | Both
   *
   * see: https://www.hl7.org/fhir/valueset-address-type.html
   */
  type: ProfileAddressType
  /**
   * Purpose of this address such as Home | Work | billing
   *
   * see: https://www.hl7.org/fhir/valueset-address-use.html
   */
  use: ProfileAddressUse
}

/**
 * Virtual/Physical location that an address references
 *
 * see: https://www.hl7.org/fhir/valueset-address-use.html
 */
export enum ProfileAddressType {
  /**  Both.  */
  Both = 'BOTH',
  /**  Physical.  */
  Physical = 'PHYSICAL',
  /**  Postal.  */
  Postal = 'POSTAL',
}

/**
 * Address contact usage
 *
 * see: https://www.hl7.org/fhir/valueset-address-type.html
 */
export enum ProfileAddressUse {
  /**  An address to be used to send bills, invoices, receipts etc.  */
  Billing = 'BILLING',
  /**  A communication address at a home.  */
  Home = 'HOME',
  /**  Other.  */
  Other = 'OTHER',
  /**  A temporary address. The period can provide more detailed information.  */
  Temp = 'TEMP',
  /**  An office address. First choice for business related contacts during business hours.  */
  Work = 'WORK',
}

/**
 * Details of a Technology mediated contact point (phone, fax, email, etc.)
 *
 * see: Details for all kinds of technology mediated contact points for a person or
 * organization, including telephone, email, etc.
 */
export type ProfileContactPoint = {
  __typename?: 'ProfileContactPoint'
  /**
   * Specifies a preferred order in which to use a set of contacts. ContactPoints
   * with lower rank values are more preferred than those with higher rank values.
   *
   * see: http://www.hl7.org/fhir/datatypes-definitions.html#ContactPoint.rank
   */
  rank?: Maybe<Scalars['NonNegativeInt']>
  /**
   * Telecommunications form for contact point - what communications system is required to make use of the contact.
   *
   * see: http://www.hl7.org/fhir/datatypes-definitions.html#ContactPoint.system
   */
  system: ProfileContactSystem
  /**
   * Identifies the purpose for the contact point.
   *
   * see: http://www.hl7.org/fhir/datatypes-definitions.html#ContactPoint.use
   */
  use: ProfileContactUse
  /**
   * The actual contact point details, in a form that is meaningful to the
   * designated communication system (i.e. phone number or email address).
   *
   * see: http://www.hl7.org/fhir/datatypes-definitions.html#ContactPoint.value
   */
  value: Scalars['String']
}

/**
 * Telecommunications form for contact point.
 * What communications system is required to make use of the contact.
 *
 * see: http://www.hl7.org/fhir/valueset-contact-point-system.html
 */
export enum ProfileContactSystem {
  /**  Email.  */
  Email = 'EMAIL',
  /**  Fax.  */
  Fax = 'FAX',
  /**  Other.  */
  Other = 'OTHER',
  /**  Pager.  */
  Pager = 'PAGER',
  /**  Phone.  */
  Phone = 'PHONE',
  /**  SMS.  */
  Sms = 'SMS',
  /**  URL.  */
  Url = 'URL',
}

/**
 * Use of contact point.
 *
 * see: http://www.hl7.org/fhir/valueset-contact-point-use.html
 */
export enum ProfileContactUse {
  /**  Home.  */
  Home = 'HOME',
  /**  Mobile.  */
  Mobile = 'MOBILE',
  /**  Old.  */
  Old = 'OLD',
  /**  Temp.  */
  Temp = 'TEMP',
  /**  Work.  */
  Work = 'WORK',
}

/**  Used to filter on profiles */
export type ProfileFilter = {
  /**  The field must match at least one of the given options */
  ONE_OF_STRING?: Maybe<Array<Scalars['String']>>
}

/**
 * HumanName - Name of a human - parts and usage
 *
 * see: http://www.hl7.org/fhir/datatypes.html#HumanName
 */
export type ProfileHumanName = {
  __typename?: 'ProfileHumanName'
  /**
   * The part of a name that links to the genealogy.
   *
   * see: http://www.hl7.org/fhir/datatypes-definitions.html#HumanName.family
   */
  family: Scalars['String']
  /**
   * Given name
   *
   * see: http://www.hl7.org/fhir/datatypes-definitions.html#HumanName.given
   */
  given: Scalars['String']
  /**
   * Part of the name that is acquired as a title due to
   * academic, legal, employment or nobility status, etc. and that appears at the start of the name
   *
   * see: http://www.hl7.org/fhir/datatypes-definitions.html#HumanName.prefix
   */
  prefix?: Maybe<Scalars['String']>
  /**
   * Part of the name that is acquired as a title due to
   * academic, legal, employment or nobility status, etc. and that appears at the end of the name.
   *
   * see: http://www.hl7.org/fhir/datatypes-definitions.html#HumanName.suffix
   */
  suffix?: Maybe<Scalars['String']>
  /**
   * Text representation of the full name
   * Specifies the entire name as it should be displayed e.g. on an application UI.
   * This may be provided instead of or as well as the specific parts.
   *
   * see: http://www.hl7.org/fhir/datatypes-definitions.html#HumanName.text
   */
  text: Scalars['String']
  /**
   * Identifies the purpose for this name.
   *
   * http://www.hl7.org/fhir/datatypes-definitions.html#HumanName.use
   */
  use: ProfileHumanNameUse
}

/**
 * Identifies the purpose for this name.
 *
 * see: http://www.hl7.org/fhir/datatypes-definitions.html#HumanName.use
 */
export enum ProfileHumanNameUse {
  /**  Anonymous.  */
  Anonymous = 'ANONYMOUS',
  /**  Maiden.  */
  Maiden = 'MAIDEN',
  /**  Nickname.  */
  Nickname = 'NICKNAME',
  /**  Official.  */
  Official = 'OFFICIAL',
  /**  Old.  */
  Old = 'OLD',
  /**  Temp.  */
  Temp = 'TEMP',
  /**  Usual.  */
  Usual = 'USUAL',
}

/**  Used to filter the provenanceEnterer field */
export type ProvenanceEntererFilter = {
  /**  The field must match at least one of the given options */
  ONE_OF?: Maybe<Array<Scalars['String']>>
}

/**  The lifecycle status of an artifact.  */
export enum PublicationStatus {
  /**  This resource is ready for normal use.  */
  Active = 'ACTIVE',
  /**  This resource is still under development and is not yet considered to be ready for normal use.  */
  Draft = 'DRAFT',
  /**  This resource has been withdrawn or superseded and should no longer be used.  */
  Retired = 'RETIRED',
  /**
   *  The authoring system does not know which of the status values currently
   * applies for this resource.  Note: This concept is not to be used for "other" -
   * one of the listed statuses is presumed to apply, it's just not known which one.
   */
  Unknown = 'UNKNOWN',
}

/**  The lifecycle status of an artifact.  */
export type PublicationStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<PublicationStatus>>>
}

export type PublicHealthcareAdmission = {
  __typename?: 'PublicHealthcareAdmission'
  id?: Maybe<Scalars['NonNegativeInt']>
  /** Has the public healthcare location been checked */
  locationChecked?: Maybe<Scalars['Boolean']>
  /** Trace status of 'pds' */
  pdsTraceStatus?: Maybe<Scalars['String']>
  /** Has the verification been overwritten by Ops */
  verificationOverwrittenByOps?: Maybe<Scalars['Boolean']>
}

/**  A measured amount (or an amount that can potentially be measured). */
export type Quantity = {
  __typename?: 'Quantity'
  /**  A computer processable form of the unit in some unit representation system. */
  code?: Maybe<Scalars['String']>
  /**  How the value should be understood and represented. Can be <, <=, >= or >. Considered "=" if there's no comparator. */
  comparator?: Maybe<Scalars['FhirCode']>
  /**  The identification of the system that provides the coded form of the unit. */
  system?: Maybe<Scalars['String']>
  /**  A human-readable form of the unit. */
  unit?: Maybe<Scalars['String']>
  /**  The value of the measured amount. The value includes an implicit precision in the presentation of the value. */
  value?: Maybe<Scalars['Float']>
}

/** Root query type. */
export type Query = {
  __typename?: 'Query'
  /** # Retrieve a single Conversation */
  _chat_conversation: Conversation
  /** # Retrieve Conversation Attachments */
  _chat_conversationAttachment: ConversationAttachment
  /** # Retrieve Conversation Content */
  _chat_conversationContent: ConversationContent
  /** # List all the Conversations */
  _chat_conversations: ConversationConnection
  /** # List all Input Suggestions */
  _chat_conversationSuggestions: Array<ConversationSuggestion>
  /** Gets address segments from an ID */
  _firstmile_getAddressDetails?: Maybe<AddressDetail>
  /** Returns addresses */
  _firstmile_getAddressPredictions?: Maybe<AddressPrediction>
  /**
   * Dummy field needed because subgraph schemas must define either `Query` or `Mutation`, but this subgraph defines neither.
   * @deprecated To avoid empty Query, needed by @graphql-codegen/schema-ast
   */
  _identity_dummyField?: Maybe<Scalars['Int']>
  /** Dummy field needed because the machinery types use for linting mean that subgraph schemas must define `Query`. */
  _inbox_dummyField?: Maybe<Scalars['Int']>
  /** Dummy field needed because subgraph schemas must define either `Query` or `Mutation`, but this subgraph defines neither. */
  _memberrelationships_dummyField?: Maybe<Scalars['Int']>
  /**
   * Dummy field needed because subgraph schemas must define either `Query` or `Mutation`, but this subgraph defines neither.
   * @deprecated To avoid empty Query, needed by @graphql-codegen/schema-ast
   */
  _memberships_dummyField?: Maybe<Scalars['Int']>
  /** Query that powers the My Health experience within the Babylon app. */
  _myhealth_healthStatus: MyHealthHealthStatus
  /** Query to fetch the My Health metric config for all of the Lab Test metrics */
  _myhealth_labTestMetrics: Array<LabTestMetricConfig>
  /**
   * Gets a appointment by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use appointmentsByIdentifier instead.
   */
  appointment?: Maybe<AppointmentResult>
  /**
   * Return the list of appointments associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  appointmentsByIdentifier?: Maybe<AppointmentsByIdentifierResult>
  /**
   * View practitioner availability slots over a given period.
   *
   * This will return the available slots associated with different practitioners in the stipulated period.
   */
  availabilitySlots: AvailabilitySlotsResult
  /**
   * Fetches all Babylon Identifiers that are associated with one of the given external identifiers.
   * This only works for identifiers added via the id-resolver, NOT for those on standard dFhir events.
   *
   * The primary purpose of this endpoint is to query for Babylon identifiers of resources that are NOT stored in the
   * Healthgraph. If they are stored in the Healthgraph then {resource}sByIdentifier would be a better query.
   */
  babylonIdentifiersFromExternalIdentifiers?: Maybe<BabylonIdentifiersFromExternalIdentifiersResult>
  /**
   * Gets a carePlan by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use carePlansByIdentifier instead.
   */
  carePlan?: Maybe<CarePlanResult>
  /**
   * Return the list of carePlans associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  carePlansByIdentifier?: Maybe<CarePlansByIdentifierResult>
  /**
   * Gets a careTeam by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use careTeamsByIdentifier instead.
   */
  careTeam?: Maybe<CareTeamResult>
  /**
   * Return the list of careTeams associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  careTeamsByIdentifier?: Maybe<CareTeamsByIdentifierResult>
  /** Check registration details for a patient. */
  checkPatientRegistrationDetails?: Maybe<CheckPatientRegistrationDetailsResult>
  /**
   * Gets a claim by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use claimsByIdentifier instead.
   */
  claim?: Maybe<ClaimResult>
  /**
   * Return the list of claims associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  claimsByIdentifier?: Maybe<ClaimsByIdentifierResult>
  /**
   * Gets a clinicalImpression by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use clinicalImpressionsByIdentifier instead.
   */
  clinicalImpression?: Maybe<ClinicalImpressionResult>
  /**
   * Return the list of clinicalImpressions associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  clinicalImpressionsByIdentifier?: Maybe<ClinicalImpressionsByIdentifierResult>
  /** Test the creation of codeable-concepts without having to implement or modify a subgraph. */
  codeableConcept?: Maybe<CodeableConcept>
  /**
   * Gets a cohortMember by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use cohortMembersByIdentifier instead.
   */
  cohortMember?: Maybe<CohortMemberResult>
  /**
   * Return the list of cohortMembers associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  cohortMembersByIdentifier?: Maybe<CohortMembersByIdentifierResult>
  /**
   * Gets a communication by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use communicationsByIdentifier instead.
   */
  communication?: Maybe<CommunicationResult>
  /**
   * Gets a communicationRequest by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use communicationRequestsByIdentifier instead.
   */
  communicationRequest?: Maybe<CommunicationRequestResult>
  /**
   * Return the list of communicationRequests associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  communicationRequestsByIdentifier?: Maybe<CommunicationRequestsByIdentifierResult>
  /**
   * Return the list of communications associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  communicationsByIdentifier?: Maybe<CommunicationsByIdentifierResult>
  /**
   * Gets a composition by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use compositionsByIdentifier instead.
   */
  composition?: Maybe<CompositionResult>
  /**
   * Return the list of compositions associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  compositionsByIdentifier?: Maybe<CompositionsByIdentifierResult>
  /**
   * Gets a condition by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use conditionsByIdentifier instead.
   */
  condition?: Maybe<ConditionResult>
  /**
   * Return the list of conditions associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  conditionsByIdentifier?: Maybe<ConditionsByIdentifierResult>
  /** Get details of a given conversation. */
  conversation: ChatConversationResult
  /** Fetch all Core Ruby Feature Switches */
  coreRubyFeatureSwitches?: Maybe<CoreRubyFeatureSwitchConnection>
  /** Fetch all regions from core-ruby. */
  coreRubyRegions?: Maybe<CoreRubyRegionConnection>
  /**
   * Gets a coverage by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use coveragesByIdentifier instead.
   */
  coverage?: Maybe<CoverageResult>
  /**  Search for coverages meeting criteria  */
  coverages?: Maybe<CoveragesResult>
  /**
   * Return the list of coverages associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  coveragesByIdentifier?: Maybe<CoveragesByIdentifierResult>
  /** Information about the current session of the User */
  currentUserSession?: Maybe<UserSession>
  /**
   * Gets a device by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use devicesByIdentifier instead.
   */
  device?: Maybe<DeviceResult>
  /**
   * Return the list of devices associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  devicesByIdentifier?: Maybe<DevicesByIdentifierResult>
  /**
   * Gets a diagnosticReport by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use diagnosticReportsByIdentifier instead.
   */
  diagnosticReport?: Maybe<DiagnosticReportResult>
  /**
   * Return the list of diagnosticReports associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  diagnosticReportsByIdentifier?: Maybe<DiagnosticReportsByIdentifierResult>
  /**
   * Gets a documentReference by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use documentReferencesByIdentifier instead.
   */
  documentReference?: Maybe<DocumentReferenceResult>
  /**
   * Return the list of documentReferences associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  documentReferencesByIdentifier?: Maybe<DocumentReferencesByIdentifierResult>
  /**
   * Gets a encounter by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use encountersByIdentifier instead.
   */
  encounter?: Maybe<EncounterResult>
  /**
   * Return the list of encounters associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  encountersByIdentifier?: Maybe<EncountersByIdentifierResult>
  /** Get all consent decisions for the given user and configuration. */
  fetchConsents?: Maybe<Array<Consent>>
  /** Gets details to match a patient for registration. */
  findPatientRegistrationMatch: FindPatientRegistrationMatchResult
  /**
   * Lists the available genders configured within the system
   *
   * Authentication: not required
   * @deprecated Do not use this query as the `User` type will be repurposed in the future (see ticket API-252). Use the `patient` query to get patient-related information.
   */
  genders: Array<Gender>
  /**
   * Gets a goal by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use goalsByIdentifier instead.
   */
  goal?: Maybe<GoalResult>
  /**
   * Return the list of goals associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  goalsByIdentifier?: Maybe<GoalsByIdentifierResult>
  /** Fetch all GP Organizations from core-ruby. */
  gpOrganizations?: Maybe<GpOrganizationConnection>
  /**
   * Gets a group by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use groupsByIdentifier instead.
   */
  group?: Maybe<GroupResult>
  /**
   * Return the list of groups associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  groupsByIdentifier?: Maybe<GroupsByIdentifierResult>
  /**
   * Gets a healthcareService by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use healthcareServicesByIdentifier instead.
   */
  healthcareService?: Maybe<HealthcareServiceResult>
  /**  Search for healthcareServices meeting criteria  */
  healthcareServices?: Maybe<HealthcareServicesResult>
  /**
   * Return the list of healthcareServices associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  healthcareServicesByIdentifier?: Maybe<HealthcareServicesByIdentifierResult>
  /**
   * Gets a insurancePlan by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use insurancePlansByIdentifier instead.
   */
  insurancePlan?: Maybe<InsurancePlanResult>
  /**  Search for insurancePlans meeting criteria  */
  insurancePlans?: Maybe<InsurancePlansResult>
  /**
   * Return the list of insurancePlans associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  insurancePlansByIdentifier?: Maybe<InsurancePlansByIdentifierResult>
  /** Verifies if an IAM user exists with the given email */
  isIamUserExisting: Scalars['Boolean']
  /** Developer convenience field for string localization testing in dev */
  localizedString?: Maybe<LocalizedString>
  /**
   * Gets a location by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use locationsByIdentifier instead.
   */
  location?: Maybe<LocationResult>
  /**  Search for locations meeting criteria  */
  locations?: Maybe<LocationsResult>
  /**
   * Return the list of locations associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  locationsByIdentifier?: Maybe<LocationsByIdentifierResult>
  /**
   * Gets a measure by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use measuresByIdentifier instead.
   */
  measure?: Maybe<MeasureResult>
  /**
   * Gets a measureReport by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use measureReportsByIdentifier instead.
   */
  measureReport?: Maybe<MeasureReportResult>
  /**
   * Return the list of measureReports associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  measureReportsByIdentifier?: Maybe<MeasureReportsByIdentifierResult>
  /**
   * Return the list of measures associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  measuresByIdentifier?: Maybe<MeasuresByIdentifierResult>
  /**
   * Gets a media by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use mediasByIdentifier instead.
   */
  media?: Maybe<MediaResult>
  /**
   * Return the list of medias associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  mediasByIdentifier?: Maybe<MediasByIdentifierResult>
  /**
   * Gets a medicationDispense by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use medicationDispensesByIdentifier instead.
   */
  medicationDispense?: Maybe<MedicationDispenseResult>
  /**
   * Return the list of medicationDispenses associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  medicationDispensesByIdentifier?: Maybe<MedicationDispensesByIdentifierResult>
  /**
   * Gets a medicationRequest by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use medicationRequestsByIdentifier instead.
   */
  medicationRequest?: Maybe<MedicationRequestResult>
  /**
   * Return the list of medicationRequests associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  medicationRequestsByIdentifier?: Maybe<MedicationRequestsByIdentifierResult>
  /**
   * Gets a medicationStatement by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use medicationStatementsByIdentifier instead.
   */
  medicationStatement?: Maybe<MedicationStatementResult>
  /**
   * Return the list of medicationStatements associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  medicationStatementsByIdentifier?: Maybe<MedicationStatementsByIdentifierResult>
  /** Gets members by a particular search criteria. */
  members: MembersSearchResponse
  /**
   * Gets a observation by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use observationsByIdentifier instead.
   */
  observation?: Maybe<ObservationResult>
  /**
   * Return the list of observations associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  observationsByIdentifier?: Maybe<ObservationsByIdentifierResult>
  /**
   * Gets a organization by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use organizationsByIdentifier instead.
   */
  organization?: Maybe<OrganizationResult>
  /**  Search for organizations meeting criteria  */
  organizations?: Maybe<OrganizationsResult>
  /**
   * Return the list of organizations associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  organizationsByIdentifier?: Maybe<OrganizationsByIdentifierResult>
  /** Gets details of a given patient. */
  patient: PatientResult
  /**  Search for patients meeting criteria  */
  patients?: Maybe<PatientsResult>
  /**
   * Return the list of patients associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  patientsByIdentifier?: Maybe<PatientsByIdentifierResult>
  /** Payment method details given a payment method id */
  paymentMethod: PaymentMethodResult
  /** Find pharmacy by id. */
  pharmacyOrganization?: Maybe<PharmacyOrganizationResult>
  /** Find pharmacies meeting given criteria. */
  pharmacyOrganizations?: Maybe<PharmacyOrganizationConnection>
  /** Fetch all portal roles from core-ruby. */
  portalRoles?: Maybe<PortalRoleConnection>
  /**
   * Gets a practitioner by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use practitionersByIdentifier instead.
   */
  practitioner?: Maybe<PractitionerResult>
  /**
   * Gets a practitionerRole by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use practitionerRolesByIdentifier instead.
   */
  practitionerRole?: Maybe<PractitionerRoleResult>
  /**  Search for practitionerRoles meeting criteria  */
  practitionerRoles?: Maybe<PractitionerRolesResult>
  /**
   * Return the list of practitionerRoles associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  practitionerRolesByIdentifier?: Maybe<PractitionerRolesByIdentifierResult>
  /**  Search for practitioners meeting criteria  */
  practitioners?: Maybe<PractitionersResult>
  /**
   * Return the list of practitioners associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  practitionersByIdentifier?: Maybe<PractitionersByIdentifierResult>
  /**
   * Gets a procedure by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use proceduresByIdentifier instead.
   */
  procedure?: Maybe<ProcedureResult>
  /**
   * Return the list of procedures associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  proceduresByIdentifier?: Maybe<ProceduresByIdentifierResult>
  /**
   * Gets details of a given patient.
   * @deprecated Do not use this query as the `User` type will be repurposed in the future (see ticket API-252). Use the `patient` query to get patient-related information.
   */
  profile: User
  /**
   * Gets a questionnaire by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use questionnairesByIdentifier instead.
   */
  questionnaire?: Maybe<QuestionnaireResult>
  /**
   * Gets a questionnaireResponse by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use questionnaireResponsesByIdentifier instead.
   */
  questionnaireResponse?: Maybe<QuestionnaireResponseResult>
  /**
   * Return the list of questionnaireResponses associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  questionnaireResponsesByIdentifier?: Maybe<QuestionnaireResponsesByIdentifierResult>
  /**
   * Return the list of questionnaires associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  questionnairesByIdentifier?: Maybe<QuestionnairesByIdentifierResult>
  /**
   * Gets a relatedPerson by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use relatedPersonsByIdentifier instead.
   */
  relatedPerson?: Maybe<HealthgraphRelatedPersonResult>
  /**
   * Return the list of relatedPersons associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  relatedPersonsByIdentifier?: Maybe<HealthgraphRelatedPersonsByIdentifierResult>
  /**
   * Gets a requestGroup by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use requestGroupsByIdentifier instead.
   */
  requestGroup?: Maybe<RequestGroupResult>
  /**
   * Return the list of requestGroups associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  requestGroupsByIdentifier?: Maybe<RequestGroupsByIdentifierResult>
  /**
   * Gets a riskAssessment by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use riskAssessmentsByIdentifier instead.
   */
  riskAssessment?: Maybe<RiskAssessmentResult>
  /**
   * Return the list of riskAssessments associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  riskAssessmentsByIdentifier?: Maybe<RiskAssessmentsByIdentifierResult>
  /**
   * Gets a serviceRequest by id.
   * This should be the Babylon ID (this is the form of id returned when fetching the 'id' field).
   *
   * Alternatively, you can pass the resource uri if known.
   *
   * If you do not have either of these, you may want to use serviceRequestsByIdentifier instead.
   */
  serviceRequest?: Maybe<ServiceRequestResult>
  /**
   * Return the list of serviceRequests associated with the given ID.
   * Although this field returns a list, that list will typically contain zero or one items only.
   */
  serviceRequestsByIdentifier?: Maybe<ServiceRequestsByIdentifierResult>
  /** Fetch all Supply Networks */
  supplyNetworks?: Maybe<SupplyNetworkConnection>
  /** Fetch session information for a specific User (meant for Admin usage) */
  user?: Maybe<UserResult>
  /** The complete list of user challenges available within the system. */
  userChallengeTemplates: UserChallengeTemplateConnection
  /** The complete list of user goals available within the system. */
  userGoalTemplates: UserGoalTemplateConnection
  /** The complete list of user lifestyle category templates available within the system. */
  userLifestyleCategoryTemplates: LifestyleCategoryTemplateConnection
}

/** Root query type. */
export type Query_Chat_ConversationArgs = {
  id: Scalars['String']
}

/** Root query type. */
export type Query_Chat_ConversationAttachmentArgs = {
  attachmentId: Scalars['String']
  conversationId: Scalars['ID']
}

/** Root query type. */
export type Query_Chat_ConversationContentArgs = {
  contentId: Scalars['String']
  conversationId: Scalars['ID']
}

/** Root query type. */
export type Query_Chat_ConversationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
  outcome?: Maybe<ConversationOutcome>
  status?: Maybe<ConversationStatus>
  type?: Maybe<Scalars['String']>
}

/** Root query type. */
export type Query_Chat_ConversationSuggestionsArgs = {
  conversationId: Scalars['ID']
  query: Scalars['String']
}

/** Root query type. */
export type Query_Firstmile_GetAddressDetailsArgs = {
  placeId: Scalars['ID']
}

/** Root query type. */
export type Query_Firstmile_GetAddressPredictionsArgs = {
  addressString: Scalars['String']
  countryCode?: Maybe<Scalars['CountryCode']>
  sessiontoken?: Maybe<Scalars['ID']>
}

/** Root query type. */
export type QueryAppointmentArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryAppointmentsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryAvailabilitySlotsArgs = {
  after?: Maybe<Scalars['Cursor']>
  filter?: Maybe<AvailabilitySlotsFilter>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryBabylonIdentifiersFromExternalIdentifiersArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryCarePlanArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryCarePlansByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryCareTeamArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryCareTeamsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryCheckPatientRegistrationDetailsArgs = {
  input: CheckPatientRegistrationDetailsInput
}

/** Root query type. */
export type QueryClaimArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryClaimsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryClinicalImpressionArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryClinicalImpressionsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryCodeableConceptArgs = {
  input: CodeableConceptInput
}

/** Root query type. */
export type QueryCohortMemberArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryCohortMembersByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryCommunicationArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryCommunicationRequestArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryCommunicationRequestsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryCommunicationsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryCompositionArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryCompositionsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryConditionArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryConditionsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryConversationArgs = {
  conversationId: Scalars['ID']
}

/** Root query type. */
export type QueryCoreRubyFeatureSwitchesArgs = {
  after?: Maybe<Scalars['Cursor']>
  filter?: Maybe<CoreRubyFeatureSwitchesFilter>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** Root query type. */
export type QueryCoreRubyRegionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryCoverageArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryCoveragesArgs = {
  _filter?: Maybe<CoverageFilter>
  _sort?: Maybe<Array<Maybe<CoverageSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryCoveragesByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryDeviceArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryDevicesByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryDiagnosticReportArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryDiagnosticReportsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryDocumentReferenceArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryDocumentReferencesByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryEncounterArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryEncountersByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryFetchConsentsArgs = {
  appName?: Maybe<Scalars['String']>
  contractId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  planId?: Maybe<Scalars['ID']>
}

/** Root query type. */
export type QueryFindPatientRegistrationMatchArgs = {
  input: FindPatientRegistrationMatchInput
}

/** Root query type. */
export type QueryGoalArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryGoalsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryGpOrganizationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  filter?: Maybe<GpOrganizationsFilter>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryGroupArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryGroupsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryHealthcareServiceArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryHealthcareServicesArgs = {
  _filter?: Maybe<HealthcareServiceFilter>
  _sort?: Maybe<Array<Maybe<HealthcareServiceSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryHealthcareServicesByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryInsurancePlanArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryInsurancePlansArgs = {
  _filter?: Maybe<InsurancePlanFilter>
  _sort?: Maybe<Array<Maybe<InsurancePlanSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryInsurancePlansByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryIsIamUserExistingArgs = {
  input: IsIamUserExistingInput
}

/** Root query type. */
export type QueryLocalizedStringArgs = {
  serverContext: LocalizedStringServerContext
}

/** Root query type. */
export type QueryLocationArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryLocationsArgs = {
  _filter?: Maybe<LocationFilter>
  _sort?: Maybe<Array<Maybe<LocationSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryLocationsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryMeasureArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryMeasureReportArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryMeasureReportsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryMeasuresByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryMediaArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryMediasByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryMedicationDispenseArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryMedicationDispensesByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryMedicationRequestArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryMedicationRequestsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryMedicationStatementArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryMedicationStatementsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryMembersArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
  last?: Maybe<Scalars['NonNegativeInt']>
  search: MembersSearch
}

/** Root query type. */
export type QueryObservationArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryObservationsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryOrganizationArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryOrganizationsArgs = {
  _filter?: Maybe<OrganizationFilter>
  _sort?: Maybe<Array<Maybe<OrganizationSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryOrganizationsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryPatientArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryPatientsArgs = {
  _filter?: Maybe<PatientFilter>
  _sort?: Maybe<Array<Maybe<PatientSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryPatientsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryPaymentMethodArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryPharmacyOrganizationArgs = {
  id?: Maybe<Scalars['ID']>
}

/** Root query type. */
export type QueryPharmacyOrganizationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  filter?: Maybe<PharmacyOrganizationsFilter>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryPortalRolesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryPractitionerArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryPractitionerRoleArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryPractitionerRolesArgs = {
  _filter?: Maybe<PractitionerRoleFilter>
  _sort?: Maybe<Array<Maybe<PractitionerRoleSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryPractitionerRolesByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryPractitionersArgs = {
  _filter?: Maybe<PractitionerFilter>
  _sort?: Maybe<Array<Maybe<PractitionerSort>>>
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryPractitionersByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryProcedureArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryProceduresByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryProfileArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryQuestionnaireArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryQuestionnaireResponseArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryQuestionnaireResponsesByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryQuestionnairesByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryRelatedPersonArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryRelatedPersonsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryRequestGroupArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryRequestGroupsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryRiskAssessmentArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryRiskAssessmentsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryServiceRequestArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryServiceRequestsByIdentifierArgs = {
  _filter: GetByIdentifierFilter
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QuerySupplyNetworksArgs = {
  after?: Maybe<Scalars['Cursor']>
  filter?: Maybe<SupplyNetworksFilter>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryUserArgs = {
  id: Scalars['ID']
}

/** Root query type. */
export type QueryUserChallengeTemplatesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryUserGoalTemplatesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Root query type. */
export type QueryUserLifestyleCategoryTemplatesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A structured set of questions intended to guide the collection of answers from
 * end-users. Questionnaires provide detailed control over order, presentation,
 * phraseology and grouping to allow coherent, consistent data collection.
 */
export type Questionnaire = HealthgraphResource &
  Resource & {
    __typename?: 'Questionnaire'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** An identifier for this question or group of questions in a particular terminology such as LOINC. */
    codes?: Maybe<CodingConnection>
    /** A free text natural language description of the questionnaire from a consumer's perspective. */
    description?: Maybe<Scalars['Markdown']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /**
     * A formal identifier that is used to identify this questionnaire when it is
     * represented in other formats, or referenced in a specification, model, design
     * or an instance.
     */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** A particular question, question grouping or display text that is part of the questionnaire. */
    items?: Maybe<QuestionnaireItemConnection>
    /**
     * A natural language name identifying the questionnaire. This name should be
     * usable as an identifier for the module by machine processing applications such
     * as code generation.
     */
    name?: Maybe<Scalars['String']>
    /** The name of the organization or individual that published the questionnaire. */
    publisher?: Maybe<Scalars['String']>
    /** The status of this questionnaire. Enables tracking the life-cycle of the content. */
    status?: Maybe<Scalars['FhirCode']>
    /** A short, descriptive, user-friendly title for the questionnaire. */
    title?: Maybe<Scalars['String']>
    /**
     * An absolute URI that is used to identify this questionnaire when it is
     * referenced in a specification, model, design or an instance; also called its
     * canonical identifier. This SHOULD be globally unique and SHOULD be a literal
     * address at which at which an authoritative instance of this questionnaire is
     * (or will be) published. This URL can be the target of a canonical reference.
     * It SHALL remain the same when the questionnaire is stored on different servers.
     */
    url?: Maybe<Scalars['Uri']>
  }

/**
 *  A structured set of questions intended to guide the collection of answers from
 * end-users. Questionnaires provide detailed control over order, presentation,
 * phraseology and grouping to allow coherent, consistent data collection.
 */
export type QuestionnaireCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A structured set of questions intended to guide the collection of answers from
 * end-users. Questionnaires provide detailed control over order, presentation,
 * phraseology and grouping to allow coherent, consistent data collection.
 */
export type QuestionnaireIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A structured set of questions intended to guide the collection of answers from
 * end-users. Questionnaires provide detailed control over order, presentation,
 * phraseology and grouping to allow coherent, consistent data collection.
 */
export type QuestionnaireItemsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of Questionnaire nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type QuestionnaireConnection = Connection & {
  __typename?: 'QuestionnaireConnection'
  /** Edges in the current connection page. */
  edges: Array<QuestionnaireEdge>
  /** List of Questionnaire object nodes in the current connection page. */
  nodes: Array<Maybe<Questionnaire>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Questionnaire connection page. */
export type QuestionnaireEdge = Edge & {
  __typename?: 'QuestionnaireEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Questionnaire object in this edge. */
  node?: Maybe<Questionnaire>
}

/**  A particular question, question grouping or display text that is part of the questionnaire.  */
export type QuestionnaireItem = {
  __typename?: 'QuestionnaireItem'
  /** One of the permitted answers for a "choice" or "open-choice" question. */
  answerOptions?: Maybe<QuestionnaireItemAnswerOptionConnection>
  /** Text, questions and other groups to be nested beneath a question or group. */
  items?: Maybe<QuestionnaireItemConnection>
  /**
   * An identifier that is unique within the Questionnaire allowing linkage to the
   * equivalent item in a QuestionnaireResponse resource.
   */
  linkId?: Maybe<Scalars['String']>
  /** The name of a section, the text of a question or text content for a display item. */
  text?: Maybe<Scalars['String']>
  /**
   * The type of questionnaire item this is - whether text for display, a grouping
   * of other items or a particular type of data to be captured (string, integer,
   * coded choice, etc.).
   */
  type?: Maybe<Scalars['FhirCode']>
}

/**  A particular question, question grouping or display text that is part of the questionnaire.  */
export type QuestionnaireItemAnswerOptionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A particular question, question grouping or display text that is part of the questionnaire.  */
export type QuestionnaireItemItemsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  One of the permitted answers for a "choice" or "open-choice" question.  */
export type QuestionnaireItemAnswerOption = {
  __typename?: 'QuestionnaireItemAnswerOption'
  /** A potential answer that's allowed as the answer to this question. */
  valueCoding?: Maybe<QuestionnaireItemAnswerOptionValueCodingResult>
  /** A potential answer that's allowed as the answer to this question. */
  valueDate?: Maybe<Scalars['FhirDate']>
  /** A potential answer that's allowed as the answer to this question. */
  valueInteger?: Maybe<Scalars['Int']>
  /** A potential answer that's allowed as the answer to this question. */
  valueReference?: Maybe<QuestionnaireItemAnswerOptionValueReferenceResult>
  /** A potential answer that's allowed as the answer to this question. */
  valueString?: Maybe<Scalars['String']>
  /** A potential answer that's allowed as the answer to this question. */
  valueTime?: Maybe<Scalars['FhirTime']>
}

/**
 * A page/slice of a list of QuestionnaireItemAnswerOption nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type QuestionnaireItemAnswerOptionConnection = Connection & {
  __typename?: 'QuestionnaireItemAnswerOptionConnection'
  /** Edges in the current connection page. */
  edges: Array<QuestionnaireItemAnswerOptionEdge>
  /** List of QuestionnaireItemAnswerOption object nodes in the current connection page. */
  nodes: Array<Maybe<QuestionnaireItemAnswerOption>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a QuestionnaireItemAnswerOption connection page. */
export type QuestionnaireItemAnswerOptionEdge = Edge & {
  __typename?: 'QuestionnaireItemAnswerOptionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the QuestionnaireItemAnswerOption object in this edge. */
  node?: Maybe<QuestionnaireItemAnswerOption>
}

/** A potential answer that's allowed as the answer to this question. */
export type QuestionnaireItemAnswerOptionValueCodingResult = Coding | None

/** A potential answer that's allowed as the answer to this question. */
export type QuestionnaireItemAnswerOptionValueReferenceResult =
  | Appointment
  | CarePlan
  | CareTeam
  | Claim
  | ClinicalImpression
  | Communication
  | CommunicationRequest
  | Composition
  | Condition
  | Coverage
  | Device
  | DiagnosticReport
  | DocumentReference
  | Encounter
  | ExternalResource
  | Goal
  | Group
  | HealthcareService
  | HealthgraphRelatedPerson
  | InsurancePlan
  | Location
  | Measure
  | MeasureReport
  | Media
  | MedicationDispense
  | MedicationRequest
  | MedicationStatement
  | None
  | NonFederatedResource
  | Observation
  | Organization
  | Patient
  | Practitioner
  | PractitionerRole
  | Procedure
  | Questionnaire
  | QuestionnaireResponse
  | RequestGroup
  | RiskAssessment
  | ServiceRequest

/**
 * A page/slice of a list of QuestionnaireItem nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type QuestionnaireItemConnection = Connection & {
  __typename?: 'QuestionnaireItemConnection'
  /** Edges in the current connection page. */
  edges: Array<QuestionnaireItemEdge>
  /** List of QuestionnaireItem object nodes in the current connection page. */
  nodes: Array<Maybe<QuestionnaireItem>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a QuestionnaireItem connection page. */
export type QuestionnaireItemEdge = Edge & {
  __typename?: 'QuestionnaireItemEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the QuestionnaireItem object in this edge. */
  node?: Maybe<QuestionnaireItem>
}

/**
 *  A structured set of questions and their answers. The questions are ordered and
 * grouped into coherent subsets, corresponding to the structure of the grouping of
 * the questionnaire being responded to.
 */
export type QuestionnaireResponse = HealthgraphResource &
  Resource & {
    __typename?: 'QuestionnaireResponse'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** A numerical score representing the result of a patient's responses to an assessment */
    assessmentScore?: Maybe<Scalars['Float']>
    /** Person who received the answers to the questions in the QuestionnaireResponse and recorded them in the system. */
    author?: Maybe<QuestionnaireResponseAuthorResult>
    /** The date and/or time that this set of answers were last changed. */
    authored?: Maybe<Scalars['FhirDateTime']>
    /**
     * The Encounter during which this questionnaire response was created or to which
     * the creation of this record is tightly associated.
     */
    encounter?: Maybe<QuestionnaireResponseEncounterResult>
    /** List of encounters that produced this Questionnare Response */
    encounters?: Maybe<QuestionnaireResponseEncountersResultConnection>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** A business identifier assigned to a particular completed (or partially completed) questionnaire. */
    identifier?: Maybe<QuestionnaireResponseIdentifierResult>
    /** A group or question item from the original questionnaire for which answers are provided. */
    items?: Maybe<QuestionnaireResponseItemConnection>
    /** The base language in which the resource is written. */
    language?: Maybe<Scalars['FhirCode']>
    /** The Questionnaire that defines and organizes the questions for which answers are being provided. */
    questionnaire?: Maybe<QuestionnaireResponseQuestionnaireResult>
    /** The person who answered the questions about the subject. */
    source?: Maybe<QuestionnaireResponseSourceResult>
    /** The position of the questionnaire response within its overall lifecycle. */
    status?: Maybe<Scalars['FhirCode']>
    /**
     * The subject of the questionnaire response.  This could be a patient,
     * organization, practitioner, device, etc.  This is who/what the answers apply
     * to, but is not necessarily the source of information.
     */
    subject?: Maybe<QuestionnaireResponseSubjectResult>
  }

/**
 *  A structured set of questions and their answers. The questions are ordered and
 * grouped into coherent subsets, corresponding to the structure of the grouping of
 * the questionnaire being responded to.
 */
export type QuestionnaireResponseEncountersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A structured set of questions and their answers. The questions are ordered and
 * grouped into coherent subsets, corresponding to the structure of the grouping of
 * the questionnaire being responded to.
 */
export type QuestionnaireResponseItemsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Person who received the answers to the questions in the QuestionnaireResponse and recorded them in the system. */
export type QuestionnaireResponseAuthorResult =
  | ExternalResource
  | HealthgraphRelatedPerson
  | None
  | NonFederatedResource
  | Patient
  | Practitioner

/**
 * A page/slice of a list of QuestionnaireResponse nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type QuestionnaireResponseConnection = Connection & {
  __typename?: 'QuestionnaireResponseConnection'
  /** Edges in the current connection page. */
  edges: Array<QuestionnaireResponseEdge>
  /** List of QuestionnaireResponse object nodes in the current connection page. */
  nodes: Array<Maybe<QuestionnaireResponse>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a QuestionnaireResponse connection page. */
export type QuestionnaireResponseEdge = Edge & {
  __typename?: 'QuestionnaireResponseEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the QuestionnaireResponse object in this edge. */
  node?: Maybe<QuestionnaireResponse>
}

/**
 * The Encounter during which this questionnaire response was created or to which
 * the creation of this record is tightly associated.
 */
export type QuestionnaireResponseEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/** List of encounters that produced this Questionnare Response */
export type QuestionnaireResponseEncountersResult =
  | Encounter
  | ExternalResource
  | NonFederatedResource

/**
 * A page/slice of a list of QuestionnaireResponseEncountersResult nodes, allowing
 * for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type QuestionnaireResponseEncountersResultConnection = Connection & {
  __typename?: 'QuestionnaireResponseEncountersResultConnection'
  /** Edges in the current connection page. */
  edges: Array<QuestionnaireResponseEncountersResultEdge>
  /** List of QuestionnaireResponseEncountersResult object nodes in the current connection page. */
  nodes: Array<Maybe<QuestionnaireResponseEncountersResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a QuestionnaireResponseEncountersResult connection page. */
export type QuestionnaireResponseEncountersResultEdge = Edge & {
  __typename?: 'QuestionnaireResponseEncountersResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the QuestionnaireResponseEncountersResult object in this edge. */
  node?: Maybe<QuestionnaireResponseEncountersResult>
}

/**  Specifies a filter to apply to a list of QuestionnaireResponse  */
export type QuestionnaireResponseFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<QuestionnaireResponseFilter>>
  /**
   * Filters on QuestionnaireResponse.authored
   * When the questionnaire response was last changed
   */
  authored?: Maybe<DateFilter>
  /**
   * Filters on QuestionnaireResponse.author
   * The author of the questionnaire response
   */
  authorUri?: Maybe<ResourceUriFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on QuestionnaireResponse.encounter
   * Encounter associated with the questionnaire response
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on QuestionnaireResponse.identifier
   * The unique identifier for the questionnaire response
   */
  identifier?: Maybe<IdentifierFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<QuestionnaireResponseFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<QuestionnaireResponseFilter>>
  /**
   * Filters on QuestionnaireResponse.subject.where(resolve() is Patient)
   * The patient that is the subject of the questionnaire response
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Filters on QuestionnaireResponse.questionnaire
   * The questionnaire the answers are provided for
   */
  questionnaireUri?: Maybe<ResourceUriFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on QuestionnaireResponse.source
   * The individual providing the information reflected in the questionnaire respose
   */
  sourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on QuestionnaireResponse.status
   * The status of the questionnaire response
   */
  status?: Maybe<QuestionnaireResponseStatusFilter>
  /**
   * Filters on QuestionnaireResponse.subject
   * The subject of the questionnaire response
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** A business identifier assigned to a particular completed (or partially completed) questionnaire. */
export type QuestionnaireResponseIdentifierResult = HealthgraphIdentifier | None

/**  A group or question item from the original questionnaire for which answers are provided.  */
export type QuestionnaireResponseItem = {
  __typename?: 'QuestionnaireResponseItem'
  /** The respondent's answer(s) to the question. */
  answers?: Maybe<QuestionnaireResponseItemAnswerConnection>
  /** DateTime the question was asked */
  asked?: Maybe<Scalars['FhirDateTime']>
  /** FHIR author */
  author?: Maybe<QuestionnaireResponseItemAuthorResult>
  /** DateTime the question was answered */
  authored?: Maybe<Scalars['FhirDateTime']>
  /** A reference to an [ElementDefinition](elementdefinition.html) that provides the details for the item. */
  definition?: Maybe<Scalars['Uri']>
  /** Whether or not this was the original answer or if it has been subsequently amended. */
  isAmended?: Maybe<Scalars['Boolean']>
  /** Questions or sub-groups nested beneath a question or group. */
  items?: Maybe<QuestionnaireResponseItemConnection>
  /** The item from the Questionnaire that corresponds to this item in the QuestionnaireResponse resource. */
  linkId?: Maybe<Scalars['String']>
  /** FHIR source */
  source?: Maybe<QuestionnaireResponseItemSourceResult>
  /** Text that is displayed above the contents of the group or as the text of the question being answered. */
  text?: Maybe<Scalars['String']>
}

/**  A group or question item from the original questionnaire for which answers are provided.  */
export type QuestionnaireResponseItemAnswersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A group or question item from the original questionnaire for which answers are provided.  */
export type QuestionnaireResponseItemItemsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  The respondent's answer(s) to the question.  */
export type QuestionnaireResponseItemAnswer = {
  __typename?: 'QuestionnaireResponseItemAnswer'
  /** The answer (or one of the answers) provided by the respondent to the question. */
  isValueBoolean?: Maybe<Scalars['Boolean']>
  /** The answer (or one of the answers) provided by the respondent to the question. */
  valueCoding?: Maybe<QuestionnaireResponseItemAnswerValueCodingResult>
  /** The answer (or one of the answers) provided by the respondent to the question. */
  valueString?: Maybe<Scalars['String']>
}

/**
 * A page/slice of a list of QuestionnaireResponseItemAnswer nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type QuestionnaireResponseItemAnswerConnection = Connection & {
  __typename?: 'QuestionnaireResponseItemAnswerConnection'
  /** Edges in the current connection page. */
  edges: Array<QuestionnaireResponseItemAnswerEdge>
  /** List of QuestionnaireResponseItemAnswer object nodes in the current connection page. */
  nodes: Array<Maybe<QuestionnaireResponseItemAnswer>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a QuestionnaireResponseItemAnswer connection page. */
export type QuestionnaireResponseItemAnswerEdge = Edge & {
  __typename?: 'QuestionnaireResponseItemAnswerEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the QuestionnaireResponseItemAnswer object in this edge. */
  node?: Maybe<QuestionnaireResponseItemAnswer>
}

/** The answer (or one of the answers) provided by the respondent to the question. */
export type QuestionnaireResponseItemAnswerValueCodingResult = Coding | None

/** FHIR author */
export type QuestionnaireResponseItemAuthorResult =
  | ExternalResource
  | HealthgraphRelatedPerson
  | None
  | NonFederatedResource
  | Patient
  | Practitioner

/**
 * A page/slice of a list of QuestionnaireResponseItem nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type QuestionnaireResponseItemConnection = Connection & {
  __typename?: 'QuestionnaireResponseItemConnection'
  /** Edges in the current connection page. */
  edges: Array<QuestionnaireResponseItemEdge>
  /** List of QuestionnaireResponseItem object nodes in the current connection page. */
  nodes: Array<Maybe<QuestionnaireResponseItem>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a QuestionnaireResponseItem connection page. */
export type QuestionnaireResponseItemEdge = Edge & {
  __typename?: 'QuestionnaireResponseItemEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the QuestionnaireResponseItem object in this edge. */
  node?: Maybe<QuestionnaireResponseItem>
}

/** FHIR source */
export type QuestionnaireResponseItemSourceResult =
  | ExternalResource
  | HealthgraphRelatedPerson
  | None
  | NonFederatedResource
  | Patient
  | Practitioner

/** The Questionnaire that defines and organizes the questions for which answers are being provided. */
export type QuestionnaireResponseQuestionnaireResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Questionnaire

export type QuestionnaireResponseResult =
  | AuthorizationError
  | QuestionnaireResponse
  | ResourceNotFoundError

export type QuestionnaireResponsesByIdentifierResult =
  | AuthorizationError
  | QuestionnaireResponseConnection

/**  A sort on QuestionnaireResponse - consists of the field to sort on and the ordering  */
export type QuestionnaireResponseSort = {
  /**  Which field to sort by  */
  field: QuestionnaireResponseSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that QuestionnaireResponse can be sorted by  */
export enum QuestionnaireResponseSortOption {
  /**
   * Sorts on QuestionnaireResponse.authored
   * When the questionnaire response was last changed
   */
  Authored = 'AUTHORED',
  /**  Do not sort  */
  None = 'NONE',
}

/** The person who answered the questions about the subject. */
export type QuestionnaireResponseSourceResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**  Lifecycle status of the questionnaire response.  */
export enum QuestionnaireResponseStatus {
  /**
   *  This QuestionnaireResponse has been filled out with answers, then marked as
   * complete, yet changes or additions have been made to it afterwards.
   */
  Amended = 'AMENDED',
  /**  This QuestionnaireResponse has been filled out with answers and the current content is regarded as definitive.  */
  Completed = 'COMPLETED',
  /**  This QuestionnaireResponse was entered in error and voided.  */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**
   *  This QuestionnaireResponse has been partially filled out with answers but
   * changes or additions are still expected to be made to it.
   */
  InProgress = 'IN_PROGRESS',
  /**
   *  This QuestionnaireResponse has been partially filled out with answers but has
   * been abandoned. It is unknown whether changes or additions are expected to be made to it.
   */
  Stopped = 'STOPPED',
}

/**  Lifecycle status of the questionnaire response.  */
export type QuestionnaireResponseStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<QuestionnaireResponseStatus>>>
}

/**
 * The subject of the questionnaire response.  This could be a patient,
 * organization, practitioner, device, etc.  This is who/what the answers apply to,
 * but is not necessarily the source of information.
 */
export type QuestionnaireResponseSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

export type QuestionnaireResult =
  | AuthorizationError
  | Questionnaire
  | ResourceNotFoundError

export type QuestionnairesByIdentifierResult =
  | AuthorizationError
  | QuestionnaireConnection

/**  A set of ordered Quantities defined by a low and high limit. */
export type Range = {
  __typename?: 'Range'
  /**
   * The high limit. The boundary is inclusive.
   * If the high element is missing, the high boundary is not known.
   */
  high?: Maybe<RangeHighResult>
  /**
   * The low limit. The boundary is inclusive.
   * If the low element is missing, the low boundary is not known.
   */
  low?: Maybe<RangeLowResult>
}

/**
 * The high limit. The boundary is inclusive.
 * If the high element is missing, the high boundary is not known.
 */
export type RangeHighResult = None | Quantity

/**
 * The low limit. The boundary is inclusive.
 * If the low element is missing, the low boundary is not known.
 */
export type RangeLowResult = None | Quantity

/**  A relationship of two Quantity values - expressed as a numerator and a denominator. */
export type Ratio = {
  __typename?: 'Ratio'
  /**  The value of the denominator. */
  denominator?: Maybe<RatioDenominatorResult>
  /**  The value of the numerator. */
  numerator?: Maybe<RatioNumeratorResult>
}

/**  The value of the denominator. */
export type RatioDenominatorResult = None | Quantity

/**  The value of the numerator. */
export type RatioNumeratorResult = None | Quantity

/** Input required to delete */
export type RecreateUserInput = {
  /** Email of the user */
  emailAddress: Scalars['EmailAddress']
  /** The Auth0 ID that uniquely identifies a User */
  id: Scalars['ID']
}

export type RecreateUserResult = None | ResourceNotFoundError

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats). */
export type RegisterPatientAddressInput = {
  /** Country (e.g. can be ISO 3166 2 or 3 letter code) */
  country?: Maybe<Scalars['CountryCode']>
  /** Street name, number, direction & P.O. Box etc. */
  line?: Maybe<Array<Scalars['String']>>
  /** Postal code for area */
  postalCode: Scalars['PostalCode']
  /** Sub-unit of country (abbreviations ok) */
  state?: Maybe<Scalars['String']>
}

/** Credential for authentication in a 3rd party system. */
export type RegisterPatientAvailableUserCredentialInput = {
  /** The token that will be used to make requests to the 3rd party system. */
  accessToken: Scalars['String']
  /** The audience of the credential. This is a well-known identifier of a set of APIs (e.g., `https://babylonhealth.com`). */
  audience: Scalars['Uri']
}

/** A name of a human broken into its constituent parts and usage information. */
export type RegisterPatientHumanNameInput = {
  /** Family name (often called 'Surname') */
  family: Scalars['String']
  /** Given names (not always 'first'). Includes middle names. */
  given: Array<Scalars['String']>
}

/** A numeric or alphanumeric string that is associated with a single object or entity within a given system. */
export type RegisterPatientIdentifierInput = {
  /**
   * The system which defines this identifier.
   *
   * A system does not need to be provided if the value is intended to be used for matching against pre-existing systems.
   */
  system?: Maybe<Scalars['Uri']>
  /** The raw value of the identifier. */
  value: Scalars['String']
}

/** Details to register a patient. */
export type RegisterPatientInput = {
  /** Accepted notices for the patient (if provided). */
  acceptedNotices?: Maybe<Array<RegisterPatientNoticeInput>>
  /** Credentials for impersonating the patient in 3rd party systems. */
  availableUserCredentials?: Maybe<
    Array<RegisterPatientAvailableUserCredentialInput>
  >
  /** The date on which the patient was born. */
  birthDate?: Maybe<Scalars['Date']>
  /** Administrative Gender of the patient. */
  gender?: Maybe<AdministrativeGender>
  /**
   * Identifiers for the patient (if provided).
   *
   * Additional identifiers can be added using the `addPatientIdentifier` mutation.
   */
  identifiers?: Maybe<Array<RegisterPatientIdentifierInput>>
  /** The managing organization for the patient (if provided). */
  managingOrganization?: Maybe<Scalars['ID']>
  /**
   * Password for the patient account (if provided).
   *
   * A password does not need to be provided if an external authentication provider
   * is being used, but must be provided in the case that the user credentials are
   * being stored by Babylon.
   */
  password?: Maybe<Scalars['String']>
  /**
   * The primary address for the patient (if provided).
   *
   * Additional addresses can be added using the `addPatientAddress` mutation.
   */
  primaryAddress?: Maybe<RegisterPatientAddressInput>
  /**
   * The primary email address for the patient (if provided).
   *
   * Note that email addresses are mandatory in some regions. Additional email
   * addresses can be added using the `addPatientEmailAddress` mutation.
   */
  primaryEmailAddress?: Maybe<Scalars['EmailAddress']>
  /**
   * The primary name for the patient (if provided).
   *
   * Additional names can be added using the `addPatientName` mutation.
   */
  primaryName?: Maybe<RegisterPatientHumanNameInput>
  /**
   * The primary phone number for the patient (if provided).
   *
   * Additional phone numbers can be added using the `addPatientPhoneNumber` mutation.
   */
  primaryPhoneNumber?: Maybe<Scalars['PhoneNumber']>
  /** The strategy to use for registering the patient (if provided). */
  registrationStrategy: Scalars['ID']
  /**
   * ID of a stub patient profile, if one exists.
   *
   * When registering a patient using a stub profile, the resulting patient record
   * will have the same ID as the stub profile. In this way, the profile ID will
   * become a proper patient ID as part of the registration process.
   */
  stubProfileId?: Maybe<Scalars['ID']>
  /** Authorisation token for a user's account (if provided). */
  token?: Maybe<Scalars['Jwt']>
}

/** Details of the notice. */
export type RegisterPatientNoticeInput = {
  id: Scalars['ID']
}

export type RegisterPatientResult =
  | EmailNoMatchError
  | EmailTakenError
  | InvalidPatientRegistrationRequest
  | Patient
  | UnrecoverableError

/** An address expressed using postal conventions (as opposed to GPS or other location definition formats). */
export type RegistrationRegisterPatientAndCreateGuardianRelationshipAddressInput = {
  /** Country (e.g. can be ISO 3166 2 or 3 letter code) */
  country?: Maybe<Scalars['CountryCode']>
  /** Street name, number, direction & P.O. Box etc. */
  line?: Maybe<Array<Scalars['String']>>
  /** Postal code for area */
  postalCode: Scalars['PostalCode']
  /** Sub-unit of country (abbreviations ok) */
  state?: Maybe<Scalars['String']>
}

/** A name of a human broken into its constituent parts and usage information. */
export type RegistrationRegisterPatientAndCreateGuardianRelationshipHumanNameInput = {
  /** Family name (often called 'Surname') */
  family: Scalars['String']
  /** Given names (not always 'first'). Includes middle names. */
  given: Array<Scalars['String']>
}

/** A numeric or alphanumeric string that is associated with a single object or entity within a given system. */
export type RegistrationRegisterPatientAndCreateGuardianRelationshipIdentifierInput = {
  /**
   * The system which defines this identifier.
   *
   * A system does not need to be provided if the value is intended to be used for matching against pre-existing systems.
   */
  system?: Maybe<Scalars['Uri']>
  /** The raw value of the identifier. */
  value: Scalars['String']
}

/** Details to register a patient and create a guardian relationship. */
export type RegistrationRegisterPatientAndCreateGuardianRelationshipInput = {
  /** Accepted notices for the patient (if provided). */
  acceptedNotices?: Maybe<
    Array<RegistrationRegisterPatientAndCreateGuardianRelationshipNoticeInput>
  >
  /** The date on which the patient was born. */
  birthDate?: Maybe<Scalars['Date']>
  /** ID of a dependent's stub profile */
  dependentStubProfileId?: Maybe<Scalars['ID']>
  /** Administrative Gender of the patient. */
  gender?: Maybe<AdministrativeGender>
  /**
   * Identifiers for the patient (if provided).
   *
   * Additional identifiers can be added using the `addPatientIdentifier` mutation.
   */
  identifiers?: Maybe<
    Array<RegistrationRegisterPatientAndCreateGuardianRelationshipIdentifierInput>
  >
  /** The managing organization for the patient (if provided). */
  managingOrganization?: Maybe<Scalars['ID']>
  /**
   * The primary address for the patient (if provided).
   *
   * Additional addresses can be added using the `addPatientAddress` mutation.
   */
  primaryAddress?: Maybe<RegistrationRegisterPatientAndCreateGuardianRelationshipAddressInput>
  /**
   * The primary email address for the patient (if provided).
   *
   * Note that email addresses are mandatory in some regions. Additional email
   * addresses can be added using the `addPatientEmailAddress` mutation.
   */
  primaryEmailAddress?: Maybe<Scalars['EmailAddress']>
  /**
   * The primary name for the patient (if provided).
   *
   * Additional names can be added using the `addPatientName` mutation.
   */
  primaryName?: Maybe<RegistrationRegisterPatientAndCreateGuardianRelationshipHumanNameInput>
  /**
   * The primary phone number for the patient (if provided).
   *
   * Additional phone numbers can be added using the `addPatientPhoneNumber` mutation.
   */
  primaryPhoneNumber?: Maybe<Scalars['PhoneNumber']>
  /** The Registration Code (validation key) used to identify the contract that this eligibility relates to */
  registrationCode?: Maybe<Scalars['String']>
  /** The strategy to use for registering the patient (if provided). */
  registrationStrategy: Scalars['ID']
  /** Persons related to the patient. */
  relatedPersons?: Maybe<
    Array<RegistrationRegisterPatientAndCreateGuardianRelationshipRelatedPersonInput>
  >
}

/** Details of the notice. */
export type RegistrationRegisterPatientAndCreateGuardianRelationshipNoticeInput = {
  id: Scalars['ID']
}

/** Person related to the patient. */
export type RegistrationRegisterPatientAndCreateGuardianRelationshipRelatedPersonInput = {
  /** ID of the person. */
  _registration_fromPatient: Scalars['ID']
  /** The relationship from the related person to the patient. */
  relationship: CodingInput
}

export type RegistrationRegisterPatientAndCreateGuardianRelationshipResult =
  | BirthDateNoMatchError
  | IdentifierNoMatchError
  | InvalidPatientRegistrationRequest
  | Patient
  | UnrecoverableError

/** https://www.hl7.org/fhir/relatedperson.html */
export type RelatedPerson = {
  __typename?: 'RelatedPerson'
  id: Scalars['ID']
  /** The patient who this RelatedPerson is related to */
  patient: Patient
  relationships?: Maybe<CodeableConceptConnection>
}

/** https://www.hl7.org/fhir/relatedperson.html */
export type RelatedPersonRelationshipsArgs = {
  after?: Maybe<Scalars['Cursor']>
  before?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
  last?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * A page/slice of a list of nodes of type `RelatedPerson`, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RelatedPersonConnection = Connection & {
  __typename?: 'RelatedPersonConnection'
  /** Edges in the current connection page. */
  edges: Array<RelatedPersonEdge>
  /** List of RelatedPerson object nodes in the current connection page. */
  nodes: Array<Maybe<RelatedPerson>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An edge in a connection page for a node of type `RelatedPerson`.
 *
 * See the [GraphQL Edges Specification](https://relay.dev/graphql/connections.htm#sec-Edges) for guidance on how to use.
 */
export type RelatedPersonEdge = Edge & {
  __typename?: 'RelatedPersonEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<RelatedPerson>
}

/** The patient this person is related to. */
export type RelatedPersonPatientResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/** Relationship to a given user. */
export type Relationship = {
  __typename?: 'Relationship'
  to: User
}

/**
 * A page/slice of a list of nodes of type `Relationship`, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RelationshipConnection = Connection & {
  __typename?: 'RelationshipConnection'
  /** Edges in the current connection page. */
  edges: Array<RelationshipEdge>
  /** List of Relationship object nodes in the current connection page. */
  nodes: Array<Maybe<Relationship>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An edge in a connection page for a node of type `Relationship`.
 *
 * See the [GraphQL Edges Specification](https://relay.dev/graphql/connections.htm#sec-Edges) for guidance on how to use.
 */
export type RelationshipEdge = Edge & {
  __typename?: 'RelationshipEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node?: Maybe<Relationship>
}

/**
 *  A group of related requests that can be used to capture intended activities that
 * have inter-dependencies such as "give this medication after that one".
 */
export type RequestGroup = HealthgraphResource &
  Resource & {
    __typename?: 'RequestGroup'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** The actions, if any, produced by the evaluation of the artifact. */
    actions?: Maybe<RequestGroupActionConnection>
    /** Provides a reference to the author of the request group. */
    author?: Maybe<RequestGroupAuthorResult>
    /** Indicates when the request group was created. */
    authoredOn?: Maybe<Scalars['FhirDateTime']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Allows a service to provide a unique, business identifier for the request. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /**
     * A URL referencing an externally defined protocol, guideline, orderset or other
     * definition that is adhered to in whole or in part by this request.
     */
    instantiatesUris?: Maybe<UriConnection>
    /**
     * Indicates the level of authority/intentionality associated with the request
     * and where the request fits into the workflow chain.
     */
    intent?: Maybe<Scalars['FhirCode']>
    /** Describes the reason for the request group in coded or textual form. */
    reasonCodes?: Maybe<CodeableConceptConnection>
    /** Indicates another resource whose existence justifies this request group. */
    reasonReferences?: Maybe<RequestGroupReasonReferenceResultConnection>
    /** Data that still need to be satisfied in the ontology */
    requestGroupDataRequirement?: Maybe<RequestGroupRequestGroupDataRequirementResult>
    /** The date of the status change */
    requestGroupLastStatusUpdate?: Maybe<Scalars['FhirDateTime']>
    /** The model version used to calculate a Request Group */
    requestGroupModelVersion?: Maybe<Scalars['String']>
    /** The current state of the request. For request groups, the status reflects the status of all the requests in the group. */
    status?: Maybe<Scalars['FhirCode']>
    /** The subject for which the request group was created. */
    subject?: Maybe<RequestGroupSubjectResult>
  }

/**
 *  A group of related requests that can be used to capture intended activities that
 * have inter-dependencies such as "give this medication after that one".
 */
export type RequestGroupActionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A group of related requests that can be used to capture intended activities that
 * have inter-dependencies such as "give this medication after that one".
 */
export type RequestGroupIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A group of related requests that can be used to capture intended activities that
 * have inter-dependencies such as "give this medication after that one".
 */
export type RequestGroupInstantiatesUrisArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A group of related requests that can be used to capture intended activities that
 * have inter-dependencies such as "give this medication after that one".
 */
export type RequestGroupReasonCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 *  A group of related requests that can be used to capture intended activities that
 * have inter-dependencies such as "give this medication after that one".
 */
export type RequestGroupReasonReferencesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  The actions, if any, produced by the evaluation of the artifact.  */
export type RequestGroupAction = {
  __typename?: 'RequestGroupAction'
  /**
   * A code that provides meaning for the action or action group. For example, a
   * section may have a LOINC code for a section of a documentation template.
   */
  codes?: Maybe<CodeableConceptConnection>
  /** The resource that is the target of the action (e.g. CommunicationRequest). */
  resource?: Maybe<RequestGroupActionResourceResult>
}

/**  The actions, if any, produced by the evaluation of the artifact.  */
export type RequestGroupActionCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of RequestGroupAction nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RequestGroupActionConnection = Connection & {
  __typename?: 'RequestGroupActionConnection'
  /** Edges in the current connection page. */
  edges: Array<RequestGroupActionEdge>
  /** List of RequestGroupAction object nodes in the current connection page. */
  nodes: Array<Maybe<RequestGroupAction>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a RequestGroupAction connection page. */
export type RequestGroupActionEdge = Edge & {
  __typename?: 'RequestGroupActionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RequestGroupAction object in this edge. */
  node?: Maybe<RequestGroupAction>
}

/** The resource that is the target of the action (e.g. CommunicationRequest). */
export type RequestGroupActionResourceResult =
  | Appointment
  | CarePlan
  | CareTeam
  | Claim
  | ClinicalImpression
  | Communication
  | CommunicationRequest
  | Composition
  | Condition
  | Coverage
  | Device
  | DiagnosticReport
  | DocumentReference
  | Encounter
  | ExternalResource
  | Goal
  | Group
  | HealthcareService
  | HealthgraphRelatedPerson
  | InsurancePlan
  | Location
  | Measure
  | MeasureReport
  | Media
  | MedicationDispense
  | MedicationRequest
  | MedicationStatement
  | None
  | NonFederatedResource
  | Observation
  | Organization
  | Patient
  | Practitioner
  | PractitionerRole
  | Procedure
  | Questionnaire
  | QuestionnaireResponse
  | RequestGroup
  | RiskAssessment
  | ServiceRequest

/** Provides a reference to the author of the request group. */
export type RequestGroupAuthorResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

/**
 * A page/slice of a list of RequestGroup nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RequestGroupConnection = Connection & {
  __typename?: 'RequestGroupConnection'
  /** Edges in the current connection page. */
  edges: Array<RequestGroupEdge>
  /** List of RequestGroup object nodes in the current connection page. */
  nodes: Array<Maybe<RequestGroup>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a RequestGroup connection page. */
export type RequestGroupEdge = Edge & {
  __typename?: 'RequestGroupEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RequestGroup object in this edge. */
  node?: Maybe<RequestGroup>
}

/**  Specifies a filter to apply to a list of RequestGroup  */
export type RequestGroupFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<RequestGroupFilter>>
  /**
   * Filters on RequestGroup.authoredOn
   * The date the request group was authored
   */
  authored?: Maybe<DateFilter>
  /**
   * Filters on RequestGroup.author
   * The author of the request group
   */
  authorUri?: Maybe<ResourceUriFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on RequestGroup.identifier
   * External identifiers for the request group
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on RequestGroup.intent
   * The intent of the request group
   */
  intent?: Maybe<RequestIntentFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<RequestGroupFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<RequestGroupFilter>>
  /**
   * Filters on RequestGroup.subject.where(resolve() is Patient)
   * The identity of a patient to search for request groups
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on RequestGroup.status
   * The status of the request group
   */
  status?: Maybe<RequestStatusFilter>
  /**
   * Filters on RequestGroup.subject
   * The subject that the request group is about
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** Indicates another resource whose existence justifies this request group. */
export type RequestGroupReasonReferenceResult =
  | Condition
  | ExternalResource
  | NonFederatedResource
  | Observation

/**
 * A page/slice of a list of RequestGroupReasonReferenceResult nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RequestGroupReasonReferenceResultConnection = Connection & {
  __typename?: 'RequestGroupReasonReferenceResultConnection'
  /** Edges in the current connection page. */
  edges: Array<RequestGroupReasonReferenceResultEdge>
  /** List of RequestGroupReasonReferenceResult object nodes in the current connection page. */
  nodes: Array<Maybe<RequestGroupReasonReferenceResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a RequestGroupReasonReferenceResult connection page. */
export type RequestGroupReasonReferenceResultEdge = Edge & {
  __typename?: 'RequestGroupReasonReferenceResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RequestGroupReasonReferenceResult object in this edge. */
  node?: Maybe<RequestGroupReasonReferenceResult>
}

/** Data that still need to be satisfied in the ontology */
export type RequestGroupRequestGroupDataRequirementResult =
  | CodeableConcept
  | None

export type RequestGroupResult =
  | AuthorizationError
  | RequestGroup
  | ResourceNotFoundError

export type RequestGroupsByIdentifierResult =
  | AuthorizationError
  | RequestGroupConnection

/**  A sort on RequestGroup - consists of the field to sort on and the ordering  */
export type RequestGroupSort = {
  /**  Which field to sort by  */
  field: RequestGroupSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that RequestGroup can be sorted by  */
export enum RequestGroupSortOption {
  /**
   * Sorts on RequestGroup.authoredOn
   * The date the request group was authored
   */
  Authored = 'AUTHORED',
  /**  Do not sort  */
  None = 'NONE',
}

/** The subject for which the request group was created. */
export type RequestGroupSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**  Codes indicating the degree of authority/intentionality associated with a request.  */
export enum RequestIntent {
  /**  The request represents a legally binding instruction authored by a Patient or RelatedPerson.  */
  Directive = 'DIRECTIVE',
  /**
   *  The request represents the view of an authorization instantiated by a
   * fulfilling system representing the details of the fulfiller's intention to act
   * upon a submitted order.
   */
  FillerOrder = 'FILLER_ORDER',
  /**
   *  An order created in fulfillment of a broader order that represents the
   * authorization for a single activity occurrence.  E.g. The administration of a
   * single dose of a drug.
   */
  InstanceOrder = 'INSTANCE_ORDER',
  /**
   *  The request represents a component or option for a RequestGroup that
   * establishes timing, conditionality and/or other constraints among a set of
   * requests.  Refer to [[[RequestGroup]]] for additional information on how this
   * status is used.
   */
  Option = 'OPTION',
  /**  The request represents a request/demand and authorization for action by a Practitioner.  */
  Order = 'ORDER',
  /**  The request represents an original authorization for action.  */
  OriginalOrder = 'ORIGINAL_ORDER',
  /**  The request represents an intention to ensure something occurs without providing an authorization for others to act.  */
  Plan = 'PLAN',
  /**
   *  The request is a suggestion made by someone/something that does not have an
   * intention to ensure it occurs and without providing an authorization to act.
   */
  Proposal = 'PROPOSAL',
  /**
   *  The request represents an automatically generated supplemental authorization
   * for action based on a parent authorization together with initial results of
   * the action taken against that parent authorization.
   */
  ReflexOrder = 'REFLEX_ORDER',
}

/**  Codes indicating the degree of authority/intentionality associated with a request.  */
export type RequestIntentFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<RequestIntent>>>
}

export type RequestPatientAccountDeletionResult =
  | PatientAccountDeletionRequest
  | PatientAccountDeletionRequestError

/**  The clinical priority of a diagnostic order.  */
export enum RequestPriority {
  /**  The request should be actioned as soon as possible - higher priority than urgent.  */
  Asap = 'ASAP',
  /**  The request has normal priority.  */
  Routine = 'ROUTINE',
  /**  The request should be actioned immediately - highest possible priority.  E.g. an emergency.  */
  Stat = 'STAT',
  /**  The request should be actioned promptly - higher priority than routine.  */
  Urgent = 'URGENT',
}

/**  The clinical priority of a diagnostic order.  */
export type RequestPriorityFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<RequestPriority>>>
}

/**  Codes identifying the lifecycle stage of a request.  */
export enum RequestStatus {
  /**  The request is in force and ready to be acted upon.  */
  Active = 'ACTIVE',
  /**  The activity described by the request has been fully performed.  No further activity will occur.  */
  Completed = 'COMPLETED',
  /**  The request has been created but is not yet complete or ready for action.  */
  Draft = 'DRAFT',
  /**
   *  This request should never have existed and should be considered 'void'.  (It
   * is possible that real-world decisions were based on it.  If real-world
   * activity has occurred, the status should be "revoked" rather than
   * "entered-in-error".).
   */
  EnteredInError = 'ENTERED_IN_ERROR',
  /**
   *  The request (and any implicit authorization to act) has been temporarily
   * withdrawn but is expected to resume in the future.
   */
  OnHold = 'ON_HOLD',
  /**
   *  The request (and any implicit authorization to act) has been terminated prior
   * to the known full completion of the intended actions.  No further activity should occur.
   */
  Revoked = 'REVOKED',
  /**
   *  The authoring/source system does not know which of the status values currently
   * applies for this request.  Note: This concept is not to be used for "other" -
   * one of the listed statuses is presumed to apply,  but the authoring/source
   * system does not know which.
   */
  Unknown = 'UNKNOWN',
}

/**  Codes identifying the lifecycle stage of a request.  */
export type RequestStatusFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<RequestStatus>>>
}

/**
 * A resource in the Babylon system.
 *
 * It can be uniquely identified by the composite key of the `__typename` and `id` fields.
 */
export type Resource = {
  /**
   * Unique, canonical identifier for the resource.
   *
   * An opaque string, unless the implementing resource explicitly defines a format.
   *
   * Note, the canonical ID for a given resource may change over time, but an ID
   * will never be reassigned to a different resource.
   */
  id: Scalars['ID']
}

/** An error indicating that the requested resource already exists. */
export type ResourceAlreadyExistsError = Error & {
  __typename?: 'ResourceAlreadyExistsError'
  /** The error message. */
  errorMessage: Scalars['String']
  /** The resource that already exists */
  existingResource?: Maybe<Resource>
  /** `__typename` of the resource which already exists, if known. */
  resourceTypename?: Maybe<Scalars['String']>
}

/** An error indicating that there are issues with the request. */
export type ResourceBadRequestError = Error & {
  __typename?: 'ResourceBadRequestError'
  /** The error message. */
  errorMessage: Scalars['String']
  /** __typename of the resource which does not exist, if known. */
  resourceTypename?: Maybe<Scalars['String']>
  /** Requested resource ID. */
  unknownId: Scalars['ID']
}

/**  Metadata - data of the resource other than the FHIR object. */
export type ResourceHealthgraphMetaResult = HealthgraphMeta | None

/** An error indicating that the requested resource does not exist. */
export type ResourceNotFoundError = Error & {
  __typename?: 'ResourceNotFoundError'
  /** The error message. */
  errorMessage: Scalars['String']
  /** `__typename` of the resource which doesn't exist, if known. */
  resourceTypename?: Maybe<Scalars['String']>
  /** Requested ID of resource which wasn't found. */
  unknownId: Scalars['ID']
}

/**  List of all available resource types  */
export enum ResourceType {
  Appointment = 'APPOINTMENT',
  Careplan = 'CAREPLAN',
  Careteam = 'CARETEAM',
  Claim = 'CLAIM',
  Clinicalimpression = 'CLINICALIMPRESSION',
  Cohortmember = 'COHORTMEMBER',
  Communication = 'COMMUNICATION',
  Communicationrequest = 'COMMUNICATIONREQUEST',
  Composition = 'COMPOSITION',
  Condition = 'CONDITION',
  Coverage = 'COVERAGE',
  Device = 'DEVICE',
  Diagnosticreport = 'DIAGNOSTICREPORT',
  Documentreference = 'DOCUMENTREFERENCE',
  Encounter = 'ENCOUNTER',
  Goal = 'GOAL',
  Group = 'GROUP',
  Healthcareservice = 'HEALTHCARESERVICE',
  Insuranceplan = 'INSURANCEPLAN',
  Location = 'LOCATION',
  Measure = 'MEASURE',
  Measurereport = 'MEASUREREPORT',
  Media = 'MEDIA',
  Medicationdispense = 'MEDICATIONDISPENSE',
  Medicationrequest = 'MEDICATIONREQUEST',
  Medicationstatement = 'MEDICATIONSTATEMENT',
  Observation = 'OBSERVATION',
  Organization = 'ORGANIZATION',
  Patient = 'PATIENT',
  Practitioner = 'PRACTITIONER',
  Practitionerrole = 'PRACTITIONERROLE',
  Procedure = 'PROCEDURE',
  Questionnaire = 'QUESTIONNAIRE',
  Questionnaireresponse = 'QUESTIONNAIRERESPONSE',
  Relatedperson = 'RELATEDPERSON',
  Requestgroup = 'REQUESTGROUP',
  Riskassessment = 'RISKASSESSMENT',
  Servicerequest = 'SERVICEREQUEST',
}

/**  Used to filter on a ResourceUri */
export type ResourceUriFilter = {
  /**  The field must match at least one of the given options */
  ONE_OF?: Maybe<Array<Scalars['String']>>
}

export type RevokeAllUserSessionsResult = ResourceNotFoundError | User

/** Input to revoke a user session */
export type RevokeUserSessionInput = {
  sessionId: Scalars['ID']
}

export type RevokeUserSessionResult = ResourceNotFoundError | UserSession

/**  An assessment of the likely outcome(s) for a patient or other subject as well as the likelihood of each outcome.  */
export type RiskAssessment = HealthgraphResource &
  Resource & {
    __typename?: 'RiskAssessment'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /**
     * Indicates the source data considered as part of the assessment (for example,
     * FamilyHistory, Observations, Procedures, Conditions, etc.).
     */
    bases?: Maybe<RiskAssessmentBasisResultConnection>
    /** The type of the risk assessment performed. */
    code?: Maybe<RiskAssessmentCodeResult>
    /** Component used in the score calculation */
    components?: Maybe<RiskAssessmentHealthScoreComponentConnection>
    /** Description of the acuity score calculation being used */
    description?: Maybe<Scalars['String']>
    /** DPU version used by the model e.g. "1.2.0" */
    dpuVersion?: Maybe<Scalars['String']>
    /** when the latest derivedFrom resource was effective */
    effectiveDate?: Maybe<Scalars['FhirDateTime']>
    /** The encounter where the assessment was performed. */
    encounter?: Maybe<RiskAssessmentEncounterResult>
    /** when the earliest derivedFrom resource will expire */
    expireAt?: Maybe<Scalars['FhirDateTime']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Business identifier assigned to the risk assessment. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** model description e.g. New hospital admissions in 12 months */
    modelDescription?: Maybe<Scalars['String']>
    /** model ID e.g. new_admissions, readmissions */
    modelId?: Maybe<Scalars['String']>
    /** model version e.g. "v1.2" */
    modelVersion?: Maybe<Scalars['String']>
    /** The date (and possibly time) the risk assessment was performed. */
    occurrenceDateTime?: Maybe<Scalars['FhirDateTime']>
    /** The provider or software application that performed the assessment. */
    performer?: Maybe<RiskAssessmentPerformerResult>
    /** Describes the expected outcome for the subject. */
    predictions?: Maybe<RiskAssessmentPredictionConnection>
    /** list of required components for this version of health score; include type field of components, e.g. BMI */
    requiredComponents?: Maybe<StringConnection>
    /** service version e.g. "0.4.1" */
    serviceVersion?: Maybe<Scalars['String']>
    /** The status of the RiskAssessment, using the same statuses as an Observation. */
    status?: Maybe<Scalars['FhirCode']>
    /** The patient or group the risk assessment applies to. */
    subject?: Maybe<RiskAssessmentSubjectResult>
    /** when the triggeredBy datapoint was observed/calculated */
    triggeredAt?: Maybe<Scalars['FhirDateTime']>
    /** datapoint triggering healthscore re/calculation */
    triggeredBy?: Maybe<Scalars['String']>
    /**  Information about how trustworthy the resource is; how likely it is to be accurate  */
    trust?: Maybe<RiskAssessmentTrustResult>
    /** The type of acuity score e.g. ALL_DEAL_RISING_COST_12_MONTH */
    type?: Maybe<Scalars['String']>
    /** version of healthscore, e.g. `1.0` */
    version?: Maybe<Scalars['String']>
  }

/**  An assessment of the likely outcome(s) for a patient or other subject as well as the likelihood of each outcome.  */
export type RiskAssessmentBasesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  An assessment of the likely outcome(s) for a patient or other subject as well as the likelihood of each outcome.  */
export type RiskAssessmentComponentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  An assessment of the likely outcome(s) for a patient or other subject as well as the likelihood of each outcome.  */
export type RiskAssessmentIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  An assessment of the likely outcome(s) for a patient or other subject as well as the likelihood of each outcome.  */
export type RiskAssessmentPredictionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  An assessment of the likely outcome(s) for a patient or other subject as well as the likelihood of each outcome.  */
export type RiskAssessmentRequiredComponentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * Indicates the source data considered as part of the assessment (for example,
 * FamilyHistory, Observations, Procedures, Conditions, etc.).
 */
export type RiskAssessmentBasisResult =
  | Condition
  | ExternalResource
  | NonFederatedResource
  | Observation
  | QuestionnaireResponse

/**
 * A page/slice of a list of RiskAssessmentBasisResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RiskAssessmentBasisResultConnection = Connection & {
  __typename?: 'RiskAssessmentBasisResultConnection'
  /** Edges in the current connection page. */
  edges: Array<RiskAssessmentBasisResultEdge>
  /** List of RiskAssessmentBasisResult object nodes in the current connection page. */
  nodes: Array<Maybe<RiskAssessmentBasisResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a RiskAssessmentBasisResult connection page. */
export type RiskAssessmentBasisResultEdge = Edge & {
  __typename?: 'RiskAssessmentBasisResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RiskAssessmentBasisResult object in this edge. */
  node?: Maybe<RiskAssessmentBasisResult>
}

/** The type of the risk assessment performed. */
export type RiskAssessmentCodeResult = CodeableConcept | None

/**
 * A page/slice of a list of RiskAssessment nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RiskAssessmentConnection = Connection & {
  __typename?: 'RiskAssessmentConnection'
  /** Edges in the current connection page. */
  edges: Array<RiskAssessmentEdge>
  /** List of RiskAssessment object nodes in the current connection page. */
  nodes: Array<Maybe<RiskAssessment>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a RiskAssessment connection page. */
export type RiskAssessmentEdge = Edge & {
  __typename?: 'RiskAssessmentEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RiskAssessment object in this edge. */
  node?: Maybe<RiskAssessment>
}

/** The encounter where the assessment was performed. */
export type RiskAssessmentEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  Specifies a filter to apply to a list of RiskAssessment  */
export type RiskAssessmentFilter = {
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<RiskAssessmentFilter>>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on (RiskAssessment.occurrence as dateTime)
   * When was assessment made?
   */
  date?: Maybe<DateFilter>
  /**
   * Filters on RiskAssessment.encounter
   * Where was assessment performed?
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on RiskAssessment.identifier
   * Unique identifier for the assessment
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on RiskAssessment.extension('https://fhir.bbl.health/StructureDefinition/BblRiskPredictionExtensionModelID')
   * model ID e.g. new_admissions, readmissions
   */
  modelId?: Maybe<StringFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<RiskAssessmentFilter>>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<RiskAssessmentFilter>>
  /**
   * Filters on RiskAssessment.subject.where(resolve() is Patient)
   * Who/what does assessment apply to?
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on RiskAssessment.performer
   * Who did assessment?
   */
  performerUri?: Maybe<ResourceUriFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on RiskAssessment.prediction.qualitativeRisk
   * Likelihood of specified outcome as a qualitative value
   */
  risk?: Maybe<CodeFilter>
  /**
   * Filters on RiskAssessment.subject
   * Who/what does assessment apply to?
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
  /**  Filter by trust values, lets you limit to results that have a certain likelihood of being true  */
  trust?: Maybe<TrustFilter>
}

/**  Recommended Actions with their priority  */
export type RiskAssessmentHealthScoreAction = {
  __typename?: 'RiskAssessmentHealthScoreAction'
  /** action uuid */
  actionId?: Maybe<Scalars['Uuid']>
  /** id used to pull content for this action, e.g. reduce_alcohol_intake */
  contentId?: Maybe<Scalars['String']>
  /** increase in score based on recommended action; value between 0 & 100 */
  potentialScoreGain?: Maybe<Scalars['Float']>
  /** priority, 1 to 100, 1 being highest. Unique across components */
  priority?: Maybe<Scalars['PositiveInt']>
}

/**
 * A page/slice of a list of RiskAssessmentHealthScoreAction nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RiskAssessmentHealthScoreActionConnection = Connection & {
  __typename?: 'RiskAssessmentHealthScoreActionConnection'
  /** Edges in the current connection page. */
  edges: Array<RiskAssessmentHealthScoreActionEdge>
  /** List of RiskAssessmentHealthScoreAction object nodes in the current connection page. */
  nodes: Array<Maybe<RiskAssessmentHealthScoreAction>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a RiskAssessmentHealthScoreAction connection page. */
export type RiskAssessmentHealthScoreActionEdge = Edge & {
  __typename?: 'RiskAssessmentHealthScoreActionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RiskAssessmentHealthScoreAction object in this edge. */
  node?: Maybe<RiskAssessmentHealthScoreAction>
}

/**  Component used in the score calculation  */
export type RiskAssessmentHealthScoreComponent = {
  __typename?: 'RiskAssessmentHealthScoreComponent'
  /** Recommended Actions with their priority */
  actions?: Maybe<RiskAssessmentHealthScoreActionConnection>
  /** data point uuid */
  componentId?: Maybe<Scalars['Uuid']>
  /** type of component, e.g. BMI, alcohol intake, cigarette smoking, physical activity */
  componentType?: Maybe<Scalars['String']>
  /** Component Derived from reference resources */
  derivedFromReferences?: Maybe<RiskAssessmentHealthScoreComponentDerivedFromReferenceConnection>
  /** when the latest derivedFrom resource was effective; if not feasible, use calculatedAt; overwrite if desired */
  effectiveDate?: Maybe<Scalars['FhirDateTime']>
  /** when the earliest derivedFrom resource will expire; overwrite if desired */
  expireAt?: Maybe<Scalars['FhirDateTime']>
  /** when this component was recorded/observed/calculated */
  recordedAt?: Maybe<Scalars['FhirDateTime']>
  /** use this field if value is of type decimal */
  valueDecimal?: Maybe<Scalars['Float']>
}

/**  Component used in the score calculation  */
export type RiskAssessmentHealthScoreComponentActionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  Component used in the score calculation  */
export type RiskAssessmentHealthScoreComponentDerivedFromReferencesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A page/slice of a list of RiskAssessmentHealthScoreComponent nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RiskAssessmentHealthScoreComponentConnection = Connection & {
  __typename?: 'RiskAssessmentHealthScoreComponentConnection'
  /** Edges in the current connection page. */
  edges: Array<RiskAssessmentHealthScoreComponentEdge>
  /** List of RiskAssessmentHealthScoreComponent object nodes in the current connection page. */
  nodes: Array<Maybe<RiskAssessmentHealthScoreComponent>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**  Component Derived from reference resources  */
export type RiskAssessmentHealthScoreComponentDerivedFromReference = {
  __typename?: 'RiskAssessmentHealthScoreComponentDerivedFromReference'
  /** Component Derived from reference resources */
  uri?: Maybe<Scalars['Uri']>
}

/**
 * A page/slice of a list of RiskAssessmentHealthScoreComponentDerivedFromReference
 * nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RiskAssessmentHealthScoreComponentDerivedFromReferenceConnection = Connection & {
  __typename?: 'RiskAssessmentHealthScoreComponentDerivedFromReferenceConnection'
  /** Edges in the current connection page. */
  edges: Array<RiskAssessmentHealthScoreComponentDerivedFromReferenceEdge>
  /** List of RiskAssessmentHealthScoreComponentDerivedFromReference object nodes in the current connection page. */
  nodes: Array<Maybe<RiskAssessmentHealthScoreComponentDerivedFromReference>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a RiskAssessmentHealthScoreComponentDerivedFromReference connection page. */
export type RiskAssessmentHealthScoreComponentDerivedFromReferenceEdge = Edge & {
  __typename?: 'RiskAssessmentHealthScoreComponentDerivedFromReferenceEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RiskAssessmentHealthScoreComponentDerivedFromReference object in this edge. */
  node?: Maybe<RiskAssessmentHealthScoreComponentDerivedFromReference>
}

/** An edge in a RiskAssessmentHealthScoreComponent connection page. */
export type RiskAssessmentHealthScoreComponentEdge = Edge & {
  __typename?: 'RiskAssessmentHealthScoreComponentEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RiskAssessmentHealthScoreComponent object in this edge. */
  node?: Maybe<RiskAssessmentHealthScoreComponent>
}

/** The provider or software application that performed the assessment. */
export type RiskAssessmentPerformerResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

/**  Describes the expected outcome for the subject.  */
export type RiskAssessmentPrediction = {
  __typename?: 'RiskAssessmentPrediction'
  /** One of the potential outcomes for the patient (e.g. remission, death,  a particular condition). */
  outcome?: Maybe<RiskAssessmentPredictionOutcomeResult>
  /** Indicates how likely the outcome is (in the specified timeframe). */
  probabilityDecimal?: Maybe<Scalars['Float']>
  /**
   * Indicates how likely the outcome is (in the specified timeframe), expressed as
   * a qualitative value (e.g. low, medium, or high).
   */
  qualitativeRisk?: Maybe<RiskAssessmentPredictionQualitativeRiskResult>
  /** Additional information explaining the prediction, encoded as JSON */
  rationaleJson?: Maybe<Scalars['String']>
  /** Indicates the period of time or age range of the subject to which the specified probability applies. */
  whenPeriod?: Maybe<RiskAssessmentPredictionWhenPeriodResult>
}

/**
 * A page/slice of a list of RiskAssessmentPrediction nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type RiskAssessmentPredictionConnection = Connection & {
  __typename?: 'RiskAssessmentPredictionConnection'
  /** Edges in the current connection page. */
  edges: Array<RiskAssessmentPredictionEdge>
  /** List of RiskAssessmentPrediction object nodes in the current connection page. */
  nodes: Array<Maybe<RiskAssessmentPrediction>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a RiskAssessmentPrediction connection page. */
export type RiskAssessmentPredictionEdge = Edge & {
  __typename?: 'RiskAssessmentPredictionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the RiskAssessmentPrediction object in this edge. */
  node?: Maybe<RiskAssessmentPrediction>
}

/** One of the potential outcomes for the patient (e.g. remission, death,  a particular condition). */
export type RiskAssessmentPredictionOutcomeResult = CodeableConcept | None

/**
 * Indicates how likely the outcome is (in the specified timeframe), expressed as a
 * qualitative value (e.g. low, medium, or high).
 */
export type RiskAssessmentPredictionQualitativeRiskResult =
  | CodeableConcept
  | None

/** Indicates the period of time or age range of the subject to which the specified probability applies. */
export type RiskAssessmentPredictionWhenPeriodResult = None | Period

export type RiskAssessmentResult =
  | AuthorizationError
  | ResourceNotFoundError
  | RiskAssessment

export type RiskAssessmentsByIdentifierResult =
  | AuthorizationError
  | RiskAssessmentConnection

/**  A sort on RiskAssessment - consists of the field to sort on and the ordering  */
export type RiskAssessmentSort = {
  /**  Which field to sort by  */
  field: RiskAssessmentSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that RiskAssessment can be sorted by  */
export enum RiskAssessmentSortOption {
  /**
   * Sorts on (RiskAssessment.occurrence as dateTime)
   * When was assessment made?
   */
  Date = 'DATE',
  /**  Do not sort  */
  None = 'NONE',
  /**  Sort by how trustworthy the resource is  */
  Trust = 'TRUST',
}

/** The patient or group the risk assessment applies to. */
export type RiskAssessmentSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**  Trust information or None if the Trust data is not present  */
export type RiskAssessmentTrustResult = None | Trust

/**  A container for slots of time that may be available for booking appointments. */
export type Schedule = Resource & {
  __typename?: 'Schedule'
  /**  The list of actors that adhere to this schedule. */
  actors?: Maybe<ScheduleActorResultConnection>
  /**
   * Unique, canonical identifier for the resource.
   * An opaque string, unless the implementing resource explicitly defines a format.
   * Note, the canonical ID for a given resource may change over time, but an ID
   * will never be reassigned to a different resource.
   */
  id: Scalars['ID']
  /**  External Ids for this item. */
  identifiers: HealthgraphIdentifierConnection
  /** Slots of time that may be available for booking appointments */
  slots: SlotConnection
}

/**  A container for slots of time that may be available for booking appointments. */
export type ScheduleActorsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A container for slots of time that may be available for booking appointments. */
export type ScheduleIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A container for slots of time that may be available for booking appointments. */
export type ScheduleSlotsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A reference to an actor that adheres to a particular schedule. */
export type ScheduleActorResult =
  | ExternalResource
  | NonFederatedResource
  | PracticeHealthcareService
  | Practitioner

/**
 * A page/slice of a list of ScheduleActorResult nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ScheduleActorResultConnection = Connection & {
  __typename?: 'ScheduleActorResultConnection'
  /** Edges in the current connection page. */
  edges: Array<ScheduleActorResultEdge>
  /** List of ScheduleActorResult object nodes in the current connection page. */
  nodes: Array<Maybe<ScheduleActorResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ScheduleActorResult connection page. */
export type ScheduleActorResultEdge = Edge & {
  __typename?: 'ScheduleActorResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ScheduleActorResult object in this edge. */
  node?: Maybe<ScheduleActorResult>
}

/** input for adding conversation events. */
export type SendEventInput = {
  /** The conversation, within which, this event takes place. */
  conversationId: Scalars['ID']
  /** The conversation event which has occurred. */
  event: ConversationEventInput
}

export type SendEventResult = ChatConversation | ResourceNotFoundError

/** input for sending converstaion message. */
export type SendMessageInput = {
  /** The conversation, within which, this message takes place. */
  conversationId: Scalars['ID']
  /** The message being sent. */
  message: ConversationInputMessage
}

/** Result of sending a conversation message. */
export type SendMessageResult =
  | ChatConversation
  | ConversationFinishedError
  | ResourceNotFoundError

/** Input required to create a password reset email */
export type SendPasswordResetEmailInput = {
  /** Email of the user */
  emailAddress: Scalars['EmailAddress']
}

export type SendPasswordResetEmailResult = None | ResourceNotFoundError

/**  A record of a request for service such as diagnostic investigations, treatments, or operations to be performed.  */
export type ServiceRequest = HealthgraphResource &
  Resource & {
    __typename?: 'ServiceRequest'
    /**
     * Gets the underlying FHIR object as a JSON string.
     * This is intended for use when you need a valid FHIR object, such as for interfacing with external EMRs
     */
    _healthgraph_fhirJson?: Maybe<Scalars['FhirJson']>
    /**  HealthGraph Metadata - data of the resource other than the FHIR object.  */
    _healthgraph_metadata?: Maybe<ResourceHealthgraphMetaResult>
    /**
     * Globally unique identifier for every resource of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
     * See: https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
     */
    _healthgraph_resourceUri?: Maybe<Scalars['ID']>
    /** When the request transitioned to being actionable. */
    authoredOn?: Maybe<Scalars['FhirDateTime']>
    /** A code that classifies the service for searching, sorting and display purposes (e.g. "Surgical Procedure"). */
    categories?: Maybe<CodeableConceptConnection>
    /**
     * A code that identifies a particular service (i.e., procedure, diagnostic
     * investigation, or panel of investigations) that have been requested.
     */
    code?: Maybe<ServiceRequestCodeResult>
    /** An encounter that provides additional information about the healthcare context in which this request is made. */
    encounter?: Maybe<ServiceRequestEncounterResult>
    /** Athena facility id */
    facilityId?: Maybe<Scalars['String']>
    /**
     * Unique, canonical identifier for the resource.
     * An opaque string, unless the implementing resource explicitly defines a format.
     * Note, the canonical ID for a given resource may change over time, but an ID
     * will never be reassigned to a different resource.
     */
    id: Scalars['ID']
    /** Identifiers assigned to this order instance by the orderer and/or the receiver and/or order fulfiller. */
    identifiers?: Maybe<HealthgraphIdentifierConnection>
    /** Whether the request is a proposal, plan, an original order or a reflex order. */
    intent?: Maybe<Scalars['FhirCode']>
    /** Does the patient consent */
    isServiceRequestConsentForPreAuthorisation?: Maybe<Scalars['Boolean']>
    /** Any other notes and comments made about the service request. For example, internal billing notes. */
    notes?: Maybe<AnnotationConnection>
    /** The date/time at which the requested service should occur. */
    occurrenceDateTime?: Maybe<Scalars['FhirDateTime']>
    /** Desired type of performer for doing the requested service. */
    performerType?: Maybe<ServiceRequestPerformerTypeResult>
    /** Indicates how quickly the ServiceRequest should be addressed with respect to other requests. */
    priority?: Maybe<Scalars['FhirCode']>
    /**
     * An explanation or justification for why this service is being requested in
     * coded or textual form.   This is often for billing purposes.  May relate to
     * the resources referred to in `supportingInfo`.
     */
    reasonCodes?: Maybe<CodeableConceptConnection>
    /**
     * Indicates another resource that provides a justification for why this service
     * is being requested.   May relate to the resources referred to in
     * `supportingInfo`.
     */
    reasonReferences?: Maybe<ServiceRequestReasonReferenceResultConnection>
    /** The individual who initiated the request and has responsibility for its activation. */
    requester?: Maybe<ServiceRequestRequesterResult>
    /** Symptom start date */
    serviceRequestReasonStartDate?: Maybe<Scalars['FhirDateTime']>
    /** The status of the order. */
    status?: Maybe<Scalars['FhirCode']>
    /**
     * On whom or what the service is to be performed. This is usually a human
     * patient, but can also be requested on animals, groups of humans or animals,
     * devices such as dialysis machines, or even locations (typically for
     * environmental scans).
     */
    subject?: Maybe<ServiceRequestSubjectResult>
    /**
     * Additional clinical information about the patient or specimen that may
     * influence the services or their interpretations.     This information includes
     * diagnosis, clinical findings and other observations.  In laboratory ordering
     * these are typically referred to as "ask at order entry questions (AOEs)".
     * This includes observations explicitly requested by the producer (filler) to
     * provide context or supporting information needed to complete the order. For
     * example,  reporting the amount of inspired oxygen for blood gas measurements.
     */
    supportingInfos?: Maybe<ServiceRequestSupportingInfoResultConnection>
  }

/**  A record of a request for service such as diagnostic investigations, treatments, or operations to be performed.  */
export type ServiceRequestCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A record of a request for service such as diagnostic investigations, treatments, or operations to be performed.  */
export type ServiceRequestIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A record of a request for service such as diagnostic investigations, treatments, or operations to be performed.  */
export type ServiceRequestNotesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A record of a request for service such as diagnostic investigations, treatments, or operations to be performed.  */
export type ServiceRequestReasonCodesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**  A record of a request for service such as diagnostic investigations, treatments, or operations to be performed.  */
export type ServiceRequestReasonReferencesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**  A record of a request for service such as diagnostic investigations, treatments, or operations to be performed.  */
export type ServiceRequestSupportingInfosArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A code that identifies a particular service (i.e., procedure, diagnostic
 * investigation, or panel of investigations) that have been requested.
 */
export type ServiceRequestCodeResult = CodeableConcept | None

/**
 * A page/slice of a list of ServiceRequest nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ServiceRequestConnection = Connection & {
  __typename?: 'ServiceRequestConnection'
  /** Edges in the current connection page. */
  edges: Array<ServiceRequestEdge>
  /** List of ServiceRequest object nodes in the current connection page. */
  nodes: Array<Maybe<ServiceRequest>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ServiceRequest connection page. */
export type ServiceRequestEdge = Edge & {
  __typename?: 'ServiceRequestEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ServiceRequest object in this edge. */
  node?: Maybe<ServiceRequest>
}

/** An encounter that provides additional information about the healthcare context in which this request is made. */
export type ServiceRequestEncounterResult =
  | Encounter
  | ExternalResource
  | None
  | NonFederatedResource

/**  Specifies a filter to apply to a list of ServiceRequest  */
export type ServiceRequestFilter = {
  /**
   * Filters on RiskIntervention.reasonCodeType
   * Reason WHY intervention is requested
   */
  _riskInterventionReasonCodeType?: Maybe<KeywordFilter>
  /**  All of the given conditions must be true  */
  AND?: Maybe<Array<ServiceRequestFilter>>
  /**
   * Filters on ServiceRequest.authoredOn
   * Date request signed
   */
  authored?: Maybe<DateFilter>
  /**
   * Filters on ServiceRequest.category
   * Classification of service
   */
  category?: Maybe<CodeFilter>
  /**
   * Filters on ServiceRequest.code
   * What is being requested/ordered
   */
  code?: Maybe<CodeFilter>
  /**  The profiles that the data has been verified to conform to.  */
  conformedProfile?: Maybe<ProfileFilter>
  /**
   * Filters on ServiceRequest.encounter
   * An encounter in which this request is made
   */
  encounterUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on ServiceRequest.identifier
   * Identifiers assigned to this order
   */
  identifier?: Maybe<IdentifierFilter>
  /**
   * Filters on ServiceRequest.intent
   * proposal | plan | directive | order | original-order | reflex-order | filler-order | instance-order | option
   */
  intent?: Maybe<RequestIntentFilter>
  /**  All of the given conditions must be false  */
  NOT?: Maybe<Array<ServiceRequestFilter>>
  /**
   * Filters on ServiceRequest.occurrence
   * When service should occur
   */
  occurrence?: Maybe<DateFilter>
  /**  At least one of the given conditions must be true  */
  OR?: Maybe<Array<ServiceRequestFilter>>
  /**
   * Filters on ServiceRequest.subject.where(resolve() is Patient)
   * Search by subject - a patient
   */
  patientUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on ServiceRequest.performerType
   * Performer role
   */
  performerType?: Maybe<CodeFilter>
  /**
   * Filters on ServiceRequest.priority
   * routine | urgent | asap | stat
   */
  priority?: Maybe<RequestPriorityFilter>
  /**  Searches for resources that were entered by one of the given services e.g. monitor or clinical-portal  */
  provenanceEnterer?: Maybe<ProvenanceEntererFilter>
  /**
   * Filters on ServiceRequest.requester
   * Who/what is requesting service
   */
  requesterUri?: Maybe<ResourceUriFilter>
  /**
   * Searches for resources by their resource URI, of the form https://<domain>.bbl.health/<ResourceType>/<ID>.
   * This is the primary way of finding a resource with an already known identity
   * See https://github.com/babylonhealth/hydrant/blob/master/docs/ids.md#resource-uris
   */
  resourceUri?: Maybe<ResourceUriFilter>
  /**
   * Filters on ServiceRequest.status
   * draft | active | on-hold | revoked | completed | entered-in-error | unknown
   */
  status?: Maybe<RequestStatusFilter>
  /**
   * Filters on ServiceRequest.subject
   * Search by subject
   */
  subjectUri?: Maybe<ResourceUriFilter>
  /**
   * The profiles that the data was tagged with when it was written as dfhir. Note that these are not 100% guaranteed to
   * be met by the data (although they most likely do) because they were not verified - you can use conformedProfiles to
   * confirm if they are correct.
   */
  taggedProfile?: Maybe<ProfileFilter>
}

/** Desired type of performer for doing the requested service. */
export type ServiceRequestPerformerTypeResult = CodeableConcept | None

/**
 * Indicates another resource that provides a justification for why this service is
 * being requested.   May relate to the resources referred to in `supportingInfo`.
 */
export type ServiceRequestReasonReferenceResult =
  | Condition
  | ExternalResource
  | NonFederatedResource
  | Observation

/**
 * A page/slice of a list of ServiceRequestReasonReferenceResult nodes, allowing
 * for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ServiceRequestReasonReferenceResultConnection = Connection & {
  __typename?: 'ServiceRequestReasonReferenceResultConnection'
  /** Edges in the current connection page. */
  edges: Array<ServiceRequestReasonReferenceResultEdge>
  /** List of ServiceRequestReasonReferenceResult object nodes in the current connection page. */
  nodes: Array<Maybe<ServiceRequestReasonReferenceResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ServiceRequestReasonReferenceResult connection page. */
export type ServiceRequestReasonReferenceResultEdge = Edge & {
  __typename?: 'ServiceRequestReasonReferenceResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ServiceRequestReasonReferenceResult object in this edge. */
  node?: Maybe<ServiceRequestReasonReferenceResult>
}

/** The individual who initiated the request and has responsibility for its activation. */
export type ServiceRequestRequesterResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Practitioner

export type ServiceRequestResult =
  | AuthorizationError
  | ResourceNotFoundError
  | ServiceRequest

export type ServiceRequestsByIdentifierResult =
  | AuthorizationError
  | ServiceRequestConnection

/**  A sort on ServiceRequest - consists of the field to sort on and the ordering  */
export type ServiceRequestSort = {
  /**  Which field to sort by  */
  field: ServiceRequestSortOption
  /**  Which order to sort in  */
  order?: Maybe<SortOrder>
}

/**  Enumerates the fields that ServiceRequest can be sorted by  */
export enum ServiceRequestSortOption {
  /**
   * Sorts on ServiceRequest.authoredOn
   * Date request signed
   */
  Authored = 'AUTHORED',
  /**  Do not sort  */
  None = 'NONE',
  /**
   * Sorts on ServiceRequest.occurrence
   * When service should occur
   */
  Occurrence = 'OCCURRENCE',
}

/**
 * On whom or what the service is to be performed. This is usually a human patient,
 * but can also be requested on animals, groups of humans or animals, devices such
 * as dialysis machines, or even locations (typically for environmental scans).
 */
export type ServiceRequestSubjectResult =
  | ExternalResource
  | None
  | NonFederatedResource
  | Patient

/**
 * Additional clinical information about the patient or specimen that may influence
 * the services or their interpretations.     This information includes diagnosis,
 * clinical findings and other observations.  In laboratory ordering these are
 * typically referred to as "ask at order entry questions (AOEs)".  This includes
 * observations explicitly requested by the producer (filler) to provide context or
 * supporting information needed to complete the order. For example,  reporting the
 * amount of inspired oxygen for blood gas measurements.
 */
export type ServiceRequestSupportingInfoResult =
  | Appointment
  | CarePlan
  | CareTeam
  | Claim
  | ClinicalImpression
  | Communication
  | CommunicationRequest
  | Composition
  | Condition
  | Coverage
  | Device
  | DiagnosticReport
  | DocumentReference
  | Encounter
  | ExternalResource
  | Goal
  | Group
  | HealthcareService
  | HealthgraphRelatedPerson
  | InsurancePlan
  | Location
  | Measure
  | MeasureReport
  | Media
  | MedicationDispense
  | MedicationRequest
  | MedicationStatement
  | NonFederatedResource
  | Observation
  | Organization
  | Patient
  | Practitioner
  | PractitionerRole
  | Procedure
  | Questionnaire
  | QuestionnaireResponse
  | RequestGroup
  | RiskAssessment
  | ServiceRequest

/**
 * A page/slice of a list of ServiceRequestSupportingInfoResult nodes, allowing for
 * standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type ServiceRequestSupportingInfoResultConnection = Connection & {
  __typename?: 'ServiceRequestSupportingInfoResultConnection'
  /** Edges in the current connection page. */
  edges: Array<ServiceRequestSupportingInfoResultEdge>
  /** List of ServiceRequestSupportingInfoResult object nodes in the current connection page. */
  nodes: Array<Maybe<ServiceRequestSupportingInfoResult>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a ServiceRequestSupportingInfoResult connection page. */
export type ServiceRequestSupportingInfoResultEdge = Edge & {
  __typename?: 'ServiceRequestSupportingInfoResultEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ServiceRequestSupportingInfoResult object in this edge. */
  node?: Maybe<ServiceRequestSupportingInfoResult>
}

/** A service type describes what function a practitioner can perform as part of a given slot. */
export type ServiceType = Resource & {
  __typename?: 'ServiceType'
  /** The mediums by which the service type can be administered. */
  allowedMediums: ServiceTypeMediumConnection
  /** The duration of the service type. Typically in minutes. */
  defaultDuration?: Maybe<Scalars['NonNegativeInt']>
  /** ID of ServiceType */
  id: Scalars['ID']
  /** The of the service type. For example Gen Med 20. */
  name: Scalars['String']
}

/** A service type describes what function a practitioner can perform as part of a given slot. */
export type ServiceTypeAllowedMediumsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** The service type connections associated with a Slot */
export type ServiceTypeConnection = Connection & {
  __typename?: 'ServiceTypeConnection'
  /** Edges in the current connection page. */
  edges: Array<ServiceTypeEdge>
  /** List of ServiceType object nodes in the current connection page. */
  nodes: Array<Maybe<ServiceType>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Service type connection page. */
export type ServiceTypeEdge = Edge & {
  __typename?: 'ServiceTypeEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Appointment object in this edge. */
  node?: Maybe<ServiceType>
}

/** The possible media by which a service type can be performed. */
export enum ServiceTypeMedium {
  /** Physical */
  Physical = 'PHYSICAL',
  /** Text */
  Text = 'TEXT',
  /** Video */
  Video = 'VIDEO',
  /** Voice */
  Voice = 'VOICE',
}

/** The service type medium connections associated with a Slot */
export type ServiceTypeMediumConnection = Connection & {
  __typename?: 'ServiceTypeMediumConnection'
  /** Edges in the current connection page. */
  edges: Array<ServiceTypeMediumEdge>
  /** List of ServiceTypeMedium object nodes in the current connection page. */
  nodes: Array<Maybe<ServiceTypeMedium>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Service type medium connection. */
export type ServiceTypeMediumEdge = Edge & {
  __typename?: 'ServiceTypeMediumEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the ServiceTypeMedium object in this edge. */
  node?: Maybe<ServiceTypeMedium>
}

/** Sex at birth. */
export enum SexAtBirth {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Other */
  Other = 'OTHER',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

/** Sex at birth. */
export enum SexAtBirthInput {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

/** A slot of time on a schedule that may be available for booking appointments. */
export type Slot = Resource & {
  __typename?: 'Slot'
  /**  Appointment booked for this slot */
  appointment?: Maybe<SlotAppointmentResult>
  /** The booking information for a slot. */
  bookingConstraint?: Maybe<SlotBookingConstraint>
  /** Shift category (e.g. regular, cover, etc). */
  category: SlotCategory
  /**  Date/Time that a slot ends. */
  end?: Maybe<Scalars['DateTime']>
  /**
   * Unique, canonical identifier for the resource.
   * An opaque string, unless the implementing resource explicitly defines a format.
   * Note, the canonical ID for a given resource may change over time, but an ID
   * will never be reassigned to a different resource.
   */
  id: Scalars['ID']
  /** External identifiers for this slot. */
  identifiers: HealthgraphIdentifierConnection
  /** Clinician's local time. */
  localTime?: Maybe<Scalars['DateTime']>
  /** The Clinic for a physical slot. Does not apply to Digital slots */
  practice?: Maybe<SlotPracticeResult>
  /** Practitioner for slot. */
  practitioner?: Maybe<SlotPractitionerResult>
  /**  Priority of slot for booking appointment */
  priority?: Maybe<Scalars['NonNegativeInt']>
  /**
   * The schedule resource that this slot was defined as part of, if one exists.
   *
   * Slots created ad-hoc, and not as part of a larger schedule, will not have a schedule associated with them.
   */
  schedule?: Maybe<SlotScheduleResult>
  /**  Service types that have been assigned to a slot. */
  serviceTypes: ServiceTypeConnection
  /**  Date/Time that a slot begins. */
  start?: Maybe<Scalars['DateTime']>
  /** The current status of the slot. */
  status: SlotStatus
}

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotIdentifiersArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A slot of time on a schedule that may be available for booking appointments. */
export type SlotServiceTypesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** The appointment booked in this slot. */
export type SlotAppointmentResult = Appointment | None

/** Constraints describing the possible bookings that can be made on a slot */
export type SlotBookingConstraint = {
  __typename?: 'SlotBookingConstraint'
  /** Slot for digital consultation */
  isDigital: Scalars['Boolean']
  /** True if appointment slot removed */
  isMarkedForRemoval: Scalars['Boolean']
  /** Non bookable slot, admin slot not for consultation */
  isNonBookable: Scalars['Boolean']
  /** Slot for physical consultation */
  isPhysical: Scalars['Boolean']
  /** Slot for text consultation */
  isText: Scalars['Boolean']
}

/** The category of the slot */
export enum SlotCategory {
  /** Cover */
  Cover = 'COVER',
  /** One-off */
  OneOff = 'ONE_OFF',
  /** Regular */
  Regular = 'REGULAR',
}

/**
 * A page/slice of a list of Slot nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type SlotConnection = Connection & {
  __typename?: 'SlotConnection'
  /** Edges in the current connection page. */
  edges: Array<SlotEdge>
  /** List of Slot object nodes in the current connection page. */
  nodes: Array<Maybe<Slot>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Slot connection page. */
export type SlotEdge = Edge & {
  __typename?: 'SlotEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the CarePlanActivity object in this edge. */
  node?: Maybe<Slot>
}

/** The clinic for a physical slot. */
export type SlotPracticeResult = None | PracticeHealthcareService

/** A result of the practitioner that a slot references. */
export type SlotPractitionerResult = None | Practitioner

/**  The schedule resource associated with a given slot. */
export type SlotScheduleResult = AuthorizationError | None | Schedule

/** The status of the slot */
export enum SlotStatus {
  /** Busy */
  Busy = 'BUSY',
  /** Busy unavailable */
  BusyUnavailable = 'BUSY_UNAVAILABLE',
  /** Free */
  Free = 'FREE',
}

/**  Specifies the direction of a Sort */
export enum SortOrder {
  /**  Ascending Order */
  Asc = 'ASC',
  /**  Descending Order */
  Desc = 'DESC',
}

/** Start conversation input. */
export type StartConversationInput = {
  /** The list of participants that will engage in this newly created converation. */
  participantIds: Array<ConversationParticipantId>
}

export type StartConversationResult = ChatConversation | ResourceNotFoundError

/** Input for StartUserChallengeAction mutation */
export type StartUserChallengeActionInput = {
  /** The user challenge action id to start */
  userChallengeActionId: Scalars['ID']
}

/** `StartUserChallengeActionResult` is a union for one of the following types. */
export type StartUserChallengeActionResult =
  | AuthorizationError
  | ResourceNotFoundError
  | UserChallenge

/** Input for StartUserChallenge mutation */
export type StartUserChallengeInput = {
  /** The user challenge id to start */
  userChallengeId: Scalars['ID']
}

/** `StartUserChallengeResult` is a union for one of the following types. */
export type StartUserChallengeResult =
  | AuthorizationError
  | ResourceNotFoundError
  | UserChallenge

/**
 * A page/slice of a list of String nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type StringConnection = Connection & {
  __typename?: 'StringConnection'
  /** Edges in the current connection page. */
  edges: Array<StringEdge>
  /** List of String object nodes in the current connection page. */
  nodes: Array<Maybe<Scalars['String']>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a String connection page. */
export type StringEdge = Edge & {
  __typename?: 'StringEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the String object in this edge. */
  node?: Maybe<Scalars['String']>
}

/**  Used to filter on a String */
export type StringFilter = {
  /**  Search over the values in this field  */
  MATCH?: Maybe<StringMatch>
  /**  The field must exactly equal at least one of the given options */
  ONE_OF?: Maybe<Array<Scalars['String']>>
}

/**
 * Used to search for string values. Each value in the query string must be present
 * in the output, though not in the same order.
 *
 * The query is analysed first, which eliminates issues like case matching or punctuation mismatches.
 *
 * This type of searching can be useful when a user is involved and you want less precise matches than a ONE_OF query.
 *
 * We recommend investigating the results first to make sure they meet your use-case, especially if the results will be used
 * in an automated process without a human in the loop.
 */
export type StringMatch = {
  /**  Each word in the query must appear in the output, though they may appear in a different order.  */
  QUERY: Scalars['String']
}

/** Formatting of string with matches. Return from Google Places API */
export type StructuredFormatting = {
  __typename?: 'StructuredFormatting'
  /** add description */
  mainText?: Maybe<Scalars['String']>
  /** add description */
  mainTextMatchedSubstrings?: Maybe<MatchedSubstringConnection>
  /** add description */
  secondaryText?: Maybe<Scalars['String']>
}

/** Whether to suggest or not */
export type SuggestableInput = {
  /** true if the input should be suggested */
  suggest?: Maybe<Scalars['Boolean']>
}

/** Supply Network */
export type SupplyNetwork = {
  __typename?: 'SupplyNetwork'
  /** Feature switch settings of the Supply Network */
  activeFeatureSwitches?: Maybe<StringConnection>
  /** ID */
  id: Scalars['ID']
  /** Name of Supply Network */
  name: Scalars['String']
  /** Priority of Supply Network */
  priority: Scalars['NonNegativeInt']
  /** Region of Supply Network */
  region: Scalars['String']
}

/** Supply Network */
export type SupplyNetworkActiveFeatureSwitchesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * A page/slice of a list of Supply Network nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type SupplyNetworkConnection = Connection & {
  __typename?: 'SupplyNetworkConnection'
  /** Edges in the current connection page. */
  edges: Array<SupplyNetworkEdge>
  /** List of ContactPoint object nodes in the current connection page. */
  nodes: Array<Maybe<SupplyNetwork>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Supple Network connection page */
export type SupplyNetworkEdge = Edge & {
  __typename?: 'SupplyNetworkEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Supply Network object in this edge. */
  node?: Maybe<SupplyNetwork>
}

/** Input for filtering Supply Networks */
export type SupplyNetworksFilter = {
  /** Filters on the name of Supply Networks */
  name?: Maybe<Scalars['String']>
}

/** "Conversation type for a support ticket */
export type SupportTicketConversationConfig = {
  /**
   * entryPoint is an string that indicates where the converstation was started
   * from. This should be used in ticketing systems to group conversations or can
   * be used in analytics.
   * e.g appHomescreen or myHealth
   */
  entryPoint?: Maybe<Scalars['String']>
  /**
   * myHealthMetricId is an string that indicates which metric the converstation
   * was started from. This should be used in ticketing systems to group
   * conversations or can be used in analytics.
   * e.g bloodPressure or bloodGlucose
   */
  metricIdentifier?: Maybe<Scalars['String']>
  /** Array of tags to be attached to the support ticket */
  tags?: Maybe<Array<SupportTicketTag>>
  /** Id of the support ticket. If not provided, a new ticket will be created. */
  ticketId?: Maybe<Scalars['String']>
}

/** Support ticket tag, will be attached to support ticket as `name:value` */
export type SupportTicketTag = {
  /** name of the tag */
  name: Scalars['String']
  /** Value of the tag */
  value: Scalars['String']
}

/** Input for symptom messages */
export type SymptomInputMessage = {
  /** symptom user selected */
  id: Scalars['ID']
  /** text user typed to get the symptom */
  text: Scalars['String']
}

/** Object of symptom inputs */
export type SymptomMessageInput = SuggestableInput & {
  __typename?: 'SymptomMessageInput'
  /** set to true to suggest the symptom to the user */
  suggest?: Maybe<Scalars['Boolean']>
}

/** Input for test kits messages */
export type TestKitsConversationData = {
  /** set the debug flag to true to get a debug response */
  debug?: Maybe<Scalars['Boolean']>
  /** order id */
  orderId: Scalars['String']
}

/** input of test kits inputs */
export type TextAndFilesFileInputMessage = {
  /** file content encoded in base64 */
  base64: Scalars['String']
  /** content type of file */
  mediaType: Scalars['String']
}

/** Input for text and files messages */
export type TextAndFilesInputMessage = {
  /** files attached with user answer */
  files: Array<TextAndFilesFileInputMessage>
  /** user answer */
  text: Scalars['String']
}

/** Object of text and files inputs */
export type TextAndFilesMessageInput = SuggestableInput & {
  __typename?: 'TextAndFilesMessageInput'
  /** allowed formats */
  fileFormats: Array<Scalars['String']>
  /** max number of files */
  maxFiles: Scalars['Int']
  /** maximum size of each file in bytes */
  maxFileSize: Scalars['Int']
  /** minimum number of files required */
  minFiles: Scalars['Int']
  /** if true will show suggestions */
  suggest?: Maybe<Scalars['Boolean']>
}

/** Input for text and files messages */
export type TextChoiceInputMessage = {
  /** id of selected choice */
  id: Scalars['ID']
}

/** Input for text messages */
export type TextInputMessage = {
  /** content of text user answer */
  text: Scalars['String']
}

/** Object of text inputs */
export type TextMessageInput = SuggestableInput & {
  __typename?: 'TextMessageInput'
  /** If true will show suggestions */
  suggest?: Maybe<Scalars['Boolean']>
}

/** Object of text and files values */
export type TextMessageValue = {
  __typename?: 'TextMessageValue'
  /** text with explanation what the content mean */
  helpText?: Maybe<Scalars['String']>
  /** content of the message */
  text: Scalars['String']
}

/**  A time period defined by a start and end date/time. */
export type TimePeriod = {
  /**
   * The end of the period.
   * If the end of the period is missing or is before start of the period, a default end date is used.
   * The start may be in the past, and the end date in the future, which means that
   * period is expected/planned to end at that time.
   */
  END?: Maybe<Scalars['DateTime']>
  /**
   * The start of the period. The boundary is inclusive.
   * If absent it defaults to the current time.
   */
  START?: Maybe<Scalars['DateTime']>
}

/**  A timing schedule that specifies an event that may occur multiple times. */
export type Timing = {
  __typename?: 'Timing'
  /**  Event occurs frequency times per period. */
  frequency: Scalars['PositiveInt']
  /**  Event occurs frequency times per period. */
  period: Scalars['Float']
  /**  unit of time. */
  periodUnit: PeriodUnit
}

/**  Trust information about the resource */
export type Trust = {
  __typename?: 'Trust'
  /**  A categorisation of trustworthiness summaring both the status of the resource and who wrote it */
  category?: Maybe<TrustCategory>
  /**  The Trust Class summarises the level of trustworthiness in a way that can be easily filtered on */
  class?: Maybe<TrustClass>
}

/**  A categorisation of trustworthiness summaring both the status of the resource and who wrote it */
export enum TrustCategory {
  /**
   * This resource was asserted by a non-human creator which is supported by robust evidence to demonstrate it
   * functions equal to or better than a human clinician.
   */
  IntelligentAgentMedicalDeviceConfirmed = 'INTELLIGENT_AGENT_MEDICAL_DEVICE_CONFIRMED',
  /**
   * This resource was asserted by a non-human creator, which may or may not use artificial intelligence.
   * The data it was derived from was not confirmed to be true.
   */
  IntelligentAgentMedicalDeviceUnconfirmed = 'INTELLIGENT_AGENT_MEDICAL_DEVICE_UNCONFIRMED',
  /**  This resource was asserted by a non-human creator, which may or may not use artificial intelligence */
  IntelligentAgentOtherConfirmed = 'INTELLIGENT_AGENT_OTHER_CONFIRMED',
  /**
   * This resource was asserted by a non-human creator which is supported by robust evidence to demonstrate it
   * functions equal to or better than a human clinician.
   * The data it was derived from was not confirmed to be true.
   */
  IntelligentAgentOtherUnconfirmed = 'INTELLIGENT_AGENT_OTHER_UNCONFIRMED',
  /**  This data is known to be incorrect. For example it may have been entered in error or be non-conformant. */
  NotTrustworthy = 'NOT_TRUSTWORTHY',
  /**  Data asserted by an Organisation */
  OrganizationConfirmed = 'ORGANIZATION_CONFIRMED',
  /**  Unconfirmed data asserted by an Organisation */
  OrganizationUnconfirmed = 'ORGANIZATION_UNCONFIRMED',
  /**
   * Data captured during an encounter with a member, where a clear expectation has been set that the data will be
   * stored in their records and may be surfaced in future interactions
   */
  PatientConfirmed = 'PATIENT_CONFIRMED',
  /**
   * Data captured during an encounter with a member where there is not a clear expectation that the data will be
   * stored in their records and surfaced in future interactions
   */
  PatientUnconfirmed = 'PATIENT_UNCONFIRMED',
  /**
   * Data asserted by a medical professional. it is confirmed to be an accurate and truthful representation of the
   * patients’ clinical state.
   */
  PractitionerConfirmed = 'PRACTITIONER_CONFIRMED',
  /**
   * This is data which has been entered but is not yet validated or confirmed to be accurate. Examples include
   * provisional diagnoses whilst awaiting for laboratory results, or a provisional diagnostic report that is awaiting
   * validation. Unconfirmed data also includes partial data.
   */
  PractitionerUnconfirmed = 'PRACTITIONER_UNCONFIRMED',
  /**  No trust information is available for this resource */
  Unknown = 'UNKNOWN',
}

/**  The Trust Class summarises the level of trustworthiness in a way that can be easily filtered on */
export enum TrustClass {
  /**
   * This category is reserved for data that is a true and accurate representation of the patient’s clinical state of
   * being or clinical history. The data has been confirmed to have safeguards that ensure the data is accurate and
   * valid.
   */
  High = 'HIGH',
  /**
   * The content of this resource is not suitable for clinical use and has little to no safeguards on the quality.
   * This data is  only suitable for product research or business analytics. It should not be used to direct clinical
   * care
   */
  Low = 'LOW',
  /**
   * This is data that does not meet the strict criteria for high trust, but some governance controls are in place to
   * ensure that the provided data is of a good quality.
   */
  Medium = 'MEDIUM',
  /**
   * The content of this resource does not reflect accurate medical information about the patient.
   * This may, for example, be because it was entered in error, or because it failed to conform to tagged profiles.
   */
  NotTrustworthy = 'NOT_TRUSTWORTHY',
}

/**
 * Filter resources by how trustworthy they are.
 * Note that trust only applies to clinical resources that are asserting disputable facts about a members health.
 * You probably want to use minClass OR categories, not both
 */
export type TrustFilter = {
  /**  A list of categories to return, if you want finer grained control than minClass offers. */
  categories?: Maybe<Array<TrustCategory>>
  /**  Only results with at least this class of trust will be returned */
  minimumClass?: Maybe<TrustClass>
}

/** Input required to unlock a user */
export type UnlockUserInput = {
  /** Email of the user */
  emailAddress: Scalars['EmailAddress']
  /** Is the user a practitioner? */
  isPractitioner: Scalars['Boolean']
}

export type UnlockUserResult = EmailAddressNotFoundError | User

/**
 * An error indicating that there are persistent issues with the request. Retrying
 * the request will not help so manual debugging is needed.
 */
export type UnrecoverableError = Error & {
  __typename?: 'UnrecoverableError'
  /**
   * Message to assist a developer in understanding and fixing the error.
   *
   * Not intended to be shown directly to an end-user.
   */
  errorMessage: Scalars['String']
  /** __typename of the resource which does not exist, if known. */
  resourceTypename?: Maybe<Scalars['String']>
  /** Requested resource ID. */
  unknownId: Scalars['ID']
}

/** Update the patient's default pharmacy in their EHR (currently only supporting Athena) */
export type UpdateDefaultPatientPharmacyInput = {
  patientId: Scalars['ID']
  pharmacyOrganizationId: Scalars['ID']
}

export type UpdateDefaultPatientPharmacyResult = Patient | ResourceNotFoundError

/** Error type for updating GPOrganization */
export type UpdateGpOrganizationError = Error & {
  __typename?: 'UpdateGPOrganizationError'
  /** Error message */
  errorMessage: Scalars['String']
}

/** Input for Updating a GP Organization */
export type UpdateGpOrganizationInput = {
  /** Country code for the fax number */
  faxCountryCode: Scalars['String']
  /**
   * Fax Number of the GP surgery
   * For the simplicity, we added faxNumber directly, instead of a ContactPoint array
   */
  faxNumber: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** Name of the GP surgery */
  name: Scalars['String']
}

/** Result from updating GP Organization */
export type UpdateGpOrganizationResult =
  | GpOrganization
  | UpdateGpOrganizationError

/** Update is read response */
export type UpdateInboxCommunicationIsReadResponse =
  | InboxCommunication
  | ResourceNotFoundError

/** Details to add a contact info to a patient */
export type UpdatePatientAddressInput = {
  /** The content of the address to update to */
  address?: Maybe<AddressInput>
  /** An id of the address to update. */
  id: Scalars['ID']
  /** Whether or not this is the primary address of this type */
  isPrimary?: Maybe<Scalars['Boolean']>
  /** The id of the patient to update. */
  patient: Scalars['ID']
  /** A string to describe the source of the address */
  source?: Maybe<Scalars['String']>
  /** The dateTime at which the address was ingested at the source */
  sourceIngestionTimestamp?: Maybe<Scalars['String']>
  /** A string to describe the status of the address */
  status?: Maybe<Scalars['String']>
}

export type UpdatePatientAddressResult = Patient | ResourceNotFoundError

/** Details to add a contact info to a patient */
export type UpdatePatientEmailAddressInput = {
  /** An email address. */
  emailAddress?: Maybe<Scalars['EmailAddress']>
  /** An id of the email address to update. */
  id: Scalars['ID']
  /** Whether or not this is the primary contact point of this type */
  isPrimary?: Maybe<Scalars['Boolean']>
  /** The id of the patient to update. */
  patient: Scalars['ID']
  /** A string to describe the source of the contact point */
  source?: Maybe<Scalars['String']>
  /** The dateTime at which the contact point was ingested at the source */
  sourceIngestionTimestamp?: Maybe<Scalars['String']>
  /** A string to describe the status of the contact point */
  status?: Maybe<Scalars['String']>
}

export type UpdatePatientEmailAddressResult = Patient | ResourceNotFoundError

/** Details to update a patient. */
export type UpdatePatientInput = {
  /** General information about the patient. */
  info: PatientInfoInput
  /** The id of the patient to update. */
  patient: Scalars['ID']
}

/** Details to add a contact info to a patient */
export type UpdatePatientPhoneNumberInput = {
  /** The id of the phone number to update */
  id: Scalars['ID']
  /** Whether or not this is the primary contact point of this type */
  isPrimary?: Maybe<Scalars['Boolean']>
  /** The id of the patient to update. */
  patient: Scalars['ID']
  /** A phone number. */
  phoneNumber?: Maybe<Scalars['PhoneNumber']>
  /** A string to describe the source of the contact point */
  source?: Maybe<Scalars['String']>
  /** The dateTime at which the contact point was ingested at the source */
  sourceIngestionTimestamp?: Maybe<Scalars['String']>
  /** A string to describe the status of the contact point */
  status?: Maybe<Scalars['String']>
  /** A string to describe the contact point is used. */
  use?: Maybe<Scalars['FhirCode']>
}

export type UpdatePatientPhoneNumberResult = Patient | ResourceNotFoundError

/** Details to update the primary address of a patient. */
export type UpdatePatientPrimaryAddressInput = {
  /** Address associated with this patient. */
  address: AddressInput
  /** The id of the patient to update. */
  patient: Scalars['ID']
}

export type UpdatePatientPrimaryAddressResult = Patient | ResourceNotFoundError

/** Details to update the primary email address of a patient. */
export type UpdatePatientPrimaryEmailAddressInput = {
  /** An email address. */
  emailAddress: Scalars['EmailAddress']
  /** The id of the patient to update. */
  patient: Scalars['ID']
}

export type UpdatePatientPrimaryEmailAddressResult =
  | Patient
  | ResourceNotFoundError

/** Details to update the primary name of a patient. */
export type UpdatePatientPrimaryNameInput = {
  /** A name of a human with text, parts and usage information. */
  name: HumanNameInput
  /** The id of the patient to update. */
  patient: Scalars['ID']
}

export type UpdatePatientPrimaryNameResult = Patient | ResourceNotFoundError

/** Details to update the primary phone number of a patient. */
export type UpdatePatientPrimaryPhoneNumberInput = {
  /** The id of the patient to update. */
  patient: Scalars['ID']
  /** A phone number. */
  phoneNumber: Scalars['PhoneNumber']
}

export type UpdatePatientPrimaryPhoneNumberResult =
  | Patient
  | ResourceNotFoundError

export type UpdatePatientResult = Patient | ResourceNotFoundError

/** Error type for updating pharmacist practitioner */
export type UpdatePharmacistPractitionerError = Error & {
  __typename?: 'UpdatePharmacistPractitionerError'
  /** Error message */
  errorMessage: Scalars['String']
  /** Babylon unique Identifier */
  id: Scalars['ID']
}

/** Input for updating a pharmacist practitioner */
export type UpdatePharmacistPractitionerInput = {
  /** Babylon core-ruby unique Identifier */
  coreRubyId: Scalars['ID']
  /** Babylon unique Identifier */
  id: Scalars['ID']
  /** Pharmacy supplier for the given pharmacist */
  pharmacySupplierId?: Maybe<Scalars['ID']>
  /** Attributes related to portal login */
  portalLoginAttributes?: Maybe<UpdatePharmacistPractitionerPortalLoginAttributesInput>
  /** Region code of the pharmacist */
  regionCode?: Maybe<Scalars['String']>
}

/** Input for updating portal login attributes for pharmacist practitioner */
export type UpdatePharmacistPractitionerPortalLoginAttributesInput = {
  /** Email id of the pharmacist */
  email?: Maybe<Scalars['EmailAddress']>
  /** Babylon unique Identifier */
  id: Scalars['ID']
  /** Whether/Not this Pharmacy is active/blocked */
  isActive?: Maybe<Scalars['Boolean']>
  /** Name of the pharmacist */
  name?: Maybe<Scalars['String']>
  /** Role of the pharmacist */
  roles: Array<Scalars['String']>
}

/** Result from updating a pharmacist practitioner */
export type UpdatePharmacistPractitionerResult =
  | Practitioner
  | UpdatePharmacistPractitionerError

/** Error type for updating pharmacy healthcare services */
export type UpdatePharmacyHealthcareServiceError = Error & {
  __typename?: 'UpdatePharmacyHealthcareServiceError'
  /** Error message */
  errorMessage: Scalars['String']
  /** Babylon unique Identifier */
  id: Scalars['ID']
}

/** Input for updating a pharmacy */
export type UpdatePharmacyHealthcareServiceInput = {
  /** The reason for blocking/deactivating this pharmacy */
  comment?: Maybe<Scalars['String']>
  /** Babylon core-ruby unique Identifier */
  coreRubyId: Scalars['ID']
  /** Fax number of the Pharmacy */
  fax?: Maybe<Scalars['String']>
  /** Babylon unique Identifier */
  id: Scalars['ID']
  /** Whether/Not this Pharmacy is active/blocked */
  isActive?: Maybe<Scalars['Boolean']>
  /** The method Babylon uses to send prescriptions */
  referralMethod?: Maybe<Scalars['String']>
}

/** Result from updating a pharmacy */
export type UpdatePharmacyHealthcareServiceResult =
  | HealthcareService
  | UpdatePharmacyHealthcareServiceError

/** Error type for updating Supply Network */
export type UpdateSupplyNetworkError = Error & {
  __typename?: 'UpdateSupplyNetworkError'
  /** Error message */
  errorMessage: Scalars['String']
}

/** Input for updating a Supply Network */
export type UpdateSupplyNetworkInput = {
  /** Feature switch settings for the Supply Network. It is enough to only pass those ones that are to be changed */
  featureSwitchSettings?: Maybe<Array<CoreRubyFeatureSwitchSettingInput>>
  /** ID of the Supply Network */
  id: Scalars['ID']
  /** Name of the Supply Network */
  name?: Maybe<Scalars['String']>
  /** Priority of the Supply Network */
  priority?: Maybe<Scalars['NonNegativeInt']>
}

/** Result from updating Supply Network */
export type UpdateSupplyNetworkResult = SupplyNetwork | UpdateSupplyNetworkError

/** Input required to update an email for a User */
export type UpdateUserEmailAddressInput = {
  /** The new email address of the User */
  emailAddress: Scalars['EmailAddress']
  /** The ID that uniquely identifies a User */
  user: Scalars['ID']
}

/**
 * A page/slice of a list of Uri nodes, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type UriConnection = Connection & {
  __typename?: 'UriConnection'
  /** Edges in the current connection page. */
  edges: Array<UriEdge>
  /** List of Uri object nodes in the current connection page. */
  nodes: Array<Maybe<Scalars['Uri']>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge in a Uri connection page. */
export type UriEdge = Edge & {
  __typename?: 'UriEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node for the Uri object in this edge. */
  node?: Maybe<Scalars['Uri']>
}

/** A URL based ContactPoint. */
export type UrlContactPoint = ContactPoint & {
  __typename?: 'UrlContactPoint'
  /** @inherit */
  system: ContactPointSystem
  /** Value of the contact point as a URL. */
  url: Scalars['Url']
}

/**  The purpose of the Claim: predetermination, preauthorization, claim.  */
export enum Use {
  /**  The treatment is complete and this represents a Claim for the services.  */
  Claim = 'CLAIM',
  /**  The treatment is proposed and this represents a Pre-authorization for the services.  */
  Preauthorization = 'PREAUTHORIZATION',
  /**  The treatment is proposed and this represents a Pre-determination for the services.  */
  Predetermination = 'PREDETERMINATION',
}

/**  The purpose of the Claim: predetermination, preauthorization, claim.  */
export type UseFilter = {
  /**  The field must match at least one of the given options  */
  ONE_OF?: Maybe<Array<Maybe<Use>>>
}

/**
 * A person who exists in the Babylon platform.
 *
 * For example, a member who registers with Babylon, a clinician who works for a
 * partner or a family member who is linked to a registered member's account.
 */
export type User = {
  __typename?: 'User'
  /** Is the user logged in from mobile? */
  _babylon_isLoggedInFromMobile: Scalars['Boolean']
  /** Get Inbox FHIR Communications */
  _inbox_getInboxCommunications: InboxCommunicationConnection
  /**
   * User is the owner of the family account
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `identities` and `relatedPersons` from the `Patient` type instead: https://platform.ops.babylontech.co.uk/domains/patient/member_relationships.
   */
  accountOwner?: Maybe<Scalars['Boolean']>
  /** The list of active user sessions for the user. */
  activeSessions?: Maybe<UserSessionConnection>
  /**
   * User's postal address. One or more addresses for the person.
   *
   * see: https://www.hl7.org/fhir/person-definitions.html#Person.address
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `addresses` from the `Patient` type instead.
   */
  address?: Maybe<ProfileAddress>
  /**
   * Legacy patient ID for Core Ruby.
   *
   * Not all members will have this property and it is not globally unique, so it
   * should be used for backwards-compatibility only.
   * @deprecated `id` should be used instead of the legacy patient ID
   */
  coreRubyPatientId?: Maybe<Scalars['NonNegativeInt']>
  /**
   * Date and time of user registration
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252).
   */
  creation: AuditEvent
  /**
   * User's date of birth
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `birthDate` from the `Patient` type instead.
   */
  dateOfBirth?: Maybe<Scalars['DateTime']>
  /**
   * User's registered email address
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `telecoms` from the `Patient` type instead.
   */
  email?: Maybe<Scalars['EmailAddress']>
  /** User family account members */
  familyMembers?: Maybe<RelationshipConnection>
  /**
   * User's firstName
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `name` from the `Patient` type instead.
   */
  firstName?: Maybe<Scalars['String']>
  /**
   * User's Administrative Gender
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `gender` from the `Patient` type instead.
   */
  gender?: Maybe<Gender>
  /** @deprecated Supports outdated naming, consumers should make use of _inbox_getInboxCommunications instead */
  getInboxCommunications: InboxCommunicationConnection
  /**
   * User's healthcare identifier
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `identifiers` from the `Patient` type instead.
   */
  healthcareIdentifier?: Maybe<HealthcareIdentifier>
  /**
   * Unique identifier of the User resource
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252).
   */
  id: Scalars['ID']
  /**
   * An identifier for this patient.
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `identifiers` from the `Patient` type instead.
   */
  identifiers?: Maybe<Array<UserIdentifier>>
  /** The concrete identity (e.g. patient) associated with the user. */
  identity?: Maybe<UserIdentityResult>
  /**
   * User's identity check
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `_VerificationResult_id` and `_VerificationResult_status` from the `Patient` type instead.
   */
  identityCheck?: Maybe<IdentityCheck>
  /** Access token generated by third-party Identity Providers */
  idpToken?: Maybe<UserIdpTokenResult>
  /**
   * User got an invitation but hasn't accepted
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `_activationStatus` from the `Patient` type instead.
   */
  invitationWasSentButNotAccepted?: Maybe<Scalars['Boolean']>
  /** Has the User enabled Multi-factor Authentication? */
  isMFAActive: Scalars['Boolean']
  /**
   * User's lastname
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `name` from the `Patient` type instead.
   */
  lastName?: Maybe<Scalars['String']>
  /**
   * Date and time user fields were last updated
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252).
   */
  lastUpdate: AuditEvent
  /** A list of MFA Enrollments. */
  mfaEnrollments?: Maybe<MfaEnrollmentConnection>
  /**
   * User is a minor (based on their region's definition of minors age)
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `isMinor` from the `Patient` type instead.
   */
  minor?: Maybe<Scalars['Boolean']>
  /**
   * FHIR Specifications - HumanName
   *
   * see: http://www.hl7.org/fhir/person-definitions.html#Person.name
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `name` from the `Patient` type instead.
   */
  name: ProfileHumanName
  /**
   * User's national id
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `identifiers` from the `Patient` type instead.
   */
  nationalId?: Maybe<Scalars['String']>
  /**
   * CoreRubyUserID of the account owner
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `identities` and `relatedPersons` from the `Patient` type instead: https://platform.ops.babylontech.co.uk/domains/patient/member_relationships.
   */
  ownerId?: Maybe<Scalars['NonNegativeInt']>
  /**
   * User member details page link
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252).
   */
  patientMembershipLink?: Maybe<Scalars['String']>
  /**
   * User's phone number International country code
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `telecoms` from the `Patient` type instead.
   */
  phoneCountryCode?: Maybe<Scalars['String']>
  /**
   * User's phone number
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `telecoms` from the `Patient` type instead.
   */
  phoneNumber?: Maybe<Scalars['PhoneNumber']>
  /**
   * User prefered consumer network
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `preferredMembership` from the `Patient` type instead.
   */
  preferredConsumerNetworkUuid?: Maybe<Scalars['String']>
  /**
   * User's public healthcare admission
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `_NhsEnrollmentRequest_id`, `_NhsEnrollmentRequest_isLocationChecked`, `_NhsEnrollmentRequest_status` and `_NhsEnrollmentRequest_isVerificationOverwrittenByOps` from the `Patient` type instead.
   */
  publicHealthcareAdmission?: Maybe<PublicHealthcareAdmission>
  /**
   * User is queued for activation
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `_NhsEnrollmentResponse_isQueuedForActivation` from the `Patient` type instead.
   */
  queuedForActivation?: Maybe<Scalars['Boolean']>
  /** User family account dependants */
  relationships?: Maybe<RelationshipConnection>
  /**
   * A list of contact details (e.g. a telephone number or an email address) by which the individual may be contacted.
   *
   * see: http://www.hl7.org/fhir/person-definitions.html#Person.telecom
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `telecoms` from the `Patient` type instead.
   */
  telecom?: Maybe<Array<ProfileContactPoint>>
}

/**
 * A person who exists in the Babylon platform.
 *
 * For example, a member who registers with Babylon, a clinician who works for a
 * partner or a family member who is linked to a registered member's account.
 */
export type User_Inbox_GetInboxCommunicationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A person who exists in the Babylon platform.
 *
 * For example, a member who registers with Babylon, a clinician who works for a
 * partner or a family member who is linked to a registered member's account.
 */
export type UserActiveSessionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * A person who exists in the Babylon platform.
 *
 * For example, a member who registers with Babylon, a clinician who works for a
 * partner or a family member who is linked to a registered member's account.
 */
export type UserGetInboxCommunicationsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A person who exists in the Babylon platform.
 *
 * For example, a member who registers with Babylon, a clinician who works for a
 * partner or a family member who is linked to a registered member's account.
 */
export type UserIdpTokenArgs = {
  system: Scalars['String']
}

/**
 * A person who exists in the Babylon platform.
 *
 * For example, a member who registers with Babylon, a clinician who works for a
 * partner or a family member who is linked to a registered member's account.
 */
export type UserMfaEnrollmentsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/**
 * A person who exists in the Babylon platform.
 *
 * For example, a member who registers with Babylon, a clinician who works for a
 * partner or a family member who is linked to a registered member's account.
 */
export type UserTelecomArgs = {
  filter?: Maybe<Scalars['String']>
}

/**
 * A given challenge for a given user (created using the `createPatientUserChallenge` mutation).
 *
 * The challenge description and title are inferred from the challenge template, and the challenge actions are populated
 * from the challenge template. As action events are completed or hidden, the corresponding challenge actions are updated
 * appropriately (namely by recording the time at which the action was completed or hidden).
 *
 * Note that the parent status field is as described in the `UserChallengeStatus` type.
 */
export type UserChallenge = {
  __typename?: 'UserChallenge'
  /** The actions associated with this challenge. */
  actions: UserChallengeActionConnection
  /** The challenge template used to create this challenge. */
  challengeTemplate: UserChallengeTemplate
  /** If provided, the time at which the final challenge action was completed. */
  completed?: Maybe<Scalars['DateTime']>
  /** The time at which the challenge was accepted/created. */
  created: Scalars['DateTime']
  /** If provided, the time at which this challenge was hidden. */
  hidden?: Maybe<Scalars['DateTime']>
  /** The primary key of this challenge. */
  id: Scalars['ID']
  /** The user this challenge is associated with (as a result of invoking the `createPatientUserChallenge` user mutation). */
  person: UserChallengePersonResult
  /** If present, the time at which this challenge was started by the client. */
  started?: Maybe<Scalars['DateTime']>
  /** The current status of the challenge. */
  status: UserChallengeStatus
}

/**
 * A given challenge for a given user (created using the `createPatientUserChallenge` mutation).
 *
 * The challenge description and title are inferred from the challenge template, and the challenge actions are populated
 * from the challenge template. As action events are completed or hidden, the corresponding challenge actions are updated
 * appropriately (namely by recording the time at which the action was completed or hidden).
 *
 * Note that the parent status field is as described in the `UserChallengeStatus` type.
 */
export type UserChallengeActionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** A user challenge action tracks the specific state of a given action. */
export type UserChallengeAction = {
  __typename?: 'UserChallengeAction'
  /** The parent user challenge associated with this action. */
  challenge: UserChallenge
  /** The challenge action template this action is based off of (containing information like the title, description, icon, etc). */
  challengeActionTemplate: UserChallengeActionTemplate
  /** If present, the time at which the action was completed by the client. */
  completed?: Maybe<Scalars['DateTime']>
  /** The time at which the action was created. */
  created: Scalars['DateTime']
  /** If present, the time at which the action was hidden by the client. */
  hidden?: Maybe<Scalars['DateTime']>
  /** The primary key of the action. */
  id: Scalars['ID']
  /** If present, the time at which the action was started by the client. */
  started?: Maybe<Scalars['DateTime']>
  /** The current status of the action. */
  status: UserChallengeStatus
}

/** A collection of user challenge actions. */
export type UserChallengeActionConnection = Connection & {
  __typename?: 'UserChallengeActionConnection'
  /** Edges in the current connection page. */
  edges: Array<UserChallengeActionEdge>
  /** List of `UserChallengeAction` nodes in the current connection page. */
  nodes: Array<Maybe<UserChallengeAction>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge for a user challenge action. */
export type UserChallengeActionEdge = Edge & {
  __typename?: 'UserChallengeActionEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserChallengeAction>
}

/**
 * A use challenge action template is a sub-component of a user challenge template.
 *
 * It defines the specific actions that make up the bigger Challenge.
 */
export type UserChallengeActionTemplate = {
  __typename?: 'UserChallengeActionTemplate'
  /** The icon to be used for the challenge action. */
  actionType: ActionType
  /** The timestamp this challenge action template was created at. */
  created: Scalars['DateTime']
  /** The description of the challenge action template. */
  description?: Maybe<LocalizedString>
  /** The primary key of the challenge action template */
  id: Scalars['ID']
  /** The logical name of the subflow to complete the action. */
  subflowIdentifier?: Maybe<Scalars['String']>
  /** The title of the challenge action template. */
  title?: Maybe<LocalizedString>
  /** The UserChallengeTemplate associated with this action. */
  userChallengeTemplate: UserChallengeTemplate
}

/** A collection of user challenge action templates. */
export type UserChallengeActionTemplateConnection = Connection & {
  __typename?: 'UserChallengeActionTemplateConnection'
  /** Edges in the current connection page. */
  edges: Array<UserChallengeActionTemplateEdge>
  /** List of `UserChallengeActionTemplate` nodes in the current connection page. */
  nodes: Array<Maybe<UserChallengeActionTemplate>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge for a user challenge action template. */
export type UserChallengeActionTemplateEdge = Edge & {
  __typename?: 'UserChallengeActionTemplateEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserChallengeActionTemplate>
}

/** A collection of user challenges. */
export type UserChallengeConnection = Connection & {
  __typename?: 'UserChallengeConnection'
  /** Edges in the current connection page. */
  edges: Array<UserChallengeEdge>
  /** List of `UserChallenge` nodes in the current connection page. */
  nodes: Array<Maybe<UserChallenge>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** Result of a delete challenge mutation */
export type UserChallengeDeleted = {
  __typename?: 'UserChallengeDeleted'
  /** The user this challenge is associated with. */
  person: UserChallengeDeletedPersonResult
}

/** Result of a delete challenge mutation */
export type UserChallengeDeletedPersonResult = Patient

/** An edge for a user challenge. */
export type UserChallengeEdge = Edge & {
  __typename?: 'UserChallengeEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserChallenge>
}

/** User challenges are currently supported for patients only, but may become applicable to practitioners in the future too. */
export type UserChallengePersonResult = Patient

/** Used to specify the status of the challenge. */
export enum UserChallengeStatus {
  /** The initial state of all user challenges. */
  Accepted = 'ACCEPTED',
  /** The state when all underlying actions have been hidden or completed. */
  Completed = 'COMPLETED',
  /** The state when the client decides to manually hide the user challenge, irrelevant of the underlying action state. */
  Hidden = 'HIDDEN',
  /** The state when the user acknowledges that the challenge has been started. */
  Started = 'STARTED',
}

/**
 * The template from which user challenges are created.
 *
 * When a client "accepts" a challenge for a user, an associated `UserChallenge`
 * will be created and populated, as per that template.
 */
export type UserChallengeTemplate = {
  __typename?: 'UserChallengeTemplate'
  /** The list of action templates that define this challenge. */
  actions: UserChallengeActionTemplateConnection
  /** The time at which this challenge template was created. */
  created: Scalars['DateTime']
  /** A short description of the challenge template. */
  description?: Maybe<LocalizedString>
  /** The primary key for the challenge template */
  id: Scalars['ID']
  /** Title of this challenge template (to be used by developers and product challenge administrators). */
  title?: Maybe<LocalizedString>
}

/**
 * The template from which user challenges are created.
 *
 * When a client "accepts" a challenge for a user, an associated `UserChallenge`
 * will be created and populated, as per that template.
 */
export type UserChallengeTemplateActionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** A collection of user challenge templates. */
export type UserChallengeTemplateConnection = Connection & {
  __typename?: 'UserChallengeTemplateConnection'
  /** Edges in the current connection page. */
  edges: Array<UserChallengeTemplateEdge>
  /** List of `UserChallengeTemplate` nodes in the current connection page. */
  nodes: Array<Maybe<UserChallengeTemplate>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge for a user challenge template. */
export type UserChallengeTemplateEdge = Edge & {
  __typename?: 'UserChallengeTemplateEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserChallengeTemplate>
}

/**
 * A page/slice of a list of nodes of type `User`, allowing for standardised pagination/navigation of the list.
 *
 * See the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm) for guidance on how to use.
 */
export type UserConnection = Connection & {
  __typename?: 'UserConnection'
  /** Edges in the current connection page. */
  edges: Array<UserEdge>
  /** Direct access to the the `User` nodes in the current connection page. */
  nodes: Array<Maybe<User>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/**
 * An edge in a connection page for a node of type `User`.
 *
 * See the [GraphQL Edges Specification](https://relay.dev/graphql/connections.htm#sec-Edges) for guidance on how to use.
 */
export type UserEdge = Edge & {
  __typename?: 'UserEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /** Node connected by the edge. */
  node: User
}

/** Something a patient sets out to accomplish */
export type UserGoal = {
  __typename?: 'UserGoal'
  /** Date of UserGoal creation */
  created: Scalars['DateTime']
  /** ID of UserGoal */
  id: Scalars['ID']
  /** Returns connection for `UserGoalActions`. */
  nextBestActions: UserGoalActionConnection
  /** Template used to instantiate this `UserGoal`. */
  template: UserGoalTemplate
}

/** Something a patient sets out to accomplish */
export type UserGoalNextBestActionsArgs = {
  after?: Maybe<Scalars['Cursor']>
  first: Scalars['NonNegativeInt']
}

/** Recommended action for patient to take. */
export type UserGoalAction = {
  __typename?: 'UserGoalAction'
  /** Date of UserGoalAction creation */
  created: Scalars['DateTime']
  /** User goal that this user goal action is contained by */
  goal: UserGoal
  /** Goal action template that this user goal action is associated with */
  goalActionTemplate: UserGoalActionTemplate
  /** ID of UserGoalAction */
  id: Scalars['ID']
}

/** A paginated list of `GoalAction`s for the given User. */
export type UserGoalActionConnection = Connection & {
  __typename?: 'UserGoalActionConnection'
  /** Edges in the current connection page. */
  edges: Array<UserGoalActionEdge>
  /** List of `UserGoalAction` nodes in the current connection page. */
  nodes: Array<Maybe<UserGoalAction>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge for a `GoalAction`. */
export type UserGoalActionEdge = Edge & {
  __typename?: 'UserGoalActionEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserGoalAction>
}

/** Action specific attributes for `UserGoalActionTemplate`. Eg. media links */
export type UserGoalActionMeta = {
  __typename?: 'UserGoalActionMeta'
  /** Optional media URL related to this `UserGoalActionMeta`. */
  _url: Scalars['String']
}

/** Actions associated to a `UserGoal`. */
export type UserGoalActionTemplate = {
  __typename?: 'UserGoalActionTemplate'
  /** Level to which this action is applicable. Example: PROGRESSING */
  _lifestyleCategoryLevel?: Maybe<Scalars['String']>
  /** Text describing an action for this user goal action template. Ex: "Walk for 30 minutes daily." */
  action: LocalizedString
  /** Condition for which this action is applicable. */
  condition: Scalars['String']
  /** Date of UserGoalActionTemplate creation */
  created: Scalars['DateTime']
  /** Links current action template to `UserGoalTemplate`. */
  goalTemplate: UserGoalTemplate
  /** ID of UserGoalActionTemplate */
  id: Scalars['ID']
  /** Category for which this action is applicable. */
  lifestyleCategory?: Maybe<Scalars['String']>
  /** Action specific attributes. Eg. media links */
  meta: UserGoalActionMeta
  /** Patient Activation Measure required to qualify for this action. */
  pamLevel: Scalars['NonNegativeInt']
}

/** A connection for a `UserGoalActionTemplate`. */
export type UserGoalActionTemplateConnection = Connection & {
  __typename?: 'UserGoalActionTemplateConnection'
  /** Edges in the current connection page. */
  edges: Array<UserGoalActionTemplateEdge>
  /** List of `UserGoalActionTemplate` nodes in the current connection page. */
  nodes: Array<Maybe<UserGoalActionTemplate>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** A edge for a `LifestyleCategoryLevelTemplate`. */
export type UserGoalActionTemplateEdge = Edge & {
  __typename?: 'UserGoalActionTemplateEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserGoalActionTemplate>
}

/** `UserGoalConnection` is a connection. */
export type UserGoalConnection = Connection & {
  __typename?: 'UserGoalConnection'
  /** Edges in the current connection page. */
  edges: Array<UserGoalEdge>
  /** List of `UserGoal` nodes in the current connection page. */
  nodes: Array<Maybe<UserGoal>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge for a `UserGoal`. */
export type UserGoalEdge = Edge & {
  __typename?: 'UserGoalEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserGoal>
}

/** Top level entity encapsulating all actions for a given goal. */
export type UserGoalTemplate = {
  __typename?: 'UserGoalTemplate'
  /** Date of UserGoalTemplate creation */
  created: Scalars['DateTime']
  /** ID of UserGoalTemplate */
  id: Scalars['ID']
  /** Text describing a goal. Example: "Reduce my anxiety." */
  summary: LocalizedString
  /** User goal action templates associated with this user goal template */
  userGoalActionTemplates: UserGoalActionTemplateConnection
}

/** Top level entity encapsulating all actions for a given goal. */
export type UserGoalTemplateUserGoalActionTemplatesArgs = {
  after?: Maybe<Scalars['Cursor']>
  first?: Maybe<Scalars['NonNegativeInt']>
}

/** A connection for a `UserGoalTemplate`. */
export type UserGoalTemplateConnection = Connection & {
  __typename?: 'UserGoalTemplateConnection'
  /** Edges in the current connection page. */
  edges: Array<UserGoalTemplateEdge>
  /** List of `UserGoalTemplate` nodes in the current connection page. */
  nodes: Array<Maybe<UserGoalTemplate>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge for a `UserGoalTemplate`. */
export type UserGoalTemplateEdge = Edge & {
  __typename?: 'UserGoalTemplateEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserGoalTemplate>
}

/** An identifier - identifies some entity uniquely and unambiguously. Typically this is used for business identifiers. */
export type UserIdentifier = {
  __typename?: 'UserIdentifier'
  /**
   * Establishes the namespace for the value - that is, a URL that describes a set values that are unique.
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `identifiers` from the `Patient` type instead.
   */
  system?: Maybe<Scalars['Uri']>
  /**
   * The portion of the identifier typically relevant to the user and which is unique within the context of the system.
   * @deprecated Do not use this field as the `User` type will be repurposed in the future (see ticket API-252). `Use `identifiers` from the `Patient` type instead.
   */
  value?: Maybe<Scalars['String']>
}

export type UserIdentityResult = Patient | Practitioner

export type UserIdpTokenResult =
  | IdpToken
  | IdpTokenError
  | ResourceNotFoundError

/** Patient specific level for a given lifestyle category. Example: Activity, PROGRESSING, 65 */
export type UserLifestyleCategoryLevel = {
  __typename?: 'UserLifestyleCategoryLevel'
  /** Date of UserLifestyleCategoryLevel creation */
  created: Scalars['DateTime']
  /** ID of UserLifestyleCategoryLevel */
  id: Scalars['ID']
  /** Lifestyle Category Level Template that this user lifestyle category level is associated with */
  lifestyleCategoryLevelTemplate: LifestyleCategoryLevelTemplate
  /** Most recent calculated score for this `UserLifestyleCategoryLevel` */
  score: Scalars['NonNegativeInt']
  /** Reflects the last time a lifestyleCategoryLevelTemplate or a score changes. */
  updated: Scalars['DateTime']
}

/** `UserLifestyleCategoryLevelConnection` is a connection. */
export type UserLifestyleCategoryLevelConnection = Connection & {
  __typename?: 'UserLifestyleCategoryLevelConnection'
  /** Edges in the current connection page. */
  edges: Array<UserLifestyleCategoryLevelEdge>
  /** List of `UserLifestyleCategoryLevel` nodes in the current connection page. */
  nodes: Array<Maybe<UserLifestyleCategoryLevel>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** An edge for a `GoalAction`. */
export type UserLifestyleCategoryLevelEdge = Edge & {
  __typename?: 'UserLifestyleCategoryLevelEdge'
  /**
   * Cursor pointing at this edge.
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserLifestyleCategoryLevel>
}

export type UserResult = ResourceNotFoundError | User

/** Information about the current User session */
export type UserSession = {
  __typename?: 'UserSession'
  /** The OAuth 2 Client ID of the party that is authorized to use this session on behalf of the user. */
  _iam_authorizedPartyId: Scalars['ID']
  /** The type of the session's primary credential. */
  credentialType?: Maybe<UserSessionCredentialType>
  /**
   * The name of the device the session is active on.
   *
   * This name is intended to be easily recognisable by the user. Null will be
   * returned if the required information is not available within the user's token.
   */
  deviceName?: Maybe<LocalizedString>
  /**
   * The id of the `Usersession`.
   *
   * The id will be `null` if `credentialType` is set to `ACCESS_TOKEN` (our only
   * source of data becomes the token which does not contain this information).
   */
  id?: Maybe<Scalars['ID']>
  /** The User who is the subject of the session */
  user?: Maybe<User>
}

/** A page/slice of a list of nodes of type `UserSession`, allowing for standardised pagination/navigation of the list. */
export type UserSessionConnection = Connection & {
  __typename?: 'UserSessionConnection'
  /** Edges in the current connection page. */
  edges: Array<UserSessionEdge>
  /** List of `UserSession` nodes in the current connection page. */
  nodes: Array<Maybe<UserSession>>
  /** Metadata about the current connection page. */
  pageInfo: PageInfo
  /**
   * The total number of items in the underlying data set.
   *
   * May be null if it is infeasible to efficiently calculate this value.
   */
  totalCount?: Maybe<Scalars['NonNegativeInt']>
}

/** The type of credential the active session is based on. */
export enum UserSessionCredentialType {
  /** Authenticated using an access token. */
  AccessToken = 'ACCESS_TOKEN',
  /** Authenticated using a public key. */
  PublicKey = 'PUBLIC_KEY',
  /** Authenticated using a refresh token. */
  RefreshToken = 'REFRESH_TOKEN',
  /** Authenticated using a refresh token where token rotation is enabled. */
  RotatingRefreshToken = 'ROTATING_REFRESH_TOKEN',
}

/** An edge in a connection page for a node of type `UserSession`. */
export type UserSessionEdge = Edge & {
  __typename?: 'UserSessionEdge'
  /**
   * Cursor pointing at this edge.
   *
   * Can be passed to the pagination parameters of the connection query to get the edges before or after this argument.
   */
  cursor: Scalars['Cursor']
  /**
   * Node connected by the edge.
   *
   * May be null if the value is unknown or if there was an error loading the identifier.
   */
  node?: Maybe<UserSession>
}

/** Object of web action message */
export type WebviewActionMessageData = {
  __typename?: 'WebviewActionMessageData'
  /** disclaimer text show to user before execution action */
  disclaimer?: Maybe<Scalars['String']>
  /** url to open */
  url: Scalars['String']
}
