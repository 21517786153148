import React, { ReactNode, useState, useCallback } from 'react'
import { useProductConfig } from '@babylon/product-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons'

import { Button, Card, Grid, Cell, Text } from '@babylon/core-ui'

import styles from './TimelineItem.module.css'

import { ConsultationTypeName } from '../../types/ConsultationTypes'
import TimelineItemInfo from './TimelineItemInfo'
import TimelineItemDetails from './TimelineItemDetails'

library.add(faEllipsisV)

export interface Item {
  consultant?: string
  consultation_type?: ConsultationTypeName
  allowed_mediums?: ConsultationTypeName[]
  consumer_network?: string
  duration_minutes: number
  duration?: string
  moreDetailsText?: string
  notes_for_member?: string
  service_type?: {
    id: string
    name: string
    member_instructions: string
  }
  timestamp?: string
  type: 'INVITATION' | 'APPOINTMENT'
}

interface Props {
  actions?: ReactNode
  item: Item
  status?: string
}

const TimelineItem = ({ item, actions, status }: Props) => {
  const [actionsOpen, setActionsOpen] = useState()
  const [detailsOpen, setDetailsOpen] = useState()
  const onActionsClick = useCallback(() => {
    setActionsOpen(!actionsOpen)
  }, [actionsOpen, setActionsOpen])
  const onMoreDetailsClick = useCallback(() => {
    setDetailsOpen(!detailsOpen)
  }, [detailsOpen, setDetailsOpen])

  const { getProp } = useProductConfig()
  const digitalInviteExtensionEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteExtensionEnabled'
  )

  const digitalInviteEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteEnabled'
  )

  const hasDetails =
    !!item?.notes_for_member ||
    !!item?.service_type?.name ||
    !!item?.service_type?.member_instructions

  let isActionsEnabled = !!actions

  if (digitalInviteExtensionEnabled || digitalInviteEnabled) {
    isActionsEnabled =
      isActionsEnabled &&
      item?.allowed_mediums?.length === 1 &&
      item?.allowed_mediums?.includes('physical')
  }

  return (
    <Card>
      <Grid columns={3}>
        <Cell width={2}>
          <TimelineItemInfo
            consultant={item.consultant}
            consultationType={item.consultation_type}
            allowedMediums={item.allowed_mediums}
            consumerNetwork={item.consumer_network}
            moreDetails={hasDetails ? 'Show appointment details' : undefined}
            onMoreDetails={onMoreDetailsClick}
            timestamp={item.timestamp}
            duration={item.duration}
            type={item.type}
          />
        </Cell>
        <Cell width={1} className={styles.side}>
          {status && <Text className={styles.status}>{status}</Text>}
          {isActionsEnabled && (
            <div className={styles.actions}>
              <Button
                icon={
                  <FontAwesomeIcon
                    icon={['fas', 'ellipsis-v']}
                    size="lg"
                    color="#757575"
                  />
                }
                intent="secondary"
                onClick={onActionsClick}
              />
              {actionsOpen && (
                <div className={styles.actionsList}>{actions}</div>
              )}
            </div>
          )}
        </Cell>
        {hasDetails && detailsOpen && (
          <Cell width={2}>
            <TimelineItemDetails
              serviceType={item?.service_type?.name}
              reason={item.notes_for_member}
              notes={item?.service_type?.member_instructions}
            />
          </Cell>
        )}
      </Grid>
    </Card>
  )
}

export default TimelineItem
