import { defineMessages } from 'react-intl'

const stateBaseId = 'timeline_test_results_status'

export default defineMessages({
  PENDING: {
    id: `${stateBaseId}.ongoing`,
    defaultMessage: 'On going',
  },
  COMPLETED: {
    id: `${stateBaseId}.completed`,
    defaultMessage: 'Completed',
  },
})
