import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import { TabGroup, Tab } from '@babylon/babylon-forms/widgets'

import { envFlag } from '@babylon/babylon-env'

import PageHeader from '@/components/PageHeader'

import Prescriptions from './prescriptions'
import Templates from './templates'
import Failed from './FailedContainer'
import UploadTemplates from './UploadTemplates'

import styles from './styles.module.scss'

const DISPLAY_NHS_REPEAT = envFlag('DISPLAY_NHS_REPEAT')
const DISPLAY_SRX_FAILED_PRESCRIPTIONS = envFlag(
  'DISPLAY_SRX_FAILED_PRESCRIPTIONS'
)

const PrescriptionsAndTemplates = ({ history, location: { pathname } }) => (
  <div>
    <PageHeader>Prescriptions</PageHeader>
    <TabGroup
      value={pathname}
      onChange={(_, route) => history.push(route)}
      className={styles.tabs}
    >
      <Tab name="/admin/prescriptions" title="Prescriptions" />
      {DISPLAY_SRX_FAILED_PRESCRIPTIONS && (
        <Tab name="/admin/prescriptions/failed" title="Failed prescriptions" />
      )}
      {DISPLAY_NHS_REPEAT && (
        <Tab
          name="/admin/prescriptions/templates"
          title="Repeat prescriptions"
        />
      )}
      {DISPLAY_NHS_REPEAT && (
        <Tab
          name="/admin/prescriptions/upload-templates"
          title="Repeat Template Report Upload"
        />
      )}
    </TabGroup>
    <Switch>
      <Route
        path="/admin/prescriptions/upload-templates"
        component={UploadTemplates}
      />
      <Route path="/admin/prescriptions/failed" component={Failed} />
      <Route path="/admin/prescriptions/templates" component={Templates} />
      <Route component={Prescriptions} />
    </Switch>
  </div>
)

export default withRouter(PrescriptionsAndTemplates)
