import React, { useState, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useField, useFormikContext } from 'formik'
import { Label, Alert } from '@babylon/core-ui'

import { Text, Card, ButtonSimple, Input } from '@babylon/medkit'

import SelectAppointmentQuery from './SelectAppointmentQuery'
import ServiceTypeSelection from './ServiceTypeSelection'
import { useDebounce, useFormikError } from '../../hooks'
import { LoadingContainer } from '../../components'
import styles from './SelectAppointment.module.css'
import ArrangeAppointmentForm from './ArrangeAppointmentTypes'
import { UnexpectedError } from '../../Utils'

const filterServiceTypes = (serviceTypes: ServiceType[], filter: string) => {
  const lowerCaseFilter = filter.toLowerCase().trim()

  return serviceTypes.filter(({ name }) =>
    name.toLowerCase().trim().includes(lowerCaseFilter)
  )
}

interface SelectAppointmentProps {
  memberId: string
  onNextStep?: (validFn?: () => void, failFn?: () => void) => void
  setEnrichedWithServiceType: (value: ArrangeAppointmentForm) => void
}

export default function SelectAppointment({
  memberId,
  onNextStep,
  setEnrichedWithServiceType,
}: SelectAppointmentProps) {
  const [filter, setFilter] = useState('')
  const { values, setFieldValue } = useFormikContext<ArrangeAppointmentForm>()
  const {
    errorMessage,
    setAllErrorMessage,
    clearErrorMessage,
  } = useFormikError()
  const { data, loading: loadingServiceTypes } = useQuery(
    SelectAppointmentQuery,
    {
      variables: {
        memberId,
        includingDigital: true,
      },
    }
  )

  const [{ value }] = useField('service_type_uuid')

  const debounced = useDebounce(filter, 300)
  // TODO address the eslint error below
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const serviceTypes = data?.patient?.service_types ?? []

  const selectedServiceType = serviceTypes.find(
    (serviceType) => serviceType.uuid === value
  )

  const filteredServiceTypes = useMemo(
    () => filterServiceTypes(serviceTypes, debounced),
    [serviceTypes, debounced]
  )

  const onValidStep = () => {
    setEnrichedWithServiceType(values)
  }

  const handleNextStepClick = () => {
    if (onNextStep) {
      onNextStep(onValidStep, setAllErrorMessage)
    }
  }

  return (
    <>
      <UnexpectedError
        visible={!!errorMessage.length}
        onClose={clearErrorMessage}
      >
        {errorMessage.map((error) => (
          <div>{error}</div>
        ))}
      </UnexpectedError>
      <Text variant="body">Please select an appointment type:</Text>
      <Card className={styles.card}>
        <Label htmlFor="Search">
          <Text variant="body" className={styles.filterLabelText}>
            Search:
          </Text>
          <Input
            className={styles.filterInput}
            name="Search"
            value={filter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFilter(e.target.value)
            }
          />
        </Label>
        <LoadingContainer fill loading={loadingServiceTypes}>
          <ServiceTypeSelection
            serviceTypes={filteredServiceTypes}
            onSelect={(serviceType) => {
              setFieldValue(
                'duration_minutes',
                serviceType?.default_duration_minutes
              )
              setFieldValue('service_type_name', serviceType.name)
              setFieldValue('allowed_mediums', serviceType.allowed_mediums)

              if (serviceType?.preferred_medium) {
                setFieldValue('preferred_medium', serviceType.preferred_medium)
              }

              setFieldValue('intimate', !!serviceType?.intimate)
              setFieldValue('chaperone_required', !!serviceType?.intimate)
            }}
          />
        </LoadingContainer>
      </Card>
      <div className={styles.nextSteps}>
        <ButtonSimple onClick={handleNextStepClick}>Next</ButtonSimple>
        {selectedServiceType?.member_instructions && (
          <Alert
            className={styles.memberInstructions}
            intent="primary"
            title={selectedServiceType.member_instructions}
          />
        )}
      </div>
    </>
  )
}
