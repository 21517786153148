import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Formik, Form } from 'formik'
import {
  Button,
  Card,
  Cell,
  FormikDatePicker,
  FormikInput,
  Grid,
} from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'

import { isDate, isValid as isValidDate } from 'date-fns'
import { MemberOpsModuleName } from '../../../../enums'

import messages from './MembersSearchForm.messages'
import styles from './styles.module.scss'

interface ClearButtonProps {
  onClick(): void
}

const ClearButton = ({ onClick }: ClearButtonProps) => {
  const fm = useFormatMessage()

  return (
    <button
      data-testid="member-search-clear"
      type="button"
      className={styles.ClearButton}
      onClick={onClick}
    >
      {fm(messages.clear)}
    </button>
  )
}

const SearchButton = ({ loading }: Pick<MembersSearchFormProps, 'loading'>) => {
  const fm = useFormatMessage()

  return (
    <Button
      testId="member-search-submit"
      type="submit"
      disabled={loading}
      className={styles.SubmitButton}
    >
      {fm(messages.search)}
    </Button>
  )
}
export interface MembersSearchFormProps {
  loading: boolean
  onClear(): void
  onSubmit(params: object): void
  goToCoreRuby: VoidFunction
  fieldValues?: object
}

function sanitizeInputs(inputs) {
  return Object.entries(inputs).reduce((acc, [fieldName, value]) => {
    if (typeof value === 'string') {
      acc[fieldName] = value.trim()
    }

    if (isDate(value) && isValidDate(value)) {
      acc[fieldName] = value
    }

    if (value === '') {
      acc[fieldName] = undefined
    }

    return acc
  }, {})
}

const MembersSearchForm = ({
  loading = false,
  onClear,
  onSubmit,
  fieldValues,
  goToCoreRuby,
}: MembersSearchFormProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.searchForm,
  })
  const fm = useFormatMessage()
  const { getProp } = useProductConfig()
  const legacySearchToggleEnabled = getProp(
    'memberOperations',
    'legacySearchToggleEnabled'
  )
  const memberSearchIdentifierValueEnabled = getProp(
    'memberOperations',
    'memberSearchIdentifierValueEnabled'
  )

  return (
    <Card className={styles.Form}>
      {legacySearchToggleEnabled && (
        <button
          type="button"
          className={styles.switchButton}
          data-testid="use-old-portal-button"
          onClick={goToCoreRuby}
        >
          {fm(messages.use_old_portal)}
        </button>
      )}
      <Formik
        initialValues={fieldValues ?? {}}
        enableReinitialize
        onSubmit={(inputs) => {
          onSubmit(sanitizeInputs(inputs))
          trackClick({
            elementName: 'search-button',
            elementType: TrackingElementType.button,
          })
        }}
        onReset={() => {
          onClear()
          trackClick({
            elementName: 'clear-button',
            elementType: TrackingElementType.button,
          })
        }}
      >
        {({ handleSubmit, handleReset }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Grid gap={20} columns={12} responsive>
              <Cell width={10}>
                <Grid flow="row" columns={12} rows={2} gap={20}>
                  {memberSearchIdentifierValueEnabled && (
                    <Cell width={12}>
                      <FormikInput
                        type="text"
                        id="identifierValue"
                        name="identifierValue"
                        label={fm(messages.identifier_value)}
                      />
                    </Cell>
                  )}

                  <Cell width={4}>
                    <FormikInput
                      type="text"
                      id="firstName"
                      name="firstName"
                      label={fm(messages.first_name)}
                    />
                  </Cell>

                  <Cell width={1} />

                  <Cell width={4}>
                    <FormikInput
                      type="text"
                      id="lastName"
                      name="lastName"
                      label={fm(messages.last_name)}
                    />
                  </Cell>
                  <Cell width={1} />

                  {!memberSearchIdentifierValueEnabled && (
                    <Cell width={2}>
                      <FormikInput
                        type="text"
                        id="coreRubyPatientId"
                        name="coreRubyPatientId"
                        label={fm(messages.babylon_id)}
                      />
                    </Cell>
                  )}

                  <Cell width={3}>
                    <FormikDatePicker
                      id="dateOfBirth"
                      name="dateOfBirth"
                      locale={navigator.language}
                      label={fm(messages.date_of_birth)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Cell>

                  <Cell width={2}>
                    <FormikInput
                      type="text"
                      id="postCode"
                      name="postCode"
                      label={fm(messages.post_code)}
                    />
                  </Cell>

                  <Cell width={2}>
                    <FormikInput
                      type="phone"
                      id="phoneNumber"
                      name="phoneNumber"
                      label={fm(messages.full_phone_number)}
                    />
                  </Cell>

                  <Cell width={3}>
                    <FormikInput
                      type="email"
                      id="email"
                      name="email"
                      label={fm(messages.email)}
                    />
                  </Cell>

                  <Cell width={2}>
                    <FormikInput
                      type="text"
                      id="nationalId"
                      name="nationalId"
                      label={fm(messages.public_healthcare_identifier)}
                    />
                  </Cell>
                </Grid>
              </Cell>
              <Cell width={2} className={styles.ActionsCell}>
                <ClearButton onClick={handleReset} />
                <SearchButton loading={loading} />
              </Cell>
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default MembersSearchForm
