import { useQuery } from '@apollo/client'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import React, { createContext, useState, useContext, ReactNode } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { MemberOpsModuleName } from '../../../../..'
import { useSnackBar } from '../../../../../hooks'
import { PATIENT_TIMEZONE_QUERY } from './queries'
import messages from './TimezoneProvider.messages'

type TimezoneToShow = 'agent' | 'patient'

export type TimezoneContextType = {
  timezone: string
  timezoneToShow: TimezoneToShow
  toggleTimezone: () => void
  loading: boolean
  error: boolean
  memberAndAgentSameTimezone: boolean
}

type TimezoneState = {
  timezone: string
  timezoneToShow: TimezoneToShow
}
export const agentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const defaultTimezoneContext: TimezoneContextType = {
  timezone: agentTimezone,
  timezoneToShow: 'agent',
  toggleTimezone: () => {},
  loading: false,
  error: false,
  memberAndAgentSameTimezone: false,
}

const TimezoneContext = createContext<TimezoneContextType>(
  defaultTimezoneContext
)

export const useTimezoneContext = (): TimezoneContextType =>
  useContext(TimezoneContext)

export const TimezoneContextProvider = ({
  children,
  patientId,
}: {
  children: ReactNode
  patientId: string
}) => {
  const defaultTimezoneState: TimezoneState = {
    timezone: agentTimezone,
    timezoneToShow: 'agent',
  }
  const fm = useFormatMessage()
  const { setSnackbarMessage } = useSnackBar()

  const { data, loading, error } = useQuery(PATIENT_TIMEZONE_QUERY, {
    variables: { patientId },
  })

  const [
    { timezone, timezoneToShow },
    setTimezoneData,
  ] = useState<TimezoneState>(defaultTimezoneState)
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })

  const patientTimezone = data?.patient?.addressBasedTimezone

  const toggleTimezone = () => {
    if (!patientTimezone) {
      return
    }

    setTimezoneData((state) => {
      const isPatientTimezone = state.timezoneToShow === 'patient'
      const newTimezone = isPatientTimezone ? agentTimezone : patientTimezone
      const newTimezoneToShow = isPatientTimezone ? 'agent' : 'patient'

      setSnackbarMessage(
        fm(
          isPatientTimezone
            ? messages.members_time_disabled
            : messages.members_time_enabled,
          { timezone: newTimezone.replace(/_/gi, ' ') }
        )
      )

      trackClick({
        elementName: isPatientTimezone
          ? 'members-timezone-disabled'
          : 'members-timezone-enabled',
        elementType: TrackingElementType.button,
      })

      return {
        timezone: newTimezone,
        timezoneToShow: newTimezoneToShow,
      }
    })
  }

  return (
    <TimezoneContext.Provider
      value={{
        timezone,
        toggleTimezone,
        timezoneToShow,
        loading,
        error: !!error || patientTimezone === null,
        memberAndAgentSameTimezone: patientTimezone === agentTimezone,
      }}
    >
      {children}
    </TimezoneContext.Provider>
  )
}
