export const statuses = {
  archived: ['EXPIRED', 'PRINTED', 'REJECTED', 'VOIDED', 'DISPENSED'],
  notArchived: [
    'APPROVAL_PENDING',
    'APPROVED',
    'CONFIRMED_RECEIVED',
    'FAILED_CREATING_PRESCRIPTION_PDF',
    'E_PRESCRIPTION_CODE_SENT',
    'FAX_NUMBER_MISSING',
    'FAX_FAILED_WILL_RETRY',
    'FAX_PERMANENTLY_FAILED',
    'FAX_SENDING',
    'FAXED',
    'PATIENT_DECISION_PENDING',
    'PRESCRIPTION_CREATED',
    'PHARMACY_SELECTED',
    'PHARMACY_SUPPLIER_SELECTED',
    'DELIVERY_PENDING',
    'PRESCRIPTION_REQUESTED',
    'ISSUED',
    'EXTERNAL_PROVIDER_PENDING',
  ],
}
