import React from 'react'
import { format, parseISO } from 'date-fns'
import { useQuery } from '@apollo/client'
import Summary from '../Summary'
import Messages from '../Messages'
import { CONVERSATION_QUERY, MESSAGES_QUERY } from './queries'
import styles from './Transcript.module.scss'

const useConversation = (userId: string, id: string) => {
  const { data } = useQuery(CONVERSATION_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { userId, conversationId: id },
  })

  return data?.chatscriptConversation
}

const useMessages = (userId: string, id: string) => {
  const { data } = useQuery(MESSAGES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { userId, conversationId: id },
  })

  return data?.chatscriptMessages?.messages
}

interface TranscriptProps {
  patient: { id: string; uuid: string; first_name: string; last_name: string }
  conversationId: string
}

const Transcript = ({ patient, conversationId }: TranscriptProps) => {
  const conversation = useConversation(patient.uuid, conversationId)
  const messages: ChatscriptMessage[] | undefined = useMessages(
    patient.uuid,
    conversationId
  )

  if (conversation && messages) {
    const agents = [
      ...new Set<string>(
        messages
          .filter(({ author }) => !!author)
          .map(({ author }) => author?.name)
      ),
    ]
    const startedAt = parseISO(messages[0].sentAt)
    const endedAt = parseISO(messages[messages.length - 1].sentAt)
    const patientFullname = `${patient.last_name.toLocaleUpperCase()}, ${
      patient.first_name
    }`
    return (
      <div className={styles.Transcript}>
        <Summary
          startedAt={format(startedAt, 'dd MMM yyyy, HH:mm')}
          endedAt={format(endedAt, 'dd MMM yyyy, HH:mm')}
          patientFullname={patientFullname}
          patientId={patient.id}
          agents={agents}
        />
        <Messages messages={messages} patientFullname={patientFullname} />
      </div>
    )
  }

  return null
}

export default Transcript
