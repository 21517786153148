import { gql } from '@apollo/client'

type ConsultantType = {
  id: string
  uuid: string
  name: string
  avatar_full_url: string
}
export interface ClinicianInformationDataType {
  consultant: ConsultantType
}

export default gql`
  query GetClinicianInformation($id: ID!) {
    consultant(id: $id) {
      id
      uuid
      name
      avatar_full_url
    }
  }
`
