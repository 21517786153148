import { defineMessages } from 'react-intl'

const baseId = 'assessment_early_exit_view'

export default defineMessages({
  back: {
    id: `${baseId}.back`,
    defaultMessage: 'Back to assessment',
  },
  instruction: {
    id: `${baseId}.instruction`,
    defaultMessage:
      'Submit this assessment to provide clinical support to this member.\n\nOnce you submit this assessment, their medical record will be updated.',
  },
  summary: {
    id: `${baseId}.summary`,
    defaultMessage: 'View patient assessment summary',
  },
  submit: {
    id: `${baseId}.submit`,
    defaultMessage: 'Submit assessment',
  },
})
