import React from 'react'
import { confirmable, createConfirmation } from 'react-confirm'
import { Button } from '@babylon/babylon-forms/forms'
import { Row } from '@babylon/babylon-forms/widgets'

import { ModalOverlay } from '@babylon/member-operations'

import styles from './styles.module.scss'

const ConfirmDialog = ({ message, okLabel, cancelLabel, cancel, proceed }) => (
  <ModalOverlay className={styles.dim} visible onRequestClose={cancel}>
    <div className={styles.dialog}>
      <p>{message}</p>
      <Row className={styles.buttonRow}>
        <Button alert onClick={proceed} label={okLabel} />
        <Button outline onClick={() => cancel()} label={cancelLabel} />
      </Row>
    </div>
  </ModalOverlay>
)

const dialog = confirmable(ConfirmDialog)
const confirm = createConfirmation(dialog, 1)
const showConfirmDialog = (
  message,
  okLabel = 'Yes',
  cancelLabel = 'No'
) => () => confirm({ message, okLabel, cancelLabel })

export default showConfirmDialog
