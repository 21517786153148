/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type GetPatientConsumerNetworkQueryVariables = Types.Exact<{
  patientId: Types.Scalars['ID']
}>

export type GetPatientConsumerNetworkQuery = {
  patientConsumerNetworks: Array<
    Types.Maybe<
      Pick<
        Types.PatientConsumerNetwork,
        'id' | 'active' | 'gp_at_hand' | 'is_preferred'
      > & {
        consumer_network?: Types.Maybe<
          Pick<Types.ConsumerNetwork, 'uuid' | 'name'>
        >
      }
    >
  >
}

export const GetPatientConsumerNetworkDocument = gql`
  query getPatientConsumerNetwork($patientId: ID!) {
    patientConsumerNetworks(id: $patientId) {
      id
      active
      consumer_network {
        uuid
        name
      }
      gp_at_hand
      is_preferred
    }
  }
`
export function useGetPatientConsumerNetworkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientConsumerNetworkQuery,
    GetPatientConsumerNetworkQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPatientConsumerNetworkQuery,
    GetPatientConsumerNetworkQueryVariables
  >(GetPatientConsumerNetworkDocument, baseOptions)
}
export function useGetPatientConsumerNetworkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientConsumerNetworkQuery,
    GetPatientConsumerNetworkQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPatientConsumerNetworkQuery,
    GetPatientConsumerNetworkQueryVariables
  >(GetPatientConsumerNetworkDocument, baseOptions)
}
export type GetPatientConsumerNetworkQueryHookResult = ReturnType<
  typeof useGetPatientConsumerNetworkQuery
>
export type GetPatientConsumerNetworkLazyQueryHookResult = ReturnType<
  typeof useGetPatientConsumerNetworkLazyQuery
>
export type GetPatientConsumerNetworkQueryResult = Apollo.QueryResult<
  GetPatientConsumerNetworkQuery,
  GetPatientConsumerNetworkQueryVariables
>
