import React from 'react'

import { LegacyFormikDropdown, Tooltip } from '@babylon/core-ui'
import {
  getOptionsFromDefaultDuration,
  setRecommendedOption,
  mapDurationsToOptions,
} from './DurationDropdownUtils'

interface Props {
  durations?: number[]
  defaultDuration?: number
  fieldName?: string
  setRecommended?: boolean
}

const DurationDropdown = ({
  durations,
  defaultDuration = 10,
  fieldName = 'duration_minutes',
  setRecommended = false,
}: Props) => {
  const durationOptions = durations
    ? mapDurationsToOptions(durations)
    : getOptionsFromDefaultDuration(defaultDuration)

  if (!durationOptions.find(({ value }) => value === defaultDuration)) {
    durationOptions.push({
      value: defaultDuration,
      label: `${defaultDuration} minutes`,
    })
  }

  const dropdownOptions = setRecommended
    ? setRecommendedOption(durationOptions, defaultDuration)
    : durationOptions

  return (
    <LegacyFormikDropdown
      id={fieldName}
      name={fieldName}
      label={
        <>
          Duration:{' '}
          <Tooltip
            info="The default duration has been set by the head office & clinicians"
            placement="right"
            overlayStyle={{ width: 300 }}
          />
        </>
      }
      options={dropdownOptions}
    />
  )
}

export default DurationDropdown
