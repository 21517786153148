import promptReason from '@/components/ReasonDialog'

const defaultReasons = [
  'Not available for private prescribing',
  'Medicine is discontinued in the region',
  'Medicine requires close monitoring',
]

const promptDrugBlacklistReason = promptReason(defaultReasons)

export default promptDrugBlacklistReason
