import { gql } from '@apollo/client'

export default gql`
  query GetServiceTypes($includingDigital: Boolean) {
    serviceTypes(including_digital: $includingDigital) {
      uuid
      name
    }
  }
`
