import { defineMessages } from 'react-intl'

const baseId = 'switch_pharmacists_banner'

export default defineMessages({
  new_pharmacists_switch_banner: {
    id: `${baseId}.new_pharmacists_switch_banner`,
    defaultMessage:
      'NEW PHARMACISTS PAGE - Click to go to the old pharmacists page. You can switch back at any time.',
  },
})
