import React from 'react'

import IsTranslationLanguageRequired from '../IsTranslationLanguageRequired'
import TranslationLanguage from '../TranslationLanguage'

import styles from './styles.module.css'

interface RequirementsFieldsProps {
  languageRequested?: string
}

const RequirementsFields = ({ languageRequested }: RequirementsFieldsProps) =>
  languageRequested ? (
    <div
      className={styles.requirementsContainer}
      data-testid="booking-appointment-requirements-fields"
    >
      <label htmlFor="requirements">Requirements</label>
      <div id="requirements">
        <fieldset>
          <div className={styles.translationLanguageAttributes}>
            <IsTranslationLanguageRequired />
          </div>
          <div className={styles.translationLanguageAttributes}>
            <TranslationLanguage />
          </div>
        </fieldset>
      </div>
    </div>
  ) : null

export default RequirementsFields
