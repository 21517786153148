enum ConsultationTypes {
  physical = 'Face to face',
  video = 'Video',
  voice = 'Voice',
  text = 'Text',
}

export type ConsultationTypeName = keyof typeof ConsultationTypes

export const mediums = Object.keys(ConsultationTypes) as ConsultationTypeName[]

export const isPhysicalConsultation = (value?: ConsultationTypeName): boolean =>
  Boolean(value) && value === 'physical'

export default ConsultationTypes
