import React, { useState, useEffect } from 'react'
import { isAfter, isBefore } from 'date-fns'
import {
  Card,
  DatePicker,
  Label,
  TimeRangePicker,
  Text,
} from '@babylon/core-ui'

import { useQueryParams } from '../../Utils'
import { useReallocateShiftErrorManager } from '../ReallocateShiftErrorManager'

import styles from './ReallocateShiftFilter.module.css'

export default function ReallocateShiftFilter() {
  const today = new Date()
  const { getQueryParams, setQueryParams } = useQueryParams()
  const { resetErrorMessage } = useReallocateShiftErrorManager()

  const {
    date: selected,
    shift_start: shiftStart,
    shift_end: shiftEnd,
  } = getQueryParams()

  return (
    <Card title="Choose shift">
      <Label htmlFor="reallocation-date-selector">Date</Label>
      <DatePicker
        dateFormat="d MMMM yyyy"
        id="reallocation-date-selector"
        data-testid="reallocation-date-selector"
        minDate={today}
        onChange={(date: Date) =>
          setQueryParams({
            date,
          })
        }
        selected={new Date(selected as string)}
        wrapperClassName={styles.ReallocationDateSelectorWrapper}
      />
      <Label htmlFor="reallocation-time-selector">Time</Label>
      <ShiftTimeSelector
        startShift={new Date(`${selected} ${shiftStart}`)}
        endShift={new Date(`${selected} ${shiftEnd}`)}
        onChange={({
          startDate,
          endDate,
        }: {
          startDate: Date | null
          endDate: Date | null
        }): void => {
          setQueryParams({
            shift_start: startDate,
            shift_end: endDate,
          })
          resetErrorMessage()
        }}
      />
    </Card>
  )
}

const ShiftTimeSelector = ({
  startShift,
  endShift,
  onChange,
}: {
  startShift: Date
  endShift: Date
  onChange
}) => {
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (isAfter(startShift, endShift)) {
      setErrorMessage('Start time must be before the end time.')
    } else if (isBefore(startShift, new Date())) {
      setErrorMessage('Start time cannot be in the past.')
    } else if (errorMessage) {
      /*
       * Clear error message only when there's an error
       * message already visible.
       * Avoids extra render when there's no message visible from the start
       */
      setErrorMessage('')
    }
  }, [endShift, errorMessage, startShift])

  return (
    <>
      <TimeRangePicker
        data-testid="reallocation-time-selector"
        startDate={startShift}
        endDate={endShift}
        hasError={!!errorMessage}
        onChange={onChange}
        timeIntervals={5}
      />
      {!!errorMessage && (
        <Text color="error" size="small">
          {errorMessage}
        </Text>
      )}
    </>
  )
}
