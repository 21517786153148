import { gql } from '@apollo/client'
import ConsultationTypes from '../../types/ConsultationTypes'

export default gql`
  mutation CreateServiceType($input: CreateServiceTypeInput!) {
    createServiceType(input: $input) {
      uuid
      name
      member_facing_name
      member_description
      us_ehr_appointment_type
      default_duration_minutes
      member_instructions
      allowed_mediums
      preferred_medium
      common
    }
  }
`

export interface CreateServiceTypeInput {
  default_duration_minutes?: number
  member_instructions?: string
  name: string
  common?: boolean
  allowed_mediums?: ConsultationTypes[]
  preferred_medium?: ConsultationTypes
  member_facing_name?: string
  member_description?: string
  us_ehr_appointment_type?: string
}
