import { defineMessages } from 'react-intl'

const baseId = 'assessment_view'

export default defineMessages({
  percentageComplete: {
    id: `${baseId}.percentage_complete`,
    defaultMessage: '{percentageComplete}% complete',
  },
})
