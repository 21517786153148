import React from 'react'
import { SvgProps } from '../../../app'

const TextConsultationIcon = ({
  height = 24,
  width = 24,
  className,
  color = 'currentColor',
}: SvgProps) => (
  <svg
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
  >
    <g>
      <rect
        x="3.75"
        y="3.75"
        width="16.5"
        height="16.5"
        rx="1.25"
        stroke={color}
        fill="none"
        strokeWidth="1.5"
      />
      <rect x="7" y="11" width="2" height="2" rx="0.5" fill={color} />
      <rect x="7" y="15" width="2" height="2" rx="0.5" fill={color} />
      <rect x="7" y="7" width="10" height="2" rx="0.5" fill={color} />
      <rect x="10" y="11" width="7" height="2" rx="0.5" fill={color} />
      <rect x="10" y="15" width="7" height="2" rx="0.5" fill={color} />
    </g>
  </svg>
)

export default TextConsultationIcon
