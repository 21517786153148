/* eslint-disable jsx-a11y/no-onchange, no-shadow, react/prop-types, jsx-a11y/label-has-for */

import React from 'react'
import cx from 'classnames'
import { Button } from '@babylon/babylon-forms/forms'
import { Select } from '@babylon/babylon-forms/widgets'

import ListView from '@/components/old-forms/ListView'
import SearchBox from '@/components/old-forms/SearchBox'
import Pagination from '@/components/old-forms/Pagination'
import Icon from '@/components/Icon'

import FileUpload from './FileUpload'

import styles from './styles.module.scss'

export const DrugListItemView = ({
  item: {
    id,
    name,
    activeIngredients,
    strength,
    pharmaceuticalForm,
    generic,
    drugRegions,
  },
  onEdit,
  onAudits,
  onBlacklist,
}) => (
  <tr>
    <td>
      <strong className={styles.highlight}>{name}</strong>
      <div>{activeIngredients.map(({ name }) => name).join(', ')}</div>
    </td>
    <td>{strength}</td>
    <td>{pharmaceuticalForm}</td>
    <td>
      {generic ? (
        <span className={cx(styles.badge, styles.generic)}>GENERIC</span>
      ) : (
        <span className={cx(styles.badge, styles.branded)}>BRANDED</span>
      )}
    </td>
    <td>
      {drugRegions.map(
        ({ blacklisted, region: { regionCode } }) =>
          blacklisted && (
            <span
              key={regionCode}
              className={cx(styles.badge, styles.blacklisted)}
            >
              <button
                title="Unblackist"
                onClick={onBlacklist(id, regionCode, false)}
              >
                <Icon name="allow" />
              </button>
              {regionCode}
            </span>
          )
      )}
    </td>
    <td>
      {drugRegions.map(({ blacklisted, region: { regionCode } }) => (
        <span
          key={regionCode}
          className={cx(styles.badge, blacklisted && styles.noButton)}
        >
          {blacklisted ? null : (
            <button
              title="Blackist"
              onClick={onBlacklist(id, regionCode, true)}
            >
              <Icon name="ban" />
            </button>
          )}
          {regionCode}
        </span>
      ))}
    </td>
    <td className={styles.actions}>
      <button onClick={() => onEdit && onEdit(id)}>
        <Icon name="edit" /> Edit
      </button>
      <button onClick={() => onAudits && onAudits(id)}>
        <Icon name="logs" /> Audits
      </button>
    </td>
  </tr>
)
const DrugsView = ({
  data: {
    loading,
    adminDrugs: { items, totalElements },
  },
  regions,
  varSize,
  setSize,
  varPage,
  setPage,
  varSort,
  lastChangedVariable,
  onAdd,
  downloadRegion,
  updateDownloadRegion,
  downloadCSV,
  uploadCSV,
  ...props
}) => {
  const regionList = regions && regions.prescriptionRegions

  return (
    <div className={styles.drugsView}>
      <div className={styles.searchBar}>
        <SearchBox
          className={styles.searchBox}
          {...props}
          loading={loading && lastChangedVariable === 'varSearchPhrase'}
        />
        <label>
          Page size:{' '}
          <select
            onChange={(event) => setSize(parseInt(event.target.value))}
            value={varSize}
          >
            <option value="10"> 10</option>
            <option value="20"> 20</option>
            <option value="50"> 50</option>
            <option value="100">100</option>
          </select>
        </label>
        <button className={styles.addButton} onClick={onAdd}>
          <Icon name="add" /> Add new drug
        </button>
      </div>
      <ListView
        {...props}
        className={styles.listView}
        sortState={varSort}
        data={items}
        ItemView={DrugListItemView}
        loading={loading && lastChangedVariable === 'varSort'}
      />
      <div className={styles.listFooter}>
        <div className={styles.download}>
          {regionList ? (
            <Select
              autocomplete
              optionsAbove
              small
              value={downloadRegion}
              onChange={(_, value) => updateDownloadRegion(value)}
              options={regionList}
              labelKey="name"
              valueKey="regionCode"
            />
          ) : null}
          {regionList ? (
            <Button
              type="button"
              small
              onClick={downloadCSV}
              disabled={!downloadRegion}
            >
              Download CSV
            </Button>
          ) : null}
          {regionList ? (
            <FileUpload onUpload={uploadCSV} disabled={!downloadRegion}>
              Upload CSV
            </FileUpload>
          ) : null}
          {regionList ? null : (
            <span className={styles.error}>
              CSV download is not available at the moment
            </span>
          )}
        </div>
        <Pagination
          {...props}
          className={styles.pagination}
          totalElements={totalElements}
          size={varSize}
          page={varPage}
          onPageClick={setPage}
          loading={loading && lastChangedVariable === 'varPage'}
        />
      </div>
    </div>
  )
}

export default DrugsView
