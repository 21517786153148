import { AuthClient, getAuthClient } from '@babylon/web-platform-utils-auth'
import { logout } from '@babylon/babylon-auth'
import { envVar } from '@babylon/babylon-env'

const newLogout = async () => {
  const authClient: AuthClient = getAuthClient()
  await authClient.logout()
}

const ENABLE_NEW_AUTH = envVar('ENABLE_NEW_AUTH') === 'true' // envVar doesn't accept to boolean

export const useLogout = () => {
  return () => {
    if (ENABLE_NEW_AUTH) {
      newLogout()
    } else {
      logout()
    }
  }
}
