import React, { useMemo, useState } from 'react'
import { AutoSuggest } from '@babylon/web-platform-ui'

import {
  CardInterface,
  ChatscriptMessageInput,
  ChatscriptMessageInputCard,
  QuestionInterface,
  QuestionOptionInterface,
  SuggestionInterface,
} from '../../../constants/types'
import { ChatscriptMessageInputType } from '../../../constants/enums'
import useSymptomSearch from './useSymptomSearch'
import AdvicePanel from './AdvicePanel'
import CommonSearches from './CommonSearches'
import NoResults from './NoResults'
import SearchConditions from './SearchConditions'
import SelectedSuggestion from './SelectedSuggestion'
import TransitionWrapper from './TransitionWrapper'

function isCardInput(
  input: ChatscriptMessageInput
): input is ChatscriptMessageInputCard {
  return input.type === ChatscriptMessageInputType.Card
}

const formatCards = (
  additionalInputs: ChatscriptMessageInput[]
): CardInterface[] =>
  additionalInputs.filter(isCardInput).map((cardInput) => cardInput.params)

const AutoSuggestInput = ({
  handleCallToAction,
  loading,
  onSubmit,
  question,
}: Props) => {
  const [
    selectedSuggestion,
    setSelectedSuggestion,
  ] = useState<SuggestionInterface>()
  const [inputValue, setInputValue] = useState('')
  const [isFetching, searchSymptoms, suggestions] = useSymptomSearch()

  const cards = useMemo<CardInterface[]>(() => {
    if (!question.additionalInputs) {
      return []
    }

    return formatCards(question.additionalInputs)
  }, [question])

  const updateInputValue = (query: string) => {
    setSelectedSuggestion(undefined)
    setInputValue(query)
    searchSymptoms(query)
  }

  const handleSuggestionSelect = (suggestionItem: any) => {
    if (!suggestionItem) {
      setInputValue('')
      setSelectedSuggestion(undefined)
      return
    }

    const newSuggestion = suggestions.find(
      (suggestion) => suggestion.id === suggestionItem.value
    )

    setInputValue(newSuggestion?.text || '')
    setSelectedSuggestion(newSuggestion)
  }

  const handleSubmit = (suggestion: SuggestionInterface) => {
    onSubmit({
      id: suggestion.id,
      text: suggestion.text,
      textInternal: suggestion.textInternal,
    })
  }

  const showNoResults =
    !isFetching && suggestions.length === 0 && inputValue.length > 1

  const options = inputValue
    ? suggestions.map((suggestion) => ({
        hintText: suggestion.description,
        value: suggestion.id,
        label: suggestion.text,
        labelInternal: suggestion.textInternal,
      }))
    : []

  return (
    <>
      <AutoSuggest
        id="auto-suggest"
        inputValue={inputValue}
        isDisabled={loading}
        isLoading={isFetching}
        menuIsOpen={
          suggestions.length > 0 && !selectedSuggestion && inputValue.length > 1
        }
        label=""
        options={options}
        onChange={updateInputValue}
        onSelect={handleSuggestionSelect}
        setInputValue={(query) => updateInputValue(query as string)}
        noOptionsMessage={() => ''}
        menuPortalTarget={document.getElementById('chatbot-web-overlay-root')}
        clearOnBlur={false}
        // @ts-ignore
        filterOption={null}
      />
      <NoResults
        handleCallToAction={handleCallToAction}
        isShown={showNoResults}
      />
      {selectedSuggestion && (
        <SelectedSuggestion
          cards={cards}
          handleCallToAction={handleCallToAction}
          loading={loading}
          onClick={() => handleSubmit(selectedSuggestion)}
          suggestion={selectedSuggestion}
        />
      )}
      <TransitionWrapper showChildren={!inputValue.length}>
        <CommonSearches onClick={updateInputValue} />
        <AdvicePanel cards={cards} handleCallToAction={handleCallToAction} />
        <SearchConditions
          handleCallToAction={handleCallToAction}
          isLoading={loading}
        />
      </TransitionWrapper>
    </>
  )
}

interface Answer {
  id: string
  text?: string
  textInternal?: string
}

interface Props {
  handleCallToAction: (option: QuestionOptionInterface) => void
  loading: boolean
  onSubmit: (answer: Answer) => void
  question: QuestionInterface
}

export default AutoSuggestInput
