import React from 'react'
import { LoadingHeader, LoadingBlock } from '../../../../LoadingContent'
import styles from './LoadingRelatedPersonsCard.module.scss'

const LoadingRelatedPersonsCard = () => (
  <div className={styles.LoadingRelatedPersonsCard}>
    <LoadingHeader />
    <LoadingBlock className={styles.LoadingRelatedPersonsCard__block} />
  </div>
)

export default LoadingRelatedPersonsCard
