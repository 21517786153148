import { defineMessages } from 'react-intl'

const baseId = 'member_more_details'

export default defineMessages({
  town_of_birth: {
    id: `${baseId}.town_of_birth`,
    defaultMessage: 'Town of birth',
  },
  country_of_birth: {
    id: `${baseId}.country_of_birth`,
    defaultMessage: 'Country of birth',
  },
  arrival_date: {
    id: `${baseId}.arrival_date`,
    defaultMessage: 'Arrival date',
  },
  timezone: {
    id: `${baseId}.timezone`,
    defaultMessage: 'Time zone',
  },
  previous_addresses: {
    id: `${baseId}.previous_addresses`,
    defaultMessage: 'Previous addresses',
  },
  emergency_contacts: {
    id: `${baseId}.emergency_contacts`,
    defaultMessage: 'Emergency contacts',
  },
  disability: {
    id: `${baseId}.disability`,
    defaultMessage: 'Disability',
  },
  armed_forces_enlisting_date: {
    id: `${baseId}.armed_forces_enlisting_date`,
    defaultMessage: 'Armed forces enlisting date',
  },
  informed_consent_date: {
    id: `${baseId}.informed_consent_date`,
    defaultMessage: 'Informed Consent signed on',
  },
  medication_history_auth_consent_date: {
    id: `${baseId}.medication_history_auth_consent_date`,
    defaultMessage: 'Medication History Authorization signed on',
  },
  gp_name: {
    id: `${baseId}.gp_name`,
    defaultMessage: 'GP name',
  },
  gp_address: {
    id: `${baseId}.gp_address`,
    defaultMessage: 'Clinic address',
  },
  previous_healthcare_authorities: {
    id: `${baseId}.previous_healthcare_authorities`,
    defaultMessage: 'Previous healthcare authorities',
  },
  gp_surgery_name: {
    id: `${baseId}.gp_surgery_name`,
    defaultMessage: 'Clinic name',
  },
  gp_surgery_phone_number: {
    id: `${baseId}.gp_surgery_phone_number`,
    defaultMessage: 'Clinic phone number',
  },
})
