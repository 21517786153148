import { gql } from '@apollo/client'

export default gql`
  query GetConsultantsDuration {
    professions {
      key
      default_slot_duration_minutes
      alternative_slot_durations_minutes
    }
  }
`
