const validator = require('validator')

// CAUTION: this function mutates its argument.
function addNegatedValidators(functions) {
  // For each `is` validator we're adding a negated one: `isNot`.
  // For example, for `isIn()` we add `isNotIn` with negated logic.
  Object.entries(functions)
    .filter(([name]) => name.startsWith('is'))
    .reduce(
      (result, [name, fn]) =>
        Object.assign(result, {
          [name.replace('is', 'isNot')](...params) {
            return !fn(...params)
          },
        }),
      functions
    )
}

validator.required = (value) =>
  typeof value === 'string'
    ? value.trim() !== ''
    : Array.isArray(value)
    ? value.length
    : value

validator.isSentence = (
  value = '',
  { min = 2, max = Number.POSITIVE_INFINITY } = {}
) => {
  const str = value.trim()
  const length = str === '' ? 0 : str.split(/\s+/).length
  return length >= min && length <= max
}

addNegatedValidators(validator)

const finalMethods = Object.keys(validator)
  .filter(
    (name) =>
      name.startsWith('is') ||
      ['required', 'matches', 'contains', 'equals'].includes(name)
  )
  .reduce((functions, name) => {
    const fn = validator[name]
    functions[name] = (msg, ...params) => (value) =>
      fn(value, ...params) ? true : msg || 'Validation error'
    return functions
  }, {})

const {
  required,
  isInt,
  isFloat,
  isNotIn,
  isLength,
  isURL,
  isCurrency,
  isEmail,
} = finalMethods

export {
  required,
  isInt,
  isFloat,
  isNotIn,
  isLength,
  isURL,
  isCurrency,
  isEmail,
}
