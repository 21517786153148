import { useFormatMessage } from '@babylon/intl'
import React, { useState } from 'react'
import { ConfirmationModal, KebabMenuRenderOptions } from '@babylon/core-ui'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { useMutation } from '@apollo/client'
import { IdentityVerificationStatus } from '../../../..'
import messages from './ProfileHeader.messages'
import { OVERRIDE_IDENTITY_CHECK_GQL } from './queries'
import { fullPatientFields, PATIENT_QUERY } from '../../../../../queries'
import { MemberOpsModuleName } from '../../../../..'

export interface OverrideIdentityCheckButtonProps
  extends KebabMenuRenderOptions {
  patientId: string
  identityCheck: IdentityCheck | null
  setSnackbarMessage: (m: string) => void
}

export const OverrideIdentityCheckButton = ({
  patientId,
  identityCheck,
  menuItemProps,
  setSnackbarMessage,
}: OverrideIdentityCheckButtonProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profilesHeader,
  })
  const fm = useFormatMessage()
  const [showModal, setShowModal] = useState(false)
  const [overrideIdentityCheck] = useMutation(OVERRIDE_IDENTITY_CHECK_GQL, {
    onCompleted: () => {
      setSnackbarMessage(fm(messages.identity_check_overridden_snack_message))
      setShowModal(false)
    },
    refetchQueries: [
      {
        query: PATIENT_QUERY(fullPatientFields),
        variables: {
          patientId,
        },
      },
    ],
  })

  if (
    identityCheck &&
    (identityCheck.status == IdentityVerificationStatus.Succeeded ||
      identityCheck.status == IdentityVerificationStatus.Pending)
  ) {
    return null
  }

  return (
    <>
      <ConfirmationModal
        confirmText={<span>{fm(messages.identity_check_override)}</span>}
        onClose={() => {
          setShowModal(false)
        }}
        onConfirm={() =>
          overrideIdentityCheck({
            variables: {
              patientId,
              identityCheckId: identityCheck && identityCheck.id,
            },
          })
        }
        open={showModal}
        includeClose={false}
      >
        <ConfirmationModal.Heading>
          {fm(messages.identity_check_modal_header)}
        </ConfirmationModal.Heading>
        <ConfirmationModal.Content>
          <p>{fm(messages.identity_check_modal_content)}</p>
        </ConfirmationModal.Content>
      </ConfirmationModal>
      <button
        {...menuItemProps}
        onClick={() => {
          setShowModal(true)
          trackClick({
            elementName: 'override-identity-check-link',
            elementType: TrackingElementType.button,
          })
        }}
        data-testid="override-identity-check-button"
      >
        {fm(messages.identity_check_button)}
      </button>
    </>
  )
}
