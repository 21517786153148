import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as Access from '@babylon/web-platform-access'
import { useFormatMessage } from '@babylon/intl'
import restrictedClipboard from '@babylon/restricted-clipboard'
import { useProductConfig } from '@babylon/product-config'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { SnackBarProvider, MemberOpsModuleName } from '../../..'

import { useCookiePersistedState } from '../utils'
import {
  clearViewPreferenceFromStorage,
  saveViewPreferenceToStorage,
  useNewVersion,
} from './helpers'
import MemberView, { OldMemberView } from './MemberView'
import messages from './MemberView.messages'

export interface MemberParams {
  id: string
}

export interface NewMemberViewProps {
  patientId: string
  onSwitchToOldView(): void
}

export type MemberProps = RouteComponentProps<MemberParams>

export default (props: MemberProps) => {
  const fm = useFormatMessage()
  const { getProp } = useProductConfig()
  const newMemberViewEnabled = getProp(
    'memberOperations',
    'newMemberViewEnabled'
  )
  const memberProfileToggleEnabled = getProp(
    'memberOperations',
    'memberProfileToggleEnabled'
  )
  const useNew = useNewVersion(props.location.search, newMemberViewEnabled)
  const [canAccessMemberView] = Access.useDecide('view-member')
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberView,
  })

  useEffect(() => {
    restrictedClipboard.pause()
    window.addEventListener('beforeunload', clearViewPreferenceFromStorage)

    return () => {
      restrictedClipboard.resume()
      window.removeEventListener('beforeunload', clearViewPreferenceFromStorage)
    }
  }, [])

  const setUseNew = (newVersion: boolean) => {
    const newUrl = newVersion ? '?' : '?version=1'
    saveViewPreferenceToStorage(newVersion)
    props.history.push(newUrl)
  }

  const [hideBanner, setHideBanner] = useCookiePersistedState(
    'mop-member-banner',
    false
  )
  const patientId = `${props.match.params.id}`

  const [snackBar, setSnackBar] = useState<Snack>({
    autoHideDuration: 5000,
    icon: undefined,
    intent: 'primary',
    message: null,
    open: false,
  })

  if (!canAccessMemberView) {
    return <span>{fm(messages.access_denied)}</span>
  }

  if (!useNew && memberProfileToggleEnabled) {
    return (
      <OldMemberView
        onSwitchToNewView={() => {
          setUseNew(true)
          setHideBanner(true)
          trackClick({
            elementName: 'switch-to-new-member-profile-btn',
            elementType: TrackingElementType.button,
          })
        }}
        onCloseBanner={() => setHideBanner(true)}
        hideBanner={hideBanner}
      />
    )
  }

  return (
    <SnackBarProvider value={{ snackBar, setSnackBar }}>
      <MemberView
        patientId={patientId}
        onSwitchToOldView={() => setUseNew(false)}
      />
    </SnackBarProvider>
  )
}
