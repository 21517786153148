import React from 'react'

const CloseIcon = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.412893 3.76592L4.01989 0.382145C4.56303 -0.127381 5.44041 -0.127381 5.98354 0.382145L9.59054 3.76592C10.4679 4.589 9.84122 6 8.60175 6L1.38776 6C0.148287 6 -0.464485 4.589 0.412893 3.76592Z"
      fill="#022345"
    />
  </svg>
)

export default CloseIcon
