import React from 'react'
import PropTypes from 'prop-types'

import { formContext } from '../forms'

const Actions = ({ children, value }) => {
  const content =
    typeof children === 'function' ? children({ item: value }) : children
  return <formContext.Provider value={value}>{content}</formContext.Provider>
}

Actions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.func,
  ]),
}

export default Actions
