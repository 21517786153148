import { gql } from '@apollo/client'

export const DrugInfo = gql`
  query DrugInfo {
    drugUnits
    drugForms
    prescriptionRegions {
      regionCode
      name
    }
  }
`

const adminDrugFieldsFragment = gql`
  fragment AdminDrugFieldsFragment on AdminDrug {
    id
    name
    generic
    activeIngredients {
      activeIngredientId
      name
    }
    strength
    pharmaceuticalForm
    vmpDmdId
    vtmId
    drugPreparation
    note
    drugRegions {
      id
      region {
        regionCode
        name
      }
      blacklisted
      controlled
      packaging {
        id
        drugId
        price
        packageSize
        unitOfMeasure
        legalCategory
        ampDmdId
      }
    }
  }
`

export const AdminDrug = gql`
  query AdminDrug($id: String) {
    adminDrug(id: $id) {
      ...AdminDrugFieldsFragment
    }
  }
  ${adminDrugFieldsFragment}
`
export const AdminActiveIngredient = gql`
  query AdminActiveIngredient($query: String!, $limit: Int) {
    adminActiveIngredient(query: $query, limit: $limit) {
      activeIngredientId
      name
      iri
    }
  }
`

export const AddAdminDrug = gql`
  mutation AddAdminDrug($adminDrugInput: AdminDrugInput!) {
    addAdminDrug(adminDrug: $adminDrugInput) {
      ...AdminDrugFieldsFragment
    }
  }
  ${adminDrugFieldsFragment}
`

export const UpdateAdminDrug = gql`
  mutation UpdateAdminDrug($adminDrugInput: AdminDrugInput!) {
    updateAdminDrug(adminDrug: $adminDrugInput) {
      ...AdminDrugFieldsFragment
    }
  }
  ${adminDrugFieldsFragment}
`
