import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName, Header } from '../../../../..'
import messages from './MemberRelationshipsCard.messages'
import styles from './MemberRelationshipsCard.module.scss'
import AddRelatedPersonsDrawer from './components/AddRelatedPerson/AddRelatedPersonsDrawer'
import RelatedPersons from './components/RelatedPersons'

interface MemberRelationshipsCardProps {
  isMinor: boolean
  isStubbedProfile: boolean
  isPendingRegistration: boolean
  memberId: string
  currentMemberName: string
}

const MemberRelationshipsCard = ({
  isMinor,
  isStubbedProfile,
  isPendingRegistration,
  memberId,
  currentMemberName,
}: MemberRelationshipsCardProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberRelatedPersons,
  })
  const [showAddRelationshipDrawer, setShowAddRelationshipDrawer] = useState(
    false
  )
  const [newPersonAdded, setNewPersonAdded] = useState(false)
  const fm = useFormatMessage()
  const cardHeaderTitle = isMinor
    ? fm(messages.guardian_header)
    : fm(messages.dependents_header)
  const buttonText = isMinor
    ? fm(messages.add_guardian_button_text)
    : fm(messages.add_dependent_button_text)
  const trackingElementName = isMinor
    ? 'add-guardian-button'
    : 'add-dependent-button'
  const isPreRegisteredMinor =
    isMinor && isStubbedProfile && !isPendingRegistration

  return (
    <div
      className={styles.MemberRelationshipsCard__wrapper}
      data-testid="member-relationships"
    >
      <Header title={cardHeaderTitle} />
      <RelatedPersons
        memberId={memberId}
        isMinor={isMinor}
        refetchRelatedPersons={newPersonAdded}
      />

      {(isPreRegisteredMinor || !isMinor) && (
        <ButtonSimple
          type="button"
          variant={ButtonVariant.secondary}
          className={styles.AddRelationshipButton}
          onClick={() => {
            trackClick({
              elementName: trackingElementName,
              elementType: TrackingElementType.button,
            })
            setShowAddRelationshipDrawer(true)
          }}
        >
          {buttonText}
        </ButtonSimple>
      )}
      {showAddRelationshipDrawer && (
        <AddRelatedPersonsDrawer
          currentMemberId={memberId}
          currentMemberName={currentMemberName}
          title={buttonText}
          toggleVisible={() => setShowAddRelationshipDrawer(false)}
          isMinor={isMinor}
          memberId={memberId}
          onSuccessfulAdd={() => {
            setShowAddRelationshipDrawer(false)
            setNewPersonAdded(true)
          }}
        />
      )}
    </div>
  )
}

export default MemberRelationshipsCard
