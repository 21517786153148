import React from 'react'
import { useHistory } from 'react-router-dom'
import { useFormatMessage } from '@babylon/intl'
import { ButtonSimple, ButtonVariant } from '@babylon/medkit'

import { logException, useSnackBar } from '../../../..'
import { CallToActionInterface, PatientInterface } from '../../types'
import messages from './messages'

const CallToActions = ({ callToActions, member, onOpenChatbot }: Props) => {
  const fm = useFormatMessage()
  const history = useHistory()
  const { setSnackbarMessage } = useSnackBar()

  const getOnClick = (actionId: string) => {
    switch (actionId) {
      case 'complete_assessment': {
        return () =>
          history.push(`/admin/patients/${member.id}/assessment/risk`)
      }
      case 'early_exit': {
        return () =>
          history.push(`/admin/patients/${member.id}/assessment/early-exit`)
      }
      case 'quick_start_next_flow': {
        return () => onOpenChatbot(true)
      }
      default: {
        return () => {
          logException(`Unsupported Assessments CTA: ${actionId}`)
          setSnackbarMessage(fm(messages.unsupported))
        }
      }
    }
  }

  return (
    <>
      {callToActions.map(({ id, title }, index) => (
        <ButtonSimple
          fullWidth
          key={id}
          onClick={getOnClick(id)}
          variant={
            index === 0 ? ButtonVariant.primary : ButtonVariant.secondary
          }
        >
          {title}
        </ButtonSimple>
      ))}
    </>
  )
}

interface Props {
  callToActions: CallToActionInterface[]
  member: PatientInterface
  onOpenChatbot: (shouldSkip: boolean) => void
}

export default CallToActions
