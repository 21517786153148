import { gql } from '@apollo/client'

export const EDIT_PHARMACY = gql`
  mutation EditPharmacy($input: UpdatePharmacyHealthcareServiceInput!) {
    updatePharmacyHealthcareService(input: $input) {
      ... on UpdatePharmacyHealthcareServiceError {
        errorMessage
        id
      }
      ... on HealthcareService {
        id
        comment
        isActive
        name
        telecoms(first: 10) {
          nodes {
            ... on OtherContactPoint {
              system
              value
            }
            ... on PhoneNumberContactPoint {
              phoneNumber
              system
            }
          }
        }
      }
    }
  }
`
