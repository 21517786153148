import { envVar } from '@babylon/babylon-env'

/**
 * Importing the env vars used by new auth so we correctly pull them in when deployed
 *  see monoweb/lib/react-scripts/config/ExtractEnvVarsPlugin.js
 */
export const AUTH_CLIENT_ID = envVar('AUTH_CLIENT_ID')
export const AUTH_DOMAIN = envVar('AUTH_DOMAIN')
export const AUTH_REDIRECT_URI = envVar('AUTH_REDIRECT_URI')
export const AUTH_SESSION_REFRESH_INTERVAL = envVar(
  'AUTH_SESSION_REFRESH_INTERVAL'
)
export const AUTH_SESSION_LOGOUT_WHEN_INACTIVE = envVar(
  'AUTH_SESSION_LOGOUT_WHEN_INACTIVE'
)
export const AUTH_LOGOUT_RETURN_TO = envVar('AUTH_LOGOUT_RETURN_TO')
export const AUTH_SESSION_TIMEOUT = envVar('AUTH_SESSION_TIMEOUT')
