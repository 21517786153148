import { useState } from 'react'

type NavState = { [navKey: string]: boolean }

type SetNavState = (navKey: string, expanded: boolean) => void

const NAV_STATE_KEY = 'navState'

const getNavState = (): NavState => {
  try {
    const navState = JSON.parse(localStorage.getItem(NAV_STATE_KEY) || '')
    return navState
  } catch (e) {
    return {}
  }
}

const addToNavState = (navKey: string, expanded: boolean) => {
  const navState: NavState = getNavState() || {}
  const newNavState = {
    ...navState,
    [navKey]: expanded,
  }
  localStorage.setItem(NAV_STATE_KEY, JSON.stringify(newNavState))
  return newNavState
}

export default (): [NavState, SetNavState] => {
  const currentNavState = getNavState()
  const [navState, setNavState] = useState(currentNavState)

  return [
    navState,
    (navKey: string, expanded: boolean) => {
      const newNavState = addToNavState(navKey, expanded)
      setNavState(newNavState)
    },
  ]
}

export { addToNavState, getNavState }
