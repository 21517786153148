import { gql } from '@apollo/client'

export interface GetAvailabilitySlotsInput {
  memberId: string
  startDate: String
  endDate: String
  consultationType: string
  consultantType: string
  consultantId?: string
  clinicId?: string
  duration?: number
  serviceTypeUuid?: string
  availableOnly?: boolean
  include?: string[]
}

const GetAvailabilitySlots = gql`
  query getAvailabilitySlots(
    $appointmentSlotsRequest: AppointmentSlotsRequest
  ) {
    appointmentSlotsV2(request: $appointmentSlotsRequest) {
      appointmentSlots: appointment_slots {
        UTCTimestamp: time
        available
        consultantsAvailability: consultants_availability {
          consultant: consultant {
            id
            name
            gender
          }
          clinicId: clinic_id
          available
        }
      }
    }
  }
`

export default GetAvailabilitySlots
