import React from 'react'
import { Alert, DropdownSelect } from '@babylon/core-ui'
import { useGetProfessionsQuery } from './GetProfessionsQuery.hooks'

interface ProfessionSearcherProps {
  onSelect: (id: string, name: string) => void
}

interface ProfessionDropdownOption {
  key: string
  value: string
}

const ProfessionSearcher = ({ onSelect }: ProfessionSearcherProps) => {
  const { loading, error, data } = useGetProfessionsQuery()
  const professions = !loading && data?.professions ? data.professions : []

  const sortedData = professions
    .map(({ id, name }) => ({
      key: id,
      value: name,
    }))
    .filter(
      (option): option is ProfessionDropdownOption =>
        typeof option.key === 'string'
    )
    .sort((a, b) => a.value.localeCompare(b.value))

  const handleMakeSelection = (profession) => {
    onSelect(profession.key, profession.value)
  }

  if (error) {
    return <Alert title="There was an error">{error}</Alert>
  }

  return (
    <div className="profession-searcher-container">
      {!loading && (
        <DropdownSelect
          options={sortedData}
          placeholder="Select profession..."
          onChange={handleMakeSelection}
        />
      )}
    </div>
  )
}

export default ProfessionSearcher
