import React from 'react'
import { useFormikContext } from 'formik'

import { buildWeekFromDay } from '../Utils'

import ModalHeader from './ModalHeader'
import AvailabilityView from './AvailabilityView'

import styles from './AppointmentAvailability.module.css'

export default function AppointmentAvailability(props) {
  const {
    error,
    validationError,
    initialSelectedDate,
    initialSelectedWeek,
  } = props
  const { values, setValues } = useFormikContext()

  const handleSelectedDateUpdate = (date) => {
    setValues({
      ...values,
      selected_date: date,
      selected_week: buildWeekFromDay(date),
    })
  }

  const handleSelectedWeekUpdate = (date) => {
    setValues({
      ...values,
      selected_week: buildWeekFromDay(date),
    })
  }

  return (
    <div id="book-appointment" className={styles.container}>
      <ModalHeader
        appointmentClinic={props.appointmentClinic}
        appointmentClinicList={props.appointmentClinicList}
        consultationType={props.consultationType}
        disabled={props.disabled}
        patient={props.patient}
        selectedDate={values.selected_date}
        selectedDateUpdate={handleSelectedDateUpdate}
        selectedWeek={values.selected_week}
        selectedWeekUpdate={handleSelectedWeekUpdate}
      />
      <AvailabilityView
        disabled={error && !validationError}
        {...props}
        appointmentClinicUpdate={(value) => {
          setValues({
            ...values,
            selected_date: initialSelectedDate,
            ...(value === 'any' && {
              selected_week: initialSelectedWeek,
            }),
          })

          props.appointmentClinicUpdate(value)
        }}
        consultationTypeUpdate={(value) => {
          setValues({
            ...values,
            chaperone_required: false,
            selected_date: initialSelectedDate,
            selected_week: initialSelectedWeek,
          })
          props.consultationTypeUpdate(value)
        }}
        selectedDate={values.selected_date}
        selectedWeek={values.selected_week}
        selectedDateUpdate={handleSelectedDateUpdate}
        selectedWeekUpdate={handleSelectedWeekUpdate}
        consentExpired={props.consentExpired}
      />
    </div>
  )
}
