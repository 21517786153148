import React from 'react'
import cx from 'classnames'

import Icon from '@/components/Icon'

import styles from './styles.module.scss'

const SearchBox = ({
  className,
  searchPhrase,
  onSearchPhraseChange,
  onSearch,
  loading,
}) => (
  <div className={cx(styles.searchBox, className)}>
    <input
      value={searchPhrase}
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          onSearch && onSearch()
          //   event.stopPropagation()
          event.preventDefault()
        }
      }}
      onChange={(event) =>
        onSearchPhraseChange && onSearchPhraseChange(event.target.value)
      }
    />
    {loading ? (
      <Icon className={styles.icon} name="spinner" />
    ) : (
      <button onClick={onSearch}>
        <Icon className={styles.icon} name="search" />
      </button>
    )}
  </div>
)

export default SearchBox
