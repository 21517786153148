import { defineMessages } from 'react-intl'

const baseId = 'warning_card'

export default defineMessages({
  cancelled: {
    id: `${baseId}.cancelled`,
    defaultMessage: 'Cancelled',
  },
  no_show: {
    id: `${baseId}.no_show`,
    defaultMessage: 'No show',
  },
  timed_out: {
    id: `${baseId}.timed_out`,
    defaultMessage: 'Timed out',
  },
})
