import React, { useState } from 'react'
import { ApolloError } from '@apollo/client'
import { useFormatMessage } from '@babylon/intl'
import TimelineEvents from './TimelineEvents'
import messages from './Timeline.messages'
import { PATIENT_HISTORY_PAGE_SIZE } from '../../queries'

export interface PatientHistoryProps {
  loading: boolean
  patientId: string
  onCancelAppointmentClick: (appointmentId: string) => void
  fetchMore(arg0: any): void
  history?: PagedHistory
  error?: ApolloError
}

const onViewAll = (
  fetchMore: (arg0: any) => void,
  patientId: string,
  page: number,
  setPage: (arg0: number) => void
) => {
  const nextPage = page + 1

  fetchMore({
    variables: {
      patientId,
      pagination: {
        currentPage: nextPage,
        pageSize: PATIENT_HISTORY_PAGE_SIZE,
      },
    },
    updateQuery: (prev: any, { fetchMoreResult }: { fetchMoreResult: any }) => {
      const prevEvents = prev.pagedHistory.events || []
      const newEvents = fetchMoreResult.pagedHistory.events || []
      const events = [...prevEvents, ...newEvents]

      if (prevEvents.length !== events.length) {
        setPage(nextPage)
      }

      fetchMoreResult.pagedHistory.events = events

      return !fetchMoreResult ? prev : fetchMoreResult
    },
  })
}

const PatientHistory = ({
  error,
  history,
  fetchMore,
  loading,
  patientId,
  onCancelAppointmentClick,
}: PatientHistoryProps) => {
  const fm = useFormatMessage()
  const hasData = !!history
  const { events = [], totalEvents = 0 } = history || {}
  const count = events.length

  const hasRemaingEvents = totalEvents > count
  const viewAllText = fm(messages.view_all_patient_history)
  const title = fm(
    messages[totalEvents || !hasData ? 'patient_history' : 'no_patient_history']
  )
  const [page, setPage] = useState(0)

  return (
    <TimelineEvents
      onCancelAppointmentClick={onCancelAppointmentClick}
      events={events}
      error={error}
      loading={loading}
      info={fm(messages.patient_history_tooltip)}
      isUpcoming={false}
      patientId={patientId}
      title={title}
      viewAllText={viewAllText}
      onViewAll={
        hasRemaingEvents
          ? () => onViewAll(fetchMore, patientId, page, setPage)
          : undefined
      }
    />
  )
}

export default PatientHistory
