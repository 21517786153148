import React, { useEffect } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useQuery } from '@apollo/client'
import RelatedPersonCard from './RelatedPersonCard'
import { GET_RELATED_PERSONS } from '../../queries'
import LoadingRelatedPersonCard from './LoadingRelatedPersonCard'
import NoContent from '../../../NoContent'
import messages from './RelatedPersonCard.messages'

type RelatedPersonsProps = {
  memberId: string
  isMinor: boolean
  refetchRelatedPersons: boolean
}
enum RelatedPersonCodes {
  GUARDIAN = 'guardian',
  DEPENDENT = 'dependent',
}

const RELATED_PERSON_CODE_MAPPING = {
  k0hwgmTfvt: RelatedPersonCodes.DEPENDENT,
  IqWHpl2qHO: RelatedPersonCodes.GUARDIAN,
}

type CodingNode = {
  node: {
    coding: {
      code: 'string'
      system: 'string'
    }
  }
}

const getRelatedPersonType = (
  codingNodes: CodingNode[],
  relatedPersonType: RelatedPersonCodes
) =>
  codingNodes.some(
    ({ node }) =>
      RELATED_PERSON_CODE_MAPPING[node.coding.code] === relatedPersonType
  )
const renderNoContent = (message: string) => (
  <NoContent testId="no-related-persons">{message}</NoContent>
)

const RelatedPersons = ({
  memberId,
  isMinor,
  refetchRelatedPersons,
}: RelatedPersonsProps) => {
  const fm = useFormatMessage()
  const { loading, error, data, refetch } = useQuery(GET_RELATED_PERSONS, {
    context: { clientName: 'platform-gateway' },
    variables: {
      patientId: memberId,
      first: 5,
      relationshipsFirst2: 10,
      input: {
        system: 'https://bbl.health',
      },
    },
  })
  const relatedPersons = data?.patient?.relatedPersons?.edges || []

  useEffect(() => {
    if (refetchRelatedPersons) {
      refetch()
    }
  }, [refetchRelatedPersons, refetch])

  return (
    <div data-testid="related-persons">
      {loading && <LoadingRelatedPersonCard />}
      {error && renderNoContent(fm(messages.error_retrieving_related_persons))}
      {!error &&
        !relatedPersons.length &&
        renderNoContent(fm(messages.no_related_persons_exist))}
      {relatedPersons.map(({ node }) => {
        const id = node?.id || ''
        const codingNodes = node?.relationships?.edges || []
        const renderRelatedPerson =
          (isMinor &&
            getRelatedPersonType(codingNodes, RelatedPersonCodes.GUARDIAN)) ||
          (!isMinor &&
            getRelatedPersonType(codingNodes, RelatedPersonCodes.DEPENDENT))

        return (
          renderRelatedPerson && (
            <RelatedPersonCard
              key={id}
              relatedPersonId={id}
              currentMemberId={memberId}
              isCurrentMemberMinor={isMinor}
              hideMenu
            />
          )
        )
      })}
    </div>
  )
}

export default RelatedPersons
