import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import {
  withFormContext,
  withLabel,
  withWrapper,
  withValue,
  withValidation,
  withOnChange,
  compose,
} from './util'

const Textarea = ({ small, className, 'data-context': ctx, ...props }) => (
  <textarea
    className={cx('widget input border', { small }, className)}
    {...props}
  />
)

Textarea.propTypes = {
  /** Change event handler method
   * @param {SyntheticEvent} event The react `SyntheticEvent`
   */
  onChange: PropTypes.func,
  /** Render the Textarea component in a smaller size */
  small: PropTypes.bool,
  /** The css class name of the component */
  className: PropTypes.string,
  /** The css style of the component */
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  /** Validation functions */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  /** @ignore */
  'data-context': PropTypes.instanceOf(Object),
}

export default compose(
  withFormContext,
  withLabel,
  withValue,
  withValidation,
  withWrapper,
  withOnChange
)(Textarea)
