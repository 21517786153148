import { gql } from '@apollo/client'

export const PrescriptionRegions = gql`
  query PrescriptionRegions {
    prescriptionRegions {
      regionCode
      name
    }
  }
`

const prescriptionFieldsFragment = gql`
  fragment PrescriptionFieldsFragment on Prescription {
    id
    consultationId
    patient {
      id
      full_name
      first_name
      last_name
    }
    drugs {
      drugName
      quantity
      directions
      packagingSize
    }
    createdAt
    updatedAt
    consultant {
      id
      name
    }
    prescriptionState
    signatureState
    deliveryMethod
    pharmacyId
    pharmacyDetails
    latestComment
    actions
  }
`

export const Prescriptions = gql`
  query AP_Prescriptions(
    $size: Int
    $page: Int
    $sort: String
    $statuses: [String]
    $dateFrom: String
    $dateTo: String
    $prescriptionId: ID
    $patientId: ID
    $firstName: String
    $lastName: String
    $pharmacyDetails: String
    $consultantId: ID
    $consultantName: String
  ) {
    prescriptions(
      size: $size
      page: $page
      sort: $sort
      statuses: $statuses
      dateFrom: $dateFrom
      dateTo: $dateTo
      prescriptionId: $prescriptionId
      patientId: $patientId
      firstName: $firstName
      lastName: $lastName
      pharmacyDetails: $pharmacyDetails
      consultantId: $consultantId
      consultantName: $consultantName
    ) {
      totalItems
      items {
        ...PrescriptionFieldsFragment
      }
    }
  }
  ${prescriptionFieldsFragment}
`

export const PrescriptionChangeState = gql`
  mutation PrescriptionChangeState(
    $id: ID!
    $action: PrescriptionStateActionType!
    $reason: String
  ) {
    prescriptionChangeState(id: $id, action: $action, reason: $reason) {
      ...PrescriptionFieldsFragment
    }
  }
  ${prescriptionFieldsFragment}
`

export const PrescriptionRetryPdf = gql`
  mutation PrescriptionRetryPdf($id: ID!) {
    prescriptionRetryPdf(id: $id)
  }
`

export const PrescriptionRetryFax = gql`
  mutation PrescriptionRetryFax($id: ID!) {
    prescriptionRetryFax(id: $id)
  }
`
export const AddCommentToPrescription = gql`
  mutation AddCommentToPrescription($prescriptionId: ID!, $comment: String!) {
    addCommentToPrescription(
      prescriptionId: $prescriptionId
      comment: $comment
    ) {
      ...PrescriptionFieldsFragment
    }
  }
  ${prescriptionFieldsFragment}
`

export const Templates = gql`
  query AP_RepeatTemplates(
    $size: Int
    $page: Int
    $sort: String
    $patientId: String
    $reviewDateMin: Date
    $reviewDateMax: Date
    $startDateMin: Date
    $startDateMax: Date
    $states: [RepeatTemplateState]
  ) {
    repeatTemplates(
      size: $size
      page: $page
      sort: $sort
      patientId: $patientId
      reviewDateMin: $reviewDateMin
      reviewDateMax: $reviewDateMax
      startDateMin: $startDateMin
      startDateMax: $startDateMax
      states: $states
    ) {
      pageIndex
      totalItems
      itemsPerPage
      items {
        id
        latestIssue {
          id
          issuedAt
          durationDays
        }
        patient {
          id
          full_name
          first_name
          last_name
        }
        drugName
        reviewDate
        userState
      }
    }
  }
`

export const FailedPrescriptions = gql`
  query FailedPrescriptions($page: Int, $size: Int) {
    prescriptionFetchFailed(page: $page, size: $size) {
      itemsPerPage
      pageIndex
      totalItems
      items {
        id
        patient {
          id
          first_name
          last_name
        }
        createdAt
        drugs {
          drugName
          quantity
          directions
          packagingSize
        }
        actions
        latestComment
      }
    }
  }
`
