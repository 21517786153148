import React, { useMemo } from 'react'
import cx from 'classnames'

import { format, addMinutes, endOfToday, startOfToday } from 'date-fns'
import { Text, Cell } from '@babylon/core-ui'

import styles from './Timeline.module.css'

interface TimelineProps {
  className?: string
  distanceBetweenSteps: number
  step: number
}

const getTimelineTimes = (step) => {
  const startTime = startOfToday()
  const endTime = endOfToday()

  const minutesInOneDay = 24 * 60

  const timelineTimes = Array(minutesInOneDay / step)
    .fill('')
    .map((_, index) => {
      if (!index) {
        return format(startTime, 'HH:mm')
      }

      return format(addMinutes(startTime, step * index), 'HH:mm')
    })

  timelineTimes.push(format(endTime, 'HH:mm'))

  return timelineTimes
}

export default function Timeline({
  className,
  distanceBetweenSteps,
  step,
}: TimelineProps) {
  const timelineTimes = useMemo(() => getTimelineTimes(step), [step])
  const timelineClassname = cx(styles.Timeline, className)

  /*
   * Wrapping return in a fragment to bypass Typescript issue with components that return arrays
   * c.f. https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
   */
  return (
    <>
      {timelineTimes.map((value, index, timelineArray) => (
        <Cell
          data-testid="timeline-value"
          key={value}
          style={{
            maxHeight:
              timelineArray.length !== index + 1
                ? `${distanceBetweenSteps}px`
                : 'auto',
          }}
          className={timelineClassname}
        >
          <Text tag="span" size="small" className={styles.TimelineTime}>
            {value}
          </Text>
          <span className={styles.TimelineLine} />
        </Cell>
      ))}
    </>
  )
}
