import { supplyAndSchedulingRoutes } from '@babylon/supply-and-scheduling'
import { envFlag } from '@babylon/babylon-env'
import { IconKeys, NavItem } from './types'
import messages from './SideNav.messages'

const navStructure = ({
  accessDashboard,
  accessAllAppointments,
  accessOldAppointments,
  accessNewAppointments,
  accessAvailability,
  accessConsultationSummaries,
  accessPrescriptions,
  accessReferrals,
  accessHospitalReferrals,
  accessNhsRegistrations,
  accessFindClinician,
  accessGpSurgeryFax,
  accessProfessions,
  accessReallocateShift,
  accessClinicianVisibility,
  accessConsumerNetworks,
  accessPlans,
  accessPromotions,
  accessPromotionValidations,
  accessUploadPromoCodes,
  accessNetworkRelationships,
  accessAppSettings,
  accessDrugs,
  accessPharmacies,
  accessPharmacists,
  accessPortalLogins,
  accessRegions,
  accessReports,
  accessServiceMix,
  accessSpecialismCategories,
  accessSupplyNetworks,
  accessAppointmentWaitTimes,
  accessLiveConversations,
  accessCareJourney,
}: {
  [key: string]: boolean
}): NavItem[] => [
  {
    title: messages.category_dashboard,
    link: accessAppointmentWaitTimes
      ? supplyAndSchedulingRoutes.appointmentWaitTimes
      : '/admin/dashboard',
    icon: IconKeys.Dashboard,
    hidden: !accessDashboard,
  },
  {
    title: messages.category_patients,
    id: 'patients',
    icon: IconKeys.Patients,
    links: [
      {
        title: messages.link_all_appointments,
        link: '/admin/appointments',
        hidden: !accessAllAppointments,
      },
      {
        title: messages.link_appointments_old,
        link: '/admin/appointments',
        hidden: !accessOldAppointments,
      },
      {
        title: messages.link_appointments_new,
        link: (id?: string) => `/consultants/${id}/appointments`,
        hidden: !accessNewAppointments,
      },
      {
        title: messages.link_consultation_summaries,
        link: '/admin/appointments/to_send_to_gp',
        hidden: !accessConsultationSummaries,
      },
      {
        title: messages.link_prescriptions,
        link: '/admin/prescriptions',
        hidden: !accessPrescriptions,
      },
      {
        title: messages.link_referrals,
        link: '/admin/referrals',
        hidden: !accessReferrals,
      },
      {
        title: messages.link_hospital_referrals,
        link: '/referrals/hospital_admin_code_search',
        hidden: !accessHospitalReferrals,
      },
      {
        title: messages.link_nhs_registrations,
        link: '/admin/public_healthcare_registrations',
        hidden: !accessNhsRegistrations,
      },
    ],
  },
  {
    title: messages.category_clinicians,
    id: 'practitioners',
    icon: IconKeys.Practitioners,
    links: [
      {
        title: messages.link_find_clinician,
        link: '/admin/consultants',
        hidden: !accessFindClinician,
      },
      {
        title: messages.link_gp_surgery_fax,
        link: envFlag('ENABLE_GP_FAX_NUMBERS_NEW_PAGE')
          ? '/admin/fax_numbers_v2'
          : '/admin/fax_numbers',
        hidden: !accessGpSurgeryFax,
      },
      {
        title: messages.link_professions,
        link: '/admin/professions',
        hidden: !accessProfessions,
      },
      {
        title: messages.link_availability,
        link: (id?: string) => `/admin/consultants/${id}/availability`,
        hidden: !accessAvailability,
      },
      {
        title: messages.link_reallocate_shift,
        link: '/admin/reallocate-shift',
        hidden: !accessReallocateShift,
      },
      {
        title: messages.link_clinician_visibility,
        link: '/admin/clinicians/clinician-visibility',
        hidden: !accessClinicianVisibility,
      },
    ],
  },
  {
    title: messages.category_commercial,
    id: 'commercial',
    icon: IconKeys.Commercial,
    links: [
      {
        title: messages.link_consumer_networks,
        link: '/admin/consumer_networks',
        hidden: !accessConsumerNetworks,
      },
      {
        title: messages.link_plans,
        link: '/admin/plans',
        hidden: !accessPlans,
      },
      {
        title: messages.link_promotions,
        link: '/admin/promotions',
        hidden: !accessPromotions,
      },
      {
        title: messages.link_promotion_validations,
        link: '/admin/promotion_validations',
        hidden: !accessPromotionValidations,
      },
      {
        title: messages.link_upload_promotion_codes,
        link: '/admin/promotion_code_upload',
        hidden: !accessUploadPromoCodes,
      },
      {
        title: messages.link_network_relationships,
        link: '/admin/consumer_networks_supply_networks',
        hidden: !accessNetworkRelationships,
      },
    ],
  },
  {
    title: messages.category_live_chat,
    link: '/live-chat',
    icon: IconKeys.LiveChat,
    hidden: !accessLiveConversations,
  },
  {
    title: messages.category_admin,
    id: 'admin',
    icon: IconKeys.Admin,
    links: [
      {
        title: messages.link_app_settings,
        link: '/admin/app_settings',
        hidden: !accessAppSettings,
      },
      {
        title: messages.link_care_journey,
        link: '/patient-care-portal',
        hidden: !accessCareJourney,
      },
      {
        title: messages.link_drugs,
        link: '/admin/drugs',
        hidden: !accessDrugs,
      },
      {
        title: messages.link_pharmacies,
        link: '/admin/pharmacies_v2',
        hidden: !accessPharmacies,
      },
      {
        title: messages.link_pharmacists,
        link: envFlag('ENABLE_PHARMACISTS_CORE_RUBY_TO_REACT_NEW_PAGE')
          ? '/admin/pharmacists_v2'
          : '/admin/pharmacists',
        hidden: !accessPharmacists,
      },
      {
        title: messages.link_portal_logins,
        link: '/admin/portal_logins',
        hidden: !accessPortalLogins,
      },
      {
        title: messages.link_regions,
        link: '/admin/regions',
        hidden: !accessRegions,
      },
      {
        title: messages.link_reports,
        link: '/reports',
        hidden: !accessReports,
      },
      {
        title: messages.link_service_mix,
        link: '/admin/service-mix',
        hidden: !accessServiceMix,
      },
      {
        title: messages.link_specialism_categories,
        link: '/admin/specialism_categories',
        hidden: !accessSpecialismCategories,
      },
      {
        title: messages.link_supply_networks,
        link: '/admin/supply_networks',
        hidden: !accessSupplyNetworks,
      },
    ],
  },
]

export { navStructure }
