import { defineMessages } from 'react-intl'

const baseId = 'alert_add_modal'

export default defineMessages({
  add_alert: {
    id: `${baseId}.add_alert`,
    defaultMessage: 'Add',
  },
  alerts: {
    id: `${baseId}.alerts`,
    defaultMessage: 'Alerts',
  },
  cancel: {
    id: `${baseId}.cancel`,
    defaultMessage: 'Cancel',
  },
  save_alert: {
    id: `${baseId}.save_alert`,
    defaultMessage: 'Add Alert',
  },
})
