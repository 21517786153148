import React, { ChangeEvent } from 'react'
import { FormField, Input, Cell } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { SearchableField } from '../../../reducer/utils'

export interface SearchFieldProps extends SearchableField {
  disabled?: boolean
  value?: string | number
  onChange?(event: ChangeEvent<HTMLInputElement>): void
}

const SearchField = ({
  cellWidth = 12,
  disabled = false,
  label,
  maxWidth,
  paramName,
  placeholder = '',
  value,
  onChange,
}: SearchFieldProps) => {
  const fm = useFormatMessage()

  const props = {
    id: paramName,
    fill: true,
    style: { maxWidth },
    disabled,
    value: value || '',
    placeholder,
    name: paramName,
    autoComplete: 'off',
    onChange,
  }

  const internationalisedLabel = label ? fm(label) : ''

  return (
    <Cell width={cellWidth}>
      <FormField id={paramName} label={internationalisedLabel}>
        <Input {...props} />
      </FormField>
    </Cell>
  )
}

export default SearchField
