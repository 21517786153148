import { createContext, useContext } from 'react'

interface SearchFeatures {
  enableLegacySearchToggle: boolean
  enableFamilyAccounts: boolean
  enableResultActions: boolean
  enableResultTags: boolean
  enableResultConsumerNetworks: boolean
  enablePreFetch: boolean
}

export const internalFeatures: SearchFeatures = {
  enableLegacySearchToggle: true,
  enableFamilyAccounts: true,
  enableResultActions: true,
  enableResultTags: true,
  enableResultConsumerNetworks: true,
  enablePreFetch: true,
}

const SearchFeaturesContext = createContext<SearchFeatures>(internalFeatures)

export default SearchFeaturesContext

export const useSearchFeaturesContext = () => useContext(SearchFeaturesContext)
