import { parseISO } from 'date-fns'
import { format } from 'date-fns-tz'
import get from 'lodash/get'
import { WorkflowTaskVariable } from '@babylon/graphql-middleware-types'
import {
  Address,
  WARNING_STATUSES,
  RepeatTemplateState,
  UserTemplateState,
} from '../types'

const dateFormat = (locale: string) => {
  switch (locale) {
    case 'en-GB':
      return 'dd MMM yyyy'
    case 'en-US':
      return 'MMM dd, yyyy'
    default:
      return 'dd MMM yyyy'
  }
}

const dateTimeFormat = (locale: string) => `${dateFormat(locale)} - HH:mm`

const isWarningStatus = (status = '') =>
  Object.values(WARNING_STATUSES).includes(status as WARNING_STATUSES)

const isFollowUpWithGP = (state: Maybe<RepeatTemplateState>) =>
  state === RepeatTemplateState.PENDING_ON_GP

const isFollowUpWithPharmicist = (state: Maybe<RepeatTemplateState>) =>
  state === RepeatTemplateState.PENDING_ON_PRESCRIBING_PHARMACIST

const isReorder = (userState: UserTemplateState) =>
  userState === UserTemplateState.REORDER_NOW ||
  userState === UserTemplateState.REORDER_SOON

const getHoursAndMinutes = (duration: number) => ({
  hours: Math.floor(duration / 60),
  minutes: duration % 60,
})

const getMinutesAndSeconds = (duration: number) => ({
  // eslint-disable-next-line no-bitwise
  minutes: Math.floor((duration / 1000 / 60) << 0).toString(),
  seconds: Math.floor((duration / 1000) % 60)
    .toString()
    .padStart(2, '0'),
})

const getDate = (date?: string | null) => (date ? parseISO(date) : new Date())

interface getDateProps {
  date?: string | null
  locale: string
}

const getFormattedDate = ({ date, locale, isIso = false }: getDateProps) => {
  const dateToFormat = getDate(
    isIso ? new Date(parseInt(date, 10))?.toISOString() : date
  )

  return format(dateToFormat, dateFormat(locale))
}

const getFormattedDateTime = ({
  date,
  locale,
  isIso = false,
}: getDateProps) => {
  const dateToFormat = getDate(
    isIso ? new Date(parseInt(date, 10))?.toISOString() : date
  )

  return format(dateToFormat, dateTimeFormat(locale))
}

const getVariableValue = (key: string) => (
  variables?: Maybe<Array<Maybe<WorkflowTaskVariable>>>
) => {
  const variable = (variables || []).find((v) => get(v, 'name') === key)

  return variable ? variable.value : null
}

const validateAddress = ({
  addressLine,
  country,
  postalCode,
}: Address): boolean => !!((addressLine || postalCode) && country)

const generateMapLink = ({ addressLine, city, country, postalCode }: Address) =>
  `https://maps.google.com/?q=${addressLine}, ${country}, ${city}, ${postalCode}`

export {
  isWarningStatus,
  isFollowUpWithGP,
  isFollowUpWithPharmicist,
  isReorder,
  getHoursAndMinutes,
  getFormattedDate,
  getFormattedDateTime,
  getMinutesAndSeconds,
  getVariableValue,
  validateAddress,
  generateMapLink,
}
