import React from 'react'
import { format, addDays, subDays, formatISO } from 'date-fns'
import { Button, Text } from '@babylon/core-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

import { ClinicianVisibilitySearchFormTypes } from '../ClinicianVisibilitySearch/ClinicianVisibilitySearch'
import { useQueryParams } from '../../hooks'

import styles from './styles.module.css'

interface VisibilityHeaderProps {
  disabled?: boolean
}

export default function VisibilityHeaderNavigation({
  disabled,
}: VisibilityHeaderProps) {
  const [
    params,
    setParams,
  ] = useQueryParams<ClinicianVisibilitySearchFormTypes>({})

  if (!params?.dateTimeFrom) {
    return null
  }

  const currentDate = new Date(params?.dateTimeFrom)

  const onPrevNavigation = () =>
    setParams({
      ...params,
      dateTimeFrom: formatISO(subDays(currentDate, 1)),
    })

  const onNextNavigation = () =>
    setParams({
      ...params,
      dateTimeFrom: formatISO(addDays(currentDate, 1)),
    })

  return (
    <div
      className={styles.visibilityHeaderNavigation}
      data-testid="visibility-header"
    >
      <Button
        className={styles.visibilityHeaderNavigationLeft}
        data-testid="visibility-header__previous-day"
        disabled={disabled}
        onClick={onPrevNavigation}
        icon={<FontAwesomeIcon rotation={180} icon={faChevronRight} />}
        intent="link"
      >
        Previous Day
      </Button>
      <Text data-testid="visibility-header__date" bold>
        {format(currentDate, 'EEEE do MMMM')}
      </Text>
      <Button
        data-testid="visibility-header__next-day"
        disabled={disabled}
        onClick={onNextNavigation}
        className={styles.visibilityHeaderNavigationRight}
        icon={
          <FontAwesomeIcon
            className={styles.visibilityHeaderNavigationIconRight}
            icon={faChevronRight}
          />
        }
        intent="link"
      >
        Next Day
      </Button>
    </div>
  )
}
