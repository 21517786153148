import { compose, withState, withHandlers } from 'recompose'
import { withRouter } from 'react-router'
import { withStore } from '@babylon/babylon-forms/forms'
import snakeCase from 'lodash/snakeCase'

import { withSpinner, shouldRouteUpdate } from '@/util'
import withErrorMessage from '@/util/withErrorMessage'
import graphqlWithProps from '@/util/graphqlWithProps'

import { Templates } from './queries'
import TemplatesView from './TemplatesView'

// DATA

export const defaultFilter = {
  patientId: '',
  startDateMin: null,
  startDateMax: null,
  reviewDateMin: null,
  reviewDateMax: null,
  states: [],
}

export const queryOptions = {
  options: {
    variables: {
      size: 10,
      page: 0,
      ...defaultFilter,
    },
  },
}

const withData = graphqlWithProps(Templates, queryOptions)

// STATE

const withStates = compose(
  withState('spinnerType', 'setSpinnerType', null),
  withState('showFilter', 'setShowFilter', false)
)

// FILTERS

const withFilterStore = withStore((props) => props.data.variables, {
  name: 'filter',
  shouldStateUpdate(props, prevState) {
    return props.data.variables !== prevState && props.spinnerType === 'filter'
  },
})

// ACTIONS

export const handlePageSizeChange = ({ data, setSpinnerType }) => (size) => {
  setSpinnerType('page-size')
  data.updateVariables({ ...data.variables, size, page: 0 })
}

export const handlePageChange = ({ data, setSpinnerType }) => (page) => {
  setSpinnerType('pagination')
  data.updateVariables({ ...data.variables, page })
}

export const handleSort = ({ data, setSpinnerType }) => (column, direction) => {
  setSpinnerType('sort')
  data.updateVariables({
    ...data.variables,
    sort: `${snakeCase(column)},${direction}`,
    page: 0,
  })
}

export const handleToggleFilter = ({ showFilter, setShowFilter }) => () =>
  setShowFilter(!showFilter)

export const handleFilterChange = ({ data, filter }) => () =>
  data.updateVariables(filter.state)

export const handleFilterReset = ({ data, setSpinnerType }) => () => {
  setSpinnerType('filter')
  data.updateVariables({
    ...defaultFilter,
    page: 0,
    _update: Symbol(),
  })
}

const withActions = withHandlers({
  handlePageSizeChange,
  handlePageChange,
  handleSort,
  handleToggleFilter,
  handleFilterChange,
  handleFilterReset,
})

// LOADER

export const isLoading = (props) => !props.data || !props.data.repeatTemplates

const withLoader = withSpinner(isLoading)

export default compose(
  withRouter,
  shouldRouteUpdate,
  withStates,
  withData,
  withErrorMessage(),
  withFilterStore,
  withActions,
  withLoader
)(TemplatesView)
