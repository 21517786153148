import { createContext } from 'react'
import { Snack } from '../../app'

interface SnackBarContextType {
  snackBar: Snack
  setSnackBar: React.Dispatch<Snack>
}
const SnackBarContext = createContext<SnackBarContextType>(
  {} as SnackBarContextType
)

export const SnackBarProvider = SnackBarContext.Provider
export default SnackBarContext
