import React from 'react'

import { Button } from '@babylon/babylon-forms/forms'

import ListView from '@/components/old-forms/ListView'

import styles from './styles.module.scss'

const DrugAuditsView = ({ columns, data: { adminDrugAudits }, history }) => (
  <div>
    <div className={styles.drugAudits}>
      <ListView columns={columns} data={adminDrugAudits} />
    </div>
    <div className={styles.rightRow}>
      <Button outline onClick={() => history.goBack()}>
        Cancel
      </Button>
    </div>
  </div>
)

export default DrugAuditsView
