import React from 'react'
import { compose, onlyUpdateForKeys } from 'recompose'

import withForcedRemount from '../withForcedRemount'

const enhance = compose(
  onlyUpdateForKeys(['title', 'src', 'className']),
  withForcedRemount
)

// eslint-disable-next-line jsx-a11y/iframe-has-title
const HistorylessIFrame = (props) => <iframe {...props} />

export default enhance(HistorylessIFrame)
