import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useHistory } from 'react-router'
import {
  handleAudits,
  handleComment,
  hasVoidDispensedPrescriptionError,
} from '@/routes/Prescriptions/AdminPrescriptions/prescriptions'
import { errorAlert, customErrorAlert } from '@/util/withErrorMessage'

import {
  FailedPrescriptions,
  PrescriptionChangeState,
  AddCommentToPrescription,
} from './queries'
import FailedView from './FailedView'
import actionTypes from '@/routes/Prescriptions/AdminPrescriptions/actionTypes.json'
import promptReason from '@/components/ReasonDialog'

interface VoidOptions {
  id: string
}

const FailedContainer = () => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const history = useHistory()

  const { data, loading, error } = useQuery(FailedPrescriptions, {
    variables: {
      size: pageSize,
      page: pageNumber,
    },
  })

  const [handleChangeState] = useMutation(PrescriptionChangeState)
  const [addCommentToPrescription] = useMutation(AddCommentToPrescription)
  const prescriptionFetchFailed = data?.prescriptionFetchFailed ?? null

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize)
  }

  const handlePageChange = (pageNumber: number) => {
    setPageNumber(pageNumber)
  }

  const handleVoid = (action: string, { id }: VoidOptions) => {
    const processAction = (reason: string) => {
      handleChangeState({
        variables: { id, action: 'VOID', reason },
        refetchQueries: ['PrescriptionAudit', 'FailedPrescriptions'],
      }).catch(() => {
        if (hasVoidDispensedPrescriptionError(error)) {
          customErrorAlert(
            "We can't void this prescription because the patient has already collected their medications."
          )
        } else {
          errorAlert()
        }
      })
    }

    promptReason(actionTypes.VOID.reasons)().then(processAction, () => null)
  }

  const getHandleAudits = () => {
    return handleAudits({
      history,
    })
  }

  const getHandleComment = () => {
    return handleComment({
      addCommentToPrescription,
      errorAlert,
    })
  }

  return (
    <FailedView
      prescriptionFetchFailed={prescriptionFetchFailed}
      loading={loading}
      error={error}
      handleAction={handleVoid}
      handleAudits={getHandleAudits()}
      handleComment={getHandleComment()}
      handlePageSizeChange={handlePageSizeChange}
      handlePageChange={handlePageChange}
    />
  )
}

export default FailedContainer
