import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { useProductConfig } from '@babylon/product-config'

import { Grid, Cell, Text } from '@babylon/core-ui'
import ConsultationTypes, {
  ConsultationTypeName,
} from '../../types/ConsultationTypes'
import styles from './TimelineItemInfo.module.css'

library.add(faCaretDown, faStopwatch)

interface Props {
  consultant?: string
  consultationType?: ConsultationTypeName
  allowedMediums?: ConsultationTypeName[]
  consumerNetwork?: string
  duration?: string
  moreDetails?: string
  onMoreDetails?: () => void
  timestamp?: string
  type: 'INVITATION' | 'APPOINTMENT'
}

const TimelineItemInfo = ({
  consultant,
  consultationType,
  allowedMediums,
  consumerNetwork,
  duration,
  moreDetails,
  onMoreDetails,
  timestamp,
  type,
}: Props) => {
  const { getProp } = useProductConfig()
  const digitalInviteExtensionEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteExtensionEnabled'
  )

  const digitalInviteEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteEnabled'
  )

  return (
    <Grid
      columns={2}
      style={{
        gridTemplateColumns: '1fr auto',
      }}
    >
      <Grid rows={3}>
        {!timestamp && (
          <Cell>
            <Text
              color="highlight-violet-type"
              className={cn(styles.tag, styles.type)}
            >
              {type}
            </Text>
          </Cell>
        )}

        {timestamp && (
          <Cell>
            <Text color="grey-type">{timestamp}</Text>
          </Cell>
        )}
        {consultationType &&
          !(digitalInviteExtensionEnabled || digitalInviteEnabled) && (
            <Cell>
              <Text bold size="large">
                {ConsultationTypes[consultationType]}
              </Text>
            </Cell>
          )}
        {allowedMediums &&
          (digitalInviteExtensionEnabled || digitalInviteEnabled) && (
            <Cell>
              <Text bold size="large">
                {allowedMediums
                  .map((medium) => ConsultationTypes[medium])
                  .join(' / ')}
              </Text>
            </Cell>
          )}

        {moreDetails && onMoreDetails && (
          <Cell>
            <button
              onClick={onMoreDetails}
              className={styles.moreDetails}
              type="button"
            >
              <Text color="grey-type">
                <FontAwesomeIcon icon={['fas', 'caret-down']} /> {moreDetails}
              </Text>
            </button>
          </Cell>
        )}
      </Grid>

      <Grid rows={3}>
        {consumerNetwork && (
          <Cell>
            <Text
              color="action-green"
              className={cn(styles.tag, styles.consumerNetwork)}
            >
              {consumerNetwork}
            </Text>
          </Cell>
        )}

        {consultant && (
          <Cell>
            <Text color="action-green">{consultant}</Text>
          </Cell>
        )}

        {duration && (
          <Cell>
            <Text color="grey-type">
              <FontAwesomeIcon icon={['far', 'stopwatch']} /> {duration}
            </Text>
          </Cell>
        )}
      </Grid>
    </Grid>
  )
}

export default TimelineItemInfo
