import { gql } from '@apollo/client'

export default gql`
  mutation AddCliniciansToServiceType(
    $service_type_uuid: ID!
    $clinician_ids: [ID!]!
  ) {
    addCliniciansToServiceType(
      service_type_uuid: $service_type_uuid
      clinician_ids: $clinician_ids
    )
  }
`
