import { gql } from '@apollo/client'

export default gql`
  query SelectAppointmentQuery($memberId: ID!, $includingDigital: Boolean) {
    patient(id: $memberId) {
      id
      service_types(including_digital: $includingDigital) {
        uuid
        name
        default_duration_minutes
        intimate
        member_instructions
        common
        preferred_medium
        allowed_mediums
      }
    }
  }
`
