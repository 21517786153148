import { getSinglePrivacyNotice } from '../api'
import { inputTypes, messageTypes } from '../constants'
import {
  ChatscriptMessageType,
  ChatscriptMessageInputType,
} from '../constants/enums'
import {
  QuestionInterface,
  ChatscriptMessage,
  LeafletInterface,
  ChatscriptMessageLeaflet,
  ChatscriptMessageNotice,
  QuestionOptionInterface,
  DateParams,
} from '../constants/types'

const INPUT_TYPE_FROM_MESSAGE_TYPE = {
  // chatscript v3 type: web app type
  [messageTypes.DATE]: inputTypes.DATE_SELECT,
  [messageTypes.TEXT_AND_FILES]: inputTypes.DRAFT,
  [messageTypes.PHONE]: inputTypes.PHONE_INPUT,
  [messageTypes.CHOICES]: inputTypes.SELECT,
  [messageTypes.SYMPTOM]: inputTypes.SYMPTOM_SELECT,
  [messageTypes.TEXT]: inputTypes.TEXT,
}

const getInputTypeFromElement = (chatbotMessage: ChatscriptMessage) => {
  const messageType = chatbotMessage.input.type
  let initialInputType = INPUT_TYPE_FROM_MESSAGE_TYPE[messageType]

  if (
    chatbotMessage.input.type === ChatscriptMessageInputType.Choices &&
    chatbotMessage.input.params.choices.find(
      (choice: any) => choice.multiselect
    )
  ) {
    initialInputType = inputTypes.MULTI_SELECT
  }

  if (!initialInputType) {
    initialInputType = inputTypes.TEXT
  }

  return initialInputType
}

const getLeafletContent = async (
  chatbotMessage: ChatscriptMessageLeaflet | ChatscriptMessageNotice
): Promise<LeafletInterface> => {
  if (chatbotMessage.type === 'notice') {
    const privacyNotice = await getSinglePrivacyNotice(chatbotMessage.value.id)

    return {
      summary: '',
      content_provider: '',
      longText: privacyNotice.data.longText,
      title: privacyNotice.data.readMoreButtonText,
    }
  }

  return {
    summary: chatbotMessage.value.summary,
    content_provider: chatbotMessage.value.source,
    url: chatbotMessage.value.url,
    title: chatbotMessage.value.title,
  }
}

const formatChatbotText = (text: string) => {
  if (text.length > 100 && text.indexOf('\n') < 0) {
    const split = text.split('. ')

    if (split.length > 1) {
      return `${split[0]}.\n\n${text.replace(`${split[0]}. `, '')}`
    }
  }

  return text
}

const questionFromSingleElement = (
  chatbotMessage: ChatscriptMessage,
  undoable: boolean = false,
  leaflet?: LeafletInterface
) => {
  const starRating = chatbotMessage.rateable
  const type = getInputTypeFromElement(chatbotMessage)
  let dateParams: DateParams | null = null
  let options: QuestionOptionInterface[] = []

  if (chatbotMessage.input.type === ChatscriptMessageInputType.Choices) {
    options = chatbotMessage.input.params.choices.map((choice) => ({
      ...choice,
      value: choice.id,
    }))
  } else if (chatbotMessage.input.type === ChatscriptMessageInputType.Date) {
    dateParams = {
      minDate: chatbotMessage.input.params.min_date,
      maxDate: chatbotMessage.input.params.max_date,
    }
  }

  let text = ''
  let textInternal = ''

  if (chatbotMessage.type !== 'diagnosis' && chatbotMessage.value.text) {
    text = chatbotMessage.value.text

    if (chatbotMessage.value.textInternal) {
      textInternal = chatbotMessage.value.textInternal
    }
  }

  const additionalInputs = chatbotMessage.additional_inputs || []

  return {
    type,
    id: chatbotMessage.id,
    text: formatChatbotText(text),
    textInternal: formatChatbotText(textInternal),
    options: options || [],
    // @ts-ignore
    helpText: chatbotMessage.value?.help_text || null,
    leaflet,
    starRating,
    undoable,
    additionalInputs,
    dateParams,
  }
}

const reduceElementsToText = (
  val: string,
  el: ChatscriptMessage,
  index: number
) => {
  if (typeof el.value.text === 'string' && el.value.text.length === 0) {
    return val
  }

  const concatenator = index > 0 ? '\n\n' : ''

  return [val, concatenator, el.value.text || el.value].join('').trim()
}

const questionFromChatbotElement = async (
  chatbotMessages: ChatscriptMessage[],
  undoable?: boolean
): Promise<QuestionInterface> => {
  const lastMessage = chatbotMessages.slice(-1)[0]

  let leaflet: LeafletInterface | undefined

  // TODO: this relies on notices or leaflets always being the last message received, which
  // so far has been true, but there's no actual guarantee about that
  if (
    lastMessage.type === ChatscriptMessageType.Notice ||
    lastMessage.type === ChatscriptMessageType.Leaflet
  ) {
    leaflet = await getLeafletContent(lastMessage)
  }

  const questionText = formatChatbotText(
    chatbotMessages
      .filter((el) =>
        [ChatscriptMessageType.Leaflet, ChatscriptMessageType.Text].includes(
          el.type
        )
      )
      .reduce(reduceElementsToText, '')
  )

  lastMessage.value.text = questionText

  return questionFromSingleElement(lastMessage, undoable, leaflet)
}

export default questionFromChatbotElement
export { formatChatbotText }
