import { defineMessages } from 'react-intl'

const baseId = 'gp_fax_numbers'

export const messages = defineMessages({
  gpFaxNumbers: {
    id: `${baseId}.gp_fax_numbers`,
    defaultMessage: 'GP Fax Numbers',
  },
  new_fax_numbers_switch_banner: {
    id: `${baseId}.new_fax_numbers_switch_banner`,
    defaultMessage:
      'NEW GP FAX NUMBERS PAGE - Click to go to the old page. You can switch back at any time.',
  },
  edit_name: {
    id: `${baseId}.gp_fax_numbers_edit_name`,
    defaultMessage: 'Name',
  },
  edit_fax_number: {
    id: `${baseId}.gp_fax_numbers_edit_fax_number`,
    defaultMessage: 'Fax number',
  },
  edit_fax_country_code: {
    id: `${baseId}.gp_fax_numbers_edit_fax_country_code`,
    defaultMessage: 'Country code',
  },
  filter_by_name: {
    id: `${baseId}.filter_by_name`,
    defaultMessage: 'Name',
  },
  filter_by_fax_number: {
    id: `${baseId}.filter_by_fax_number`,
    defaultMessage: 'Fax Number (without country code)',
  },
})
