import React from 'react'
import { Button, Text } from '@babylon/core-ui'

import styles from './ReallocateShiftCTA.module.css'

interface ReallocateShiftCTAType {
  ['data-testid']?: string
  disabled?: boolean
  errorMessage?: string
  onClick: () => void
}

export default function ReallocateShiftCTA({
  'data-testid': dataTestId,
  disabled,
  errorMessage,
  onClick,
}: ReallocateShiftCTAType) {
  return (
    <div data-testid={dataTestId} className={styles.ReallocateShiftCTA}>
      {errorMessage && (
        <Text
          data-testid="reallocate-shift-error-message"
          className={styles.ReallocateShiftCTAError}
          color="error"
        >
          {errorMessage}
        </Text>
      )}
      <Button dataTestId="reallocate-cta" disabled={disabled} onClick={onClick}>
        Reallocate selected shift
      </Button>
    </div>
  )
}
