import { IntlFormatters } from 'react-intl'
import * as Yup from 'yup'
import { envVar } from '@babylon/babylon-env'
import { format } from 'date-fns'
import messages from './messages'
import { isValidOrEmptyPhoneNumber } from '../../../../../Edit/utils'

export type CreateNewPatientFormValuesType = {
  firstname: string
  lastname: string
  dateOfBirth?: Date
  addressFirstLine: string
  addressSecondLine: string | null
  state: string
  city: string
  postalCode: string
  phoneNumber: string
  emailAddress: string
}
const validationSchema = (fm: IntlFormatters['formatMessage']) => {
  Yup.addMethod(Yup.string, 'phoneNumber', isValidOrEmptyPhoneNumber)
  const requiredMessage = fm(messages.cant_be_blank)
  const stringRequired = Yup.string().required(requiredMessage)

  return Yup.object().shape({
    firstname: stringRequired,
    lastname: stringRequired,
    addressFirstLine: stringRequired,
    addressSecondLine: Yup.string().nullable(),
    state: stringRequired,
    city: stringRequired,
    postalCode: stringRequired,
    phoneNumber: Yup.string()
      .trim()
      .phoneNumber(fm(messages.invalid_phone_number))
      .required(requiredMessage),
    emailAddress: Yup.string()
      .email(fm(messages.invalid))
      .required(requiredMessage),
    dateOfBirth: Yup.date().required(requiredMessage),
  })
}

export const FORM_INITIAL_VALUES: CreateNewPatientFormValuesType = {
  firstname: '',
  lastname: '',
  addressFirstLine: '',
  addressSecondLine: null,
  postalCode: '',
  state: '',
  phoneNumber: '',
  emailAddress: '',
  dateOfBirth: undefined,
  city: '',
}
const getCountryCode = () => {
  const ENVIRONMENT = envVar('ENVIRONMENT')

  return (ENVIRONMENT?.split?.('-')[1] || 'uk').toLowerCase()
}
export const variablesForCreatePatientMutation = (
  values: CreateNewPatientFormValuesType
) => {
  const addressSecondLine = values?.addressSecondLine
  const addressLine = addressSecondLine?.length
    ? [values.addressFirstLine, addressSecondLine]
    : [values.addressFirstLine]

  return {
    input: {
      info: {
        birthDate: values.dateOfBirth
          ? format(values.dateOfBirth, 'yyyy-MM-dd')
          : null,
      },
      primaryName: {
        family: values.lastname,
        given: [values.firstname],
      },
      primaryEmailAddress: values.emailAddress,
      primaryPhoneNumber: values.phoneNumber,
      primaryAddress: {
        city: values.city,
        state: values.state,
        postalCode: values.postalCode,
        line: addressLine,
        country: getCountryCode(),
      },
    },
  }
}
const GUARDIAN_CODE = 'IqWHpl2qHO'

export const getPatientLinkingInput = (memberId, guardianProfileId) => ({
  input: {
    dependentStubProfileId: memberId,
    registrationStrategy: 'outreach-dependent',
    managingOrganization: 'BABYLON',
    relatedPersons: [
      {
        _registration_fromPatient: guardianProfileId,
        relationship: {
          system: 'https://bbl.health',
          code: GUARDIAN_CODE,
        },
      },
    ],
  },
})

export default validationSchema
