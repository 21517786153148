/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type GetCliniciansQueryVariables = Types.Exact<{
  name?: Types.Maybe<Types.Scalars['String']>
}>

export type GetCliniciansQuery = {
  getEligibleConsultants?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.Consultant, 'id' | 'name'>>>
  >
}

export const GetCliniciansDocument = gql`
  query GetClinicians($name: String) {
    getEligibleConsultants(name: $name) {
      id
      name
    }
  }
`
export function useGetCliniciansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCliniciansQuery,
    GetCliniciansQueryVariables
  >
) {
  return Apollo.useQuery<GetCliniciansQuery, GetCliniciansQueryVariables>(
    GetCliniciansDocument,
    baseOptions
  )
}
export function useGetCliniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCliniciansQuery,
    GetCliniciansQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetCliniciansQuery, GetCliniciansQueryVariables>(
    GetCliniciansDocument,
    baseOptions
  )
}
export type GetCliniciansQueryHookResult = ReturnType<
  typeof useGetCliniciansQuery
>
export type GetCliniciansLazyQueryHookResult = ReturnType<
  typeof useGetCliniciansLazyQuery
>
export type GetCliniciansQueryResult = Apollo.QueryResult<
  GetCliniciansQuery,
  GetCliniciansQueryVariables
>
