import { useFormatMessage } from '@babylon/intl'
import React, { useState } from 'react'
import { ConfirmationModal, KebabMenuRenderOptions } from '@babylon/core-ui'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { useMutation, ApolloError } from '@apollo/client'
import { AlertFilled } from '@babylon/icons'
import messages from './ProfileHeader.messages'
import { RECREATE_USER_GQL } from './queries'
import { MemberOpsModuleName } from '../../../../..'

export interface FixLoginIssuesButtonProps extends KebabMenuRenderOptions {
  patientUuid: string
  email: string
  setSnackbarMessage: (
    message: string,
    icon?: any,
    intent?: any,
    autoHideDuration?: number
  ) => void
}

export const FixLoginIssuesButton = ({
  patientUuid,
  email,
  menuItemProps,
  setSnackbarMessage,
}: FixLoginIssuesButtonProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profilesHeader,
  })
  const fm = useFormatMessage()
  const [showModal, setShowModal] = useState(false)
  const [recreateUserConfirm] = useMutation(RECREATE_USER_GQL, {
    variables: {
      input: {
        id: patientUuid,
        emailAddress: email,
      },
    },
    context: { clientName: 'platform-gateway' },
    onCompleted: (data: any) => {
      setShowModal(false)
      if (data.recreateUser.__typename === 'ResourceNotFoundError') {
        console.error('ERROR message: %s', data.recreateUser.errorMessage)
        setSnackbarMessage(
          fm(messages.fix_login_issues_failed, {
            message: data.recreateUser.errorMessage,
          }),
          AlertFilled,
          'error',
          10000
        )
      } else {
        console.log('Fixed login issues for patientId: %s', patientUuid)
        setSnackbarMessage(fm(messages.fix_login_issues_success))
      }
    },
  })

  return (
    <>
      <button
        {...menuItemProps}
        type="button"
        onClick={() => {
          setShowModal(true)
          trackClick({
            elementName: 'fix-login-issues-check-btn',
            elementType: TrackingElementType.button,
          })
        }}
        data-testid="fix-login-issues-button"
      >
        {fm(messages.fix_login_issues_button)}
      </button>

      <ConfirmationModal
        confirmText={<span>{fm(messages.fix_login_issues_confirmation)}</span>}
        cancelText={<span>{fm(messages.fix_login_issues_cancel)}</span>}
        onClose={() => {
          setShowModal(false)
        }}
        onConfirm={() => {
          recreateUserConfirm().catch(
            ({
              message,
              graphQLErrors,
              networkError,
              extraInfo,
            }: ApolloError) => {
              console.error(
                'ERROR message: %s, graphQLErrors: %s, networkError: %s, extraInfo: %s',
                message,
                graphQLErrors.map((e) => JSON.stringify(e)).join('\n'),
                networkError,
                extraInfo
              )
              setSnackbarMessage(
                fm(messages.fix_login_issues_failed, { message }),
                AlertFilled,
                'error',
                10000
              )
            }
          )
        }}
        open={showModal}
        includeClose={false}
      >
        <ConfirmationModal.Heading>
          {fm(messages.fix_login_issues_header)}
        </ConfirmationModal.Heading>
        <ConfirmationModal.Content>
          <p>{fm(messages.fix_login_issues_content)}</p>
        </ConfirmationModal.Content>
      </ConfirmationModal>
    </>
  )
}
