import { gql } from '@apollo/client'

export const QUERY_DEVICES = gql`
  query AP_GetDevices($patientId: ID!) {
    getDevices(patientId: $patientId) {
      platform
      appVersion
      dateLastUsed
    }
  }
`
