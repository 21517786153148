import { gql } from '@apollo/client'

export interface SyncHumanityDataItem {
  id: string
  name: string
}

export interface SyncHumanityData {
  userId: string
  fromDate: string
  toDate: string
  clinicians?: SyncHumanityDataItem[]
  supplyNetworks?: SyncHumanityDataItem[]
  professions?: SyncHumanityDataItem[]
  practices?: SyncHumanityDataItem[]
}

export default gql`
  mutation SyncHumanity($filters: SyncHumanityFilters!) {
    syncHumanity(filters: $filters) {
      humanity_sync_id
    }
  }
`
