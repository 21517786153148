import React, { useMemo } from 'react'
import { useField } from 'formik'
import { envFlag } from '@babylon/babylon-env'

import { RadioGroup, FormField, Radio, Text, Theme } from '@babylon/core-ui'

import { OTHER_SERVICE_TYPE } from './utils'

import styles from './ServiceTypeSelection.module.css'

interface ServiceTypeSelectionProps {
  serviceTypes: ServiceType[]
  onSelect: (ServiceType: ServiceType) => void
}

export default function ServiceTypeSelection({
  serviceTypes = [],
  onSelect,
}: ServiceTypeSelectionProps) {
  const frequentlyUsed = useMemo(
    () => serviceTypes.filter((serviceType) => serviceType?.common),
    [serviceTypes]
  )

  const fieldName = 'service_type_uuid'
  const [{ value }, , { setValue }] = useField(fieldName)

  const serviceTypePerUuid = useMemo(
    () =>
      serviceTypes.reduce((acc, serviceType) => {
        acc[serviceType.uuid] = serviceType

        return acc
      }, {}),
    [serviceTypes]
  )

  const handleRadioSelect = (serviceTypeUuid) => {
    setValue(serviceTypeUuid)
    onSelect(serviceTypePerUuid[serviceTypeUuid] || OTHER_SERVICE_TYPE)
  }

  return (
    <Theme className={styles.RadioTheme}>
      <FormField id={fieldName}>
        {frequentlyUsed.length > 0 && (
          <RadioGroup
            value={value}
            name={fieldName}
            columns={3}
            label="Frequently used"
            onChange={handleRadioSelect}
          >
            {frequentlyUsed.map(({ uuid, name }) => (
              <Radio key={uuid} value={uuid}>
                <Text>{name}</Text>
              </Radio>
            ))}
          </RadioGroup>
        )}
        <RadioGroup
          value={value}
          name={fieldName}
          label="All"
          columns={3}
          onChange={handleRadioSelect}
        >
          {serviceTypes.map(({ uuid, name }) => (
            <Radio className={styles.RadioButton} key={uuid} value={uuid}>
              <Text>{name}</Text>
            </Radio>
          ))}
          {envFlag('ENABLE_CREATION_WITHOUT_SERVICE_TYPE', false) && (
            <Radio
              className={styles.RadioButton}
              value={OTHER_SERVICE_TYPE.uuid}
            >
              <Text>{OTHER_SERVICE_TYPE.name}</Text>
            </Radio>
          )}
        </RadioGroup>
      </FormField>
    </Theme>
  )
}
