import { defineMessages } from 'react-intl'

const baseId = 'member_edit_contact_details'

export default defineMessages({
  title: {
    id: `${baseId}.title`,
    defaultMessage: 'Contact details',
  },
  email: {
    id: `${baseId}.email`,
    defaultMessage: 'Email',
  },
  phone_number: {
    id: `${baseId}.phone_number`,
    defaultMessage: 'Phone number',
  },
  address: {
    id: `${baseId}.address`,
    defaultMessage: 'Address',
  },
  address_state_code: {
    id: `${baseId}.address_state_code`,
    defaultMessage: 'State',
  },
  postcode: {
    id: `${baseId}.postcode`,
    defaultMessage: 'Postcode',
  },
  region: {
    id: `${baseId}.region`,
    defaultMessage: 'Region',
  },
  country: {
    id: `${baseId}.country`,
    defaultMessage: 'Country',
  },
  emergency_title: {
    id: `${baseId}.emergency_title`,
    defaultMessage: 'Emergency contact details {number}',
  },
  emergency_first_name: {
    id: `${baseId}.emergency_first_name`,
    defaultMessage: 'First name',
  },
  emergency_last_name: {
    id: `${baseId}.emergency_last_name`,
    defaultMessage: 'Last name',
  },
  emergency_phone_number: {
    id: `${baseId}.emergency_phone_number`,
    defaultMessage: 'Phone number',
  },
  emergency_email: {
    id: `${baseId}.emergency_email`,
    defaultMessage: 'Email',
  },
})
