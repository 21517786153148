import React from 'react'
import { Grid, Cell } from '@babylon/core-ui'

import AvailabilitySlots from './AvailabilitySlots'
import AppointmentDetails from '../AppointmentDetails'
import { AppointmentFilters } from '../AppointmentFilters'

import Calendar from '../ScheduleAppointment/BookAppointmentView/components/Calendar'
import RequirementsFields from '../ScheduleAppointment/BookAppointmentView/components/RequirementsFields'

import styles from './AvailabilityView.module.css'

export default function AvailabilityView({
  appointmentClinic,
  appointmentClinicList,
  appointmentClinicLoading,
  appointmentClinicUpdate,
  consultant,
  consultantList,
  consultantLoading,
  consultantType,
  consultantTypeList,
  consultantTypeUpdate,
  consultantUpdate,
  consultationType,
  consultationTypeList,
  consultationTypeUpdate,
  consultationTimezone,
  consultationTimezoneUpdate,
  disabled,
  filters,
  languageRequested,
  memberId,
  onEdit,
  patient,
  selectedDate,
  selectedDateUpdate,
  selectedWeek,
  selectedWeekUpdate,
  serviceTypeUuid,
  slotDuration,
  slotDurationList,
  slotDurationUpdate,
  consentExpired,
}) {
  return (
    <div className={styles.body}>
      <Grid columns="5" height="100%">
        <Cell width="1" className={styles.left}>
          <Calendar
            appointmentClinic={appointmentClinic}
            consultationType={consultationType}
            selectedDate={selectedDate}
            selectedDateUpdate={selectedDateUpdate}
            selectedWeek={selectedWeek}
            selectedWeekUpdate={selectedWeekUpdate}
          />
          <AppointmentDetails
            filters={filters}
            serviceTypes={patient.service_types}
            onEdit={onEdit}
          />
          <AppointmentFilters
            filters={filters}
            consultant={consultant}
            sharedFilterClassName={styles.commonDropdown}
            consultationTypeList={consultationTypeList}
            consultationTimezoneUpdate={consultationTimezoneUpdate}
            consultantTypeList={consultantTypeList}
            slotDurationList={slotDurationList}
            consultantList={consultantList}
            appointmentClinicList={appointmentClinicList}
            consultationTypeUpdate={consultationTypeUpdate}
            consultantTypeUpdate={consultantTypeUpdate}
            slotDurationUpdate={slotDurationUpdate}
            consultantUpdate={consultantUpdate}
            appointmentClinicUpdate={appointmentClinicUpdate}
            consultantType={consultantType}
            consultationType={consultationType}
            consultantLoading={consultantLoading}
            appointmentClinicLoading={appointmentClinicLoading}
            disabled={disabled}
          />
          <div>
            <RequirementsFields languageRequested={languageRequested} />
          </div>
        </Cell>
        <Cell width="4" className={styles.right}>
          <AvailabilitySlots
            clinicList={appointmentClinicList}
            consultationType={consultationType}
            consultantType={consultantType}
            memberId={memberId}
            selectedClinicId={appointmentClinic}
            selectedClinicianId={consultant}
            selectedDate={new Date(selectedDate).toString()}
            selectedWeek={selectedWeek}
            serviceTypeUuid={serviceTypeUuid}
            slotDuration={slotDuration}
            timezoneId={consultationTimezone}
            appointmentFeatures={filters?.appointment_features}
            consentExpired={consentExpired}
          />
        </Cell>
      </Grid>
    </div>
  )
}
