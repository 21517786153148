import React, { FC, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Spinner } from '@babylon/core-ui'
import { PATIENTS_BY_IDS } from '../../../data/queries'
import {
  OnDetailsCopy,
  OnTransferOwnership,
  OnResendInvitationEmail,
} from '../../../types'
import MemberContext from '../data/context'
import MemberCard from '../MemberCard'
import styles from './styles.module.scss'

export interface Props {
  onDetailsCopy: OnDetailsCopy
  onTransferOwnership: OnTransferOwnership
  onResendInvitationEmail: OnResendInvitationEmail
}

export interface QueriedFamilyMembersProps extends Props {
  ids: string[]
  onFamilyMembersLoaded: (familyMembers: Member[]) => void
}

export interface FamilyMembersProps extends Props {
  members: Member[]
}

const FamilyMembers = ({
  members,
  onDetailsCopy,
  onTransferOwnership,
  onResendInvitationEmail,
}: FamilyMembersProps) => (
  <>
    {members.map((member: Member) => (
      <div
        key={member.id}
        className={styles.familyMember}
        data-testid="search-result-family-member"
      >
        <MemberCard
          isMain={false}
          {...member}
          onDetailsCopy={onDetailsCopy}
          onTransferOwnership={onTransferOwnership}
          onResendInvitationEmail={onResendInvitationEmail}
        />
      </div>
    ))}
  </>
)

const QueriedFamilyMembers: FC<QueriedFamilyMembersProps> = ({
  ids,
  onFamilyMembersLoaded,
  ...rest
}) => {
  const { familyMembers } = useContext(MemberContext)

  const familyMembersLoadedHandler = ({ patientsByIds }: any) => {
    onFamilyMembersLoaded(patientsByIds)
  }

  const { loading, error, data } = useQuery(PATIENTS_BY_IDS, {
    notifyOnNetworkStatusChange: true,
    variables: { ids },
    onCompleted: familyMembersLoadedHandler,
  })

  if (familyMembers && familyMembers.length) {
    return <FamilyMembers members={familyMembers} {...rest} />
  }

  if (loading) {
    return (
      <div className={styles.spinner}>
        <Spinner centered size="large" color="var(--primary-color)" />
      </div>
    )
  }

  if (error) {
    return <>{`Error! ${error} ${data}`}</>
  }

  return null
}

export default QueriedFamilyMembers
