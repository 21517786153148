import React, { useEffect } from 'react'
import { configureScope } from '@sentry/browser'

import { useBabylonUser } from '@babylon/babylon-user'
import { envUrl } from '@babylon/babylon-env'
import { Button, spinnerLoaderInstance } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { addSessionId, removeSessionId } from '@babylon/member-operations'
import * as Access from '@babylon/web-platform-access'
import { PageErrorMessage } from '@babylon/cce-shared-components'

import AppRouting from '../AppRouting'

import styles from './styles.module.css'
import messages from './AppContent.messages'
import { useLogout } from '@/util/useLogout'

const CLINICAL_PORTAL_URL = envUrl('CLINICAL_PORTAL_URL')
const UserAppContent = () => {
  const fm = useFormatMessage()
  const logout = useLogout()
  const user = useBabylonUser()
  const [
    canAccessAdminPortal,
    canAccessAdminPortalError,
    canAccessAdminPortalLoading,
  ] = Access.useDecide('admin-portal')

  useEffect(() => {
    addSessionId()
    window.addEventListener('beforeunload', removeSessionId)
    return () => window.removeEventListener('beforeunload', removeSessionId)
  }, [])

  if (canAccessAdminPortalError) {
    return <PageErrorMessage />
  }

  if (canAccessAdminPortalLoading) {
    return spinnerLoaderInstance
  }

  if (!canAccessAdminPortal) {
    return (
      <div className={styles.userTypeWarning}>
        <div className={styles.userWarningText}>
          {fm(messages.user_warning)}
          <br />
          <a
            href={`${CLINICAL_PORTAL_URL}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fm(messages.use_clinical_portal)}
          </a>
          <br />
          <Button className={styles.logoutButton} onClick={() => logout()}>
            {fm(messages.logout)}
          </Button>
        </div>
      </div>
    )
  }

  const { id, type, timezone, roles } = user
  configureScope((scope) => {
    scope.setUser({ id, type, timezone, roles })
  })

  return <AppRouting />
}

export default UserAppContent
