import { format } from 'date-fns-tz'

export function getTimezoneGMT(
  date?: Date | string,
  timezoneId?: string | null,
  dateFormat?: string
): string {
  if (!timezoneId) return ''
  return format(new Date(date || ''), `${dateFormat || ''}(OOO)`, {
    timeZone: timezoneId,
  })
}
