import React from 'react'
import { Field, FieldProps } from 'formik'
import { FormField, PillContainer } from '@babylon/core-ui'

import ConsultationTypes, {
  ConsultationTypeName,
} from '../types/ConsultationTypes'

interface Props {
  consultationTypes?: ConsultationTypeName[]
  onChange?: (value: ConsultationTypeName) => void
}

const mapMediumToPill = (consultationTypes: ConsultationTypeName[] = []) =>
  consultationTypes.map((consultationType: ConsultationTypeName) => ({
    name: ConsultationTypes[consultationType],
    key: consultationType,
    id: consultationType,
  }))

const ConsultationTypePills = ({ consultationTypes, onChange }: Props) => (
  <Field name="consultation_type">
    {({ field: { value }, form: { setFieldValue } }: FieldProps<string>) => (
      <FormField id="consultation_type" label="Medium of Appointment:">
        <PillContainer
          pills={mapMediumToPill(consultationTypes)}
          selected={value}
          onSelectPill={(selectedValue: ConsultationTypeName) => {
            setFieldValue('consultation_type', selectedValue)

            if (onChange) {
              onChange(selectedValue)
            }
          }}
        />
      </FormField>
    )}
  </Field>
)

export { mapMediumToPill }

export default ConsultationTypePills
