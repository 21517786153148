import { gql } from '@apollo/client'

const platformGatewayPatientFragment = `
  accountOwner
  address {
    line1
    line2
    line3
    city
    state
    postalCode
  }
  coreRubyPatientId
  dateOfBirth
  email
  firstName
  gender {
    id
  }
  healthcareIdentifier {
    unconfirmedPublicIdentifier
    privateIdentifier
    publicIdentifier
  }
  id
  identityCheck {
    status
  }
  invitationWasSentButNotAccepted
  lastName
  minor
  phoneNumber
  preferredConsumerNetworkUuid
  publicHealthcareAdmission {
    id
    pdsTraceStatus
  }
  queuedForActivation
`

export const PatientSearchV2 = gql`
  query V2_SearchPatients(
    $first: NonNegativeInt
    $last: NonNegativeInt
    $after: Cursor
    $before: Cursor
    $search: MembersSearch!
  ) {
    members(
      first: $first
      after: $after
      last: $last
      before: $before
      search: $search
    ) {
      results {
        edges {
          node {
            ${platformGatewayPatientFragment}
            familyMembers {
              edges {
                node {
                  to {
                    ${platformGatewayPatientFragment}
                  }
                }
              }
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
      totalResults
    }
  }
`

export const GetConsumerNetworks = gql`
  query AP_getConsumerNetworks {
    consumerNetworks {
      name
      uuid
    }
  }
`

export const GetSupportInteraction = gql`
  query AP_getSupportInteraction(
    $transactionId: ID!
    $transactionType: String!
  ) {
    supportInteraction(
      transactionId: $transactionId
      transactionType: $transactionType
    ) {
      transactionId
      transactionType
      phoneNumber
      email
    }
  }
`
