import React from 'react'
import { useProductConfig } from '@babylon/product-config'
import * as Access from '@babylon/web-platform-access'
import Profile from './Profile'
import PatientSearch from './PatientSearch'
import AppBar from '../AppBar'

interface Props {
  renderMenuToggleButton?: () => JSX.Element
}

const TopBar = ({ renderMenuToggleButton }: Props) => {
  const { getProp } = useProductConfig()
  const sideNavEnabled = getProp('memberOperations', 'sideNavEnabled')
  const [canAccessMemberSearch] = Access.useDecide('member-search')

  const leadingSection = () => (
    <>
      {sideNavEnabled && renderMenuToggleButton && renderMenuToggleButton()}
      {canAccessMemberSearch && <PatientSearch />}
    </>
  )

  const trailingSection = () => <Profile />

  return (
    <AppBar leadingSection={leadingSection} trailingSection={trailingSection} />
  )
}

export default TopBar
