import React from 'react'
import { result } from '@babylon/babylon-forms/forms'
import { graphql } from '@apollo/client/react/hoc'

const graphqlWithProps = (query, config) => {
  const { props, name, options, ...otherConfig } = config || {}

  const getVariables = (props) => {
    const optionsResult = result(options, props) || {}

    return optionsResult.variables
  }

  return (Component) => {
    let onNextResponse

    const GraphqlComponent = graphql(query, {
      ...otherConfig,
      props: ({ ownProps, ...rest }) => {
        const {
          _graphqlVariables_,
          _updateVariables_,
          ...otherProps
        } = ownProps
        const dataName = name || (rest.data ? 'data' : 'mutate')
        const data = {
          ...rest[dataName],
          updateVariables: _updateVariables_,
        }

        if (onNextResponse) {
          setTimeout(onNextResponse, 0)
          onNextResponse = null
        }

        return props
          ? props({
              ownProps: otherProps,
              [dataName]: data,
            })
          : {
              ...otherProps,
              [dataName]: data,
            }
      },
      options: ({ _graphqlVariables_ }) => ({
        ...options,
        variables: _graphqlVariables_,
      }),
    })(Component)

    return class extends React.Component {
      constructor(props) {
        super(props)
        this.state = {
          variables: getVariables(props),
        }
      }

      // TO-DO: fix unsafe method:
      // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
      UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.variables !== nextProps.variables) {
          this.setState({ variables: getVariables(nextProps) })
        }
      }

      updateVariables = (variables, callback) => {
        this.setState({
          variables: {
            ...this.state.variables,
            ...variables,
          },
        })
        onNextResponse = callback
      }

      render() {
        const props = {
          ...this.props,
          _graphqlVariables_: this.state.variables,
          _updateVariables_: this.updateVariables,
        }

        return <GraphqlComponent {...props} />
      }
    }
  }
}

export default graphqlWithProps
