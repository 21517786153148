import { graphql, withApollo } from '@apollo/client/react/hoc'
import { compose, withHandlers, withStateHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'

import withSpinner from '@/util/withSpinner'
import withErrorMessage from '@/util/withErrorMessage'
import graphqlWithProps from '@/util/graphqlWithProps'

import { AdminActiveIngredients, DeleteAdminDrugAlert } from './queries'

import ActiveIngredientsView from './ActiveIngredientsView'

// DATA

export const queryOptions = {
  options: {
    variables: {
      size: 10,
      page: 0,
      query: '',
      sort: 'name,asc',
    },
  },
}

const withData = compose(
  graphqlWithProps(AdminActiveIngredients, queryOptions),
  graphql(DeleteAdminDrugAlert, { name: 'deleteAdminDrugAlert' })
)

// STATE

export const defaultState = {
  spinnerType: null,
}

export const setSpinnerType = (state) => (spinnerType) => ({
  ...state,
  spinnerType,
})

const withState = withStateHandlers(defaultState, {
  setSpinnerType,
})

// ACTIONS

export const handlePageSizeChange = ({ data, setSpinnerType }) => (size) => {
  setSpinnerType('page-size')
  data.updateVariables({ size, page: 0 })
}

export const handlePageChange = ({ data, setSpinnerType }) => (page) => {
  setSpinnerType('pagination')
  data.updateVariables({ page })
}

export const updateVariables = debounce(
  (data, query) => data.updateVariables({ query, page: 0 }),
  500
)

export const handleSearchChange = ({ data }) => (event, query) => {
  updateVariables(data, query)
}

export const handleSort = ({ data, setSpinnerType }) => (column, direction) => {
  setSpinnerType('sort')
  data.updateVariables({
    sort: `${column},${direction}`,
    page: 0,
  })
}

export const handleAuditClick = ({ history }) => (event, activeIngredients) => {
  history.push(`/admin/drugs/active-ingredients/audits/${activeIngredients.id}`)
}

const withActions = withHandlers({
  handlePageSizeChange,
  handlePageChange,
  handleSearchChange,
  handleSort,
  handleAuditClick,
})

// LOADER

export const isLoading = (props) =>
  !props.data || !props.data.adminActiveIngredients

const withLoader = withSpinner(isLoading)

export default compose(
  withRouter,
  withData,
  withState,
  withApollo,
  withErrorMessage(),
  withActions,
  withLoader
)(ActiveIngredientsView)
