import React from 'react'
import Icon from '@/components/Icon'

const LabelWithIcon = ({ icon, label }) => (
  <span>
    <Icon name={icon} />
    <span> {label}</span>
  </span>
)

export default LabelWithIcon
