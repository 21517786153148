import { useState, useEffect } from 'react'

import { UseSelectionControlV2, ISelectionControlOption } from '../form.types'

const useSelectionControl: UseSelectionControlV2 = (
  options,
  initialValue = '',
  onChange,
  isControlledComponent = false
) => {
  const [selectOptions, setSelectOptions] = useState(options)

  const currentOptions = isControlledComponent ? [...options] : selectOptions

  const selectInitialValue = () =>
    selectOptions.map((option) =>
      option.value === initialValue ? { ...option, selected: true } : option
    )

  useEffect(() => {
    if (isControlledComponent) {
      return
    }

    const updatedOptions = selectInitialValue()

    setSelectOptions(updatedOptions)
  }, [options]) // eslint-disable-line react-hooks/exhaustive-deps

  const onOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedSelectOptions = currentOptions.map((opt) => {
      if (opt.value === event.target.value) {
        opt.selected = true
      } else {
        opt.selected = false
      }

      return opt
    })

    if (!isControlledComponent) {
      setSelectOptions(changedSelectOptions)
    }

    if (onChange) {
      const selectedOptions = currentOptions
        .filter((option: ISelectionControlOption) => option.selected)
        .map((option: ISelectionControlOption) => option.value)

      onChange(selectedOptions[0])
    }
  }

  return { selectOptions, onOptionChange }
}

export default useSelectionControl
