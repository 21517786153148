import { differenceInYears, differenceInMonths } from 'date-fns'
import { IntlFormatters } from 'react-intl'
import messages from '../CopiableMemberInformation.messages'

/**
 * Utility function localises a user's age
 *
 * @param birthdate -  the member's birthdate
 * @param formatter - the localisation formatter
 */
export default (
  dateOfBirth: string | null,
  formatter: IntlFormatters['formatMessage']
) => {
  const years = dateOfBirth
    ? differenceInYears(new Date(), new Date(dateOfBirth))
    : undefined
  const months =
    years === 0 && dateOfBirth
      ? differenceInMonths(new Date(), new Date(dateOfBirth))
      : undefined

  if (years === undefined && months === undefined) {
    return null
  }

  return years === 0 && months !== undefined
    ? `(${formatter(messages.display_age_months, { months })})`
    : `(${formatter(messages.display_age_years, { years })})`
}
