import React, { ReactNode } from 'react'
import {
  set,
  formatISO,
  getMinutes,
  differenceInCalendarDays,
  addHours,
} from 'date-fns'

import { QueryParamsProvider } from '../Utils'

const getDefaultStartTime = () => {
  const timeNow = new Date()
  const minutesNow = getMinutes(timeNow)
  const closestRoundMinutes = Math.ceil(minutesNow / 5) * 5

  return set(timeNow, {
    minutes: closestRoundMinutes + 5,
    seconds: 0,
    milliseconds: 0,
  })
}

const getDefaultEndTime = () => {
  const defaultStartTime = getDefaultStartTime()
  const maxEndTime = set(defaultStartTime, {
    hours: 23,
    minutes: 59,
    seconds: 0,
    milliseconds: 0,
  })
  const shiftEndTime = addHours(defaultStartTime, 8)

  return differenceInCalendarDays(shiftEndTime, maxEndTime) > 0
    ? maxEndTime
    : shiftEndTime
}

const reallocateShiftPageParams = {
  date: {
    default: () => formatISO(new Date(), { representation: 'date' }),
    encode: (value: Date): string =>
      formatISO(value, { representation: 'date' }),
  },
  shift_start: {
    default: () => formatISO(getDefaultStartTime(), { representation: 'time' }),
    encode: (value: Date): string =>
      formatISO(value, { representation: 'time' }),
  },
  shift_end: {
    default: () =>
      formatISO(getDefaultEndTime(), {
        representation: 'time',
      }),
    encode: (value: Date): string =>
      formatISO(value, { representation: 'time' }),
  },
  first_clinician_id: {
    default: null,
    decode: (id: string): number => parseInt(id, 10),
  },
  covering_clinician_id: {
    default: null,
    decode: (id: string): number => parseInt(id, 10),
  },
}

export default function ReallocateShiftPageParams({
  children,
}: {
  children: ReactNode
}) {
  return (
    <QueryParamsProvider pageParams={reallocateShiftPageParams}>
      {children}
    </QueryParamsProvider>
  )
}
