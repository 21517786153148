import React from 'react'

import { Form, Button } from '@babylon/babylon-forms/forms'
import { required, isInt } from '@babylon/babylon-forms/validation'

import { Text, Select, Checkbox, Row } from '@babylon/babylon-forms/widgets'

import timePeriodOptions from './timePeriodOptions.json'

import styles from './styles.module.scss'

const regionFormat = ({ regionCode, name }) => (
  <span>
    <strong>{regionCode}</strong> - {name}
  </span>
)

const DrugAlertEditView = ({
  regions,
  store,
  getActiveIngredientOptions,
  handleSubmit,
  handleCancel,
}) => (
  <Form
    store={store}
    onSubmit={handleSubmit}
    className={styles.drugAlertForm}
    autoComplete="off"
  >
    <Select
      disabled={!!store.state.id}
      label="Active ingredient"
      autocomplete
      objectValue
      name="activeIngredient"
      labelKey="name"
      valueKey="activeIngredientId"
      loadOptions={getActiveIngredientOptions}
      autoload={false}
      validate={required('Active ingredient must be selected')}
    />
    <Row stretch even className={styles.shrink}>
      <Select
        multi
        autocomplete
        objectValue
        name="regions"
        label="Select region"
        options={regions}
        valueKey="regionCode"
        labelKey="regionCode"
        optionRenderer={regionFormat}
        validate={required('At least one region must be selected')}
      />
      <Checkbox name="allowPrescribe" label="Allow prescription" />
    </Row>
    <Row stretch even end>
      <Text
        name="prescriptionLimit"
        label="Set prescription limit"
        value={(state) => String(state.prescriptionLimit || '')}
        validate={[
          isInt('Prescription limit must be a number'),
          isInt('Prescription limit must be greater then zero', {
            min: 1,
          }),
          isInt('The value is beyond of the maximum limit', {
            max: 32767,
          }),
        ]}
      />
      <Select
        placeholder="Select max time period"
        name="timePeriod"
        label="Max time period"
        options={timePeriodOptions}
        validate={required('Time period must be selected')}
      />
    </Row>
    <div className={styles.buttonRow}>
      <Button>Save</Button>
      <Button outline type="button" onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  </Form>
)

export default DrugAlertEditView
