import { defineMessages } from 'react-intl'

const baseId = 'assessment_view'

export default defineMessages({
  resetAssessment: {
    id: `${baseId}.reset_assessment`,
    defaultMessage: 'Reset assessment',
  },
})
