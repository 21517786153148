import { defineMessages } from 'react-intl'

const baseId = 'prescription_links'

export default defineMessages({
  active_prescriptions: {
    id: `${baseId}.active_prescriptions`,
    defaultMessage: 'Active prescriptions',
  },
  archived_prescriptions: {
    id: `${baseId}.archived_prescriptions`,
    defaultMessage: 'Archived prescriptions',
  },
})
