import { useQuery } from '@apollo/client'
import { useProductConfig } from '@babylon/product-config'
import EHR_PATIENT_QUERY from './query'

/**
 * Hook fetches an US EHR Patient
 */
function useEhrPatient(patientId: string | null) {
  const { getProp } = useProductConfig()

  const includeEHRPatient =
    getProp('memberOperations', 'usEhrPatientQueryEnabled') ?? false

  const { data = {}, loading, error } = useQuery(EHR_PATIENT_QUERY, {
    variables: {
      patientId,
    },
    skip: includeEHRPatient !== true || !patientId,
  })

  return {
    ehrPatient: data.getEHRPatient ?? null,
    loading,
    error,
  }
}

export default useEhrPatient
