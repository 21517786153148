import React, { ReactNode } from 'react'
import cn from 'classnames'

import { Spinner } from '@babylon/core-ui'
import styles from './LoadingContainer.module.css'

interface LoadingContainerProps {
  children?: ReactNode
  className?: string
  'data-testid'?: string
  fill?: boolean
  loading?: boolean
}

export default function LoadingContainer({
  children,
  className,
  'data-testid': dataTestId = 'loading-spinner',
  fill = false,
  loading = false,
}: LoadingContainerProps) {
  return (
    <div
      className={cn(
        styles.container,
        { [styles.containerFill]: fill },
        className
      )}
    >
      {children}
      {loading && (
        <div data-testid={dataTestId} className={styles.overlay}>
          <Spinner size="large" centered className={styles.spinner} />
        </div>
      )}
    </div>
  )
}
