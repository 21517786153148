import React, { useState, ReactElement } from 'react'
import { Query } from '@apollo/client/react/components'
import { QueryResult } from '@apollo/client'
import { Input, Modal } from '@babylon/core-ui'
import EntityList from './EntityList'
import { Entity, EntityQuery, EntityRendererProps } from './index'
import titleCase from './titleCase'
import styles from '../../ServiceMix.module.css'

export interface SelectableEntity<E extends Entity> {
  entity: E
  readOnly: boolean
  selected?: boolean
}

interface EntityItemsModalProps<E extends Entity> {
  name: string
  query: EntityQuery<E>
  entityRenderer: (props: EntityRendererProps<E>) => ReactElement
  currentItems: E[]
  open: boolean
  allowFiltering: boolean
  onClose: () => void
  onItemsAdded: (itemIds: string[]) => void
}

const EntityItemsModal = <E extends Entity>({
  name,
  query,
  entityRenderer,
  currentItems,
  open,
  allowFiltering,
  onClose,
  onItemsAdded,
}: EntityItemsModalProps<E>) => {
  const [filterText, setFilterText] = useState('')
  const titleCaseName = titleCase(name)

  return (
    <Modal
      label={`${titleCaseName}(s)`}
      open={open}
      onClose={onClose}
      className={styles.MedKitTheme}
    >
      <h1>{`${titleCaseName}(s)`}</h1>
      {allowFiltering ? (
        <Input
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          style={{ width: '100%', marginBottom: '20px' }}
          placeholder={`Search for a ${name} by name`}
        />
      ) : null}

      <Query query={query.query} variables={query.variables(filterText)}>
        {({ loading, error, data }: QueryResult) => {
          if (error) {
            return <span>{`Error! ${error}`}</span>
          }

          const items: E[] = loading ? [] : query.resultSelector(data)
          const selectableItems: SelectableEntity<E>[] = items.map((i) => ({
            entity: i,
            readOnly: currentItems.some((cI) => cI.id === i.id),
          }))

          return (
            <EntityList
              name={name}
              loading={loading}
              items={selectableItems}
              entityRenderer={entityRenderer}
              onItemsAdded={(itemIds) => {
                onItemsAdded(itemIds)
                onClose()
              }}
            />
          )
        }}
      </Query>
    </Modal>
  )
}

export default EntityItemsModal
