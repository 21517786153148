import React, { useState } from 'react'
import MembersSearchView from './MembersSearchView'
import { parseErrors, formatParams } from './utils'
import {
  useMemberSearchQuery,
  useMemberSearchValidation,
  useSupportInteractionQuery,
} from './hooks'

export interface MembersSearchWrapperProps {
  goToGQL: VoidFunction
  goToCoreRuby: VoidFunction
}

const MembersSearchWrapper = ({
  goToGQL,
  goToCoreRuby,
}: MembersSearchWrapperProps) => {
  const [errors, setErrors] = useState<Array<string>>([])
  const [searchParams, setSearchParams] = useState<any | null>(null)
  const completeSearch = () => setErrors([])

  const memberSearchValidationSchema = useMemberSearchValidation()
  const [getMembers, { data, loading }] = useMemberSearchQuery({
    onCompleted: () => completeSearch(),
    onError: (error) => setErrors(parseErrors(error)),
    errorPolicy: 'all',
  })

  const clearSearch = () => {
    setSearchParams(null)
  }

  const submitSearch = (
    formParams: { [key: string]: any },
    variables: object
  ) => {
    try {
      memberSearchValidationSchema.validateSync(formParams, {
        abortEarly: false,
      })
      getMembers({
        variables: {
          search: formatParams(formParams),
          ...variables,
        },
      })
    } catch (e) {
      setErrors(e.inner.map((err) => err.message))
    }
  }

  const onSearch = (params, variables) => {
    setSearchParams(params)
    setErrors([])
    submitSearch(params, variables)
  }

  const { loading: supportInteractionLoading } = useSupportInteractionQuery({
    onCompleted: ({ supportInteraction }) => {
      const { email, phoneNumber } = supportInteraction ?? {}
      setSearchParams({
        email,
        phoneNumber,
      })
      submitSearch({ email, phoneNumber }, { first: 10 })
    },
    onError: (error) => setErrors(parseErrors(error)),
  })

  return (
    <MembersSearchView
      data={searchParams !== null ? data : null}
      errors={errors}
      loading={loading || supportInteractionLoading}
      onChangePage={submitSearch}
      onClear={clearSearch}
      onSearch={onSearch}
      searchParamsSnapshot={searchParams}
      fieldValues={searchParams}
      goToGQL={goToGQL}
      goToCoreRuby={goToCoreRuby}
    />
  )
}

export default MembersSearchWrapper
