import { defineMessages } from 'react-intl'

const baseId = 'assessment_card'

export default defineMessages({
  header: {
    id: `${baseId}.header`,
    defaultMessage: 'Assessment',
  },
})
