import { gql } from '@apollo/client'

export const EDIT_GP_FAX_NUMBERS = gql`
  mutation EditGPOrganization($input: UpdateGpOrganizationInput!) {
    updateGpOrganization(input: $input) {
      ... on UpdateGPOrganizationError {
        errorMessage
      }
      ... on GPOrganization {
        id
        name
        telecom(first: 10) {
          nodes {
            ... on FaxNumberContactPoint {
              faxNumber
              faxCountryCode
              system
            }
          }
        }
      }
    }
  }
`

export const DELETE_GP_FAX_NUMBER = gql`
  mutation DeleteGPOrganization($input: DeleteGpOrganizationInput!) {
    deleteGpOrganization(input: $input) {
      ... on DeleteGpOrganizationError {
        errorMessage
      }
      ... on GPOrganization {
        id
        name
      }
    }
  }
`

export const CREATE_GP_FAX_NUMBER = gql`
  mutation CreateGPOrganization($input: CreateGpOrganizationInput!) {
    createGpOrganization(input: $input) {
      ... on CreateGPOrganizationError {
        errorMessage
      }
      ... on GPOrganization {
        id
        name
        telecom(first: 10) {
          nodes {
            ... on FaxNumberContactPoint {
              faxNumber
              faxCountryCode
              system
            }
          }
        }
      }
    }
  }
`
