import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import messages from './Unknown.messages'
import styles from './styles.module.scss'

export interface UnknownProps {
  label?: string
}

const Unknown = ({ label }: UnknownProps) => {
  const fm = useFormatMessage()
  const { unknown } = messages

  return <span className={styles.unknown}>{label || fm(unknown)}</span>
}

export default Unknown
