import { defineMessages } from 'react-intl'

const baseId = 'assessment_risk_form'

export default defineMessages({
  selectLevel: {
    id: `${baseId}.select_level`,
    defaultMessage: 'Select risk level',
  },
  selectLevelError: {
    id: `${baseId}.select_level_error`,
    defaultMessage: 'Please select a risk level',
  },
  highRisk: {
    id: `${baseId}.high_risk`,
    defaultMessage: 'High risk',
  },
  mediumRisk: {
    id: `${baseId}.medium_risk`,
    defaultMessage: 'Medium risk',
  },
  lowRisk: {
    id: `${baseId}.low_risk`,
    defaultMessage: 'Low risk',
  },
  back: {
    id: `${baseId}.back`,
    defaultMessage: 'Back to assessment',
  },
  submit: {
    id: `${baseId}.submit`,
    defaultMessage: 'Submit assessment',
  },
})
