import { defineMessages } from 'react-intl'

const baseId = 'experiments'

export default defineMessages({
  heading: {
    id: `${baseId}.heading`,
    defaultMessage: 'Experiments to opt in to',
  },
  new_member_view: {
    id: `${baseId}.new_member_view`,
    defaultMessage: 'New member view',
  },
  member_timeline_repeat_prescriptions: {
    id: `${baseId}.member_timeline_repeat_prescriptions`,
    defaultMessage: 'Add Repeat Prescriptions to Member Timeline',
  },
  mop_responsive_timeline: {
    id: `${baseId}.mop_responsive_timeline`,
    defaultMessage: 'Use responsive timeline',
  },
  mop_chat_history: {
    id: `${baseId}.mop_chat_history`,
    defaultMessage: 'Use chat history',
  },
  account_service_chat_history_enabled: {
    id: `${baseId}.account_service_chat_history_enabled`,
    defaultMessage: 'Enable account services',
  },
  member_alerts_enabled: {
    id: `${baseId}.member_alerts_enabled`,
    defaultMessage: 'Member Alerts',
  },
  // below are messages from product config experiments
  consultation_summaries_enabled: {
    id: `${baseId}.consultation_summaries_enabled`,
    defaultMessage: 'Use Consultation Summaries',
  },
  include_workflows_enabled: {
    id: `${baseId}.include_workflows_enabled`,
    defaultMessage: 'Add Workflows in Timeline',
  },
  member_relations_enabled: {
    id: `${baseId}.member_relations_enabled`,
    defaultMessage: 'Use Member Relations',
  },
  new_member_view_enabled: {
    id: `${baseId}.new_member_view_enabled`,
    defaultMessage: 'Use the New Member View',
  },
  platform_gateway_search_enabled: {
    id: `${baseId}.platform_gateway_search_enabled`,
    defaultMessage: 'Use Platform Gateway Search',
  },
  member_profile_toggle_enabled: {
    id: `${baseId}.member_profile_toggle_enabled`,
    defaultMessage: 'Use Old Member Details Page',
  },
})
