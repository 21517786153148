import { gql } from '@apollo/client'

export const FETCH_PRESCRIPTIONS = gql`
  query AP_PrescriptionsCount($statuses: [String], $patientId: ID) {
    prescriptions(statuses: $statuses, patientId: $patientId) {
      totalItems
    }
  }
`

export const ISSUE_USER_ID_TOKEN = gql`
  mutation IssueIdToken($patientId: ID!, $audience: Url!) {
    issuePatientIdToken(patientId: $patientId, audience: $audience) {
      ... on IdToken {
        token
      }
      ... on InvalidAudienceError {
        errorMessage
        invalidAudience
      }
    }
  }
`
