import React from 'react'

import styles from './styles.module.scss'

const PaginationSize = ({ totalItems, spinner, value, onChange }) => (
  <span className={styles.pageSize}>
    <label htmlFor="pageSize">Display</label>
    <select
      id="pageSize"
      onBlur={(event) => onChange(parseInt(event.target.value))}
      onChange={(event) => onChange(parseInt(event.target.value))}
      value={value}
    >
      <option value="10"> 10</option>
      <option value="20"> 20</option>
      <option value="50"> 50</option>
      <option value="100">100</option>
    </select>
    <span>
      of <strong>{totalItems}</strong> prescriptions
    </span>
    {spinner}
  </span>
)

export default PaginationSize
