import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import InfiniteScroll from 'react-infinite-scroller'
import { ApolloError } from '@apollo/client'
import { Spinner } from '@babylon/core-ui'
import { ErrorBlock } from '..'
import messages from './InfiniteScroller.messages'
import styles from './InfiniteScroller.module.scss'

export interface InfiniteScrollerProps {
  scrollParentRef: HTMLElement | null
  children: ReactNode
  loadMore: () => void
  hasMore?: boolean
  hasResults?: boolean
  error?: ApolloError
}

export default function InfiniteScroller({
  scrollParentRef,
  children,
  loadMore,
  hasMore,
  hasResults,
  error,
}: InfiniteScrollerProps) {
  if (error) {
    return (
      <div className={styles.InfiniteScroller__error}>
        <ErrorBlock>{error.graphQLErrors.map((err) => err.message)}</ErrorBlock>
      </div>
    )
  }

  return (
    <InfiniteScroll
      pageStart={0}
      initialLoad={false}
      loader={
        <Spinner
          key={0}
          className={styles.InfiniteScroller__spinner}
          size="medium"
        />
      }
      loadMore={loadMore}
      hasMore={hasMore}
      useWindow={!scrollParentRef}
      threshold={100}
      getScrollParent={() => scrollParentRef || null}
    >
      {hasResults ? (
        children
      ) : (
        <p>
          <FormattedMessage {...messages.empty} />
        </p>
      )}
    </InfiniteScroll>
  )
}
