/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '@babylon/graphql-middleware-types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type GetProfessionsQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetProfessionsQuery = {
  professions: Array<Pick<Types.Profession, 'id' | 'key' | 'name'>>
}

export const GetProfessionsDocument = gql`
  query GetProfessions {
    professions {
      id
      key
      name
    }
  }
`
export function useGetProfessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProfessionsQuery,
    GetProfessionsQueryVariables
  >
) {
  return Apollo.useQuery<GetProfessionsQuery, GetProfessionsQueryVariables>(
    GetProfessionsDocument,
    baseOptions
  )
}
export function useGetProfessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfessionsQuery,
    GetProfessionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetProfessionsQuery, GetProfessionsQueryVariables>(
    GetProfessionsDocument,
    baseOptions
  )
}
export type GetProfessionsQueryHookResult = ReturnType<
  typeof useGetProfessionsQuery
>
export type GetProfessionsLazyQueryHookResult = ReturnType<
  typeof useGetProfessionsLazyQuery
>
export type GetProfessionsQueryResult = Apollo.QueryResult<
  GetProfessionsQuery,
  GetProfessionsQueryVariables
>
