import React from 'react'
import styles from './styles.module.css'

const SendSVG = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.sendSVG}
  >
    <path d="M18.3717 9.10947L1.44914 0.570489C0.620778 0.152422 -0.279474 0.994864 0.0821942 1.85001L3.1955 9.20444C3.41123 9.71344 3.41053 10.288 3.19552 10.7963L0.082417 18.1506C-0.279227 19.0058 0.621047 19.8482 1.4501 19.4295L18.3724 10.891C19.102 10.5209 19.1027 9.47819 18.3717 9.10947Z" />
  </svg>
)

export default SendSVG
