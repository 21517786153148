import React from 'react'
import { Redirect } from 'react-router'
import { Link, useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import qs from 'qs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faCalendar,
  faFileDownload,
} from '@fortawesome/pro-regular-svg-icons'
import {
  Button,
  Page,
  Table,
  TableRow,
  TableCell,
  Text,
  Pagination,
  Spinner,
  Divider,
} from '@babylon/core-ui'
import { envFlag } from '@babylon/babylon-env'
import { format, parseISO } from 'date-fns'
import { useApolloClient } from '@apollo/client'
import { useBabylonUser } from '@babylon/babylon-user'
import TimezoneDate from '../Utils/TimezoneDate'
import {
  validPageNumber,
  handlePrevClick,
  handleNextClick,
  QueryString,
} from '../Utils/PaginationUtils'
import styles from './AvailabilitySyncShiftsPage.module.css'
import { useGetHumanitySyncShiftItemsQuery } from './GetHumanitySyncShifts.hooks'
import { AvailabilitySyncShiftFilters } from '../../components/AvailabilitySyncShiftFilters'
import type { AvailabilitySyncShiftFilterValues } from '../../components/AvailabilitySyncShiftFilters'
import {
  GetCliniciansDocument,
  GetCliniciansQuery,
  GetCliniciansQueryVariables,
} from './GetClinicians.hooks'
import {
  isValidAsyncDropdownValue,
  parseAsyncDropdownValue,
} from '../../utils/filters'
import { buildOldClinicianCalendarPageUrl } from '../../utils/page'
import { PaginationTotalCount } from '../../components/PaginationTotalCount'
import { useRequestSyncShiftsReportMutation } from '../../hooks/mutations/request-sync-shift-report'

const PER_PAGE = 20
const THIRTY_SECONDS = 1000 * 60

interface MatchParams {
  syncId: string
}

const AvailabilitySyncShiftsPage = () => {
  const apolloClient = useApolloClient()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch<MatchParams>()
  const user = useBabylonUser()

  const { syncId } = match.params

  const [
    requestSyncShiftsReport,
    {
      loading: isRequestSyncShiftsReportLoading,
      error: requestSyncShiftsReportError,
    },
  ] = useRequestSyncShiftsReportMutation({
    onCompleted: (data) => {
      const { reportDownloadUrl } = data.requestSyncShiftsReport

      const link = document.createElement('a')

      link.setAttribute('href', reportDownloadUrl)
      link.setAttribute('download', 'download')
      link.click()
    },
    onError: () => {
      console.error('Something went wrong. Please try again.')
    },
    variables: {
      input: {
        syncId,
      },
    },
  })

  const areAvailabilitySyncShiftFiltersEnabled = envFlag(
    'ENABLE_AVAILABILITY_SYNC_SHIFT_FILTERS'
  )

  const areAvailabilitySyncShiftDownloadsEnabled = envFlag(
    'ENABLE_AVAILABILITY_SYNC_SHIFT_DOWNLOAD'
  )

  const querystring: QueryString = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 4,
  })
  const page = validPageNumber(querystring?.page)
    ? parseInt(querystring?.page, 10)
    : 1

  const { loading, error, data, refetch } = useGetHumanitySyncShiftItemsQuery({
    variables: {
      id: syncId,
      limit: PER_PAGE,
      offset: page * PER_PAGE - PER_PAGE,
      timezone: areAvailabilitySyncShiftFiltersEnabled
        ? user.timezone
        : undefined,
      filters: areAvailabilitySyncShiftFiltersEnabled
        ? {
            consultantId: querystring.consultantId,
            consultantName: parseAsyncDropdownValue(querystring.consultantName),
            shiftStartDate: querystring.shiftStartDate,
            shiftEndDate: querystring.shiftEndDate,
            error: querystring.error,
            warning: querystring.warning,
          }
        : undefined,
    },
    pollInterval: THIRTY_SECONDS,
  })

  const handleConsultantSearch = async (name: string) => {
    const { data: consultantData } = await apolloClient.query<
      GetCliniciansQuery,
      GetCliniciansQueryVariables
    >({
      query: GetCliniciansDocument,
      variables: {
        name,
      },
    })

    return (
      consultantData?.getEligibleConsultants?.map((consultant) => ({
        value: `${consultant?.id};${consultant?.name}`,
        label: consultant?.name,
      })) ?? []
    )
  }

  const total = data?.humanitySyncShiftItems.total || 0
  const totalPages = data ? Math.ceil(total / PER_PAGE) : 1

  const handleRefetch = () => {
    refetch().catch((err) => console.log(err))
  }

  const handlePageClick = (selectedPage: number) => {
    if (!validPageNumber(selectedPage)) {
      return
    }

    history.push({
      search: qs.stringify({ ...querystring, page: selectedPage }),
    })
  }

  const handleFilterFormSubmit = (
    values: AvailabilitySyncShiftFilterValues
  ) => {
    const {
      consultantId,
      consultantName,
      shiftStartDate,
      shiftEndDate,
      error,
      warning,
    } = values

    history.push({
      search: qs.stringify({
        ...querystring,
        page: undefined,
        consultantId,
        consultantName,
        shiftStartDate: shiftStartDate
          ? format(shiftStartDate, 'yyyy-MM-dd')
          : undefined,
        shiftEndDate: shiftEndDate
          ? format(shiftEndDate, 'yyyy-MM-dd')
          : undefined,
        error,
        warning,
      }),
    })
  }

  const handleDownloadButtonClick = async () => {
    await requestSyncShiftsReport()
  }

  if (!syncId) {
    return <Redirect to="/admin/availability_sync" />
  }

  const { shifts } = data?.humanitySyncShiftItems || {}

  const initialFilterValues = {
    consultantId: querystring.consultantId,
    consultantName: isValidAsyncDropdownValue(querystring.consultantName)
      ? querystring.consultantName
      : undefined,
    shiftStartDate: querystring.shiftStartDate
      ? parseISO(querystring.shiftStartDate)
      : undefined,
    shiftEndDate: querystring.shiftEndDate
      ? parseISO(querystring.shiftEndDate)
      : undefined,
    error: querystring.error,
    warning: querystring.warning,
  }

  return (
    <Page title="Availability Sync Shifts">
      <Link to="/admin/availability_sync">&laquo; Back to syncs</Link>

      <div className={styles.ShiftsListing}>
        {areAvailabilitySyncShiftFiltersEnabled && (
          <>
            <div className={styles.Filters}>
              <AvailabilitySyncShiftFilters
                initialValues={initialFilterValues}
                onSubmit={handleFilterFormSubmit}
                onConsultantSearch={handleConsultantSearch}
              />
            </div>

            <Divider />
          </>
        )}

        <div className={styles.pagination}>
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPrevClick={() => handlePrevClick(page, history, querystring)}
            onNextClick={() =>
              handleNextClick(page, totalPages, history, querystring)
            }
            onPageClick={handlePageClick}
          />

          <div>
            <PaginationTotalCount totalCount={total} />
          </div>

          {areAvailabilitySyncShiftDownloadsEnabled && (
            <>
              {!loading && Boolean(shifts?.length) ? (
                <div className={styles.download}>
                  {requestSyncShiftsReportError && (
                    <span className={styles.downloadError}>
                      Something went wrong. Please try again.
                    </span>
                  )}

                  <Button
                    intent="secondary"
                    onClick={handleDownloadButtonClick}
                    loading={isRequestSyncShiftsReportLoading}
                  >
                    <FontAwesomeIcon
                      icon={faFileDownload}
                      className={styles.downloadIcon}
                    />
                    Download CSV
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </div>

        <Table
          fullWidth
          striped
          headers={[
            <Text bold>Shift ID</Text>,
            <Text bold>Consultant ID</Text>,
            <Text bold>Consultant name</Text>,
            <Text bold>Shift pattern</Text>,
            <Text bold>Shift start</Text>,
            <Text bold>Shift end</Text>,
            <Text bold>Errors</Text>,
            <Text bold>Warnings</Text>,
            <></>,
          ]}
        >
          {loading && (
            <TableRow>
              <td colSpan={10} className={styles.SingleCol}>
                <Spinner size="large" color="gray" testid="spinner" centered />
              </td>
            </TableRow>
          )}

          {error && (
            <TableRow>
              <td colSpan={10} className={styles.SingleCol}>
                There was an error fetching the list, please{' '}
                <Button
                  loading={loading}
                  disabled={loading}
                  type="button"
                  inline
                  onClick={() => handleRefetch()}
                >
                  try reloading
                </Button>
              </td>
            </TableRow>
          )}

          {!loading && !shifts?.length && (
            <TableRow>
              <td colSpan={10} className={styles.SingleCol}>
                <Text size="large" align="center" bold>
                  <FontAwesomeIcon icon={faTimes} /> No shifts were found
                </Text>
              </td>
            </TableRow>
          )}

          {!loading &&
            shifts &&
            shifts.length > 0 &&
            shifts.map((shift) => (
              <TableRow key={`row_${shift.shift_id}`}>
                <TableCell>
                  <Text data-testid="shift-id">{shift.shift_id}</Text>
                </TableCell>
                <TableCell>
                  <div className={styles.consultantIdCell}>
                    <Text data-testid="shift-consultant-id">
                      {shift.consultant_id}
                    </Text>

                    {shift.consultant_id && (
                      <Link
                        to={buildOldClinicianCalendarPageUrl(
                          shift.consultant_id
                        )}
                        className={styles.consultantAvailabilityLink}
                      >
                        <FontAwesomeIcon
                          icon={faCalendar}
                          title="View consultant availability"
                        />
                      </Link>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <Text data-testid="shift-consultant-name">
                    {shift.consultant_name}
                  </Text>
                </TableCell>
                <TableCell>
                  {!shift.shift_pattern && (
                    <Text
                      data-testid="shift-pattern"
                      className={styles.ColumnMissingDataText}
                    >
                      n/a
                    </Text>
                  )}
                  {shift.shift_pattern !== null && (
                    <Text data-testid="shift-pattern">
                      {shift.shift_pattern}
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  {!shift.shift_start && (
                    <Text
                      data-testid="shift-start"
                      className={styles.ColumnMissingDataText}
                    >
                      n/a
                    </Text>
                  )}
                  {shift.shift_start !== null && (
                    <Text data-testid="shift-start">
                      <TimezoneDate
                        date={shift.shift_start}
                        format="MMM do, yyyy kk:mm"
                      />
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  {!shift.shift_end && (
                    <Text
                      data-testid="shift-end"
                      className={styles.ColumnMissingDataText}
                    >
                      n/a
                    </Text>
                  )}
                  {shift.shift_end !== null && (
                    <Text data-testid="shift-end">
                      <TimezoneDate
                        date={shift.shift_end}
                        format="MMM do, yyyy kk:mm"
                      />
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  {!shift.errors?.length && (
                    <Text
                      data-testid="shift-errors"
                      className={styles.ColumnMissingDataText}
                    >
                      none
                    </Text>
                  )}
                  {shift.errors && shift.errors.length > 0 && (
                    <Text data-testid="shift-errors">
                      {shift.errors.join(', ')}
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  {!shift.warnings?.length && (
                    <Text
                      data-testid="shift-warnings"
                      className={styles.ColumnMissingDataText}
                    >
                      none
                    </Text>
                  )}
                  {shift.warnings && shift.warnings.length > 0 && (
                    <Text data-testid="shift-warnings">
                      {shift.warnings.join(', ')}
                    </Text>
                  )}
                </TableCell>
                <TableCell />
              </TableRow>
            ))}
        </Table>
      </div>
    </Page>
  )
}

export default AvailabilitySyncShiftsPage
