import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Button, Cell, Grid, Heading } from '@babylon/core-ui'
import { useProductConfig } from '@babylon/product-config'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { useQuery } from '@apollo/client'
import { MemberOpsModuleName, ViewMoreToggler } from '../../../../..'

import { PDSStatuses } from '../../../../../util/pdsStatus'
import AccountTags, {
  IdentityVerificationStatus,
} from '../../../../AccountTags'
import {
  Address,
  Email,
  GenderAge,
  IDs,
  PhoneNumber,
} from '../../../../CopiableMemberInformation'
import PatientName from '../../../../PatientName'
import SendRegistrationInviteModal from './SendRegistrationInviteModal'
import { SetSnackbarMessage } from '../../../types'
import MoreDetails from '../MoreDetails'
import ProfileHeaderKebabMenu from './ProfileHeaderKebabMenu'
import messages from './ProfileHeader.messages'
import styles from './ProfileHeader.module.scss'
import { LegalConsentsDecisions, MFA_GQL } from './queries'
import { EnrollmentTypes, getMfaStatus } from './utils/mfaDataSelector'

type LegalConsentsDecisionsFeatureDisabledState = {
  state: 'featureDisabled'
}

type LegalConsentsDecisionsLoadingState = {
  state: 'loading'
}

type LegalConsentsDecisionsLoadedState = LegalConsentsDecisions & {
  state: 'loaded'
}

export type LegalConsentsDecisionsState =
  | LegalConsentsDecisionsLoadingState
  | LegalConsentsDecisionsLoadedState
  | LegalConsentsDecisionsFeatureDisabledState

export interface MemberAddress {
  firstLine?: string
  secondLine?: string
  thirdLine?: string
  postCode?: string
  stateCode?: string
  city?: string
}

export interface MemberProfileProps {
  member: Member
  memberAddress: MemberAddress
  legalConsentsDecisionsState: LegalConsentsDecisionsState
  setSnackbarMessage: SetSnackbarMessage
  onSwitchToOldView: () => void
  isStubbedProfile?: boolean
  isPendingRegistration?: boolean
}

const MemberProfile = ({
  onSwitchToOldView,
  setSnackbarMessage,
  member,
  memberAddress,
  legalConsentsDecisionsState,
  isStubbedProfile,
  isPendingRegistration,
}: MemberProfileProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profilesHeader,
  })
  const [expanded, setExpanded] = useState<boolean>(false)
  const [isRegistrationModalVisible, setIsRegistrationModalVisible] = useState(
    false
  )
  const history = useHistory()
  const { getBlob } = useProductConfig()
  const {
    memberProfileShowMoreEnabled,
    memberProfileToggleEnabled,
    smsMfaMaintenanceEnabled,
    memberProfileBupaTagEnabled,
    preregistrationManagementEnabled,
  } = getBlob('memberOperations')

  const {
    account_owner_id,
    account_owner,
    accounts_count,
    date_of_birth,
    demographics,
    email,
    family_accounts,
    family_accounts_owner,
    first_name,
    full_phone_number,
    gender,
    healthcare_identifier,
    id,
    identity_check,
    last_name,
    minor,
    profile_locked,
    profile_locks,
    region,
    uuid,
  } = member

  const {
    firstLine,
    secondLine,
    thirdLine,
    postCode,
    stateCode,
    city,
  } = memberAddress

  const { data } = useQuery(MFA_GQL, {
    context: { clientName: 'platform-gateway' },
    variables: {
      id: uuid,
      first: 1,
    },
    skip: !smsMfaMaintenanceEnabled || isStubbedProfile,
  })

  const publicId = healthcare_identifier?.public_identifier || ''
  const privateId = healthcare_identifier?.private_identifier || ''
  const unconfirmedPublicId =
    healthcare_identifier?.unconfirmed_public_identifier || ''

  const moreDetailsHandler = () => {
    const newExpanded = !expanded
    setExpanded(newExpanded)
    trackClick({
      elementName: `${
        newExpanded ? 'show-all-details-btn' : 'hide-all-details-btn'
      }`,
      elementType: TrackingElementType.button,
    })
  }

  const copyToClipboardHandler = (message: string, elemName: string) => {
    trackClick({
      elementName: `copy-to-clipboard-${elemName}-btn`,
      elementType: TrackingElementType.button,
    })

    return setSnackbarMessage(message)
  }

  const pdsStatus =
    demographics && !!demographics.status
      ? (demographics.status as PDSStatuses)
      : undefined

  const isMFAActive = data?.user?.isMFAActive
  const mfaEnrollments = data?.user?.mfaEnrollments
  const isSmsMfaActive = getMfaStatus({
    isMFAActive,
    mfaEnrollments,
    enrollmentType: EnrollmentTypes.sms,
  })

  const hasFamilyAccounts = !!(
    (family_accounts && family_accounts.length) ||
    account_owner_id ||
    (accounts_count && accounts_count > 0)
  )

  const showMoreDetailsEnabled =
    memberProfileShowMoreEnabled && !isStubbedProfile
  const sendRegistrationEmailEnabled =
    (isStubbedProfile || isPendingRegistration) &&
    !minor &&
    preregistrationManagementEnabled

  const shouldShowConsentRequiredTag = (
    decisionName: keyof LegalConsentsDecisions,
    decisionsState: LegalConsentsDecisionsState
  ) =>
    decisionsState.state === 'loaded' &&
    (decisionsState[decisionName] == null ||
      decisionsState[decisionName]?.expired ||
      !decisionsState[decisionName]?.accepted)

  return (
    <>
      <div className={styles.profileDetails}>
        <div className={styles.profileAccount}>
          <div className={styles.profileHero}>
            <div>
              <AccountTags
                familyAccount={hasFamilyAccounts}
                familyAccountOwner={family_accounts_owner}
                idVerificationStatus={
                  identity_check &&
                  (identity_check.status as IdentityVerificationStatus)
                }
                minor={minor}
                pdsStatus={pdsStatus}
                isSmsMfaActive={
                  smsMfaMaintenanceEnabled ? isSmsMfaActive : undefined
                }
                showInformedConsentDecisionRequired={shouldShowConsentRequiredTag(
                  'informedConsent',
                  legalConsentsDecisionsState
                )}
                showMedicalHistoryAuthConsentDecisionRequired={shouldShowConsentRequiredTag(
                  'medicationHistory',
                  legalConsentsDecisionsState
                )}
                isStubbedProfile={isStubbedProfile}
                isPendingRegistration={isPendingRegistration}
                profileLocks={profile_locks}
                isProfileLocked={memberProfileBupaTagEnabled && profile_locked}
              />

              <Heading level="h1" data-testid="member-profile-name">
                <PatientName
                  firstName={first_name}
                  lastName={last_name}
                  id={id}
                  tagName="span"
                />
              </Heading>

              {account_owner && (
                <div className={styles.ownerLink}>
                  <FormattedMessage {...messages.family_account_owner} />
                  :&nbsp;
                  <PatientName
                    firstName={account_owner.first_name}
                    lastName={account_owner.last_name}
                    id={account_owner_id}
                    tagName="span"
                    onClick={(e) => {
                      trackClick({
                        elementName: 'account-owner-link',
                        elementType: TrackingElementType.link,
                        href: e.currentTarget.href,
                      })
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles.actions}>
              {memberProfileToggleEnabled && (
                <button
                  className={styles.useOldPortal}
                  data-testid="use-old-portal-button"
                  type="button"
                  onClick={() => {
                    onSwitchToOldView()
                    trackClick({
                      elementName: 'use-old-portal-btn',
                      elementType: TrackingElementType.button,
                    })
                  }}
                >
                  <FormattedMessage {...messages.switch_old_view} />
                </button>
              )}
              <div className={styles.actions__menu}>
                {sendRegistrationEmailEnabled && (
                  <>
                    <Button
                      fill
                      intent="secondary"
                      inline
                      className={styles.actions__button}
                      onClick={() => {
                        trackClick({
                          elementName:
                            'member-profile-send-registration-email-button',
                          elementType: TrackingElementType.button,
                        })
                        setIsRegistrationModalVisible(true)
                      }}
                      testId="member-profile-send-registration-email-button"
                    >
                      <FormattedMessage {...messages.send_registration_email} />
                    </Button>
                    <SendRegistrationInviteModal
                      patientUuid={uuid}
                      firstName={first_name}
                      lastName={last_name}
                      email={email}
                      isModalVisible={isRegistrationModalVisible}
                      closeModal={() => setIsRegistrationModalVisible(false)}
                      setSnackbarMessage={setSnackbarMessage}
                    />
                  </>
                )}
                {!isStubbedProfile && (
                  <>
                    <Button
                      fill
                      intent="secondary"
                      inline
                      className={styles.actions__button}
                      onClick={() => {
                        history.push(`/admin/patients/${id}/book-appointment`)
                        trackClick({
                          elementName: 'book-appointment-btn',
                          elementType: TrackingElementType.button,
                        })
                      }}
                      testId="member-profile-book-appointment-button"
                    >
                      <FormattedMessage {...messages.book_appointment} />
                    </Button>
                    <ProfileHeaderKebabMenu
                      member={member}
                      setSnackbarMessage={setSnackbarMessage}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Grid className={styles.profileContent} columns={12} columnGap={16}>
          <Cell width={2}>
            <GenderAge
              dateOfBirth={date_of_birth}
              gender={gender}
              onDateOfBirthCopy={(message) =>
                copyToClipboardHandler(message, 'gender-age')
              }
            />
          </Cell>
          <Cell width={4}>
            <Address
              line1={firstLine}
              line2={secondLine}
              line3={thirdLine}
              city={city}
              county={stateCode}
              postcode={postCode}
              region={region ? region.name : undefined}
              onAddressCopy={(message) =>
                copyToClipboardHandler(message, 'address')
              }
            />
          </Cell>
          <Cell width={3}>
            <div>
              <PhoneNumber
                phoneNumber={full_phone_number}
                onPhoneNumberCopy={(message) =>
                  copyToClipboardHandler(message, 'phone-number')
                }
              />
            </div>
            <div>
              <Email
                email={email}
                onEmailCopy={(message) =>
                  copyToClipboardHandler(message, 'email-address')
                }
              />
            </div>
          </Cell>
          <Cell width={3}>
            <IDs
              babylonId={id}
              privateId={privateId}
              publicId={publicId}
              unconfirmedPublicId={unconfirmedPublicId}
              babylonUserUuid={uuid}
              onIDCopy={(message, type) =>
                copyToClipboardHandler(message, `${type}-id`)
              }
            />
          </Cell>
        </Grid>
        {showMoreDetailsEnabled && (
          <div>
            <ViewMoreToggler
              mode="dark"
              testId="member-profile-header-more-button"
              open={expanded}
              onToggle={moreDetailsHandler}
            >
              <FormattedMessage
                {...(expanded
                  ? messages.hide_all_details
                  : messages.show_all_details)}
              />
            </ViewMoreToggler>
          </div>
        )}
      </div>
      {showMoreDetailsEnabled && expanded && (
        <MoreDetails
          memberId={member.id}
          setSnackbarMessage={setSnackbarMessage}
          informedConsentDecisionDate={
            legalConsentsDecisionsState.state === 'loaded'
              ? legalConsentsDecisionsState.informedConsent?.date
              : undefined
          }
          medicalHistoryAuthorizationDecisionDate={
            legalConsentsDecisionsState.state === 'loaded'
              ? legalConsentsDecisionsState.medicationHistory?.date
              : undefined
          }
        />
      )}
    </>
  )
}

export default MemberProfile
