import React from 'react'

import { Text, Tag } from '@babylon/core-ui'

import { DurationOptions } from '../../components'
import EntryField from '../EntryField'

interface ProfessionDetailProps {
  alternativeSlotDuration: number[]
  defaultSlotDuration: number
  medicalIdentifierLabel: string | null
}

const ProfessionDetail = ({
  alternativeSlotDuration = [],
  defaultSlotDuration,
  medicalIdentifierLabel,
}: ProfessionDetailProps) => {
  const slotDuration = [...alternativeSlotDuration, defaultSlotDuration]

  return (
    <>
      <EntryField label="Duration options">
        <DurationOptions>
          {(value: number) => (
            <>
              <Text
                style={{
                  textDecoration: slotDuration.includes(value)
                    ? ''
                    : 'line-through',
                }}
              >{`${value} minutes`}</Text>
              {defaultSlotDuration === value && <Tag>Default</Tag>}
            </>
          )}
        </DurationOptions>
      </EntryField>
      <EntryField label="Medical Identifier">
        <Text tag="p">{medicalIdentifierLabel}</Text>
      </EntryField>
    </>
  )
}

export default ProfessionDetail
