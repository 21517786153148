import React from 'react'
import { useField } from 'formik'
import { Textarea } from '@babylon/medkit'
import ErrorsMessages from '@babylon/medkit/src/Forms/ErrorMessages'

interface AppointmentTypeNotesProps {
  className?: string
  helpText?: string
  label: string
  labelClassName?: string
}

export default function AppointmentTypeNotes({
  className,
  label,
  helpText,
  labelClassName,
}: AppointmentTypeNotesProps) {
  const [{ value }, meta, { setValue }] = useField('notes_for_member')

  const error = meta.touched && meta.error

  return (
    <Textarea
      name="notes_for_member"
      id="notes_for_member"
      label={label}
      helpText={helpText}
      onChange={(event) => {
        const notes = event.currentTarget.value
        setValue(notes)
      }}
      className={className}
      value={value}
      ErrorMessages={() =>
        error ? <ErrorsMessages formErrors={[error]} /> : null
      }
      error={!!error}
      labelProps={{ className: labelClassName }}
    />
  )
}
