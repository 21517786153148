import React, { useReducer, FormEvent } from 'react'
import { Close } from '@babylon/icons'
import {
  Button,
  Cell,
  Grid,
  Modal,
  DropdownSelect,
  CheckboxGroup,
  Checkbox,
  DropdownSelectOption,
  Select,
  SelectOptionType,
} from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackBar, MemberOpsModuleName } from '../../../../../../..'
import {
  CONSUMER_NETWORKS_QUERY,
  CREATE_APPOINTMENT_CREDIT_MUTATION,
  PROFESSIONS_QUERY,
  APPOINTMENT_CREDIT_REASONS,
} from './queries'
import messages from './AppointmentCredits.messages'
import styles from './AddAppointmentCreditModal.module.scss'

const FORM_RESET = 'form-reset'
const SET_CONSUMER_NETWORK = 'set-consumer-network'
const SET_PROFESSIONS = 'set-professions'
const SET_REASON = 'set-reason'
const SET_FORM_ERROR = 'set-form-error'

type FormState = {
  consumerNetwork: DropdownSelectOption
  professions: string[]
  reason: SelectOptionType
  isSubmitEnabled: boolean
  formError: Error | null
}

const initialState = {
  consumerNetwork: { key: '', value: '' },
  professions: [],
  reason: { value: '', label: '' },
  isSubmitEnabled: false,
  formError: null,
} as FormState

type ReducerAction = {
  type: string
  consumerNetwork?: DropdownSelectOption
  professions?: string[]
  reason?: SelectOptionType
  formError?: Error
}

/**
 * Utility function checks the form state to determine if submission is enabled
 */
function getIsSubmitEnabled({
  consumerNetwork,
  professions,
  reason,
}: FormState) {
  const hasNetwork = consumerNetwork.key !== '' && consumerNetwork.value !== ''
  const hasProfessions = professions.length > 0
  const hasReason = reason.value !== '' && reason.label !== ''

  return hasNetwork && hasProfessions && hasReason
}

function reducer(state: FormState, action: ReducerAction): FormState {
  let newState: FormState

  switch (action.type) {
    case SET_CONSUMER_NETWORK:
      newState = { ...state, consumerNetwork: action.consumerNetwork! }

      break

    case SET_PROFESSIONS:
      newState = { ...state, professions: action.professions! }

      break

    case SET_REASON:
      newState = { ...state, reason: action.reason! }

      break

    case SET_FORM_ERROR:
      newState = { ...state, formError: action.formError! }

      break

    case FORM_RESET:
      newState = initialState

      break

    default:
      newState = state

      break
  }

  const isSubmitEnabled = getIsSubmitEnabled(newState)

  return { ...newState, isSubmitEnabled }
}

function useFetchReasons() {
  const { data } = useQuery(APPOINTMENT_CREDIT_REASONS, {})

  const reasons = data?.appointmentCreditReasons.map(
    (appointmentCreditReason) => {
      const { id, reason } = appointmentCreditReason

      return { value: id, label: reason }
    }
  )

  return reasons as SelectOptionType[]
}

function useFetchProfessions() {
  const { data } = useQuery(PROFESSIONS_QUERY, {})

  const professionLookup: Record<string, string> = {}
  const professionCheckboxes: unknown[] = []

  if (data) {
    for (const profession of data.professions) {
      const { id, name } = profession

      professionLookup[id] = name

      professionCheckboxes.push(
        <Checkbox key={id} value={id}>
          {name}
        </Checkbox>
      )
    }
  }

  return { professionLookup, professionCheckboxes }
}

function useFetchConsumerNetworks(patientId: string) {
  const { data } = useQuery(CONSUMER_NETWORKS_QUERY, {
    variables: { patientId },
  })

  const consumerNetworks = data?.patientConsumerNetworks.map(
    ({ consumer_network }) => {
      const { id, name } = consumer_network

      return { key: id, value: name }
    }
  )

  return consumerNetworks as DropdownSelectOption[]
}

type Props = {
  patientId: string
  isOpened: boolean
  onClose: () => void
  onAddCreditSuccess: () => void
}

const AddAppointmentCreditModal = ({
  isOpened,
  onClose,
  onAddCreditSuccess,
  patientId,
}: Props) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()
  const { setSnackbarMessage } = useSnackBar()
  const [formState, dispatch] = useReducer(reducer, initialState)
  const [addAppointmentCredit] = useMutation(CREATE_APPOINTMENT_CREDIT_MUTATION)

  const consumerNetworks = useFetchConsumerNetworks(patientId)
  const { professionLookup, professionCheckboxes } = useFetchProfessions()
  const reasonOptions = useFetchReasons()

  const closeModal = () => {
    dispatch({ type: FORM_RESET })
    onClose()
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const { consumerNetwork, professions, reason } = formState
    const professionsPayload = professions.map((id) => professionLookup[id])

    // will track on click or form submit (keypress)
    trackClick({
      elementName: 'add-appointment-credit-btn',
      elementType: TrackingElementType.button,
    })

    try {
      await addAppointmentCredit({
        variables: {
          patientId,
          consumerNetworkId: consumerNetwork.key,
          professions: professionsPayload,
          reasonId: reason.value,
        },
      })

      trackClick({
        elementName: 'add-appointment-credit-button',
        elementType: TrackingElementType.button,
      })
      setSnackbarMessage(fm(messages.add_credit_success), null, 'success')
      onAddCreditSuccess()
      closeModal()
    } catch (e) {
      setSnackbarMessage(fm(messages.add_credit_error), null, 'error')
    }
  }

  return (
    <Modal
      label={fm(messages.add_credit_modal_heading)}
      open={isOpened}
      includeClose={false}
      onClose={closeModal}
    >
      <div className={styles.ModalContent}>
        <Grid columns={6} className={styles.ModalHeader}>
          <Cell width={4}>
            <h2>{fm(messages.add_credit_modal_heading)}</h2>
          </Cell>
          <Cell left={6}>
            <button
              type="button"
              onClick={closeModal}
              className={styles.AddAppointmentCreditModal__closeButton}
            >
              <Close />
              <span>Close</span>
            </button>
          </Cell>
        </Grid>
        <form
          className={styles.AddAppointmentCreditForm}
          onSubmit={handleSubmit}
        >
          <label className={styles.FieldLabel}>
            {fm(messages.add_credit_modal_consumer_network)}
          </label>
          <DropdownSelect
            data-testid="select-consumer-network"
            name="select-consumer-network"
            selectedOption={formState.consumerNetwork}
            options={consumerNetworks}
            onChange={(selected) =>
              dispatch({
                type: SET_CONSUMER_NETWORK,
                consumerNetwork: selected,
              })
            }
          />
          <label className={styles.FieldLabel}>
            {fm(messages.add_credit_modal_professions)}
          </label>
          <div data-testid="select-professions">
            <CheckboxGroup
              className={styles.AddAppointmentCreditForm__Professions}
              name="professions"
              value={formState.professions}
              onChange={(selected) =>
                dispatch({ type: SET_PROFESSIONS, professions: selected })
              }
            >
              {professionCheckboxes}
            </CheckboxGroup>
          </div>
          <label className={styles.FieldLabel} htmlFor="credit-reason">
            {fm(messages.add_credit_modal_reason)}
          </label>
          <Select
            options={reasonOptions}
            selectedOption={formState.reason}
            onChange={(selected) =>
              dispatch({ type: SET_REASON, reason: selected })
            }
            id="credit-reason"
            data-testid="select-credit-reason"
            maxMenuHeight={150}
          />

          <Button
            type="submit"
            data-testid="submit-add-credit"
            disabled={!formState.isSubmitEnabled}
            fill
            className={styles.CreateCreditButton}
          >
            {fm(messages.add_credit_modal_create_credit_button)}
          </Button>
        </form>
      </div>
    </Modal>
  )
}

export default AddAppointmentCreditModal
