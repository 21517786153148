import { gql } from '@apollo/client'
import {
  commonConsultationFields,
  commonPatientFields,
  commonRepeatTemplateFields,
} from './common'

export const PATIENT_QUERY = (fields = commonPatientFields) => gql`
  query AP_Patient($patientId: ID!) {
    patient(id: $patientId) {
      ${fields}
    }
  }
`

export const CONSULTATIONS = gql`
  query AP_Consultations(
    $searchCriteria: ConsultationSearchCriteria
    $paginationCriteria: PaginationCriteria
  ) {
    consultationsv2(
      searchCriteria: $searchCriteria
      paginationCriteria: $paginationCriteria
    ) {
      totalItems
      items {
        ${commonConsultationFields}
      }
    }
  }
`

export const PATIENT_CONSULTATIONS = gql`
  query AP_PatientConsultations(
    $patientUuid: ID!
    $dateRange: DateRange
    $status: ConsultationStatus
  ) {
    consultations: getPatientConsultations(
      patientUuid: $patientUuid
      dateRange: $dateRange
      status: $status
    ) {
      totalItems
      items {
        ${commonConsultationFields}
      }
    }
  }
`

export const REPEAT_TEMPLATES = gql`
  query AP_RepeatTemplates(
    $patientId: String
    $lastChangeDateMin: Date
    $lastChangeDateMax: Date
    $userStatesFilter: [UserStateFilter]
    $size: Int
  ) {
    repeatTemplates(
      patientId: $patientId
      lastChangeDateMin: $lastChangeDateMin
      lastChangeDateMax: $lastChangeDateMax
      userStates: [CHECKING_REORDER, FOLLOW_UP_NEEDED, READY_TO_COLLECT, REORDER_NOW, REORDER_SOON]
      userStatesFilter: $userStatesFilter
      size: $size
    ) {
      items {
        ${commonRepeatTemplateFields}
      }
    }
  }
`

export const PendingInvitesFragment = gql`
  fragment PendingInvitesFragment on AppointmentInvite {
    id
    consultation_type
    duration_minutes
    preferred_medium
    allowed_mediums
    notes_for_member
    preferred_profession_name
    service_type {
      id
      uuid
      member_instructions
      name
    }
    expired
  }
`

export const WORKFLOW_TASKS = gql`
  query AP_WorkflowTasks(
    $patientId: ID
    $searchCriteria: WorkflowSearchCriteria
  ) {
    workflowTasks(memberId: $patientId, searchCriteria: $searchCriteria) {
      id
      clinician {
        id
        name
        gender
      }
      consumerNetwork {
        name
      }
      due_date
      end_time
      name
      owner
      task_definition_key
      variables {
        name
        value
      }
      workflow {
        state
        workflow_definition_key
        workflowDefinition {
          key
          workflow_definition_name
        }
      }
    }
  }
`

export const PAGED_HISTORY = gql`
  query AP_PAGED_HISTORY(
    $patientId: ID!,
    $pagination: PaginationCriteria,
    $eventTypes: [TimelineEventType],
  ) {

    # Aliased for backwards compat
    pagedHistory: getTimelineEventsByPatient(
      patientId: $patientId
      eventTypes: $eventTypes,
      pagination: $pagination,
    ) {
      totalEvents
      hasMore
      events {
        ... on Consultation {
          ${commonConsultationFields}
          referrals {
            id
            referralPdf
            specialismName
            specialismCategoryId
          }
        }
        ... on WorkflowV2 {
          id
          consumerNetwork {
            name
          }
          end_time
          state
          variables {
            name
            value
          }
          workflow_definition_key
          workflowDefinition {
            workflow_definition_name
          }
        }
        ... on RepeatTemplate {
          ${commonRepeatTemplateFields}
        }
      }
    }
  }
`

export const PATIENT_TIMELINE = gql`
  query AP_Timeline($patientId: ID!, $includingDigital: Boolean) {
    patient(id: $patientId) {
      id
      pendingInvites(includingDigital: $includingDigital) {
        ...PendingInvitesFragment
      }
    }
  }
  ${PendingInvitesFragment}
`

export const APPOINTMENT_CALL_INFO = gql`
  query AP_AppointmentCallFlowSteps($appointmentId: ID!) {
    getCallFlowSteps(appointmentId: $appointmentId) {
      call_flow_steps {
        triggered_at
        duration_in_ms
      }
      missed_by
    }
  }
`
