/* AUTO-GENERATED, DO NOT EDIT THIS FILE */
/* eslint-disable */
import * as Types from '../types/generated/federated-graph'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type PractitionerQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']
  filter: Types.AppointmentFilter
  appointmentSort?: Types.Maybe<Array<Types.AppointmentSort>>
  first: Types.Scalars['NonNegativeInt']
  cursor?: Types.Maybe<Types.Scalars['Cursor']>
}>

export type PractitionerQuery = {
  practitioner?: Types.Maybe<
    Pick<Types.Practitioner, 'id'> & {
      names?: Types.Maybe<{
        edges: Array<{
          node?: Types.Maybe<
            Pick<Types.HealthgraphHumanName, 'text' | 'family'> & {
              given?: Types.Maybe<Pick<Types.StringConnection, 'nodes'>>
            }
          >
        }>
      }>
      appointments?: Types.Maybe<{
        pageInfo: Pick<Types.PageInfo, 'hasNextPage' | 'endCursor'>
        edges: Array<
          Pick<Types.AppointmentEdge, 'cursor'> & {
            node?: Types.Maybe<
              Pick<
                Types.Appointment,
                | 'id'
                | 'status'
                | 'start'
                | 'end'
                | 'requestedLanguage'
                | 'medium'
                | 'patientConsumerNetwork'
              > & {
                identifiers?: Types.Maybe<{
                  edges: Array<{
                    node?: Types.Maybe<
                      Pick<Types.HealthgraphIdentifier, 'system' | 'value'>
                    >
                  }>
                }>
                reasonCodes?: Types.Maybe<{
                  nodes: Array<
                    Types.Maybe<{
                      text?: Types.Maybe<Pick<Types.LocalizedString, 'text'>>
                    }>
                  >
                }>
                participants?: Types.Maybe<{
                  edges: Array<{
                    node?: Types.Maybe<{
                      actor?: Types.Maybe<
                        | { __typename: 'ExternalResource' }
                        | ({ __typename: 'NonFederatedResource' } & Pick<
                            Types.NonFederatedResource,
                            'id'
                          >)
                        | { __typename: 'None' }
                        | ({ __typename: 'Patient' } & Pick<
                            Types.Patient,
                            'id' | 'gender' | 'isMinor'
                          > & {
                              name?: Types.Maybe<
                                Pick<
                                  Types.HumanName,
                                  | 'text'
                                  | 'family'
                                  | 'given'
                                  | 'prefix'
                                  | 'suffix'
                                >
                              >
                              identifiers?: Types.Maybe<{
                                edges: Array<{
                                  node?: Types.Maybe<
                                    Pick<
                                      Types.HealthgraphIdentifier,
                                      'system' | 'value'
                                    >
                                  >
                                }>
                              }>
                            })
                        | ({ __typename: 'Practitioner' } & Pick<
                            Types.Practitioner,
                            'id'
                          >)
                      >
                      types?: Types.Maybe<{
                        edges: Array<{
                          node?: Types.Maybe<{
                            codings?: Types.Maybe<{
                              edges: Array<{
                                node?: Types.Maybe<
                                  Pick<Types.Coding, 'code' | 'system'> & {
                                    display?: Types.Maybe<
                                      Pick<Types.LocalizedString, 'text'>
                                    >
                                  }
                                >
                              }>
                            }>
                          }>
                        }>
                      }>
                    }>
                  }>
                }>
              }
            >
          }
        >
      }>
    }
  >
}

export const PractitionerDocument = gql`
  query Practitioner(
    $id: ID!
    $filter: AppointmentFilter!
    $appointmentSort: [AppointmentSort!]
    $first: NonNegativeInt!
    $cursor: Cursor
  ) {
    practitioner(id: $id) {
      ... on Practitioner {
        id
        names(first: 1) {
          edges {
            node {
              text
              family
              given(first: 1) {
                nodes
              }
            }
          }
        }
        appointments(
          first: $first
          _filter: $filter
          _sort: $appointmentSort
          after: $cursor
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            cursor
            node {
              id
              status
              start
              end
              requestedLanguage
              medium
              identifiers(first: 4) {
                edges {
                  node {
                    system
                    value
                  }
                }
              }
              reasonCodes(first: 4) {
                nodes {
                  text {
                    text
                  }
                }
              }
              patientConsumerNetwork
              participants(first: 5) {
                edges {
                  node {
                    actor {
                      __typename
                      ... on Resource {
                        id
                      }
                      ... on Practitioner {
                        id
                      }
                      ... on Patient {
                        id
                        gender
                        isMinor
                        name {
                          text
                          family
                          given
                          prefix
                          suffix
                        }
                        identifiers(first: 4) {
                          edges {
                            node {
                              system
                              value
                            }
                          }
                        }
                      }
                    }
                    types(first: 5) {
                      edges {
                        node {
                          codings(first: 5) {
                            edges {
                              node {
                                code
                                display {
                                  text
                                }
                                system
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export function usePractitionerQuery(
  baseOptions: Apollo.QueryHookOptions<
    PractitionerQuery,
    PractitionerQueryVariables
  >
) {
  return Apollo.useQuery<PractitionerQuery, PractitionerQueryVariables>(
    PractitionerDocument,
    baseOptions
  )
}
export function usePractitionerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PractitionerQuery,
    PractitionerQueryVariables
  >
) {
  return Apollo.useLazyQuery<PractitionerQuery, PractitionerQueryVariables>(
    PractitionerDocument,
    baseOptions
  )
}
export type PractitionerQueryHookResult = ReturnType<
  typeof usePractitionerQuery
>
export type PractitionerLazyQueryHookResult = ReturnType<
  typeof usePractitionerLazyQuery
>
export type PractitionerQueryResult = Apollo.QueryResult<
  PractitionerQuery,
  PractitionerQueryVariables
>
