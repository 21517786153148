import React from 'react'
import Select from 'react-select'
import cx from 'classnames'

import styles from './SelectWidget.module.scss'

const loadOptions = (actions, name) => (input) => {
  if (input === '') {
    return Promise.resolve([])
  }

  return actions
    .onLoadOptions(name, input)
    .then((data) => ({
      options: data,
    }))
    .catch(() => ({
      options: [
        {
          $ERROR: true,
          disabled: true,
        },
      ],
    }))
}

const SelectWidget = ({
  className,
  name,
  label,
  value,
  actions,
  options,
  valueKey,
  labelKey,
  ...rest
}) => {
  const SelectComponent = options ? Select : Select.Async

  return (
    <div
      className={cx(
        styles.selectWidget,
        'babylon-component-library__asFormControl__control',
        className
      )}
    >
      <label className="label">{label}</label>
      <SelectComponent
        simpleValue
        {...rest}
        value={value}
        onChange={(value) => actions.onChange(name, value)}
        valueKey={valueKey}
        labelKey={labelKey}
        options={options}
        filterOption={options ? undefined : () => true}
        optionRenderer={(option) =>
          option.$ERROR ? (
            <span className={styles.error}>
              Error loading the options, please try again later
            </span>
          ) : (
            option[labelKey || 'label']
          )
        }
        loadOptions={options ? null : loadOptions(actions, name)}
      />
    </div>
  )
}

export default SelectWidget
