import React from 'react'

import { Grid, Cell } from '@babylon/core-ui'

import style from './DurationOptions.module.css'

interface DurationOptionsProps {
  children: Function
}

const DEFAULT_TIMES = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]

const DurationOptions = ({ children }: DurationOptionsProps) => (
  <Grid className={style.DurationOptionsGrid} columns={3} rows={4}>
    {DEFAULT_TIMES.map((value) => (
      <Cell key={value}>{children(value)}</Cell>
    ))}
  </Grid>
)

export default DurationOptions
