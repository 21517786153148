import { gql } from '@apollo/client'

export default gql`
  query AppointmentInvite($inviteId: ID!) {
    appointmentInvite(id: $inviteId) {
      id
      consultation_type
      duration_minutes
      notes_for_member
      service_type_uuid: nullable_service_type_uuid
      allowed_mediums
      preferred_medium
      allowed_professions
      preferred_profession
      intimate
    }
    professions {
      key
      id
    }
  }
`
