import { gql } from '@apollo/client'

export default gql`
  query GetProfessionInfo($id: String) {
    profession(id: $id) {
      name
      alternative_slot_durations_minutes
      default_slot_duration_minutes
      medical_identifier_label
    }
  }
`
