import React, { FC, ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import {
  Text,
  Grid,
  Cell,
  Spinner,
  Alert,
  Snackbar,
  SnackbarIntent,
} from '@babylon/core-ui'
import { FilterSummary } from '@babylon/cce-shared-components'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../..'

import SearchResultItem, { SetSnackbarMessage } from '../SearchResultItem'
import messages from './SearchResults.messages'
import styles from './styles.module.scss'

export interface CustomFilterSummaryProps {
  hide: boolean
  currentPage: number
  total: number
  totalPages: number
  rowsPerPage: number
  onPageChange?: (pageNumber: number) => void
  onRowsPerPageChange?: (rows: number) => void
}

export interface SearchResultItemsProps {
  members: Member[]
  setSnackbarMessage: SetSnackbarMessage
}

export interface MembersSearchResultsProps {
  currentPage: number
  rowsPerPage: number
  total: number
  totalPages: number
  errors?: string[]
  list?: Member[]
  loading?: boolean
  pristine?: boolean
  onPageChange?: (pageNumber: number) => void
  onRowsPerPageChange?: (rows: number) => void
}

const SpinnerOverlay = () => (
  <div className={styles.SpinnerOverlay}>
    <Spinner centered size="large" color="var(--primary-color)" />
  </div>
)

const CustomFilterSummary: FC<CustomFilterSummaryProps> = ({
  hide,
  currentPage,
  total,
  totalPages,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const fm = useFormatMessage()

  return (
    <Grid className={hide ? styles.HideSearchResults : undefined}>
      <Cell width={12}>
        <FilterSummary
          heading=""
          activeCount={total}
          activeLabel={fm(messages.active_label)}
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          rowOptions={[5, 10, 15, 20, 25]}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          rowsPerPageLabel={fm(messages.rows_per_page_label)}
        />
      </Cell>
    </Grid>
  )
}

const SearchResultItems: FC<SearchResultItemsProps> = ({
  members,
  setSnackbarMessage,
}) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.membersSearchView,
  })
  const [expandedId, setExpandedId] = useState<string>('')
  const onFamilyMembersToggle = (memberId: string) => {
    const isExpanded = memberId === expandedId

    if (!isExpanded) {
      trackClick({
        elementName: 'view-family-accounts-btn',
        elementType: TrackingElementType.button,
      })
    } else {
      trackClick({
        elementName: 'hide-family-accounts-btn',
        elementType: TrackingElementType.button,
      })
    }

    setExpandedId(isExpanded ? '' : memberId)
  }

  return (
    <>
      {members.map((member) => (
        <div key={member.id} className={styles.SearchResultItemWrapper}>
          <SearchResultItem
            expanded={expandedId === member.id}
            onFamilyMembersToggle={onFamilyMembersToggle}
            setSnackbarMessage={setSnackbarMessage}
            {...member}
          />
        </div>
      ))}
    </>
  )
}

const SearchResults: FC<MembersSearchResultsProps> = ({
  list = [],
  loading,
  currentPage,
  total,
  pristine,
  totalPages,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  errors = [],
}) => {
  const [snackbar, setSnackbar] = useState<Snack>({
    open: false,
    message: null,
    icon: undefined,
    intent: 'primary',
    autoHideDuration: 3000,
  })

  const hasError = !!errors.length
  const withResults = list.length > 0 && !hasError
  const noResults = !withResults && !hasError && !pristine && !loading
  const filterSummaryProps = {
    currentPage,
    total,
    totalPages,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
  }

  const closeSnackbarHandler = () => {
    setSnackbar({
      ...snackbar,
      open: false,
      message: null,
    })
  }

  const setSnackbarMessageHandler = (
    message?: string,
    icon?: ReactNode,
    intent: SnackbarIntent = 'primary'
  ) => {
    setSnackbar({
      ...snackbar,
      message,
      open: !!message,
      icon,
      intent,
    })
  }
  const fm = useFormatMessage()

  return (
    <>
      <Snackbar
        data-testid="snackbar"
        autoHideDuration={snackbar.autoHideDuration}
        className={styles.snackbar}
        icon={snackbar.icon}
        intent={snackbar.intent}
        message={snackbar.message}
        open={snackbar.open}
        onClose={closeSnackbarHandler}
      />
      <div data-testid="search-results" className={styles.SearchResults}>
        {loading ? <SpinnerOverlay /> : null}
        {withResults ? (
          <div className={loading ? styles.LoadingSearchResults : undefined}>
            <CustomFilterSummary hide={!withResults} {...filterSummaryProps} />
            <SearchResultItems
              members={list}
              setSnackbarMessage={setSnackbarMessageHandler}
            />
            <CustomFilterSummary
              hide={!withResults || list.length < 3}
              {...filterSummaryProps}
            />
          </div>
        ) : null}

        {noResults ? (
          <Text
            data-testid="search-results-no-results"
            className={styles.NoResults}
          >
            {fm(messages.no_patients_found)}
          </Text>
        ) : null}

        {hasError ? (
          <Alert className={styles.Alert} intent="error" title="Errors">
            <ul data-testid="search-results-errors">
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        ) : null}

        {hasError || noResults ? (
          <Alert
            className={styles.Alert}
            intent="primary"
            title={fm(messages.title_improve_search_results)}
          >
            <ul data-testid="search-results-alert-text">
              <li>
                <FormattedMessage
                  {...messages.body_improve_search_results}
                  values={{
                    b: (chunks: any) => <strong>{chunks}</strong>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  {...messages.body_improve_search_results_two}
                  values={{
                    b: (chunks: any) => <strong>{chunks}</strong>,
                  }}
                />
              </li>
            </ul>
          </Alert>
        ) : null}
      </div>
    </>
  )
}

export default SearchResults
