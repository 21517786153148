import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Box } from '@babylon/medkit'
import { useProductConfig } from '@babylon/product-config'

import { Header } from '../../../..'
import { AssessmentMemberCard } from '../../../../Assessment'
import messages from './messages'

const AssessmentCard = ({ patientId }: Props) => {
  const fm = useFormatMessage()
  const { getProp } = useProductConfig()
  const assessmentsEnabled = getProp('memberOperations', 'assessmentsEnabled')

  if (!assessmentsEnabled) {
    return null
  }

  return (
    <Box>
      <Header title={fm(messages.header)} />
      <AssessmentMemberCard memberId={patientId} />
    </Box>
  )
}

interface Props {
  patientId: string
}

export default AssessmentCard
