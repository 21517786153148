import React from 'react'
import {
  Box,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import { useFormatMessage } from '@babylon/intl'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import messages from './AppointmentWaitTimeTable.messages'
import { AppointmentWaitTimeStatus } from '../AppointmentWaitTimeStatus'
import type { AppointmentWaitTimeTableProps } from './AppointmentWaitTimeTable.types'

const tableHeadings = [
  {
    size: '16%',
    label: messages.statusTableHeading,
  },
  {
    size: '28%',
    label: messages.consumerNetworkTableHeading,
  },
  {
    size: '28%',
    label: messages.serviceTypeTableHeading,
  },
  {
    size: '28%',
    label: messages.waitingTimeTableHeading,
  },
]

const AppointmentWaitTimeTable = ({
  waitTimes,
  isLoading,
}: AppointmentWaitTimeTableProps) => {
  const fm = useFormatMessage()

  return (
    <Table aria-label={fm(messages.tableTitleLabel)}>
      <TableHead>
        <TableRow>
          {tableHeadings.map((heading) => (
            <TableCell
              key={fm(heading.label)}
              component="th"
              width={heading.size}
            >
              {fm(heading.label)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading ? (
          waitTimes.map(
            ({
              status,
              consumerNetwork,
              serviceType,
              nextAppointmentTimes,
            }) => (
              <TableRow
                key={`${consumerNetwork.name}-${serviceType.name}`}
                hover
              >
                <TableCell>
                  <AppointmentWaitTimeStatus status={status} />
                </TableCell>
                <TableCell>{consumerNetwork.name}</TableCell>
                <TableCell>{serviceType.name}</TableCell>
                <TableCell>
                  {nextAppointmentTimes?.[0]
                    ? formatDistanceToNowStrict(
                        parseISO(nextAppointmentTimes[0])
                      )
                    : null}
                </TableCell>
              </TableRow>
            )
          )
        ) : (
          <TableRow>
            <TableCell colSpan={tableHeadings.length}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight={400}
              >
                <CircularProgress />
              </Box>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default AppointmentWaitTimeTable
