import React from 'react'
import { FormField } from '@babylon/core-ui'
import { useField } from 'formik'
import MediumSelection from '../../../features/ArrangeAppointment/MediumSelection'
import ConsultationTypes, { mediums } from '../../../types/ConsultationTypes'
import ServiceTypeField from './ServiceTypeField'
import styles from './AllowedMediumField.module.css'

interface Props {
  isEditing: boolean
}

const AllowedMediumField = ({ isEditing = false }: Props) => {
  const [, { touched, value, error }, { setValue }] = useField(
    'allowed_mediums'
  )

  const handleChange = (selectedMediums) => {
    setValue(selectedMediums)
  }

  const errMessage = (error as string) || ''
  const touchedField = (touched || false) && !!errMessage

  return isEditing ? (
    <FormField
      errorMessage={errMessage}
      displayError={touchedField}
      className={styles.container}
    >
      <MediumSelection
        options={mediums.filter((medium) => medium !== 'text')}
        selectedMediums={value}
        onChange={handleChange}
      />
    </FormField>
  ) : (
    <ServiceTypeField
      label="Medium(s)"
      value={value?.map((medium) => ConsultationTypes[medium]).join(', ')}
    />
  )
}

export default AllowedMediumField
