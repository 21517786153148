import React, { RefObject } from 'react'
import { useField } from 'formik'

import { Label, Input } from '@babylon/core-ui'

import { fieldLabelMap } from '../ClinicianVisibilitySearchValidation'

import { MultiTag } from '../../../components'

interface ClinicianIdInputProps {
  inputRef?: RefObject<HTMLInputElement>
}

function ClinicianIdInput({ inputRef }: ClinicianIdInputProps) {
  const formField = 'clinicianId'
  const [{ value }, { error }, { setValue }] = useField(formField)

  const handleKeyEnter = (e) => {
    e.preventDefault()

    if (e?.target?.value) {
      setValue([...new Set([...value, e?.target?.value])])

      // Should be using a ref but Input is not playing well with forwardRef
      e.target.value = ''
    }
  }

  return (
    <>
      <Label htmlFor="clinician-id-input">{fieldLabelMap[formField]}</Label>
      <Input
        inputRef={inputRef}
        data-testid="input-clinician-id"
        onEnterKeyDown={handleKeyEnter}
        id="clinician-id-input"
        fill
        intent={error ? 'error' : undefined}
      />
      <MultiTag
        tags={value?.map((val) => ({
          key: val,
          name: val,
        }))}
        onChange={(tags) => setValue(tags.map(({ key }) => key))}
      />
    </>
  )
}

export default ClinicianIdInput
