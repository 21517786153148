import React, { useRef, useState, useMemo } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Grid, Cell, DropdownSelect, Button, Spinner } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { PortalTransaction as IPortalTransaction } from '@babylon/graphql-middleware-types'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import InfiniteScroll from 'react-infinite-scroller'
import {
  PopupDialogProps,
  logException,
  useSnackBar,
  DrawerView,
  MemberOpsModuleName,
} from '../../../../../../..'
import { errorListFromQueryError } from '../../../../../../../util/helpers'
import {
  TRANSACTIONS_QUERY,
  GET_TRANSACTION_REASONS,
  APPLY_REFUND,
} from './queries'
import Transaction from './Transaction'
import styles from './Transactions.module.scss'
import messages from './Transactions.messages'

interface TransactionsProps extends Omit<PopupDialogProps, 'children'> {
  patientId: string
}

export default ({ patientId, toggleVisible, visible }: TransactionsProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })
  const fm = useFormatMessage()
  const ref = useRef<HTMLDivElement | null>(null)
  const [page, setPage] = useState(2)
  const [hasMore, setHasMore] = useState(true)

  const [reasonsModalVisible, setReasonsModalVisible] = useState(false)
  const [refundReason, setRefundReason] = useState<Reason>({
    id: 0,
    reason: '',
  })
  const [transactions, setTransactions] = useState<IPortalTransaction[]>([])

  const [transactionId, setTransactionId] = useState('')

  const { setSnackbarMessage } = useSnackBar()

  type Reason = {
    id: number
    reason: string
  }

  const { data, fetchMore } = useQuery<{
    getTransactions: IPortalTransaction[]
  }>(TRANSACTIONS_QUERY, {
    variables: {
      patientId,
      page: 1,
    },
    onCompleted: ({ getTransactions }) => {
      setTransactions((t) => [...t, ...getTransactions])
      setHasMore(data?.getTransactions.length === 25)
    },
  })
  const { data: transactionReasonsData } = useQuery<{
    getTransactionReasons: Reason[]
  }>(GET_TRANSACTION_REASONS)

  const [applyRefund] = useMutation(APPLY_REFUND, {
    onError: (error) => {
      const errors = errorListFromQueryError(error)
      errors.forEach((error) => {
        if (error.response.status === 500) {
          logException(error)
        }
      })
      setSnackbarMessage(fm(messages.transaction_error), null, 'error')
    },
    onCompleted: ({ applyRefund }) => {
      setTransactions((transactions) =>
        transactions.map((transaction) => {
          if (transaction.id === transactionId) {
            return applyRefund
          }

          return transaction
        })
      )
      setSnackbarMessage(fm(messages.transaction_success), null, 'success')
    },
  })

  const loadFunc = () => {
    fetchMore({
      variables: {
        page,
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        setPage((x) => x + 1)

        if (fetchMoreResult.getTransactions.length !== 25) {
          setHasMore(false)
        }

        setTransactions((t) => [...t, ...fetchMoreResult.getTransactions])
      },
    })
  }

  const transactionReasonsOptions = useMemo(
    () =>
      transactionReasonsData?.getTransactionReasons
        ?.map(({ id, reason }) => ({
          key: `${id}`,
          value: reason,
        }))
        .sort((a, b) => parseInt(a.key, 10) - parseInt(b.key, 10)),
    [transactionReasonsData]
  )

  return (
    <DrawerView
      title={fm(messages.transactions)}
      toggleVisible={toggleVisible}
      visible={visible}
      ref={ref}
    >
      <div className={styles.Transactions}>
        {!reasonsModalVisible ? (
          <>
            <Grid columns="9" className={styles.Transactions__heading}>
              <Cell width={2}>{fm(messages.type)}</Cell>
              <Cell>{fm(messages.card)}</Cell>
              <Cell>{fm(messages.price)}</Cell>
              <Cell width={2}>{fm(messages.created)}</Cell>
            </Grid>
            <InfiniteScroll
              loadMore={loadFunc}
              loader={
                <Spinner
                  className={styles.Transactions__spinner}
                  key="infinite-loader"
                />
              }
              hasMore={hasMore}
              initialLoad={false}
              useWindow={false}
              getScrollParent={() => ref.current}
              threshold={0}
            >
              {transactions.map((transaction) => (
                <Transaction
                  key={transaction.id}
                  {...transaction}
                  patientId={patientId}
                  onRefund={(id) => {
                    setTransactionId(id)
                    setReasonsModalVisible(true)
                  }}
                />
              ))}
            </InfiniteScroll>
          </>
        ) : (
          <Grid columns="2">
            <Cell width={2}>
              <div>{fm(messages.refundTransaction)}</div>
            </Cell>
            <Cell width={1}>
              <DropdownSelect
                data-testid="reasons-drop-down"
                options={transactionReasonsOptions}
                onChange={(option) => {
                  setRefundReason({
                    id: option.key as number,
                    reason: option.value as string,
                  })
                }}
                selectedOption={{
                  key: refundReason.id,
                  value: refundReason.reason,
                }}
              />
            </Cell>
            <Cell className={styles.ButtonGroup} width={2}>
              <Button
                className={styles.Button__blue}
                onClick={() => {
                  applyRefund({
                    variables: {
                      input: {
                        patientId,
                        transactionId,
                        reasonId: refundReason.id,
                      },
                    },
                  })
                  setReasonsModalVisible(false)
                  trackClick({
                    elementName: 'transactions-refund-btn',
                    elementType: TrackingElementType.button,
                  })
                }}
              >
                {fm(messages.refund)}
              </Button>
              <Button
                className={styles.Button__white}
                onClick={() => {
                  setReasonsModalVisible(false)
                }}
              >
                {fm(messages.cancel)}
              </Button>
            </Cell>
          </Grid>
        )}
      </div>
    </DrawerView>
  )
}
