import React from 'react'
import cx from 'classnames'

import { Input } from '../forms'

const Checkbox = ({ children, className, ...props }) => (
  <Input
    {...props}
    type="checkbox"
    label={children || props.label}
    className={cx(className)}
  />
)

export default Checkbox
