import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Text } from '@babylon/medkit'
import { TrackingElementType, useTrackClick } from '@babylon/tracking/react'
import { MemberOpsModuleName } from '../../../../../../..'
import { DrawerView } from '../../../../../..'
import AddRelatedPersonsForm from './AddRelatedPersonsForm'
import RelatedPersonCard from '../RelatedPersons/RelatedPersonCard'
import CreateNewPatientForm from '../CreateNewPatientForm'
import styles from '../../MemberRelationshipsCard.module.scss'
import messages from '../../MemberRelationshipsCard.messages'

export type AddRelatedPersonsDrawerProps = {
  currentMemberId: string
  currentMemberName: string
  toggleVisible: () => void
  title: string
  isMinor: boolean
  memberId: string
  onSuccessfulAdd: () => void
}

const AddRelatedPersonsDrawer = ({
  currentMemberId,
  currentMemberName,
  toggleVisible,
  title,
  isMinor,
  memberId,
  onSuccessfulAdd,
}: AddRelatedPersonsDrawerProps) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.memberRelatedPersons,
  })
  const fm = useFormatMessage()
  const [uuid, setUuid] = useState<string | undefined>()
  const [showCreateNewPatientForm, setShowCreateNewPatientForm] = useState(
    false
  )
  const drawerHeaderTitle = showCreateNewPatientForm
    ? fm(messages.create_new_patient_button)
    : title
  const searchByUuid = (paramUuid: string | undefined) => setUuid(paramUuid)

  return (
    <DrawerView
      className={styles.AddRelatedPersonsDrawer}
      title={drawerHeaderTitle}
      visible
      toggleVisible={toggleVisible}
      disableCloseOnClickOut
      headerClassName={styles.AddRelatedPersonsDrawer__header}
    >
      <div
        data-testid="add-related-persons-drawer"
        className={styles.AddRelatedPersonsDrawer__body}
      >
        {!showCreateNewPatientForm ? (
          <>
            <AddRelatedPersonsForm
              onCreateNewPatientButtonClick={() => {
                trackClick({
                  elementName: 'launch-new-patient-profile-form-button',
                  elementType: TrackingElementType.button,
                })
                setShowCreateNewPatientForm(true)
                setUuid(undefined)
              }}
              isMinor={isMinor}
              handleSubmit={searchByUuid}
              memberId={uuid}
            />
            {uuid && (
              <>
                <Text
                  variant="body"
                  className={styles.AddRelatedPersonsDrawer__body}
                >
                  {fm(messages.search_result_message)}
                </Text>
                <RelatedPersonCard
                  relatedPersonId={uuid}
                  currentMemberId={currentMemberId}
                  currentMemberName={currentMemberName}
                  isCurrentMemberMinor={isMinor}
                  hideDrawer={toggleVisible}
                />
              </>
            )}
          </>
        ) : (
          <CreateNewPatientForm
            isMinor={isMinor}
            hideCreateNewPatientForm={() => setShowCreateNewPatientForm(false)}
            onSuccess={onSuccessfulAdd}
            onError={(newPatientId) => {
              setUuid(newPatientId)
              setShowCreateNewPatientForm(false)
            }}
            memberId={memberId}
          />
        )}
      </div>
    </DrawerView>
  )
}

export default AddRelatedPersonsDrawer
