import { RequestIdGenerator } from '@babylon/shared-utils'

import { APP_LOCALE, APP_NAME, VERSION } from './config'
import authAccessor from '../util/auth'

const requestIdGenerator = new RequestIdGenerator({ app: 'chatbot-web' })

const getHeaders = async () => {
  const auth = authAccessor()
  const authHeaders = await auth.requestHeaders()
  const babylonRequestId = requestIdGenerator.generate()

  return {
    'Accept-Language': APP_LOCALE,
    'babylon-request-id': babylonRequestId,
    'X-App-Name': APP_NAME,
    'X-App-Version': VERSION,
    'X-Platform': 'web',
    ...authHeaders,
  }
}

export default getHeaders
