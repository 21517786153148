import React, { useMemo, useCallback } from 'react'
import {
  DataGrid as MuiDataGrid,
  GridRowParams,
  GridActionsCellItem,
} from '@mui/x-data-grid'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import {
  Dimensions,
  SortRule,
  Pagination,
  ColumnDefinition,
  RowData,
  RowsSettings,
  Action,
} from './types'

export type ColumnsVisibility = {
  [column: string]: boolean
}

type Props = {
  /* Dimensions of the table */
  dimensions?: Dimensions

  /* Loading indication. If true, the loading animation is used */
  loading?: boolean

  /* Initial sort settings */
  initialSorting?: SortRule[]

  /* Pagination settings */
  pagination?: Pagination

  /* Columns definition */
  columns: ColumnDefinition[]

  /* Data to be displayed in the table */
  rows: RowData[]

  /* Settings affecting all rows */
  rowsSettings?: RowsSettings

  /* Initial columns to show. All other data should be hidden */
  initialColumnsToShow?: ColumnsVisibility

  /* Content for special column actions */
  actions?: Action[]

  /* The system prop that allows defining system overrides as well as additional CSS styles. */
  sx?: SxProps<Theme>
}

export const DataGrid: React.FC<Props> = ({
  dimensions,
  loading,
  initialSorting,
  pagination,
  columns,
  rows,
  rowsSettings,
  actions,
  initialColumnsToShow,
  sx,
}) => {
  const columnsMapped = useMemo(
    () => [
      ...columns.map((column) => ({
        ...column,
        field: column.fieldNameInRow,
      })),
      ...(actions
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<RowData>) =>
                actions.map((action) => (
                  <GridActionsCellItem
                    key={action.text}
                    label={action.text}
                    onClick={() => action.onClick(params.row)}
                    showInMenu={action.onlyShowInDropdownMenu}
                    icon={action.icon}
                  />
                )),
            },
          ]
        : []),
    ],
    [columns, actions]
  )

  const initialState = useMemo(() => {
    const sortModel = initialSorting?.map((sorting) => ({
      field: sorting.column,
      sort: sorting.order,
    }))

    const paginationOptions =
      pagination?.initialPageSize || pagination?.initialPage
        ? {
            paginationModel: {
              pageSize: pagination?.initialPageSize,
              page: pagination?.initialPage || 0,
            },
          }
        : undefined

    return {
      sorting: {
        sortModel,
      },
      pagination: paginationOptions,
      columns: {
        columnVisibilityModel: initialColumnsToShow,
      },
    }
  }, [initialSorting, pagination, initialColumnsToShow])

  const getRowSpacing = useCallback(() => {
    return {
      top: rowsSettings?.rowSpacing?.top,
      bottom: rowsSettings?.rowSpacing?.bottom,
    }
  }, [rowsSettings])

  return (
    <div
      style={{
        width: dimensions?.width,
        height: dimensions?.height,
      }}
    >
      <MuiDataGrid
        initialState={initialState}
        loading={loading}
        columns={columnsMapped}
        rows={(rows as unknown) as any[]}
        autoHeight={dimensions?.dynamic}
        {...(pagination?.pageSizeOptions && {
          pageSizeOptions: pagination.pageSizeOptions,
        })}
        paginationMode={pagination?.mode || 'client'}
        paginationModel={pagination?.paginationState}
        onPaginationModelChange={pagination?.handlePagination}
        rowCount={pagination?.rowCount}
        getRowHeight={rowsSettings?.dynamicHeight ? () => 'auto' : undefined}
        getRowSpacing={rowsSettings?.rowSpacing ? getRowSpacing : undefined}
        sx={
          sx || {
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
              py: 1,
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '15px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '22px',
            },
          }
        }
      />
    </div>
  )
}
