import React, { ReactNode } from 'react'
import { Button, Card, Cell, Grid } from '@babylon/core-ui'
import { Form, Formik, FormikConfig, FormikValues } from 'formik'
import { UnexpectedError } from '../Utils'
import styles from './SearchForm.module.css'

interface Props extends FormikConfig<FormikValues> {
  'data-testid'?: string
  children: ReactNode
  errorMessage?: string
  resetForm: () => void
}

export default function SearchForm({
  'data-testid': dataTestId,
  children,
  errorMessage,
  initialValues,
  onSubmit,
  resetForm,
  validate,
  validationSchema,
  enableReinitialize,
}: Props) {
  return (
    <>
      <UnexpectedError visible={!!errorMessage} message={errorMessage} />
      <Card data-testid={dataTestId}>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          enableReinitialize={enableReinitialize}
        >
          <Form>
            <div className={styles.wrapper} data-testid="inner-search-form">
              <Grid>
                {children}
                <Cell className={styles.formActions}>
                  <Button intent="link" type="reset" onClick={resetForm}>
                    Reset
                  </Button>
                  <Button type="submit">Search</Button>
                </Cell>
              </Grid>
            </div>
          </Form>
        </Formik>
      </Card>
    </>
  )
}
