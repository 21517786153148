import { gql } from '@apollo/client'

export const EDIT_PHARMACIST = gql`
  mutation EditPharmacist($input: UpdatePharmacistPractitionerInput!) {
    updatePharmacistPractitioner(input: $input) {
      ... on Practitioner {
        id
        identifiers(first: 10) {
          nodes {
            system
            value
          }
        }
        isActive
        names(first: 1) {
          nodes {
            text
          }
        }
        practitionerRole(first: 10) {
          nodes
        }
      }
      ... on UpdatePharmacistPractitionerError {
        id
        errorMessage
      }
    }
  }
`

export const CREATE_PHARMACIST = gql`
  mutation CreatePharmacist($input: CreatePharmacistPractitionerInput!) {
    createPharmacistPractitioner(input: $input) {
      ... on Practitioner {
        id
      }
    }
  }
`
