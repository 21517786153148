import React from 'react'
import { FormikInput } from '@babylon/core-ui'
import { useFormatMessage } from '@babylon/intl'
import { FormSection, FieldRow } from '../FormFields'
import messages from './GPDetails.messages'

export interface GPDetailsProps {
  disableMemberEditForm?: boolean
}

const GPDetails = ({ disableMemberEditForm }: GPDetailsProps) => {
  const fm = useFormatMessage()

  return (
    <div data-testid="member-edit-gp-details">
      <FormSection title={fm(messages.title)}>
        <FieldRow testId="previous_practice_name">
          <FormikInput
            id="previous_practice_name"
            name="previous_practice_name"
            label={fm(messages.previous_practice_name)}
            readOnly
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="gp_name">
          <FormikInput
            id="gp_name"
            name="gp_details.gp_name"
            label={fm(messages.gp_name)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="gp_address">
          <FormikInput
            id="gp_address_first_line"
            name="gp_details.gp_address_first_line"
            label={fm(messages.gp_address)}
            disabled={disableMemberEditForm}
          />
          <FormikInput
            id="gp_address_second_line"
            name="gp_details.gp_address_second_line"
          />
          <FormikInput
            id="gp_address_third_line"
            name="gp_details.gp_address_third_line"
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow size="small" testId="gp_address_post_code">
          <FormikInput
            id="gp_address_post_code"
            name="gp_details.gp_address_post_code"
            label={fm(messages.gp_postcode)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="gp_surgery_name">
          <FormikInput
            id="gp_surgery_name"
            name="gp_details.gp_surgery_name"
            label={fm(messages.gp_surgery_name)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
        <FieldRow testId="gp_surgery_phone_number">
          <FormikInput
            id="gp_surgery_phone_number"
            name="gp_details.gp_surgery_phone_number"
            label={fm(messages.gp_surgery_phone_number)}
            disabled={disableMemberEditForm}
          />
        </FieldRow>
      </FormSection>
    </div>
  )
}

export default GPDetails
