import React from 'react'
import { Spinner } from '@babylon/medkit'

import { logException } from '../../../..'
import useAssessmentStage from '../../utils/useAssessmentStage'
import Card from '../Card'

const CardWrapper = ({ memberId }: Props) => {
  const { data, error, loading } = useAssessmentStage(memberId)

  if (error) {
    // TODO: [HC-855] display errors to users
    logException(error)
  }

  const { assessmentStage, member } = data

  return (
    <section>
      {loading && <Spinner center variant="primary" />}
      {assessmentStage && (
        <Card assessmentStage={assessmentStage} member={member} />
      )}
    </section>
  )
}

interface Props {
  memberId: string
}

export default CardWrapper
