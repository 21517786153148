import { AvailabilitySlotType } from '../ClinicianTimeline/GetClinicianAvailability'

enum ShiftSlotTypes {
  booked = 'Booked',
  available = 'Available',
  admin = 'Admin slot',
  blocked = 'Not scheduled for work',
}

export type ShiftSlotTypeName = keyof typeof ShiftSlotTypes

interface ShiftSlotType
  extends Partial<Pick<AvailabilitySlotType, 'admin' | 'appointment'>> {}

export const getShiftSlotType = (slot?: ShiftSlotType) => {
  if (!slot) {
    return 'blocked'
  }

  if (!slot?.appointment?.id && !slot?.admin) {
    return 'available'
  }

  if (slot?.admin) {
    return 'admin'
  }

  return 'booked'
}

export default ShiftSlotTypes
