import React from 'react'
import { Text } from '@babylon/medkit'

import styles from './DataPointStateText.module.scss'

const DataPointStateText = ({ state, text }: Props) => (
  <Text className={styles[`stateColour_${state}`]} tag="span" variant="body">
    {text}
  </Text>
)

interface Props {
  state: string
  text: string
}

export default DataPointStateText
