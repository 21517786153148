import React, { useState, useMemo } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { useQuery } from '@apollo/client'
import {
  Connection,
  CoreRubyFeatureSwitchEdge,
  CoreRubyRegion,
  CoreRubyRegionEdge,
  StringEdge,
  SupplyNetworkEdge,
} from '@/gql/graphql'
import { CoreDataTable } from '@/components/DataTableFromGQL'
import { messages } from './messages'
import {
  QUERY_GET_PAGE_QUERY,
  QUERY_GET_SUPPLY_NETWORKS,
} from './apollo/queries'
import {
  EditObjectConfig,
  EditObjectConfigField,
} from '@/components/DataTableFromGQL/types'
import {
  CREATE_SUPPLY_NETWORK,
  UPDATE_SUPPLY_NETWORK,
} from '@/routes/SupplyNetworks/apollo/mutations'
import { FeatureSwitch } from '@/routes/Pharmacies/types'

const rowFormatter = (data: Connection) =>
  data?.edges.map((item: SupplyNetworkEdge) => ({
    id: item.node?.id as string,
    name: item.node?.name as string,
    region: item.node?.region as string,
    priority: item.node?.priority as number,
    featureSwitchSettings: item.node?.activeFeatureSwitches?.edges.map(
      (featureSwitchEdge: StringEdge) => featureSwitchEdge.node
    ),
  }))

const filterFormatter = (filters: Record<string, string>) => {
  if (filters.name) return { name: filters.name.trim() }

  return {}
}

/*
 * Turns snake case string into a nicer, human-readable format
 */
const humanify = (snakeCaseString: string): string =>
  (snakeCaseString[0].toUpperCase() + snakeCaseString.slice(1)).replace(
    /_/g,
    ' '
  )

const mapFeatureSwitchesToCheckboxMultiSelectOptions = (
  featureSwitches: FeatureSwitch[]
): Array<{ value: string; label: string }> =>
  featureSwitches.map((featureSwitch: FeatureSwitch) => ({
    value: featureSwitch.name,
    label: humanify(featureSwitch.name),
  }))

export const SupplyNetworksDataGrid = () => {
  const fm = useFormatMessage()

  const [featureSwitches, setFeatureSwitches] = useState<FeatureSwitch[]>([])
  const [regions, setRegions] = useState<CoreRubyRegion[]>([])

  const { loading: loadingData, error: errorLoadingData } = useQuery(
    QUERY_GET_PAGE_QUERY,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true, // required to trigger onCompleted function after refetch
      context: {
        clientName: 'platform-gateway',
      },
      onCompleted: (data: any) => {
        setFeatureSwitches(
          data.coreRubyFeatureSwitches?.edges?.map(
            (edge: CoreRubyFeatureSwitchEdge) => ({
              name: edge.node?.name as string,
              description: edge.node?.description as string,
              ownedBy: edge.node?.ownedBy as string,
            })
          )
        )

        setRegions(
          data.coreRubyRegions?.edges?.map((edge: CoreRubyRegionEdge) => ({
            name: edge.node?.name as string,
            code: edge.node?.code as string,
            id: edge.node?.id as string,
          }))
        )
      },
    }
  )

  const filterOptions = [
    {
      name: 'name',
      label: fm(messages.name),
    },
  ]

  const columns = [
    {
      fieldNameInRow: 'name',
      headerName: 'Name',
      flex: 3,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div>
            <a href={`/admin/supply_networks/${params.row.id}`}>
              {params.value}
            </a>
          </div>
        )
      },
    },
    {
      fieldNameInRow: 'region',
      headerName: 'Region',
      flex: 1,
      sortable: false,
    },
  ]

  const editFields: EditObjectConfigField[] = useMemo(
    () => [
      {
        name: 'name',
        type: 'text',
        dataField: 'name',
        label: fm(messages.name),
        required: true,
      },
      {
        name: 'id',
        hidden: true,
      },
      {
        name: 'priority',
        type: 'number',
        dataField: 'priority',
        label: fm(messages.priority),
        required: true,
      },
      {
        name: 'featureSwitchSettings',
        type: 'checkbox-multi-select',
        dataField: 'featureSwitchSettings',
        label: fm(messages.featureSwitches),
        options: mapFeatureSwitchesToCheckboxMultiSelectOptions(
          featureSwitches
        ),
        required: false,
      },
    ],
    [fm, featureSwitches]
  )

  const addConfig: EditObjectConfig = useMemo(
    () => ({
      mutation: CREATE_SUPPLY_NETWORK,
      headings: {
        header: 'name',
      },
      fields: [
        {
          name: 'name',
          type: 'text',
          dataField: 'name',
          label: fm(messages.name),
          required: true,
        },
        {
          name: 'regionCode',
          type: 'select',
          dataField: 'regionCode',
          label: fm(messages.region),
          options: regions.map((region: CoreRubyRegion) => ({
            label: region.name,
            value: region.code,
          })),
          required: true,
        },
        {
          name: 'priority',
          type: 'number',
          dataField: 'priority',
          label: fm(messages.priority),
          required: true,
        },
        {
          name: 'featureSwitchSettings',
          type: 'checkbox-multi-select',
          dataField: 'featureSwitchSettings',
          label: fm(messages.featureSwitches),
          options: mapFeatureSwitchesToCheckboxMultiSelectOptions(
            featureSwitches
          ),
          required: false,
        },
      ],
      mutationInputFormatter: (input: any) => {
        return {
          ...input,
          regionCode: input.regionCode.value,
          featureSwitchSettings: input.featureSwitchSettings.map(
            (setting: string) => ({
              name: setting,
              isTurnedOn: true,
            })
          ),
        }
      },
    }),
    [fm, regions, featureSwitches]
  )

  const loadedWithoutErrors = () => {
    return !loadingData && !errorLoadingData
  }

  const editConfig: EditObjectConfig = {
    mutation: UPDATE_SUPPLY_NETWORK,
    fields: editFields,
    headings: {
      header: 'name',
    },
    mutationInputFormatter: (input: any) => {
      return {
        id: input.id,
        name: input.name,
        priority: input.priority,
        featureSwitchSettings: [
          // Every feature switch that is not in the 'input' should be turned off
          ...featureSwitches
            .filter(
              (featureSwitch) =>
                !input.featureSwitchSettings.includes(featureSwitch.name)
            )
            .map((featureSwitch) => ({
              name: featureSwitch.name,
              isTurnedOn: false,
            })),
          // Every feature switch that is in the 'input' should be turned on
          ...input.featureSwitchSettings.map((setting: string) => ({
            name: setting,
            isTurnedOn: true,
          })),
        ],
      }
    },
  }

  return (
    <div>
      <CoreDataTable
        id="supply_networks"
        objectTypeLabel={fm(messages.supplyNetworks)}
        columns={columns}
        query={QUERY_GET_SUPPLY_NETWORKS}
        rowFormatter={rowFormatter}
        editConfig={loadedWithoutErrors() ? editConfig : undefined}
        addConfig={loadedWithoutErrors() ? addConfig : undefined}
        filterFormatter={filterFormatter}
        filterOptions={filterOptions}
      />
    </div>
  )
}
