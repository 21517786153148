import { gql } from '@apollo/client'

export const PrescriptionRegions = gql`
  query PrescriptionRegions {
    prescriptionRegions {
      regionCode
      name
    }
  }
`

const adminDrugListFieldsFragment = gql`
  fragment AdminDrugListFieldsFragment on AdminDrug {
    id
    name
    strength
    pharmaceuticalForm
    generic
    drugRegions {
      id
      region {
        regionCode
      }
      blacklisted
    }
    activeIngredients {
      activeIngredientId
      name
    }
  }
`

export const AdminDrugs = gql`
  query AdminDrugs($query: String, $size: Int, $page: Int, $sort: String) {
    adminDrugs(query: $query, size: $size, page: $page, sort: $sort) {
      totalElements
      items {
        ...AdminDrugListFieldsFragment
      }
    }
  }
  ${adminDrugListFieldsFragment}
`

export const BlacklistDrug = gql`
  mutation BlacklistDrug(
    $id: ID!
    $region: String!
    $value: Boolean!
    $reason: String!
  ) {
    blacklistDrug(id: $id, region: $region, value: $value, reason: $reason) {
      ...AdminDrugListFieldsFragment
    }
  }
  ${adminDrugListFieldsFragment}
`

export const AdminDrugAlerts = gql`
  query AdminDrugAlerts(
    $query: String!
    $size: Int
    $page: Int
    $sort: String
  ) {
    adminDrugAlerts(query: $query, size: $size, page: $page, sort: $sort) {
      pageIndex
      totalItems
      itemsPerPage
      items {
        id
        activeIngredient {
          activeIngredientId
          name
        }
        regions
        prescriptionLimit
        timePeriod
        allowPrescribe
        createdAt
      }
    }
  }
`

export const AdminActiveIngredients = gql`
  query AdminActiveIngredients(
    $query: String!
    $size: Int
    $page: Int
    $sort: String
  ) {
    adminActiveIngredients(
      query: $query
      size: $size
      page: $page
      sort: $sort
    ) {
      pageIndex
      totalItems
      itemsPerPage
      items {
        id
        name
        iri
      }
    }
  }
`

export const DeleteAdminDrugAlert = gql`
  mutation DeleteAdminDrugAlert($id: ID!) {
    deleteAdminDrugAlert(id: $id)
  }
`
