import React from 'react'
import { FormattedMessage } from 'react-intl'
import ErrorBlock from '../ErrorBoundary/ErrorBlock'
import messages from './ServiceErrors.messages'
import styles from './ServiceErrors.module.scss'

export enum Services {
  Appointments = 'appointments',
  Workflows = 'workflows',
  RepeatTemplates = 'repeatTemplates',
}

export interface ServiceErrorsProps {
  services?: Services[]
}

const ServiceErrors = ({
  services,
}: ServiceErrorsProps & React.PropsWithChildren<unknown>) =>
  services && services.length ? (
    <div className={styles.serviceErrors} data-testid="service-errors">
      <ErrorBlock>
        <ErrorBlock.Heading>
          <FormattedMessage {...messages.heading} />
        </ErrorBlock.Heading>
        <p>
          <FormattedMessage {...messages.body} />
        </p>
        <ul className={styles.serviceErrors__services}>
          {services.sort().map((service) => (
            <li key={service} className={styles.serviceErrors__service}>
              <FormattedMessage {...messages[service]} />
            </li>
          ))}
        </ul>
        <ErrorBlock.NextSteps>
          <FormattedMessage {...messages.next_steps} />
        </ErrorBlock.NextSteps>
      </ErrorBlock>
    </div>
  ) : null

export default ServiceErrors
