export interface DropdownOption {
  value: number | string
  label: string
}

const MAX_DURATION = 120

const mapDurationToOption = (duration: number): DropdownOption => ({
  value: duration,
  label: `${duration} minutes`,
})

const mapDurationsToOptions = (durations: number[] = []): DropdownOption[] =>
  durations.map(mapDurationToOption)

const getDropdownOption = (value: number, options: DropdownOption[]) =>
  options.find((option) => option.value === value)

const getOptionsFromDefaultDuration = (
  defaultDuration?: number
): DropdownOption[] => {
  if (!defaultDuration) {
    return []
  }

  const quantity = Math.floor(MAX_DURATION / defaultDuration) || 1

  return new Array(quantity)
    .fill('')
    .map((_, index) => mapDurationToOption((index + 1) * defaultDuration))
}

const setRecommendedOption = (
  options: DropdownOption[],
  recommendedOptionValue?: number
): DropdownOption[] =>
  options.map(({ value, label }, index) =>
    (!recommendedOptionValue && index === 0) || value === recommendedOptionValue
      ? {
          value,
          label: `${label} (recommended)`,
        }
      : { value, label }
  )

export {
  getDropdownOption,
  getOptionsFromDefaultDuration,
  mapDurationsToOptions,
  mapDurationToOption,
  MAX_DURATION,
  setRecommendedOption,
}
