import React from 'react'
import { compose, withState, withHandlers } from 'recompose'
import withLifecycle from '@hocs/with-lifecycle'
import { Spinner } from '@babylon/babylon-forms/widgets'

import IFrame from '../IFrame'
import { ADMIN_PORTAL_FRAME_LEGACY_PORTAL_URL } from '../env-urls'
import styles from './styles.module.css'

let firstMount = false
let windowNumber = 0

const pathStr = (loc) => `${loc.pathname}${loc.search}${loc.hash}`
const noop = (x) => x

const enhance = compose(
  withState('frameLoading', 'setFrameLoading', true),
  withHandlers({
    onFrameLoad: ({ setFrameLoading }) => () => {
      setFrameLoading(false)
    },
  }),
  withLifecycle(
    ({
      onLinkClicked = noop,
      onFormSubmitted = noop,
      onScriptLoaded = noop,
      onContentLoaded = noop,
      onPageSizeChanged = noop,
      onModalOpened = noop,
      onMessageSent = noop,
    }) => ({
      onWillReceiveProps(props, nextProps) {
        const path = pathStr(props.location)
        const nextPath = pathStr(nextProps.location)

        if (nextPath !== path && !nextProps.frameLoading) {
          nextProps.setFrameLoading(true)
        }
      },
      onDidMount() {
        if (!firstMount) {
          firstMount = true
          addEventListener('message', ({ data, source }) => {
            // TODO: only process this message if we trust `event.origin`
            switch (data.type) {
              case 'LINK_CLICKED': {
                const { link, openExternally } = data
                if (openExternally) {
                  window.open(link, `window-${++windowNumber}`)
                } else {
                  onLinkClicked(link)
                }
                break
              }

              case 'FORM_SUBMITTED': {
                onFormSubmitted()
                break
              }

              case 'SCRIPT_LOADED': {
                onScriptLoaded(data.href)
                break
              }

              case 'CONTENT_LOADED': {
                onContentLoaded({
                  width: data.width,
                  height: data.height,
                })
                break
              }

              case 'PAGE_SIZE_CHANGED': {
                onPageSizeChanged({
                  width: data.width,
                  height: data.height,
                })
                break
              }

              case 'MODAL_OPENED': {
                onModalOpened()
                onMessageSent(source, data)
                break
              }

              default: {
                onMessageSent(source, data)
                break
              }
            }
          })
        }
      },
    })
  )
)

const AdminPortalFrame = ({ location, frameLoading, onFrameLoad }) => {
  const embedUrl = `${window.location.protocol}//${window.location.host}/`
  const searchParams = location.search
    ? `${location.search}&embed=${embedUrl}`
    : `?embed=${embedUrl}`
  const path = location.pathname.substr(1)
  const src = `${ADMIN_PORTAL_FRAME_LEGACY_PORTAL_URL}${path}${searchParams}`

  return (
    <div className={styles.legacyScreen}>
      <Spinner visible={frameLoading} />
      <IFrame
        title="Page Content"
        onLoad={onFrameLoad}
        src={src}
        className={styles.historylessIFrame}
      />
    </div>
  )
}

export default enhance(AdminPortalFrame)
