async function copyToClipboard(text: string, onCopy: () => void) {
  try {
    await navigator.clipboard.writeText(text)
    onCopy()
  } catch (err) {
    console.error('Failed to copy: ', err)
  }
}

export { copyToClipboard }
