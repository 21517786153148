import React, { useState, useCallback, useMemo } from 'react'
import { Formik, Form } from 'formik'

import { envFlag, envVar } from '@babylon/babylon-env'
import { Alert, Card } from '@babylon/core-ui'
import { useBabylonUser, User } from '@babylon/babylon-user'
import { useProductConfig } from '@babylon/product-config'

import { useFormatMessage } from '@babylon/intl'
import {
  DurationField,
  GroupingField,
  InstructionsField,
  NameField,
  Validation,
  AllowedMediumField,
  DefaultMediumField,
  CategoryField,
} from './ServiceTypeFields'
import { CreateServiceTypeInput } from '../NewServiceType/CreateServiceTypeMutation'
import { SaveServiceTypeInput } from '../ServiceType/SaveServiceTypeMutation'
import ServiceTypeFormActions from './ServiceTypeFormActions'
import { ConsultationTypeName } from '../../types/ConsultationTypes'
import { ServiceTypeCategory } from '../../types/ServiceType'
import messages from './ServiceTypeInfo.messages'
import styles from './ServiceTypeInfo.module.css'
import USEhrAppointmentTypeField from './ServiceTypeFields/USEhrAppointmentTypeField'

interface Props {
  isEditing?: boolean
  isNew?: boolean
  name?: string
  memberFacingName?: string
  durationMinutes?: number
  usEhrAppointmentType?: string
  grouping?: string
  instructions?: string
  memberDescription?: string
  allowedMediums?: ConsultationTypeName[]
  preferredMedium?: ConsultationTypeName
  onCancel?: () => void
  onSave?: (
    input: SaveServiceTypeInput & CreateServiceTypeInput
  ) => Promise<void>
  uuid?: string
  categories?: ServiceTypeCategory[]
  preferredCategories?: ServiceTypeCategory[]
}

interface FormikInitialValues {
  member_instructions?: string
  default_duration_minutes?: number
  grouping?: string
  name?: string
  member_facing_name?: string
  member_description?: string
  us_ehr_appointment_type?: string
  allowed_mediums?: ConsultationTypeName[]
  preferred_medium?: ConsultationTypeName
}

const ServiceTypeInfo = ({
  isEditing = false,
  isNew = false,
  name,
  memberFacingName,
  durationMinutes,
  usEhrAppointmentType,
  grouping,
  instructions,
  memberDescription,
  allowedMediums = [],
  preferredMedium,
  onCancel,
  onSave,
  uuid,
  categories = [],
  preferredCategories = [],
}: Props) => {
  const { getProp } = useProductConfig()
  const [editing, setEditing] = useState(isEditing || isNew)
  const user = useBabylonUser() as User

  const formatedCategories = preferredCategories.map((cat) => ({
    value: cat.uuid,
    label: cat.name,
  }))

  const usEhrAppointmentTypes = useMemo(
    () =>
      getProp('supplyAndScheduling', 'serviceType.usEhrAppointmentTypes') || [],
    [getProp]
  )

  const usEhrAppointmentTypeDropdownData = Array.isArray(usEhrAppointmentTypes)
    ? usEhrAppointmentTypes.map((appointmentType) => ({
        value: appointmentType,
        label: appointmentType,
      }))
    : []

  const handleCancel = useCallback(() => {
    setEditing(false)

    if (onCancel) {
      onCancel()
    }
  }, [onCancel, setEditing])

  const handleEdit = useCallback(() => {
    setEditing(true)
  }, [setEditing])

  const handleSubmit = useCallback(
    (values) => {
      const input = { ...values }

      if (!isNew) {
        input.uuid = uuid
      }

      input.service_type_categories = input.service_type_categories.map(
        ({ value, label }) => ({
          uuid: value,
          name: label,
        })
      )

      if (!input?.member_facing_name.trim()) {
        input.member_facing_name = input.name
      }

      // @ts-ignore
      if (!usEhrAppointmentTypes.includes(input.us_ehr_appointment_type)) {
        input.us_ehr_appointment_type = null
      }

      if (onSave) {
        onSave(input).then(() => {
          if (!isNew) {
            setEditing(false)
          }
        })
      }
    },
    [isNew, onSave, usEhrAppointmentTypes, uuid]
  )

  const hasAccessToManageServiceType =
    envFlag('MANAGE_SERVICE_TYPE') && user?.admin

  const digitalInviteExtensionEnabled = getProp(
    'clinicalCare.appointments.adminPortal',
    'digitalInviteExtensionEnabled'
  )

  const initialFormikValues = {
    member_instructions: instructions,
    default_duration_minutes: durationMinutes,
    grouping,
    name,
    member_facing_name: memberFacingName,
    // initial values should be undefined for validated fields
    // pre-existing service types without member description are return as null
    // hence the reason for coercion
    member_description: memberDescription ?? undefined,
    service_type_categories: formatedCategories,
  } as FormikInitialValues

  if (digitalInviteExtensionEnabled) {
    initialFormikValues.allowed_mediums = allowedMediums
    initialFormikValues.preferred_medium = preferredMedium
  }

  const isDev =
    process.env.NODE_ENV === 'development' || envVar('ENVIRONMENT') === 'dev'
  const deploymentRegion = envVar('ENVIRONMENT_COUNTRY_ISO_CODE')
  const showUSEhrAppointmentType = deploymentRegion?.startsWith('US') || isDev

  const fm = useFormatMessage()

  if (showUSEhrAppointmentType) {
    initialFormikValues.us_ehr_appointment_type =
      usEhrAppointmentType ?? fm(messages.serviceTypeUsEhrAppointmentTypeNotSet)
  }

  const memberFacingNameAndDescriptionEnabled = envFlag(
    'ENABLE_SERVICE_TYPE_MEMBER_FACING_NAME_AND_DESCRIPTION'
  )

  const immutableServiceTypesEnabled = envFlag('ENABLE_IMMUTABLE_SERVICE_TYPES')
  const isImmutable = immutableServiceTypesEnabled && !isNew
  const isEditable = editing && !isImmutable
  const showAlert = immutableServiceTypesEnabled && (isNew || editing)

  return (
    <Formik
      initialValues={initialFormikValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={Validation(
        messages,
        fm,
        digitalInviteExtensionEnabled,
        memberFacingNameAndDescriptionEnabled,
        showUSEhrAppointmentType
      )}
    >
      {({ values, handleReset }) => (
        <Form>
          <Card
            title={name}
            actions={
              !hasAccessToManageServiceType ? null : (
                <ServiceTypeFormActions
                  editing={editing}
                  onReset={handleReset}
                  onEdit={handleEdit}
                  onCancel={handleCancel}
                  isNew={isNew}
                />
              )
            }
          >
            {showAlert && (
              <Alert
                intent="warning"
                className={styles.alert}
                title={fm(messages.serviceTypeImmutableAlertTitle)}
              >
                {fm(messages.serviceTypeImmutableAlertMessage)}
              </Alert>
            )}

            {editing && (
              <NameField
                isEditing={isEditable}
                value={values.name}
                label={fm(messages.name)}
              />
            )}

            {memberFacingNameAndDescriptionEnabled && (
              <NameField
                isEditing={editing}
                value={values.member_facing_name ?? values.name}
                label={fm(messages.memberFacingName)}
                id="member_facing_name"
                name="member_facing_name"
                toolTipText={fm(messages.memberFacingNameInfo)}
              />
            )}

            <DurationField
              isEditing={isEditable}
              value={values.default_duration_minutes}
            />

            {showUSEhrAppointmentType && (
              <USEhrAppointmentTypeField
                isEditing={isEditable}
                label={fm(messages.serviceTypeUsEhrAppointmentType)}
                dropdownData={usEhrAppointmentTypeDropdownData}
                value={values.us_ehr_appointment_type}
                tooltipText={fm(
                  messages.serviceTypeUsEhrAppointmentTypeTooltipText
                )}
              />
            )}

            <GroupingField isEditing={editing} value={values.grouping} />

            {digitalInviteExtensionEnabled && (
              <>
                <AllowedMediumField isEditing={isEditable} />
                <DefaultMediumField isEditing={isEditable} />
              </>
            )}
            <CategoryField
              isEditing={editing}
              categories={categories}
              preferredCategories={preferredCategories}
            />
            {memberFacingNameAndDescriptionEnabled && (
              <InstructionsField
                isEditing={editing}
                value={values.member_description}
                id="member_description"
                name="member_description"
                label={fm(messages.memberDescription)}
                placeholder={fm(messages.memberDescriptionPlaceholder)}
                tooltipText={fm(messages.memberDescriptionInfo)}
              />
            )}
            <InstructionsField
              isEditing={editing}
              value={values.member_instructions}
              id="member_instructions"
              name="member_instructions"
              label={fm(messages.instruction)}
              placeholder={fm(messages.instructionsPlaceholder)}
              tooltipText={fm(messages.instructionsInfo)}
            />
          </Card>
        </Form>
      )}
    </Formik>
  )
}

export default ServiceTypeInfo
