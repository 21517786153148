import React, { PropsWithChildren } from 'react'

import styles from './styles.module.css'

type Props = PropsWithChildren<{
  onClick?: () => void
  type?: 'normal' | 'alert'
}>

const typeClasses = {
  alert: styles.alert,
  normal: styles.normal,
}

const MenuButton = ({ children, onClick, type = 'normal' }: Props) => (
  <button
    type="button"
    className={[styles.textButton, styles.menuButton, typeClasses[type]].join(
      ' '
    )}
    onClick={onClick}
  >
    {children}
  </button>
)

export default MenuButton
