import { Params } from './index'

type ACTIONS =
  | 'SUBMIT_SEARCH'
  | 'UPDATE_PAGE'
  | 'UPDATE_ROWS_PER_PAGE'
  | 'UPDATE_FIELD'
  | 'CLEAR_SEARCH'
  | 'UPDATE_FORM'
  | 'COMPLETE_PAGE_LOAD'
  | 'COMPLETE_SEARCH'
  | 'SET_ERRORS'

export interface Command {
  type: ACTIONS

  [key: string]: any
}

export const submitSearch = (params: Params): Command => ({
  type: 'SUBMIT_SEARCH',
  params,
})

export const updatePage = (page: number): Command => ({
  type: 'UPDATE_PAGE',
  page,
})

export const updateRowsPerPage = (per_page: number): Command => ({
  type: 'UPDATE_ROWS_PER_PAGE',
  per_page,
})

export const updateField = (name: string, value: any): Command => ({
  type: 'UPDATE_FIELD',
  name,
  value,
})

export const clearSearch = (): Command => ({
  type: 'CLEAR_SEARCH',
})

export const updateForm = (params: Params): Command => ({
  type: 'UPDATE_FORM',
  params,
})

export const completePageLoad = (
  decryptedPatientsSearchFilters: any
): Command => ({
  type: 'COMPLETE_PAGE_LOAD',
  decryptedPatientsSearchFilters,
})

export const completeSearch = (): Command => ({
  type: 'COMPLETE_SEARCH',
})

export const setErrors = (errors: any): Command => ({
  type: 'SET_ERRORS',
  errors,
})
