import React from 'react'
import cx from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { Grid, Cell } from '@babylon/core-ui'
import { useTrackClick, TrackingElementType } from '@babylon/tracking/react'
import { formatToTable } from './utils'
import styles from './styles.module.scss'
import messages from './messages'
import { CopyButton } from '../../../../../..'
import { MemberOpsModuleName } from '../../../../../../..'

type DeviceProps = {
  platform: string
  appVersion: string
  dateLastUsed: string
  headers: string[]
}

const Device = ({
  platform,
  appVersion,
  dateLastUsed,
  headers,
}: DeviceProps) => {
  const fm = useFormatMessage()
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.profileAccountServices,
  })

  return (
    <Grid columns="7" className={cx(styles.Device, styles.Grid)} center>
      <Cell width={2}>{platform}</Cell>
      <Cell width={2}>{appVersion}</Cell>
      <Cell width={2}>{dateLastUsed}</Cell>
      <Cell className={styles.CopyIcon}>
        <CopyButton
          onClick={() => {
            trackClick({
              elementName: 'copy-device-btn',
              elementType: TrackingElementType.button,
            })
          }}
          copiableContent={formatToTable(
            {
              platform,
              appVersion,
              dateLastUsed,
            },
            headers
          )}
          successMessage={fm(messages.copied_device_to_clipboard)}
        />
      </Cell>
    </Grid>
  )
}

export default Device
