import React from 'react'
import cx from 'classnames'

import { Button } from '../forms'
import Options from './Options'

class MoreOptions extends React.Component {
  state = {
    visible: false,
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside, false)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside, false)
  }

  render() {
    const { children, className, label, small, ...props } = this.props
    const { visible } = this.state
    return (
      <div
        className={cx('more-options', className)}
        ref={(ref) => (this.ref = ref)}
      >
        <Button small={small} onClick={this.toggleOptions}>
          {label || '⫶'}
        </Button>
        <div className={cx('options-wrapper', visible ? 'visible' : 'hidden')}>
          <Options {...props} onClick={this.toggleOptions}>
            {children}
          </Options>
        </div>
      </div>
    )
  }

  toggleOptions = () => {
    const { visible } = this.state
    this.setState({ visible: !visible })
  }

  handleClickOutside = (event) => {
    const { visible } = this.state
    if (visible && this.ref && !this.ref.contains(event.target)) {
      this.setState({ visible: false })
    }
  }
}

export default MoreOptions
