import React from 'react'
import { Button } from '@babylon/babylon-forms/forms'
import {
  SearchBox,
  DataGrid,
  Columns,
  Column,
  Actions,
  Pagination,
  PaginationSize,
  Row,
} from '@babylon/babylon-forms/widgets'

import LabelWithIcon from '@/components/LabelWithIcon'
import TimezoneDate from '@/components/TimezoneDate'

import Icon from '@/components/Icon'

import styles from './styles.module.scss'

const Spinner = ({ visible }) => (visible ? <Icon name="spinner" /> : null)

const formatActiveIngredient = (alert) => alert.activeIngredient.name

const formatRegions = (alert) =>
  alert.regions.map((region) => (
    <span key={region} className={styles.badge}>
      {region}
    </span>
  ))

const periodLabels = {
  D: 'day',
  W: 'week',
  M: 'month',
  Y: 'year',
}

const formatTimePeriod = (alert) => {
  const [, count, type] = alert.timePeriod.match(/^P(\d*)(W|M|Y|D)$/)

  return `${count} ${periodLabels[type]}${parseInt(count) !== 1 ? 's' : ''}`
}

const formatAllowPrescribe = (alert) => (alert.allowPrescribe ? 'Yes' : 'No')

const formatDate = ({ createdAt }) => <TimezoneDate date={createdAt} />

const sortLabelToColumnName = (label) =>
  label === 'activeIngredient.name' ? 'activeIngredient' : label

const parseSort = (sort) => {
  const [column, direction] = sort.split(',')

  return {
    column: sortLabelToColumnName(column),
    direction,
  }
}

const AlertsView = ({
  spinnerType,
  data: {
    loading,
    adminDrugAlerts: { itemsPerPage, pageIndex, totalItems, items },
    variables: { sort },
  },
  handlePageSizeChange,
  handlePageChange,
  handleEditClick,
  handleAuditClick,
  handleAddClick,
  handleDeleteClick,
  handleSearchChange,
  handleSort,
}) => (
  <div className={styles.alertView}>
    <Row className={styles.listHeader} stretch center>
      <Row>
        <SearchBox
          icon={<Icon name="search" />}
          className={styles.searchBox}
          onChange={handleSearchChange}
        />
        <PaginationSize
          value={itemsPerPage}
          onChange={handlePageSizeChange}
          totalItems={totalItems}
          spinner={<Spinner visible={spinnerType === 'page-size' && loading} />}
        />
      </Row>
      <Button outline onClick={handleAddClick}>
        <Icon name="add" /> Add new drug alert
      </Button>{' '}
    </Row>
    <DataGrid
      value={items}
      sort={{
        ...parseSort(sort),
        loading: spinnerType === 'sort' && loading,
      }}
      onSort={handleSort}
      icons={{
        asc: <Icon name="sort-up" />,
        desc: <Icon name="sort-down" />,
        sort: <Icon name="sort" />,
        spinner: <Spinner visible />,
      }}
    >
      <Columns>
        <Column
          name="activeIngredient"
          label="Active ingredient"
          value={formatActiveIngredient}
          sort
        />
        <Column name="region" label="Region" value={formatRegions} />
        <Column name="prescriptionLimit" label="Prescription limit" />
        <Column name="timePeriod" label="Period" value={formatTimePeriod} />
        <Column
          name="allowPrescribe"
          label="Can prescribe"
          value={formatAllowPrescribe}
        />
        <Column name="createdAt" label="Created at" value={formatDate} sort />
      </Columns>
      <Actions>
        <Button small onClick={handleEditClick}>
          <LabelWithIcon icon="edit" label="Edit" />
        </Button>
        <Button small onClick={handleDeleteClick}>
          <LabelWithIcon icon="delete" label="Delete" />
        </Button>
        <Button small onClick={handleAuditClick}>
          <LabelWithIcon icon="logs" label="Audits" />
        </Button>
      </Actions>
    </DataGrid>
    <div className={styles.listFooter}>
      <div />
      <div className={styles.pagination}>
        <div className={styles.spinner}>
          <Spinner visible={spinnerType === 'pagination' && loading} />
        </div>
        <Pagination
          page={pageIndex}
          size={itemsPerPage}
          totalElements={totalItems}
          onChange={handlePageChange}
        />
      </div>
    </div>
  </div>
)

export default AlertsView
