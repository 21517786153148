import React from 'react'
import styles from './styles.module.scss'

export interface TagListProps {
  testId?: string
  children?: React.ReactNode
}

export const TagList = ({ children, testId }: TagListProps) => (
  <div className={styles.tagWrapper} data-testid={testId}>
    {children}
  </div>
)
