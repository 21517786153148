import { defineMessages } from 'react-intl'

const baseId = 'appointment_wait_times'

export default defineMessages({
  pageTitle: {
    id: `${baseId}.page_title`,
    defaultMessage: 'Service status dashboard',
  },
  pageIntro: {
    id: `${baseId}.page_intro`,
    defaultMessage:
      'Information on how long would a patient need to wait to get their appointment now for different service lines.',
  },
})
