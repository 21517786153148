import React from 'react'
import cx from 'classnames'

import { withFormContext } from '../forms'

import Checkbox from './Checkbox'

const CheckboxGroup = ({
  children,
  config,
  name,
  label,
  'data-context': ctx,
  className,
  onChange,
  value,
}) => {
  const valueResolver = value
    ? (childName) => (state) => value(state, childName)
    : (childName) => (state) => state[name][childName]
  const handleChange = (childName) => (event, value) => {
    if (onChange) {
      onChange(event, childName, value)
    } else {
      ctx.store.setState({
        [name]: {
          ...ctx.store.state[name],
          [childName]: value,
        },
      })
    }
  }
  const nodes = config
    ? config.map(({ name, label }) => (
        <Checkbox
          key={name}
          name={name}
          label={label}
          value={valueResolver(name)}
          onChange={handleChange(name)}
        />
      ))
    : React.Children.map(children, (child) =>
        React.cloneElement(child, {
          value: valueResolver(child.props.name),
          onChange: handleChange(child.props.name),
        })
      )

  return label ? (
    <fieldset className={cx(className)}>
      <legend>{label}</legend>
      <span>{nodes}</span>
    </fieldset>
  ) : (
    <span className={cx(className)}>{nodes}</span>
  )
}

export default withFormContext(CheckboxGroup)
