import React, { useCallback } from 'react'
import { Redirect, Link, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'

import { Page } from '@babylon/core-ui'

import { useFormatMessage } from '@babylon/intl'
import { UnexpectedError } from '../../Utils'
import { LoadingContainer } from '../../components'
import CreateServiceTypeMutation, {
  CreateServiceTypeInput,
} from './CreateServiceTypeMutation'
import ServiceTypeInfo from '../ServiceTypeInfo'
import GetServiceTypeCategories from '../GetServiceTypeCategoriesQuery'
import messages from '../serviceTypes.messages'

interface Props {
  baseUrl: string
}

const NewServiceType = ({ baseUrl }: Props) => {
  const history = useHistory()
  const [
    createServiceType,
    {
      data: { createServiceType: { uuid = null } = {} } = {},
      loading: loadingMutation,
      error: errorMutation,
    },
  ] = useMutation(CreateServiceTypeMutation)

  const {
    loading: loadingCategories,
    data: categories,
    error: errorCategories,
  } = useQuery(GetServiceTypeCategories)

  const fm = useFormatMessage()

  const loading = loadingMutation || loadingCategories
  const error = errorMutation || errorCategories

  const handleSave = useCallback(
    async (input: CreateServiceTypeInput) => {
      await createServiceType({ variables: { input } })
    },
    [createServiceType]
  )

  const handleCancel = useCallback(() => {
    history.push('/admin/service-mix')
  }, [history])

  return (
    <Page
      breadcrumbs={[
        <Link key="1" to="/admin/service-mix">
          {fm(messages.header)}
        </Link>,
        'Add new Service Type',
      ]}
    >
      {uuid && <Redirect to={`${baseUrl}/${uuid}`} />}
      <UnexpectedError message={error && error.message} visible={!!error} />
      <LoadingContainer loading={loading}>
        <ServiceTypeInfo
          isNew
          onCancel={handleCancel}
          onSave={handleSave}
          categories={categories?.serviceTypeCategories}
        />
      </LoadingContainer>
    </Page>
  )
}

export default NewServiceType
