import { IntlFormatters } from 'react-intl'

import { DataPointCollectionState } from '../../enums'
import { FlowInterface } from '../../types'
import messages from './messages'

const getAssessmentState = (flows: FlowInterface[]) => {
  const flowStates = flows.map((flow) => flow.status.dataPointCollectionState)

  if (flowStates.every((state) => state === 'none_collected')) {
    return DataPointCollectionState.NONE_COLLECTED
  }

  if (flowStates.every((state) => state === 'all_collected')) {
    return DataPointCollectionState.ALL_COLLECTED
  }

  return DataPointCollectionState.PARTIALLY_COLLECTED
}

const getDisplayValues = (
  fm: IntlFormatters['formatMessage'],
  state: DataPointCollectionState
) => {
  switch (state) {
    case DataPointCollectionState.NONE_COLLECTED: {
      return {
        buttonText: fm(messages.buttonNoneCollected),
        stateTag: fm(messages.tagNoneCollected),
      }
    }
    case DataPointCollectionState.PARTIALLY_COLLECTED: {
      return {
        buttonText: fm(messages.buttonPartiallyCollected),
        stateTag: fm(messages.tagPartiallyCollected),
      }
    }
    case DataPointCollectionState.ALL_COLLECTED: {
      return {
        buttonText: fm(messages.buttonAllCollected),
        stateTag: fm(messages.tagAllCollected),
      }
    }
    default: {
      return {}
    }
  }
}

export { getAssessmentState, getDisplayValues }
