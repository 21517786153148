import { gql } from '@apollo/client'

export const UPDATE_MARK_AS_SENT_MUTATION = gql`
  mutation updateMarkedAsSent($consultationId: ID!, $markedAsSent: Boolean!) {
    updateMarkedAsSent(id: $consultationId, markedAsSent: $markedAsSent)
  }
`

export const UPDATE_SEND_FAX = gql`
  mutation gpSurgerySendFax($id: ID!, $faxNumberId: String!) {
    gpSurgerySendFax(id: $id, faxNumberId: $faxNumberId)
  }
`
