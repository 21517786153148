import React, { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Heading, Modal, Pagination, Spinner } from '@babylon/core-ui'
import { ButtonSimple, ButtonVariant, Grid, LinkButton } from '@babylon/medkit'
import { useFormatMessage } from '@babylon/intl'
import {
  useTracking,
  useTrackClick,
  TrackingActionType,
  TrackingElementType,
} from '@babylon/tracking/react'
import { Header, MemberOpsModuleName } from '../../../../..'
import { Alert as AlertType } from '../../../types'
import { LoadingHeader, LoadingBlock } from '../../../..'

import NoContent from '../NoContent'
import AlertAddModal from '../AlertAddModal'
import AlertCard from '../AlertCard'
import styles from './AlertList.module.scss'
import messages from './AlertList.messages'
import { fetchAlertsGQL } from './queries'

export interface AlertListProps {
  patientId: string
  setSnackbarMessage: (m: string) => void
  viewable?: number
  pageSize?: number
}

export interface AddAlertButtonProps {
  onClick(): void
}

export interface AlertsProps {
  alerts: AlertType[]
  viewable: number
  onViewMore(): void
  onRemove(): void
}

const AddAlertButton: FC<AddAlertButtonProps> = ({ children, onClick }) => (
  <ButtonSimple
    type="button"
    intent="secondary"
    variant={ButtonVariant.secondary}
    className={styles.AddAlertButton}
    onClick={() => {
      onClick()
    }}
    data-testid="member-alerts-open-add-alert-modal"
  >
    {children}
  </ButtonSimple>
)

const Alerts = ({ alerts, viewable, onViewMore, onRemove }: AlertsProps) => {
  const fm = useFormatMessage()

  if (!alerts.length) {
    return (
      <NoContent testId="member-alerts-no-alerts">
        {fm(messages.no_alerts)}
      </NoContent>
    )
  }

  return (
    <>
      {alerts.slice(0, viewable).map((alert: any) => (
        <AlertCard key={alert.id} {...alert} onRemove={onRemove} />
      ))}
      {alerts.length > viewable && (
        <Grid>
          <div className={styles.ViewMore}>
            <LinkButton
              data-testid="member-alerts-view-more"
              onClick={onViewMore}
            >
              {`${fm(messages.view_more, { count: alerts.length })}`}
            </LinkButton>
          </div>
        </Grid>
      )}
    </>
  )
}

const PaginatedAlertsModal = ({
  alerts,
  currentPage,
  onCloseHandler,
  onRemove,
  pagedAlerts,
  pageSize,
  setCurrentPage,
  viewMore,
}) => {
  const fm = useFormatMessage()

  return (
    <Modal label={fm(messages.header)} open={viewMore} onClose={onCloseHandler}>
      <div
        className={styles.Alert__modal}
        data-testid="member-alerts-alerts-modal-content"
      >
        <header>
          <Heading level="h2">{fm(messages.header)}</Heading>
        </header>
        <div className={styles.Alert__items}>
          {pagedAlerts.map((alert) => (
            <AlertCard key={alert.id} {...alert} onRemove={onRemove} />
          ))}
        </div>
        {alerts.length > pageSize && (
          <Pagination
            currentPage={currentPage}
            onNextClick={() => setCurrentPage(currentPage + 1)}
            onPageClick={(pageIndex: number) => setCurrentPage(pageIndex)}
            onPrevClick={() => setCurrentPage(currentPage - 1)}
            totalPages={Math.ceil(alerts.length / pageSize)}
          />
        )}
      </div>
    </Modal>
  )
}

const LoadingAlertList = () => (
  <div className={styles.loadingAlerts}>
    <LoadingHeader />
    <Grid>
      <LoadingBlock className={styles.loadingBlock} />
      <LoadingBlock className={styles.loadingBlock} />
    </Grid>
  </div>
)

export const AlertList: FC<AlertListProps> = ({
  patientId,
  setSnackbarMessage,
  viewable = 2,
  pageSize = 4,
}) => {
  const { trackClick } = useTrackClick({
    moduleName: MemberOpsModuleName.alertList,
  })
  const { trackEvent } = useTracking()
  const fm = useFormatMessage()
  const [viewMore, setViewMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [isAlertAddModalOpen, setIsAlertAddModalOpen] = useState(false)
  const { loading, error, data, refetch } = useQuery(fetchAlertsGQL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      patientId,
      sortOptions: { by: 'UPDATED', order: 'DESC' },
    },
  })

  const onViewMoreHandler = () => {
    setViewMore(true)
    trackClick({
      elementName: 'view-more-button',
      elementType: TrackingElementType.button,
    })
  }
  const onCloseHandler = () => setViewMore(false)
  const onRemoveHandler = () => {
    refetch()
    setViewMore(false)
    setSnackbarMessage(fm(messages.alert_removed))
    trackEvent({
      patientId,
      elementName: 'remove-alert-success',
      moduleName: MemberOpsModuleName.alertList,
      elementType: TrackingElementType.form,
      actionType: TrackingActionType.formSuccess,
    })
  }
  const openAddAlertModal = () => {
    setIsAlertAddModalOpen(true)
    trackClick({
      elementName: 'open-add-alert-modal-button',
      elementType: TrackingElementType.button,
    })
  }

  if (error) {
    return <span>{`${fm(messages.error)} ${error} ${data}`}</span>
  }

  const alerts = data?.patient_alerts
  const position = (currentPage - 1) * pageSize

  if (loading && !alerts) {
    return <LoadingAlertList />
  }

  if (loading && alerts) {
    return <Spinner centered />
  }

  const pagedAlerts: AlertType[] = alerts.slice(position, position + pageSize)

  return (
    <>
      <div className={styles.Alerts}>
        <Header
          title={fm(messages.header)}
          level="h2"
          className={styles.AlertsList_Header}
          actions={
            <AddAlertButton onClick={openAddAlertModal}>
              {fm(messages.add_alert)}
            </AddAlertButton>
          }
        />

        <Alerts
          alerts={alerts}
          viewable={viewable}
          onViewMore={onViewMoreHandler}
          onRemove={onRemoveHandler}
        />
      </div>

      <PaginatedAlertsModal
        alerts={alerts}
        currentPage={currentPage}
        onCloseHandler={onCloseHandler}
        onRemove={onRemoveHandler}
        pagedAlerts={pagedAlerts}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        viewMore={viewMore}
      />

      <AlertAddModal
        patientId={patientId}
        visible={isAlertAddModalOpen}
        onCancel={() => setIsAlertAddModalOpen(false)}
        onSave={() => {
          refetch()
          setIsAlertAddModalOpen(false)
          setSnackbarMessage(fm(messages.alert_added))
          trackEvent({
            patientId,
            elementName: 'add-alert-success',
            moduleName: MemberOpsModuleName.alertList,
            elementType: TrackingElementType.form,
            actionType: TrackingActionType.formSuccess,
          })
        }}
      />
    </>
  )
}

export default AlertList
