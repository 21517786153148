import React, { MutableRefObject } from 'react'
import 'focus-visible/dist/focus-visible'
import { FieldInputProps } from 'formik'

import { Fieldset } from '../..'
import useSelectionControl from '../hooks/useSelectionControl'

import { Radio } from '.'
import { ISelectionControlOption } from '../form.types'

export interface RadioGroupProps {
  name: string
  legend: string
  options: ISelectionControlOption[]
  onChange?: (selectedOptions: string[] | string) => void
  field?: FieldInputProps<any>
  helpText?: string
  ErrorMessages?: React.FC<any>
  className?: string
  fieldRef?: MutableRefObject<HTMLInputElement | null>
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  legend,
  options,
  onChange,
  field,
  helpText,
  ErrorMessages,
  className,
  fieldRef,
}: RadioGroupProps) => {
  const { selectOptions, onOptionChange } = useSelectionControl(
    options,
    onChange
  )

  return (
    <Fieldset legend={legend} helpText={helpText} role="radiogroup">
      {ErrorMessages && <ErrorMessages />}
      {selectOptions.map(
        ({
          value,
          selected,
          disabled,
          label,
          helpText,
        }: ISelectionControlOption) => (
          <Radio
            {...field}
            ref={fieldRef}
            id={`${name}-${value}`}
            key={`${name}-${value}`}
            name={name}
            value={value}
            className={className}
            checked={!!selected}
            helpText={helpText}
            onChange={(e) => {
              onOptionChange(e)
              field?.onChange(e)
            }}
            disabled={disabled}
          >
            {label}
          </Radio>
        )
      )}
    </Fieldset>
  )
}

export default RadioGroup
