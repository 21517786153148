import React from 'react'

import { SetSnackbarMessage } from '../../../types'
import MemberProfile, {
  LegalConsentsDecisionsState,
  MemberAddress,
} from './MemberProfile'
import styles from './ProfileHeader.module.scss'

export interface ProfileHeaderProps {
  member: Member
  memberAddress: MemberAddress
  legalDecisionsState: LegalConsentsDecisionsState
  setSnackbarMessage: SetSnackbarMessage
  onSwitchToOldView: () => void
  isStubbedProfile?: boolean
  isPendingRegistration?: boolean
}

const ProfileHeader = ({
  member,
  memberAddress,
  legalDecisionsState,
  onSwitchToOldView,
  setSnackbarMessage,
  isStubbedProfile,
  isPendingRegistration,
}: ProfileHeaderProps) => (
  <header className={styles.profileHeader} data-testid="member-profile-header">
    {!!member && (
      <MemberProfile
        member={member}
        memberAddress={memberAddress}
        legalConsentsDecisionsState={legalDecisionsState}
        setSnackbarMessage={setSnackbarMessage}
        onSwitchToOldView={onSwitchToOldView}
        isStubbedProfile={isStubbedProfile}
        isPendingRegistration={isPendingRegistration}
      />
    )}
  </header>
)

export default ProfileHeader
