import { useQuery, QueryFunctionOptions } from '@apollo/client'
import { useMemo } from 'react'

import GetConsultantsQuery from './GetConsultantsQuery'

interface ProfessionData {
  professions: Profession[]
}

interface ConsultantType {
  [key: string]: Pick<Profession, 'id' | 'key' | 'name'>
}

interface ConsultantTypeReturn {
  consultantTypeDict: ConsultantType
  loading: boolean
  error: any
}

interface useConsultantTypeProps extends Pick<QueryFunctionOptions, 'skip'> {}

const useConsultantType = (
  options?: useConsultantTypeProps
): ConsultantTypeReturn => {
  const { data, loading, error } = useQuery<ProfessionData>(
    GetConsultantsQuery,
    { skip: options?.skip }
  )

  const consultantTypeDict = useMemo(() => {
    if (loading) {
      return {}
    }

    const { professions = [] } = data || {}

    return professions.reduce((acc, profession) => {
      acc[profession.key.toUpperCase()] = profession

      return acc
    }, {})
  }, [data, loading])

  return { consultantTypeDict, loading, error }
}

export default useConsultantType
