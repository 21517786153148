import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Close } from '@babylon/icons'
import { Cell, Grid, Modal } from '@babylon/core-ui'
import { ButtonSimple, ButtonVariant, Text } from '@babylon/medkit'
import styles from './AlertDeleteConfirmationModal.module.scss'
import messages from './AlertDeleteConfirmationModal.messages'

const AlertDeleteConfirmationModal = ({
  onCloseHandler,
  onApproveHandler,
  showConfirmation,
}) => {
  const fm = useFormatMessage()

  return (
    <Modal
      open={showConfirmation}
      onClose={onCloseHandler}
      className={styles.AlertDeleteConfirmationModal}
    >
      <div data-testid="member-alerts-remove-modal">
        <Grid
          templateColumns="1fr 40px"
          className={styles.AlertDeleteConfirmationModal__header}
        >
          <Cell
            width={1}
            className={styles.AlertDeleteConfirmationModal__heading}
          >
            <Text variant="h3">{fm(messages.confirm_remove_heading)}</Text>
          </Cell>

          <Cell center left={3}>
            <button
              type="button"
              onClick={onCloseHandler}
              className={styles.AlertDeleteConfirmationModal__closeButton}
              data-testid="close-btn"
            >
              <Close />
              <span>Close</span>
            </button>
          </Cell>
        </Grid>

        <div className={styles.AlertDeleteConfirmationModal__body}>
          <Text tag="p" variant="body">
            {fm(messages.confirm_remove_body)}
          </Text>
        </div>

        <div className={styles.AlertDeleteConfirmationModal__buttons}>
          <ButtonSimple
            variant={ButtonVariant.secondary}
            fullWidth={false}
            onClick={onApproveHandler}
          >
            {fm(messages.confirm_remove_yes)}
          </ButtonSimple>

          <ButtonSimple
            variant={ButtonVariant.primary}
            fullWidth={false}
            onClick={onCloseHandler}
            className={styles.AlertDeleteConfirmationModal__buttonReject}
          >
            {fm(messages.confirm_remove_no)}
          </ButtonSimple>
        </div>
      </div>
    </Modal>
  )
}

export default AlertDeleteConfirmationModal
